import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { TableFooter, TablePagination } from "@material-ui/core";
import Loader from "../components/Loader2";
import {
  getRepeatedList,
  getCities,
  updateRepeatedList,
} from "../actions/bookingActions";
import Button from "@mui/material/Button";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import RaiseComplaintModal from "../components/Comments/raiseTicketModal";
import MessageIcon from "@material-ui/icons/Message";
import CommentsModal2 from "../components/Comments/repeatedListComment";
import { genrateRepeatedListFilter } from "../../helpers/generateUrl";
import moment from "moment";
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "90%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    tableContainer: {
      marginTop: "1rem",
      position: "sticky",
      top: "200px",
    },
  })
);

interface Props {
  getRepeatedList: any;
  repetedLead: any;
  getCities: any;
  cities: any;
  updateRepeatedList: any;
  loading: boolean;
}

const LeadsTable: React.FC<Props> = ({
  getRepeatedList,
  repetedLead,
  getCities,
  cities,
  updateRepeatedList,
  loading,
}) => {
  const classes = useStyles();
  document.querySelectorAll("#dateWidthCustom").forEach((el) => {
    el.addEventListener("keydown", function (e) {
      e.preventDefault();
    });
  });
  const [tableStatus, setTableStatus] = useState<any>("pending");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [leadId, setLeadId] = useState<number>(0);
  const [lead_id, setLead_id] = useState<any>();
  const [created_at, setCreatedAt] = useState<string>("");
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [raiseComplaint, setRaiseComplaint] = useState(false);
  const [open2, setOpen2] = useState<boolean>(false);
  const [activeButton, setActiveButton] = useState<any>("pending");
  const history = useHistory();
  useEffect(() => {
    getRepeatedList(`status=${tableStatus}`);
  }, [tableStatus]);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = repetedLead?.links && repetedLead?.links?.next?.split("?")[1];
      getRepeatedList(url);
    } else if (newPage < page) {
      let url =
        repetedLead?.links && repetedLead?.links?.previous?.split("?")[1];
      getRepeatedList(url);
    }
    setPage(newPage);
  };
  const filterBookings = (e:any) => {
    e.preventDefault();
    const body: any = {
      lead: lead_id,
      created_at: created_at,
      start_date,
      end_date,
    };
    const url = genrateRepeatedListFilter(body).substring(2);
    getRepeatedList(`${url}&page=1`);
    setPage(0);
  };
  const handleRaiseTicket = (id: number) => {
    setRaiseComplaint(true);
    setLeadId(id);
  };
  const handleModal = (id: number) => {
    setOpen2(true);
    setLeadId(id);
  };
  const handlePending = () => {
    setActiveButton("pending");
    setTableStatus("pending");
  };
  const handleConfirm = () => {
    setActiveButton("confirm");
    setTableStatus("confirmed");
  };
  const handleConfirmAction = async (id: any) => {
    const data: any = {
      action: "confirmed",
    };
    await updateRepeatedList(id, data);
    getRepeatedList(`status=${tableStatus}`);
  };
  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <h2>Repeated Lead Table</h2>
        <form onSubmit={filterBookings}>
          <Grid
            container
            spacing={3}
            direction="row"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                className="input"
                name="lead"
                type="number"
                placeholder="Lead Id"
                value={lead_id}
                variant="outlined"
                onChange={(e) => setLead_id(e.target.value)}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                className="input"
                id="dateWidthCustom"
                inputProps={{
                  max: moment().format("YYYY-MM-DD"),
                }}
                name="Created At"
                type="date"
                label="Created Date"
                value={created_at}
                variant="outlined"
                onChange={(e) => setCreatedAt(e.target.value as string)}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                className="input"
                id="dateWidthCustom"
                name="start_date"
                type="date"
                required={end_date!==""}
                label="Start Date"
                value={start_date}
                variant="outlined"
                onChange={(e) => setStart_date(e.target.value as string)}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                name="end_date"
                id="dateWidthCustom"
                type="date"
                required={start_date!==""}
                value={end_date}
                className="input"
                label="End Date"
                variant="outlined"
                onChange={(e) => setEnd_date(e.target.value as string)}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid container spacing={3} direction="row" style={{ margin: "0" }}>
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  type="submit"
                  // onClick={filterBookings}
                >
                  Filter
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => history.push("/dashboard/ce/RepetedList")}
                >
                  Reset
                </Button>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant={
                    activeButton == "type=send" ? "contained" : "outlined"
                  }
                  fullWidth
                 
                  color="secondary"
                  onClick={() => handlePending()}
                >
                  Pending
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant={
                    activeButton == "type=send" ? "contained" : "outlined"
                  }
                  fullWidth
                  color="primary"
                  onClick={() => handleConfirm()}
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>

        <div
          className="data-table"
          style={{ width: "100%", marginTop: "2rem" }}
        >
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "540px" }}
          >
            {loading ? (
              <Loader />
            ) : (
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">Lead ID</StyledTableCell>
                    <StyledTableCell align="center">Created At</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                    <StyledTableCell align="center">
                      Lead Source Url
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Patient name
                    </StyledTableCell>
                    <StyledTableCell align="center">City</StyledTableCell>
                    <StyledTableCell align="center">
                      Patient Area
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Call status
                    </StyledTableCell>
                    <StyledTableCell align="center">Language</StyledTableCell>
                    <StyledTableCell align="center">Priority</StyledTableCell>
                    <StyledTableCell align="center">Call Count</StyledTableCell>
                    <StyledTableCell align="center">
                      Status Action
                    </StyledTableCell>
                    <StyledTableCell align="center">Edit</StyledTableCell>
                    <StyledTableCell align="center">
                      Raise Ticket
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {repetedLead.results &&
                    repetedLead.results.length > 0 &&
                    repetedLead.results.map((lead: any, index: any) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="center">
                            {lead.lead.id}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {" "}
                            {new Date(lead.lead.created_at).toLocaleString()}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <div>
                              <MessageIcon
                                onClick={() => handleModal(lead.lead.id)}
                              />
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {lead.lead.lead_source_url}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {lead.lead.patient_name}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {lead.lead.patient_city}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {lead.lead.patient_area}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {lead.lead.call_status}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {lead.lead.language}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {lead.lead.priority}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {lead.lead.count}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {lead.action === "pending" ? (
                              <Button
                                color="primary"
                                variant="text"
                                onClick={() => handleConfirmAction(lead.id)}
                              >
                                Click to confirm
                              </Button>
                            ) : (
                              lead.action
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Link
                              to={`/dashboard/ce/fill-info?id=${lead.lead.id}`}
                            >
                              <EditIcon />
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Button
                              variant={
                                !raiseComplaint ? "contained" : "outlined"
                              }
                              color="secondary"
                              onClick={() => handleRaiseTicket(lead.lead.id)}
                            >
                              <ConfirmationNumberIcon />
                            </Button>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TablePagination
                    colSpan={6}
                    count={repetedLead.count || 0}
                    rowsPerPageOptions={[]}
                    rowsPerPage={rowsPerPage}
                    // onChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    SelectProps={{
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                  />
                </TableFooter>
              </Table>
            )}
          </TableContainer>
          <RaiseComplaintModal
            open={raiseComplaint}
            setOpen={setRaiseComplaint}
            leadId={Number(leadId)}
            bookingId={0} booking={undefined} totalPrice={0}          />
          <CommentsModal2
            open={open2}
            getCities={getCities}
            cities={cities}
            setOpen={setOpen2}
            leadId={leadId}
          />
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  repetedLead: state.BookingReducer.repetedLead,
  cities: state.BookingReducer.cities,
  loading: state.BookingReducer.loading,
});

export default connect(mapStateToProps, {
  getRepeatedList,
  getCities,
  updateRepeatedList,
})(LeadsTable);
