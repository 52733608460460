import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  getFamilyPackage,
  createNewBooking,
  getFamilyPackageBooking,
  postFamilyPackageBooking,
} from "../../actions/bookingActions";
import Button from '@mui/material/Button';
import { connect} from "react-redux";
import { FamilyPackageBookingList } from "./FamilyPackageList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "100%",
      padding: "20px 10px 10px 10px",
      background: "#ffffff",
      margin: "10px",
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    },
    paper: {
      marginTop: "2rem",
      width: "100%",
    }
  })
);

interface Props {
  getFamilyPackage: any;
  familyPackageList: any;
  createNewBooking: any;
  loading: boolean;
  getFamilyPackageBooking: any;
  postFamilyPackageBooking: any;
  familyPackageBookingList: any;
}

const FamilyPackageBooking: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [customerName, setCustomerName] = useState<string>("");
  const [customerEmail, setCustomerEmail] = useState<string>("");
  const [customerMobile, setCustomerMobile] =useState<any>("");
  const [noOfMember, setNoOfMember] = useState<string>("");
  const [noOfMonth,setNoOfMonth]=useState<any>("")
  const [packages, setPackage] = useState<any>("");
  const timer = useRef<any>(0);

  useEffect(() => {
    props.getFamilyPackage();
    props.getFamilyPackageBooking();
  }, [props.getFamilyPackage, props.getFamilyPackageBooking]);

  const [mobileValidation, setMobileValidation] = useState({
    customer_phonenumber: true,
  });

  const verifyMobile = (type: any) => {
    if(type==="mobile"){
      if(parseInt(customerMobile&&customerMobile[0])<6||customerMobile.length<10){
        setMobileValidation((prev: any) => ({
              ...prev,
              customer_phonenumber: false,
            }));}
    }
   
   
  };

  const submitForm = async (e: any) => {
    e.preventDefault();
    const data: any = {
      customer_name: customerName,
      customer_email: customerEmail,
      customer_mobile: customerMobile,
      no_of_member: noOfMember,
      family_package: packages && packages?.id,
    };
    await props.postFamilyPackageBooking(data);
    props.getFamilyPackage();
    props.getFamilyPackageBooking();
    setCustomerMobile("");
    setCustomerName("");
    setCustomerEmail("");
    setNoOfMember("");
    setPackage("");
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.contentHeader}>
        <div className={classes.leftContentHeader}>
          <h2>Family Package Booking</h2>
        </div>
        <div className={classes.rightContentHeader}></div>
      </div>

      <div className={classes.contentTable}>
        <form onSubmit={submitForm}>
          <Grid container spacing={2} style={{ margin: "1rem auto" }}>
            <Grid container item xs={12} md={12} spacing={2}>
              <Grid item xs={12} md={4}>
                <h5>Customer Name</h5>
                <TextField
                  className="input"
                  name="zone"
                  type="text"
                  value={customerName}
                  variant="outlined"
                  style={{ width: "100%", margin: "0" }}
                  required
                  onChange={(e) => String(e.target.value).length<=100? setCustomerName(e.target.value.replace(/[^a-zA-Z ]/g, '') as string):""}
                  placeholder={"Enter name of customer"}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <h5>Mobile Number</h5>
                <TextField
                  className="input"
                  name="customer_phonenumber"
                  type="number"
                  onWheel={(e: any) => e.target.blur()}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  value={customerMobile}
                  placeholder="Enter Mobile Number"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) => setCustomerMobile(e.target.value as string)}
                  onBlur={() => verifyMobile("mobile")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      customer_phonenumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.customer_phonenumber &&
                    "Incorrect Phone Number"
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <h5>Email</h5>
                <TextField
                  className="input"
                  name="customer_email"
                  type="email"
                  placeholder="customer.reports@redcliffelabs.com"
                  value={customerEmail || ""}
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) => setCustomerEmail(e.target.value as string)}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <h5>Package</h5>
                <Autocomplete
                  id="customerPackage"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setNoOfMember(obj?.quantity)
                      setNoOfMonth(obj?.months)
                      setPackage(newValue);
                    }
                  }}
                  disableClearable
                  options={props.familyPackageList.results}
                  limitTags={0}
                  freeSolo
                  disableCloseOnSelect
                  aria-required
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      props.getFamilyPackage(`search=${newInputValue}`);
                    }, 1000);
                  }}
                  value={""}
                  getOptionLabel={(option: any) => {
                    return option.name;
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      placeholder="Package"
                      variant="outlined"
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "0",
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <h5>Quantity</h5>
                <TextField
                  className="input"
                  name="no_of_family_member"
                  type="number"
                  value={noOfMember || ""}
                  variant="outlined"
                  style={{ width: "100%", margin: "0" }}
                  required
                  onChange={(e) => setNoOfMember(e.target.value as string)}
                  placeholder={"Quantity"}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <h5>Month</h5>
                <TextField
                  className="input"
                  name="month"
                  type="number"
                  value={noOfMonth || ""}
                  variant="outlined"
                  style={{ width: "100%", margin: "0" }}
                  required
                  onChange={(e) => setNoOfMonth(e.target.value as string)}
                  placeholder={"Month"}
                  disabled={true}
                />
              </Grid>
            
            </Grid>
          </Grid>

          <div className={classes.buttonContainer}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{ marginLeft: "1rem", marginBottom: "20px" }}
              disabled={props.loading || !customerName || !noOfMember || !mobileValidation.customer_phonenumber}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
      <div className={classes.contentTable}>
        <FamilyPackageBookingList
          familyPackageBookingList={props.familyPackageBookingList}
          getFamilyPackageBooking={props.getFamilyPackageBooking}
          loading={props.loading}
        />
      </div>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  familyPackageList: state.BookingReducer.familyPackageList,
  familyPackageBookingList: state.BookingReducer.familyPackageBookingList,
  createBooking: state.BookingReducer.createBooking,
  loading: state.BookingReducer.loading,
});

export default connect(mapStateToProps, {
  getFamilyPackage,
  createNewBooking,
  getFamilyPackageBooking,
  postFamilyPackageBooking,
})(FamilyPackageBooking);
