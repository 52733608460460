import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Grid, Select, MenuItem, TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import MessageIcon from "@material-ui/icons/Message";
import { getPrescriptionData, updatePrescriptionStatus, getCities } from "../actions/HealthAdviserAdminAction";
import { TableFooter, TablePagination } from "@material-ui/core";
import moment from "moment";
import Loader from "../components/loader";
import Button from '@mui/material/Button';
import { genrateCollectionCentrLedgerFilter } from "../../helpers/generateUrl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Modal from '@mui/material/Modal';
import ImageIcon from '@material-ui/icons/Image';
import { AddBoxTwoTone } from '@material-ui/icons';
import PrescriptionComments from '../components/Comments/prescriptionComments'
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
const StyledTableCell:any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
      padding: 5
    },

  })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "90%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    image: {
      width: "100px",
      height: "auto",
    },
    imageSM: {
      width: "60px",
      height: "60px",
      border: "2px solid #000 ",
      borderRadius: "50%"
    },
    imgContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "wrap"
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      flexWrap: "wrap"
    },
    imagePaper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[1],
    },
    tableContainer: {
      marginTop: "1rem",
      position: "sticky",
      top: "200px",
    },
  })
);
function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

interface Props {
  loading: boolean;
  getPrescriptionData: any;
  updatePrescriptionStatus: any;
  getCities: any;
  cities: any;
  prescriptionData: any;
}

const PrescriptionTable: React.FC<Props> = ({
  loading,
  getPrescriptionData,
  updatePrescriptionStatus,
  getCities,
  cities,
  prescriptionData,
}) => {
  const timer = useRef<any>(0);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [open2, setOpen2] = useState<boolean>(false)
  const [leadId, setLeadId] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [status, setStatus] = useState<any>("none");
  const [ladgerType, setLedgerType] = useState<any>("none");
  const [center, setCenter] = useState<any>([]);
  const [openImage, setImageOpen] = React.useState(false);
  const [imageUrl, setImageUrl] = useState("")
  const [modalStyle] = React.useState(getModalStyle);
  const [prescriptionStatus, setPrescriptionStatus] = useState<any>("pending")
  const history = useHistory();

  useEffect(() => {
    getPrescriptionData(`?status=${prescriptionStatus}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prescriptionStatus]);
  useEffect(() => {
    getCities()
  }, [])

  const dispatch = useDispatch();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = prescriptionData.links && prescriptionData.links.next.split("?")[1];
      getPrescriptionData(`?${url}`);
    } else if (newPage < page) {
      let url = prescriptionData.links && prescriptionData.links.previous.split("?")[1];
      getPrescriptionData(`?${url}`);
    }
    setPage(newPage);
  };
  const handleStartModal = (e: any) => {
    setImageUrl(e)
    setImageOpen(true)
  }
  const handleImageClose = () => {
    setImageOpen(false)

  }
  const handleConfirmPrescription = () => {
    setPrescriptionStatus("confirmed")
  };
  const handlePendongPrescription = () => {
    setPrescriptionStatus("pending")
  };
  const handleConfirm = (id: any) => {
    const data: any = {
      status: "confirmed"
    }
    updatePrescriptionStatus(data, id)
    setTimeout(() => {
      getPrescriptionData(`?status=${prescriptionStatus}`);
    }, 1000);

  }
  const handleModal = (id: number) => {
    setOpen2(true);
    setLeadId(id);
  };

  const body = (
    <div style={modalStyle} className={classes.imagePaper}>
      <img src={imageUrl} alt="popup" style={{ width: "550px", height: "450px" }} />
    </div>
  );
  const handleImageDownload = (file: any, name: any) => {
    
    var b64 = file
    var link = document.createElement('a');
    if (b64.indexOf("application/pdf") != -1) {
      link.download = `${name}.pdf`;
    }
    else if (b64.indexOf("application/vnd.openxmlformats") != -1) {
      link.download = `${name}.doc`;
    }
    else if (b64.indexOf("image/jpeg") != -1) {
      link.download = `${name}.jpeg`;
    }
    else {
      link.download = `${name}.docx`;
    }

    link.href = b64;
    link.click();

  }
  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <h4 style={{ textAlign: "left" }}>PRESCRIPTION</h4>
        <div className={classes.buttonContainer}>
          <Button variant="contained" onClick={handleConfirmPrescription} style={{ marginLeft: "1rem", backgroundColor: "#4caf50" }}>Confirm </Button>
          <Button variant="contained" onClick={handlePendongPrescription} style={{ marginLeft: "1rem", backgroundColor: "#f44336" }}>Pending </Button>
        </div>
        <div
          className="data-table"
          style={{ width: "100%", marginTop: "2rem" }}
        >
          <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "540px" }}>
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Patient Name</StyledTableCell>
                  <StyledTableCell align="center">Age</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                  <StyledTableCell align="center">Customer Name</StyledTableCell>
                  <StyledTableCell align="center">Mobile</StyledTableCell>
                  <StyledTableCell align="center">Address</StyledTableCell>
                  <StyledTableCell align="center">Test Name</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">Created At</StyledTableCell>
                  <StyledTableCell align="center">Update Status</StyledTableCell>
                  <StyledTableCell align="center">Image</StyledTableCell>
                  <StyledTableCell align="center">New booking</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {prescriptionData.results &&
                  prescriptionData.results.length > 0 &&
                  prescriptionData.results.map((data: any, index: any) => {
                    let pieces: any = data.image.split('.').pop()
                    const last: any = pieces
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">{data?.parent_name}</StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.age}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div>
                            <MessageIcon onClick={() => handleModal(data?.id)} />
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.mobile}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.address}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.testname}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.status}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.created_at.substring(0, 10)}{" "}
                          {data?.created_at.substring(11, 19)}{" "}
                          </StyledTableCell>
                        <StyledTableCell align="center">
                          {data.status === "pending" ? (
                            <Button
                              color="primary"
                              variant="text"
                              onClick={() => handleConfirm(data.id)}
                            >
                              Confirm Status
                            </Button>
                          ) : ""}

                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.base64file !== null && data.image === "" ? (<OfflineBoltIcon onClick={() => handleImageDownload(data.base64file, data?.name)} />) : data?.base64file === null && data.image !== "" ? (

                            last === "png" || last === "jpg" || last === "jpeg" ? (<a href={data?.image} rel="noreferrer noopener" target="_blank"><ImageIcon /></a>) : (<a href={data?.image} rel="noreferrer noopener" target="_blank"><PictureAsPdfIcon /></a>)

                          ) : data?.base64file === null && data.image === "" ? "File Not Available" : ""}
                        </StyledTableCell>
                        <StyledTableCell align="center" onClick={() => history.push(`/dashboard/haa/booking-with-lead/${data.id}`)}>
                          <AddBoxTwoTone />
                        </StyledTableCell>



                      </StyledTableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TablePagination
                  colSpan={6}
                  count={prescriptionData.count || 0}
                  rowsPerPageOptions={[]}
                  rowsPerPage={rowsPerPage}
                  // onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </TableFooter>
            </Table>
            <Modal
              open={openImage}
              onClose={handleImageClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              {body}
            </Modal>
            <PrescriptionComments open={open2} getCities={getCities} cities={cities} setOpen={setOpen2} leadId={leadId} />

          </TableContainer>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  prescriptionData: state.HealthAdviserAdminReducer.prescriptionData,
  loading: state.HealthAdviserAdminReducer.loading,
  cities: state.HealthAdviserAdminReducer.cities,
});

export default connect(mapStateToProps, { getPrescriptionData, updatePrescriptionStatus, getCities })(
  PrescriptionTable
);
