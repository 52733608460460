import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
    Button,
    MenuItem,
    TextField,
    Grid,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { updateTicketStatus } from '../../actions/salesLeadAction'
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "scroll",
        position: "sticky",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
        overflow: "scroll",
        position: "sticky",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem",
    },
}));

type ModalProps = {
    openTicketModal: any
    setOpenTicketModal: any
    ticketIndividualDetails: any
    updateTicketStatus: any;
    getBookingQueryTickets:any;
    bookingComplaints: any;
    setCollectionDate: any;
    packageList: any;
    getPackage: any;
    getAvailableSlots: any;
    zoneId: any;
    booking_slots: any;
    ticketDetails: any;
    collectionDate: any;

};

const CommentsModal2: React.FC<ModalProps> = ({
    openTicketModal,
    setOpenTicketModal,
    ticketIndividualDetails,
    updateTicketStatus,
    getBookingQueryTickets,
    bookingComplaints,
    setCollectionDate,
    packageList,
    getPackage,
    getAvailableSlots,
    zoneId,
    ticketDetails,
    collectionDate,

}) => {
    const classes = useStyles();
    const history = useHistory();
    const [disposition, setDisposition] = useState<any>("")
    const [subDisposition, setSubDisposition] = useState<any>("")
    const [subDispositionData, setSubDispostionData] = useState<any>()
    const [query, setQuery] = useState<any>("")
    const [slotKey, setSlotKey] = useState<any>(0);

    const handleClose = () => {
        setOpenTicketModal(false);
          getBookingQueryTickets()
        //  history.push("/dashboard/sla/booking/tickets")

    };
    useEffect(() => {
        if (disposition !== "") {
            const selecterDisposiotion = bookingComplaints.suggested_disposition.filter((data: any) => data.disposition === disposition)
            setSubDispostionData(selecterDisposiotion[0]?.sub_disposition)

        }

    }, [disposition])
    const submitComplaint = () => {
        const body = {
            query: query,
            action_disposition: disposition,
            action_sub_disposition: subDisposition
        }
        updateTicketStatus(body, ticketIndividualDetails?.id)
        getBookingQueryTickets()
        setDisposition("")
        setSubDisposition("")
        setSubDispostionData([])
        setQuery("")
        setOpenTicketModal(false);
    }

    const handleCollectionDate = (e: any) => {
        setCollectionDate(e.target.value);
        setSlotKey(e.target.value.substring(8, 10));
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openTicketModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
            >
                <Fade in={openTicketModal}>
                    <div className={classes.paper}>
                        <div className={classes.head}>
                            <h4
                                style={{
                                    marginBottom: "1rem",
                                }}
                                id="transition-modal-title"
                            >
                                Action
                            </h4>
                            <CloseIcon onClick={() => handleClose()} />
                        </div>
                        <Grid
                            container
                            direction="row"
                            alignItems="flex-start"
                            justify="space-between"
                            spacing={3}
                            style={{ margin: "1rem auto" }}
                        >


                            <Grid item xs={12} md={6}>
                                <TextField
                                    className="input"
                                    name="action"
                                    select
                                    label="Disposition"
                                    variant="outlined"
                                    value={disposition}
                                    style={{ width: "100%" }}
                                    onChange={(e) => setDisposition(e.target.value as string)}
                                >
                                   {bookingComplaints?.suggested_disposition && bookingComplaints?.suggested_disposition.length > 0 && bookingComplaints?.suggested_disposition.map((data: any) => {
                                        return (
                                            <MenuItem value={data?.disposition}>
                                                {data?.disposition}
                                            </MenuItem>
                                        )
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    className="input"
                                    name="action"
                                    select
                                    label="Sub Disposition"
                                    variant="outlined"
                                    disabled={disposition === ""}
                                    value={subDisposition}
                                    style={{ width: "100%" }}
                                    onChange={(e) => setSubDisposition(e.target.value as string)}
                                >
                                    {subDispositionData && subDispositionData !== undefined && subDispositionData.map((data: any) => {
                                        return (
                                            <MenuItem value={data}>
                                                {data}
                                            </MenuItem>
                                        )
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6} md={12}>
                                <TextareaAutosize
                                    maxRows={6}
                                    aria-label="maximum height"
                                    placeholder="Please write query....."
                                    value={query}
                                    onChange={(e) => setQuery(e.target.value as string)}
                                    style={{ width: 1100, height: 80 }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ height: "50px" }}
                                    onClick={submitComplaint}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>

    );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {
    updateTicketStatus
})(CommentsModal2);
