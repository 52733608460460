import React, { useState } from "react";
import { createStyles, Grid, makeStyles, withStyles, Table, TableContainer, TableHead, TableRow, Theme, TableCell, TableBody, Chip } from "@material-ui/core";
import { Paper, Button, TextField } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { getAssignedUnassignedBooking, getZone, updateBooking, getAvailableSlots } from '../actions/salesLeadAction'
import { useEffect } from "react";
import { useRef } from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from "react-router-dom";
import Loader from "../Components/loader";
// import './index.sass'
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,

        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px"
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
    })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 10,fontWeight:600
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

interface Props {
    getAssignedUnassignedBooking: any
    allBookings: any
    getZone: any
    zoneList: any
    updateBooking: any
    getAvailableSlots: any
    booking_slots: any
    loading: any
}

const grid = 8;
const getItemStyle = (isDragging: any, draggableStyle: any, status: any) => {
    return ({
        userSelect: "none",
        padding: 1,
        margin: `5px`,
        maxWidth: "200px",
        background: isDragging ? "violet" : status === "NA" ? "white" : status === "hold" ? "lightyellow" : status === "pending" ? "cyan" : status === "confirmed" ? "greenyellow" : status === "cancelled" ? "pink" : status === "bookingcancelled" ? "red" : "lightyellow",
        ...draggableStyle
    })
};

const getListStyle = (isDraggingOver: any) => ({
    padding: 1,
    minWidth: "200px",
});

const BookingCard: React.FC<Props> = ({
    getAssignedUnassignedBooking,
    allBookings,
    getZone,
    zoneList,
    updateBooking,
    getAvailableSlots,
    booking_slots,
    loading,
}) => {
    const classes = useStyles()
    const history = useHistory()

    useEffect(() => {
        getZone()
        return () => {
            getAssignedUnassignedBooking("", true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const [zone, setZone] = useState(0)
    const [collection_date, setCollection_date] = useState("")
    const [bookings, setBookings] = useState<any>([])
    const [phlebos, setPhlebos] = useState<any>([])
    const timer = useRef<any>(0)

    const filterBookings = () => {
        const body: any = {
            collection_date,
            "customer_zone": zone,
        }
        getAvailableSlots(collection_date, zone)
    }
    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <h2>Available Slots</h2>
            <Grid container spacing={1} direction="row" alignItems="center" justify="center" style={{ margin: "0" }}>
                <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                        id="zone"
                        onChange={(event, newValue) => {
                            if (newValue) {
                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                setZone(obj.id)
                            }
                        }}
                        options={zoneList?.results || []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        getOptionLabel={(option: any) => option.city.name}
                        onInputChange={(event, newInputValue) => {
                            clearTimeout(timer.current)
                            timer.current = setTimeout(() => {
                                getZone(newInputValue)
                            }, 1000)
                            if (newInputValue.length === 0) {
                                setZone(0)
                            }
                        }}
                        renderInput={(params) => (
                            <TextField className="input"
                                {...params}
                                label="Zone *"
                                variant="outlined"
                                style={{ width: "100%", margin: "0", padding: "0" }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <TextField
                        name="collection_date"
                        type="date"
                        value={collection_date}
                        className="input"
                        label="Collection Date *"
                        variant="outlined"
                        onChange={(e) => setCollection_date(e.target.value as string)}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Button
                        variant="contained"
                        color="secondary"

                        fullWidth
                        onClick={filterBookings}
                        disabled={collection_date === "" || zone === 0}
                    >
                        Filter
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Button
                        variant="contained"
                        color="primary"

                        fullWidth
                        onClick={() => history.push("/dashboard/sl/availableSlot")}
                        disabled={collection_date === "" && zone === 0}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
            <div className={classes.toolbar} />
            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "540px" }}>
                {loading ? <Loader /> : (
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="center">Start Time </StyledTableCell>
                                <StyledTableCell align="center">End Time </StyledTableCell>
                                <StyledTableCell align="center">Available Slots </StyledTableCell>
                                <StyledTableCell align="center">Maxbooking Slot</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {booking_slots && booking_slots.results && booking_slots.results[0].zone_data && booking_slots.results[0].zone_data.time_slots.map((data: any, index: any) => {
                                return (
                                    <StyledTableRow key={index}>

                                        <StyledTableCell style={{ height: "50px" }} align="center">{data?.start_time}</StyledTableCell>
                                        <StyledTableCell style={{ height: "50px" }} align="center">{data?.end_time}</StyledTableCell>
                                        <StyledTableCell style={{ height: "50px" }} align="center">{data?.available_slots}</StyledTableCell>
                                        <StyledTableCell style={{ height: "50px" }} align="center">{data?.maxbookingslot}</StyledTableCell>
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                )}
            </TableContainer>
        </main>
    );
}

//
const mapStateToProps = (state: any) => ({
    allBookings: state.SalesLeadReducer.allBookings,
    zoneList: state.SalesLeadReducer.zoneList,
    booking_slots: state.SalesLeadReducer.booking_slots,
    loading: state.SalesLeadReducer.loading,
})

export default connect(mapStateToProps, {
    getAssignedUnassignedBooking,
    getZone,
    updateBooking,
    getAvailableSlots,
})(BookingCard);