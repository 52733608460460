import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import {
    withStyles,
    Theme,
    createStyles,
    makeStyles,
} from "@material-ui/core/styles";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getBookingCalls } from '../../actions/HealthAdviserAdminAction'
import { useHistory } from 'react-router';
// import { updateUnregisterdBooking } from "../../actions/CustomerSupport";

let width = window.innerWidth;
let isMobile = width <= 500;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
    })
);
type ModalProps = {
    bookingCall:any;
    getBookingCalls:any;
    bookingId:any;

};
interface Props {
    getBookingCalls:any;
    loading: Boolean;
}

const BookingCallCount: React.FC<ModalProps> = ({
    bookingCall,
    getBookingCalls,
    bookingId,
}) => {
    const classes = useStyles();
     const history = useHistory()
    const [open, setOpen] = React.useState(false);
     useEffect(() => {
      
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ bookingCall.results])
  const handleClickOpen = () => {
    getBookingCalls(bookingId)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

    return (
        <div>
      <Button variant="contained" color="primary" style={{ marginLeft: "1rem",marginTop:"-10px" }} onClick={handleClickOpen}>Booking Call Count</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          {bookingCall && bookingCall.results && bookingCall.results.length>0?(
 bookingCall.results.map((data:any)=>{ 
            return(
              
             
              <div>
              <DialogTitle>Customer Name : {data.booking.customer_name}</DialogTitle> 
              <DialogTitle> First Call: {new Date(data.created_at).toLocaleString()}</DialogTitle> 
              <DialogTitle> Last Call: {new Date(data.updated_at).toLocaleString()}</DialogTitle> 
               <DialogTitle> Customer Support Call : {data.cs_call_count}</DialogTitle> 
                <DialogTitle> Phlebo User Call: {data.ph_call_count}</DialogTitle> 
                 <DialogTitle> Phlebo Admin Call: {data.pha_call_count}</DialogTitle> 
                 <DialogTitle> Sample Accessioning Call: {data.sa_call_count}</DialogTitle> 
                 <DialogTitle> Support Executive Call: {data.se_call_count}</DialogTitle> 
              </div>
          )})
          ):<DialogTitle> No Booking Call Found</DialogTitle> }
         
          
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
    );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {
    // updateUnregisterdBooking
    getBookingCalls,
})(BookingCallCount);
