
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    withStyles,
    Theme,
    createStyles,
    makeStyles,
} from "@material-ui/core/styles";
import { Grid, Select, Chip, MenuItem, TextField } from '@material-ui/core'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from 'react-router-dom';
import Autocomplete from "@material-ui/lab/Autocomplete";
import EditIcon from "@material-ui/icons/Edit";
import { getRCFreshLeadDData, getCities, getAgentList } from "../actions/salesLeadAction";
import { TableFooter, TablePagination } from "@material-ui/core";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import moment from 'moment';
import Loader from "../Components/loader";
import { Button } from "@material-ui/core";
import { MessageRounded } from '@material-ui/icons';
import { generateRCFreshsUrl } from "../../helpers/generateUrl";
import RaiseComplaintModal from "../Components/comments/raiseTicketModal";
// import { generateCallRecordingUrl } from "../../helpers/generateUrl";
import { ExitToAppRounded } from "@material-ui/icons";

const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,fontWeight:600
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 700,
        },
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "90%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
          },
    })
);

interface Props {
    getRCFreshLeadDData: any;
    rcFreshLead: any;
    getCities: any;
    cities: any;
    getAgentList: any;
    agentsList: any;
    loading: boolean;

}

const RCFreshTable: React.FC<Props> = ({
    getRCFreshLeadDData,
    rcFreshLead,
    getCities,
    cities,
    getAgentList,
    agentsList,
    loading,
}) => {
    const dispatch = useDispatch()
    const classes = useStyles();
    const timer = useRef<any>(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [raiseComplaint, setRaiseComplaint] = useState(false);
    const [leadId, setLeadId] = useState<number>(0);
    const [category, setCategory] = useState<any>([]);
    const [priority, setPriority] = useState<any>([]);
    const [city, setCity] = useState<any>([]);
    const [agent, setAgent] = useState<any>([]);
    const [start_date, setStart_date] = useState<string>("");
    const [end_date, setEnd_date] = useState<string>("");
    const [followUpDate, setFollowUpDate] = useState<string>("");
    const [partner, setPartner] = useState<any>([]);
    const [phone, setPhone] = useState("")
    const [id, setId] = useState<string>("");
    const history = useHistory()

    useEffect(() => {
        getRCFreshLeadDData()
        getCities()
        getAgentList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = rcFreshLead.next;

            getRCFreshLeadDData(url.substring(url.indexOf("?")));
        } else if (newPage < page) {
            let url = rcFreshLead.previous;

            getRCFreshLeadDData(
                url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?"))
            );
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        getRCFreshLeadDData(`?page_size=${event.target.value}`);
        setPage(0);
    };

    const filterLeads = () => {
        const body: any = {
           lead_call_status_id: category.join(","),
            followUpDate,
            city: city.join(","),
            start_date,
            phone,
            end_date,
            agent: agent.join(","),
            id,
        };
        const url = generateRCFreshsUrl(body).substring(2);
        getRCFreshLeadDData(`?${url}`);
        setPage(0);
    };

    const handleRaiseTicket = (id: number) => {
        setRaiseComplaint(true);
        setLeadId(id);
    };

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <h4 style={{ fontWeight: "bold" }}>RC FRESH LEADS TABLE</h4>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                >
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            name="leadId"
                            type="number"
                            value={id}
                            className="input"
                            variant="outlined"
                            placeholder="Lead Id"
                            onChange={(e) => setId(e.target.value as string)}
                            style={{ width: "100%" }}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="phone"
                            type="text"
                            label="Phone"
                            value={phone}
                            variant="outlined"
                            onChange={(e) => setPhone(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                            id="city"
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id);
                                }
                                setCity(sourceIds);
                            }}
                            options={cities}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            getOptionLabel={(option: any) => option.name}
                            getOptionDisabled={(option: any) => {
                                return city.includes(option.id);
                            }}
                            disableClearable
                            disableCloseOnSelect
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    getCities(newInputValue);
                                }, 1000);
                                if (newInputValue.length === 0) {
                                    setCity([]);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    placeholder="City"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                            id="agent"
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id);
                                }
                                setAgent(sourceIds);
                            }}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            options={agentsList.results||[]}
                            getOptionDisabled={(option: any) => {
                                return agent.includes(option.id);
                            }}
                            disableClearable
                            disableCloseOnSelect
                            getOptionLabel={(option: any) =>
                                option.user && option.user.username
                            }
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    getAgentList(`?code=${newInputValue.replace(" ", "")}`);
                                }, 1000);
                                if (newInputValue.length === 0) {
                                    setPartner([]);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    placeholder="Agent"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="start_date"
                            type="date"
                            label="Start Date"
                            value={start_date}
                            variant="outlined"
                            onChange={(e) => setStart_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            name="end_date"
                            type="date"
                            value={end_date}
                            className="input"
                            label="End Date"
                            variant="outlined"
                            onChange={(e) => setEnd_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    
                    <Grid item xs={12} sm={6} md={3}>
                        <Button
                            variant="contained"
                            color="secondary"
                        
                            fullWidth
                            onClick={filterLeads}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Button
                            variant="contained"
                            color="primary"
                        
                            fullWidth
                            onClick={() => history.push("/dashboard/sl/RCFreshLeadsTable")}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <div className={classes.toolbar} />
                <div className="data-table" style={{ width: "100%", marginTop: "2rem" }}>
                <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "560px" }}>
                        {loading ? <Loader /> : (
                           <Table stickyHeader aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell>Lead ID</StyledTableCell>
                                        <StyledTableCell align="center">Count</StyledTableCell>
                                        <StyledTableCell align="center">Created At</StyledTableCell>
                                        <StyledTableCell align="center">
                                            Lead Source URL
                                        </StyledTableCell>
                                        <StyledTableCell align="center">Agent</StyledTableCell>
                                        <StyledTableCell align="center">Call</StyledTableCell>
                                        <StyledTableCell align="center">
                                            Contact number
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            Whatsapp contact
                                        </StyledTableCell>
                                        <StyledTableCell align="center">Patient name</StyledTableCell>
                                        <StyledTableCell align="center">
                                            Calling gender
                                        </StyledTableCell>
                                        <StyledTableCell align="center">Patient age</StyledTableCell>
                                        <StyledTableCell align="center">Patient Area</StyledTableCell>
                                        <StyledTableCell align="center">Lead source</StyledTableCell>
                                        <StyledTableCell align="center">Call status</StyledTableCell>
                                        <StyledTableCell align="center">Language</StyledTableCell>
                                        <StyledTableCell align="center">Priority</StyledTableCell>
                                        <StyledTableCell align="center">Edit</StyledTableCell>
                                        <StyledTableCell align="center">Raise Ticket</StyledTableCell>

                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {rcFreshLead.results && rcFreshLead.results.length > 0 && rcFreshLead.results.map((lead: any, index: any) => {
                                        return (
                                            <StyledTableRow key={lead.id}>
                                                <StyledTableCell align="center">{lead.lead.id}</StyledTableCell>
                                                <StyledTableCell align="center">{lead.lead.count}</StyledTableCell>
                                                <StyledTableCell align="center"> {new Date(lead.created_at).toLocaleString()}</StyledTableCell>
                                                <StyledTableCell align="center">{lead.lead.lead_source}</StyledTableCell>
                                                <StyledTableCell align="center">{lead.panel_user}</StyledTableCell>
                                                <StyledTableCell align="center">{lead.lead.call}</StyledTableCell>
                                                <StyledTableCell align="center">{lead.lead.content_number}</StyledTableCell>
                                                <StyledTableCell align="center">{lead.lead.whatsapp_contact}</StyledTableCell>
                                                <StyledTableCell align="center">{lead.lead.patient_name}</StyledTableCell>
                                                <StyledTableCell align="center">{lead.lead.calling_gender}</StyledTableCell>
                                                <StyledTableCell align="center">{lead.lead.patient_age}</StyledTableCell>
                                                <StyledTableCell align="center">{lead.lead.patient_area}</StyledTableCell>
                                                <StyledTableCell align="center">{lead.lead.lead_source}</StyledTableCell>
                                                <StyledTableCell align="center">{lead.lead.call_status}</StyledTableCell>
                                                <StyledTableCell align="center">{lead.lead.language}</StyledTableCell>
                                                <StyledTableCell align="center">{lead.lead.priority}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Link to={`/dashboard/sl/fill-info?id=${lead.lead.id}`}>
                                                        <EditIcon />
                                                    </Link>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Button
                                                        variant={!raiseComplaint ? "contained" : "outlined"}
                                                        color="secondary"
                                                        onClick={() => handleRaiseTicket(lead.lead.id)}
                                                    >
                                                        <ConfirmationNumberIcon />
                                                    </Button>
                                                </StyledTableCell>



                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TablePagination
                                        colSpan={6}
                                        count={rcFreshLead.count || 0}
                                        rowsPerPageOptions={[10, 20, 30, 40, 50]}
                                        rowsPerPage={rowsPerPage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                    />
                                </TableFooter>
                            </Table>
                        )}
                    </TableContainer>
                </div>
                {/* <RaiseComplaintModal
                    open={raiseComplaint}
                    setOpen={setRaiseComplaint}
                    leadId={Number(leadId)}
                    bookingId={0}
                /> */}
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    rcFreshLead: state.SalesLeadReducer.rcFreshLead,
    cities: state.SalesLeadReducer.cities,
    agentsList: state.SalesLeadReducer.agentsList,
    loading: state.SalesLeadReducer.loading,
});

export default connect(mapStateToProps, {
    getRCFreshLeadDData,
    getCities,
    getAgentList,
})(
    RCFreshTable
);
