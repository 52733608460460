export const ACTIONS = {
    CHANGE_PASSWORD: "change_password",
    GET_PIN_CODE: "cca/get_pin_code",
    GET_CITIES: "cca/get_cities",
    GET_AREA: "cca/get_area",
    GET_AGENTS: "cca/get_agents",
    GET_AGENTS_BY_USERGROUP: "cca/get_agents_by_usergroup",
    GET_PACKAGES: "cca/get_packages",
    GET_PHLEBOS: "cca/get_phlebos",
    GET_PARTNERS: "cca/get_partners",
    GET_COUPONS: "cca/get_coupons",
    CREATE_BOOKING: "cca/create_booking",
    UPDATE_BOOKING: "cca/update_booking",
    GET_BOOKING_LIST: "cca/get_booking_list",
    GET_LEAD_DETAILS: "cca/get_lead_details",
    GET_LEAD_BY_ID: "cca/get_lead_by_id",
    LEAD_DETAILS_RESET: "cca/lead_details_reset",
    GET_LEADS: "cca/get_leads",
    GET_BOOKING_BY_ID: "cca/get_booking_by_id",
    CLICK_TO_CALL: "cca/click_to_call",
    GET_POSTIVE_CALLS_COMMENTS: "cca/get_postive_calls_commnets",
    ADD_POSTIVE_CALLS_COMMENT: "cca/add_comments",
    SET_COMMENTS: "cca/set_commnets",
    UPLOAD_FILE: "cca/upload",
    SET_LOADING: "cca/set_loading",
    SEARCH_CALL: "cca/serach",
    BOOKING_RESET: "cca/booking_reset",
    ADD_COMMENT: "positive_calls/add_comment",
    CREATE_CALL: "cca/createcall",
    GET_LEAD_SOURCE: "cca/getleadsource",
    GET_RECORDINGS: "cca/get_recordings",
    GET_BOOKINGS_COMMENTS: "cca/get_bookings_commnets",
    ADD_BOOKINGS_COMMENT: "cca/add_bookings_comments",
    GET_WHATSAPP_COMMENTS: "cca/get_whatsapp_comments",
    GET_WHATSAPP_COMMENTS_BY_LEAD: "cca/get_whatsapp_comments_by_lead",
    SEND_WHATSAPP_COMMENTS: "cca/send",
    GET_CALL_STATS: "cca/get_call_stats",
    RAISE_COMPLAINT: "cca/raise_complaint",
    GET_COMPLAINTS: "cca/get_complaints",
    ASSIGN_DOCTOR: "cca/assign_doctor",
    GET_BREAK_RESPONSE: "cca/get_break_response",
    SEND_FEEDBACK: "cca/send_feedback",
    GET_AVAILABLE_SLOTS: "cca/get_available_slots",
    GET_ALL_LEADS: "cca/get_all_leads",
    LEAD_TRANSFER_BY_COMMA: "cca/set_comments",
    GET_PANEL_USERS: "cca/get_panel_user",
    GET_CLIENT_LEDGER_DETAILS: "cca/get_client_ledger_details/",
    GET_CENTER_INFORMATION: "cca/get_center_information",
    GET_TIMESLOTS: "cca/get_timeslot",
    CREATE_ADDITIONAL_BOOKING: "cca/create_additional_booking",
    UPDATE_ADDITIONAL_BOOKING: "cca/update_additional_booking",
    CREATE_IMAGING_BOOKING: "cca/CREATE_IMAGING_BOOKING",
    GET_COLLECTION_SLOT: "cca/get_collection_slot",
    GET_QRCODE_DATA: "cca/get_qrcode_data",
    GET_PACKAGE_TUBE_DETAILS: "cca/get_package_tube_details",
    GET_PAYMENT_INFORMATION: "cca/get_payment_information",
    GET_PAYMENT_SEND_LINK: "cca/get_payment_link",
    GET_RED_COIN: "cca/get_red_coin",
    GET_SUB_CENTER_INFORMATION: "cca/get_sub_center_information",
    CHECK_GEOFENCE_AREA: "cca/checking-geofence-area",
    GET_IMAGING_TIMESLOTS: "cca/get_imaging_time_slots",
    GET_MULTIPLE_PACKAGES: "cca/get_multiple_package",
    GET_DISPLAY_CITIES: "ce/get_display_cities",
    GET_USERS_LIST: "cca/get_user_list",
    UPDATE_USERS_LIST: "cca/update_user_list",
    GET_PANEL_COMMENT: "cca/get_panel_comments",
    GET_PANEL_USER_LIST_DATA: "cca/get_panel_user_list_data",
    GET_PANELUSER_ACTIVE_DEACTIVE: "cca/get_active_deactive_panel_user",
    GET_AGENT_DATA: "cca/get_agent_data",
    GET_ASSIGNED_USERGROUP: "cca/get_assigned_usergroup",
    UPDATE_USERS_STATUS: "cca/update_user_status",
    UPDATED_PANEL_USER: "cca/updated_panel_user",
    CREATE_PANEL_USER: "cca/create_panel_user",
    GET_PRESCRIPTION_DATA: "cca/get_prescription_data",
    GET_MAMPMYINDIA_ACCESS_TOKEN: "cca/get-mapmyindia-access-token",
    POST_CUSTOMER_REVIEW: "cca/get_customer_review",
    GET_CUSTOMER_REVIEW_DATA: "cca/get_customer_review_data",
    GET_NOTIFICATION_MESSAGE: "cca/get_notification_message",
    GET_TICKET_DATA: "cca/get_ticket_data",
    GET_TICKET_CATEGORIES: "cca/get_ticket_category",
    GET_TICEKT_SUB_CATEGORIES: "cca/get_ticket_categories",
    CREATE_TASK_TICKET: "cca/create_task_ticket",
    GET_RED_TECH_COMMENT: "cca/redtech_comments/",
    POST_RED_TECH_COMMENT: "cca/postredtech_comments/",
    RAISE_IMAGING_COMPLAINT: "cca/imagining_booking_ticket",
    GET_AGENT_RAISE_TICEKT_DATA: "cca/raise_ticekt_data",
    GET_USER_DETAILS: "cca/get_user_details",
    CREATE_IMAGING_BOOKING_CUSTOM: "cca/imaging_booking_custom",
    CHECK_GEOFENCE_AREA_MAMPMYINDIA: "cca/checking-geofence-area-mapmyindia",
    SET_GEO_LOADING: "cca/set_geo_loading",
    SET_GEO_CHECK_ERROR: "cca/set_geo_check_error",
    CREATE_PILOT_BOOKING: "cca/create_pilot_booking",
    GET_CE_CENTER_FOR_CC: "cca/get_ce_center_for_cc",
    GET_CURRENT_DAY_BOOKING: "cca/get_current_day_booking",
    GET_ALL_BOOKING_EXPORTS: "cca/get_all_booking_exports",
};

interface GetRedTechComment {
    type: typeof ACTIONS.GET_RED_TECH_COMMENT;
    payload: Array<any>;
}
interface GetTicketData {
    type: typeof ACTIONS.GET_TICKET_DATA;
    payload: Object;
}
interface GetTicketCategory {
    type: typeof ACTIONS.GET_TICKET_CATEGORIES;
    payload: Object;
}
interface GetTicketSubCategories {
    type: typeof ACTIONS.GET_TICEKT_SUB_CATEGORIES;
    payload: Object;
}
interface PostRedTechComment {
    type: typeof ACTIONS.POST_RED_TECH_COMMENT;
    payload: Object;
}
interface CreatePilotBooking {
    type: typeof ACTIONS.CREATE_PILOT_BOOKING;
    payload: boolean;
}
interface CreateTaskTicket {
    type: typeof ACTIONS.CREATE_TASK_TICKET;
    payload: Array<any>;
}
interface GetPinCode {
    type: typeof ACTIONS.GET_PIN_CODE;
    payload: Array<any>;
}
interface GetNotificationMessage {
    type: typeof ACTIONS.GET_NOTIFICATION_MESSAGE;
    payload: Array<any>;
}
interface GetCustomerReviewData {
    type: typeof ACTIONS.GET_CUSTOMER_REVIEW_DATA;
    payload: Array<any>;
}
interface GetMapMyIndiaAccessToken {
    type: typeof ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN
    payload: Array<any>
}
interface CreateCustomerReview {
    type: typeof ACTIONS.POST_CUSTOMER_REVIEW
    payload: Array<any>
}
interface CheckGeoFenceAreaMapMyIndia {
    type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA
    payload: Array<any>
}
interface GetPrescriptionData {
    type: typeof ACTIONS.GET_PRESCRIPTION_DATA;
    payload: Array<any>;
}
interface CreatePanelUser {
    type: typeof ACTIONS.CREATE_PANEL_USER;
    payload: Object;
}
interface UpdatedPanelUser {
    type: typeof ACTIONS.UPDATED_PANEL_USER;
    payload: Object;
}
interface SetUserList {
    type: typeof ACTIONS.UPDATE_USERS_LIST;
    payload: Object;
}
interface GetPanelComments {
    type: typeof ACTIONS.GET_PANEL_COMMENT;
    payload: Array<any>;
}
interface GetPanelUserList {
    type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA;
    payload: Array<any>;
}
interface GetAgentData {
    type: typeof ACTIONS.GET_AGENT_DATA;
    payload: Array<any>;
}
interface GetAssignedUsergroup {
    type: typeof ACTIONS.GET_ASSIGNED_USERGROUP;
    payload: Object;
}
interface GetBreakResponse {
    type: typeof ACTIONS.GET_BREAK_RESPONSE;
    payload: Object;
}
interface UpdateUserStatus {
    type: typeof ACTIONS.UPDATE_USERS_STATUS;
    payload: Object;
}
interface GetDisplayCities {
    type: typeof ACTIONS.GET_DISPLAY_CITIES;
    payload: Array<any>;
}
interface GetPaymentLink {
    type: typeof ACTIONS.GET_PAYMENT_SEND_LINK;
    payload: Array<any>;
}
interface GetImagingTimeSlots {
    type: typeof ACTIONS.GET_IMAGING_TIMESLOTS;
    payload: Array<any>;
}

interface GetPackageTubeDetails {
    type: typeof ACTIONS.GET_PACKAGE_TUBE_DETAILS;
    payload: Array<any>;
}
interface GetPaymentInfro {
    type: typeof ACTIONS.GET_PAYMENT_INFORMATION;
    payload: Array<any>;
}
interface GetCollectionSlot {
    type: typeof ACTIONS.GET_COLLECTION_SLOT;
    payload: Array<any>;
}
interface GetQRCodeData {
    type: typeof ACTIONS.GET_QRCODE_DATA;
    payload: Array<any>;
}
interface GetCeCenterForCc {
    type: typeof ACTIONS.GET_CE_CENTER_FOR_CC;
    payload: Array<any>;
}
interface CreateImagingBooking {
    type: typeof ACTIONS.CREATE_IMAGING_BOOKING;
    payload: boolean;
}

interface CheckGeoCheckError {
    type: typeof ACTIONS.SET_GEO_CHECK_ERROR;
    payload: boolean;
}
interface CreateAdditionalBooking {
    type: typeof ACTIONS.CREATE_ADDITIONAL_BOOKING;
    payload: Object;
}
interface UpdateAdditionalBooking {
    type: typeof ACTIONS.UPDATE_ADDITIONAL_BOOKING;
    payload: Object;
}
interface GetTimeSlot {
    type: typeof ACTIONS.GET_TIMESLOTS;
    payload: Array<any>;
}
interface ChangePassword {
    type: typeof ACTIONS.CHANGE_PASSWORD;
    payload: Object;
}

interface UploadFile {
    type: typeof ACTIONS.UPLOAD_FILE;
    payload: Object;
}

interface GetCities {
    type: typeof ACTIONS.GET_CITIES;
    payload: Array<any>;
}

interface GetArea {
    type: typeof ACTIONS.GET_AREA;
    payload: Array<any>;
}

interface GetAgents {
    type: typeof ACTIONS.GET_AGENTS;
    payload: Array<any>;
}

interface GetPhlebo {
    type: typeof ACTIONS.GET_PHLEBOS;
    payload: Array<any>;
}


interface SetComments {
    type: typeof ACTIONS.SET_COMMENTS;
    payload: Object;
}

interface GetPositiveCallsComments {
    type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
    payload: Object;
}

interface AddPositiveCallsComments {
    type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
    payload: Object;
}

interface ClickToCall {
    type: typeof ACTIONS.CLICK_TO_CALL;
    payload: Array<any>;
}

interface GetPackages {
    type: typeof ACTIONS.GET_PACKAGES;
    payload: Array<any>;
}

interface GetPartners {
    type: typeof ACTIONS.GET_PARTNERS;
    payload: Array<any>;
}

interface GetCoupons {
    type: typeof ACTIONS.GET_COUPONS;
    payload: Array<any>;
}

interface SetLoading {
    type: typeof ACTIONS.SET_LOADING;
    payload: boolean;
}
interface CreateImagingBookingCustom {
    type: typeof ACTIONS.CREATE_IMAGING_BOOKING_CUSTOM;
    payload: boolean;
}

interface CreateBooking {
    type: typeof ACTIONS.CREATE_BOOKING;
    payload: boolean;
}

interface UpdateBooking {
    type: typeof ACTIONS.UPDATE_BOOKING;
    payload: boolean;
}
interface GeoLoading {
    type: typeof ACTIONS.SET_GEO_LOADING;
    payload: boolean;
}

interface GetBookings {
    type: typeof ACTIONS.GET_BOOKING_LIST;
    payload: boolean;
}

interface GetBookingById {
    type: typeof ACTIONS.GET_BOOKING_BY_ID;
    payload: boolean;
}
interface GetUserDetails {
    type: typeof ACTIONS.GET_USER_DETAILS;
    payload: Object;
}
interface GetLeads {
    type: typeof ACTIONS.GET_LEADS;
    payload: boolean;
}

interface GetLeadDetails {
    type: typeof ACTIONS.GET_LEAD_DETAILS;
    payload: boolean;
}
interface GetMultiplepackage {
    type: typeof ACTIONS.GET_MULTIPLE_PACKAGES;
    payload: Array<any>;
}

interface GetLeadById {
    type: typeof ACTIONS.GET_LEAD_BY_ID;
    payload: boolean;
}

interface ResetLeadDetails {
    type: typeof ACTIONS.LEAD_DETAILS_RESET;
    payload: boolean;
}

interface GetPinCode {
    type: typeof ACTIONS.GET_PIN_CODE;
    payload: Array<any>;
}
interface ChangePassword {
    type: typeof ACTIONS.CHANGE_PASSWORD;
    payload: Object;
}
interface GetCities {
    type: typeof ACTIONS.GET_CITIES;
    payload: Array<any>;
}

interface GetArea {
    type: typeof ACTIONS.GET_AREA;
    payload: Array<any>;
}
interface GetPhlebo {
    type: typeof ACTIONS.GET_PHLEBOS;
    payload: Array<any>;
}

interface GetAgents {
    type: typeof ACTIONS.GET_AGENTS;
    payload: Array<any>;
}
interface SetComments {
    type: typeof ACTIONS.SET_COMMENTS;
    payload: Object;
}

interface GetPositiveCallsComments {
    type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
    payload: Object;
}

interface AddPositiveCallsComments {
    type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
    payload: Object;
}
interface ClickToCall {
    type: typeof ACTIONS.CLICK_TO_CALL;
    payload: Array<any>;
}

interface GetPackages {
    type: typeof ACTIONS.GET_PACKAGES;
    payload: Array<any>;
}

interface GetPartners {
    type: typeof ACTIONS.GET_PARTNERS;
    payload: Array<any>;
}

interface GetCoupons {
    type: typeof ACTIONS.GET_COUPONS;
    payload: Array<any>;
}

interface SetLoading {
    type: typeof ACTIONS.SET_LOADING;
    payload: boolean;
}

interface CreateBooking {
    type: typeof ACTIONS.CREATE_BOOKING;
    payload: boolean;
}
interface getAgentDataRaiseTicektData {
    type: typeof ACTIONS.GET_AGENT_RAISE_TICEKT_DATA;
    payload: boolean;
}

interface UpdateBooking {
    type: typeof ACTIONS.UPDATE_BOOKING;
    payload: boolean;
}

interface GetBookings {
    type: typeof ACTIONS.GET_BOOKING_LIST;
    payload: boolean;
}

interface GetBookingById {
    type: typeof ACTIONS.GET_BOOKING_BY_ID;
    payload: boolean;
}

interface GetLeads {
    type: typeof ACTIONS.GET_LEADS;
    payload: boolean;
}

interface GetLeadDetails {
    type: typeof ACTIONS.GET_LEAD_DETAILS;
    payload: boolean;
}

interface GetLeadById {
    type: typeof ACTIONS.GET_LEAD_BY_ID;
    payload: boolean;
}

interface ResetLeadDetails {
    type: typeof ACTIONS.LEAD_DETAILS_RESET;
    payload: boolean;
}
interface SearchCall {
    type: typeof ACTIONS.SEARCH_CALL;
    payload: Object;
}
interface SearchCall {
    type: typeof ACTIONS.SEARCH_CALL;
    payload: Object;
}
interface GetAgentByUserGroup {
    type: typeof ACTIONS.GET_AGENTS_BY_USERGROUP;
    payload: Object;
}
interface GetClientLedgerDetails {
    type: typeof ACTIONS.GET_CLIENT_LEDGER_DETAILS;
    payload: Array<any>;
}
interface GetCenterInfo {
    type: typeof ACTIONS.GET_CENTER_INFORMATION;
    payload: Array<any>;
}
interface GetSubCenterInformation {
    type: typeof ACTIONS.GET_SUB_CENTER_INFORMATION;
    payload: Array<any>;
}
interface GetRedCoin {
    type: typeof ACTIONS.GET_RED_COIN;
    payload: Array<any>;
}
interface CheckGeoFenceArea {
    type: typeof ACTIONS.CHECK_GEOFENCE_AREA
    payload: Array<any>
}
interface PostImagingComplaint {
    type: typeof ACTIONS.RAISE_IMAGING_COMPLAINT
    payload: Array<any>
}
interface getCurrentDayBooking {
    type: typeof ACTIONS.GET_CURRENT_DAY_BOOKING;
    payload: Array<any>;
}
interface GetAllBookingExports {
    type: typeof ACTIONS.GET_ALL_BOOKING_EXPORTS;
    payload: Array<any>;
  }


export type PhleboAdminTypes =
    | SearchCall
    | GetPinCode
    | ChangePassword
    | GetCities
    | GetArea
    | GetAgents
    | GetPhlebo
    | GetAgentByUserGroup
    | GetPackages
    | CreateBooking
    | UpdateBooking
    | GetPartners
    | GetCoupons
    | GetBookings
    | GetBookingById
    | SetLoading
    | GetLeadDetails
    | GetLeads
    | GetLeadById
    | ResetLeadDetails
    | ClickToCall
    | GetPositiveCallsComments
    | AddPositiveCallsComments
    | SetComments
    | UploadFile
    | GetPinCode
    | ChangePassword
    | GetCities
    | GetArea
    | GetAgents
    | GetPhlebo
    | GetPackages
    | CreateBooking
    | UpdateBooking
    | GetPartners
    | GetCoupons
    | GetBookings
    | GetBookingById
    | SetLoading
    | GetLeadDetails
    | GetLeads
    | GetLeadById
    | ResetLeadDetails
    | ClickToCall
    | GetPositiveCallsComments
    | AddPositiveCallsComments
    | SetComments
    | SearchCall
    | GetClientLedgerDetails
    | GetCenterInfo
    | GetTimeSlot
    | CreateAdditionalBooking
    | UpdateAdditionalBooking
    | CreateImagingBooking
    | GetCollectionSlot
    | GetQRCodeData
    | GetPackageTubeDetails
    | GetPaymentInfro
    | GetPaymentLink
    | GetSubCenterInformation
    | GetRedCoin
    | CheckGeoFenceArea
    | GetImagingTimeSlots
    | GetMultiplepackage
    | GetDisplayCities
    | SetUserList
    | GetPanelComments
    | GetPanelUserList
    | GetAgentData
    | GetAssignedUsergroup
    | UpdateUserStatus
    | UpdatedPanelUser
    | CreatePanelUser
    | GetPrescriptionData
    | CheckGeoFenceAreaMapMyIndia
    | GetMapMyIndiaAccessToken
    | CreateCustomerReview
    | GetCustomerReviewData
    | GetNotificationMessage
    | GetTicketData
    | GetTicketCategory
    | GetTicketSubCategories
    | CreateTaskTicket
    | GetRedTechComment
    | PostRedTechComment
    | PostImagingComplaint
    | getAgentDataRaiseTicektData
    | GetUserDetails
    | CreateImagingBookingCustom
    | GeoLoading
    | CheckGeoCheckError
    | CreatePilotBooking
    | GetCeCenterForCc
    | GetBreakResponse
    | getCurrentDayBooking
    | GetAllBookingExports;