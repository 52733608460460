import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Box from "@mui/material/Box";
import {
  Container,
  Grid,
  TextField,
  MenuItem,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useRef } from "react";
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import AuditScoreView from "../tables/auditScoreView";
import AuditScoreBackingView from "../tables/auditScoreBackupView";
// import DispositionsModal from "../Modals/dispositionsModal";
import {
  getQualityScoringReport,
  getAgentList,
  getQualityScoringReportForBackUp,
} from "../actions/salesLeadAction";
import { connect } from "react-redux";
// import { ExportToCsv } from "export-to-csv";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    centerIt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
      overflow: "scroll",
      position: "sticky",
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    rightContentHeader: {
      width: "30%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    tableIcon: {
      display: "center",
      justifyContent: "center",
    },
    modal: {
      position: "absolute",
      width: "20%",
      top: "40%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "70%",
      float: "left",
    },
    fontWeight: {
      fontWeight: "bold",
    },
  })
);
interface Props {
  getQualityScoringReport: any;
  qualityScoringReport: any;
  getQualityScoringReportForBackUp: any;
  get_quality_scoring_report_for_backup: any;
  getAgentList: any;
  agentsList: any;
  loading: any;
}

const AuditScore: React.FC<Props> = ({
  getQualityScoringReport,
  qualityScoringReport,
  getQualityScoringReportForBackUp,
  get_quality_scoring_report_for_backup,
  getAgentList,
  agentsList,
  loading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);

  const [leadId, setLeadId] = useState<any>("");
  const [fromDate, setFromDate] = useState<any>("");
  const [toDate, setToDate] = useState<any>("");
  const [agentLocation, setAgentLocation] = useState<any>("");
  const [agent, setAgent] = useState<any>("");
  const [agentType, setAgentType] = useState<any>("");
  const [exportData, setExportData] = useState<any>([]);
  const [openModal, setOpenModal] = useState<any>(false);
  const [dataForModal, setDataForModal] = useState<any>("");
  const [statusForAuditScore, setStatusForAuditScore] =
    useState<boolean>(false);
  const [statusForEditButton, setStatusForEditButton] =
    useState<boolean>(false);

  useEffect(() => {
    getAgentList();
    getQualityScoringReport();
    getQualityScoringReportForBackUp(`?backup=true`);
  }, []);

  const handleFilter = () => {
    getQualityScoringReport(
      `?lead=${leadId}&created_at__lt=${toDate}&created_at__gt=${fromDate}&agent_id=${agent}&location=${agentLocation}&agent_type=${agentType}`
    );

    getQualityScoringReportForBackUp(
      `?backup=true&lead=${leadId}&created_at__lt=${toDate}&created_at__gt=${fromDate}&agent_id=${agent}&location=${agentLocation}&agent_type=${agentType}`
    );
  };



  const options = {
    fieldSeparator: ",",
    filename: "Audit Score",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
  };

  useEffect(() => {
    const storeDataToExport: any = [];
    if (qualityScoringReport) {
      qualityScoringReport.results?.length > 0 &&
        qualityScoringReport.results?.map((list: any, index: any) => {
          list?.quality_details?.map((list2: any, index: any) => {
            storeDataToExport.push({
              LeadId: index === 1 ? list?.lead_details?.id : "",
              UCId: index === 1 ? list?.call_sid : "",
              Agent: index === 1 ? list?.agent_name : "",
              TL: index === 1 ? list?.tl : "",
              QualityExecutive: index === 1 ? list?.quality_username : "",
              CallDate:
                index === 1
                  ? moment(list?.created_at).format("DD-MM-YYYY")
                  : "",
              AuditDate:
                index === 1
                  ? moment(list?.audit_date).format("DD-MM-YYYY")
                  : "",
              CallDuration: list?.callduration,
              Recoding: list.uploaded_url,
              Language:
                index === 1
                  ? list?.language?.map((list2: any) => list2?.name_en)
                  : "",
              CallObservation: index === 1 ? list?.remark : "",
              SoftSkills: index === 1 ? list?.soft_skills.toFixed(2) : "",
              Paramter: list2?.parameter,
              SubParameter: list2?.subparameter,
              Score: list2?.score,
              Comment: list2?.comment,
              Remark: list2?.remark,
            });
          });
        });
    }
    setExportData(storeDataToExport);
  }, [qualityScoringReport]);

  //   const csvExporter = new ExportToCsv(options);

  const handleExportToExcel = () => {
    //do Nothing
    // if (exportData.length != 0) {
    //   csvExporter.generateCsv(exportData);
    // }
  };

  const handleOpenModal = () => {
    setStatusForEditButton(true);
    setOpenModal(true);
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.contentHeader}>
          <div className={classes.leftContentHeader}>
            <h2 style={{ marginLeft: "30px" }}>Audit Score</h2>
          </div>
        </div>
        {/* <DispositionsModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          dataForModal={dataForModal}
          setDataForModal={setDataForModal}
          statusForEditButton={statusForEditButton}
          setStatusForEditButton={setStatusForEditButton}
        /> */}
        <Box>
          <Container maxWidth={false}>
            <Grid
              container
              spacing={3}
              direction="row"
              style={{ margin: "0", alignItems: "left" }}
            >
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  name="lead-id"
                  className="input"
                  type="number"
                  label="Lead Id"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={leadId}
                  onChange={(e) => setLeadId(e.target.value)}
                />
              </Grid>
              {/* <Grid item xs={12} sm={4} md={2}>
                <Autocomplete
                  id="agentname"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setAgent(obj.id);
                    }
                  }}
                  options={agentsList.results || []}
                  freeSolo
                  blurOnSelect
                  aria-required
                  getOptionLabel={(option: any) =>
                    option.user && option.user.fullname
                  }
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getAgentList(`${newInputValue}`);
                    }, 1000);
                    if (newInputValue.length === 0) {
                      setAgent(0);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      name="agent-name"
                      label="Agent Name"
                    />
                  )}
                />
              </Grid> */}
              {/* <Grid item xs={12} sm={4} md={2}> */}
              {/* <TextField
                  name="agent-type"
                  className="input"
                  label="Agent Type"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={agentType}
                  onChange={(e) => setAgentType(e.target.value)}
                /> */}
              {/* <TextField
                  select
                  name="agent-type"
                  className="input"
                  label="Agent Type"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={agentType}
                  onChange={(e) => setAgentType(e.target.value)}
                >
                  <MenuItem value="CustomerExecutive">
                    Customer Executive
                  </MenuItem>
                  <MenuItem value="SupportExecutive">
                    Support Executive
                  </MenuItem>
                  <MenuItem value="VerificationExecutive">
                    Verification Executive
                  </MenuItem>
                  <MenuItem value="CustomerSupport">Customer Support</MenuItem>
                  <MenuItem value="Phlebos">Phlebos</MenuItem>
                </TextField>
              </Grid> */}
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  name="from-date"
                  type="date"
                  className="input"
                  label="Call From Date"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <TextField
                  name="to-date"
                  type="date"
                  className="input"
                  label="Call To Date"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </Grid>
              {/* <Grid item xs={12} sm={4} md={2}>
                <TextField
                  select
                  name="agent-locations"
                  className="input"
                  label="Agent Locations"
                  variant="outlined"
                  style={{ width: "100%" }}
                  value={agentLocation}
                  onChange={(e) => setAgentLocation(e.target.value)}
                >
                  <MenuItem value="Noida">Noida</MenuItem>
                  <MenuItem value="Gurugram">Gurugram</MenuItem>
                  <MenuItem value="Hyderabad">Hyderabad</MenuItem>
                  <MenuItem value="Mumbai">Mumbai</MenuItem>
                  <MenuItem value="Bengaluru">Bengaluru</MenuItem>
                </TextField>
              </Grid> */}
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="secondary"
                
                  fullWidth
                  onClick={handleFilter}
                >
                  Filter
                </Button>
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                
                  fullWidth
                  onClick={() => history.push("/dashboard/sl/auditScore")}
                >
                  Reset
                </Button>
              </Grid>
              {/* <Grid item xs={12} sm={4} md={2}>
                <Button
                  disabled={qualityScoringReport.length === 0}
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  style={{ padding: "15px 0", fontWeight: "bold" }}
                  onClick={handleExportToExcel}
                >
                  Export to Excel
                </Button>
              </Grid> */}
              <Grid item xs={12} sm={4} md={4}></Grid>
              {/* <Grid item xs={12} sm={4} md={2}>
                <Button
                  variant="contained"
                  color="primary"
                
                  fullWidth
                  onClick={handleOpenModal}
                  disabled={statusForAuditScore}
                >
                  Audit Score
                </Button>
              </Grid> */}
            </Grid>
            <AuditScoreView
              qualityScoringReport={qualityScoringReport}
              getQualityScoringReport={getQualityScoringReport}
              openModal={openModal}
              setOpenModal={setOpenModal}
              dataForModal={dataForModal}
              setDataForModal={setDataForModal}
              statusForAuditScore={statusForAuditScore}
              setStatusForAuditScore={setStatusForAuditScore}
              statusForEditButton={statusForEditButton}
              setStatusForEditButton={setStatusForEditButton}
              loading={loading}
            />

            <Grid item xs={12}>
              <Typography
                style={{ marginTop: "25px" }}
                variant="h5"
                component="h1"
                align="left"
                className={classes.fontWeight}
              >
                Backup Calls
              </Typography>
            </Grid>

            <AuditScoreBackingView
              get_quality_scoring_report_for_backup={
                get_quality_scoring_report_for_backup
              }
              getQualityScoringReportForBackUp={
                getQualityScoringReportForBackUp
              }
              openModal={openModal}
              setOpenModal={setOpenModal}
              dataForModal={dataForModal}
              setDataForModal={setDataForModal}
              statusForAuditScore={statusForAuditScore}
              setStatusForAuditScore={setStatusForAuditScore}
              statusForEditButton={statusForEditButton}
              setStatusForEditButton={setStatusForEditButton}
              loading={loading}
            />
          </Container>
        </Box>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  qualityScoringReport: state.SalesLeadReducer.qualityScoringReport,
  agentsList: state.SalesLeadReducer.agentsList,
  get_quality_scoring_report_for_backup:
    state.SalesLeadReducer.get_quality_scoring_report_for_backup,
  loading: state.SalesLeadReducer.loading,
});

export default connect(mapStateToProps, {
  getQualityScoringReport,
  getAgentList,
  getQualityScoringReportForBackUp,
})(AuditScore);
