import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  getPackage,
  getPinCode,
  getPackageTags,
  getPackageCategory,
} from "../actions/bookingActions";
import "./filter.sass";
import {
  Paper,
  Grid,
  Typography,
  Card,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormControl,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      width: "100%",
      padding: theme.spacing(1),
      display: "grid",
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    spacebtm: {
      marginBottom: 15,
    },
  })
);

interface Props {
  children: any;
  getPackage: any;
  packageList: any;
  getPinCode: any;
  pin_code: any;
  getPackageTags: any;
  packageTags: any;
  getPackageCategory: any;
  packageCategory: any;
  loading: Boolean;
}

const LeadsPage: React.FC<Props> = ({
  getPackage,
  packageList,
  getPinCode,
  pin_code,
  getPackageTags,
  packageTags,
  getPackageCategory,
  packageCategory,
  loading,
}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);
  const [pincode, setPinCode] = useState<String>("");
  const [pincodeInput, setPincodeInput] = useState<string>("");
  const [cityId, setCityId] = useState<any>("");
  const [inputValue, setInputValue] = useState<any>("");
  const [tags, setTags] = useState<any>([]);
  const [category, setCategory] = useState<any>([]);
  useEffect(() => {
    // getPackage();
    getPinCode();
    getPackageTags();
    getPackageCategory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [page, setPage] = useState(1);
  const handleTagsCategory = (value: any, type: any) => {
    const tagsArr = [...tags];
    const categoryArr = [...category];
    if (type === "tags") {
      if (value.target.checked === true) {
        tagsArr.push(value.target.name);
        setTags(tagsArr);
      } else if (value.target.checked === false) {
        tagsArr.length > 0 &&
          tagsArr.map((val: any, index: any) => {
            if (val === value.target.name) {
              tagsArr.splice(index, 1);
            }
          });

        setTags(tagsArr);
      }
    } else if (type === "category") {
      if (value.target.checked === true) {
        categoryArr.push(value.target.name);
        setCategory(categoryArr);
      } else if (value.target.checked === false) {
        categoryArr.length > 0 &&
        categoryArr.map((val: any, index: any) => {
            if (val === value.target.name) {
              categoryArr.splice(index, 1);
            }
          });
        setCategory(categoryArr);
      }
    }
  };
  console.log(tags, category);
  const handlePage = (event: any, value: any) => {
    if (cityId !== "" && inputValue !== "") {
      getPackage(
        `city_id=${cityId}&code=${inputValue}&page=${value}&tag=${tags}&category=${category}`
      );
    } else if (cityId !== "") {
      getPackage(
        `city_id=${cityId}&page=${value}&tag=${tags}&category=${category}`
      );
    } else {
      getPackage(`page=${value}&tag=${tags}&category=${category}`);
    }
    setPage(value);
  };
  const handlePinCode = (val: String) => {
    if (val) {
      setPinCode(val as string);
      const { cityid } = pin_code?.results?.find(
        (code: any) => code?.pincode === val
      );
      setCityId(cityid);
    }
  };

  useEffect(() => {
    getPackage(`city_id=${cityId}&tag=${tags}&category=${category}`);
  }, [cityId, tags, category]);

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <Grid container spacing={2} md={12}>
          <Grid item md={3} xs={12}>
            <Typography variant="h5" align="left">
              Test Packages
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} md={12}>
          <Grid item md={6} xs={3}>
            <Autocomplete
              id="pincode"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  handlePinCode(obj?.pincode as String);
                }
              }}
              options={pin_code?.results || []}
              
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) =>
                `${option?.pincode === undefined ? "" : option.pincode}`
              }
              inputValue={pincodeInput}
              onInputChange={(event, newInputValue) => {
                if(newInputValue.length<=6){
                  setPincodeInput(String(newInputValue.replace(/[^0-9]/g,"")).trimStart());
                }
                
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPinCode(newInputValue.split(",")[0]);
                  setPage(1);
                }, 1000);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Select Pincode"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={3}>
            <Autocomplete
              freeSolo
              id="test-name-filter"
              options={packageList.results}
              // onChange={(newValue) => {
              //   getPackage(`code=${newValue}`);
              // }}
              disableClearable
              inputValue={inputValue}
              getOptionLabel={(option: any) => option.name}
              onInputChange={(event, newInputValue) => {
                setInputValue(String(newInputValue.replace(/[^a-zA-Z ]/g, '')).trimStart());
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                
                  getPackage(
                    `city_id=${cityId}&code=${String(newInputValue.replace(/[^a-zA-Z ]/g, '')).trimStart()}&tag=${tags}&category=${category}`
                  );
                  setPage(1);
                }, 200);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  variant="outlined"
                  label="Search by Test name"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  InputProps={{ ...params.InputProps, type: "search" }}
                />
              )}
              disabled={pincode === ""}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend">Filter by Tags</FormLabel>
              <FormGroup row={true}>
                {packageTags &&
                  packageTags.results &&
                  packageTags.results.length > 0 &&
                  packageTags.results.map((val: any, index: any) => {
                    return (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            name={val?.tags}
                            onChange={(e) => handleTagsCategory(e, "tags")}
                          />
                        }
                        label={val?.tags}
                      />
                    );
                  })}
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" variant="standard">
              <FormLabel component="legend">Filter by Categories</FormLabel>
              <FormGroup row={true}>
                {packageCategory &&
                  packageCategory.results &&
                  packageCategory.results.length > 0 &&
                  packageCategory.results.map((val: any, index: any) => {
                    return (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            name={val?.name}
                            onChange={(e) =>
                              handleTagsCategory( e, "category")
                            }
                          />
                        }
                        label={val?.name}
                      />
                    );
                  })}
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item md={12}>
            <Divider></Divider>
          </Grid>
          <Grid container spacing={2} md={12} className={classes.spacebtm}>
            {packageList.results &&
              packageList.results.length > 0 &&
              packageList.results.map((data: any, index: any) => {
                return (
                  <Grid container item xs={12} md={4} xl={3}>
                    <Card
                      variant="outlined"
                      className={classes.card}
                      elevation={3}
                      component={Paper}
                    >
                      <Grid item md={12}>
                        <Typography variant="h6" align="center">
                          <b> {data.name}</b>
                        </Typography>
                      </Grid>
                      <Grid item md={12}>
                        <Divider></Divider>
                      </Grid>
                      <Grid item md={12}>
                        <div>
                          <Typography
                            variant="h6"
                            align="left"
                            style={{ padding: "10px" }}
                          >
                            Parameters:<span>{data.parameter}</span>
                          </Typography>
                          <Typography
                            variant="h6"
                            align="right"
                            style={{ padding: "10px" }}
                          >
                            TaT Time:
                            <span>
                              {data.package_city_prices
                                ? data.package_city_prices.tat_time
                                : data.tat_time}
                            </span>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item md={12}>
                        <p>{data.description}</p>
                      </Grid>

                      <Grid container item md={12}>
                        <Grid item container md={6}>
                          <Card
                            className={classes.card}
                            style={{ height: 100 }}
                            variant="outlined"
                          >
                            <Grid item md={12}>
                              <Typography variant="h6" align="center">
                                Package Price
                              </Typography>
                            </Grid>
                            <Grid item md={12}>
                              <Typography variant="h6" align="center">
                                <b>{data.package_price}</b>
                              </Typography>
                            </Grid>
                          </Card>
                        </Grid>
                        <Grid item container md={6}>
                          <Card
                            className={classes.card}
                            style={{ height: 100 }}
                            variant="outlined"
                          >
                            <Grid item md={12}>
                              <Typography variant="h6" align="center">
                                Offer Price
                              </Typography>
                            </Grid>
                            <Grid item md={12}>
                              <Typography variant="h6" align="center">
                                <b>
                                  {" "}
                                  {data.package_city_prices === null
                                    ? data.offer_price
                                    : data.package_city_prices.offer_price}
                                </b>
                              </Typography>
                            </Grid>
                          </Card>
                        </Grid>
                        <Grid item container md={12}>
                          <a href={data.web_link} target="_blank">
                            {data.web_link}
                          </a>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
          <Grid item md={12}>
            <Pagination
              count={Math.ceil(parseInt(packageList.count) / 50)}
              page={page}
              variant="outlined"
              color="primary"
              onChange={handlePage}
            />
          </Grid>
        </Grid>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  packageList: state.BookingReducer.packageList,
  pin_code: state.BookingReducer.pin_code,
  packageTags: state.BookingReducer.packageTags,
  packageCategory: state.BookingReducer.packageCategory,
  loading: state.BookingReducer.loading,
});

export default connect(mapStateToProps, {
  getPackage,
  getPinCode,
  getPackageTags,
  getPackageCategory,
})(LeadsPage);
