import React, { useState, useEffect } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Container,
    Grid,
    TextField,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import { Paper, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import Button from '@mui/material/Button';
import { useRef } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { MessageRounded } from '@material-ui/icons';
// import { getIncorrectNumberLead } from '../actions/salesLeadAction'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from "react-router";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
         content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        root: {
           display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
        },
    },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        listStyle: {
            marginLeft: "20px",
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
    })
);


interface Props {

}

const CallDashboard: React.FC<Props> = ({



}) => {

    const classes = useStyles()
    const history = useHistory()
    const bull = <span className={classes.bullet}>•</span>;

    return (
         <main className={classes.content}>
              <div className={classes.toolbar} />
        <div className={classes.root}>
               
      <Paper elevation={3} />
      <Paper />
      <Paper elevation={3} />
    </div>
    </main>
    )
}

const mapStateToProps = (state: any) => ({
})

export default connect(mapStateToProps, {
})(CallDashboard);

