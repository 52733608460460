import React, { useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CardActions from "@material-ui/core/CardActions";
import Button from '@mui/material/Button';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { updateLeadDetailsF_1, getCities, getAreaName, clickToCall, getUnmaskedNumber } from "../../actions/bookingActions";
import "./index.sass";
import { Grid, Paper } from "@material-ui/core";
import CallIcon from "@material-ui/icons/Call";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import CommentsModal2 from "../Comments/Comment2";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "100px",
      height: "60px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem"
    },
    selectedSlot: {
      width: "100px",
      height: "60px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, white, cyan)",
      cursor: "pointer",
      margin: "0.5rem"
    },
    chips: {
      display: 'flex',
    },
    typography: {
      padding: theme.spacing(2),
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px"
    },
  })
);
interface Props {
  updateLeadDetailsF_1: Function;
  id: any;
  cities: any;
  getCities: any,
  areaList: any,
  getAreaName: any,
  data: any;
  clickToCall: Function;
  unmaskedNumber: any;
  getUnmaskedNumber: any;
}

const Relevent: React.FC<Props> = ({
  updateLeadDetailsF_1,
  id,
  cities,
  getCities,
  areaList,
  getAreaName,
  data,
  clickToCall,
  unmaskedNumber,
  getUnmaskedNumber,
}) => {
  const classes = useStyles();
  const [patient_name, setPatientName] = React.useState<string>("");
  const [patient_age, setPatientAge] = React.useState<string>("");
  const [calling_gender, setCallingGender] = React.useState<string>("");
  const [priority, setpriority] = React.useState<string>(data.priority || "none");
  const [content_number, setContentNumber] = React.useState<string>("");
  const [whatsapp_contact, setWhatsappContact] = React.useState<string>("");
  const [address, setAddress] = React.useState<string>("");
  const [city, setCity] = React.useState<number>(0);
  const [cityName, setCityName] = React.useState<string>("");
  const [area, setArea] = React.useState<number>(0);
  const timer = useRef<any>(0)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [redCoin, setRedCoin] = React.useState<Number>()
  const [commentModalOpen, setCommentModalOpen] = React.useState<boolean>(false);
  const[leadId,setLeadId]=React.useState<number>(0);
  const [leadSource, setLeadSource] = React.useState(data?.lead_source || "");

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const popid = open ? 'simple-popover' : undefined;

  useEffect(() => {
    setPatientName(data.patient_name)
    setLeadId(data.id)
    setPatientAge(data.patient_age)
    setCallingGender(data.calling_gender)
    setContentNumber(data.call)
    setWhatsappContact(data.whatsapp_contact)
    setCity(data?.patient_city_id || 0)
    setCityName(data?.patient_city || "")
    setArea(data.patient_area)
    setAddress(data.address)
    setRedCoin(data.redcash_coins)
    setLeadSource(data.lead_source);
    // getCities()
  }, [data]);

  useEffect(() => {
    getAreaName(city)
  }, [city, getAreaName])

  const handleCallClick = (id: number, type: string) => {
    clickToCall(id, type, "?is_outbound=true");
  };

  const [mobileValidation, setMobileValidation] = React.useState({
    customer_phonenumber: true,
    customer_whatsapppnumber: true,
  })

  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (!Number(content_number) || parseInt(content_number && content_number[0]) < 6 || content_number.length !== 10) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_phonenumber: false,
        }));
      }
    }

    if (type === "whatsapp") {
      if (!Number(whatsapp_contact) || parseInt(whatsapp_contact && whatsapp_contact[0]) < 6 || whatsapp_contact.length !== 10) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_whatsapppnumber: false,
        }));
      }
    }
  };

  const submitForm = async () => {
    let body = {
      patient_name,
      patient_age,
      calling_gender,
      priority,
      content_number,
      whatsapp_contact,
      address,
      patient_city: city,
      patient_area: area,
    }
    updateLeadDetailsF_1(id, body)
  };
  const handleUnmaskedNumber = (e: any, id: any, source: any, type: any) => {
    setAnchorEl(e.currentTarget);
    getUnmaskedNumber(id, source, type)
  }

  return (
    <Paper className={classes.paper} elevation={5}>
      <Popover
        id={popid}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.typography}>{unmaskedNumber.number}</Typography>
      </Popover>
      <Grid container direction="row" justify="space-between" alignItems="flex-end" spacing={3} style={{ margin: "1rem auto" }}>
        <Grid item xs={12} md={4}>
          <p>Customer Name</p>
          <TextField
            name="customer_name"
            type="text"
            placeholder="Enter Name"
            value={patient_name || ""}
            className="input"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%", margin: "0" }}
            onChange={(e) => String(e.target.value).length <= 100 ? setPatientName(e.target.value.replace(/[^a-zA-Z ]/g, '') as string) : ""}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <p>Age</p>
          <TextField
            name="customer_age"
            type="number"
            placeholder="Enter Age"
            value={patient_age || ""}
            className="input"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%", margin: "0" }}
            onChange={(e) => (Number(e.target.value) < 0 || Number(e.target.value) > 110) ? "" : setPatientAge(e.target.value as any)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <p>Lead Source</p>
          <TextField
            name="lead_source"
            type="text"
            placeholder="Lead Source"
            value={leadSource || ""}
            className="input"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%", margin: "0" }}
            disabled
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <p>Gender</p>
          <Select
            name="customer_gender"
            variant="outlined"
            defaultValue="male"
            value={calling_gender || "none"}
            style={{ width: "100%", margin: "0" }}
            onChange={(e) => setCallingGender(e.target.value as any)}
            className="input"
          >
            <MenuItem value={"none"}>Select</MenuItem>
            <MenuItem value={"male"}>Male</MenuItem>
            <MenuItem value={"female"}>Female</MenuItem>
            <MenuItem value={"other"}>Other</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} md={4}>
          <p>Priority</p>
          <Select
            name="customer_gender"
            variant="outlined"
            defaultValue="male"
            value={priority || "none"}
            style={{ width: "100%", margin: "0" }}
            onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
              setpriority(e.target.value as string)
            }
            className="input"
          >
            <MenuItem value={"none"}>Select</MenuItem>
            <MenuItem value={"SuperCritical"}>SuperCritical</MenuItem>
            <MenuItem value={"Urgent"}>Urgent</MenuItem>
            <MenuItem value={"HopeFull"}>HopeFull</MenuItem>
            <MenuItem value={"Low"}>Low</MenuItem>
            <MenuItem value={"Other"}>Other</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} md={4}>
          <p>Mobile Number
            <CallIcon
              onClick={() => handleCallClick(data.id, "contact")}

            />
            {/* <VisibilityIcon
              onClick={(e: any) => handleUnmaskedNumber(e, data.id, "lead", "contact")}
              style={{ marginLeft: "2rem" }} /> */}
          </p>
          <TextField
            name="mobile_number"
            type="text"
            placeholder="Enter Number"
            // onInput={(e: any) => {
            //   e.target.value = Math.max(0, parseInt(e.target.value))
            //     .toString()
            //     .slice(0, 10);
            // }}
            value={content_number  ? `XXXXXX${content_number.slice(-4)}` : ""}
            className="input"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%", margin: "0" }}
            onChange={(e) => setContentNumber(e.target.value as any)}
            onBlur={() => verifyMobile("mobile")}
            onFocus={() => {
              setMobileValidation((prev: any) => ({
                ...prev,
                customer_phonenumber: true
              }))
            }}
            helperText={!mobileValidation.customer_phonenumber && "Incorrect Contact Number"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <p>Whatsapp Number
            <CallIcon
              onClick={() => handleCallClick(data.id, "whatsapp")}
            />
            {/* <VisibilityIcon
              onClick={(e: any) => handleUnmaskedNumber(e, data.id, "lead", "whatsapp")}
              style={{ marginLeft: "2rem" }} /> */}
          </p>
          <TextField
            name="whatsapp_number"
            type="text"
            placeholder="Enter Number"
            value={whatsapp_contact  ? `XXXXXX${whatsapp_contact.slice(-4)}` : ""}
            className="input"
            variant="outlined"
            // onInput={(e: any) => {
            //   e.target.value = Math.max(0, parseInt(e.target.value))
            //     .toString()
            //     .slice(0, 10);
            // }}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%", margin: "0" }}
            onChange={(e) => setWhatsappContact(e.target.value as any)}
            onBlur={() => verifyMobile("whatsapp")}
            onFocus={() => {
              setMobileValidation((prev: any) => ({
                ...prev,
                customer_whatsapppnumber: true
              }))
            }}
            helperText={!mobileValidation.customer_whatsapppnumber && "Incorrect Whatsapp Number"}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <p>City</p>
          <Autocomplete
            id="city"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setCity(obj?.id)
              }
            }}
            className="input"
            options={cities || []}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) => option?.name}
            inputValue={cityName}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current)
              setCityName(newInputValue)
              timer.current = setTimeout(() => {
                getCities(newInputValue)
              }, 1000)
              if (newInputValue?.length === 0) {
                setCity(0)
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="City"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <p>Area</p>
          <Select
            name="area"
            placeholder="Enter Area Name"
            value={area || "none"}
            className="input"
            variant="outlined"
            style={{ width: "100%", margin: "0" }}
            onChange={(e) => setArea(e.target.value as any)}
          >
            <MenuItem value={"none"} disabled>Select Area</MenuItem>
            {city !== 0 && areaList.results && areaList.results.map((item: any) => {
              return (
                <MenuItem value={item.id}>{item.area}</MenuItem>
              )
            })}
          </Select>
        </Grid>

        <Grid item xs={12} md={4}>
          <p>Address</p>
          <TextField
            name="address"
            type="text"
            placeholder="Enter Address"
            value={address || ""}
            className="input"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%", margin: "0" }}
            onChange={(e) => setAddress(e.target.value as any)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <p>Red Cash</p>
          <TextField
            name="address"
            type="number"
            placeholder="Red Cash"
            value={redCoin}
            className="input"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%", margin: "0" }}
          // onChange={(e) => setAddress(e.target.value as any)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
        <p>Dispose</p>
          <Button
            variant="contained"
            color="secondary"
            disabled={typeof data.id === "undefined"}
            onClick={() => setCommentModalOpen(true)}
            style={{ width: "100%", margin: "0" }}
          >
            Dispose
          </Button>
        </Grid>

      </Grid>

      <CardActions
        style={{ paddingLeft: "16px" }}
      >
        <Button
          style={{
            paddingTop: "0",
            paddingBottom: "0",
          }}
          variant="contained"
          color="primary"
          size="large"
          onClick={submitForm}
          disabled={
            !mobileValidation.customer_phonenumber ||
            !mobileValidation.customer_whatsapppnumber ||
            !cityName
          }
        >
          <b>Submit</b>
        </Button>
      </CardActions>
      <CommentsModal2
        open={commentModalOpen}
        getCities={getCities}
        cities={cities}
        setOpen={setCommentModalOpen}
        leadId={leadId}
      />
    </Paper>

  );
};

const mapStateToProps = (state: any) => ({
  cities: state.BookingReducer.cities,
  areaList: state.BookingReducer.areaList,
  unmaskedNumber: state.BookingReducer.unmaskedNumber,
});

export default connect(mapStateToProps, {
  updateLeadDetailsF_1,
  getCities,
  getAreaName,
  clickToCall,
  getUnmaskedNumber
})(
  Relevent
);
