import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import { Grid, TextField, Select, MenuItem } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
  getWhatsappComments,
  searchCall,
} from "../../CustomerExecutive-Panel/actions/bookingActions";
import { TableFooter, TablePagination } from "@material-ui/core";
import Loader from "../components/Loader2";
import { generateWhatsappMessageUrl } from "../../helpers/generateUrl";
import { ExitToAppRounded, MessageRounded } from "@material-ui/icons";
import ReplyModal from "../components/Comments/replyModal";

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    tableContainer: {
      marginTop: "1rem",
      position: "sticky",
      top: "200px",
    },
  })
);

interface Props {
  loadingWhatsapp: boolean;
  whatsappComments: any;
  getWhatsappComments: any;
  getAgentList: any;
  agentsList: any;
}

const LeadsTable: React.FC<Props> = ({
  whatsappComments,
  getWhatsappComments,
  getAgentList,
  agentsList,
  loadingWhatsapp,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [id, setId] = useState<string>("");
  const [createdAt, setCreatedAt] = useState<string>("");
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [type, setType] = useState("none");

  const [open, setOpen] = useState<boolean>(false);
  const [leadId, setLeadId] = useState<number>(0);

  const history = useHistory();

  useEffect(() => {
    getWhatsappComments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const filterLeads = () => {
    const body: any = {
      start_date,
      lead: id,
      created_date: createdAt,
      end_date,
      type,
    };
    const url = generateWhatsappMessageUrl(body).substring(2);

    getWhatsappComments(`${url}`);
  };

  const viewLead = (id: any) => {
    let body = {
      search_by: "call_id",
      value: `${id}`,
    };
    dispatch(searchCall(body));
    history.push("/dashboard/ce/lead/search");
  };

  const handlereply = (id: any) => {
    setOpen(true);
    setLeadId(id);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = whatsappComments?.links?.next;
      getWhatsappComments(url.substring(url.indexOf("?") + 1));
    } else if (newPage < page) {
      let url = whatsappComments?.links?.previous;
      getWhatsappComments(
        url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?") + 1)
      );
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <h2>Whatsapp Messages</h2>
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "left" }}
        >
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="leadID"
              type="number"
              value={id}
              className="input"
              variant="outlined"
              placeholder="Lead Id"
              onChange={(e) => setId(e.target.value as string)}
              style={{ width: "100%" }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="Created At"
              type="date"
              label="Created Date"
              value={createdAt}
              variant="outlined"
              disabled={start_date !== "" || end_date !== ""}
              onChange={(e) => setCreatedAt(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="Start At"
              type="date"
              label="Start Date"
              value={start_date}
              variant="outlined"
              onChange={(e) => setStart_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="End At"
              type="date"
              label="End Date"
              value={end_date}
              variant="outlined"
              onChange={(e) => setEnd_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="status"
              variant="outlined"
              value={type}
              style={{ width: "100%" ,height:"40px"}}
              onChange={(e) => setType(e.target.value as string)}
            >
              <MenuItem value={"none"} disabled>
                Type
              </MenuItem>
              <MenuItem value={"1"}>Incoming</MenuItem>
              <MenuItem value={"2"}>Outgoing</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={filterLeads}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => history.push("/dashboard/ce/whatsapp")}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <div
          className="data-table"
          style={{ width: "100%", marginTop: "2rem" }}
        >
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "540px" }}
          >
            {loadingWhatsapp ? (
              <Loader />
            ) : (
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">Lead ID</StyledTableCell>
                    <StyledTableCell align="center">Created At</StyledTableCell>
                    <StyledTableCell align="center">Message</StyledTableCell>
                    <StyledTableCell align="center">Panel User</StyledTableCell>
                    <StyledTableCell align="center">Mobile</StyledTableCell>
                    <StyledTableCell align="center">Reply</StyledTableCell>
                    <StyledTableCell align="center">Status</StyledTableCell>
                    <StyledTableCell align="center">Call Type</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {whatsappComments && whatsappComments.results ? (
                    whatsappComments.results.map((item: any) => {
                      return (
                        <StyledTableRow key={item.id}>
                          <StyledTableCell align="center">
                            {item.lead ? item.lead : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {new Date(item.created_at).toLocaleString()}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.message ? item.message : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.panel_user ? item.panel_user : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.mobile_no ? item.mobile_no : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <MessageRounded
                              onClick={() => handlereply(item.lead)}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.status ? item.status : "Na"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.msgtype === "1"
                              ? "Incomming Call"
                              : item.msgtype === "2"
                              ? "Outgoing Call"
                              : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <ExitToAppRounded
                              onClick={() => viewLead(item.lead)}
                            />
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })
                  ) : (
                    <p style={{ textAlign: "center" }}>No data found</p>
                  )}
                </TableBody>
                {whatsappComments && whatsappComments.count > 0 && (
                  <TableFooter>
                    <TablePagination
                      colSpan={6}
                      count={whatsappComments?.count || 0}
                      rowsPerPageOptions={[]}
                      rowsPerPage={whatsappComments?.page_size || 20}
                      // onChangeRowsPerPage={handleChangeRowsPerPage}
                      page={page}
                      SelectProps={{
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                    />
                  </TableFooter>
                )}
              </Table>
            )}
          </TableContainer>
        </div>
        {open && (
          <ReplyModal
            open={open}
            setOpen={setOpen}
            setLeadId={setLeadId}
            leadId={leadId}
            callApi={"whatsapp"}
          />
        )}
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  whatsappComments: state.BookingReducer.whatsappComments,
  loadingWhatsapp: state.BookingReducer.loadingWhatsapp,
});

export default connect(mapStateToProps, { getWhatsappComments, searchCall })(
  LeadsTable
);
