import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Container, Grid, TextField } from "@material-ui/core";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MenuItem, Select } from "@material-ui/core";
import { generateBookingFilterUrl } from "../../helpers/generateUrl";
import { connect } from "react-redux";
import {
  getAgentList,
  getLeadSource,
  getPartners,
  getAllBookings,
  getCities,
  getPhlebos,
  getVerifiedbyList,
  getAllNewBookings,
  getUserDetails,
} from "../actions/bookingActions";
import { getAllLeads, leadTransferByComma } from "../actions/bookingActions";
import "./filter.sass";
import { useHistory } from "react-router";
import BookingsTable from "../tables/bookingsTable";
import Loader from "../components/loader";
import { getPaymentInfo } from "../actions/bookingActions";
import PaymentModal from "../components/Comments/paymentforModal";
import { BOOKING_STATUS } from "../../booking_status";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import HelpIcon from "@material-ui/icons/Help";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(2),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:2,
      justifyContent: "space-between",
    },
  })
);

interface Props {
  children: any;
  agentsList: any;
  bookingList: any;
  partnerList: any;
  getAgentList: any;
  getPartners: any;
  getAllBookings: any;
  leadTransferByComma: any;
  agentBYUserGroup: any;
  getCities: any;
  cities: any;
  getPhlebos: any;
  getAllLeads: any;
  phleboList: any;
  getLeadSource: any;
  lead_source: any;
  loading: Boolean;
  bookingListLoader: boolean;
  getPaymentInfo: any;
  paymentInformation: any;
  getVerifiedbyList: any;
  verifiedbyList: any;
  getAllNewBookings: any;
  userDetails: any
  getUserDetails: any;
  newbookingList: any;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
  agentsList,
  partnerList,
  getAgentList,
  getPartners,
  getAllBookings,
  leadTransferByComma,
  agentBYUserGroup,
  getAllLeads,
  bookingList,
  getCities,
  cities,
  getPhlebos,
  phleboList,
  getLeadSource,
  lead_source,
  getPaymentInfo,
  paymentInformation,
  loading,
  bookingListLoader,
  getVerifiedbyList,
  verifiedbyList,
  getAllNewBookings,
  userDetails,
  getUserDetails,
  newbookingList,
}) => {
  const classes = useStyles();

  const history = useHistory();

  const [openModal, setopenModal] = useState<boolean>(false);

  const [status, setStatus] = useState<any>([]);
  const [source, setSource] = useState<any>([]);
  const [partner, setPartner] = useState<any>([]);
  const [agent, setAgent] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [VerifiedbyList, setVerifiedbyList] = useState<any>([]);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [booking_date, setBooking_date] = useState("");
  const [collection_date, setCollection_date] = useState("");
  const [verificationStatus, setVerificationStatus] = useState<any>([]);
  const [pickup_status, setPickup_status] = useState<any>([]);
  const [name, setName] = useState("");
  const [phlebo, setPhlebo] = useState<any>([]);
  const [phone, setPhone] = useState<any>("");
  const [totalCount, setTotalCount] = useState<any>("");
  const [bookingId, setBookingid] = React.useState<any>("");
  const [userGroup, setUserGroup] = useState("none");
  const [page, setPage] = useState(0);
  const [sample_registered, setSampleRegisterd] = useState<any>("none");
  const [report_status, setReportStatus] = useState<any>("none");
  const [selectedLeads, setSelectedLeads] = useState<any>([]);
  const [panel_user, setPanelUser] = React.useState<number | null>(null);
  const [paymentId, setPaymentId] = useState<any>(0);
  const [openPaymentModal, setOpenPaymentModal] = useState<any>(false);
  const [defaultStatus, setDefaultStatus] = useState<any>([]);
  const [leadId, setLeadId] = React.useState<any>("");
  const [no_call, setNoCall] = React.useState<any>("");
  const [open, setOpen] = React.useState(false);
  const [locationVerified, setLocationVerified] = useState<any>("none");
  const [locationMessageStatus, setLocationMessageStatus] = useState<any>([]);
  const timer = useRef<any>(0);

  //transition modal start
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //transition modal end

  useEffect(() => {
    if (history.action === "POP") {
      let ceBookingFilter: any;
      ceBookingFilter = localStorage.getItem("CEBookingFilter");
      if (ceBookingFilter) {
        ceBookingFilter = JSON.parse(ceBookingFilter);

        const statusD = ceBookingFilter?.status?.split(",");
        let statusTemp = new Array();
        BOOKING_STATUS.map((item: any) => {
          statusD.map((itm: any) => {
            if (item.value == itm) {
              statusTemp.push(item);
            }
          });
        });
        setDefaultStatus(statusTemp);
        ceBookingFilter?.locationMessageStatus &&
        setLocationMessageStatus(ceBookingFilter?.locationMessageStatus?.split(","));
        ceBookingFilter?.status &&
          setStatus(ceBookingFilter?.status?.split(","));
        ceBookingFilter?.source &&
          setSource(ceBookingFilter?.source?.split(","));
        ceBookingFilter?.partner &&
          setPartner(ceBookingFilter?.partner?.split(","));
        ceBookingFilter?.city && setCity(ceBookingFilter?.city?.split(","));
        ceBookingFilter?.start_date &&
          setStart_date(ceBookingFilter?.start_date);
        setCollection_date(ceBookingFilter?.collection_date);
        ceBookingFilter?.verificationStatus &&
          setVerificationStatus(
            ceBookingFilter?.verificationStatus?.split(",")
          );
        ceBookingFilter?.pickup_status &&
          setPickup_status(ceBookingFilter?.pickup_status?.split(","));

        setName(ceBookingFilter?.name);
        setPhlebo(ceBookingFilter?.phlebo);

        setPhone(ceBookingFilter?.phone);
        setSampleRegisterd(ceBookingFilter?.sample_registered);
        setUserGroup(ceBookingFilter?.userGroup);
        setReportStatus(ceBookingFilter?.report_status);
        setBookingid(ceBookingFilter?.bookingId);
        setLeadId(ceBookingFilter?.leadId);
        const url = generateBookingFilterUrl(ceBookingFilter).substring(2);
        const pageNo = page ? page : 1;
        // getAllBookings(`${url}&page=${pageNo}&optimize=true`);
        {
          userDetails?.profile?.pilot_user ? (
            getAllNewBookings(`${url}&page=${pageNo}`)
          ) : getAllBookings(`${url}&page=${pageNo}&optimize=true`);
        }

      }
    } else {
      let url = ``;
      // getAgentList()
      // getAgentList("", "CustomerExecutive");
      // getPartners()
      // getAllBookings(`optimize=true`);
      {
        userDetails?.profile?.pilot_user ? (
          getAllNewBookings("")
        ) :  getAllBookings(`optimize=true`);
      }
      // getCities()
      // getPhlebos()
      getLeadSource();
      localStorage.removeItem("CEBookingFilter");
      setDefaultStatus([]);
      // getVerifiedbyList("?usergroup=VerificationExecutive")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDefaultStatus]);
  const onKeyDown = (e: any) => {
    if (e.key === "Enter") {
      filterBookings(e);
    }
  };

  const filterBookings = (e: any) => {
    const body: any = {
      status: status ? status.join(",") : "",
      verificationStatus: verificationStatus
        ? verificationStatus.join(",")
        : "",
      source: source ? source.join(",") : "",
      agent: agent ? agent.join(",") : "",
      city: city ? city.join(",") : "",
      start_date,
      end_date,
      booking_date,
      bookingId,
      collection_date,
      verified_by: VerifiedbyList ? VerifiedbyList : "",
      pickup_status: pickup_status ? pickup_status.join(",") : "",
      name,
      phlebo: phlebo ? phlebo.join(",") : "",
      partner: partner ? partner.join(",") : "",
      location_event_status: locationMessageStatus ? locationMessageStatus.join(",") : "",
      user_group: userGroup,
      phone,
      sample_registered,
      report_status,
      lead: leadId,
      no_call: no_call,
    };
    let url = generateBookingFilterUrl(body).substring(2);
    localStorage.setItem("CEBookingFilter", JSON.stringify(body));
    if (locationVerified === "a") {
      if (url === "") url = "address_verified=true&distance_above_deviation=true"
      else url = url + "&address_verified=true&distance_above_deviation=true"
    }
    if (locationVerified === "b") {
      if (url === "") url = "address_verified=false&distance_above_deviation=true"
      else url = url + "&address_verified=false&distance_above_deviation=true"
    }
    if (locationVerified === "c") {
      if (url === "") url = "address_verified=true&distance_above_deviation=false"
      else url = url + "&address_verified=true&distance_above_deviation=false"
    }
    if (locationVerified === "d") {
      if (url === "") url = "location_not_shared=true"
      else url = url + "&location_not_shared=true"
    }
    if (locationVerified === "e") {
      if (url === "") url = "customer_out_of_zone=true"
      else url = url + "&customer_out_of_zone=true"
    }
    // getAllBookings(url ? `${url}&page=1&optimize=true` : "page=1&optimize=true");
    {
      userDetails?.profile?.pilot_user ? (
        getAllNewBookings(url ? `${url}&page=1` : "page=1")
        ) :   getAllBookings(url ? `${url}&page=1&optimize=true` : "page=1&optimize=true")
    }
    setPage(0);
  };
  const transferLead = async () => {
    let body = {
      lead_ids: selectedLeads.join(", "),
      panel_user,
    };
    await leadTransferByComma(body);
    getAllLeads("");
    setSelectedLeads([]);
    getAllLeads("");
    history.push("/dashboard/cl/booking_table");
  };
  useEffect(() => {
    if (paymentId != 0) {
      getPaymentInfo(`?booking=${paymentId}`);
    }
  }, [paymentId]);
  document.querySelectorAll("#noTyping").forEach(el=>{
    el.addEventListener("keydown", function(e){
      e.preventDefault()
    })
  })

//   useEffect(() => {
//     getUserDetails("")

// }, [])
  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "right" }}
        >
          <Grid item xs={12} sm={8}>
            <h4 style={{ fontWeight: "bold" }}>BOOKING DASHBOARD <HelpIcon style={{ color: "#9c27b0" }} onClick={handleClickOpen} /></h4>
          </Grid>
          <Grid item xs={12} sm={4}>
            <h5 style={{ fontWeight: "bold" }}>BOOKING COUNT : {totalCount}</h5>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="lead"
              type="number"
              label="Lead ID"
              value={leadId}
              onKeyDown={onKeyDown}
              variant="outlined"
              onChange={(e) => setLeadId(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="no_call"
              type="number"
              label="Call Count"
              value={no_call}
              onKeyDown={onKeyDown}
              variant="outlined"
              onChange={(e) => setNoCall(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="booking"
              type="number"
              label="Booking Id"
              value={bookingId}
              onKeyDown={onKeyDown}
              variant="outlined"
              onChange={(e) => setBookingid(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="name"
              type="text"
              label="Name"
              value={name}
              variant="outlined"
              onKeyDown={onKeyDown}
              onChange={(e) => setName(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="phone"
              type="number"
              label="Phone"
              value={phone}
              variant="outlined"
              onKeyDown={onKeyDown}
              onChange={(e) => setPhone(e.target.value)}
              onInput={(e: any) => {
                e.target.value = e.target.value
                  ? Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10)
                  : "";
              }}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setCity(sourceIds);
              }}
              options={cities || []}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              onKeyDown={onKeyDown}
              getOptionLabel={(option: any) => option.name}
              getOptionDisabled={(option: any) => {
                return city.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCities(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setCity([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="City"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="status"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setStatus(sourceIds);
              }}
              options={BOOKING_STATUS || []}
              blurOnSelect
              aria-required
              onKeyDown={onKeyDown}
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return status.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Booking Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
              defaultValue={defaultStatus}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="partner"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setPartner(sourceIds);
              }}
              freeSolo
              blurOnSelect
              limitTags={1}
              multiple
              options={partnerList?.results || []}
              onKeyDown={onKeyDown}
              getOptionDisabled={(option: any) => {
                return partner.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) => option.user && option.user.name}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPartners(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPartner([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Partner"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setSource(sourceIds);
              }}
              freeSolo
              multiple
              id="checkboxes-tags-demo"
              options={lead_source || []}
              disableCloseOnSelect
              limitTags={1}
              disableClearable
              onKeyDown={onKeyDown}
              getOptionLabel={(option: any) => option.name}
              getOptionDisabled={(option: any) => {
                return source.includes(option.id);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Source"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="verifiedby"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setVerifiedbyList(sourceIds);
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              options={verifiedbyList?.results || []}
              getOptionDisabled={(option: any) => {
                return VerifiedbyList.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                option.user && option.user.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getVerifiedbyList(
                    `?code=${newInputValue}&usergroup=VerificationExecutive`
                  );
                }, 1000);
                if (newInputValue.length === 0) {
                  setVerifiedbyList([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Verified by"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="agent"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setAgent(sourceIds);
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              options={agentsList?.results || []}
              getOptionDisabled={(option: any) => {
                return agent.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                option.user && option.user.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getAgentList(`?code=${newInputValue.replace(" ", "")}`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPartner([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Agent"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="phlebo"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setPhlebo(sourceIds);
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              options={
                phleboList.results
                  ? [
                    ...phleboList.results,
                    { id: 0, user: { username: "NoPhlebo" } },
                  ]
                  : []
              }
              getOptionDisabled={(option: any) => {
                return phlebo.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onKeyDown={onKeyDown}
              getOptionLabel={(option: any) =>
                option.user && option.user.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPhlebos(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPhlebo([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Phlebo Name"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setVerificationStatus(sourceIds);
              }}
              options={[
                {
                  text: "Pending",
                  value: "pending",
                },
                {
                  text: "Confirmed",
                  value: "confirmed",
                },
                {
                  text: "Cancelled",
                  value: "cancelled",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              onKeyDown={onKeyDown}
              multiple
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return verificationStatus.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Verification Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setPickup_status(sourceIds);
              }}
              options={[
                {
                  text: "Pending",
                  value: "pending",
                },
                {
                  text: "Confirmed",
                  value: "confirmed",
                },
                {
                  text: "Cancelled",
                  value: "cancelled",
                },
                {
                  text: "Hold",
                  value: "hold",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.text}
              onKeyDown={onKeyDown}
              getOptionDisabled={(option: any) => {
                return pickup_status.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Pickup Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="usergroup"
              variant="outlined"
              value={userGroup}
              style={{ width: "100%", height: "40px" }}
              onChange={(e) => setUserGroup(e.target.value as string)}
            >
              <MenuItem disabled value={"none"}>
                User Type
              </MenuItem>
              <MenuItem value={"SupportExecutive"}>SupportExecutive</MenuItem>
              <MenuItem value={"SampleAccessioning"}>
                SampleAccessioning
              </MenuItem>
              <MenuItem value={"CustomerExecutive"}>CustomerExecutive</MenuItem>
              <MenuItem value={"VerificationExecutive"}>
                VerificationExecutive
              </MenuItem>
              <MenuItem value={"PhleboAdmin"}>PhleboAdmin</MenuItem>
              <MenuItem value={"Phlebos"}>Phlebos</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="start_date"
              type="date"
              label="Start Date"
              value={
                booking_date === "" || collection_date === "" ? start_date : ""
              }
              variant="outlined"
              disabled={booking_date !== "" || collection_date !== ""}
              onChange={(e) => setStart_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="end_date"
              type="date"
              value={
                booking_date === "" || collection_date === "" ? end_date : ""
              }
              className="input"
              label="End Date"
              variant="outlined"
              disabled={booking_date !== "" || collection_date !== ""}
              onChange={(e) => setEnd_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="booking_date"
              type="date"
              value={start_date === "" || end_date === "" ? booking_date : ""}
              className="input"
              label="Booking Date"
              variant="outlined"
              disabled={start_date !== "" || end_date !== ""}
              onChange={(e) => setBooking_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="collection_date"
              type="date"
              value={
                start_date === "" || end_date === "" ? collection_date : ""
              }
              className="input"
              label="Collection Date"
              variant="outlined"
              disabled={start_date !== "" || end_date !== ""}
              onChange={(e) => setCollection_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="sampleregisterd"
              variant="outlined"
              value={sample_registered}
              style={{ width: "100%", height: "40px" }}
              onChange={(e) => setSampleRegisterd(e.target.value as String)}
            >
              <MenuItem disabled value={"none"}>
                Sample Type
              </MenuItem>
              <MenuItem value={"true"}>Registerd</MenuItem>
              <MenuItem value={"false"}>Not Registerd</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="sampleregisterd"
              variant="outlined"
              value={report_status}
              style={{ width: "100%", height: "40px" }}
              onChange={(e) => setReportStatus(e.target.value as String)}
            >
              <MenuItem disabled value={"none"}>
                Report Status
              </MenuItem>
              <MenuItem value={"na"}>None</MenuItem>
              <MenuItem value={"pending"}>Pending</MenuItem>
              <MenuItem value={"partial"}>Partial</MenuItem>
              <MenuItem value={"consolidate"}>Consolidate</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
                <Autocomplete
                  id="location_verified"
                  onChange={(event, newValue) => {
                    let sourceIds: any = [];
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setLocationVerified(obj.value);
                    }

                  }}
                  options={[
                    {
                      text: "More than 3 Km - Verified",
                      value: "a",
                    },
                    {
                      text: "More than 3 Km - Not Verified",
                      value: "b",
                    },
                    {
                      text: "Less than 3 Km - Verified",
                      value: "c",
                    },
                    {
                      text: "Location not shared",
                      value: "d",
                    },
                    {
                      text: "Non-Serviceable - Not Verified",
                      value: "e",
                    },
                  ]}
                  freeSolo
                  blurOnSelect
                  aria-required
                  getOptionLabel={(option: any) => option.text}
                  disableClearable
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      label="Location/Verified"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
              <Autocomplete
                id="noTyping"
                onChange={(event, newValue) => {
                  let sourceIds: any = [];
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    sourceIds = obj.map((item: any) => item.value);
                  }
                  setLocationMessageStatus(sourceIds);
                }}
                options={[
                  {
                    text: "Triggred",
                    value: "triggred",
                  },
                  {
                    text: "Sent",
                    value: "sent",
                  },
                  {
                    text: "Delivered",
                    value: "delivered",
                  },
                  {
                    text: "Read",
                    value: "read",
                  },
                ]}
                freeSolo
                blurOnSelect
                aria-required
                limitTags={1}
                multiple
                getOptionLabel={(option: any) => option.text}
                onKeyDown={onKeyDown}

                disableClearable
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Location Message Status"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                )}
              />
            </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              // style={{ height: "54px" }}
              fullWidth
              onClick={filterBookings}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              // style={{ height: "54px" }}
              fullWidth
              onClick={() => history.push("/dashboard/cl/booking_table")}
            >
              Reset
            </Button>
          </Grid>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Note:"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                <div style={{ margin: "1rem" }}>
                  <div style={{ alignItems: "left", display: "inline-block", margin: "1rem" }}>
                    <small>
                      <ul style={{ paddingLeft: "15px" }}>
                        <li>You can filter by booking date or start and end date.</li>
                        <li>You can filter leads when bookings are available.</li>
                        <li>T: Total Additional Booking count</li>
                        <li>P: Pending count for additional member</li>
                        <li>C: Confirmed count for additional member</li>
                      </ul>
                    </small>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>

          </Dialog>
        </Grid>
        <br />
        {selectedLeads.length > 0 && (
          <React.Fragment>
            <h2 style={{ textAlign: "center", marginTop: "2rem" }}>
              Transfer To:
            </h2>
            <Grid
              container
              spacing={2}
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  id="agentname"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setPanelUser(obj.id);
                    }
                  }}
                  options={agentBYUserGroup?.results || []}
                  freeSolo
                  blurOnSelect
                  aria-required
                  getOptionLabel={(option: any) =>
                    option.user && option.user.username
                  }
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getAgentList(
                        `?code=${newInputValue.replace(" ", "")}`,
                        "CustomerExecutive"
                      );
                    }, 1000);
                    if (newInputValue.length === 0) {
                      setPanelUser(0);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      label="Agent username"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="contained"
                  color="success"
                  // style={{ height: "54px" }}
                  fullWidth
                  onClick={transferLead}
                  disabled={
                    panel_user === null ||
                    panel_user === 0

                  }
                >
                  Transfer
                </Button>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        <PaymentModal
          openModalP={openPaymentModal}
          setOpenModalP={setOpenPaymentModal}
          paymentInformation={paymentInformation}
        />

        {loading ? (
          <Loader />
        ) : (
          bookingList.results &&
          bookingList.results.length > 0 && (
            <Box>
              <Container maxWidth={false}>
                <Grid container spacing={2}>
                  <BookingsTable
                      setopenModal={setopenModal}
                      openModal={openModal}
                      page={page}
                      setPage={setPage}
                      loading={loading}
                      getBookings={getAllBookings}
                      bookingList={bookingList}
                      getAgentList={getAgentList}
                      agentBYUserGroup={agentBYUserGroup}
                      leadTransferByComma={leadTransferByComma}
                      getAllLeads={getAllLeads}
                      selectedLeads={selectedLeads}
                      setSelectedLeads={setSelectedLeads}
                      panel_user={panel_user}
                      setPanelUser={setPanelUser}
                      paymentId={paymentId}
                      setPaymentId={setPaymentId}
                      openPaymentModal={openPaymentModal}
                      setOpenPaymentModal={setOpenPaymentModal}
                      setTotalCount={setTotalCount}
                      bookingListLoader={bookingListLoader}
                      getAllNewBookings={getAllNewBookings}
                      newbookingList={newbookingList} 
                      userDetails={userDetails}     
                                   />
                </Grid>
              </Container>
            </Box>
          )
        )}
        {bookingList.results && bookingList.results.length === 0 && (
          <h4 style={{ textAlign: "center" }}>No Data Found</h4>
        )}
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  agentsList: state.CustomerLeadReducer.agentsList,
  partnerList: state.CustomerLeadReducer.partnerList,
  bookingList: state.CustomerLeadReducer.bookingList,
  cities: state.CustomerLeadReducer.cities,
  phleboList: state.CustomerLeadReducer.phleboList,
  loading: state.CustomerLeadReducer.loading,
  bookingListLoader: state.CustomerLeadReducer.bookingListLoader,
  lead_source: state.CustomerLeadReducer.lead_source,
  paymentInformation: state.CustomerLeadReducer.paymentInformation,
  agentBYUserGroup: state.CustomerLeadReducer.agentBYUserGroup,
  verifiedbyList: state.CustomerLeadReducer.verifiedbyList,
  userDetails: state.CustomerLeadReducer.userDetails,
  newbookingList: state.CustomerLeadReducer.newbookingList,
});

export default connect(mapStateToProps, {
  getAgentList,
  getPartners,
  getCities,
  getPhlebos,
  getLeadSource,
  getAllLeads,
  leadTransferByComma,
  getAllBookings,
  getPaymentInfo,
  getVerifiedbyList,
  getAllNewBookings,
  getUserDetails,
})(CustomerLeadFilterPage);
