import React, { useState, useEffect } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Grid, TextField, withStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import { useRef } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../Components/loader';
import moment from 'moment';
import { MessageRounded } from '@material-ui/icons';
import { getCurrentDayBooking, getCities } from '../actions/salesLeadAdminAction'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from "react-router";
import { generateCurrentDayBookingFilterUrl } from "../../helpers/generateUrl";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,fontWeight:600
        },

    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

interface Props {
    getCurrentDayBooking: any;
    currentBooking: any;
    page: any;
    getCities: any
    openModal: any,
    cities: any
    setopenModal: any,
    loading: Boolean;
}

const BookingsTable: React.FC<Props> = ({
    getCurrentDayBooking,
    currentBooking,
    getCities,
    cities,
    openModal,
    setopenModal,
    loading

}) => {
    const classes = useStyles()
    useEffect(() => {
        getCurrentDayBooking()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const [page, setPage] = React.useState(0);
    const [city, setCity] = useState<any>([])
    const timer = useRef<any>(0)
    const [collection_date, setCollection_date] = useState("")
    const [booking_date, setBooking_date] = useState("")
    const history = useHistory()

    // const [bookingId, setBookingId] = useState<number>(0);

    // const handleClick = (id: number) => {
    //     setopenModal(true);
    //     setBookingId(id);
    // };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = currentBooking.links && currentBooking.links.next.split("?")[1];
            getCurrentDayBooking(url);
        } else if (newPage < page) {
            let url = currentBooking.links && currentBooking.links.previous.split("?")[1];
            getCurrentDayBooking(url);
        }
        setPage(newPage as number);
    };
    const filterBookings = () => {
        const body: any = {
            city: city.join(","),
            booking_date,
            collection_date,
        }
        const url = generateCurrentDayBookingFilterUrl(body).substring(2)
        getCurrentDayBooking(`${url}`)
        setPage(0)
    }

    return (
        <div style={{ width: "100%" }}>
            <div className={classes.toolbar} />
            <h2>Current Day Booking</h2>
            <Grid container spacing={1} direction="row" style={{ margin: "0" }}>
                <Grid item xs={12} sm={4} md={2}>
                    <Autocomplete
                        id="city"
                        onChange={(event, newValue) => {
                            let sourceIds: any = []
                            if (newValue) {
                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                sourceIds = obj.map((item: any) => item.id)
                            }
                            setCity(sourceIds)
                        }}
                        options={cities}
                        freeSolo
                        blurOnSelect
                        aria-required
                        limitTags={1}
                        multiple
                        getOptionLabel={(option: any) => option.name}
                        getOptionDisabled={(option: any) => {
                            return city.includes(option.id)
                        }}
                        disableClearable
                        disableCloseOnSelect
                        onInputChange={(event, newInputValue) => {
                            clearTimeout(timer.current)
                            timer.current = setTimeout(() => {
                                getCities(newInputValue)
                            }, 1000)
                            if (newInputValue.length === 0) {
                                setCity([])
                            }
                        }}
                        renderInput={(params) => (
                            <TextField className="input"
                                {...params}
                                placeholder="City"
                                variant="outlined"
                                style={{ width: "100%", margin: "0", padding: "0" }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        name="collection_date"
                        type="date"
                        value={collection_date}
                        className="input"
                        label="Collection Date *"
                        variant="outlined"
                        onChange={(e) => setCollection_date(e.target.value as string)}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        name="booking_date"
                        type="date"
                        value={booking_date}
                        className="input"
                        label="Booking Date"
                        variant="outlined"
                        onChange={(e) => setBooking_date(e.target.value as string)}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <Button
                        variant="contained"
                        color="secondary"

                        fullWidth
                        onClick={filterBookings}
                    >
                        Filter
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <Button
                        variant="contained"
                        color="primary"

                        fullWidth
                        onClick={() => history.push("/dashboard/sla/currentDayBooking")}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
            <div className={classes.toolbar} />
            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "560px" }}>
                {loading ? <Loader /> : (
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="center">City</StyledTableCell>
                                <StyledTableCell align="center">Bookings</StyledTableCell>
                                <StyledTableCell align="center">Assinged</StyledTableCell>
                                <StyledTableCell align="center">Unassinged</StyledTableCell>
                                <StyledTableCell align="center">Cancelled</StyledTableCell>
                                <StyledTableCell align="center">Picked</StyledTableCell>
                                <StyledTableCell align="center">Collection Slot</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {
                                currentBooking && currentBooking.length > 0 && currentBooking.map((booking: any, index: any) => {
                                    return (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell style={{ height: "50px" }} align="center">{booking.city}</StyledTableCell>
                                            <StyledTableCell style={{ height: "50px" }} align="center">{booking.bookings}</StyledTableCell>
                                            <StyledTableCell style={{ height: "50px" }} align="center">{booking.assinged}</StyledTableCell>
                                            <StyledTableCell style={{ height: "50px" }} align="center">{booking.unassinged}</StyledTableCell>
                                            <StyledTableCell style={{ height: "50px" }} align="center">{booking.cancelled}</StyledTableCell>
                                            <StyledTableCell style={{ height: "50px" }} align="center">{booking.picked}</StyledTableCell>
                                            <StyledTableCell style={{ height: "50px" }} align="center">
                                                {booking.collection_slot && moment(`${booking.collection_slot.split("-")[0]}`, ["HH.mm.ss"]).format("hh:mm A")}
                                                {" - "}
                                                {booking.collection_slot && moment(`${booking.collection_slot.split("-")[2]}`, ["HH.mm.ss"]).format("hh:mm A")}
                                            </StyledTableCell>

                                        </StyledTableRow>
                                    )
                                })}
                        </TableBody>
                        <TableFooter>
                            <StyledTableRow>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    colSpan={5}
                                    count={currentBooking.length || 0}
                                    rowsPerPage={currentBooking.length || 0}
                                    page={page}
                                    onPageChange={handleChangePage}
                                />
                            </StyledTableRow>
                        </TableFooter>
                    </Table>
                )}
            </TableContainer>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    currentBooking: state.SalesLeadAdminReducer.currentBooking,
    cities: state.SalesLeadAdminReducer.cities,
})

export default connect(mapStateToProps, {
    getCurrentDayBooking,
    getCities,
})(BookingsTable);

