import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Container, Box, Grid, TextField } from "@material-ui/core";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  MenuItem,
  Select,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  getCityAlias,
  getPackage,
  createCityAlias,
  getCityComments,
  getCities,
} from "../../actions/salesLeadAdminAction";
import CityTable from "./citylist";
import packagelist from "./citylist";
import { useHistory } from "react-router";
import { genrateCenterAddressPriceFilter } from "../../../helpers/generateUrl";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
  })
);

interface Props {
  loading: boolean;
  getCityAlias: any;
  cityAlias: any;
  getCities: any;
  cities: any;
  createCityAlias: any;
  getCityComments: any;
  cityComments: any;
}

const CustomUploader: React.FC<Props> = ({
  loading,
  getCityAlias,
  cityAlias,
  cities,
  getCities,
  createCityAlias,
  getCityComments,
  cityComments,
}) => {
  const timer = useRef<any>(0);
  const classes = useStyles();
  const [packageId, setPackageId] = useState<any>("");
  const [alias, setAlias] = useState<any>("");
  const [openModal, setopenModal] = useState<boolean>(false);
  const [cityId, setCityId] = useState<any>("");
  const history = useHistory();
  const [city, setCity] = useState<any>([]);

  useEffect(() => {
    getCityAlias("");
    getPackage(`org_type=homedx`);
  }, []);

  const updateAlias = async () => {
    const body: any = {
      city: city,
      name: alias,
    };
    await createCityAlias(body);
    getCityAlias("");
    history.push("/dashboard/sla/CityAlias-Master");
  };

  const filterCenter = async () => {
    const body: any = {
      city_name: city,
    };
    const url = genrateCenterAddressPriceFilter(body).substring(2);
    // setCurrentUrl(url)
    getCityAlias(`${url}`);
  };

  const handleReset = () => {
    history.push(`/dashboard/sla/CityAlias-Master`);
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid
        container
        spacing={1}
        direction="row"
        style={{ alignItems: "right" }}
      >
        <Grid item xs={12} sm={6} md={4}>
        <Autocomplete
            id="city"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setCity(obj.id);
              }
            }}
            options={cities || []}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) => option.name}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getCities(newInputValue);
              }, 1000);
              if (newInputValue.length === 0) {
                setCity(0);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="City *"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            name="alias"
            type="text"
            value={alias}
            className="input"
            label="ENTER ALIAS"
            variant="outlined"
            
            onChange={(e) => setAlias(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={updateAlias}
            disabled={alias === "" && city===0}
          >
            Create Alias
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        direction="row"
        style={{ alignItems: "right", marginTop: "1rem" }}
      >
        <Grid item xs={12} sm={6} md={3}></Grid>
        <Grid item xs={12} sm={6} md={3}>
        <Autocomplete
            id="city"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setCity(obj.id);
              }
            }}
            options={cities || []}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) => option.name}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getCities(newInputValue);
              }, 1000);
              if (newInputValue.length === 0) {
                setCity(0);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="City *"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={filterCenter}
            disabled={city==0}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            
            onClick={handleReset}
          >
            Reset
          </Button>
        </Grid>
      </Grid>

      <CityTable
        getCityAlias={getCityAlias}
        cityAlias={cityAlias}
        setopenModal={setopenModal}
        setCityId={setCityId}
      />
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.SalesLeadAdminReducer.loading,
  cityAlias: state.SalesLeadAdminReducer.cityAlias,
  cities: state.SalesLeadAdminReducer.cities,
});

export default connect(mapStateToProps, {
  getCityAlias,
  getCities,
  createCityAlias,
  getCityComments,
})(CustomUploader);
