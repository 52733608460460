export const ACTIONS = {
    CHANGE_PASSWORD: "change_password",
    GET_PIN_CODE: "ha/get_pin_code",
    GET_CITIES: "ha/get_cities",
    GET_AREA: "ha/get_area",
    GET_AGENTS: "ha/get_agents",
    GET_AGENTS_BY_USERGROUP: "ha/get_agents_by_usergroup",
    GET_PACKAGES: "ha/get_packages",
    GET_PHLEBOS: "ha/get_phlebos",
    GET_PARTNERS: "ha/get_partners",
    GET_COUPONS: "ha/get_coupons",
    CREATE_BOOKING: "ha/create_booking",
    UPDATE_BOOKING: "ha/update_booking",
    GET_BOOKING_LIST: "ha/get_booking_list",
    GET_LEAD_DETAILS: "ha/get_lead_details",
    GET_LEAD_BY_ID: "ha/get_lead_by_id",
    LEAD_DETAILS_RESET: "ha/lead_details_reset",
    GET_LEADS: "ha/get_leads",
    GET_BOOKING_BY_ID: "ha/get_booking_by_id",
    CLICK_TO_CALL: "ha/click_to_call",
    GET_POSTIVE_CALLS_COMMENTS: "ha/get_postive_calls_commnets",
    ADD_POSTIVE_CALLS_COMMENT: "ha/add_comments",
    SET_COMMENTS: "ha/set_commnets",
    UPLOAD_FILE: "ha/upload",
    SET_LOADING: "ha/set_loading",
    SEARCH_CALL: "ha/serach",
    BOOKING_RESET: "ha/booking_reset",
    ADD_COMMENT: "positive_calls/add_comment",
    CREATE_CALL: "ha/createcall",
    GET_LEAD_SOURCE: "ha/getleadsource",
    GET_RECORDINGS: "ha/get_recordings",
    GET_BOOKINGS_COMMENTS: "ha/get_bookings_commnets",
    ADD_BOOKINGS_COMMENT: "ha/add_bookings_comments",
    GET_WHATSAPP_COMMENTS: "ha/get_whatsapp_comments",
    GET_WHATSAPP_COMMENTS_BY_LEAD: "ha/get_whatsapp_comments_by_lead",
    SEND_WHATSAPP_COMMENTS: "ha/send",
    GET_CALL_STATS: "ha/get_call_stats",
    RAISE_COMPLAINT: "ha/raise_complaint",
    GET_COMPLAINTS: "ha/get_complaints",
    ASSIGN_DOCTOR: "ha/assign_doctor",
    SEND_FEEDBACK: "ha/send_feedback",
    GET_AVAILABLE_SLOTS: "ha/get_available_slots",
    GET_ALL_LEADS: "ha/get_all_leads",
    LEAD_TRANSFER_BY_COMMA: "ha/set_comments",
    GET_PANEL_USERS: "ha/get_panel_user",
    GET_SMS_DETAILS: "ha/get_sms_details",
    GET_EMAIL_DETAILS: "ha/get_email_details",
    GET_TOP_RECORDS: "ha/get_top_records",
    GET_SYNC_DATA: "ha/get_sync_data",
    GET_UNREGISTERD_BOOKING: "ha/get_unregisterd_booking",
    UPDATE_UNREGISTERD_BOOKING_STATUS: "ha/update_unregisterd_booking_status",
    GET_BOOKING_CALLS: "ha/get_booking_call",
    SAME_NUMBER_BOOKING: "ha/same_number_booking",
    GET_BREAK_RESPONSE: "ce/get_break_response",
    GET_PANEL_BREAK: "ha/get_panel_break",
    GET_SMS_REPORT_SEND: "ha/get_sms_report_send",
    GET_PAYMENT_SEND_LINK: "ha/get_payment_send_link",
    GET_PAYMENT_RESEND_LINK: "ha/get_payment_resend_link",
    GET_PAYMENT_CANCEL_LINK: "ha/get_payment_cancel_link",
    GET_SMS_LINK: "ha/send_sms_link",
    CREATE_ADDITIONAL_BOOKING: "ha/create_additional_booking",
    UPDATE_ADDITIONAL_BOOKING: "ha/update_additional_booking",
    GET_REPORT_DOWNLOAD: "ha/get_report_download",
    GET_REPORT_STATUS: "ha/get_report_status",
    GET_CENTER_INFORMATION: "ha/get_center_information",
    GET_NOTIFICATION_MESSAGE: "ha/get_notification_message",
    GET_HOLD_BOOKING_LIST: "ha/get_hold_booking_list",
    GET_PRESCRIPTION_DATA: "ha/get_prescription_data",
    UPDATE_PRESCRIPTION_STATUS:"ha/update_prescription_status",
    GET_HOLD_BOOKING_CALL_COUNT:"ha/get_hold_booking_call_count",
    GET_USER_DETAILS:"ha/get_user_details",
    GET_VERIFICATION_BOOKING_DETAILS: "ha/get_verification_booking_details",
    GET_UNMASKED_NUMBER:"ha/get_unmasked_number",
    CHECK_GEOFENCE_AREA: "ha/checking-geofence-area",
    GET_FAMILY_PACKAGES: "ha/get_family_packages",
    GET_FAMILY_PACKAGES_BOOKING: "ha/get_family_packages_booking",
    CREATE_FAMILY_PACKAGES_BOOKING: "ha/create_family_packages_booking",
    GET_RED_COIN:"ha/get_red_coin",
    GET_REPORT_DATA:"ha/get_report_data",
    GET_HEALTH_TEST_PARAMETER:"ha/get_health_test_parameter",
    GET_PARAMETER_COUNT:"ha/get_parameter_count",
    GET_DISPOSITIONS: "ha/get_dispositions",
    GET_SUB_CENTER_INFORMATION:"ha/get_sub_center_information",
    GET_IMAGING_TIMESLOTS: "ha/get_imaging_timeslots",
    CREATE_IMAGING_BOOKING: "ha/CREATE_IMAGING_BOOKING",
    GET_MULTIPLE_PACKAGES: "ha/get_multiple_package",
    GET_DISPLAY_CITIES: "ha/get_display_cities",
    GET_MAMPMYINDIA_ACCESS_TOKEN: "ha/get-mapmyindia-access-token",
    CHECK_GEOFENCE_AREA_MAMPMYINDIA: "ha/checking-geofence-area-mapmyindia",
    GET_ADDRESS_TYPE_SEATING:"ce/get_address_type_seating",
    GET_TRANSFER_BOOKING:"ha/get_transfer_booking",
    UPDATE_TRANSFER_TICKET_STATUS:"ha/update_ticket_status",
    GET_TICKET_DATA: "ha/get_ticket_data",
    GET_TICKET_CATEGORIES: "ha/get_ticket_category",
    GET_TICEKT_SUB_CATEGORIES: "ha/get_ticket_categories",
    CREATE_TASK_TICKET: "ha/create_task_ticket",
    GET_RED_TECH_COMMENT: "ha/redtech_comments/",
    POST_RED_TECH_COMMENT: "ha/postredtech_comments/",
    GET_CE_CENTER_FOR_CC:"ha/get_ce_center_for_cc",
    GET_TIMESLOTS: "ha/get_timeslot",
    CREATE_PILOT_BOOKING: "ha/create_pilot_booking",


};

interface GetPinCode {
    type: typeof ACTIONS.GET_PIN_CODE;
    payload: Array<any>;
}
interface UpdateTicketStatus {
    type: typeof ACTIONS.UPDATE_TRANSFER_TICKET_STATUS;
    payload: Array<any>;
}
interface GetTransferBooking {
    type: typeof ACTIONS.GET_TRANSFER_BOOKING;
    payload: Array<any>;
}
interface GetDispositions {
    type: typeof ACTIONS.GET_DISPOSITIONS;
    payload: Array<any>;
}
interface GetUnmaskedNumber {
    type: typeof ACTIONS.GET_UNMASKED_NUMBER;
    payload: Array<any>;
}
interface GetParameterCount {
    type: typeof ACTIONS.GET_PARAMETER_COUNT;
    payload: Array<any>;
}
interface GetHealthTestParameter {
    type: typeof ACTIONS.GET_HEALTH_TEST_PARAMETER;
    payload: Array<any>;
}
interface GetReportData {
    type: typeof ACTIONS.GET_REPORT_DATA;
    payload: Array<any>;
}
interface GetTimeSlot {
    type: typeof ACTIONS.GET_TIMESLOTS;
    payload: Array<any>;
  }
interface GetRedCoin {
    type: typeof ACTIONS.GET_RED_COIN;
    payload: Array<any>;
}
interface CheckGeoFenceArea {
    type: typeof ACTIONS.CHECK_GEOFENCE_AREA
    payload: Array<any>
}
interface GetUserDetails {
    type: typeof ACTIONS.GET_USER_DETAILS;
    payload: Array<any>;
}
interface UpdatePrescriptionStatus {
    type: typeof ACTIONS.UPDATE_PRESCRIPTION_STATUS;
    payload: Array<any>;
}
interface GetHoldBookingCallCount {
    type: typeof ACTIONS.GET_HOLD_BOOKING_CALL_COUNT;
    payload: Array<any>;
}
interface GetPrescriptionData {
    type: typeof ACTIONS.GET_PRESCRIPTION_DATA;
    payload: Array<any>;
}
interface ChangePassword {
    type: typeof ACTIONS.CHANGE_PASSWORD;
    payload: Object;
}
interface GetAddressTypeSeating {
    type: typeof ACTIONS.GET_ADDRESS_TYPE_SEATING;
    payload: Object;
}

interface UploadFile {
    type: typeof ACTIONS.UPLOAD_FILE;
    payload: Object;
}

interface GetCities {
    type: typeof ACTIONS.GET_CITIES;
    payload: Array<any>;
}

interface GetArea {
    type: typeof ACTIONS.GET_AREA;
    payload: Array<any>;
}

interface CreatePilotBooking {
    type: typeof ACTIONS.CREATE_PILOT_BOOKING;
    payload: boolean;
  }
interface GetAgents {
    type: typeof ACTIONS.GET_AGENTS;
    payload: Array<any>;
}

interface GetPhlebo {
    type: typeof ACTIONS.GET_PHLEBOS;
    payload: Array<any>;
}

interface SetComments {
    type: typeof ACTIONS.SET_COMMENTS;
    payload: Object;
}

interface GetPositiveCallsComments {
    type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
    payload: Object;
}

interface AddPositiveCallsComments {
    type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
    payload: Object;
}

interface ClickToCall {
    type: typeof ACTIONS.CLICK_TO_CALL;
    payload: Array<any>;
}

interface GetPackages {
    type: typeof ACTIONS.GET_PACKAGES;
    payload: Array<any>;
}

interface GetPartners {
    type: typeof ACTIONS.GET_PARTNERS;
    payload: Array<any>;
}

interface GetCoupons {
    type: typeof ACTIONS.GET_COUPONS;
    payload: Array<any>;
}

interface SetLoading {
    type: typeof ACTIONS.SET_LOADING;
    payload: boolean;
}

interface CreateBooking {
    type: typeof ACTIONS.CREATE_BOOKING;
    payload: boolean;
}
interface GetCeCenterForCc {
    type: typeof ACTIONS.GET_CE_CENTER_FOR_CC;
    payload: Array<any>;
  }

interface UpdateBooking {
    type: typeof ACTIONS.UPDATE_BOOKING;
    payload: boolean;
}

interface GetBookings {
    type: typeof ACTIONS.GET_BOOKING_LIST;
    payload: boolean;
}

interface GetBookingById {
    type: typeof ACTIONS.GET_BOOKING_BY_ID;
    payload: boolean;
}

interface GetLeads {
    type: typeof ACTIONS.GET_LEADS;
    payload: boolean;
}

interface GetLeadDetails {
    type: typeof ACTIONS.GET_LEAD_DETAILS;
    payload: boolean;
}

interface GetLeadById {
    type: typeof ACTIONS.GET_LEAD_BY_ID;
    payload: boolean;
}

interface ResetLeadDetails {
    type: typeof ACTIONS.LEAD_DETAILS_RESET;
    payload: boolean;
}

interface GetPinCode {
    type: typeof ACTIONS.GET_PIN_CODE;
    payload: Array<any>;
}
interface ChangePassword {
    type: typeof ACTIONS.CHANGE_PASSWORD;
    payload: Object;
}
interface GetCities {
    type: typeof ACTIONS.GET_CITIES;
    payload: Array<any>;
}

interface GetArea {
    type: typeof ACTIONS.GET_AREA;
    payload: Array<any>;
}
interface GetPhlebo {
    type: typeof ACTIONS.GET_PHLEBOS;
    payload: Array<any>;
}

interface GetAgents {
    type: typeof ACTIONS.GET_AGENTS;
    payload: Array<any>;
}

interface SetComments {
    type: typeof ACTIONS.SET_COMMENTS;
    payload: Object;
}

interface GetPositiveCallsComments {
    type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
    payload: Object;
}

interface AddPositiveCallsComments {
    type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
    payload: Object;
}
interface ClickToCall {
    type: typeof ACTIONS.CLICK_TO_CALL;
    payload: Array<any>;
}

interface GetPackages {
    type: typeof ACTIONS.GET_PACKAGES;
    payload: Array<any>;
}

interface GetPartners {
    type: typeof ACTIONS.GET_PARTNERS;
    payload: Array<any>;
}

interface GetCoupons {
    type: typeof ACTIONS.GET_COUPONS;
    payload: Array<any>;
}

interface SetLoading {
    type: typeof ACTIONS.SET_LOADING;
    payload: boolean;
}

interface CreateBooking {
    type: typeof ACTIONS.CREATE_BOOKING;
    payload: boolean;
}

interface UpdateBooking {
    type: typeof ACTIONS.UPDATE_BOOKING;
    payload: boolean;
}

interface GetBookings {
    type: typeof ACTIONS.GET_BOOKING_LIST;
    payload: boolean;
}

interface GetBookingById {
    type: typeof ACTIONS.GET_BOOKING_BY_ID;
    payload: boolean;
}

interface GetLeads {
    type: typeof ACTIONS.GET_LEADS;
    payload: boolean;
}
interface GetLeads {
    type: typeof ACTIONS.GET_LEADS;
    payload: boolean;
}

interface GetLeadDetails {
    type: typeof ACTIONS.GET_LEAD_DETAILS;
    payload: boolean;
}

interface GetLeadById {
    type: typeof ACTIONS.GET_LEAD_BY_ID;
    payload: boolean;
}

interface ResetLeadDetails {
    type: typeof ACTIONS.LEAD_DETAILS_RESET;
    payload: boolean;
}
interface SearchCall {
    type: typeof ACTIONS.SEARCH_CALL;
    payload: Object;
}
interface SearchCall {
    type: typeof ACTIONS.SEARCH_CALL;
    payload: Object;
}

interface GetAgentByUserGroup {
    type: typeof ACTIONS.GET_AGENTS_BY_USERGROUP;
    payload: Object;
}
interface GetSmsDetails {
    type: typeof ACTIONS.GET_SMS_DETAILS;
    payload: Array<any>;
}
interface GetEmailDetails {
    type: typeof ACTIONS.GET_EMAIL_DETAILS;
    payload: Array<any>;
}
interface GetTopRecords {
    type: typeof ACTIONS.GET_TOP_RECORDS;
    payload: Array<any>;
}
interface GetSyncData {
    type: typeof ACTIONS.GET_SYNC_DATA;
    payload: Object;
}
interface GetUnregisterdBooking {
    type: typeof ACTIONS.GET_UNREGISTERD_BOOKING;
    payload: Object;
}
interface UpdateUnregisterdBookingStatus {
    type: typeof ACTIONS.UPDATE_UNREGISTERD_BOOKING_STATUS;
    payload: Object;
}
interface GetBookingCall {
    type: typeof ACTIONS.GET_BOOKING_CALLS;
    payload: Array<any>;
}
interface SameNumberBooking {
    type: typeof ACTIONS.SAME_NUMBER_BOOKING;
    payload: Object;
}
interface GetBreakResponse {
    type: typeof ACTIONS.GET_BREAK_RESPONSE;
    payload: Object;
}
interface GetPanelBreak {
    type: typeof ACTIONS.GET_PANEL_BREAK;
    payload: Object;
}
interface GetSmsReportSent {
    type: typeof ACTIONS.GET_SMS_REPORT_SEND;
    payload: Object;
}
interface GetPaymentSendLink {
    type: typeof ACTIONS.GET_PAYMENT_SEND_LINK;
    payload: Object;
}
interface GetPaymentReSendLink {
    type: typeof ACTIONS.GET_PAYMENT_RESEND_LINK;
    payload: Object;
}
interface GetPaymentCancelLink {
    type: typeof ACTIONS.GET_PAYMENT_CANCEL_LINK;
    payload: Object;
}
interface SendSmsLink {
    type: typeof ACTIONS.GET_SMS_LINK;
    payload: Object;
}
interface CreateAdditionalBooking {
    type: typeof ACTIONS.CREATE_ADDITIONAL_BOOKING;
    payload: Object;
}
interface UpdateAdditionalBooking {
    type: typeof ACTIONS.UPDATE_ADDITIONAL_BOOKING;
    payload: Object;
}
interface GetReportDownload {
    type: typeof ACTIONS.GET_REPORT_DOWNLOAD;
    payload: Object;
}
interface GetReportStatus {
    type: typeof ACTIONS.GET_REPORT_STATUS;
    payload: Array<any>;
}
interface GetCenterInfo {
    type: typeof ACTIONS.GET_CENTER_INFORMATION;
    payload: Array<any>;
}
interface GetNotificationMessage {
    type: typeof ACTIONS.GET_NOTIFICATION_MESSAGE;
    payload: Array<any>;
}
interface GetHoldBookingList {
    type: typeof ACTIONS.GET_HOLD_BOOKING_LIST;
    payload: Array<any>;
}
interface GetVerificationBookingDetails {
    type: typeof ACTIONS.GET_VERIFICATION_BOOKING_DETAILS;
    payload: Array<any>;
}
interface GetFamilyPackages {
    type: typeof ACTIONS.GET_FAMILY_PACKAGES;
    payload: Array<any>;
}

interface GetFamilyPackagesBooking {
    type: typeof ACTIONS.GET_FAMILY_PACKAGES_BOOKING;
    payload: Array<any>;
}

interface CreateFamilyPackageBooking {
    type: typeof ACTIONS.CREATE_FAMILY_PACKAGES_BOOKING;
    payload: Object;
}
interface GetSubCenterInformation {
    type: typeof ACTIONS.GET_SUB_CENTER_INFORMATION;
    payload: Array<any>;
}
interface GetImagingTimeSlots {
    type: typeof ACTIONS.GET_IMAGING_TIMESLOTS;
    payload: Array<any>;
}
interface CreateImagingBooking {
    type: typeof ACTIONS.CREATE_IMAGING_BOOKING;
    payload: boolean;
}
interface GetMultiplepackage {
    type: typeof ACTIONS.GET_MULTIPLE_PACKAGES;
    payload: Array<any>;
}
interface GetDisplayCities {
    type: typeof ACTIONS.GET_DISPLAY_CITIES;
    payload: Array<any>;
}
interface GetMapMyIndiaAccessToken {
    type: typeof ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN
    payload: Array<any>
}
interface CheckGeoFenceAreaMapMyIndia {
    type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA
    payload: Array<any>
}

interface GetRedTechComment {
    type: typeof ACTIONS.GET_RED_TECH_COMMENT;
    payload: Array<any>;
  }
  interface GetTicketData {
    type: typeof ACTIONS.GET_TICKET_DATA;
    payload: Object;
  }
  interface GetTicketCategory {
    type: typeof ACTIONS.GET_TICKET_CATEGORIES;
    payload: Object;
  }
  interface GetTicketSubCategories {
    type: typeof ACTIONS.GET_TICEKT_SUB_CATEGORIES;
    payload: Object;
  }
  interface PostRedTechComment {
    type: typeof ACTIONS.POST_RED_TECH_COMMENT;
    payload: Object;
  }
  interface CreateTaskTicket {
    type: typeof ACTIONS.CREATE_TASK_TICKET;
    payload: Array<any>;
  }

export type PhleboAdminTypes =
    | SearchCall
    | GetPinCode
    | ChangePassword
    | GetCities
    | GetArea
    | GetAgents
    | GetPhlebo
    | GetAgentByUserGroup
    | GetPackages
    | CreateBooking
    | UpdateBooking
    | GetPartners
    | GetCoupons
    | GetBookings
    | GetBookingById
    | SetLoading
    | GetLeadDetails
    | GetLeads
    | GetLeadById
    | ResetLeadDetails
    | ClickToCall
    | GetPositiveCallsComments
    | AddPositiveCallsComments
    | SetComments
    | UploadFile
    | GetPinCode
    | ChangePassword
    | GetCities
    | GetArea
    | GetAgents
    | GetPhlebo
    | GetPackages
    | CreateBooking
    | UpdateBooking
    | GetPartners
    | GetCoupons
    | GetBookings
    | GetBookingById
    | SetLoading
    | GetLeadDetails
    | GetLeads
    | GetLeadById
    | ResetLeadDetails
    | ClickToCall
    | GetPositiveCallsComments
    | AddPositiveCallsComments
    | SetComments
    | SearchCall
    | GetSmsDetails
    | GetEmailDetails
    | GetTopRecords
    | GetSyncData
    | GetUnregisterdBooking
    | UpdateUnregisterdBookingStatus
    | GetBookingCall
    | SameNumberBooking
    | GetBreakResponse
    | GetPanelBreak
    | GetSmsReportSent
    | GetPaymentSendLink
    | GetPaymentReSendLink
    | GetPaymentCancelLink
    | SendSmsLink
    | CreateAdditionalBooking
    | UpdateAdditionalBooking
    | GetReportDownload
    | GetReportStatus
    | GetCenterInfo
    | GetNotificationMessage
    | GetHoldBookingList
    | GetPrescriptionData
    | UpdatePrescriptionStatus
    | GetHoldBookingCallCount
    | GetUserDetails
    | GetVerificationBookingDetails
    | GetUnmaskedNumber
    | CheckGeoFenceArea
    | GetFamilyPackages
    | GetFamilyPackagesBooking
    | CreateFamilyPackageBooking
    | GetRedCoin
    | GetReportData
    | GetHealthTestParameter
    | GetParameterCount
    | GetDispositions
    | GetSubCenterInformation
    | GetImagingTimeSlots
    | CreateImagingBooking
    | GetMultiplepackage
    | GetDisplayCities
    | GetMapMyIndiaAccessToken
    | CheckGeoFenceAreaMapMyIndia
    | GetAddressTypeSeating
    | GetTransferBooking
    | UpdateTicketStatus
    | GetTicketData
    | GetTicketCategory
    | GetTicketSubCategories
    | CreateTaskTicket
    | GetRedTechComment
    | PostRedTechComment
    |GetCeCenterForCc
    |GetTimeSlot
    |CreatePilotBooking