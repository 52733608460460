import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Container,
  Box,
  Grid,
  TextField,
  Checkbox,
  Chip,
} from "@material-ui/core";
import {
  Paper,
  Table,
  MenuItem,
  TableBody,
  Select,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
  Switch,
} from "@material-ui/core";
import { CheckBoxOutlineBlank, CheckBoxRounded } from "@material-ui/icons";
import Button from "@mui/material/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import PackageCommentModal from "../../Components/comment/packageComment";
import {
  getSubCenterInfo,
  getCenterAddressPrice,
  getTimeslots,
  getDays,
  BulkPackageEdit,
  getCentrePackageComments,
  getCities,
} from "../../actions/ImagingAdminAction";
import Loader from "../../Components/loader";
import { genrateCenterAddressPriceFilter } from "../../../helpers/generateUrl";
import { ACTIONS } from "../../interfaces/actionTypes/ImagingAdminType";
import TableData from "./testpackagetable";
import { useDispatch } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
  })
);

interface Props {
  getSubCenterInfo: any;
  subCenterInfor: any;
  getCenterAddressPrice: any;
  centerAddressPrice: any;
  getTimeslots: any;
  timeSlots: any;
  dayList: any;
  getDays: any;
  loading: any;
  BulkPackageEdit: any;
  getCentrePackageComments: any;
  centrePackageComments: any;
  getCities: any;
  cities: any;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
  getSubCenterInfo,
  subCenterInfor,
  getCenterAddressPrice,
  centerAddressPrice,
  getTimeslots,
  timeSlots,
  dayList,
  getDays,
  BulkPackageEdit,
  loading,
  getCentrePackageComments,
  centrePackageComments,
  getCities,
  cities,
}) => {
  let dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [centerId, setCenterId] = useState<any>("");
  const [pincodeTxt, setPincodeTxt] = useState<any>("");
  const [testType, setTestType] = useState<string>("");
  const [currentUrl, setCurrentUrl] = useState<any>("");
  const [testStatus, setTestStatus] = useState("none");
  const [editData, setEditData] = useState<boolean>(false);
  const [timeslotsIds, setTimeslotsIds] = useState<any>([]);
  const [timeInfo, setTimeInfo] = useState<any>([]);
  const [timeInfoDisp, setTimeInfoDisp] = useState<any>([]);
  const [dayId, setDayId] = useState<any>([]);
  const [dayDisp, setDayDisp] = useState<any>([]);
  const [dayKey, setDayKey] = useState<any>(0);
  const [timeKey, setTimeKey] = useState<any>(0);
  const [timeDayDisplay, setTimeDayDisplay] = useState<any>([]);
  const [openModal, setopenModal] = useState<boolean>(false);
  const [packId, setpackId] = useState<any>("");
  const [centreKey, setCentreKey] = useState<any>(0);
  const [typeKey, setTypeKey] = useState<any>(0);
  useEffect(() => {
    getDays();
    getTimeslots();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [city, setCity] = useState<any>("");
  const filterTest = () => {
    const body: any = {
      center_address: centerId,
      type: testType,
    };
    const url = genrateCenterAddressPriceFilter(body).substring(2);
    getCenterAddressPrice(`?${url}`);
    setCurrentUrl(url);
  };

  const handleReset = () => {
    setCentreKey(centreKey + 1 + typeKey);
    setEditData(false);
    setTypeKey(typeKey + 1 + centreKey);
    dispatch({ type: ACTIONS.GET_CENTER_ADDRESS_PRICE, payload: [] });
  };

  useEffect(() => {
    getSubCenterInfo(`?imaging=true`);
  }, []);

  const activeDeactiveAction = async () => {
    const tempD = [...dayDisp];
    const newTemp = new Array();
    tempD.map((data: any) => {
      newTemp.push({
        day: data.day,
        timeslot: data.timeslot.map((da: any) => da.id),
      });
    });
    const body = {
      center_address: centerId,
      type: testType,
      is_active: testStatus,
      time_info: newTemp,
    };
    await BulkPackageEdit(body);
    getCenterAddressPrice(`?${currentUrl}`);
    // setEditData(false);
  };
  useEffect(() => {
    let tempData = [...timeInfo];
    tempData.push({ day: dayDisp, timeslot: timeSlots.results });
    setTimeInfo(tempData);
  }, [dayId]);
  const handleDelete = (index: any, index2: any) => {
    const tempData: any = [...dayDisp];
    const tempDataTime: any = [...dayDisp[index].timeslot];
    tempDataTime.splice(index2, 1);
    tempData[index].timeslot = tempDataTime;

    setDayDisp(tempData);
  };
useEffect(() => {
  if(pincodeTxt.length===6){
    getSubCenterInfo(`?imaging=true&pincode=${pincodeTxt}`)
  }
}, [pincodeTxt])

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "left" }}
        >
          <Grid item xs={12} sm={6} md={2}>
            <TextField
              className="input"
              label="Pincode"
              variant="outlined"
              fullWidth
              onChange={(e) =>
                setPincodeTxt(
                  String(e.target.value.replace(/[^0-9]/g, "")).trimStart()
                    .length <=6
                    ? String(e.target.value.replace(/[^0-9]/g, "")).trimStart()
                    : pincodeTxt
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Autocomplete
              id="city"
              key={pincodeTxt}
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setCity(obj.id);
                  getSubCenterInfo(`?imaging=true&pincode=${pincodeTxt.length===6?pincodeTxt:""}&city=${obj.id}`);
                  setCentreKey(centreKey + 1 + typeKey);
                }
              }}
              options={cities}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => option.name}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCities(newInputValue);
                  if (newInputValue.length === 0) {
                    setCity("");
                  }
                }, 1000);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  className="input"
                  label="City"
                  variant="outlined"
                  style={{ width: "100%", marginBottom: "1rem" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              id="center"
              key={Number(centreKey)+Number(pincodeTxt)}
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setCenterId(obj.id);
                }
              }}
              options={subCenterInfor.length > 0 ? subCenterInfor : []}
              freeSolo
              blurOnSelect
              aria-required
              loading={loading}
              limitTags={1}
              getOptionLabel={(option: any) =>
                `Center:${option?.center?.name},Address:${option?.address}`
              }
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getSubCenterInfo(
                    `?imaging=true&city=${city}&pincode=${pincodeTxt.length===6?pincodeTxt:""}&name=${newInputValue}`
                  );
                }, 1000);
                if (newInputValue.length === 0) {
                  setCenterId("");
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  variant="outlined"
                  label="CENTER NAME"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              id="colony-area-sector"
              key={typeKey}
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setTestType(obj.value);
                }
              }}
              options={[
                {
                  text: "X-Ray",
                  value: "XRay",
                },
                {
                  text: "MRI",
                  value: "MRI",
                },
                {
                  text: "USG",
                  value: "USG",
                },
                {
                  text: "PET SCAN",
                  value: "PET",
                },
                {
                  text: "NUCLEAR",
                  value: "NUC",
                },
                {
                  text: "DEXA",
                  value: "DEXA",
                },
                {
                  text: "Mammography",
                  value: "MAM",
                },
                {
                  text: "Cardio",
                  value: "CAR",
                },
                {
                  text: "Neurology",
                  value: "NEU",
                },
                {
                  text: "CT",
                  value: "CT",
                },
                {
                  text: "CBCT",
                  value: "CBCT",
                },
                {
                  text: "PFT",
                  value: "PFT",
                },
                {
                  text: "Audiology",
                  value: "Audiology",
                },
                {
                  text: "Gastroenterology",
                  value: "Gastroenterology",
                },
                {
                  text: "Gyne",
                  value: "Gyne",
                },
                {
                  text: "Path At Centre",
                  value: "PAC",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => option.text}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="TYPE"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={centerId === "" || testType === ""}
              onClick={filterTest}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={1}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleReset}
            >
              Reset
            </Button>
          </Grid>
          {centerAddressPrice &&
          centerAddressPrice.results &&
          centerAddressPrice.results.length > 0 ? (
            <Grid item xs={12} sm={6} md={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={centerId === "" || testType === ""}
                onClick={() => setEditData(true)}
              >
                Edit Timing
              </Button>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
        {editData ? (
          <Grid
            container
            spacing={1}
            direction="row"
            style={{ alignItems: "left" }}
          >
            <Grid item xs={12} sm={6} md={4}>
              <Select
                className="input"
                name="usergroup"
                variant="outlined"
                value={testStatus}
                style={{ width: "100%" }}
                onChange={(e) => setTestStatus(e.target.value as string)}
              >
                <MenuItem disabled value={"none"}>
                  Type
                </MenuItem>
                <MenuItem value={"true"}>Active</MenuItem>
                <MenuItem value={"false"}>Inactive</MenuItem>
              </Select>
              {testStatus === "none" ? (
                <p style={{ color: "red" }}>Please Select Type</p>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Autocomplete
                id="day"
                key={timeKey}
                onChange={(event, newValue) => {
                  if (newValue) {
                    let timeArr: any = [];
                    let timeIdArr: any = [];
                    newValue.map((val: any) => {
                      let obj = JSON.parse(JSON.stringify(val, null, " "));
                      timeArr.push({
                        day: obj.id,
                        dayName: obj.day,
                        timeslot: timeSlots.results,
                      });
                      timeIdArr.push(obj.id);
                    });
                    setDayId(timeIdArr);
                    setDayDisp(timeArr);
                  }
                }}
                multiple
                options={dayList.results || []}
                disableClearable
                limitTags={0}
                getOptionLabel={(option: any) => option.day}
                freeSolo
                disabled={testStatus === "none"}
                disableCloseOnSelect
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: any, index: number) => (
                    <Chip
                      variant="default"
                      color="primary"
                      label={option.day}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Day"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    className="input"
                  />
                )}
              />
              {dayId.length === 0 ? (
                <p style={{ color: "red" }}>Please Select Day</p>
              ) : (
                ""
              )}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={testStatus === "none" || dayId.length === 0}
                onClick={activeDeactiveAction}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
        {dayDisp && dayDisp.length > 0 ? (
          <TableContainer component={Paper}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Day</TableCell>
                  <TableCell align="center">Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dayDisp &&
                  dayDisp.length > 0 &&
                  dayDisp.map((row: any, index: any) => (
                    <TableRow key={row.name}>
                      <TableCell align="center">{row.dayName}</TableCell>
                      <TableCell align="center">
                        {row.timeslot.map((data: any, index2: any) => {
                          return (
                            <Chip
                              key={index2}
                              label={`${data?.start_time} Onward`}
                              onDelete={() => handleDelete(index, index2)}
                            />
                          );
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          ""
        )}

        <TableData
          centerAddressPrice={centerAddressPrice}
          currentUrl={currentUrl}
          getCenterAddressPrice={getCenterAddressPrice}
          setopenModal={setopenModal}
          setpackId={setpackId}
        />
        <PackageCommentModal
          packId={packId}
          openModal={openModal}
          setopenModal={setopenModal}
          getCentrePackageComments={getCentrePackageComments}
          centrePackageComments={centrePackageComments}
          loading={loading}
        />
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.ImagingAdminReducer.loading,
  subCenterInfor: state.ImagingAdminReducer.subCenterInfor,
  timeSlots: state.ImagingAdminReducer.timeSlots,
  centerAddressPrice: state.ImagingAdminReducer.centerAddressPrice,
  dayList: state.ImagingAdminReducer.dayList,
  centrePackageComments: state.ImagingAdminReducer.centrePackageComments,
  cities: state.ImagingAdminReducer.cities,
});

export default connect(mapStateToProps, {
  getSubCenterInfo,
  getCenterAddressPrice,
  getTimeslots,
  getDays,
  BulkPackageEdit,
  getCentrePackageComments,
  getCities,
})(CustomerLeadFilterPage);
