import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, withStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../components/loader/index';
import { MessageRounded } from '@material-ui/icons';
import { getQueryTickets, searchCall } from '../../CustomerLead-Panel/actions/bookingActions'
import { useEffect } from 'react';
import RaiseComplaintModal from '../components/Comments/updateTicketModal'
import {
    Container,
   Grid,
    TextField,
} from '@material-ui/core';
import Box from '@mui/material/Box';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);

const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,fontWeight:600
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);
interface Props {
    getQueryTickets: any
    complaints: any
    searchCall: any
    loading: any
}

const BookingsTable: React.FC<Props> = ({
    getQueryTickets,
    complaints,
    searchCall,
    loading,
}) => {
    const classes = useStyles()
    const history = useHistory()
    const [raiseComplaint, setRaiseComplaint] = useState(false)
    const [leadId, setLeadId] = useState(0)
    const [type, setType] = useState("")
    const [query, setQuery] = useState("")
    const [status, setStatus] = useState("")
    const [activeTab, setActiveTab] = React.useState<string>("");
    const [ticketId, setTicketId] = useState(0)

    const handleOpenModal = (ticket: any) => {
        setLeadId(ticket.lead.id)
        setType(ticket.querytype)
        setQuery(ticket.query)
        setStatus(ticket.status)
        setRaiseComplaint(true)
        setTicketId(ticket.id)
    }

    useEffect(() => {
        getQueryTickets("lead_ticket",activeTab)
    },[activeTab])

    useEffect(() => {
        if (raiseComplaint === false) {
            getQueryTickets("lead_ticket", activeTab)
        }
    }, [raiseComplaint])

    const [page, setPage] = useState(0)

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = complaints.links && complaints.links.next.split("?")[1];
            getQueryTickets("lead_ticket", activeTab === "" ? url : `${activeTab}&${url}`);
        } else if (newPage < page) {
            let url = complaints.links && complaints.links.previous.split("?")[1];
            getQueryTickets("lead_ticket", activeTab === "" ? url : `${activeTab}&${url}`);

        }
        setPage(newPage as number);
    };

    const viewLeadHandler = (id: any) => {
        let body = {
            search_by: "call_id",
            value: id || 0,
        };
        searchCall(body)
        history.push("/dashboard/cl/lead/search");
    };
    const handleReceivedTicket = (type: any) => {
        setActiveTab(`type=${type}`)
    }
    const handleAllTicket = () => {
        setActiveTab("")
    }
    const handleSendTicket = (type: any) => {
        setActiveTab(`type=${type}`)
    }
    return (
        <main className="data-table" style={{ width: "100%" }}>
            <div className={classes.toolbar} />
            <Grid
                container
                spacing={1}
                direction="row"
                style={{ margin: "1rem" }}
            >
              <Grid item xs={12} sm={4} md={3}>
                    <Button
                        variant={activeTab == "" ? "contained" : "outlined"}
                        color="primary"
                        fullWidth
                        onClick={() => handleAllTicket()}
                    >
                        All
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <Button
                        variant={activeTab == "type=received" ? "contained" : "outlined"}
                        color="primary" 
                        fullWidth
                        onClick={() => handleReceivedTicket("received")}
                    >
                        Received Ticket
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <Button
                        variant={activeTab == "type=send" ? "contained" : "outlined"}
                        color="primary"
                        fullWidth
                        onClick={() => handleSendTicket("send")}
                    >
                        Send Ticket
                    </Button>
                </Grid>
            </Grid>
            <Paper elevation={15} className={classes.paper}>
            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "580px" }}>
                    {loading ? <Loader /> : (
                        <Table stickyHeader aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Lead Id</StyledTableCell>
                                    <StyledTableCell align="center">Agent</StyledTableCell>
                                    <StyledTableCell align="center">Comment</StyledTableCell>
                                    <StyledTableCell align="center">Created At</StyledTableCell>
                                    <StyledTableCell align="center">Query Type</StyledTableCell>
                                    <StyledTableCell align="center">Query Description</StyledTableCell>
                                    <StyledTableCell align="center">Status</StyledTableCell>
                                    <StyledTableCell align="center">View</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    complaints.results && complaints.results.length > 0 && complaints.results.map((ticket: any, index: any) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="center">{ticket.lead && ticket.lead.id}</StyledTableCell>
                                                <StyledTableCell align="center">{ticket.panel_user && ticket.panel_user.name}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Button
                                                        color="primary"
                                                        variant="text"
                                                    >
                                                        <MessageRounded onClick={() => handleOpenModal(ticket)} />
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">{new Date(ticket.created_at).toLocaleString()}</StyledTableCell>
                                                <StyledTableCell align="center">{ticket.querytype}</StyledTableCell>
                                                <StyledTableCell align="center">{ticket.query}</StyledTableCell>
                                                <StyledTableCell align="center">{ticket.status}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Button
                                                        color="secondary"
                                                        variant="contained"
                                                        onClick={() => viewLeadHandler(ticket.lead.id)}
                                                    >
                                                        View
                                                    </Button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })
                                }
                            </TableBody>
                            <TableFooter>
                                <StyledTableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[]}
                                        colSpan={30}
                                        count={complaints.count || 0}
                                        rowsPerPage={complaints.page_size}
                                        page={page}
                                        onPageChange={handleChangePage}
                                    />
                                </StyledTableRow>
                            </TableFooter>
                        </Table>
                    )}
                </TableContainer>
                <RaiseComplaintModal open={raiseComplaint} setOpen={setRaiseComplaint} ticketId={ticketId} queryType={type} queryDesc={query} queryStatus={status} bookingId={0} leadId={Number(leadId)} getQueryTickets={undefined} bookingList={undefined} ticketDetails={undefined} packageList={undefined} getPackage={undefined} getAvailableSlots={undefined} booking_slots={undefined} collectionDate={undefined} setCollectionDate={undefined}  zoneId={undefined} />
            </Paper>
        </main>
    )
}

const mapStateToProps = (state: any) => ({
    loading: state.CustomerLeadReducer.loading,
    complaints: state.CustomerLeadReducer.complaints
})

export default connect(mapStateToProps, {
    getQueryTickets,
    searchCall
})(BookingsTable);

