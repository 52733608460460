import React, { useState } from "react";
import { useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Paper,
  TextField,
  Grid,
  Select,
  MenuItem,
  TableContainer,
  Slider,
  Chip,
  withStyles,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import { Editor, OriginalTools } from "react-bootstrap-editor";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { useEffect } from "react";
import {
  getPinCode,
  createSubCenter,
  getCenterInfo,
  getSalesManager,
  getSubCenterInfo,
  checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken,
  checkGeoFenceArea,
  updateSubCentre,
  disabledSubCenter,
  getDisplayCities,
  getCities,
} from "../../actions/ImagingAdminAction";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import { useHistory } from "react-router-dom";
import Switch from "@mui/material/Switch";
import moment from "moment";
import Loader from "../../Components/loader/index";
import Radio from "@material-ui/core/Radio";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";
import { CompareArrowsOutlined, Edit } from "@material-ui/icons";
import { TableFooter, TablePagination } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { getCenterFilter } from "../../../helpers/generateUrl";
import { ACTIONS } from "../../interfaces/actionTypes/ImagingAdminType";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
// import "./index.sass";
import { StringDecoder } from "string_decoder";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyBPjDlsHYhWtjt3FxgRPnTMXN-qJZ9hz0s");

Geocode.enableDebug();
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#3f51b5",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    table: {
      minWidth: 700,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
      minHeight: "64px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    hoveItem: {
      cursor: "pointer",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    centerIt: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

// const PrettoSlider: any = withStyles({
//   root: {
//     color: "rgb(146, 74, 145)",
//     height: 8,
//   },
//   thumb: {
//     height: 24,
//     width: 24,
//     backgroundColor: "violet",
//     border: "2px solid purple",
//     marginTop: -8,
//     marginLeft: -12,
//     "&:focus, &:hover, &$active": {
//       boxShadow: "inherit",
//     },
//   },
//   active: {},
//   valueLabel: {
//     left: "calc(-50% + 4px)",
//   },
//   track: {
//     height: 8,
//     borderRadius: 4,
//   },
//   rail: {
//     height: 8,
//     borderRadius: 4,
//   },
// })(Slider);
const DefaultSwitch: any = withStyles({
  // switchBase: {
  //   color: "red",
  //   "&$checked": {
  //     color: "green",
  //   },
  //   "&$checked + $track": {
  //     backgroundColor: "green",
  //   },
  // },
  // checked: {},
  // track: {
  //   backgroundColor: "red",
  //   color: "red",
  // },
})(Switch);

interface Props {
  // getCentreCode: any;
  // centreCode: any;
  getCenterInfo: any;
  getPinCode: any;
  centerInfo: any;
  pin_code: any;
  createSubCenter: any;
  getSalesManager: any;
  salesManager: any;
  mapmyindiaAccessToken: any;
  getMapMyIndiaAccessToken: any;
  checkGeoFenchMapMyIndia: any;
  geoFencesMapMyIndia: any;
  getSubCenterInfo: any;
  subCenterInfor: any;
  disabledSubCenter: any;
  loading: any;
  updateSubCentre: any;
  getDisplayCities: any;
  displayCities: any;
  getCities: any;
  cities: any;
}
const CreateCentrePage: React.FC<Props> = ({
  getCenterInfo,
  getPinCode,
  centerInfo,
  pin_code,
  createSubCenter,
  getSalesManager,
  salesManager,
  getSubCenterInfo,
  subCenterInfor,
  updateSubCentre,
  checkGeoFenchMapMyIndia,
  geoFencesMapMyIndia,
  getMapMyIndiaAccessToken,
  mapmyindiaAccessToken,
  disabledSubCenter,
  loading,
  getDisplayCities,
  displayCities,
  getCities,
  cities,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  let dispatch = useDispatch();
  const [center, setCentre] = useState<any>("");
  const [pincodeTxt, setPincodeTxt] = useState<any>("");
  const [gLocation, setGLocation] = useState<any>();
  const [centreName, setCentreName] = useState<any>("");
  const [mobileNumber, setMobileNumber] = useState<any>("");
  const [altNumber, setaltNumber] = useState<any>("");
  const [addressStatus, setAddressStatus] = useState<any>("confirm");
  const [centerAddress, setCentreAddress] = useState<any>("");
  const [cityName, setCityName] = React.useState<String>("");
  const [cityId, setCityId] = React.useState<any>();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [cId, setCid] = useState<any>(0);
  const [centerHeadalterNateNumber, setCentreHeadAlternateNumber] =
    useState<any>();
  const [centerHeadalContactNumber, setCentreHeadContactNumber] =
    useState<any>();
  const [email, setEmail] = React.useState("");
  const [area, setArea] = React.useState<String>("");
  const [center_longitude, setCentreLongitude] = useState<any>("");
  const [center_latitude, setCentreLatitude] = useState<any>("");
  const [updateId, setUpdateId] = useState<any>("");
  const [pinCodeId, setPincodeId] = useState<any>("");
  const [pinCodeIdDef, setPincodeIdDef] = useState<any>("");
  const [pincode, setPincode] = useState<any>();
  const [file1, setFile1] = React.useState<any>([]);
  const [accreditation, setAccreditation] = useState<any>("none");
  const [disableEdit, setDisableEdit] = useState<boolean>(false);
  const [centerAddressText, setCentreAddressText] = React.useState<any>("");
  const [mobileValidation, setMobileValidation] = useState({
    mobileNumber: true,
    centerHeadalterNateNumber: true,
    centerHeadalContactNumber: true,
    altNumber: true,
  });
  const [city, setCity] = useState<any>("");
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
  const [assignLabId, setAssignLabId] = useState<any>(5);
  const [editButton, setEditButton] = useState<boolean>(false);
  const [centerId, setCenterId] = useState<any>("");
  const [pincodeNum, setPincodeNum] = useState<any>("");
  const [open, setOpen] = React.useState(false);
  const [centreDataStatus, setCentreDataStatus] = useState<any>();
  const [additionalDetails, setAdditionalDetails] = useState<any>("");
  const verifyMobile = (type: any) => {
    const mobileRegEx = /^[6-9]\d{9}$/;
    if (
      type === "mob" &&
      mobileNumber &&
      !mobileRegEx.test(mobileNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        mobileNumber: false,
      }));
    }
    if (
      type === "centAlt" &&
      altNumber &&
      !mobileRegEx.test(altNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        altNumber: false,
      }));
    }
    if (
      type === "contAlt" &&
      centerHeadalterNateNumber &&
      !mobileRegEx.test(centerHeadalterNateNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        centerHeadalterNateNumber: false,
      }));
    }
    if (
      type === "contMob" &&
      centerHeadalContactNumber &&
      !mobileRegEx.test(centerHeadalContactNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        centerHeadalContactNumber: false,
      }));
    }
  };

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  useEffect(() => {
    getSalesManager();
    getCenterInfo("?imaging=true");
    getSubCenterInfo();
    getDisplayCities();
    getMapMyIndiaAccessToken();
  }, []);

  useEffect(() => {
    if (pin_code?.results && pin_code?.results.length > 0) {
      setCityId(pin_code?.results[0].cityid);
      setPincode(pin_code?.results[0].pincode);
      setPincodeId(pin_code?.results[0].id);
      setArea(pin_code?.results[0].area);
      setCityName(pin_code?.results[0].city);
    }
  }, [pin_code]);

  const fileSelectedHandler1 = (e: React.ChangeEvent<{ files: any }>) => {
    setFile1(e.target.files[0]);
  };
  const [checked, setChecked] = React.useState(false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleReserAddress = () => {
    setCid(cId + 2);
    setCentreLatitude("");
    setCentreLongitude("");
    setPincode("");
    setCityName("");
    setArea("");
    setCentreAddress("");
    setAddressStatus("confirm");
  };

  const handleEditCentre = (data: any) => {
    setCentre(data.center.id);
    setCentreName(data.center.name);
    setCentreHeadContactNumber(data.contact_number);
    setEmail(data.center_email);
    setMobileNumber(data.contact_number);
    setGLocation(data.google_location);
    setCentreAddress(data?.address);
    setAccreditation(data?.center_accreditation);
    setCentreAddressText(data?.address);
    setPincodeId(data?.location?.id);
    setPincodeNum(data?.location?.pincode);
    setArea(data?.location?.area);
    setCityName(data?.location?.city_name);
    setCentreLongitude(data?.logitude);
    setCentreLatitude(data?.latitude);
    setCityId(data?.location?.city);
    setPincodeIdDef(data?.location);
    setEditButton(true);
    setUpdateId(data.id);
  };
  const handleSubmitCentrePreview = (e: any) => {
    e.preventDefault();
    setOpenPreviewModal(true);
  };
  const handleConfirmAddress = () => {
    setAddressStatus("reset");
    getPinCode(
      `${pincode}&pincode=${pincode}&city=${cityName}&area=${area}&imaging=true`
    );
  };

  const handleSubmitCentre = async () => {
    // e.preventDefault();
    const formData = new FormData();

    formData.append("center", center);
    formData.append(" city", cityId);
    formData.append("center_accreditation", accreditation);

    formData.append("contact_number", centerHeadalContactNumber);

    formData.append("center_email", email);

    formData.append("pnot_doc", file1);

    formData.append("google_location", gLocation);
    formData.append("location", pinCodeId);
    formData.append("additional_detail", additionalDetails);
    if (centerAddress !== "") {
      formData.append("address", centerAddress);
    }
    if (center_longitude !== "") {
      formData.append("logitude", center_longitude);
    }
    if (center_latitude !== "") {
      formData.append("latitude", center_latitude);
    }
    if (editButton) {
      await updateSubCentre(formData, updateId);
      dispatch({ type: ACTIONS.GET_PIN_CODE, payload: [] });
      history.push("/dashboard/ia/sub-centre");
    } else {
      await createSubCenter(formData);
      dispatch({ type: ACTIONS.GET_PIN_CODE, payload: [] });
      history.push("/dashboard/ia/sub-centre");
    }

    // getCenterInfo();
  };
  //mapmyindia
  const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
  const [mapAddressObject, setMapAddressObject] = useState<any>({});
  const [accessToken, setAccessToken] = useState<any>("");
  const [customer_longitude, setCustomerLongitude] = useState<any>("");
  const [customer_latitude, setCustomerLatitude] = useState<any>("");
  const [currentUrl, setCurrentUrl] = useState<any>("");

  useEffect(() => {
    if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
      setCentreLatitude(geoFencesMapMyIndia?.latitude);
      setCentreLongitude(geoFencesMapMyIndia?.longitude);
    }
  }, [geoFencesMapMyIndia]);

  useEffect(() => {
    if (mapmyindiaAccessToken?.accesstoken) {
      setAccessToken(mapmyindiaAccessToken?.accesstoken);
    }
  }, [mapmyindiaAccessToken]);

  useEffect(() => {
    if (mapAddressObject?.eLoc) {
      getPinCode(
        `${mapAddressObject?.addressTokens?.pincode}&pincode=${mapAddressObject?.addressTokens?.pincode}&city=${mapAddressObject?.addressTokens.city}&area=${mapAddressObject?.addressTokens?.locality}&imaging=true`
      );
      setCentreAddress(mapAddressObject?.placeAddress);
      checkGeoFenchMapMyIndia(mapAddressObject?.eLoc, "NA");
    }
  }, [mapAddressObject]);

  useEffect(() => {
    if (pin_code?.results && pin_code?.results.length > 0) {
      setCityId(pin_code?.results[0].cityid);
      setPincodeNum(pin_code?.results[0].pincode);
      setPincodeId(pin_code?.results[0].id);
      setArea(pin_code?.results[0].area);
      setCityName(pin_code?.results[0].city);
    }
  }, [pin_code]);

  const filterCenter = async () => {
    const body: any = {
      center: centerId,
    };
    const url = getCenterFilter(body).substring(2);
    // setCurrentUrl(url);
    getSubCenterInfo(`?imaging=true&${url}`);
  };

  const DisableSubCenterAddress = async (pack: any) => {
    const body: any = {
      is_active: !pack.is_active,
    };
    await disabledSubCenter(pack.id, body);
    history.push("/dashboard/ia/subcenter_deactivate");
    setOpen(false);
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = subCenterInfor.links && subCenterInfor.links.next.split("?")[1];
      getSubCenterInfo(`?${url}`);
    } else if (newPage < page) {
      let url =
        subCenterInfor.links && subCenterInfor.links.previous.split("?")[1];
      getSubCenterInfo(`${url == undefined ? "" : "?" + url}`);
    }
    setPage(newPage);
  };
  useEffect(() => {
    if (pincodeTxt.length === 6) {
      getSubCenterInfo(`?imaging=true&pincode=${pincodeTxt}`);
    }
  }, [pincodeTxt]);
  const locationAutoCompleteSearch = (val: string) => {
    if (val.length > 2) {
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName = "jsonp_callback_" + "data" + id;
        window[callbackName] = (data: any) => {
          delete window[callbackName];
          const ele = window.document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          const suggestedData = data?.suggestedLocations;
          if (suggestedData.length > 0) {
            setSuggestedAddres(data?.suggestedLocations);
          }
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = window.document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          window.document.getElementsByTagName("head")[0] ||
          window.document.body ||
          window.document.documentElement
        ).appendChild(script);
      });
    }
  };
  const handleClosePreview = () => {
    setOpenPreviewModal(false);
  };

  const handleClickOpen = (data: any) => {
    setCentreDataStatus(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //mapmyindia
  const handleActivateCentre = () => {
    DisableSubCenterAddress(centreDataStatus);
  };
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <form onSubmit={handleSubmitCentrePreview}>
        <Paper elevation={5} className={classes.paper}>
          <h4>SUB CENTRE CREATION</h4>

          <Grid
            container
            spacing={3}
            direction="row"
            style={{ alignItems: "left" }}
          >
            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                id="center"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setCentre(obj.id);
                  }
                }}
                freeSolo
                blurOnSelect
                options={centerInfo?.results || []}
                disableCloseOnSelect
                getOptionLabel={(option: any) =>
                  option?.display_name === null
                    ? option?.name
                    : option?.display_name
                }
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getCenterInfo(
                      `?imaging=true&name_or_code=${newInputValue}`
                    );
                  }, 1000);

                  setCentreName(newInputValue);
                }}
                inputValue={centreName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Master Centre"
                    className="input"
                    variant="outlined"
                    fullWidth
                    required
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <TextField
                className="input"
                name="glink"
                type="text"
                label="Google location"
                value={gLocation}
                required
                variant="outlined"
                onChange={(e) => setGLocation(e.target.value)}
                style={{ width: "100%" }}
              />
            </Grid>
            {checked ? (
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  className="input"
                  name="centreaddress"
                  type="text"
                  label="Centre Address"
                  value={centerAddress}
                  required
                  variant="outlined"
                  onChange={(e) => setCentreAddress(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
            ) : (
              <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                  key={updateId}
                  id="colony-area-sector"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setMapAddressObject(obj);
                    }
                  }}
                  options={suggestedAddress ? suggestedAddress : []}
                  freeSolo
                  blurOnSelect
                  aria-required
                  getOptionLabel={(option: any) =>
                    `${option?.placeName} ${option?.placeAddress}`
                  }
                  defaultValue={{
                    placeName: centerAddressText,
                    placeAddress: "",
                  }}
                  filterOptions={(option: any) => option}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      locationAutoCompleteSearch(newInputValue);
                    }, 500);
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      label="Centre Address"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      required
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6} md={3}>
              {/* <Autocomplete
                key={updateId}
                id="pincode"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setPincodeId(obj?.id as Number);
                    setArea(obj.area as String);
                    setCityName(obj.city as String);
                    setCityId(obj.cityid);
                  }
                }}
                defaultValue={
                  pinCodeIdDef || {
                    id: "",
                    area: "",
                    city: "",
                    cityid: "",
                    pincode: "",
                  }
                }
                options={pin_code.results || []}
                freeSolo
                blurOnSelect
                aria-required
                onInputChange={(event, newInputValue) => {
                  getPinCode(newInputValue);
                }}
                // inputValue={pinCodeIdDef}
                getOptionLabel={(option: any) =>
                  option.pincode ? `${option.pincode} ,  ${option.area}` : ""
                }
                disableClearable
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Pin Code"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                )}
              /> */}
              {checked ? (
                <TextField
                  className="input"
                  onWheel={(e: any) => e.target.blur()}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 6);
                  }}
                  name="pincode"
                  type="number"
                  label="PINCODE"
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              ) : (
                <TextField
                  className="input"
                  name="pincode"
                  type="text"
                  label="PINCODE"
                  value={pincode}
                  disabled
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              )}
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <TextField
                className="input"
                name="contact"
                type="number"
                label="Contact Number"
                value={centerHeadalContactNumber}
                variant="outlined"
                style={{ margin: "0", width: "100%" }}
                onChange={(e) =>
                  setCentreHeadContactNumber(e.target.value as String)
                }
                onBlur={() => verifyMobile("contMob")}
                onFocus={() => {
                  setMobileValidation((prev: any) => ({
                    ...prev,
                    centerHeadalContactNumber: true,
                  }));
                }}
                onWheel={(e: any) => e.target.blur()}
                onInput={(e: any) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                helperText={
                  !mobileValidation.centerHeadalContactNumber &&
                  "Incorrect Mobile Number"
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                name="email"
                type="email"
                value={email}
                className="input"
                label="Email "
                variant="outlined"
                onChange={(e) => setEmail(e.target.value as string)}
                style={{ width: "100%" }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Select
                className="input"
                name="accreditation"
                variant="outlined"
                value={accreditation}
                style={{ width: "100%", height: "40px" }}
                onChange={(e) => setAccreditation(e.target.value as string)}
              >
                <MenuItem disabled value={"none"}>
                  {" "}
                  Accreditation
                </MenuItem>
                <MenuItem value={"NABL"}>NABL</MenuItem>
                <MenuItem value={"NABH"}>NABH</MenuItem>
                <MenuItem value={"NABL_AND_NABH"}>NABL AND NABH</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6} md={3} style={{ marginTop: "-10px" }}>
              <sup
                style={{
                  fontSize: "11px",
                  marginLeft: "15px",
                  marginTop: "15px",
                  background: "#fff",
                  color: "rgba(0, 0, 0, 0.54)",
                  letterSpacing: "0.00938em",
                  height: "40px",
                }}
              >
                Upload PNDT
              </sup>
              <input
                className="input"
                style={{
                  width: "100%",
                  marginTop: "-15px",
                  padding: "18.5px 14px",
                  border: "1px solid rgba(0, 0, 0, 0.23)",
                  borderRadius: "5px",
                }}
                type="file"
                name="file"
                id="file"
                multiple
                onChange={fileSelectedHandler1}
              />
            </Grid>
            {checked ? (
              <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                  id="city"
                  key={cId}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setCityName(obj);
                    }
                  }}
                  options={displayCities.cities || []}
                  freeSolo
                  blurOnSelect
                  aria-required
                  limitTags={1}
                  getOptionLabel={(option: any) => option}
                  disableClearable
                  disableCloseOnSelect
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      setCityName(newInputValue);
                    }, 1000);
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      label="City Name "
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                />
              </Grid>
            ) : (
              ""
            )}
            {checked ? (
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="latitude"
                  type="text"
                  value={center_latitude}
                  className="input"
                  label="Latitude "
                  variant="outlined"
                  onChange={(e) => setCentreLatitude(e.target.value)}
                  disabled={!checked}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
            ) : (
              ""
            )}

            {checked ? (
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="Longitude"
                  type="text"
                  value={center_longitude}
                  onChange={(e) => setCentreLongitude(e.target.value)}
                  className="input"
                  label="Logitude "
                  variant="outlined"
                  disabled={!checked}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
            ) : (
              ""
            )}

            {checked ? (
              <Grid item xs={12} sm={6} md={3}>
                {addressStatus === "confirm" ? (
                  <Button
                    onClick={handleConfirmAddress}
                    variant="contained"
                    style={{ background: "#4caf50" }}
                    startIcon={<CheckCircleIcon />}
                  >
                    Confirm Address
                  </Button>
                ) : (
                  <Button
                    onClick={handleReserAddress}
                    variant="contained"
                    style={{ background: "#3f51b5" }}
                    endIcon={<HighlightOffIcon />}
                  >
                    Reset Address
                  </Button>
                )}
              </Grid>
            ) : (
              ""
            )}

            <Grid item xs={12} sm={6} md={3}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={checked}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Manual"
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                name="Additional Details"
                type="text"
                value={additionalDetails}
                className="input"
                placeholder="Additional Details"
                variant="outlined"
                onChange={(e) =>
                  setAdditionalDetails(String(e.target.value).toUpperCase())
                }
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={
                  !mobileValidation.centerHeadalContactNumber ||
                  loading ||
                  center === ""
                }
                type="submit"
              >
                {editButton
                  ? "Preview Update Sub Centre"
                  : "Preview Create Sub Center"}
              </Button>
            </Grid>
            {!editButton ? (
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  disabled={loading}
                  onClick={() => history.push("/dashboard/ia/sub-centre")}
                >
                  Reset
                </Button>
              </Grid>
            ) : (
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => history.push("/dashboard/ia/sub-centre")}
                >
                  Cancel Update
                </Button>
              </Grid>
            )}
          </Grid>
        </Paper>
        <Dialog
          open={openPreviewModal}
          onClose={() => handleClosePreview()}
          fullWidth
          maxWidth="lg"
        >
          <Grid container style={{ padding: 20 }} spacing={3}>
            <Grid item md={4} lg={2}>
              {" "}
              Master Centre : {centreName}{" "}
            </Grid>
            <Grid item md={4} lg={3}>
              {" "}
              Google location : {gLocation}{" "}
            </Grid>

            <Grid item md={4} lg={2}>
              Centre Address : {centerAddress}
            </Grid>
            <Grid item md={4} lg={2}>
              {" "}
              : {}{" "}
            </Grid>
            <Grid item md={4} lg={2}>
              {" "}
              Pincode : {pincodeNum}{" "}
            </Grid>
            <Grid item md={4} lg={2}>
              {" "}
              City : {cityName}{" "}
            </Grid>

            <Grid item md={4} lg={3}>
              {" "}
              Email : {email}{" "}
            </Grid>
            <Grid item md={4} lg={2}>
              {" "}
              Contact Number : {centerHeadalContactNumber}{" "}
            </Grid>
            <Grid item md={4} lg={2}>
              {" "}
              Center Accreditation : {accreditation}{" "}
            </Grid>
            <Grid sm={12} />
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={
                  !mobileValidation.mobileNumber ||
                  !mobileValidation.altNumber ||
                  !mobileValidation.centerHeadalContactNumber ||
                  !mobileValidation.centerHeadalterNateNumber
                }
                onClick={() => handleSubmitCentre()}
              >
                {editButton ? "Update Sub Centre" : "Create Sub Center"}
              </Button>
            </Grid>
            {!editButton ? (
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => history.push("/dashboard/ia/sub-centre")}
                >
                  Reset
                </Button>
              </Grid>
            ) : (
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => history.push("/dashboard/ia/sub-centre")}
                >
                  Cancel Update
                </Button>
              </Grid>
            )}
          </Grid>
        </Dialog>
      </form>
      <Paper elevation={5} className={classes.paper}>
        {/* <Grid
          container
          spacing={3}
          className={classes.centerIt}
          style={{
            margin: "1rem auto",
          }}
        >
          <Grid item xs={12} sm={6} md={2}>
            <h5 style={{ marginTop: 15 }}>Search Option </h5>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              className={classes.input}
              label="Centre/Code"
              variant="outlined"
              fullWidth
              onChange={(e) => getCenterInfo(`?name_or_code=${e.target.value}`)}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() =>
                history.push("/dashboard/ia/sub-centre")
              }
            >
              Reset
            </Button>
          </Grid>
        </Grid> */}

        <div
          className="data-table"
          style={{ width: "100%", marginTop: "1rem" }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            style={{ alignItems: "right" }}
          >
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                className="input"
                label="Pincode"
                variant="outlined"
                fullWidth
                onChange={(e) =>
                  setPincodeTxt(
                    String(e.target.value.replace(/[^0-9]/g, "")).trimStart()
                      .length <= 6
                      ? String(
                          e.target.value.replace(/[^0-9]/g, "")
                        ).trimStart()
                      : pincodeTxt
                  )
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Autocomplete
                id="city"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setCity(obj.id);
                    getSubCenterInfo(
                      `?imaging=true&pincode=${
                        pincodeTxt.length === 6 ? pincodeTxt : ""
                      }&city=${obj.id}`
                    );
                  }
                }}
                options={cities}
                freeSolo
                blurOnSelect
                aria-required
                getOptionLabel={(option: any) => option.name}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getCities(newInputValue);
                    if (newInputValue.length === 0) {
                      setCity("");
                    }
                  }, 1000);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="input"
                    label="City"
                    variant="outlined"
                    style={{ width: "100%", marginBottom: "1rem" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                id="center"
                key={city}
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setCenterId(obj?.center?.id);
                  }
                }}
                options={subCenterInfor.length > 0 ? subCenterInfor : []}
                freeSolo
                blurOnSelect
                aria-required
                loading={loading}
                limitTags={1}
                getOptionLabel={(option: any) =>
                  `Center:${option?.center?.name},Address:${option?.address}`
                }
                disableClearable
                disableCloseOnSelect
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getSubCenterInfo(
                      `?imaging=true&pincode=${
                        pincodeTxt.length === 6 ? pincodeTxt : ""
                      }&city=${city}&name=${newInputValue}`
                    );
                  }, 1000);
                  if (newInputValue.length === 0) {
                    setCenterId("");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="SUB CENTRE NAME"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={filterCenter}
                disabled={centerId === ""}
              >
                Filter
              </Button>
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => history.push("/dashboard/ia/sub-centre")}
              >
                Reset
              </Button>
            </Grid>
          </Grid>

          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "515px" }}
          >
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Centre Name</StyledTableCell>
                  <StyledTableCell align="center">Email</StyledTableCell>
                  <StyledTableCell align="center">Phone</StyledTableCell>
                  <StyledTableCell align="center">
                    Center Accreditation
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Google Location{" "}
                  </StyledTableCell>
                  <StyledTableCell align="center">Location</StyledTableCell>
                  <StyledTableCell align="center">Edit Centre</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">
                    Deactivate Sub Center{" "}
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {subCenterInfor &&
                  subCenterInfor.length > 0 &&
                  subCenterInfor.map((data: any, index: any) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">
                          {" "}
                          {data?.center.name}{" "}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {" "}
                          {data?.center_email}{" "}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {" "}
                          {data?.contact_number}{" "}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {" "}
                          {data?.center_accreditation}{" "}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {" "}
                          {data?.google_location}{" "}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {" "}
                          {/* {data?.location?.area},{data?.location?.city_name},
                          {data?.location?.pincode}{" "} */}
                          {data?.address},
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <Button
                            disabled={disableEdit}
                            color="primary"
                            variant="contained"
                            onClick={() => handleEditCentre(data)}
                          >
                            <Edit />
                          </Button>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <DefaultSwitch
                            checked={data.is_active}
                            name="checkedA"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                            disableRipple
                            // onClick={() => DisableCenterAddress(data)}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button
                            // color="primary"
                            // variant="contained"
                            onClick={() => handleClickOpen(data)}
                          >
                            <DefaultSwitch
                              name="checkedA"
                              checked={data.is_active}
                            />
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
              {/* <TableFooter>
                <TablePagination
                  colSpan={6}
                  count={subCenterInfor.count || 0}
                  rowsPerPageOptions={[]}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </TableFooter> */}
            </Table>
          </TableContainer>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{" Do You want to Deactivate Centre"}</DialogTitle>
            <DialogActions>
              <Button onClick={handleClose}>Disagree</Button>
              <Button onClick={handleActivateCentre}>Agree</Button>
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  pin_code: state.ImagingAdminReducer.pin_code,
  centerInfo: state.ImagingAdminReducer.centerInfo,
  subCenterInfor: state.ImagingAdminReducer.subCenterInfor,
  salesManager: state.ImagingAdminReducer.salesManager,
  checkGeoFenceStatus: state.ImagingAdminReducer.checkGeoFenceStatus,
  geoFencesMapMyIndia: state.ImagingAdminReducer.geoFencesMapMyIndia,
  loading: state.ImagingAdminReducer.loading,
  mapmyindiaAccessToken: state.ImagingAdminReducer.mapmyindiaAccessToken,
  displayCities: state.ImagingAdminReducer.displayCities,
  cities: state.ImagingAdminReducer.cities,
});
export default connect(mapStateToProps, {
  getPinCode,
  getSalesManager,
  createSubCenter,
  getCenterInfo,
  getSubCenterInfo,
  updateSubCentre,
  checkGeoFenceArea,
  checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken,
  disabledSubCenter,
  getDisplayCities,
  getCities,
})(CreateCentrePage);
