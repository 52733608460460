import { ACTIONS, BookingTypes } from "../interfaces/actionTypes/booking";

type InitialState = {
  pin_code: Array<any>;
  cities: Array<any>;
  areaList: Array<any>;
  agentsList: Array<any>;
  packageList: Array<any>;
  familyPackageList: Array<any>;
  familyPackageBookingList: Array<any>;
  phleboList: Array<any>;
  partnerList: Array<any>;
  couponList: Array<any>;
  locationVerified: Array<any>;
  createBooking: Object;
  createPilotBooking: Object;
  createFamilyPackageBooking: Object;
  booking: Object;
  todays_positive_calls: Object;
  ticketComments: Array<any>
  newbookingList: Array<any>
  lead_details: Object;
  leadById: Object;
  leads: Array<any>;
  bookingList: Array<any>;
  dispositionList:Array<any>;
  communicationWhatsapp: Array<any>;
  sendLocationOnWhatsapp: Array<any>;
  comment: any;
  bookingsComment: any;
  lead_id: number;
  read_call_details: object;
  all_recordings: object;
  whatsappComments: object;
  whatsappCommentsByLead: object;
  callStats: object;
  complaint: object;
  complaints: object;
  lead_source: any;
  booking_slots: object;
  search_results: object;
  additional_booking: object;
  call_status: object;
  all_lead: object;
  topRcFreshLeads: object;
  topCfreshLeads: object;
  latest_lead: object;
  sameNumberBooking: boolean;
  loading: boolean;
  leadAddressLoading: boolean;
  bookingIdLoader: boolean;
  biomarkerLoader: boolean;
  loadingWhatsapp: boolean;
  breakResponse: Array<any>;
  panelBreak: Array<any>;
  paymentSendLink: Array<any>;
  paymentReSendLink: Array<any>;
  paymentCancelLink: Array<any>;
  smsDetails: Array<any>;
  emailDetails: Array<any>;
  smsReportSend: Array<any>;
  centerInfo: Array<any>;
  sendSMSLink: Array<any>;
  notificationMessage: Array<any>;
  reportStatus: Array<any>;
  unmaskedNumber: Array<any>;
  userDetails: Array<any>;
  labDetails: Array<any>;
  notificationType: Array<any>;
  zoneList: Object;
  usersData: Object;
  checkGeoFenceStatus: boolean;
  geoFencesMapMyIndia: Array<any>;
  mapmyindiaAccessToken: Array<any>;
  addressDetailsLatLong: Array<any>;
  todayMissedCallsList: Array<any>;
  repeatedLead: Array<any>;
  reportDownload: Array<any>;
  callNotification: Array<any>;
  repetedLead: Array<any>;
  timeslots: Array<any>;
  createImagingBooking: Array<any>;
  redCoin: Array<any>;
  subCenter: Array<any>;
  imagingTimeSlots: Array<any>;
  imaginBooking: Array<any>;
  leadStatus: Array<any>;
  multiplePackage: Array<any>;
  displayCities: Array<any>;
  prescription: Array<any>;
  doctorInfo: Array<any>;
  imagingTicket: Array<any>;
  imaginraiseTicket: Array<any>;
  imagingTicketcount: Array<any>;
  createIMGBookingCustom: Array<any>;
  createWhatsappTemplate: Array<any>;
  ticketData: Array<any>;
  ticketCategories: Array<any>;
  ticketSubCategories: Array<any>;
  redtechcomment: Array<any>;
  cancelHoldBooking: Array<any>;
  qualityScoringReport: Array<any>;
  get_quality_scoring_report_for_backup: Array<any>;
  ltvLead: Object;
  userStats: Object;
  ceCenterForCC: Array<any>;
  whatsappTemplate: Array<any>;
  disposition: Array<any>;
  geoLoading:boolean;
  bookingListLoader: boolean;
  qualityScoreLoader: boolean
  qualityScoreBackupLoader: boolean;
  csTicketLoading:boolean;
  geoCheckError:string
  bookingComplaints: Array<any>
  geoFencesMapMyIndiaForCustomer: Array<any>;
  geoLoadingforcustomer: boolean;
  geoCheckErrorForCustomer: string;
  biomarkerScreening: Array<any>;
  biomarkerUltasound: Array<any>;
  markerAdditionMember: Array<any>;
  crmLeadAddress: Array<any>;
  crmPostLeadAddress: Array<any>;
  packageTags:Array<any>;
  packageCategory:Array<any>;
  whatsappMessageStatus: Array<any>;
};

const initialState: InitialState = {
  userStats: {},
  ltvLead: {},
  pin_code: [],
  cities: [],
  areaList: [],
  agentsList: [],
  phleboList: [],
  packageList: [],
  subCenter: [],
  familyPackageList: [],
  familyPackageBookingList: [],
  partnerList: [],
  couponList: [],
  locationVerified: [],
  createBooking: {},
  createPilotBooking: {},
  createFamilyPackageBooking: {},
  booking: {},
  ticketComments: [],
  lead_details: {},
  leadById: {},
  read_call_details: {},
  todays_positive_calls: {},
  all_recordings: {},
  whatsappComments: {},
  whatsappCommentsByLead: {},
  additional_booking: {},
  callStats: {},
  complaint: {},
  complaints: {},
  leads: [],
  bookingList: [],
  comment: {},
  bookingsComment: {},
  lead_id: 0,
  lead_source: {},
  booking_slots: {},
  search_results: {},
  call_status: {},
  all_lead: {},
  topRcFreshLeads: {},
  topCfreshLeads: {},
  latest_lead: {},
  breakResponse: [],
  panelBreak: [],
  sameNumberBooking: false,
  loading: false,
  leadAddressLoading: false,
  bookingIdLoader: false,
  biomarkerLoader: false,
  loadingWhatsapp: false,
  paymentSendLink: [],
  paymentReSendLink: [],
  paymentCancelLink: [],
  imaginBooking: [],
  smsDetails: [],
  emailDetails: [],
  smsReportSend: [],
  centerInfo: [],
  sendSMSLink: [],
  notificationMessage: [],
  reportStatus: [],
  unmaskedNumber: [],
  userDetails: [],
  labDetails: [],
  notificationType: [],
  createImagingBooking: [],
  zoneList: {},
  usersData: {},
  checkGeoFenceStatus: false,
  geoFencesMapMyIndia: [],
  mapmyindiaAccessToken: [],
  addressDetailsLatLong: [],
  todayMissedCallsList: [],
  repeatedLead: [],
  reportDownload: [],
  leadStatus: [],
  callNotification: [],
  repetedLead: [],
  timeslots: [],
  redCoin: [],
  imagingTimeSlots: [],
  multiplePackage: [],
  displayCities: [],
  prescription: [],
  doctorInfo: [],
  imagingTicket: [],
  imaginraiseTicket: [],
  imagingTicketcount: [],
  createIMGBookingCustom: [],
  createWhatsappTemplate: [],
  ticketData: [],
  ticketCategories: [],
  ticketSubCategories: [],
  redtechcomment: [],
  cancelHoldBooking: [],
  qualityScoringReport: [],
  get_quality_scoring_report_for_backup: [],
  ceCenterForCC: [],
  whatsappTemplate: [],
  communicationWhatsapp: [],
  sendLocationOnWhatsapp: [],
  geoLoading:false,
  disposition: [],
  geoCheckError:"",
  bookingComplaints: [],
  bookingListLoader:false,
  qualityScoreLoader:false,
  qualityScoreBackupLoader:false,
  csTicketLoading:false,
  geoFencesMapMyIndiaForCustomer: [],
  geoLoadingforcustomer: false,
  geoCheckErrorForCustomer: "",
  biomarkerScreening: [],
  biomarkerUltasound: [],
  markerAdditionMember: [],
  newbookingList: [],
  crmLeadAddress: [],
  crmPostLeadAddress:[],
  packageTags:[],
  dispositionList:[],
  packageCategory:[],
  whatsappMessageStatus: []
};

const BookingReducer = (state = initialState, action: BookingTypes) => {
  switch (action.type) {
    case ACTIONS.GET_ALL_LEADS:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
      case ACTIONS.SET_ALL_BOOKING_LOADING:
        return {
          ...state,
          bookingListLoader: action.payload,
        };
      case ACTIONS.SET_QUALITYSCORE_LOADING:
        return {
          ...state,
          qualityScoreLoader: action.payload,
        };
      case ACTIONS.SET_QUALITYSCOREBACKUP_LOADING:
        return {
          ...state,
          qualityScoreBackupLoader: action.payload,
        };
      case ACTIONS.SET_CSTICKET_LOADING:
        return {
          ...state,
          csTicketLoading: action.payload,
        };
    case ACTIONS.GET_USER_STATS:
      return {
        ...state,
        userStats: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TOP_RC_FRESH_LEADS:
      return {
        ...state,
        topRcFreshLeads: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RED_COIN:
      return {
        ...state,
        redCoin: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TOP_C_FRESH_LEADS:
      return {
        ...state,
        topCfreshLeads: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LATEST_LEAD:
      return {
        ...state,
        latest_lead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PIN_CODE:
      return {
        ...state,
        pin_code: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITIES:
      return {
        ...state,
        cities: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_CALL:
      return {
        ...state,
        loading: false,
        lead_id: action.payload,
      };
    case ACTIONS.GET_RECORDINGS:
      return {
        ...state,
        all_recordings: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_SOURCE:
      return {
        ...state,
        lead_source: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AREA:
      return {
        ...state,
        areaList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBOS:
      return {
        ...state,
        phleboList: action.payload,
        loading: false,
      };
    case ACTIONS.SEARCH_CALL:
      return {
        ...state,
        search_results: action.payload,
        loading: false,
      };
    case ACTIONS.GET_POSTIVE_CALLS_COMMENTS:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.SET_COMMENTS:
      return {
        ...state,
        comment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_COMMENT:
      return {
        ...state,
        comments: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_POSTIVE_CALLS_COMMENT:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_DETAILS:
      return {
        ...state,
        lead_details: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SUB_CENTER_INFORMATION:
      return {
        ...state,
        subCenter: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEADS:
      return {
        ...state,
        leads: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_BY_ID:
      return {
        ...state,
        leadById: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_DETAILS_RESET:
      return {
        ...state,
        leadById: {},
        lead_details: {},
        leads: [],
        crmPostLeadAddress: [],
        communicationWhatsapp: [],
        loading: false,
      };
    case ACTIONS.BOOKING_RESET:
      return {
        ...state,
        createBooking: {},
        bookingList: [],
        redCoin: [],
        booking: {},
        booking_slots: {},
        loading: false,
        bookingIdLoader: false,
      };
    case ACTIONS.GET_AGENTS:
      return {
        ...state,
        agentsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGES:
      return {
        ...state,
        packageList: action.payload,
        loading: false,
      };
      case ACTIONS.GET_PACKAGES_TAGS:
        return {
          ...state,
          packageTags: action.payload,
          loading: false,
        };
        case ACTIONS.GET_PACKAGES_CATEGORY:
        return {
          ...state,
          packageCategory: action.payload,
          loading: false,
        };
    case ACTIONS.GET_FAMILY_PACKAGES:
      return {
        ...state,
        familyPackageList: action.payload,
        loading: false,
      };

    case ACTIONS.GET_FAMILY_PACKAGES_BOOKING:
      return {
        ...state,
        familyPackageBookingList: action.payload,
        loading: false,
      };
    case ACTIONS.POST_IMAGING_BOOKING_DISPOSITION:
      return {
        ...state,
        imaginBooking: action.payload,
        loading: false,
      };

    case ACTIONS.GET_PARTNERS:
      return {
        ...state,
        partnerList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COUPONS:
      return {
        ...state,
        couponList: action.payload,
        loading: false,
      };
    case ACTIONS.LOCATION_VERIFIED:
      return {
        ...state,
        locationVerified: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_BOOKING:
      return {
        ...state,
        createBooking: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_PILOT_BOOKING:
      return {
        ...state,
        createPilotBooking: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_IMAGING_BOOKING:
      return {
        ...state,
        createIMGBooking: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_WHATSAPP_TEMPLATE:
      return {
        ...state,
        createWhatsappTemplate: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_IMAGING_BOOKING_CUSTOM:
      return {
        ...state,
        createIMGBookingCustom: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_FAMILY_PACKAGES_BOOKING:
      return {
        ...state,
        createFamilyPackageBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_LIST:
      return {
        ...state,
        bookingList: action.payload,
        bookingListLoader: false,
      };
    case ACTIONS.GET_BOOKING_BY_ID:
      return {
        ...state,
        booking: action.payload,
        bookingIdLoader: false,
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.SET_LEAD_ADDRESS_LOADER:
      return {
        ...state,
        leadAddressLoading: action.payload,
      };
    case ACTIONS.SET_BOOKING_ID_LOADER:
      return {
        ...state,
        bookingIdLoader: action.payload,
      };
    case ACTIONS.SET_BIOMARKER_LOADER:
      return {
        ...state,
        biomarkerLoader: action.payload,
      };
    case ACTIONS.GET_BOOKINGS_COMMENTS:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_BOOKINGS_COMMENT:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
      case ACTIONS.GET_LEAD_DISPOSITION_LIST:
        return {
          ...state,
          dispositionList: action.payload,
          loading: false,
        };
    case ACTIONS.GET_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loadingWhatsapp: false,
      };
    case ACTIONS.SET_LOADING_WHATSAPP:
      return {
        ...state,
        loadingWhatsapp: action.payload,
      };
    case ACTIONS.SET_GEO_LOADING:
      return {
        ...state,
        geoLoading: action.payload,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS_BY_LEAD:
      return {
        ...state,
        whatsappCommentsByLead: action.payload,
        loading: false,
      };
    case ACTIONS.SEND_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CALL_STATS:
      return {
        ...state,
        callStats: action.payload,
        loading: false,
      };
    case ACTIONS.RAISE_COMPLAINT:
      return {
        ...state,
        complaint: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COMPLAINTS:
      return {
        ...state,
        complaints: action.payload,
        loading: false,
      };
    case ACTIONS.ASSIGN_DOCTOR:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.SEND_FEEDBACK:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.GET_AVAILABLE_SLOTS:
      return {
        ...state,
        booking_slots: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.SAME_NUMBER_BOOKING:
      return {
        ...state,
        sameNumberBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BREAK_RESPONSE:
      return {
        ...state,
        breakResponse: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_BREAK:
      return {
        ...state,
        panelBreak: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SEND_LOCATION_REQUEST:
      return {
        ...state,
        communicationWhatsapp: action.payload,
        loading: false,
      };
    case ACTIONS.SEND_LOCATION_REQUEST:
      return {
        ...state,
        sendLocationOnWhatsapp: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_SEND_LINK:
      return {
        ...state,
        paymentSendLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_RESEND_LINK:
      return {
        ...state,
        paymentReSendLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_CANCEL_LINK:
      return {
        ...state,
        paymentCancelLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_DETAILS:
      return {
        ...state,
        smsDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_EMAIL_DETAILS:
      return {
        ...state,
        emailDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_REPORT_SEND:
      return {
        ...state,
        smsReportSend: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTER_INFORMATION:
      return {
        ...state,
        centerInfo: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_LINK:
      return {
        ...state,
        sendSMSLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_FIREBASE_NOTIFICATION:
      return {
        ...state,
        notificationMessage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_STATUS:
      return {
        ...state,
        leadStatus: action.payload,
        loading: false,
      };

    case ACTIONS.GET_REPORT_STATUS:
      return {
        ...state,
        reportStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_UNMASKED_NUMBER:
      return {
        ...state,
        unmaskedNumber: action.payload,
        loading: false,
      };

    case ACTIONS.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LTV_LEAD:
      return {
        ...state,
        ltvLead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_DETAILS:
      return {
        ...state,
        labDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ZONE:
      return {
        ...state,
        zoneList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USER_DATA:
      return {
        ...state,
        usersData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_NOTIFICATION_TYPE:
      return {
        ...state,
        notificationType: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA:
      return {
        ...state,
        checkGeoFenceStatus: action.payload,
        loading: false,
      };
      case ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA_FOR_CUSTOMER_ADDRESS:
        return {
          ...state,
          geoFencesMapMyIndiaForCustomer: action.payload,
          // loading: false,
          geoLoadingforcustomer: false,
          geoCheckErrorForCustomer: "",
        };
        case ACTIONS.SET_GEO_LOADING_FOR_CUSTOMER:
          return {
            ...state,
            geoLoadingforcustomer: action.payload,
          };
          case ACTIONS.SET_GEO_ERROR_FOR_CUSTOMER:
            return {
              ...state,
              geoCheckErrorForCustomer: action.payload,
            };
    case ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA:
      return {
        ...state,
        geoFencesMapMyIndia: action.payload,
        geoLoading: false,
        geoCheckError:""
      };
    case ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN:
      return {
        ...state,
        mapmyindiaAccessToken: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG:
      return {
        ...state,
        addressDetailsLatLong: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_MESSAGE_STATUS:
      return {
        ...state,
        whatsappMessageStatus: action.payload,
        // loading: false,
      };
    case ACTIONS.GET_TODAYS_MISSED_CALLS:
      return {
        ...state,
        todayMissedCallsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPEATED_LEAD:
      return {
        ...state,
        repeatedLead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_DOWNLOAD:
      return {
        ...state,
        reportDownload: action.payload,
        loading: false,
      };
    case ACTIONS.GET_NOTIFICATION_MESSAGE:
      return {
        ...state,
        callNotification: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPEATED_LEADS:
      return {
        ...state,
        repetedLead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TIMESLOTS:
      return {
        ...state,
        timeslots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CRM_LEAD_ADDRESS:
      return {
        ...state,
        crmLeadAddress: action.payload,
        loading: false,
      };
    case ACTIONS.GET_IMAGING_TIMESLOTS:
      return {
        ...state,
        imagingTimeSlots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MULTIPLE_PACKAGES:
      return {
        ...state,
        multiplePackage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DISPLAY_CITIES:
      return {
        ...state,
        displayCities: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PRESCRIPTION_DATA:
      return {
        ...state,
        prescription: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DOCTOR_INFO_BY_ID:
      return {
        ...state,
        doctorInfo: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RED_TECH_COMMENT:
      return {
        ...state,
        redtechcomment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_IMAGING_TICKET:
      return {
        ...state,
        imagingTicket: action.payload,
        loading: false,
      };

    case ACTIONS.GET_IMAGING_RAISE_TICKET:
      return {
        ...state,
        imaginraiseTicket: action.payload,
        loading: false,
      };
    case ACTIONS.GET_IMAGING_TICKET_COUNT:
      return {
        ...state,
        imagingTicketcount: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_DATA:
      return {
        ...state,
        ticketData: action.payload,
        loading: false,
      };
      case ACTIONS.GET_NEW_BOOKING_LIST:
        return {
          ...state,
          newbookingList: action.payload,
          bookingListLoader: false,
        };
    case ACTIONS.GET_TICKET_CATEGORIES:
      return {
        ...state,
        ticketCategories: action.payload,
        loading: false,
      };

    case ACTIONS.GET_TICEKT_SUB_CATEGORIES:
      return {
        ...state,
        ticketSubCategories: action.payload,
        loading: false,
      };
    case ACTIONS.GET_HOLD_CANCEL_BOOKING:
      return {
        ...state,
        cancelHoldBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QUALITY_SCORING_REPORT:
      return {
        ...state,
        qualityScoringReport: action.payload,
        qualityScoreLoader: false,
      };
    case ACTIONS.GET_QUALITY_SCORING_REPORT_FOR_BACKUP:
      return {
        ...state,
        get_quality_scoring_report_for_backup: action.payload,
        qualityScoreBackupLoader: false,
      };
    case ACTIONS.GET_CE_CENTER_FOR_CC:
      return {
        ...state,
        ceCenterForCC: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_TEMPLATE:
      return {
        ...state,
        whatsappTemplate: action.payload,
        loading: false,
      };
      case ACTIONS.SET_GEO_LOADING:
        return {
          ...state,
          geoLoading: action.payload,
        };
      case ACTIONS.SET_GEO_CHECK_ERROR:
        return {
          ...state,
          geoCheckError: action.payload,
        };
    case ACTIONS.GET_BOOKING_COMPLAINTS:
        return {
            ...state,
            bookingComplaints: action.payload,
            csTicketLoading: false,
        };
    case ACTIONS.UPDATE_DISPOSITION:
      return {
          ...state,
          disposition: action.payload,
          loading: false,
      };
    case ACTIONS.GET_TICKET_COMMENTS:
      return {
          ...state,
          ticketComments: action.payload,
          loading: false,
      };
    case ACTIONS.GET_BIOMARKER_SCREENING:
      return {
        ...state,
        biomarkerScreening: action.payload,
        biomarkerLoader: false,
      };
    case ACTIONS.GET_BIOMARKER_ULTASOUND:
      return {
        ...state,
        biomarkerUltasound: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDITIONAL_MEMBER:
      return {
        ...state,
        markerAdditionMember: action.payload,
        loading: false,
      };
    case ACTIONS.POST_CRM_LEAD_ADDRESS:
      return {
        ...state,
        crmPostLeadAddress: action.payload,
        leadAddressLoading: false,
      };
      case ACTIONS.BIOMARKER_RESET:
        return {
          ...state,
          biomarkerScreening: {},
        };
    default:
      return state;
  }
};

export default BookingReducer;
