import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, TextField, Grid, Select, MenuItem } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { updatePhleboLocation,
  checkGeoFenceArea,
   checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken
 } from "../../actions/salesLeadAdminAction";
 import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import Loader from "../loader";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { GoogleMapsAPI } from "../../../helpers/client-config";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "800px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));

type ModalProps = {
  openEditPhlebo: boolean;
  editPhleboUser: any;
  updatePhleboLocation: any;
  setOpenEditPhlebo: Function;
  loading: Boolean;
  getPhlebos:any;
  checkGeoFenchMapMyIndia:any;
  geoFencesMapMyIndia:any;
  mapmyindiaAccessToken:any;
  getMapMyIndiaAccessToken:any
};

const EditPheleboLocation: React.FC<ModalProps> = ({
  openEditPhlebo,
  setOpenEditPhlebo,
  editPhleboUser,
  updatePhleboLocation,
  getPhlebos,
  loading,
  checkGeoFenchMapMyIndia,
  geoFencesMapMyIndia,
  mapmyindiaAccessToken,
  getMapMyIndiaAccessToken
}) => {
  const history = useHistory();
  const classes = useStyles();

  const [currentLocation, setCurrentLocation] = useState<any>(null);
  const [location, setLocation] = useState<any>("");
  const [currentLongitude, setCurrentLongitude] = useState<any>("");
  const [currentLatitude, setCurrentLatitude] = useState<any>("");
  useEffect(()=>{
    getMapMyIndiaAccessToken();
  },[])

  const handleClose = () => {
    setOpenEditPhlebo(false);
  };

  const editPheleboLocation = async () => {
    const body: any = {
      longitude: currentLongitude,
      latitude: currentLatitude,
      id: editPhleboUser?.id,
      location: location,
    };
    await updatePhleboLocation(body);
    getPhlebos()
    setOpenEditPhlebo(false);
  };

  useEffect(() => {
    setCurrentLocation(currentLocation ? currentLocation?.label : "");
    if (currentLocation?.label) {
      setLocation(currentLocation?.label)
      geocodeByAddress(currentLocation?.label)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          lat && setCurrentLatitude(lat);
          lng && setCurrentLongitude(lng);
        });
    }
  }, [currentLocation]);

  //mapmyindia

  const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
  const [mapAddressObject, setMapAddressObject] = useState<any>({});
  const [accessToken, setAccessToken] = useState<any>("");

  useEffect(() => {
    if(geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude){
      setCurrentLatitude(geoFencesMapMyIndia?.latitude)
      setCurrentLongitude(geoFencesMapMyIndia?.longitude)
    }
  }, [geoFencesMapMyIndia]);

  useEffect(() => {
    if(mapmyindiaAccessToken?.accesstoken){
      setAccessToken(mapmyindiaAccessToken?.accesstoken)
    }
  }, [mapmyindiaAccessToken]);

  useEffect(() => {
    if (mapAddressObject?.eLoc) {
      setLocation(mapAddressObject?.placeAddress);
      checkGeoFenchMapMyIndia(mapAddressObject?.eLoc, "NA")
    }
  }, [mapAddressObject]);

  const locationAutoCompleteSearch = (val: string) => {
    if (val.length > 2) {
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName = "jsonp_callback_" + "data" + id;
        window[callbackName] = (data: any) => {
          delete window[callbackName];
          const ele = document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          const suggestedData = data?.suggestedLocations
          if (suggestedData.length > 0){
            setSuggestedAddres(data?.suggestedLocations);
          }
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          document.getElementsByTagName("head")[0] ||
          document.body ||
          document.documentElement
        ).appendChild(script);
      });
    } 
  };
  //mapmyindia

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openEditPhlebo}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Fade in={openEditPhlebo}>
            <div className={classes.paper}>
              <div className={classes.head}>
                <h4
                  style={{
                    marginBottom: "1rem",
                  }}
                  id="transition-modal-title"
                >
                  Set Phlebo Home Location
                </h4>
                <CloseIcon onClick={() => handleClose()} />
              </div>

              <Grid
                container
                direction="row"
                alignItems="flex-start"
                justify="space-between"
                spacing={3}
                style={{ margin: "1rem auto" }}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <h6>Name</h6>
                  <TextField
                    name="name"
                    type="text"
                    value={editPhleboUser?.user?.name}
                    className="input"
                    variant="outlined"
                    placeholder="Phlebo Name"
                    disabled={true}
                    style={{
                      width: "100%",
                      minWidth: "200px",
                      background: "#ccc",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  <h6>Select Home Location</h6>
                  {/* <GooglePlacesAutocomplete
                    apiKey={GoogleMapsAPI}
                    debounce={500}
                    selectProps={{
                      currentLocation,
                      onChange: setCurrentLocation,
                    }}
                  /> */}
                   <Autocomplete
                    id="colony-area-sector"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setMapAddressObject(obj);
                      }
                    }}
                    options={suggestedAddress ? suggestedAddress : []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    getOptionLabel={(option: any) => `${option?.placeName}, ${option?.placeAddress}`}
                    filterOptions={(option: any) => option}
                    onInputChange={(event, newInputValue) => {
                      locationAutoCompleteSearch(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Search Address"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ height: "50px" }}
                    onClick={editPheleboLocation}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Fade>
        )}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  phleboPhone: state.SalesLeadAdminReducer.phleboPhone,
  loading: state.SalesLeadAdminReducer.loading,
  geoFencesMapMyIndia: state.SalesLeadAdminReducer.geoFencesMapMyIndia,
  mapmyindiaAccessToken: state.SalesLeadAdminReducer.mapmyindiaAccessToken,
  checkGeoFenceStatus: state.SalesLeadAdminReducer.checkGeoFenceStatus,
});

export default connect(mapStateToProps, {
  updatePhleboLocation,
  checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken
})(EditPheleboLocation);
