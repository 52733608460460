import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, TextField, Button } from "@material-ui/core";
import "./BookingPage.sass";
import {getUserDetails} from '../../actions/CollectionCenterAdmin'
import NewBooking from "./NewBooking/NewBooking";
import ImagineBooking from './NewBooking/ImagingBooking'
import { connect } from "react-redux";
import ImagineNewBooking from './NewBooking/ImagingNewBooking'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "100%",
      padding: "0px 0px 10px 0px",
      marginTop: "40px",
      minHeight: "300px",
    },
  })
);

interface Props {
  getUserDetails:any;
  lead:any;
  userDetails:any
 }

 const BookingPage: React.FC<Props> = ({
  getUserDetails,
  userDetails,
  lead,
}) => {
  const classes = useStyles();
  const [bookingType, setBookingType] = useState<any>("homedx");
  const [newBooking, setNewBooking] = useState<Boolean>(true);

  const homedx = () => {
    setNewBooking(true)
    setBookingType("homedx")
  }
  const imaging = () => {
    setNewBooking(false)
    setBookingType("imaging")
  }
useEffect(()=>{
    getUserDetails("")
},[])

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12} sm={4} md={3}>
            <Button
              variant="contained"
              style={{ marginLeft: "1rem", marginBottom: "1rem", background:bookingType==="homedx" ? "#ffeb3b" : "" }}
              onClick={() => setBookingType("homedx")}
              fullWidth
            >
              HOME DX
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Button
              variant="contained"
              style={{ marginLeft: "1rem", marginBottom: "1rem", background: bookingType==="imaging" ? "#ffeb3b" : "" }}
              onClick={() => setBookingType("imaging")}
              fullWidth
            >
              {userDetails?.profile?.executive_type === "BML"?"Radiology + Pathology":"IMAGING"} 
            </Button>
          </Grid>
         
          {/* <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              style={{ marginLeft: "1rem", marginBottom: "1rem", background: bookingType==="genomics" ? "#ffeb3b" : "" }}
              onClick={() => setBookingType("genomics")}
              fullWidth
            >
              Genomics
            </Button>
          </Grid> */}
          <Grid item xs={12} sm={6} md={6}>
            <h2  style={{ marginLeft: "1rem", marginBottom: "1rem"}}>
              {bookingType==="homedx" ? "HOME-DX BOOKING" :bookingType==="imaging" ? "IMAGING BOOKING":"GENOMICS BOOKING"}
            </h2>
            
          </Grid>
        </Grid>
        <div className={classes.contentTable}>
        {userDetails?.profile?.executive_type !== "BML"
          ? (
            <>
        {bookingType==="homedx" ?  <NewBooking lead={lead} /> :bookingType==="imaging" ? <ImagineBooking />:""}
        </>
        ) : 
        <>
         {bookingType==="homedx" ?  <NewBooking lead={lead} /> :bookingType==="imaging" ? <ImagineNewBooking />:""}
        </>}
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userDetails: state.CollectionCenterAdminReducer.userDetails,
});
export default connect(mapStateToProps, {
  getUserDetails
})(BookingPage);