import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, TextField, MenuItem, Select } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getAnalyticsRecording,
  initiateRefund,
  updateInitiateRefund,
  getPhlebos,
  getLabCredential,
} from "../../actions/salesLeadAdminAction";
import Button from '@mui/material/Button';
import Loader from "../../Components/loader";
import AnalyticsRecordingTable from "../../tables/analyticsRecording";
import moment from "moment";
let width = window.innerWidth;
let isMobile = width <= 500;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: isMobile ? "400px" : "800px",
    },
  })
);

interface Props {
  loading: Boolean;
  getAnalyticsRecording: any;
  analyticsRecording: any;
  initiateRefund: any;
  updateInitiateRefund: any;
  getPhlebos: any;
  phleboList: any;
  getLabCredential: any;
  labCredential: any;
}

const AnalyticsRecording: React.FC<Props> = ({
  loading,
  getAnalyticsRecording,
  analyticsRecording,
  initiateRefund,
  updateInitiateRefund,
  getPhlebos,
  phleboList,
  getLabCredential,
  labCredential,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0)
  const [status, setStatus] = useState<any>("none");

  useEffect(() => {
    getAnalyticsRecording();
  }, []);



  const filterAnalatics = () => {
    if (status === "hot") getAnalyticsRecording("?hot=true")
    else if (status === "cold") getAnalyticsRecording("?cold=true")
    else if (status === "both") getAnalyticsRecording("")
    else getAnalyticsRecording("")
  };

  var currentDateObj = new Date();
  var numberOfMlSeconds = currentDateObj.getTime();
  var subMlSeconds = 60 * 90 * 1000;
  var newDateObj = new Date(numberOfMlSeconds - subMlSeconds);

  let lastUpdatedTime = moment(newDateObj).format('MMMM Do YYYY, h:mm:ss a');

  return (
    <>
      <main className={classes.content}>
        <div style={{ width: "99%" }}>
          <div className={classes.toolbar} />
          <h3 style={{ fontWeight: "bold" }}>  Analytics Recording</h3>
          <Grid
            container
            spacing={2}
            direction="row"
          >

            <Grid item xs={12} sm={4} md={2}>
              <Select className="input"
                name="status"
                variant="outlined"
                value={status}
                style={{ width: "100%", height: "40px" }}
                onChange={(e) => setStatus(e.target.value as string)}
              >
                <MenuItem value={"none"} disabled>Call Type</MenuItem>
                <MenuItem value={"both"}>Both</MenuItem>
                <MenuItem value={"hot"}>Hot Call</MenuItem>
                <MenuItem value={"cold"}>Cold Call</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
              <Button
                variant="contained"
                color="secondary"

                fullWidth
                onClick={filterAnalatics}
              >
                Filter
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Button
                variant="contained"
                color="primary"

                fullWidth
                onClick={() => history.push("/dashboard/sla/analytics-recording")}
              >
                Reset
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} >
              <span></span>
              <div style={{ display: "flex", justifyContent: "space-between",marginRight:"10px" }}>

                <h5 style={{ paddingTop: "10px" }}>Last Updated At: {lastUpdatedTime}</h5>

              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.toolbar} />


        <AnalyticsRecordingTable
          loading={loading}
          analyticsRecording={analyticsRecording}
          getAnalyticsRecording={getAnalyticsRecording}
        />
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loading: state.SalesLeadAdminReducer.loading,
    analyticsRecording: state.SalesLeadAdminReducer.analyticsRecording,
    phleboList: state.SalesLeadAdminReducer.phleboList,
    labCredential: state.SalesLeadAdminReducer.labCredential,
  };
};

export default connect(mapStateToProps, {
  getAnalyticsRecording,
  initiateRefund,
  updateInitiateRefund,
  getPhlebos,
  getLabCredential,
})(AnalyticsRecording);