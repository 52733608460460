import React, { useState,useEffect } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import "./BookingPage.sass"
import NewBooking from './NewBooking/NewBooking';
import EditBooking from './EditBooking';
import ImagineBooking from './NewBooking/ImagingBooking'
import { Container,Grid, TextField, Button } from "@material-ui/core";
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AdminBooking from './NewBooking/AdminNewBooking'


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "100%",
            flexGrow: 21,
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        contentTable: {
            width: "100%",
            padding: "0px 0px 10px 0px",
            marginTop: "40px",
            minHeight: "300px",
          },
    })
);


interface Props {
lead:any;
}

const BookingPage: React.FC<Props> = (
  lead,
) => {
    const classes = useStyles();

    const [newBooking, setNewBooking] = useState<Boolean>(true)
    const [bookingType, setBookingType] = useState<any>("homedx");
    const [value, setValue] = React.useState(0);
    const [leadId, setLeadId] = useState<string>("");
    useEffect(() => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const org_type = params.get("org_type");
      const lead = params.get("lead");
      if (org_type==="imagine") {
        setValue(1);
      }
      if (lead) {
        setLeadId(lead)
      }
     
    }, [window.location.search]);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    return (
        <>
            <main className={classes.content}>
        <div className={classes.toolbar} />
        <Box sx={{ width: '100%', bgcolor: 'background.paper', }}>
          <Tabs value={value} onChange={handleChange} centered>
            <Tab style={{background:value===0?"#ffeb3b":"#fffdfc",color:"black"}}  label="HOME-DX BOOKING" />
            {/* <Tab style={{background:value===1?"#ffeb3b":"#fffdfc",color:"black"}} label="ADMIN HOME-DX BOOKING" /> */}
            {/* <Tab style={{background:value===2?"#ffeb3b":"#fffdfc",color:"black"}} label="IMAGING BOOKING" /> */}
            
          </Tabs>
        </Box>
        {/* <Grid
          container
          spacing={3}
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12} sm={6} md={3}>
            <Button
              variant="contained"
              style={{ marginLeft: "1rem", marginBottom: "1rem", background: newBooking ? "#ffeb3b" : "" }}
              onClick={() => setNewBooking(true)}
              fullWidth
            >
              HOME DX
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              variant="contained"
              style={{ marginLeft: "1rem", marginBottom: "1rem", background: !newBooking ? "#ffeb3b" : "" }}
              onClick={() => setNewBooking(false)}
              fullWidth
            >
              IMAGING
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <h2  style={{ marginLeft: "1rem", marginBottom: "1rem"}}>
              {newBooking ? "HOME DX BOOKING PAGE" : "IMAGING BOOKING PAGE"}
            </h2>
            
          </Grid>
        </Grid> */}
        <div className={classes.contentTable}>
        {value===0?<NewBooking lead={leadId} /> :value===1?<AdminBooking lead={leadId}/>:<ImagineBooking lead={leadId}   />}
          {/* {newBooking ? <NewBooking lead={leadId}/> : <ImagineBooking />} */}
        </div>
      </main>
        </>
    )
}

export default BookingPage
