import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import barcode from "../../images/barcode.png";

import CloseIcon from '@material-ui/icons/Close';
import Modal from '@mui/material/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button, Paper } from '@material-ui/core';
import ReactToPrint from "react-to-print";
import moment from "moment";
import Loader from '../loader';
import { getPaymentInfo } from '../../actions/CollectionCenter'
let width = window.innerWidth;


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: "auto",
    padding: "0.2rem ",
    width: "80%",
    marginLeft: "10%"
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  table: {
    maxWidth: "70%",
    margin: "auto"
  },
  scrollComponent: {
    width: "95%",
    height: "100%",
    overflowY: "auto",
  },
  toolbar: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  listStyle: {
    marginLeft: "15px",
  }
}));


type PropsCR = {
  loading: Boolean;
  bookingId: any;
  openInvoiceModel: boolean;
  setOpenInvoiceModel: Function;
  booking: any;
  getPaymentInfo: any
  paymentInformation: any;

};

const CashRecipt: React.FC<PropsCR> = ({
  openInvoiceModel,
  bookingId,
  setOpenInvoiceModel,
  booking,
  getPaymentInfo,
  paymentInformation,
  loading,

}) => {
  const classes = useStyles();
  const history = useHistory();

  const { id } = useParams<{ id: string }>();
  const handleClose = () => {
    setOpenInvoiceModel(false);
  };
  const [paid, setPaid] = useState<any>("");
  const [addPkg, setAddPkg] = useState<any>([]);
  const [open, setOpen] = React.useState<boolean>();
  const totalAmount: any = booking.discounted_price &&
    booking.discounted_price.final_total_price &&
    Math.floor(
      booking.discounted_price.final_total_price
    ) - Math.floor(booking.redcash_discounted_amount)
  let prerequisitesLoaded = false;

  const componentRef = useRef<any>(null);
  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const reactToPrintTrigger = React.useCallback(() => {
    return <button style={{ marginLeft: "10px" }}>Print Receipt</button>;
  }, []);
  const handleBack = () => {
    localStorage.removeItem("addonPrice");
    history.push(`/dashboard/cc/booking-view/${bookingId}`);
  };

  const onHandleClose = () => {
    // setOpen(false);
    history.push(`/dashboard/cc/booking-view/${bookingId}`);
  };
  useEffect(() => {
    if (booking.pk) {
      getPaymentInfo(`?booking=${booking.pk}`);
    }
  }, [booking])

  const amountToWordConverter = (number: any) => {
    const first: any = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen '];
    const tens: any = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
    const mad: any = ['', 'thousand', 'million', 'billion', 'trillion'];
    let word: any = '';
    for (let i = 0; i < mad.length; i++) {
      let tempNumber = number % (100 * Math.pow(1000, i));
      if (Math.floor(tempNumber / Math.pow(1000, i)) !== 0) {
        if (Math.floor(tempNumber / Math.pow(1000, i)) < 20) {
          word = first[Math.floor(tempNumber / Math.pow(1000, i))] + mad[i] + ' ' + word;
        } else {
          word = tens[Math.floor(tempNumber / (10 * Math.pow(1000, i)))] + '-' + first[Math.floor(tempNumber / Math.pow(1000, i)) % 10] + mad[i] + ' ' + word;
        }
      }

      tempNumber = number % (Math.pow(1000, i + 1));
      if (Math.floor(tempNumber / (100 * Math.pow(1000, i))) !== 0) word = first[Math.floor(tempNumber / (100 * Math.pow(1000, i)))] + 'hunderd ' + word;
    }
    return word;
  }
  

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openInvoiceModel}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openInvoiceModel}>
          <div className={classes.scrollComponent}>

            <Paper elevation={15} className={classes.paper} >
              <>
                <button
                  style={{
                    width: "100px",
                    borderRadius: "5%",
                    backgroundColor: "#ef5350",
                    color: "white",
                    marginLeft: "30px",
                    marginBottom: "11px",
                  }}
                  onClick={handleBack}
                >
                  {"<< Go Back"}
                </button>
                <ReactToPrint
                  content={reactToPrintContent}
                  trigger={reactToPrintTrigger}
                />

                <Button onClick={onHandleClose}  >
                  <CloseIcon style={{ marginLeft: "410px", color: "#ef5350", display: "flex" }} />
                </Button>
                <div
                  ref={componentRef}
                  style={{
                    margin: 20,
                  }}
                >
                  <meta charSet="UTF-8" style={{ fontFamily: "sans-serif" }} ref={componentRef} />
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                    style={{ fontFamily: "sans-serif" }}
                  />
                  <title style={{ fontFamily: "sans-serif" }}>Invoice</title>
                  <div
                    className="invoiceContainer"
                    style={{ fontFamily: "sans-serif", marginTop: 0 }}
                  >
                    <div id="divToPrint" style={{ fontFamily: "sans-serif" }}>
                      <hr
                        className="headerLine"
                        style={{
                          border: "5px solid #275279",
                          fontFamily: "sans-serif",
                          width: "98%"
                        }}
                      />
                      <div
                        className="logoContainer"
                        style={{
                          alignItems: "center",
                          marginTop: "1rem",
                          paddingLeft: "2rem",
                          paddingRight: "2rem"
                        }}
                      >
                        <img
                          className="invoiceImage"
                          src="https://staticredcliffelabs.s3.amazonaws.com/media/gallary-file/None/2f0ad441-1bf2-47f1-9cd8-0ecf0d06217b.png"
                          style={{ fontFamily: "sans-serif", height: 60 }}
                        />
                        <span
                          className="invoiceHeading"
                          style={{
                            float: "right",
                            fontFamily: "sans-serif",
                            fontSize: 27,
                            fontWeight: 700
                          }}
                        >
                          Invoice
                        </span>
                      </div>
                      <hr style={{ fontFamily: "sans-serif" }} />
                      <div className="invoiceGrid" style={{ marginTop: "-8px" }}>
                        <div
                          className="invoiceGridItem"
                          style={{
                            float: "left",
                            width: "17%",
                            border: "2px solid rgba(214, 209, 209, 0.8)",
                            borderBottom: "none",
                            borderLeft: "none",
                            borderTop: "none",
                            fontFamily: "sans-serif",
                            fontSize: 14,
                            fontWeight: 400,
                            paddingTop: 6,
                            textAlign: "center"
                          }}
                        >
                          INVOICE NO.
                          <div
                            className="invoiceGridValue"
                            style={{
                              fontFamily: "sans-serif",
                              fontSize: 16,
                              fontWeight: 600,
                              padding: "1rem"
                            }}
                          >
                            {new Date().getFullYear().toString() + booking.pk}
                          </div>
                        </div>
                        <div
                          className="invoiceGridItem"
                          style={{
                            float: "left",
                            width: "20%",
                            border: "2px solid rgba(214, 209, 209, 0.8)",
                            borderBottom: "none",
                            borderLeft: "none",
                            borderTop: "none",
                            fontFamily: "sans-serif",
                            fontSize: 14,
                            fontWeight: 400,
                            paddingTop: 6,
                            textAlign: "center"
                          }}
                        >
                          INVOICE DATE
                          <div
                            className="invoiceGridValue"
                            style={{
                              fontFamily: "sans-serif",
                              fontSize: 16,
                              fontWeight: 600,
                              padding: "1rem"
                            }}
                          >
                            {booking?.collection_date}
                          </div>
                        </div>
                        <div
                          className="invoiceGridItem"
                          style={{
                            float: "left",
                            width: "18%",
                            border: "2px solid rgba(214, 209, 209, 0.8)",
                            borderBottom: "none",
                            borderLeft: "none",
                            borderTop: "none",
                            fontFamily: "sans-serif",
                            fontSize: 14,
                            fontWeight: 400,
                            paddingTop: 6,
                            textAlign: "center"
                          }}
                        >
                          BOOKING ID
                          <div
                            className="invoiceGridValue"
                            style={{
                              fontFamily: "sans-serif",
                              fontSize: 16,
                              fontWeight: 600,
                              padding: "1rem"
                            }}
                          >
                            {booking?.pk}
                          </div>
                        </div>
                        <div
                          className="invoiceGridItem"
                          style={{
                            float: "left",
                            width: "18%",
                            border: "2px solid rgba(214, 209, 209, 0.8)",
                            borderBottom: "none",
                            borderLeft: "none",
                            borderTop: "none",
                            fontFamily: "sans-serif",
                            fontSize: 14,
                            fontWeight: 400,
                            paddingTop: 6,
                            textAlign: "center"
                          }}
                        >
                          TEST PERFORM DATE
                          <div
                            className="invoiceGridValue"
                            style={{
                              fontFamily: "sans-serif",
                              fontSize: "16xpx",
                              fontWeight: 600,
                              padding: "1rem"
                            }}
                          >
                            {booking?.collection_date}
                          </div>
                        </div>
                        <div
                          className="invoiceGridItem invoiceGridItemLast"
                          style={{
                            float: "left",
                            width: "15%",
                            border: "2px solid rgba(214, 209, 209, 0.8)",
                            borderBottom: "none",
                            borderLeft: "none",
                            borderRight: "none",
                            borderTop: "none",
                            fontFamily: "sans-serif",
                            fontSize: 14,
                            fontWeight: 400,
                            paddingTop: 6,
                            textAlign: "center"
                          }}
                        >
                          SAC CODE
                          <div
                            className="invoiceGridValue"
                            style={{
                              fontFamily: "sans-serif",
                              fontSize: 16,
                              fontWeight: 600,
                              padding: "1rem"
                            }}
                          >
                            999316
                          </div>
                        </div>
                      </div>
                      <hr style={{ clear: "left", fontFamily: "sans-serif" }} />
                      <div
                        className="invoiceBill"
                        style={{
                          clear: "left",
                          backgroundColor: "#f2fcff",
                          fontFamily: "sans-serif",
                          paddingTop: "2rem",
                          paddingBottom: "2rem",
                          paddingLeft: "1rem",
                          paddingRight: "1rem",
                          height: "10rem"
                        }}
                      >
                        <div
                          className="billForm"
                          style={{
                            float: "left",
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            width: "60%"
                          }}
                        >
                          <div
                            className="billFormHeading"
                            style={{
                              color: "#11406b",
                              fontFamily: "sans-serif",
                              fontWeight: 600,
                              paddingBottom: "1.5rem"
                            }}
                          >
                            BILL FROM
                          </div>
                          <div
                            className="billFormContent"
                            style={{ fontFamily: "sans-serif", padding: "0.5rem" }}
                          >
                            CIN Number:-{" "}
                            <strong style={{ fontFamily: "sans-serif" }}>
                              U85100UP2021PTC153514
                            </strong>
                          </div>
                          <div
                            className="billFormContent"
                            style={{ fontFamily: "sans-serif", padding: "0.5rem" }}
                          >
                            <strong style={{ fontFamily: "sans-serif" }}>
                              H-55, 1ST FLOOR, SECTOR-63, NOIDA, Gautam Buddha Nagar, Uttar
                              Pradesh, India, 201301
                            </strong>
                          </div>
                          <div
                            className="billFormContent"
                            style={{ fontFamily: "sans-serif", padding: "0.5rem" }}
                          >
                            <strong style={{ fontFamily: "sans-serif" }}>
                              Redcliffe Life Images Private Limited
                            </strong>
                          </div>
                        </div>
                        <div
                          className="billForm"
                          style={{
                            float: "right",
                            width: "35%",
                            fontFamily: "sans-serif",
                            fontSize: 15
                          }}
                        >
                          <div
                            className="billFormHeading"
                            style={{
                              color: "#11406b",
                              fontFamily: "sans-serif",
                              fontWeight: 600,
                              paddingBottom: "1.5rem"
                            }}
                          >
                            CUSTOMER DETAILS
                          </div>
                          <div style={{ fontFamily: "sans-serif" }}>
                            <strong style={{ fontFamily: "sans-serif" }}>
                              {booking?.customer_name}
                              {booking?.additional_members && booking?.additional_members.length > 0 && booking?.additional_members?.map((data: any) => {
                                return (
                                  <>
                                    <span>&nbsp;{data?.customer_name}</span>
                                  </>
                                )

                              })}
                              <br />


                            </strong>
                            <span>{booking?.booking_full_address}</span>
                          </div>
                        </div>
                      </div>
                      <hr style={{ clear: "left", fontFamily: "sans-serif" }} />
                      <div style={{ fontFamily: "sans-serif" }}>
                        <div
                          className="invoiceTestDescHead"
                          style={{
                            backgroundColor: "#f4f4f4",
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            fontWeight: 600,
                            marginTop: ".5rem",
                            padding: ".7rem",
                            paddingBottom: "2rem"
                          }}
                        >
                          <div style={{ float: "left", fontFamily: "sans-serif" }}>
                            TEST DESCRIPTION
                          </div>
                          <div style={{ float: "right", fontFamily: "sans-serif" }}>AMOUNT</div>
                        </div>
                        <div
                          className="billDetailsName"
                          style={{ fontFamily: "sans-serif", fontSize: 15, fontWeight: 600 }}
                        >
                          <p
                            style={{
                              color: "#11406b",
                              fontFamily: "sans-serif",
                              paddingLeft: "2rem"
                            }}
                          >
                            {booking?.description}
                            {booking.additional_members && booking.additional_members.length > 0 && booking.additional_members.map((data: any) => {
                              return (
                                <>
                                  <span>&nbsp;{data?.customer_name}</span>
                                </>
                              )

                            })}
                          </p>
                          <hr style={{ fontFamily: "sans-serif" }} />
                        </div>
                        {booking?.packages && booking?.packages.length > 0 && booking?.packages.map((pack: any) => {
                          return (
                            <div
                              className="billDetailsTests"
                              style={{
                                color: "black !important",
                                fontFamily: "sans-serif",
                                fontSize: 15,
                                padding: "0.5rem",
                                paddingRight: "1rem",
                                paddingLeft: "2rem !important"
                              }}
                            >
                              {/* <div > */}
                                 <strong style={{ fontFamily: "sans-serif" }}> 
                                 
                                  {/* <p style={{ fontFamily: "sans-serif" }}> */}
                                  {pack?.name}
                                  {/* </p> */}
                                </strong> 
                              {/* </div> */}
                              <div style={{ float: "right", fontFamily: "sans-serif" }}>
                                <strong style={{ fontFamily: "sans-serif" }}>
                                  ₹{pack.package_center_address_prices
                                    ? pack
                                      .package_center_address_prices
                                      .offer_price
                                    : pack.offer_price}
                                </strong>
                              </div>
                            </div>
                          )
                        })}
                        {booking?.additional_members && booking?.additional_members.length > 0 && booking?.additional_members?.map((addn: any) => {
                          return (addn?.packages && addn?.packages?.length > 0 && addn?.packages?.map((pack: any) => {
                            return (
                              <div
                                className="billDetailsTests"
                                style={{
                                  color: "black !important",
                                  fontFamily: "sans-serif",
                                  fontSize: 15,
                                  padding: "0.5rem",
                                  paddingRight: "1rem",
                                  paddingLeft: "2rem !important"
                                }}
                              >
                                {/* <div style={{ float: "left", fontFamily: "sans-serif" }}> */}
                                  <strong style={{ fontFamily: "sans-serif" }}>
                                    {pack.name}
                                  </strong>
                                {/* </div> */}
                                <div style={{ float: "right", fontFamily: "sans-serif" }}>
                                  <strong style={{ fontFamily: "sans-serif" }}>
                                    ₹{pack.package_center_address_prices
                                      ? pack
                                        .package_center_address_prices
                                        .offer_price
                                      : pack.offer_price}
                                  </strong>
                                </div>
                              </div>
                            )
                          }))

                        })}
                       
                        <div
                          className="invoiceTestDescTotal"
                          style={{
                            backgroundColor: "#fbfbfb",
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            padding: "1rem",
                            paddingBottom: "1.6rem"
                          }}
                        >
                          <div style={{ float: "left", fontFamily: "sans-serif" }}>
                            Discount
                          </div>
                          <div style={{ float: "right", fontFamily: "sans-serif" }}>
                            ₹   {booking.discounted_price &&
                              booking.discounted_price.counpon_discount &&
                              Math.floor(
                                booking.discounted_price.counpon_discount
                              )}
                          </div>
                        </div>
                        {/* <div
                          className="invoiceTestDescTotal"
                          style={{
                            backgroundColor: "#fbfbfb",
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            padding: "1rem",
                            paddingBottom: "1.6rem"
                          }}
                        >
                          <div style={{ float: "left", fontFamily: "sans-serif" }}>
                            Gift Card
                          </div>
                          <div style={{ float: "right", fontFamily: "sans-serif" }}>
                            ₹   {booking.discounted_price &&
                              booking.discounted_price.counpon_discount &&
                              Math.floor(
                                booking.discounted_price.counpon_discount
                              )}
                          </div>
                        </div>
                        <div
                          className="invoiceTestDescTotal"
                          style={{
                            backgroundColor: "#fbfbfb",
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            padding: "1rem",
                            paddingBottom: "1.6rem"
                          }}
                        >
                          <div style={{ float: "left", fontFamily: "sans-serif" }}>
                            Red Cash
                          </div>
                          <div style={{ float: "right", fontFamily: "sans-serif" }}>
                            ₹   {booking.discounted_price &&
                              booking.discounted_price.counpon_discount &&
                              Math.floor(
                                booking.discounted_price.counpon_discount
                              )}
                          </div>
                        </div> */}
                        <div
                          className="invoiceTestDescTotal"
                          style={{
                            backgroundColor: "#fbfbfb",
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            marginTop: ".5rem",
                            padding: "1rem",
                            paddingBottom: "1.2rem"
                          }}
                        >
                          <div style={{ float: "left", fontFamily: "sans-serif" }}>
                            Total Amount
                          </div>
                          <div style={{ float: "right", fontFamily: "sans-serif" }}>
                            ₹ {booking.discounted_price &&
                              booking.discounted_price.final_total_price &&
                              Math.floor(
                                booking.discounted_price.final_total_price
                              ) - Math.floor(booking.redcash_discounted_amount)- Math.floor(booking.giftcard_discounted_amount)}
                          </div>
                        </div>
                        <hr style={{ fontFamily: "sans-serif" }} />
                        {/* <div
                          className="invoiceTestDescTotal1"
                          style={{
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            fontWeight: 600,
                            padding: "0.5rem",
                            paddingBottom: "1.6rem"
                          }}
                        >
                          <div style={{ float: "left", fontFamily: "sans-serif" }}>
                            Grand Total
                          </div>
                          <div style={{ float: "right", fontFamily: "sans-serif" }}>
                            ₹ {booking.discounted_price &&
                              booking.discounted_price.final_total_price &&
                              Math.floor(
                                booking.discounted_price.final_total_price
                              ) - Math.floor(booking.redcash_discounted_amount)}
                          </div>
                        </div> */}
                        <div
                          className="invoiceTestDescTotal2"
                          style={{
                            backgroundColor: "#f4f4f4",
                            fontFamily: "sans-serif",
                            fontSize: 15,
                            fontWeight: 600,
                            padding: ".5rem",
                            paddingBottom: "2rem"
                          }}
                        >
                          <div style={{ float: "left", fontFamily: "sans-serif" }}>
                            Paid Amount
                          </div>
                          <div style={{ float: "right", fontFamily: "sans-serif" }}>
                          ₹{booking.discounted_price &&
                                  booking.discounted_price.final_total_price &&
                                  Math.floor( booking.discounted_price.final_total_price ) - Math.floor(booking.redcash_discounted_amount)- Math.floor(booking?.giftcard_discounted_amount)}
                          </div>
                        </div>
                      </div>
                      <div
                        className="invoiceSummary"
                        style={{
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          lineHeight: "1.4",
                          marginTop: "1.4rem",
                          paddingLeft: "1rem",
                          paddingRight: "1rem",
                          marginBottom: "10rem"
                        }}
                      >
                        <div style={{ float: "left", fontFamily: "sans-serif" }}>
                          <strong style={{ fontFamily: "sans-serif" }}>
                            Amount Chargable (in words)
                          </strong>
                          <div style={{ fontFamily: "sans-serif" }}>
                            <i style={{ fontFamily: "sans-serif" }}>
                              Rupees {amountToWordConverter(totalAmount)}
                              Only
                            </i>
                          </div>
                          <div
                            className="blueColor"
                            style={{ color: "#11406b", fontFamily: "sans-serif", fontSize: 14 }}
                          >
                            Company's PAN No.{" "}
                            <span style={{ fontFamily: "sans-serif" }}>AALCR3475L</span>
                          </div>
                        </div>
                        <div
                          className="paymentSummary"
                          style={{ float: "right", fontFamily: "sans-serif", textAlign: "end" }}
                        >
                          <strong style={{ fontFamily: "sans-serif" }}>
                            Payment Mode Summary
                          </strong>
                          <table
                            className="invoiceSummaryTable"
                            style={{
                              borderCollapse: "collapse",
                              fontFamily: "sans-serif",
                              fontSize: 13,
                              marginTop: ".5rem",
                              textAlign: "left",
                              width: "100%"
                            }}
                          >
                            <tbody>
                              <tr
                                style={{ backgroundColor: "#f1f1f1", fontFamily: "sans-serif" }}
                              >
                                <td
                                  style={{
                                    fontFamily: "sans-serif",
                                    padding: ".6rem",
                                    paddingRight: "1.6rem"
                                  }}
                                >
                                  Payment Date
                                </td>
                                <td
                                  style={{
                                    fontFamily: "sans-serif",
                                    padding: ".6rem",
                                    paddingRight: "1.6rem"
                                  }}
                                >
                                  Payment Mode
                                </td>
                                <td style={{ fontFamily: "sans-serif", padding: ".6rem" }}>
                                  Amount
                                </td>
                              </tr>
                              <tr
                                style={{ backgroundColor: "#f9f9f9", fontFamily: "sans-serif" }}
                              >
                                <td
                                  style={{
                                    fontFamily: "sans-serif",
                                    padding: ".6rem",
                                    paddingRight: "1.6rem"
                                  }}
                                >
                                  {booking?.collection_date}
                                </td>
                                <td
                                  style={{
                                    fontFamily: "sans-serif",
                                    padding: ".6rem",
                                    paddingRight: "1.6rem"
                                  }}
                                >
                                  {booking?.paymentMode}
                                </td>
                                <td style={{ fontFamily: "sans-serif", padding: ".6rem" }}>
                                  ₹{totalAmount}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <hr style={{ fontFamily: "sans-serif" }} />
                        </div>
                      </div>
                      <div
                        className="invoiceNotes"
                        style={{
                          clear: "left",
                          backgroundColor: "#fbfbfb",
                          fontFamily: "sans-serif",
                          fontSize: 13,
                          lineHeight: "1.4",
                          marginBottom: "1rem",
                          marginTop: "1.6rem",
                          padding: ".5rem"
                        }}
                      >
                        <strong style={{ fontFamily: "sans-serif" }}>Important Notes:</strong>
                        <ul style={{ fontFamily: "sans-serif" }}>
                          <li style={{ fontFamily: "sans-serif" }}>
                            This invoice can be used for tax exemption under section 80-D
                            (Preventive Health Checkup)
                          </li>
                          <li style={{ fontFamily: "sans-serif" }}>
                            All dispute / claims concerning to reports are subject to the courts
                            of Delhi only
                          </li>
                          <li style={{ fontFamily: "sans-serif" }}>
                            Redcliffe Life Images assumes no liability towards any delays beyond
                            its control
                          </li>
                          <li style={{ fontFamily: "sans-serif" }}>
                            It is recommended that you consult your treating Doctor/Physician
                            before choosing any treatment based on your report
                          </li>
                          <li style={{ fontFamily: "sans-serif" }}>
                            Maximum liability of the Company shall not exceed the amount charged
                            by the Company for the services availed
                          </li>
                          <li style={{ fontFamily: "sans-serif" }}>
                            This is a computer generated receipt and does not require
                            signature/stamp
                          </li>
                        </ul>
                      </div>
                      <div style={{ margin: "2rem", fontSize: 14 }}>
                        <b>Disclaimer</b>: Redcliffe Life Images Pvt Ltd works only as a
                        Non-Medical consultant to {booking?.center?.name} Redcliffe Imaging is not a radiology centre or medical
                        professional. All radiology and imaging services are performed and
                        supervised solely by {booking?.center?.name}

                      </div>
                      <div
                        className="invoiceFooter"
                        style={{
                          backgroundColor: "#275279",
                          fontFamily: "sans-serif",
                          fontSize: 14,
                          padding: "1rem",
                          paddingBottom: "2rem"
                        }}
                      >
                        <div
                          className="invoiceFooterItem"
                          style={{
                            float: "left",
                            width: 370,
                            alignItems: "center",
                            fontFamily: "sans-serif"
                          }}
                        >
                          <img
                            style={{ width: 20 }}
                            src="https://staticredcliffelabs.s3.amazonaws.com/media/gallary-file/None/6caf9ab9-3148-440f-8f5b-9a120a5783bc.png"
                            alt=""
                          />
                          <span
                            style={{
                              color: "#ffffff",
                              fontWeight: 700,
                              position: "absolute",
                              marginTop: 2,
                              marginLeft: 4
                            }}
                          >
                            &nbsp;898-898-0101
                          </span>
                        </div>
                        <div
                          className="invoiceFooterItem"
                          style={{
                            float: "left",
                            width: 180,
                            alignItems: "center",
                            fontFamily: "sans-serif"
                          }}
                        >
                          <img
                            style={{ width: 20 }}
                            src="https://staticredcliffelabs.s3.amazonaws.com/media/gallary-file/None/804fe414-ed12-4471-8904-d800456ec605.png"
                            alt=""
                          />
                          <span
                            style={{
                              color: "#ffffff",
                              fontWeight: 700,
                              position: "absolute",
                              marginTop: 2,
                              marginLeft: 5
                            }}
                          >
                            &nbsp;care@redcliffelabs.com
                          </span>
                        </div>
                        <div
                          className="invoiceFooterItem"
                          style={{
                            float: "right",
                            width: 240,
                            alignItems: "center",
                            fontFamily: "sans-serif"
                          }}
                        >
                          <img
                            width="20px"
                            src="https://staticredcliffelabs.s3.amazonaws.com/media/gallary-file/None/952ffdef-5ac1-4b92-be8b-8f010d24b2ae.png"
                            alt=""
                          />
                          <span
                            style={{
                              color: "#ffffff",
                              fontWeight: 700,
                              position: "absolute",
                              marginTop: 2,
                              marginLeft: 4
                            }}
                          >
                            &nbsp;www.redcliffelabs.com/imaging
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>

            </Paper>
          </div>



        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  paymentInformation: state.CollectionCenterReducer.paymentInformation,
});

export default connect(mapStateToProps, {
  getPaymentInfo
})(CashRecipt);

