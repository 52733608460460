import React, { useState, useEffect } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Grid, TextField, withStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import { useRef } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../Components/loader/index';
import moment from 'moment';
import { MessageRounded } from '@material-ui/icons';
import { getCustomerReviewData, getCurrentDayBooking } from '../../actions/CollectionCenterAdmin'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from "react-router";
// import { generateCurrentDayBookingFilterUrl } from "../../helpers/generateUrl";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);
const StyledTableCell:any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#3f51b5",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
    getCustomerReviewData:any;
    getCurrentDayBooking:any;
    customerReview:any;
    loading: Boolean;
    currentBooking: any;
}

const BookingsTable: React.FC<Props> = ({
    loading,
    getCustomerReviewData,
    getCurrentDayBooking,
    customerReview,
    currentBooking,
}) => {
    const classes = useStyles()
    useEffect(() => {
        getCustomerReviewData()
        getCurrentDayBooking()


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const [page, setPage] = React.useState(0);
    const [city, setCity] = useState<any>([])
    const timer = useRef<any>(0)
    const [collection_date, setCollection_date] = useState("")
    const [booking_date, setBooking_date] = useState("")
    const history = useHistory()

    // const [bookingId, setBookingId] = useState<number>(0);

    // const handleClick = (id: number) => {
    //     setopenModal(true);
    //     setBookingId(id);
    // };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = currentBooking.links && currentBooking.links.next.split("?")[1];
            getCurrentDayBooking(url);
        } else if (newPage < page) {
            let url = currentBooking.links && currentBooking.links.previous.split("?")[1];
            getCurrentDayBooking(url);
        }
        setPage(newPage as number);
    };
    // const filterBookings = () => {
    //     const body: any = {
    //         city: city.join(","),
    //         booking_date,
    //         collection_date,
    //     }
    //     const url = generateCurrentDayBookingFilterUrl(body).substring(2)
    //     getCurrentDayBooking(`${url}`)
    //     setPage(0)
    // }

    return (
        <div style={{ width: "100%" }}className="data-table">
            <div className={classes.toolbar} />
            <h4 style={{fontWeight:"bold",alignItems:"left"}}>BOOKING REVIEW</h4>
            {/* <Paper elevation={15} className={classes.paper}> */}
            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "560px" }}>
                {loading ? <Loader /> : (
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="center">Booking Id</StyledTableCell>
                                <StyledTableCell align="center">Ratings</StyledTableCell>
                                <StyledTableCell align="center">review</StyledTableCell>
                                <StyledTableCell align="center">created_at</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {
                               customerReview&&customerReview.results&& customerReview.results.length > 0 && customerReview.results.map((review: any, index: any) => {
                                    return (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell style={{ height: "50px" }} align="center">{review?.booking}</StyledTableCell>
                                            <StyledTableCell style={{ height: "50px" }} align="center">{review?.ratings}</StyledTableCell>
                                            <StyledTableCell style={{ height: "50px" }} align="center">{review?.review}</StyledTableCell>
                                            <StyledTableCell style={{ height: "50px" }} align="center">
                                                {review?.created_at.substring(0,10)}<br/>
                                                {review?.created_at.substring(11,19)}
                                                </StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                        </TableBody>
                        <TableFooter>
                            <StyledTableRow>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    colSpan={5}
                                    count={currentBooking.length || 0}
                                    rowsPerPage={currentBooking.length || 0}
                                    page={page}
                                    onPageChange={handleChangePage}
                                />
                            </StyledTableRow>
                        </TableFooter>
                    </Table>
                )}
            </TableContainer>
            {/* </Paper> */}

        </div>
    )
}

const mapStateToProps = (state: any) => ({
    customerReview: state.CollectionCenterAdminReducer.customerReview,
    currentBooking: state.CollectionCenterAdminReducer.currentBooking,
})

export default connect(mapStateToProps, {
    getCustomerReviewData,
    getCurrentDayBooking,
})(BookingsTable);

