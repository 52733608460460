import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Modal from '@mui/material/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button } from '@material-ui/core';
import {
    Chip,
    Paper,
    Table,
    withStyles,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
}
    from '@material-ui/core';

import Loader from '../loader';
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from 'react-router-dom';
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    paper: {
        marginTop: "2rem",
        padding: "0.5rem 2rem",
        width: "100%",
    },
    table: {
        maxWidth: "100%",
        margin: "auto"
    },
    tableContainer: {
        marginTop: "2rem",
        position: "sticky",
        top: "200px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem",
    },
    toolbar: {
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
    },
    listStyle: {
        marginLeft: "20px",
    }
}));
const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,fontWeight:600
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);
interface Props {

}
type ModalProps = {
    loading: Boolean;
    openReportModal: boolean;
    setReportModal: Function;
    reportStatus: any;
};

const CreatePhlebo: React.FC<ModalProps> = ({
    openReportModal,
    setReportModal,
    reportStatus,
    loading
}) => {

    const history = useHistory();
    const classes = useStyles();
    const [page, setPage] = useState(0);

    const handleClose = () => {
        setReportModal(false);
    };
    return (
        <div>
            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "560px" }}>
                <Table stickyHeader aria-label="simple table">
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={openReportModal}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500
                        }}
                    >
                        <Fade in={openReportModal}>
                            <div className={classes.paper}>
                                <Paper elevation={15} className={classes.paper}>
                                    <TableContainer className={classes.table} component={Paper}>
                                        <div className={classes.head}>
                                            <h4
                                                style={{
                                                    marginBottom: "1rem",
                                                }}
                                                id="transition-modal-title"
                                            >
                                                REPORT STATUS
                                            </h4>
                                            <CloseIcon onClick={() => setReportModal(false)} />

                                        </div>
                                        {loading ? <Loader /> : (
                                            <Table aria-label="simple table">

                                                <TableHead>
                                                    <StyledTableRow>
                                                        <StyledTableCell align="center">Booking Id </StyledTableCell>
                                                        <StyledTableCell align="center">Customer Name </StyledTableCell>
                                                        <StyledTableCell align="center">Test Code </StyledTableCell>
                                                        <StyledTableCell align="center">Test Name</StyledTableCell>
                                                        <StyledTableCell align="center">Lab</StyledTableCell>
                                                        <StyledTableCell align="center">Created At</StyledTableCell>
                                                        <StyledTableCell align="center">Status</StyledTableCell>
                                                    </StyledTableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {
                                                        reportStatus.results && reportStatus.results.length > 0 && reportStatus.results.map((data: any, index: any) => {
                                                            if (data.booking !== null) {
                                                                return (
                                                                    <TableRow key={index}>

                                                                        <TableCell style={{ height: "50px" }} align="center">{data?.booking.id}</TableCell>
                                                                        <TableCell style={{ height: "50px" }} align="center">{data?.booking.customer_name}</TableCell>
                                                                        <TableCell style={{ height: "50px" }} align="center">{data?.testCode}</TableCell>
                                                                        <TableCell style={{ height: "50px" }} align="center">{data?.testName}</TableCell>
                                                                        <TableCell style={{ height: "50px" }} align="center">{data?.lab}</TableCell>
                                                                        <TableCell style={{ height: "50px" }} align="center">{new Date(data?.created_at).toLocaleString()}</TableCell>
                                                                        <TableCell style={{ height: "50px" }} align="center">{data?.status}</TableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                            else {
                                                                return (
                                                                    <TableRow key={index}>

                                                                        <TableCell style={{ height: "50px" }} align="center">{data?.add_member_booking.id}</TableCell>
                                                                        <TableCell style={{ height: "50px" }} align="center">{data?.add_member_booking.customer_name}</TableCell>
                                                                        <TableCell style={{ height: "50px" }} align="center">{data?.testCode}</TableCell>
                                                                        <TableCell style={{ height: "50px" }} align="center">{data?.testName}</TableCell>
                                                                        <TableCell style={{ height: "50px" }} align="center">{data?.lab}</TableCell>
                                                                        <TableCell style={{ height: "50px" }} align="center">{new Date(data?.created_at).toLocaleString()}</TableCell>
                                                                        <TableCell style={{ height: "50px" }} align="center">{data?.status}</TableCell>
                                                                    </TableRow>)
                                                            }

                                                        })}


                                                </TableBody>
                                            </Table>
                                        )}
                                    </TableContainer>

                                </Paper>
                            </div>
                        </Fade>
                    </Modal>
                </Table>
            </TableContainer>

        </div >
    );
};

const mapStateToProps = (state: any) => ({
    // area: state.ProductManagerReducer.area,
});

export default connect(mapStateToProps, {
})(CreatePhlebo);

