import React, { useEffect } from "react";
import {

  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Loader from "../loader";
import "./index.sass";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: "2rem",
    padding: "0.5rem 2rem",
    width: "100%",
  },
  table: {
    maxWidth: "100%",
    margin: "auto",
  },
  toolbar: {
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  listStyle: {
    marginLeft: "20px",
  },
  tableContainer: {
    marginTop: "1rem",
    position: "sticky",
    top: "200px",
  },
}));

const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,fontWeight:600
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

type ModalProps = {
  openModal: boolean;
  setOpenModal: Function;
  loading: boolean;
  labDetails: any;
};

const BrakModal: React.FC<ModalProps> = ({
  openModal,
  setOpenModal,
  labDetails,
  loading,
}) => {
  const classes = useStyles();



  const handleClose = () => {
    setOpenModal(false);
  };

  // useEffect(() => {

  //     getBreakResponse(`self=true`)
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [openModal]);
  // const handleBreak=()=>{
  //     getPanelBreak("true")
  //     setMessage("You are on Break Please click RE JOIN to Start Work")
  //     setTimeout(() => {
  //        getBreakResponse(`self=true`)
  //     }, 100);

  //     //  setOpenModal(false);
  // }
  // const handleReJoin=()=>{
  //     getPanelBreak("false")
  //     setMessage("Break Option")
  //      setOpenModal(false);
  // }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <Paper elevation={15} className={classes.paper}>
            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "580px" }}>
                <h2>LAB DETAILS</h2>
                {loading ? <Loader /> : (
                  <Table stickyHeader aria-label="simple table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell align="center">Name</StyledTableCell>
                        <StyledTableCell align="center">Org Id</StyledTableCell>
                        <StyledTableCell align="center">Zones</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {labDetails &&
                        labDetails?.lab_credentials &&
                        labDetails?.lab_credentials?.lab &&
                        labDetails?.lab_credentials?.lab.length > 0 &&
                        labDetails?.lab_credentials?.lab?.map(
                          (data: any, index: any) => {
                            return (
                              <StyledTableRow key={index}>
                                <StyledTableCell
                                  style={{ height: "50px" }}
                                  align="center"
                                >
                                  {data.name}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ height: "50px" }}
                                  align="center"
                                >
                                  {data.orgid}
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{ height: "50px" }}
                                  align="center"
                                >
                                  {data?.lab_zone.map((zone: any) => {
                                    return `${zone?.zone}-${zone?.is_active === true
                                        ? "Active"
                                        : "InActive"
                                      },`;
                                  })}
                                </StyledTableCell>
                              </StyledTableRow>
                            );
                          }
                        )}
                    </TableBody>
                    <TableFooter>
                      {/* <StyledTableRow>
                        <TablePagination
                                        rowsPerPageOptions={[]}
                                        colSpan={5}
                                        count={breakData.count||0}
                                        rowsPerPage={breakData.page_size}
                                        page={page}
                                        onPageChange={handleChangePage}
                                    />
                      </StyledTableRow> */}
                    </TableFooter>
                  </Table>
                )}
              </TableContainer>
            </Paper>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.SalesLeadReducer.loading,
});

export default connect(mapStateToProps, {
  // getBreakResponse,
  // getPanelBreak,
})(BrakModal);
