import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import LinearProgress from "@material-ui/core/LinearProgress";
// import SearchBar from "../../../search-bar";
import Button from '@mui/material/Button';
import { connect } from "react-redux";
// import { fbFileUpload } from "../../../../actions/fb-agent/fileUploadActions";
import "./index.sass";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

interface Props {
  children: any;
}

const AnalystDashboardRight: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      {/* <div className="call-options"></div> */}
      {/* <SearchBar /> */}
      {/* <div></div> */}
      {/* <main className={classes.toolbar}>
        <div className={classes.content} /> */}
      {children && children}
      {/* </main> */}
    </>
  );
};


export default AnalystDashboardRight;
