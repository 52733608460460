import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  Paper,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import Loader from "../../components/loader";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      maxWidth: "100%",
    },
    tableHead: {
      background: "rgb(146, 74, 145)!important",
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
    tableBodyCell: {
      padding: "10px 5px 10px 5px!important",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
  },
  })
);

const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,fontWeight:600
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);
interface Props {
  loading: boolean;
  getFamilyPackageBooking: any;
  familyPackageBookingList: any;
}

export const FamilyPackageBookingList: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [page, setPage] = useState<number>(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        props.familyPackageBookingList?.links &&
        props.familyPackageBookingList?.links.next.split("?")[1];
      props.getFamilyPackageBooking(url);
    } else if (newPage < page) {
      let url =
        props.familyPackageBookingList?.links &&
        props.familyPackageBookingList?.links.previous.split("?")[1];
      props.getFamilyPackageBooking(url);
    }
    setPage(newPage as number);
  };
 
  return (
    <div>
     <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "560px" }}>
        {props.loading ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <StyledTableCell className={classes.tableHeadCell}>ID</StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>Lead ID</StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Customer Name
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Customer Email
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Customer Mobile
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>Package</StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  No of Member
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>Agent</StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Created Date
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.familyPackageBookingList && props.familyPackageBookingList?.results &&
                props.familyPackageBookingList?.results.length > 0 &&
                props.familyPackageBookingList?.results.map(
                  (booking: any, index: any) => {
                    return (
                      <TableRow key={index}>
                        <StyledTableCell align="center">{booking?.id}</StyledTableCell>
                        <StyledTableCell align="center">
                          <Link
                            to={`/dashboard/cl/fill-info?id=${booking?.lead}`}
                          >
                            {booking?.lead}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell className={classes.tableBodyCell}>
                          {booking?.customer_name}
                        </StyledTableCell>
                        <StyledTableCell className={classes.tableBodyCell}>
                          {booking?.customer_email}
                        </StyledTableCell>
                        <StyledTableCell className={classes.tableBodyCell}>
                          {booking?.customer_mobile}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          className={classes.tableBodyCell}
                        >
                          {booking?.package_name}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          className={classes.tableBodyCell}
                        >
                          {booking?.no_of_member}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          className={classes.tableBodyCell}
                        >
                          {booking?.agent?.name}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          className={classes.tableBodyCell}
                        >
                          {booking?.created_at}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          className={classes.tableBodyCell}
                        >
                          {booking?.status}
                        </StyledTableCell>
                      </TableRow>
                    );
                  }
                )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={30}
                  count={props.familyPackageBookingList.count || 0}
                  rowsPerPage={props.familyPackageBookingList.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};