import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Grid, TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import MessageIcon from "@material-ui/icons/Message";
import CallIcon from "@material-ui/icons/Call";
import {
  getImagingTicket,
  getImagingTicketCount,
  clickToCall,
  getLeadSource,
  getTopRCFreshLeads,
  getCities,
  getPrescriptionData,
} from "../actions/bookingActions";
import { TableFooter, TablePagination } from "@material-ui/core";
import Loader from "../components/Loader2";
import CommentsModal from "../components/Comments/comment";
import CommentsModal2 from "../components/Comments/Comment2";
import Button from '@mui/material/Button';
import { generateLeadsUrl } from "../../helpers/generateUrl";
import RaiseComplaintModal from "../components/Comments/raiseTicketModal";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import Fade from "@material-ui/core/Fade";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { CALL_DISABLE_TIME } from "../../CallTimeDisable";

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  tableContainer: {
    marginTop: "1rem",
    position: "sticky",
    top: "200px",
  },
  content: {
    width: "80%",
    flexGrow: 21,
    //   padding: theme.spacing(3),
  },
  paper: {
    marginTop: "2rem",
    padding: "0.5rem 2rem",
    width: "100%",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

interface Props {
  getTopRCFreshLeads: Function;
  topRcFreshLeads: any;
  lead_source: any;
  loading: boolean;
  // resendSms: Function;
  clickToCall: Function;
  getLeadSource: any;
  getCities: any;
  cities: any;
  getPrescriptionData: any;
  prescription: any;
  getImagingTicket: any;
  imagingTicket: any;
  getImagingTicketCount: any;
  imagingTicketcount: any;
}

const LeadsTable: React.FC<Props> = ({
  getTopRCFreshLeads,
  topRcFreshLeads,
  clickToCall,
  getLeadSource,
  lead_source,
  getCities,
  cities,
  loading,
  getPrescriptionData,
  prescription,
  getImagingTicket,
  imagingTicket,
  getImagingTicketCount,
  imagingTicketcount,
}) => {
  const classes = useStyles();
  const [open2, setOpen2] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [raiseComplaint, setRaiseComplaint] = useState(false);

  const [leadId, setLeadId] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    50 || topRcFreshLeads.results.count
  );
  const [prscriptionLead, setPrescriptionLead] = useState<any>("");
  const [pLink, setPlink] = useState<any>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openPrescription = Boolean(anchorEl);
  const [fileType, setFileType] = useState<any>("");
  const [openPdf, setOpenPdf] = useState<boolean>(false);
  const history = useHistory();
  const [callButton, setCallButton] = useState<boolean>(true);
  const [activeButton, setActiveButton] = useState<any>("pending");
  const [tableStatus, setTableStatus] = useState<any>("pending");
  const [id, setId] = useState<string>("");
  const [panel_user, setPanelUser] = React.useState<number | null>(null);
  const timer = useRef<any>(0);

  const [selectedTicket, setSelectedTicket] = useState<any>([]);

  const [messageType, setMessageType] = useState<any>(false);



  const CATEGORY: any = [
    {
      id: 1,
      text: "Interested/Follow up",
      city: true,
      leadId: false,
    },
    {
      id: 2,
      text: "Not Interested",
      city: true,
      query: false,
    },
    {
      id: 3,
      text: "Order Booked",
      city: true,
      query: false,
    },
    {
      id: 4,
      text: "Ringing",
      city: false,
      query: false,
    },
    {
      id: 5,
      text: "Number Doesn't Exist",
      city: false,
      query: false,
    },
    {
      id: 6,
      text: "By Mistake & Wrong Number",
      city: false,
      query: false,
    },
    {
      id: 7,
      text: "Call Back",
      city: true,
      query: true,
    },
    {
      id: 8,
      text: "Business Realated Call",
      city: true,
      query: false,
    },
    {
      id: 9,
      text: "Spam Call",
      city: false,
      query: false,
    },
    {
      id: 10,
      text: "Enquiry Call",
      city: true,
      query: false,
    },
    {
      id: 11,
      text: "CFresh",
      city: false,
      query: false,
    },
    {
      id: 12,
      text: "Test Not Available",
      city: true,
      query: false,
    },
    {
      id: 13,
      text: "Subscribed to Competitor",
      city: true,
      query: false,
    },
    {
      id: 14,
      text: "Slot Issue",
      city: true,
      query: false,
    },
    {
      id: 15,
      text: "Same Day Slot",
      city: true,
      query: false,
    },
    {
      id: 16,
      text: "Already Booked",
      city: false,
      query: false,
    },
    {
      id: 17,
      text: "Not Contactable",
      city: false,
      query: false,
    },
    {
      id: 18,
      text: "Out of Station",
      city: true,
      query: false,
    },
    {
      id: 19,
      text: "Out of Service Area",
      city: true,
      query: false,
    },
    {
      id: 20,
      text: "Need Time to Think",
      city: true,
      query: false,
    },
    {
      id: 21,
      text: "Email/Whatsapp Package",
      city: true,
      leadId: false,
    },
    {
      id: 22,
      text: "Discount/Price Enquiry",
      city: true,
      leadId: false,
    },
    {
      id: 23,
      text: "CS issue",
      city: false,
      leadId: true,
    },
    {
      id: 24,
      text: "Call Not Reachable",
      city: false,
      leadId: false,
    },
    {
      id: 25,
      text: "Call Later",
      city: true,
      leadId: false,
    },
    {
      id: 26,
      text: "Call drop",
      city: true,
      leadId: false,
    },
    {
      id: 27,
      text: "Call Disconnected",
      city: true,
      leadId: false,
    },
    {
      id: 28,
      text: "Below <5 year patient",
      city: true,
      leadId: false,
    },
    {
      id: 29,
      text: "DND",
      city: false,
      leadId: false,
    },
    {
      id: 30,
      text: "Phlebo's Issue",
      city: false,
      leadId: true,
    },
    {
      id: 31,
      text: "Reports Regarding Issue",
      city: false,
      leadId: true,
    },
    {
      id: 32,
      text: "Health Advice",
      city: false,
      leadId: true,
    },
    {
      id: 33,
      text: "Health Consultation",
      city: false,
      leadId: true,
    },
    {
      id: 34,
      text: "Health Consultation",
      city: false,
      leadId: false,
    },
    {
      id: 35,
      text: "Language Barrier",
      city: false,
      leadId: false,
    },
    {
      id: 36,
      text: "RCFresh",
      city: false,
      leadId: false,
    },
    {
      id: 37,
      text: "HCbooking",
      city: false,
      leadId: false,
    },
  ];

  const priorityArr: any = [
    {
      value: "SuperCritical",
    },
    {
      value: "Urgent",
    },
    {
      value: "HopeFull",
    },
    {
      value: "Low",
    },
  ];

  // useEffect(() => {
  //   // getTopRCFreshLeads("?multiple_category=36&latest_size=10&latest=true");
  //   // getLeadSource();
  //   // getImagingTicket("?raised=false");
  // }, []);

  useEffect(() => {
    if (open2 === true) {
      getCities();
    }
  }, [open2])

  useEffect(() => {
    getImagingTicketCount();
  }, []);

  useEffect(() => {
    if (open2 === false) {
      setLeadId(0);
    }
  }, [open2]);

  const handleClick = (id: number) => {
    setOpen(true);
    setLeadId(id);
  };

  const handleModal = (id: number) => {
    setOpen2(true);
    setLeadId(id);
  };

  const filterLeads = () => {
    const body: any = {
      lead: id,
    };
    const url = generateLeadsUrl(body).substring(2);
    getImagingTicket(`?raised=false&${url}`);
    setPage(0);
  };
  const handleCallClick = (id: number, type: string) => {
    setLeadId(id);
    setCallButton(false);
    clickToCall(id, type, `?imaging_ticket=true`);
    setTimeout(() => {
      setCallButton(true);
    }, CALL_DISABLE_TIME);
  };

  const handleRaiseTicket = (id: number) => {
    setRaiseComplaint(true);
    setLeadId(id);
  };

  useEffect(() => {
    if (prscriptionLead !== "") {
      getPrescriptionData(`?lead=${prscriptionLead}`);
    }
  }, [prscriptionLead]);

  const handlePrescriptionClick = (
    id: any,
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setPrescriptionLead(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFrameView = (data: any) => {
    if (data.base64file !== null && data.image === "") {
      setFileType("b64");
      setPlink(`data:application/pdf;base64,${data.base64file}`);
    } else if (data.base64file === null && data.image !== "") {
      let pieces: any = data.image.split(".").pop();
      const last: any = pieces;
      setFileType(last);
      setPlink(data.image);
    }
    setOpenPdf(true);
  };
  const handlePdfModelClose = () => {
    setOpenPdf(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        imagingTicket?.links && imagingTicket?.links?.next?.split("?")[1];
      getImagingTicket(`?${url}`);
    } else if (newPage < page) {
      let url =
        imagingTicket?.links &&
        imagingTicket?.links?.previous?.split("?")[1];
      getImagingTicket(`?${url}`);
    }
    setPage(newPage as number);
  };

  useEffect(() => {
    getImagingTicket(`?raised=false&status=${tableStatus}`);
  }, [tableStatus]);

  const handlePending = () => {
    setActiveButton("pending");
    setTableStatus("pending");
    setMessageType(false)
  };
  const handleConfirm = () => {
    setActiveButton("confirm");
    setTableStatus("confirmed");
    setMessageType(true)
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const transferBooking = async () => {
    let body = {
      ids: selectedTicket.join(", "),
    };
    // await trasferBooking(body);
    // setSelectedBooking([])
    // getAgentListHealthAdvisor("?usergroup=HealthAdviser");
    // getAllBookings()
  };

  return (
    <>
      <main className={classes.content}>
        <div
          className="data-table"
          style={{ width: "100%", marginTop: "4rem" }}
        >
          <h3 style={{ fontWeight: "bold" }}>Imaging Tickets</h3>
          <Grid
            container
            spacing={1}
            direction="row"
            style={{ alignItems: "left" }}
          >
            <Grid item xs={12} sm={4} md={3}>
              <TextField
                name="leadId"
                type="text"
                value={id}
                className="input"
                variant="outlined"
                label="Lead Id"
                onChange={(e) => setId(String(e.target.value).replace(/[^0-9]/g,""))}
                style={{ width: "100%" }}
                required
              />
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                disabled={!id}
                onClick={filterLeads}
              >
                Filter
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => history.push("/dashboard/ce/imaging-ticekt")}
              >
                Reset
              </Button>
            </Grid>
            <div
              style={{ width: "100%", marginTop: "2rem", alignItems: "right" }}
            >
              <span style={{ fontSize: "1.3rem", marginRight: "8.0rem" }}>
                PRICE : {imagingTicketcount?.total_booking_revenue}
              </span>
              <span style={{ fontSize: "1.3rem", marginRight: "8.0rem" }}>
                CONVERTING BOOKING:{" "}
                {imagingTicketcount?.total_converted_booking}
              </span>
              {/* <span style={{ fontSize: "1.3rem" }}>GENERATE TICEKT: {imagingTicketcount?.total_generated_ticket}</span>  */}
            </div>
            <Grid
              container
              spacing={1}
              direction="row"
              style={{ margin: "0rem", marginTop: "2rem" }}
            >
              {/* <Grid item xs={12} sm={4} md={3}>

              </Grid> */}
              <Grid item xs={12} sm={6} md={2}>
                <Button
                  style={{ marginLeft: "1rem", marginBottom: "1rem" }}
                 
                  variant={activeButton ? "contained" : "outlined"}
                  color="primary"
                  fullWidth
                  onClick={() => handlePending()}
                >
                  Pending
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Button
                  style={{ marginLeft: "1rem", marginBottom: "1rem" }}
                  variant={activeButton  ? "contained" : "outlined"}
                  fullWidth
                  color="secondary"
                  
                  onClick={() => handleConfirm()}
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {selectedTicket.length > 0 && (
            <React.Fragment>
              <h2 style={{ textAlign: "center", marginTop: "2rem" }}>
                Transfer To:
              </h2>
              <Grid
                container
                spacing={3}
                direction="row"
                alignItems="center"
                justify="center"
              >
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    id="agentname"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setPanelUser(obj.id);
                      }
                    }}
                    options={[]}
                    freeSolo
                    blurOnSelect
                    aria-required
                    getOptionLabel={(option: any) =>
                      option.user && option.user.username
                    }
                    // onInputChange={(event, newInputValue) => {
                    //   clearTimeout(timer.current);
                    //   timer.current = setTimeout(() => {
                    //       getAgentListHealthAdvisor(
                    //       `?code=${newInputValue.replace(" ", "")}&usergroup=HealthAdviser`
                    //     );
                    //   }, 1000);
                    //   if (newInputValue.length === 0) {
                    //     setPanelUser(0);
                    //   }
                    // }}
                    renderInput={(params: any) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Agent username"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        required
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    variant="contained"

                    style={{ height: "56px" }}
                    fullWidth
                    onClick={transferBooking}
                  >
                    Transfer
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          )}

          {/* <Paper elevation={15} className={classes.paper}> */}
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "560px" }}
          >
            {loading ? (
              <Loader />
            ) : (
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    {/* <StyledTableCell align="center">
                                    <input
                                        type="checkbox"
                                        className="input"
                                        name="selectall"
                                        id="selectall"
                                        onChange={(e) =>
                                            e.target.checked
                                                ? setSelectedTicket(
                                                  imagingTicket.results &&
                                                  imagingTicket.results.map((bData: any) => {
                                                        return bData?.lead?.id && bData?.lead?.id;
                                                    })
                                                )
                                                : setSelectedTicket([])
                                        }
                                    />
                                </StyledTableCell> */}
                    <StyledTableCell align="center">Lead ID</StyledTableCell>
                    <StyledTableCell align="center">Created At</StyledTableCell>
                    <StyledTableCell align="center">Comments</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                    <StyledTableCell align="center">
                      Lead Source Url
                    </StyledTableCell>
                    <StyledTableCell align="center">Agent</StyledTableCell>
                    <StyledTableCell align="center">Call</StyledTableCell>
                    <StyledTableCell align="center">
                      Contact number
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Patient name
                    </StyledTableCell>
                    <StyledTableCell align="center">City</StyledTableCell>
                    <StyledTableCell align="center">
                      Patient Area
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Call status
                    </StyledTableCell>
                    <StyledTableCell align="center">Language</StyledTableCell>
                    <StyledTableCell align="center">Edit</StyledTableCell>
                    <StyledTableCell align="center">
                      Raise Ticket
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Prescription
                    </StyledTableCell>
                    <StyledTableCell align="center">Priority</StyledTableCell>
                    <StyledTableCell align="center">Call Count</StyledTableCell>
                    <StyledTableCell align="center">Status</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {imagingTicket &&
                    imagingTicket?.results &&
                    imagingTicket?.results.length > 0 &&
                    imagingTicket?.results.map((data: any, index: any) => {
                      return (
                        <StyledTableRow key={index}>
                          {/* <StyledTableCell align="center">
                                                <input
                                                    type="checkbox"
                                                    name="selectall"
                                                    id="selectall"
                                                    checked={selectedTicket.find(
                                                      (bI: any) => bI === data?.lead?.id
                                                  )}
                                                    onChange={(e) =>
                                                        !e.target.checked
                                                            ? setSelectedTicket(
                                                              selectedTicket.filter(
                                                                    (bI: any) => bI !== data?.lead?.id
                                                                )
                                                            )
                                                            : setSelectedTicket((prev: any) => [
                                                                ...prev,
                                                                data?.lead?.id,
                                                            ])
                                                    }
                                                />
                                            </StyledTableCell> */}
                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.lead?.id}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            {new Date(data?.created_at).toLocaleString()}
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row" align="center">
                            <div>
                              <MessageIcon
                                onClick={() => handleClick(data?.lead?.id)}
                              />
                            </div>
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            <div>
                              <MessageIcon
                                onClick={() => handleModal(data?.lead?.id)}
                              />
                            </div>
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.lead?.lead_source_url}
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.by_user?.name}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            <Button
                              disabled={!callButton}
                              className="addDeck"
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                handleCallClick(data?.lead?.id, "call")
                              }
                              startIcon={<CallIcon />}
                            ></Button>
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.agent?.phonenumber}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.lead?.patient_name}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.lead?.patient_city}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.lead?.patient_area}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.lead?.call_status?.name}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.lead?.language}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            <Link
                              to={`/dashboard/ce/fill-info?id=${data?.lead?.id}`}
                            >
                              <EditIcon />
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            <Button
                              variant={
                                !raiseComplaint ? "contained" : "outlined"
                              }
                              color="secondary"
                              onClick={() => handleRaiseTicket(data.lead.id)}
                            >
                              <ConfirmationNumberIcon />
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="center"
                          ></StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.lead?.priority}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.lead?.count}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row"  align="center">
                          {data?.status}
                        </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <StyledTableRow>
                    <TablePagination
                      rowsPerPageOptions={[]}
                      colSpan={3}
                      count={imagingTicket?.count || 0}
                      rowsPerPage={imagingTicket?.page_size}
                      page={page}
                      onPageChange={handleChangePage}
                    />
                  </StyledTableRow>
                </TableFooter>
              </Table>
            )}
          </TableContainer>
          {/* </Paper> */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openPdf}
            onClose={handlePdfModelClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={openPdf}>
              <>
                {fileType === "pdf" ? (
                  <iframe
                    style={{ width: "80%", height: "750px" }}
                    src={pLink}
                    title="title"
                  >
                    Presss me: <a href={pLink}>Download PDF</a>
                  </iframe>
                ) : fileType === "b64" ? (
                  <iframe
                    src={pLink}
                    style={{ width: "80%", height: "750px" }}
                    title="Iframe Example"
                  ></iframe>
                ) : (
                  ""
                )}
              </>
            </Fade>
          </Modal>
        </div>
        <CommentsModal open={open} setOpen={setOpen} leadId={leadId} />
        {open2 && 
        <CommentsModal2
          open={open2}
          getCities={getCities}
          cities={cities}
          setOpen={setOpen2}
          leadId={leadId}
        />}
        <RaiseComplaintModal
          open={raiseComplaint}
          setOpen={setRaiseComplaint}
          leadId={Number(leadId)}
          bookingId={0} booking={undefined} totalPrice={0}        />
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.BookingReducer.loading,
  cities: state.BookingReducer.cities,
  imagingTicket: state.BookingReducer.imagingTicket,
  imagingTicketcount: state.BookingReducer.imagingTicketcount,
  topRcFreshLeads: state.BookingReducer.topRcFreshLeads,
  lead_source: state.BookingReducer.lead_source,
  prescription: state.BookingReducer.prescription,
});

export default connect(mapStateToProps, {
  getImagingTicket,
  getImagingTicketCount,
  getCities,
  getTopRCFreshLeads,
  clickToCall,
  getLeadSource,
  getPrescriptionData,
})(LeadsTable);
