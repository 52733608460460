import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from '@mui/material/Button';
import { connect, useDispatch } from "react-redux";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "300px",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "300px",
      padding: "20px 10px 10px 10px",
      background: "#ffffff",
      margin: "20px",
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    },
    paper: {
      marginTop: "2rem",
      width: "100%",
    }
  })
);

interface Props {
}

const FamilyPackageBooking: React.FC<Props> = (props) => {
  const classes = useStyles();
  function hideDiv() {
    // $('#iframe-id').contents().find('#crmScreen').hide();
  }
  return (
    <div>
      <div className={classes.toolbar} />
       {/* <iframe src="https://agent1.cloudagent.in/toolbar_widget/index.html?apiKey={apiKey}&customer=redcliffe_lab&agentid=abhishekraj&phoneNumber=07676118652&pin=100&action=formLogin#dashboard-agent" width="350px" height="600px"/> */}
    </div>
  );
};
const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, {
})(FamilyPackageBooking);
