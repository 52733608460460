import { ACTIONS } from "../interfaces/actionTypes/ImagingAdminType";
import AXIOS from "../../config/Axios";
import Prefix from "../../config/ApiPrefix";
import snackBarUpdate from "../../actions/snackBarActions";
import SecureStorage from "../../config/SecureStorage";

export const changePassword = (body: any) => async (dispatch: Function) => {
  try {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    await AXIOS.post(
      `${Prefix.api}/auth/password_reset/reset_password/`,
      body,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({ type: ACTIONS.CHANGE_PASSWORD, payload: null });
    snackBarUpdate({
      payload: {
        message: "Password Updated",
        status: true,
        type: "success",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getCities = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/core/cities/${url ? `?q=${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_CITIES, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getAreaName = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/core/city-area/${url ? `?id=${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_AREA, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const uploadBulkPackage = (body: any) => async (dispatch: Function) => {
  try {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    await AXIOS.post(
      `${Prefix.api}/package/package_center_bulk_upload/`,
      body,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({ type: ACTIONS.UPLOAD_BULK_PACAKGE, payload: null });
    snackBarUpdate({
      payload: {
        message: "TEST UPLOAD SUCCESSFULLY",
        status: true,
        type: "success",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getImagingTestList = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });

  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/package/imaging-test-list/`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_IMAGING_TEST_LIST, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getCenterAddressPrice =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/package/center_address_price/${url ? url : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_CENTER_ADDRESS_PRICE, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getImagingPackageAlias =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/package/alias/${url ? url : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_IMAGING_PACKAGE_ALIAS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getPackage = (url: string) => async (dispatch: Function) => {
  // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/package/crmdata/${url ? url : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PACKAGES, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const CreateImagingAlias = (body: any) => async (dispatch: Function) => {
  try {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    await AXIOS.post(`${Prefix.api}/package/alias/create/`, body, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.CREATE_IMAGING_ALIAS, payload: null });
    snackBarUpdate({
      payload: {
        message: "TEST ALIAS UPDATED SUCCESSFULLY",
        status: true,
        type: "success",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const createCenterPackage =
  (body: any) => async (dispatch: Function) => {
    try {
      dispatch({ type: ACTIONS.SET_LOADING, payload: true });
      await AXIOS.post(`${Prefix.api}/package/package-center-create/`, body, {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      });
      dispatch({ type: ACTIONS.CREATE_CENTER_PACKAGE, payload: null });
      snackBarUpdate({
        payload: {
          message: "TEST CREATED SUCCESSFULLY",
          status: true,
          type: "success",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    } catch (err: any) {
      let title = "";
      if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors);
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const updatedPackageAlias =
  (id: any, body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/package/alias/edit/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.UPDATED_PACKAGE_ALIAS,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "TEST ALIAS UPDATED",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getCodeGenData = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });

  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/package/test-code-gen/${url ? url : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_CODE_GENRATOR_DATA, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getSubCenterInfo = (url: string) => async (dispatch: Function) => {
  // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/center/center-address/${url ? url : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_SUB_CENTER_INFORMATION, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getDays = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/core/days/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.GET_DAYS_LIST, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getDayWiseTimeSlotList =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/package/day-wise-time-slot-list/${url ? url : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_DAY_WISE_TIME_SLOT, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getTimeslots = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/phlebo/timeslots/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({
      type: ACTIONS.GET_TIMESLOTS,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const updateDayWiseimeSlots =
  (id: any, body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/package/day-wise-time-slot-edit/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.DAY_WISE_TIME_SLOT_EDIT,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "TIME SLOTS UPDATED SUCCESSFULLY",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getPinCode = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });

  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/phlebo/pincode/${url ? `?code=${url}` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PIN_CODE, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getMapMyIndiaAccessToken = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN, payload: [] });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/panel/mapmyindia-auth-token/`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};

export const BulkPackageEdit =
  (bookingData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/package/package-edit-in-bulk/`,
        bookingData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.UPDATE_BULK_PACKAGE, payload: data });
      snackBarUpdate({
        payload: {
          message: "Test Update Succesfully",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getDocument = (url: string) => async (dispatch: Function) => {
  // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/core/document/${url ? url : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_DOCUMENT, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getSalesManager = (url: string) => async (dispatch: Function) => {
  // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/panel/data/?usergroup=ImagingSalesManager&code=${
        url ? url : ""
      }`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_SALES_MANAGER, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const checkGeoFenchMapMyIndia =
  (eloc: string, pincode: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA, payload: [] });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/checking-geofence-area-mapmyindia/?eloc=${eloc}&pincode=${pincode}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      //dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const createSubCenter =
  (bookingData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/center/center-address-create/`,
        bookingData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_NEW_CENTER, payload: data });
      snackBarUpdate({
        payload: {
          message: "Center Created Succesfully",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getCenterInfo = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/center/data/${url ? url : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_CENTER_INFORMATION, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_CENTER_INFORMATION, payload: [] });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const updateSubCentre =
  (body: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/center/center-address-edit/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.UPDATED_CENTER_PACKAGE,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "SUB CENTER UPDATED",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const createImagingPackage =
  (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(`${Prefix.api}/package/create/`, body, {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      });
      dispatch({
        type: ACTIONS.CREATE_IMAGING_PACKAGE,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Test Created",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  
export const checkGeoFenceArea =
  (latitude: any, longitude: string, zone_id: string) =>
  async (dispatch: Function) => {
    //dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    dispatch({ type: ACTIONS.CHECK_GEOFENCE_AREA, payload: false });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/checking-geofence-area/?latitude=${latitude}&longitude=${longitude}&zone_id=${zone_id}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CHECK_GEOFENCE_AREA, payload: true });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      //dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const createNewCenter =
  (bookingData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/center/imaging-create/`,
        bookingData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_NEW_CENTER, payload: data });
      snackBarUpdate({
        payload: {
          message: "Center Created Succesfully",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const updatedCenterPackage =
  (body: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/package/package-center-create/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.UPDATED_CENTER_PACKAGE,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "CENTER TEST UPDATED",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const updateCreateImagingPackage =
  (body: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
      try {
        const { data } = await AXIOS.post(`${Prefix.api}/package/edit/${id}`, 
        body, 
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        });
      dispatch({
        type: ACTIONS.UPDATE_IMAGING_PACKAGE,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Test Updated",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const updateCenter =
  (centerData: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/center/update/${id}`,
        centerData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.UPDATE_CENTER, payload: data });
      snackBarUpdate({
        payload: {
          message: "CENTER UPDATED",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getCentrePackageComments =
  (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/package/comment/${url ? url : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_CENTER_PACKAGE_COMMENTS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong!",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.GET_CENTER_INFORMATION, payload: [] });
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getTicketData = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });

  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/redtech/tasks/${url ? `${url}` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_TICKET_DATA, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getTicketCategories = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });

  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/redtech/categories/${url ? `${url}/` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_TICKET_CATEGORIES, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getTicketSubCategories =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/redtech/sub-categories/${url ? `${url}` : ""}`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_TICEKT_SUB_CATEGORIES, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const CreateTaskTicket = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/redtech/tasks/`, body, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.CREATE_TASK_TICKET, payload: data });
    snackBarUpdate({
      payload: {
        message: "Task Created Successfully",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getRedTechComment = (id: number) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/redtech/comments/?ticket=${id}&ordering=-created_at`,

      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_RED_TECH_COMMENT,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err?.response?.data?.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const postRedTechComment = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/redtech/comments/`, body, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({
      type: ACTIONS.POST_RED_TECH_COMMENT,
      payload: data,
    });
    snackBarUpdate({
      payload: {
        message: "Message Created",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.message;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getCentreComments =
  (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/center/center_comment/${url ? url : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_CENTER_COMMENTS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong!",
          status: true,
          type: "error",
        },
      })(dispatch);

      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getDisplayCities =
  (url: string, newUrl: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(`${Prefix.api}/core/displaycities/`, {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      });
      dispatch({ type: ACTIONS.GET_DISPLAY_CITIES, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const disabledCenter  =
  (id: any, body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/center/update/${id}`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.DISABLED_CENTER,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Center Updated Successfully",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const disabledSubCenter =
  (id: any, body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/center/center-address-edit/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.DISABLED_SUB_CENTER,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: " Sub Center Updated Successfully",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const updatePackage =
  (slug: any, body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/package/edit/${slug}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.UPDATE_PACKAGE,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Package Updated",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
