import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import MessageIcon from "@material-ui/icons/Message";
import CallIcon from "@material-ui/icons/Call";
import { getHoldCancelBooking } from "../actions/bookingActions";
import { TableFooter, TablePagination } from "@material-ui/core";
import Loader from "../components/Loader2";
import CommentsModal from "../components/Comments/comment";

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  tableContainer: {
    marginTop: "1rem",
    position: "sticky",
    top: "200px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

interface Props {
  loading: boolean;

  getHoldCancelBooking: any;
  cancelHoldBooking: any;
}

const HoldCancelledTable: React.FC<Props> = ({
  loading,

  getHoldCancelBooking,
  cancelHoldBooking,
}) => {
  const classes = useStyles();
  const [open2, setOpen2] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [raiseComplaint, setRaiseComplaint] = useState(false);

  const [leadId, setLeadId] = useState<number>(0);
  const [page, setPage] = useState(0);

  const [prscriptionLead, setPrescriptionLead] = useState<any>("");
  const [pLink, setPlink] = useState<any>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openPrescription = Boolean(anchorEl);
  const [fileType, setFileType] = useState<any>("");
  const [openPdf, setOpenPdf] = useState<boolean>(false);
  const history = useHistory();
  const [callButton, setCallButton] = useState<boolean>(true);

  useEffect(() => {
    getHoldCancelBooking();
    // getCities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleClick = (id: number) => {
    setOpen(true);
    setLeadId(id);
  };
  useEffect(() => {
    if (open2 === false) {
      setLeadId(0);
    }
  }, [open2]);

  return (
    <>
      <Grid container spacing={2} style={{ marginTop: "2rem" }}>
        <Grid item sm={12} md={6}>
          <h4>Cancelled Bookings</h4>
          <div style={{ width: "100%" }} className="data-table">
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ height: "500px" }}
          >
            {loading ? (
              <Loader />
            ) : (
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">Lead ID</StyledTableCell>
                    <StyledTableCell align="center">Booking ID</StyledTableCell>
                    <StyledTableCell align="center">
                      Booking Date
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Booking Status
                    </StyledTableCell>

                    <StyledTableCell align="center">Comments</StyledTableCell>
                    <StyledTableCell align="center">
                      Patient name
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {cancelHoldBooking &&
                    cancelHoldBooking.cancelled &&
                    cancelHoldBooking?.cancelled.length > 0 &&
                    cancelHoldBooking?.cancelled.map(
                      (data: any, index: any) => {
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell align="center">
                              {data?.lead_id}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {data?.id}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {data?.booking_date}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {data?.booking_status}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              <div>
                                <MessageIcon
                                  onClick={() => handleClick(data?.id)}
                                />
                              </div>
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {data?.customer_name}
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      }
                    )}
                </TableBody>
              </Table>
            )}
          </TableContainer>
          </div>
        </Grid>
        <Grid item sm={12} md={6}>
          <h4>Hold Bookings</h4>
          <div style={{ width: "100%" }} className="data-table">
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ height: "500px" }}
          >
            {loading ? (
              <Loader />
            ) : (
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">Lead ID</StyledTableCell>
                    <StyledTableCell align="center">Booking ID</StyledTableCell>
                    <StyledTableCell align="center">
                      Booking Date
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Booking Status
                    </StyledTableCell>

                    <StyledTableCell align="center">Comments</StyledTableCell>
                    <StyledTableCell align="center">
                      Patient name
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {cancelHoldBooking &&
                    cancelHoldBooking?.hold &&
                    cancelHoldBooking?.hold.length > 0 &&
                    cancelHoldBooking?.hold.map((data: any, index: any) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="center">
                            {data?.lead_id}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.id}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.booking_date}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.booking_status}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <div>
                              <MessageIcon
                                onClick={() => handleClick(data?.id)}
                              />
                            </div>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.customer_name}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            )}
          </TableContainer>
          </div>
        </Grid>
      </Grid>

      <CommentsModal open={open} setOpen={setOpen} leadId={leadId} />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.BookingReducer.loading,
  cancelHoldBooking: state.BookingReducer.cancelHoldBooking,
});

export default connect(mapStateToProps, {
  getHoldCancelBooking,
})(HoldCancelledTable);
