import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Card,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import {
  getPackage,
  updateBooking,
  getBookingById,
  getCoupons,
  getAvailableSlots,
  getCities,
  getSubCenterInfo,
  getPinCode,
  getImagingTimeslots,
  getMultiplePackage,
  getDisplayCities,
  getMapMyIndiaAccessToken,
  checkGeoFenceArea,
  checkGeoFenchMapMyIndia,
} from "../../../actions/CollectionCenterAdmin";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import Button from "@mui/material/Button";
import "./index.sass";
import { connect } from "react-redux";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router";
import { Chip } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { GENDER_CHOICE } from "../../constant";
import { GoogleMapsAPI } from "../../../../helpers/client-config";
import Geocode from "react-geocode";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
Geocode.setApiKey("AIzaSyBPjDlsHYhWtjt3FxgRPnTMXN-qJZ9hz0s");
Geocode.enableDebug();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 10px 10px",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      padding: "20px 20px 20px 20px",
      marginTop: "20px",
      width: "100%",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    packageLink: {
      color: "#ffffff",
      textDecoration: "none",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
  })
);

interface Props {
  getPackage: any;
  packageList: any;
  couponList: any;
  getCoupons: any;
  updateBooking: any;
  match: any;
  booking: any;
  getBookingById: any;
  booking_slots: any;
  getAvailableSlots: any;
  loading: boolean;
  getCities: any;
  cities: any;
  getSubCenterInfo: any;
  subCenter: any;
  getPinCode: any;
  getImagingTimeslots: any;
  pin_code: any;
  imagingTimeSlots: any;
  getMultiplePackage: any;
  multiplePackage: any;
  getDisplayCities: any;
  displayCities: any;
  checkGeoFenceArea: any;
  checkGeoFenceStatus: boolean;
  checkGeoFenchMapMyIndia: any;
  geoFencesMapMyIndia: any;
  getMapMyIndiaAccessToken: any;
  mapmyindiaAccessToken: any;
}

const EditBookingForm: React.FC<Props> = ({
  packageList,
  getPackage,
  couponList,
  getCoupons,
  updateBooking,
  match,
  booking,
  getBookingById,
  booking_slots,
  getAvailableSlots,
  getCities,
  cities,
  subCenter,
  loading,
  getSubCenterInfo,
  getPinCode,
  getImagingTimeslots,
  imagingTimeSlots,
  getMultiplePackage,
  multiplePackage,
  getDisplayCities,
  displayCities,
  checkGeoFenceArea,
  checkGeoFenceStatus,
  checkGeoFenchMapMyIndia,
  geoFencesMapMyIndia,
  getMapMyIndiaAccessToken,
  mapmyindiaAccessToken,
  pin_code,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [collection_date, setCollection_date] = useState<String>(
    booking.collection_date
  );
  const [booking_date, setBooking_date] = useState<string>(
    booking.booking_date
  );
  const [selectedSlot, setSelectedSlot] = useState<Number>(
    booking.collection_slot && booking.collection_slot.id
  );
  const [customer_name, setCustomer_name] = useState<String>(
    booking.customer_name
  );
  const [customer_designation, setCustomer_designation] = useState<string>();
  const [designation, setDesignation] = useState<String>();

  const [customer_age, setCustomer_age] = useState<String>(
    booking.customer_age
  );
  const [customer_gender, setCustomer_gender] = useState<string>();
  const [customer_phonenumber, setCustomer_phonenumber] = useState<String>(
    booking.customer_phonenumber
  );
  const [customer_alternatenumber, setCustomer_alternatenumber] =
    useState<String>(booking.customer_altphonenumber);
  const [customer_whatsapppnumber, setCustomer_whatsapppnumber] =
    useState<String>(booking.customer_whatsapppnumber);
  const [customer_email, setCustomer_email] = useState<String>(
    booking.customer_email
  );

  const [customer_longitude, setCustomerLongitude] = useState<any>(
    booking?.customer_longitude || ""
  );
  const [customer_latitude, setCustomerLatitude] = useState<any>(
    booking?.customer_latitude || ""
  );
  const [center_longitude, setCenterLongitude] = useState<any>(
    booking?.center_address?.longitude || ""
  );
  const [center_latitude, setCenterLatitude] = useState<any>(
    booking?.center_address?.latitude || ""
  );
  const [payment_mode, setPaymentMode] = useState<String>(
    booking?.payment_mode || "none"
  );
  const [customer_areapincode, setCustomer_areapincode] = useState<Number>(
    booking?.customer_areapincode?.id
  );
  const [customer_address, setCustomer_address] = useState<String>(
    booking.customer_address && booking.customer_address
  );
  const [customer_city, setCustomer_city] = useState<String>("");
  const [customer_aadhar, setCustomer_aadhar] = useState<String>(
    booking.customer_aadhar
  );
  const [passport_number, setPassportNumber] = useState<String>(
    booking.passport_number
  );
  const [adharPassport, setAdharPassport] = useState(
    booking.customer_aadhar !== "" ? "customer_aadhar" : "passport_number"
  );
  const [packageDetails, setPackageDetails] = useState<any>(booking.packages);
  const [cityDisplayName, setCityDisplayname] = useState<any>(
    booking.display_name
  );
  const [centre_address, setcentre_address] = useState<any>(
    booking?.center_address && booking?.center_address?.address
  );
  const [packageKey, setPackageKey] = useState<any>(0);
  const [redDisc, setRedDisc] = useState<any>(0);
  const [packages, setPackages] = useState<Number[]>(
    booking?.packages &&
      booking.packages.map((pack: any) => {
        return pack.id;
      })
  );
  const [coupon, setCoupon] = useState<Number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [price, setPrice] = useState<any>(
    booking.discounted_price && booking.discounted_price.total_price_package
  );
  const [discount, setDiscount] = useState<any>(
    booking?.discounted_price && booking?.discounted_price?.counpon_discount
  );
  const [totalPrice, setTotalPrice] = useState<any>(
    booking?.discounted_price && booking?.discounted_price?.final_total_price
  );
  const [extraCharge, setExtraCharge] = useState<boolean>(
    booking?.discounted_price && booking?.discounted_price?.phlebo_cost !== 0
  );
  const [cityName, setCityName] = useState<any>("");
  const [cityId, setCityId] = useState<any>(booking && booking.cityid);
  const [centerName, setCentername] = useState<String>(booking?.center?.name);
  const [centerId, setCenterId] = useState<any>(booking?.center?.id);
  const [centerKey, setCenterKey] = useState<any>(0);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [testType, setTestType] = useState<string>(
    booking?.packages && booking?.packages[0].type
  );
  const [mobileValidation, setMobileValidation] = useState({
    customer_alternatenumber: true,
    customer_phonenumber: true,
    customer_whatsapppnumber: true,
  });

  const bookingId = match.params.id;
  const [subCenterId, setSubCenterId] = useState<any>(
    booking?.center_address?.id
  );
  const [customer_landmark, setCustomer_landmark] = useState<String>(
    booking.customer_landmark || ""
  );
  const [subCenterDetails, setSubCenterDetails] = useState(
    booking.center_address
  );
  const [allPackage, setAllPackaage] = useState<boolean>(false);
  const [imgTimeSlots, setImgTimeSlots] = useState<any>([]);
  const [subCenterData, setSubCenterData] = useState<any>([]);
  const [centerMessage, setCenterMessage] = useState<string>("");
  const [singlePackage, setSinglePackage] = useState<any>({});
  const [couponMessage, setCouponMessage] = useState<string>("");
  const [tncMessage, setTncMessage] = useState<string>("");
  const [redCashCheck, setRedCashCheck] = useState<boolean>(false);
  const [isPaneOpen, setPaneOpen] = useState<boolean>(false);
  const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
  const [mapAddressObject, setMapAddressObject] = useState<any>({});
  const [accessToken, setAccessToken] = useState<any>("");
  //cc Admin Rights
  const [verification_remark, setVerification_remark] = useState<string>(
    booking.verification_remark
  );
  const [verification_status, setVerification_status] = useState<string>(
    booking.verification_status
  );
  const [booking_status, setBooking_status] = useState<string>(
    booking.booking_status
  );
  const [verification_status_d, setVerification_status_d] = useState<string>(
    booking?.status_disposition?.verification_status_d
  );
  const [verification_status_d_others, setVerification_status_d_others] =
    useState<string>(booking?.status_disposition?.verification_status_d);
  const [booking_status_d, setBooking_status_d] = useState<string>(
    booking?.status_disposition?.booking_status_d
  );
  const [booking_status_d_others, setBooking_status_d_others] =
    useState<string>(booking?.status_disposition?.booking_status_d);
  const [pickup_status_d, setPickup_status_d] = useState<string>(
    booking?.status_disposition?.pickup_status_d
  );
  const [pickup_status_d_others, setPickup_status_d_others] = useState<string>(
    booking?.status_disposition?.pickup_status_d
  );
  const [phlebo_verification_remark, setPhlebo_verification_remark] =
    useState<string>(booking?.status_disposition?.phlebo_verification_remark);
  const [phlebo_incentive, setPhlebo_incentive] = useState(
    booking.phlebo_incentive
  );
  const [phlebo_convenience, setPhlebo_convenience] = useState(
    booking.phlebo_convenience
  );
  const [pickup_status, setPickup_status] = useState(booking?.pickup_status);
  const [pickup_amount, setPickup_amount] = useState(0);
  const [pickup_time, setPickup_time] = useState(booking.pickup_time);
  const [remarks, setRemarks] = useState<string>(booking?.remarks);
  const [rm_comment, setRMComment] = useState<any>();
  const [rca_comment, setRCAComment] = useState<any>();
  const [discountPercentage, setDiscountPercentage] = useState<any>();
  const [pickup_date, setPickup_date] = useState(booking.pickup_date);
  // const [payment_source, setPayment_source] = useState(booking.payment_source)
  //cc admin right

  const timer = useRef<any>(0);

  useEffect(() => {
    if (booking) {
      setCollection_date(booking?.collection_date);
      setBooking_date(booking?.booking_date);
      setSelectedSlot(booking.collection_slot && booking.collection_slot.id);
      setCustomer_name(booking?.customer_name);
      setCustomer_age(booking.customer_age);
      setCustomer_phonenumber(booking.customer_phonenumber);
      setCustomer_alternatenumber(booking.customer_altphonenumber);
      setCustomer_whatsapppnumber(booking.customer_whatsapppnumber);
      setCustomer_email(booking.customer_email);
      setCustomer_landmark(booking?.customer_landmark);
      setCustomer_address(booking.customer_address && booking.customer_address);
      setCustomer_aadhar(booking.customer_aadhar);
      setAdharPassport(
        booking.customer_aadhar !== "" ? "customer_aadhar" : "passport_number"
      );
      setPackageDetails(booking.packages || []);
      setPackages(
        booking.packages &&
          booking.packages.map((pack: any) => {
            return pack.id;
          })
      );
      setSubCenterId(booking?.center_address?.id);
      setTestType(booking?.packages && booking?.packages[0]?.type);
      setPrice(
        booking?.discounted_price &&
          booking.discounted_price.total_price_package
      );
      setDiscount(
        booking.discounted_price && booking.discounted_price.counpon_discount
      );
      setTotalPrice(
        booking.discounted_price && booking.discounted_price.final_total_price
      );
      setExtraCharge(
        booking.discounted_price && booking.discounted_price.phlebo_cost !== 0
      );
      setCityName(booking?.display_name);
      setCityId(booking && booking.cityid);
      getPinCode(
        booking?.customer_areapincode &&
          `${booking?.customer_areapincode?.pincode}&show_code=true`
      );
      setSelectedSlot(booking?.collection_slot && booking?.collection_slot?.id);
      setCustomerLongitude(booking?.customer_longitude);
      setCustomerLatitude(booking?.customer_latitude);
      setSubCenterDetails(booking?.center_address);
      setVerification_status_d(
        booking?.status_disposition?.verification_status_d
      );
      setBooking_status_d(booking?.status_disposition?.booking_status_d);
      setPickup_status_d(booking?.status_disposition?.pickup_status_d);
    }
  }, [booking]);

  useEffect(() => {
    if (booking?.coupon_applied) {
      setCoupon(booking?.coupon_applied.id);
      setCouponCode(booking?.coupon_applied?.name);
    }
    if (booking?.designation && booking?.customer_gender) {
      setCustomer_designation(booking?.designation);
      const findGender = GENDER_CHOICE.filter(
        (el: any) => el.db_name == booking?.designation
      );
      if (
        booking?.designation == "Master" &&
        booking?.customer_gender == "male"
      ) {
        setDesignation("Baby Boy");
      } else if (
        booking?.designation == "Master" &&
        booking?.customer_gender == "female"
      ) {
        setDesignation("Baby Girl");
      } else {
        findGender.length > 0 && setDesignation(findGender[0].display_name);
      }
      setCustomer_gender(booking?.customer_gender);
    }
    setCityName(booking?.display_name);
    getDisplayCities();
  }, []);

  useEffect(() => {
    const percentage = ((+discount / (totalPrice + +discount)) * 100).toFixed(
      2
    );
    setDiscountPercentage(percentage);
  }, [discount, totalPrice]);

  useEffect(() => {
    getCoupons("?org_type=imaging");
    getCities("", "?is_imaging=true");
    getMapMyIndiaAccessToken();
  }, []);
  useEffect(() => {
    if (subCenterId !== "" && packages !== undefined) {
      getImagingTimeslots(
        `?packages=${packages}&collection_date=${collection_date}&center_address=${subCenterId}`
      );
    }
  }, [subCenterId]);
  useEffect(() => {
    if (subCenterId !== "") {
      setImgTimeSlots(imagingTimeSlots);
    }
  });
  useEffect(() => {
    if (testType !== undefined) {
      if (allPackage) {
        getPackage(`org_type=imaging&type=${testType}`);
      } else {
        getPackage(
          `center_address=${subCenterId}&org_type=imaging&type=${testType}`
        );
      }
    }
  }, [allPackage, testType]);

  useEffect(() => {
    if (packages !== undefined) {
      getSubCenterInfo(
        `?imaging=true&customer_latitude=${customer_latitude}&customer_longitude=${customer_longitude}&package=${packages}&display_name=${cityName}`
      );
    }
  }, []);
  useEffect(() => {
    if (packages !== undefined) {
      getSubCenterInfo(
        `?imaging=true&customer_latitude=${customer_latitude}&customer_longitude=${customer_longitude}&package=${packages}&display_name=${cityName}`
      );
    }
  }, [packages]);

  useEffect(() => {
    getBookingById(`${bookingId}`);
  }, [bookingId]);

  const handlePackages = (pack: any) => {
    setPackageDetails(pack);
    let packIds: any = [];
    pack &&
      pack.length > 0 &&
      pack.map((item: any) => {
        packIds.push(item.id);
        setPackages(packIds);
      });
    // multiplePackage && multiplePackage.results && multiplePackage.results.length > 0 && multiplePackage.results.map((item: any) => {
    //     if (item.package_center_address_prices === null) {
    //         accumulatedPrice = accumulatedPrice + item.offer_price
    //     }
    //     else {
    //         accumulatedPrice = accumulatedPrice + item.package_center_address_prices.offer_price
    //     }

    //     if (accumulatedPrice < min_phlebo_cost) {
    //         setExtraCharge(false)
    //     } else {
    //         setExtraCharge(false)
    //     }
    // }
    // )
    // setPrice(Math.floor(accumulatedPrice))
    // setDiscount(0)
    // setTotalPrice(Math.floor(accumulatedPrice))
    // setCoupon(0)
    // setCouponCode("")
  };
  useEffect(() => {
    const min_phlebo_cost =
      packageList &&
      packageList?.results &&
      packageList?.results.length > 0 &&
      packageList?.results[0].min_booking_amount_for_phlebo;
    let accumulatedPrice: any = 0;
    multiplePackage &&
      multiplePackage?.results &&
      multiplePackage?.results?.length > 0 &&
      multiplePackage?.results.map((item: any) => {
        if (item.package_center_address_prices === null) {
          accumulatedPrice = accumulatedPrice + item?.offer_price;
        } else {
          accumulatedPrice =
            accumulatedPrice + item?.package_center_address_prices?.offer_price;
        }

        if (accumulatedPrice < min_phlebo_cost) {
          setExtraCharge(false);
        } else {
          setExtraCharge(false);
        }
      });
    setPrice(Math.floor(accumulatedPrice));
    // setDiscount(0);
    setTotalPrice(Math.floor(accumulatedPrice));
    setCoupon(0);
    setCouponCode("");
  }, [multiplePackage]);

  useEffect(() => {
    if (extraCharge) {
      setTotalPrice(Math.floor(price - discount + 200));
    } else {
      setTotalPrice(Math.floor(price - discount));
    }
  }, [price, discount, extraCharge, multiplePackage]);

  useEffect(() => {
    if (totalPrice < 0) {
      setTotalPrice(0);
    }
  }, [totalPrice, multiplePackage]);

  const applyCoupon = () => {
    if (couponCode === "") {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
      return;
    }
    const appliedCoupon =
      couponList.results.find((coup: any) => coup.id === coupon) ||
      couponList.results.find((coup: any) => coup.code === couponCode);
    if (appliedCoupon && appliedCoupon.minimum_price < totalPrice) {
      setCoupon(appliedCoupon.id);
      if (appliedCoupon.discount_type.toLowerCase() === "percentage") {
        if (appliedCoupon.is_red_cash) {
          setDiscount(Math.floor(price));
        } else {
          setDiscount(Math.floor((price * appliedCoupon.discount) / 100));
        }
      }
      if (appliedCoupon.discount_type.toLowerCase() === "amount") {
        if (appliedCoupon.is_red_cash) {
          setDiscount(0);
        } else {
          setDiscount(Math.floor(appliedCoupon.discount));
        }
      }
    } else {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
    }
  };
  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(customer_phonenumber && customer_phonenumber[0]) < 6 ||
        (customer_phonenumber && customer_phonenumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_phonenumber: false,
        }));
      }
    }
    if (type === "alternate") {
      if (
        parseInt(customer_alternatenumber && customer_alternatenumber[0]) < 6 ||
        (customer_alternatenumber && customer_alternatenumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_alternatenumber: false,
        }));
      }
    }
    if (type === "whatsapp") {
      if (
        parseInt(customer_whatsapppnumber && customer_whatsapppnumber[0]) < 6 ||
        (customer_whatsapppnumber && customer_whatsapppnumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_whatsapppnumber: false,
        }));
      }
    }
  };
  const submitForm = async (e: any) => {
    e.preventDefault();
    const data: any = {
      booking_date: moment(new Date()).format("YYYY-MM-DD"),
      collection_date: collection_date,
      collection_slot: selectedSlot,
      customer_name: customer_name,
      customer_age: customer_age,
      customer_gender: customer_gender,
      customer_email: customer_email,
      customer_phonenumber: customer_phonenumber,
      customer_altphonenumber: customer_alternatenumber,
      customer_whatsapppnumber: customer_whatsapppnumber,
      customer_address: customer_landmark,
      centre_address: centre_address,
      customer_landmark: customer_landmark,
      customer_longitude: customer_longitude,
      customer_latitude: customer_latitude,
      customer_areapincode: customer_areapincode,
      customer_aadhar: customer_aadhar,
      passport_number: passport_number === null ? "" : passport_number,
      packages: packages,
      coupon_applied: coupon,
      designation: customer_designation,
      center: centerId,
      display_name: cityName,
      center_address: subCenterId,
      verification_status: verification_status,
      booking_status:
        verification_status === "cancelled" ? "cancelled" : booking_status,
      verification_remark: verification_remark,
      pickup_status:
        verification_status === "cancelled" ? "cancelled" : pickup_status,
      pickup_receive_amount: pickup_amount,
      remarks: remarks,
      booking_status_d:
        booking_status_d === "others"
          ? booking_status_d_others
          : booking_status_d,
      verification_status_d:
        verification_status_d === "others"
          ? verification_status_d_others
          : verification_status_d,
      pickup_status_d:
        pickup_status_d === "others" ? pickup_status_d_others : pickup_status_d,
      // payment_source: payment_source,
      payment_mode: payment_mode,
    };
    // if(data["verification_status"] === "cancelled") {
    //   data["booking_status"] = "cancelled";
    //   data["pickup_status"] = "cancelled";
    // }
    // if(data["booking_status"] = "cancelled") {
    //   data["pickup_status"] = "cancelled";
    // }
    if (data["pickup_status_d"] === null || pickup_status_d_others === null) {
      delete data["pickup_status_d"];
    }
    if (data["designation"] === null || customer_designation === null) {
      delete data["designation"];
    }

    if (data["pickup_receive_amount"] === 0 || pickup_amount === null) {
      delete data["pickup_receive_amount"];
    }

    if (
      data["phlebo_convenience"] === 0 ||
      phlebo_convenience === "" ||
      phlebo_convenience === null
    ) {
      delete data["phlebo_convenience"];
    }

    if (
      data["phlebo_incentive"] === 0 ||
      phlebo_incentive === "" ||
      phlebo_incentive === null
    ) {
      delete data["phlebo_incentive"];
    }

    if (
      data["verification_remark"] === null ||
      verification_remark === "" ||
      verification_remark === null
    ) {
      delete data["verification_remark"];
    }
    if (data["remarks"] === null || remarks === "" || remarks === null) {
      delete data["remarks"];
    }
    if (data["rca_comment"] === null || rca_comment === null) {
      delete data["rca_comment"];
    }
    if (data["rm_comment"] === null || rm_comment === null) {
      delete data["rm_comment"];
    }

    if (
      data["phlebo_verification_remark"] === null ||
      phlebo_verification_remark === "" ||
      phlebo_verification_remark === null
    ) {
      delete data["phlebo_verification_remark"];
    }

    // if (data['payment_source'] === null || payment_source === "") {
    //   delete data['payment_source'];
    // }

    if (payment_mode === "none" && pickup_status === "confirmed") {
      alert("Without Select Payment Mode you can't confirm booking");
    }

    if (data["coupon_applied"] === 0 || couponCode === "") {
      delete data["coupon_applied"];
    }
    if (pickup_status === "confirmed") {
      alert("Invoice is sent to customer");
      await updateBooking(data, bookingId);

      history.push(`/dashboard/cca/booking-view/${bookingId}`);
    } else {
      await updateBooking(data, bookingId);

      history.push(`/dashboard/cca/booking-view/${bookingId}`);
    }
  };

  const changeCollectionDate = (e: any) => {
    setSelectedSlot(0);
    setCollection_date(e.target.value as string);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdharPassport((event.target as HTMLInputElement).value);
  };

  const titleSelection = (title: string) => {
    const findGender = GENDER_CHOICE.filter(
      (el: any) => el.display_name == title
    );
    setDesignation(title);
    setCustomer_designation(findGender[0].db_name);
    setCustomer_gender(findGender[0].gender);
  };

  const handleSubCenter = (val: any) => {
    setCenterId(val.center.id);
    setSubCenterId(val.id);
    setCenterLatitude(val.latitude);
    setCenterLongitude(val.logitude);
    setCustomer_address(val.address);
    setcentre_address(val?.center_address?.address);
    setCustomer_areapincode(parseInt(val.location.id));
    setCustomer_city(val.location.city);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleresetPackage = () => {
    setSubCenterId("");
    setSubCenterDetails({});
    setPackageDetails([]);
    setPackages([]);
    setPackageKey(selectedSlot);
    setSelectedSlot(0);
    setAllPackaage(true);
    setOpen(false);
    setImgTimeSlots([]);
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (subCenterId !== "" && packages !== undefined) {
      getMultiplePackage(
        `?multiple_ids=${packages}&center_address=${subCenterId}&imaging=true&org_type=imaging`
      );
    }
  }, [packages, subCenterId]);
  useEffect(() => {
    if (subCenter) {
      setSubCenterData(subCenter);
    }
  }, [subCenter]);
  useEffect(() => {
    // if (packages.length > 0) {
    //   if (subCenterData.length === undefined) {
    //     setCenterMessage("No Center Available for this package");
    //   } else {
    //     setCenterMessage("");
    //   }
    // }
  }, [subCenterData]);

  useEffect(() => {
    if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
      setCustomerLatitude(geoFencesMapMyIndia?.latitude);
      setCustomerLongitude(geoFencesMapMyIndia?.longitude);
    }
  }, [geoFencesMapMyIndia]);

  useEffect(() => {
    if (mapmyindiaAccessToken?.accesstoken) {
      setAccessToken(mapmyindiaAccessToken?.accesstoken);
    }
  }, [mapmyindiaAccessToken]);

  useEffect(() => {
    if (mapAddressObject?.eLoc) {
      getPinCode(mapAddressObject?.addressTokens?.pincode);
      setCustomer_landmark(mapAddressObject?.placeAddress);
      checkGeoFenchMapMyIndia(mapAddressObject?.eLoc, "NA");
    }
  }, [mapAddressObject]);

  useEffect(() => {
    if (pin_code?.results && pin_code?.results.length > 0) {
      setCityId(pin_code?.results[0].cityid);
    }
  }, [pin_code]);

  const locationAutoCompleteSearch = (val: string) => {
    if (val.length > 2) {
      setSuggestedAddres([]);
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName = "jsonp_callback_" + "data" + id;
        window[callbackName] = (data: any) => {
          delete window[callbackName];
          const ele = document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          resolve(data?.suggestedLocations);
          setSuggestedAddres(data?.suggestedLocations);
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          document.getElementsByTagName("head")[0] ||
          document.body ||
          document.documentElement
        ).appendChild(script);
      });
    }
  };
  const hanbdlePaymentMode = (e: any) => {
    setPaymentMode(e.target.value as string);
    if (e.target.value === "cash") {
      setPickup_amount(booking.receivable_amount);
    } else {
      setPickup_amount(0);
    }
  };
  useEffect(() => {
    if (pickup_status === "confirmed") {
      setPickup_amount(totalPrice);
    } else {
      setPickup_amount(0);
      setPaymentMode("none");
    }
  }, [pickup_status]);

  const VERIFICATION_DISPOSITION = [
    { id: 1, text: "Verification Done", status: "confirmed" },
    { id: 2, text: "Reschedule ", status: "rescheduled" },
    { id: 3, text: "Better price from somewhere else", status: "cancelled" },
    { id: 3, text: "Duplicate Booking", status: "cancelled" },
    { id: 5, text: "System Created Booking", status: "cancelled" },
    { id: 6, text: "Misselling", status: "cancelled" },
    { id: 7, text: "Ringing", status: "pending" },
    { id: 8, text: "Switch OFF", status: "pending" },
    { id: 9, text: "Hold for now confirm after sometime ", status: "pending" },
    { id: 10, text: "Incoming facility not available ", status: "pending" },
    {
      id: 11,
      text: "Booking made with invalid contact no.",
      status: "pending",
    },
    { id: 12, text: "Resampling", status: "resampling" },
    { id: 13, text: "Patient Not Reachable", status: "cancelled" },
    { id: 14, text: "Non Availability of Near By Lab", status: "cancelled" },
    { id: 15, text: "others", status: "confirmed" },
    { id: 16, text: "others", status: "rescheduled" },
    { id: 17, text: "others", status: "pending" },
    { id: 18, text: "others", status: "cancelled" },
  ];
  const BOOKING_DISPOSITION = [
    { id: 1, text: "Scan done & Patient Satisfy", status: "confirmed" },
    { id: 2, text: "Doctor Unavailibility ", status: "rescheduled" },
    { id: 3, text: "Time slot not available", status: "rescheduled" },
    { id: 4, text: "Pre test report required", status: "rescheduled" },
    { id: 5, text: "Medical Reason", status: "rescheduled" },
    { id: 6, text: "Financial Issue", status: "rescheduled" },
    { id: 7, text: "Personal Reason", status: "rescheduled" },
    { id: 8, text: "Price Issue ", status: "cancelled" },
    { id: 9, text: "Non availability of near by lab", status: "cancelled" },
    { id: 10, text: "Doctor Unavailability", status: "cancelled" },
    { id: 11, text: "Already Test Done", status: "cancelled" },
    { id: 12, text: "Wants to go dr advice lab", status: "cancelled" },
    { id: 13, text: "Doctor Advice", status: "cancelled" },
    { id: 13, text: "Wrong test booked", status: "cancelled" },
    { id: 14, text: "Center Review on google", status: "cancelled" },
    { id: 15, text: "CGHS Pannel", status: "cancelled" },
    { id: 16, text: "Non availability of Test (Method )", status: "cancelled" },
    { id: 17, text: "Medical Reason", status: "cancelled" },
    { id: 18, text: "Financial Issue", status: "cancelled" },
    { id: 19, text: "Patient not reachable", status: "hold" },
    { id: 20, text: "Will confirm timing later", status: "hold" },
    { id: 21, text: "Resampling", status: "resampling" },
    { id: 22, text: "Pending", status: "pending" },
    { id: 23, text: "Ready to go for centre", status: "confirmed" },
    { id: 24, text: "Patient not Reachable", status: "rescheduled" },
    { id: 25, text: "others", status: "pending" },
    { id: 26, text: "others", status: "confirmed" },
    { id: 27, text: "others", status: "cancelled" },
    { id: 28, text: "others", status: "rescheduled" },
    { id: 29, text: "others", status: "hold" },
  ];
  const PICKUP_DISPOSITION = [
    { id: 1, text: "Scan Done & patient satisfy", status: "confirmed" },
    {
      id: 2,
      text: "Test not matched according to prescription",
      status: "cancelled",
    },
    { id: 3, text: "Dr not available", status: "cancelled" },
    { id: 4, text: "Same center through other source", status: "cancelled" },
    { id: 5, text: "Machine not Working", status: "cancelled" },
    { id: 6, text: "Anesthisia not available", status: "cancelled" },
    { id: 7, text: "Waiting Time", status: "cancelled" },
    { id: 8, text: "Behaviour of lab staff", status: "cancelled" },
    { id: 9, text: "Machine not Working", status: "reschedule" },
    { id: 10, text: "Dr unavailability", status: "reschedule" },
    { id: 11, text: "Waiting Time", status: "reschedule" },
    { id: 11, text: "Pending", status: "pending" },
    { id: 12, text: "Lab Experience", status: "cancelled" },
    { id: 12, text: "others", status: "confirmed" },
    { id: 12, text: "others", status: "reschedule" },
    { id: 12, text: "others", status: "pending" },
    { id: 12, text: "others", status: "cancelled" },
  ];
  const VERIFICATION_DISP = VERIFICATION_DISPOSITION.filter(
    (data: any) => data.status === verification_status
  );
  const BOOKING_DISP = BOOKING_DISPOSITION.filter(
    (data: any) => data.status === booking_status
  );
  const PICKUP_DISP = PICKUP_DISPOSITION.filter(
    (data: any) => data.status === pickup_status
  );

  useEffect(() => {
    setBooking_status_d(booking?.status_disposition?.booking_status_d);
  }, [booking_status]);
  useEffect(() => {
    setPickup_status_d(booking?.status_disposition?.pickup_status_d);
  }, [pickup_status]);
  useEffect(() => {
    setVerification_status_d(
      booking?.status_disposition?.verification_status_d
    );
  }, [verification_status]);
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.contentHeader}>
        <h2>EDIT IMAGING BOOKING</h2>
      </div>
      <Paper className={classes.paper} elevation={15}>
        <form onSubmit={submitForm}>
          <Grid container spacing={3} style={{ margin: "auto" }}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Booking date</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    className="input"
                    name="collection_date"
                    type="date"
                    value={booking_date || ""}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "5.5px 14px",
                    }}
                    required
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Scanning date</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    className="input"
                    name="collection_date"
                    type="date"
                    value={
                      (collection_date && collection_date.toString()) || ""
                    }
                    // onChange={(e) => setCollection_date(e.target.value as string)}
                    onChange={(e) => changeCollectionDate(e)}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "5.5px 14px",
                    }}
                    // min={`${new Date().getFullYear()}-${(new Date().getMonth() + 1) < 10 ? `0${(new Date().getMonth() + 1)}` : (new Date().getMonth() + 1)}-${new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()}`}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>City Name</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="cityname"
                    onChange={(event, newValue) => {
                      let cName: any = "";
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        cName = obj;
                      }
                      setCityName(cName);
                    }}
                    options={displayCities.cities || []}
                    freeSolo
                    blurOnSelect
                    inputValue={cityName}
                    onInputChange={(event, newInputValue) => {
                      setCityName(newInputValue);
                    }}
                    aria-required
                    limitTags={1}
                    getOptionLabel={(option: any) => option}
                    getOptionDisabled={(option: any) => {
                      const cName = displayCities.cities.indexOf(
                        booking.display_name
                      );

                      return option === displayCities.cities[cName];
                    }}
                    disableCloseOnSelect
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="City Name"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Customer Address</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="colony-area-sector"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setMapAddressObject(obj);
                      }
                    }}
                    options={suggestedAddress ? suggestedAddress : []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    getOptionLabel={(option: any) => `${option?.placeAddress}`}
                    filterOptions={(option: any) => option}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        locationAutoCompleteSearch(newInputValue);
                      }, 500);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Search Address"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        required
                      />
                    )}
                    defaultValue={{ placeAddress: customer_landmark }}
                  />
                </Grid>
                {customer_latitude !== "" ? (
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {errorMessage}
                  </div>
                ) : (
                  ""
                )}
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Test Type</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    className="input"
                    name="testType"
                    variant="outlined"
                    value={testType}
                    style={{ width: "100%", margin: "0" }}
                    onChange={(e) => setTestType(e.target.value as string)}
                  >
                    <MenuItem value="XRay">X-Ray</MenuItem>
                    <MenuItem value="MRI">MRI</MenuItem>
                    <MenuItem value="USG">USG</MenuItem>
                    <MenuItem value="PET">PET SCAN</MenuItem>
                    <MenuItem value="NUC">NUCLEAR</MenuItem>
                    <MenuItem value="DEXA">DEXA</MenuItem>
                    <MenuItem value="MAM">Mammography</MenuItem>
                    <MenuItem value="CAR">Cardio</MenuItem>
                    <MenuItem value="NEU">Neurology</MenuItem>
                    <MenuItem value="CT">CT</MenuItem>
                    <MenuItem value="CBCT">CBCT</MenuItem>
                    <MenuItem value="PFT">PFT</MenuItem>
                    <MenuItem value="Audiology">Audiology</MenuItem>
                    <MenuItem value="Gastroenterology">
                      Gastroenterology
                    </MenuItem>
                    <MenuItem value="Gyne">GYNE</MenuItem>
                    <MenuItem value="PAC">Path At Centre</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Test Name</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="package"
                    key={packageKey}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let packs: any = [];
                        newValue.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          packs.push(obj);
                        });
                        handlePackages(packs);
                      }
                    }}
                    multiple
                    options={packageList.results}
                    value={packageDetails}
                    disableClearable
                    limitTags={0}
                    getOptionLabel={(option: any) =>
                      option.name && option.package_center_address_prices
                        ? option.name.toString()
                        : option.name
                    }
                    getOptionDisabled={(option: any) => {
                      const packID = packageDetails.map((pack: any) => pack.id);

                      return packID.includes(option.id);
                    }}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getPackage(
                          allPackage
                            ? `org_type=imaging&code=${newInputValue}&type=${testType}`
                            : `center_address=${subCenterId}&org_type=imaging&code=${newInputValue}&type=${testType}`
                        );
                      }, 1000);
                    }}
                    freeSolo
                    disableCloseOnSelect
                    renderTags={(value: string[], getTagProps) =>
                      value.map((option: any, index: number) => (
                        <Chip
                          variant="default"
                          color="primary"
                          label={
                            option.name && option.package_center_address_prices
                              ? option.name.toString() +
                                ", " +
                                option.package_center_address_prices.tat_time
                              : option.name + ", " + option.tat_time
                          }
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Package"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        className="input"
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Center</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="subcenter"
                    key={centerKey}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        handleSubCenter(obj as String);
                      }
                    }}
                    options={subCenterData.length > 0 ? subCenterData : []}
                    freeSolo
                    blurOnSelect
                    value={subCenterDetails}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getSubCenterInfo(
                          `?imaging=true&name=${newInputValue}&customer_latitude=${customer_latitude}&customer_longitude=${customer_longitude}&package=${packages}&display_name=${cityName}`
                        );
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) => `${option?.address}`}
                    getOptionDisabled={(option: any) => {
                      const packID = subCenterDetails.id;
                      return option.id;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Sub Center"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        className="input"
                        required
                      />
                    )}
                    disabled={packages?.length === 0}
                  />
                  <p style={{ color: "red" }}>{centerMessage}</p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                className="sampleCollectionTimeImaging"
                style={{ height: "375px!important", overflow: "auto" }}
              >
                <p style={{ marginBottom: "1rem" }}>Scanning Time</p>
                <div className={classes.slotContainer}>
                  {imgTimeSlots &&
                    imgTimeSlots?.results &&
                    imgTimeSlots?.results?.length > 0 &&
                    imgTimeSlots.results.map((slot: any) => {
                      return (
                        imgTimeSlots.results.length > 0 && (
                          <Card
                            key={slot.id}
                            className={
                              slot.id === selectedSlot
                                ? classes.selectedSlot
                                : classes.slot
                            }
                            onClick={() => setSelectedSlot(slot.id as Number)}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                color: "black",
                                fontSize: "14px",
                              }}
                            >
                              {slot.start_time} onwards
                            </p>
                            <p style={{ textAlign: "center", color: "green" }}>
                              {" "}
                              {/* Availabe slots: {slot.available_slots} */}
                            </p>
                          </Card>
                        )
                      );
                    })}
                </div>
              </Card>
              <Grid item xs={12} md={6}>
                <p>
                  {" "}
                  <span
                    onClick={handleClickOpen}
                    style={{ color: "#ab003c", cursor: "pointer" }}
                  >
                    click here
                  </span>{" "}
                  to View all Package
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            spacing={3}
            style={{ margin: "1rem auto", background: "#eff" }}
          >
            <Grid item xs>
              <p>Title</p>
              <Select
                className="input"
                name="customer_designation"
                variant="outlined"
                value={designation || ""}
                style={{ width: "100%", margin: "0" }}
                onChange={(e) => titleSelection(e.target.value as string)}
                required
              >
                <MenuItem value="Mrs">Mrs</MenuItem>
                <MenuItem value="Miss">Miss</MenuItem>
                <MenuItem value="Ms">Ms</MenuItem>
                <MenuItem value="Smt">Smt</MenuItem>
                <MenuItem value="Mr">Mr</MenuItem>
                <MenuItem value="Baby Boy">Baby Boy</MenuItem>
                <MenuItem value="Baby Girl">Baby Girl</MenuItem>
              </Select>
            </Grid>
            <Grid item xs>
              <p>Customer Name</p>
              <TextField
                name="customer_name"
                type="text"
                placeholder="Enter Name"
                value={customer_name}
                className="input"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%", margin: "0" }}
                onChange={(e) =>
                  setCustomer_name(
                    e.target.value.replace(/[^A-Za-z- ']/, "") as String
                  )
                }
                required
              />
            </Grid>
            <Grid item xs>
              <p>Age</p>
              <TextField
                name="customer_age"
                type="number"
                placeholder="Enter Age"
                value={customer_age}
                className="input"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%", margin: "0" }}
                inputProps={{ min: 0, max: 150 }}
                onChange={(e) => {
                  let ageValue = parseInt(e.target.value);
                  if (ageValue > 150) ageValue = 150;
                  if (ageValue < 0) ageValue = 0;
                  e.target.value = ageValue.toString();
                  setCustomer_age(e.target.value as any);
                }}
                required
              />
            </Grid>
            <Grid item xs>
              <p>Gender</p>
              <TextField
                className="input"
                name="customer_gender"
                variant="outlined"
                value={
                  customer_gender === "male"
                    ? "Male"
                    : customer_gender === "female"
                    ? "Female"
                    : ""
                }
                style={{ width: "100%", margin: "0", padding: "0" }}
                onChange={(e) => setCustomer_gender(e.target.value as string)}
                required
                disabled
              ></TextField>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ margin: "1rem auto" }}
          >
            <Grid item xs={12} md={5}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Mobile Number</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="customer_phonenumber"
                    type="text"
                    value={customer_phonenumber}
                    placeholder="Enter Mobile Number"
                    className="input"
                    variant="outlined"
                    disabled
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_phonenumber(e.target.value as String)
                    }
                    onBlur={() => verifyMobile("mobile")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_phonenumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_phonenumber &&
                      "Incorrect Phone Number"
                    }
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Alternate Mobile Number</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="customer_alternatenumber"
                    type="text"
                    value={customer_alternatenumber}
                    placeholder="Enter Alternate Mobile Number"
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_alternatenumber(e.target.value as String)
                    }
                    required
                    onBlur={() => verifyMobile("whatsapp")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_whatsapppnumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_whatsapppnumber &&
                      "Incorrect Whatsapp Number"
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Email</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="customer_email"
                    type="email"
                    placeholder="Enter Email"
                    value={customer_email}
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_email(e.target.value as String)
                    }
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Whatsapp Number</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="customer_whatsapppnumber"
                    type="text"
                    placeholder="Enter Whatsapp Number"
                    value={customer_whatsapppnumber}
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_whatsapppnumber(e.target.value as String)
                    }
                    onBlur={() => verifyMobile("mobile")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_phonenumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_phonenumber &&
                      "Incorrect Phone Number"
                    }
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Aadhaar/Passport</FormLabel>
                    <RadioGroup
                      aria-label="Choose"
                      name="adharpassport"
                      value={adharPassport}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="customer_aadhar"
                        control={<Radio />}
                        label="Aadhaar Number"
                      />
                      <FormControlLabel
                        value="passport_number"
                        control={<Radio />}
                        label="Passport Number"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    className="input"
                    name="customer_aadhar"
                    type="text"
                    placeholder={
                      adharPassport === "customer_aadhar"
                        ? "Enter Aadhaar Number"
                        : "Enter Passport Number"
                    }
                    value={
                      adharPassport === "customer_aadhar"
                        ? customer_aadhar
                        : passport_number
                    }
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onInput={(e: any) => {
                      e.target.value =
                        adharPassport === "customer_aadhar"
                          ? e.target.value.toString().slice(0, 12)
                          : e.target.value.toString().slice(0, 10);
                    }}
                    onChange={(e) =>
                      adharPassport === "customer_aadhar"
                        ? setCustomer_aadhar(
                            e.target.value.replace(/[^0-9]/, "") as String
                          )
                        : setPassportNumber(
                            e.target.value.replace(/[^A-Z0-9]/, "") as String
                          )
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={7} style={{ padding: "10px" }}>
              <div className="price-details-new-booking">
                <table style={{ width: "100%", margin: "0" }}>
                  <thead>
                    <tr>
                      <td className="cell" align="center">
                        S No.
                      </td>
                      <td className="cell" align="center">
                        Name
                      </td>
                      <td className="cell" align="center">
                        Package
                      </td>
                      <td className="cell" align="center">
                        Price
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="cell" align="center">
                        1
                      </td>
                      <td className="cell" align="left">
                        {customer_designation} {customer_name}
                      </td>
                      <td className="cell" align="left">
                        {multiplePackage &&
                          multiplePackage?.results &&
                          multiplePackage?.results?.length > 0 &&
                          multiplePackage?.results?.map(
                            (packageItem: any, index: any) => {
                              return (
                                <ul>
                                  <li>
                                    <a
                                      onClick={() => {
                                        setSinglePackage(packageItem);
                                        setPaneOpen(true);
                                      }}
                                      className={classes.packageLink}
                                    >
                                      {packageItem?.name}
                                      <br /> DESCRIPTION :{" "}
                                      {packageItem?.description}
                                      <br />
                                      TaT :{" "}
                                      {packageItem
                                        ?.package_center_address_prices
                                        ?.tat_time
                                        ? packageItem
                                            .package_center_address_prices
                                            .tat_time
                                        : packageItem?.tat_time}
                                      <br />
                                      Price :{" "}
                                      {packageItem.package_center_address_prices
                                        ? packageItem
                                            ?.package_center_address_prices
                                            ?.offer_price
                                        : packageItem.offer_price}
                                    </a>
                                  </li>
                                </ul>
                              );
                            }
                          )}
                      </td>
                      <td className="cell" align="center">
                        ₹ {Math.floor(totalPrice)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4}>
                        <table style={{ width: "100%" }}>
                          <tr>
                            <td
                              className="cell"
                              align="left"
                              rowSpan={3}
                              colSpan={2}
                              style={{ width: "60%" }}
                              valign="top"
                            >
                              <Grid container spacing={1} direction="row">
                                <Grid item xs={12}>
                                  <p>Enter Coupon</p>
                                </Grid>
                                <Grid item xs={8}>
                                  <Autocomplete
                                    id="coupon"
                                    onChange={(event, newValue) => {
                                      if (newValue) {
                                        let obj = JSON.parse(
                                          JSON.stringify(newValue, null, " ")
                                        );
                                        setCoupon(obj.id as Number);
                                        setCouponCode(obj.code as string);
                                      }
                                    }}
                                    inputValue={couponCode}
                                    options={couponList?.results || []}
                                    freeSolo
                                    blurOnSelect
                                    onInputChange={(event, newInputValue) => {
                                      clearTimeout(timer.current);
                                      setCouponCode(newInputValue);
                                      timer.current = setTimeout(() => {
                                        getCoupons(
                                          `?code=${newInputValue}&org_type=imaging`
                                        );
                                      }, 1000);
                                      if (newInputValue === "") {
                                        setCoupon(0);
                                        setDiscount(0);
                                        setTncMessage("");
                                        setCouponMessage("");
                                      }
                                    }}
                                    getOptionLabel={(option: any) =>
                                      option.code
                                    }
                                    getOptionDisabled={(option: any) =>
                                      option.minimum_price > totalPrice
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        className="input"
                                        {...params}
                                        placeholder="Enter Coupon"
                                        variant="outlined"
                                        style={{
                                          width: "100%",
                                          margin: "0",
                                          padding: "0",
                                        }}
                                        inputProps={{
                                          ...params.inputProps,
                                          onKeyDown: (e) => {
                                            if (e.key === "Enter") {
                                              e.stopPropagation();
                                            }
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    disabled={redCashCheck}
                                    onClick={applyCoupon}
                                  >
                                    Apply
                                  </Button>
                                </Grid>
                                <p
                                  style={{
                                    color: "#8e24aa",
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    alignItems: "center",
                                  }}
                                >
                                  {couponMessage} <br />
                                  {tncMessage}
                                </p>
                              </Grid>
                            </td>
                            <td
                              className="cell"
                              align="right"
                              style={{ width: "20%" }}
                            >
                              Discount
                            </td>
                            <td
                              className="cell"
                              align="center"
                              style={{ width: "20%" }}
                            >
                              ₹ {Math.floor(discount)}
                            </td>
                          </tr>
                          {/* <tr>
                            <td
                              className="cell"
                              align="right"
                              style={{ width: "20%" }}
                            >
                              RedCash Discount
                            </td>
                            <td
                              className="cell"
                              align="center"
                              style={{ width: "20%" }}
                            >
                              ₹ {redDisc > 0 ? Math.floor(redDisc) : 0}
                            </td>
                          </tr> */}
                          <tr>
                            <td className="cell" align="right">
                              Discount Percentage
                            </td>
                            <td className="cell" align="center">
                              {discountPercentage > 0
                                ? `${discountPercentage}%`
                                : "-"}
                            </td>
                          </tr>

                          <tr>
                            <td className="cell" align="right">
                              <h4 className="totalPrice">Total Price</h4>
                            </td>
                            <td className="cell" align="center">
                              <h4 className="totalPrice">
                                ₹{" "}
                                {redDisc > 0
                                  ? Math.floor(totalPrice) - Math.floor(redDisc)
                                  : Math.floor(totalPrice)}
                              </h4>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            direction="row"
            style={{ margin: "10px auto", alignItems: "left" }}
          >
            <Grid
              container
              item
              xs={12}
              md={4}
              spacing={3}
              style={{ margin: "10px 0", width: "100%", padding: "10px" }}
            >
              <Grid item xs={12} style={{ padding: "0" }}>
                <p>Verification Status</p>
              </Grid>
              <Grid item xs={12} style={{ padding: "0" }}>
                <Select
                  className="input"
                  name="verification_status"
                  variant="outlined"
                  value={verification_status}
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  onChange={(e) => {
                    setVerification_status(e.target.value as string);
                    // if(verification_status === "cancelled"){
                    //   setBooking_status("cancelled")
                    // }
                  }}
                  // required
                  // disabled={booking.verification_status !== "pending"}
                >
                  <MenuItem value={"pending"}>Pending</MenuItem>
                  <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                  <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                  <MenuItem value={"rescheduled"}>Rescheduled</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={4}
              spacing={3}
              style={{ margin: "10px 0", width: "100%", padding: "10px" }}
            >
              <Grid item xs={12} style={{ padding: "0" }}>
                <p>Verification Disposition</p>
              </Grid>
              {verification_status_d === "others" ? (
                <Grid item xs={12} style={{ padding: "0" }}>
                  <TextField
                    name="verification_remark"
                    placeholder="Enter Verification Disposition"
                    type="text"
                    value={verification_status_d_others}
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setVerification_status_d_others(e.target.value as string)
                    }
                    required={verification_status_d === "others"}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} style={{ padding: "0" }}>
                  <Select
                    className="input"
                    name="booking_status"
                    variant="outlined"
                    required
                    value={verification_status_d}
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    onChange={(e) =>
                      setVerification_status_d(e.target.value as string)
                    }
                  >
                    {VERIFICATION_DISP.map((data: any) => {
                      <MenuItem disabled value={verification_status_d}>
                        {verification_status_d}
                      </MenuItem>;
                      return <MenuItem value={data.text}>{data.text}</MenuItem>;
                    })}
                  </Select>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={4}
              spacing={3}
              style={{ margin: "10px 0", width: "100%", padding: "10px" }}
            >
              <Grid item xs={12} style={{ padding: "0" }}>
                <p>Verification Remark</p>
              </Grid>
              <Grid item xs={12} style={{ padding: "0" }}>
                <TextField
                  name="verification_remark"
                  placeholder="Enter Remark"
                  type="text"
                  value={verification_remark}
                  className="input"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) =>
                    setVerification_remark(e.target.value as string)
                  }
                  required
                />
              </Grid>
            </Grid>
            {verification_remark &&
            verification_status_d &&
            verification_status ? (
              <>
                <Grid item xs={12} style={{ padding: "0" }}>
                  {" "}
                  <hr />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={4}
                  spacing={3}
                  style={{ margin: "10px 0", width: "100%", padding: "10px" }}
                >
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Booking Status</p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <Select
                      className="input"
                      name="booking_status"
                      variant="outlined"
                      value={
                        verification_status === "cancelled"
                          ? "cancelled"
                          : booking_status
                      }
                      disabled={verification_status === "cancelled"}
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      onChange={(e) => {
                        setBooking_status(e.target.value as string);
                      }}
                      required={verification_status !== "cancelled"}
                    >
                      <MenuItem value={"pending"}>Pending</MenuItem>
                      <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                      <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                      <MenuItem value={"hold"}>Hold</MenuItem>
                      <MenuItem value={"rescheduled"}>Rescheduled</MenuItem>
                      <MenuItem value={"resampling"}>Resampling</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={4}
                  spacing={3}
                  style={{ margin: "10px 0", width: "100%", padding: "10px" }}
                >
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Booking Disposition</p>
                  </Grid>
                  {booking_status_d === "others" ? (
                    <TextField
                      name="booking_status_d"
                      placeholder="Booking Disposition"
                      type="text"
                      value={booking_status_d_others}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                      onChange={(e) =>
                        setBooking_status_d_others(e.target.value as string)
                      }
                      required={
                        booking_status_d === "others" ||
                        verification_status !== "cancelled"
                      }
                      disabled={verification_status === "cancelled"}
                    />
                  ) : (
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <Select
                        className="input"
                        name="booking_status"
                        variant="outlined"
                        required={verification_status !== "cancelled"}
                        // {booking_status !== "pending" && booking_status !== "cancelled"}
                        // || verification_status !== "cancelled"}
                        value={booking_status_d}
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        onChange={(e) =>
                          setBooking_status_d(e.target.value as string)
                        }
                        disabled={verification_status === "cancelled"}
                      >
                        <MenuItem disabled value={booking_status_d}>
                          {booking_status_d}
                        </MenuItem>
                        {BOOKING_DISP.map((data: any) => {
                          return (
                            <MenuItem value={data.text}>{data.text}</MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={4}
                  spacing={3}
                  style={{ margin: "10px 0", width: "100%", padding: "10px" }}
                >
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Remark</p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <TextField
                      name="remark"
                      placeholder="Enter Remark"
                      type="text"
                      value={remarks}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                      required={verification_status !== "cancelled"}
                      disabled={verification_status === "cancelled"}
                      onChange={(e) => setRemarks(e.target.value as string)}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )}

            {/* <Grid container item xs={12} md={4} spacing={3} style={{ margin: "10px 0", width: "100%", padding: "10px" }}>
              <Grid item xs={12} style={{ padding: "0" }}>
                <p>Payment Source</p>
              </Grid>
              <Grid item xs={12} style={{ padding: "0" }}>
                <Select
                  className="input"
                  name="payment_source"
                  variant="outlined"
                  value={payment_source}
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  onChange={(e) => setPayment_source(e.target.value as string)}
                  required={booking.booking_status === "confirmed"}

                >
                  <MenuItem value={"cash"}>Cash</MenuItem>
                  <MenuItem value={"online"}>Online</MenuItem>

                </Select>
              </Grid>
            </Grid> */}
            {verification_remark &&
            verification_status_d &&
            verification_status &&
            booking_status &&
            remarks &&
            booking_status_d ? (
              <>
                <Grid item xs={12} style={{ padding: "0" }}>
                  {" "}
                  <hr />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={3}
                  spacing={3}
                  style={{ margin: "10px 0", width: "100%", padding: "10px" }}
                >
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Pickup Status</p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <Select
                      className="input"
                      name="pickup_status"
                      variant="outlined"
                      // value={pickup_status || "pending"}
                      value={
                        verification_status === "cancelled" ||
                        booking_status === "cancelled"
                          ? "cancelled"
                          : pickup_status
                      }
                      disabled={
                        verification_status === "cancelled" ||
                        booking_status === "cancelled"
                      }
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      onChange={(e) =>
                        setPickup_status(e.target.value as string)
                      }
                      required={
                        verification_status !== "cancelled" ||
                        booking_status !== "cancelled"
                      }
                    >
                      <MenuItem value={"pending"}>Pending</MenuItem>
                      <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                      <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                      <MenuItem value={"hold"}>Hold</MenuItem>
                      <MenuItem value={"reschedule"}>Reschedule </MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={3}
                  spacing={3}
                  style={{ margin: "10px 0", width: "100%", padding: "10px" }}
                >
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Pickup Disposition</p>
                  </Grid>
                  {pickup_status_d === "others" ? (
                    <TextField
                      name="pickup_status_d"
                      placeholder="Pickup Disposition"
                      type="text"
                      value={pickup_status_d_others}
                      className="input"
                      variant="outlined"
                      required={
                        verification_status !== "cancelled" ||
                        pickup_status_d === "others"
                      }
                      disabled={
                        verification_status === "cancelled" ||
                        booking_status === "cancelled"
                      }
                      style={{ margin: "0", width: "100%" }}
                      onChange={(e) =>
                        setPickup_status_d_others(e.target.value as any)
                      }
                    />
                  ) : (
                    <Grid item xs={12} style={{ padding: "0" }}>
                      <Select
                        className="input"
                        name="pickup_status_d"
                        variant="outlined"
                        value={pickup_status_d}
                        required={
                          verification_status !== "cancelled" &&
                          booking_status !== "cancelled"
                        }
                        // required={verification_status!=="cancelled"}
                        disabled={
                          verification_status === "cancelled" ||
                          booking_status === "cancelled"
                        }
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        onChange={(e) =>
                          setPickup_status_d(e.target.value as string)
                        }
                      >
                        <MenuItem disabled value={pickup_status_d}>
                          {pickup_status_d}
                        </MenuItem>
                        {PICKUP_DISP.map((data: any) => {
                          return (
                            <MenuItem value={data.text}>{data.text}</MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={3}
                  spacing={3}
                  style={{ margin: "10px 0", width: "100%", padding: "10px" }}
                >
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Payment Mode</p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <Select
                      className="input"
                      name="payment_mode"
                      variant="outlined"
                      value={payment_mode || ""}
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      disabled={pickup_status !== "confirmed"}
                      onChange={(e: any) => hanbdlePaymentMode(e)}
                      required={pickup_status === "confirmed"}
                    >
                      <MenuItem disabled value={"none"}>
                        None
                      </MenuItem>
                      <MenuItem value={"cash"}>Cash</MenuItem>
                      <MenuItem value={"online"}>Online Payment</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={3}
                  spacing={3}
                  style={{ margin: "10px 0", width: "100%", padding: "10px" }}
                >
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Pickup Amount</p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <TextField
                      name="pickup_amount"
                      placeholder="Pickup Amount"
                      type="number"
                      value={pickup_amount}
                      className="input"
                      variant="outlined"
                      disabled
                      style={{ margin: "0", width: "100%" }}
                      required={pickup_status === "confirmed"}
                      // onChange={(e) => setPickup_amount(e.target.value as any)}
                    />
                  </Grid>
                </Grid>
              </>
            ) : (
              ""
            )}

            {/* <Grid
              container
              item
              xs={12}
              md={4}
              spacing={3}
              style={{ margin: "10px 0", width: "100%", padding: "10px" }}
            >
              <Grid item xs={12} style={{ padding: "0" }}>
                <p>Pickup Date</p>
              </Grid>
              <Grid item xs={12} style={{ padding: "0" }}>
                <input
                  className="input"
                  name="collection_date"
                  type="date"
                  value={pickup_date || ""}
                  style={{ width: "100%", margin: "0", padding: "5.5px 14px" }}
                  onChange={(e) => setPickup_date(e.target.value)}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={4}
              spacing={3}
              style={{ margin: "10px 0", width: "100%", padding: "10px" }}
            >
              <Grid item xs={12} style={{ padding: "0" }}>
                <p>Pickup Time</p>
              </Grid>
              <Grid item xs={12} style={{ padding: "0" }}>
                <input
                  className="input"
                  name="collection_date"
                  type="time"
                  value={pickup_time || ""}
                  style={{ width: "100%", margin: "0", padding: "5.5px 14px" }}
                  onChange={(e) => setPickup_time(e.target.value)}
                  disabled
                />
              </Grid>
            </Grid> */}
          </Grid>

          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ margin: "1rem auto" }}
          >
            <Grid item xs={12}>
              <Grid item xs={12}>
                <h3
                  style={{
                    borderTop: "dotted 1px #cccc",
                    paddingTop: "10px",
                    fontSize: "24px",
                    fontWeight: "bold",
                  }}
                >
                  CENTER ADDRESS
                </h3>
              </Grid>
              <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                <Grid item xs={12} md={3}>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Center Name</p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <TextField
                      className="input"
                      name="centre_address"
                      type="text"
                      placeholder="Enter address"
                      value={booking?.center?.name || ""}
                      disabled
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Center Address</p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <TextField
                      className="input"
                      name="centre_address"
                      type="text"
                      placeholder="Enter address"
                      value={centre_address}
                      disabled
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Center Longitude</p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <TextField
                      className="input"
                      name="customer_longitude"
                      type="text"
                      placeholder="Enter Longitude"
                      value={center_longitude}
                      disabled
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Center Latitude</p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <TextField
                      className="input"
                      name="customer_latitude"
                      type="text"
                      placeholder="Enter Latitude"
                      value={center_latitude}
                      disabled
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={loading || !selectedSlot || packages?.length === 0}
            >
              Update booking
            </Button>
          </div>
        </form>
      </Paper>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {" "}
          Do You Want To Change Package
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ color: "#ab003c" }}
          >
            For Changing Package You have to select center and time slot as well{" "}
            <br />
            Our center and Time Slot is mapped according to Package
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleresetPackage} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  pin_code: state.CollectionCenterAdminReducer.pin_code,
  packageList: state.CollectionCenterAdminReducer.packageList,
  couponList: state.CollectionCenterAdminReducer.couponList,
  booking: state.CollectionCenterAdminReducer.booking,
  booking_slots: state.CollectionCenterAdminReducer.booking_slots,
  loading: state.CollectionCenterAdminReducer.loading,
  cities: state.CollectionCenterAdminReducer.cities,
  subCenter: state.CollectionCenterAdminReducer.subCenter,
  imagingTimeSlots: state.CollectionCenterAdminReducer.imagingTimeSlots,
  multiplePackage: state.CollectionCenterAdminReducer.multiplePackage,
  displayCities: state.CollectionCenterAdminReducer.displayCities,
  checkGeoFenceStatus: state.CollectionCenterAdminReducer.checkGeoFenceStatus,
  geoFencesMapMyIndia: state.CollectionCenterAdminReducer.geoFencesMapMyIndia,
  mapmyindiaAccessToken:
    state.CollectionCenterAdminReducer.mapmyindiaAccessToken,
});

export default connect(mapStateToProps, {
  getPackage,
  updateBooking,
  getCoupons,
  getBookingById,
  getAvailableSlots,
  getCities,
  getSubCenterInfo,
  getPinCode,
  getImagingTimeslots,
  getMultiplePackage,
  getDisplayCities,
  checkGeoFenceArea,
  checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken,
})(EditBookingForm);
