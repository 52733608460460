
import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    withStyles,
    Theme,
    createStyles,
    makeStyles,
} from "@material-ui/core/styles";
import { Grid, Select, Chip, MenuItem, TextField, Checkbox, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core'
import Paper from "@material-ui/core/Paper";
import { Link } from 'react-router-dom';
import moment from 'moment';
import Loader from "../components/loader";
import Button from '@mui/material/Button';
import { getReportData, getHealthTestParameter, getPackage, getParameterCount, clickToCallBooking, getReportDownload,getCities } from '../actions/HealthAdviserAction';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Modal from '@mui/material/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CallIcon from "@material-ui/icons/Call";
import MessageIcon from "@material-ui/icons/Message";
import HealthDispostion from "../components/Comments/HealthDisposition";
import { generateHealthDataFilterUrl } from '../../helpers/generateUrl'
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 700,
        },
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "90%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            // width: "50%",
            // height: "50%",
        },
        paper1: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),

        },
        head: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "2rem",
        },
    })
);
const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,fontWeight:600
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

interface Props {
    loading: boolean;
    getReportData: any;
    reportData: any;
    getHealthTestParameter: any;
    healthTestParameter: any;
    getPackage: any;
    packageList: any;
    getParameterCount: any;
    parameterCount: any;
    clickToCallBooking: any;
    getReportDownload: any;
    reportDownload: any;
    getCities:any;
    cities:any;
}

const LeadsTable: React.FC<Props> = ({
    loading,
    getReportData,
    reportData,
    getHealthTestParameter,
    healthTestParameter,
    getPackage,
    packageList,
    getParameterCount,
    parameterCount,
    clickToCallBooking,
    getReportDownload,
    reportDownload,
    getCities,
    cities,
}) => {
    const classes = useStyles();
    const timer = useRef<any>(0)
    const [open, setOpen] = React.useState(false);
    const [openModel, setOpenModel] = React.useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [id, setId] = useState<string>("")
    const [createdAt, setCreatedAt] = useState<string>("")
    const [direction, setDirection] = useState<string>("none")
    const [status, setStatus] = useState<string>("none")
    const [booking, setBookingid] = React.useState<any>("")
    const [add_booking, setadd_booking] = React.useState<any>("")
    const [start_date, setStartDate] = useState("")
    const [end_date, setEndDate] = useState("")
    const [test_parameter, setTestParameter] = useState<any>(0);
    const [package_id, setPackageId] = useState<any>(0);
    const [customer_name, setcustomerName] = useState<any>("")
    const [customer_phonenumber, setcustomer_phonenumber] = useState("");
    const [b64File, setB6File] = useState("");
    const [normalAbnormal, setNormalAbnormal] = useState<string>("none")
    const [open2, setOpen2] = useState<boolean>(false)
    const [testValue, setTestValue] = useState<any>([])
    const [openPdf, setOpenPdf] = useState<boolean>(false)
    const [leadId, setLeadId] = useState<number>(0);
    const [bId, setBId] = useState<number>(0);
    const history = useHistory()

    useEffect(() => {
        getReportData()
        getHealthTestParameter()
        getPackage()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatch = useDispatch()

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = reportData.links.next.split("?")[1];
            getReportData(`?${url}`)
        } else if (newPage < page) {
            let url = reportData.links.previous.split("?")[1];
            getReportData(url === undefined ? "" : `?${url}`)
        }
        setPage(newPage);
    };
    const filterHealthData = () => {
        const body: any = {
            booking: booking,
            add_booking: add_booking,
            customer_phonenumber: customer_phonenumber,
            customer_name: customer_name,
            test_parameter: test_parameter,
            start_date: start_date,
            end_date: end_date,
            package_id: package_id,
            normal: normalAbnormal
        }
        const url = generateHealthDataFilterUrl(body).substring(2)
        getReportData(`${url ? `?${url}&page=1` : "?page=1"}`)
        setPage(0)
    }
    const handleIdFileter = (booking: any) => {
        if (booking.booking !== null) {
            const body: any = {
                booking: booking?.booking?.id
            }
            const url = generateHealthDataFilterUrl(body).substring(2)
            getReportData(`${url ? `?${url}&page=1` : "?page=1"}`)
            setPage(0)
        }
        else {
            const body: any = {
                add_booking: booking?.add_booking?.id
            }
            const url = generateHealthDataFilterUrl(body).substring(2)
            getReportData(`${url ? `?${url}&page=1` : "?page=1"}`)
            setPage(0)
        }
    }
    const handleModelOpen = (data: any) => {
        setTestValue(data)
        setOpenModel(true);
    }

    const handleShowParameter = (id: any) => {
        setOpen(true);
        getParameterCount(`?id=${id}`)
    }
    const handleClose = () => {
        setOpen(false);
    };
    const handleModelClose = () => {
        setOpenModel(false);
    }
    const handleCallClick = (id: number, type: string) => {
        clickToCallBooking(id, type);
    };
    const handlePdfModelClose = () => {
        setOpenPdf(false)
    }
    const handleViewPdf = (data: any) => {
        if (data.booking !== null) {
            getReportDownload(`${data?.booking?.id}/`)
        }
        else if (data?.add_booking !== null || data.booking === null) {
            getReportDownload(`${data?.add_booking?.id}/?additional_id=true`)
        }
        else {
            alert("Booking Id Not Exist")
        }
        setOpenPdf(true)
    }
    const handleModal = (leadId: number,bId:number) => {
        setOpen2(true);
        setLeadId(leadId);
        setBId(bId)
      };
    return (
        <>
            <main className="data-table">
                <div className={classes.toolbar} />
                <h2>Health Data</h2>
                <Grid container spacing={1} direction="row" style={{ alignItems: "left" }}>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="booking"
                            type="number"
                            label="Booking Id"
                            disabled={add_booking !== ""}
                            value={booking}
                            variant="outlined"
                            onChange={(e) => setBookingid(e.target.value)}
                            style={{ width: "100%" }}

                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="additionalbooking"
                            type="number"
                            disabled={booking !== ""}
                            label="Additional Member Id"
                            value={add_booking}
                            variant="outlined"
                            onChange={(e) => setadd_booking(e.target.value)}
                            style={{ width: "100%" }}

                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="customername"
                            type="text"
                            label="Customer Name"
                            value={customer_name}
                            variant="outlined"
                            onChange={(e) => setcustomerName(e.target.value)}
                            style={{ width: "100%" }}

                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="phone Number"
                            type="text"
                            label="Phone Number"
                            value={customer_phonenumber}
                            variant="outlined"
                            onChange={(e) => setcustomer_phonenumber(e.target.value as string)}
                            style={{ width: "100%" }}

                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="start_date"
                            type="date"
                            value={start_date}
                            className="input"
                            label="Start Date"
                            variant="outlined"

                            onChange={(e: any) => setStartDate(e.target.value as string)}
                            style={{ width: "100%" }}

                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="endDate"
                            type="date"
                            value={end_date}
                            className="input"
                            label="End Date"
                            variant="outlined"

                            onChange={(e: any) => setEndDate(e.target.value as string)}
                            style={{ width: "100%" }}

                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Select className="input"
                            name="customer_designation"
                            variant="outlined"
                            value={normalAbnormal}
                            style={{ width: "100%", height: "39px" }}

                            onChange={(e) => setNormalAbnormal(e.target.value as string)}
                            required
                        >
                            <MenuItem disabled value={"none"}>Normal/Abnormal</MenuItem>
                            <MenuItem value={"true"}>Normal</MenuItem>
                            <MenuItem value={"false"}>Abnormal</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="testparameter"
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    setTestParameter(obj.id)
                                }
                            }}
                            options={healthTestParameter.results || []}
                            freeSolo
                            blurOnSelect
                            aria-required

                            getOptionLabel={(option: any) => option.test_name}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                                    getHealthTestParameter(`?test_name=${newInputValue}`)
                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setTestParameter(0)
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    label="Test Parameter"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="package"
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    setPackageId(obj.id)
                                }
                            }}
                            options={packageList.results || []}
                            freeSolo
                            blurOnSelect
                            aria-required

                            getOptionLabel={(option: any) => option.name}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                                    getPackage(`code=${newInputValue}`)
                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setPackageId(0)
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    label="Package List"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={filterHealthData}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => history.push("/dashboard/ha/health_data")}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <div className="data-table" style={{ width: "100%", marginTop: "2rem" }}>
                    <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "540px" }}>
                        {loading ? <Loader /> : (
                            <Table stickyHeader aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align="center">Booking Id</StyledTableCell>
                                        <StyledTableCell align="center">Click-To-Call</StyledTableCell>
                                        <StyledTableCell align="center">Action</StyledTableCell>
                                        <StyledTableCell align="center">Customer Name</StyledTableCell>
                                        <StyledTableCell align="center">Collections Date</StyledTableCell>
                                        {/* <StyledTableCell align="center">Package Code</StyledTableCell> */}
                                        <StyledTableCell align="center">Package Name</StyledTableCell>
                                        {/* <StyledTableCell align="center">Test Value</StyledTableCell> */}
                                        <StyledTableCell align="center">Booking View</StyledTableCell>
                                        <StyledTableCell align="center">PDF View</StyledTableCell>

                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {reportData && reportData?.results && reportData?.results.length > 0 && reportData.results.map((booking: any, index: any) => {
                                        const heihLight = booking && booking?.test_values && booking?.test_values.length > 0 && booking?.test_values.find((data: any) => data.is_highlighted === true)
                                        return (
                                            <StyledTableRow key={booking?.pk} style={{ backgroundColor: heihLight === undefined ? "" : "#ffeb3b" }}>
                                                <StyledTableCell align="center" style={{ cursor: "pointer", color: "#2196f3" }} onClick={() => handleIdFileter(booking)}>
                                                    {booking.booking !== null ? booking?.booking?.id : booking?.add_booking?.id}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <CallIcon
                                                        onClick={() => handleCallClick(booking.booking !== null ? booking?.booking?.id : booking?.add_booking?.booking_id, "call")}
                                                    />

                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <div>
                                                        <MessageIcon onClick={() => handleModal(booking.booking !== null ? booking?.booking?.lead_id : booking?.add_booking?.lead_id,booking.booking !== null ? booking?.booking?.id : booking?.add_booking?.id)} />
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">{booking.booking !== null ? booking?.booking?.customer_name : booking?.add_booking?.customer_name}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {
                                                        booking.booking !== null ? booking?.booking?.collection_date : booking?.add_booking?.collection_date
                                                    }
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="center">
                                                    {booking?.package?.code}
                                                </StyledTableCell> */}
                                                <StyledTableCell align="center">
                                                    {booking?.package?.name}
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="center">
                                                    <Button
                                                        variant="contained"
                                                        style={{ background:booking.booking!==null? "#e91e63":"#f44336"}}

                                                        fullWidth
                                                        onClick={() => handleModelOpen(booking?.test_values)}
                                                    >
                                                        Test Value
                                                    </Button>
                                                </StyledTableCell> */}
                                                <StyledTableCell align="center">
                                                    <Link to={`/dashboard/ha/booking-view/${booking.booking !== null ? booking?.booking?.id : booking?.add_booking?.booking_id}`} style={{ textDecoration: "none" }}>
                                                        <Button
                                                            variant="contained"
                                                            style={{ background: booking.booking !== null ? "#4caf50" : "#f44336" }}
                                                            fullWidth
                                                        >
                                                            Booking View
                                                        </Button>
                                                    </Link>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Button
                                                        variant="contained"
                                                        style={{ background: booking.booking !== null ? "#ffeb3b" : "#f44336" }}
                                                        fullWidth
                                                        onClick={() => handleViewPdf(booking)}
                                                    >
                                                        PDF View
                                                    </Button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TablePagination
                                        colSpan={30}
                                        count={reportData.count || 0}
                                        rowsPerPageOptions={[]}
                                        rowsPerPage={rowsPerPage}
                                        // onChangeRowsPerPage={handleChangeRowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                    />
                                </TableFooter>
                            </Table>
                        )}
                    </TableContainer>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={openModel}
                        onClose={handleModelClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={openModel}>
                            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "67%", maxWidth: "85%" }}>
                                <Table stickyHeader aria-label="simple table">
                                    <div className={classes.paper}>
                                        <div className={classes.head}>

                                            <h2 id="transition-modal-title">Test Value Data</h2>
                                            <CloseIcon onClick={() => setOpenModel(false)} />
                                        </div>

                                        <p id="transition-modal-description">Test Value Data is :-</p>

                                        <StyledTableCell>

                                            {testValue && testValue.length > 0 && testValue.map((data: any, index: any) => {
                                                return (

                                                    <h6 id="transition-modal-description" style={{ background: data.is_highlighted ? "#ffeb3b" : "" }}>
                                                        <a style={{ color: "#1976d2", cursor: "pointer" }}
                                                            onClickCapture={() => handleShowParameter(data?.test_parameter?.id)}
                                                        >
                                                            ({index + 1}) Parameter: {data?.test_parameter?.name}<br
                                                            />
                                                        </a>
                                                        <br />
                                                        Test Method: {data?.test_method}
                                                        <br />
                                                        Value : <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: `${data?.value}`,
                                                            }}
                                                        />
                                                    </h6>
                                                )
                                            })}
                                        </StyledTableCell>
                                    </div>
                                </Table>

                            </TableContainer>
                        </Fade>

                    </Modal>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={open}>
                            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "67%", maxWidth: "85%" }}>
                                <Table stickyHeader aria-label="simple table">
                                    <div className={classes.paper1}>
                                        <div className={classes.head}>

                                            <h2 id="transition-modal-title">PARAMETER READING</h2>
                                            <CloseIcon onClick={() => setOpen(false)} />
                                        </div>

                                        {parameterCount && parameterCount?.results && parameterCount?.results.length > 0 && parameterCount?.results.map((data: any) => {
                                            return (
                                                <h6 id="transition-modal-description">
                                                    <b> Test Name : </b>{data.test_name}<br />
                                                    <b>Test Unit :</b> {data.test_unit}<br />
                                                    <b>Critical Lower Femail :</b> {data?.critical_lower_female}<br />
                                                    <b>Critical Lower Male :</b> {data?.critical_lower_male}<br />
                                                    <b> Critical Upper Femail :</b> {data?.critical_upper_female}<br />
                                                    <b> Critical Upper male : </b>{data?.critical_upper_male}<br />
                                                    <b> Lower Bound Femail : </b>{data?.lower_bound_female}<br />
                                                    <b>Lower Bound Mail :</b> {data?.lower_bound_male}<br />
                                                    <b> Upper Bound Femail :</b> {data?.upper_bound_female}<br />
                                                    <b> Upper Bound Mail :</b> {data?.upper_bound_male}<br />
                                                    <b> Other Femail :</b><div
                                                        dangerouslySetInnerHTML={{
                                                            __html: `${data?.other_female}`,
                                                        }}
                                                    /><br />
                                                    <b> Other Mail :</b> <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: `${data?.other_male}`,
                                                        }}
                                                    /><br />
                                                </h6>
                                            )
                                        })}
                                    </div>
                                </Table>
                            </TableContainer>
                        </Fade>
                    </Modal>

                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={open}>
                            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "67%", maxWidth: "85%" }}>
                                <Table stickyHeader aria-label="simple table">
                                    <div className={classes.paper1}>
                                        <div className={classes.head}>

                                            <h2 id="transition-modal-title">PARAMETER READING</h2>
                                            <CloseIcon onClick={() => setOpen(false)} />
                                        </div>

                                        {parameterCount && parameterCount?.results && parameterCount?.results.length > 0 && parameterCount?.results.map((data: any) => {
                                            return (
                                                <h6 id="transition-modal-description">
                                                    <b> Test Name : </b>{data.test_name}<br />
                                                    <b>Test Unit :</b> {data.test_unit}<br />
                                                    <b>Critical Lower Femail :</b> {data?.critical_lower_female}<br />
                                                    <b>Critical Lower Male :</b> {data?.critical_lower_male}<br />
                                                    <b> Critical Upper Femail :</b> {data?.critical_upper_female}<br />
                                                    <b> Critical Upper male : </b>{data?.critical_upper_male}<br />
                                                    <b> Lower Bound Femail : </b>{data?.lower_bound_female}<br />
                                                    <b>Lower Bound Mail :</b> {data?.lower_bound_male}<br />
                                                    <b> Upper Bound Femail :</b> {data?.upper_bound_female}<br />
                                                    <b> Upper Bound Mail :</b> {data?.upper_bound_male}<br />
                                                    <b> Other Femail :</b><div
                                                        dangerouslySetInnerHTML={{
                                                            __html: `${data?.other_female}`,
                                                        }}
                                                    /><br />
                                                    <b> Other Mail :</b> <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: `${data?.other_male}`,
                                                        }}
                                                    /><br />
                                                </h6>
                                            )
                                        })}
                                    </div>
                                </Table>
                            </TableContainer>
                        </Fade>
                    </Modal>
                    {reportDownload && reportDownload?.allReportDetails ? (
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classes.modal}
                            open={openPdf}
                            onClose={handlePdfModelClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={openPdf}>


                                <iframe
                                    src={`data:application/pdf;base64,${reportDownload?.allReportDetails?.reportDetails}`}
                                    style={{ width: "80%", height: "750px" }}
                                    title="Iframe Example"
                                ></iframe>


                            </Fade>
                        </Modal>
                    ) : reportDownload.message ? alert("Your report is not ready yet") : ""

                    }

                </div>
            </main>
            <HealthDispostion
        open={open2}
        setOpen={setOpen2}
        leadId={leadId}
        getCities={getCities}
        cities={cities}
        bId={bId}
      />
        </>
    );
};

const mapStateToProps = (state: any) => ({
    reportData: state.HealthAdviserReducer.reportData,
    healthTestParameter: state.HealthAdviserReducer.healthTestParameter,
    packageList: state.HealthAdviserReducer.packageList,
    parameterCount: state.HealthAdviserReducer.parameterCount,
    reportDownload: state.HealthAdviserReducer.reportDownload,
    loading: state.HealthAdviserReducer.loading,
    cities: state.HealthAdviserReducer.cities,

});

export default connect(mapStateToProps, {
    getReportData,
    getHealthTestParameter,
    getPackage,
    getParameterCount,
    clickToCallBooking,
    getReportDownload,
    getCities,
})(
    LeadsTable
);
