import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useEffect } from "react";
import {
  getWhatsappCommentsByLead,
  getWhatsappComments,
  sendWhatsappComments,
} from "../../../CustomerExecutive-Panel/actions/bookingActions";
import { useHistory } from "react-router-dom";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import Stack from "@mui/material/Stack";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));

type ModalProps = {
  open: boolean;
  loading: boolean;
  leadId: number;
  whatsappCommentsByLead: any;
  getWhatsappCommentsByLead: any;
  sendWhatsappComments: any;
  user: any;
  setLeadId: any;
  setOpen: Function;
  getWhatsappComments: any;
  whatsappComments: any;
  callApi: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  open,
  leadId,
  setOpen,
  loading,
  getWhatsappCommentsByLead,
  sendWhatsappComments,
  whatsappCommentsByLead,
  user,
  setLeadId,
  getWhatsappComments,
  whatsappComments,
  callApi,
}) => {
  const classes = useStyles();

  const history = useHistory();

  const [comment, setComment] = useState<string>("");
  const [maxHeight, setMaxHeight] = React.useState<any>(300);

  const handleClose = () => {
    if (callApi === "whatsapp") {
      getWhatsappComments();
    }
    setOpen(false);
  };
  useEffect(() => {
    if (leadId !== 0) {
      getWhatsappCommentsByLead(`lead=${leadId}`);
    }
  }, [leadId]);

  const submitForm = (e: any) => {
    if (whatsappCommentsByLead.results && whatsappCommentsByLead.results.length > 0) {
      const len = whatsappCommentsByLead.results.length;
      sendWhatsappComments(
        whatsappCommentsByLead.results && whatsappCommentsByLead.results[len - 1]?.id,
        comment
      );
    }
    setComment("");
    setTimeout(() => {
      getWhatsappCommentsByLead(`lead=${leadId}`);
    }, 3000);
  };

  useEffect(() => {
    setComment("");
    if (leadId !== 0 && leadId !== null && leadId !== undefined){
      getWhatsappCommentsByLead(`lead=${leadId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendWhatsappComments]);

  let msgDiv = document.getElementById("messages");
  useEffect(() => {
    if (msgDiv) {
      msgDiv.scrollTop = msgDiv.scrollHeight;
    }
  }, [getWhatsappCommentsByLead, msgDiv, loading]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      // style={{maxHeight: }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.head}>
              <p
                style={{
                  marginBottom: "1rem",
                }}
                id="transition-modal-title"
              >
                Reply message
              </p>
              <CloseIcon onClick={handleClose} />
            </div>
            <div
              className="messages-container"
              id="messages"
              style={{ marginBottom: "1rem", height: "300px" }}
            >
              {loading ? (
                <Button
                  variant="text"
                  color="default"
                  style={{
                    textTransform: "none",
                    display: "block",
                    height: "100%",
                    fontSize: "1.3rem",
                  }}
                  fullWidth
                >
                  Loading Messages...
                </Button>
              ) : (
                whatsappCommentsByLead &&
                whatsappCommentsByLead.count > 0 &&
                whatsappCommentsByLead.results &&
                whatsappCommentsByLead.results
                  .map((comment: any, index: any) => {
                    let by_me = comment.msgtype === "2";
                    return (
                      <div
                        key={comment.id}
                        className={by_me ? "myMessages" : "othersMessages"}
                      >
                        <Button
                          variant="text"
                          color={by_me ? "primary" : "secondary"}
                          style={{ textTransform: "none", display: "block" }}
                        >
                          {by_me
                            ? comment.panel_user
                            : "Patient"
                          }
                          {" "}
                          : {comment.message}
                          {" "} 
                          {comment.created_at.substring(0, 10)} {" - "}
                          {comment.created_at.substring(11, 19)}
                        </Button>
                      </div>
                    );
                  })
              )}
            </div>
            {/* <Grid container spacing={3} direction="row" alignItems="center" justify="center" className="options">
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => setComment("abc")}
                                    style={{ textTransform: "capitalize" }}
                                >
                                    ABCcfvbfhvhjbvhbvwhjvbjvbjd
                                </Button>
                            </Grid>
                        </Grid> */}
            <TextField
              name="comment"
              type="text"
              value={comment}
              className="input"
              label="Message"
              variant="outlined"
              placeholder="Comment"
              onChange={(e) => setComment(e.target.value as string)}
              style={{ width: "100%", margin: "1rem auto" }}
              required
            />
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={submitForm}
              disabled={comment === ""}
            >
              Submit
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  whatsappCommentsByLead: state.BookingReducer.whatsappCommentsByLead,
  whatsappComments: state.BookingReducer.whatsappComments,
  loading: state.BookingReducer.loading,
  user: state.loginReducer.user,
});

export default connect(mapStateToProps, {
  getWhatsappCommentsByLead,
  getWhatsappComments,
  sendWhatsappComments,
})(CommentsModal2);
