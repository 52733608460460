import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Loader from "../loader";
import { connect } from "react-redux";
import {  Paper, TextField, Grid, Switch, Select, MenuItem } from "@material-ui/core";
import Button from '@mui/material/Button';
import { useEffect } from "react";
import { useHistory } from 'react-router';
import { getLeadSource, createPanelUser } from "../../actions/salesLeadAction";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        paper: {
            padding: "1rem",
            width: "100%",
            borderRadius: "10px"
        },
        head: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "1rem",
            textAlign: "center"
        },
        heading: {
            fontWeight: "normal",
            fontSize: "1.2rem",
            margin: "0"
        },
        table: {
            maxHeight: "400px",
            margin: "auto",
            overflow: "auto"
        },
        image: {
            width: "100px",
            height: "auto",
        },
        imageSM: {
            width: "50px",
            height: "auto",
        },
        imgContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap"
        }
    })
);

interface Props {
    loading: any;
    getLeadSource: any;
    lead_source: any;
    createPanelUser: any;
}

const BlogsPage: React.FC<Props> = ({
    loading,
    getLeadSource,
    lead_source,
    createPanelUser,
}) => {
    const classes = useStyles();

    const [name, setName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState<String>("")
    const [email, setEmail] = useState<String>("")
    const [userGroup, setUserGroup] = useState<any>("none")
    const history = useHistory()
    const [mobileValidation, setMobileValidation] = useState({
        phoneNumber: true,
    })
    const verifyMobile = (type: any) => {
        if (type === "mobile") {
            if (parseInt(phoneNumber && phoneNumber[0]) < 6 || phoneNumber && phoneNumber.length < 10) {
                setMobileValidation((prev: any) => ({
                    ...prev,
                    phoneNumber: false,
                }));
            }
        }
    }


    useEffect(() => {
        getLeadSource()
    }, [])

    const submitHandler = async (e: any) => {
        e.preventDefault()
        const body: any = {
            phonenumber: phoneNumber,
            fullname: name,
            group: userGroup,
            email
        }
        await createPanelUser(body)
        history.push("/dashboard/sl/users")
    }
    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Paper elevation={5} className={classes.paper}>
                    <h3 style={{ textAlign: "center" }}>Create User</h3>
                    {loading ? <Loader /> : (
                        <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Full Name</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter FullName"
                                        value={name}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Phone Number</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                       name="customer_phonenumber"
                                       type="number"
                                       onInput={(e: any) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value))
                                          .toString()
                                          .slice(0, 10);
                                      }}
                                      onWheel={(e: any) => e.target.blur()}
                                        value={phoneNumber}
                                        placeholder="Enter Mobile Number"
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setPhoneNumber(e.target.value as String)}
                                        onBlur={() => verifyMobile("mobile")}
                                        onFocus={() => {
                                            setMobileValidation((prev: any) => ({
                                                ...prev,
                                                phoneNumber: true
                                            }))
                                        }}
                                        helperText={!mobileValidation.phoneNumber && "Incorrect Phone Number"}
                                        required
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Email</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="customer_email"
                                        type="email"
                                        placeholder="Enter Email"
                                        value={email}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setEmail(e.target.value as String)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>User Group</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Select className="input"
                                        name="sampleregisterd"
                                        variant="outlined"
                                        value={userGroup}
                                        style={{ width: "100%", margin: "0" }}

                                        onChange={(e) => setUserGroup(e.target.value as String)}

                                    >
                                        <MenuItem disabled value={"none"}>User Group</MenuItem>
                                        <MenuItem value={"CustomerExecutive"}>Customer Executive</MenuItem>
                                        <MenuItem value={"SupportExecutive"}>Suport Executtive</MenuItem>
                                        <MenuItem value={"CustomerSupport"}>Customer Support</MenuItem>
                                        <MenuItem value={"VerificationExecutive"}>Verification Executive</MenuItem>

                                    </Select>
                                </Grid>
                            </Grid>

                            <Grid container item xs={12} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        fullWidth
                                        disabled={!mobileValidation?.phoneNumber}
                                        onClick={submitHandler}
                                    >
                                        Create User
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.SalesLeadReducer.loading,
    lead_source: state.SalesLeadReducer.lead_source,
})

export default connect(mapStateToProps, {
    getLeadSource,
    createPanelUser
})(BlogsPage);
