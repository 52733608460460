import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useRef } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import {Grid,withStyles,TextField } from "@material-ui/core";
import Box from '@mui/material/Box';
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from "@material-ui/core/Paper";
import { getLabDetails, getZone } from "../../actions/salesLeadAdminAction";
import Button from '@mui/material/Button';
import LabModal from "./labDetailsModal";
import { genrateLabDetailsFilter } from "../../../helpers/generateUrl";
import "./lab.css";
const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  td: {
    padding: "10px 15px",
  },
});
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    td: { padding: "20px 15px" },
    toolbar: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(4, 4),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  
  })
);
const StyledTableCell:any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  loading: boolean;
  getLabDetails: any;
  labDetails: any;
  getZone: any;
  zoneList: any;
}
const LeadsTable: React.FC<Props> = ({
  labDetails,
  getLabDetails,
  getZone,
  zoneList,
  loading,
}) => {
  useEffect(() => {
    getLabDetails();
    getZone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function createData(lab_credentials: any, panel_user: any) {
    return {
      lab_credentials,
      panel_user,
    };
  }

  const [openModal, setOpenModal] = React.useState(false);
  const [labData, setLabData] = React.useState([]);
  const [zoneName, setZoneName] = useState<any>([]);
  const timer = useRef<any>(0);
  const handleLabDetails = (row: any) => {
    const labD = labDetails.results.find(
      (data: any) => data.lab_credentials && data.lab_credentials.name === row
    );
    setLabData(labD);
    
    setOpenModal(true);
  };
  function Row(props: { row: ReturnType<typeof createData> }) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    const classes = useRowStyles();
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell style={{ width: "3rem" }}>
            {/* <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton> */}
          </TableCell>

          <TableCell
            // onClick={() => setOpen(!open)}
            className="tablerow"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            {row?.lab_credentials?.name}
          </TableCell>
          <TableCell
            // onClick={() => setOpen(!open)}
            className="tablerow"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            {row?.lab_credentials?.address}
          </TableCell>
          <TableCell
            className="tablerow"
            style={{ fontSize: "20px", fontWeight: "bold" }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleLabDetails(row?.lab_credentials?.name)}
            >
              LAB DETAILS
            </Button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="tablerow"
                        style={{ fontWeight: "bold" }}
                      >
                        NAME
                      </TableCell>
                      <TableCell
                        className="tablerow"
                        style={{ fontWeight: "bold" }}
                      >
                        PHONE NUMBER
                      </TableCell>
                      <TableCell
                        className="tablerow"
                        style={{ fontWeight: "bold" }}
                      >
                        USER GROUP
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row?.panel_user?.map((data: any) => (
                      <TableRow key={data?.id}>
                        <TableCell className="tablerow">{data?.name}</TableCell>
                        <TableCell className="tablerow">
                          {data?.phonenumber}
                        </TableCell>
                        <TableCell className="tablerow">
                          {data?.usergroup}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  const classes1 = useStyles();
  const classes = useStyles();
  const filterLab = (e: any) => {
    const body: any = {
      zone: zoneName,
    }
    const url = genrateLabDetailsFilter(body).substring(2)
     getLabDetails(`?${url}`)
  }

  return (
    <>
      <Grid container spacing={3} direction="row" alignItems="center" justify="center">
        <TableContainer component={Paper} style={{ marginTop: "4rem" }}>
          <Grid container spacing={3} direction="row" alignItems="center" justify="center">
            <Grid item xs={12} sm={6} md={6}>
              <Autocomplete
                id="zonename"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setZoneName(obj.id)
                  }
                }}
                options={zoneList?.results || []}
                freeSolo
                blurOnSelect
                aria-required
                getOptionLabel={(option: any) => option.city.name}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current)
                  timer.current = setTimeout(() => {
                    getZone(`${newInputValue}`)
                  }, 1000)
                  if (newInputValue.length === 0) {
                    setZoneName(0)
                  }
                }}
                renderInput={(params) => (
                  <TextField className="input"
                    {...params}
                    placeholder="Zone/City"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Button
                variant="contained"
                color="secondary"
                style={{ height: "56px" }}
                fullWidth
                onClick={filterLab}
              >
                Search
              </Button>
            </Grid>
          </Grid>
          <div className={classes1.toolbar} />
          <h1 style={{ fontSize: "20px", textAlign: "center" }}>LAB DETAILS</h1>
          <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "560px" }}>
          <Table stickyHeader aria-label="simple table">
            {/* <TableCell>LAB NAME</TableCell> */}
            <TableHead>
              <StyledTableRow>
                <StyledTableCell />
                <StyledTableCell style={{ fontWeight: "bold" }} className="tablerow">
                  LAB NAME
                </StyledTableCell>
                <StyledTableCell style={{ fontWeight: "bold" }} className="tablerow">
                  Address
                </StyledTableCell>
                <StyledTableCell
                  style={{ fontWeight: "bold", alignItems: "right" }}
                  className="tablerow"
                >
                  ACTION
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {labDetails &&
                labDetails?.results &&
                labDetails?.results?.length > 0 &&
                labDetails?.results?.map((data: any) => (
                  <Row key={data?.id} row={data} />
                ))}
            </TableBody>
          </Table>
          <LabModal
            openModal={openModal}
            setOpenModal={setOpenModal}
            labDetails={labData}
          />
          </TableContainer>
        </TableContainer>
      </Grid>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  labDetails: state.SalesLeadAdminReducer.labDetails,
  zoneList: state.SalesLeadAdminReducer.zoneList,
  loading: state.SalesLeadAdminReducer.loading,
});

export default connect(mapStateToProps, { getLabDetails, getZone })(LeadsTable);
