import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid, Select, MenuItem, TextField,createStyles, Theme } from '@material-ui/core'
import Box from '@mui/material/Box';
import { getLeadSource, getAgentList, getCities, clickToCall, getAllLeads, leadTransferByComma } from '../actions/salesLeadAdminAction'
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button } from "@material-ui/core";
import { generateLeadsUrl } from "../../helpers/generateUrl";
import { useRef } from "react";
import Loader from "../Components/loader";
import { Container } from "@material-ui/core";
import Export from '../Components/Export/leads'
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
    })
);

interface Props {
    getAllLeads: Function;
    all_lead: any;
    lead_source: any;
    loading: boolean;
    // resendSms: Function;
    clickToCall: Function;
    getLeadSource: any;
    user_panel: any;
    leadTransferByComma: any;
    getAgentList: any;
    agentsList: any;
    getCities: any;
    cities: any;
}

const LeadsTable: React.FC<Props> = ({
    getAllLeads,
    all_lead,
    getLeadSource,
    lead_source,
    getAgentList,
    agentsList,
    getCities,
    cities,
    loading,
}) => {
    const classes = useStyles();

    const [category, setCategory] = useState<any>([])
    const [id, setId] = useState<string>("")
    const [createdAt, setCreatedAt] = useState<string>("")
    const [followUpDate, setFollowUpDate] = useState<string>("")
    const [leadSource, setleadSource] = useState<any>([])
    const [priority, setPriority] = useState<any>([])
    const [city, setCity] = useState<any>([])
    const [start_date, setStart_date] = useState<string>("")
    const [end_date, setEnd_date] = useState<string>("")

    const timer = useRef<any>(0)

    const history = useHistory()

    const CATEGORY: any = [
        {
            id: 1,
            text: "Interested/Follow up",
            city: true,
            leadId: false
        },
        {
            id: 2,
            text: "Not Interested",
            city: true,
            query: false,
        },
        {
            id: 3,
            text: "Order Booked",
            city: true,
            query: false,
        },
        {
            id: 4,
            text: "Ringing",
            city: false,
            query: false,
        },
        {
            id: 5,
            text: "Number Doesn't Exist",
            city: false,
            query: false,
        },
        {
            id: 6,
            text: "By Mistake & Wrong Number",
            city: false,
            query: false,
        },
        {
            id: 7,
            text: "Call Back",
            city: true,
            query: false,
        },
        {
            id: 8,
            text: "Business Realated Call",
            city: true,
            query: false,
        },
        {
            id: 9,
            text: "Spam Call",
            city: false,
            query: false,
        },
        {
            id: 10,
            text: "Enquiry Call",
            city: true,
            query: false,
        },
        {
            id: 11,
            text: "CFresh",
            city: false,
            query: false,
        },
        {
            id: 12,
            text: "Test Not Available",
            city: true,
            query: false,
        },
        {
            id: 13,
            text: "Subscribed to Competitor",
            city: true,
            query: false,
        },
        {
            id: 14,
            text: "Slot Issue",
            city: true,
            query: false,
        },
        {
            id: 15,
            text: "Same Day Slot",
            city: true,
            query: false,
        },
        {
            id: 16,
            text: "Already Booked",
            city: false,
            query: false,
        },
        {
            id: 17,
            text: "Not Contactable",
            city: false,
            query: false,
        },
        {
            id: 18,
            text: "Out of Station",
            city: true,
            query: false,
        },
        {
            id: 19,
            text: "Out of Service Area",
            city: true,
            query: false,
        },
        {
            id: 20,
            text: "Need Time to Think",
            city: true,
            query: false,
        },
        {
            id: 21,
            text: "Email/Whatsapp Package",
            city: true,
            leadId: false
        },
        {
            id: 22,
            text: "Discount/Price Enquiry",
            city: true,
            leadId: false
        },
        {
            id: 23,
            text: "CS issue",
            city: false,
            leadId: true
        },
        {
            id: 24,
            text: "Call Not Reachable",
            city: false,
            leadId: false
        },
        {
            id: 25,
            text: "Call Later",
            city: true,
            leadId: false
        },
        {
            id: 26,
            text: "Call drop",
            city: true,
            leadId: false
        },
        {
            id: 27,
            text: "Call Disconnected",
            city: true,
            leadId: false
        },
        {
            id: 28,
            text: "Below <5 year patient",
            city: true,
            leadId: false
        },
        {
            id: 29,
            text: "DND",
            city: false,
            leadId: false
        },
        {
            id: 30,
            text: "Phlebo's Issue",
            city: false,
            leadId: true
        },
        {
            id: 31,
            text: "Reports Regarding Issue",
            city: false,
            leadId: true
        },
        {
            id: 32,
            text: "Health Advice",
            city: false,
            leadId: true
        },
        {
            id: 33,
            text: "Health Consultation",
            city: false,
            leadId: true
        },
        {
            id: 34,
            text: "Resampling",
            city: false,
            leadId: false
        },
        {
            id: 35,
            text: "Language Barrier",
            city: false,
            leadId: false
        },
          {
            id: 36,
            text: "RCFresh",
            city: false,
            leadId: false
        },
    ]

    const priorityArr: any = [
        {
            value: "SuperCritical",
        },
        {
            value: "Urgent",
        },
        {
            value: "HopeFull",
        },
        {
            value: "Low",
        }
    ]

    const [filtered, setFiltered] = useState(false)

    useEffect(() => {
        getLeadSource()
        // getCities()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filterLeads = () => {
        const body: any = {
           lead_call_status_id: category.join(","),
            leadSource: leadSource.join(","),
            priority: priority.join(","),
            createdAt,
            followUpDate,
            city: city.join(","),
            start_date,
            end_date,
            id
        }
        const url = generateLeadsUrl(body).substring(2)
        getAllLeads(`?${url}&download=true`)
        setFiltered(true)
    }

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} /><Grid
                    container
                    spacing={2}
                    direction="row"
                    style={{ alignItems: "right" }}
                >
                    <Grid item xs={12} sm={8}>
                        <h2 style={{ fontWeight: "bold" }}>EXPORT LEADS</h2>
                    </Grid>
                </Grid>
                <Grid container spacing={3} direction="row" style={{alignItems:"left"}}>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="leadId"
                            type="text"
                            value={id}
                            className="input"
                            variant="outlined"
                            placeholder="Lead Id"
                            onChange={(e) => setId(e.target.value as string)}
                            style={{ width: "100%" }}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="city"
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setCity(sourceIds)
                            }}
                            limitTags={1}
                            options={cities || []}
                            freeSolo
                            blurOnSelect
                            aria-required
                            multiple
                            getOptionLabel={(option: any) => option.name}
                            getOptionDisabled={(option: any) => {
                                return city.includes(option.id)
                            }}
                            disableClearable
                            disableCloseOnSelect
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                                    getCities(newInputValue)
                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setCity(0)
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="City"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="start_date"
                            type="date"
                            label="Start Date"
                            value={start_date}
                            variant="outlined"
                            onChange={(e) => setStart_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="end_date"
                            type="date"
                            value={end_date}
                            className="input"
                            label="End Date"
                            variant="outlined"
                            onChange={(e) => setEnd_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>

                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="Created At"
                            type="date"
                            label="Created Date"
                            value={createdAt}
                            variant="outlined"
                             disabled={start_date !== "" || end_date !== ""}
                            onChange={(e) => setCreatedAt(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="Created At"
                            type="date"
                            label="Follow-Up Date"
                            value={followUpDate}
                            variant="outlined"
                            onChange={(e) => setFollowUpDate(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setCategory(sourceIds)
                            }}
                            multiple
                            id="checkboxes-tags-demo"
                            options={CATEGORY || []}
                            disableCloseOnSelect
                            limitTags={1}
                            getOptionLabel={(option: any) => option.text}
                            getOptionDisabled={(option: any) => {
                                return category.includes(option.id)
                            }}
                            disableClearable
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Status"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setleadSource(sourceIds)
                            }}
                            multiple
                            id="checkboxes-tags-demo"
                            options={lead_source || []}
                            disableCloseOnSelect
                            limitTags={1}
                            getOptionLabel={(option: any) => option.name}
                            getOptionDisabled={(option: any) => {
                                return leadSource.includes(option.id)
                            }}
                            disableClearable
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Lead Source"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.value)
                                }
                                setPriority(sourceIds)
                            }}
                            multiple
                            id="checkboxes-tags-demo"
                            options={priorityArr || []}
                            disableCloseOnSelect
                            limitTags={1}
                            getOptionLabel={(option: any) => option.value}
                            getOptionDisabled={(option: any) => {
                                return priority.includes(option.value)
                            }}
                            disableClearable
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Priority"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{ height: "56px" }}
                            fullWidth
                            onClick={filterLeads}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ height: "56px" }}
                            fullWidth
                            onClick={() => {
                                setFiltered(false)
                                history.push("/dashboard/sla/leads/export")
                            }}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <br />
                {loading ? <Loader /> : filtered && all_lead.results && all_lead.results.length > 0 && (
                    <Box>
                        <Container maxWidth={false}>
                            <Grid container spacing={3}>
                                <Export loading={loading} all_lead={all_lead} />
                            </Grid>
                        </Container>
                    </Box>
                )}
                {all_lead.results && all_lead.results.length === 0 && (
                    <h6 style={{ textAlign: "center" }}>No Data Found</h6>
                )}
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    all_lead: state.SalesLeadAdminReducer.all_lead,
    lead_source: state.SalesLeadAdminReducer.lead_source,
    user_panel: state.SalesLeadAdminReducer.user_panel,
    agentsList: state.SalesLeadAdminReducer.agentsList,
    cities: state.SalesLeadAdminReducer.cities,
    loading: state.SalesLeadAdminReducer.loading,
});

export default connect(mapStateToProps, { getAllLeads, clickToCall, getLeadSource, getCities, leadTransferByComma, getAgentList })(
    LeadsTable
);
