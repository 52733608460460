import React, { useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CardActions from "@material-ui/core/CardActions";
import Button from '@mui/material/Button';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { updateLeadDetailsF_1, getCities, getAreaName } from "../../actions/bookingActions";
import "./index.sass";
import { Grid, Paper } from "@material-ui/core";
import { clickToCall } from '../../actions/bookingActions'
import CallIcon from "@material-ui/icons/Call";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "100px",
      height: "60px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem"
    },
    selectedSlot: {
      width: "100px",
      height: "60px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, white, cyan)",
      cursor: "pointer",
      margin: "0.5rem"
    },
    chips: {
      display: 'flex',
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px"
    },
  })
);
interface Props {
  updateLeadDetailsF_1: Function;
  id: any;
  cities: any;
  getCities: any,
  areaList: any,
  getAreaName: any,
  data: any;
  clickToCall: Function;
}

const Relevent: React.FC<Props> = ({
  updateLeadDetailsF_1,
  id,
  cities,
  getCities,
  areaList,
  getAreaName,
  data,
  clickToCall
}) => {
  const classes = useStyles();
  const [patient_name, setPatientName] = React.useState<string>("");
  const [patient_age, setPatientAge] = React.useState<string>("");
  const [calling_gender, setCallingGender] = React.useState<string>("");
  const [priority, setpriority] = React.useState<string>(data.priority || "none");
  const [content_number, setContentNumber] = React.useState<string>("");
  const [whatsapp_contact, setWhatsappContact] = React.useState<string>("");
  const [address, setAddress] = React.useState<string>("");
  const [agent, setAgent] = React.useState<string>("")
  const [city, setCity] = React.useState<number>(0);
  const [cityName, setCityName] = React.useState<string>("");
  const [area, setArea] = React.useState<number>(0);

  const timer = useRef<any>(0)

  useEffect(() => {
    setPatientName(data.patient_name)
    setPatientAge(data.patient_age)
    setCallingGender(data.calling_gender)
    setContentNumber(data.call)
    setWhatsappContact(data.whatsapp_contact)
    setCity(data.patient_city_id)
    setCityName(data.patient_city)
    setArea(data.patient_area)
    setAddress(data.address)
    setAgent(data.panel_user)
    getCities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    getAreaName(city)
  }, [city, getAreaName])

  const handleCallClick = (id: number, type: string) => {
    clickToCall(id, type);
  };

  const [mobileValidation, setMobileValidation] = React.useState({
    customer_phonenumber: true,
    customer_whatsapppnumber: true,
  })

  const verifyMobile = (type: any) => {
    if(type==="mobile"){
      if(parseInt(content_number&&content_number[0])<6){
        setMobileValidation((prev: any) => ({
              ...prev,
              customer_phonenumber: false,
            }));}
    }
  
    if(type=== "whatsapp" ){
      if(parseInt(whatsapp_contact&&whatsapp_contact[0])<6){
        setMobileValidation((prev: any) => ({
              ...prev,
              customer_whatsapppnumber: false,
            }));}
    }
  };

  const submitForm = async () => {
    let body = {
      patient_name,
      patient_age,
      calling_gender,
      priority,
      content_number,
      whatsapp_contact,
      address,
      patient_city: city,
      patient_area: area,
    }
    updateLeadDetailsF_1(id, body)
  };
  return (
    <Paper className={classes.paper} elevation={15}>

      <Grid container direction="row" justify="space-between" alignItems="flex-end" spacing={3} style={{ margin: "1rem auto" }}>
        <Grid item xs>
          <p>Customer Name</p>
          <TextField
            name="customer_name"
            type="text"
            placeholder="Enter Name"
            value={patient_name || ""}
            className={classes.textField}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%", margin: "0" }}
            onChange={(e) => setPatientName(e.target.value as any)}
          />
        </Grid>
        <Grid item xs>
          <p>Age</p>
          <TextField
            name="customer_age"
            type="text"
            placeholder="Enter Age"
            value={patient_age || ""}
            className={classes.textField}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%", margin: "0" }}
            onChange={(e) => setPatientAge(e.target.value as any)}
          />
        </Grid>
        <Grid item xs>
          <p>Agent</p>
          <TextField
            name="agent"
            type="text"
            placeholder="Enter Agent"
            value={agent || ""}
            className={classes.textField}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%", margin: "0" }}
            onChange={(e) => setAgent(e.target.value as any)}
            disabled
          />
        </Grid>
        <Grid item xs>
          <p>Gender</p>
          <Select
            name="customer_gender"
            variant="outlined"
            defaultValue="male"
            value={calling_gender || "none"}
            style={{ width: "100%", margin: "0", padding: "0" }}
            onChange={(e) => setCallingGender(e.target.value as any)}
          >
            <MenuItem value={"none"}>Select</MenuItem>
            <MenuItem value={"male"}>Male</MenuItem>
            <MenuItem value={"female"}>Female</MenuItem>
            <MenuItem value={"other"}>Other</MenuItem>
          </Select>
        </Grid>
        <Grid item xs>
          <p>Priority</p>
          <Select
            name="customer_gender"
            variant="outlined"
            defaultValue="male"
            value={priority || "none"}
            style={{ width: "100%", margin: "0", padding: "0" }}
            onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
              setpriority(e.target.value as string)
            }
          >
            <MenuItem value={"none"}>Select</MenuItem>
            <MenuItem value={"SuperCritical"}>SuperCritical</MenuItem>
            <MenuItem value={"Urgent"}>Urgent</MenuItem>
            <MenuItem value={"HopeFull"}>HopeFull</MenuItem>
            <MenuItem value={"Low"}>Low</MenuItem>
            <MenuItem value={"Other"}>Other</MenuItem>
          </Select>
        </Grid>
      </Grid>

      <Grid container direction="row" justify="space-between" alignItems="flex-end" spacing={3} style={{ margin: "1rem auto" }}>
        <Grid item xs>
          <p>Mobile Number
            <CallIcon
              onClick={() => handleCallClick(data.id, "contact")}
            />
          </p>
          <TextField
            name="mobile_number"
            type="text"
            placeholder="Enter Number"
            value={content_number || ""}
            className={classes.textField}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%", margin: "0" }}
            onChange={(e) => setContentNumber(e.target.value as any)}
            onBlur={() => verifyMobile("mobile")}
            onFocus={() => {
              setMobileValidation((prev: any) => ({
                ...prev,
                customer_phonenumber: true
              }))
            }}
            helperText={!mobileValidation.customer_phonenumber && "Incorrect Contact Number"}
          />
        </Grid>
        <Grid item xs>
          <p>Whatsapp Number
            <CallIcon
              onClick={() => handleCallClick(data.id, "whatsapp")}
            />
          </p>
          <TextField
            name="whatsapp_number"
            type="text"
            placeholder="Enter Number"
            value={whatsapp_contact || ""}
            className={classes.textField}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%", margin: "0" }}
            onChange={(e) => setWhatsappContact(e.target.value as any)}
            onBlur={() => verifyMobile("whatsapp")}
            onFocus={() => {
              setMobileValidation((prev: any) => ({
                ...prev,
                customer_whatsapppnumber: true
              }))
            }}
            helperText={!mobileValidation.customer_whatsapppnumber && "Incorrect Whatsapp Number"}
          />
        </Grid>
        <Grid item xs>
          <p>City</p>
          <Autocomplete
            id="city"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setCity(obj.id)
              }
            }}
            options={cities}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) => option.name}
            inputValue={cityName}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current)
              setCityName(newInputValue)
              timer.current = setTimeout(() => {
                getCities(newInputValue)
              }, 1000)
              if (newInputValue.length === 0) {
                setCity(0)
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="City"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs>
          <p>Area</p>
          <Select
            name="area"
            placeholder="Enter Area Name"
            value={area || "none"}
            className={classes.textField}
            variant="outlined"
            style={{ width: "100%", margin: "0" }}
            onChange={(e) => setArea(e.target.value as any)}
          >
            <MenuItem value={"none"} disabled>Select Area</MenuItem>
            {city !== 0 && areaList.results && areaList.results.map((item: any) => {
              return (
                <MenuItem value={item.id}>{item.area}</MenuItem>
              )
            })}
          </Select>
        </Grid>

      </Grid>


      <Grid container direction="row" justify="space-between" alignItems="flex-end" spacing={3} style={{ margin: "1rem auto" }}>
        <Grid item xs>
          <p>Address</p>
          <TextField
            name="address"
            type="text"
            placeholder="Enter Address"
            value={address || ""}
            className={classes.textField}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            style={{ width: "100%", margin: "0" }}
            onChange={(e) => setAddress(e.target.value as any)}
          />
        </Grid>
      </Grid>

      <CardActions
        style={{ paddingLeft: "16px" }}
      >
        <Button
          style={{
            background: "#924A91",
            color: "#fff",
            paddingTop: "0",
            paddingBottom: "0",
            width: "100%",
          }}
          size="large"
          onClick={submitForm}
          disabled={
            !mobileValidation.customer_phonenumber ||
            !mobileValidation.customer_whatsapppnumber ||
            !cityName
          }
        >
          <b>Submit</b>
        </Button>
      </CardActions>
    </Paper>
  );
};

const mapStateToProps = (state: any) => ({
  cities: state.CustomerLeadReducer.cities,
  areaList: state.CustomerLeadReducer.areaList,
});

export default connect(mapStateToProps, { updateLeadDetailsF_1, getCities, getAreaName, clickToCall })(
  Relevent
);
