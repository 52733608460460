import React from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, } from '@material-ui/data-grid';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
    })
);

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

interface Props {
    all_lead: any;
    loading: boolean;
}

const LeadsExport: React.FC<Props> = ({
    all_lead,
    loading
}) => {
    const classes = useStyles();

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'created_at', headerName: 'Created Date', width: 200 },
        { field: 'call', headerName: 'Call', width: 200 },
        { field: 'count', headerName: 'Count', width: 200 },
        { field: 'call_status', headerName: 'Call Status', width: 200 },
        { field: 'calling_gender', headerName: 'Calling Gender', width: 200 },
        { field: 'content_number', headerName: 'Contact Number', width: 200 },
        { field: 'followup_date', headerName: 'Follow Up Date', width: 200 },
        { field: 'followup_time', headerName: 'Follow Up Time', width: 200 },
        { field: 'language', headerName: 'Language', width: 200 },
        { field: 'lead_source', headerName: 'Lead Source', width: 200 },
        { field: 'panel_user', headerName: 'Panel user', width: 200 },
        { field: 'patient_name', headerName: 'patient Name', width: 200 },
        { field: 'patient_age', headerName: 'patient Age', width: 200 },
        { field: 'patient_area', headerName: 'patient Area', width: 200 },
        { field: 'patient_city', headerName: 'patient City', width: 200 },
        { field: 'priority', headerName: 'Priority', width: 200 },
        { field: 'whatsapp_contact', headerName: 'Whatsapp Contact', width: 200 },
    ];
    let rows:any = []
if (all_lead.results) {
        rows = all_lead.results.length > 0 && all_lead.results.map((data: any) => {
            return {
                ...data,
                id: data.id||data.pk,
                created_at:new Date(data.created_at).toLocaleString(),
                call:data.call,
                call_status:data.call_status,
                calling_gender:data.calling_gender,
                content_number:data.content_number,
                followup_date:data.followup_date,
                followup_time:data.followup_time,
                language:data.language,
                lead_source:data.lead_source,
                panel_user:data.panel_user,
                patient_name:data.patient_name,
                patient_age:data.patient_age,
                patient_area:data.patient_area&&data.patient_area.area,
                patient_city:data.patient_city,
                priority:data.priority,
                whatsapp_contact:data.whatsapp_contact
            }
        })
    }
    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Paper elevation={15} className={classes.paper}>
                <div style={{ height: 700 }}>
                    <DataGrid rowsPerPageOptions={[]}
                        rows={rows}
                        columns={columns}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        pageSize={50}
                        rowCount={rows.length|| 0}
                        loading={loading}
                    />
                </div>
            </Paper>
        </main>
    )
}

const mapStateToProps = (state: any) => ({

})

export default connect(mapStateToProps, {

})(LeadsExport);