import React from "react";
import CreateCall from "../create-call";

function ModelFormSelect({ index, setOpen, open }) {
  switch (index) {
    case 7:
      return <CreateCall setOpen={setOpen} />;
    default:
      return null;
  }
}

export default ModelFormSelect;
