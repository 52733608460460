import React, { useState, useEffect } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Grid, TextField, withStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import { useRef } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../Components/loader';
import moment from 'moment';
import { MessageRounded } from '@material-ui/icons';
import { getIncorrectNumberLead } from '../actions/salesLeadAdminAction'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from "react-router";
// import { generateEmailFilterUrl } from "../../helpers/generateUrl";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        listStyle: {
            marginLeft: "20px",
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,fontWeight:600
        },

    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

interface Props {
    getIncorrectNumberLead: any,
    incorrectNumberLeads: any,
    loading: Boolean;
}

const BookingsTable: React.FC<Props> = ({
    getIncorrectNumberLead,
    incorrectNumberLeads,
    loading
}) => {
    const classes = useStyles()
    useEffect(() => {
        getIncorrectNumberLead()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const history = useHistory()
    const [bookingId, setBookingId] = useState<any>("")
    const [email, setEmail] = useState("")
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = incorrectNumberLeads.links && incorrectNumberLeads.links.next;
            getIncorrectNumberLead(url);
        } else if (newPage < page) {
            let url = incorrectNumberLeads.links && incorrectNumberLeads.links.previous;
            getIncorrectNumberLead(url);
        }
        setPage(newPage as number);
    };

    // const filterBookings = () => {
    //     const body: any = {
    //         booking:bookingId,
    //         email,
    //     }
    //     const url = generateEmailFilterUrl(body).substring(2)
    //     getIncorrectNumberLead(`${url}`)
    //     // setPage(0)
    // }

    return (
        <div style={{ width: "100%" }} className="data-table">
            <div className={classes.toolbar} />
            <h2>Incorrect Lead Number</h2>
            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "560px" }}>

                {loading ? <Loader /> : (
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="center">Phone Number</StyledTableCell>
                                <StyledTableCell align="center">lead_source</StyledTableCell>
                                <StyledTableCell align="center">Created At</StyledTableCell>
                                <StyledTableCell align="center">Updated at</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {
                                incorrectNumberLeads.results && incorrectNumberLeads.results.length > 0 && incorrectNumberLeads.results.map((data: any, index: any) => {
                                    return (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell style={{ height: "50px" }} align="center">{data.phone_number}</StyledTableCell>
                                            <StyledTableCell style={{ height: "50px" }} align="center">{data.lead_source || "NA"}</StyledTableCell>
                                            <StyledTableCell style={{ height: "50px" }} align="center">{new Date(data.created_at).toLocaleString()}</StyledTableCell>
                                            <StyledTableCell style={{ height: "50px" }} align="center">{new Date(data.updated_at).toLocaleString()}</StyledTableCell>

                                        </StyledTableRow>
                                    )
                                })}
                        </TableBody>
                        <TableFooter>
                            <StyledTableRow>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    colSpan={5}
                                    count={incorrectNumberLeads.count || 0}
                                    rowsPerPage={incorrectNumberLeads.page_size}
                                    page={page}
                                    onPageChange={handleChangePage}
                                />
                            </StyledTableRow>
                        </TableFooter>
                    </Table>
                )}
            </TableContainer>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    incorrectNumberLeads: state.SalesLeadAdminReducer.incorrectNumberLeads,
})

export default connect(mapStateToProps, {
    getIncorrectNumberLead,

})(BookingsTable);

