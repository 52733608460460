import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { Edit, Message } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../components/loader";
import { MessageRounded } from "@material-ui/icons";
import CallIcon from "@material-ui/icons/Call";
// import { clickToCallBooking } from "../actions/QualityExecutiveActions";
import AdditonalFamilyModal from "../tables/AdditionalFamily/AdditionFamilyDetails";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    // paper: {
    //   width: "120%",
    // },
    table: {
      maxWidth: "100%",
    },
    tableHead: {
      background: "rgb(146, 74, 145)!important",
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    fontWeight: {
      fontWeight: "bold",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface BookingListProps {
  getQualityScoringReport: any;
  qualityScoringReport: any;
  openModal: any;
  setOpenModal: any;
  dataForModal: any;
  setDataForModal: any;
  statusForAuditScore: any;
  setStatusForAuditScore: any;
  statusForEditButton: any;
  setStatusForEditButton: any;
  loading: Boolean;
}

const AuditScoreView: React.FC<BookingListProps> = ({
  getQualityScoringReport,
  qualityScoringReport,
  openModal,
  setOpenModal,
  dataForModal,
  setDataForModal,
  statusForAuditScore,
  setStatusForAuditScore,
  statusForEditButton,
  setStatusForEditButton,
  loading,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [quantityDetails, setQuantityDetails] = useState<any>([]);
  const [fullWidth, setFullWidth] = React.useState<any>(true);
  const [maxWidth, setMaxWidth] = React.useState<any>("md");
  const [page, setPage] = React.useState<any>(0);
  const [getId, setGetId] = useState<number>(0);

  const viewScore = (getQuantityDetails: any) => {
    setQuantityDetails(getQuantityDetails);
    setOpen(true);
  };

  const handleOpenModal = (singleObj: any) => {
    setDataForModal(singleObj);
    setGetId(singleObj.id);
    setStatusForAuditScore(true);
    setOpenModal(true);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        qualityScoringReport.links &&
        qualityScoringReport.links.next.split("?")[1];
      getQualityScoringReport(url);
    } else if (newPage < page) {
      let url =
        qualityScoringReport.links &&
        qualityScoringReport.links.previous.split("?")[1];
      getQualityScoringReport(url);
    }
    setPage(newPage as number);
  };

  return (
    <div style={{ width: "100%" }} className="data-table">
      <Dialog
        // fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          style={{ color: "purple", background: "pink" }}
          id="alert-dialog-title"
        >
          View Score
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Paper>
              <TableContainer style={{ height: "500px" }}>
                <Table stickyHeader aria-label="simple">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className={classes.fontWeight}>
                        Parameter
                      </TableCell>
                      <TableCell align="center" className={classes.fontWeight}>
                        Sub-Parameter
                      </TableCell>
                      <TableCell align="center" className={classes.fontWeight}>
                        Remark
                      </TableCell>
                      <TableCell align="center" className={classes.fontWeight}>
                        Comment
                      </TableCell>
                      <TableCell align="center" className={classes.fontWeight}>
                        Score
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {quantityDetails &&
                      quantityDetails.length > 0 &&
                      quantityDetails.map((list: any, index: any) => (
                        <TableRow key={index}>
                          <TableCell align="center">
                            {list?.parameter}
                          </TableCell>
                          <TableCell align="center">
                            {list?.subparameter}
                          </TableCell>
                          <TableCell align="center">
                            {list?.remark === null ? "N/A" : list?.remark}
                          </TableCell>
                          <TableCell align="center">
                            {list?.comment ? list?.comment : "N/A"}
                          </TableCell>
                          <TableCell align="center">{list?.score}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "560px", height: "600px" }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <StyledTableRow>
                <StyledTableCell className={classes.tableHeadCell}>
                  Lead&nbsp;Id
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  UCID
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Agent
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  TL
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  QA
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  &nbsp;&nbsp;Call&nbsp;Date&nbsp;&nbsp;
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  &nbsp;&nbsp;Audit&nbsp;Date
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Disposition
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Call Duration
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Audio Call
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Language
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Call Observeration
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Soft&nbsp;Skills
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Overall&nbsp;Score
                </StyledTableCell>
                <StyledTableCell align="center">View</StyledTableCell>
                {/* <StyledTableCell align="center">Edit</StyledTableCell> */}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {qualityScoringReport?.results &&
                qualityScoringReport?.results?.length > 0 &&
                qualityScoringReport?.results?.map((list: any) => {
                  return (
                    <StyledTableRow key={list.id}>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {list?.lead_details?.id}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {list?.call_sid}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {list?.agent_name}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {list?.tl}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {list?.quality_username}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {moment(list?.created_at).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {moment(list?.audit_date).format("DD-MM-YYYY")}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {list?.disposition ? list?.disposition : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {list?.callduration}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {list.uploaded_url ? (
                          <audio controls muted>
                            <source src={list.uploaded_url} type="audio/wav" />
                            Your browser does not support the
                            <code>audio</code> element.
                          </audio>
                        ) : (
                          "N/A"
                        )}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {list?.language?.map((list2: any) => list2?.name_en)}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {list?.remark}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {list?.soft_skills.toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {Number(list?.score).toFixed(2)}
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        <RemoveRedEyeIcon
                          onClick={() => viewScore(list?.quality_details)}
                        />
                      </StyledTableCell>
                      {/* <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                      >
                        {statusForEditButton === true ? (
                          <Button disabled>
                            <Edit />
                          </Button>
                        ) : (
                          <Button
                            disabled={
                              getId === 0
                                ? false
                                : list?.id === getId
                                ? false
                                : true
                            }
                          >
                            <Edit
                              onClick={() => {
                                handleOpenModal(list);
                              }}
                            />
                          </Button>
                        )}
                      </StyledTableCell> */}
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={3}
                count={qualityScoringReport.count || 0}
                rowsPerPage={qualityScoringReport.page_size}
                page={page}
                onPageChange={handleChangePage}
              />
            </TableFooter>
          </Table>
        )}
      </TableContainer>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(AuditScoreView);
