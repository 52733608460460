import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from '@mui/material/Button';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { leadTransferByComma, leadTransfer } from "../../../actions/bookingActions";
import { isEmpty } from "../../../../helpers/isEmpty";
import {Container, Grid } from "@material-ui/core";
import Box from '@mui/material/Box';
const useStylesNew = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: 'space-between'
    }
  })
);
const useStyles = makeStyles({
  root: {
    flexGrow: 0,
    background: "#F7F7F7",
    margin: "10px 0",
  },
});

interface Props {
  leadTransfer: Function;
  leadTransferByComma: Function;
  user_panel: any;
  // call_status: any;
  loading: boolean;
}

const CLLeadTransfer: React.FC<Props> = ({
  leadTransfer,
  leadTransferByComma,
  user_panel,
  // call_status,
  loading,
}) => {
  const classes = useStyles();
  const classesNew = useStylesNew();

  const [ids, setIDs] = React.useState<string>("");
  const [panel_user, setPanelUser] = React.useState<number | null>(null);
  const [lead_type, setLeadType] = React.useState<string>("id");
  const [priority, setPriority] = React.useState<string>("Super");
  const [status_call, setCallStatus] = React.useState<number>(0);

  const CATEGORY: any = [
    {
      id: 1,
      text: "Interested/Follow up",
      city: true,
      leadId: false
    },
    {
      id: 2,
      text: "Not Interested",
      city: true,
      query: false,
    },
    {
      id: 3,
      text: "Order Booked",
      city: true,
      query: false,
    },
    {
      id: 4,
      text: "Ringing",
      city: false,
      query: false,
    },
    {
      id: 5,
      text: "Number Doesn't Exist",
      city: false,
      query: false,
    },
    {
      id: 6,
      text: "By Mistake & Wrong Number",
      city: false,
      query: false,
    },
    {
      id: 7,
      text: "Call Back",
      city: true,
      query: false,
    },
    {
      id: 8,
      text: "Business Realated Call",
      city: true,
      query: false,
    },
    {
      id: 9,
      text: "Spam Call",
      city: false,
      query: false,
    },
    {
      id: 10,
      text: "Enquiry Call",
      city: true,
      query: false,
    },
    {
      id: 11,
      text: "CFresh",
      city: false,
      query: false,
    },
    {
      id: 12,
      text: "Test Not Available",
      city: true,
      query: false,
    },
    {
      id: 13,
      text: "Subscribed to Competitor",
      city: true,
      query: false,
    },
    {
      id: 14,
      text: "Slot Issue",
      city: true,
      query: false,
    },
    {
      id: 15,
      text: "Same Day Slot",
      city: true,
      query: false,
    },
    {
      id: 16,
      text: "Already Booked",
      city: false,
      query: false,
    },
    {
      id: 17,
      text: "Not Contactable",
      city: false,
      query: false,
    },
    {
      id: 18,
      text: "Out of Station",
      city: true,
      query: false,
    },
    {
      id: 19,
      text: "Out of Service Area",
      city: true,
      query: false,
    },
    {
      id: 20,
      text: "Need Time to Think",
      city: true,
      query: false,
    },
    {
      id: 21,
      text: "Email/Whatsapp Package",
      city: true,
      leadId: false
    },
    {
      id: 22,
      text: "Discount/Price Enquiry",
      city: true,
      leadId: false
    },
    {
      id: 23,
      text: "CS issue",
      city: false,
      leadId: true
    },
    {
      id: 24,
      text: "Call Not Reachable",
      city: false,
      leadId: false
    },
    {
      id: 25,
      text: "Call Later",
      city: true,
      leadId: false
    },
    {
      id: 26,
      text: "Call drop",
      city: true,
      leadId: false
    },
    {
      id: 27,
      text: "Call Disconnected",
      city: true,
      leadId: false
    },
    {
      id: 28,
      text: "Below <5 year patient",
      city: true,
      leadId: false
    },
    {
      id: 29,
      text: "DND",
      city: false,
      leadId: false
    },
    {
      id: 30,
      text: "Phlebo's Issue",
      city: false,
      leadId: true
    },
    {
      id: 31,
      text: "Reports Regarding Issue",
      city: false,
      leadId: true
    },
    {
      id: 32,
      text: "Health Advice",
      city: false,
      leadId: true
    },
    {
      id: 33,
      text: "Health Consultation",
      city: false,
      leadId: true
    },
    {
      id: 34,
      text: "Health Consultation",
      city: false,
      leadId: false
    },
    {
      id: 35,
      text: "Language Barrier",
      city: false,
      leadId: false
    },
    {
      id: 36,
      text: "RCFresh",
      city: false,
      leadId: false
    },
  ]

  useEffect(() => {
    leadTransfer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLeadChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLeadType(event.target.value as string);
  };

  const handlePriority = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPriority(event.target.value as string);
  };

  const handleIDs = (event: React.ChangeEvent<{ value: unknown }>) => {
    setIDs(event.target.value as string);
  };

  const handleCallStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCallStatus(event.target.value as number);
  };

  const submitForm = async () => {
    let body = {
      lead_ids: ids,
      value: lead_type,
      status_call,
      panel_user,
      priority,
    };
    await leadTransferByComma(body);
  };

  return (
    <>
      <main className={classesNew.content}>
        <div className={classesNew.toolbar} />
        <Box>
          <Container maxWidth={false}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid item>
                  <Card className={classes.root}>
                    <CardContent>
                      <div className="header-collapse">Lead Transfer </div>
                      <form className="form-fields info">
                        <div className="form-elements" style={{ display: "flex" }}>
                          <div style={{ width: "100%" }} >
                            <p>Lead ID</p>
                            <TextareaAutosize
                              aria-label="minimum height"
                              minRows={10}
                              style={{ width: "100%" }}
                              value={ids}
                              onChange={handleIDs}
                            />
                          </div>
                        </div>
                        <div className="form-elements" style={{ display: "flex" }}>
                          <div style={{ width: "100%" }}>
                            <p>Number is</p>
                            <FormControl variant="outlined" style={{ width: "100%" }} required>
                              <Select
                                id="demo-simple-select-required"
                                style={{ width: "100%" }}
                                inputProps={{ "aria-label": "Without label" }}
                                value={lead_type}
                                onChange={handleLeadChange}
                              >
                                <MenuItem value={"number"}>Phone no</MenuItem>
                                <MenuItem value={"id"}>Lead ID</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <div className="form-elements" style={{ display: "flex" }}>
                          <div style={{ width: "100%" }}>
                            <p>Username</p>
                            <FormControl variant="outlined" style={{ width: "100%" }} required>
                              {!isEmpty(user_panel) && user_panel.panel_user.length > 0 ? (
                                <Autocomplete
                                  id="combo-box-demo"
                                  // value={treatment_city}
                                  onChange={(event, newValue) => {
                                    if (newValue) {
                                      let obj = JSON.parse(
                                        JSON.stringify(newValue, null, " ")
                                      );

                                      setPanelUser(obj.id);
                                    }
                                  }}
                                  options={user_panel.panel_user}
                                  freeSolo
                                  getOptionLabel={(option: any) => option.user}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      style={{ width: "100%" }}
                                    />
                                  )}
                                />
                              ) : (
                                <TextField variant="outlined" />
                              )}
                            </FormControl>
                          </div>
                        </div>
                        <div className="form-elements" style={{ display: "flex" }}>
                          <div style={{ width: "100%" }}>
                            <p>Call Status</p>
                            <FormControl variant="outlined" style={{ width: "100%" }} required>
                              <Select
                                id="demo-simple-select-required"
                                style={{ width: "100%" }}
                                inputProps={{ "aria-label": "Without label" }}
                                value={status_call}
                                onChange={handleCallStatus}
                              >
                                <MenuItem value={0} disabled>Select Call Status</MenuItem>
                                {CATEGORY.map((item: any) => {
                                  return (
                                    <MenuItem value={item.id} key={item.id}>{item.text}</MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                        <div className="form-elements" style={{ display: "flex" }}>
                          <div style={{ width: "100%" }}>
                            <p>Priority</p>
                            <FormControl variant="outlined" style={{ width: "100%" }} required>
                              <Select
                                id="demo-simple-select-required"
                                style={{ width: "100%" }}
                                inputProps={{ "aria-label": "Without label" }}
                                value={priority}
                                onChange={handlePriority}
                              >
                                <MenuItem value={0}>Select Priority</MenuItem>
                                <MenuItem value={"Urgent"}>Urgent</MenuItem>
                                <MenuItem value={"Critical"}>Critical</MenuItem>
                                <MenuItem value={"High"}>High</MenuItem>
                                <MenuItem value={"Hopefull"}>Hopefull</MenuItem>
                                <MenuItem value={"Medium"}>Medium</MenuItem>
                                <MenuItem value={"Super"}>Super</MenuItem>
                                <MenuItem value={"SuperCritical"}>SuperCritical</MenuItem>
                              </Select>
                            </FormControl>
                          </div>
                        </div>
                      </form>
                      <CardActions style={{ padding: "8px 0" }}>
                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          fullWidth
                          onClick={submitForm}
                          disabled={ids === "" || panel_user === null}
                        >
                          <p style={{ margin: "0" }}>
                            {loading ? (
                              <CircularProgress style={{ color: "#fff" }} size={24} />
                            ) : (
                              "Submit"
                            )}
                          </p>
                        </Button>
                      </CardActions>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  // call_status: state.leadTransferReducer.call_status,
  user_panel: state.CustomerLeadReducer.user_panel,
  loading: state.CustomerLeadReducer.loading,
});
export default connect(mapStateToProps, { leadTransferByComma, leadTransfer })(
  CLLeadTransfer
);
