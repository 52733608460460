
import React, { useState } from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  withStyles,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  TableCell,
  TableBody,
  Chip,
} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Button from '@mui/material/Button';
import { Paper, TextField } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import {
  bookingAutoAssign,
  getAssignedUnassignedBooking,
  getCities,
  updateBooking,
  getZone,
  syncBookingtoMapMyIndia,
  getPhleboAutoAssign,
} from "../../actions/salesLeadAdminAction";
import "./index.sass";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useEffect } from "react";
import { useRef } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Link, useHistory } from "react-router-dom";
import { generateBookingFilterUrl } from "../../../helpers/generateUrl";
import Loader from "../Loader2/index";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 10,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getAssignedUnassignedBooking: any;
  allBookings: any;
  getCities: any;
  cities: any;
  updateBooking: any;
  getZone: any;
  zoneList: any;
  bookingAutoAssign: any;
  loading: any;
  syncBookingtoMapMyIndia: any;
  assignedloader: boolean
  getPhleboAutoAssign: any;
  phleboAutoAssign: any;
}

const getItemStyle = (
  isDragging: any,
  draggableStyle: any,
  status: any,
  item: any,
  vstatus: any
) => {
  return {
    userSelect: "none",
    padding: 1,
    margin: `5px`,
    maxWidth: "200px",
    background: item
      ? "#ce93d8"
      : vstatus === "pending"
        ? "#fbc02d"
        : isDragging
          ? "violet"
          : status === "NA"
            ? "white"
            : status === "hold"
              ? "lightyellow"
              : status === "pending"
                ? "cyan"
                : status === "confirmed"
                  ? "greenyellow"
                  : status === "cancelled"
                    ? "pink"
                    : status === "bookingcancelled"
                      ? "red"
                      : "lightyellow",
    ...draggableStyle,
  };
};

const getListStyle = (isDraggingOver: any) => ({
  padding: 1,
  minWidth: "200px",
});

const BookingCard: React.FC<Props> = ({
  getAssignedUnassignedBooking,
  allBookings,
  getCities,
  cities,
  updateBooking,
  getZone,
  zoneList,
  bookingAutoAssign,
  loading,
  syncBookingtoMapMyIndia,
  assignedloader,
  getPhleboAutoAssign,
  phleboAutoAssign
}) => {
  const classes = useStyles();

  const history = useHistory();

  useEffect(() => {
    getCities();
    getZone();
    return () => {
      getAssignedUnassignedBooking("", true);
    };
  }, []);

  const [city, setCity] = useState(0);
  const [collection_date, setCollection_date] = useState("");

  const [bookings, setBookings] = useState<any>([]);
  const [phlebos, setPhlebos] = useState<any>([]);
  const [zoneName, setZoneName] = useState<number>(0);
  const [currentDay, setCurrentDay] = useState<string>("0")
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)
  const timer = useRef<any>(0);

  useEffect(() => {
    let tempBookings: any = [];
    let tempPhlebos: any = [];
    Object.keys(allBookings).map((el: any) => {
      if (el !== "phlebos") {
        tempBookings.push(allBookings[el]);
      } else {
        tempPhlebos.push(...allBookings[el]);
      }
    });
    setBookings(tempBookings.filter((item: any) => item !== undefined));
    setPhlebos(tempPhlebos.filter((item: any) => item !== undefined));
  }, [allBookings]);

  const onDragEnd = async (result: any) => {
    const { source, destination, draggableId } = result;

    if (!destination) {
      return;
    }

    const sInd: any = +draggableId;
    const dInd: any = destination.droppableId.split("-")[2];

    if (
      source.droppableId !== destination.droppableId &&
      dInd &&
      dInd !== "unassigned"
    ) {
      const data: any = {
        phlebo: dInd,
        manual_asigned: true,
      };
      await updateBooking(data, sInd);
      filterBookings();
    } else if (dInd && dInd === "unassigned") {
      const data: any = {
        remove_phlebo: true,
      };
      await updateBooking(data, sInd);
      filterBookings();
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentDay((event.target as HTMLInputElement).value);
  };

  const filterBookings = () => {
    const body: any = {
      city,
      collection_date,
    };
    const url = generateBookingFilterUrl(body).substring(2);
    getAssignedUnassignedBooking(url);
  };

  const getAutoCompleteZone = (val: string) => {
    getZone(val);
  };

  const autoAssignBooking = async () => {
    const body: any = {
      zone_id: zoneName,
    };
    await bookingAutoAssign(body);
    getAssignedUnassignedBooking("", true);
  };

  const assignBookingtoMapMyIndia = async () => {
    if (currentDay === "0") {
      await getPhleboAutoAssign(`days=${currentDay}&slot_time=${updatedTime()}&zone_id=${zoneName}&all_time=True`);
    }
    else {
      await getPhleboAutoAssign(`days=${currentDay}&zone_id=${zoneName}&all_time=True&all_slots=True`);
    }


  };

  function updatedTime() {
    var d1 = new Date(),
      d2 = new Date(d1);
    d2.setMinutes(d1.getMinutes() + 45);
    var d3 = d2.getHours() + ':' + d2.getMinutes() + ':' + d2.getSeconds()
    return d3

  }





  const syncBookingAssing = async () => {
    await syncBookingtoMapMyIndia();
    getAssignedUnassignedBooking("", true);
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <h2>Assign Booking</h2>
      <Grid
        container
        spacing={1}
        direction="row"
        style={{
          marginTop: "20px",
          padding: "10px",
          background: "#f3f3f3",
          borderRadius: 8,
        }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <strong>Booking Auto Assign to Phlebo</strong>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            id="zone"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setZoneName(obj.id);
              }
            }}
            defaultValue={
              zoneList?.results &&
              zoneList?.results.find((code: any) => {
                return code.id === zoneName;
              })
            }
            options={zoneList?.results || []}
            freeSolo
            blurOnSelect
            getOptionLabel={(option: any) =>
              `${option.id}, ${option?.city?.name}`
            }
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getAutoCompleteZone(newInputValue.split(",")[0]);
              }, 1000);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Zone"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                className="input"
                required
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={6} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"
           
            fullWidth
            onClick={() =>  autoAssignBooking()}
            disabled={zoneName === ""}
          >
            Auto Assign
          </Button>
        </Grid> */}
        <Grid item xs={6} sm={4} md={2}>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              value={currentDay}
              name="radio-buttons-group"
              onChange={handleChange}
            >
              <span>
              <FormControlLabel value="0" control={<Radio />} label="Today" />
              <FormControlLabel value="1" control={<Radio />} label="Tomorrow" />
              </span>
            

            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={6} sm={4} md={3}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => {
              assignBookingtoMapMyIndia()
              setTimeout(() => setButtonDisabled(false), 3600000);
              setButtonDisabled(true)

            }}
            disabled={buttonDisabled || zoneName === 0}
          >
            Assign Booking
          </Button>
        </Grid>
        {/* <Grid item xs={6} sm={4} md={3}>
          <Button
            variant="contained"
            color="primary"
          
            fullWidth
            onClick={() => syncBookingAssing()}
          >
            Booking Sync From MMI
          </Button>
        </Grid> */}
        <Grid item xs={12} sm={6} md={3}>
          <Link
            to="/dashboard/sla/view-booking-on-map/"
            style={{ textDecoration: "none" }}
          >
            <Button
              variant="contained"
              color="primary"

              fullWidth
            >
              View Booking On Map
            </Button>
          </Link>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        direction="row"
        style={{
          marginTop: "20px",
          padding: "10px",
          background: "#f3f3f3",
          borderRadius: 8,
        }}
      >
        <Grid item xs={12} sm={12} md={12}>
          <strong>Filter</strong>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            id="city"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setCity(obj.id);
              }
            }}
            options={cities || []}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) => option.name}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getCities(newInputValue);
              }, 1000);
              if (newInputValue.length === 0) {
                setCity(0);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="City *"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            name="collection_date"
            type="date"
            value={collection_date}
            className="input"
            label="Collection Date *"
            variant="outlined"
            onChange={(e) => setCollection_date(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="contained"
            color="secondary"
            // disabled={loading}
            fullWidth
            onClick={filterBookings}
            disabled={collection_date === "" || city === 0}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="contained"
            color="primary"

            fullWidth
            onClick={() => history.push("/dashboard/sla/assignbookings")}
            disabled={collection_date === "" && city === 0}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{ alignItems: "left", display: "inline-block", margin: "1rem" }}
        >
          <div className="r-cl" style={{ display: "flex" }}>
            <div
              className="boxStyle"
              style={{
                border: "1px solid",
                width: "17px",
                height: "17px",
                background: "#ba68c8",
              }}
            ></div>
            <div style={{ marginLeft: "5px" }}>Phlebo Reached</div>
          </div>
          <div className="r-cl" style={{ display: "flex" }}>
            <div
              className="boxStyle"
              style={{
                border: "1px solid",
                width: "17px",
                height: "17px",
                background: "#fbc02d",
              }}
            ></div>
            <div style={{ marginLeft: "5px" }}>Verification Status Pending</div>
          </div>
          <div className="r-cl" style={{ display: "flex" }}>
            <div
              className="boxStyle"
              style={{
                border: "1px solid",
                width: "17px",
                height: "17px",
                background: "white",
              }}
            ></div>
            <div style={{ marginLeft: "5px" }}>Booking Status NA</div>
          </div>
          <div className="r-cl" style={{ display: "flex" }}>
            <div
              className="boxStyle"
              style={{
                border: "1px solid",
                width: "17px",
                height: "17px",
                background: "lightyellow",
              }}
            ></div>
            <div style={{ marginLeft: "5px" }}>Booking Status Hold</div>
          </div>
          <div className="r-cl" style={{ display: "flex" }}>
            <div
              className="boxStyle"
              style={{
                border: "1px solid",
                width: "17px",
                height: "17px",
                background: "cyan",
              }}
            ></div>
            <div style={{ marginLeft: "5px" }}>Booking Status Pending</div>
          </div>
          <div className="r-cl" style={{ display: "flex" }}>
            <div
              className="boxStyle"
              style={{
                border: "1px solid",
                width: "17px",
                height: "17px",
                background: "greenyellow",
              }}
            ></div>
            <div style={{ marginLeft: "5px" }}>Booking Status Confirmed</div>
          </div>
          <div className="r-cl" style={{ display: "flex" }}>
            <div
              className="boxStyle"
              style={{
                border: "1px solid",
                width: "17px",
                height: "17px",
                background: "pink",
              }}
            ></div>
            <div style={{ marginLeft: "5px" }}>Booking Status Cancelled</div>
          </div>
          <div className="r-cl" style={{ display: "flex" }}>
            <div
              className="boxStyle"
              style={{
                border: "1px solid",
                width: "17px",
                height: "17px",
                background: "red",
              }}
            ></div>
            <div style={{ marginLeft: "5px" }}>Booking Status Bookingcancelled</div>
          </div>
        </div>
        <div
          style={{ alignItems: "center", display: "inline-block", margin: "1rem 40% 1rem 1rem" }}
        >
          <div>PICKUP STATUS</div>
          <div className="r-cl" style={{ display: "flex" }}>
            <div
              className="boxStyle"
              style={{
                border: "1px solid",
                width: "17px",
                height: "17px",
                background: "purple",
              }}
            ></div>
            <div style={{ marginLeft: "5px" }}>Is Dragging</div>
          </div>
          <div className="r-cl" style={{ display: "flex" }}>
            <div
              className="boxStyle"
              style={{
                border: "1px solid",
                width: "17px",
                height: "17px",
                background: "brown",
              }}
            ></div>
            <div style={{ marginLeft: "5px" }}>Hold</div>
          </div>
          <div className="r-cl" style={{ display: "flex" }}>
            <div
              className="boxStyle"
              style={{
                border: "1px solid",
                width: "17px",
                height: "17px",
                background: "#3f51b5",
              }}
            ></div>
            <div style={{ marginLeft: "5px" }}>Pending</div>
          </div>
          <div className="r-cl" style={{ display: "flex" }}>
            <div
              className="boxStyle"
              style={{
                border: "1px solid",
                width: "17px",
                height: "17px",
                background: "green",
              }}
            ></div>
            <div style={{ marginLeft: "5px" }}>Confirmed</div>
          </div><div className="r-cl" style={{ display: "flex" }}>
            <div
              className="boxStyle"
              style={{
                border: "1px solid",
                width: "17px",
                height: "17px",
                background: "#f50057",
              }}
            ></div>
            <div style={{ marginLeft: "5px" }}>Cancelled</div>
          </div><div className="r-cl" style={{ display: "flex" }}>
            <div
              className="boxStyle"
              style={{
                border: "1px solid",
                width: "17px",
                height: "17px",
                background: "white",
              }}
            ></div>
            <div style={{ marginLeft: "5px" }}>Other</div>
          </div>
        </div>
      </div>
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "600px" }}
      >
        {assignedloader ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <React.Fragment>
                  {bookings && bookings.length > 0 && (
                    <React.Fragment>
                      <StyledTableCell align="center">
                        Time slot
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Unassigned Bookings
                      </StyledTableCell>
                    </React.Fragment>
                  )}
                  {phlebos &&
                    phlebos.length > 0 &&
                    phlebos.map((item: any) => {
                      return (
                        <StyledTableCell key={item.id} align="center">
                         {item?.user && item?.user?.username}{"(" + item?.latitude + "," + item?.longitude + ")"}
                        </StyledTableCell>
                      );
                    })}
                </React.Fragment>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {bookings &&
                bookings.map((el: any, ind: any) => (
                  <React.Fragment key={ind}>
                    <StyledTableRow>
                      <React.Fragment>
                        <StyledTableCell
                          align="center"
                          style={{ minWidth: "150px" }}
                        >
                          <Button
                            variant="text"
                            style={{
                              color: "black",
                            }}
                          >
                            {el.slot.split("-")[0]}
                            <br />
                            {" to "}
                            <br />
                            {el.slot.split("-")[1]}
                            {/* {moment(`${el.slot.split("-")[0]}`, ["HH.mm.ss"]).format("hh:mm A")}
                                                    <br />
                                                    {" to "}
                                                    <br />
                                                    {moment(`${el.slot.split("-")[1]}`, ["HH.mm.ss"]).format("hh:mm A")} */}
                          </Button>
                        </StyledTableCell>
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable
                            key={ind}
                            droppableId={`${el.slot}-unassigned`}
                          >
                            {(provided, snapshot) => (
                              <StyledTableCell
                                align="center"
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                                {...provided.droppableProps}
                              >
                                {el &&
                                  el.unassigned &&
                                  el.unassigned.map((item: any, index: any) => {
                                    let packages: any = [];
                                    item.packages &&
                                      item.packages.map(
                                        (pack: any, index: any) => {
                                          return packages.push(pack.name);
                                        }
                                      );
                                    return (
                                      <Draggable
                                        key={item.pk}
                                        draggableId={`${item.pk}`}
                                        index={index}
                                      >
                                        {(provided, snapshot) => (
                                          <Paper
                                            elevation={5}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style,
                                              item.booking_status ===
                                                "cancelled"
                                                ? "bookingcancelled"
                                                : "NA",
                                              item.phlebo_reached,
                                              item.verification_status
                                            )}
                                          >
                                            <div
                                              className="card"
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                // justifyContent: "space-around",
                                              }}
                                            >
                                              <div>
                                                <Chip
                                                  label={`Booking Id: ${item.pk}`}
                                                  color="secondary"
                                                  onClick={() =>
                                                    history.push(
                                                      `/dashboard/sla/booking-view/${item.pk}`
                                                    )
                                                  }
                                                  style={{
                                                    width: "100%",
                                                    fontSize: "10px",
                                                    background:
                                                      !snapshot.isDragging
                                                        ? "black"
                                                        : "purple",
                                                  }}
                                                // style={{
                                                //     color: !snapshot.isDragging ? "black" : "purple",
                                                //     border: `2px solid ${!snapshot.isDragging ? "black" : "purple"}`
                                                // }}
                                                />
                                              </div>
                                              {/* <p>
                                                                                        <b>Packages: </b>{packages.join(", ").length > 30 ? `${packages.join(", ").substring(0, 30)}...` : packages.join(", ")}
                                                                                    </p> */}
                                              <div className="cards">
                                                <div className="card_child">
                                                  <b>C_Name: </b>
                                                  <span className="spans">
                                                    {item.customer_name &&
                                                      item.customer_name}{" "}
                                                  </span>
                                                </div>
                                                <div className="card_child">
                                                  <b>Amount: </b>{" "}
                                                  <span className="spans">
                                                    &#8377;
                                                    {item.discounted_price &&
                                                      item.discounted_price
                                                        .final_total_price}
                                                  </span>
                                                </div>
                                                <div className="card_child">
                                                  <b>lat Long: </b>{" "}
                                                  <span className="spans">
                                                    {item.customer_latitude &&
                                                      item.customer_latitude}{","} {item.customer_longitude &&
                                                        item.customer_longitude}
                                                  </span>
                                                </div>
                                                <div className="card_child">
                                                  <b>Address: </b>{" "}
                                                  <span className="spans">
                                                    {item?.booking_full_address}
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </Paper>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                                {provided.placeholder}
                              </StyledTableCell>
                            )}
                          </Droppable>
                          {phlebos &&
                            phlebos?.length > 0 &&
                            phlebos?.map((phleb: any, index: any) => {
                              return (
                                <Droppable
                                  key={index}
                                  droppableId={`${el.slot}-${phleb.id}`}
                                >
                                  {(provided, snapshot) => (
                                    <StyledTableCell
                                      align="center"
                                      ref={provided.innerRef}
                                      style={getListStyle(
                                        snapshot.isDraggingOver
                                      )}
                                      {...provided.droppableProps}
                                    >
                                      {el &&
                                        el.assigned &&
                                        el.assigned.map(
                                          (item: any, index: any) => {
                                            let packages: any = [];
                                            item?.packages &&
                                              item?.packages.map(
                                                (pack: any, index: any) => {
                                                  return packages.push(
                                                    pack?.name
                                                  );
                                                }
                                              );
                                            if (
                                              phleb?.user?.username ===
                                              item?.phlebo?.name
                                            ) {
                                              return (
                                                <Draggable
                                                  key={item.pk}
                                                  draggableId={`${item.pk}`}
                                                  index={index}
                                                >
                                                  {(provided, snapshot) => (
                                                    <Paper
                                                      elevation={5}
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps
                                                          .style,
                                                        item.booking_status ===
                                                          "cancelled"
                                                          ? "bookingcancelled"
                                                          : item.pickup_status,
                                                        item.phlebo_reached,
                                                        item.verification_status
                                                      )}
                                                    >
                                                      <div
                                                        className="card"
                                                        style={{
                                                          display: "flex",
                                                          flexDirection:
                                                            "column",
                                                          // justifyContent: "space-around",
                                                        }}
                                                      >
                                                        <div>
                                                          <Chip
                                                            label={`Booking Id: ${item.pk}`}
                                                            onClick={() =>
                                                              history.push(
                                                                `/dashboard/sla/booking-view/${item.pk}`
                                                              )
                                                            }
                                                            color="secondary"
                                                            style={{
                                                              width: "100%",
                                                              background:
                                                                snapshot.isDragging
                                                                  ? "purple"
                                                                  : item.pickup_status ===
                                                                    "hold"
                                                                    ? "brown"
                                                                    : item.pickup_status ===
                                                                      "pending"
                                                                      ? "#3f51b5"
                                                                      : item.pickup_status ===
                                                                        "confirmed"
                                                                        ? "green"
                                                                        : item.pickup_status ===
                                                                          "cancelled"
                                                                          ? "#f50057"
                                                                          : "white",
                                                            }}
                                                          />
                                                        </div>
                                                        {/* <p>
                                                                                                    <b>Packages: </b>{packages.join(", ").length > 30 ? `${packages.join(", ").substring(0, 30)}...` : packages.join(", ")}
                                                                                                </p> */}
                                                        <div className="cards">
                                                          <div className="card_child">
                                                            <b>C_Name: </b>
                                                            <span className="spans">
                                                              {item.customer_name &&
                                                                item.customer_name}
                                                            </span>
                                                          </div>
                                                          <div className="card_child">
                                                            <b>Amount: </b>{" "}
                                                            <span className="spans">
                                                              &#8377;
                                                              {item.discounted_price &&
                                                                item
                                                                  .discounted_price
                                                                  .final_total_price}
                                                            </span>
                                                          </div>
                                                          <div className="card_child">
                                                            <b>lat Long: </b>{" "}
                                                            <span className="spans">
                                                              {item.customer_latitude &&
                                                                item.customer_latitude}{","} {item.customer_longitude &&
                                                                  item.customer_longitude}
                                                            </span>
                                                          </div>
                                                          <div className="card_child">
                                                            <b>Address: </b>{" "}
                                                            <span className="spans">
                                                              {
                                                                item?.booking_full_address
                                                              }
                                                            </span>
                                                          </div>
                                                        </div>
                                                        {/* <div className="cards">
                                                                                                    <b>Customer Name: </b>{item.customer_name && item.customer_name}<br/>
                                                                                                    <b>Amount: </b>&#8377;{item.discounted_price && item.discounted_price.final_total_price}<br/>
                                                                                                     <b>Address: </b>{item.customer_address}
                                                                                                </div> */}
                                                        {/* <p>
                                                                                                    <b>Amount: </b>&#8377;{item.discounted_price && item.discounted_price.final_total_price}
                                                                                                </p>
                                                                                                <p>
                                                                                                    <b>Address: </b>{item.customer_address}
                                                                                                </p> */}
                                                        {/* <Button
                                                                                                    variant="outlined"
                                                                                                    color="default"
                                                                                                    fullWidth
                                                                                                    onClick={() => history.push(`/dashboard/sla/booking-view/${item.pk}`)}
                                                                                                    style={{
                                                                                                        color: snapshot.isDragging ? "purple" : item.booking_status === "cancelled" ? "black" : item.pickup_status === "hold" ? "brown" : item.pickup_status === "pending" ? "#3f51b5" : item.pickup_status === "confirmed" ? "green" : item.pickup_status === "cancelled" ? "#f50057" : "white",
                                                                                                        border: `2px solid ${snapshot.isDragging ? "purple" : item.booking_status === "cancelled" ? "black" : item.pickup_status === "hold" ? "brown" : item.pickup_status === "pending" ? "#3f51b5" : item.pickup_status === "confirmed" ? "green" : item.pickup_status === "cancelled" ? "#f50057" : "white"}`,
                                                                                                    }}
                                                                                                >
                                                                                                    View Booking
                                                                                                </Button> */}
                                                      </div>
                                                    </Paper>
                                                  )}
                                                </Draggable>
                                              );
                                            } else {
                                              return <div key={index}></div>;
                                            }
                                          }
                                        )}
                                      {provided.placeholder}
                                    </StyledTableCell>
                                  )}
                                </Droppable>
                              );
                            })}
                        </DragDropContext>
                      </React.Fragment>
                    </StyledTableRow>
                  </React.Fragment>
                ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  allBookings: state.SalesLeadAdminReducer.allBookings,
  cities: state.SalesLeadAdminReducer.cities,
  loading: state.SalesLeadAdminReducer.loading,
  assignedloader: state.SalesLeadAdminReducer.assignedloader,
  zoneList: state.SalesLeadAdminReducer.zoneList,
  phleboAutoAssign: state.SalesLeadAdminReducer.phleboAutoAssign,
});

export default connect(mapStateToProps, {
  getAssignedUnassignedBooking,
  getCities,
  updateBooking,
  getZone,
  bookingAutoAssign,
  getPhleboAutoAssign,
  syncBookingtoMapMyIndia,
})(BookingCard);
