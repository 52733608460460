import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import Button from '@mui/material/Button';
import EditLocationIcon from '@mui/icons-material/EditLocation';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../components/Loader2";
import BookingCommentsModal from "../components/Comments/bookingComments";
import { MessageRounded } from "@material-ui/icons";
import CallIcon from "@material-ui/icons/Call";
import { clickToCallBooking } from "../actions/bookingActions";
import AdditonalFamilyModal from "../tables/AdditionalFamily/AdditionFamilyDetails";
import LocationVerificationModal from "../components/Comments/locationVerificationModal";
import { CALL_DISABLE_TIME } from "../../CallTimeDisable";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
    },
    tableHead: {
      background: "rgb(146, 74, 145)!important",
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,fontWeight:600
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface BookingListProps {
  getBookings: any;
  userDetails: any;
  bookingList: any;
  clickToCallBooking: Function;
  page: any;
  setPage: any;
  openModal: any;
  setopenModal: any;
  newbookingList:any;
  loading: Boolean;
  getAllNewBookings: any;
  bookingListLoader:boolean;
}

const BookingsTable: React.FC<BookingListProps> = ({
  getBookings,
  bookingList,
  clickToCallBooking,
  page,
  setPage,
  getAllNewBookings,
  newbookingList,
  openModal,
  setopenModal,
  loading,
  bookingListLoader,
  userDetails,
}) => {
  const classes = useStyles();
  const [bookingId, setBookingId] = useState<number>(0);
  const [additionalMemebr, setAdditionalMemebr] = useState<any>();
  const [additionalModal, setAdditonalModal] = useState<boolean>(false);
  const [leadId, setLeadId] = useState<number>(0);
  const [callButton, setCallButton] = useState<boolean>(true);
  const [bookingIdLocation, setBookingIdLocation] = useState<number>(0);
  const [leadIdLocation, setLeadIdLocation] = useState<number>(0);
  const [openLocation, setOpenLocation] = useState<boolean>(false);
  const [verifyKey, setVerifyKey] = useState<boolean>(false);
  const [customerLatitude, setCustomerLatitude] = useState<any>();
  const [customerLongitude, setCustomerLongitude] = useState<any>();
  const [customerLatitude1, setCustomerLatitude1] = useState<any>();
  const [customerLongitude1, setCustomerLongitude1] = useState<any>();
  const [cityId, setCityId] = useState<any>("");
  const [addressLine2, setAddressLine2] = useState<string>();
  const [favoritePlace, setFavoritePlace] = useState<any>();
  const [customer_address, setCustomer_address] = useState<String>("");
  const [customer_landmark1, setCustomer_landmark1] = useState<string>("");
  const [heading, setHeading] = useState<string>("");
  const handleClick = (id: number) => {
    setopenModal(true);
    setBookingId(id);
  };
  const handleCallClick = (id: number, type: string) => {
    setLeadId(id);
    setCallButton(false);
    clickToCallBooking(id, type);
    setTimeout(() => {
      setCallButton(true);
    }, CALL_DISABLE_TIME);
  };
  const handleClickLocation = (id: number) => {
    setOpenLocation(true);
    setBookingIdLocation(id);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = bookingList.links && bookingList.links.next.split("?")[1];
      // getBookings(`${url}&optimize=true`);
      {
        userDetails?.profile?.pilot_user ? (
          getAllNewBookings(`${url}`)
        ) : getBookings(url.includes("optimize=true") ? url : `${url}&optimize=true`);
      }

    } else if (newPage < page) {
      let url = bookingList.links && bookingList.links.previous.split("?")[1];
      {
        userDetails?.profile?.pilot_user ? (
          getAllNewBookings(`${url}`)
        ) : getBookings(url.includes("optimize=true") ? url : `${url}&optimize=true`);
      }
    }
    setPage(newPage as number);
  };
  const handleAdditionalView = (member: any) => {
    setAdditionalMemebr(member);
    setAdditonalModal(true);
  };
  return (
    <div style={{ width: "100%" }} className="data-table">
      <TableContainer className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "550px" }}
      >
        {bookingListLoader ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <StyledTableRow>
                <StyledTableCell className={classes.tableHeadCell}>
                  Action
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Booking ID
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Lead ID
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Location Verified
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Comment
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Call
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                Location Event Status
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Booking Date
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Name
                </StyledTableCell>
                {/* <TableCell className={classes.tableHeadCell}>Agent</TableCell> */}
                <StyledTableCell className={classes.tableHeadCell}>
                  Age
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Gender
                </StyledTableCell>
                {/* <TableCell className={classes.tableHeadCell}>Email</TableCell> */}
                <StyledTableCell className={classes.tableHeadCell}>
                  Collection Date
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Collection Time
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Booking Time
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Zone Name
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Mobile Number
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Booking Status
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Verification Status
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Pickup Status
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Report Status
                </StyledTableCell>
                <StyledTableCell className={classes.tableHeadCell}>
                  Remarks
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {bookingList?.results &&
                bookingList?.results.length > 0 &&
                bookingList?.results.map((booking: any, index: any) => {
                  const pendingData = booking?.additional_members.filter(
                    (data: any) => data?.pickup_status === "pending"
                  );
                  const confirmedData = booking?.additional_members.filter(
                    (data: any) => data?.pickup_status === "confirmed"
                  );
                  return (
                    <StyledTableRow
                      key={index}
                      style={{
                        background:
                          booking?.org_type === "homedx" ? "" : "#ffeb3b",
                      }}
                    >
                      <StyledTableCell align="center">
                        <Link
                          to={`/dashboard/ce/booking-view/${booking?.pk}`}
                          style={{ textDecoration: "none" }}
                        >
                          <Button variant="contained" color="secondary">
                            View
                          </Button>
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.additional_members.length > 0 ? (
                          <div>
                            {booking?.pk}+
                            <Chip
                              label={
                                "T" +
                                booking?.additional_members.length +
                                `${
                                  confirmedData.length > 0
                                    ? "C" + confirmedData.length
                                    : ""
                                }` +
                                `${
                                  pendingData.length > 0
                                    ? "P" + pendingData.length
                                    : ""
                                }`
                              }
                              style={{
                                background:
                                  pendingData.length > 0
                                    ? "#e91e63"
                                    : "#1b5e20",
                              }}
                              onClick={() =>
                                handleAdditionalView(booking?.additional_members)
                              }
                            />
                          </div>
                        ) : (
                          booking?.pk
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.lead}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        { booking?.customer_out_of_zone === true ? 
                          <Button
                          onClick={() => {
                            setCustomerLatitude(booking?.customer_latitude)
                            setCustomerLatitude1(booking?.verified_location?.lat)
                            setCustomerLongitude(booking?.customer_longitude)
                            setCustomerLongitude1(booking?.verified_location?.long)
                            setLeadIdLocation(booking?.lead)
                            setCityId(booking?.customer_areapincode?.city?.id)
                            setAddressLine2(booking?.address_line2)
                            setFavoritePlace("")
                            setCustomer_address(booking?.customer_address)
                            setCustomer_landmark1(booking?.customer_landmark)
                            setHeading("Non-Serviceable - Not Verified")
                            handleClickLocation(booking?.pk)
                          }}
                          variant="outlined"
                          style={{ width: "270px", padding: "0px" }}
                        >Non-Serviceable - Not Verified</Button> : (
                          (booking?.address_verified === false  && booking?.distance_above_deviation === false) ? 
                            <Button
                              onClick={() => {
                                setCustomerLatitude(booking?.customer_latitude)
                                setCustomerLatitude1(booking?.verified_location?.lat)
                                setCustomerLongitude(booking?.customer_longitude)
                                setCustomerLongitude1(booking?.verified_location?.long)
                                setLeadIdLocation(booking?.lead)
                                setCityId(booking?.customer_areapincode?.city?.id)
                                setAddressLine2(booking?.address_line2)
                                setFavoritePlace("")
                                setCustomer_address(booking?.customer_address)
                                setCustomer_landmark1(booking?.customer_landmark)
                                setHeading("Location Not Shared")
                                handleClickLocation(booking?.pk)
                              }}
                              variant="outlined"
                              style={{ width: "270px", padding: "0px" }}
                            >Location Not Shared</Button> : 
                            ((booking?.address_verified === true  && booking?.distance_above_deviation === true) ?
                              <Button
                                onClick={() => {
                                  setCustomerLatitude(booking?.customer_latitude)
                                  setCustomerLatitude1(booking?.verified_location?.lat)
                                  setCustomerLongitude(booking?.customer_longitude)
                                  setCustomerLongitude1(booking?.verified_location?.long)
                                  setLeadIdLocation(booking?.lead)
                                  setCityId(booking?.customer_areapincode?.city?.id)
                                  setAddressLine2(booking?.address_line2)
                                  setFavoritePlace("")
                                  setCustomer_address(booking?.customer_address)
                                  setCustomer_landmark1(booking?.customer_landmark)
                                  setHeading("More than 3 Km - Verified")
                                  handleClickLocation(booking?.pk)
                                }}
                                variant="outlined"
                                style={{ width: "270px", padding: "0px" }}
                              >More than 3 Km - Verified</Button> :
                              (
                                (booking?.address_verified === false  && booking?.distance_above_deviation === true) ?
                                <Button
                                  onClick={() => {
                                    setCustomerLatitude(booking?.customer_latitude)
                                    setCustomerLatitude1(booking?.verified_location?.lat)
                                    setCustomerLongitude(booking?.customer_longitude)
                                    setCustomerLongitude1(booking?.verified_location?.long)
                                    setLeadIdLocation(booking?.lead)
                                    setCityId(booking?.customer_areapincode?.city?.id)
                                    setAddressLine2(booking?.address_line2)
                                    setFavoritePlace("")
                                    setCustomer_address(booking?.customer_address)
                                    setCustomer_landmark1(booking?.customer_landmark)
                                    setHeading("More than 3 Km - Not Verified")
                                    handleClickLocation(booking?.pk)
                                  }}
                                  variant="outlined"
                                  style={{ width: "270px", padding: "0px" }}
                                >More than 3 Km - Not Verified</Button> :
                                (
                                  (booking?.address_verified === true  && booking?.distance_above_deviation === false) ?
                                    <Button
                                      onClick={() => {
                                        setCustomerLatitude(booking?.customer_latitude)
                                        setCustomerLatitude1(booking?.verified_location?.lat)
                                        setCustomerLongitude(booking?.customer_longitude)
                                        setCustomerLongitude1(booking?.verified_location?.long)
                                        setLeadIdLocation(booking?.lead)
                                        setCityId(booking?.customer_areapincode?.city?.id)
                                        setAddressLine2(booking?.address_line2)
                                        setFavoritePlace("")
                                        setCustomer_address(booking?.customer_address)
                                        setCustomer_landmark1(booking?.customer_landmark)
                                        setHeading("Less than 3 Km - Verified")
                                        handleClickLocation(booking?.pk)
                                      }}
                                      variant="outlined"
                                      style={{ width: "270px", padding: "0px" }}
                                    >Less than 3 Km - Verified</Button> :
                                    <Button></Button>
                                )
                              )))
                        }
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div>
                          <MessageRounded
                            onClick={() => handleClick(booking?.pk)}
                          />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {/* <CallIcon
                            onClick={() => handleCallClick(booking.pk, "call")}
                          /> */}
                        <Button
                          disabled={!callButton}
                          className="addDeck"
                          variant="contained"
                          color="primary"
                          onClick={() => handleCallClick(booking?.pk, "call")}
                          startIcon={<CallIcon />}
                        ></Button>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.location_event_status?booking?.location_event_status:"NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.booking_date}
                      </StyledTableCell>
                      <StyledTableCell align="center">{`${
                        booking?.designation || ""
                      } ${booking?.customer_name}`}</StyledTableCell>
                      {/* <TableCell align="center">
                          {(booking.agent && booking.agent.name) || "NA"}
                        </TableCell> */}
                      <StyledTableCell align="center">
                        {booking?.customer_age}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.customer_gender}
                      </StyledTableCell>
                      {/* <TableCell align="center">
                          {booking.customer_email}
                        </TableCell> */}
                      <StyledTableCell align="center">
                        {booking?.collection_date}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.collection_slot?.slot?.split("-")[0]}
                        {" - "}
                        {booking?.collection_slot?.slot?.split("-")[1]}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {" "}
                        {new Date(booking?.created_at).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.customer_areapincode?.area}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                      {booking?.numbers?.customer_phonenumber}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip
                          label={`${booking?.booking_status}`}
                          color="primary"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip
                          label={`${booking?.verification_status}`}
                          color="primary"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip
                          label={`${booking?.pickup_status}`}
                          color="primary"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Chip
                          label={`${booking?.report_status}`}
                          color="primary"
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {booking?.remarks}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={3}
                  count={bookingList?.count || 0}
                  rowsPerPage={bookingList?.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>

      {bookingList.results && bookingList.results.length === 0 && (
        <h6 style={{ textAlign: "center" }}>No Data Found</h6>
      )}
      <BookingCommentsModal
        openModal={openModal}
        setopenModal={setopenModal}
        bookingId={bookingId}
        leadId={leadId}
      />
      <LocationVerificationModal
        openModal={openLocation}
        setopenModal={setOpenLocation}
        bookingId={bookingIdLocation}
        leadId={leadIdLocation}
        setCustomerLatitude={setCustomerLatitude}
        setCustomerLongitude={setCustomerLongitude}
        customerLatitude={customerLatitude}
        customerLongitude={customerLongitude}
        setCustomerLatitude1={setCustomerLatitude1}
        setCustomerLongitude1={setCustomerLongitude1}
        customerLatitude1={customerLatitude1}
        customerLongitude1={customerLongitude1}
        cityId={cityId}
        addressLine2={addressLine2}
        favoritePlace={favoritePlace}
        customer_address={customer_address}
        customer_landmark1={customer_landmark1}
        addressId={"none"}
        heading={heading}
        setVerifyKey={setVerifyKey}
      />
      <AdditonalFamilyModal
        additionalMemebr={additionalMemebr}
        additionalModal={additionalModal}
        setAdditonalModal={setAdditonalModal}
        loading={loading}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {
  clickToCallBooking,
})(BookingsTable);
