import React, { useState, useRef } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Paper, Table, MenuItem, TableBody, Select, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, withStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../Components/loader/index';
import { MessageRounded } from '@material-ui/icons';
import { useEffect } from 'react';
import { genrateTeamFiltr } from '../../../helpers/generateUrl'
import { useHistory } from "react-router";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  getPerformanceData,
  getPanelUserListTeamLead,
  getAgentList,
} from '../../actions/salesLeadAdminAction'

import {
  Container,
  Grid,
  TextField,
} from '@material-ui/core';
import Box from '@mui/material/Box';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto"
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },

  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);


interface Props {
  getPerformanceData: any;
  performanceData: any;
  getPanelUserListTeamLead: any;
  getAgentList: any;
  agentsList:any;
  teamLeadList: any;
  loading: any;
}

const BookingsTable: React.FC<Props> = ({
  getPerformanceData,
  performanceData,
  getPanelUserListTeamLead,
  agentsList,
  teamLeadList,
  getAgentList,
  loading,
}) => {
  const history = useHistory()
  const classes = useStyles()
  const timer = useRef<any>(0)
  const [raiseComplaint, setRaiseComplaint] = useState(false)
  const [partner, setPartner] = useState<any>([])
  const [bookingID, setBookingID] = useState(0)
  const [type, setType] = useState("")
  const [query, setQuery] = useState("none")

  const [end_date, setEnd_date] = useState("")
  const [status, setStatus] = useState("")
  const [ticket_Status, setTicketStatus] = useState<any>("")
  const [ticketId, setTicketId] = useState(0)
  const [created_date, setCreated_date] = useState("")
  const [teamLead, setTeamLead] = useState<any>("");
  const [salesLead, setSalesLead] = useState<any>("");
  const [agent, setAgent] = useState<any>([]);
  const [bookingId, setBookingid] = React.useState<any>("")
  const [activeTab, setActiveTab] = React.useState<string>("");
  const [performance_date, setPerformance_date] = useState("")
  const handleOpenModal = (ticket: any) => {
    setBookingID(ticket.booking?.pk || ticket.booking?.id)
    setType(ticket.querytype)
    setQuery(ticket.query)
    setStatus(ticket.status)
    setTicketId(ticket.id)
    setRaiseComplaint(true)
  }
  useEffect(() => {
    getPerformanceData()
  }, [])

  useEffect(() => {
    getAgentList("?usergroup=CustomerExecutive");
    getPanelUserListTeamLead(`?usergroup=CustomerLead`)

  }, [])


  const filterTeamData = () => {
    const body: any = {
      tl: teamLead,
      sl: salesLead,
      agent: agent ? agent.join(",") : "",
      performance_date: performance_date,
    }
    localStorage.setItem("PANELUSERFILTER", JSON.stringify(body));
    const url = genrateTeamFiltr(body).substring(2)
    getPerformanceData(url ? `${url}&page=1` : "page=1");
  }

  const [page, setPage] = useState(0)
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = performanceData.links && performanceData.links.next.split("?")[1];
      getPerformanceData(url);
    } else if (newPage < page) {
      let url = performanceData.links && performanceData.links.previous.split("?")[1];
      getPerformanceData(url);
    }
    setPage(newPage as number);
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid
        container
        spacing={2}
        direction="row"
        style={{ alignItems: "right" }}
      >
        <Grid item xs={12} sm={10}>
          <h2>Employee Performance</h2>
        </Grid>
        <Grid container spacing={1} direction="row" style={{ alignItems: "left" }}>
          <Grid item xs={12} sm={4} md={2}>

            <Autocomplete
              id="agent"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setAgent(sourceIds);
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              options={agentsList?.results || []}
              getOptionDisabled={(option: any) => {
                return agent.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                option?.id && option?.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getAgentList(`?code=${newInputValue.replace(" ", "")}&usergroup=CustomerExecutive`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setAgent([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Agent"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="Team Lead"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setTeamLead(obj.id);
                }

              }}
              freeSolo
              blurOnSelect
              limitTags={1}
              options={teamLeadList?.results || []}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) => option?.id && option?.username}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPanelUserListTeamLead(`?code=${newInputValue}&usergroup=CustomerLead`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setTeamLead("");
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Team Lead"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="performance_date"
              type="date"
              label="Performance Date"
              value={performance_date}
              variant="outlined"
              onChange={(e) => setPerformance_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={filterTeamData}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => history.push("/dashboard/sla/performance")}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <div style={{ width: "100%" }} className="data-table">
      <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "550px" }}>
        {loading ? <Loader /> : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">User Name</StyledTableCell>
                <StyledTableCell align="center">Login Hours</StyledTableCell>
                <StyledTableCell align="center">Logout Hours</StyledTableCell>
                <StyledTableCell align="center">Break Time</StyledTableCell>
                <StyledTableCell align="center">Total Calls</StyledTableCell>
                <StyledTableCell align="center">Total Booking</StyledTableCell>
                <StyledTableCell align="center">Total Main Booking Count</StyledTableCell>
                <StyledTableCell align="center">Number Of Lead Assigned</StyledTableCell>
                <StyledTableCell align="center">Total Recording Time</StyledTableCell>
                <StyledTableCell align="center">Conversion Percent</StyledTableCell>
                <StyledTableCell align="center">Fullfilled Revenue</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {
                performanceData?.result && performanceData?.result.length > 0 && performanceData.result.map((performance: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>

                      <StyledTableCell align="center">{performance?.username}</StyledTableCell>
                      <StyledTableCell align="center">{performance?.login_time}</StyledTableCell>
                      <StyledTableCell align="center">{performance?.logout_time}</StyledTableCell>
                      <StyledTableCell align="center">{performance?.break_time}</StyledTableCell>
                      <StyledTableCell align="center">{performance?.total_calls}</StyledTableCell>
                      <StyledTableCell align="center">{performance?.total_booking}</StyledTableCell>
                      <StyledTableCell align="center">{performance?.total_main_booking_count}</StyledTableCell>
                      <StyledTableCell align="center">{performance?.number_of_lead_assigned}</StyledTableCell>
                      <StyledTableCell align="center">{performance?.total_recording_time}</StyledTableCell>
                      <StyledTableCell align="center">{performance?.conversion_percent}</StyledTableCell>
                      <StyledTableCell align="center">{performance?.fullfilled_revenue}</StyledTableCell>


                    </StyledTableRow>
                  )
                })
              }
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={3}
                  count={performanceData.count || 0}
                  rowsPerPage={performanceData.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      </div>

    </main>
  )
}

const mapStateToProps = (state: any) => ({
  loading: state.SalesLeadAdminReducer.loading,
  performanceData: state.SalesLeadAdminReducer.performanceData,
  agentsList: state.SalesLeadAdminReducer.agentsList,
  teamLeadList: state.SalesLeadAdminReducer.teamLeadList,
})

export default connect(mapStateToProps, {
  getPerformanceData,
  getPanelUserListTeamLead,
  getAgentList,
})(BookingsTable);

