import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {
  Button,
  Paper,
  MenuItem,
  Select,
  TextField,
  Grid,
  TableCell,
  TableContainer,
  Table,
  withStyles,
  TableBody,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {
  raiseNewComplaint,
  raiseNewTicektDisposition,
  initiateRefund,
} from "../../actions/salesLeadAdminAction";
import { useEffect } from "react";
import Loader from "../../Components/loader";
import { Link } from "react-router-dom";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "1200px",
    overflow: "scroll",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
  table: {
    maxWidth: "100%",
    margin: "auto",
    overflow: "scroll",
  },
  tableContainer: {
    marginTop: "2rem",
    position: "sticky",
    top: "200px",
    overflow: "scroll",
  },
}));
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

type ModalProps = {
  open: boolean;
  bookingId: number;
  raiseNewComplaint: any;
  raiseNewTicektDisposition: any;
  leadId: number;
  setOpen: Function;
  bookingComplaints: any;
  loading: any;
  totalPrice: number;
  initiateRefund: any;
  booking: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  open,
  bookingId,
  leadId,
  raiseNewTicektDisposition,
  raiseNewComplaint,
  setOpen,
  bookingComplaints,
  loading,
  totalPrice,
  initiateRefund,
  booking,
}) => {
  const classes = useStyles();

  const [query, setQuery] = useState("none");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("none");
  const [newTicket, setNewTicket] = useState<boolean>(false);
  const [disposition, setDisposition] = useState<any>("");
  const [createBookingId, setCreateBookingId] = useState<any>(bookingId);
  const [createAmount, setCreateAmount] = useState<any>(totalPrice);
  const [bookingType, setBookingType] = useState<any>()
  const blockCharInPhoneField = (e: React.KeyboardEvent<HTMLDivElement>) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();

  const handleClose = () => {
    setDisposition("")
    setOpen(false);
    setNewTicket(false)
  };


  useEffect(() => {
    setCreateAmount(totalPrice);
  }, [totalPrice]);

  const raiseComplaint = () => {
    const body1: any = {
      query: description,
      disposition: disposition,
      lead_id: leadId,
      booking_id: bookingId,
    };
    const body2: any = {
      amount: Number(createAmount),
      booking: createBookingId,
      cs_remarks: description,
    };
    if (bookingId !== 0) {
      delete body1.lead_id;
    }
    if (leadId !== 0) {
      delete body1.booking_id;
    }
    if (disposition === "") {
      delete body1.disposition;
    }

    if (disposition !== "refund_query") {
      raiseNewTicektDisposition(body1);
      setQuery("none");
      setDescription("");
      setDisposition("");
      handleClose();
    } else initiateRefund(body2);
    setQuery("none");
    setDescription("");
    setDisposition("");
    handleClose();
  };


  const handleCross = () => {
    setOpen(false);
    setNewTicket(false);
  };
  useEffect(() => {
    setBookingType(booking?.booking_type)
  }, [booking])


  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            {bookingType === "b2c" && booking?.org_type === "homedx" ? (
              <>
                <div className={classes.head}>

                  <h4
                    style={{
                      marginBottom: "1rem",
                    }}
                    id="transition-modal-title"
                  >
                    Raise a query ticket
                  </h4>
                  <CloseIcon onClick={handleCross} />
                </div>
                {bookingComplaints?.results &&
                  bookingComplaints?.results.length > 0 &&
                  !newTicket ? (
                  <>
                    <TableContainer
                      className={classes.tableContainer}
                      component={Paper}
                      style={{ maxHeight: "500px" }}
                    >
                      {loading ? (
                        <Loader />
                      ) : (
                        <Table stickyHeader aria-label="simple table">
                          <TableHead>
                            <StyledTableRow>
                              <StyledTableCell align="center">
                                Booking Id
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Child Id
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Agent
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Created At
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Query Type
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Query Description
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Receiver Remarks
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Status
                              </StyledTableCell>
                              <StyledTableCell align="center">View</StyledTableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            {bookingComplaints?.results &&
                              bookingComplaints?.results.length > 0 &&
                              bookingComplaints?.results.map(
                                (ticket: any, index: any) => {
                                  return (
                                    <StyledTableRow key={index}>
                                      <StyledTableCell align="center">
                                        {ticket?.booking !== null
                                          ? ticket?.booking?.id ||
                                          ticket?.booking?.pk
                                          : ticket?.add_booking?.mainbooking}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {ticket?.booking === null
                                          ? ticket?.add_booking.id
                                          : ""}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {ticket.panel_user &&
                                          ticket.panel_user.name}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {new Date(
                                          ticket.created_at
                                        ).toLocaleString()}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {ticket.querytype}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {ticket.query}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {ticket.receicer_remarks}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        {ticket.status}
                                      </StyledTableCell>
                                      <StyledTableCell align="center">
                                        <Link
                                          to={`/dashboard/sla/booking-view/${ticket?.booking !== null
                                              ? ticket?.booking?.id ||
                                              ticket?.booking?.pk
                                              : ticket?.add_booking?.mainbooking
                                            }`}
                                        >
                                          <Button
                                            color="secondary"
                                            variant="contained"
                                          >
                                            View
                                          </Button>
                                        </Link>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                }
                              )}
                          </TableBody>
                        </Table>
                      )}
                    </TableContainer>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      style={{ height: "50px" }}
                      onClick={() => setNewTicket(true)}
                    >
                      Do You Want to Raise New Ticket
                    </Button>
                  </>
                ) : (
                  <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    justify="space-between"
                    spacing={3}
                    style={{ margin: "1rem auto" }}
                  >
                    {/* <Grid item xs={12} md={12}>
                                    <Select className="input"
                                        name="action"
                                        variant="outlined"
                                        value={query}
                                        style={{ width: "100%" }}
                                        onChange={(e) => setQuery(e.target.value as string)}
                                    >
                                        <MenuItem disabled value={"none"}>Query Type</MenuItem>
                                        <MenuItem value={"SampleAccessioning"}>SampleAccessioning</MenuItem>
                                        <MenuItem value={"CustomerSupport"}>CustomerSupport</MenuItem>
                                        <MenuItem value={"PhleboAdmin"}>PhleboAdmin</MenuItem>
                                        <MenuItem value={"RouteManager"}>Route Manager</MenuItem>
                                        <MenuItem value={"Doctor"}>Doctor</MenuItem>
                                    </Select>
                                </Grid> */}
                    {/* {query === "CustomerSupport" || query === "CustomerSupport" ? ( */}
                    <Grid item xs={12}>
                      <p>Ticket Type</p>
                      <Select
                        className="input"
                        name="action"
                        variant="outlined"
                        value={disposition}
                        style={{ width: "100%" }}
                        onChange={(e) => setDisposition(e.target.value as string)}
                      >
                        <MenuItem value={"phlebo_delay"}>Phlebo Delay</MenuItem>
                        <MenuItem value={"phlebo_behavior"}>
                          Phlebo Behavior
                        </MenuItem>
                        <MenuItem value={"phlebo_markhold"}>
                          Phlebo Markhold
                        </MenuItem>
                        <MenuItem value={"resampling"}>Resampling</MenuItem>
                        <MenuItem value={"report_related_query"}>
                          Report Tat Breached
                        </MenuItem>
                        <MenuItem value={"report_not_delivered"}>
                          Report Not Delivered
                        </MenuItem>
                        <MenuItem value={"fault_report"}>Fault Report</MenuItem>
                        <MenuItem value={"refund_query"}>Refund Query</MenuItem>
                        <MenuItem value={"doctor_consultation"}>
                          Doctor Consultations
                        </MenuItem>
                        <MenuItem value={"social_media_ticket"}>
                          Social Media Ticket
                        </MenuItem>
                        <MenuItem value={"others"}>Others</MenuItem>
                      </Select>
                    </Grid>
                    {/* ) : ""} */}

                    {disposition === "refund_query" ? (
                      <>
                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            className="input"
                            name="name"
                            type="number"
                            onWheel={(e: any) => e.target.blur()}
                            label="Booking Id"
                            value={createBookingId}
                            variant="outlined"
                            onChange={(e) => setCreateBookingId(e.target.value)}
                            style={{ width: "100%" }}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <TextField
                            className="input"
                            name="name"
                            type="number"
                            onWheel={(e: any) => e.target.blur()}
                            label="Amount"
                            inputProps={{ min: 0, max: totalPrice }}
                            value={createAmount}
                            onKeyDown={(e) => {
                              blockCharInPhoneField(e)

                            }}
                            variant="outlined"
                            onChange={(e) => {
                              const inputValue = e.target.value;

                              if (inputValue.length === 0) {
                                setCreateAmount(0)
                              }
                              else {
                                setCreateAmount(inputValue);
                              }
                            }}
                            style={{ width: "100%" }}
                          />
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}

                    <Grid item xs={12}>
                      <TextField
                        name="comment"
                        type="text"
                        multiline
                        rows={3}
                        value={description}
                        className="input"
                        variant="outlined"
                        placeholder="Description"
                        onChange={(e) => setDescription(e.target.value as string)}
                        style={{ width: "100%" }}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ height: "50px" }}
                        onClick={raiseComplaint}
                        disabled={description === "" || disposition === ""}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </>) : <h3 style={{ color: "red", alignItems: "center", display: "flex" }}> Doctor's consultation is not allowed for this booking(B2B)</h3>}
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.SalesLeadAdminReducer.loading,
});

export default connect(mapStateToProps, {
  raiseNewComplaint,
  raiseNewTicektDisposition,
  initiateRefund,
})(CommentsModal2);