import React, { useState, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Paper,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Grid, TextField, MenuItem, Select } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../../components/loader";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    conlorChange: {
      background: "red",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);
const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  loading: Boolean;
  analyticsRecording: any;
  getAnalyticsRecording: any;
}

const AnalyticsRecordingTable: React.FC<Props> = ({
  loading,
  analyticsRecording,
  getAnalyticsRecording,
}) => {
  const classes = useStyles();
  const history = useHistory();





  const len = analyticsRecording && analyticsRecording?.call_picked ? Object.keys(analyticsRecording.call_picked).length : 0
  const Arr = len > 0 ? Object.keys(analyticsRecording?.call_picked) : []

  return (
    <div style={{ width: "100%" }} className="data-table">
      <div style={{ width: "100%" }} className="data-table">
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ maxHeight: "500px" }}
        >
          {loading ? (
            <Loader />
          ) : (
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Created At</StyledTableCell>
                  <StyledTableCell align="center">Direction</StyledTableCell>
                  <StyledTableCell align="center">Call Picked</StyledTableCell>
                  <StyledTableCell align="center">Lead One Try Count</StyledTableCell>
                  <StyledTableCell align="center">Lead Two Try Count</StyledTableCell>
                  <StyledTableCell align="center">Lead Three Try Count</StyledTableCell>
                  <StyledTableCell align="center">Total Lead Count</StyledTableCell>
                  <StyledTableCell align="center">Unique Lead Count</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {Arr &&
                  Arr.length > 0 && Arr.map((list: any, i: any) => (
                    <StyledTableRow>
                      <StyledTableCell align="center">
                        {JSON.stringify(analyticsRecording?.created_at[Arr[i]])}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {JSON.stringify(analyticsRecording?.direction[Arr[i]])}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {JSON.stringify(analyticsRecording?.call_picked[Arr[i]])}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {JSON.stringify(analyticsRecording?.lead_one_try_count[Arr[i]])}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {JSON.stringify(analyticsRecording?.lead_two_try_count[Arr[i]])}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {JSON.stringify(analyticsRecording?.lead_three_try_count[Arr[i]])}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {JSON.stringify(analyticsRecording?.total_lead_count[Arr[i]])}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {JSON.stringify(analyticsRecording?.unique_lead_count[Arr[i]])}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                }
              </TableBody>

            </Table>
          )}
        </TableContainer>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {

})(AnalyticsRecordingTable);