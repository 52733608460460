export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  GET_USERS_LIST: "sla/get_user_list",
  UPDATE_USERS_LIST: "sla/update_user_list",
  GET_AREA: "sla/get_area",
  CREATE_AREA: "sla/create_area",
  UPDATE_AREA: "sla/update_area",
  GET_ZONE: "sla/get_zone",
  GET_LTV_LEAD: "ce/get_ltv_lead",
  UPDATE_ZONE: "sla/update_zone",
  CREATE_ZONE: "sla/create_zone",
  GET_TIMESLOTS: "sla/get_timeslots",
  GET_BLOG_TAGS: "sla/get_tags",
  GET_BLOG_CATEGORY: "sla/get_blog_categgory",
  GET_BLOG_IMAGES: "sla/get_blog_images",
  GET_BLOGS: "sla/get_blogs",
  GET_BLOG_BY_SLUG: "sla/get_blog_by_slug",
  UPDATE_BLOGS: "sla/update_blogs",
  CREATE_BLOGS: "sla/create_blogs",
  DELETE_BLOG: "sla/delete_blog",
  CREATE_COUPON: "sla/create_coupon",
  UPDATE_COUPON: "sla/update_coupon",
  GET_PACKAGE: "sla/get_package",
  SET_LOADING_INDIVIDUAL_CHAT: "sla_booking/set_loading_individual_chat",
  CREATE_PACKAGE: "sla/create_package",
  UPDATE_PACKAGE: "sla/update_package",
  GET_CITY_PRICES: "sla/get_city_prices",
  SET_LOADING: "sla/set_loading",
  SET_BOOKING_ID_LOADER: "sla/set_booking_id_loader",
  SET_BIOMARKER_LOADER: "sla/set_biomarker_loader",
  CREATE_WHATSAPP_TEMPLATE: "sla/create_whatsapp_template",
  GET_WHATSAPP_TEMPLATE: "sla/get_whatsapp_template",
  GET_PIN_CODE: "sla/get_pin_code",
  GET_CITIES: "sla/get_cities",
  GET_AGENTS: "sla/get_agents",
  GET_AGENTS_BY_USERGROUP: "sla/get_agents_by_usergroup",
  GET_PACKAGES: "sla/get_packages",
  GET_PHLEBOS: "sla/get_phlebos",
  GET_DASHBOARD_OVERVIEW: "sla/get_dashboard_overview",
  GET_PARTNERS: "sla/get_partners",
  GET_COUPONS: "sla/get_coupons",
  CREATE_BOOKING: "sla/create_booking",
  UPDATE_BOOKING: "sla/update_booking",
  GET_BOOKING_LIST: "sla/get_booking_list",
  GET_NEW_BOOKING_LIST: "sla/get_new_booking_list",
  GET_LEAD_DETAILS: "sla/get_lead_details",
  GET_LEAD_BY_ID: "sla/get_lead_by_id",
  LEAD_DETAILS_RESET: "sla/lead_details_reset",
  GET_LEADS: "sla/get_leads",
  GET_BOOKING_BY_ID: "sla/get_booking_by_id",
  CLICK_TO_CALL: "sla/click_to_call",
  GET_POSTIVE_CALLS_COMMENTS: "sla/get_postive_calls_commnets",
  ADD_POSTIVE_CALLS_COMMENT: "sla/add_comments",
  SET_COMMENTS: "sla/set_commnets",
  UPLOAD_FILE: "sla/upload",
  SEARCH_CALL: "sla/serach",
  BOOKING_RESET: "sla/booking_reset",
  TABLE_RESET: "sla/table_reset",
  ADD_COMMENT: "positive_calls/add_comment",
  CREATE_CALL: "sla/createcall",
  GET_LEAD_SOURCE: "sla/getleadsource",
  GET_RECORDINGS: "sla/get_recordings",
  GET_BOOKINGS_COMMENTS: "sla/get_bookings_commnets",
  ADD_BOOKINGS_COMMENT: "sla/add_bookings_comments",
  GET_WHATSAPP_COMMENTS: "sla/get_whatsapp_comments",
  GET_WHATSAPP_UNREAD_MSG: "sla/get_whatsapp_unread_msg",
  GET_WHATSAPP_COMMENTS_BY_LEAD: "sla/get_whatsapp_comments_by_lead",
  SEND_WHATSAPP_COMMENTS: "sla/send",
  GET_CALL_STATS: "sla/get_call_stats",
  RAISE_COMPLAINT: "sla/raise_complaint",
  GET_COMPLAINTS: "sla/get_complaints",
  ASSIGN_DOCTOR: "sla/assign_doctor",
  SEND_FEEDBACK: "sla/send_feedback",
  GET_AVAILABLE_SLOTS: "sla/get_available_slots",
  GET_ALL_LEADS: "sla/get_all_leads",
  LEAD_TRANSFER_BY_COMMA: "sla/set_comments",
  LEAD_TRANSFER_TO_TATATALE: "sla/lead_transfer_to_tatatale",
  GET_PANEL_USERS: "sla/get_panel_user",
  GET_LAB_CREDENTIAL_DETAILS: "sla/get_lab_credential_details",
  UPDATE_LAB_CREDENTIALS: "sla/update_lab_credentials",
  GET_ZONE_COMMENTS: "sla/get_zone_comments",
  GET_DISPOSITIONS: "sla/get_dispositions",
  GET_DISPOSITION_DATA: "sla/get_disposition_data",
  ADD_REMARK_ON_CALL: "sla/add_remark_on_call",
  GET_INCORRECT_NUMBER_LEAD: "sla/get_incorrect_number_lead",
  SAME_NUMBER_BOOKING: "sla/same_number_booking",
  GET_PAYMENT_SEND_LINK: "sla/get_payment_send_link",
  GET_PAYMENT_RESEND_LINK: "sla/get_payment_resend_link",
  GET_PAYMENT_CANCEL_LINK: "sla/get_payment_cancel_link",
  GET_SMS_REPORT_SEND: "sla/get_sms_report_send",
  GET_BREAK_DATA: "sla/get_break_data",
  CREATE_ADDITIONAL_BOOKING: "sla/create_additional_booking",
  UPDATE_ADDITIONAL_BOOKING: "sla/update_additional_booking",
  GET_ASSIGNED_USERGROUP: "sla/get_assigned_usergroup",
  GET_TODAYS_CALL_DETAILS: "sla/get_todays_call_details",
  GET_TOP_RECORDS: "sla/get_top_records",
  GET_VERIFICATION_BOOKING_DETAILS: "sla/get_verification_booking_details",
  GET_SMS_DETAILS: "sla/get_sms_details",
  GET_EMAIL_DETAILS: "sla/get_email_details",
  GET_REPORT_STATUS: "sla/get_report_status",
  GET_CENTER_INFORMATION: "sla/get_center_information",
  GET_LAB_DETAILS: "sla/get_lab_details",
  GET_PAYMENT_INFORMATION: "sla/get_payment_information",
  GET_NOTIFICATION_MESSAGE: "sla/get_notification_message",
  GET_USER_DETAILS: "sla/get_user_details",
  GET_NEW_USER_DETAILS: "sla/get_new_user_details",
  GET_REPORT_DOWNLOAD: "sla/get_report_download",
  CREATE_PANEL_USER: "sla/create_panel_user",
  UPDATED_PANEL_USER: "sla/updated_panel_user",
  ASSIGNED_PANEL_USER_DATA: "sla/assigned_panel_user_data",
  GET_PANEL_USER_LIST_DATA: "sla/get_panel_user_list_data",
  GET_PANEL_USER_DATA: "sla/get_panel_user_DATA",
  GET_AREA_COMMENTS: "sla/get_area_comments",
  GET_PANEL_COMMENT: "sla/get_panel_comments",
  GET_UNMASKED_NUMBER: "sla/get_unmasked_number",
  GET_FIREBASE_NOTIFICATION: "sla/get_firebase_notification",
  GET_PANELUSER_ACTIVE_DEACTIVE: "sla/get_active_deactive_panel_user",
  GET_AGENT_DATA: "sla/get_agent_data",
  GET_TODAYS_MISSED_CALLS: "sla/get_today_missed_calls",
  UPDATE_USERS_STATUS: "sla/update_user_status",
  CHECK_GEOFENCE_AREA: "sla/checking-geofence-area",
  GET_RCFRESH_LEAD_DATA: "sla/get_rcfresh_lead_data",
  GET_INDIVIDUAL_WHATSAPP_CHAT: "sla/get_individual_whatsapp_chat",
  GET_BOOKING_LIST_WITH_TAT_TIME: "sla/get_booking_list_with_tat_time",
  GET_ADDITIONAL_BOOKING_LIST_WITH_TAT_TIME:
    "sla/get_additional_booking_list_with_tat_time",
  GET_ASSIGNED_UNASSIGNED_BOOKING: "sla/get_assigned_unassigned_bookings",
  AUTO_ASSIGN_BOOKING: "sla/auto_assign_booking",
  GET_CURRENT_DAY_BOOKING: "sla/get_current_day_booking",
  GET_CITY_WISE_PHLEBOS: "sla/get_city_wise_phlebos",
  GET_ATTENDENCE: "sla/get_attendence",
  GET_VERIFIED_BY_LIST: "sla/get_verified_by_list",
  GET_BOOKING_JOURNEY_DETAILS: "sla/booking_journey_details",
  GET_PACKAGE_TAGS: "sla/get_package_tags",
  GET_ADDITIONAL_BOOKING: "sla/get_additional_booking",
  GET_SIGNATURE: "sla/get_signature",
  GET_AGENTS_NEW_DATA: "sla/get_agents_new_data",
  GET_QRCODE_DATA: "sla/get_qrcode_data",
  GET_PACKAGE_CATEGORY: "sla/get_package_category",
  GET_SAMPLE_HANDOVER_DATA: "sla/getsample_handover_data",
  GET_RIDER_LIST: "sla/get_rider_list",
  UPDATE_RIDER_TO_LAB_STATUS: "sla/update_rider_to_lab_status",
  GET_SAMPLE_JOURNEY_COMMENTS: "sla/get_sample_journey_comments",
  UPDATE_PHLEBOS: "sla/update_phlebos",
  GET_PHLEBO_COMMENTS: "sla/get_phlebo_commnets",
  CREATE_NEW_PHLEBO: "sla/create_phlebo",
  GET_PHLEBO_BY_PHONE: "sla/get_phlebo_by_phone",
  GET_RED_COIN: "sla/get_red_coins",
  GET_COLLECTION_SLOT: "sla/get_collection_slot",
  GET_SUB_CENTER_INFORMATION: "sla/get_sub_center_information",
  GET_REPORT_DATA: "sla/get_report_data",
  GET_HEALTH_TEST_PARAMETER: "sla/get_health_test_parameter",
  GET_PARAMETER_COUNT: "sla/get_parameter_count",
  GET_LEAD_STATUS: "sla/get_lead_status",
  GET_PRESCRIPTION_DATA: "sla/get_prescription_data",
  GET_IMAGING_TIMESLOTS: "sla/get_imaging_timeslots",
  CREATE_IMAGING_BOOKING: "sla/CREATE_IMAGING_BOOKING",
  GET_MULTIPLE_PACKAGES: "sla/get_multiple_package",
  GET_DISPLAY_CITIES: "sla/get_display_cities",
  GET_MAMPMYINDIA_ACCESS_TOKEN: "sla/get-mapmyindia-access-token",
  GET_ADDRESS_DETAILS_LAT_LONG: "sla/get-address-details-lat-long",
  CHECK_GEOFENCE_AREA_MAMPMYINDIA: "sla/checking-geofence-area-mapmyindia",
  CHECK_GEOFENCE_AREA_MAMPMYINDIA_FOR_CUSTOMER_ADDRESS:
    "sla/checking-geofence-area-mapmyindia_for_customer",
  GET_ADDRESS_TYPE_SEATING: "sla/get_address_type_seating",
  GET_SMS_LINK: "sla/get_sms_link",
  GET_MAP_BOOKING_LIST: "sla/get_map_booking",
  GET_PHLEBO_CURRENT_LOCATION: "sla/phlebo_current_location",
  GET_BOOKING_CALLS: "sla/get_booking_calls",
  GET_PANEL_USER_LIST_DATA_SALES_LEAD:
    "sla/get_panel_user_list_data_sales_lead",
  GET_PANEL_USER_LIST_DATA_TEAM_LEAD: "sla/get_panel_user_list_data_team_lead",
  GET_PHLEBO_RIDER_DUE_AMOUNT: "sla/get_phlebo_rider_due_amount",
  GET_IMAGING_TICKET: "sla/get_imaging_ticket",
  GET_IMAGING_TICKET_COUNT: "sla/get_imaging_ticket_count",
  GET_IMAGING_RAISE_TICKET: "sla/get_imaging_raise_ticket",
  GET_TOP_RC_FRESH_LEADS: "sla/get_top_rc_fresh_leads",
  TRANSFER_IMAGING_TICKETS: "sla/transfer_imaging_tickets",
  GET_PAYMENT_REFUND_LIST: "sla/get_payment_refund_list",
  INITIATE_REFUND: "sla/initiate_refund",
  UPDATE_INITIATED_REFUND: "sla/update_initiate_refund",
  GET_PERFORMANCE_DATA: "sla/get_performance_data",
  GET_TICKET_DATA: "sla/get_ticket_data",
  SEND_REPORT_BY_ID: "sla/send_report",
  GET_TICKET_CATEGORIES: "sla/get_ticket_category",
  GET_TICEKT_SUB_CATEGORIES: "sla/get_ticket_categories",
  CREATE_TASK_TICKET: "sla/create_task_ticket",
  GET_RED_TECH_COMMENT: "sla/redtech_comments/",
  POST_RED_TECH_COMMENT: "sla/postredtech_comments/",
  GET_LOGISTIC_TAT_DATA: "sla/booking/logistic_tat/",
  GET_QUALITY_SCORING_REPORT: "sla/get_quality_scoring_report/",
  GET_QUALITY_SCORING_REPORT_FOR_BACKUP:
    "sla/get_quality_scoring_report_for_backup/",
  GET_FAILED_REPORT_DATA: "sla/report_failed/",
  GET_BATCH_BOOKING_AMOUNT: "sla/get_batch_amount",
  UPDATE_BATCH_PAYMENT: "sla/update_batch_payment",
  GET_BATCH_PAYMENT: "sla/get_batch_payment",
  GET_CE_CENTER_FOR_CC: "sla/get_ce_center_for_cc",
  GET_BREAK_RESPONSE: "sla/get_break_response",
  CREATE_PILOT_BOOKING: "sla/create_pilot_booking",
  SET_GEO_LOADING: "sla/set_geo_loading",
  SET_GEO_LOADING_FOR_CUSTOMER: "sla/set_geo_loading_for_customer",
  SET_GEO_CHECK_ERROR: "sla/set_geo_check_error",
  SET_GEO_ERROR_FOR_CUSTOMER: "sla/set_geo_check_error_for_customer",
  UPDATE_LEADS: "sla/update_leads",
  RAISE_NEW_RAISE_TICEKT_DISPOSITION: "sla/cstech_create-ticekt",
  POST_PHLEBO_RIDER_MAP: "sla/post_phlebo_rider_map",
  UPDATE_PHLEBO_RIDER: "sla/update_phlebo_rider",
  GET_PHLEBO_RIDER: "sla/get_phlebo_rider",
  GET_ROUTES: "sla/get_routes",
  GET_TICKET_COMMENTS: "sla/ticket_comments",
  GET_BOOKING_COMPLAINTS: "sla/get_booking_complaints",
  UPDATE_TICKET_STATUS: "sla/update_ticekt_status",
  UPDATE_DISPOSITION: "sla/update_disposition",
  GET_BASE_CITIES: "sla/base_city",
  POST_COLD_CALL_LEAD_UPLOAD: "sla/post_cold_lead_upload",
  GET_COLD_CALLING_LEAD_DATA: "sla/post_cold_lead_data",
  UPDATE_PANEL_USERS: "sla/update_panel_users",
  GET_PANEL_LIST: "sla/get_panel_list",
  GET_PANELS_USERS: "sla/get_panels_users",
  SET_ALL_BOOKING_LOADING: "sla/set_all_booking_loading",
  SET_QUALITYSCOREBACKUP_LOADING: "sla/set_qualityscorebackup_loader",
  SET_PHLEBO_LOADING: "sla/set_phlebo_loader",
  SET_LOADING_CHAT: "sla_booking/set_loading_chat",
  SET_RIDER_LOADING: "sla/set_rider_loader",
  SET_ASSIGNED_LOADING: "sla/set_assigned_loader",
  GET_ANALYTICS_RECORDING: "sla/get_analytics_recording",
  GET_TICKET_COMMENTS_FOR_DOCUMENTS: "sla/ticket_comments_for_documents",
  CREATE_PHLEBO_LEAVE: "sla/create_phlebo_leave",
  GET_PHLEBO_LEAVE_LIST: "sla/get_phlebo_leave_list",
  GET_PHLEBO_LIST_UNDER_ROUTE_MANAGER:
    "sla/get_phlebo_list_under_route_manager",
  UPDATE_PHLEBO_LEAVE: "sla/update_phlebo_leave",
  GET_BIOMARKER_SCREENING: "sla/biomarker_screening",
  GET_BIOMARKER_ULTASOUND: "sla/biomarker_ultasound",
  GET_ADDITIONAL_MEMBER: "sla/additional_member",
  SET_LOADING_COMMENT: "sla_booking/set_loading_comment",
  BIOMARKER_RESET: "sla/biomarker_reset",
  // BOOKING_RESET: "sla/booking_reset",
  SEND_LOCATION_REQUEST: "sla/communication/send-location-request/",
  GET_CRM_LEAD_ADDRESS: "sla/get_auth/address-crm/",
  POST_CRM_LEAD_ADDRESS: "sla/post_auth/address-crm/",
  LOCATION_VERIFIED: "sla/location_verified",
  GET_OZONTEL_SKILL_DATA: "sla/get_ozontel_skill_data",
  SET_REPORT_LOADING: "sla/set_report_loading",
  SIGN_OUT_IN_WHATSAPP_CHAT: "sla/sign_out_in_whatsapp_chat",
  SWITCH_CHAT_AGENT_IN_WHATSAPP_CHAT: "sla/switch_chat_agent_in_whatsapp_chat",
  HOLD_THE_CHAT: "sla/hold_the_chat",
  REDTECH_TICKETS_USING_LEAD: "sla/redtech_tickets_using_lead",
  SEND_LAST_REPORT_TO_LEAD: "sla/send_last_report_to_lead",
  LATEST_BOOKING_USING_LEAD: "sla/latest_booking_using_lead",
  QUEUE_NUMBER: "sla/queue_number",
  GET_CAMPAINGS_DETAILS: "sla/dialer-settings",
  UPDATE_ATTENDANCE: "sla/update_attendance",
  GET_PHLEBO_AUTO_ASSIGN: "sla/update_attendance",
  GET_PHLEBO_TIMELINE: "sla/timeline",
  GET_PACKAGE_ALIAS: "sla/get_package_alias",
  CREATE_PACKAGE_ALIAS: "sla/package_alias_created",
  GET_PACKAGE_COMMENTS: "sla/get_package_comments",
  UPDATED_PACKAGE_ALIAS: "sla/package_alias_updated",
  GET_CITY_ALIAS: "sla/get_city_alias",
  CREATE_CITY_ALIAS: "sla/city_alias_created",
  GET_CITY_COMMENTS: "sla/get_city_comments",
  UPDATED_CITY_ALIAS: "sla/city_alias_updated",
};

interface UpdateAttendance {
  type: typeof ACTIONS.UPDATE_ATTENDANCE;
  payload: any;
}
interface GetWhatsappTemplate {
  type: typeof ACTIONS.GET_WHATSAPP_TEMPLATE;
  payload: Array<any>;
}
interface queueNumber {
  type: typeof ACTIONS.QUEUE_NUMBER;
  payload: boolean;
}
interface SignOutInWhatsappChat {
  type: typeof ACTIONS.SIGN_OUT_IN_WHATSAPP_CHAT;
  payload: boolean;
}
interface SwitchChatAgentInWhatsappChat {
  type: typeof ACTIONS.SWITCH_CHAT_AGENT_IN_WHATSAPP_CHAT;
  payload: boolean;
}
interface getHoldTheChat {
  type: typeof ACTIONS.HOLD_THE_CHAT;
  payload: boolean;
}
interface GetPackageComments {
  type: typeof ACTIONS.GET_PACKAGE_COMMENTS;
  payload: Object;
}
interface allRedtechTicketsUsingLead {
  type: typeof ACTIONS.REDTECH_TICKETS_USING_LEAD;
  payload: boolean;
}
interface latestBookingUsingLead {
  type: typeof ACTIONS.LATEST_BOOKING_USING_LEAD;
  payload: boolean;
}
interface sendLastReportToLeadId {
  type: typeof ACTIONS.SEND_LAST_REPORT_TO_LEAD;
  payload: boolean;
}
interface SetReportLoading {
  type: typeof ACTIONS.SET_REPORT_LOADING;
  payload: boolean;
}
interface getCrmLeadAddress {
  type: typeof ACTIONS.GET_CRM_LEAD_ADDRESS;
  payload: Array<any>;
}
interface PostCrmLeadAddress {
  type: typeof ACTIONS.POST_CRM_LEAD_ADDRESS;
  payload: Object;
}
interface SendLocationRequest {
  type: typeof ACTIONS.SEND_LOCATION_REQUEST;
  payload: Object;
}
interface LocationVerified {
  type: typeof ACTIONS.LOCATION_VERIFIED;
  payload: boolean;
}

interface CreateWhatsappTemplate {
  type: typeof ACTIONS.CREATE_WHATSAPP_TEMPLATE;
  payload: boolean;
}
interface GetCampaignsDetails {
  type: typeof ACTIONS.GET_CAMPAINGS_DETAILS;
  payload: boolean;
}

interface UpdatedPanelUser {
  type: typeof ACTIONS.UPDATED_PANEL_USER;
  payload: Object;
}
interface UpadtepanelUser {
  type: typeof ACTIONS.UPDATE_PANEL_USERS;
  payload: Object;
}
interface GetPanelList {
  type: typeof ACTIONS.GET_PANEL_LIST;
  payload: Array<any>;
}
interface GetAnalyticsRecording {
  type: typeof ACTIONS.GET_ANALYTICS_RECORDING;
  payload: Object;
}
interface getPanelsUsers {
  type: typeof ACTIONS.GET_PANELS_USERS;
  payload: Object;
}
interface CreatePilotBooking {
  type: typeof ACTIONS.CREATE_PILOT_BOOKING;
  payload: boolean;
}
interface GetBreakResponse {
  type: typeof ACTIONS.GET_BREAK_RESPONSE;
  payload: Object;
}

interface CreatePackageAlias {
  type: typeof ACTIONS.CREATE_PACKAGE_ALIAS;
  payload: Array<any>;
}
interface GetCeCenterForCc {
  type: typeof ACTIONS.GET_CE_CENTER_FOR_CC;
  payload: Array<any>;
}
interface GetBatchBookingAmount {
  type: typeof ACTIONS.GET_BATCH_BOOKING_AMOUNT;
  payload: boolean;
}
interface GetPaymentRefundList {
  type: typeof ACTIONS.GET_PAYMENT_REFUND_LIST;
  payload: Array<any>;
}
interface InitiateRefund {
  type: typeof ACTIONS.INITIATE_REFUND;
  payload: Array<any>;
}
interface GetPerformanceData {
  type: typeof ACTIONS.GET_PERFORMANCE_DATA;
  payload: Array<any>;
}
interface GetFailedReportData {
  type: typeof ACTIONS.GET_FAILED_REPORT_DATA;
  payload: Array<any>;
}
interface GetLtvLead {
  type: typeof ACTIONS.GET_LTV_LEAD;
  payload: boolean;
}
interface UpdateInitiateRefund {
  type: typeof ACTIONS.UPDATE_INITIATED_REFUND;
  payload: Array<any>;
}
interface GetTopRcFreshLeads {
  type: typeof ACTIONS.GET_TOP_RC_FRESH_LEADS;
  payload: Array<any>;
}
interface UpdatePackageAlias {
  type: typeof ACTIONS.UPDATED_PACKAGE_ALIAS;
  payload: Array<any>;
}

interface TransferImagingTicket {
  type: typeof ACTIONS.TRANSFER_IMAGING_TICKETS;
  payload: Array<any>;
}
interface SetLoadingIndividualChat {
  type: typeof ACTIONS.SET_LOADING_INDIVIDUAL_CHAT;
  payload: boolean;
}
interface GetImagingTicketCount {
  type: typeof ACTIONS.GET_IMAGING_TICKET_COUNT;
  payload: Object;
}
interface GetImagingTicket {
  type: typeof ACTIONS.GET_IMAGING_TICKET;
  payload: Object;
}
interface GetAgentNewData {
  type: typeof ACTIONS.GET_AGENTS_NEW_DATA;
  payload: Array<any>;
}
interface GetPhleboRiderDueAmount {
  type: typeof ACTIONS.GET_PHLEBO_RIDER_DUE_AMOUNT;
  payload: Array<any>;
}
interface GetSMSLink {
  type: typeof ACTIONS.GET_SMS_LINK;
  payload: Object;
}
interface GetPackageAlias {
  type: typeof ACTIONS.GET_PACKAGE_ALIAS;
  payload: Array<any>;
}

interface GetBookingCall {
  type: typeof ACTIONS.GET_BOOKING_CALLS;
  payload: Object;
}
interface GetAddressTypeSeating {
  type: typeof ACTIONS.GET_ADDRESS_TYPE_SEATING;
  payload: Object;
}
interface GetPrescriptionData {
  type: typeof ACTIONS.GET_PRESCRIPTION_DATA;
  payload: Array<any>;
}

interface GetPanelUserDataSalesLead {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA_SALES_LEAD;
  payload: Array<any>;
}
interface GetPanelUserDataTeamLead {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA_TEAM_LEAD;
  payload: Array<any>;
}
interface GetParameterCount {
  type: typeof ACTIONS.GET_PARAMETER_COUNT;
  payload: Array<any>;
}
interface GetHealthTestParameter {
  type: typeof ACTIONS.GET_HEALTH_TEST_PARAMETER;
  payload: Array<any>;
}
interface GetReportData {
  type: typeof ACTIONS.GET_REPORT_DATA;
  payload: Array<any>;
}
interface SendReportById {
  type: typeof ACTIONS.SEND_REPORT_BY_ID;
  payload: Object;
}
interface GetQRCodeData {
  type: typeof ACTIONS.GET_QRCODE_DATA;
  payload: Array<any>;
}
interface GetPackageCategory {
  type: typeof ACTIONS.GET_PACKAGE_CATEGORY;
  payload: Array<any>;
}
interface GetAdditionalBooking {
  type: typeof ACTIONS.GET_ADDITIONAL_BOOKING;
  payload: Array<any>;
}
interface GetPhleboAutoAssign {
  type: typeof ACTIONS.GET_PHLEBO_AUTO_ASSIGN;
  payload: Array<any>;
}
interface GetSignature {
  type: typeof ACTIONS.GET_SIGNATURE;
  payload: Array<any>;
}
interface GetAttendence {
  type: typeof ACTIONS.GET_ATTENDENCE;
  payload: Object;
}
interface GetCityWisePhlebos {
  type: typeof ACTIONS.GET_CITY_WISE_PHLEBOS;
  payload: Object;
}
interface AutoAssignBooking {
  type: typeof ACTIONS.AUTO_ASSIGN_BOOKING;
  payload: Array<any>;
}
interface GetPackageTags {
  type: typeof ACTIONS.GET_PACKAGE_TAGS;
  payload: Array<any>;
}
interface GetAssignedUnassignedBooking {
  type: typeof ACTIONS.GET_ASSIGNED_UNASSIGNED_BOOKING;
  payload: Array<any>;
}
interface GetPhleboTimeline {
  type: typeof ACTIONS.GET_PHLEBO_TIMELINE;
  payload: Object;
}
interface UpdateUserStatus {
  type: typeof ACTIONS.UPDATE_USERS_STATUS;
  payload: Object;
}
interface CheckGeoFenceArea {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA;
  payload: Array<any>;
}
interface GetMapBooking {
  type: typeof ACTIONS.GET_MAP_BOOKING_LIST;
  payload: Array<any>;
}
interface GetIndividualWhatsappChat {
  type: typeof ACTIONS.GET_INDIVIDUAL_WHATSAPP_CHAT;
  payload: Array<any>;
}
interface GetBookingsTatTime {
  type: typeof ACTIONS.GET_BOOKING_LIST_WITH_TAT_TIME;
  payload: boolean;
}
interface GetAdditionalBookingsTatTime {
  type: typeof ACTIONS.GET_ADDITIONAL_BOOKING_LIST_WITH_TAT_TIME;
  payload: boolean;
}
interface GetRCFreshLeadData {
  type: typeof ACTIONS.GET_RCFRESH_LEAD_DATA;
  payload: Array<any>;
}
interface GetUnmaskedNumber {
  type: typeof ACTIONS.GET_UNMASKED_NUMBER;
  payload: Array<any>;
}
interface GetAgentData {
  type: typeof ACTIONS.GET_AGENT_DATA;
  payload: Array<any>;
}
interface GetActiveDeactivePanelUser {
  type: typeof ACTIONS.GET_PANELUSER_ACTIVE_DEACTIVE;
  payload: Array<any>;
}
interface GetAreaComments {
  type: typeof ACTIONS.GET_AREA_COMMENTS;
  payload: Array<any>;
}
interface GetPanelComments {
  type: typeof ACTIONS.GET_PANEL_COMMENT;
  payload: Array<any>;
}
interface AssignedpanelUser {
  type: typeof ACTIONS.ASSIGNED_PANEL_USER_DATA;
  payload: Object;
}
interface CreatePanelUser {
  type: typeof ACTIONS.CREATE_PANEL_USER;
  payload: Object;
}
interface GetTataTalyLead {
  type: typeof ACTIONS.LEAD_TRANSFER_TO_TATATALE;
  payload: Object;
}
interface GetAssignedUsergroup {
  type: typeof ACTIONS.GET_ASSIGNED_USERGROUP;
  payload: Object;
}
interface SetUserList {
  type: typeof ACTIONS.UPDATE_USERS_LIST;
  payload: Object;
}
interface GetPhleboCurrentLocation {
  type: typeof ACTIONS.GET_PHLEBO_CURRENT_LOCATION;
  payload: Array<any>;
}
interface GetArea {
  type: typeof ACTIONS.GET_AREA;
  payload: Object;
}
interface CreateArea {
  type: typeof ACTIONS.CREATE_AREA;
  payload: Object;
}
interface UpdateArea {
  type: typeof ACTIONS.UPDATE_AREA;
  payload: Object;
}
interface GetZone {
  type: typeof ACTIONS.GET_ZONE;
  payload: Object;
}
interface CreateZone {
  type: typeof ACTIONS.CREATE_ZONE;
  payload: Object;
}
interface UpdateZone {
  type: typeof ACTIONS.UPDATE_ZONE;
  payload: Object;
}
interface GetTimeslots {
  type: typeof ACTIONS.GET_TIMESLOTS;
  payload: Object;
}
interface GetBlogTags {
  type: typeof ACTIONS.GET_BLOG_TAGS;
  payload: Object;
}
interface GetRedCoins {
  type: typeof ACTIONS.GET_RED_COIN;
  payload: Array<any>;
}
interface UpdatePhlebos {
  type: typeof ACTIONS.UPDATE_PHLEBOS;
  payload: Object;
}
interface GetBlogCategory {
  type: typeof ACTIONS.GET_BLOG_CATEGORY;
  payload: Object;
}
interface GetBlogImages {
  type: typeof ACTIONS.GET_BLOG_IMAGES;
  payload: Object;
}
interface GetBlogs {
  type: typeof ACTIONS.GET_BLOGS;
  payload: Object;
}
interface GetBlogBySlug {
  type: typeof ACTIONS.GET_BLOG_BY_SLUG;
  payload: Object;
}
interface CreateBlogs {
  type: typeof ACTIONS.CREATE_BLOGS;
  payload: Object;
}
interface UpdateBlogs {
  type: typeof ACTIONS.UPDATE_BLOGS;
  payload: Object;
}
interface DeleteBlog {
  type: typeof ACTIONS.DELETE_BLOG;
  payload: Object;
}
interface CreateCoupon {
  type: typeof ACTIONS.CREATE_COUPON;
  payload: Object;
}
interface UpdateCoupon {
  type: typeof ACTIONS.UPDATE_COUPON;
  payload: Object;
}
interface GetPackageBySLug {
  type: typeof ACTIONS.GET_PACKAGE;
  payload: Object;
}
interface CreatePackage {
  type: typeof ACTIONS.CREATE_PACKAGE;
  payload: Object;
}
interface UpdatePackage {
  type: typeof ACTIONS.UPDATE_PACKAGE;
  payload: Object;
}
interface GetCityPrices {
  type: typeof ACTIONS.GET_CITY_PRICES;
  payload: Object;
}
interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}
interface SetBookingIdLoader {
  type: typeof ACTIONS.SET_BOOKING_ID_LOADER;
  payload: boolean;
}
interface SetBiomarkerLoader {
  type: typeof ACTIONS.SET_BIOMARKER_LOADER;
  payload: boolean;
}
interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}

interface UploadFile {
  type: typeof ACTIONS.UPLOAD_FILE;
  payload: Object;
}

interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}

interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}

interface GetPhlebo {
  type: typeof ACTIONS.GET_PHLEBOS;
  payload: Array<any>;
}
interface GetLogisticTatData {
  type: typeof ACTIONS.GET_LOGISTIC_TAT_DATA;
  payload: Array<any>;
}
interface SetLoadingComment {
  type: typeof ACTIONS.SET_LOADING_COMMENT;
  payload: boolean;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}

interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}

interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}
interface GetPhleboComment {
  type: typeof ACTIONS.GET_PHLEBO_COMMENTS;
  payload: Object;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}
interface GetNewBookings {
  type: typeof ACTIONS.GET_NEW_BOOKING_LIST;
  payload: boolean;
}
interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}
interface CreatePhlebo {
  type: typeof ACTIONS.CREATE_NEW_PHLEBO;
  payload: boolean;
}

interface GetPhleboByPhone {
  type: typeof ACTIONS.GET_PHLEBO_BY_PHONE;
  payload: Object;
}
interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}

interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}
interface ResetTable {
  type: typeof ACTIONS.TABLE_RESET;
  payload: boolean;
}

interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}

interface GetPhlebo {
  type: typeof ACTIONS.GET_PHLEBOS;
  payload: Array<any>;
}
interface GetDashboardOverview {
  type: typeof ACTIONS.GET_DASHBOARD_OVERVIEW;
  payload: Array<any>;
}
interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}
interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}
interface SetLoadingComment {
  type: typeof ACTIONS.SET_LOADING_COMMENT;
  payload: boolean;
}
interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}
interface GetCollectionSlot {
  type: typeof ACTIONS.GET_COLLECTION_SLOT;
  payload: Array<any>;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}

interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface GetAgentByUserGroup {
  type: typeof ACTIONS.GET_AGENTS_BY_USERGROUP;
  payload: Object;
}
interface GetLabCredentialDetails {
  type: typeof ACTIONS.GET_LAB_CREDENTIAL_DETAILS;
  payload: Object;
}
interface UpdatelabCredential {
  type: typeof ACTIONS.UPDATE_LAB_CREDENTIALS;
  payload: Object;
}
interface GetZoneComment {
  type: typeof ACTIONS.GET_ZONE_COMMENTS;
  payload: Object;
}
interface GetDispositions {
  type: typeof ACTIONS.GET_DISPOSITIONS;
  payload: Array<any>;
}
interface GetDispositionData {
  type: typeof ACTIONS.GET_DISPOSITION_DATA;
  payload: Object;
}
interface AddRemarkOnCall {
  type: typeof ACTIONS.ADD_REMARK_ON_CALL;
  payload: Object;
}
interface GetIncorrectNumberLead {
  type: typeof ACTIONS.GET_INCORRECT_NUMBER_LEAD;
  payload: Object;
}
interface SameNumberBooking {
  type: typeof ACTIONS.SAME_NUMBER_BOOKING;
  payload: Object;
}
interface GetPaymentSendLink {
  type: typeof ACTIONS.GET_PAYMENT_SEND_LINK;
  payload: Object;
}
interface GetPaymentReSendLink {
  type: typeof ACTIONS.GET_PAYMENT_RESEND_LINK;
  payload: Object;
}
interface GetPaymentCancelLink {
  type: typeof ACTIONS.GET_PAYMENT_CANCEL_LINK;
  payload: Object;
}
interface GetSmsReportSent {
  type: typeof ACTIONS.GET_SMS_REPORT_SEND;
  payload: Object;
}
interface GetBreakData {
  type: typeof ACTIONS.GET_BREAK_DATA;
  payload: Object;
}
interface GetBookingJourney {
  type: typeof ACTIONS.GET_BOOKING_JOURNEY_DETAILS;
  payload: Array<any>;
}
interface CreateAdditionalBooking {
  type: typeof ACTIONS.CREATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface UpdateAdditionalBooking {
  type: typeof ACTIONS.UPDATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface GetTodayCallDetails {
  type: typeof ACTIONS.GET_TODAYS_CALL_DETAILS;
  payload: Array<any>;
}
interface GetTopRecords {
  type: typeof ACTIONS.GET_TOP_RECORDS;
  payload: Array<any>;
}
interface GetVerificationBookingDetails {
  type: typeof ACTIONS.GET_VERIFICATION_BOOKING_DETAILS;
  payload: Array<any>;
}
interface GetSmsDetails {
  type: typeof ACTIONS.GET_SMS_DETAILS;
  payload: Array<any>;
}
interface GetEmailDetails {
  type: typeof ACTIONS.GET_EMAIL_DETAILS;
  payload: Array<any>;
}
interface GetReportStatus {
  type: typeof ACTIONS.GET_REPORT_STATUS;
  payload: Array<any>;
}
interface GetCenterInfo {
  type: typeof ACTIONS.GET_CENTER_INFORMATION;
  payload: Array<any>;
}
interface GetLabDetails {
  type: typeof ACTIONS.GET_LAB_DETAILS;
  payload: Array<any>;
}
interface GetPaymentInfro {
  type: typeof ACTIONS.GET_PAYMENT_INFORMATION;
  payload: Array<any>;
}
interface GetNotificationMessage {
  type: typeof ACTIONS.GET_NOTIFICATION_MESSAGE;
  payload: Array<any>;
}
interface GetPanelUserList {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA;
  payload: Array<any>;
}
interface GetReportDownload {
  type: typeof ACTIONS.GET_REPORT_DOWNLOAD;
  payload: Object;
}
interface getPanelUserData {
  type: typeof ACTIONS.GET_PANEL_USER_DATA;
  payload: Object;
}
interface GetUserDetails {
  type: typeof ACTIONS.GET_USER_DETAILS;
  payload: Object;
}
interface GetNewUserDetails {
  type: typeof ACTIONS.GET_NEW_USER_DETAILS;
  payload: Object;
}
interface GetFireBaseNotification {
  type: typeof ACTIONS.GET_FIREBASE_NOTIFICATION;
  payload: Object;
}
interface GetCurrentDayBooking {
  type: typeof ACTIONS.GET_CURRENT_DAY_BOOKING;
  payload: Object;
}

interface GetTodayMissedCalls {
  type: typeof ACTIONS.GET_TODAYS_MISSED_CALLS;
  payload: Object;
}
interface GetVerifiedByList {
  type: typeof ACTIONS.GET_VERIFIED_BY_LIST;
  payload: Object;
}
interface GetSampleHandOver {
  type: typeof ACTIONS.GET_SAMPLE_HANDOVER_DATA;
  payload: Array<any>;
}
interface GetRiderList {
  type: typeof ACTIONS.GET_RIDER_LIST;
  payload: Array<any>;
}
interface UpdateRiderToLabStatus {
  type: typeof ACTIONS.UPDATE_RIDER_TO_LAB_STATUS;
  payload: Array<any>;
}
interface GetSampleJourneyComments {
  type: typeof ACTIONS.GET_SAMPLE_JOURNEY_COMMENTS;
  payload: Object;
}
interface GetSubCenterInformation {
  type: typeof ACTIONS.GET_SUB_CENTER_INFORMATION;
  payload: Array<any>;
}

interface GetLeadStatus {
  type: typeof ACTIONS.GET_LEAD_STATUS;
  payload: Object;
}
interface GetImagingTimeSlots {
  type: typeof ACTIONS.GET_IMAGING_TIMESLOTS;
  payload: Array<any>;
}
interface CreateImagingBooking {
  type: typeof ACTIONS.CREATE_IMAGING_BOOKING;
  payload: boolean;
}
interface GetMultiplepackage {
  type: typeof ACTIONS.GET_MULTIPLE_PACKAGES;
  payload: Array<any>;
}
interface GetDisplayCities {
  type: typeof ACTIONS.GET_DISPLAY_CITIES;
  payload: Array<any>;
}
interface GetMapMyIndiaAccessToken {
  type: typeof ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN;
  payload: Array<any>;
}

interface GetAddressDetailsLatLong {
  type: typeof ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG;
  payload: Array<any>;
}
interface CheckGeoFenceAreaMapMyIndia {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA;
  payload: Array<any>;
}
interface CheckGeoFenceAreaMapMyIndiaForCustomer {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA_FOR_CUSTOMER_ADDRESS;
  payload: Array<any>;
}

interface GetRedTechComment {
  type: typeof ACTIONS.GET_RED_TECH_COMMENT;
  payload: Array<any>;
}
interface GetTicketData {
  type: typeof ACTIONS.GET_TICKET_DATA;
  payload: Object;
}
interface GetTicketCategory {
  type: typeof ACTIONS.GET_TICKET_CATEGORIES;
  payload: Object;
}
interface GetTicketSubCategories {
  type: typeof ACTIONS.GET_TICEKT_SUB_CATEGORIES;
  payload: Object;
}
interface PostRedTechComment {
  type: typeof ACTIONS.POST_RED_TECH_COMMENT;
  payload: Object;
}
interface CheckGeoFenceAreaMapMyIndiaForCustomer {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA_FOR_CUSTOMER_ADDRESS;
  payload: Array<any>;
}
interface CreateTaskTicket {
  type: typeof ACTIONS.CREATE_TASK_TICKET;
  payload: Array<any>;
}
interface GetQualityScoringReport {
  type: typeof ACTIONS.GET_QUALITY_SCORING_REPORT;
  payload: Array<any>;
}

interface GetQualityScoringReportForBackup {
  type: typeof ACTIONS.GET_QUALITY_SCORING_REPORT_FOR_BACKUP;
  payload: Array<any>;
}
interface UpdateBatchPayment {
  type: typeof ACTIONS.UPDATE_BATCH_PAYMENT;
  payload: Array<any>;
}
interface GetBatchPayment {
  type: typeof ACTIONS.GET_BATCH_PAYMENT;
  payload: Array<any>;
}
interface GeoLoading {
  type: typeof ACTIONS.SET_GEO_LOADING;
  payload: boolean;
}
interface GeoLoadingForCustomer {
  type: typeof ACTIONS.SET_GEO_LOADING_FOR_CUSTOMER;
  payload: boolean;
}
interface CheckGeoCheckError {
  type: typeof ACTIONS.SET_GEO_CHECK_ERROR;
  payload: boolean;
}
interface CheckGeoCheckErrorForCustomer {
  type: typeof ACTIONS.SET_GEO_ERROR_FOR_CUSTOMER;
  payload: boolean;
}

interface UpdateLeads {
  type: typeof ACTIONS.UPDATE_LEADS;
  payload: Array<any>;
}
interface RaiseNewTicektDisposition {
  type: typeof ACTIONS.RAISE_NEW_RAISE_TICEKT_DISPOSITION;
  payload: Array<any>;
}

interface PostPhleboRiderMapping {
  type: typeof ACTIONS.POST_PHLEBO_RIDER_MAP;
  payload: Array<any>;
}
interface UpdatePhleboRider {
  type: typeof ACTIONS.UPDATE_PHLEBO_RIDER;
  payload: Array<any>;
}
interface GeoLoadingForCustomer {
  type: typeof ACTIONS.SET_GEO_LOADING_FOR_CUSTOMER;
  payload: boolean;
}
interface GetPhleboRider {
  type: typeof ACTIONS.GET_PHLEBO_RIDER;
  payload: Array<any>;
}
interface CheckGeoCheckErrorForCustomer {
  type: typeof ACTIONS.SET_GEO_ERROR_FOR_CUSTOMER;
  payload: boolean;
}
interface GetRoutes {
  type: typeof ACTIONS.GET_ROUTES;
  payload: Array<any>;
}
interface GetTicketComments {
  type: typeof ACTIONS.GET_TICKET_COMMENTS;
  payload: Array<any>;
}
interface GetBookingQueryTickets {
  type: typeof ACTIONS.GET_BOOKING_COMPLAINTS;
  payload: boolean;
}
interface UpdateTicketStatus {
  type: typeof ACTIONS.UPDATE_TICKET_STATUS;
  payload: Array<any>;
}
interface UpdateActionDisposition {
  type: typeof ACTIONS.UPDATE_DISPOSITION;
  payload: Array<any>;
}
interface GetBaseCities {
  type: typeof ACTIONS.GET_BASE_CITIES;
  payload: Array<any>;
}
interface PostColdCallingLeadUpload {
  type: typeof ACTIONS.POST_PHLEBO_RIDER_MAP;
  payload: Array<any>;
}
interface GetAnalyticsRecording {
  type: typeof ACTIONS.GET_ANALYTICS_RECORDING;
  payload: Object;
}
interface getColdCallLeadData {
  type: typeof ACTIONS.GET_COLD_CALLING_LEAD_DATA;
  payload: Array<any>;
}

interface GetBookingsLoader {
  type: typeof ACTIONS.SET_ALL_BOOKING_LOADING;
  payload: boolean;
}
interface SetQualityScoreBackupLoader {
  type: typeof ACTIONS.SET_QUALITYSCOREBACKUP_LOADING;
  payload: Array<any>;
}

interface SetLoadingChat {
  type: typeof ACTIONS.SET_LOADING_CHAT;
  payload: boolean;
}
interface SetPhlebopLoader {
  type: typeof ACTIONS.SET_PHLEBO_LOADING;
  payload: Array<any>;
}
interface SetRiderLoader {
  type: typeof ACTIONS.SET_RIDER_LOADING;
  payload: Array<any>;
}
interface SetAssignedUnassignedLoader {
  type: typeof ACTIONS.SET_ASSIGNED_LOADING;
  payload: Array<any>;
}
interface GetTicketCommentsForDocuments {
  type: typeof ACTIONS.GET_TICKET_COMMENTS_FOR_DOCUMENTS;
  payload: Array<any>;
}
interface CreateLeaveList {
  type: typeof ACTIONS.CREATE_PHLEBO_LEAVE;
  payload: Array<any>;
}
interface GetPhleboLeaveList {
  type: typeof ACTIONS.GET_PHLEBO_LEAVE_LIST;
  payload: Object;
}
interface GetPhleboUnderRm {
  type: typeof ACTIONS.GET_PHLEBO_LIST_UNDER_ROUTE_MANAGER;
  payload: Array<any>;
}
interface UpdatePhleboLeave {
  type: typeof ACTIONS.UPDATE_PHLEBO_LEAVE;
  payload: Array<any>;
}
interface BiomarkerReset {
  type: typeof ACTIONS.BIOMARKER_RESET;
  payload: boolean;
}
interface GetBiomarkerScreening {
  type: typeof ACTIONS.GET_BIOMARKER_SCREENING;
  payload: Object;
}
interface GetAdditionalMember {
  type: typeof ACTIONS.GET_ADDITIONAL_MEMBER;
  payload: Array<any>;
}
interface GetBiomarkerUltasound {
  type: typeof ACTIONS.GET_BIOMARKER_ULTASOUND;
  payload: Object;
}
interface getOzontelSkillData {
  type: typeof ACTIONS.GET_OZONTEL_SKILL_DATA;
  payload: Object;
}
interface GetCityAlias {
  type: typeof ACTIONS.GET_CITY_ALIAS;
  payload: Array<any>;
}
interface CreateCityAlias {
  type: typeof ACTIONS.CREATE_CITY_ALIAS;
  payload: Array<any>;
}
interface GetCityComments {
  type: typeof ACTIONS.GET_CITY_COMMENTS;
  payload: Object;
}
export type BookingTypes =
  | UpdateAttendance
  | SetUserList
  | GetArea
  | CreateArea
  | UpdateArea
  | GetZone
  | CreateZone
  | UpdateZone
  | GetBlogs
  | GetBlogBySlug
  | CreateBlogs
  | UpdateBlogs
  | GetTimeslots
  | GetBlogTags
  | GetBlogCategory
  | GetBlogImages
  | CreateCoupon
  | UpdateCoupon
  | DeleteBlog
  | GetPackageBySLug
  | CreatePackage
  | UpdatePackage
  | GetCityPrices
  | SetLoading
  | GetDashboardOverview
  | SearchCall
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetAgents
  | GetPhlebo
  | GetAgentByUserGroup
  | GetPackages
  | CreateBooking
  | GetLtvLead
  | UpdateBooking
  | GetPartners
  | GetCoupons
  | GetBookings
  | GetBookingById
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ResetTable
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | CreateAdditionalBooking
  | UpdateAdditionalBooking
  | SetComments
  | UploadFile
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetAgents
  | GetPhlebo
  | GetPackages
  | CreateBooking
  | UpdateBooking
  | GetPartners
  | GetCoupons
  | GetBookings
  | GetBookingById
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | SetComments
  | SearchCall
  | GetLabCredentialDetails
  | UpdatelabCredential
  | GetZoneComment
  | GetDispositions
  | GetDispositionData
  | AddRemarkOnCall
  | GetIncorrectNumberLead
  | SameNumberBooking
  | GetAgentNewData
  | GetPaymentSendLink
  | GetPaymentReSendLink
  | GetPaymentCancelLink
  | GetSmsReportSent
  | GetBreakData
  | GetAssignedUsergroup
  | GetTodayCallDetails
  | GetTopRecords
  | GetVerificationBookingDetails
  | GetSmsDetails
  | GetEmailDetails
  | GetReportStatus
  | GetCenterInfo
  | GetLabDetails
  | GetPaymentInfro
  | GetNotificationMessage
  | GetTataTalyLead
  | GetUserDetails
  | GetReportDownload
  | CreatePanelUser
  | UpdatedPanelUser
  | AssignedpanelUser
  | GetPanelUserList
  | getPanelUserData
  | GetAreaComments
  | GetPanelComments
  | GetUnmaskedNumber
  | GetActiveDeactivePanelUser
  | GetAgentData
  | GetTodayMissedCalls
  | UpdateUserStatus
  | CheckGeoFenceArea
  | GetRCFreshLeadData
  | GetIndividualWhatsappChat
  | GetBookingsTatTime
  | GetAdditionalBookingsTatTime
  | GetAssignedUnassignedBooking
  | AutoAssignBooking
  | GetCurrentDayBooking
  | GetCityWisePhlebos
  | GetAttendence
  | GetVerifiedByList
  | GetBookingJourney
  | GetPackageTags
  | GetAdditionalBooking
  | GetSignature
  | GetQRCodeData
  | GetPackageCategory
  | GetSampleHandOver
  | GetRiderList
  | UpdateRiderToLabStatus
  | GetSampleJourneyComments
  | UpdatePhlebos
  | GetPhleboComment
  | CreatePhlebo
  | GetPhleboByPhone
  | GetRedCoins
  | GetCollectionSlot
  | GetSubCenterInformation
  | GetReportData
  | GetHealthTestParameter
  | GetParameterCount
  | GetLeadStatus
  | GetPrescriptionData
  | GetImagingTimeSlots
  | CreateImagingBooking
  | GetMultiplepackage
  | GetDisplayCities
  | GetMapMyIndiaAccessToken
  | CheckGeoFenceAreaMapMyIndia
  | GetAddressTypeSeating
  | GetSMSLink
  | GetMapBooking
  | GetPhleboCurrentLocation
  | GetBookingCall
  | GetPanelUserDataSalesLead
  | GetPanelUserDataTeamLead
  | GetPhleboRiderDueAmount
  | GetImagingTicket
  | GetImagingTicketCount
  | GetTopRcFreshLeads
  | TransferImagingTicket
  | GetPaymentRefundList
  | InitiateRefund
  | UpdateInitiateRefund
  | GetPerformanceData
  | GetTicketData
  | GetTicketCategory
  | GetTicketSubCategories
  | CreateTaskTicket
  | GetRedTechComment
  | GetLogisticTatData
  | PostRedTechComment
  | GetQualityScoringReport
  | GetQualityScoringReportForBackup
  | GetFailedReportData
  | SendReportById
  | GetBatchBookingAmount
  | UpdateBatchPayment
  | GetBatchPayment
  | GetCeCenterForCc
  | GetBreakResponse
  | CreatePilotBooking
  | GeoLoading
  | CheckGeoCheckError
  | UpdateLeads
  | RaiseNewTicektDisposition
  | PostPhleboRiderMapping
  | UpdatePhleboRider
  | GetPhleboRider
  | GetRoutes
  | GetTicketComments
  | GetBookingQueryTickets
  | UpdateActionDisposition
  | GetBaseCities
  | UpdateTicketStatus
  | PostColdCallingLeadUpload
  | getColdCallLeadData
  | UpadtepanelUser
  | GetPanelList
  | getPanelsUsers
  | GetBookingsLoader
  | SetQualityScoreBackupLoader
  | SetPhlebopLoader
  | SetRiderLoader
  | SetAssignedUnassignedLoader
  | GetAnalyticsRecording
  | CheckGeoFenceAreaMapMyIndiaForCustomer
  | GeoLoadingForCustomer
  | CheckGeoCheckErrorForCustomer
  | GetTicketCommentsForDocuments
  | CreateLeaveList
  | GetPhleboLeaveList
  | GetPhleboUnderRm
  | UpdatePhleboLeave
  | BiomarkerReset
  | GetBiomarkerScreening
  | GetAdditionalMember
  | GetBiomarkerUltasound
  | GetNewBookings
  | GetWhatsappTemplate
  | CreateWhatsappTemplate
  | GetNewUserDetails
  | SendLocationRequest
  | getCrmLeadAddress
  | PostCrmLeadAddress
  | LocationVerified
  | SetLoadingChat
  | GetAddressDetailsLatLong
  | SetLoadingIndividualChat
  | SetLoadingComment
  | getOzontelSkillData
  | SetLoadingChat
  | GetAddressDetailsLatLong
  | SetLoadingIndividualChat
  | SetLoadingComment
  | SetBookingIdLoader
  | SetBiomarkerLoader
  | SetBiomarkerLoader
  | SetReportLoading
  | SignOutInWhatsappChat
  | SwitchChatAgentInWhatsappChat
  | getHoldTheChat
  | allRedtechTicketsUsingLead
  | sendLastReportToLeadId
  | latestBookingUsingLead
  | GetCampaignsDetails
  |GetPhleboAutoAssign
  |GetPhleboTimeline
  |UpdatePackageAlias
  |CreatePackageAlias
  |GetPackageComments
  |GetPackageAlias
  | queueNumber
  | GetCityAlias
  | CreateCityAlias
  | GetCityComments;
