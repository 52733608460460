import React, { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
  fade,
} from "@material-ui/core/styles";
import Callmodel from "../pages/Callmodel";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DialpadIcon from "@material-ui/icons/Dialpad";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SettingsOverscanSharpIcon from "@material-ui/icons/SettingsOverscanSharp";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import {
  logout,
  panelUserLogout,
  getFirebaseNotification,
} from "../../actions/loginActions";
import { useDispatch } from "react-redux";
import HomeIcon from "@material-ui/icons/Home";
import QuizIcon from "@mui/icons-material/Quiz";
import {
  PhonelinkLock,
  Book,
  WhatsApp,
  ConfirmationNumberRounded,
  AddBoxTwoTone,
} from "@material-ui/icons";
import StoreIcon from "@material-ui/icons/Store";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import {
  changePassword,
  searchCall,
  getNotificationMessage,
  getUserDetails,
} from "../actions/bookingActions";
import CallModal from "../components/call-modal";
import PhoneAndroid from "@material-ui/icons/PhoneAndroid";
import EqualizerRoundedIcon from "@material-ui/icons/EqualizerRounded";
import FreeBreakfastIcon from "@material-ui/icons/FreeBreakfast";
import BreakModal from "../components/Comments/breakModal";
import TextsmsIcon from "@material-ui/icons/Textsms";
import FilterCenterFocusIcon from "@material-ui/icons/FilterCenterFocus";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import InvertColorsIcon from "@material-ui/icons/InvertColors";
import NotificationsPausedIcon from "@material-ui/icons/NotificationsPaused";
import DataUsageIcon from "@material-ui/icons/DataUsage";
import { getToken, onMessageListener } from "../../firebase";
import CallNotificationModal from "../components/Comments/CallNotificationModal";
import NewUpdateModal from "../components/Comments/newUpdate";
import AirplayIcon from "@material-ui/icons/Airplay";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import EmailIcon from '@mui/icons-material/Email';
import ListAltIcon from "@mui/icons-material/ListAlt";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    dashbord: {
      color: "#fff",
      fontSize: "18px",
      fontWeight: "normal",
      padding: "20px 0px 10px 10px",
    },
    title: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      color: "#fff",
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "left",
      justifyContent: "flex-start",
      backgroundColor: "#924A91",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    titleButtor: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
      color: "#fff",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  })
);

interface Props {
  userDetails: any;
  getNotificationMessage: any;
  callNotification: any;
  notificationMessage: any;
  passwordStatus: boolean;
  getUserDetails: any;
}
const SupportExecutive: React.FC<Props> = ({
  getNotificationMessage,
  callNotification,
  userDetails,
  passwordStatus,
  notificationMessage,
  getUserDetails,
}) => {
  let dispatch = useDispatch();
  const classes = useStyles();
  let history = useHistory();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [option, setOption] = React.useState<string>("call_id");
  const [value, setValue] = React.useState<string>("");
  const [heading, setHeading] = React.useState<string>("");
  const [modelFormIndex, setModelFormIndex] = React.useState<number>(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [changePasswordModal, setchangePasswordModal] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const openPopover = Boolean(anchorEl);
  const [openRightMenu, setOpenRightMenu] = React.useState<boolean>(false);
  const [isTokenFound, setTokenFound] = useState(false);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [bodyData, setBodyData] = useState<any>({});
  const audio = new Audio(
    "https://static.redcliffelabs.com/media/mixkit-modern-classic-door-bell-sound-113.mp3"
  );
  const [anchorElUpdate, setAnchorElUpdate] = React.useState(null);
  const id = open ? "transitions-popper" : undefined;

  const handleClick = (event: any) => {
    setOpenRightMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenRightMenu(false);
  };

  // useEffect(() => {
  //   let token;
  //   async function tokenFunc() {token = await getToken(setTokenFound);
  //     if (token) {
  //       const body = {
  //         token,
  //       };
  //       dispatch(getFirebaseNotification(body));
  //     }
  //     return token;
  //   }

  //   tokenFunc();
  // }, [setTokenFound]);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
      setBodyData(JSON.parse(payload.notification.body));
    })
    .catch((err) => console.log("failed: ", err));

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
    // getNotificationMessage(`?is_read=false`);
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const updatePassword = async () => {
    const body: any = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    await dispatch(changePassword(body));
    setchangePasswordModal(false);
  };
 

  const handleDrawerOpen = () => {
    setOpen1(true);
  };

  const handleValueChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value as string);
  };

  const handleDrawerClose = () => {
    setOpen1(false);
  };

  const handleOpen = (name: string, index: number) => {
    setOpen(true);
    setHeading(name);
    setModelFormIndex(index);
    audio.play();
  };
  useEffect(() => {
    if (!passwordStatus) {
      setchangePasswordModal(true);
    }
  }, [passwordStatus]);
  const submitForm = (e: any) => {
    e.preventDefault();
    let body = {
      search_by: option,
      value,
    };
    dispatch(searchCall(body));
    history.push("/dashboard/ce/lead/search");
  };

  const handleBreakButton = () => {
    setOpenModal(true);
  };
  const handleLogout = () => {
    dispatch(panelUserLogout());
    dispatch(logout());
  };
  const handleUpdateDetails = (event: any) => {
    setAnchorElUpdate(anchorElUpdate ? null : event.currentTarget);
  };

  useEffect(() => {
    if (notification.body !== "") {
      audio.play();
    }
  }, [notification]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open1,
        })}
      >
        <Toolbar style={{ backgroundColor: "#924A91" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open1,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Redcliffelabs
          </Typography>
          <Typography className={classes.titleButtor} variant="h6">
            {/* <Link to="/dashboard/pcc/fill-info"> */}
            {/* <Button
              variant="contained"
              color="secondary"
              onClick={() => handleOpen("Create Call", 7)}
            >
              Create Call
            </Button> */}
            {/* </Link>     */}
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <form onSubmit={submitForm}>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                value={value}
                onChange={handleValueChange}
                inputProps={{ "aria-label": "search" }}
              />
            </form>
          </div>
          <div>
            <Button
              variant="text"
              onClick={(e) => handleClick(e)}
              size="medium"
              endIcon={<KeyboardArrowDownIcon />}
              style={{ color: "#fff" }}
            >
              {userDetails &&
                userDetails.profile &&
                userDetails.profile.username}
            </Button>
            <Menu
              id="fade-menu"
              open={openRightMenu}
              onClose={handleClose}
              TransitionComponent={Fade}
              anchorEl={anchorEl}
            >
              <MenuItem onClick={() => null}>
                Mobile -{" "}
                {userDetails &&
                  userDetails.profile &&
                  userDetails.profile.phonenumber}
              </MenuItem>
              <MenuItem onClick={() => setchangePasswordModal(true)}>
                Change Password
              </MenuItem>
              <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
            </Menu>
          </div>
          
          <IconButton>
            <DeveloperModeIcon
              aria-describedby={id}
              onClick={handleUpdateDetails}
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open1,
          [classes.drawerClose]: !open1,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open1,
            [classes.drawerClose]: !open1,
          }),
        }}
      >
        <Dialog
          open={changePasswordModal}
          // onClose={() => setchangePasswordModal(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
          <DialogContent>
            <TextField
              id="name"
              placeholder="Old Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="New Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="Confirm Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => updatePassword()} color="primary">
              Update
            </Button>
            {/* <Button
              onClick={() => setchangePasswordModal(false)}
              color="primary"
            >
              Cancel
            </Button> */}
          </DialogActions>
        </Dialog>
        <div className={classes.toolbar}>
          <span className={classes.dashbord}>Dashboard</span>
          <IconButton onClick={handleDrawerClose} style={{ color: "#fff" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to="/dashboard/ce">
            <ListItem button>
              <Tooltip title="Dashboard" arrow>
                <ListItemIcon>
                  < HomeIcon  style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/ce/booking">
            <ListItem button>
              <Tooltip title="New Booking" arrow>
                <ListItemIcon>
                  <AddBoxTwoTone style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="New Booking" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/ce/AuditScore">
            <ListItem button>
              <Tooltip title="Audit Score" arrow>
                <ListItemIcon>
                  <LoyaltyIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Audit Score" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/ce/bookings">
            <ListItem button>
              <Tooltip title="Bookings List" arrow>
                <ListItemIcon>
                  <Book style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Bookings List" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/ce/booking/tickets">
            <ListItem button>
              <Tooltip title="Booking Tickets" arrow>
                <ListItemIcon>
                  <ConfirmationNumberRounded style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Booking Tickets" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/ce/lead/emailDetails">
            <ListItem button>
              <Tooltip title="Booking Email Details" arrow>
                <ListItemIcon>
                  <EmailIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Booking Email Details" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/ce/lead/smsDetails">
            <ListItem button>
              <Tooltip title="Booking SMS Details" arrow>
                <ListItemIcon>
                  <TextsmsIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Booking SMS Details" />
            </ListItem>
          </Link>
        </List>

        <ListItem button onClick={() => handleBreakButton()}>
          <Tooltip title="Break Button" arrow>
            <ListItemIcon>
              <FreeBreakfastIcon style={{ color: "#924A91" }} />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Break Button" />
        </ListItem>
        <List>
          <Link to="/dashboard/ce/calls_recording">
            <ListItem button>
              <Tooltip title="Calls Recording" arrow>
                <ListItemIcon>
                  <PhoneAndroid style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Calls Recording" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/ce/centerInfo">
            <ListItem button>
              <Tooltip title="Center Information" arrow>
                <ListItemIcon>
                  <FilterCenterFocusIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Center Information" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/ce/generate-coupon">
            <ListItem button>
              <Tooltip title="Family Package" arrow>
                <ListItemIcon>
                  <StoreIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Family Package" />
            </ListItem>
          </Link>
        </List>

        <List>
          <Link to="/dashboard/ce/imaging-ticekt">
            <ListItem button>
              <Tooltip title="Imaging Tickets" arrow>
                <ListItemIcon>
                  <SettingsOverscanSharpIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Imaging Tickets" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/ce/Leadtable">
            <ListItem button>
              <Tooltip title="Leads" arrow>
                <ListItemIcon>
                  <AirplayIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Leads" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/ce/labDetails">
            <ListItem button>
              <Tooltip title="Lab Details" arrow>
                <ListItemIcon>
                  <DialpadIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Lab Details" />
            </ListItem>
          </Link>
        </List>

        <List>
          <Link to="/dashboard/ce/RepetedList">
            <ListItem button>
              <Tooltip title="Repeated Leads" arrow>
                <ListItemIcon>
                  <DataUsageIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Repeated Leads" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/ce/sendNotification">
            <ListItem button>
              <Tooltip title="Send Notification" arrow>
                <ListItemIcon>
                  <NotificationsPausedIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Send Notification" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/ce/testPackages">
            <ListItem button>
              <Tooltip title="Test Packages" arrow>
                <ListItemIcon>
                  <InvertColorsIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Test Packages" />
            </ListItem>
          </Link>
        </List>


        {/* <Link to="/dashboard/ce/missed-calls">
            <ListItem button>
              <Tooltip title="Missed Calls" arrow>
                <ListItemIcon>
                  <PhoneAndroid style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Missed Calls" />
            </ListItem>
          </Link> */}



        {/* <List>
          <Link to="/dashboard/ce/calls/stats">
            <ListItem button>
              <Tooltip title="Calls Stats" arrow>
                <ListItemIcon>
                  <EqualizerRoundedIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Calls Stats" />
            </ListItem>
          </Link>
        </List> */}




        <List>
          <Link to="/dashboard/ce/whatsapp">
            <ListItem button>
              <Tooltip title="WhatsApp Messages" arrow>
                <ListItemIcon>
                  <WhatsApp style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="WhatsApp Messages" />
            </ListItem>
          </Link>
        </List>

        {/* <List>
          <Link to="/dashboard/ce/bookingwithmap">
            <ListItem button>
              <Tooltip title="Booking with Pincode  Search" arrow>
                <ListItemIcon>
                  <AddBoxTwoTone style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Booking with Pincode  Search" />
            </ListItem>
          </Link>
        </List> */}


        {/* <List>
          <Link to="/dashboard/ce/giftcard_services">
            <ListItem button>
              <Tooltip title="Gift Card Services" arrow>
                <ListItemIcon>
                  <CardGiftcardIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Gift Card Service" />
            </ListItem>
          </Link>
        </List> */}

        {/* <List>
          <Link to="/dashboard/ce/helpdesk">
            <ListItem button>
              <Tooltip title=" Help Desk" arrow>
                <ListItemIcon>
                  <QuizIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary=" Help Desk" />
            </ListItem>
          </Link>
        </List>  */}
        <Divider />
        <List>
          <ListItem button onClick={() => handleLogout()}>
            <Tooltip title="Logout" arrow>
              <ListItemIcon>
                <ExitToAppIcon style={{ color: "#924A91" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      {callNotification &&
        callNotification?.results &&
        callNotification?.results?.length > 0
        ? callNotification?.results.map((data: any) => {
          return (
            <Popover
              id="mouse-over-popover"
              className={classes.popover}
              classes={{
                paper: classes.paper,
              }}
              open={openPopover}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography>{data?.notification.call_type}</Typography>
            </Popover>
          );
        })
        : null}

      <BreakModal openModal={openModal} setOpenModal={setOpenModal} />
      <CallModal
        open={open}
        setOpen={setOpen}
        heading={heading}
        index={modelFormIndex}
      />
      <CallNotificationModal bodyData={bodyData} />
      <NewUpdateModal
        anchorElUpdate={anchorElUpdate}
        setAnchorElUpdate={setAnchorElUpdate}
        id={id}
      />
      {/* <Callmodel /> */}
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  userDetails: state.BookingReducer.userDetails,
  passwordStatus: state.loginReducer.passwordStatus,
  notificationMessage: state.loginReducer.notificationMessage,
  callNotification: state.BookingReducer.callNotification,
});

export default connect(mapStateToProps, {
  getNotificationMessage,
  getUserDetails,
})(SupportExecutive);
