import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { getPackage, getPinCode, getCities, getLeadById } from "../actions/bookingActions";
import "./filter.sass";
import {
  Paper,
  Grid,
  Typography,
  Card,
  FormControl,
  TextField,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      width: "100%",
      padding: theme.spacing(1),
      display: "grid",
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    spacebtm: {
      marginBottom: 15,
    },
  })
);

interface Props {
  getPackage: any;
  packageList: any;
  getPinCode: any;
  pin_code: any;
  loading: Boolean;
  getCities: any;
  cities: any;
  getLeadById: any;
  leadById: any;
  geoFencesMapMyIndia: any;
  show: any;
}

const LeadsPage: React.FC<Props> = ({
  getPackage,
  packageList,
  getPinCode,
  pin_code,
  loading,
  getCities,
  cities,
  getLeadById,
  leadById,
  geoFencesMapMyIndia,
  show,
}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);
  const [pincode, setPinCode] = useState<String>("")
  const [cityId, setCityId] = useState<any>("")
  useEffect(() => {
    if (cityId !== undefined&&cityId!==null) 
    if (show) {
      getPackage();
      getCities();
     
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);
  const [page, setPage] = useState(0);
  const [city, setCity] = useState<any>([]);
  const [cityName, setCityName] = useState<any>("");

  const handlePage = (event: any, value: any) => {
    if (cityId !== undefined&&cityId!==null) {
    getPackage(`page=${value}`);
    setPage(value);
  }
  };
  const handlePinCode = (val: String) => {
    setPinCode(val as string)
    const { cityid } = pin_code.results.find((code: any) => code.pincode === val)
    setCityId(cityid)
  }

  useEffect(() => {
    setCity(geoFencesMapMyIndia?.cityid)
    setCityName(geoFencesMapMyIndia?.city_name)
  }, [geoFencesMapMyIndia]);

  return (
    <>

      <div style={{ width: "100%" }}>


        <h3 style={{ fontWeight: "bold", textAlign: "center" }}>Test Packages</h3>
        <Grid
          container
          spacing={1}
          direction="row"
        >
          <Grid item xs={12} sm={6} >
          <Autocomplete
                id="city"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setCity(obj?.id);
                    setCityName(obj?.name)
                  }
                }}
                options={cities}
                freeSolo
                blurOnSelect
                aria-required
                // inputValue={cityName || ""}
                // defaultValue={cityName || ""}
                value={cityName || ""}
                getOptionLabel={(option: any) => option?.name !== undefined ? option?.name : cityName}
                getOptionDisabled={(option: any) => {
                  return (option?.id !== undefined ? city === option?.id : city);
                }}
                disableClearable
                disableCloseOnSelect
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    if (event !== null) getCities(newInputValue);
                  }, 1000);
                  // if (newInputValue?.length === 0) {
                  //   setCity([]);
                  // }
                }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="City"
                    variant="outlined"
                    // disabled
                    // value={cityName}
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                )}
              />
              {/* <Autocomplete
                              id="city"
                              onChange={(event, newValue) => {
                                if (newValue) {
                                  let obj = JSON.parse(
                                    JSON.stringify(newValue, null, " ")
                                  );
                                  setCity(obj.id);
                                  setCityName(obj.name);
                                }
                              }}
                              options={cities}
                              freeSolo
                              blurOnSelect
                              aria-required
                              getOptionLabel={(option: any) => option.name}
                              inputValue={cityName}
                              onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                setCityName(newInputValue);
                                timer.current = setTimeout(() => {
                                  getCities(newInputValue);
                                }, 1000);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  className="input"
                                  {...params}
                                  placeholder="City"
                                  variant="outlined"
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    minWidth: "200px",
                                  }}
                                />
                              )}
                            /> */}
              {/* <TextField
                    className="input"
                    label="City"
                    variant="outlined"
                    value={leadById?.patient_city}
                    disabled
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  /> */}
            {/* <Autocomplete
              id="pincode"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  handlePinCode(obj.pincode as String)
                }
              }}
              options={pin_code.results}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => `${option.pincode}`}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current)
                timer.current = setTimeout(() => {
                  getPinCode(newInputValue.split(",")[0])
                }, 1000)
              }}
              renderInput={(params) => (
                <TextField className="input"
                  {...params}
                  placeholder="Pin Code"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            /> */}
          </Grid>
          <Grid item xs={12} sm={6} >
            <Autocomplete
              freeSolo
              id="test-name-filter"
              options={packageList.results}
              // onChange={(newValue) => {
              //   getPackage(`code=${newValue}`);
              // }}
              disableClearable
              getOptionLabel={(option: any) => option.name}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                   if (cityId !== undefined&&cityId!==null) {
                    if (event !== null) getPackage(`city_id=${city}&code=${newInputValue}`)
                   }
                }, 200);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  variant="outlined"
                  label="Search by Test name"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  InputProps={{ ...params.InputProps, type: "search" }}
                />

              )}
              disabled={city === ""}
            />
          </Grid>
        </Grid>


        {packageList.results &&
          packageList.results.length > 0 &&
          packageList.results.map((data: any, index: any) => {
            return (

              <Card
                variant="outlined"
                className={classes.card}
                elevation={3}
                component={Paper}
              >

                <Typography variant="h6" align="center">
                  <b> {data.name}</b>
                </Typography>


                <Divider></Divider>


                <div>
                  <Typography
                    variant="h6"
                    align="left"
                    style={{ padding: "10px" }}
                  >
                    Parameters:<span>{data.parameter}</span>
                  </Typography>
                  <Typography
                    variant="h6"
                    align="right"
                    style={{ padding: "10px" }}
                  >
                    TaT Time:<span>{data.package_city_prices ? data.package_city_prices.tat_time : data.tat_time}</span>
                  </Typography>
                </div>


                <p>{data.description}</p>



                <Card
                  className={classes.card}
                  style={{ height: 100 }}
                  variant="outlined"
                >

                  <Typography variant="h6" align="center">
                    Package Price
                  </Typography>

                  <Typography variant="h6" align="center">
                    <b>{data.package_price}</b>
                  </Typography>

                </Card>


                <Card
                  className={classes.card}
                  style={{ height: 100 }}
                  variant="outlined"
                >

                  <Typography variant="h6" align="center">
                    Offer Price
                  </Typography>

                  <Typography variant="h6" align="center">
                    <b> {data.package_city_prices === null ? data.offer_price : data.package_city_prices.offer_price}</b>
                  </Typography>

                </Card>


                <a href={data.web_link} target="_blank">
                  {data.web_link}
                </a>


              </Card>

            );
          })}


        <Pagination
          count={Math.ceil(parseInt(packageList.count) / 50)}
          page={page}
          variant="outlined"
          color="primary"
          onChange={handlePage}
        />


      </div>

    </>
  );
};

const mapStateToProps = (state: any) => ({
  packageList: state.BookingReducer.packageList,
  pin_code: state.BookingReducer.pin_code,
  loading: state.BookingReducer.loading,
  cities: state.BookingReducer.cities,
  leadById: state.BookingReducer.leadById,
  geoFencesMapMyIndia: state.BookingReducer.geoFencesMapMyIndia,
});

export default connect(mapStateToProps, {
  getPackage,
  getPinCode,
  getCities,
  getLeadById,
})(LeadsPage);