import React, { useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Loader from "../loader";
import { connect } from "react-redux";
import { Button, Paper, TextField, Grid, Chip } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createBlogs, getBlogTags, getBlogCategory, getBlogImages } from '../../actions/salesLeadAdminAction'
import { useEffect } from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        paper: {
            padding: "1rem",
            width: "100%",
            borderRadius: "10px"
        },
        head: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "1rem",
            textAlign: "center"
        },
        heading: {
            fontWeight: "normal",
            fontSize: "1.2rem",
            margin: "0"
        },
        table: {
            maxHeight: "400px",
            margin: "auto",
            overflow: "auto"
        },
        image: {
            width: "100px",
            height: "auto",
        },
        imageSM: {
            width: "50px",
            height: "auto",
        },
        imgContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap"
        }
    })
);

interface Props {
    createBlogs: any;
    getBlogTags: any;
    getBlogCategory: any;
    blogCategories: any;
    blogTags: any;
    getBlogImages: any;
    blogImages: any;
    loading: any;
}

const BlogsPage: React.FC<Props> = ({
    createBlogs,
    blogCategories,
    blogTags,
    getBlogTags,
    getBlogCategory,
    getBlogImages,
    blogImages,
    loading,
}) => {
    const classes = useStyles();

    const timer = useRef<any>(0)

    const [title, setTitle] = useState("")
    const [summary, setSummary] = useState("")
    const [content, setContent] = useState("")
    const [thumbnail, setThumbnail] = useState<any>("")
    const [headerImage, setHeaderImage] = useState<any>("")
    const [metaTitle, setMetaTitle] = useState("")
    const [metaDescription, setMetaDescription] = useState("")
    const [metaKeyword, setMetaKeyword] = useState("")
    const [tags, setTags] = useState<any>("")
    const [category, setCategory] = useState<any>("")
    const [images, setImages] = useState<any>("")

    useEffect(() => {
        getBlogCategory()
        getBlogTags()
        getBlogImages()
    }, [])

    const handleSetThumbnail = (e: React.ChangeEvent<{ files: any }>) => {
        setThumbnail(e.target.files[0])
    }

    const handleSetHeaderImage = (e: React.ChangeEvent<{ files: any }>) => {
        setHeaderImage(e.target.files[0])
    }

    const submitHandler = () => {
        const formData = new FormData();
        formData.append("title", title)
        formData.append("thumbnail", thumbnail)
        tags.map((tag: any) => formData.append("tags", tag))
        category.map((cat: any) => formData.append("category", cat))
        images.map((img: any) => formData.append("images", img))
        formData.append("summary", summary)
        formData.append("content", content)
        formData.append("meta_title", metaTitle)
        formData.append("meta_description", metaDescription)
        formData.append("meta_keyword", metaKeyword)
        formData.append("header_image", headerImage)
        createBlogs(formData)
    }

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Paper elevation={5} className={classes.paper}>
                    <h3 style={{ textAlign: "center" }}>Create Blog</h3>
                    {loading ? <Loader /> : (
                        <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Title</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter Title"
                                        value={title}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Meta Title</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter Title"
                                        value={metaTitle}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e: any) => setMetaTitle(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Meta Description</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter Title"
                                        value={metaDescription}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e: any) => setMetaDescription(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Meta Keywork</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="meta keywork"
                                        type="text"
                                        placeholder="Enter Title"
                                        value={metaKeyword}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setMetaKeyword(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Summmary</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="summary"
                                        type="text"
                                        multiline
                                        rows={3}
                                        placeholder="Enter Title"
                                        value={summary}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setSummary(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Content</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="content"
                                        type="text"
                                        multiline
                                        rows={3}
                                        placeholder="Enter Title"
                                        value={content}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setContent(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Thumbnail</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <input className="input" style={{ width: "100%", margin: "0", padding: "18.5px 14px", border: "1px solid rgba(0, 0, 0, 0.23)", borderRadius: "5px" }} type="file" name="file" id="file" onChange={handleSetThumbnail} />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Header Image</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <input className="input" style={{ width: "100%", margin: "0", padding: "18.5px 14px", border: "1px solid rgba(0, 0, 0, 0.23)", borderRadius: "5px" }} type="file" name="file" id="file" onChange={handleSetHeaderImage} />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Tags</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Autocomplete
                                        id="tags"
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                let tagId: any = []
                                                newValue.map((val: any) => {
                                                    let obj = JSON.parse(JSON.stringify(val, null, " "));
                                                    tagId.push(obj.id)
                                                })
                                                setTags(tagId)
                                            }
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            clearTimeout(timer.current)
                                            timer.current = setTimeout(() => {
                                                getBlogTags(newInputValue)
                                            }, 1000)
                                        }}
                                        multiple
                                        disableClearable
                                        limitTags={2}
                                        options={blogTags.results}
                                        freeSolo
                                        blurOnSelect
                                        getOptionLabel={(option: any) => option.tags}
                                        renderTags={(value: string[], getTagProps) =>
                                            value.map((option: any, index: number) => (
                                                <Chip
                                                    key={index}
                                                    variant="default"
                                                    color="primary"
                                                    label={option.tags}
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Tags"
                                                variant="outlined"
                                                style={{ width: "100%", margin: "0", padding: "0" }}
                                                className="input"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Category</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Autocomplete
                                        id="category"
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                let categoryId: any = []
                                                newValue.map((val: any) => {
                                                    let obj = JSON.parse(JSON.stringify(val, null, " "));
                                                    categoryId.push(obj.id)
                                                })
                                                setCategory(categoryId)
                                            }
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            clearTimeout(timer.current)
                                            timer.current = setTimeout(() => {
                                                getBlogCategory(newInputValue)
                                            }, 1000)
                                        }}
                                        multiple
                                        disableClearable
                                        limitTags={2}
                                        options={blogCategories.results}
                                        freeSolo
                                        blurOnSelect
                                        getOptionLabel={(option: any) => option.category}
                                        renderTags={(value: string[], getTagProps) =>
                                            value.map((option: any, index: number) => (
                                                <Chip
                                                    key={index}
                                                    variant="default"
                                                    color="primary"
                                                    label={option.category}
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Category"
                                                variant="outlined"
                                                style={{ width: "100%", margin: "0", padding: "0" }}
                                                className="input"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Images</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Autocomplete
                                        id="images"
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                let imageIds: any = []
                                                newValue.map((val: any) => {
                                                    let obj = JSON.parse(JSON.stringify(val, null, " "));
                                                    imageIds.push(obj.id)
                                                })
                                                setImages(imageIds)
                                            }
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            clearTimeout(timer.current)
                                            timer.current = setTimeout(() => {
                                                getBlogImages(newInputValue)
                                            }, 1000)
                                        }}
                                        multiple
                                        disableClearable
                                        limitTags={1}
                                        options={blogImages.results}
                                        freeSolo
                                        blurOnSelect
                                        getOptionLabel={(option: any) => option.header_image}
                                        renderTags={(value: string[], getTagProps) =>
                                            value.map((option: any, index: number) => (
                                                <Chip
                                                    key={index}
                                                    variant="default"
                                                    color="primary"
                                                    label={option.header_image}
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Images"
                                                variant="outlined"
                                                style={{ width: "100%", margin: "0", padding: "0" }}
                                                className="input"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        fullWidth
                                        onClick={submitHandler}
                                    >
                                        Create Blog
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
            </main>
        </>
    );
};


const mapStateToProps = (state: any) => ({
    blogCategories: state.SalesLeadAdminReducer.blogCategories,
    blogTags: state.SalesLeadAdminReducer.blogTags,
    blogImages: state.SalesLeadAdminReducer.blogImages,
    loading: state.SalesLeadAdminReducer.loading,
})

export default connect(mapStateToProps, {
    createBlogs,
    getBlogTags,
    getBlogCategory,
    getBlogImages,
})(BlogsPage);