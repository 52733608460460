import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
    withStyles,
    Theme,
    createStyles,
    makeStyles,
} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { getCallStats, getAgentList } from "../actions/salesLeadAdminAction";
import { Grid, TextField, Container} from "@material-ui/core";
import Box from '@mui/material/Box';
import Loader from "../Components/loader";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import { generateLeadsUrl } from "../../helpers/generateUrl";
import Export from "../Components/Export/callStats";

const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,fontWeight:600
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 700,
        },
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
    })
);

interface Props {
    getCallStats: any;
    callStats: any;
    getAgentList: any;
    agentsList: any;
    loading: boolean;
}

const LeadsTable: React.FC<Props> = ({
    getCallStats,
    callStats,
    getAgentList,
    agentsList,
    loading,
}) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [filtered, setFiltered] = useState(false)
    const [createdAt, setCreatedAt] = useState("")
    const [startDate, setStartDate] = useState("")
    const [agent, setAgent] = useState<any>([])

    const timer = useRef<any>(0)

    useEffect(() => {
        getCallStats()
        // getAgentList()
    }, [])

    const history = useHistory()
    const filterLeads = () => {
        const body: any = {
            start_date: startDate,
            created_date: createdAt,
            agent: agent.join(","),
        }
        const url = generateLeadsUrl(body).substring(2)
        getCallStats(`?${url}`)
         setFiltered(true)
        setPage(0)
    }

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <h2>Call Stats</h2>
                <Grid container spacing={1} direction="row" >
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="Created At"
                            type="date"
                            label="Start Date"
                            value={startDate}
                            variant="outlined"
                            onChange={(e) => setStartDate(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="Created At"
                            type="date"
                            label="Recorded Date/End Date"
                            value={createdAt}
                            variant="outlined"
                            onChange={(e) => setCreatedAt(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="agent"
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setAgent(sourceIds)
                            }}
                            options={agentsList?.results||[]}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            getOptionLabel={(option: any) => option?.id && option?.username}
                            getOptionDisabled={(option: any) => {
                                return agent.includes(option.id)
                            }}
                            disableClearable
                            disableCloseOnSelect
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                                    getAgentList(`?code=${newInputValue.replace(" ", "")}&usergroup=CustomerExecutive`);
                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setAgent(0)
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Agent"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={filterLeads}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => history.push("/dashboard/sla/calls/stats")}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <br/>
                {loading ? <Loader /> : filtered && callStats.data && callStats.data.length > 0 && (
                    <Box>
                        <Container maxWidth={false}>
                            <Grid container spacing={3}>
                                <Export loading={loading} callStats={callStats} />
                            </Grid>
                        </Container>
                    </Box>
                )}
                {callStats.data && callStats.data.length === 0 && (
                    <h6 style={{ textAlign: "center" }}>No Data Found</h6>
                )}
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.SalesLeadAdminReducer.loading,
    callStats: state.SalesLeadAdminReducer.callStats,
    agentsList: state.SalesLeadAdminReducer.agentsList,
});

export default connect(mapStateToProps, { getCallStats, getAgentList })(
    LeadsTable
);