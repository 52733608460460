import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Select,
  MenuItem,
  TablePagination,
  TableFooter,
  withStyles,
  Switch,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from '@mui/material/Button';
import "./index.sass";
import { CheckCircleOutlineRounded, Edit } from "@material-ui/icons";
import { connect } from "react-redux";
import { updateCoupon, getCoupons } from "../../actions/HealthAdviserAdminAction";
import Loader from "../loader";
import CreateCouponModal from "./CreateCouponModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    paper: {
      padding: "1rem",
      width: "100%",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    heading: {
      fontWeight: "normal",
      fontSize: "1.2rem",
    },
    actionButton: {
      margin: "0 0.5rem",
      cursor: "pointer",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
const StyledTableCell:any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const DefaultSwitch:any = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: {
    backgroundColor: "red",
  },
})(Switch);

interface Props {
  getCoupons: any;
  couponList: any;
  updateCoupon: any;
  loading: Boolean;
}

const PinCodePage: React.FC<Props> = ({
  getCoupons,
  couponList,
  updateCoupon,
  loading,
}) => {
  const classes = useStyles();
  const [search, setSearch] = useState<String>("");
  const [editCoupon, setEditCoupon] = useState("");
  const [couponCode, setCouponCode] = useState<string | null>(null);
  const [couponName, setCouponName] = useState<string | null>(null);
  const [discountType, setDiscountType] = useState<string>("none");
  const [amount, setAmount] = useState<string | null>(null);
  const [minPrice, setMinPrice] = useState<string | null>(null);
  const [validOnce, setValidOnce] = useState<boolean | string>("none");
  const [userGroup, setUserGroup] = useState<boolean | any>("none");
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);

  const handleSearch = (e: React.ChangeEvent<{ value: unknown }>) => {
    setSearch(e.target.value as string);
    getCoupons(`?code=${e.target.value}`);
  };

  useEffect(() => {
    getCoupons();
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = couponList.links && couponList.links.next.split("?")[1];
      getCoupons(url);
    } else if (newPage < page) {
      let url = couponList.links && couponList.links.previous.split("?")[1];
      getCoupons(url);
    }
    setPage(newPage as number);
  };

  const handleEditCoupon = (coupon: any) => {
    setEditCoupon(coupon.code);
    setCouponName(coupon.name);
    setCouponCode(coupon.code);
    setAmount(coupon.discount);
    setDiscountType(coupon.discount_type);
    setMinPrice(coupon.minimum_price);
    setValidOnce(coupon.valid_once_per_user);
    setUserGroup(coupon.usergroup.map((data: any) => data.name).join(","));
  };

  const handleSubmit = async (coupon: any) => {
    setEditCoupon("");
    const body: any = {
      name: couponName,
      code: couponCode,
      discount: amount,
      minimum_price: minPrice,
      discount_type: discountType,
      valid_once_per_user: Boolean(validOnce),
      usergroup: userGroup.join(","),
    };
    await updateCoupon(coupon.code, body);
    getCoupons();
  };

  const disableCoupons = async (coupon: any) => {
    const body: any = {
      is_active: !coupon.is_active,
    };
    await updateCoupon(coupon.code, body);
    getCoupons();
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
          <Grid container spacing={3} style={{ margin: "1rem auto" }}>
            <Grid item xs={12} md={4}>
              <h2>Manage Coupons</h2>
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={8}
              direction="row"
              alignItems="center"
              justify="flex-end"
            >
              <TextField
                id="search"
                type="search"
                value={search}
                className="input"
                variant="outlined"
                placeholder="Search"
                onChange={(e) => handleSearch(e)}
                style={{ margin: "0" }}
              />
              <Button
                variant="contained"
                color="primary"
                style={{ marginLeft: "1rem" }}
                onClick={() => setOpen(true)}
              >
                Add Coupon
              </Button>
            </Grid>
          </Grid>
          {loading ? (
            <Loader />
          ) : (
            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "560px" }}>
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">Coupon Name</StyledTableCell>
                    <StyledTableCell align="center">Coupon Code</StyledTableCell>
                    <StyledTableCell align="center">Min Price</StyledTableCell>
                    <StyledTableCell align="center">Type</StyledTableCell>
                    <StyledTableCell align="center">Discount</StyledTableCell>
                    <StyledTableCell align="center">Valid once per user</StyledTableCell>
                    <StyledTableCell align="center">User Group</StyledTableCell>
                    <StyledTableCell align="center">Active</StyledTableCell>
                    {/* <TableCell align="center">Edit</TableCell> */}
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {couponList.results &&
                    couponList.results.length > 0 &&
                    couponList.results.map((code: any, index: any) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell align="center">
                            {editCoupon === code.code ? (
                              <TextField
                                name="coupon"
                                type="text"
                                value={
                                  couponName !== null ? couponName : code.name
                                }
                                className="input"
                                variant="outlined"
                                placeholder="Coupon"
                                onChange={(e) =>
                                  setCouponName(e.target.value as string)
                                }
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  minWidth: "200px",
                                }}
                              />
                            ) : (
                              code.name
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editCoupon === code.code ? (
                              <TextField
                                name="coupon"
                                type="text"
                                value={
                                  couponCode !== null ? couponCode : code.code
                                }
                                className="input"
                                variant="outlined"
                                placeholder="Coupon"
                                onChange={(e) =>
                                  setCouponCode(e.target.value as string)
                                }
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  minWidth: "200px",
                                }}
                              />
                            ) : (
                              code.code
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editCoupon === code.code ? (
                              <TextField
                                name="area"
                                type="text"
                                value={
                                  minPrice !== null
                                    ? minPrice
                                    : code.minimum_price
                                }
                                className="input"
                                variant="outlined"
                                placeholder="Min Price"
                                onChange={(e) =>
                                  setMinPrice(e.target.value as string)
                                }
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  minWidth: "200px",
                                }}
                              />
                            ) : (
                              code.minimum_price
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editCoupon === code.code ? (
                              <Select
                                className="input"
                                name="validOnce"
                                variant="outlined"
                                value={
                                  discountType !== "none"
                                    ? discountType
                                    : code.discount_type
                                }
                                style={{
                                  width: "100%",
                                  textAlign: "left",
                                  minWidth: "200px",
                                }}
                                onChange={(e: any) =>
                                  setDiscountType(e.target.value as string)
                                }
                              >
                                <MenuItem disabled value={"none"}>
                                  Discount Type
                                </MenuItem>
                                <MenuItem value={"amount"}>Amount</MenuItem>
                                <MenuItem value={"percentage"}>
                                  Percentage
                                </MenuItem>
                              </Select>
                            ) : (
                              code.discount_type
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editCoupon === code.code ? (
                              <TextField
                                name="city"
                                type="text"
                                value={amount !== null ? amount : code.discount}
                                className="input"
                                variant="outlined"
                                placeholder="Discount"
                                onChange={(e) =>
                                  setAmount(e.target.value as string)
                                }
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  minWidth: "200px",
                                }}
                              />
                            ) : (
                              code.discount
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {editCoupon === code.code ? (
                              <Select
                                className="input"
                                name="validOnce"
                                variant="outlined"
                                value={
                                  validOnce !== "none"
                                    ? validOnce
                                    : code.valid_once_per_user.toString()
                                }
                                style={{
                                  width: "100%",
                                  textAlign: "left",
                                  minWidth: "200px",
                                }}
                                onChange={(e: any) =>
                                  setValidOnce(e.target.value as string)
                                }
                              >
                                <MenuItem disabled value={"none"}>
                                  Valid Once
                                </MenuItem>
                                <MenuItem value={"true"}>Yes</MenuItem>
                                <MenuItem value={"false"}>No</MenuItem>
                              </Select>
                            ) : code.valid_once_per_user ? (
                              "Yes"
                            ) : (
                              "No"
                            )}
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            {editCoupon === code.code ? (
                              <Autocomplete
                                id="city"
                                onChange={(event, newValue) => {
                                  let sourceIds: any = [];
                                  if (newValue) {
                                    let obj = JSON.parse(
                                      JSON.stringify(newValue, null, " ")
                                    );
                                    sourceIds = obj.map(
                                      (item: any) => item.value
                                    );
                                  }
                                  setUserGroup(sourceIds);
                                }}
                                options={[
                                  {
                                    text: "CustomerLead",
                                    value: "CustomerLead",
                                  },
                                  {
                                    text: "SupportExecutive",
                                    value: "SupportExecutive",
                                  },
                                  {
                                    text: "CustomerExecutive",
                                    value: "CustomerExecutive",
                                  },
                                  {
                                    text: "SampleAccessioning",
                                    value: "SampleAccessioning",
                                  },
                                  {
                                    text: "VerificationExecutive",
                                    value: "VerificationExecutive",
                                  },
                                  {
                                    text: "PhleboAdmin",
                                    value: "PhleboAdmin",
                                  },
                                  {
                                    text: "Finance",
                                    value: "Finance",
                                  },
                                  {
                                    text: "Phlebos",
                                    value: "Phlebos",
                                  },
                                  {
                                    text: "SalesLead",
                                    value: "SalesLead",
                                  },
                                ]}
                                freeSolo
                                blurOnSelect
                                aria-required
                                limitTags={1}
                                multiple
                                getOptionLabel={(option: any) => option.text}
                                getOptionDisabled={(option: any) => {
                                  return userGroup.includes(option.value);
                                }}
                                disableClearable
                                disableCloseOnSelect
                                renderInput={(params) => (
                                  <TextField
                                    className="input"
                                    {...params}
                                    placeholder="User Group"
                                    variant="outlined"
                                    style={{
                                      width: "100%",
                                      margin: "0",
                                      padding: "0",
                                    }}
                                  />
                                )}
                              />
                            ) : (
                              code.usergroup
                                .map((data: any) => data.name)
                                .join(",")
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <DefaultSwitch
                              checked={code.is_active}
                              name="checkedA"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                              disableRipple
                              onClick={() => disableCoupons(code)}
                            />
                          </StyledTableCell>
                          {/* <TableCell align="center">
                            {editCoupon === code.code ? (
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => handleSubmit(code)}
                              >
                                <CheckCircleOutlineRounded />
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                variant="contained"
                                onClick={() => handleEditCoupon(code)}
                              >
                                <Edit />
                              </Button>
                            )}
                          </TableCell> */}
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <StyledTableRow>
                    <TablePagination
                      rowsPerPageOptions={[]}
                      colSpan={3}
                      count={couponList.count || 0}
                      rowsPerPage={couponList.page_size}
                      page={0}
                      onPageChange={handleChangePage}
                    />
                  </StyledTableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
          {couponList && couponList.length === 0 && (
            <h3 className={classes.heading} style={{ textAlign: "center" }}>
              No Data Found
            </h3>
          )}
        <CreateCouponModal open={open} setOpen={setOpen} />
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  couponList: state.HealthAdviserAdminReducer.couponList,
  loading: state.HealthAdviserAdminReducer.loading,
});

export default connect(mapStateToProps, {
  getCoupons,
  updateCoupon,
})(PinCodePage);
