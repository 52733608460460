import React, { useState } from "react";
import { useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Paper,
  TextField,
  Grid,
  Select,
  MenuItem,
  TableContainer,
  Slider,
  Chip,
  withStyles,
  Dialog,
} from "@material-ui/core";
import Button from '@mui/material/Button';
import { Editor, OriginalTools } from "react-bootstrap-editor";
import { useEffect } from "react";
import {
  getPinCode,
  createSubCenter,
  getCenterInfo,
  getSalesManager,
  getSubCenterInfo,
  checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken,
  checkGeoFenceArea,
  updateSubCentre,
  disabledSubCenter,
} from "../../../actions/ImagingAdminAction";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import { useHistory } from "react-router-dom";
import Switch from "@mui/material/Switch";
import moment from "moment";
import Loader from "../../../Components/loader/index";
import Radio from "@material-ui/core/Radio";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link } from "react-router-dom";
import { CompareArrowsOutlined, Edit } from "@material-ui/icons";
import { TableFooter, TablePagination } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { getCenterFilter } from "../../../../helpers/generateUrl";
import { ACTIONS } from "../../../interfaces/actionTypes/ImagingAdminType";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import "../index.sass";
import { StringDecoder } from "string_decoder";
import Geocode from "react-geocode";
Geocode.setApiKey("AIzaSyBPjDlsHYhWtjt3FxgRPnTMXN-qJZ9hz0s");

Geocode.enableDebug();
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#3f51b5",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    table: {
      minWidth: 700,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
      minHeight: "64px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    hoveItem: {
      cursor: "pointer",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    centerIt: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

const PrettoSlider: any = withStyles({
  root: {
    color: "rgb(146, 74, 145)",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "violet",
    border: "2px solid purple",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
const DefaultSwitch: any = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: {
    backgroundColor: "red",
  },
})(Switch);

interface Props {
  // getCentreCode: any;
  // centreCode: any;
  getCenterInfo: any;
  getPinCode: any;
  centerInfo: any;
  pin_code: any;
  createSubCenter: any;
  getSalesManager: any;
  salesManager: any;
  mapmyindiaAccessToken: any;
  getMapMyIndiaAccessToken: any;
  checkGeoFenchMapMyIndia: any;
  geoFencesMapMyIndia: any;
  getSubCenterInfo: any;
  subCenterInfor: any;
  disabledSubCenter: any;
  loading: any;
  updateSubCentre: any;
}
const CreateCentrePage: React.FC<Props> = ({
  getPinCode,
  pin_code,
  createSubCenter,
  getSalesManager,
  salesManager,
  getSubCenterInfo,
  subCenterInfor,
  updateSubCentre,
  checkGeoFenchMapMyIndia,
  geoFencesMapMyIndia,
  getMapMyIndiaAccessToken,
  mapmyindiaAccessToken,
  disabledSubCenter,
  loading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  let dispatch = useDispatch();
  


  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);



  const [area, setArea] = React.useState<String>("");




  const [centerId, setCenterId] = useState<any>("");

  
     

  useEffect(() => {

    getSubCenterInfo(`?is_active=false`);
  
  }, []);


  

  const filterCenter = async () => {
    const body: any = {
      center: centerId,
    };
    const url = getCenterFilter(body).substring(2);
    // setCurrentUrl(url);
    getSubCenterInfo(`?imaging=true&${url}`);
  };

  const DisableSubCenterAddress = async (pack: any) => {
    const body: any = {
      is_active: !pack.is_active,
    };

    

    await disabledSubCenter(pack.id, body);
    history.push("/dashboard/ia/center_deactivate");
  };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = subCenterInfor.links && subCenterInfor.links.next.split("?")[1];
      getSubCenterInfo(`?${url}`);
    } else if (newPage < page) {
      let url =
        subCenterInfor.links && subCenterInfor.links.previous.split("?")[1];
      getSubCenterInfo(`${url == undefined ? "" : "?" + url}`);
    }
    setPage(newPage);
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Paper elevation={5} className={classes.paper}>
        <div
          className="data-table"
          style={{ width: "100%", marginTop: "1rem" }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            style={{ alignItems: "right" }}
          >
            <Grid item xs={12} sm={6} md={3}>
              <Autocomplete
                id="center"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setCenterId(obj?.center?.id);
                  }
                }}
                options={subCenterInfor.length > 0 ? subCenterInfor : []}
                freeSolo
                blurOnSelect
                aria-required
                limitTags={1}
                getOptionLabel={(option: any) =>
                  `Center:${option?.center?.name},Address:${option?.address}`
                }
                disableClearable
                disableCloseOnSelect
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getSubCenterInfo(`?imaging=true&name=${newInputValue}`);
                  }, 1000);
                  if (newInputValue.length === 0) {
                    setCenterId("");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="SUB CENTRE NAME"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <Button
                variant="contained"
                color="secondary"
               
                fullWidth
                onClick={filterCenter}
                disabled={centerId === ""}
              >
                Filter
              </Button>
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <Button
                variant="contained"
                color="secondary"
             
                fullWidth
                onClick={() => history.push("/dashboard/ia/subcenter_deactivate")}
              >
                Reset
              </Button>
            </Grid>
          </Grid>

          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "515px" }}
          >
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Centre Name</StyledTableCell>
                  <StyledTableCell align="center">Email</StyledTableCell>
                  <StyledTableCell align="center">Phone</StyledTableCell>
                  <StyledTableCell align="center">
                    Center Accreditation
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Google Location{" "}
                  </StyledTableCell>
                  <StyledTableCell align="center">Location</StyledTableCell>

                  <StyledTableCell align="center">
                    Deactivate Sub Center{" "}
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {subCenterInfor &&
                  subCenterInfor.length > 0 &&
                  subCenterInfor.map((data: any, index: any) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">
                          {" "}
                          {data?.center.name}{" "}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {" "}
                          {data?.center_email}{" "}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {" "}
                          {data?.contact_number}{" "}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {" "}
                          {data?.center_accreditation}{" "}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {" "}
                          {data?.google_location}{" "}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {" "}
                          {/* {data?.location?.area},{data?.location?.city_name},
                          {data?.location?.pincode}{" "} */}
                          {data?.address},
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          <DefaultSwitch
                            checked={data.is_active}
                            name="checkedA"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                            disableRipple
                            onClick={() => DisableSubCenterAddress(data)}
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TablePagination
                  colSpan={6}
                  count={subCenterInfor.count || 0}
                  rowsPerPageOptions={[]}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </Paper>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  pin_code: state.ImagingAdminReducer.pin_code,
  subCenterInfor: state.ImagingAdminReducer.subCenterInfor,
  salesManager: state.ImagingAdminReducer.salesManager,
  checkGeoFenceStatus: state.ImagingAdminReducer.checkGeoFenceStatus,
  geoFencesMapMyIndia: state.ImagingAdminReducer.geoFencesMapMyIndia,
  loading: state.ImagingAdminReducer.loading,
  mapmyindiaAccessToken: state.ImagingAdminReducer.mapmyindiaAccessToken,
});
export default connect(mapStateToProps, {
  getPinCode,
  getSalesManager,
  createSubCenter,
  getSubCenterInfo,
  updateSubCentre,
  checkGeoFenceArea,
  checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken,
  disabledSubCenter,
})(CreateCentrePage);
