import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { MenuItem, Select, Button } from "@material-ui/core";
// import "./Tableform.css";
import image from "../../../../images/logo1.png";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import {
  getBiomarkerScreening,
  getBookingById,
  getBiomarkerUltasound,
  getAdditionalMember,
  resetBiomarker,
  resetBookings,
} from "../../../actions/bookingActions";
import { savePDF } from "@progress/kendo-react-pdf";
import ReactToPrint from "react-to-print";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { useHistory } from "react-router";
import {
  GridColumnHeadersItemCollection,
  useGridControlState,
} from "@material-ui/data-grid";

const useStyles = makeStyles((theme) =>
  createStyles({
    pageBreak: {
      pageBreakAfter: "always",
    },
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: "none",
    },
    rightContentHeader: {
      width: "88%",
      float: "left",
      textAlign: "left",
      paddingRight: "20px",
    },
  })
);

interface Props {
  match: any;
  getBiomarkerScreening: any;
  biomarkerScreening: any;
  loading: Boolean;
  booking: any;
  getBookingById: any;
  getBiomarkerUltasound: any;
  biomarkerUltasound: any;
  getAdditionalMember: any;
  markerAdditionMember: any;
  resetBiomarker: any;
  resetBookings: any;
}

const BiomarkerScreeningTestRequistionFrom: React.FC<Props> = ({
  match,
  booking,
  getBookingById,
  getBiomarkerScreening,
  biomarkerScreening,
  loading,
  getBiomarkerUltasound,
  biomarkerUltasound,
  getAdditionalMember,
  markerAdditionMember,
  resetBiomarker,
  resetBookings,
}) => {
  const booking_id = match.params.id;

  const classes = useStyles();
  const history = useHistory();
  const [personName, setPersonName] = useState<any>("main");
  const [usg2Codes, setUsg2Codes] = React.useState([
    "BC462",
    "BC448",
    "RGFTS-005",
    "RGFTS-004",
    "RGFTS-003",
    "RGFTS-002",
    "RGFTS-001",
    "BC138",
    "BC137",
  ]);
  const [data, setData] = useState<any>({});
  const [dataUSG1, setDataUSG1] = useState<any>("");
  const [dataUSG2, setDataUSG2] = useState<any>("");
  const [state, setState] = useState<any>({});
  const [isUSG1, setIsUSG1] = useState<any>("");
  const [gravidaData, setGravidaData] = useState<boolean>(false);
  const [paraData, setParaData] = useState<boolean>(false);
  const [abortionData, setAbortionData] = useState<boolean>(false);
  const [liveData, setLiveData] = useState<boolean>(false);
  const [naturalData, setNaturalData] = useState<boolean>(false);
  const [assistedData, setAssistedData] = useState<boolean>(false);
  const [sselfData, setSelfData] = useState<boolean>(false);
  const [donorData, setDonorData] = useState<boolean>(false);
  const [spermData, setSpermData] = useState<boolean>(false);
  const [packageCodes, setPackageCodes] = useState<any>([]);

  const bookingId = match.params.id;
  const componentRef = useRef<any>(null);
  useEffect(() => {
    resetBiomarker();
    resetBookings();
  }, []);
  useEffect(() => {
    if (booking_id) {
      getBiomarkerScreening(`booking_id=${bookingId}`);
      getBookingById(`${booking_id}/`);
      // getAdditionalMember(booking_id);
      // getBiomarkerUltasound(booking_id)
    }
  }, [booking_id]);

  const handleChange = (event: any) => {
    setPersonName(event.target.value);
    setState({ state, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    if (
      biomarkerScreening &&
      biomarkerScreening?.results &&
      biomarkerScreening?.results.length > 0
    ) {
      setData(biomarkerScreening.results[0]);
      setGravidaData(biomarkerScreening.results[0].gravida);
      setParaData(biomarkerScreening.results[0].para);
      setAbortionData(biomarkerScreening.results[0].abortion);
      setLiveData(biomarkerScreening.results[0].live);
      setNaturalData(biomarkerScreening.results[0].natural);
      setAssistedData(biomarkerScreening.results[0].assisted);
      setSelfData(biomarkerScreening.results[0].sself);
      setDonorData(biomarkerScreening.results[0].donor);
      setSpermData(biomarkerScreening.results[0].sperm);
    } else if (
      biomarkerScreening &&
      biomarkerScreening?.results &&
      biomarkerScreening?.results.length === 0
    ) {
      setData("");
    }
  }, [biomarkerScreening]);

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);
  const reactToPrintTrigger = React.useCallback(() => {
    return <button style={{ marginLeft: "10px" }}>Print Receipt</button>;
  }, []);

  useEffect(() => {
    if (
      personName === "main" &&
      booking_id !== undefined &&
      booking_id !== ""
    ) {
      resetBiomarker();
      getBiomarkerScreening(`booking_id=${bookingId}`);
    } else {
      resetBiomarker();
      getBiomarkerScreening(`add_member_booking=${personName}`);
    }
  }, [personName]);
  useEffect(() => {
    if (
      booking &&
      booking?.packages &&
      booking?.packages?.length > 0 &&
      personName === "main"
    ) {
      setPackageCodes(booking.packages.map((val: any) => val.code));
    } else if (
      booking &&
      booking?.additional_members &&
      booking?.additional_members?.length > 0 &&
      personName !== "main"
    ) {
      booking?.additional_members.map((data: any) => {
        if (data.id === personName) {
          setPackageCodes(data.packages.map((val: any) => val.code));
        }
      });
    }
  }, [booking, personName]);
  useEffect(() => {
    if (
      packageCodes &&
      packageCodes.length > 0 &&
      usg2Codes.filter((value) => packageCodes.includes(value)).length > 0
    ) {
      setIsUSG1(true);
    } else if (
      packageCodes &&
      packageCodes.length > 0 &&
      usg2Codes.filter((value) => packageCodes.includes(value)).length === 0
    ) {
      setIsUSG1(false);
    }
  }, [packageCodes]);
  useEffect(() => {
    data &&
      data.ultrasounddetails &&
      data.ultrasounddetails.length > 0 &&
      data.ultrasounddetails.map((val: any) => {
        if (val.type === "1st_trimester") {
          setDataUSG1(val);
        } else if (val.type === "2nd_trimester") {
          setDataUSG2(val);
        } else {
          setDataUSG2("");
          setDataUSG1("");
        }
      });
  }, [data]);

  return (
    <>
      <div>
        <div style={{ height: "20px", marginTop: "35%" }}>
          <div className={classes.rightContentHeader}>
            <Select
              className="input"
              name="priority"
              variant="outlined"
              style={{
                width: "100%",
                marginLeft: "1rem",
                marginBottom: "1rem",
                alignItems: "flex-start",
              }}
              value={personName}
              onChange={(e) => setPersonName(e.target.value)}
            >
              {/* <MenuItem disabled selected value={'none'}>Customer Name</MenuItem> */}
              <MenuItem value={"main"}>{booking?.customer_name}</MenuItem>
              {booking?.additional_members &&
                booking?.additional_members.length > 0 &&
                booking?.additional_members.map((data: any) => {
                  return (
                    <MenuItem value={data?.id}>{data?.customer_name}</MenuItem>
                  );
                })}
            </Select>
            <ReactToPrint
              content={reactToPrintContent}
              trigger={reactToPrintTrigger}
            />
          </div>
        </div>
      </div>
      {loading ? (
        <h4 style={{ margin: "60px auto" }}>Loading...</h4>
      ) : data === "" ? (
        <h3 style={{ margin: "50px auto" }}>
          Double Marker Form is not available for the selected patient.
        </h3>
      ) : (
        <div style={{ width: 1000, display: "block" }} ref={componentRef}>
          <div
            style={{
              display: "block",
              height: 1600,
              width: "100%",
              background: "#f1f1f1",
              padding: "15px",
              margin: "10px auto",
            }}
          >
            <div style={{ textAlign: "center", boxSizing: "border-box" }}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  background: "#AD70A6",
                  marginTop: "52px",
                }}
              >
                <div style={{ width: "50%", textAlign: "left", padding: 15 }}>
                  <h3>
                    <img src={image} alt="logo" style={{ width: "150px" }} />
                  </h3>
                </div>
                <div style={{ width: "50%", textAlign: "right", padding: 15 }}>
                  <div
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "25px",
                    }}
                  >
                    DOUBLE MARKER FORM
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                }}
              >
                <div style={{ width: "50%" }}>
                  <h6
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: "30px",
                      marginTop: "100px",
                      marginBottom: "35px",
                    }}
                  >
                    PATIENT DETAILS
                  </h6>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <label
                        style={{
                          marginLeft: "-291px",
                          lineHeight: "1",
                          fontSize: "25px",
                        }}
                      >
                        Customer Name
                      </label>
                      <input
                        type="input"
                        style={{
                          width: "95%",
                          height: "40px",
                          display: "block",
                          marginLeft: "7px",
                        }}
                        value={
                          personName === "main"
                            ? data?.booking?.customer_name
                            : data?.add_member_booking?.customer_name
                        }
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: "7px",
                    }}
                  >
                    <div style={{ width: "49%" }}>
                      <label
                        style={{
                          marginLeft: "-164px",
                          lineHeight: "1",
                          fontSize: "25px",
                        }}
                      >
                        DOB
                      </label>
                      <input
                        type="input"
                        style={{
                          marginRight: "100px",
                          height: "40px",
                          width: "95%",
                          display: "block",
                          marginLeft: "7px",
                        }}
                        value={data?.dob}
                      />
                    </div>
                    <div style={{ width: "49%" }}>
                      <label
                        style={{
                          marginLeft: "-171px",
                          lineHeight: "1",
                          fontSize: "25px",
                        }}
                      >
                        Age
                      </label>
                      <input
                        type="input"
                        style={{
                          width: "95%",
                          height: "40px",
                          display: "block",
                          marginLeft: "7px",
                        }}
                        value={
                          personName === "main"
                            ? data?.booking?.customer_age
                            : data?.add_member_booking?.customer_age
                        }
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: "7px",
                    }}
                  >
                    <div style={{ width: "33%" }}>
                      <label
                        style={{
                          marginLeft: "-80px",
                          lineHeight: "1",
                          fontSize: "25px",
                        }}
                      >
                        Height
                      </label>
                      <input
                        type="input"
                        style={{
                          width: "95%",
                          height: "40px",
                          display: "block",
                          marginLeft: "7px",
                        }}
                        value={
                          data?.height?.slice(0, 1) +
                          " ft " +
                          data?.height?.slice(2, 3) +
                          " inch "
                        }
                      />
                    </div>
                    <div style={{ width: "33%" }}>
                      <label
                        style={{
                          marginLeft: "-64px",
                          lineHeight: "1",
                          fontSize: "25px",
                        }}
                      >
                        Weight
                      </label>
                      <input
                        type="input"
                        style={{
                          width: "90%",
                          height: "40px",
                          display: "flex",
                          marginLeft: "7px",
                        }}
                        value={data?.weight + " KG "}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: "7px",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <label
                        style={{
                          marginRight: "500px",
                          lineHeight: "1",
                          fontSize: "25px",
                        }}
                      >
                        Address
                      </label>
                      <input
                        type="input"
                        style={{
                          width: "95%",
                          height: "40px",
                          display: "block",
                          marginLeft: "7px",
                        }}
                        // value={personName === "main" ? data?.booking?.customer_areapincode?.area : data?.add_member_booking?.customer_areapincode}
                        value={
                          personName === "main"
                            ? data?.booking?.customer_landmark
                            : data?.add_member_booking?.customer_areapincode
                        }
                      />
                    </div>
                  </div>
                  <h6
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: "30px",
                      marginTop: "15px",
                    }}
                  >
                    PREGNANCY HISTORY DETAILS
                  </h6>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      fontSize: "25px",
                      marginRight: "100px",
                    }}
                  >
                    <p>PARITY:</p>
                  </div>
                  <div>
                    <div>
                      <FormGroup row>
                        <FormControlLabel
                          control={<Checkbox checked={gravidaData} />}
                          label="Gravida"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={paraData}
                              onChange={handleChange}
                            />
                          }
                          label="Para"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={abortionData}
                              onChange={handleChange}
                            />
                          }
                          label="Abortion"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={liveData}
                              onChange={handleChange}
                            />
                          }
                          label=" Live"
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      fontSize: "25px",
                    }}
                  >
                    <p>LMP DATE</p>
                    <div style={{ width: "30%", height: "10px" }}>
                      <input
                        type="input"
                        style={{
                          width: "95%",
                          display: "block",
                          marginLeft: "5px",
                        }}
                        value={data?.lmp_date}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      fontSize: "25px",
                      marginRight: "100px",
                    }}
                  >
                    {/* <p>Nature of Conception:</p> */}
                    <p>Conception Details:</p>
                  </div>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={naturalData}
                          onChange={handleChange}
                        />
                      }
                      label="Natural"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={assistedData}
                          onChange={handleChange}
                        />
                      }
                      label="Assisted"
                    />
                  </FormGroup>

                  <div>
                    {naturalData !== true ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            fontSize: "25px",
                            marginRight: "100px",
                          }}
                        >
                          <p>PROCEDURE TYPE:</p>
                          <div>
                            <div>
                              <FormGroup row>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        data?.procedure_type === "ivf"
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                    />
                                  }
                                  label="IVF"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        data?.procedure_type === "icsi"
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                    />
                                  }
                                  label="ICSI"
                                />
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={
                                        data?.procedure_type === "iui"
                                          ? true
                                          : false
                                      }
                                      onChange={handleChange}
                                    />
                                  }
                                  label="IUI"
                                />
                              </FormGroup>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                  </div>

                  {naturalData !== true ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          fontSize: "25px",
                          marginRight: "100px",
                        }}
                      >
                        <p>If Assisted reproduction:</p>
                      </div>
                      <div>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={sselfData}
                                onChange={handleChange}
                              />
                            }
                            label="Self"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={donorData}
                                onChange={handleChange}
                              />
                            }
                            label="Donor"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={spermData}
                                onChange={handleChange}
                              />
                            }
                            label="Sperm"
                          />
                        </FormGroup>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      fontSize: "25px",
                      marginRight: "100px",
                    }}
                  >
                    <p>PREGNANCY PRESENT:</p>
                  </div>
                  <div>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              data?.present_pregenancy === "singleton"
                                ? true
                                : false
                            }
                            onChange={handleChange}
                          />
                        }
                        label="Singleton"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              data?.present_pregenancy === "twin" ? true : false
                            }
                            onChange={handleChange}
                          />
                        }
                        label="Twin"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={data?.prev_baby ? true : false}
                            onChange={handleChange}
                          />
                        }
                        label="Previous Baby with T21/T18/T13/ONTD"
                      />
                      <FormControlLabel
                        label="Family History of T21/T18/T13/ONTD:"
                        control={
                          <Checkbox
                            checked={data?.family_history ? true : false}
                            onChange={handleChange}
                          />
                        }
                      />
                    </FormGroup>
                    <div></div>
                  </div>
                </div>

                <div style={{ width: "50%" }}>
                  <h6
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: "30px",
                      marginTop: "100px",
                      marginBottom: "35px",
                    }}
                  >
                    CLINICIAN DETAILS
                  </h6>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <label
                        style={{
                          marginLeft: "-320px",
                          lineHeight: "1",
                          fontSize: "25px",
                        }}
                      >
                        Doctor Name
                      </label>
                      <input
                        type="input"
                        style={{
                          width: "95%",
                          display: "block",
                          height: "40px",
                          marginLeft: "7px",
                        }}
                        value={data?.clinician_name}
                      />
                    </div>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: "7px",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <label
                        style={{
                          marginLeft: "-270px",
                          lineHeight: "1",
                          fontSize: "25px",
                        }}
                      >
                        Doctor Mobile No.
                      </label>
                      <input
                        type="input"
                        style={{
                          width: "95%",
                          height: "40px",
                          display: "block",
                          marginLeft: "7px",
                        }}
                        value={data?.mobile}
                      />
                    </div>
                  </div>

                  <h6
                    style={{
                      textAlign: "left",
                      fontWeight: "bold",
                      fontSize: "30px",
                      marginTop: "170px",
                    }}
                  >
                    ULTRASOUND DETAILS
                  </h6>
                  {/* <div
                  style={{
                    display: "flex",
                    width: "100%",
                    fontSize: "25px",
                    marginTop: "15px",
                  }}
                >
                  <p>Date of USG</p>
                  <div style={{ width: "30%", height: "10px" }}>
                    <input
                      type="input"
                      style={{
                        width: "95%",
                        display: "block",
                        marginLeft: "7px",
                      }}
                      value={data?.date_of_usg}
                    />
                  </div>
                </div> */}

                  {/* {data?.present_pregenancy === "singleton" ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <p>CRL:</p>
                      <div
                        style={{
                          width: "30%",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <input
                          type="input"
                          style={{
                            width: 60,
                            height: 20,
                            marginLeft: "7px",
                          }}
                          value={
                            data &&
                            data.ultrasounddetails &&
                            data?.ultrasounddetails[0]?.crl
                          }
                        />{" "}
                        <p>mm</p>
                      </div>
                      <p>NT:</p>
                      <div
                        style={{
                          width: "30%",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <input
                          type="input"
                          style={{
                            width: 60,
                            height: 20,
                            marginLeft: "5px",
                          }}
                          value={
                            data &&
                            data.ultrasounddetails &&
                            data?.ultrasounddetails[0]?.nt
                          }
                        />{" "}
                        <p>mm</p>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        marginTop: "10px",
                      }}
                    >
                      <p>BPD:</p>
                      <div
                        style={{
                          width: "30%",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <input
                          type="input"
                          style={{
                            width: 60,
                            height: 20,
                            marginLeft: "5px",
                          }}
                          value={
                            data &&
                            data.ultrasounddetails &&
                            data?.ultrasounddetails[0]?.bpd
                          }
                        />{" "}
                        <p>mm</p>
                      </div>
                      <p>AC:</p>
                      <div
                        style={{
                          width: "30%",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <input
                          type="input"
                          style={{
                            width: 60,
                            height: 20,
                            marginLeft: "5px",
                          }}
                          value={
                            data &&
                            data.ultrasounddetails &&
                            data?.ultrasounddetails[0]?.ac
                          }
                        />{" "}
                        <p>mm</p>
                      </div>



                      <p>Nasbal Bone(NB):</p>
                      <div
                        style={{
                          width: "30%",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <input
                          type="input"
                          style={{
                            width: 60,
                            height: 20,
                            marginLeft: "5px",
                          }}
                          value={
                            data &&
                            data?.ultrasounddetails &&
                            data?.ultrasounddetails[0]?.nb
                          }
                        />
                        <p>mm</p>
                      </div>


                      <p>HC</p>
                      <div
                        style={{
                          width: "30%",
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <input
                          type="input"
                          style={{
                            width: 60,
                            height: 20,
                            marginLeft: "5px",
                          }}
                          value={
                            data &&
                            data.ultrasounddetails &&
                            data?.ultrasounddetails[0]?.hc
                          }
                        />
                        <p>mm</p>
                      </div>

                    </div>
                  </div> 

                ) : (
                   <>
                   */}
                  {isUSG1 === true ? (
                    <>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            fontSize: "25px",
                            marginTop: "15px",
                          }}
                        >
                          {/* <p>TWIN A</p> */}
                          <p>1 st Trimester USG Details</p>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            fontSize: "25px",
                          }}
                        >
                          <p>USG DATE</p>
                          <div style={{ width: "30%", height: "10px" }}>
                            <input
                              type="input"
                              style={{
                                width: "95%",
                                display: "block",
                                marginLeft: "5px",
                              }}
                              value={data?.date_of_usg}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            marginTop: "10px",
                          }}
                        >
                          <p>CRL:</p>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="input"
                              style={{
                                width: 60,
                                height: 20,
                                marginLeft: "5px",
                              }}
                              value={dataUSG1?.crl}
                            />{" "}
                            <p>mm</p>
                          </div>
                          <p>NT:</p>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="input"
                              style={{
                                width: 60,
                                height: 20,
                                marginLeft: "5px",
                              }}
                              value={dataUSG1?.nt}
                            />{" "}
                            <p>mm</p>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            marginTop: "10px",
                          }}
                        >
                          <p>BPD:</p>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="input"
                              style={{
                                width: 60,
                                height: 20,
                                marginLeft: "5px",
                              }}
                              value={dataUSG1?.bpd}
                            />{" "}
                            <p>mm</p>
                          </div>
                          <p>AC:</p>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="input"
                              style={{
                                width: 60,
                                height: 20,
                                marginLeft: "5px",
                              }}
                              value={dataUSG1?.ac}
                            />{" "}
                            <p>mm</p>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            marginTop: "10px",
                          }}
                        >
                          <p>NB:</p>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="input"
                              style={{
                                width: 60,
                                height: 20,
                                marginLeft: "5px",
                              }}
                              value={dataUSG1?.nb}
                            />
                            <p>mm</p>
                          </div>
                          <p>HC:</p>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="input"
                              style={{
                                width: 60,
                                height: 20,
                                marginLeft: "5px",
                              }}
                              value={dataUSG1?.hc}
                            />
                            <p>mm</p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            fontSize: "25px",
                            marginTop: "15px",
                          }}
                        >
                          {/* <p>TWIN A</p> */}
                          <p>1 st Trimester USG Details</p>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            fontSize: "25px",
                          }}
                        >
                          <p>USG DATE</p>
                          <div style={{ width: "30%", height: "10px" }}>
                            <input
                              type="input"
                              style={{
                                width: "95%",
                                display: "block",
                                marginLeft: "5px",
                              }}
                              value={data?.date_of_usg}
                            />
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            marginTop: "10px",
                          }}
                        >
                          <p>CRL:</p>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="input"
                              style={{
                                width: 60,
                                height: 20,
                                marginLeft: "5px",
                              }}
                              value={dataUSG1?.crl}
                            />{" "}
                            <p>mm</p>
                          </div>
                          <p>NT:</p>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="input"
                              style={{
                                width: 60,
                                height: 20,
                                marginLeft: "5px",
                              }}
                              value={dataUSG1?.nt}
                            />{" "}
                            <p>mm</p>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            marginTop: "10px",
                          }}
                        >
                          <p>BPD:</p>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="input"
                              style={{
                                width: 60,
                                height: 20,
                                marginLeft: "5px",
                              }}
                              value={dataUSG1?.bpd}
                            />{" "}
                            <p>mm</p>
                          </div>
                          <p>AC:</p>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="input"
                              style={{
                                width: 60,
                                height: 20,
                                marginLeft: "5px",
                              }}
                              value={dataUSG1?.ac}
                            />{" "}
                            <p>mm</p>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            marginTop: "10px",
                          }}
                        >
                          <p>NB:</p>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="input"
                              style={{
                                width: 60,
                                height: 20,
                                marginLeft: "5px",
                              }}
                              value={dataUSG1?.nb}
                            />
                            <p>mm</p>
                          </div>
                          <p>HC:</p>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="input"
                              style={{
                                width: 60,
                                height: 20,
                                marginLeft: "5px",
                              }}
                              value={dataUSG1?.hc}
                            />
                            <p>mm</p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            fontSize: "25px",
                            marginTop: "15px",
                          }}
                        >
                          {/* <p>TWIN B</p> */}
                          <p> 2nd Trimester USG Details </p>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            fontSize: "25px",
                          }}
                        >
                          <p>USG DATE</p>
                          <div style={{ width: "30%", height: "10px" }}>
                            <input
                              type="input"
                              style={{
                                width: "95%",
                                display: "block",
                                marginLeft: "5px",
                              }}
                              value={data?.date_of_usg}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            marginTop: "10px",
                          }}
                        >
                          <p>BPD:</p>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="input"
                              style={{
                                width: 60,
                                height: 20,
                                marginLeft: "5px",
                              }}
                              value={dataUSG2?.bpd}
                            />
                            <p>mm</p>
                          </div>
                          <p>FL:</p>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="input"
                              style={{
                                width: 60,
                                height: 20,
                                marginLeft: "5px",
                              }}
                              value={dataUSG2?.fl}
                            />{" "}
                            <p>mm</p>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            marginTop: "10px",
                          }}
                        >
                          <p>HC:</p>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="input"
                              style={{
                                width: 60,
                                height: 20,
                                marginLeft: "5px",
                              }}
                              value={dataUSG2?.hc}
                            />{" "}
                            <p>mm</p>
                          </div>
                          <p>AC:</p>
                          <div
                            style={{
                              width: "30%",
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            <input
                              type="input"
                              style={{
                                width: 60,
                                height: 20,
                                marginLeft: "5px",
                              }}
                              value={dataUSG2?.ac}
                            />{" "}
                            <p>mm</p>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* </>
                )} */}
                  <div
                    style={{
                      display: "flex",
                      width: "80%",
                      fontSize: "20px",
                      marginTop: "100px",
                    }}
                  >
                    <p>Patient's Signature </p>
                    <div style={{ width: "50%" }}>
                      <img
                        style={{ width: "100px", height: "80px" }}
                        src={data?.patient_signature}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <p>
                  I have read and understood the consents of this form. I
                  further declare and undertake that the above information
                  provided by me is true and correct in all aspects.
                </p>
              </div>
              <div>
                <footer
                  style={{
                    background: "#AD70A6",
                    textAlign: "center",
                    padding: "20px",
                    color: "#fff",
                    border: "2px solid black",
                  }}
                >
                  <p>
                    <b>Redcliffe Life Tech Pvt.Ltd.</b> &nbsp;{" "}
                    <p style={{ display: "inline" }}>
                      H-55, Sector-63, Electronic City Noida, Noida-201301 (U.P)
                      INDIA,
                    </p>
                  </p>

                  <p>
                    Phone: 898 898 8787 | &nbsp; Email: care@redcliffelabs.com |
                    &nbsp; Website: www.redcliffelabs.com
                  </p>
                </footer>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                display: "block",
                height: 1600,
                width: "100%",
                padding: "15px",
              }}
            >
              <h6
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                USG IMAGE-
              </h6>
              <img
                // style={{ width: 1000, height: "100%" }}
                src={data?.attachment_one}
              />
            </div>
          </div>
          {/* <div className="pageBreak" /> */}
          <div
            style={{
              display: "block",
              height: 1600,
              width: "100%",
              padding: "15px",
            }}
          >
            <h6
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              {/* USG IMAGE -2 */}
            </h6>

            <img
              // style={{ width: 1000, height: "100%" }}
              src={data?.attachment_two}
            />
          </div>
          <div
            style={{
              display: "block",
              height: 1600,
              width: "100%",
              padding: "15px",
            }}
          >
            <h6
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              {/* USG IMAGE -3 */}
            </h6>

            <img
              // style={{ width: 1000, height: "100%" }}
              src={data?.attachment_three}
            />
          </div>
        </div>
      )}
    </>
  );
};
const mapStateToProps = (state: any) => ({
  biomarkerScreening: state.BookingReducer.biomarkerScreening,
  loading: state.BookingReducer.loading,
  booking: state.BookingReducer.booking,
  biomarkerUltasound: state.BookingReducer.biomarkerUltasound,
  markerAdditionMember: state.BookingReducer.markerAdditionMember,
});

export default connect(mapStateToProps, {
  getBiomarkerScreening,
  getBookingById,
  getBiomarkerUltasound,
  getAdditionalMember,
  resetBiomarker,
  resetBookings,
})(BiomarkerScreeningTestRequistionFrom);
