import React, { useState, useRef, useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Paper,
  TextField,
  Grid,
  Slider,
  withStyles,
  MenuItem,
  Select,
  InputAdornment,
  Dialog,
} from "@material-ui/core";
import Button from '@mui/material/Button';
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Geocode from "react-geocode";
import { useDispatch } from "react-redux";
import {
  getPinCode,
  createNewCenter,
  updateCenter,
  getSalesManager,
  getDocument,
  checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken,
  checkGeoFenceArea,
  getCenterInfo,
  getDisplayCities,
} from "../../actions/ImagingAdminAction";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Loader from "../../Components/loader/index";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { ACTIONS } from "../../interfaces/actionTypes/ImagingAdminType";
import CenterTable from "./CenterTable";
import "./index.sass";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
Geocode.setApiKey("AIzaSyBPjDlsHYhWtjt3FxgRPnTMXN-qJZ9hz0s");
Geocode.enableDebug();

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#3f51b5",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    table: {
      minWidth: 700,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
      minHeight: "64px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    hoveItem: {
      cursor: "pointer",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    centerIt: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

const PrettoSlider: any = withStyles({
  root: {
    color: "rgb(146, 74, 145)",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "violet",
    border: "2px solid purple",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

interface Props {
  getPinCode: any;
  pin_code: any;
  createNewCenter: any;
  getSalesManager: any;
  salesManager: any;
  getDocument: any;
  getCentrePackageComments: any;
  document: any;
  checkGeoFenceArea: any;
  checkGeoFenceStatus: boolean;
  checkGeoFenchMapMyIndia: any;
  geoFencesMapMyIndia: any;
  getMapMyIndiaAccessToken: any;
  mapmyindiaAccessToken: any;
  getCenterInfo: any;
  updateCenter: any;
  centerInfo: any;
  centreObject: any;
  getDisplayCities: any;
  displayCities: any;
}
const CreateCentrePage: React.FC<Props> = ({
  getPinCode,
  pin_code,
  createNewCenter,
  getSalesManager,
  getCentrePackageComments,
  salesManager,
  getDocument,
  document,
  updateCenter,
  checkGeoFenchMapMyIndia,
  geoFencesMapMyIndia,
  getMapMyIndiaAccessToken,
  mapmyindiaAccessToken,
  getCenterInfo,
  centerInfo,
  centreObject,
  getDisplayCities,
  displayCities,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  let dispatch = useDispatch();
  const [centerName, setCentreName] = useState<any>("");
  const [centerType, setCentreType] = useState<any>("none");
  // const [centerCode, setCentreCode] = useState<any>("");
  const [expiryDate, setExpiryDate] = useState("");
  const [contractDate, setContractDate] = useState("");
  const [mobileNumber, setMobileNumber] = useState<any>("");
  const [altNumber, setaltNumber] = useState<any>("");
  const [sales_ManagerName, setSales_ManagerName] = useState<any>("");
  const [sales_Manager, setSales_Manager] = useState<any>("");
  const [sales_ManagerDef, setSales_ManagerDef] = useState<any>("");
  const [ownerName, setOwnerName] = useState("");
  const [centerAddress, setCentreAddress] = React.useState<any>("");
  const [centerAddressText, setCentreAddressText] = React.useState<any>("");
  const [cityName, setCityName] = React.useState<String>("");
  const [cityId, setCityId] = React.useState<any>();
  const [nationality, setNationality] = React.useState("Indian");
  const [centerId, setCenterId] = useState<any>();

  const [centerHeadalterNateNumber, setCentreHeadAlternateNumber] =
    useState<any>();
  const [centerHeadalContactNumber, setCentreHeadContactNumber] =
    useState<any>();
  const [email, setEmail] = React.useState("");
  const [fileList, setFileList] = useState<any>([]);
  const [area, setArea] = React.useState<String>("");
  React.useState<any>("");
  const [gstinNumber, setGstinNumber] = React.useState("");
  const [panNumber, setPanNumber] = React.useState("");
  const [center_latitude, setCentreLatitude] = useState<any>("");
  const [center_longitude, setCentreLongitude] = useState<any>("");
  const [pinCodeId, setPincodeId] = useState<any>("");
  const [pincode, setPincode] = useState<any>();
  const [pinCodeIdDef, setPincodeIdDef] = useState<any>("");
  const [onboardDocument, setOnboardDocument] = useState<any>([]);
  const [openModal, setopenModal] = useState<boolean>(false);
  const [addressStatus, setAddressStatus] = useState<any>("confirm");
  const [additionalDetails, setAdditionalDetails] = useState<any>("")

  const [cId, setCid] = useState<any>(0);
  const [paymentType, setPaymentType] = useState<any>("none");
  const [smsType, setSmsType] = React.useState({
    mail: false,
    sms: false,
    screen: false,
    isbillinglock: false,
    isapproved: false,
    is_booking_payment_sms: false,
    is_booking_sms: false,
    is_report_sms: false,
    is_report_whatsapp: false,
    is_report_email: false,
    is_shown_to_pha: false,
    is_shown_to_sales: false,
    is_auto_verify: false,
    is_price_hide: false,
  });
  const [mobileValidation, setMobileValidation] = useState({
    mobileNumber: true,
    centerHeadalterNateNumber: true,
    centerHeadalContactNumber: true,
    altNumber: true,
  });
  const [centerShow, setCentreShow] = useState<boolean>(false);
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
  const [centerHeadShow, setCentreHeadShow] = useState<boolean>(false);
  const [centerBankShow, setCentreBankShow] = useState<boolean>(false);
  const [gLocation, setGLocation] = useState<any>();
  const [editButton, setEditButton] = useState<any>(false);
  const verifyMobile = (type: any) => {
    const mobileRegEx = /^[6-9]\d{9}$/;
    if (
      type === "mob" &&
      mobileNumber &&
      !mobileRegEx.test(mobileNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        mobileNumber: false,
      }));
    }
    if (
      type === "centAlt" &&
      altNumber &&
      !mobileRegEx.test(altNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        altNumber: false,
      }));
    }
    if (
      type === "contAlt" &&
      centerHeadalterNateNumber &&
      !mobileRegEx.test(centerHeadalterNateNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        centerHeadalterNateNumber: false,
      }));
    }
    if (
      type === "contMob" &&
      centerHeadalContactNumber &&
      !mobileRegEx.test(centerHeadalContactNumber.toString())
    ) {
      setMobileValidation((prev: any) => ({
        ...prev,
        centerHeadalContactNumber: false,
      }));
    }
  };
  const CenterTypeData = [
    {
      value: "single",
      text: "Stand alone",
    },
    {
      value: "chain",
      text: "Chain",
    },
  ];


  const salutationData = [
    {
      value: "Mr",
      text: "Mr",
    },
    {
      value: "Master",
      text: "Master",
    },
    {
      value: "Miss",
      text: "Miss",
    },
    {
      value: "Smt",
      text: "Smt",
    },
    {
      value: "Dr.",
      text: "Dr.",
    },
    {
      value: "Baby or Just Born",
      text: "Baby or Just Born",
    },
  ];

  const onboardingDocument = [
    {
      value: "PNDT",
      text: "PNDT",
    },
    {
      value: "INCORPORATION CERTIFICATE",
      text: "INCORPORATIONCERTIFICATE",
    },
    {
      value: "AGREEMENT COPY",
      text: "AGREEMENTCOPY",
    },
    {
      value: "PAN",
      text: "PAN",
    },
    {
      value: "AADHAAR CARD",
      text: "AADHAARCARD",
    },
  ];
  useEffect(() => {
    getSalesManager();
    getDocument();
    getMapMyIndiaAccessToken();
    getDisplayCities();
    getCenterInfo("?imaging=true");
  }, []);

  const fileSelectedHandler1 = (
    e: React.ChangeEvent<{ files: any }>,
    val: any
  ) => {
    // setFile1(e.target.files[0]);
    if (fileList.length === 0) {
      const list: any = [...fileList];
      toBase64(e.target.files[0]).then((data: any) => {
        list.push({
          document_id: val.id,
          file: data,
        });
      });
      setFileList(list);
    } else {
      const list: any = [...fileList];

      fileList.map((data: any, index: any) => {
        if (val.id === data.document_id) {
          toBase64(e.target.files[0]).then((data: any) => {
            list[index].file = data;
          });

          setFileList(list);
        } else if (val.id !== data.document_id) {
          toBase64(e.target.files[0]).then((data: any) => {
            list.push({
              document_id: val.id,
              file: data,
            });
          });
          setFileList(list);
        }
      });

      setFileList(list);
    }
  };

  const handleEdit = (data: any) => {

    // setCentreCode(data?.center_code);

    setCentreName(data?.name);
    setCentreType(data?.center_type);
    setGLocation(data?.google_map);
    setCentreAddress(data?.address);
    setCentreAddressText(data?.address);
    setPincodeId(data?.area?.id);
    setArea(data?.area?.area);
    setPaymentType(data?.type)
    setCityName(data?.area?.city_name);
    setCityId(data?.area?.city);
    setPincodeIdDef(data?.area);
    setCentreLatitude(data.latitude);
    setCentreLongitude(data.logitude);
    setCentreShow(true);
    setPincode(
      data?.area?.pincode === "null" ||
        data?.area?.pincode === null ||
        data?.area?.pincode === undefined
        ? ""
        : data?.area?.pincode
    );
    setOwnerName(data?.owner_name);
    setEmail(data?.email);
    setCentreHeadContactNumber(data?.mobile);
    if (data?.sales_manager) {
      setSales_Manager(data?.sales_manager?.id);
      setSales_ManagerDef({
        id: data?.sales_manager?.id,
        user: { username: data?.sales_manager?.name },
      });
    }

    setCentreHeadShow(true);
    setContractDate(data?.contract_start_date);
    setExpiryDate(data?.contract_end_date);
    setGstinNumber(
      data?.gstin === null || data?.gstin === null ? "" : data?.gstin
    );
    setPanNumber(data?.pan === null || data?.pan === null ? "" : data?.pan);
    setCentreBankShow(true);
    setCenterId(data?.id);
    setEditButton(true);
  };
  const handleSubmitCentre = async () => {
    const formData = {
      name: centerName,
      center_documrnts: fileList,
      center_type: centerType,
      is_chain: centerType === "chain" ? true : false,
      google_location: gLocation,
      city: cityId,
      // center_code: centerCode,
      contract_start_date: contractDate,
      contract_end_date: expiryDate,
      mobile: centerHeadalContactNumber,
      sales_manager: sales_Manager,
      address: centerAddress,
      location: pinCodeId,
      latitude: center_latitude,
      logitude: center_longitude,
      owner_name: ownerName,
      gstin: gstinNumber ? gstinNumber : null,
      pan: panNumber ? panNumber : null,
      email: email,
      owner_nationality: nationality,
      additional_detail: additionalDetails,
      org_type: "imaging",
      is_active: true,
      report_email_sent_to: "customer",
      is_report_email: true,
      type: paymentType,
    };
    if (centerType == "chain" || editButton) {
      delete formData.google_location;
    }
    if (sales_Manager == "") {
      delete formData.sales_manager;
    }
    if (centerAddress == "") {
      delete formData.address;
    }
    if (center_longitude == "") {
      delete formData.logitude;
    }
    if (center_latitude == "") {
      delete formData.latitude;
    }
    if (editButton) {
      await updateCenter(formData, centerId);
    } else {
      await createNewCenter(formData);
    }
    handleClosePreview();
    setTimeout(() => {
      dispatch({ type: ACTIONS.GET_PIN_CODE, payload: [] });
      history.push("/dashboard/ia");
    }, 1000);
  };
  const handleSubmitCentrePreview = (e: any) => {
    e.preventDefault();
    setOpenPreviewModal(true);
  };
  const toBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  //mapmyindia
  const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
  const [mapAddressObject, setMapAddressObject] = useState<any>({});
  const [accessToken, setAccessToken] = useState<any>("");
  useEffect(() => {
    if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
      setCentreLatitude(geoFencesMapMyIndia?.latitude);
      setCentreLongitude(geoFencesMapMyIndia?.longitude);
    }
  }, [geoFencesMapMyIndia]);

  useEffect(() => {
    if (mapmyindiaAccessToken?.accesstoken) {
      setAccessToken(mapmyindiaAccessToken?.accesstoken);
    }
  }, [mapmyindiaAccessToken]);

  useEffect(() => {
    if (mapAddressObject?.eLoc) {
      getPinCode(
        `${mapAddressObject?.addressTokens?.pincode}&pincode=${mapAddressObject?.addressTokens?.pincode}&city=${mapAddressObject?.addressTokens.city}&area=${mapAddressObject?.addressTokens?.locality}&imaging=true`
      );
      setCentreAddress(mapAddressObject?.placeAddress);
      checkGeoFenchMapMyIndia(mapAddressObject?.eLoc, "NA");
    }
  }, [mapAddressObject]);

  useEffect(() => {
    if (pin_code?.results && pin_code?.results.length > 0) {
      setCityId(pin_code?.results[0].cityid);
      setPincode(pin_code?.results[0].pincode);
      setPincodeId(pin_code?.results[0].id);
      setArea(pin_code?.results[0].area);
      setCityName(pin_code?.results[0].city);
    }
  }, [pin_code]);

  const locationAutoCompleteSearch = (val: string) => {
    if (val.length > 2) {
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName = "jsonp_callback_" + "data" + id;
        window[callbackName] = (data: any) => {
          delete window[callbackName];
          const ele = window.document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          const suggestedData = data?.suggestedLocations;
          if (suggestedData.length > 0) {
            setSuggestedAddres(data?.suggestedLocations);
          }
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = window.document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          window.document.getElementsByTagName("head")[0] ||
          window.document.body ||
          window.document.documentElement
        ).appendChild(script);
      });
    }
  };

  //mapmyindia
  const handleClosePreview = () => {
    setOpenPreviewModal(false);
  };
  const [checked, setChecked] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };


  const handleConfirmAddress = () => {
    setAddressStatus("reset");
    getPinCode(
      `${pincode}&pincode=${pincode}&city=${cityName}&area=${area}&imaging=true`
    );
  };
  const handleReserAddress = () => {
    setCid(cId + 2);
    setCentreLatitude("");
    setCentreLongitude("");
    setPincode("");
    setCityName("");
    setArea("");
    setCentreAddress("");
    setAddressStatus("confirm");
  };
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <form onSubmit={handleSubmitCentrePreview}>
        <Paper elevation={5} className={classes.paper}>
          <h4
            className={classes.hoveItem}
            style={{ fontWeight: "bold", background: "#3f51b5", color: "#fff" }}
            onClick={() => setCentreShow(centerShow ? false : true)}
          >
            {centerShow ? <ExpandLessIcon /> : <ExpandMoreIcon />} CENTRE
            CREATION MASTER
          </h4>
          {centerShow ? (
            <Grid
              container
              spacing={3}
              direction="row"
              style={{ alignItems: "left" }}
            >
              {/* <Grid item xs={12} sm={6} md={3}>
                <TextField
                  className="input"
                  name="title"
                  type="text"
                  label="Centre Code"
                  value={centerCode}
                  required
                  onChange={(e) => setCentreCode(e.target.value)}
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </Grid> */}

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  className="input"
                  name="center_name"
                  type="text"
                  label="Centre Name"
                  value={centerName}
                  required
                  variant="outlined"
                  onChange={(e) => setCentreName(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>

                <Select
                  aria-required
                  className="input"
                  style={{ width: "100%", height: "40px" }}
                  variant="outlined"
                  label="Centre Type"
                  value={centerType}
                  required
                  onChange={(e) => setCentreType(e.target.value as string)}
                >
                  <MenuItem disabled value={"none"}> Centre Type</MenuItem>
                  <MenuItem value={"single"}>Stand alone</MenuItem>
                  <MenuItem value={"chain"}>Chain</MenuItem>

                </Select>

              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Select
                  key={paymentType}                  
                  aria-required
                  className="input"
                  variant="outlined"
                  style={{ width: "100%" ,height:"40px"}}
                  label="Lab Payment Type"
                  value={paymentType}
                  required
                  onChange={(e) => setPaymentType(e.target.value)}
                >
                  <MenuItem disabled value={"none"}> Lab Payment Type </MenuItem>
                  <MenuItem value={"postpaid"}>Postpaid </MenuItem>
                  <MenuItem value={"prepaid"}>Prepaid</MenuItem>
                </Select>
              </Grid>
              {editButton === false && centerType == "single" || editButton === true && centerType == "chain" && centerType == "single" ? (
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="glink"
                    type="text"
                    label="Google location"
                    value={gLocation}
                    required
                    variant="outlined"
                    onChange={(e) => setGLocation(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </Grid>
              ) : (
                ""
              )}
              {checked ? (
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    className="input"
                    name="centreaddress"
                    type="text"
                    label="Centre Address"
                    value={centerAddress}
                    required
                    variant="outlined"
                    onChange={(e) => setCentreAddress(e.target.value)}
                    style={{ width: "100%" }}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    key={centerId}
                    id="colony-area-sector"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setMapAddressObject(obj);
                      }
                    }}
                    options={suggestedAddress ? suggestedAddress : []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    defaultValue={{
                      placeName: centerAddressText,
                      placeAddress: "",
                    }}
                    getOptionLabel={(option: any) =>
                      `${option?.placeName} ${option?.placeAddress}`
                    }
                    filterOptions={(option: any) => option}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        locationAutoCompleteSearch(newInputValue);
                      }, 500);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="Centre Address"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        required
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={12} sm={6} md={3}>
                {/* <Autocomplete
                  key={centerId}
                  id="pincode"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setPincodeId(obj?.id as Number);
                      setArea(obj.area as String);
                      setCityName(obj.city as String);
                      setCityId(obj.cityid);
                    }
                  }}
                  defaultValue={
                    pinCodeIdDef || {
                      id: "",
                      area: "",
                      city: "",
                      cityid: "",
                      pincode: "",
                    }
                  }
                  options={pin_code.results || []}
                  freeSolo
                  blurOnSelect
                  aria-required
                  onInputChange={(event, newInputValue) => {
                    getPinCode(newInputValue);
                  }}
                  // inputValue={pinCodeIdDef}
                  getOptionLabel={(option: any) =>
                    option.pincode ? `${option.pincode} ,  ${option.area}` : ""
                  }
                  disableClearable
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      label="Pin Code"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                /> */}
                {checked ? (
                  <TextField
                    className="input"
                    onWheel={(e: any) => e.target.blur()}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 6);
                    }}
                    name="pincode"
                    type="number"
                    label="PINCODE"
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                ) : (
                  <TextField
                    className="input"
                    name="pincode"
                    type="text"
                    label="PINCODE"
                    value={pincode}
                    disabled
                    variant="outlined"
                    style={{ width: "100%" }}
                  />
                )}
              </Grid>
              {checked ? (
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    id="city"
                    key={cId}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setCityName(obj);
                      }
                    }}
                    options={displayCities.cities || []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    limitTags={1}
                    getOptionLabel={(option: any) => option}
                    disableClearable
                    disableCloseOnSelect
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        setCityName(newInputValue);
                      }, 1000);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        label="City Name "
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name="city"
                    type="text"
                    value={cityName}
                    className="input"
                    label="City Name "
                    variant="outlined"
                    disabled
                    style={{ width: "100%" }}
                    required
                  />
                </Grid>
              )}
              {checked ? (
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name="area"
                    type="text"
                    value={area}
                    className="input"
                    label="Area "
                    variant="outlined"
                    onChange={(e) => setArea(e.target.value)}
                    style={{ width: "100%" }}
                    required
                  />
                </Grid>
              ) : (
                ""
              )}
              {editButton === false || editButton === true && centerType == "chain" ? (
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name="latitude"
                    type="text"
                    value={center_latitude}
                    className="input"
                    label="Latitude "
                    variant="outlined"
                    onChange={(e) => setCentreLatitude(e.target.value)}
                    // disabled={!checked}
                    style={{ width: "100%" }}
                    required
                  />
                </Grid>
              ) : (
                ""
              )}
              {editButton === false || editButton === true && centerType == "chain" ? (
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    name="Longitude"
                    type="text"
                    value={center_longitude}
                    onChange={(e) => setCentreLongitude(e.target.value)}
                    className="input"
                    label="Logitude "
                    variant="outlined"
                    // disabled={!checked}
                    style={{ width: "100%" }}
                    required
                  />
                </Grid>
              ) : (
                ""
              )}
              {checked ? (
                <Grid item xs={12} sm={6} md={3}>
                  {addressStatus === "confirm" ? (
                    <Button
                      onClick={handleConfirmAddress}
                      variant="contained"
                      style={{ background: "#4caf50" }}
                      startIcon={<CheckCircleIcon />}
                    >
                      Confirm Address
                    </Button>
                  ) : (
                    <Button
                      onClick={handleReserAddress}
                      variant="contained"
                      style={{ background: "#3f51b5" }}
                      endIcon={<HighlightOffIcon />}
                    >
                      Reset Address
                    </Button>
                  )}
                </Grid>
              ) : (
                ""
              )}

              <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Mannual"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          <h4
            className={classes.hoveItem}
            onClick={() => setCentreHeadShow(centerHeadShow ? false : true)}
            style={{
              fontWeight: "bold",
              marginTop: "1rem",
              background: "#3f51b5",
              color: "#fff",
            }}
          >
            {centerHeadShow ? <ExpandLessIcon /> : <ExpandMoreIcon />} CENTRE
            HEAD DETAILS
          </h4>
          {centerHeadShow ? (
            <Grid
              container
              spacing={3}
              direction="row"
              style={{ alignItems: "left" }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="Centre Head Name"
                  type="text"
                  value={ownerName}
                  className="input"
                  label="Centre Head Name"
                  variant="outlined"
                  onChange={(e) => setOwnerName(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  name="email"
                  type="email"
                  value={email}
                  className="input"
                  label="Email "
                  variant="outlined"
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  className="input"
                  name="contact"
                  type="number"
                  label="Centre Head Mobile Number"
                  value={centerHeadalContactNumber}
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) =>
                    setCentreHeadContactNumber(e.target.value as String)
                  }
                  onBlur={() => verifyMobile("contMob")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      centerHeadalContactNumber: true,
                    }));
                  }}
                  onWheel={(e: any) => e.target.blur()}
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  helperText={
                    !mobileValidation.centerHeadalContactNumber &&
                    "Incorrect Mobile Number"
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                  key={centerId}
                  id="onboardingmanger"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setSales_Manager(obj.id);
                    }
                  }}
                  defaultValue={
                    sales_ManagerDef || { id: "", user: { username: "" } }
                  }
                  options={salesManager.results || []}
                  freeSolo
                  blurOnSelect
                  onInputChange={(event, newInputValue) => {
                    getSalesManager(newInputValue);
                    setSales_ManagerName(newInputValue);
                  }}
                  getOptionLabel={(option: any) => `${option.user?.username}`}
                  disableClearable
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      label="Onboarding Manager"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          <h4
            className={classes.hoveItem}
            onClick={() => setCentreBankShow(centerBankShow ? false : true)}
            style={{
              fontWeight: "bold",
              marginTop: "1rem",
              background: "#3f51b5",
              color: "#fff",
            }}
          >
            {centerBankShow ? <ExpandLessIcon /> : <ExpandMoreIcon />}{" "}
            ONBOARDING DOCUMENT
          </h4>
          {centerBankShow ? (
            <Grid
              container
              spacing={3}
              direction="row"
              style={{ alignItems: "left" }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <p>ONBOARDING DOCUMENT</p>
                <Autocomplete
                  id="city"
                  onChange={(event, newValue) => {
                    let sourceIds: any = [];
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      sourceIds = obj.map((item: any) => item);
                    }
                    setOnboardDocument(sourceIds);
                  }}
                  options={document || []}
                  freeSolo
                  blurOnSelect
                  aria-required
                  limitTags={1}
                  multiple
                  getOptionLabel={(option: any) => option.name}
                  getOptionDisabled={(option: any) => {
                    return document.includes(option.name);
                  }}
                  disabled={editButton}
                  disableClearable
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            
                            <ArrowDropDownIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="On Boarding Document"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                />
              </Grid>

              {onboardDocument.map((val: any) => {
                return (
                  <Grid item xs={12} sm={6} md={3}>
                    <p>{val.name}</p>
                    <input
                      key={val.id}
                      className="input"
                      style={{
                        width: "100%",
                        margin: "0",
                        padding: "18.5px 14px",
                        border: "1px solid rgba(0, 0, 0, 0.23)",
                        borderRadius: "5px",
                      }}
                      type="file"
                      name="file"
                      id="file"
                      multiple
                      onChange={(e) => fileSelectedHandler1(e, val)}
                    />
                  </Grid>
                );
              })}
              <Grid item xs={12} sm={6} md={3}>
                <p>CONTRACT DATE</p>
                <TextField
                  name="contract_date"
                  type="date"
                  value={contractDate}
                  className="input"
                  placeholder="Contract Date"
                  variant="outlined"
                  required
                  inputProps={{
                    min: editButton
                      ? moment(contractDate).format("YYYY-MM-DD")
                      : moment().format("YYYY-MM-DD"),
                  }}
                  onChange={(e) => setContractDate(e.target.value as string)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <p>EXPIRY DATE</p>
                <TextField
                  name="expiry_date"
                  type="date"
                  disabled={contractDate === ""}
                  value={expiryDate}
                  className="input"
                  placeholder="Expiry Date"
                  inputProps={{
                    min: moment(contractDate).format("YYYY-MM-DD"),
                  }}
                  helperText={
                    moment(expiryDate).isAfter(contractDate)
                      ? ""
                      : "Expiry Date Should Greater Than Contract Date"
                  }
                  variant="outlined"
                  onChange={(e) => setExpiryDate(e.target.value as string)}
                  style={{ width: "100%" }}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <p>GSTIN NUMBER</p>
                <TextField
                  name="GSTINNumber"
                  type="text"
                  value={gstinNumber}
                  className="input"
                  placeholder="GSTIN"
                  variant="outlined"
                  onChange={(e) => setGstinNumber(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <p>PANCARD NUMBER</p>
                <TextField
                  name="panNumber"
                  type="text"
                  value={panNumber}
                  className="input"
                  placeholder="PAN"
                  variant="outlined"
                  onChange={(e) =>
                    setPanNumber(String(e.target.value).toUpperCase())
                  }
                  style={{ width: "100%" }}
                  helperText="Ex:- ABCDE9999F"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <p>Additional Details</p>
                <TextField
                  name="Additional Details"
                  type="text"
                  value={additionalDetails}
                  className="input"
                  placeholder="Please Enter Additional Details"
                  variant="outlined"
                  onChange={(e) =>
                    setAdditionalDetails(String(e.target.value).toUpperCase())
                  }
                  style={{ width: "100%" }}

                />
              </Grid>
            </Grid>
          ) : (
            ""
          )}
          <Grid
            container
            spacing={3}
            direction="row"
            style={{ alignItems: "right" }}
          >
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={
                  !mobileValidation.mobileNumber ||
                  !mobileValidation.altNumber ||
                  !mobileValidation.centerHeadalContactNumber ||
                  !mobileValidation.centerHeadalterNateNumber
                }
                type="submit"
              >
                {editButton ? "Preview Update Centre" : "Preview Create Center"}
              </Button>
            </Grid>
            {!editButton ? (
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => history.push("/dashboard/ia")}
                >
                  Reset
                </Button>
              </Grid>
            ) : (
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => history.push("/dashboard/ia")}
                >
                  Cancel Update
                </Button>
              </Grid>
            )}
          </Grid>
        </Paper>
        <Dialog
          open={openPreviewModal}
          onClose={() => handleClosePreview()}
          fullWidth
          maxWidth="lg"
        >
          <Grid container style={{ padding: 20 }} spacing={3}>
            <Grid item md={4} lg={2}>
              Centre Name:{centerName}
            </Grid>
            <Grid item md={4} lg={2}>
              Centre Type:{" "}
              {CenterTypeData &&
                CenterTypeData.map((val: any) => {
                  if (centerType === val?.value) {
                    return val?.text;
                  }
                })}
            </Grid>
            <Grid item md={4} lg={2}>
              Google Loaction: {gLocation}
            </Grid>
            <Grid item md={4} lg={2}>
              Centre Address : {centerAddress}
            </Grid>
            <Grid item md={4} lg={2}>
              Pincode : {pincode}
            </Grid>
            <Grid item md={4} lg={2}>
              {" "}
              City : {cityName}{" "}
            </Grid>
            <Grid item md={4} lg={2}>
              {" "}
              Centre Head Name : {ownerName}{" "}
            </Grid>
            <Grid item md={4} lg={2}>
              {" "}
              Email : {email}{" "}
            </Grid>
            <Grid item md={4} lg={2}>
              {" "}
              Centre Head Mobile Number : {centerHeadalContactNumber}{" "}
            </Grid>
            <Grid item md={4} lg={2}>
              {" "}
              Onboarding Manager : {sales_ManagerName}{" "}
            </Grid>
            <Grid item md={4} lg={2}>
              {" "}
              Contract Date : {contractDate}{" "}
            </Grid>
            <Grid item md={4} lg={2}>
              {" "}
              Expiry Date : {expiryDate}{" "}
            </Grid>
            <Grid item md={4} lg={2}>
              {" "}
              GSTIN Number : {gstinNumber}{" "}
            </Grid>
            <Grid item md={4} lg={2}>
              {" "}
              PANCARD Number : {panNumber}{" "}
            </Grid>
            <Grid sm={12} />
            <Grid item xs={12} sm={6} md={3}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={
                  !mobileValidation.mobileNumber ||
                  !mobileValidation.altNumber ||
                  !mobileValidation.centerHeadalContactNumber ||
                  !mobileValidation.centerHeadalterNateNumber
                }
                onClick={() => handleSubmitCentre()}
              >
                {editButton ? "Update Centre" : "Create Center"}
              </Button>
            </Grid>
            {!editButton ? (
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => history.push("/dashboard/ia")}
                >
                  Reset
                </Button>
              </Grid>
            ) : (
              <Grid item xs={12} sm={6} md={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => history.push("/dashboard/ia")}
                >
                  Cancel Update
                </Button>
              </Grid>
            )}
          </Grid>
        </Dialog>
      </form>
      <CenterTable
        centreData={centerInfo}
        getCenterInfo={getCenterInfo}
        handleEdit={handleEdit}
        openModal={openModal}
      />
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  pin_code: state.ImagingAdminReducer.pin_code,
  document: state.ImagingAdminReducer.document,
  salesManager: state.ImagingAdminReducer.salesManager,
  centerInfo: state.ImagingAdminReducer.centerInfo,
  checkGeoFenceStatus: state.ImagingAdminReducer.checkGeoFenceStatus,
  geoFencesMapMyIndia: state.ImagingAdminReducer.geoFencesMapMyIndia,
  mapmyindiaAccessToken: state.ImagingAdminReducer.mapmyindiaAccessToken,
  centreObject: state.ImagingAdminReducer.centreObject,
  displayCities: state.ImagingAdminReducer.displayCities,
});
export default connect(mapStateToProps, {
  getPinCode,
  getDocument,
  getSalesManager,
  createNewCenter,
  checkGeoFenceArea,
  checkGeoFenchMapMyIndia,
  getCenterInfo,
  getMapMyIndiaAccessToken,
  updateCenter,
  getDisplayCities,
})(CreateCentrePage);
