import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Container, Grid, TextField } from "@material-ui/core";
import Box from "@mui/material/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";
import { MenuItem, Select } from "@material-ui/core";
import Button from '@mui/material/Button';
import { generateBookingFilterUrl } from "../../helpers/generateUrl";
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { connect } from "react-redux";
import {
  getAgentList,
  getLeadSource,
  getPartners,
  getAllBookings,
  getCities,
  getPhlebos,
  getPanelUserList,
  getVerifiedbyList,
  getLeadStatus,
} from "../actions/salesLeadAction";
import { getAllLeads, leadTransferByComma } from "../actions/salesLeadAction";
import "./filter.sass";
import { useHistory } from "react-router";
import BookingsTable from "../tables/bookingsTable";
import Loader from "../Components/loader/index";
import { getPaymentInfo } from "../actions/salesLeadAction";
import PaymentModal from "../Components/comments/paymentforModal";
import BookingCommentsModal from "../Components/comments/bookingComments";
import HelpIcon from "@material-ui/icons/Help";
import Chip from "@material-ui/core/Chip";
import { BOOKING_STATUS } from "../../booking_status";
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// const CATEGORY: any = [
//   {
//     id: 1,
//     text: "Interested/Follow up",
//     city: true,
//     leadId: false,
//   },
//   {
//     id: 2,
//     text: "Not Interested",
//     city: true,
//     query: false,
//   },
//   {
//     id: 3,
//     text: "Order Booked",
//     city: true,
//     query: false,
//   },
//   {
//     id: 4,
//     text: "Ringing",
//     city: false,
//     query: false,
//   },
//   {
//     id: 5,
//     text: "Number Doesn't Exist",
//     city: false,
//     query: false,
//   },
//   {
//     id: 6,
//     text: "By Mistake & Wrong Number",
//     city: false,
//     query: false,
//   },
//   {
//     id: 7,
//     text: "Call Back",
//     city: true,
//     query: false,
//   },
//   {
//     id: 8,
//     text: "Business Realated Call",
//     city: true,
//     query: false,
//   },
//   {
//     id: 9,
//     text: "Spam Call",
//     city: false,
//     query: false,
//   },
//   {
//     id: 10,
//     text: "Enquiry Call",
//     city: true,
//     query: false,
//   },
//   {
//     id: 11,
//     text: "CFresh",
//     city: false,
//     query: false,
//   },
//   {
//     id: 12,
//     text: "Test Not Available",
//     city: true,
//     query: false,
//   },
//   {
//     id: 13,
//     text: "Subscribed to Competitor",
//     city: true,
//     query: false,
//   },
//   {
//     id: 14,
//     text: "Slot Issue",
//     city: true,
//     query: false,
//   },
//   {
//     id: 15,
//     text: "Same Day Slot",
//     city: true,
//     query: false,
//   },
//   {
//     id: 16,
//     text: "Already Booked",
//     city: false,
//     query: false,
//   },
//   {
//     id: 17,
//     text: "Not Contactable",
//     city: false,
//     query: false,
//   },
//   {
//     id: 18,
//     text: "Out of Station",
//     city: true,
//     query: false,
//   },
//   {
//     id: 19,
//     text: "Out of Service Area",
//     city: true,
//     query: false,
//   },
//   {
//     id: 20,
//     text: "Need Time to Think",
//     city: true,
//     query: false,
//   },
//   {
//     id: 21,
//     text: "Email/Whatsapp Package",
//     city: true,
//     leadId: false,
//   },
//   {
//     id: 22,
//     text: "Discount/Price Enquiry",
//     city: true,
//     leadId: false,
//   },
//   {
//     id: 23,
//     text: "CS issue",
//     city: false,
//     leadId: true,
//   },
//   {
//     id: 24,
//     text: "Call Not Reachable",
//     city: false,
//     leadId: false,
//   },
//   {
//     id: 25,
//     text: "Call Later",
//     city: true,
//     leadId: false,
//   },
//   {
//     id: 26,
//     text: "Call drop",
//     city: true,
//     leadId: false,
//   },
//   {
//     id: 27,
//     text: "Call Disconnected",
//     city: true,
//     leadId: false,
//   },
//   {
//     id: 28,
//     text: "Below <5 year patient",
//     city: true,
//     leadId: false,
//   },
//   {
//     id: 29,
//     text: "DND",
//     city: false,
//     leadId: false,
//   },
//   {
//     id: 30,
//     text: "Phlebo's Issue",
//     city: false,
//     leadId: true,
//   },
//   {
//     id: 31,
//     text: "Reports Regarding Issue",
//     city: false,
//     leadId: true,
//   },
//   {
//     id: 32,
//     text: "Health Advice",
//     city: false,
//     leadId: true,
//   },
//   {
//     id: 33,
//     text: "Health Consultation",
//     city: false,
//     leadId: true,
//   },
//   {
//     id: 34,
//     text: "Health Consultation",
//     city: false,
//     leadId: false,
//   },
//   {
//     id: 35,
//     text: "Language Barrier",
//     city: false,
//     leadId: false,
//   },
//   {
//     id: 36,
//     text: "RCFresh",
//     city: false,
//     leadId: false,
//   },
//   {
//     id: 37,
//     text: "HCbooking",
//     city: false,
//     leadId: false,
//   },
// ];
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
  })
);

interface Props {
  children: any;
  agentsList: any;
  bookingList: any;
  partnerList: any;
  getAgentList: any;
  getPartners: any;
  getAllBookings: any;
  leadTransferByComma: any;
  agentBYUserGroup: any;
  getCities: any;
  cities: any;
  getPhlebos: any;
  getAllLeads: any;
  phleboList: any;
  getLeadSource: any;
  lead_source: any;
  loading: Boolean;
  getPaymentInfo: any;
  paymentInformation: any;
  getPanelUserList: any;
  panelUserList: any;
  userDetails: any;
  getVerifiedbyList: any;
  verifiedbyList: any;
  getLeadStatus: any;
  leadStatus: any;
  newbookingList: any;
  getAllNewBookings: any;
  bookingListLoader: any;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
  agentsList,
  partnerList,
  getAgentList,
  getPartners,
  getAllBookings,
  leadTransferByComma,
  agentBYUserGroup,
  getAllLeads,
  bookingList,
  userDetails,
  getCities,
  cities,
  getPhlebos,
  phleboList,
  getLeadSource,
  lead_source,
  getPaymentInfo,
  paymentInformation,
  getPanelUserList,
  panelUserList,
  loading,
  getVerifiedbyList,
  verifiedbyList,
  getLeadStatus,
  leadStatus,
  newbookingList,
  getAllNewBookings,
  bookingListLoader,
}) => {
  const classes = useStyles();

  const history = useHistory();

  const [openModal, setopenModal] = useState<boolean>(false);

  const [status, setStatus] = useState<any>([]);
  const [source, setSource] = useState<any>([]);
  const [partner, setPartner] = useState<any>([]);
  const [agent, setAgent] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [VerifiedbyList, setVerifiedbyList] = useState<any>([]);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [booking_date, setBooking_date] = useState("");
  const [collection_date, setCollection_date] = useState("");
  const [verificationStatus, setVerificationStatus] = useState<any>([]);
  const [pickup_status, setPickup_status] = useState<any>([]);
  const [name, setName] = useState("");
  const [phlebo, setPhlebo] = useState<any>([]);
  const [phone, setPhone] = useState("");
  const [bookingId, setBookingid] = React.useState<any>("");
  const [leadId, setLeadId] = React.useState<any>("");
  const [no_call, setNoCall] = React.useState<any>("");
  const [userGroup, setUserGroup] = useState("none");
  const [page, setPage] = useState(0);
  const [sample_registered, setSampleRegisterd] = useState<any>("none");
  const [report_status, setReportStatus] = useState<any>("none");
  const [selectedLeads, setSelectedLeads] = useState<any>([]);
  const [panel_user, setPanelUser] = React.useState<number | null>(null);
  const [paymentId, setPaymentId] = useState<any>(0);
  const [openPaymentModal, setOpenPaymentModal] = useState<any>(false);
  const [defaultStatus, setDefaultStatus] = useState<any>([]);
  const [teamLead, setTeamLead] = useState<any>([]);
  const [category, setCategory] = useState<any>([]);
  const timer = useRef<any>(0);
  const [totalCount, setTotalCount] = useState<any>("");
  const [sourceType, setSourceType] = useState<any>("none");
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (history.action === "POP") {
      let ceBookingFilter: any;
      ceBookingFilter = localStorage.getItem("CEBookingFilter");
      if (ceBookingFilter) {
        ceBookingFilter = JSON.parse(ceBookingFilter);

        const statusD = ceBookingFilter?.status?.split(",");
        let statusTemp = new Array();
        BOOKING_STATUS.map((item: any) => {
          statusD.map((itm: any) => {
            if (item.value == itm) {
              statusTemp.push(item);
            }
          });
        });
        setDefaultStatus(statusTemp);
        ceBookingFilter?.status &&
          setStatus(ceBookingFilter?.status?.split(","));
        ceBookingFilter?.source &&
          setSource(ceBookingFilter?.source?.split(","));
        ceBookingFilter?.partner &&
          setPartner(ceBookingFilter?.partner?.split(","));
        ceBookingFilter?.city && setCity(ceBookingFilter?.city?.split(","));
        ceBookingFilter?.start_date &&
          setStart_date(ceBookingFilter?.start_date);
        setCollection_date(ceBookingFilter?.collection_date);
        ceBookingFilter?.verificationStatus &&
          setVerificationStatus(
            ceBookingFilter?.verificationStatus?.split(",")
          );
        ceBookingFilter?.pickup_status &&
          setPickup_status(ceBookingFilter?.pickup_status?.split(","));

        setName(ceBookingFilter?.name);
        setPhlebo(ceBookingFilter?.phlebo);

        setPhone(ceBookingFilter?.phone);
        setSampleRegisterd(ceBookingFilter?.sample_registered);
        setSampleRegisterd(ceBookingFilter?.tl);
        setUserGroup(ceBookingFilter?.userGroup);
        setReportStatus(ceBookingFilter?.report_status);
        setBookingid(ceBookingFilter?.bookingId);
        setLeadId(ceBookingFilter?.leadId);
        const url = generateBookingFilterUrl(ceBookingFilter).substring(2);
        const pageNo = page ? page : 1;
        if (userDetails?.profile?.executive_type === "UpgradedCE") {
          getAllBookings(`${url}&page=${pageNo}&booking_amount=true&optimize=true&pickup_status="cancelled"`);
        }
        else {
          getAllBookings(`${url}&page=${pageNo}&optimize=true`);
        }
      }
    } else {
      let url = ``;
      // getAgentList();
      getAgentList("?usergroup=CustomerExecutive");
      getPartners();
      if (userDetails?.profile?.executive_type === "UpgradedCE") {
        getAllBookings(`booking_amount=true&optimize=true &pickup_status=cancelled`);
      }
      else {
        getAllBookings(`optimize=true `);
      }
      // getCities();
      // getPhlebos();
      getLeadSource();
      getLeadStatus();
      // getPanelUserList();
      localStorage.removeItem("CEBookingFilter");
      // getVerifiedbyList("?usergroup=VerificationExecutive")
      setDefaultStatus([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDefaultStatus]);

  const onKeyDown = (e: any) => {
    if (e.key === "Enter") {
      filterBookings(e);
    }
  };
  const filterBookings = (e: any) => {
    const body: any = {
      status: status ? status.join(",") : "",
      verificationStatus: verificationStatus
        ? verificationStatus.join(",")
        : "",
      source: source ? source.join(",") : "",
      agent: agent ? agent.join(",") : "",
      city: city ? city.join(",") : "",
      start_date,
      end_date,
      booking_date,
      bookingId,
      collection_date,
      pickup_status: pickup_status ? pickup_status.join(",") : "",
      name,
      verified_by: VerifiedbyList ? VerifiedbyList : "",
      phlebo: phlebo ? phlebo.join(",") : "",
      partner: partner ? partner.join(",") : "",
      user_group: userGroup,
      phone,
      sample_registered,
      report_status,
      lead: leadId,
      no_call: no_call,
      tl: teamLead ? teamLead.join(",") : "",
      lead_call_status_id: category.join(","),
      source_type: sourceType,
    };
    const url = generateBookingFilterUrl(body).substring(2);
    localStorage.setItem("CEBookingFilter", JSON.stringify(body));
    {
      userDetails?.profile?.executive_type === "UpgradedCE" ? (
        <>
          {getAllBookings(url ? `${url}&page=1&booking_amount=true&optimize=true&pickup_status=cancelled` : "page=1&optimize=true")}
        </>
      ) :
        getAllBookings(url ? `${url}&page=1&optimize=true` : "page=1&optimize=true");
    }

    setPage(0);
  };
  const transferLead = async () => {
    let body = {
      lead_ids: selectedLeads.join(", "),
      panel_user,
      booking: true,
    };
    await leadTransferByComma(body);
    getAllLeads("");
    setSelectedLeads([]);
    getAllLeads("");
    getAgentList();
    getAgentList("", "CustomerExecutive");
    // history.push("/dashboard/sl")
  };

  useEffect(() => {
    if (paymentId != 0) {
      getPaymentInfo(`?booking=${paymentId}`);
    }
  }, [paymentId]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid
          container
          spacing={1}
          direction="row"

        >
          <Grid
            container
            spacing={1}
            direction="row"
            style={{ alignItems: "right" }}
          >
            <Grid item xs={12} sm={8}>
              <h4 style={{ fontWeight: "bold" }}>CANCEL BOOKING  <HelpIcon style={{ color: "#9c27b0" }} onClick={handleClickOpen} /> </h4>
            </Grid>
           
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="lead"
              type="number"
              placeholder="Lead ID"
              onWheel={(e: any) => e.target.blur()}
              value={leadId}
              onKeyDown={onKeyDown}
              variant="outlined"
              onChange={(e) => setLeadId(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="no_call"
              type="number"
              label="Call Count"
              // onWheel={(e: any) => e.target.blur()}
              value={no_call}
              onKeyDown={onKeyDown}
              variant="outlined"
              onChange={(e) => setNoCall(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="booking"
              type="number"
              placeholder="Booking Id"
              value={bookingId}
              onWheel={(e: any) => e.target.blur()}
              onKeyDown={onKeyDown}
              variant="outlined"
              onChange={(e) => setBookingid(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="name"
              type="text"
              label="Name"
              value={name}
              variant="outlined"
              onKeyDown={onKeyDown}
              onChange={(e) => setName(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="phone"
              type="number"
              label="Phone"
              value={phone}
              variant="outlined"
              onKeyDown={onKeyDown}
              onChange={(e) => setPhone(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setCity(sourceIds);
              }}
              options={cities || []}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              onKeyDown={onKeyDown}
              getOptionLabel={(option: any) => option.name}
              getOptionDisabled={(option: any) => {
                return city.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCities(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setCity([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="City"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="status"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setStatus(sourceIds);
              }}
              options={BOOKING_STATUS || []}
              blurOnSelect
              aria-required
              onKeyDown={onKeyDown}
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return status.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Booking Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
              defaultValue={defaultStatus}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="partner"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setPartner(sourceIds);
              }}
              freeSolo
              blurOnSelect
              limitTags={1}
              multiple
              options={partnerList?.results || []}
              onKeyDown={onKeyDown}
              getOptionDisabled={(option: any) => {
                return partner.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) => option.user && option.user.name}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPartners(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPartner([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Partner"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setSource(sourceIds);
              }}
              freeSolo
              multiple
              id="checkboxes-tags-demo"
              options={lead_source || []}
              disableCloseOnSelect
              limitTags={1}
              disableClearable
              onKeyDown={onKeyDown}
              getOptionLabel={(option: any) => option.name}
              getOptionDisabled={(option: any) => {
                return source.includes(option.id);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Source"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="agent"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setAgent(sourceIds);
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              options={agentsList?.results || []}
              getOptionDisabled={(option: any) => {
                return agent.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                option.user && option.user.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getAgentList(`?code=${newInputValue.replace(" ", "")}`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setAgent([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Agent"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="verifiedby"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setVerifiedbyList(sourceIds);
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              options={verifiedbyList?.results || []}
              getOptionDisabled={(option: any) => {
                return VerifiedbyList.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                option.user && option.user.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getVerifiedbyList(
                    `?code=${newInputValue}&usergroup=VerificationExecutive`
                  );
                }, 1000);
                if (newInputValue.length === 0) {
                  setVerifiedbyList([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Verified by"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="Team Lead"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setTeamLead(sourceIds);
              }}
              freeSolo
              blurOnSelect
              limitTags={1}
              multiple
              options={panelUserList?.results || []}
              onKeyDown={onKeyDown}
              getOptionDisabled={(option: any) => {
                return teamLead.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) => option.user && option.user.name}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPanelUserList(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setTeamLead([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Team Lead"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="phlebo"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setPhlebo(sourceIds);
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              options={
                phleboList.results
                  ? [
                    ...phleboList.results,
                    { id: 0, user: { username: "NoPhlebo" } },
                  ]
                  : []
              }
              getOptionDisabled={(option: any) => {
                return phlebo.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onKeyDown={onKeyDown}
              getOptionLabel={(option: any) =>
                option.user && option.user.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPhlebos(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPhlebo([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Phlebo Name"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setVerificationStatus(sourceIds);
              }}
              options={[
                {
                  text: "Pending",
                  value: "pending",
                },
                {
                  text: "Confirmed",
                  value: "confirmed",
                },
                {
                  text: "Cancelled",
                  value: "cancelled",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              onKeyDown={onKeyDown}
              multiple
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return verificationStatus.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Verification Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setPickup_status(sourceIds);
              }}
              options={[
                {
                  text: "Pending",
                  value: "pending",
                },
                {
                  text: "Confirmed",
                  value: "confirmed",
                },
                {
                  text: "Cancelled",
                  value: "cancelled",
                },
                {
                  text: "Hold",
                  value: "hold",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.text}
              onKeyDown={onKeyDown}
              getOptionDisabled={(option: any) => {
                return pickup_status.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Pickup Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="usergroup"
              variant="outlined"
              value={userGroup}
              style={{ width: "100%", height: "40px" }}
              onChange={(e) => setUserGroup(e.target.value as string)}
            >
              <MenuItem disabled value={"none"}>
                User Type
              </MenuItem>
              <MenuItem value={"SupportExecutive"}>SupportExecutive</MenuItem>
              <MenuItem value={"SampleAccessioning"}>
                SampleAccessioning
              </MenuItem>
              <MenuItem value={"CustomerExecutive"}>CustomerExecutive</MenuItem>
              <MenuItem value={"VerificationExecutive"}>
                VerificationExecutive
              </MenuItem>
              <MenuItem value={"PhleboAdmin"}>PhleboAdmin</MenuItem>
              <MenuItem value={"Phlebos"}>Phlebos</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="start_date"
              type="date"
              label="Start Date"
              value={
                booking_date === "" || collection_date === "" ? start_date : ""
              }
              variant="outlined"
              disabled={booking_date !== "" || collection_date !== ""}
              onChange={(e) => setStart_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="end_date"
              type="date"
              value={
                booking_date === "" || collection_date === "" ? end_date : ""
              }
              className="input"
              label="End Date"
              variant="outlined"
              disabled={booking_date !== "" || collection_date !== ""}
              onChange={(e) => setEnd_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setCategory(sourceIds);
              }}
              multiple
              id="checkboxes-tags-demo"
              options={leadStatus || []}
              disableCloseOnSelect
              limitTags={1}
              getOptionLabel={(option: any) => option?.name}
              getOptionDisabled={(option: any) => {
                return category.includes(option.id);
              }}
              disableClearable
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="booking_date"
              type="date"
              value={start_date === "" || end_date === "" ? booking_date : ""}
              className="input"
              label="Booking Date"
              variant="outlined"
              disabled={start_date !== "" || end_date !== ""}
              onChange={(e) => setBooking_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="collection_date"
              type="date"
              value={
                start_date === "" || end_date === "" ? collection_date : ""
              }
              className="input"
              label="Collection Date"
              variant="outlined"
              disabled={start_date !== "" || end_date !== ""}
              onChange={(e) => setCollection_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="sampleregisterd"
              variant="outlined"
              value={sample_registered}
              style={{ width: "100%", height: "40px" }}
              onChange={(e) => setSampleRegisterd(e.target.value as String)}
            >
              <MenuItem disabled value={"none"}>
                Sample Type
              </MenuItem>
              <MenuItem value={"true"}>Registerd</MenuItem>
              <MenuItem value={"false"}>Not Registerd</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="sampleregisterd"
              variant="outlined"
              value={report_status}
              style={{ width: "100%", height: "40px" }}
              onChange={(e) => setReportStatus(e.target.value as String)}
            >
              <MenuItem disabled value={"none"}>
                Report Status
              </MenuItem>
              <MenuItem value={"na"}>None</MenuItem>
              <MenuItem value={"pending"}>Pending</MenuItem>
              <MenuItem value={"partial"}>Partial</MenuItem>
              <MenuItem value={"consolidate"}>Consolidate</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="sourceType"
              variant="outlined"
              value={sourceType}
              style={{ width: "100%", height: "40px" }}
              onChange={(e) => setSourceType(e.target.value as string)}
            >
              <MenuItem disabled value={"none"}>
                SourceType
              </MenuItem>
              <MenuItem value={"cocall"}>CoCall</MenuItem>
              <MenuItem value={"hotcall"}>Hotcall</MenuItem>
              <MenuItem value={"ltv"}>Ltv</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"

              fullWidth
              onClick={filterBookings}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"

              fullWidth
              onClick={() => history.push("/dashboard/sl/cancel_booking")}
            >
              Reset
            </Button>
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            Note:
            <div style={{}}>
              <div
                style={{
                  alignItems: "left",
                  display: "inline-block",
                  margin: "1rem",
                }}
              >
                <small>
                  <ul style={{ paddingLeft: "15px" }}>
                    <li>
                      You can filter by booking date or start and end date.
                    </li>
                    <li>T: Total Additional Booking count</li>
                    <li>P: Pending count for additional member</li>
                    <li>C: Confirmed count for additional member</li>
                    <li>
                      For B2B booking first select Booking Type b2b then apply
                      any filter option
                    </li>
                  </ul>
                </small>
              </div>
              <div
                style={{
                  alignItems: "left",
                  display: "inline-block",
                  margin: "1rem",
                }}
              >
                <div className="r-cl" style={{ display: "flex" }}>
                  <div
                    className="boxStyle"
                    style={{
                      border: "1px solid",
                      width: "17px",
                      height: "17px",
                      background: "#ffe082",
                    }}
                  ></div>
                  <div style={{ marginLeft: "5px" }}>
                    B2B booking and Payable Amount is Zero
                  </div>
                </div>
                <div className="r-cl" style={{ display: "flex" }}>
                  <div
                    className="boxStyle"
                    style={{
                      border: "1px solid",
                      width: "17px",
                      height: "17px",
                      background: "#f48fb1",
                    }}
                  ></div>
                  <div style={{ marginLeft: "5px" }}>
                    B2B booking and Payable Amount is not Zero
                  </div>
                </div>
                <div className="r-cl" style={{ display: "flex" }}>
                  <div
                    className="boxStyle"
                    style={{
                      border: "1px solid",
                      width: "17px",
                      height: "17px",
                      background: "#64ffda",
                    }}
                  ></div>
                  <div style={{ marginLeft: "5px" }}>
                    B2C booking and Payable Amount is Zero
                  </div>
                </div>
                <div className="r-cl" style={{ display: "flex" }}>
                  <div
                    className="boxStyle"
                    style={{
                      border: "1px solid",
                      width: "17px",
                      height: "17px",
                      background: "default",
                    }}
                  ></div>
                  <div style={{ marginLeft: "5px" }}>
                    B2C booking and Payable Amount is not Zero
                  </div>
                </div>
              </div>
            </div>
          </Grid> */}
        </Grid>
        <br />
        {userDetails?.profile?.executive_type === "UpgradedCE"
          ? (
            <>
              {selectedLeads.length > 0 && (
                <React.Fragment>
                  <h2 style={{ textAlign: "center", marginTop: "2rem" }}>
                    Lead Transfer
                  </h2>
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item xs={12} sm={4}>
                      <Autocomplete
                        id="agentname"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                            setPanelUser(obj.id);
                          }
                        }}
                        options={agentsList.results || []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        getOptionLabel={(option: any) =>
                          option.user && option.user.username
                        }
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            getAgentList(
                              `?code=${newInputValue.replace(
                                " ",
                                ""
                              )}&usergroup=CustomerExecutive`
                            );
                          }, 1000);
                          if (newInputValue.length === 0) {
                            setPanelUser(0);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            placeholder="Agent username"
                            variant="outlined"
                            style={{ width: "100%", margin: "0", padding: "0" }}
                            required
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Button
                        variant="contained"
                        color="success"
                        fullWidth
                        onClick={transferLead}
                        disabled={
                          panel_user === null ||
                          panel_user === 0 
                         
                        }
                      >
                        Transfer
                      </Button>
                    </Grid>
                  </Grid>
                </React.Fragment>
              )}
            </>
          ) : ""}
        <PaymentModal
          openModalP={openPaymentModal}
          setOpenModalP={setOpenPaymentModal}
          paymentInformation={paymentInformation}
        />

        {bookingListLoader ? (
          <Loader />
        ) : (
          bookingList.results &&
          bookingList.results.length > 0 && (
            <Box>
              <Container maxWidth={false}>
                <Grid container spacing={3}>
                  <BookingsTable
                      setopenModal={setopenModal}
                      openModal={openModal}
                      page={page}
                      setPage={setPage}
                      loading={loading}
                      getBookings={getAllBookings}
                      bookingList={bookingList}
                      getAgentList={getAgentList}
                      agentBYUserGroup={agentBYUserGroup}
                      leadTransferByComma={leadTransferByComma}
                      getAllLeads={getAllLeads}
                      selectedLeads={selectedLeads}
                      setSelectedLeads={setSelectedLeads}
                      panel_user={panel_user}
                      setPanelUser={setPanelUser}
                      paymentId={paymentId}
                      setPaymentId={setPaymentId}
                      openPaymentModal={openPaymentModal}
                      setOpenPaymentModal={setOpenPaymentModal}
                      setTotalCount={setTotalCount}
                      setBookingId={setBookingid}
                      userDetails={userDetails}
                      newbookingList={newbookingList} 
                      getAllNewBookings={getAllNewBookings}
                      bookingListLoader={bookingListLoader}
                      />
                </Grid>
              </Container>
            </Box>
          )
        )}
        {bookingList.results && bookingList.results.length === 0 && (
          <h6 style={{ textAlign: "center" }}>No Data Found</h6>
        )}
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Note:"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div style={{ margin: "1rem" }}>
                <div style={{ alignItems: "left", display: "inline-block", margin: "1rem" }}>
                  <small>
                    <ul style={{ paddingLeft: "15px" }}>
                      <li>
                        You can filter by booking date or start and end date.
                      </li>
                      <li>T: Total Additional Booking count</li>
                      <li>P: Pending count for additional member</li>
                      <li>C: Confirmed count for additional member</li>
                      <li>
                        For B2B booking first select Booking Type b2b then apply
                        any filter option
                      </li>
                    </ul>
                  </small>
                </div>
                <div style={{ alignItems: "left", display: "inline-block", margin: "1rem" }}>
                  <small>
                    <div className="r-cl" style={{ display: "flex" }}><div className="boxStyle" style={{ border: "1px solid", width: "17px", height: "17px", background: "#ffe082", }}></div><div style={{ marginLeft: "5px" }}>B2B booking and Payable Amount is Zero</div></div>
                    <div className="r-cl" style={{ display: "flex" }}><div className="boxStyle" style={{ border: "1px solid", width: "17px", height: "17px", background: "#f48fb1", }}></div><div style={{ marginLeft: "5px" }}>B2B booking and Payable Amount is not Zero</div></div>
                    <div className="r-cl" style={{ display: "flex" }}><div className="boxStyle" style={{ border: "1px solid", width: "17px", height: "17px", background: "#64ffda", }}></div><div style={{ marginLeft: "5px" }}>B2C booking and Payable Amount is Zero</div></div>
                    <div className="r-cl" style={{ display: "flex" }}><div className="boxStyle" style={{ border: "1px solid", width: "17px", height: "17px", background: "default", }}></div><div style={{ marginLeft: "5px" }}>B2C booking and Payable Amount is not Zero</div></div>

                  </small>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>

        </Dialog>
      </main>
      <BookingCommentsModal
        openModal={openModal}
        setopenModal={setopenModal}
        bookingId={bookingId}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  agentsList: state.SalesLeadReducer.agentsList,
  partnerList: state.SalesLeadReducer.partnerList,
  bookingList: state.SalesLeadReducer.bookingList,
  cities: state.SalesLeadReducer.cities,
  phleboList: state.SalesLeadReducer.phleboList,
  loading: state.SalesLeadReducer.loading,
  lead_source: state.SalesLeadReducer.lead_source,
  paymentInformation: state.SalesLeadReducer.paymentInformation,
  agentBYUserGroup: state.SalesLeadReducer.agentBYUserGroup,
  panelUserList: state.SalesLeadReducer.panelUserList,
  verifiedbyList: state.SalesLeadReducer.verifiedbyList,
  leadStatus: state.SalesLeadReducer.leadStatus,
  userDetails: state.SalesLeadReducer.userDetails,
  bookingListLoader: state.SalesLeadReducer.bookingListLoader,
});

export default connect(mapStateToProps, {
  getAgentList,
  getPartners,
  getCities,
  getPhlebos,
  getLeadSource,
  getAllLeads,
  leadTransferByComma,
  getAllBookings,
  getPaymentInfo,
  getPanelUserList,
  getVerifiedbyList,
  getLeadStatus,
})(CustomerLeadFilterPage);
