import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { createStyles, makeStyles, useTheme, Theme, fade } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Popover from '@material-ui/core/Popover';
import AppBar from '@material-ui/core/AppBar';
import FastfoodIcon from "@material-ui/icons/Fastfood";
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import HistoryIcon from "@material-ui/icons/History";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { WhatsApp } from "@material-ui/icons";
import StoreIcon from '@material-ui/icons/Store';
import { logout, panelUserLogout } from "../../actions/loginActions";
import { useDispatch } from "react-redux";
import HomeIcon from '@material-ui/icons/Home';
import {
  ListAltRounded,
  PhoneAndroidRounded,
  Book
} from "@material-ui/icons";
import DialpadIcon from "@material-ui/icons/Dialpad";
import EqualizerRoundedIcon from '@material-ui/icons/EqualizerRounded';
import StorageIcon from '@material-ui/icons/Storage';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Tooltip } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { changePassword, getUserDetails, getFirebaseNotification, getNotificationMessage } from '../actions/HealthAdviserAdminAction';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
// import { messaging } from "../../firebase";
import { PhonelinkLock, AddBoxTwoTone, CloudUpload } from '@material-ui/icons';
import PhoneAndroid from '@material-ui/icons/PhoneAndroid';
import ConfirmationNumberRounded from '@material-ui/icons/ConfirmationNumberRounded';
import TextsmsIcon from '@material-ui/icons/Textsms';
import CallMissedIcon from '@material-ui/icons/CallMissed';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import BreakModal from '../components/Comments/breakModal'
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import InvertColorsIcon from "@material-ui/icons/InvertColors";
import Fade from "@material-ui/core/Fade";
import NewUpdateModal from '../components/Comments/newUpdate';
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import QuizIcon from '@mui/icons-material/Quiz';
import HealingIcon from '@material-ui/icons/Healing';
import { SettingsApplicationsRounded } from "@material-ui/icons";
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    title: {
      flexGrow: 1,
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      color: "#fff",
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "left",
      justifyContent: "flex-start",
      backgroundColor: "#924A91",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    dashbord: {
      color: "#fff",
      fontSize: "18px",
      fontWeight: "normal",
      padding: "20px 0px 10px 10px",
    },
    content: {
      width: "80%",
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }),
);

interface Props {
  getUserDetails: any;
  userDetails: any;
  getFirebaseNotification: any;
  getNotificationMessage: any;
  notificationMessage: any;
}
const CustomerSupport: React.FC<Props> = ({
  getUserDetails,
  getNotificationMessage,
  userDetails,
  notificationMessage,
  getFirebaseNotification,
}
) => {
  let dispatch = useDispatch();
  let history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const soundUrl = '../sounds/rising-pops.mp3';
  const [open, setOpen] = React.useState(false);
  const [changePasswordModal, setchangePasswordModal] = React.useState(false)
  const [oldPassword, setOldPassword] = React.useState("")
  const [newPassword, setNewPassword] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")
  const [openModal, setOpenModal] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const openPopover = Boolean(anchorEl);
  const [heights, setheights] = useState(window.innerHeight);

  const [notificationData, setnotificationData] = useState("");
  const [openRightMenu, setOpenRightMenu] = React.useState<boolean>(false);
  const [anchorElUpdate, setAnchorElUpdate] = React.useState(null);
  const id = open ? 'transitions-popper' : undefined;
  const [value, setValue] = React.useState<string>("");
  const handleClick = (event: any) => {
    setOpenRightMenu(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpenRightMenu(false);
  };
  useEffect(() => {
    getUserDetails();
  }, []);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    // getNotificationMessage(`?is_read=false`)
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleWindowSizeChange = () => {
    setheights(window.innerHeight);
  };
  const handleNotification = () => {
    history.push("/dashboard/c/inbox")
  };
  const updatePassword = async () => {
    const body: any = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword
    }
    await dispatch(changePassword(body))
    setchangePasswordModal(false)
    dispatch(logout())
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleBreakButton = () => {
    setOpenModal(true);
  }
  const handleLogout = () => {
    dispatch(panelUserLogout())
    dispatch(logout())
  }
  const handleUpdateDetails = (event: any) => {
    setAnchorElUpdate(anchorElUpdate ? null : event.currentTarget);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={{ backgroundColor: "#924A91" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap >
            Redcliffelabs
          </Typography>
          <div>
            <Button
              variant="text"
              onClick={(e) => handleClick(e)}
              size="medium"
              endIcon={<KeyboardArrowDownIcon />}
              style={{ color: "#fff" }}
            >
              {userDetails &&
                userDetails.profile &&
                userDetails.profile.username}
            </Button>
            <Menu
              id="fade-menu"
              open={openRightMenu}
              onClose={handleClose}
              TransitionComponent={Fade}
              anchorEl={anchorEl}
            >
              <MenuItem onClick={() => null}>
                Mobile -{" "}
                {userDetails &&
                  userDetails.profile &&
                  userDetails.profile.phonenumber}
              </MenuItem>
              <MenuItem onClick={() => setchangePasswordModal(true)}>
                Change Password
              </MenuItem>
              <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
            </Menu>
          </div>
          <IconButton>
            <DeveloperModeIcon aria-describedby={id} onClick={handleUpdateDetails} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Dialog open={changePasswordModal} 
        // onClose={() => setchangePasswordModal(false)} 
        aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
          <DialogContent>
            <TextField
              id="name"
              placeholder="Old Password"
              type="password"
              fullWidth
              style={{ width: "100%", margin: "0.3rem auto", padding: "14px !important" }}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="New Password"
              type="password"
              fullWidth
              style={{ width: "100%", margin: "0.3rem auto", padding: "14px !important" }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="Confirm Password"
              type="password"
              fullWidth
              style={{ width: "100%", margin: "0.3rem auto", padding: "14px !important" }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => updatePassword()} color="primary">
              Update
            </Button>
            {/* <Button onClick={() => setchangePasswordModal(false)} color="primary">
              Cancel
            </Button> */}
          </DialogActions>
        </Dialog>
        <div className={classes.toolbar}>
          <span className={classes.dashbord}>Dashboard</span>
          <IconButton onClick={handleDrawerClose} style={{ color: "#fff" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to="/dashboard/haa">
            <ListItem button>
              <Tooltip title="Home" arrow>
                <ListItemIcon>
                  <HomeIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Home" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/haa/booking/tickets">
            <ListItem button>
              <Tooltip title="Booking Tickets" arrow>
                <ListItemIcon>
                  <ConfirmationNumberRounded style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Booking Tickets" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/haa/breakData">
            <ListItem button>
              <Tooltip title="Break Details" arrow>
                <ListItemIcon>
                  <FastfoodIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Break Details" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/haa/calls_recording">
            <ListItem button>
              <Tooltip title="Call Recording" arrow>
                <ListItemIcon>
                  <HistoryIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Call Recording" />
            </ListItem>
          </Link>
        </List>

        <List>
          <Link to="/dashboard/haa/calls/stats">
            <ListItem button>
              <Tooltip title="Call Stats" arrow>
                <ListItemIcon>
                  <EqualizerRoundedIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Call Stats" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/haa/dispositions/leadexport">
            <ListItem button>
              <Tooltip title="Export Lead Dispositions" arrow>
                <ListItemIcon>
                  <CloudUpload style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Export Lead Dispositions" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/haa/health_data">
            <ListItem button>
              <Tooltip title="Health Data" arrow>
                <ListItemIcon>
                  <HealingIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Health Data" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/haa/helpdesk">
            <ListItem button>
              <Tooltip title="Help Desk" arrow>
                <ListItemIcon>
                  <QuizIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Help Desk" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/haa/labDetails">
            <ListItem button>
              <Tooltip title="Lab Details" arrow>
                <ListItemIcon>
                  <DialpadIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Lab Details" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/haa/leads">
            <ListItem button>
              <Tooltip title="Leads" arrow>
                <ListItemIcon>
                  <ListAltRounded style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Leads" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/haa/coupons">
            <ListItem button>
              <Tooltip title="Manage Coupons" arrow>
                <ListItemIcon>
                  <FreeBreakfastIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Manage Coupons" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/haa/users">
            <ListItem button>
              <Tooltip title="Manage Users" arrow>
                <ListItemIcon>
                  <SettingsApplicationsRounded style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Manage Users" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/haa/booking">
            <ListItem button>
              <Tooltip title="New booking" arrow>
                <ListItemIcon>
                  <AddBoxTwoTone style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="New booking" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/haa/prescription">
            <ListItem button>
              <Tooltip title="Prescription" arrow>
                <ListItemIcon>
                  <NoteAddIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Prescription" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/haa/testPackages">
            <ListItem button>
              <Tooltip title="Test Packages" arrow>
                <ListItemIcon>
                  <InvertColorsIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Test Packages" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/haa/transfered-booking">
            <ListItem button>
              <Tooltip title="Transfered booking" arrow>
                <ListItemIcon>
                  <StorageIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Transfered Booking" />
            </ListItem>
          </Link>
        </List>

        <List>
          <Link to="/dashboard/haa/whatsapp">
            <ListItem button>
              <Tooltip title="WhatsApp Messages" arrow>
                <ListItemIcon>
                  <WhatsApp style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="WhatsApp Messages" />
            </ListItem>
          </Link>
        </List>
        {/* <List>
          <Link to="/dashboard/haa/call_details">
            <ListItem button>
              <Tooltip title="Call Recording" arrow>
                <ListItemIcon>
                  <HistoryIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Call Recording" />
            </ListItem>
          </Link>
        </List> */}

        <Divider />
        <List>
          <ListItem button onClick={() => handleLogout()}>
            <Tooltip title="Logout" arrow>
              <ListItemIcon>
                <ExitToAppIcon style={{ color: "#924A91" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
        <BreakModal openModal={openModal} setOpenModal={setOpenModal} />
      </Drawer>
      <NewUpdateModal
        anchorElUpdate={anchorElUpdate}
        setAnchorElUpdate={setAnchorElUpdate}
        id={id}
      />
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  userDetails: state.HealthAdviserReducer.userDetails,
  notificationMessage: state.HealthAdviserReducer.notificationMessage,
})


export default connect(mapStateToProps, {
  getUserDetails,
  getFirebaseNotification,
  getNotificationMessage,
})(CustomerSupport);