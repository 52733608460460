
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    withStyles,
    Theme,
    createStyles,
    makeStyles,
} from "@material-ui/core/styles";
import { Grid, Select, Chip, MenuItem, TextField,Switch} from '@material-ui/core'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from 'react-router-dom';
import { TableFooter, TablePagination } from "@material-ui/core";
import moment from 'moment';
import Loader from "../../Components/loader";
import { Button } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import {updatedPackageAlias} from '../../actions/ImagingAdminAction'
import CommentIcon from '@material-ui/icons/Comment';

const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#3f51b5",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 700,
        },
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "100%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);
const DefaultSwitch:any = withStyles({
    switchBase: {
        color: "red",
        '&$checked': {
            color: "green",
        },
        '&$checked + $track': {
            backgroundColor: "green",
        },
    },
    checked: {},
    track: {
        backgroundColor: "red"
    },
})(Switch);

interface Props {
    loading: boolean;
    getImagingPackageAlias: any;
    imagingPackageAlias: any;
    updatedPackageAlias:any;
    setopenModal:any;
    setpackId:any
}

const LeadsTable: React.FC<Props> = ({
    loading,
    getImagingPackageAlias,
    imagingPackageAlias,
    updatedPackageAlias,
    setopenModal,
    setpackId,
}) => {
    const classes = useStyles();
    const history = useHistory()
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [editPackage, setEditPackage] = useState<any>({})
    const [aliasName,setAliasName]=useState<any>("")
    const [packageId,setpackageId]=useState<any>("")
    const [isActive, setIsActive] = useState(false)


    // useEffect(() => {
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const dispatch = useDispatch()
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = imagingPackageAlias.next;

            getImagingPackageAlias(url);
        } else if (newPage < page) {
            let url = imagingPackageAlias.previous;
            getImagingPackageAlias(url);
        }
        setPage(newPage);
    };

    const handleEditPackage = (pack:any) => {
        setAliasName(pack?.name)
        setpackageId(pack.id)
        setIsActive(pack?.is_active)
        setEditPackage(pack)
    }

    const handleUser = async() =>{
        const body:any = {
            name:aliasName,
            is_active:isActive
        }
        await updatedPackageAlias(packageId,body)
        setEditPackage({}) 
        getImagingPackageAlias(`?org_type=imaging`)

    }
    const handleModal = (id:any) => {
        setopenModal(true)
        setpackId(id)
    }
    return (
        <>
            <main className={classes.content}>
                <h4 style={{ fontWeight: "bold", marginTop: "2rem" }}>TEST LIST</h4>
                <div className="data-table" style={{ width: "100%" }}>
                    <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "560px" }}>
                        {loading ? <Loader /> : (
                            <Table stickyHeader aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align="center">Code</StyledTableCell>
                                        <StyledTableCell align="center">Test Name</StyledTableCell>
                                        <StyledTableCell align="center">Alias</StyledTableCell>
                                        <StyledTableCell align="center">COMMENT</StyledTableCell>
                                        <StyledTableCell align="center">Is Active </StyledTableCell>
                                        <StyledTableCell align="center">Action </StyledTableCell>

                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {imagingPackageAlias?.results && imagingPackageAlias?.results?.length > 0 && imagingPackageAlias?.results.map((pack: any, index: any) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="center">{pack?.package?.code}</StyledTableCell>
                                                <StyledTableCell align="center">{pack?.package?.name}({pack?.name})</StyledTableCell>
                                                <StyledTableCell align="center">
                                                {editPackage?.id === pack?.id ? (
                                                      <TextField
                                                      name="subject"
                                                      type="text"
                                                      value={aliasName}
                                                      className="input"
                                                      variant="outlined"
                                                      label="Alias"
                                                      placeholder="Alias"
                                                      onChange={(e) => setAliasName(e.target.value as string)}
                                                      style={{ width: "100%" }}
                                                      required
                                                  />
                                                ):pack?.alias_list}
                                                </StyledTableCell>
                                                <StyledTableCell align="center"><CommentIcon onClick={()=>handleModal(pack?.package?.id)}/></StyledTableCell>
                                                <StyledTableCell align="center">
                                                {editPackage?.id === pack?.id? (
                                                        <Switch
                                                            defaultChecked={pack?.is_active}
                                                            checked={isActive}
                                                            onChange={(e) => setIsActive(e.target.checked)}
                                                            name="checkedA"
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                    ) : (
                                                        <DefaultSwitch
                                                            checked={pack?.is_active}
                                                            name="checkedA"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            disableRipple
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                {editPackage?.id === pack?.id ? (
                                                        <Button
                                                            color="primary"
                                                            variant="text"
                                                            onClick={() => handleUser()}
                                                        >
                                                            Submit
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            color="primary"
                                                            variant="text"
                                                            onClick={() => handleEditPackage(pack)}
                                                        >
                                                            Edit
                                                        </Button>
                                                    )}
                                                </StyledTableCell>

                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TablePagination
                                        colSpan={6}
                                        count={imagingPackageAlias.count || 0}
                                        rowsPerPageOptions={[]}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                    />
                                </TableFooter>
                            </Table>
                        )}
                    </TableContainer>
                </div>
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.ImagingAdminReducer.loading,
});

export default connect(mapStateToProps,
    {
        updatedPackageAlias
    })(LeadsTable);
