import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";

import Button from '@mui/material/Button';
import { Grid, Select, MenuItem, TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TableFooter, TablePagination } from "@material-ui/core";
import Loader from "../components/Loader2";
import { generateCallRecordingUrl } from "../../helpers/generateUrl";
import { ExitToAppRounded } from "@material-ui/icons";
import { getAllRecordings, searchCall } from "../actions/bookingActions";

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

interface Props {
  loading: boolean;
  all_recordings: any;
  getAllRecordings: any;
}

const LeadsTable: React.FC<Props> = ({
  all_recordings,
  getAllRecordings,
  loading,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [id, setId] = useState<string>("");
  const [createdAt, setCreatedAt] = useState<string>("");
  const [direction, setDirection] = useState<string>("none");
  const [status, setStatus] = useState<string>("none");

  const history = useHistory();

  useEffect(() => {
    getAllRecordings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const filterLeads = () => {
    const body: any = {
      status,
      lead: id,
      created_date: createdAt,
      direction,
    };
    const url = generateCallRecordingUrl(body).substring(2);
    getAllRecordings(`?${url}`);
    setPage(0);
  };

  const viewLead = (id: any) => {
    let body = {
      search_by: "call_id",
      value: `${id}`,
    };
    dispatch(searchCall(body));
    history.push("/dashboard/ce/lead/search");
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = all_recordings.next;
      getAllRecordings(url.substring(url.indexOf("?")));
    } else if (newPage < page) {
      let url = all_recordings.previous;
      getAllRecordings(
        url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?"))
      );
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  document.querySelectorAll("#noTyping").forEach(el=>{
    el.addEventListener("keydown", function(e){
      e.preventDefault()
    })
  })

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <h4 style={{ fontWeight: "bold" }}>CALLS RECORDING </h4>
        <Grid
          container
          spacing={1}
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="leadID"
              type="number"
              value={id}
              className="input"
              variant="outlined"
              placeholder="Lead Id"
              onChange={(e) => setId(e.target.value as string)}
              style={{ width: "100%" }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              id="noTyping"
              name="Created At"
              type="date"
              label="Created Date"
              value={createdAt}
              variant="outlined"
              inputProps={{ 
                min: `${new Date().getFullYear() - 5}-${new Date().getMonth() + 1 < 10
                  ? `0${new Date().getMonth() + 1}`
                  : new Date().getMonth() + 1
                }-${new Date().getDate() < 10
                  ? `0${new Date().getDate()}`
                  : new Date().getDate()
                }`, 
                max: `${new Date().getFullYear() + 2}-${
                  new Date().getMonth() + 1 < 10
                    ? `0${new Date().getMonth() + 1}`
                    : new Date().getMonth() + 1
                }-${
                  new Date().getDate() < 10
                    ? `0${new Date().getDate()}`
                    : new Date().getDate()
                }`
              }}
              onChange={(e) => setCreatedAt(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
         
            <Select
              className="input"
              name="status"
              variant="outlined"
              value={status}
              style={{ width: "100%" ,height:"40px"}}
              onChange={(e) => setStatus(e.target.value as string)}
            >
              <MenuItem value={"none"} disabled>
                Call Picked
              </MenuItem>
              <MenuItem value={"true"}>Yes</MenuItem>
              <MenuItem value={"false"}>No</MenuItem>
            </Select>
           
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="status"
              variant="outlined"
              value={direction}
              style={{ width: "100%" ,height:"40px"}}
              onChange={(e) => setDirection(e.target.value as string)}
            >
              <MenuItem value={"none"} disabled>
                Direction
              </MenuItem>
              <MenuItem value={"incoming"}>Incoming</MenuItem>
              <MenuItem value={"outbound-api"}>Outgoing</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={filterLeads}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => history.push("/dashboard/ce/calls_recording")}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <div
          className="data-table"
          style={{ width: "100%", marginTop: "2rem" }}
        >
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "580px" }}
          >
            {loading ? (
              <Loader />
            ) : (
              <Table stickyHeader aria-label="simple table">
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell align="center">Lead ID</StyledTableCell>
                    <StyledTableCell align="center">Panel User</StyledTableCell>
                    <StyledTableCell align="center">Date/Time</StyledTableCell>
                    <StyledTableCell align="center">Status</StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                    <StyledTableCell align="center">Direction</StyledTableCell>
                    <StyledTableCell align="center">
                      Call Duration
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Hangup Cause
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Disposition
                    </StyledTableCell>
                    <StyledTableCell align="center">Recording</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {all_recordings &&
                    all_recordings.results &&
                    all_recordings.results.map((item: any) => {
                      return (
                        <StyledTableRow key={item.id}>
                          <StyledTableCell align="center">
                            {item.lead ? item.lead : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.panel_user ? item.panel_user : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.start_time ? item.start_time : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.status ? item.status : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <ExitToAppRounded
                              onClick={() => viewLead(item.lead)}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.direction ? item.direction : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.callduration ? item.callduration : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.hangup_clause ? item.hangup_clause : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.disposition ? item.disposition : "NA"}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {item.uploaded_url ? (
                              // <ReactAudioPlayer
                              //   src={row.uploaded_url}
                              //   // autoPlay
                              //   controls
                              // // onSeeked
                              // />
                              <audio controls>
                                <source
                                  src={item.uploaded_url}
                                  type="audio/wav"
                                />
                                Your browser does not support the
                                <code>audio</code> element.
                              </audio>
                            ) : null}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
                <TableFooter>
                  <TablePagination
                    colSpan={6}
                    count={all_recordings.count || 0}
                    rowsPerPageOptions={[]}
                    rowsPerPage={all_recordings?.page_size || 25}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    page={page}
                    SelectProps={{
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                  />
                </TableFooter>
              </Table>
            )}
          </TableContainer>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  all_recordings: state.BookingReducer.all_recordings,
  loading: state.BookingReducer.loading,
});

export default connect(mapStateToProps, { getAllRecordings, searchCall })(
  LeadsTable
);
