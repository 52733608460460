import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Container, Box, Grid, TextField } from "@material-ui/core";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  MenuItem,
  Select,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  getPackageAlias,
  getPackage,
  createPackageAlias,
  getPackageComments,
} from "../../actions/salesLeadAdminAction";
import PackageTable from "./packagelist";
import packagelist from "./packagelist";
import { useHistory } from "react-router";
import { genrateCenterAddressPriceFilter } from "../../../helpers/generateUrl";
import PackageCommentModal from "../../Components/comments/packageComment";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
  })
);

interface Props {
  loading: boolean;
  getPackageAlias: any;
  packageAlias: any;
  packageList: any;
  getPackage: any;
  createPackageAlias: any;
  getPackageComments: any;
  packageComments: any;
}

const CustomUploader: React.FC<Props> = ({
  loading,
  getPackageAlias,
  packageAlias,
  packageList,
  getPackage,
  createPackageAlias,
  getPackageComments,
  packageComments,
}) => {
  const timer = useRef<any>(0);
  const classes = useStyles();
  const [packageId, setPackageId] = useState<any>("");
  const [aliasName, setAliasName] = useState<any>("");
  const [alias, setAlias] = useState<any>("");
  const [openModal, setopenModal] = useState<boolean>(false);
  const [packId, setpackId] = useState<any>("");
  const history = useHistory();
  const [page, setPage] = useState(0);

  useEffect(() => {
    getPackageAlias(`?org_type=homedx&is_active=all`);
    getPackage(`org_type=homedx`);
  }, []);

  const updateAlias = async () => {
    const body: any = {
      package: packageId,
      name: alias,
    };
    await createPackageAlias(body);
    getPackageAlias(`?org_type=homedx&is_active=all`);
    history.push("/dashboard/sla/alias");
  };

  const filterCenter = async () => {
    const body: any = {
      packageid: packageId,
      packagename:  aliasName
    };
    const url = genrateCenterAddressPriceFilter(body).substring(2);
    // setCurrentUrl(url)
    getPackageAlias(`?org_type=homedx&is_active=all&${url}`);
  };

  const handleReset = () => {
    history.push(`/dashboard/sla/Alias`);
  };

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid
        container
        spacing={1}
        direction="row"
        style={{ alignItems: "right" }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            id="package_1"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setPackageId(obj?.id);
              }
            }}
            options={packageList?.results || []}
            freeSolo
            blurOnSelect
            aria-required
            limitTags={1}
            getOptionLabel={(option: any) => option?.name}
            disableClearable
            disableCloseOnSelect
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getPackage(`org_type=homedx&search=${newInputValue}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setPackageId("");
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="TEST NAME"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            name="alias"
            type="text"
            value={alias}
            className="input"
            label="ENTER ALIAS"
            variant="outlined"
            disabled={packageId === ""}
            onChange={(e) => setAlias(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={updateAlias}
            disabled={packageId === "" || alias === ""}
          >
            Create Alias
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        direction="row"
        style={{ alignItems: "right", marginTop: "1rem" }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <Autocomplete
            id="package_2"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setPackageId(obj.id);
              }
            }}
            options={packageList?.results || []}
            freeSolo
            blurOnSelect
            aria-required
            limitTags={1}
            getOptionLabel={(option: any) => option.name}
            disableClearable
            disableCloseOnSelect
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getPackage(`org_type=homedx&search=${newInputValue}`)
              }, 1000);
              if (newInputValue.length === 0) {
                setPackageId("");
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="TEST NAME"
                placeholder="You Can Search By The Test Name"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            name="aliasName"
            type="text"
            value={aliasName}
            className="input"
            label="Alias Name"
            variant="outlined"
            onChange={(e: any) => setAliasName(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={filterCenter}
            disabled={packageId === "" && aliasName=== ""}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={packageId === "" && aliasName=== ""}
            onClick={handleReset}
          >
            Reset
          </Button>
        </Grid>
      </Grid>

      <PackageTable
        getPackageAlias={getPackageAlias}
        packageAlias={packageAlias}
        setopenModal={setopenModal}
        setpackId={setpackId}
        page={page}
        setPage={setPage}
      />
      <PackageCommentModal
        packId={packId}
        openModal={openModal}
        setopenModal={setopenModal}
        getPackageComments={getPackageComments}
        packageComments={packageComments}
        loading={loading}
      />
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.SalesLeadAdminReducer.loading,
  packageAlias: state.SalesLeadAdminReducer.packageAlias,
  packageList: state.SalesLeadAdminReducer.packageList,
  packageComments: state.SalesLeadAdminReducer.packageComments,
});

export default connect(mapStateToProps, {
  getPackageAlias,
  getPackage,
  createPackageAlias,
  getPackageComments,
})(CustomUploader);
