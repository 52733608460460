import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, Paper, MenuItem, Select, TextField, Grid, TableCell, TableContainer, Table, withStyles, TableBody, TableHead, TableRow } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {  getAllRedtechTicketsUsingLead } from '../../actions/salesLeadAction';
import { useEffect } from "react";
import Loader from '../Loader2';
import { Link } from 'react-router-dom';

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: 'scroll',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
        overflow: 'scroll'
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    },
    table: {
        maxWidth: "100%",
        margin: "auto",
        overflow: 'scroll'
    },
    tableContainer: {
        marginTop: "2rem",
        position: "sticky",
        top: "200px",
        overflow: 'scroll'

    },
}));
const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },

    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

type ModalProps = {
    open: boolean;
    getAllRedtechTicketsUsingLead: any;
    leadId: number;
    setOpen: Function;
    allRedtechTicketsUsingLeadData: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
    open,
    leadId,
    getAllRedtechTicketsUsingLead,
    setOpen,
    allRedtechTicketsUsingLeadData,

}) => {
    const classes = useStyles();

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (open === true && leadId !== undefined && leadId !== null && leadId !== 0 ) {
            getAllRedtechTicketsUsingLead(leadId);
        }
    }, [open])

    const handleCross = () => {
        setOpen(false);
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.head}>
                            <h4
                                style={{
                                    marginBottom: "1rem",
                                }}
                                id="transition-modal-title"
                            >
                                Ticket History
                            </h4>
                            <CloseIcon onClick={handleCross} />
                        </div>
                        <TableContainer
                            className={classes.tableContainer}
                            component={Paper}
                            style={{ maxHeight: "540px" }}
                            >
                            {false ? (
                                <Loader />
                            ) : (
                                <Table stickyHeader aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow>
                                    <StyledTableCell align="center">Customer Name</StyledTableCell>
                                    <StyledTableCell align="center">Ticket Id</StyledTableCell>
                                    <StyledTableCell align="center">Booking Id</StyledTableCell>
                                    {/* <StyledTableCell align="center">Action</StyledTableCell>
                                    <StyledTableCell align="center">Comments</StyledTableCell> */}
                                    <StyledTableCell align="center">Packages</StyledTableCell>
                                    <StyledTableCell align="center">Ticket Type</StyledTableCell>
                                    <StyledTableCell align="center">Disposition</StyledTableCell>
                                    <StyledTableCell align="center">Sub Disposition</StyledTableCell>
                                    <StyledTableCell align="center">Initial Query</StyledTableCell>
                                    <StyledTableCell align="center">Latest Reply</StyledTableCell>
                                    <StyledTableCell align="center">Status</StyledTableCell>
                                    <StyledTableCell align="center">Created At</StyledTableCell>
                                    <StyledTableCell align="center">Follow Up </StyledTableCell>
                                    <StyledTableCell align="center">Attempted </StyledTableCell>
                                    <StyledTableCell align="center">Updated At </StyledTableCell>
                                    <StyledTableCell align="center">Created By</StyledTableCell>
                                    <StyledTableCell align="center">Received By</StyledTableCell>
                                    {/* <StyledTableCell align="center">View</StyledTableCell> */}
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {allRedtechTicketsUsingLeadData &&
                                    allRedtechTicketsUsingLeadData?.length > 0 &&
                                    allRedtechTicketsUsingLeadData?.map((ticket: any, index: any) => {
                                        return (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="center">
                                            {ticket?.booking !== null
                                                ? ticket?.booking?.customer_name
                                                : ""}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                            {ticket?.id}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                            {ticket?.booking !== null
                                                ? ticket?.booking?.id || ticket?.booking?.pk
                                                : ticket?.add_booking?.mainbooking}
                                            </StyledTableCell>
                                            {/* <StyledTableCell align="center">
                                            {(ticket?.action_status === "closed" || ticket?.action_status === "forced_closed") ?
                                                "" :
                                                <Button color="primary" variant="text">
                                                <MessageRounded
                                                    onClick={() => handleDispositionModal(ticket)}
                                                />
                                                </Button>
                                            }
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                            <div>
                                                <MessageRounded
                                                onClick={() => handleClick(ticket?.id, ticket?.disposition)}
                                                />
                                            </div>
                                            </StyledTableCell> */}
                                            <StyledTableCell align="center">
                                            {ticket?.resampling_packages && ticket?.resampling_packages?.length > 0 && 
                                                ticket?.resampling_packages.map((pack: any) => {
                                                return (
                                                    pack?.name + ", "
                                                )
                                                })
                                            }
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                            {ticket?.disposition_name}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                            {ticket?.action_disposition}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                            {ticket?.action_sub_disposition}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                            {ticket?.query}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                            {ticket?.receicer_remarks}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                            {ticket?.action_status_name}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                            {new Date(ticket?.created_at).toLocaleString()}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                            {new Date(ticket?.call_later_time).toLocaleString()}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                            {ticket?.attempted}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                            {new Date(ticket?.updated_at).toLocaleString()}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                            {ticket?.panel_user && ticket?.panel_user?.name}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                            {ticket?.received_by?.name}
                                            </StyledTableCell>
                                            {/* <StyledTableCell align="center">
                                            <Link
                                                to={`/dashboard/sl/booking-view/${ticket?.booking !== null
                                                ? ticket?.booking?.id || ticket?.booking?.pk
                                                : ticket?.add_booking?.mainbooking
                                                }`}
                                            >
                                                <Button color="secondary" variant="contained">
                                                View
                                                </Button>
                                            </Link>
                                            </StyledTableCell> */}
                                        </StyledTableRow>
                                        );
                                    })}
                                </TableBody>
                                {/* <TableFooter>
                                    <StyledTableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[]}
                                        colSpan={3}
                                        count={allRedtechTicketsUsingLeadData.count || 0}
                                        rowsPerPage={allRedtechTicketsUsingLeadData?.page_size || 20}
                                        page={page}
                                        onPageChange={handleChangePage}
                                    />
                                    </StyledTableRow>
                                </TableFooter> */}
                                </Table>
                            )}
                        </TableContainer>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    allRedtechTicketsUsingLeadData: state.SalesLeadReducer.allRedtechTicketsUsingLeadData
});

export default connect(mapStateToProps, {
    getAllRedtechTicketsUsingLead,
})(CommentsModal2);
