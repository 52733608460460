import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
	Container,
	Grid,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from '@material-ui/core';
import { useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import Relevent from "../components/Fill-Info";
import OtherUpdates from "../components/Other-Info";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getLeadDetails } from "../actions/HealthAdviserAdminAction";
import Loader from "../components/loader";
import CommentHistory from "../components/comment-history";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 0,
			//   width: "100%",
			//   marginTop: "20px",
		},
		toolbar: {
			alignItems: 'center',
			justifyContent: 'flex-end',
			padding: theme.spacing(0, 1),
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
		},
		content: {
			width: "80%",
			flexGrow: 21,
			//   padding: theme.spacing(3),
		},
		card: {
			height: "100%",
			padding: theme.spacing(1),
		},
		card_grid: {
			// wordSpacing:3,
			justifyContent: 'space-between'
		},
		paper: {
			marginTop: "2rem",
			padding: "0.5rem 2rem",
			width: "100%",
		},
		table: {
			maxWidth: "100%",
			margin: "auto"
		},
		tableContainer: {
			marginTop: "1rem",
			position: "sticky",
			top: "200px",
		},
	})
);

const StyledTableCell:any = withStyles((theme: Theme) =>
	createStyles({
		head: {
			backgroundColor: "#924A91",
			color: theme.palette.common.white,
			fontSize: "14px",
		},
		body: {
			fontSize: 14,
		},
	})
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
	createStyles({
		root: {
			"&:nth-of-type(odd)": {
				backgroundColor: theme.palette.action.hover,
			},
		},
	})
)(TableRow);

interface Props {
	children: any;
	getLeadDetails: Function;
	lead_details: any;
	loading: boolean;
}

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const CustomerExecutiveFillInfoPage: React.FC<Props> = ({
	getLeadDetails,
	lead_details,
	loading,
}) => {
	const classes = useStyles();
	let query = useQuery();
	const [id, setId] = React.useState<number>(0);
	// const [data, setData] = React.useState<object>({});
	useEffect(() => {
		getLeadDetails(query.get("id")!)
		setId(parseInt(query.get("id")!));
		// setData(lead_details);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<main className={classes.content}>
				<div className={classes.toolbar} />
				<Box>
					<Box style={{
						display: 'flex',
						justifyContent: 'flex-end',
						margin: "10px"
					}}>
						<Link to="/dashboard/haa/booking">
							<Button
								color="primary"
								variant="contained"
								style={{ margin: "5px" }}
							>
								New Booking
							</Button>
						</Link>

					</Box>
					<br />
					<Container maxWidth={false}>
						<Grid container spacing={3}>
							{!lead_details ?
								(<Loader />) : (Object.keys(lead_details).length > 0 ?
									(<>
										<Relevent id={id} data={lead_details} />
										<OtherUpdates id={id} data={lead_details} />
										<div style={{ width: "100%" }} className="data-table">
											<Paper elevation={15} className={classes.paper}>
											<TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "540px" }}>
													{loading ? <Loader /> : (
														<Table stickyHeader aria-label="simple table">
															<TableHead>
																<StyledTableRow>
																	<StyledTableCell align="center">Booking Id</StyledTableCell>
																	<StyledTableCell align="center">Booking Date</StyledTableCell>
																	<StyledTableCell align="center">Name</StyledTableCell>
																	<StyledTableCell align="center">Agent</StyledTableCell>
																	<StyledTableCell align="center">Age</StyledTableCell>
																	<StyledTableCell align="center">Gender</StyledTableCell>
																	<StyledTableCell align="center">Email</StyledTableCell>
																	<StyledTableCell align="center">Collection Date</StyledTableCell>
																	<StyledTableCell align="center">Collection Time</StyledTableCell>
																	<StyledTableCell align="center">Zone Name</StyledTableCell>
																	<StyledTableCell align="center">Mobile Number</StyledTableCell>
																	<StyledTableCell align="center">Booking Number</StyledTableCell>
																	<StyledTableCell align="center">Booking Status</StyledTableCell>
																	<StyledTableCell align="center">Verification Status</StyledTableCell>
																	<StyledTableCell align="center">Action</StyledTableCell>
																</StyledTableRow>
															</TableHead>
															<TableBody>
																{
																	lead_details.bookings && lead_details.bookings.length > 0 && lead_details.bookings.map((booking: any, index: any) => {
																		return (
																			<StyledTableRow key={index}>
																				<StyledTableCell align="center">{booking.additional_members.length > 0 ? (
																					<div>
																						{booking.pk}+
																						<Chip
																							label={`${booking.additional_members.length}`}
																							style={{ background: "#ffeb3b" }}
																						/>
																					</div>

																				) : booking.pk}</StyledTableCell>
																				<StyledTableCell align="center">{booking.booking_date}</StyledTableCell>
																				<StyledTableCell align="center">{booking.customer_name}</StyledTableCell>
																				<StyledTableCell align="center">{(booking.agent && booking.agent.name) || "NA"}</StyledTableCell>
																				<StyledTableCell align="center">{booking.customer_age}</StyledTableCell>
																				<StyledTableCell align="center">{booking.customer_gender}</StyledTableCell>
																				<StyledTableCell align="center">{booking.customer_email}</StyledTableCell>
																				<StyledTableCell align="center">{booking.collection_date}</StyledTableCell>
																				<StyledTableCell align="center">
																					{booking.collection_slot && moment(`${booking.collection_slot.slot.split("-")[0]}`, ["HH.mm.ss"]).format("hh:mm A")}
																					{" - "}
																					{booking.collection_slot && moment(`${booking.collection_slot.slot.split("-")[1]}`, ["HH.mm.ss"]).format("hh:mm A")}
																				</StyledTableCell>
																				<StyledTableCell align="center">{booking.customer_areapincode.area}</StyledTableCell>
																				<StyledTableCell align="center">{booking.customer_phonenumber}</StyledTableCell>
																				<StyledTableCell align="center">{booking.additional_members.length > 0 ? (
																					<div>
																						{booking.pk}+
																						<Chip
																							label={`${booking.additional_members.length}`}
																							style={{ background: "#ffeb3b" }}
																						/>
																					</div>

																				) : booking.pk}</StyledTableCell>
																				<StyledTableCell align="center"><Chip label={`${booking.booking_status}`} style={{ background: booking.booking_status === "pending" ? "#ffee58" : booking.booking_status === "confirmed" ? "#00e676" : booking.booking_status === "cancelled" ? "#ff3d00" : "#d500f9" }} /></StyledTableCell>
																				<StyledTableCell align="center"><Chip label={`${booking.verification_status}`} style={{ background: booking.verification_status === "pending" ? "#ffee58" : booking.verification_status === "confirmed" ? "#00e676" : booking.verification_status === "cancelled" ? "#ff3d00" : "#d500f9" }} /></StyledTableCell>
																				<StyledTableCell align="center">
																					<Link to={`/dashboard/ha/booking-view/${booking.pk}`} style={{ textDecoration: "none" }}>
																						<Button variant="contained" color="secondary">View</Button>
																					</Link>
																				</StyledTableCell>
																			</StyledTableRow>
																		)
																	})}
															</TableBody>
														</Table>
													)}
												</TableContainer>
												{lead_details.bookings && lead_details.bookings.length === 0 && (
													<h6 style={{ textAlign: "center" }}>No Data Found</h6>
												)}
											</Paper>
										</div>
									</>) : ("NA"))
							}
						</Grid>
					</Container>
					<br />
					<CommentHistory />
				</Box>
			</main>
		</>
	);
};
const mapStateToProps = (state: any) => ({
	lead_details: state.HealthAdviserAdminReducer.lead_details,
	loading: state.HealthAdviserAdminReducer.loading
});


export default connect(mapStateToProps, { getLeadDetails })(CustomerExecutiveFillInfoPage);
