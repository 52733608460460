import React, { useState, useEffect } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Grid, TextField } from '@material-ui/core';
import Button from '@mui/material/Button';
import { useRef } from "react";
import { connect } from 'react-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useHistory } from "react-router";
import Divider from '@material-ui/core/Divider';
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { getUserData, getNotificationType, sendNotification } from '../actions/bookingActions'
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        listStyle: {
            marginLeft: "20px",
        },
        formControl: {
            margin: theme.spacing(2),
            minWidth: 325,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    })
);


interface Props {
    getUserData: any;
    getNotificationType: any;
    notificationType: any;
    sendNotification: any;
    usersData: any;
}

const CenterTable: React.FC<Props> = ({
    getUserData,
    getNotificationType,
    notificationType,
    sendNotification,
    usersData,
}) => {
    const classes = useStyles()
    const history = useHistory()
    const timer = useRef<any>(0);
    const [status, setStatus] = React.useState('');
    const [userName, setUserName] = useState<any>([]);
    const [userGroup, setUserGroup] = React.useState('');
    const [title, setTitle] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [file, setFile] = React.useState<any>([]);
    useEffect(() => {
        // getNotificationType()
        getUserData()
    }, [])

    const fileSelectedHandler = (e: React.ChangeEvent<{ files: any }>) => {
        setFile(e.target.files)
    }

    const handleSnedNotification = async () => {
        const formData = new FormData();
        formData.append("title", title)
        formData.append("receiver", userName)
        formData.append("type", "")
        formData.append("notification", message)
        if (file.length > 0) {
            for (let i = 0; i < file.length; i++) {
                formData.append("image", file[i])
            }
        }
        formData.append("related_object_id", "")
        await sendNotification(formData)
        history.push("/dashboard/ce/sendNotification")
    }
    return (
        <div style={{ width: "100%" }}>
            <div className={classes.toolbar} />
            <h2> Notification Center</h2>
            <Paper elevation={15} className={classes.paper}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                    <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={status}
                        onChange={(e) => setStatus(e.target.value as string)}
                        style={{ width: "100%", height: "40px" }}
                        label="Status"
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value="individual">Individual</MenuItem>
                        <MenuItem value="usergroup">User Group</MenuItem>
                    </Select>
                </FormControl>
                {status === "individual" ? (
                    <FormControl variant="outlined" className={classes.formControl}>
                        <Autocomplete
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.username);
                                }
                                setUserName(sourceIds);
                            }}
                            freeSolo
                            multiple
                            id="checkboxes-tags-demo"
                            options={usersData.results}
                            disableCloseOnSelect
                            limitTags={1}
                            disableClearable
                            getOptionLabel={(option: any) => option.username}
                            getOptionDisabled={(option: any) => {
                                return userName.includes(option.username);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    placeholder="Receive"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required
                                />
                            )}
                        />
                    </FormControl>
                ) : status === "usergroup" ? (
                    <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="demo-simple-select-outlined-label">User Group</InputLabel>
                        <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={userGroup}
                            onChange={(e) => setUserGroup(e.target.value as string)}
                            style={{ width: "100%", height: "50px" }}
                            label="User Group"
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            <MenuItem value="SupportExecutive">Support Executive</MenuItem>
                            <MenuItem value="VerificationExecutive">Verification Executive</MenuItem>
                            <MenuItem value="CustomerSupport">Customer Support</MenuItem>
                            <MenuItem value="CustomerLead">Customer Lead</MenuItem>
                        </Select>
                    </FormControl>
                ) : ""}
                <FormControl variant="outlined" className={classes.formControl}>
                    <div className="file-uploader">
                        <input className="input" style={{ width: "100%", margin: "0", padding: "5px 5px", border: "1px solid rgba(0, 0, 0, 0.23)", borderRadius: "5px",height: "40px" }} type="file" name="file" id="file" multiple onChange={fileSelectedHandler} />

                    </div>
                </FormControl>
                <Divider />
                <form className="modal-form chat-form">
                    <TextField
                        id="standard-basic"
                        value={title}
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                            setTitle(e.target.value as string)
                        }
                        style={{ width: "100%", height: "80px", marginTop: "10px" }}
                        label="Title" />
                    <TextareaAutosize
                        aria-label="minimum height"
                        minRows={3}
                        placeholder="Write Message..."
                        value={message}
                        onChange={(e: React.ChangeEvent<{ value: unknown }>) =>
                            setMessage(e.target.value as string)
                        }
                        style={{ width: "100%", height: "150px", marginRight: "10px" }}
                    />
                    <div className="Button">
                        <Button
                            variant="contained"
                            style={{ marginTop: "1rem", backgroundColor: "#ab47bc", margin: "1rem" }}
                            onClick={() => handleSnedNotification()}
                            disabled={message===""||title===""||status===""}

                        >
                            Send Notification
                        </Button>
                        <Button
                            variant="contained"
                            style={{ marginTop: "1rem", backgroundColor: "#29b6f6", margin: "1rem" }}
                            onClick={() => history.push("/dashboard/ce/sendNotification")}
                        >
                            Reset Notification
                        </Button>
                    </div>
                </form>

            </Paper>

        </div>
    )
}

const mapStateToProps = (state: any) => ({
    usersData: state.BookingReducer.usersData,
    notificationType: state.BookingReducer.notificationType,
})

export default connect(mapStateToProps, {
    getUserData,
    getNotificationType,
    sendNotification,
})(CenterTable);

