import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Grid,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    TablePagination,
    TableFooter,
    withStyles,
    Switch,
} from '@material-ui/core';
import { Button, Chip,} from "@material-ui/core";
import './index.sass'
import { CheckCircleOutlineRounded, Edit } from "@material-ui/icons";
import { connect } from "react-redux";
import { getPanelList, getPanelsUsers,updatePanelUsers } from '../../actions/salesLeadAdminAction'
import Loader from "../loader";
import moment from "moment";
import { useHistory } from 'react-router';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        paper: {
            padding: "1rem",
            width: "100%"
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        heading: {
            fontWeight: "normal",
            fontSize: "1.2rem"
        },
        actionButton: {
            margin: "0 0.5rem",
            cursor: "pointer"
        }, tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
          },
    })
);
const StyledTableCell:any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
    
  })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);


interface Props {
    loading: Boolean;
    getPanelList: any;
    panelList: any;
    getPanelsUsers: any;
    userList: any;
    updatePanelUsers:any;
}

const AssignedPanel: React.FC<Props> = ({
    getPanelList,
    panelList,
    getPanelsUsers,
    userList,
    updatePanelUsers,
    loading
}) => {
    //const history = useHistory()
    const classes = useStyles();
    const [editUser, setEditUser] = useState("")
    const [assignedUser, setAssignedUser] = useState<any>([])
    const [assignedUserId, setAssignedUserId] = useState<any>([])
    //const [updateStatus,setUpdatedStatus]=useState<Boolean>(false)
    const timer = useRef<any>(0)
    
    useEffect(() => {
        getPanelList()
        getPanelsUsers()
    }, [])
  
    const handleUserList = (user: any) => {
        setEditUser(user.id)
        setAssignedUser(user.asigned)
        setAssignedUserId(user.asigned.map((item: any) => item.id))
    }
    

    const handleSubmit = async (user: any) => {
        setEditUser("")
        const body: any = {
            asigned:assignedUserId
        }
        await updatePanelUsers(body,user.id)
        // setTimeout(()=>{
        //     getPanelList()
        // },6000)
        getPanelList()
    }

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
               <h2>Assign Panel User</h2>
                    {loading ? <Loader /> : (
                        <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "900px" }}>
                            <Table stickyHeader aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align="center">Name</StyledTableCell>
                                        <StyledTableCell align="center">Panel User</StyledTableCell>
                                        <StyledTableCell align="center">Edit</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {panelList && panelList.results && panelList.results.length > 0 && panelList.results.map((code: any, index: any) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="center">
                                                    {code.user.username}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editUser === code.id ? (
                                                        <Autocomplete
                                                            id="Panel User"
                                                            onChange={(event, newValue) => {
                                                                if (newValue) {
                                                                    let panelUserArr: any = []
                                                                    let panelUserId: any = []
                                                                    newValue.map((val: any) => {
                                                                        let obj = JSON.parse(JSON.stringify(val, null, " "));
                                                                        panelUserArr.push(obj)
                                                                        panelUserId.push(obj.id)
                                                                    })
                                                                    setAssignedUser(panelUserArr)
                                                                    setAssignedUserId(panelUserId)
                                                                }
                                                            }}
                                                            value={assignedUser}
                                                            onInputChange={(event, newInputValue) => {
                                                                clearTimeout(timer.current)
                                                                timer.current = setTimeout(() => {
                                                                    getPanelsUsers(newInputValue)
                                                                }, 1000)
                                                            }}
                                                            multiple
                                                            disableClearable
                                                            disableCloseOnSelect
                                                            limitTags={1}
                                                            options={userList.results}
                                                            freeSolo
                                                            blurOnSelect
                                                            getOptionDisabled={(option: any) => {
                                                                return assignedUserId.includes(option.id)
                                                            }}
                                                            getOptionLabel={(option: any) => option?.user && option?.user?.name}
                                                            renderTags={(value: string[], getTagProps) =>
                                                                value.map((option: any, index: number) => (
                                                                    <Chip
                                                                        key={index}
                                                                        variant="default"
                                                                        color="primary"
                                                                        label={option?.user && option?.user?.name}
                                                                        {...getTagProps({ index })}
                                                                    />
                                                                ))
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder="Panel User"
                                                                    variant="outlined"
                                                                    style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                                    className="input"
                                                                />
                                                            )}
                                                        />
                                                    ) : (
                                                        <React.Fragment>
                                                            <div style={{padding: '5px 5px 5px 5px'}}>
                                                                {code?.asigned && code?.asigned.length > 0 && code?.asigned.map((data: any) => {
                                                                    return (
                                                                        <span key={data.id} style={{ padding: "0px 0px 0px 10px" }}>{data.user?.name}, </span>
                                                                    )
                                                                })}
                                                            </div>
                                                        </React.Fragment>
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editUser === code.id ? (
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={() => handleSubmit(code)}
                                                        >
                                                            <CheckCircleOutlineRounded />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={() => handleUserList(code)}
                                                        >
                                                            <Edit />
                                                        </Button>
                                                    )}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <StyledTableRow>
                                        {/* <TablePagination
                                            rowsPerPageOptions={[]}
                                            colSpan={3}
                                            count={(zoneList && zoneList.count) || 0}
                                            rowsPerPage={zoneList && zoneList.page_size}
                                            page={page}
                                            onPageChange={handleChangePage}
                                        /> */}
                                    </StyledTableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    )}
                    {/* {zoneList && zoneList.length === 0 && (
                        <h3 className={classes.heading} style={{ textAlign: "center" }}>No Data Found</h3>
                    )} */}
               
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.SalesLeadAdminReducer.loading,
    panelList: state.SalesLeadAdminReducer.panelList,
    userList: state.SalesLeadAdminReducer.userList,
})

export default connect(mapStateToProps, {
    getPanelList,
    getPanelsUsers,
    updatePanelUsers,
})(AssignedPanel);