import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, MenuItem, Select, TextField, Container, Box, Grid } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { setCFreshComments } from "../../../actions/bookingActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useEffect } from "react";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: "0.5rem 2rem 2rem 2rem",
        width: "100%",
        boxShadow: theme.shadows[5],
        border: "2px solid #000",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

type ModalProps = {
    // open: boolean;
    leadId: number;
    // setOpen: Function;
    setCFreshComments: any
    displayCities: any;
    centreName: any
    cityNames: any
    tempData: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
    // open,
    leadId,
    // setOpen,
    setCFreshComments,
    displayCities,
    centreName,
    cityNames,
    tempData,
}) => {
    const classes = useStyles();

    const CATEGORY: any = [
        {
            id: 39,
            text: "Price Issue ",
            city: false,
            testName: false,
            query: false,
            dateTime: false,
            testType: false,
            price: false,
            number: false
        },
        {
            id: 51,
            text: "Pathology Reports query",
            city: false,
            testName: false,
            query: false,
            dateTime: false,
            testType:false,
            price:false,
            number:false,
            pannel:false
        },
        {
            id: 12,
            text: "Test Not Available",
            city: false,
            testName: true,
            testType: true,
            query: false,
            dateTime: false,
        },
        {
            id: 1,
            text: "Interested/Follow up",
            city: false,
            leadId: false,
            dateTime: true,
            testType: false,
        },
        // {
        //     id: 2,
        //     text: "Not Interested",
        //     city: true,
        //     query: false,
        //     dateTime: false,
        // },
        // {
        //     id: 3,
        //     text: "Order Booked",
        //     city: true,
        //     query: false,
        //     dateTime: false,
        // },
        {
            id: 4,
            text: "Ringing",
            city: false,
            leadId: false,
            dateTime: false,
            testType: false,
        },
        {
            id: 5,
            text: "Number Doesn't Exist",
            city: false,
            query: false,
            dateTime: false,
            testType: false,
        },
        {
            id: 6,
            text: "By Mistake & Wrong Number",
            city: false,
            query: false,
            dateTime: false,
            testType: false,
        },
        // {
        //     id: 7,
        //     text: "Call Back",
        //     city: true,
        //     query: false,
        //     dateTime: true,
        // },
        // {
        //     id: 8,
        //     text: "Business Realated Call",
        //     city: true,
        //     query: false,
        //     dateTime: false,
        //     testType: false,
        // },
        // {
        //     id: 9,
        //     text: "Spam Call",
        //     city: false,
        //     query: false,
        //     dateTime: false,
        // },
        // {
        //     id: 10,
        //     text: "Enquiry Call",
        //     city: true,
        //     query: false,
        //     dateTime: false,
        // },
        // {
        //     id: 11,
        //     text: "CFresh",
        //     city: false,
        //     query: false,

        // },
       
        {
            id: 13,
            text: "Subscribed to Competitor",
            city: false,
            query: false,
            dateTime: false,
            testType: false,
            competitor: true,
            testName: true,
            price: true,
            number: false,
        },
        // {
        //     id: 14,
        //     text: "Slot Issue",
        //     city: false,
        //     query: false,
        //     dateTime: true,
        //     testType: false,
        // },
        // {
        //     id: 15,
        //     text: "Same Day Slot",
        //     city: true,
        //     query: false,
        //     dateTime: false,
        //     testType: false,
        // },
        // {
        //     id: 16,
        //     text: "Already Booked",
        //     city: false,
        //     query: false,
        //     dateTime: false,
        //     testType: false,
        // },
        {
            id: 17,
            text: "Not Contactable",
            city: false,
            query: false,
            dateTime: false,
            testType: false,
        },
        // {
        //     id: 18,
        //     text: "Out of Station",
        //     city: true,
        //     query: false,
        //     dateTime: false,
        //     testType: false,
        // },

        // {
        //     id: 19,
        //     text: "Out of Service Area",
        //     city: true,
        //     pincode: false,
        //     query: false,
        //     dateTime: false,
        //     testType: false,
        // },
        {
            id: 20,
            text: "Need Time to Think",
            city: false,
            query: false,
            dateTime: true,
            testType: false,
        },
        // {
        //     id: 21,
        //     text: "Email/Whatsapp Package",
        //     city: true,
        //     leadId: false,
        //     dateTime: false,
        //     testType: false,
        // },
        // {
        //     id: 22,
        //     text: "Discount/Price Enquiry",
        //     city: true,
        //     leadId: false,
        //     dateTime: false,
        //     testType: false,
        // },
        // {
        //     id: 23,
        //     text: "CS issue",
        //     city: false,
        //     leadId: true,
        //     dateTime: false,
        //     testType: false,
        // },
        // {
        //     id: 24,
        //     text: "Call Not Reachable",
        //     city: false,
        //     leadId: false,
        //     dateTime: false,
        //     testType: false,
        // },
        {
            id: 25,
            text: "Call Later",
            city: false,
            leadId: false,
            dateTime: true,
            testType: false,
        },
        {
            id: 26,
            text: "Call drop",
            city: false,
            leadId: false,
            dateTime: true,
            testType: false,
        },
        {
            id: 27,
            text: "Call Disconnected",
            city: true,
            leadId: false,
            dateTime: false,
            testType: false,
        },
        // {
        //     id: 28,
        //     text: "Below <5 year patient",
        //     city: true,
        //     leadId: false,
        //     dateTime: false,
        //     testType: false,
        // },
        {
            id: 29,
            text: "DND",
            city: false,
            leadId: false,
            dateTime: false,
            testType: false,
        },
        // {
        //     id: 30,
        //     text: "Phlebo's Issue",
        //     city: false,
        //     leadId: true,
        //     dateTime: false,
        //     testType: false,
        // },
        // {
        //     id: 31,
        //     text: "Reports Regarding Issue",
        //     city: false,
        //     leadId: true,
        //     dateTime: false,
        //     testType: false,
        //     raise: true
        // },
        // {
        //     id: 32,
        //     text: "Health Advice",
        //     city: false,
        //     leadId: true,
        //     dateTime: false,
        //     testType: false,
        // },
        // {
        //     id: 33,
        //     text: "Health Consultation",
        //     city: false,
        //     leadId: true,
        //     dateTime: false,
        //     testType: false,
        // },
        // {
        //     id: 34,
        //     text: "Resampling",
        //     city: false,
        //     leadId: false,
        //     dateTime: false,
        //     testType: false,
        // },
        {
            id: 35,
            text: "Language Barrier",
            city: false,
            leadId: false,
            dateTime: true,
            testType: false,
            language: true
        },
        // {
        //     id: 38,
        //     text: "Radiology",
        //     city: true,
        //     testName: false,
        //     query: false,
        //     dateTime: false,
        //     testType: false,
        // },
       
        {
            id: 40,
            text: "Services not available nearby",
            city: true,
            testName: false,
            query: false,
            dateTime: false,
            testType: false,
            price: false,
            number: false
        },
        {
            id: 41,
            text: "Reports Issue",
            city: false,
            testName: false,
            query: true,
            dateTime: false,
            testType: false,
            price: false,
            number: false
        },
        {
            id: 42,
            text: "Bangla Saheb",
            city: false,
            testName: true,
            query: false,
            dateTime: false,
            testType: true,
            price: false,
            number: false
        },
        {
            id: 43,
            text: "Other radiology centres",
            city: false,
            testName: true,
            query: false,
            dateTime: false,
            testType: true,
            price: false,
            number: false
        },
        {
            id: 44,
            text: "Panel Patient ",
            city: false,
            testName: true,
            language: false,
            query: false,
            dateTime: false,
            testType: false,
            price: false,
            number: false,
            pannel: true,

        },
        {
            id: 46,
            text: "Dr/technician not available",
            city: false,
            testName: false,
            query: false,
            dateTime: true,
            testType: false,
            price: false,
            number: false,
            pannel: false
        },
        {
            id: 47,
            text: "Centre active but Test unavailable",
            city: false,
            testName: false,
            query: false,
            dateTime: true,
            testType: false,
            price: false,
            number: false,
            pannel: false
        },
        {
            id: 48,
            text: "Requested prescription on Whatsapp",
            city: false,
            testName: false,
            query: false,
            dateTime: true,
            testType: false,
            price: false,
            number: false,
            pannel: false
        },
        {
            id: 49,
            text: "Pathology Reports query ",
            city: false,
            testName: false,
            query: false,
            dateTime: false,
            testType: false,
            price: false,
            number: false,
            pannel: false
        },
    ]

    const [comment, setComment] = useState<string>("");
    // const [sub_category, setSubCategory] = React.useState<string>("none");
    const [category, setCategory] = React.useState<number>(0);
    // const [lead_comment, setLeadComment] = React.useState<string>("");
    const [city, setCity] = React.useState<number>(0);
    const [cityName, setCityName] = React.useState<string>("");
    const [status, setStatus] = useState("none")
    const [query, setQuery] = useState("none")
    const [testName, setTestname] = useState("")
    const [compatitorName, setCompatitorName] = useState<any>("")
    const [testType, setTestType] = useState("")
    const [pincode, setPincode] = useState<number>()
    const [callDate, setCallDate] = useState("")
    const [callTime, setCallTime] = useState("")
    const [followup_date, setFollowupDate] = useState("")
    const [followup_time, setFollowupTime] = useState("")
    const [price, setPrice] = useState<any>("")
    const [number, setNumber] = useState<any>("")
    const [language, setLanguage] = useState<any>("")
    const [pannelName, setPannelName] = useState<any>("")
    const history = useHistory()
    const timer = useRef<any>(0)

    document.querySelectorAll("#noTyping").forEach(el=>{
        el.addEventListener("keydown", function(e){
          e.preventDefault()
        })
      })

    const bodyData = CATEGORY.filter((data: any) => data.id == category)
    const handleSubmit = async (e: any) => {
        e.preventDefault()

        let body: any = {
            category: bodyData[0].id,
            sub_category: bodyData[0].text,
            lead_comment: leadId,
            comment,
            pincode,
            test: testName !== "" ? testName : tempData.packages.map((data: any) => data.slug).join("|"),
            city: city,
            status: status,
            query: query,
            followup_date: callDate,
            followup_time: callTime,
            center: tempData?.center_address,
            cityname: tempData?.display_name,
            price: price,
            number: number,
            language: language,
            pannelName: pannelName,

        };

        if (body['city'] === 0) {
            delete body['city'];
        }
        if (body['price'] === "") {
            delete body['price'];
        }
        if (body['number'] === "") {
            delete body['number'];
        }
        if (body['pannelName'] === "") {
            delete body['pannelName'];
        }
        if (body['language'] === "") {
            delete body['language'];
        }
        if (body['followup_date'] === "") {
            delete body['followup_date'];
        }
        if (body['followup_time'] === "") {
            delete body['followup_time'];
        }
        if (body['pincode'] === 0) {
            delete body['pincode'];
        }
        if (body['test'] === 0) {
            delete body['test'];
        }
        if (body['status'] === "none") {
            delete body['status'];
        }
        if (body['query'] === "none") {
            delete body['query'];
        }
        await setCFreshComments(body);
        history.push("/dashboard/cl/booking")
        setComment("")
        setCategory(0)

    };

    let maxDate = new Date().toISOString().slice(0,10);
    let monthValue = maxDate.slice(5,7);
    let monthValueInt = parseInt(monthValue) + 1;
    let monthValueIntoString = monthValueInt === 13 ? "01" : monthValueInt < 10 ? `0${monthValueInt}` : `${monthValueInt}`;
    let yearValue = monthValueInt === 13 ? parseInt(maxDate.slice(0,4)) + 1 : parseInt(maxDate.slice(0,4));

    let maxFinal = `${yearValue}-${monthValueIntoString}-${maxDate.slice(8,10)}`

    return (
        <div>
            <div className={classes.paper}>
                <Select className="input"
                    name="action"
                    variant="outlined"
                    value={category}
                    style={{ width: "100%", marginBottom: "1rem" }}
                    onChange={(e) => setCategory(e.target.value as number)}
                    required
                >
                    <MenuItem disabled value={0}>Please Select</MenuItem>
                    {CATEGORY.map((item: any) => {
                        return (
                            <MenuItem key={item.id} value={`${item.id}`}>{item.text}</MenuItem>
                        )
                    })}
                </Select>
                <br />
                <br />
                <Grid container spacing={3}>

                    {bodyData[0] && bodyData[0].testType && (
                        <Grid item xs={12} md={6}>
                            <TextField
                                className="input"
                                name="testType"
                                variant="outlined"
                                select
                                label="Test Type"
                                value={testType}
                                style={{ width: "100%", margin: "0" }}
                                onChange={(e) => setTestType(e.target.value as string)}
                            >
                                <MenuItem value="XRay">X-Ray</MenuItem>
                                <MenuItem value="MRI">MRI</MenuItem>
                                <MenuItem value="USG">USG</MenuItem>
                                <MenuItem value="PET">PET SCAN</MenuItem>
                                <MenuItem value="NUC">NUCLEAR</MenuItem>
                                <MenuItem value="DEXA">DEXA</MenuItem>
                                <MenuItem value="MAM">Mammography</MenuItem>
                                <MenuItem value="CAR">Cardio</MenuItem>
                                <MenuItem value="NEU">Neurology</MenuItem>
                                <MenuItem value="CT">CT</MenuItem>
                                <MenuItem value="CBCT">CBCT</MenuItem>
                                <MenuItem value="PFT">PFT</MenuItem>
                                <MenuItem value="Gyne">GYNE</MenuItem>
                            </TextField>
                        </Grid>
                    )}


                    {bodyData[0] && bodyData[0].testName && (
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="test"
                                style={{ width: "100%", marginBottom: "1rem" }}
                                label="Test name"
                                variant="outlined"
                                onChange={(e) => setTestname(e.target.value as any)}
                                className="input" />
                        </Grid>
                    )}


                    {bodyData[0] && bodyData[0].competitor && (
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="test"
                                style={{ width: "100%", marginBottom: "1rem" }}
                                label="Competitor Name"
                                variant="outlined"
                                onChange={(e) => setCompatitorName(e.target.value as any)}
                                className="input" />
                        </Grid>
                    )}


                    {bodyData[0] && bodyData[0].price && (
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="test"
                                style={{ width: "100%", marginBottom: "1rem" }}
                                label="Price"
                                variant="outlined"
                                onChange={(e) => setPrice(e.target.value as any)}
                                className="input" />
                        </Grid>
                    )}


                    {bodyData[0] && bodyData[0].number && (
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="test"
                                style={{ width: "100%", marginBottom: "1rem" }}
                                label="Number"
                                variant="outlined"
                                onChange={(e) => setNumber(e.target.value as any)}
                                className="input" />
                        </Grid>
                    )}
                    {bodyData[0] && bodyData[0].language && (
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="test"
                                style={{ width: "100%", marginBottom: "1rem" }}
                                label="Language"
                                variant="outlined"
                                onChange={(e) => setLanguage(e.target.value as any)}
                                className="input" />
                        </Grid>
                    )}
                    {bodyData[0] && bodyData[0].pannel && (
                        <Grid item xs={12} md={6}>
                            <TextField
                                id="test"
                                style={{ width: "100%", marginBottom: "1rem" }}
                                label="Language"
                                variant="outlined"
                                onChange={(e) => setPannelName(e.target.value as any)}
                                className="input" />
                        </Grid>
                    )}


                </Grid>
                <>
                    {bodyData[0] && bodyData[0].pincode && (
                        <TextField
                            id="pincode"
                            style={{ width: "100%", marginBottom: "1rem" }}
                            label="Pincode"
                            type="number"
                            variant="outlined"
                            onChange={(e) => setPincode(e.target.value as any)}
                            inputProps={{ min: 99999, max: 999999 }}
                            className="input" />
                    )}
                </>
                {bodyData[0] && bodyData[0].city && (
                    <Autocomplete
                        id="cityname"
                        onChange={(event, newValue) => {
                            let cName: any = "";
                            if (newValue) {
                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                cName = obj
                            }
                            setCityName(cName);

                        }}
                        options={displayCities.cities || []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        limitTags={1}
                        getOptionLabel={(option: any) => option}
                        disableClearable
                        disableCloseOnSelect
                        renderInput={(params) => (
                            <TextField
                                className="input"
                                {...params}
                                label="City Name"
                                variant="outlined"
                                style={{ width: "100%", margin: "0", padding: "0" }}
                            />
                        )}
                    />
                )}
                <br />
                {bodyData[0] && bodyData[0].dateTime && (
                    <Grid container spacing={3} direction="row" alignItems="center" justify="center" style={{ margin: "0.5rem" }}>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                id="noTyping"
                                className="input"
                                name="call_date"
                                type="date"
                                label={bodyData[0] && bodyData[0].text + " Date"}
                                inputProps={{ 
                                    min: new Date().toISOString().slice(0,10), 
                                    max: maxFinal
                                }}
                                value={callDate}
                                variant="outlined"
                                onChange={(e) => setCallDate(e.target.value as string)}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                id="time"
                                label={bodyData[0] && bodyData[0].text + " Time"}
                                type="time"
                                value={callTime}
                                defaultValue="07:30"
                                className={classes.textField}
                                onChange={(e) => setCallTime(e.target.value as string)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
                {bodyData[0] && bodyData[0].query && (
                    <>
                        <Select className="input"
                            name="action"
                            variant="outlined"
                            value={query}
                            style={{ width: "100%", marginBottom: "1rem" }}
                            onChange={(e) => setQuery(e.target.value as string)}
                            required
                        >
                            <MenuItem disabled value={"none"}>Query Type</MenuItem>
                            <MenuItem value={"CustomerLead"}>CustomerLead</MenuItem>
                            <MenuItem value={"CustomerSupport"}>Customer Support</MenuItem>
                            <MenuItem value={"SupportExecutive"}>SupportExecutive</MenuItem>
                            <MenuItem value={"SampleAccessioning"}>SampleAccessioning</MenuItem>
                            <MenuItem value={"PhleboAdmin"}>PhleboAdmin</MenuItem>
                            <MenuItem value={"Finance"}>Finance</MenuItem>
                            {/* <MenuItem value={"Doctor"}>Doctor</MenuItem> */}
                        </Select>
                        {query !== "CustomerSupport" ? (
                            <Select className="input"
                                name="action"
                                variant="outlined"
                                value={status}
                                style={{ width: "100%", marginBottom: "1rem" }}
                                onChange={(e) => setStatus(e.target.value as string)}
                                required
                            >
                                <MenuItem disabled value={"none"}>Selecct Status</MenuItem>
                                <MenuItem value={"pending"}>Pending</MenuItem>
                                {/* <MenuItem value={"confirmed"}>Confirmed</MenuItem>
          <MenuItem value={"cancelled"}>Cancelled</MenuItem> */}
                            </Select>
                        ) : ""}

                    </>
                )}
                  <br />
                <br />
                <TextField
                    name="comment"
                    type="text"
                    multiline
                    rows={4}
                    value={comment}
                    className="input"
                    label="Comment"
                    variant="outlined"
                    placeholder="Comment"
                    onChange={(e) => setComment(e.target.value as string)}
                    style={{ width: "100%", marginBottom: "1rem" }}
                    required
                />

                <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    style={{ height: "50px" }}
                    type="submit"
                    disabled={
                        category === 0 ||
                        comment === ""
                    }
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {
    setCFreshComments
})(CommentsModal2);
