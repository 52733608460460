import React, { useState, useEffect } from "react";
import { Chip, Grid, Typography, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import Divider from "@material-ui/core/Divider";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Card from "@mui/material/Card";
import { getDashboardOverview } from "../actions/bookingActions";
import DirectionsBikeTwoToneIcon from "@material-ui/icons/DirectionsBikeTwoTone";
import BarChartTwoToneIcon from "@mui/icons-material/BarChartTwoTone";
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DoDisturbOffRoundedIcon from '@mui/icons-material/DoDisturbOffRounded';
import Avatar from "@mui/material/Avatar";
import moment from "moment";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: "100%",
      padding: "20px",
    },
    cardContent: {
      textAlign: "center",
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    text: { color: "black" },
  })
);

interface Props {
  getDashboardOverview: any;
  dashboardOverview: any;
}

const Dashboard: React.FC<Props> = ({
  getDashboardOverview,
  dashboardOverview,
}) => {
  const classes = useStyles();
  useEffect(() => {
    getDashboardOverview(`overview_date=${moment().format("YYYY-MM-DD")}`);
  }, []);

  document.querySelectorAll("#noTyping").forEach(el=>{
    el.addEventListener("keydown", function(e){
      e.preventDefault()
    })
  })

  return (
    <div style={{ width: "90%", margin: "60px auto" }}>
      <Grid
          container
          spacing={1}
          direction="row"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
        <Grid>
          <h4 style={{ fontWeight: "bold" }}>DASHBOARD</h4>
        </Grid>
        <Grid>
          <TextField
            type="date"
            id="noTyping"
            defaultValue={moment().format("YYYY-MM-DD")}
            onChange={(e) =>
              getDashboardOverview(
                `overview_date=${moment(e.target.value).format("YYYY-MM-DD")}`
              )
            }
            inputProps={{ min: `${new Date().getFullYear() - 100}-${new Date().getMonth() + 1 < 10
              ? `0${new Date().getMonth() + 1}`
              : new Date().getMonth() + 1
              }-${new Date().getDate() < 10
                ? `0${new Date().getDate()}`
                : new Date().getDate()
              }`, max: `${new Date().getFullYear() + 100}-${
                new Date().getMonth() + 1 < 10
                  ? `0${new Date().getMonth() + 1}`
                  : new Date().getMonth() + 1
              }-${
                new Date().getDate() < 10
                  ? `0${new Date().getDate()}`
                  : new Date().getDate()
              }` }}
          />
        </Grid>
      </Grid>
      {/* //booking */}
      <div style={{ margin: "10px 0px 0px 0px" }}>
        <h5>BOOKING</h5>
        <div className="cardsAppointment">
          <div>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{dashboardOverview?.total_booking}</h5>
                <span style={{ color: "#ffffff" }}>Total</span>
              </Grid>
            </Grid>
          </div>
          <div>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <CancelRoundedIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{dashboardOverview?.cancelled_booking}</h5>
                <span style={{ color: "#ffffff" }}>Cancelled</span>
              </Grid>
            </Grid>
          </div>
          <div>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <CheckCircleRoundedIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{dashboardOverview?.verified_booking}</h5>
                <span style={{ color: "#ffffff" }}>Verified</span>
              </Grid>
            </Grid>
          </div>
          <div>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <DoDisturbOffRoundedIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{dashboardOverview?.unverified_booking}</h5>
                <span style={{ color: "#ffffff" }}>Unverified</span>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      {/* //collection */}
      {/* <Grid container spacing={3} style={{ marginTop: 15 }}>
        <Grid item xs={12}>
          <div style={{ display: "flex", width: "100%" }}>
            <h6 style={{ width: 160 }}>Sample Collection</h6>
            <hr style={{ width: "95%", marginTop: 10 }} />
          </div>
        </Grid>

        <Grid item xs={12} lg={3} sm={6}>
        
          <Card className={classes.card}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <DirectionsBikeTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5>{dashboardOverview?.total_pickup}</h5>
                <span style={{ color: "grey" }}>Total</span>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} lg={3} sm={6}>
       
          <Card className={classes.card}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <DirectionsBikeTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5>{dashboardOverview?.confirm_pickup}</h5>
                <span style={{ color: "grey" }}>Confirmed</span>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12} lg={3} sm={6}>
      
          <Card className={classes.card}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <DirectionsBikeTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5>{dashboardOverview?.pending_pickup}</h5>
                <span style={{ color: "grey" }}>Pending</span>
              </Grid>
            </Grid>
          </Card>
        </Grid>

        <Grid item xs={12} lg={3} sm={6}>
       
          <Card className={classes.card}>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <DirectionsBikeTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5>{dashboardOverview?.cancelled_pickup}</h5>
                <span style={{ color: "grey" }}>Cancelled</span>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid> */}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  dashboardOverview: state.CustomerLeadReducer.dashboardOverview,
});

export default connect(mapStateToProps, {
  getDashboardOverview,
})(Dashboard);
