import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Card,
    Grid,
    MenuItem,
    Paper,
    Select,
    TextField,
    Dialog,
} from "@material-ui/core";
import {
    getPinCode,
    getPackage,
    updateBooking,
    getBookingById,
    getCoupons,
    getAvailableSlots,
    getMapMyIndiaAccessToken,
    // checkGeoFenceArea,
    checkGeoFenchMapMyIndia,
    getTimeslots,
    getUserDetails,
} from "../../../actions/HealthAdviserAction";
import {
    CITY,
    LOCALITY,
    ADDRESS_LINE1,
    ADDRESS_LINE2,
    LANDMARK,
    PINCODE,
} from "../../constant";
import Button from "@mui/material/Button";
import "./index.sass";
import { connect } from "react-redux";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router";
import { Chip } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import GooglePlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from "react-google-places-autocomplete";
import { GoogleMapsAPI } from "../../../../helpers/client-config";
import { GENDER_CHOICE } from "../../constant";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        contentHeader: {
            width: "100%",
            padding: "0px 10px 10px 10px",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            padding: "20px 20px 20px 20px",
            marginTop: "20px",
            width: "100%",
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        head: {
            textAlign: "center",
            fontWeight: "normal",
            marginBottom: "2rem",
        },
        slotContainer: {
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
        },
        slot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            margin: "0.5rem",
        },
        selectedSlot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
            cursor: "pointer",
            margin: "0.5rem",
        },
        chips: {
            display: "flex",
        },
        chip: {
            margin: 2,
            background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
            maxWidth: "130px",
        },
    })
);

interface Props {
    getPinCode: any;
    pin_code: any;
    getPackage: any;
    packageList: any;
    couponList: any;
    getCoupons: any;
    updateBooking: any;
    match: any;
    booking: any;
    getBookingById: any;
    booking_slots: any;
    getAvailableSlots: any;
    loading: boolean;
    checkGeoFenceArea: any;
    checkGeoFenceStatus: boolean;
    checkGeoFenchMapMyIndia: any;
    geoFencesMapMyIndia: any;
    getMapMyIndiaAccessToken: any;
    mapmyindiaAccessToken: any;
    getTimeslots: any;
    timeslots: any;
    userDetails: any;
    getUserDetails: any;
}

const EditBookingForm: React.FC<Props> = ({
    getPinCode,
    pin_code,
    packageList,
    getPackage,
    couponList,
    getCoupons,
    updateBooking,
    match,
    booking,
    getBookingById,
    booking_slots,
    getAvailableSlots,
    loading,
    // checkGeoFenceArea,
    // checkGeoFenceStatus,
    checkGeoFenchMapMyIndia,
    geoFencesMapMyIndia,
    getMapMyIndiaAccessToken,
    mapmyindiaAccessToken,
    getTimeslots,
    timeslots,
    userDetails,
    getUserDetails,
}) => {
    const classes = useStyles();

    const history = useHistory();
    const [cType, setCType] = useState<any>("percentage");
    const [cValue, setCValue] = useState<any>("");
    const [collection_date, setCollection_date] = useState<String>(
        booking.collection_date
    );
    const [booking_date, setBooking_date] = useState<string>(
        booking.booking_date
    );
    const [pincode, setPinCode] = useState<String>(
        booking.customer_areapincode && booking.customer_areapincode.id
    );
    const [zoneName, setZoneName] = useState<String>(
        booking.customer_areapincode && booking.customer_areapincode.area
    );
    const [timeSlots, setTimeSlots] = useState<Array<Object>>([]);
    const [selectedSlot, setSelectedSlot] = useState<Number>(
        booking.collection_slot && booking.collection_slot.id
    );

    const [customer_name, setCustomer_name] = useState<String>(
        booking.customer_name
    );
    const [customer_designation, setCustomer_designation] = useState<string>();
    const [designation, setDesignation] = useState<String>();

    const [customer_age, setCustomer_age] = useState<String>(
        booking.customer_age
    );
    const [booking_status, setBooking_status] = useState<string>(
        booking?.booking_status
    );
    const [customer_gender, setCustomer_gender] = useState<string>();
    const [customer_phonenumber, setCustomer_phonenumber] = useState<String>(
        booking.customer_phonenumber
    );
    const [customer_alternatenumber, setCustomer_alternatenumber] =
        useState<String>(booking.customer_altphonenumber);
    const [customer_whatsapppnumber, setCustomer_whatsapppnumber] =
        useState<String>(booking.customer_whatsapppnumber);
    const [customer_email, setCustomer_email] = useState<String>(
        booking.customer_email
    );
    const [customer_landmark, setCustomer_landmark] = useState<String>(
        booking?.customer_landmark
    );
    // const [sublocality, setSubLocality] = useState<any>(booking?.landmark)
    const [landmark, setLandmark] = useState<any>(null);
    // const [geoLocation, setGeoLocation] = useState<any>([]);
    const [customer_longitude, setCustomerLongitude] = useState<any>("");
    const [customer_latitude, setCustomerLatitude] = useState<any>("");
    const [customer_areapincode, setCustomer_areapincode] = useState<Number>(0);
    const [customer_areaname, setCustomer_areaname] = useState<String>(
        booking.customer_areapincode && booking.customer_areapincode.area
    );
    const [customer_address, setCustomer_address] = useState<String>(
        booking.customer_address && booking.customer_address
    );
    const [addressLine2, setAddressLine2] = useState<string>(
        booking?.address_line2 && booking?.address_line2
    );
    const [customer_areapincodeDef, setCustomer_areapincodeDef] =
        useState<Number>(booking.customer_areapincode);
    const [favoritePlace, setFavoritePlace] = useState<any>(
        booking?.landmark && booking?.landmark
    );
    const [customer_city, setCustomer_city] = useState<String>("");
    const [customer_aadhar, setCustomer_aadhar] = useState<String>(
        booking.customer_aadhar
    );
    const [verification_remark, setVerification_remark] = useState<string>(
        booking?.verification_remark
    );
    const [verification_status, setVerification_status] = useState<string>(
        booking?.verification_status
    );
    const [newBillId, setNewBillId] = useState<String>("")


    const [adharPassport, setAdharPassport] = useState(
        booking.customer_aadhar !== "" ? "customer_aadhar" : "passport_number"
    );
    const [partner, setPartner] = useState<String>(
        booking.partner && booking.partner.id
    );
    const [packageDetails, setPackageDetails] = useState<any>(booking.packages);
    const [packageKey, setPackageKey] = useState<any>(0);
    const [packages, setPackages] = useState<Number[]>(
        booking.packages &&
        booking.packages.map((pack: any) => {
            return pack.id;
        })
    );

    const [coupon, setCoupon] = useState<Number>(0);
    const [couponCode, setCouponCode] = useState<string>("");
    const [price, setPrice] = useState<any>(
        booking.discounted_price && booking.discounted_price.total_price_package
    );
    const [discount, setDiscount] = useState<any>(
        booking.discounted_price && booking.discounted_price.counpon_discount
    );
    const [totalPrice, setTotalPrice] = useState<any>(
        booking.discounted_price && booking.discounted_price.final_total_price
    );
    const [patientId, setPatientId] = useState<String>(booking.patientId)
    const [newPatientId, setNewPatientId] = useState<String>("")
    const [extraCharge, setExtraCharge] = useState<boolean>(
        booking.discounted_price && booking.discounted_price.phlebo_cost !== 0
    );
    const [bill_id, setBillId] = useState<String>(booking.bill_id)
    const [cityId, setCityId] = useState<any>(booking && booking.cityid);
    const [disposition, setDispositon] = useState<string>(booking?.setDispositon);
    const [packKey, setPackKey] = useState<any>(0);
    const [zoneId, setZoneId] = useState<any>("");
    const [couponKey, setCouponKey] = useState<any>(0);
    const [is_pincode, setIsPincode] = useState<boolean>(true);
    const [passport, setPassport] = useState<any>(
        booking?.passport_number === null ? "" : booking?.passport_number
    );
    const [aadhar, setAadhar] = useState<any>(booking?.customer_aadhar);
    const [bookingFor, setBookingFor] = useState<any>(booking?.book_for);
    const [isPrescribed, setIsPrescribed] = useState<any>(
        booking?.is_prescription
    );
    const [open1, setOpen1] = React.useState(false);
    const [openAddModal, setOpenAddModal] = useState<any>(false);
    const [mobileValidation, setMobileValidation] = useState({
        customer_alternatenumber: true,
        customer_phonenumber: true,
        customer_whatsapppnumber: true,
    });
    const bookingId = match.params.id;
    const timer = useRef<any>(0);

    //corprate booking
    const [bId, setBId] = useState<any>("");
    const [aId, setAId] = useState<any>("");
    const [type, setType] = useState<any>("");

    useEffect(() => {
        if (booking?.coupon_applied) {
            setCoupon(booking?.coupon_applied.id);
            setCouponCode(booking?.coupon_applied?.name);
        }
        if (booking?.designation && booking?.customer_gender) {
            setCustomer_designation(booking?.designation);
            const findGender = GENDER_CHOICE.filter(
                (el: any) => el.db_name == booking?.designation
            );
            if (
                booking?.designation == "Master" &&
                booking?.customer_gender == "male"
            ) {
                setDesignation("Baby Boy");
            } else if (
                booking?.designation == "Master" &&
                booking?.customer_gender == "female"
            ) {
                setDesignation("Baby Girl");
            } else {
                findGender.length > 0 && setDesignation(findGender[0].display_name);
            }
            setCustomer_gender(booking?.customer_gender);
        }
        getUserDetails();
    }, []);

    useEffect(() => {
        getPackage(`city_id=${cityId}&gender=${customer_gender}`);
        getCoupons("?org_type=homedx");
        getMapMyIndiaAccessToken();
    }, []);

    useEffect(() => {
        getBookingById(`${bookingId}/`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingId]);

    useEffect(() => {
        getArea(
            collection_date,
            booking.customer_areapincode && booking.customer_areapincode.pincode
        );
        setCustomer_areapincode(booking?.customer_areapincode.id);
        setPassport(
            booking?.passport_number === null ? "" : booking?.passport_number
        );
        setAadhar(booking?.customer_aadhar);
        setBookingFor(booking?.book_for);
        setIsPrescribed(booking?.is_prescription);
    }, [booking]);

    useEffect(() => {
        const slots = booking_slots && booking_slots.results;
        setTimeSlots(slots && slots[0].zone_data && slots[0].zone_data.time_slots);
    }, [booking_slots]);

    const handlePinCode = (val: String) => {
        setPinCode(val as string);
        // getArea(val as String)
        getArea(collection_date, val as String);
        const { id, area, city, cityid } = pin_code.results.find(
            (code: any) => code.pincode === val
        );
        setCustomer_areapincode(id as Number);
        getPackage(`city_id=${cityid}&gender=${customer_gender}`);
        setCustomer_areaname(area as String);
        setCustomer_city(city as String);
        setCityId(cityid);
        setIsPincode(false);
        setPackageDetails([]);
        setPackages([]);
        setPackageKey(cityid);
        setCoupon(0);
        setCouponKey(cityid);
    };
    const getArea = (collection_date: String, pincode: String) => {
        const zone =
            pin_code.results &&
            pin_code.results.find((code: any) => code.pincode === pincode);
        setZoneName(zone && (zone.area as String));
        if (zone && zone.zone_data && zone.zone_data.id) {
            if (booking?.centre_address === null) {
                getAvailableSlots(
                    collection_date,
                    zone && zone.zone_data && zone.zone_data.id
                );
            } else {
                getTimeslots();
            }
        }

        setCustomer_areapincode(zone && (zone.id as Number));
        setCustomer_city(zone && (zone.city as String));
        setZoneId(zone && zone.zone_data && zone.zone_data.id);
    };

    const checkNormalPackage = (packages: any) => {
        let isNormalPackage = false;
        packages &&
            packages.length > 0 &&
            packages.map((item: any) => {
                if (!item.is_addon) {
                    isNormalPackage = true;
                }
            });
        return isNormalPackage;
    };

    const getNoofPackageBuyOneGetOne = () => {
        let noOfBuyOneGetOne = 0;
        booking.additional_members &&
            booking.additional_members.map((item: any) => {
                if (item?.packages && item?.packages.length > 0) {
                    item?.packages.map((packageItem: any) => {
                        if (packageItem?.code == "PL50") {
                            noOfBuyOneGetOne = noOfBuyOneGetOne + 1;
                        }
                    });
                }
            });
        return noOfBuyOneGetOne;
    };

    const handlePackages = (customerPackage: any) => {
        const min_phlebo_cost =
            packageList &&
            packageList?.results &&
            packageList?.results.length > 0 &&
            packageList?.results[0].min_booking_amount_for_phlebo;
        setPackageDetails(customerPackage);
        let accumulatedPrice: any = 0;
        let packIds: any = [];

        if (customerPackage && customerPackage.length > 0) {
            let atLeastOneNormalPackage = checkNormalPackage(customerPackage);
            let pl50Count =
                packageDetails?.length > 0 ? getNoofPackageBuyOneGetOne() : 0;
            let priceApplicable = pl50Count > 0 ? pl50Count % 2 : 0;
            customerPackage.map((item: any) => {
                packIds.push(item.id);
                if (pl50Count > 0 && priceApplicable == 1 && item?.code == "PL50") {
                    accumulatedPrice = accumulatedPrice + 0;
                } else {
                    if (
                        atLeastOneNormalPackage &&
                        item.is_addon &&
                        item.addon_price &&
                        item.addon_price > 0
                    ) {
                        accumulatedPrice = accumulatedPrice + item.addon_price;
                    } else {
                        if (item.package_city_prices === null) {
                            accumulatedPrice = accumulatedPrice + item.offer_price;
                        } else {
                            accumulatedPrice =
                                accumulatedPrice + item.package_city_prices.offer_price;
                        }
                    }
                }
                if (accumulatedPrice < min_phlebo_cost) {
                    setExtraCharge(true);
                } else {
                    setExtraCharge(false);
                }
            });
        }
        // Edit Family Members
        if (
            booking?.additional_members &&
            booking?.additional_members?.length > 0
        ) {
            booking.additional_members.map((item: any) => {
                if (item.packages) {
                    let atLeastOneNormalPackage = checkNormalPackage(item?.packages);
                    item.packages.map((pack: any) => {
                        if (
                            atLeastOneNormalPackage &&
                            pack.is_addon &&
                            pack.addon_price &&
                            pack.addon_price > 0
                        ) {
                            accumulatedPrice = accumulatedPrice + pack.addon_price;
                        } else {
                            if (pack.package_city_prices === null) {
                                accumulatedPrice = accumulatedPrice + pack.offer_price;
                            } else {
                                accumulatedPrice =
                                    accumulatedPrice + pack.package_city_prices.offer_price;
                            }
                            if (accumulatedPrice < min_phlebo_cost) {
                                setExtraCharge(true);
                            } else {
                                setExtraCharge(false);
                            }
                        }
                    });
                }
            });
        }
        setPackages(packIds);
        setPrice(Math.floor(accumulatedPrice));
    };

    useEffect(() => {
        if (extraCharge) {
            setTotalPrice(Math.floor(price - discount + 200));
        } else {
            setTotalPrice(Math.floor(price - discount));
        }
    }, [price, discount, extraCharge]);

    useEffect(() => {
        if (totalPrice < 0) {
            setTotalPrice(0);
        }
    }, [totalPrice]);

    const applyCoupon = () => {
        if (couponCode === "") {
            setCouponCode("");
            setCoupon(0);
            setDiscount(0);
            return;
        }
        const appliedCoupon =
            couponList.results.find((coup: any) => coup.id === coupon) ||
            couponList.results.find((coup: any) => coup.code === couponCode);
        if (appliedCoupon && appliedCoupon.minimum_price < totalPrice) {
            setCoupon(appliedCoupon.id);
            if (appliedCoupon.discount_type.toLowerCase() === "percentage") {
                if (appliedCoupon.is_red_cash) {
                    setDiscount(0);
                } else {
                    setDiscount(Math.floor((price * appliedCoupon.discount) / 100));
                }
            }
            if (appliedCoupon.discount_type.toLowerCase() === "amount") {
                if (appliedCoupon.is_red_cash) {
                    setDiscount(0);
                } else {
                    setDiscount(Math.floor(appliedCoupon.discount));
                }
            }
        } else {
            setCouponCode("");
            setCoupon(0);
            setDiscount(0);
        }
    };
    const applyCouponPerc = () => {
        if (cValue === "") {
            setCouponCode("");
            setCoupon(0);
            setDiscount(0);
            return;
        }
        const appliedCoupon = { cType: cType, cValue: cValue };
        if (appliedCoupon.cValue < totalPrice) {
            if (appliedCoupon.cType === "percentage") {
                if (appliedCoupon.cValue > 100) {
                    alert("Please Select Valid Number");
                }
                setDiscount(Math.floor((price * appliedCoupon.cValue) / 100));
            }
            if (appliedCoupon.cType === "amount") {
                setDiscount(Math.floor(appliedCoupon.cValue));
            }
        } else {
            setCouponCode("");
            setCoupon(0);
            setDiscount(0);
        }
    };
    const verifyMobile = (type: any) => {
        if (type === "mobile") {
            if (
                parseInt(customer_phonenumber && customer_phonenumber[0]) < 6 ||
                (customer_phonenumber && customer_phonenumber.length !== 10)
            ) {
                setMobileValidation((prev: any) => ({
                    ...prev,
                    customer_phonenumber: false,
                }));
            }
        }
        if (type === "alternate") {
            if (
                parseInt(customer_alternatenumber && customer_alternatenumber[0]) < 6 ||
                (customer_alternatenumber && customer_alternatenumber.length !== 10)
            ) {
                setMobileValidation((prev: any) => ({
                    ...prev,
                    customer_alternatenumber: false,
                }));
            }
        }
        if (type === "whatsapp") {
            if (
                parseInt(customer_whatsapppnumber && customer_whatsapppnumber[0]) < 6 ||
                (customer_whatsapppnumber && customer_whatsapppnumber.length !== 10)
            ) {
                setMobileValidation((prev: any) => ({
                    ...prev,
                    customer_whatsapppnumber: false,
                }));
            }
        }
    };
    const submitForm = async (e: any) => {
        e.preventDefault();
        const data: any = {
            booking_date: moment(new Date()).format("YYYY-MM-DD"),
            collection_date: collection_date,
            collection_slot: selectedSlot,
            customer_name: customer_name,
            customer_age: customer_age,
            customer_gender: customer_gender,
            customer_email: customer_email,
            customer_phonenumber: customer_phonenumber,
            customer_altphonenumber: customer_alternatenumber,
            customer_whatsapppnumber: customer_whatsapppnumber,
            customer_address: customer_address,
            customer_landmark: customer_landmark,
            customer_longitude: customer_longitude,
            customer_latitude: customer_latitude,
            customer_areapincode: customer_areapincode,
            customer_city: customer_city,
            customer_aadhar: aadhar,
            passport_number: passport === null ? "" : passport,
            packages: packages,
            coupon_applied: coupon,
            coupon_amount: Number(cValue),
            coupon_type: cType,
            // "partner": booking_source,
            designation: customer_designation,
            address_line2: addressLine2,
            is_prescription: isPrescribed,
            landmark: favoritePlace,
            verification_remark: verification_remark,
            verification_status: verification_status,

            booking_status: booking_status,
            disposition: disposition,
            patientId: newPatientId,
            "bill_id": newBillId,
        };
        if (cValue === "") {
            delete data.coupon_amount;
            delete data.coupon_type;
        }
        if (data["coupon_applied"] === 0 || couponCode === "") {
            delete data["coupon_applied"];
        }

        // if (removeCoupn && !data['coupon_applied']) {
        //     data['remove_coupon'] = "true"
        // }
        await updateBooking(data, bookingId);
        history.push(`/dashboard/ha/booking-view/${bookingId}`);
    };
    const changeCollectionDate = (e: any) => {
        setSelectedSlot(0);
        setCollection_date(e.target.value as string);
        if (zoneId) {
            getPackage(`city_id=${cityId}&gender=${customer_gender}`);
            if (booking?.centre_address === null) {
                getAvailableSlots(e.target.value, zoneId);
            } else {
                getTimeslots();
            }
        }
    };

    useEffect(() => {
        setCustomer_landmark(landmark ? landmark.label : booking.customer_landmark);
        if (landmark?.label) {
            geocodeByAddress(landmark?.label)
                .then((results) => getLatLng(results[0]))
                .then(({ lat, lng }) => {
                    lat && setCustomerLatitude(lat);
                    lng && setCustomerLongitude(lng);
                });
        }
    }, [landmark]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAdharPassport((event.target as HTMLInputElement).value);
    };

    const titleSelection = (title: string) => {
        const findGender = GENDER_CHOICE.filter(
            (el: any) => el.display_name == title
        );
        setDesignation(title);
        getPackage(`city_id=${booking?.cityid}&org_type=homedx&gender=${findGender[0]?.gender}`);
        setCustomer_designation(findGender[0].db_name);
        setCustomer_gender(findGender[0].gender);
    };
    //mapmyindia
    const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
    const [mapAddressObject, setMapAddressObject] = useState<any>({});
    const [accessToken, setAccessToken] = useState<any>("");
    useEffect(() => {
        if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
            setCustomerLatitude(geoFencesMapMyIndia?.latitude);
            setCustomerLongitude(geoFencesMapMyIndia?.longitude);
        }
    }, [geoFencesMapMyIndia]);

    useEffect(() => {
        if (mapmyindiaAccessToken?.accesstoken) {
            setAccessToken(mapmyindiaAccessToken?.accesstoken);
        }
    }, [mapmyindiaAccessToken]);

    useEffect(() => {
        if (mapAddressObject?.eLoc) {
            getPinCode(mapAddressObject?.addressTokens?.pincode);
            setCustomer_landmark(mapAddressObject?.placeAddress);
            checkGeoFenchMapMyIndia(mapAddressObject?.eLoc, "NA");
        }
    }, [mapAddressObject]);

    useEffect(() => {
        if (pin_code?.results && pin_code?.results.length > 0) {
            setCityId(pin_code?.results[0].cityid);
            setPinCode(pin_code?.results[0].pincode);
        }
    }, [pin_code]);

    const locationAutoCompleteSearch = (val: string) => {
        if (val.length > 2) {
            setSuggestedAddres([]);
            const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
            return new Promise(function (resolve, reject) {
                const id = "_" + Math.round(10000 * Math.random());
                const callbackName = "jsonp_callback_" + "data" + id;
                window[callbackName] = (data: any) => {
                    delete window[callbackName];
                    const ele = document.getElementById(id);
                    ele?.parentNode?.removeChild(ele);
                    resolve(data?.suggestedLocations);
                    setSuggestedAddres(data?.suggestedLocations);
                };
                const src = urlForMap + "&callback=" + callbackName;
                const script = document.createElement("script");
                script.src = src;
                script.id = id;
                script.addEventListener("error", reject);
                (
                    document.getElementsByTagName("head")[0] ||
                    document.body ||
                    document.documentElement
                ).appendChild(script);
            });
        }
    };
    const handleAddTest = (bid: any, aid: any, type: any) => {
        setOpenAddModal(true);
        setBId(bid);
        setType(type);
        setAId(aid);
    };
    const handleCloseAddModal = () => {
        setOpen1(false);
        setOpenAddModal(false);
        getBookingById(`${bookingId}`);
    };
    const handleRemoveModel = (bid: any, aid: any, type: any) => {
        setOpen1(true);
        setBId(bid);
        setType(type);
        setAId(aid);
    };

    const BOOKING_DISPOSITION: any = [
        {
            id: 8,
            name: "Time Slot Changed"
        },
        {
            id: 9,
            name: "Cancel-Out Of Station"
        },
        {
            id: 10,
            name: "Cancel-Travel Plan Changed"
        },
        {
            id: 11,
            name: "Cancel-Duplicate Booking"
        },
        {
            id: 12,
            name: "Cancel"
        },
        {
            id: 21,
            name: "Booked From Different Lab"
        },
        {
            id: 15,
            name: "Wrong Number"
        },
        {
            id: 22,
            name: "Cancel-Out Of Service Area"
        },
        {
            id: 23,
            name: "Confirmed"
        },
        {
            id: 24,
            name: "Rescheduled"
        },
        {
            id: 14,
            name: "Call Later"
        },
        {
            id: 54,
            name: "Cancel-Customer did not picked the call",
        },
        {
            id: 55,
            name: "Cancel-Wrong Commitment by Sales/Phelbo",
        },
        {
            id: 56,
            name: "Cancel-Number Switched Off",
        },
        {
            id: 57,
            name: "Cancel-Patient Hospitalized",
        },
        {
            id: 58,
            name: "Sample Collected",
        },
        {
            id: 59,
            name: "Phlebo On The Way/Phlebo At Location",
        },
        {
            id: 60,
            name: "Cancel-Requested Time Slot Not Given",
        },
        {
            id: 61,
            name: "Booking Reschedule-Customer Not in Fasting",
        },
        {
            id: 62,
            name: "Booking Reschedule-Phlebo did not reach on time",
        },
    ]
    document.querySelectorAll("#noTyping").forEach(el=>{
        el.addEventListener("keydown", function(e){
          e.preventDefault()
        })
      })

    //mapmyindia
    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Dialog open={openAddModal}>
                <Grid container md={12} style={{ padding: "15px 20px 0 20px" }}>
                    <Grid item xs={6} style={{ textAlign: "left" }}>
                        <h4>Test Addon</h4>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleCloseAddModal}
                        >
                            Close
                        </Button>
                    </Grid>
                </Grid>

               
            </Dialog>
            <Dialog open={open1}>
                <Grid container md={12} style={{ padding: "15px 20px 0 20px" }}>
                    <Grid item xs={6} style={{ textAlign: "left" }}>
                        <h4>Remove Test</h4>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleCloseAddModal}
                        >
                            Close
                        </Button>
                    </Grid>
                </Grid>

                
            </Dialog>
            <div className={classes.contentHeader}>
                <Grid
                    container
                    direction="row"
                    alignItems="flex-start"
                    justify="space-between"
                    spacing={3}
                // style={{ margin: "1rem auto" }}
                >
                    <Grid item xs={6} sm={6} md={6}>
                        <h3
                            style={{
                                paddingTop: "10px",
                                fontSize: "24px",
                                fontWeight: "bold",
                            }}
                        >
                            EDIT BOOKING
                        </h3>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <h3
                            style={{
                                paddingTop: "10px",
                                fontSize: "24px",
                                fontWeight: "bold",
                            }}
                        >
                            {booking?.centre_address !== null
                                ? "CENTRE : " +
                                String(booking?.centre_address?.name).toUpperCase()
                                : "HOME-DX BOOKING"}
                        </h3>
                    </Grid>
                </Grid>
            </div>
            <Paper className={classes.paper} elevation={15}>
                <form onSubmit={submitForm}>
                    <Grid container spacing={3} style={{ margin: "auto" }}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <p>Booking date</p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <input
                                        className="input"
                                        name="collection_date"
                                        type="date"
                                        value={booking_date || ""}
                                        style={{
                                            width: "100%",
                                            margin: "0",
                                            padding: "18.5px 14px",
                                            height: "40px",
                                        }}
                                        required
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <p>Sample Collection date</p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <input
                                        className="input"
                                        name="collection_date"
                                        type="date"
                                        value={
                                            (collection_date && collection_date.toString()) || ""
                                        }
                                        // onChange={(e) => setCollection_date(e.target.value as string)}
                                        onChange={(e) => changeCollectionDate(e)}
                                        style={{
                                            width: "100%",
                                            margin: "0",
                                            padding: "18.5px 14px",
                                            height: "40px",
                                        }}
                                        id="noTyping"
                                        max={`${new Date().getFullYear() + 1}-${
                                        new Date().getMonth() + 1 < 10
                                            ? `0${new Date().getMonth() + 1}`
                                            : new Date().getMonth() + 1
                                        }-${
                                        new Date().getDate() < 10
                                            ? `0${new Date().getDate()}`
                                            : new Date().getDate()
                                        }`}
                                        // min={`${new Date().getFullYear()}-${(new Date().getMonth() + 1) < 10 ? `0${(new Date().getMonth() + 1)}` : (new Date().getMonth() + 1)}-${new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()}`}
                                        required
                                    />
                                </Grid>
                            </Grid>
                            {/* <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Pin Code</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="pincode"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        handlePinCode(obj.pincode as String);
                      }
                    }}
                    defaultValue={
                      customer_areapincodeDef || {
                        id: "",
                        pincode: "",
                        area: "",
                      }
                    }
                    options={pin_code.results}
                    freeSolo
                    blurOnSelect
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getPinCode(newInputValue.split(",")[0]);
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) => `${option.pincode}`}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Pin Code"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        className="input"
                        disabled={booking.phlebo !== null}
                        required
                      />
                    )}
                    disabled
                  />
                </Grid>
              </Grid> */}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid
                                container
                                spacing={0}
                                style={{
                                    padding: "10px",
                                    background: "#e1f5fe",
                                    marginLeft: "-10px",
                                }}
                            >
                                <Grid item xs={12} md={6}>
                                    <p>Locality </p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <p>
                                        : <strong>{customer_landmark}</strong>
                                        &nbsp;{" "}
                                    </p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <p>House No./Plot No./Flat No./Door No./Shop/C/-</p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <p>
                                        : <strong>{customer_address}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <p>
                                        Apartment/Building/Colony/Block/Sector/
                                        Street/Gali/Road/Chawl
                                    </p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <p>
                                        : <strong>{addressLine2}</strong>
                                    </p>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p>Landmark/Sublocality</p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <p>
                                        : <strong>{favoritePlace}</strong>
                                    </p>
                                </Grid>
                                {/* <Grid item xs={12} md={6}>
                  <p>Area Name </p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>
                    : <strong>{zoneName}</strong>
                  </p>
                </Grid> */}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-end"
                        spacing={3}
                        style={{ margin: "1rem auto", background: "#eff" }}
                    >
                        <Grid item md={3}>
                            <p>Mobile Number</p>
                            <TextField
                                name="customer_phonenumber"
                                type="text"
                                value={customer_phonenumber}
                                placeholder="Enter Mobile Number"
                                className="input"
                                variant="outlined"
                                disabled
                                style={{ margin: "0", width: "100%" }}
                                onChange={(e) =>
                                    setCustomer_phonenumber(e.target.value as String)
                                }
                                onBlur={() => verifyMobile("mobile")}
                                onFocus={() => {
                                    setMobileValidation((prev: any) => ({
                                        ...prev,
                                        customer_phonenumber: true,
                                    }));
                                }}
                                helperText={
                                    !mobileValidation.customer_phonenumber &&
                                    "Incorrect Phone Number"
                                }
                                required
                            />
                        </Grid>
                        <Grid item md={3}>
                            <p>Alternate Mobile Number</p>
                            <TextField
                                name="customer_alternatenumber"
                                type="text"
                                value={customer_alternatenumber}
                                placeholder="Enter Alternate Mobile Number"
                                className="input"
                                variant="outlined"
                                style={{ margin: "0", width: "100%" }}
                                onChange={(e) =>
                                    setCustomer_alternatenumber(e.target.value as String)
                                }
                                required
                                onBlur={() => verifyMobile("alternate")}
                                onFocus={() => {
                                    setMobileValidation((prev: any) => ({
                                        ...prev,
                                        customer_alternatenumber: true,
                                    }));
                                }}
                                helperText={
                                    !mobileValidation.customer_alternatenumber &&
                                    "Incorrect alternate Number"
                                }
                            />
                        </Grid>
                        <Grid item md={3}>
                            <p>Whatsapp Number</p>
                            <TextField
                                name="customer_whatsapppnumber"
                                type="text"
                                value={customer_whatsapppnumber}
                                placeholder="Enter Whatsapp Mobile Number"
                                className="input"
                                variant="outlined"
                                style={{ margin: "0", width: "100%" }}
                                onChange={(e) =>
                                    setCustomer_whatsapppnumber(e.target.value as String)
                                }
                                required
                                onBlur={() => verifyMobile("whatsapp")}
                                onFocus={() => {
                                    setMobileValidation((prev: any) => ({
                                        ...prev,
                                        customer_whatsapppnumber: true,
                                    }));
                                }}
                                helperText={
                                    !mobileValidation.customer_whatsapppnumber &&
                                    "Incorrect Whatsapp Number"
                                }
                            />
                        </Grid>
                        <Grid item md={3}>
                            <p>Email</p>
                            <TextField
                                name="customer_email"
                                type="email"
                                placeholder="Enter Email"
                                value={customer_email}
                                className="input"
                                variant="outlined"
                                style={{ margin: "0", width: "100%" }}
                                onChange={(e) => setCustomer_email(e.target.value as String)}
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        spacing={3}
                        style={{ margin: "1rem auto", background: "#e1f5fe" }}
                    >
                        <Grid item md={2}>
                            <p>Title</p>
                            <Select
                                className="input"
                                name="customer_designation"
                                variant="outlined"
                                value={designation || ""}
                                style={{ width: "100%", margin: "0", height: "40px" }}
                                onChange={(e) => titleSelection(e.target.value as string)}
                                required
                            >
                                <MenuItem value="Mrs">Mrs</MenuItem>
                                <MenuItem value="Miss">Miss</MenuItem>
                                <MenuItem value="Ms">Ms</MenuItem>
                                <MenuItem value="Smt">Smt</MenuItem>
                                <MenuItem value="Mr">Mr</MenuItem>
                                <MenuItem value="Baby Boy">Baby Boy</MenuItem>
                                <MenuItem value="Baby Girl">Baby Girl</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={2}>
                            <p>Customer Name</p>
                            <TextField
                                name="customer_name"
                                type="text"
                                placeholder="Enter Name"
                                value={customer_name}
                                className="input"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ width: "100%", margin: "0" }}
                                onChange={(e) => setCustomer_name(e.target.value.replace(/[^A-Za-z- ']/, '') as String)}
                                required
                            />
                        </Grid>
                        <Grid item md={2}>
                            <p>Age</p>
                            <TextField
                                name="customer_age"
                                type="number"
                                placeholder="Enter Age"
                                value={customer_age}
                                className="input"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ width: "100%", margin: "0" }}
                                inputProps={{ min: 0, max: 150 }}
                                onChange={(e) => {
                                    let ageValue = parseInt(e.target.value);
                                    if (ageValue > 150) ageValue = 150;
                                    if (ageValue < 0) ageValue = 0;
                                    e.target.value = ageValue.toString()
                                    setCustomer_age(e.target.value as any)
                                }}
                                required
                            />
                        </Grid>
                        <Grid item md={2}>
                            <p>Gender</p>
                            <TextField
                                className="input"
                                name="customer_gender"
                                variant="outlined"
                                value={customer_gender === "male" ? "Male" : customer_gender === "female" ? "Female" : ""}
                                style={{
                                    width: "100%",
                                    margin: "0",
                                    padding: "0",
                                    height: "40px",
                                }}
                                onChange={(e) => setCustomer_gender(e.target.value as string)}
                                required
                                disabled
                            />
                        </Grid>
                        {booking?.center?.center_type == "corporate" ? (
                            <>
                                <Grid item md={2}>
                                    <p>Add Package</p>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ width: "100%", margin: "0", height: 55 }}
                                        onClick={() =>
                                            handleAddTest(
                                                booking?.id === undefined ? booking?.pk : booking?.id,
                                                "aid",
                                                "booking"
                                            )
                                        }
                                    >
                                        Add Package
                                    </Button>
                                </Grid>
                                <Grid item md={2}>
                                    <p>Remove Package</p>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        style={{ width: "100%", margin: "0", height: 55 }}
                                        onClick={() =>
                                            handleRemoveModel(
                                                booking?.id === undefined ? booking?.pk : booking?.id,
                                                "aid",
                                                "booking"
                                            )
                                        }
                                    >
                                        Remove
                                    </Button>
                                </Grid>
                            </>
                        ) : (

                            <>
                                <Grid item md={2}>
                                    <p>Package Name</p>
                                    <Autocomplete
                                        id="package"
                                        key={packageKey}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                let packs: any = [];
                                                newValue.map((val: any) => {
                                                    let obj = JSON.parse(JSON.stringify(val, null, " "));
                                                    packs.push(obj);
                                                });
                                                handlePackages(packs);
                                                setPackKey(1);
                                            }
                                        }}
                                        multiple
                                        options={packageList.results}
                                        value={packageDetails}
                                        disableClearable
                                        limitTags={0}
                                        getOptionLabel={(option: any) =>
                                            option.name && option.package_city_prices
                                                ? option.name.toString() +
                                                ", " +
                                                option.package_city_prices.tat_time +
                                                ", Rs. " +
                                                option?.package_city_prices.offer_price +
                                                "/-"
                                                : option.name +
                                                ", " +
                                                option?.tat_time +
                                                ", Rs. " +
                                                option?.offer_price +
                                                "/-"
                                        }
                                        getOptionDisabled={(option: any) => {
                                            const packID = packageDetails.map((pack: any) => pack.id);
                                            return packID.includes(option.id);
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            clearTimeout(timer.current);
                                            timer.current = setTimeout(() => {
                                                getPackage(`city_id=${cityId}&search=${newInputValue}`);
                                            }, 1000);
                                        }}
                                        freeSolo
                                        disableCloseOnSelect
                                        renderTags={(value: string[], getTagProps) =>
                                            value.map((option: any, index: number) => (
                                                <Chip
                                                    variant="default"
                                                    color="primary"
                                                    label={
                                                        option.package_city_prices
                                                            ? option.name.toString() +
                                                            ", " +
                                                            option.package_city_prices.tat_time +
                                                            ", Rs. " +
                                                            option?.package_city_prices.offer_price +
                                                            "/-"
                                                            : option.name.toString() +
                                                            ", " +
                                                            option.tat_time +
                                                            ", Rs." +
                                                            option?.offer_price +
                                                            "/-"
                                                    }
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="Package"
                                                variant="outlined"
                                                style={{ width: "100%", margin: "0", padding: "0" }}
                                                className="input"
                                            />
                                        )}
                                    />
                                </Grid>
                            </>
                        )}
                        <Grid item md={2}>
                            <p>Partner</p>
                            <TextField
                                name="partner"
                                type="text"
                                value={partner}
                                placeholder="Enter Order Id"
                                className="input"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={(e) => setPartner(e.target.value as String)}
                                style={{ width: "100%", margin: "0" }}
                            />
                        </Grid>
                        <Grid item md={2}>
                            <p>Passport</p>
                            <TextField
                                className="input"
                                name="passport"
                                type="text"
                                placeholder="Enter Passport Number"
                                value={passport}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ width: "100%", margin: "0" }}
                                onChange={(e: any) => setPassport(e.target.value)}
                            />
                        </Grid>
                        <Grid item md={2}>
                            <p>Aadhar Number</p>
                            <TextField
                                className="input"
                                name="aadhar"
                                type="number"
                                onWheel={(e: any) => e.target.blur()}
                                onInput={(e: any) => {
                                    e.target.value = Math.max(0, parseInt(e.target.value))
                                        .toString()
                                        .slice(0, 16);
                                }}
                                placeholder="Enter Aadhar Number"
                                value={aadhar}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ width: "100%", margin: "0" }}
                                onChange={(e: any) => setAadhar(e.target.value)}
                            />
                        </Grid>
                        <Grid item md={3}>
                            <p>Booking For</p>
                            <Select
                                className="input"
                                name="bookingFor"
                                variant="outlined"
                                value={bookingFor}
                                style={{ width: "100%", margin: "0", height: "40px" }}
                                required
                                onChange={(e: any) => setBookingFor(e.target.value)}
                            >
                                <MenuItem value="self">Self</MenuItem>
                                <MenuItem value="father_in_law">Father/Father in law</MenuItem>
                                <MenuItem value="mother_in_law">Mother/Mother in law</MenuItem>
                                <MenuItem value="son_in_law">Son/Son in law</MenuItem>
                                <MenuItem value="daughter_in_law">
                                    Daughter/Daughter in law
                                </MenuItem>
                                <MenuItem value="spouse">Spouse</MenuItem>
                                <MenuItem value="friend">Friend</MenuItem>
                                <MenuItem value="other">Other</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item md={3}>
                            <p>Is Prescribed</p>
                            <Select
                                className="input"
                                name="is_prescribed"
                                variant="outlined"
                                value={isPrescribed}
                                style={{ width: "100%", margin: "0", height: "40px" }}
                                onChange={(e: any) => setIsPrescribed(e.target.value)}
                            >
                                <MenuItem value="true">Yes</MenuItem>
                                <MenuItem value="false">No</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={3}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        style={{ margin: "1rem auto" }}
                    >
                        <Grid item xs={12} md={6}>
                            <Card
                                className="sampleCollectionTime"
                                style={{ minHeight: "470px", overflow: "auto" }}
                            >
                                <p style={{ marginBottom: "1rem" }}>Sample Collection Time</p>
                                <div className={classes.slotContainer}>
                                    {booking?.centre_address === null
                                        ? timeSlots &&
                                        timeSlots.length > 0 &&
                                        timeSlots.map((slot: any) => {
                                            return (
                                                slot.available_slots > 0 && (
                                                    <Card
                                                        key={slot.id}
                                                        className={
                                                            slot.id === selectedSlot
                                                                ? classes.selectedSlot
                                                                : classes.slot
                                                        }
                                                        onClick={() => setSelectedSlot(slot.id as Number)}
                                                    >
                                                        <p
                                                            style={{
                                                                textAlign: "center",
                                                                color: "black",
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            {slot.start_time1hr}-{slot.end_time1hr}
                                                        </p>
                                                        {/* <p style={{ textAlign: "center", color: "black" }}>{moment(`${slot.start_time}`, ["HH.mm.ss"]).format("hh:mm A")} - {moment(`${slot.end_time}`, ["HH.mm.ss"]).format("hh:mm A")}</p> */}
                                                        <p
                                                            style={{ textAlign: "center", color: "green" }}
                                                        >
                                                            {" "}
                                                            Availabe slots: {slot.available_slots}
                                                        </p>
                                                    </Card>
                                                )
                                            );
                                        })
                                        : timeslots &&
                                        timeslots?.results &&
                                        timeslots.results.length > 0 &&
                                        timeslots.results.map((slot: any) => {
                                            return (
                                                <Card
                                                    key={slot.id}
                                                    className={
                                                        slot.id === selectedSlot
                                                            ? classes.selectedSlot
                                                            : classes.slot
                                                    }
                                                    onClick={() => setSelectedSlot(slot.id as Number)}
                                                >
                                                    <p
                                                        style={{
                                                            textAlign: "center",
                                                            color: "black",
                                                            fontSize: "14px",
                                                        }}
                                                    >
                                                        {slot.start_time}-{slot.end_time}
                                                    </p>
                                                    {/* <p style={{ textAlign: "center", color: "black" }}>{moment(`${slot.start_time}`, ["HH.mm.ss"]).format("hh:mm A")} - {moment(`${slot.end_time}`, ["HH.mm.ss"]).format("hh:mm A")}</p> */}
                                                </Card>
                                            );
                                        })}
                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6} style={{ padding: "1rem" }}>
                            <div className="priceDetails" style={{ padding: "1rem" }}>
                                <table style={{ width: "100%", margin: "0" }}>
                                    <thead>
                                        <tr>
                                            <td className="cell">Customer Name</td>
                                            <td className="cell">Packages</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="cell">{customer_name}</td>
                                            <td className="cell">
                                                <ul>
                                                    {packageDetails.map((pack: any, index: any) => {
                                                        return (
                                                            <li key={index}>
                                                                {pack.name}
                                                                <br />
                                                                DESCRIPTION : {pack.description}
                                                                <br />
                                                                TaT:{" "}
                                                                {pack.package_city_prices
                                                                    ? pack.package_city_prices.tat_time
                                                                    : pack.tat_time}
                                                                <br />
                                                                Price:{" "}
                                                                {pack.package_city_prices
                                                                    ? pack.package_city_prices.offer_price
                                                                    : pack.offer_price}
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </td>
                                        </tr>
                                        {booking?.additional_members &&
                                            booking?.additional_members.map(
                                                (member: any, index: any) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td className="cell">{member.customer_name}</td>
                                                            <td className="cell">
                                                                <ul>
                                                                    {member.packages &&
                                                                        member.packages.map(
                                                                            (pack: any, index: any) => {
                                                                                return (
                                                                                    <li key={index}>
                                                                                        {pack.name}
                                                                                        <br />
                                                                                        DESCRIPTION : {pack.description}
                                                                                        <br />
                                                                                        TaT:{" "}
                                                                                        {pack.package_city_prices
                                                                                            ? pack.package_city_prices
                                                                                                .tat_time
                                                                                            : pack.tat_time}
                                                                                        <br />
                                                                                        Price:{" "}
                                                                                        {pack.package_city_prices
                                                                                            ? pack.package_city_prices
                                                                                                .offer_price
                                                                                            : pack.offer_price}
                                                                                    </li>
                                                                                );
                                                                            }
                                                                        )}
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                    </tbody>
                                </table>
                            </div>
                            <div
                                className="priceDetails"
                                style={{ padding: "1rem 1rem 0 1rem" }}
                            >
                                <h6 className="totalPrice">
                                    Price: ₹ {Math.floor(price)} {extraCharge ? "+ 200" : ""}
                                </h6>
                                <h6 className="totalPrice">
                                    RedCash Discount: ₹{" "}
                                    {Math.floor(booking?.redcash_discounted_amount)}
                                </h6>
                                <h6 className="totalPrice">Discount: ₹ {discount}</h6>
                                <h6 className="totalPrice">
                                    Total Amount: ₹{" "}
                                    {totalPrice - Math.floor(booking?.redcash_discounted_amount)}
                                </h6>

                            </div>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={3}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        style={{ margin: "10px auto" }}
                    >
                        <Grid item xs={12}>

                            <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                                {/* <Grid
                                    direction="row"
                                    justify="space-around"
                                    alignItems="center"
                                    container
                                    item
                                    xs={12}
                                    md={6}
                                    spacing={3}
                                >
                                    <Grid item xs={12} md={5}>
                                        <p>{CITY}</p>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <TextField
                                            name="city"
                                            type="text"
                                            value={
                                                (booking.customer_areapincode &&
                                                    booking.customer_areapincode?.city?.display_name) ||
                                                ""
                                            }
                                            disabled
                                            className="input"
                                            variant="outlined"
                                            style={{ margin: "0", width: "100%" }}
                                        />
                                    </Grid>
                                </Grid> */}

                                {/* <Grid
                                    direction="row"
                                    justify="space-around"
                                    alignItems="center"
                                    container
                                    item
                                    xs={12}
                                    md={6}
                                    spacing={3}
                                >
                                    <Grid item xs={12} md={5}>
                                        <p>{LOCALITY}</p>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <Autocomplete
                                            id="colony-area-sector"
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    let obj = JSON.parse(
                                                        JSON.stringify(newValue, null, " ")
                                                    );
                                                    setMapAddressObject(obj);
                                                }
                                            }}
                                            options={suggestedAddress ? suggestedAddress : []}
                                            freeSolo
                                            blurOnSelect
                                            aria-required
                                            getOptionLabel={(option: any) => `${option?.placeAddress}`}
                                            filterOptions={(option: any) => option}
                                            onInputChange={(event, newInputValue) => {
                                                clearTimeout(timer.current);
                                                timer.current = setTimeout(() => {
                                                    locationAutoCompleteSearch(newInputValue);
                                                }, 500);
                                            }}
                                            disabled
                                            renderInput={(params) => (
                                                <TextField
                                                    className="input"
                                                    {...params}
                                                    placeholder="Search Address"
                                                    variant="outlined"
                                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                                    required
                                                />
                                            )}
                                            defaultValue={{ "placeAddress": customer_landmark }}
                                        />
                                    </Grid>
                                </Grid> */}

                                {/* <Grid
                                    direction="row"
                                    justify="space-around"
                                    alignItems="center"
                                    container
                                    item
                                    xs={12}
                                    md={6}
                                    spacing={3}
                                >
                                    <Grid item xs={12} md={5}>
                                        <p>{ADDRESS_LINE1}</p>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <TextField
                                            name="customer_address"
                                            type="text"
                                            value={customer_address}
                                            onChange={(e) => setCustomer_address(e.target.value as string)}
                                            className="input"
                                            variant="outlined"
                                            style={{ margin: "0", width: "100%" }}
                                        />
                                    </Grid>
                                </Grid> */}
                                {/* <Grid
                                    direction="row"
                                    justify="space-around"
                                    alignItems="center"
                                    container
                                    item
                                    xs={12}
                                    md={6}
                                    spacing={3}
                                >
                                    <Grid item xs={12} md={5}>
                                        <p>{ADDRESS_LINE2}</p>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <TextField
                                            name="customer_address"
                                            type="text"
                                            value={addressLine2}
                                            onChange={(e) =>
                                                setAddressLine2(e.target.value as string)
                                            }
                                            className="input"
                                            variant="outlined"
                                            style={{ margin: "0", width: "100%" }}
                                        />
                                    </Grid>
                                </Grid> */}
                                {/* <Grid
                                    direction="row"
                                    justify="space-around"
                                    alignItems="center"
                                    container
                                    item
                                    xs={12}
                                    md={6}
                                    spacing={3}
                                >
                                    <Grid item xs={12} md={5}>
                                        <p>{LANDMARK}</p>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <TextField
                                            name="customer_address"
                                            type="text"
                                            value={favoritePlace}
                                            onChange={(e) => setFavoritePlace(e.target.value as string)}
                                            className="input"
                                            variant="outlined"
                                            style={{ margin: "0", width: "100%" }}
                                        />
                                    </Grid>
                                </Grid> */}


                                {/* <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>Area</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="areaName"
                      type="text"
                      disabled
                      value={
                        (pin_code.results &&
                          pin_code.results.length > 0 &&
                          pin_code.results[0].city) ||
                        ""
                      }
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid> */}
                                {/* <Grid
                  direction="row"
                  justify="space-around"
                  alignItems="center"
                  container
                  item
                  xs={12}
                  md={6}
                  spacing={3}
                >
                  <Grid item xs={12} md={5}>
                    <p>{PINCODE}</p>
                  </Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      name="pincode"
                      type="text"
                      disabled
                      value={
                        (booking.customer_areapincode &&
                          booking.customer_areapincode.pincode) ||
                        ""
                      }
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>  */}

                                <Grid
                                    container
                                    direction="row"
                                    spacing={3}
                                    style={{ margin: "1rem auto", }}
                                >
                                    <Grid item md={2}>
                                        <p>Verification Remark</p>


                                        <TextField
                                            name="verification_remark"
                                            placeholder="Enter Remark"
                                            type="text"
                                            value={verification_remark}
                                            className="input"
                                            variant="outlined"
                                            style={{ margin: "0", width: "100%" }}
                                            onChange={(e) =>
                                                setVerification_remark(e.target.value as string)
                                            }
                                            required
                                        />
                                    </Grid>
                                    <Grid item md={2}>
                                        <p>Patient</p>
                                        <TextField
                                            name="patientId"
                                            type="text"
                                            placeholder="Patient Id"
                                            value={patientId}
                                            disabled={patientId !== null}
                                            className="input"
                                            variant="outlined"
                                            style={{ margin: "0", width: "100%" }}
                                            onChange={(e) => setNewPatientId(e.target.value as String)}
                                        // required
                                        />
                                    </Grid>
                                    <Grid item md={2}>
                                        <p>Bill Id</p>
                                        <TextField
                                            name="bill_id"
                                            type="text"
                                            placeholder="Bill Id"
                                            value={bill_id}
                                            disabled={bill_id !== null}
                                            className="input"
                                            variant="outlined"
                                            style={{ margin: "0", width: "100%" }}
                                            onChange={(e) => setNewBillId(e.target.value as String)}
                                        // required
                                        />
                                    </Grid>
                                    <Grid item md={2}>
                                        <p>Verification Status</p>

                                        <Select
                                            className="input"
                                            name="verification_status"
                                            variant="outlined"
                                            value={verification_status || "pending"}
                                            style={{ width: "100%", margin: "0", padding: "0", height: "40px" }}
                                            onChange={(e) => setVerification_status(e.target.value as string)}
                                            disabled={booking.verification_status || booking.phlebo !== null}
                                            required
                                        >
                                            <MenuItem value={"pending"}>Pending</MenuItem>
                                            <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                                            <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                                        </Select>
                                    </Grid>

                                    <Grid item md={2}>
                                        <p>Booking Status</p>


                                        <Select
                                            className="input"
                                            name="booking_status"
                                            variant="outlined"
                                            value={booking_status || "pending"}
                                            style={{ width: "100%", margin: "0", padding: "0", height: "40px" }}
                                            disabled={booking.booking_status === "resampling"}
                                            onChange={(e) => setBooking_status(e.target.value as string)}
                                            // disabled={booking.booking_status==="rescheduled" || booking.booking_status==="resampling" }
                                            required
                                        >
                                            <MenuItem disabled value={booking_status}>{booking_status}</MenuItem>
                                            <MenuItem value={"pending"}>Pending</MenuItem>
                                            <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                                            <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                                            <MenuItem value={"rescheduled"}>Rescheduled</MenuItem>

                                        </Select>
                                    </Grid>
                                    <Grid item md={2}>
                                        <p>Disposition Type</p>

                                        <Select
                                            className="input"
                                            name="disposition"
                                            variant="outlined"
                                            value={disposition}
                                            style={{ width: "100%", margin: "0", padding: "0", height: "40px" }}
                                            onChange={(e) => setDispositon(e.target.value as string)}
                                            required
                                        >
                                            {BOOKING_DISPOSITION.map((item: any) => {
                                                return (
                                                    <MenuItem key={item.id} value={`${item.id}`}>
                                                        {item.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <div className={classes.buttonContainer}>
                        <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            disabled={
                                loading ||
                                !selectedSlot ||
                                packages.length === 0 ||
                                !mobileValidation.customer_alternatenumber ||
                                !mobileValidation.customer_phonenumber ||
                                !mobileValidation.customer_whatsapppnumber
                            }
                        >
                            Update booking
                        </Button>
                    </div>
                </form>
            </Paper>
        </main>
    );
};
const mapStateToProps = (state: any) => ({
    pin_code: state.HealthAdviserReducer.pin_code,
    packageList: state.HealthAdviserReducer.packageList,
    couponList: state.HealthAdviserReducer.couponList,
    booking: state.HealthAdviserReducer.booking,
    booking_slots: state.HealthAdviserReducer.booking_slots,
    loading: state.HealthAdviserReducer.loading,
    // checkGeoFenceStatus: state.HealthAdviserReducer.checkGeoFenceStatus,
    geoFencesMapMyIndia: state.HealthAdviserReducer.geoFencesMapMyIndia,
    mapmyindiaAccessToken: state.HealthAdviserReducer.mapmyindiaAccessToken,
    timeslots: state.HealthAdviserReducer.timeslots,
    userDetails: state.HealthAdviserReducer.userDetails,
});

export default connect(mapStateToProps, {
    getPinCode,
    getPackage,
    updateBooking,
    getCoupons,
    getBookingById,
    getAvailableSlots,
    // checkGeoFenceArea,
    checkGeoFenchMapMyIndia,
    getMapMyIndiaAccessToken,
    getTimeslots,
    getUserDetails,
})(EditBookingForm);
