import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import { Chip, Paper, withStyles, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import { Grid, TextField } from "@material-ui/core";
import SearchIcon from '@mui/icons-material/Search';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Autocomplete from "@material-ui/lab/Autocomplete";
import BackupIcon from '@mui/icons-material/Backup';
import { genrateColdCalling } from '../../../helpers/generateUrl'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  postColdCallLeadUpload,
  getColdCallLeadData,
  getLeadSource,
  getCities
} from "../../actions/salesLeadAdminAction";
import { BOOKING_STATUS } from '../../../booking_status'
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      ...theme.mixins.toolbar,
    },
    item: {
    },
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be bel
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      wordSpacing: 3,
      justifyContent: "space-between",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);


interface Props {
  loading: boolean;
  postColdCallLeadUpload: any;
  getColdCallLeadData: any;
  coldCallData: any;
  getLeadSource: any;
  lead_source: any
  getCities: any;
  cities: any;
}

const CustomUploader: React.FC<Props> = ({
  loading,
  postColdCallLeadUpload,
  getColdCallLeadData,
  coldCallData,
  getLeadSource,
  lead_source,
  getCities,
  cities,
}) => {
  const timer = useRef<any>(0);
  const history = useHistory()
  const classes = useStyles();
  const [file, setFile] = React.useState<any>([]);
  const [file_name, setFileName] = React.useState<string>("");
  const [buttonState, setButtonState] = useState<boolean>(false);
  const [isDndFilter, setisDndFilter] = useState("none")
  const [dateForm, setDateForm] = useState<string>("")
  const [dateTo, SetdateTo] = useState<string>("")
  const [source, setSource] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [status, setStatus] = useState<any>([])
  const [fileTypeError,setFileTypeError]=useState<any>("")

  const handleFileChange = (e: React.ChangeEvent<{ files: any }>) => {
    setFile(e.target.files[0]);
    if(e.target.files[0]?.type==="text/csv"){
      setFileName(e.target.files[0].name);
      setFileTypeError("")
    }
    else{
      setFileTypeError("Please select csv file")
    }
   
  };
  const submitFile = async () => {
    const formData = new FormData();
    formData.append("csv_data", file);

    await postColdCallLeadUpload(formData);
    setButtonState(true);
    setTimeout(() => {
      setButtonState(false);
    }, 1000 * 60 * 10);
  };
  useEffect(() => {
    getColdCallLeadData("");
    getLeadSource("");
    getCities("")
  }, []);

  const filterColdCalling = () => {
    const body: any = {
      is_dnd: isDndFilter,
      status: status ? status.join(",") : "",
      source: source ? source.join(",") : "",
      created_at_gte: dateForm,
      created_at_lte: dateTo,
      city: city ? city.join(",") : "",
    }
    const url = genrateColdCalling(body).substring(2)
    getColdCallLeadData(url ? `?${url}` : "");
  }
  const ExportColdCalling = () => {
    const body: any = {
      is_dnd: isDndFilter,
      status: status ? status.join(",") : "",
      source: source ? source.join(",") : "",
      created_at_gte: dateForm,
      created_at_lte: dateTo,
      city: city ? city.join(",") : "",
    }
    const url = genrateColdCalling(body).substring(2)
    getColdCallLeadData(url ? `?${url}&download=true` : "?download=true");
    alert("An Email has been sent to your registered mail address")
  }

  const handleCsvData = () => {
    getColdCallLeadData("")
  };
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid xs={12} sm={8} md={6} lg={6} spacing={3}>

        <Grid item xs={12} md={8} className={classes.item}>
          <h4 style={{ fontWeight: "bold" }}> COLD CALLING LEAD UPLOADER</h4>
        </Grid>
      </Grid>

      <Grid container spacing={1} direction="row" style={{ alignItems: "left" }}>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="isDnd"
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setisDndFilter(obj.value);
              }
            }}
            options={[
              {
                text: "Active",
                value: "true",
              },
              {
                text: "Inactive",
                value: "false",
              }
            ]}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) => option.text}
            disableClearable
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="DND"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            name="dateForm"
            type="date"
            value={dateForm}
            className="input"
            label="Date Form"
            variant="outlined"

            onChange={(e) => setDateForm(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            name="dateForm"
            type="date"
            value={dateTo}
            className="input"
            label="Date TO"
            variant="outlined"

            onChange={(e) => SetdateTo(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                sourceIds = obj.map((item: any) => item?.id);
              }
              setSource(sourceIds);
            }}
            freeSolo
            multiple
            id="checkboxes-tags-demo"
            options={lead_source || []}
            disableCloseOnSelect
            limitTags={1}
            disableClearable
            getOptionLabel={(option: any) => option?.name}
            getOptionDisabled={(option: any) => {
              return source.includes(option?.id);
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="Source"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="city"
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                sourceIds = obj.map((item: any) => item.id);
              }
              setCity(sourceIds);
            }}
            options={cities || []}
            freeSolo
            blurOnSelect
            aria-required
            limitTags={1}
            multiple
            getOptionLabel={(option: any) => option?.name}
            getOptionDisabled={(option: any) => {
              return city.includes(option.id);
            }}
            disableClearable
            disableCloseOnSelect
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getCities(newInputValue);
              }, 1000);
              if (newInputValue.length === 0) {
                setCity([]);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="City"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="status"
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                sourceIds = obj.map((item: any) => item.value);
              }
              setStatus(sourceIds);
            }}
            options={BOOKING_STATUS}
            blurOnSelect
            aria-required

            limitTags={1}
            multiple
            getOptionLabel={(option: any) => option.text}
            getOptionDisabled={(option: any) => {
              return status.includes(option.value);
            }}
            disableClearable
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Booking Status"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}

          />
        </Grid>

        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={filterColdCalling}
            startIcon={<SearchIcon />}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2} >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<RestartAltIcon />}
            onClick={() => history.push("/dashboard/sla/coldcallleaduploader")}
          >
            Reset
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2} >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<BackupIcon />}
            onClick={ExportColdCalling}
          >
            Export To Email
          </Button>
        </Grid>
      </Grid>


      <Grid
        container
        spacing={1}
        direction="row"
        style={{ alignItems: "left", marginTop: "20px" }}
      >
        <Grid item xs={12} sm={6} md={4}>
          <input
            type="text/csv"
            style={{
              width: "100%",
              height: "56px",
              padding: "6px 16px",
              marginRight: "5px",
            }}
            placeholder="File Name"
            value={file_name}
            disabled
          />
          <p style={{color:"red"}}>{fileTypeError}</p>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            fullWidth
            style={{ height: "56px" }}
            variant="contained"
            component="label"
            color="secondary"
          >
            Select CSV File
            <input
              type="file"
              name="file"
              id="file"
              hidden
              onChange={handleFileChange}
            />
          </Button>

        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            variant="contained"
            fullWidth
            component="label"
            style={{ height: "56px", background: "#924A91" }}
            onClick={submitFile}
            disabled={buttonState || file_name === ""||fileTypeError!==""}
          >
            Upload
          </Button>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <a
            href="https://staticredcliffelabs.s3.ap-south-1.amazonaws.com/media/Cold+Calling+Lead+Upload+Example.csv"
            target=""
          >
            <Button
              fullWidth
              style={{ height: "56px", background: "#4caf50", marginTop: "40px" }}
              variant="contained"
              component="label"
              onClick={handleCsvData}
            >
              Download  Sample CSV  File
            </Button>
          </a>
        </Grid>

        <Grid
          container
          spacing={1}
        >
          <Grid item xs={12} md={8} className={classes.item}>
            <small>
              <ul style={{ paddingLeft: "15px", marginTop: "20px" }}>
                <li>
                  Note : Only CSV File Can Be upload
                </li>
                <li>
                  Note : You Can Download Your File After 30 Minutes Of Uploades
                </li>
              </ul>
            </small>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
      >

        {/* <Grid item xs={12} sm={6} md={4}>
          <a
            href="https://staticredcliffelabs.s3.amazonaws.com/media/csv/7e7dc65b-2b88-46ba-b3ff-3e52d2b0d950.csv"
            target="_black"
          >
            <Button
              fullWidth
              style={{ height: "56px", background: "#4caf50", marginTop: "40px" }}
              variant="contained"
              component="label"
              onClick={handleCsvData}
            >
              Download CSV  File
            </Button>
          </a>
        </Grid> */}
      </Grid>



    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.SalesLeadAdminReducer.loading,
  coldCallData: state.SalesLeadAdminReducer.coldCallData,
  lead_source: state.SalesLeadAdminReducer.lead_source,
  cities: state.SalesLeadReducer.cities,

});

export default connect(mapStateToProps, {
  postColdCallLeadUpload,
  getColdCallLeadData,
  getLeadSource,
  getCities,
})(CustomUploader);