import React from "react";
import ImaginAdminLeft from "../../ImagingAdmin-Panel/dashboard-left";
import ImagingAdminRight from "../../ImagingAdmin-Panel/dashboard-right";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        padding: "20px"
    }
});

interface Props {
    children: any;
}

const LeadUploaderDash: React.FC<Props> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <ImaginAdminLeft />
            <ImagingAdminRight>{children && children}</ImagingAdminRight>
        </div>
    );
};

export default LeadUploaderDash;
