import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    withStyles,
    Theme,
    createStyles,
    makeStyles,
} from "@material-ui/core/styles";
import { Grid, Select, MenuItem, TextField } from '@material-ui/core'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { getTopCallRecords, searchCall } from "../../actions/bookingActions";
import { TableFooter, TablePagination } from "@material-ui/core";
import Loader from "../loader";
import Button from '@mui/material/Button';
import { ExitToAppRounded } from "@material-ui/icons";

const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,fontWeight:600
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 700,
        },
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "100%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
    })
);

interface Props {
    loading: boolean;
    topRecord: any;
    getTopCallRecords: any;
}

const LeadsTable: React.FC<Props> = ({
    topRecord,
    getTopCallRecords,
    loading,
}) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [id, setId] = useState<string>("")
    const [createdAt, setCreatedAt] = useState<string>("")
    const [direction, setDirection] = useState<string>("none")
    const [status, setStatus] = useState<string>("none")

    const history = useHistory()

    useEffect(() => {
        getTopCallRecords()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatch = useDispatch()

    // const filterLeads = () => {
    //     const body: any = {
    //         status,
    //         lead: id,
    //         created_date: createdAt,
    //         direction,
    //     }
    //     const url = generateCallRecordingUrl(body).substring(2)
    //     getTopCallRecords(`?${url}`)
    //     setPage(0)
    // }

    const viewLead = (id: any) => {
        let body = {
            search_by: "call_id",
            value: `${id}`,
        };
        dispatch(searchCall(body));
        history.push("/dashboard/cl/lead/search");
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = topRecord.next;
            getTopCallRecords(url.substring(url.indexOf("?")));
        } else if (newPage < page) {
            let url = topRecord.previous;
            getTopCallRecords(
                url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?"))
            );
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <div className="data-table" style={{ width: "100%", marginTop: "2rem" }}>
                    <TableContainer component={Paper}>
                         <h4>Missed Calls</h4>
                        {loading ? <Loader /> : (
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="center">Lead ID</StyledTableCell>
                                        <StyledTableCell align="center">Date/Time</StyledTableCell>
                                        <StyledTableCell align="center">Status</StyledTableCell>
                                        <StyledTableCell align="center">Action</StyledTableCell>
                                        <StyledTableCell align="center">Direction</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {topRecord && topRecord.results && topRecord.results.map((item: any) => {
                                        return (
                                            <StyledTableRow key={item.id}>
                                                <StyledTableCell align="center">
                                                    {item.lead ? item.lead : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {item.created_at ? new Date(item.created_at).toLocaleString() : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {item.status ? item.status : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <ExitToAppRounded onClick={() => viewLead(item.lead)} />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {item.direction ? item.direction : "NA"}
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TablePagination
                                        colSpan={6}
                                        count={topRecord.count || 0}
                                        rowsPerPageOptions={[]}
                                        rowsPerPage={rowsPerPage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                    />
                                </TableFooter>
                            </Table>
                        )}
                    </TableContainer>
                </div>
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    topRecord: state.CustomerLeadReducer.topRecord,
    loading: state.CustomerLeadReducer.loading,
});

export default connect(mapStateToProps, {getTopCallRecords, searchCall })(
    LeadsTable
);
