import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {

    getAgentList,
    getPartners,
    getAllBookings,
    getPanelUserListSalesLead,
    getPanelUserListTeamLead
} from '../actions/HealthAdviserAdminAction'
import LeadsTable from '../tables/leads'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        card: {
            height: "100%",
            padding: theme.spacing(1),
        },
        card_grid: {
            // wordSpacing:3,
            justifyContent: 'space-between'
        },
    })
);

interface Props {
    children: any;
    agentsList: any;
    bookingList: any;
    partnerList: any;
    getAgentList: any;
    getPartners: any;
    getAllBookings: any;
    loading: Boolean;
    getPanelUserListSalesLead: any;
    salesLeadList: any;
    getPanelUserListTeamLead: any;
    teamLeadList: any;
}

const LeadsPage: React.FC<Props> = ({
    agentsList,
    partnerList,
    getAgentList,
    getPartners,
    getAllBookings,
    bookingList,
    loading,
    salesLeadList,
    getPanelUserListSalesLead,
    getPanelUserListTeamLead,
    teamLeadList,
}) => {
    const classes = useStyles();


    useEffect(() => {
        getAgentList()
        getPartners()
        getAllBookings()
        getPanelUserListSalesLead(`?usergroup=SalesLead`)
        getPanelUserListTeamLead(`?usergroup=CustomerLead`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <LeadsTable getPanelUserListSalesLead={getPanelUserListSalesLead} salesLeadList={salesLeadList} getPanelUserListTeamLead={getPanelUserListTeamLead} teamLeadList={teamLeadList} />
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    agentsList: state.HealthAdviserAdminReducer.agentsList,
    partnerList: state.HealthAdviserAdminReducer.partnerList,
    bookingList: state.HealthAdviserAdminReducer.bookingList,
    loading: state.HealthAdviserAdminReducer.loading,
    salesLeadList: state.HealthAdviserAdminReducer.salesLeadList,
    teamLeadList: state.HealthAdviserAdminReducer.teamLeadList,
})

export default connect(mapStateToProps, {
    getAgentList,
    getPartners,
    getAllBookings,
    getPanelUserListSalesLead,
    getPanelUserListTeamLead
})(LeadsPage);
