
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    withStyles,
    Theme,
    createStyles,
    makeStyles,
} from "@material-ui/core/styles";
import { Grid, Select, Chip, MenuItem, TextField } from '@material-ui/core'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from 'react-router-dom';
import { getVerificationBookingDetails } from "../actions/salesLeadAction";
import { TableFooter, TablePagination } from "@material-ui/core";
import Loader from "../Components/loader";
import { Button } from "@material-ui/core";
import { generateVerifiedBookingFilterUrl } from "../../helpers/generateUrl";
// import { generateCallRecordingUrl } from "../../helpers/generateUrl";

const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,fontWeight:600
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 700,
        },
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "90%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);

interface Props {
    loading: boolean;
    getVerificationBookingDetails: any;
    verificationBookingDetails: any;
}

const LeadsTable: React.FC<Props> = ({
    loading,
    verificationBookingDetails,
    getVerificationBookingDetails,
}) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [bookingId, setBookingid] = React.useState<any>("")
    const [booking_date, setBooking_date] = useState("")
    const [collection_date, setCollection_date] = useState("")
    const [type, setBookingType] = useState<String>("none")
    const history = useHistory()

    useEffect(() => {
        getVerificationBookingDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatch = useDispatch()
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = verificationBookingDetails.next;

            getVerificationBookingDetails(url);
        } else if (newPage < page) {
            let url = verificationBookingDetails.previous;
            getVerificationBookingDetails(url);
        }
        setPage(newPage);
    };

    const filterBookings = () => {
        const body: any = {
            bookingId,
            collection_date,
            booking_date,
            type
        }
        const url = generateVerifiedBookingFilterUrl(body).substring(2)
        getVerificationBookingDetails(`${url ? `${url}&page=1` : "page=1"}`)
        setPage(0)
    }

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <h4 style={{ fontWeight: "bold" }}>VERIFIED BOOKING TABLE</h4>
                <Grid container spacing={3} direction="row" alignItems="center" justify="center">
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="booking"
                            type="number"
                            placeholder="Booking Id"
                            value={bookingId}
                            variant="outlined"
                            onChange={(e) => setBookingid(e.target.value)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            name="booking_date"
                            type="date"
                            value={booking_date}
                            className="input"
                            label="Booking Date"
                            variant="outlined"

                            onChange={(e: any) => setBooking_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            name="collection_date"
                            type="date"
                            value={collection_date}
                            className="input"
                            label="Collection Date"
                            variant="outlined"

                            onChange={(e: any) => setCollection_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Select className="input"
                            name="sampleregisterd"
                            variant="outlined"
                            value={type}
                            style={{ width: "100%", margin: "0" }}

                            onChange={(e) => setBookingType(e.target.value as String)}

                        >
                            <MenuItem disabled value={"none"}>Booking Type</MenuItem>
                            <MenuItem value={"DTMF"}>Call</MenuItem>
                            <MenuItem value={"SMS"}>SMS</MenuItem>

                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Button
                            variant="contained"
                            color="secondary"
                          
                            fullWidth
                            onClick={filterBookings}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Button
                            variant="contained"
                            color="primary"
                          
                            fullWidth
                            onClick={() => history.push("/dashboard/sl/verifiedbooking")}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <div className={classes.toolbar} />
                <div className="data-table" style={{ width: "100%", marginTop: "2rem" }}>
                    <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "560px" }}>
                        {loading ? <Loader /> : (
                            <Table stickyHeader aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align="center">Booking Id</StyledTableCell>
                                        <StyledTableCell align="center">Lims Id</StyledTableCell>
                                        <StyledTableCell align="center">Booking Date</StyledTableCell>
                                        <StyledTableCell align="center">Price</StyledTableCell>
                                        <StyledTableCell align="center">Name</StyledTableCell>
                                        <StyledTableCell align="center">Agent</StyledTableCell>
                                        <StyledTableCell align="center">Age</StyledTableCell>
                                        <StyledTableCell align="center">Gender</StyledTableCell>
                                        <StyledTableCell align="center">Email</StyledTableCell>
                                        <StyledTableCell align="center">Collection Date</StyledTableCell>
                                        <StyledTableCell align="center">Collection Time</StyledTableCell>
                                        <StyledTableCell align="center">Zone Name</StyledTableCell>
                                        <StyledTableCell align="center">Mobile Number</StyledTableCell>
                                        <StyledTableCell align="center">Booking Status</StyledTableCell>
                                        <StyledTableCell align="center">Report Status</StyledTableCell>
                                        <StyledTableCell align="center">Verification Status</StyledTableCell>
                                        <StyledTableCell align="center">SE Call Count</StyledTableCell>
                                        <StyledTableCell align="center">Pickup Status</StyledTableCell>
                                        <StyledTableCell align="center">Pickup Amount</StyledTableCell>
                                        <StyledTableCell align="center">Payment Id</StyledTableCell>
                                        <StyledTableCell align="center">Payment Mode</StyledTableCell>
                                        <StyledTableCell align="center">Pickup Date</StyledTableCell>
                                        <StyledTableCell align="center">Pickup Time</StyledTableCell>
                                        <StyledTableCell align="center">Verified By</StyledTableCell>
                                        <StyledTableCell align="center">Action</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {verificationBookingDetails.results && verificationBookingDetails.results.length > 0 && verificationBookingDetails.results.map((booking: any, index: any) => {
                                        return (
                                            <StyledTableRow key={booking.pk}>
                                                <StyledTableCell align="center">{booking.booking.pk}</StyledTableCell>
                                                <StyledTableCell align="center">{booking.booking.bill_id || "N/A"}</StyledTableCell>
                                                <StyledTableCell align="center">{booking.booking.booking_date}</StyledTableCell>
                                                <StyledTableCell align="center">{booking.booking.discounted_price && booking.booking.discounted_price.final_total_price}</StyledTableCell>
                                                <StyledTableCell align="center">{`${booking.booking.designation || ""} ${booking.booking.customer_name}`}</StyledTableCell>
                                                <StyledTableCell align="center">{(booking.booking.agent && booking.booking.agent.name) || "NA"}</StyledTableCell>
                                                <StyledTableCell align="center">{booking.booking.customer_age}</StyledTableCell>
                                                <StyledTableCell align="center">{booking.booking.customer_gender}</StyledTableCell>
                                                <StyledTableCell align="center">{booking.booking.customer_email}</StyledTableCell>
                                                <StyledTableCell align="center">{booking.booking.collection_date}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {booking.booking.collection_slot && booking.booking.collection_slot.slot.split("-")[0]}
                                                    {" - "}
                                                    {booking.booking.collection_slot && booking.booking.collection_slot.slot.split("-")[1]}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">{booking.booking.customer_areapincode && booking.booking.customer_areapincode.area}</StyledTableCell>
                                                <StyledTableCell align="center">{booking.booking.customer_phonenumber}</StyledTableCell>
                                                <StyledTableCell align="center"><Chip label={`${booking?.booking_status}`} color="primary" /></StyledTableCell>
                                                <StyledTableCell align="center"><Chip label={`${booking.booking.report_status}`} color="primary" /></StyledTableCell>
                                                <StyledTableCell align="center"><Chip label={`${booking?.verification_status}`} color="primary" /></StyledTableCell>
                                                <StyledTableCell align="center"><Chip label={`${booking.booking.se_call_count}`} color="primary" /></StyledTableCell>
                                                <StyledTableCell align="center"><Chip label={`${booking.booking.pickup_status}`} color="primary" /></StyledTableCell>
                                                <StyledTableCell align="center">{booking.booking.pickup_receive_amount}</StyledTableCell>
                                                {booking.booking.payment_mode === "online" && booking.booking.pickup_receive_amount !== null ? (
                                                    <StyledTableCell align="center">{booking.booking.plink_id}</StyledTableCell>
                                                ) :
                                                    <StyledTableCell align="center"></StyledTableCell>}
                                                <StyledTableCell align="center">{booking.booking.payment_mode}</StyledTableCell>
                                                <StyledTableCell align="center">{booking.booking.pickup_date}</StyledTableCell>
                                                <StyledTableCell align="center">{booking.booking.pickup_time}</StyledTableCell>
                                                <StyledTableCell align="center">{booking.booking.verify_by && booking.booking.verify_by.name}</StyledTableCell>

                                                <StyledTableCell align="center">
                                                    <Link to={`/dashboard/sl/booking-view/${booking.booking.pk}`} style={{ textDecoration: "none" }}>
                                                        <Button variant="contained" color="secondary">View</Button>
                                                    </Link>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TablePagination
                                        colSpan={6}
                                        count={verificationBookingDetails.count || 0}
                                        rowsPerPageOptions={[]}
                                        rowsPerPage={rowsPerPage}
                                        // onChangeRowsPerPage={handleChangeRowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                    />
                                </TableFooter>
                            </Table>
                        )}
                    </TableContainer>
                </div>
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    verificationBookingDetails: state.SalesLeadReducer.verificationBookingDetails,
    loading: state.SalesLeadReducer.loading,
});

export default connect(mapStateToProps, { getVerificationBookingDetails })(
    LeadsTable
);
