import React from "react";
import HealthAdviserDashboardLeft from "../../HealthAdviser-Panel/dashboard-left";
import HealthAdviserDashboardRight from "../../HealthAdviser-Panel/dashboard-right";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        padding: "20px"
    }
});

interface Props {
    children: any;
}

const CustomerSupportAgentDash: React.FC<Props> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <HealthAdviserDashboardLeft />
            <HealthAdviserDashboardRight>{children && children}</HealthAdviserDashboardRight>
        </div>
    );
};

export default CustomerSupportAgentDash;

