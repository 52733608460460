
import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    withStyles,
    Theme,
    createStyles,
    makeStyles,
} from "@material-ui/core/styles";
import { Toolbar, Typography, IconButton, Card, Grid, Select, Chip, MenuItem, TextField, Switch, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, AppBar } from '@material-ui/core'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from 'react-router-dom';
import { TableFooter, TablePagination } from "@material-ui/core";
import moment from 'moment';
import Loader from "../../Components/loader";
import { Button } from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import { GridCloseIcon } from "@material-ui/data-grid";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { updatedPackageAlias } from '../../actions/ImagingAdminAction'
import { CheckCircleOutlineRounded, Edit } from "@material-ui/icons";
import AddIndividualPackage from './addPackageDayTime'
import CommentIcon from '@material-ui/icons/Comment';
import CenterOnboarding from "../CenterOnboarding";

const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#3f51b5",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 700,
        },
        root: {
            flexGrow: 0,
        },
        appBar: {
            position: "relative",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "100%",
            flexGrow: 21,
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);
const DefaultSwitch: any = withStyles({
    switchBase: {
        color: "red",
        '&$checked': {
            color: "green",
        },
        '&$checked + $track': {
            backgroundColor: "green",
        },
    },
    checked: {},
    track: {
        backgroundColor: "red"
    },
})(Switch);

interface Props {
    loading: boolean;
    getImagingPackageAlias: any;
    imagingPackageAlias: any;
    updatedPackageAlias: any;
    getCenterAddressPrice: any;
    centerAddressPrice: any;
    getDayWiseTimeSlotList: any;
    daywisetimeslot: any;
    getTimeslots: any;
    timeSlots: any
    updateDayWiseimeSlots: any;
    updatedCenterPackage: any;
    createCenterPackage: any;
    currentUrl: any;
    packageList: any
    getPackage: any
    getSubCenterInfo: any
    subCenterInfor: any
    setOpenPackageModal: Function;
    openPackageModal: boolean;
    dayList: any;
    setopenModal: any;
    setpackId: any;
    setOrgType: any;
}

const LeadsTable: React.FC<Props> = ({
    loading,
    getImagingPackageAlias,
    imagingPackageAlias,
    updatedPackageAlias,
    getCenterAddressPrice,
    centerAddressPrice,
    getDayWiseTimeSlotList,
    daywisetimeslot,
    getTimeslots,
    timeSlots,
    updateDayWiseimeSlots,
    updatedCenterPackage,
    createCenterPackage,
    packageList,
    getPackage,
    getSubCenterInfo,
    subCenterInfor,
    currentUrl,
    setOpenPackageModal,
    openPackageModal,
    dayList,
    setopenModal,
    setpackId,
    setOrgType,
}) => {
    const classes = useStyles();
    const history = useHistory()
    const timer = useRef<any>(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [editPackage, setEditPackage] = useState<any>({})
    const [aliasName, setAliasName] = useState<any>("")
    const [packageId, setpackageId] = useState<any>("")
    const [isActive, setIsActive] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [timeslots, setTimeslots] = useState<any>([])
    const [timeslotsIds, setTimeslotsIds] = useState<any>([])
    const [editTimeSlots, setTimeSlots] = useState("")
    const [timeSlotsSelected, setTimeSlotsSelected] = useState<any>([])
    const [pakagePrice, setPackagePrice] = useState<any>("")
    const [offerPrice, setOfferPrice] = useState<any>("")
    const [packStatus, setpackStatus] = useState<boolean>(false)
    const [transferPrice, setTransferPrice] = useState<any>("")


    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatch = useDispatch()
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = centerAddressPrice.links.next;
            getCenterAddressPrice(url.substring(url.indexOf("?")));
        } else if (newPage < page) {
            let url = centerAddressPrice.links.previous;
            getCenterAddressPrice(
                url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?"))
            );
        }
        setPage(newPage);
    };

    const handleEditPackage = (data: any) => {
        setEditPackage(data)
        setOfferPrice(data?.offer_price)
        setTransferPrice(data?.transfer_price)
        setPackagePrice(data?.package_price)
        setpackStatus(data.is_active)
        setpackageId(data.id)
    }

    const handleUpdatePackage = async () => {
        const body: any = {
            offer_price: offerPrice,
            package_price: pakagePrice,
            is_active: packStatus,
            transfer_price: transferPrice
        }
        await updatedCenterPackage(body,packageId)
        getCenterAddressPrice(`?${currentUrl}`)
        setEditPackage({})
    }
    const handleTimeSLots = (data: any) => {
        setOpen(true);
        getDayWiseTimeSlotList(`?package_center=${data.id}`)
        getTimeslots()
    }

    const handleClose = () => {
        setOpen(false);
    };
    const handleEditTime = (data: any) => {
        setTimeSlots(data.id)
        setTimeSlotsSelected(data.timeslot)

    }
    const handleSubmitTimeSlots = async (data: any) => {
        const body = {
            timeslot: timeslotsIds
        }
        await updateDayWiseimeSlots(data.id, body)
        setTimeSlots("")
        setOpen(false)
        getTimeslots()
    }
    const handleModal = (id: any) => {
        setopenModal(true)
        setpackId(id)
    }
    return (
        <>
            <main className={classes.content}>
                <div className="data-table" style={{ width: "100%" }}>
                    <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "560px" }}>
                        {loading ? <Loader /> : (
                            <Table stickyHeader aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align="center">CENTRE</StyledTableCell>
                                        <StyledTableCell align="center">TEST NAME</StyledTableCell>
                                        <StyledTableCell align="center">COMMENTS</StyledTableCell>
                                        <StyledTableCell align="center">MRP</StyledTableCell>
                                        <StyledTableCell align="center">GO LIVE PRICE </StyledTableCell>
                                        <StyledTableCell align="center">TRANSFER PRICE </StyledTableCell>
                                        <StyledTableCell align="center">STATUS </StyledTableCell>
                                        <StyledTableCell align="center">EDIT</StyledTableCell>
                                        <StyledTableCell align="center">TIME/DATE </StyledTableCell>
                                        <StyledTableCell align="center">REMARKS</StyledTableCell>

                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {centerAddressPrice && centerAddressPrice.results && centerAddressPrice.results.length > 0 && centerAddressPrice.results.map((data: any, index: any) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="center">{data?.center_address?.center_address?.name}</StyledTableCell>
                                                <StyledTableCell align="center">{data?.package?.name}</StyledTableCell>
                                                <StyledTableCell align="center"><CommentIcon onClick={() => handleModal(data?.package.id)} /></StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editPackage?.id === data?.id ? (
                                                        <TextField
                                                            name="mrp"
                                                            type="text"
                                                            value={pakagePrice}
                                                            className="input"
                                                            variant="outlined"
                                                            placeholder="MRP"
                                                            onChange={(e) => setPackagePrice(e.target.value as string)}
                                                            style={{ width: "100%" }}
                                                            required
                                                        />
                                                    ) : data?.package_price}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editPackage?.id === data?.id ? (
                                                        <TextField
                                                            name="offerprice"
                                                            type="text"
                                                            value={offerPrice}
                                                            className="input"
                                                            variant="outlined"
                                                            placeholder="Offer Price"
                                                            onChange={(e) => setOfferPrice(e.target.value as string)}
                                                            style={{ width: "100%" }}
                                                            required
                                                        />
                                                    ) : data?.offer_price}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editPackage?.id === data?.id ? (
                                                        <TextField
                                                            name="transferPrice"
                                                            type="text"
                                                            value={transferPrice}
                                                            className="input"
                                                            variant="outlined"
                                                            placeholder="Transfer Price"
                                                            onChange={(e) => setTransferPrice(e.target.value as string)}
                                                            style={{ width: "100%" }}
                                                            required
                                                        />
                                                    ) : data?.transfer_price}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editPackage?.id === data?.id ? (
                                                        <Switch
                                                            defaultChecked={data?.is_active}
                                                            checked={packStatus}
                                                            onChange={(e) => setpackStatus(e.target.checked)}
                                                            name="checkedA"
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                    ) : (
                                                        <DefaultSwitch
                                                            checked={data?.is_active}
                                                            name="checkedA"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            disableRipple
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editPackage?.id === data?.id ? (
                                                        <Button
                                                            color="primary"
                                                            variant="text"
                                                            onClick={() => handleUpdatePackage()}
                                                        >
                                                            Submit
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            color="primary"
                                                            variant="text"
                                                            onClick={() => handleEditPackage(data)}
                                                        >
                                                            Edit
                                                        </Button>
                                                    )}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Button
                                                        color="primary"
                                                        variant="text"
                                                        onClick={() => handleTimeSLots(data)}
                                                    >
                                                        View Time
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">{data?.remarks}</StyledTableCell>


                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TablePagination
                                        colSpan={6}
                                        count={centerAddressPrice.count || 0}
                                        rowsPerPageOptions={[]}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                    />
                                </TableFooter>
                            </Table>
                        )}
                    </TableContainer>
                </div>
                <Dialog fullScreen open={open} onClose={handleClose}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <GridCloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div className="data-table" style={{ width: "100%" }}>
                        <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "560px" }}>
                            {loading ? <Loader /> : (
                                <Table stickyHeader aria-label="simple table">
                                    <TableHead>
                                        <StyledTableRow>
                                            <StyledTableCell align="center">Day</StyledTableCell>
                                            <StyledTableCell align="center">Time</StyledTableCell>
                                            <StyledTableCell align="center">Action</StyledTableCell>

                                        </StyledTableRow>
                                    </TableHead>
                                    <TableBody>
                                        {daywisetimeslot && daywisetimeslot.length > 0 && daywisetimeslot.map((data: any, index: any) => {
                                            return (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell align="center">{data?.day}</StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {editTimeSlots === data.id ? (
                                                            <Autocomplete
                                                                id="timeslots"
                                                                onChange={(event, newValue) => {
                                                                    if (newValue) {
                                                                        let timeslotsArr: any = []
                                                                        let timeslotId: any = []
                                                                        newValue.map((val: any) => {
                                                                            let obj = JSON.parse(JSON.stringify(val, null, " "));
                                                                            timeslotsArr.push(obj)
                                                                            timeslotId.push(obj.id)
                                                                        })
                                                                        setTimeSlotsSelected(timeslotsArr)
                                                                        setTimeslotsIds(timeslotId)
                                                                    }
                                                                }}
                                                                multiple
                                                                options={timeSlots.results}
                                                                value={timeSlotsSelected}
                                                                disableClearable
                                                                limitTags={0}
                                                                getOptionLabel={(option: any) => `${option.start_time} onward`}
                                                                getOptionDisabled={(option: any) => {
                                                                    const timeId = timeSlotsSelected.map((times: any) => times.id);
                                                                    return timeId.includes(option.id);
                                                                }}
                                                                onInputChange={(event, newInputValue) => {
                                                                    clearTimeout(timer.current);
                                                                    timer.current = setTimeout(() => {
                                                                        getTimeslots(newInputValue);
                                                                    }, 1000);
                                                                }}
                                                                freeSolo
                                                                disableCloseOnSelect
                                                                renderTags={(value: string[], getTagProps) =>
                                                                    value.map((option: any, index: number) => (
                                                                        <Chip
                                                                            variant="default"
                                                                            color="primary"
                                                                            label={`${option.start_time} onward`}
                                                                            {...getTagProps({ index })}
                                                                        />
                                                                    ))
                                                                }
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        placeholder="Package"
                                                                        variant="outlined"
                                                                        style={{ width: "100%", margin: "0", padding: "0" }}
                                                                        className="input"
                                                                    />
                                                                )}
                                                            />
                                                        ) : (
                                                            <React.Fragment>
                                                                {data.timeslot.map((slot: any) => {
                                                                    return (
                                                                        <p key={slot.id} style={{ margin: "0.5rem auto" }}>{`${slot.start_time} ${slot.end_time}`}</p>
                                                                    )
                                                                })}
                                                            </React.Fragment>
                                                        )}

                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {editTimeSlots === data.id ? (
                                                            <Button
                                                                color="primary"
                                                                variant="contained"
                                                                onClick={() => handleSubmitTimeSlots(data)}
                                                            >
                                                                <CheckCircleOutlineRounded />
                                                            </Button>
                                                        ) : (
                                                            <Button
                                                                color="primary"
                                                                variant="contained"
                                                                onClick={() => handleEditTime(data)}
                                                            >
                                                                <Edit />
                                                            </Button>
                                                        )}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        })}
                                    </TableBody>
                                    <TableFooter>
                                        {/* <TablePagination
                                        colSpan={6}
                                        count={verificationBookingDetails.count || 0}
                                        rowsPerPageOptions={[]}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                    /> */}
                                    </TableFooter>
                                </Table>
                            )}
                        </TableContainer>
                    </div>
                </Dialog>
                <AddIndividualPackage
                    packageList={packageList}
                    getPackage={getPackage}
                    getSubCenterInfo={getSubCenterInfo}
                    subCenterInfor={subCenterInfor}
                    setOpenPackageModal={setOpenPackageModal}
                    openPackageModal={openPackageModal}
                    loading={loading}
                    getTimeslots={getTimeslots}
                    timeSlots={timeSlots}
                    dayList={dayList}
                    createCenterPackage={createCenterPackage}
                    getCenterAddressPrice={getCenterAddressPrice}
                    currentUrl={currentUrl}
                    setOrgType={setOrgType}
                />
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.ImagingAdminReducer.loading,
});

export default connect(mapStateToProps,
    {
        updatedPackageAlias
    })(LeadsTable);
