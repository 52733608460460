//Authentication
import React, { useState } from "react";
import Pusher from "pusher-js";
import { Link } from "react-router-dom";
import { checkRole } from '../containers/dash/userRole'
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import "./index.css"
import "react-notifications/lib/notifications.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import AnalystAgentDash from "../containers/CustomerExecutive-agent";
// import Modal from "../components/Modal";
import MainLayout from "../Hoc/MainLayout";
import SnackBar from "../components/SnackBar";
import Login from "../containers/login";
import SecureStorage from "./SecureStorage";
import { checkUser, locationDataFromPusherAPI } from "../actions/loginActions";
import { messageBox } from "../actions/notificationAction";
import { connect } from "react-redux";
import { ACTIONS, LoginActionTypes } from '../interfaces/actionTypes/loginTypes';
import { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
// import SecondModal from "../components/SecondModal";
import Dash from "../containers/dash";
// CustomerExecutive Panel
import CustomerExecutiveHomePage from "../CustomerExecutive-Panel/pages/home";
import FilterPage from "../CustomerExecutive-Panel/pages/filter";
import CEBookingPage from "../CustomerExecutive-Panel/components/Bookings/BookingPage";
import ViewBooking from "../CustomerExecutive-Panel/components/Bookings/ViewBooking";
import EditBookingForm from "../CustomerExecutive-Panel/components/Bookings/EditBookingForm/EditBookingForm";
import CEImagingEditBookingForm from "../CustomerExecutive-Panel/components/Bookings/EditBookingForm/ImagingEditBooking";
import CESearchResultsMain from "../CustomerExecutive-Panel/components/search/SearchResult";
import CustomerExecutiveFillInfoPage from "../CustomerExecutive-Panel/pages/fill-info";
import CustomerExecutiveDirectionTable from "../CustomerExecutive-Panel/tables/callsTable";
import CustomerExecutiveWhatsapp from "../CustomerExecutive-Panel/tables/whatsappMessages";
import CECallsStats from "../CustomerExecutive-Panel/components/CallsStats";
import CEComplaintsBooking from "../CustomerExecutive-Panel/tables/queryTicketsBooking";
import CESMSDetails from "../CustomerExecutive-Panel/tables/smsDetails";
import CEEMAILDetails from "../CustomerExecutive-Panel/tables/emailDetails";
import CECenterInfo from "../CustomerExecutive-Panel/tables/centerTable";
import CEInbox from "../CustomerExecutive-Panel/components/notificationInbox/index";
import CEAddAditionalFamily from "../CustomerExecutive-Panel/components/Bookings/NewBooking/AddFamilyMember";
import EditAditionalFamily from "../CustomerExecutive-Panel/components/Bookings/EditBookingForm/EditaditionalFamily";
import CECoupon from "../CustomerExecutive-Panel/components/Coupon/index";
import CustomerExecutiveTestPackages from "../CustomerExecutive-Panel/pages/testPackages";
import CELabDetails from "../CustomerExecutive-Panel/components/LabModal/labDetails";
import CESendNotification from "../CustomerExecutive-Panel/pages/SendNotification";
import CEAuditScore from "../CustomerExecutive-Panel/pages/auditScore";
// import CETodayMissedCalls from "../CustomerExecutive-Panel/tables/MissedCalls";
import CECloudAgent from "../CustomerExecutive-Panel/pages/cloudAgent";
import CERepeatedLead from "../CustomerExecutive-Panel/pages/repeatedLead";
import CELeadDetails from "../CustomerExecutive-Panel/tables/leads";
import CEImaginingTicekt from "../CustomerExecutive-Panel/tables/imagingRaiseTicket";
import CERadiologyToHomeDx from "../CustomerExecutive-Panel/components/Bookings/NewBooking/RadiologyToHomeDx";
import CEHomeDxTORadiology from "../CustomerExecutive-Panel/components/Bookings/NewBooking/HomeDx-Radiology";
import CEHelpDesk from "../CustomerExecutive-Panel/pages/HelpDesk";
import CEBiomarkerScreening from "../CustomerExecutive-Panel/components/Bookings/ViewBooking/BiomarkerScreeningreport";
// import CEInvoiceCopy from '../CustomerExecutive-Panel/pages/Invoice'

//Collection Center
import CCDash from "../containers/CollectionCenter-agent";
import CCBookingPage from "../CollectionCenter-Panel/Components/Bookings/BookingPage";
import CCViewBooking from "../CollectionCenter-Panel/Components/Bookings/ViewBooking/index";
import CCEditBookingForm from "../CollectionCenter-Panel/Components/Bookings/EditBookingForm/ImagingEditBooking";
import CCFilterPage from "../CollectionCenter-Panel/Pages/filter";
import CCCCClientLedgerDetails from "../CollectionCenter-Panel/Tables/ClientLedgerDetails";
import CCPackageList from "../CollectionCenter-Panel/Tables/PackageList";
import CCHelpDesk from "../CollectionCenter-Panel/Pages/HelpDesk";

//Collection Center Admin
import CCADash from "../containers/CollectionCenterAdmin-agent";
import CCABookingPage from "../CollectionCenter-Admin/Components/Bookings/BookingPage";
import CCAViewBooking from "../CollectionCenter-Admin/Components/Bookings/ViewBooking/index";
import CCAEditHomeDxBookingForm from "../CollectionCenter-Admin/Components/Bookings/EditBookingForm/EditBookingForm";
import CCAEditBookingForm from "../CollectionCenter-Admin/Components/Bookings/EditBookingForm/ImagingEditBooking";
import CCAFilterPage from "../CollectionCenter-Admin/Pages/filter";
import CCACCClientLedgerDetails from "../CollectionCenter-Admin/Tables/ClientLedgerDetails";
import CCAPackageList from "../CollectionCenter-Admin/Tables/PackageList";
import CCASubAdminPage from "../CollectionCenter-Admin/Components/SubAdmin";
import CCABookingReview from "../CollectionCenter-Admin/Pages/CustomerReview/CustomerReviewData";
import CCAComplaintsBooking from "../CollectionCenter-Admin/Tables/queryTicketsBooking";
import CCAHelpDesk from "../CollectionCenter-Admin/Pages/HelpDesk";

// Imaging Admin
import ImagingAdminDash from "../containers/ImagingAdmin-agent";
import ImagingAdminCenterOnboarding from "../ImagingAdmin-Panel/pages/CenterOnboarding/index";
import ImagingAdminSubCentre from "../ImagingAdmin-Panel/pages/SubCentre/index";
import ImagingAdminTestMaster from "../ImagingAdmin-Panel/pages/Test-Master/index";
import ImagingAdminPackageMaster from "../ImagingAdmin-Panel/pages/Alias-Master";
import imagingAdminMobalitytable from "../ImagingAdmin-Panel/pages/Modality-Desk/index";
import imagingAdminCenterDeactivate from "../ImagingAdmin-Panel/pages/Center-Deactivate/index";
import imagingAdminSubCenterDeactivate from "../ImagingAdmin-Panel/pages/SubCentre/SubCenter-Deactivate/index";
import imagingAdminHelpDesk from "../ImagingAdmin-Panel/pages/HelpDesk";

//Customer Lead
import CustomerLeadDash from "../containers/CustomerLead-agent";
import CustomerLeadBookingPage from "../CustomerLead-Panel/components/Bookings/BookingPage";
import CustomerLeadFilterPage from "../CustomerLead-Panel/pages/filter";
import CustomerLeadHome from "../CustomerLead-Panel/pages/Dashboard";
import CustomerLeadFillInfoPage from "../CustomerLead-Panel/pages/fill-info";
import LeadsPage from "../CustomerLead-Panel/pages/LeadsPage";
import CustommerLeadViewBooking from "../CustomerLead-Panel/components/Bookings/ViewBooking/index";
import CustommerLeadEditBookingForm from "../CustomerLead-Panel/components/Bookings/EditBookingForm/EditBookingForm.tsx";
import CustommerLeadEditBookingExport from "../CustomerLead-Panel/pages/exportFilter";
import CustommerLeadExport from "../CustomerLead-Panel/pages/exportLead";
import CLLeadTransfer from "../CustomerLead-Panel/pages/lead-transfer/by-comma";
import CLSearchResultsMain from "../CustomerLead-Panel/components/search/SearchResult";
import CustomUploader from "../CustomerLead-Panel/pages/custom-uploader";
import CLCalls from "../CustomerLead-Panel/tables/callsTable";
import CustomerLeadWhatsapp from "../CustomerLead-Panel/tables/whatsappMessages";
import CallStatsTable from "../CustomerLead-Panel/tables/callStatsTable.tsx";
import CustomerLeadCallDetails from "../CustomerLead-Panel/tables/callDetails";
import CLComplaintsLeads from "../CustomerLead-Panel/tables/queryTicketsLeads.tsx";
import CLComplaintsBooking from "../CustomerLead-Panel/tables/queryTicketsBooking.tsx";
import CLDisposition from "../CustomerLead-Panel/pages/leadstatus/filter";
import CLDispositionExport from "../CustomerLead-Panel/pages/leadstatus/export";
import CLDispositionBookingExport from "../CustomerLead-Panel/pages/bookingstatus/export";
import CLManageUsers from "../CustomerLead-Panel/tables/manageUsers";
import BookingsResamplingTable from "../CustomerLead-Panel/tables/queryResampling";
import IncorrectNumberLeads from "../CustomerLead-Panel/tables/incorrectNumberLeads";
import CLBreakTable from "../CustomerLead-Panel/tables/breakDetails";
import CLCallDashBoard from "../CustomerLead-Panel/components/Call-Dashboard/index";
import CLManageCoupons from "../CustomerLead-Panel/components/ManageCoupons";
// import CLMissedCall from "../CustomerLead-Panel/tables/missedCall";
import CLLabDetails from "../CustomerLead-Panel/components/LabModal/labDetails";
import CLCallLogs from "../CustomerLead-Panel/components/CallModal/callModal";
import ClVerifiedBooking from "../CustomerLead-Panel/tables/bookingVerificationTable";
import ClCenterInfo from "../CustomerLead-Panel/tables/centerTable";
import CLSMSDetails from "../CustomerLead-Panel/tables/smsDetails";
import CLEMAILDetails from "../CustomerLead-Panel/tables/emailDetails";
import CLInbox from "../CustomerLead-Panel/components/notificationInbox/index";
import CustommerLeadTestPackages from "../CustomerLead-Panel/pages/testPackages";
import CustommerLeadAuditScore from "../CustomerLead-Panel/pages/auditScore";
import CLAddAditionalFamily from "../CustomerLead-Panel/components/Bookings/NewBooking/AddFamilyMember";
import CLCoupon from "../CustomerLead-Panel/components/Coupon/index";
import CLImagingEditBookingForm from "../CustomerLead-Panel/components/Bookings/EditBookingForm/ImagingEditBooking";
import CustommerLeadEmployeePerformance from "../CustomerLead-Panel/pages/Performance/performanceTable";
import CLHelpDesk from "../CustomerLead-Panel/pages/HelpDesk";

//SalesLead Panel
import SalesLeadBookingPage from "../SalesLead-Panel/Components/Bookings/BookingPage";
import SalesLeadFilterPage from "../SalesLead-Panel/pages/filter";
import SalesLeadDashboard from "../SalesLead-Panel/pages/Dashboard";
import SalesLeadViewBooking from "../SalesLead-Panel/Components/Bookings/ViewBooking/index";
import SalesLeadEditBookingForm from "../SalesLead-Panel/Components/Bookings/EditBookingForm/EditBookingForm";
import SalesLeadHomePage from "../SalesLead-Panel/Components/Home";
import SalesLeadCreatePackage from "../SalesLead-Panel/Components/ManagePackages/CreatePackage";
import SalesLeadEditPackage from "../SalesLead-Panel/Components/ManagePackages/EditPackage";
import SalesLeadCreateUser from "../SalesLead-Panel/Components/SubAdmin/CreateUser";
import SalesLeadEditUser from "../SalesLead-Panel/Components/SubAdmin/editUser";
import SalesLeadDash from "../containers/SalesLead-Panel";
import SalesLeadSubAdminPage from "../SalesLead-Panel/Components/SubAdmin";
import SalesLeadPinCodePage from "../SalesLead-Panel/Components/PinCode";
import SalesLeadManageCoupons from "../SalesLead-Panel/Components/ManageCoupons";
import SalesLeadManageZones from "../SalesLead-Panel/Components/ManageZones";
import SLBookingWithLeadwhatsapp from "../SalesLead-Panel/Components/CRMChat/bookingWithLeadwhatsapp";
import SalesLeadManagePackages from "../SalesLead-Panel/Components/ManagePackages";
import SalesLeadManageBlogs from "../SalesLead-Panel/Components/ManageBlogs";
import SalesLeadCreateBlog from "../SalesLead-Panel/Components/ManageBlogs/CreateBlog";
import SalesLeadEditBlog from "../SalesLead-Panel/Components/ManageBlogs/EditBlog";
import SalesLeadBookingExport from "../SalesLead-Panel/pages/exportFilter";
import SalesLeadLeadExport from "../SalesLead-Panel/pages/exportLead";
import SalesLeadLabCredentials from "../SalesLead-Panel/Components/labsCredential";
import SalesLeadLeadsPage from "../SalesLead-Panel/pages/LeadsPage";
import SalesLeadAgentLoginStatus from "../SalesLead-Panel/pages/AgentLoginStatus/AgentLoginForm";
import SalesLeadCustomUploader from "../SalesLead-Panel/pages/custom-uploader";
import SalesLeadRCFreshLeadTable from "../SalesLead-Panel/tables/RCFreshTable";
import SalesLeadCLLeadTransfer from "../SalesLead-Panel/pages/lead-transfer/by-comma";
import SalesLeadCLDispositionExport from "../SalesLead-Panel/pages/leadstatus/export";
import SalesLeadCLDispositionBookingExport from "../SalesLead-Panel/pages/bookingstatus/export";
import SalesLeadCLCalls from "../SalesLead-Panel/tables/callsTable";
// import SalesLeadWhatsapp from "../SalesLead-Panel/tables/whatsappMessages";
import SalesLeadCRMchat from "../SalesLead-Panel/Components/CRMChat/whatsAppChat";
import SalesLeadCallDetails from "../SalesLead-Panel/tables/callDetails";
// import SalesLeadMissedCalls from "../SalesLead-Panel/tables/MissedCalls";
import SalesLeadCallStatsTable from "../SalesLead-Panel/tables/callStatsTable";
import SalesLeadCLComplaintsBooking from "../SalesLead-Panel/tables/queryTicketsBooking";
import SalesLeadsCLComplaintsLeads from "../SalesLead-Panel/tables/queryTicketsLeads";
import SalesLeadIncorrectNumberLeads from "../SalesLead-Panel/tables/incorrectNumberLeads";
import SalesLeadCLSearchResultsMain from "../SalesLead-Panel/Components/search/SearchResult";
import SalesLeadFillInfoPage from "../SalesLead-Panel/pages/fill-info";
import SalesLeadBreakTable from "../SalesLead-Panel/tables/breakDetails";
import SalesLeadDownload from "../SalesLead-Panel/Components/Bookings/DownloadBooking/Trf";
import SalesLeadAssignPanelUser from "../SalesLead-Panel/Components/AssignPanelUser/index";
import SalesLeadCallLogs from "../SalesLead-Panel/Components/CallModal/callModal";
import SalesLeadVerifiedBooking from "../SalesLead-Panel/tables/bookingVerificationTable";
import SLSMSDetails from "../SalesLead-Panel/tables/smsDetails";
import SLEMAILDetails from "../SalesLead-Panel/tables/emailDetails";
import SalesLeadTestPackages from "../SalesLead-Panel/pages/testPackages";
import SLCenterInfo from "../SalesLead-Panel/tables/centerTable";
import SLLabDetails from "../SalesLead-Panel/Components/LabModal/labDetails";
import SLInbox from "../SalesLead-Panel/Components/notificationInbox/index";
import SLAddAditionalFamily from "../SalesLead-Panel/Components/Bookings/NewBooking/AddFamilyMember";
// import SalesLeadCRMchat from "../SalesLead-Panel/Components/CRMChat/chatBox";
import SalesLeadTatTimeBookingtable from "../SalesLead-Panel/pages/tatTimeBookingFilter";
import SalesLeadAuditScore from "../SalesLead-Panel/pages/auditScore";
import SLbookingWithTaTExport from "../SalesLead-Panel/pages/exportTatFilter";
import SLImagingEditBookingForm from "../SalesLead-Panel/Components/Bookings/EditBookingForm/ImagingEditBooking";
import SalesLeadEmployeePerformance from "../SalesLead-Panel/pages/Performance/performanceTable";
import SalesLeadHelpDesk from "../SalesLead-Panel/pages/HelpDesk";
import SalesLeadAvailableSlots from "../SalesLead-Panel/pages/availableSlot";
import SalesLeadCancelBooking from "../SalesLead-Panel/pages/CancleBookingfilter";
import SLBiomarkerScreening from "../SalesLead-Panel/Components/Bookings/ViewBooking/BiomarkerScreeningreport";

// import SLQVCCallsExport from "../SalesLead-Panel/filters/quality-recording-filters/export";

//SalesLead Admin Panel
import SalesLeadAdminBookingPage from "../SalesLeadAdmin-Panel/Components/Bookings/BookingPage";
import SalesLeadAdminFilterPage from "../SalesLeadAdmin-Panel/pages/filter";
import SalesLeadAdminDashboard from "../SalesLeadAdmin-Panel/pages/Dashboard";
import SalesLeadAdminViewBooking from "../SalesLeadAdmin-Panel/Components/Bookings/ViewBooking/index";
import SalesLeadAdminEditBookingForm from "../SalesLeadAdmin-Panel/Components/Bookings/EditBookingForm/EditBookingForm";
import SalesLeadAdminHomePage from "../SalesLeadAdmin-Panel/Components/Home";
import SalesLeadAdminCreatePackage from "../SalesLeadAdmin-Panel/Components/ManagePackages/CreatePackage";
import SalesLeadAdminEditPackage from "../SalesLeadAdmin-Panel/Components/ManagePackages/EditPackage";
import SalesLeadAdminCreateUser from "../SalesLeadAdmin-Panel/Components/SubAdmin/CreateUser";
import SalesLeadAdminEditUser from "../SalesLeadAdmin-Panel/Components/SubAdmin/editUser";
import SalesLeadAdminDash from "../containers/SalesLeadAdmin-Panel";
import SalesLeadAdminSubAdminPage from "../SalesLeadAdmin-Panel/Components/SubAdmin";
import SalesLeadAdminPinCodePage from "../SalesLeadAdmin-Panel/Components/PinCode";
import SLAViewBookingOnMap from "../SalesLeadAdmin-Panel/Components/BookingCard/ViewBookingOnMap";
import SalesLeadAdminManageCoupons from "../SalesLeadAdmin-Panel/Components/ManageCoupons";
import SalesLeadAdminManageZones from "../SalesLeadAdmin-Panel/Components/ManageZones";
import SalesLeadAdminManagePackages from "../SalesLeadAdmin-Panel/Components/ManagePackages";
import SalesLeadAdminManageBlogs from "../SalesLeadAdmin-Panel/Components/ManageBlogs";
import SalesLeadAdminCreateBlog from "../SalesLeadAdmin-Panel/Components/ManageBlogs/CreateBlog";
import SalesLeadAdminEditBlog from "../SalesLeadAdmin-Panel/Components/ManageBlogs/EditBlog";
import SalesLeadAdminBookingExport from "../SalesLeadAdmin-Panel/pages/exportFilter";
import SalesLeadAdminLeadExport from "../SalesLeadAdmin-Panel/pages/exportLead";
import SalesLeadAdminLabCredentials from "../SalesLeadAdmin-Panel/Components/labsCredential";
import SalesLeadAdminLeadsPage from "../SalesLeadAdmin-Panel/pages/LeadsPage";
import SalesLeadAdminDisposition from "../SalesLeadAdmin-Panel/pages/leadstatus/filter";
import SalesLeadAdminCustomUploader from "../SalesLeadAdmin-Panel/pages/custom-uploader";
import SalesLeadAdminRCFreshLeadTable from "../SalesLeadAdmin-Panel/tables/RCFreshTable";
import SalesLeadAdminCLLeadTransfer from "../SalesLeadAdmin-Panel/pages/lead-transfer/by-comma";
import SalesLeadAdminCLDispositionExport from "../SalesLeadAdmin-Panel/pages/leadstatus/export";
import SalesLeadAdminCLDispositionBookingExport from "../SalesLeadAdmin-Panel/pages/bookingstatus/export";
import SalesLeadAdminCLCalls from "../SalesLeadAdmin-Panel/tables/callsTable";
import SalesLeadAdminWhatsapp from "../SalesLeadAdmin-Panel/tables/whatsappMessages";
import SalesLeadAdminCallDetails from "../SalesLeadAdmin-Panel/tables/callDetails";
// import SalesLeadAdminMissedCalls from "../SalesLeadAdmin-Panel/tables/MissedCalls";
import SalesLeadAdminCallStatsTable from "../SalesLeadAdmin-Panel/tables/callStatsTable";
import SalesLeadAdminCLComplaintsBooking from "../SalesLeadAdmin-Panel/tables/queryTicketsBooking";
import SalesLeadAdminsCLComplaintsLeads from "../SalesLeadAdmin-Panel/tables/queryTicketsLeads";
import SalesLeadAdminIncorrectNumberLeads from "../SalesLeadAdmin-Panel/tables/incorrectNumberLeads";
import SalesLeadAdminCLSearchResultsMain from "../SalesLeadAdmin-Panel/Components/search/SearchResult";
import SalesLeadAdminFillInfoPage from "../SalesLeadAdmin-Panel/pages/fill-info";
// import SalesLeadAdminQVCCallsExport from "../SalesLeadAdmin-Panel/filters/quality-recording-filters/export";
import SalesLeadAdminBreakTable from "../SalesLeadAdmin-Panel/tables/breakDetails";
import SalesLeadAdminAssignPanelUser from "../SalesLeadAdmin-Panel/Components/AssignPanelUser/index";
import SalesLeadAdminCallLogs from "../SalesLeadAdmin-Panel/Components/CallModal/callModal";
import SalesLeadAdminVerifiedBooking from "../SalesLeadAdmin-Panel/tables/bookingVerificationTable";
import SLAdminSMSDetails from "../SalesLeadAdmin-Panel/tables/smsDetails";
import SLAdminEMAILDetails from "../SalesLeadAdmin-Panel/tables/emailDetails";
import SalesLeadAdminTestPackages from "../SalesLeadAdmin-Panel/pages/testPackages";
import SLAdminCenterInfo from "../SalesLeadAdmin-Panel/tables/centerTable";
import SLAdminLabDetails from "../SalesLeadAdmin-Panel/Components/LabModal/labDetails";
import SLAdminInbox from "../SalesLeadAdmin-Panel/Components/notificationInbox/index";
import SLAdminAddAditionalFamily from "../SalesLeadAdmin-Panel/Components/Bookings/NewBooking/AddFamilyMember";
import SLAdminAnalyticsRecording from "../SalesLeadAdmin-Panel/pages/AnalyticsRecording/index";
// import SalesLeadAdminCRMchat from "../SalesLeadAdmin-Panel/Components/CRMChat/chatBox";
import SLACRMchat from "../SalesLeadAdmin-Panel/Components/CRMChat/whatsAppChat";
import SLABookingWithLeadwhatsapp from "../SalesLeadAdmin-Panel/Components/CRMChat/bookingWithLeadwhatsapp";
import SalesLeadAdminTatTimeBookingtable from "../SalesLeadAdmin-Panel/pages/tatTimeBookingFilter";
import SLAdminbookingWithTaTExport from "../SalesLeadAdmin-Panel/pages/exportTatFilter";
import SLABookingCard from "../SalesLeadAdmin-Panel/Components/BookingCard/index";
import SLACurrentDayBooking from "../SalesLeadAdmin-Panel/pages/currentDayBooking";
import SLACityWisePhlebo from "../SalesLeadAdmin-Panel/pages/cityWisePhlebo";
import SLAColdCallingLeadUpload from "../SalesLeadAdmin-Panel/pages/ColdCallingLeadUpload/index";
import SLACityAvailableSlots from "../SalesLeadAdmin-Panel/pages/availableSlot";
import SLAManageAttendance from "../SalesLeadAdmin-Panel/Components/ManageAttendance/index";
import SLATRFDownload from "../SalesLeadAdmin-Panel/Components/Bookings/DownloadBooking/Trf";
import SLAPhleboBookingView from "../SalesLeadAdmin-Panel/Components/PhleboBookingPanel/BookingView";
import SLAPleboAdminCommentView from "../SalesLeadAdmin-Panel/Components/PhleboBookingPanel/CommentView";
import SLASampleHandOver from "../SalesLeadAdmin-Panel/tables/sampleHandOverTable";
import SalesLeadAdminManagePhlebo from "../SalesLeadAdmin-Panel/tables/managePhlebos";
import SLAImagingEditBookingForm from "../SalesLeadAdmin-Panel/Components/Bookings/EditBookingForm/ImagingEditBooking";
import SLAAdvisorHealthData from "../SalesLeadAdmin-Panel/tables/healthData";
import SLABookingWithDispo from "../SalesLeadAdmin-Panel/Components/Bookings/NewBooking/bookingWithDisposition";
import SalesLeadAdminPhaRiderCashDue from "../SalesLeadAdmin-Panel/pages/PhleboRiderDue";
import SalesLeadAdminImagingBookingTicket from "../SalesLeadAdmin-Panel/pages/imagingTicket";
import SLARefundPayment from "../SalesLeadAdmin-Panel/pages/RrfundPayment/refundPaymentFilter";
import SalesLeadAdminEmployeePerformance from "../SalesLeadAdmin-Panel/pages/Performance/performanceTable";
import SalesLeadAdminHelpDesk from "../SalesLeadAdmin-Panel/pages/HelpDesk";
import SalesLeadAdminReportNotDeliverd from "../SalesLeadAdmin-Panel/pages/ConsolidateReportBooking/filter";
import SalesLeadAdminTatReport from "../SalesLeadAdmin-Panel/tables/bookingTatReport";
import SalesLeadADMINAuditScore from "../SalesLeadAdmin-Panel/pages/auditScore";
import SalesLeadADMINdoctorConsultation from "../SalesLeadAdmin-Panel/pages/doctorConsultationFilter";
import SalesLeadAdminLogisticTat from "../SalesLeadAdmin-Panel/tables/logisticTatReport";
import SalesLeadAdminBatchedPayment from "../SalesLeadAdmin-Panel/pages/BatchedPayment/index";
import SalesLeadAdminPhleboRiderMap from "../SalesLeadAdmin-Panel/pages/phleboRiderMapping";
import SalesLeadAdminRoutePlan from "../SalesLeadAdmin-Panel/tables/routePlan";
import SalesLeadAdminHoliday from "../SalesLeadAdmin-Panel/pages/HolidayCalender/index";
import SLABiomarkerScreening from "../SalesLeadAdmin-Panel/Components/Bookings/ViewBooking/BiomarkerScreeningreport";
import SalesLeadAdminCampDetails from "../SalesLeadAdmin-Panel/tables/CampDetails";
import SalesLeadAdminCreateAlias from "../SalesLeadAdmin-Panel/pages/Alias-Master/index";
import SalesLeadAdminCreateCityAlias from "../SalesLeadAdmin-Panel/pages/CityAlias-Master/index";


//Health Advisor
import HealthAdviserAgentDash from "../containers/HealthAdviser-agent/index";
import HealthAdviserBookingsPage from "../HealthAdviser-Panel/pages/filter";
import HealthAdviserNewBookingsPage from "../HealthAdviser-Panel/components/Bookings/BookingPage";
import HealthAdviserViewBooking from "../HealthAdviser-Panel/components/Bookings/ViewBooking/index";
import HealthAdviserEditBooking from "../HealthAdviser-Panel/components/Bookings/EditBookingForm/EditBookingForm";
import HACenterInfo from "../HealthAdviser-Panel/tables/centerTable";
import HealthAdviserFillInfoPage from "../HealthAdviser-Panel/pages/fill-info";
import HAPrescriptionData from "../HealthAdviser-Panel/tables/prescriptionDetails";
import HealthAdviserBookingWithLead from "../HealthAdviser-Panel/components/Bookings/NewBooking/bookingWithLead";
import HealthAdvisorBookingWithDispo from "../HealthAdviser-Panel/components/Bookings/NewBooking/bookingWithDisposition";
import HealthAdviserTestPackages from "../HealthAdviser-Panel/pages/testPackages";
import HealthAdvisorHealthData from "../HealthAdviser-Panel/tables/healthData";
import HealthAdvisorDispositionExport from "../HealthAdviser-Panel/pages/leadstatus/export";
import HAAddAditionalFamily from "../HealthAdviser-Panel/components/Bookings/NewBooking/AddFamilyMember";
import HealthAdvisorTransferBooking from "../HealthAdviser-Panel/pages/transferBookingfilter";
import HAHelpDesk from "../HealthAdviser-Panel/pages/HelpDesk";

//Health Advisor Admin
import HealthAdviserAdminAgentDash from "../containers/HealthAdviserAdmin-agent/index";
import HealthAdviserAdminBookingsPage from "../HealthAdviserAdmin-Panel/pages/filter";
import HealthAdviserAdminNewBookingsPage from "../HealthAdviserAdmin-Panel/components/Bookings/BookingPage";
import HealthAdviserAdminViewBooking from "../HealthAdviserAdmin-Panel/components/Bookings/ViewBooking/index";
import HealthAdviserAdminEditBooking from "../HealthAdviserAdmin-Panel/components/Bookings/EditBookingForm/EditBookingForm";
import HealthAdviserAdminCenterInfo from "../HealthAdviserAdmin-Panel/tables/centerTable";
import HealthAdviserAdminFillInfoPage from "../HealthAdviserAdmin-Panel/pages/fill-info";
import HealthAdviserAdminPrescriptionData from "../HealthAdviserAdmin-Panel/tables/prescriptionDetails";
import HealthAdviserAdminBookingWithLead from "../HealthAdviserAdmin-Panel/components/Bookings/NewBooking/bookingWithLead";
import HealthAdviserAdminBookingWithDispo from "../HealthAdviserAdmin-Panel/components/Bookings/NewBooking/bookingWithDisposition";
// import HealthAdviserAdminTestPackages from "../HealthAdviserAdmin-Panel/pages/testPackages";
import HealthAdviserAdminHealthData from "../HealthAdviserAdmin-Panel/tables/healthData";
import HealthAdviserAdminDispositionExport from "../HealthAdviserAdmin-Panel/pages/leadstatus/export";
import HealthAdviserAdminAddAditionalFamily from "../HealthAdviserAdmin-Panel/components/Bookings/NewBooking/AddFamilyMember";
import HealthAdvisorAdminTransferBooking from "../HealthAdviserAdmin-Panel/pages/transferBookingfilter";
import HealthAdvisorAdminTestPackages from "../HealthAdviserAdmin-Panel/pages/testPackages";
import HealthAdvisorAdminLabDetails from "../HealthAdviserAdmin-Panel/components/LabModal/labDetails";
import HealthAdvisorAdminWhatsapp from "../HealthAdviserAdmin-Panel/tables/whatsappMessages";
import HealthAdvisorAdminManageCoupons from "../HealthAdviserAdmin-Panel/components/ManageCoupons";
import HealthAdvisorAdminCallDetails from "../HealthAdviserAdmin-Panel/tables/callDetails";
import HealthAdvisorAdminComplaintsBooking from "../HealthAdviserAdmin-Panel/tables/queryTicketsBooking";
import HealthAdvisorAdmiCallStatsTable from "../HealthAdviserAdmin-Panel/tables/callStatsTable";
import HealthAdvisorAdmiBreakTable from "../HealthAdviserAdmin-Panel/tables/breakDetails";
import HealthAdvisorAdmiCreateUser from "../HealthAdviserAdmin-Panel/components/SubAdmin/CreateUser";
import HealthAdvisorAdmiEditUser from "../HealthAdviserAdmin-Panel/components/SubAdmin/editUser";
import HealthAdvisorAdmiSubAdminPage from "../HealthAdviserAdmin-Panel/components/SubAdmin";
import HealthAdvisorAdmiCallDetails from "../HealthAdviserAdmin-Panel/tables/callDetails";
import HealthAdvisorAdmiLeadsPage from "../HealthAdviserAdmin-Panel/pages/LeadsPage";
import HealthAdvisorAdmiHelpDesk from "../HealthAdviserAdmin-Panel/pages/HelpDesk";
import CallModal from '../CustomerExecutive-Panel/pages/Callmodel'
var jwt = require('jsonwebtoken');
let userGroup = localStorage.getItem("userProfileuserGroup");

const Routes = ({
  // locationDataFromPusher
}) => {
  if (window) {
    var path = window.location.pathname;
    var query = window.location.search;
  }
  const history = useHistory();
  const [localData, setLocalData] = useState(localStorage.notificationData);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [messageInfo, setMessageInfo] = React.useState(undefined);
  const [userDetail, setUserDetail] = React.useState({
    userName: null,
    authToken: null
  });

  useEffect(() => {
    const checkLoginInterval = setInterval(() => {
      const authToken = SecureStorage.getItem("token");
      const userName = SecureStorage.getItem("username");
      if (authToken && userName) {
        setUserDetail({
          authToken,
          userName
        });
        clearInterval(checkLoginInterval);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    Notification.requestPermission()
      .then()
      .catch();
    if (userDetail.authToken && userDetail.userName) {
      const apiEndpoint = `${process.env.REACT_APP_API_PATH}api/v1/auth`
      Pusher.logToConsole = true;
      var pusher = new Pusher(`${process.env.REACT_APP_CALLPOPUP_KEY}`, {
        cluster: "ap2",
        forceTLS: true,
        // Channel authorization
        channelAuthorization: {
          endpoint: `${apiEndpoint}/pusher/auth/`,
          headers: {
            Authorization: `Token ${userDetail.authToken}`,
          },
        },
      });
      // User sign-in
      // pusher.signin();
      const userNameForPusher = userDetail?.userName.replace(/ /g, "")
      var channel = pusher.subscribe(`private-${userDetail?.userName}`);
      channel.bind("new_call", function (data) {
        localStorage.setItem("notificationData", JSON.stringify(data));
        NotificationManager.success(
          handle(data), ``,
          30000
        );
      });

      channel.bind("location_update", function (data) {
        dispatch({ type: ACTIONS.LOCATION_DATA_FROM_PUSHER, payload: data });
      });
      channel.bind("new_whatsapp", function (data) {
        dispatch({ type: ACTIONS.WHATSAPP_DATA_FROM_PUSHER, payload: data });
        NotificationManager.info(
          handleWhatsapp(data), ``,
          3000
        );
      });
      channel.bind("new_whatsapp", function (data) {
        dispatch({ type: ACTIONS.WHATSAPP_DATA_FROM_PUSHER_FOR_SL, payload: data });
        NotificationManager.info(
          handleWhatsapp(data), ``,
          3000
        );
      });
      const decoded = jwt.decode(userDetail.authToken);
      userGroup = decoded?.user_group;
      if (decoded?.user_group === "CustomerExecutive") {
        var channelForAddressId = pusher.subscribe(`private-group;CustomerExecutive`);
        channelForAddressId.bind("whatsapp_reciept", function (data) {
          dispatch({ type: ACTIONS.WHATSAPP_ADDRESS_ID_DETAIL, payload: data });
        });
      }
    }
  }, [userDetail]);

  useEffect(() => {
    if (localData) {
      NotificationManager.success(
        handle(JSON.parse(localData)), ``,
        30000
      );
    }
  }, [])

  const handleWhatsapp = (data) => {
    return (
      <div>
        <p>
          <span style={{ color: "black" }}>Lead: {data?.id}</span>
          <br />
          <b> {data?.patient_name} {": "} {data?.message}</b>
          <br />
          <b style={{ color: "black" }}>
            {new Date(data?.latest_chat_created_at).toLocaleString()}
          </b>
        </p>
      </div>
    );
  };

  const handle = (data) => {
    var timestamp = data.timestamp
    var date1 = new Date(timestamp);
    var ComplateDate = date1.getDate() +
      "/" + (date1.getMonth() + 1) +
      "/" + date1.getFullYear() +
      " " + date1.getHours() +
      ":" + date1.getMinutes() +
      ":" + date1.getSeconds()
    return (
      <div onClick={() => localStorage.setItem("notificationData", "")}>
        <p><b>Lead: {data?.lead_id}</b><br />Camp: {data?.camp_name} ,Timestamp: {ComplateDate}</p>
        <div
          className="upper_panel"
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: 20,
          }}
        >
          <Link
            className="btn"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgb(16, 185, 129)",
              height: 40,
              borderRadius: "20px",
              color: "white",
              width: "90%",
              fontSize: 14,
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            target="_blank"
            to={`/dashboard/ce/fill-info?id=${data?.lead_id}`}
          >
            VISIT
          </Link>
        </div>
      </div>
    );
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };

  return (
    <BrowserRouter>
      <NotificationContainer />
      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        TransitionProps={{ onExited: handleExited }}
        message={messageInfo ? messageInfo.message : undefined}
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />

      <MainLayout>
        <SnackBar />
        {userGroup === "CustomerExecutive" ? <CallModal /> : ""}

        {/* <PushNotification /> */}
        <Switch>
          <Route path="/" exact component={Login} />
          <Route
            path="/dashboard"
            exact={false}
            component={() => {
              const token = SecureStorage.getItem("token");
              const decoded = jwt.decode(token);
              if (decoded?.user_group === "CustomerExecutive") {
                // setTokenReceive(token);
                return (
                  <Switch>
                    <Route
                      path="/dashboard"
                      exact
                      component={() => <Dash path={path} query={query} />}
                    />
                    <Route
                      path="/dashboard/ce"
                      exact={false}
                      component={() => {
                        return (
                          <Switch>
                            <AnalystAgentDash>
                              <CallModal />
                              <Route
                                path="/dashboard/ce"
                                exact
                                component={CustomerExecutiveHomePage}

                              />
                              <Route
                                path="/dashboard/ce/calls_recording"
                                exact
                                component={
                                  CustomerExecutiveDirectionTable
                                }
                              />
                              <Route
                                path="/dashboard/ce/calls/stats"
                                exact
                                component={CECallsStats}
                              />
                              <Route
                                path="/dashboard/ce/whatsapp"
                                exact
                                component={CustomerExecutiveWhatsapp}
                              />
                              <Route
                                path="/dashboard/ce/bookings"
                                exact
                                component={FilterPage}
                              />
                              <Route
                                path="/dashboard/ce/booking"
                                exact
                                component={CEBookingPage}
                              />
                              <Route
                                path="/dashboard/ce/radiology-homedx/:id"
                                exact
                                component={CERadiologyToHomeDx}
                              />
                              <Route
                                path="/dashboard/ce/imaging-ticekt"
                                exact
                                component={CEImaginingTicekt}
                              />
                              <Route
                                path="/dashboard/ce/homedx-radiology/:id"
                                exact
                                component={CEHomeDxTORadiology}
                              />
                              <Route
                                path="/dashboard/ce/booking-view/:id"
                                exact
                                component={ViewBooking}
                              />
                              <Route
                                path="/dashboard/ce/booking-edit/:id/edit"
                                exact
                                component={EditBookingForm}
                              />
                              <Route
                                path="/dashboard/ce/booking-imaging-edit/:id/edit"
                                exact
                                component={CEImagingEditBookingForm}
                              />
                              <Route
                                path="/dashboard/ce/booking-additionalFamily/:id/add"
                                exact
                                component={CEAddAditionalFamily}
                              />
                              <Route
                                path="/dashboard/ce/booking-editadditionalFamily/:id/edit"
                                exact
                                component={EditAditionalFamily}
                              />

                              {/* <Route
                                      path="/dashboard/ce/missed-calls"
                                      exact
                                      component={CETodayMissedCalls}
                                    /> */}
                              <Route
                                path="/dashboard/ce/Leadtable"
                                exact
                                component={CELeadDetails}
                              />

                              <Route
                                path="/dashboard/ce/cloud-agent"
                                exact
                                component={CECloudAgent}
                              />
                              <Route
                                path="/dashboard/ce/RepetedList"
                                exact
                                component={CERepeatedLead}
                              />
                              {/* <Route
                                      path="/dashboard/ce/InvoiceCopy"
                                      exact
                                      component={CEInvoiceCopy}
                                    /> */}

                              <Route
                                path="/dashboard/ce/fill-info"
                                exact
                                component={CustomerExecutiveFillInfoPage}
                              />
                              <Route
                                path="/dashboard/ce/lead/search"
                                exact
                                component={CESearchResultsMain}
                              />
                              <Route
                                exact
                                path="/dashboard/ce/lead/smsDetails"
                                component={CESMSDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/ce/lead/emailDetails"
                                component={CEEMAILDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/ce/booking/tickets"
                                component={CEComplaintsBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/ce/centerInfo"
                                component={CECenterInfo}
                              />
                              <Route
                                exact
                                path="/dashboard/ce/inbox"
                                component={CEInbox}
                              />
                              <Route
                                exact
                                path="/dashboard/ce/generate-coupon"
                                component={CECoupon}
                              />
                              <Route
                                exact
                                path="/dashboard/ce/testPackages"
                                component={CustomerExecutiveTestPackages}
                              />
                              <Route
                                exact
                                path="/dashboard/ce/labDetails"
                                component={CELabDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/ce/sendNotification"
                                component={CESendNotification}
                              />
                              <Route
                                exact
                                path="/dashboard/ce/AuditScore"
                                component={CEAuditScore}
                              />
                              <Route
                                exact
                                path="/dashboard/ce/helpdesk"
                                component={CEHelpDesk}
                              />
                            </AnalystAgentDash>
                          </Switch>
                        );
                      }}
                    />
                    <Route
                      path="*"
                    >
                      <Redirect to="/" />
                    </Route>
                  </Switch>)
              } else if (decoded?.user_group === "SalesLeadAdmin") {
                // setTokenReceive(token);
                return (
                  <Switch>
                    <Route
                      path="/dashboard"
                      exact
                      component={() => <Dash path={path} query={query} />}
                    />
                    <Route
                      path="/dashboard/sla"
                      exact={false}
                      component={() => {
                        return (
                          <Switch>
                            <SalesLeadAdminDash>
                              <Route
                                exact
                                path="/dashboard/sla"
                                component={SalesLeadAdminDashboard}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/booking_table"
                                component={SalesLeadAdminFilterPage}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/dash"
                                component={SalesLeadAdminHomePage}
                              />
                              <Route
                                path="/dashboard/sla/booking"
                                exact
                                component={SalesLeadAdminBookingPage}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/pincodes"
                                component={SalesLeadAdminPinCodePage}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/view-booking-on-map"
                                component={SLAViewBookingOnMap}
                              />
                              <Route
                                path="/dashboard/sla/whatsapp_chat"
                                exact
                                component={SLACRMchat}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/phleboridermap"
                                component={SalesLeadAdminPhleboRiderMap}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/route_plan"
                                component={SalesLeadAdminRoutePlan}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/holiday"
                                component={SalesLeadAdminHoliday}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/campaigns"
                                component={SalesLeadAdminCampDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/booking-with-leadwhatsapp/:id"
                                component={SLABookingWithLeadwhatsapp}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/coupons"
                                component={SalesLeadAdminManageCoupons}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/zones"
                                component={SalesLeadAdminManageZones}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/packages"
                                component={SalesLeadAdminManagePackages}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/packages/edit/:slug"
                                component={SalesLeadAdminEditPackage}
                              />
                              <Route
                                path="/dashboard/sla/booking-additionalFamily/:id/add"
                                exact
                                component={SLAdminAddAditionalFamily}
                              />
                              <Route
                                path="/dashboard/sla/analytics-recording"
                                exact
                                component={SLAdminAnalyticsRecording}
                              />
                              {/* <Route
                                path="/dashboard/sla/crm_chat"
                                exact
                                component={SalesLeadAdminCRMchat}
                              /> */}
                              <Route
                                exact
                                path="/dashboard/sla/packages/create"
                                component={SalesLeadAdminCreatePackage}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/users"
                                component={SalesLeadAdminSubAdminPage}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/users/create"
                                component={SalesLeadAdminCreateUser}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/users/editUser"
                                component={SalesLeadAdminEditUser}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/blogs"
                                component={SalesLeadAdminManageBlogs}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/blogs/create"
                                component={SalesLeadAdminCreateBlog}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/blog/:slug"
                                component={SalesLeadAdminEditBlog}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/bookings/export"
                                component={SalesLeadAdminBookingExport}
                              />

                              <Route
                                exact
                                path="/dashboard/sla/leads/export"
                                component={SalesLeadAdminLeadExport}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/labcredential"
                                component={SalesLeadAdminLabCredentials}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/leads"
                                component={SalesLeadAdminLeadsPage}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/dispositions"
                                component={SalesLeadAdminDisposition}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/uploader"
                                component={SalesLeadAdminCustomUploader}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/RCFreshLeadsTable"
                                component={SalesLeadAdminRCFreshLeadTable}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/leads-transfer"
                                component={SalesLeadAdminCLLeadTransfer}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/dispositions/leadexport"
                                component={
                                  SalesLeadAdminCLDispositionExport
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/sla/dispositions/bookingexport"
                                component={
                                  SalesLeadAdminCLDispositionBookingExport
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/sla/lead/smsDetails"
                                component={SLAdminSMSDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/lead/emailDetails"
                                component={SLAdminEMAILDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/calls"
                                component={SalesLeadAdminCLCalls}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/whatsapp"
                                component={SalesLeadAdminWhatsapp}
                              />
                               <Route
                                exact
                                path="/dashboard/sla/Alias"
                                component={SalesLeadAdminCreateAlias}
                              />
                               <Route
                                exact
                                path="/dashboard/sla/CityAlias-Master"
                                component={SalesLeadAdminCreateCityAlias}
                              />

                              <Route
                                exact
                                path="/dashboard/sla/call_details"
                                component={SalesLeadAdminCallDetails}
                              />
                              {/* <Route
                                      exact
                                      path="/dashboard/sla/missed-calls"
                                      component={SalesLeadAdminMissedCalls}
                                    /> */}
                              <Route
                                exact
                                path="/dashboard/sla/calls/stats"
                                component={SalesLeadAdminCallStatsTable}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/booking/tickets"
                                component={
                                  SalesLeadAdminCLComplaintsBooking
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/sla/lead/tickets"
                                component={
                                  SalesLeadAdminsCLComplaintsLeads
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/sla/lead/incorrectNumberLead"
                                component={
                                  SalesLeadAdminIncorrectNumberLeads
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/sla/booking-view/:id"
                                component={SalesLeadAdminViewBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/phlebo"
                                component={SalesLeadAdminManagePhlebo}
                              />
                              <Route
                                path="/dashboard/sla/booking-imaging-edit/:id/edit"
                                exact
                                component={SLAImagingEditBookingForm}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/booking-with-disposition/:id"
                                component={SLABookingWithDispo}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/cash-due-on-pha-rider"
                                component={SalesLeadAdminPhaRiderCashDue}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/booking/imaging-ticket"
                                component={
                                  SalesLeadAdminImagingBookingTicket
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/sla/health_data"
                                component={SLAAdvisorHealthData}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/booking-edit/:id/edit"
                                component={SalesLeadAdminEditBookingForm}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/lead/search"
                                component={
                                  SalesLeadAdminCLSearchResultsMain
                                }
                              />
                              {/* <Route
                                      exact
                                      path="/dashboard/sla/calls/export"
                                      component={SalesLeadAdminQVCCallsExport}
                                    /> */}
                              <Route
                                exact
                                path="/dashboard/sla/fill-info"
                                component={SalesLeadAdminFillInfoPage}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/breakData"
                                component={SalesLeadAdminBreakTable}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/assignPanelUser"
                                component={SalesLeadAdminAssignPanelUser}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/verifiedbooking"
                                component={SalesLeadAdminVerifiedBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/callLog"
                                component={SalesLeadAdminCallLogs}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/testPackages"
                                component={SalesLeadAdminTestPackages}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/centerInfo"
                                component={SLAdminCenterInfo}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/labDetails"
                                component={SLAdminLabDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/tat_timeBooking"
                                component={
                                  SalesLeadAdminTatTimeBookingtable
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/sla/inbox"
                                component={SLAdminInbox}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/export_tat_booking"
                                component={SLAdminbookingWithTaTExport}
                              />

                              <Route
                                exact
                                path="/dashboard/sla/assignbookings"
                                component={SLABookingCard}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/currentDayBooking"
                                component={SLACurrentDayBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/cityWisePhlebos"
                                component={SLACityWisePhlebo}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/coldcallleaduploader"
                                component={SLAColdCallingLeadUpload}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/availableSlot"
                                component={SLACityAvailableSlots}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/phleboBookingView"
                                component={SLAPhleboBookingView}
                              />

                              <Route
                                exact
                                path="/dashboard/sla/timeline/:id/view"
                                component={SLAPleboAdminCommentView}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/attendance"
                                component={SLAManageAttendance}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/download/:id/download"
                                component={SLATRFDownload}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/SampleHandOver"
                                component={SLASampleHandOver}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/payment-refund"
                                component={SLARefundPayment}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/performance"
                                component={
                                  SalesLeadAdminEmployeePerformance
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/sla/helpdesk"
                                component={SalesLeadAdminHelpDesk}
                              />
                              <Route
                                path="/dashboard/sla/report_not_deliverd"
                                exact
                                component={
                                  SalesLeadAdminReportNotDeliverd
                                }
                              />
                              <Route
                                path="/dashboard/sla/doctor_consultation"
                                exact
                                component={
                                  SalesLeadADMINdoctorConsultation
                                }
                              />
                              <Route
                                path="/dashboard/sla/booking-tat-report"
                                exact
                                component={SalesLeadAdminLogisticTat}
                              />
                              <Route
                                path="/dashboard/sla/batched_payment"
                                exact
                                component={SalesLeadAdminBatchedPayment}
                              />
                              <Route
                                exact
                                path="/dashboard/sla/auditScore"
                                component={SalesLeadADMINAuditScore}
                              />
                            </SalesLeadAdminDash>
                          </Switch>
                        );
                      }}
                    />
                    <Route
                      path="*"
                    >
                      <Redirect to="/" />
                    </Route>
                  </Switch>)
              }
              else if (decoded?.user_group === "SalesLead") {
                // setTokenReceive(token);
                return (
                  <Switch>
                    <Route
                      path="/dashboard"
                      exact
                      component={() => <Dash path={path} query={query} />}
                    />
                    <Route
                      path="/dashboard/sl"
                      exact={false}
                      component={() => {
                        return (
                          <Switch>
                            <SalesLeadDash>
                              <Route
                                exact
                                path="/dashboard/sl"
                                component={SalesLeadDashboard}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/booking_table"
                                component={SalesLeadFilterPage}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/dash"
                                component={SalesLeadHomePage}
                              />
                              <Route
                                path="/dashboard/sl/booking"
                                exact
                                component={SalesLeadBookingPage}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/pincodes"
                                component={SalesLeadPinCodePage}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/coupons"
                                component={SalesLeadManageCoupons}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/zones"
                                component={SalesLeadManageZones}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/booking-with-leadwhatsapp/:id"
                                component={SLBookingWithLeadwhatsapp}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/packages"
                                component={SalesLeadManagePackages}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/packages/edit/:slug"
                                component={SalesLeadEditPackage}
                              />
                              <Route
                                path="/dashboard/sl/booking-additionalFamily/:id/add"
                                exact
                                component={SLAddAditionalFamily}
                              />
                              {/* <Route
                                path="/dashboard/sl/crm_chat"
                                exact
                                component={SalesLeadCRMchat}
                              /> */}
                              <Route
                                exact
                                path="/dashboard/sl/packages/create"
                                component={SalesLeadCreatePackage}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/users"
                                component={SalesLeadSubAdminPage}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/users/create"
                                component={SalesLeadCreateUser}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/users/editUser"
                                component={SalesLeadEditUser}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/blogs"
                                component={SalesLeadManageBlogs}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/blogs/create"
                                component={SalesLeadCreateBlog}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/blog/:slug"
                                component={SalesLeadEditBlog}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/bookings/export"
                                component={SalesLeadBookingExport}
                              />

                              <Route
                                exact
                                path="/dashboard/sl/leads/export"
                                component={SalesLeadLeadExport}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/labcredential"
                                component={SalesLeadLabCredentials}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/leads"
                                component={SalesLeadLeadsPage}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/agentloginstatus"
                                component={SalesLeadAgentLoginStatus}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/uploader"
                                component={SalesLeadCustomUploader}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/RCFreshLeadsTable"
                                component={SalesLeadRCFreshLeadTable}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/leads-transfer"
                                component={SalesLeadCLLeadTransfer}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/dispositions/leadexport"
                                component={SalesLeadCLDispositionExport}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/dispositions/bookingexport"
                                component={
                                  SalesLeadCLDispositionBookingExport
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/sl/lead/smsDetails"
                                component={SLSMSDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/lead/emailDetails"
                                component={SLEMAILDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/calls"
                                component={SalesLeadCLCalls}
                              />
                              {/* <Route
                                exact
                                path="/dashboard/sl/whatsapp"
                                component={SalesLeadWhatsapp}
                              /> */}
                              <Route
                                path="/dashboard/sl/whatsapp_chat"
                                exact
                                component={SalesLeadCRMchat}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/calls_recording"
                                component={SalesLeadCallDetails}
                              />
                              {/* <Route
                                      exact
                                      path="/dashboard/sl/missed-calls"
                                      component={SalesLeadMissedCalls}
                                    /> */}
                              <Route
                                exact
                                path="/dashboard/sl/calls/stats"
                                component={SalesLeadCallStatsTable}
                              />
                              {/* <Route
                                      exact
                                      path="/dashboard/sl/calls/export"
                                      component={SLQVCCallsExport}
                                    /> */}
                              <Route
                                exact
                                path="/dashboard/sl/booking/tickets"
                                component={SalesLeadCLComplaintsBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/lead/tickets"
                                component={SalesLeadsCLComplaintsLeads}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/lead/incorrectNumberLead"
                                component={SalesLeadIncorrectNumberLeads}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/booking-view/:id"
                                component={SalesLeadViewBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/booking-edit/:id/edit"
                                component={SalesLeadEditBookingForm}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/lead/search"
                                component={SalesLeadCLSearchResultsMain}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/performance"
                                component={SalesLeadEmployeePerformance}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/helpdesk"
                                component={SalesLeadHelpDesk}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/availableSlot"
                                component={SalesLeadAvailableSlots}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/cancel_booking"
                                component={SalesLeadCancelBooking}
                              />
                              <Route
                                path="/dashboard/sl/booking-imaging-edit/:id/edit"
                                exact
                                component={SLImagingEditBookingForm}
                              />

                              <Route
                                exact
                                path="/dashboard/sl/fill-info"
                                component={SalesLeadFillInfoPage}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/breakData"
                                component={SalesLeadBreakTable}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/download/:id/download"
                                component={SalesLeadDownload}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/assignPanelUser"
                                component={SalesLeadAssignPanelUser}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/verifiedbooking"
                                component={SalesLeadVerifiedBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/callLog"
                                component={SalesLeadCallLogs}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/testPackages"
                                component={SalesLeadTestPackages}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/centerInfo"
                                component={SLCenterInfo}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/labDetails"
                                component={SLLabDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/tat_timeBooking"
                                component={SalesLeadTatTimeBookingtable}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/auditScore"
                                component={SalesLeadAuditScore}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/inbox"
                                component={SLInbox}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/export_tat_booking"
                                component={SLbookingWithTaTExport}
                              />
                            </SalesLeadDash>
                          </Switch>
                        );
                      }}
                    />
                    <Route
                      path="*"
                    >
                      <Redirect to="/" />
                    </Route>
                  </Switch>)
              }
              else if (decoded?.user_group === "CustomerLead") {
                // setTokenReceive(token);
                return (
                  <Switch>
                    <Route
                      path="/dashboard"
                      exact
                      component={() => <Dash path={path} query={query} />}
                    />
                    <Route
                      path="/dashboard/cl"
                      exact={false}
                      component={() => {
                        return (
                          <Switch>
                            <CustomerLeadDash>
                              <Route
                                exact
                                path="/dashboard/cl/booking_table"
                                component={CustomerLeadFilterPage}
                              />
                              <Route
                                exact
                                path="/dashboard/cl"
                                component={CustomerLeadHome}
                              />
                              <Route
                                path="/dashboard/cl/booking"
                                exact
                                component={CustomerLeadBookingPage}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/calls"
                                component={CLCalls}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/whatsapp"
                                component={CustomerLeadWhatsapp}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/bookings/export"
                                component={CustommerLeadEditBookingExport}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/leads/export"
                                component={CustommerLeadExport}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/booking-view/:id"
                                component={CustommerLeadViewBooking}
                              />
                              <Route
                                path="/dashboard/cl/booking-additionalFamily/:id/add"
                                exact
                                component={CLAddAditionalFamily}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/booking-edit/:id/edit"
                                component={CustommerLeadEditBookingForm}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/generate-coupon"
                                component={CLCoupon}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/leads"
                                component={LeadsPage}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/fill-info"
                                component={CustomerLeadFillInfoPage}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/leads-transfer"
                                component={CLLeadTransfer}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/lead/search"
                                component={CLSearchResultsMain}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/uploader"
                                component={CustomUploader}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/booking/tickets"
                                component={CLComplaintsBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/booking/resampling"
                                component={BookingsResamplingTable}
                              />
                              <Route
                                path="/dashboard/cl/booking-imaging-edit/:id/edit"
                                exact
                                component={CLImagingEditBookingForm}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/dispositions"
                                component={CLDisposition}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/dispositions/leadexport"
                                component={CLDispositionExport}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/dispositions/bookingexport"
                                component={CLDispositionBookingExport}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/users"
                                component={CLManageUsers}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/lead/tickets"
                                component={CLComplaintsLeads}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/calls/stats"
                                component={CallStatsTable}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/call_recording"
                                component={CustomerLeadCallDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/lead/incorrectNumberLead"
                                component={IncorrectNumberLeads}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/breakData"
                                component={CLBreakTable}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/callDashboard"
                                component={CLCallDashBoard}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/coupons"
                                component={CLManageCoupons}
                              />
                              {/* <Route
                                      exact
                                      path="/dashboard/cl/lead/missedCall"
                                      component={CLMissedCall}
                                    /> */}
                              <Route
                                exact
                                path="/dashboard/cl/labDetails"
                                component={CLLabDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/callLog"
                                component={CLCallLogs}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/lead/smsDetails"
                                component={CLSMSDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/lead/emailDetails"
                                component={CLEMAILDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/verifiedbooking"
                                component={ClVerifiedBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/centerInfo"
                                component={ClCenterInfo}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/inbox"
                                component={CLInbox}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/performance"
                                component={
                                  CustommerLeadEmployeePerformance
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/cl/testPackages"
                                component={CustommerLeadTestPackages}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/AuditScore"
                                component={CustommerLeadAuditScore}
                              />
                              <Route
                                exact
                                path="/dashboard/cl/helpdesk"
                                component={CLHelpDesk}
                              />
                            </CustomerLeadDash>
                          </Switch>
                        );
                      }}
                    />
                    <Route
                      path="*"
                    >
                      <Redirect to="/" />
                    </Route>
                  </Switch>)
              }
              else if (decoded?.user_group === "ImagingAdmin") {
                // setTokenReceive(token);
                return (
                  <Switch>
                    <Route
                      path="/dashboard"
                      exact
                      component={() => <Dash path={path} query={query} />}
                    />
                    <Route
                      path="/dashboard/ia"
                      exact={false}
                      component={() => {
                        return (
                          <Switch>
                            <ImagingAdminDash>
                              <Route
                                exact
                                path="/dashboard/ia"
                                component={ImagingAdminCenterOnboarding}
                              />
                              <Route
                                exact
                                path="/dashboard/ia/sub-centre"
                                component={ImagingAdminSubCentre}
                              />
                              <Route
                                exact
                                path="/dashboard/ia/testmaster"
                                component={ImagingAdminTestMaster}
                              />
                              <Route
                                exact
                                path="/dashboard/ia/create-alias"
                                component={ImagingAdminPackageMaster}
                              />
                              <Route
                                exact
                                path="/dashboard/ia/modality"
                                component={imagingAdminMobalitytable}
                              />

                              <Route
                                exact
                                path="/dashboard/ia/center_deactivate"
                                component={imagingAdminCenterDeactivate}
                              />
                              <Route
                                exact
                                path="/dashboard/ia/subcenter_deactivate"
                                component={
                                  imagingAdminSubCenterDeactivate
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/ia/helpdesk"
                                component={imagingAdminHelpDesk}
                              />
                            </ImagingAdminDash>
                          </Switch>
                        );
                      }}
                    />
                    <Route
                      path="*"
                    >
                      <Redirect to="/" />
                    </Route>
                  </Switch>)
              }

              else if (decoded?.user_group === "CollectionCenterAdmin") {
                // setTokenReceive(token);
                return (
                  <Switch>
                    <Route
                      path="/dashboard"
                      exact
                      component={() => <Dash path={path} query={query} />}
                    />
                    <Route
                      path="/dashboard/cca"
                      exact={false}
                      component={() => {
                        return (
                          <Switch>
                            <CCADash>
                              <Route
                                path="/dashboard/cca"
                                exact
                                component={CCAFilterPage}
                              />
                              <Route
                                path="/dashboard/cca/booking"
                                exact
                                component={CCABookingPage}
                              />
                              <Route
                                path="/dashboard/cca/booking-view/:id"
                                exact
                                component={CCAViewBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/cca/booking-edit/:id/edit"
                                component={CCAEditHomeDxBookingForm}
                              />
                              <Route
                                path="/dashboard/cca/booking-imaging-edit/:id/edit"
                                exact
                                component={CCAEditBookingForm}
                              />
                              <Route
                                path="/dashboard/cca/package-list"
                                exact
                                component={CCAPackageList}
                              />
                              <Route
                                path="/dashboard/cca/users"
                                exact
                                component={CCASubAdminPage}
                              />
                              <Route
                                path="/dashboard/cca/ledger-details"
                                exact
                                component={CCACCClientLedgerDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/cca/booking/tickets"
                                component={CCAComplaintsBooking}
                              />
                              <Route
                                path="/dashboard/cca/booking/review"
                                exact
                                component={CCABookingReview}
                              />
                              <Route
                                exact
                                path="/dashboard/cca/helpdesk"
                                component={CCAHelpDesk}
                              />
                            </CCADash>
                          </Switch>
                        );
                      }}
                    />
                    <Route
                      path="*"
                    >
                      <Redirect to="/" />
                    </Route>
                  </Switch>)
              }
              else if (decoded?.user_group === "CollectionCenter") {
                // setTokenReceive(token);
                return (
                  <Switch>
                    <Route
                      path="/dashboard"
                      exact
                      component={() => <Dash path={path} query={query} />}
                    />
                    <Route
                      path="/dashboard/cc"
                      exact={false}
                      component={() => {
                        return (
                          <Switch>
                            <CCDash>
                              <Route
                                path="/dashboard/cc"
                                exact
                                component={CCFilterPage}
                              />
                              <Route
                                path="/dashboard/cc/booking"
                                exact
                                component={CCBookingPage}
                              />
                              <Route
                                path="/dashboard/cc/booking-view/:id"
                                exact
                                component={CCViewBooking}
                              />
                              <Route
                                path="/dashboard/cc/booking-imaging-edit/:id/edit"
                                exact
                                component={CCEditBookingForm}
                              />
                              <Route
                                path="/dashboard/cc/package-list"
                                exact
                                component={CCPackageList}
                              />
                              <Route
                                exact
                                path="/dashboard/cc/helpdesk"
                                component={CCHelpDesk}
                              />
                              <Route
                                path="/dashboard/cc/ledger-details"
                                exact
                                component={CCCCClientLedgerDetails}
                              />
                            </CCDash>
                          </Switch>
                        );
                      }}
                    />
                    <Route
                      path="*"
                    >
                      <Redirect to="/" />
                    </Route>
                  </Switch>)
              }
              else if (decoded?.user_group === "HealthAdviser") {
                // setTokenReceive(token);
                return (
                  <Switch>
                    <Route
                      path="/dashboard"
                      exact
                      component={() => <Dash path={path} query={query} />}
                    />
                    <Route
                      path="/dashboard/ha"
                      exact={false}
                      component={() => {
                        return (
                          <Switch>
                            <HealthAdviserAgentDash>
                              <Route
                                exact
                                path="/dashboard/ha"
                                component={HealthAdviserBookingsPage}
                              />
                              <Route
                                path="/dashboard/ha/booking-additionalFamily/:id/add"
                                exact
                                component={HAAddAditionalFamily}
                              />
                              <Route
                                exact
                                path="/dashboard/ha/booking"
                                component={HealthAdviserNewBookingsPage}
                              />
                              <Route
                                exact
                                path="/dashboard/ha/transfered-booking"
                                component={HealthAdvisorTransferBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/ha/booking-view/:id"
                                component={HealthAdviserViewBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/ha/health_data"
                                component={HealthAdvisorHealthData}
                              />
                              <Route
                                exact
                                path="/dashboard/ha/dispositions/leadexport"
                                component={HealthAdvisorDispositionExport}
                              />
                              <Route
                                exact
                                path="/dashboard/ha/booking-with-lead/:id"
                                component={HealthAdviserBookingWithLead}
                              />
                              <Route
                                exact
                                path="/dashboard/ha/booking-with-disposition/:id"
                                component={HealthAdvisorBookingWithDispo}
                              />
                              <Route
                                exact
                                path="/dashboard/ha/booking-edit/:id/edit"
                                component={HealthAdviserEditBooking}
                              />
                              <Route
                                path="/dashboard/ha/fill-info"
                                exact
                                component={HealthAdviserFillInfoPage}
                              />
                              <Route
                                exact
                                path="/dashboard/ha/centerInfo"
                                component={HACenterInfo}
                              />
                              <Route
                                exact
                                path="/dashboard/ha/prescription"
                                component={HAPrescriptionData}
                              />
                              <Route
                                exact
                                path="/dashboard/ha/helpdesk"
                                component={HAHelpDesk}
                              />
                              <Route
                                exact
                                path="/dashboard/ha/testPackages"
                                component={HealthAdviserTestPackages}
                              />
                            </HealthAdviserAgentDash>
                          </Switch>
                        );
                      }}
                    />
                    <Route
                      path="*"
                    >
                      <Redirect to="/" />
                    </Route>
                  </Switch>)
              }
              else if (decoded?.user_group === "HealthAdviserAdmin") {
                // setTokenReceive(token);
                return (
                  <Switch>
                    <Route
                      path="/dashboard"
                      exact
                      component={() => <Dash path={path} query={query} />}
                    />
                    <Route
                      path="/dashboard/haa"
                      exact={false}
                      component={() => {
                        return (
                          <Switch>
                            <HealthAdviserAdminAgentDash>
                              <Route
                                exact
                                path="/dashboard/haa"
                                component={HealthAdviserAdminBookingsPage}
                              />
                              <Route
                                exact
                                path="/dashboard/haa/transfered-booking"
                                component={
                                  HealthAdvisorAdminTransferBooking
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/haa/calls_recording"
                                component={HealthAdvisorAdmiCallDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/haa/leads"
                                component={HealthAdvisorAdmiLeadsPage}
                              />
                              <Route
                                exact
                                path="/dashboard/haa/helpdesk"
                                component={HealthAdvisorAdmiHelpDesk}
                              />
                              <Route
                                exact
                                path="/dashboard/haa/testPackages"
                                component={HealthAdvisorAdminTestPackages}
                              />
                              <Route
                                exact
                                path="/dashboard/haa/labDetails"
                                component={HealthAdvisorAdminLabDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/haa/users"
                                component={HealthAdvisorAdmiSubAdminPage}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/users/create"
                                component={HealthAdvisorAdmiCreateUser}
                              />
                              <Route
                                exact
                                path="/dashboard/sl/users/editUser"
                                component={HealthAdvisorAdmiEditUser}
                              />
                              <Route
                                path="/dashboard/haa/booking-additionalFamily/:id/add"
                                exact
                                component={
                                  HealthAdviserAdminAddAditionalFamily
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/haa/booking"
                                component={
                                  HealthAdviserAdminNewBookingsPage
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/haa/booking-view/:id"
                                component={HealthAdviserAdminViewBooking}
                              />
                              <Route
                                exact
                                path="/dashboard/haa/health_data"
                                component={HealthAdviserAdminHealthData}
                              />
                              <Route
                                exact
                                path="/dashboard/haa/dispositions/leadexport"
                                component={
                                  HealthAdviserAdminDispositionExport
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/haa/call_details"
                                component={HealthAdvisorAdminCallDetails}
                              />
                              <Route
                                exact
                                path="/dashboard/haa/calls/stats"
                                component={
                                  HealthAdvisorAdmiCallStatsTable
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/haa/booking/tickets"
                                component={
                                  HealthAdvisorAdminComplaintsBooking
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/haa/booking-with-lead/:id"
                                component={
                                  HealthAdviserAdminBookingWithLead
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/haa/breakData"
                                component={HealthAdvisorAdmiBreakTable}
                              />
                              <Route
                                exact
                                path="/dashboard/haa/booking-with-disposition/:id"
                                component={
                                  HealthAdviserAdminBookingWithDispo
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/haa/booking-edit/:id/edit"
                                component={HealthAdviserAdminEditBooking}
                              />
                              <Route
                                path="/dashboard/haa/fill-info"
                                exact
                                component={HealthAdviserAdminFillInfoPage}
                              />
                              <Route
                                path="/dashboard/haa/whatsapp"
                                exact
                                component={HealthAdvisorAdminWhatsapp}
                              />
                              <Route
                                exact
                                path="/dashboard/haa/coupons"
                                component={
                                  HealthAdvisorAdminManageCoupons
                                }
                              />
                              <Route
                                exact
                                path="/dashboard/haa/centerInfo"
                                component={HealthAdviserAdminCenterInfo}
                              />
                              <Route
                                exact
                                path="/dashboard/haa/prescription"
                                component={
                                  HealthAdviserAdminPrescriptionData
                                }
                              />
                              {/* <Route
                                      exact
                                      path="/dashboard/haa/testPackages"
                                      component={HealthAdviserAdminTestPackages}
                                    /> */}
                            </HealthAdviserAdminAgentDash>
                          </Switch>
                        );
                      }}
                    />

                    <Route
                      path="*"
                    >
                      <Redirect to="/" />
                    </Route>
                  </Switch>)
              }

              else {
                return <Redirect to="/" />;
              }
            }}
          />
        </Switch>
        {/* <Modal /> */}
        {/* <SecondModal /> */}
      </MainLayout>
    </BrowserRouter>
  );
}
const mapStateToProps = (state) => ({
  // locationDataFromPusher: state.loginReducer.locationDataFromPusher,
});

export default connect(mapStateToProps, { checkUser, messageBox, locationDataFromPusherAPI })(Routes);