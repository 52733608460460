import { ACTIONS } from "../interfaces/actionTypes/booking";
import AXIOS from "../../config/Axios";
import PrefixBeta from "../../config/ApiPrefixBeta";
import Prefix from "../../config/ApiPrefix";
import snackBarUpdate from "../../actions/snackBarActions";
import SecureStorage from "../../config/SecureStorage";
import headers from "../../helpers/headers";
import { AnyAction } from "redux";

export const changePassword = (body: any) => async (dispatch: Function) => {
  try {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    await AXIOS.post(
      `${Prefix.api}/auth/password_reset/reset_password/`,
      body,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({ type: ACTIONS.CHANGE_PASSWORD, payload: null });
    snackBarUpdate({
      payload: {
        message: "Password Updated",
        status: true,
        type: "success",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const clickToCallBooking =
  (id: number, contact_type: string) => async (dispatch: Function) => {
    try {
      await AXIOS.get(
        `${Prefix.api}/booking/clicktocall/${id}/${contact_type}/`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CLICK_TO_CALL, payload: null });
      snackBarUpdate({
        payload: {
          message: "Call Succeded",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err?.response?.data?.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getAllLeads = (query: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });

  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/lead/lead/${query}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_ALL_LEADS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getTopRCFreshLeads =
  (query: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/lead/lead/?${
          query === "49"
            ? "multiple_category=49&latest_size=10&latest=true"
            : "multiple_category=36,11&latest_size=10&latest=true"
        }`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_TOP_RC_FRESH_LEADS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getTopCfreshLeads =
  (query: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/lead/lead/?category=7&latest_size=10&latest=true`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_TOP_C_FRESH_LEADS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getRepeatedLead =
  (query: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/repeated_lead/`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_REPEATED_LEAD, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getLatestLeads = (query: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });

  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/lead/lead/${query}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_LATEST_LEAD, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const clickToCall =
  (id: number, contact_type: string, status: any) =>
  async (dispatch: Function) => {
    try {
      await AXIOS.get(
        `${Prefix.api}/lead/clicktocall/${id}/${contact_type}/${
          status ? status : ""
        }`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CLICK_TO_CALL, payload: null });
      snackBarUpdate({
        payload: {
          message: "Call Succeded",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getPinCode = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });

  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/phlebo/pincode/${url ? `?code=${url}` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PIN_CODE, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getCities =
  (url: string, newUrl: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/core/cities/${url ? `?q=${url}` : newUrl ? newUrl : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_CITIES, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getDisplayCities =
  (url: string, newUrl: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(`${Prefix.api}/core/displaycities/`, {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      });
      dispatch({ type: ACTIONS.GET_DISPLAY_CITIES, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getAreaName = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/core/city-area/${url ? `?id=${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_AREA, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getAgentList = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/panel/data/${url ? `?code=${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_AGENTS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getPackage = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
     `${PrefixBeta.api}/core/packages/crmdata${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PACKAGES, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getPackageTags = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/package/tags/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PACKAGES_TAGS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response.data.errors) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getPackageCategory = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/package/category/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PACKAGES_CATEGORY, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response.data.errors) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getMultiplePackage =
  (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/package/crmdata/${url ? `${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_MULTIPLE_PACKAGES, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getFamilyPackage = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/package/family-package/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_FAMILY_PACKAGES, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getFamilyPackageBooking =
  (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/family-package-booking/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_FAMILY_PACKAGES_BOOKING, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const postFamilyPackageBooking =
  (bookingData: any, id?: number) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/booking/family-package-booking/${id ? `?${id}` : ""}`,
        bookingData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_FAMILY_PACKAGES_BOOKING, payload: data });
      snackBarUpdate({
        payload: {
          message: "Booked",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const checkGeoFenceArea =
  (latitude: any, longitude: string, zone_id: string) =>
  async (dispatch: Function) => {
    dispatch({ type: ACTIONS.CHECK_GEOFENCE_AREA, payload: false });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/checking-geofence-area/?latitude=${latitude}&longitude=${longitude}&zone_id=${zone_id}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CHECK_GEOFENCE_AREA, payload: true });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      //dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const checkGeoFenchMapMyIndia =
  (eloc: string, pincode: string, cityname: string = 'NA') => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA, payload: [] });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/checking-geofence-area-mapmyindia/?eloc=${eloc}&pincode=${pincode}&cityname=${cityname}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      //dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getMapMyIndiaAccessToken = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN, payload: [] });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/panel/mapmyindia-auth-token/`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};

export const getPartners = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/partner/data/${url ? `?code=${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PARTNERS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getCoupons = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/coupon/data/${url ? `${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_COUPONS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const createNewBooking =
  (bookingData: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/booking/data/${id ? `?${id}` : ""}`,
        bookingData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_BOOKING, payload: data });
      snackBarUpdate({
        payload: {
          message: "Booked",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        if (err.response?.data?.error) {
          title = err.response.data?.error;
        } else {
          title = err.response.data?.errors;
        }
      } else {
        title = "Something went wrong!";
      }

      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const createImagingBooking =
  (bookingData: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/booking/imaging/${id ? `?${id}/` : ""}`,
        bookingData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_IMAGING_BOOKING, payload: data });
      snackBarUpdate({
        payload: {
          message: "Booking Create Successfully",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const createBookWithMapBound =
  (bookingData: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/booking/data/v1/${id ? `?${id}` : ""}`,
        bookingData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_BOOKING, payload: data });
      snackBarUpdate({
        payload: {
          message: "Booked",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (
        (err.response.data?.errors[0] &&
          err.response.data?.errors[0].startsWith(
            "A booking is already done with this number  by"
          )) ||
        err.response.data.errors[0].startsWith(
          "A booking is already done with this number by"
        )
      ) {
        dispatch({ type: ACTIONS.SAME_NUMBER_BOOKING, payload: true });
        title = err.response.data.errors[0];
      } else if (
        (err.response.data?.errors[0] &&
          err.response.data?.errors[0].startsWith(
            "A booking is already done with this number  by"
          ) !== true) ||
        err.response.data.errors[0].startsWith(
          "A booking is already done with this number by"
        ) !== true
      ) {
        title = err.response.data.errors[0];
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const createAdditionalBooking =
  (id: any, bookingData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/booking/additional_booking/${id}/`,
        bookingData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_ADDITIONAL_BOOKING, payload: data });
      snackBarUpdate({
        payload: {
          message: "Family member added",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const updateAdditionalBooking =
  (id: any, bookingData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/booking/ret_up_additional_booking/${id}/`,
        bookingData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_ADDITIONAL_BOOKING, payload: data });
      snackBarUpdate({
        payload: {
          message: "Family member updated",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getAllBookings = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/data/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_BOOKING_LIST, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err?.response?.data?.detail;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type:
          err?.response?.data?.detail ===
          "An Email has been sent to your registered mail address"
            ? "success"
            : "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_BOOKING_LIST, payload: [] });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getBookings = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/data/${url ? `search?id=${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_BOOKING_LIST, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_BOOKING_LIST, payload: [] });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getBookingById = (id: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_BOOKING_ID_LOADER, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/data/${id ? `${id}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_BOOKING_BY_ID, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
  dispatch({ type: ACTIONS.SET_BOOKING_ID_LOADER, payload: false });
};

// export const updateBooking = (bookingData: any, id: any) => async (dispatch: Function) => {
//     dispatch({ type: ACTIONS.SET_LOADING, payload: true });
//     try {
//         const { data } = await AXIOS.put(
//             `${Prefix.api}/booking/data/${id}/`,
//             bookingData,
//             { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
//         );
//         dispatch({ type: ACTIONS.CREATE_BOOKING, payload: data });
//         snackBarUpdate({
//             payload: {
//                 message: "Booking Updated",
//                 status: true,
//                 type: "success",
//             },
//         })(dispatch);
//     } catch (err:any) {
//           let title = "";

//         if (err.response) {
//             title = err.response.data.errors;
//         } else {
//             title = "Something went wrong!";
//         }
//         snackBarUpdate({
//             payload: {
//                 message: title || "Something went wrong",
//                 status: true,
//                 type: "error",
//             },
//         })(dispatch);
//         dispatch({ type: ACTIONS.SET_LOADING, payload: false });
//         throw err;
//     }
// };
export const updateBooking =
  (bookingData: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/booking/data/${id}/`,
        bookingData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_BOOKING, payload: data });
      snackBarUpdate({
        payload: {
          message: "Booking Updated",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data.errors[0] && err.response.data.errors[0] !== "") {
        title = err.response.data.errors[0];
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getLeadDetails =
  (id: string, shownumber: string = "false") =>
  async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      if (shownumber === "true") {
        var { data } = await AXIOS.get(
          `${Prefix.api}/lead/lead-update/${id}/`,
          {
            headers: {
              Authorization: `Token ${SecureStorage.getItem("token")}`,
            },
          }
        );
      } else {
        var { data } = await AXIOS.get(
          `${Prefix.api}/lead/lead-update/${id}/`,
          {
            headers: {
              Authorization: `Token ${SecureStorage.getItem("token")}`,
            },
          }
        );
      }
      dispatch({ type: ACTIONS.GET_LEAD_DETAILS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong!",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const updateLeadDetailsF_2 =
  (id: string, body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    const { followup_date, followup_time, language } = body;
    try {
      const { data } = await AXIOS.patch(
        `${Prefix.api}/lead/lead-update/${id}/`,
        { followup_date, followup_time, language },
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_LEAD_DETAILS, payload: data });
      snackBarUpdate({
        payload: {
          message: "Success",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong!",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const updateLeadDetailsF_1 =
  (id: string, body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    const {
      patient_name,
      patient_age,
      calling_gender,
      priority,
      content_number,
      whatsapp_contact,
      address,
      patient_city,
      patient_area,
    } = body;
    try {
      const { data } = await AXIOS.patch(
        `${Prefix.api}/lead/lead-update/${id}/`,
        {
          patient_name,
          patient_age,
          calling_gender,
          priority,
          content_number,
          whatsapp_contact,
          address,
          patient_city,
          patient_area,
        },
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_LEAD_DETAILS, payload: data });
      snackBarUpdate({
        payload: {
          message: "Success",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong!",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getLeads = (body: any, url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/panel/search/${url ? `?${url}` : ""}`,
      body,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_LEADS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const resetLeadDetails = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.LEAD_DETAILS_RESET });
};

export const resetBookings = () => async (dispatch: Function) => {
  dispatch({
    type: ACTIONS.BOOKING_RESET,
  });
};

export const getLeadById = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/lead/dashboard/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.GET_LEAD_BY_ID, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getLtvLead = (id: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/lead/ltv/${id}`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.GET_LTV_LEAD, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = String(err.response.data.detail);
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getPositiveCallsComments =
  (id: number) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/lead/lead-related-comments/${id}/`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_POSTIVE_CALLS_COMMENTS,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const addPositiveCallComment =
  (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    const { comment, callId } = body;
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/lead/lead-details/${callId}/`,
        {
          comment,
        },
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      snackBarUpdate({
        payload: {
          message: "Created!",
          status: true,
          type: "success",
        },
      })(dispatch);

      dispatch({
        type: ACTIONS.ADD_POSTIVE_CALLS_COMMENT,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const setCFreshComments = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  const {
    category,
    sub_category,
    lead_comment,
    comment,
    pincode,
    test,
    city,
    status,
    query,
    followup_date,
    followup_time,
    center,
    cityname,
    price,
    number,
    language,
    pannelName,
  } = body;
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/lead/status-allot/`,
      {
        category,
        sub_category,
        lead_comment,
        comment,
        pincode,
        test,
        city,
        status,
        query,
        followup_date,
        followup_time,
        center,
        cityname,
        price,
        number,
        language,
        pannelName,
      },
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.SET_COMMENTS, payload: data });
    snackBarUpdate({
      payload: {
        message: "Success!",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err?.response?.data?.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const addComment = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  const { comment, callId } = body;
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/lead/lead-details/${callId}/`,
      {
        comment,
      },
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    snackBarUpdate({
      payload: {
        message: "Created!",
        status: true,
        type: "success",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.ADD_COMMENT, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const createCall = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  const { name, phone_no, source } = body;

  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/lead/generate-lead-by-user/`,
      {
        name,
        phone_no,
        source,
      },
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.CREATE_CALL, payload: data.lead });
    snackBarUpdate({
      payload: {
        message: "Success!",
        status: true,
        type: "success",
      },
    })(dispatch);
    // window.location.assign("/agent/dashboard/patient/create-appointment");
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getLeadSource = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });

  try {
    const { data } = await AXIOS.get(`${Prefix.api}/lead/lead-source/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.GET_LEAD_SOURCE, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getAllRecordings = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/communication/call_status/${url ? url : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_RECORDINGS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data?.errors) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getBookingsComments =
  (id: number) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/lead/booking-related-comments/${id}/`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_BOOKINGS_COMMENTS,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const addBookingsComment = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  const { comment, bookingId } = body;
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/lead/booking-details/${bookingId}/`,
      {
        comment,
      },
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    snackBarUpdate({
      payload: {
        message: "Created!",
        status: true,
        type: "success",
      },
    })(dispatch);

    dispatch({
      type: ACTIONS.ADD_BOOKINGS_COMMENT,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getWhatsappComments = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING_WHATSAPP, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/communication/whatsapp_message_comment/${
        url ? `?${url}` : ""
      }`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_WHATSAPP_COMMENTS,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING_WHATSAPP, payload: false });
    throw err;
  }
};

export const getWhatsappCommentsByLead =
  (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/communication/whatsapp_message_comment/?${url}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_WHATSAPP_COMMENTS_BY_LEAD,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const sendWhatsappComments =
  (leadId: any, message: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/communication/send_whatsapp_message/${leadId}/`,
        { message },
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.SEND_WHATSAPP_COMMENTS,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Message Created",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.detail;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getCallStats = () => async (dispatch: Function) => {
  // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/analytics/panaluser_calls/`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_CALL_STATS,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getUserStats = () => async (dispatch: Function) => {
  // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/analytics/daily-activity/`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_USER_STATS,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const raiseNewComplaint =
  (type: any, body: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/booking/${type}/create/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.RAISE_COMPLAINT,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Ticket Raised",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getQueryTickets =
  (type: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/${type}/data/${id ? `?${id}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_COMPLAINTS,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const updateQueryTicket =
  (type: any, body: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/booking/${type}/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.RAISE_COMPLAINT,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Ticket Updated",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data.errors[0] && err.response.data.errors[0] !== "") {
        title = err.response.data.errors[0];
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const complaintReset = () => async (dispatch: Function) => {
  dispatch({
    type: ACTIONS.RAISE_COMPLAINT,
    payload: {},
  });
};

export const assignDoctor = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/booking/doctor_cunsultation/`,
      body,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.ASSIGN_DOCTOR,
      payload: data,
    });
    snackBarUpdate({
      payload: {
        message: "Doctor Assigned",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getDoctorBookings = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/doctor_cunsultation/${url ? `?${url}` : ""}`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_BOOKING_LIST,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getDoctorBookingById = (id: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/doctor_cunsultation/${id}/`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_BOOKING_BY_ID,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const sendNewFeedback =
  (id: any, body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/booking/doctor_cunsultation/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.SEND_FEEDBACK,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Feedback Sent",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getAvailableSlots =
  (collection_date: any, customer_zone: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/booking_slot_collection_date/?collection_date=${
          collection_date ? collection_date : ""
        }&customer_zone=${customer_zone ? customer_zone : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_AVAILABLE_SLOTS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const searchCall = (body: any) => async (dispatch: Function) => {
  const { search_by, value } = body;
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/panel/search/?self=true`,
      {
        search_by,
        value,
      },
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.SEARCH_CALL, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getPhlebos = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/phlebo/data/${url ? `?code=${url}` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PHLEBOS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getBreakResponse = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/panel/data/${url ? `?${url}` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_BREAK_RESPONSE, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getPanelBreak = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/panel/panel_break/${url ? `${url}/` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PANEL_BREAK, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getPaymentSendLink = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/payment/send_link/${url ? `${url}/` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PAYMENT_SEND_LINK, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.detail;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getPaymentReSendLink =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/payment/resend_link/${url ? `${url}/` : ""}`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_PAYMENT_RESEND_LINK, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getPaymentCancelLink =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/payment/cancel_link/${url ? `${url}/` : ""}`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_PAYMENT_CANCEL_LINK, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getLabDetails = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/panel/panel_in_lab/${url ? `${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_LAB_DETAILS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getSmsDetails = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/notification/booking_sms/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_SMS_DETAILS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_BOOKING_LIST, payload: [] });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getEmailDetails = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/notification/email_sms/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_EMAIL_DETAILS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_BOOKING_LIST, payload: [] });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getSmsReportSend = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/communication/mail_report_sms/${url ? `${url}/` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_SMS_REPORT_SEND, payload: data });
  } catch (err: any) {
    let title = "";

    if (err.response) {
      title = err.response.data.detail;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getCenterInfo = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/center/data/${url ? url : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_CENTER_INFORMATION, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_CENTER_INFORMATION, payload: [] });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getSubCenterInfo = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/center/center-address/${url ? url : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_SUB_CENTER_INFORMATION, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } if (err.response.data?.errors) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_CENTER_INFORMATION, payload: [] });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getSMSLink = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/notification/report_feedback/${url ? `${url}/` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_SMS_LINK, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getNotificationMessage =
  (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/notification/send/${url ? url : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_NOTIFICATION_MESSAGE, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const sendNotification = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/notification/send/`,
      body,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.SEND_NOTIFICATION, payload: data });
    snackBarUpdate({
      payload: {
        message: "token received",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getReportDownload = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/report_status/${url ? `${url}` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_REPORT_DOWNLOAD, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getReportStatus = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/status/${url ? `${url}` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_REPORT_STATUS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getUnmaskedNumber =
  (id: any, source: any, type: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/panel/unmasked/${id ? `${id}` : ""}/${
          source ? `${source}` : ""
        }/${type ? `${type}` : ""}/`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_UNMASKED_NUMBER, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getUserDetails = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(`${Prefix.api3}/auth/shortprofile/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.GET_USER_DETAILS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_USER_DETAILS, payload: [] });
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getFirebaseNotification =
  (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/notification/token_save/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_FIREBASE_NOTIFICATION, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong!",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getZone = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/phlebo/zone/data/${url ? `?city_name=${url}` : ""}`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_ZONE,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getUserData = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/auth/data/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.GET_USER_DATA, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getNotificationType =
  (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(`${Prefix.api}/notification/type/`, {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      });
      dispatch({ type: ACTIONS.GET_NOTIFICATION_TYPE, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getTodaysMissedCalls =
  (url: string) => async (dispatch: Function) => {
    // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/analytics/panaluser_missed_calls_count/${
          url ? `${url}` : ""
        }`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_TODAYS_MISSED_CALLS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getRepeatedList = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/lead/repeated_lead/${url ? `?${url}` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_REPEATED_LEADS, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const updateRepeatedList =
  (id: any, leadData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/lead/repeated_lead/${id}/`,
        leadData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.UPDATED_REPEATED_LIST, payload: data });
      snackBarUpdate({
        payload: {
          message: "LEAD UPDATED SUCCESSFULLY",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getTimeslots = () => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/phlebo/timeslots/`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({
      type: ACTIONS.GET_TIMESLOTS,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getImagingTimeslots = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/phlebo/imaging-package-slots/${url ? url : ""}`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_IMAGING_TIMESLOTS,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.detail;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getRedCoing = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  dispatch({ type: ACTIONS.GET_RED_COIN, payload: [] });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/coupon/available-coins/?phonenumber=${url}`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_RED_COIN,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getLeadStatus = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(`${Prefix.api}/lead/lead-status/${url ? url : ""}`, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({
      type: ACTIONS.GET_LEAD_STATUS,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getPrescriptionData =
  (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/prescription_upload/${url ? url : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_PRESCRIPTION_DATA, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getDoctorInfoById = (id: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/center/ref_doctor/?center_address=${id}`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_DOCTOR_INFO_BY_ID,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const imaginBookingDisposition =
  (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/lead/imaging-disposition/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.POST_IMAGING_BOOKING_DISPOSITION,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Booking Cancel Successfully",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getImagingTicket = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });

  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/imaging-ticket/${url ? url : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_IMAGING_TICKET, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const sendInitialMessage = (id: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });

  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/communication/send_imaging_initial_message/${id}/`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.SEND_INITIAL_WHATSAPP_MESSAGE, payload: data });
    snackBarUpdate({
      payload: {
        message: "Initial Message Send Successfully",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getImagingRaiseTicket =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/imaging-ticket/?raised=false${url ? url : ""}`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_IMAGING_RAISE_TICKET, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getImagingTicketCount =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/imaging-ticket-count/`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_IMAGING_TICKET_COUNT, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const updateImagingTicketStataus =
  (id: any, ticketStatus: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/booking/imaging-ticket/${id}/`,
        ticketStatus,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.UPDATE_IMAGING_TICKET, payload: data });
      snackBarUpdate({
        payload: {
          message: "Ticket Updated Successfully",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const createImagingBookingCustom =
  (bookingData: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/booking/imaging-custom/${id ? `?${id}/` : ""}`,
        bookingData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_IMAGING_BOOKING_CUSTOM, payload: data });
      snackBarUpdate({
        payload: {
          message: "Booking Create Successfully",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getTicketData = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });

  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/redtech/tasks/${url ? `${url}` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_TICKET_DATA, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getTicketCategories = (url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });

  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/redtech/categories/${url ? `${url}/` : ""}`,

      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_TICKET_CATEGORIES, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getTicketSubCategories =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/redtech/sub-categories/${url ? `${url}` : ""}`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_TICEKT_SUB_CATEGORIES, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const CreateTaskTicket = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/redtech/tasks/`, body, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.CREATE_TASK_TICKET, payload: data });
    snackBarUpdate({
      payload: {
        message: "Task Created Successfully",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getRedTechComment = (id: number) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/redtech/comments/?ticket=${id}&ordering=-created_at`,

      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_RED_TECH_COMMENT,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err?.response?.data?.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const postRedTechComment = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/redtech/comments/`, body, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({
      type: ACTIONS.POST_RED_TECH_COMMENT,
      payload: data,
    });
    snackBarUpdate({
      payload: {
        message: "Message Created",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.message;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getHoldCancelBooking =
  (query: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/booking-data-pickup-set/`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_HOLD_CANCEL_BOOKING, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getQualityScoringReport =
  (filter: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/communication/quality-scoring-report/qef/${
          filter ? `${filter}` : ""
        }`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_QUALITY_SCORING_REPORT, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getQualityScoringReportForBackUp =
  (filter: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/communication/quality-scoring-report/qef/${
          filter ? `${filter}` : ""
        }`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_QUALITY_SCORING_REPORT_FOR_BACKUP,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const getceCentre =(url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/center/ce_center/${ url ? `${url}` : "" }`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_CE_CENTER_FOR_CC,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const checkGeoFenchMapMyIndiaPilot =
  (eloc: string, pincode: string, cityname: string = 'NA') => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA, payload: [] });
    dispatch({ type: ACTIONS.SET_GEO_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/checking-geofence-area-mapmyindia_2/?eloc=${eloc}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
        dispatch({ type: ACTIONS.SET_GEO_CHECK_ERROR, payload:err.response.data.errors });
      } else {
        title = "Something went wrong!";
        dispatch({ type: ACTIONS.SET_GEO_CHECK_ERROR, payload:""});
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_GEO_LOADING, payload: false });
      throw err;
    }
  };
  export const createPilotNewBooking =
  (bookingData: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/booking/data_v2/${id ? `?${id}` : ""}`,
        bookingData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_PILOT_BOOKING, payload: data });
      snackBarUpdate({
        payload: {
          message: "Booked",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const getBookingQueryTickets = (id: any) => async (
    dispatch: Function
  ) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
  
        `${Prefix.api}/redtech/csticket/${id ? `${id}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_BOOKING_COMPLAINTS,
        payload: data,
      });
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.detail) {
        title = JSON.stringify(err?.response?.data?.detail);
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const updateActionDisposition =
  (type: any, body: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/booking/booking_ticket/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.UPDATE_DISPOSITION,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Ticket Updated",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const updateTicketStatus =
  (body: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.put(
        `${Prefix.api}/redtech/csticket/${id}/`,
        body,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.UPDATE_TICKET_STATUS,
        payload: data,
      });
      snackBarUpdate({
        payload: {
          message: "Ticket Updated Successfully",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const getTicketComments = (id: number) => async (
    dispatch: Function
  ) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/redtech/csticket/comments/${id}`,
  
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_TICKET_COMMENTS,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const checkGeoFenchMapMyIndiaPilotForCustomerAddress =
  (eloc: string, pincode: string, cityname: string = 'NA') => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA_FOR_CUSTOMER_ADDRESS, payload: [] });
    dispatch({ type: ACTIONS.SET_GEO_LOADING_FOR_CUSTOMER, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/checking-geofence-area-mapmyindia_2/?eloc=${eloc}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA_FOR_CUSTOMER_ADDRESS,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = err.response.data.errors;
        dispatch({ type: ACTIONS.SET_GEO_ERROR_FOR_CUSTOMER, payload: err.response.data.errors });
      } else {
        title = "Something went wrong!";
        dispatch({ type: ACTIONS.SET_GEO_ERROR_FOR_CUSTOMER, payload: "" });
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      //dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      dispatch({ type: ACTIONS.SET_GEO_LOADING_FOR_CUSTOMER, payload: false });
      throw err;
    }
  };

  export const getWhatsappTemplate =(url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/communication/message-template/?message_type=WHATSAPP`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_WHATSAPP_TEMPLATE,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const createWhatsappTemplate =
  (templateData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/communication/send_whatsapp_temp/?${templateData}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_WHATSAPP_TEMPLATE, payload: data });
      snackBarUpdate({
        payload: {
          message: "Message Sent Successfully.",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  // export const createWhatsappTemplate =
  // (templateData: any, body: any) => async (dispatch: Function) => {
  //   dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  //   try {
  //     const { data } = await AXIOS.post(
  //       `${Prefix.api}/communication/send_whatsapp_temp/?${templateData}`,
  //       templateData,
  //       {
  //         headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
  //       }
  //     );
  //     dispatch({ type: ACTIONS.CREATE_WHATSAPP_TEMPLATE, payload: data });
  //     snackBarUpdate({
  //       payload: {
  //         message: "Template Created Successfully",
  //         status: true,
  //         type: "success",
  //       },
  //     })(dispatch);
  //   } catch (err: any) {
  //     let title = "";
  //     if (err.response) {
  //       if (err.response?.data?.error) {
  //         title = err.response.data?.error;
  //       } else {
  //         title = err.response.data?.errors;
  //       }
  //     } else {
  //       title = "Something went wrong!";
  //     }

  //     snackBarUpdate({
  //       payload: {
  //         message: title || "Something went wrong",
  //         status: true,
  //         type: "error",
  //       },
  //     })(dispatch);
  //     dispatch({ type: ACTIONS.SET_LOADING, payload: false });
  //     throw err;
  //   }
  // };

  export const getBiomarkerScreening =
  (id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_BIOMARKER_LOADER, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/biomarker_screening/${id ? `?${id}` : ""}`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_BIOMARKER_SCREENING, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.detail;
      } else {
        title = "Not found";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
    dispatch({ type: ACTIONS.SET_BIOMARKER_LOADER, payload: false });
  };
 export const resetBiomarker = () => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.BIOMARKER_RESET });
  };
export const getBiomarkerUltasound =
  (id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}booking/ultasound_details/${
          id ? `?booking_id=${id}` : ""
        }`,

        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_BIOMARKER_ULTASOUND, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.detail;
      } else {
        title = "Not found";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getAdditionalMember = (id: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/booking/additional_booking/${id}/`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({ type: ACTIONS.GET_ADDITIONAL_MEMBER, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getNewPackage = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${PrefixBeta.api}/core/packages${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_PACKAGES, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.errors;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getAllNewBookings = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${PrefixBeta.api}/core/bookings/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_BOOKING_LIST, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = err.response.data.detail;
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong!",
        status: true,
        type:
          err.response.data.detail ===
            "An Email has been sent to your registered mail address"
            ? "success"
            : "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.GET_BOOKING_LIST, payload: [] });
    dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: false });
    throw err;
  }
};
export const getCommunicationOnWhatsapp =(url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/communication/on-whatsapp/${ url ? `?${url}` : "" }`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_SEND_LOCATION_REQUEST,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const SendLocationRequest = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/communication/send-location-request/`, body, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.SEND_LOCATION_REQUEST, payload: data });
    snackBarUpdate({
      payload: {
        message: "Message Sent Successfully",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getCrmLeadAddress =(url: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/auth/address-crm/${ url ? `?${url}` : "" }`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_CRM_LEAD_ADDRESS,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = JSON?.stringify(err?.response?.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const postCrmLeadAddress = (body: any,lead_id:any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LEAD_ADDRESS_LOADER, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/auth/address-crm/?lead_id=${lead_id}`,
     body, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.POST_CRM_LEAD_ADDRESS, payload: data });
    // snackBarUpdate({
    //   payload: {
    //     message: "",
    //     status: true,
    //     type: "success",
    //   },
    // })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response.data?.errors) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
  dispatch({ type: ACTIONS.SET_LEAD_ADDRESS_LOADER, payload: false });
};
export const verifyLocation = (id: any) => async (dispatch: Function) => {
  // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.put(
      `${Prefix.api}/booking/verify-location/${id}`,
      {},
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.LOCATION_VERIFIED,
      payload: data,
    });
    snackBarUpdate({
      payload: {
        message: "Location Verified Successfully",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data?.errors) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data?.msg) {
      title = JSON.stringify(err.response.data?.msg).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
  // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
};

export const postCrmLeadAddressModal = (body: any,lead_id:any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(`${Prefix.api}/auth/address-crm/?lead_id=${lead_id}`,
     body, {
      headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
    });
    dispatch({ type: ACTIONS.POST_CRM_LEAD_ADDRESS, payload: data });
    // snackBarUpdate({
    //   payload: {
    //     message: "",
    //     status: true,
    //     type: "success",
    //   },
    // })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    // snackBarUpdate({
    //   payload: {
    //     message: title || "Something went wrong",
    //     status: true,
    //     type: "error",
    //   },
    // })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};

export const getAddressDetailsLatLong = (lat: any, long: any, lead: any, bookingId: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG, payload: [] });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/auth/get-address-details-lat-long/?latitude=${lat}&longitude=${long}&lead_id=${lead}&booking_id=${bookingId}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data?.errors) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    throw err;
  }
};

export const getWhatsappMessageStatus =(addressId: any) => async (dispatch: Function) => {
  // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/auth/address/${ addressId ? `${addressId}/` : "" }`,
      {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      }
    );
    dispatch({
      type: ACTIONS.GET_WHATSAPP_MESSAGE_STATUS,
      payload: data,
    });
  } catch (err: any) {
    let title = "";
    if (err.response.data?.detail) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err.response.data?.errors) {
      title = JSON.stringify(err.response.data?.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const getLeadDispostion = (url: string) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_ALL_BOOKING_LOADING, payload: true });
  try {
    const { data } = await AXIOS.get(
      `${Prefix.api}/lead/status-category/${url ? `?${url}` : ""}`,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.GET_LEAD_DISPOSITION_LIST, payload: data });
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = JSON?.stringify(err?.response?.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const postDispositionData = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  const {
    category,
    sub_category,
    lead_comment,
    comment,
    pincode,
    test,
    city,
    status,
    query,
    followup_date,
    followup_time,
    center,
    cityname,
    price,
    number,
    language,
    pannelName,
  } = body;
  try {
    const { data } = await AXIOS.post(
      `${Prefix.apiv2}/lead/v2/lead-status-update/`,
      {
        category,
        sub_category,
        lead_comment,
        comment,
        pincode,
        test,
        city,
        status,
        query,
        followup_date,
        followup_time,
        center,
        cityname,
        price,
        number,
        language,
        pannelName,
      },
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.POST_DISPOSITION_DATA, payload: data });
    snackBarUpdate({
      payload: {
        message: "Success!",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err?.response?.data?.errors) {
      title = JSON.stringify(err.response.data.errors).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else if (err?.response?.data?.detail) {
      title = JSON.stringify(err.response.data.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};
export const initiateRefund = (body: any) => async (dispatch: Function) => {
  dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  try {
    const { data } = await AXIOS.post(
      `${Prefix.api}/payment/refund-payment/`,
      body,
      { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
    );
    dispatch({ type: ACTIONS.INITIATE_REFUND, payload: data });
    snackBarUpdate({
      payload: {
        message: "Refund Initiate successfully!",
        status: true,
        type: "success",
      },
    })(dispatch);
  } catch (err: any) {
    let title = "";
    if (err.response) {
      title = JSON.stringify(err.response.data?.detail).replace(
        /"|{|}|[|]/g,
        ""
      );
    } else {
      title = "Something went wrong!";
    }
    snackBarUpdate({
      payload: {
        message: title || "Something went wrong",
        status: true,
        type: "error",
      },
    })(dispatch);
    dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    throw err;
  }
};