import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import MessageIcon from "@material-ui/icons/Message";
import CallIcon from "@material-ui/icons/Call";
import {
  clickToCall,
  getLeadSource,
  getTopRCFreshLeads,
  getCities,
  getPrescriptionData,
  getImagingTicket,
  sendInitialMessage,
  getAllLeads,
} from "../actions/bookingActions";
import { TableFooter, TablePagination } from "@material-ui/core";
import Loader from "../components/Loader2";
import CommentsModal from "../components/Comments/comment";
import CommentsModal2 from "../components/Comments/Comment2";
import TicketModal from "../components/Comments/TicketStatusModal";
import Button from '@mui/material/Button';
import { generateLeadsUrl } from "../../helpers/generateUrl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RaiseComplaintModal from "../components/Comments/raiseTicketModal";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { CALL_DISABLE_TIME } from "../../CallTimeDisable";
import { genrateImagingTicektFilter } from "../../helpers/generateUrl";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import ReplyModal from "../components/Comments/replyModal";

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  tableContainer: {
    marginTop: "1rem",
    position: "sticky",
    top: "200px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

interface Props {
  getTopRCFreshLeads: Function;
  topRcFreshLeads: any;
  lead_source: any;
  loading: boolean;
  sendInitialMessage: any;
  getAllLeads: any;
  // resendSms: Function;
  clickToCall: Function;
  getLeadSource: any;
  getCities: any;
  cities: any;
  getPrescriptionData: any;
  prescription: any;
  getImagingTicket: any;
  imagingTicket: any;
}

const LeadsTable: React.FC<Props> = ({
  getTopRCFreshLeads,
  topRcFreshLeads,
  clickToCall,
  sendInitialMessage,
  getLeadSource,
  getAllLeads,
  lead_source,
  getCities,
  cities,
  loading,
  getPrescriptionData,
  prescription,
  getImagingTicket,
  imagingTicket,
}) => {
  const classes = useStyles();
  const [open2, setOpen2] = useState<boolean>(false);
  const [statusModalOpen, setStatusModalOpen] = useState<boolean>(false);
  const [ticketId, setTicketId] = useState<any>("");
  const [open, setOpen] = useState<boolean>(false);
  const [raiseComplaint, setRaiseComplaint] = useState(false);

  const [leadId, setLeadId] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    50 || topRcFreshLeads.results.count
  );
  const [prscriptionLead, setPrescriptionLead] = useState<any>("");
  const [pLink, setPlink] = useState<any>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openPrescription = Boolean(anchorEl);
  const [fileType, setFileType] = useState<any>("");
  const [openPdf, setOpenPdf] = useState<boolean>(false);
  const history = useHistory();
  const [callButton, setCallButton] = useState<boolean>(true);
  const [status, setStatus] = useState<any>([]);
  const [openWhatsapp, setOpenWhatsapp] = useState<boolean>(false);
  const [id, setId] = useState<string>("");

  const CATEGORY: any = [
    {
      id: 1,
      text: "Interested/Follow up",
      city: true,
      leadId: false,
    },
    {
      id: 2,
      text: "Not Interested",
      city: true,
      query: false,
    },
    {
      id: 3,
      text: "Order Booked",
      city: true,
      query: false,
    },
    {
      id: 4,
      text: "Ringing",
      city: false,
      query: false,
    },
    {
      id: 5,
      text: "Number Doesn't Exist",
      city: false,
      query: false,
    },
    {
      id: 6,
      text: "By Mistake & Wrong Number",
      city: false,
      query: false,
    },
    {
      id: 7,
      text: "Call Back",
      city: true,
      query: false,
    },
    {
      id: 8,
      text: "Business Realated Call",
      city: true,
      query: false,
    },
    {
      id: 9,
      text: "Spam Call",
      city: false,
      query: false,
    },
    {
      id: 10,
      text: "Enquiry Call",
      city: true,
      query: false,
    },
    {
      id: 11,
      text: "CFresh",
      city: false,
      query: false,
    },
    {
      id: 12,
      text: "Test Not Available",
      city: true,
      query: false,
    },
    {
      id: 13,
      text: "Subscribed to Competitor",
      city: true,
      query: false,
    },
    {
      id: 14,
      text: "Slot Issue",
      city: true,
      query: false,
    },
    {
      id: 15,
      text: "Same Day Slot",
      city: true,
      query: false,
    },
    {
      id: 16,
      text: "Already Booked",
      city: false,
      query: false,
    },
    {
      id: 17,
      text: "Not Contactable",
      city: false,
      query: false,
    },
    {
      id: 18,
      text: "Out of Station",
      city: true,
      query: false,
    },
    {
      id: 19,
      text: "Out of Service Area",
      city: true,
      query: false,
    },
    {
      id: 20,
      text: "Need Time to Think",
      city: true,
      query: false,
    },
    {
      id: 21,
      text: "Email/Whatsapp Package",
      city: true,
      leadId: false,
    },
    {
      id: 22,
      text: "Discount/Price Enquiry",
      city: true,
      leadId: false,
    },
    {
      id: 23,
      text: "CS issue",
      city: false,
      leadId: true,
    },
    {
      id: 24,
      text: "Call Not Reachable",
      city: false,
      leadId: false,
    },
    {
      id: 25,
      text: "Call Later",
      city: true,
      leadId: false,
    },
    {
      id: 26,
      text: "Call drop",
      city: true,
      leadId: false,
    },
    {
      id: 27,
      text: "Call Disconnected",
      city: true,
      leadId: false,
    },
    {
      id: 28,
      text: "Below <5 year patient",
      city: true,
      leadId: false,
    },
    {
      id: 29,
      text: "DND",
      city: false,
      leadId: false,
    },
    {
      id: 30,
      text: "Phlebo's Issue",
      city: false,
      leadId: true,
    },
    {
      id: 31,
      text: "Reports Regarding Issue",
      city: false,
      leadId: true,
    },
    {
      id: 32,
      text: "Health Advice",
      city: false,
      leadId: true,
    },
    {
      id: 33,
      text: "Health Consultation",
      city: false,
      leadId: true,
    },
    {
      id: 34,
      text: "Health Consultation",
      city: false,
      leadId: false,
    },
    {
      id: 35,
      text: "Language Barrier",
      city: false,
      leadId: false,
    },
    {
      id: 36,
      text: "RCFresh",
      city: false,
      leadId: false,
    },
    {
      id: 37,
      text: "HCbooking",
      city: false,
      leadId: false,
    },
  ];

  const priorityArr: any = [
    {
      value: "SuperCritical",
    },
    {
      value: "Urgent",
    },
    {
      value: "HopeFull",
    },
    {
      value: "Low",
    },
  ];

  useEffect(() => {
    let url = ``;
    getTopRCFreshLeads("?multiple_category=36&latest_size=10&latest=true");
    // getLeadSource();
    getImagingTicket();
    // getCities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (open2 === false) {
      setLeadId(0);
    }
  }, [open2]);
  const handleInitialWhatsapp = (id: any) => {
    sendInitialMessage(id);
    let url = ``;
    getAllLeads(url);
  };
  const handlereply = (id: any) => {
    setOpenWhatsapp(true);
    setLeadId(id);
  };

  const handleClick = (id: number) => {
    setOpen(true);
    setLeadId(id);
  };

  const handleModal = (id: number) => {
    setOpen2(true);
    setLeadId(id);
  };

  const handleCallClick = (id: number, type: string) => {
    setLeadId(id);
    setCallButton(false);
    clickToCall(id, type);
    setTimeout(() => {
      setCallButton(true);
    }, CALL_DISABLE_TIME);
  };

  const handleRaiseTicket = (id: number) => {
    setRaiseComplaint(true);
    setLeadId(id);
  };

  useEffect(() => {
    if (prscriptionLead !== "") {
      getPrescriptionData(`?lead=${prscriptionLead}`);
    }
  }, [prscriptionLead]);

  const handlePrescriptionClick = (
    id: any,
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setPrescriptionLead(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFrameView = (data: any) => {
    if (data.base64file !== null && data.image === "") {
      setFileType("b64");
      setPlink(`data:application/pdf;base64,${data.base64file}`);
    } else if (data.base64file === null && data.image !== "") {
      let pieces: any = data.image.split(".").pop();
      const last: any = pieces;
      setFileType(last);
      setPlink(data.image);
    }
    setOpenPdf(true);
  };
  const handlePdfModelClose = () => {
    setOpenPdf(false);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const filterBookings = (e: any) => {
    const body: any = {
      status,
      lead: id,
    };
    const url = genrateImagingTicektFilter(body).substring(2);
    getImagingTicket(url ? `?${url}&page=1` : "?page=1");
    // setPage(0);
  };
  const handleStatusModal = (id: any) => {
    setStatusModalOpen(true);
    setTicketId(id);
  };

  return (
    <>
      <div className="data-table" style={{ width: "100%", marginTop: "2rem" }}>
        <h4 style={{ fontWeight: "bold" }}>Imaging Tickets</h4>
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "left" }}
        >
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setStatus(sourceIds);
              }}
              options={[
                {
                  text: "Pending",
                  value: "pending",
                },
                {
                  text: "Confirmed",
                  value: "confirmed",
                },
                {
                  text: "Call Back",
                  value: "call_back",
                },
              ]}
              blurOnSelect
              clearOnBlur
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return status.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Select Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="lead"
              type="text"
              value={id}
              label="lead"
              className="input"
              variant="outlined"
              onChange={(e) => setId(e.target.value.replace(/[^0-9]/g,"") as string)}
              style={{ width: "100%" }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              disabled={status.length===0&&!id}
              onClick={filterBookings}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => history.push("/dashboard/ce/Leadtable")}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ maxHeight: "350px" }}
        >
          {loading ? (
            <Loader />
          ) : (
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Lead ID</StyledTableCell>
                  <StyledTableCell align="center">Created At</StyledTableCell>
                  <StyledTableCell align="center">Comments</StyledTableCell>
                  {/* <StyledTableCell align="center">Action</StyledTableCell> */}
                  <StyledTableCell align="center">
                    Update Status
                  </StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">Whatsapp</StyledTableCell>
                  <StyledTableCell align="center">
                    Send Whatsapp
                  </StyledTableCell>
                  <StyledTableCell align="center">Remarks</StyledTableCell>
                  <StyledTableCell align="center">
                    Lead Source Url
                  </StyledTableCell>
                  <StyledTableCell align="center">Agent</StyledTableCell>
                  <StyledTableCell align="center">Call</StyledTableCell>
                  <StyledTableCell align="center">
                    Contact number
                  </StyledTableCell>
                  <StyledTableCell align="center">Patient name</StyledTableCell>
                  <StyledTableCell align="center">City</StyledTableCell>
                  <StyledTableCell align="center">Patient Area</StyledTableCell>
                  <StyledTableCell align="center">Call status</StyledTableCell>
                  <StyledTableCell align="center">Language</StyledTableCell>
                  <StyledTableCell align="center">Edit</StyledTableCell>
                  <StyledTableCell align="center">Raise Ticket</StyledTableCell>
                  <StyledTableCell align="center">Prescription</StyledTableCell>
                  <StyledTableCell align="center">Priority</StyledTableCell>
                  {/* <StyledTableCell align="center">Call Count</StyledTableCell> */}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {imagingTicket &&
                imagingTicket?.results &&
                imagingTicket?.results.length > 0
                  ? imagingTicket?.results.map((data: any, index: any) => {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.lead?.id}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            {new Date(data?.created_at).toLocaleString()}
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row" align="center">
                            <div>
                              <MessageIcon
                                onClick={() => handleClick(data?.lead.id)}
                              />
                            </div>
                          </StyledTableCell>
                          {/* <StyledTableCell component="th" scope="row" align="center">
                        <div>
                          <MessageIcon onClick={() => handleModal(data?.lead?.id)} />
                        </div>
                      </StyledTableCell> */}
                          <StyledTableCell component="th" scope="row" align="center">
                            <div>
                              <EqualizerIcon
                                onClick={() => handleStatusModal(data?.id)}
                              />
                            </div>
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row" align="center">
                            {JSON.stringify(data?.status)}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <WhatsAppIcon
                              onClick={() => handlereply(data.id)}
                            />
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {data?.lead_source === "imaging" ||
                            data?.lead_source === "imaging_lp" ||
                            data?.lead_source === "imaging_call" ||
                            data?.lead_source === "imaging_homedx" ||
                            data?.lead_source === "ZOKO_IMAGING" ||
                            data?.lead_source === "imaging_alliance" ||
                            data?.lead_source === "imaging_dsa" ||
                            data?.lead_source === "imaging_web" ||
                            data?.lead_source === "justdial_imaging" ? (
                              <SendIcon
                                onClick={() => handleInitialWhatsapp(data.id)}
                              />
                            ) : (
                              ""
                            )}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.remarks}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.lead?.lead_source_url}
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.by_user?.name}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            <Button
                              disabled={!callButton}
                              className="addDeck"
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                handleCallClick(data?.lead?.id, "call")
                              }
                              startIcon={<CallIcon />}
                            ></Button>
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.agent?.phonenumber}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.lead?.patient_name}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.lead?.city_name}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.lead?.patient_area}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.lead?.call_status?.name}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.lead?.language}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            <Link
                              to={`/dashboard/ce/fill-info?id=${data?.lead?.id}`}
                            >
                              <EditIcon />
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            <Button
                              variant={
                                !raiseComplaint ? "contained" : "outlined"
                              }
                              color="secondary"
                              onClick={() => handleRaiseTicket(data.lead.id)}
                            >
                              <ConfirmationNumberIcon />
                            </Button>
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            {data.is_prescription ? (
                              <Button
                                aria-controls="fade-menu"
                                aria-haspopup="true"
                                onClick={(event: any) =>
                                  handlePrescriptionClick(data.lead.id, event)
                                }
                              >
                                <NoteAddIcon />
                              </Button>
                            ) : (
                              "NA"
                            )}
                            <Menu
                              id="fade-menu"
                              anchorEl={anchorEl}
                              keepMounted
                              open={openPrescription}
                              onClose={handleClose}
                              TransitionComponent={Fade}
                            >
                              {prescription &&
                                prescription?.results &&
                                prescription?.results?.length > 0 &&
                                prescription?.results.map((data: any) => {
                                  let pieces: any = data.image.split(".").pop();
                                  const last: any = pieces;
                                  return (
                                    <>
                                      {data?.base64file !== null &&
                                      data.image === "" ? (
                                        <MenuItem
                                          onClick={() => handleFrameView(data)}
                                        >
                                          Name :{data?.name}, Age:{data?.age},
                                          Created Date :
                                          {new Date(
                                            data.created_at
                                          ).toLocaleString()}
                                        </MenuItem>
                                      ) : data?.base64file === null &&
                                        data.image !== "" ? (
                                        last === "png" ||
                                        last === "jpg" ||
                                        last === "jpeg" ? (
                                          <MenuItem>
                                            <a
                                              href={data?.image}
                                              rel="noreferrer noopener"
                                              target="_blank"
                                            >
                                              Name :{data?.name}, Age:
                                              {data?.age}, Created Date :
                                              {new Date(
                                                data.created_at
                                              ).toLocaleString()}
                                            </a>
                                          </MenuItem>
                                        ) : (
                                          <MenuItem>
                                            <a
                                              href={data?.image}
                                              rel="noreferrer noopener"
                                              target="_blank"
                                            >
                                              Name :{data?.name}, Age:
                                              {data?.age}, Created Date :
                                              {new Date(
                                                data.created_at
                                              ).toLocaleString()}
                                            </a>
                                          </MenuItem>
                                        )
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  );
                                })}
                            </Menu>
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center">
                            {data?.lead?.priority}
                          </StyledTableCell>
                          {/* <StyledTableCell component="th" scope="row" align="center">
                            {data?.lead?.count}
                          </StyledTableCell> */}
                        </StyledTableRow>
                      );
                    })
                  : "Data Not Available"}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openPdf}
          onClose={handlePdfModelClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openPdf}>
            <>
              {fileType === "pdf" ? (
                <iframe
                  style={{ width: "80%", height: "750px" }}
                  src={pLink}
                  title="title"
                >
                  Presss me: <a href={pLink}>Download PDF</a>
                </iframe>
              ) : fileType === "b64" ? (
                <iframe
                  src={pLink}
                  style={{ width: "80%", height: "750px" }}
                  title="Iframe Example"
                ></iframe>
              ) : (
                ""
              )}
            </>
          </Fade>
        </Modal>
      </div>
      <CommentsModal open={open} setOpen={setOpen} leadId={leadId} />
      <CommentsModal2
        open={open2}
        getCities={getCities}
        cities={cities}
        setOpen={setOpen2}
        leadId={leadId}
      />
      <TicketModal
        open={statusModalOpen}
        setOpen={setStatusModalOpen}
        ticketId={ticketId}
      />
      <RaiseComplaintModal
        open={raiseComplaint}
        setOpen={setRaiseComplaint}
        leadId={Number(leadId)}
        bookingId={0} booking={undefined} totalPrice={0}      />
      <ReplyModal
        open={openWhatsapp}
        setOpen={setOpenWhatsapp}
        setLeadId={setLeadId}
        leadId={leadId}
        callApi={"no"}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  topRcFreshLeads: state.BookingReducer.topRcFreshLeads,
  lead_source: state.BookingReducer.lead_source,
  cities: state.BookingReducer.cities,
  loading: state.BookingReducer.loading,
  imagingTicket: state.BookingReducer.imagingTicket,
  prescription: state.BookingReducer.prescription,
});

export default connect(mapStateToProps, {
  getTopRCFreshLeads,
  clickToCall,
  getLeadSource,
  getCities,
  getPrescriptionData,
  getImagingTicket,
  getAllLeads,
})(LeadsTable);
