import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Chip, Paper,withStyles, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../components/loader/index';
import moment from 'moment';
import { getSyncData, getSMSLink, getSmsReportSend, clickToCallBooking, getReportDownload } from "../actions/HealthAdviserAction";
import { MessageRounded } from '@material-ui/icons';
import BookingCountModal from "../components/Comments/bookingcallcountmodal"
import { useHistory } from "react-router-dom";
import CallIcon from "@material-ui/icons/Call";
import SendIcon from '@material-ui/icons/Send';
import GetAppIcon from '@material-ui/icons/GetApp';
import AdditonalFamilyModal from '../tables/AdditionalFamily/AdditionFamilyDetails'
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
          },

    })
);
const StyledTableCell:any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);



interface Props {
    getBookings: any;
    bookingList: any;
    page: any;
    setPage: any;
    getSyncData: any;
    bookingCall: any;
    setopenModal: any;
    reportDownload: any;
    clickToCallBooking: any;
    getReportDownload: any;
    getSmsReportSend: any;
    getSMSLink: any;
    openModal: any;
    loading: Boolean;
    setBookingId:any;
}

const BookingsTable: React.FC<Props> = ({
    getBookings,
    bookingList,
    getSyncData,
    page,
    setPage,
    bookingCall,
    getSmsReportSend,
    getReportDownload,
    clickToCallBooking,
    reportDownload,
    getSMSLink,
    setopenModal,
    openModal,
    loading,
    setBookingId
}) => {
    const classes = useStyles()
    const [reportSendStatus, setReportSendStatus] = useState(false)
    const [additionalMemebr, setAdditionalMemebr] = useState<any>()
    const [additionalModal, setAdditonalModal] = useState<boolean>(false)
    const history = useHistory()
    const handleClick = (id: number) => {
        setopenModal(true);
        setBookingId(id);
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = bookingList.links && bookingList.links.next.split("?")[1];
            getBookings(url);
        } else if (newPage < page) {
            let url = bookingList.links && bookingList.links.previous.split("?")[1];
            getBookings(url);
        }
        setPage(newPage as number);
    };
    const handleSync = (id: any) => {
        getSyncData(id)
        alert("Confirmed")
        setTimeout(() => {
            getBookings()
        }, 1000);

    }
    const handleSMSSend = (id: any) => {
        getSMSLink(id)
        alert("SMS Sent Successfully")
    }
    const handleSendReportSms = (id: any) => {
        getSmsReportSend(`${id}/booking`)
        setReportSendStatus(true)
        alert("Report SMS Send Successfully")
    }
    const handleCallClick = (id: number, type: string) => {
        clickToCallBooking(id, type);
    };
    const handleDownloadReport = (report: any, id: any) => {
        getReportDownload(`${id}/`)
        
        var b64 = report?.allReportDetails?.reportDetails;
        var bin = window.atob(b64);
        var link = document.createElement('a');
        link.download = `${report.allReportDetails.Patient_Name}-${report.allReportDetails.Age}.pdf`;
        link.href = 'data:application/octet-stream;base64,' + b64;
        link.click();
    };
    const handleAdditionalView = (member: any) => {
        setAdditionalMemebr(member);
        setAdditonalModal(true)
    }
    return (
        <div className="data-table" style={{ width: "100%" }}>
            
            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "540px" }}>
                    {loading ? <Loader /> : (
                        <Table stickyHeader  aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Action</StyledTableCell>
                                    <StyledTableCell align="center">Booking Id</StyledTableCell>
                                    <StyledTableCell align="center">Lims Id</StyledTableCell>
                                    <StyledTableCell align="center">Booking Date</StyledTableCell>
                                    <StyledTableCell align="center">Name</StyledTableCell>
                                    <StyledTableCell align="center">Agent</StyledTableCell>
                                    <StyledTableCell align="center">Age</StyledTableCell>
                                    <StyledTableCell align="center">Gender</StyledTableCell>
                                    <StyledTableCell align="center">Email</StyledTableCell>
                                    <StyledTableCell align="center">Collection Date</StyledTableCell>
                                    <StyledTableCell align="center">Collection Time</StyledTableCell>
                                    <StyledTableCell align="center">Booking Time</StyledTableCell>
                                    <StyledTableCell align="center">Zone Name</StyledTableCell>
                                    <StyledTableCell align="center">Mobile Number</StyledTableCell>
                                    <StyledTableCell align="center">Pickup Receive Amount</StyledTableCell>
                                    <StyledTableCell align="center">Booking Status</StyledTableCell>
                                    <StyledTableCell align="center">Pickup Status</StyledTableCell>
                                    <StyledTableCell align="center">Report Status</StyledTableCell>
                                     <StyledTableCell align="center">Verification Status</StyledTableCell> 
                                    
                                    {/* <TableCell align="center">Call Action</TableCell> */}
                                    <StyledTableCell align="center">Review SMS</StyledTableCell>
                                    <StyledTableCell align="center">Send Report Sms</StyledTableCell>
                                    <StyledTableCell align="center">Call</StyledTableCell>
                                    <StyledTableCell align="center">Comment</StyledTableCell>

                                    {/* <TableCell align="center">Sync</TableCell> */}
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    bookingList.results && bookingList.results.length > 0 && bookingList.results.map((booking: any, index: any) => {
                                        const pendingData = booking.additional_members.filter((data: any) => data.pickup_status === "pending")
                                        const confirmedData = booking.additional_members.filter((data: any) => data.pickup_status === "confirmed")
                                        return (
                                            <StyledTableRow style={{ background: booking.report_status === "consolidate" ? "#4bf296" : booking.report_status === "partial" ? "#faa928" : booking.report_status === "pending" ? "#fa283d" : "#fa283d" }} key={index}>
                                                <StyledTableCell align="center">
                                                    <Link to={`/dashboard/ha/booking-view/${booking.pk}`} style={{ textDecoration: "none" }}>
                                                        <Button variant="contained" style={{ backgroundColor: "#87d5fa" }}>View</Button>
                                                    </Link>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">{booking.additional_members.length > 0 ? (
                                                    <div>
                                                        {booking.pk}+
                                                        <Chip
                                                            label={"T" + booking.additional_members.length + `${confirmedData.length > 0 ? "C" + confirmedData.length : ""}` + `${pendingData.length > 0 ? "P" + pendingData.length : ""}`}
                                                            style={{ background: pendingData.length > 0 ? "#e91e63" : "#1b5e20" }}
                                                            onClick={() => handleAdditionalView(booking.additional_members)}
                                                        />
                                                    </div>

                                                ) : booking.pk}</StyledTableCell>
                                                <StyledTableCell align="center">{booking.bill_id || "N/A"}</StyledTableCell>

                                                <StyledTableCell align="center">{booking.booking_date}</StyledTableCell>
                                                <StyledTableCell align="center">{`${booking.designation || ""} ${booking.customer_name}`}</StyledTableCell>
                                                <StyledTableCell align="center">{(booking.agent && booking.agent.name) || "NA"}</StyledTableCell>
                                                <StyledTableCell align="center">{booking.customer_age}</StyledTableCell>
                                                <StyledTableCell align="center">{booking.customer_gender}</StyledTableCell>
                                                <StyledTableCell align="center">{booking.customer_email}</StyledTableCell>
                                                <StyledTableCell align="center">{booking.collection_date}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {booking.collection_slot && moment(`${booking.collection_slot.slot.split("-")[0]}`, ["HH.mm.ss"]).format("hh:mm A")}
                                                    {" - "}
                                                    {booking.collection_slot && moment(`${booking.collection_slot.slot.split("-")[1]}`, ["HH.mm.ss"]).format("hh:mm A")}
                                                </StyledTableCell>
                                                <StyledTableCell align="center"> {new Date(booking.created_at).toLocaleString()}</StyledTableCell>
                                                <StyledTableCell align="center">{booking.customer_areapincode.area}</StyledTableCell>
                                                <StyledTableCell align="center">{booking.customer_phonenumber}</StyledTableCell>
                                                <StyledTableCell align="center">{booking.pickup_receive_amount || "NA"}</StyledTableCell>
                                                <StyledTableCell align="center"><Chip label={`${booking.booking_status}`} style={{ backgroundColor: "#ffffff" }} /></StyledTableCell>
                                                <StyledTableCell align="center"><Chip label={`${booking.pickup_status}`} style={{ backgroundColor: "#ffffff" }} /></StyledTableCell>
                                                <StyledTableCell align="center"><Chip label={`${booking.report_status}`} style={{ backgroundColor: "#ffffff" }} /></StyledTableCell>
                                                  <StyledTableCell align="center"><Chip label={`${booking.verification_status}`} style={{background:booking.verification_status==="pending"?"#ffee58":booking.verification_status==="confirmed"?"#00e676":booking.verification_status==="cancelled"?"#ff3d00":"#d500f9"}}/></StyledTableCell> 

                                                {/* <TableCell align="center">
                                                    <BookingCountModal bookingCall={bookingCall} bookingId={booking.pk} />
                                                </TableCell> */}
                                                <StyledTableCell align="center">
                                                    <SendIcon onClick={() => handleSMSSend(booking.pk)} />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {booking.report_status === "Partial" ||
                                                        booking.report_status === "consolidate" ? (
                                                        <SendIcon onClick={() => handleSendReportSms(booking.pk)} />
                                                    ) : "NA"}

                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <CallIcon
                                                        onClick={() => handleCallClick(booking.pk, "call")}
                                                    />
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="center">
                                                    {booking.report_status === "partial" || booking.report_status === "consolidate" ? (
                                                        <GetAppIcon
                                                            onClick={() => handleDownloadReport(reportDownload, booking.pk)}
                                                        />
                                                    ) : (<GetAppIcon
                                                        onClick={() => alert("Report Not Avilable")}
                                                    />)}

                                                </StyledTableCell> */}

                                                <StyledTableCell align="center">
                                                    <div>
                                                        <MessageRounded onClick={() => handleClick(booking.pk)} />
                                                    </div>
                                                </StyledTableCell>

                                                {/* <TableCell align="center">
                                                    {booking.bill_id === null && (
                                                        <Button disabled={booking.is_sync} variant="contained" color="inherit" onClick={() => handleSync(booking.pk)}>Sync</Button>

                                                    )}

                                                </TableCell> */}
                                            </StyledTableRow>

                                        )
                                    })}
                            </TableBody>
                            <TableFooter>
                                <StyledTableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[]}
                                        colSpan={30}
                                        count={bookingList.count || 0}
                                        rowsPerPage={bookingList.page_size}
                                        page={page}
                                        onPageChange={handleChangePage}
                                    />
                                </StyledTableRow>
                            </TableFooter>
                        </Table>
                    )}
                </TableContainer>
                {bookingList.results && bookingList.results.length === 0 && (
                    <h6 style={{ textAlign: "center" }}>No Data Found</h6>
                )}
                <AdditonalFamilyModal additionalMemebr={additionalMemebr} additionalModal={additionalModal} setAdditonalModal={setAdditonalModal} loading={loading} />
           
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    bookingCall: state.HealthAdviserReducer.bookingCall,
    reportDownload: state.HealthAdviserReducer.reportDownload,

})

export default connect(mapStateToProps, {
    getSyncData, getSMSLink, getSmsReportSend, clickToCallBooking, getReportDownload,

})(BookingsTable);

