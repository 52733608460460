import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Grid, Select, MenuItem, TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Link } from "react-router-dom";
import { getLedgerDetails, getCenterInfo } from "../actions/CollectionCenterAdmin";
import { TableFooter, TablePagination } from "@material-ui/core";
import moment from "moment";
import Loader from "../Components/loader";
import { Button } from "@material-ui/core";
import { genrateCollectionCentrLedgerFilter } from "../../helpers/generateUrl";
import Autocomplete from "@material-ui/lab/Autocomplete";

const StyledTableCell:any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#3f51b5",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
      textTransform: "capitalize",
    },
  })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "90%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
  },
  })
);

interface Props {
  loading: boolean;
  getLedgerDetails: any;
  clientLedgerDetails: any;
  getCenterInfo: any;
  centerInfo: any;
}

const LeadsTable: React.FC<Props> = ({
  loading,
  clientLedgerDetails,
  getLedgerDetails,
  getCenterInfo,
  centerInfo,
}) => {
  const timer = useRef<any>(0);
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [status, setStatus] = useState<any>("none");
  const [ladgerType, setLedgerType] = useState<any>("none");
  const [center, setCenter] = useState<any>([]);

  const history = useHistory();

  useEffect(() => {
    getLedgerDetails();
    getCenterInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  // const handleChangePage = (
  //     event: React.MouseEvent<HTMLButtonElement> | null,
  //     newPage: number
  // ) => {
  //     if (newPage > page) {
  //         let url = clientLedgerDetails.next;
  //         getLedgerDetails(url.substring(url.indexOf("?")));
  //     } else if (newPage < page) {
  //         let url = clientLedgerDetails.previous;
  //         getLedgerDetails(
  //             url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?"))
  //         );
  //     }
  //     setPage(newPage);
  // };
  const filterBookings = () => {
    const body: any = {
      center: center.join(","),
      start_date,
      end_date,
      ledger_type: ladgerType,
      status,
    };
    const url = genrateCollectionCentrLedgerFilter(body).substring(2);
    getLedgerDetails(`${url}&page=1`);
    setPage(0);
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12} sm={6} md={3}>
            <Select
              className="input"
              name="status"
              variant="outlined"
              value={status}
              style={{ width: "100%" }}
              onChange={(e) => setStatus(e.target.value as String)}
            >
              <MenuItem disabled value="none">
                Status
              </MenuItem>
              <MenuItem value={"completed"}>Completed</MenuItem>
              <MenuItem value={"canceled"}>Canceled</MenuItem>
              <MenuItem value={"deleted"}>Deleted</MenuItem>
              <MenuItem value={"hold"}>Hold</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Select
              className="input"
              name="ledger_type"
              variant="outlined"
              value={ladgerType}
              style={{ width: "100%" }}
              onChange={(e) => setLedgerType(e.target.value as String)}
            >
              <MenuItem disabled value="none">
                Ladger Type
              </MenuItem>
              <MenuItem value={"debit"}>Debit</MenuItem>
              <MenuItem value={"credit"}>Credit</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              className="input"
              name="start_date"
              type="date"
              label="Start Date"
              value={start_date}
              variant="outlined"
              onChange={(e) => setStart_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="end_date"
              type="date"
              value={end_date}
              className="input"
              label="End Date"
              variant="outlined"
              onChange={(e) => setEnd_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid container spacing={3} direction="row" alignItems="flex-start">
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Button
              variant="contained"
              color="secondary"
              style={{ height: "56px" }}
              fullWidth
              onClick={filterBookings}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Button
              variant="contained"
              color="primary"
              style={{ height: "56px" }}
              fullWidth
              onClick={() =>
                history.push("/dashboard/cca/ledger-details")
              }
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <div className={classes.toolbar} />
        <h4 style={{ textAlign: "center" }}>Ledger</h4>
        <div
          className="data-table"
          style={{ width: "100%", marginTop: "2rem" }}
        >
         <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "580px" }}>
          <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">S. No.</StyledTableCell>
                  <StyledTableCell align="center">Type</StyledTableCell>
                  <StyledTableCell align="center">Amount</StyledTableCell>
                  <StyledTableCell align="center">
                    Payment Date.
                  </StyledTableCell>
                  <StyledTableCell align="center">Remarks</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {clientLedgerDetails.results &&
                  clientLedgerDetails.results.length > 0 &&
                  clientLedgerDetails.results.map((client: any, index: any) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {client.ledger_type}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {client.amount}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {client?.ledger_date &&
                            moment(`${client.ledger_date}`, [
                              "YYYY-MM-DD HH.mm.ss",
                            ]).format("YYYY-MM-DD hh:mm A")}
                        </StyledTableCell>
                        <StyledTableCell>
                          {client.remarks}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {client.status}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                {/* <TablePagination
                                        colSpan={6}
                                        count={clientLedgerDetails.count || 0}
                                        rowsPerPageOptions={[]}
                                        rowsPerPage={rowsPerPage}
                                        // onChangeRowsPerPage={handleChangeRowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                    /> */}
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  clientLedgerDetails: state.CollectionCenterAdminReducer.clientLedgerDetails,
  centerInfo: state.CollectionCenterAdminReducer.centerInfo,
  loading: state.CollectionCenterAdminReducer.loading,
});

export default connect(mapStateToProps, { getLedgerDetails, getCenterInfo })(
  LeadsTable
);
