import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, MenuItem, Select, TextField, Grid } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { useEffect } from "react";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    }
}));

type ModalProps = {
    openModal: boolean;
    updateTrasferTicketStatus: any;
    setopenModal: Function;
    ticketId:any;
    ticketStatus:any;
    setTicketStatus:any;
    remarks:any;
    setRemarks:any;
    getTransferBooking:any;
    ticketStatusType:any;
};

const TicketStatusModal: React.FC<ModalProps> = ({
    openModal,
    updateTrasferTicketStatus,
    setopenModal,
    ticketStatus,
    setTicketStatus,
    remarks,
    setRemarks,
    getTransferBooking,
    ticketStatusType,
    ticketId,
}) => {
    const classes = useStyles();
    const handleClose = () => {
        setopenModal(false);
    };

    const updateTicket = () => {
        const body: any = {
            ticket_status:ticketStatus,
            remarks: remarks,
        }
        updateTrasferTicketStatus(body, ticketId)
        getTransferBooking(`?ticket_status=${ticketStatusType}`)
        handleClose()
    };

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <div className={classes.paper}>
                        <div className={classes.head}>
                            <h4
                                style={{
                                    marginBottom: "1rem",
                                }}
                                id="transition-modal-title"
                            >
                               Update Ticekt Status
                            </h4>
                            <CloseIcon onClick={() => setopenModal(false)} />
                        </div>
                        <Grid container direction="row" alignItems="flex-start" justify="space-between" spacing={3} style={{ margin: "1rem auto" }}>
                            <Grid item xs={12} md={12}>
                                <Select className="input"
                                    name="action"
                                    variant="outlined"
                                    value={ticketStatus}
                                    style={{ width: "100%" }}
                                    onChange={(e) => setTicketStatus(e.target.value as string)}
                                >
                                    <MenuItem disabled value={"none"}>Ticket Status</MenuItem>
                                    <MenuItem value={"pending"}>pending</MenuItem>
                                    <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                                    <MenuItem value={"re_transferred"}>ReTransferred</MenuItem>
                                </Select>
                            </Grid>
                       
                            <Grid item xs={12}>
                                <TextField
                                    name="comment"
                                    type="text"
                                    multiline
                                    rows={3}
                                    value={remarks}
                                    className="input"
                                    variant="outlined"
                                    placeholder="Remarks...."
                                    onChange={(e) => setRemarks(e.target.value as string)}
                                    style={{ width: "100%" }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ height: "50px" }}
                                    onClick={updateTicket}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, {
})(TicketStatusModal);
