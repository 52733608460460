import React from 'react';
import clsx from 'clsx';
import { Link } from "react-router-dom";
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Tooltip from '@material-ui/core/Tooltip';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import LockResetIcon from '@mui/icons-material/LockReset';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { logout, panelUserLogout } from "../../actions/loginActions";
import { useDispatch } from "react-redux";
import { changePassword } from '../actions/ImagingAdminAction';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import KeyboardHideIcon from '@material-ui/icons/KeyboardHide';
import RateReviewIcon from '@material-ui/icons/RateReview';
import CachedIcon from '@mui/icons-material/Cached';
import AccountTreeTwoToneIcon from "@material-ui/icons/AccountTreeTwoTone";
import QuizIcon from '@mui/icons-material/Quiz';
import DvrIcon from '@material-ui/icons/Dvr';
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 0,
		},
		appBar: {
			zIndex: theme.zIndex.drawer + 1,
			transition: theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
		},
		appBarShift: {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		title: {
			flexGrow: 1,
			display: 'none',
			[theme.breakpoints.up('sm')]: {
				display: 'block',
			},
		},
		menuButton: {
			marginRight: 36,
		},
		hide: {
			display: 'none',
		},
		drawer: {
			color: "#fff",
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap',
		},
		drawerOpen: {
			width: drawerWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: 'hidden',
			width: theme.spacing(7) + 1,
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(9) + 1,
			},
		},
		toolbar: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
			backgroundColor: "#3f51b5",
			padding: theme.spacing(0, 1),
			// necessary for content to be below app bar
			...theme.mixins.toolbar,
		},
		content: {
			width: "80%",
			flexGrow: 1,
			padding: theme.spacing(3),
		},
	}),
);

export default function CustomerExecutive() {
	let dispatch = useDispatch();
	const classes = useStyles();
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);

	const [changePasswordModal, setchangePasswordModal] = React.useState(false)

	const [oldPassword, setOldPassword] = React.useState("")
	const [newPassword, setNewPassword] = React.useState("")
	const [confirmPassword, setConfirmPassword] = React.useState("")

	const updatePassword = async () => {
		const body: any = {
			old_password: oldPassword,
			new_password: newPassword,
			confirm_password: confirmPassword
		}
		await dispatch(changePassword(body))
		setchangePasswordModal(false)
	}

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};
	const handleLogout = () => {
		dispatch(panelUserLogout())
		dispatch(logout())
	}
	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open,
				})}
			>
				<Toolbar style={{ backgroundColor: "#3f51b5" }}>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						className={clsx(classes.menuButton, {
							[classes.hide]: open,
						})}
					>
						<MenuIcon />
					</IconButton>
					<Typography className={classes.title} variant="h6" noWrap >
						Redcliffe Imaging
					</Typography>
				</Toolbar>
			</AppBar>
			<Drawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
			>
				<div className={classes.toolbar}>
					<p>Dashboard</p>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					</IconButton>
				</div>
				<Divider />
				<List>
					<Link to="/dashboard/ia">
						<ListItem button>
							<Tooltip title="CENTER ONBOARDING" arrow>
								<ListItemIcon>
									<CenterFocusStrongIcon style={{ color: "#3f51b5" }} />
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary="CENTER ONBOARDING" />
						</ListItem>
					</Link>
				</List>
				<List>
					<Link to="/dashboard/ia/sub-centre">
						<ListItem button>
							<Tooltip title="SUB CENTRE" arrow>
								<ListItemIcon>
									<AccountTreeTwoToneIcon style={{ color: "#3f51b5" }} />
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary="SUB CENTRE" />
						</ListItem>
					</Link>
				</List>
				<List>
					<Link to="/dashboard/ia/testmaster">
						<ListItem button>
							<Tooltip title="TEST MASTER" arrow>
								<ListItemIcon>
									<KeyboardHideIcon style={{ color: "#3f51b5" }} />
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary="TEST MASTER" />
						</ListItem>
					</Link>
				</List>
				<List>
					<Link to="/dashboard/ia/modality">
						<ListItem button>
							<Tooltip title="SCAN TYPE" arrow>
								<ListItemIcon>
									<DvrIcon style={{ color: "#3f51b5" }} />
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary="SCAN TYPE" />
						</ListItem>
					</Link>
				</List>
				<List>
					<Link to="/dashboard/ia/create-alias">
						<ListItem button>
							<Tooltip title="ALIAS LIST" arrow>
								<ListItemIcon>
									<RateReviewIcon style={{ color: "#3f51b5" }} />
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary="ALIAS LIST" />
						</ListItem>
					</Link>
				</List>
				<List>
					<Link to="/dashboard/ia/center_deactivate">
						<ListItem button>
							<Tooltip title="DEACTIVATED CENTER" arrow>
								<ListItemIcon>
									<CachedIcon style={{ color: "#3f51b5" }} />
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary="DEACTIVATED CENTER" />
						</ListItem>
					</Link>
				</List>
				<List>
					<Link to="/dashboard/ia/subcenter_deactivate">
						<ListItem button>
							<Tooltip title="DEACTIVATED SUB CENTER" arrow>
								<ListItemIcon>
									<LockResetIcon style={{ color: "#3f51b5" }} />
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary="DEACTIVATED SUB CENTER" />
						</ListItem>
					</Link>
				</List>
				<List>
          <Link to="/dashboard/ia/helpdesk">
            <ListItem button>
              <Tooltip title="Help Desk" arrow>
                <ListItemIcon>
                  <QuizIcon style={{ color: "#3f51b5" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Help Desk" />
            </ListItem>
          </Link>
        </List>
				<Divider />
				<List>
					<ListItem button onClick={() => handleLogout()}>
						<ListItemIcon>
							<ExitToAppIcon style={{ color: "#3f51b5" }} />
						</ListItemIcon>
						<ListItemText primary="Logout" />
					</ListItem>
				</List>
			</Drawer>
		</div>
	);
}