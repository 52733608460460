import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Container,
    Grid,
    TextField,
    Select,
    MenuItem,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { generateBookingFilterUrl } from "../../helpers/generateUrl";
import { connect } from "react-redux";
import { getAgentList, getLeadSource, getPartners, getDoctorBookings, getCities } from '../actions/salesLeadAdminAction'
import './filter.sass'
import { useHistory } from "react-router";
// import BookingsTable from "../tables/bookingsTable";
import DoctorConsultation from "../tables/doctorConsultation";
import Loader from "../Components/loader/index";
import { BOOKING_STATUS } from '../../booking_status';
import BookingCommentsModal from '../Components/comments/bookingComments'
import DoctorCusonltancyDisposition from '../Components/comments/DoctorDisposition'
import { border } from "@mui/system";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        card: {
            height: "100%",
            padding: theme.spacing(1),
        },

        card_grid: {
            // wordSpacing:3,
            justifyContent: "space-between",
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
        },
    })
);

interface Props {
    children: any;
    agentsList: any;
    bookingList: any;
    partnerList: any;
    getAgentList: any;
    getPartners: any;
    getDoctorBookings: any;
    getCities: any;
    cities: any;
    getLeadSource: any;
    lead_source: any;
    loading: Boolean;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
    agentsList,
    partnerList,
    getAgentList,
    getPartners,
    getDoctorBookings,
    bookingList,
    getCities,
    cities,
    getLeadSource,
    lead_source,
    loading,
}) => {
    const classes = useStyles();

    const history = useHistory();

    const [openModal, setopenModal] = useState<boolean>(false);
    const [booking_type, setbooking_type] = useState<String>("none");
    const [status, setStatus] = useState<any>([]);
    const [ticketstatus, setTicketStatus] = useState<any>("none")
    const [partner, setPartner] = useState<any>([]);
    const [agent, setAgent] = useState<any>([]);
    const [city, setCity] = useState<any>([]);
    const [booking_date, setBooking_date] = useState("");
    const [ticketstart_date, setTicketStartDate] = useState("")
    const [ticketend_date, setTicketEndDate] = useState("")
    const [collection_date, setCollection_date] = useState("");
    const [verificationStatus, setVerificationStatus] = useState("none");
    const [pickup_status, setPickup_status] = useState("none");
    const [disposition, setDisposition] = useState<any>("none");
    const [bookingId, setBookingid] = React.useState<any>("");
    const [created_date, setCreatedAt] = useState<string>("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [report_status, setReportStatus] = useState<any>("none");
    const [page, setPage] = useState(0);
    const [feedBackStaus, setFeedBackStatus] = useState<String>("open");
    const [bColor, setBcolor] = useState<String>("open");
    const [docDisOpen, setDocDisOpen] = useState<boolean>(false);
    const [ticketId, setTicketId] = useState<any>("");
    const [doctor, setDoctor] = useState(0);
    const [createdstart_date, setCreatedStartDate] = useState("")
    const [createdend_date, setCreatedEndDate] = useState("")
    const timer = useRef<any>(0);

    useEffect(() => {
        getAgentList(`?usergroup=Doctor`);
        // getPartners()
        // getCities()
        getLeadSource();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        getDoctorBookings(`bucket=${feedBackStaus}`);
    }, [feedBackStaus]);

    const filterBookings = () => {
        const body: any = {
            status: status.join(","),
            city: city.join(","),
            booking_date,
            bookingId,
            created_date,
            name,
            report_status,
            phone,
            doctor,
            disposition,
            booking_type: booking_type,
            bucket: ticketstatus,
            ticket_closed_date_start: ticketstart_date,
            ticket_closed_date_end: ticketend_date,
            start_date: createdstart_date,
            end_date: createdend_date,
        };

        const url = generateBookingFilterUrl(body).substring(2);
        getDoctorBookings(`${url}&page=1`);
        setPage(0);
    };
    const handlePendingTicket = () => {
        setFeedBackStatus("open");
        setBcolor("open");
    };
    const handleCloseTicket = () => {
        setFeedBackStatus("closed");
        setBcolor("closed");
    };
    const handleWip = () => {
        setFeedBackStatus("wip");
        setBcolor("wip");
    };

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <h2 style={{ fontWeight: "bold" }}>Doctor Consultation </h2>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    style={{ alignItems: "left" }}
                >
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="booking"
                            type="number"
                            label="Booking Id"
                            value={bookingId}
                            variant="outlined"
                            onChange={(e) => setBookingid(e.target.value)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="name"
                            type="text"
                            label="Name"
                            value={name}
                            variant="outlined"
                            onChange={(e) => setName(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="phone"
                            type="number"
                            label="Phone"
                            value={phone}
                            variant="outlined"
                            onChange={(e) => setPhone(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="city"
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id);
                                }
                                setCity(sourceIds);
                            }}
                            options={cities || []}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            getOptionLabel={(option: any) => option.name}
                            getOptionDisabled={(option: any) => {
                                return city.includes(option.id);
                            }}
                            disableClearable
                            disableCloseOnSelect
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    getCities(newInputValue);
                                }, 1000);
                                if (newInputValue.length === 0) {
                                    setCity([]);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="City"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="Created Date"
                            type="date"
                            label="Created Ticekt Date"
                            value={created_date}
                            variant="outlined"
                            onChange={(e) => setCreatedAt(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="bookingtype"
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    setReportStatus(obj.value);
                                }

                            }}
                            options={[
                                {
                                    text: "None",
                                    value: "na",
                                },
                                {
                                    text: "Pending",
                                    value: "pending",
                                },
                                {
                                    text: "Partial",
                                    value: "partial",
                                },
                                {
                                    text: "Consolidate",
                                    value: "consolidate",
                                },
                            ]}
                            freeSolo
                            blurOnSelect
                            aria-required
                            getOptionLabel={(option: any) => option.text}
                            disableClearable
                            disableCloseOnSelect
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Report Status"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="dispo"
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    setDisposition(obj.value);
                                }
                            }}
                            options={[
                                {
                                    text: "CUSTOMER NOT RESPONDING",
                                    value: "customer_not_responding",
                                },
                                {
                                    text: "FAULTY REPORTS",
                                    value: "faulty_reports",
                                },
                                {
                                    text: "RESAMPLING REQUIRED",
                                    value: "resampling_required",
                                },
                                {
                                    text: "CUSTOMER NOT CONTACTABLE",
                                    value: "customer_not_contactable",
                                },
                                {
                                    text: "CALL LATER",
                                    value: "call_later",
                                },
                                {
                                    text: "COMPLETE",
                                    value: "conplete",
                                },
                            ]}
                            freeSolo
                            blurOnSelect
                            aria-required
                            getOptionLabel={(option: any) => option.text}
                            disableClearable
                            disableCloseOnSelect
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Doctor Disposition"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="doctor"
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    setDoctor(obj.id);
                                }
                            }}
                            options={agentsList?.results || []}
                            freeSolo
                            blurOnSelect
                            aria-required
                            getOptionLabel={(option: any) => option?.id && option?.username}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    getAgentList(`?code=${newInputValue}&usergroup=Doctor`);
                                }, 1000);
                                if (newInputValue.length === 0) {
                                    setDoctor(0);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    placeholder="Doctor Name"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="bookingtype"
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    setbooking_type(obj.value);
                                }

                            }}
                            options={[
                                {
                                    text: "B2B",
                                    value: "b2b",
                                },
                                {
                                    text: "B2C",
                                    value: "b2c",
                                },
                            ]}
                            freeSolo
                            blurOnSelect
                            aria-required
                            getOptionLabel={(option: any) => option.text}
                            disableClearable
                            disableCloseOnSelect
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Booking Type"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="ticketstatus"
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    setTicketStatus(obj.value);
                                }

                            }}
                            options={[
                                {
                                    text: "Open",
                                    value: "open",
                                },
                                {
                                    text: "Closed",
                                    value: "closed",
                                },
                                {
                                    text: "Pending",
                                    value: "pending",
                                },
                            ]}
                            freeSolo
                            blurOnSelect
                            aria-required
                            getOptionLabel={(option: any) => option.text}
                            disableClearable
                            disableCloseOnSelect
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Ticket Status"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="ticketstart_date"
                            type="date"
                            value={ticketstart_date}
                            className="input"
                            label="Ticket Closed Start Date"
                            variant="outlined"
                            onChange={(e: any) => setTicketStartDate(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="ticketend_date"
                            type="date"
                            value={ticketend_date}
                            className="input"
                            label="Ticket Closed End Date"
                            variant="outlined"
                            onChange={(e: any) => setTicketEndDate(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="createdstart_date"
                            type="date"
                            value={createdstart_date}
                            className="input"
                            label="Created Start Date"
                            variant="outlined"
                            onChange={(e: any) => setCreatedStartDate(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="createdend_date"
                            type="date"
                            value={createdend_date}
                            className="input"
                            label="Created End Date"
                            variant="outlined"
                            onChange={(e: any) => setCreatedEndDate(e.target.value as string)}
                            style={{ width: "100%" }}

                        />
                    </Grid>

                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={filterBookings}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => history.push("/dashboard/sla/doctor_consultation")}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <br />
                <div className={classes.buttonContainer}>
                    {/* <Button
            onClick={handlePendingTicket}
            variant="contained"
            color={bColor === "open" ? "default" : "default"}
            style={{ marginLeft: "1rem" }}
          >
            Pending Ticket
          </Button>
          <Button
            onClick={handleCloseTicket}
            variant="contained"
            color={bColor === "closed" ? "primary" : "default"}
            style={{ marginLeft: "1rem" }}
          >
            Closed Ticket
          </Button> */}
                    {/* <Button fullWidth onClick={handleWip} variant="contained" color={bColor === "wip" ? "primary" : "default"} style={{ marginLeft: "1rem" }}>Wip Ticket</Button>s */}
                </div>
                {loading ? (
                    <Loader />
                ) : (
                    bookingList.results &&
                    bookingList.results.length > 0 && (
                        <Box>
                            <Container maxWidth={false}>
                                <Grid container spacing={3}>
                                    <DoctorConsultation
                                        openModal={openModal}
                                        setopenModal={setopenModal}
                                        page={page}
                                        setPage={setPage}
                                        loading={loading}
                                        getBookings={getDoctorBookings}
                                        bookingList={bookingList}
                                        setBookingId={setBookingid}
                                        bookingId={bookingId}
                                        setTicketId={setTicketId}
                                        setDocDisOpen={setDocDisOpen}
                                    />
                                </Grid>
                            </Container>
                        </Box>
                    )
                )}
                {bookingList.results && bookingList.results.length === 0 && (
                    <h6 style={{ textAlign: "center" }}>No Data Found</h6>
                )}
            </main>
            <BookingCommentsModal
                openModal={openModal}
                setopenModal={setopenModal}
                bookingId={bookingId}
            />
            <DoctorCusonltancyDisposition
                docDisOpen={docDisOpen}
                setDocDisOpen={setDocDisOpen}
                ticketId={ticketId}
            />
        </>
    );
};

const mapStateToProps = (state: any) => ({
    agentsList: state.SalesLeadAdminReducer.agentsList,
    partnerList: state.SalesLeadAdminReducer.partnerList,
    bookingList: state.SalesLeadAdminReducer.bookingList,
    cities: state.SalesLeadAdminReducer.cities,
    loading: state.SalesLeadAdminReducer.loading,
    lead_source: state.SalesLeadAdminReducer.lead_source,
});

export default connect(mapStateToProps, {
    getAgentList,
    getPartners,
    getCities,
    getLeadSource,
    getDoctorBookings
})(CustomerLeadFilterPage);
