import "./index.sass";
import React, { useState } from "react";
import {
  createStyles,
  Grid,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core";
import { Button, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import {
  getAssignedUnassignedBooking,
  getZone,
  getMapMyIndiaAccessToken,
  getCities,
  getMapBookings,
  getPhleboCurrentLocation
} from "../../actions/salesLeadAdminAction";
import { useEffect } from "react";
import { useRef } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { generateBookingFilterUrl } from "../../../helpers/generateUrl";
import fetchJsonp from "fetch-jsonp";


const position = [51.505, -0.09];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    tableContainer: {
      marginTop: "2rem",
      top: "200px",
    },
    mapCSS: {
      height: "700px",
      width: "100%"
    }
  })
);

interface ViewBookingOnMapProps {
  getAssignedUnassignedBooking: any;
  allBookings: any;
  getZone: any;
  zoneList: any;
  loading: any;
  getMapMyIndiaAccessToken: any;
  mapmyindiaAccessToken: any;
  getCities: any;
  cities: any;
  getMapBookings: any;
  mapBooking: any;
  getPhleboCurrentLocation: any;
  phleboCurrentLocation: any;
  cityId:any;
}

const ViewBookingOnMap: React.FC<ViewBookingOnMapProps> = ({
  getAssignedUnassignedBooking,
  allBookings,
  getZone,
  zoneList,
  loading,
  getMapMyIndiaAccessToken,
  mapmyindiaAccessToken,
  getCities,
  cities,
  getMapBookings,
  mapBooking,
  getPhleboCurrentLocation,
  phleboCurrentLocation,
  cityId
}) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    getCities();
    getZone();
    getMapMyIndiaAccessToken();
    // const todayDate = new Date().toISOString().slice(0, 10)
    // getAssignedUnassignedBooking(`multiple_city=1&collection_date=${todayDate}`);
    return () => {
      getAssignedUnassignedBooking("", true);
    };
  }, []);

  const [city, setCity] = useState(0);
  const [collection_date, setCollection_date] = useState("");

  const [bookings, setBookings] = useState<any>([]);
  const [phlebos, setPhlebos] = useState<any>([]);
  const [zoneName, setZoneName] = useState<string>("none");
  const [phleboIds, setPhleboIds] = useState<any>([])
  const [accessToken, setAccessToken] = useState<any>("");
  const [mapMyIndiaLiveResponse,setMapMyIndiaLiveResponse]=useState<any>([])
  const timer = useRef<any>(0);
  let mapData: any

  useEffect(() => {
    let tempBookings: any = [];
    let tempPhlebos: any = [];
    Object.keys(allBookings).map((el: any) => {
      if (el !== "phlebos") {
        tempBookings.push(allBookings[el]);
      } else {
        tempPhlebos.push(...allBookings[el]);
      }
    });
    setBookings(tempBookings.filter((item: any) => item !== undefined));
    setPhlebos(tempPhlebos.filter((item: any) => item !== undefined));
  }, [allBookings]);
  useEffect(() => {
    if (mapmyindiaAccessToken?.accesstoken) {
      setAccessToken(mapmyindiaAccessToken?.accesstoken)
    }
  }, [mapmyindiaAccessToken]);

  useEffect(()=>{
      if(cityId!==""){
        getPhleboCurrentLocation(`?city=${cityId}`)
      }
  },[])

  const loadExternalScript = (token: any, call: any) => {
    var mapurl =
      `https://apis.mapmyindia.com/advancedmaps/api/` +
      token +
      `/map_sdk?layer=vector&v=2.0`;
    const scriptElement = document.createElement("script");
    document.head.appendChild(scriptElement);
    scriptElement.onload = () => {
      return call(true);
    };
    scriptElement.id = "mapScript";
    scriptElement.src = mapurl;
    scriptElement.async = true;
    scriptElement.defer = true;
  };




  const getPhleboLiveLocation = async () => {
    let config = {
      headers: {
        'Authorization': `Bearer ${accessToken}`,

      },
    }
    axios.get(`https://intouch.mapmyindia.in/iot/api/devices/?id=${phleboIds.join(",")}`, config)
      .then(function (response: any) {
        setMapMyIndiaLiveResponse(response)

      })
      .catch(function (error: any) {
        
      })

  }
  useEffect(() => {
    if (phleboIds.length!==0) {
      getPhleboLiveLocation();
    }
  }, [phleboIds])

  useEffect(()=>{
    const phleboId:any=[]
    phleboCurrentLocation && phleboCurrentLocation?.phlebos && phleboCurrentLocation?.phlebos.length > 0&&phleboCurrentLocation?.phlebos.map((data:any)=>{
        if(data.mapmyindiaid!==null){
            phleboId.push(data.mapmyindiaid)
        }
    })
    setPhleboIds(phleboId)
  },[phleboCurrentLocation])
  useEffect(() => {
    if (mapmyindiaAccessToken?.accesstoken !== undefined) {
      loadExternalScript(mapmyindiaAccessToken?.accesstoken, (data: any) => {
        if (data) {
          if (window.hasOwnProperty("MapmyIndia")) {
            mapData = window['MapmyIndia'].Map("tempMap", {
              center: [28.627941, 77.37493],
              zoom: 4,
              zoomControl: true,
            });
            mapData.on("load", mapMarketDisplay)
          }
        }
      });

    }
  }, [mapmyindiaAccessToken?.accesstoken, phleboCurrentLocation,phleboIds,mapMyIndiaLiveResponse]);


  const mapMarketDisplay = () => {
    window['MapmyIndia'].Polyline({
      map: mapData,
      // editable:JSON.parse(edit),
      path: eval(phleboCurrentLocation && phleboCurrentLocation?.geofancearea && phleboCurrentLocation?.geofancearea.length > 0 && phleboCurrentLocation.geofancearea),
      strokeColor: "#9c27b0",
      strokeOpacity: 1.0,
      strokeWeight: 4,
      fitbounds: true,
      lineGap: 0,
      fitboundOptions: { padding: 120, duration: 1000 },
      popupHtml: "route 1",
      popupOptions: { offset: { 'bottom': [0, -20] } }
    })
   

    {
      mapMyIndiaLiveResponse && mapMyIndiaLiveResponse?.data && mapMyIndiaLiveResponse?.data?.data&&mapMyIndiaLiveResponse?.data?.data.length>0&&mapMyIndiaLiveResponse?.data?.data.map((loc: any) => (
        window['MapmyIndia'].addMarker({
          map: mapData,
          position: { "lat": Number(loc?.location?.latitude), "lng": Number(loc?.location?.longitude) },
          fitbounds: false,
          fitboundOptions: { padding: 120, duration: 1000, maxZoom: 12 },
          width: "35px",
          height: "45px",
          icon: "https://cdnjs.cloudflare.com/ajax/libs/emojione/2.2.7/assets/svg/1f3cd.svg",
          popupOptions: { "openPopup": false },
          popupHtml: `<div className="cards">
          <div className="card_child">
              <span className="spans">Phlebo Name :${loc?.deviceDetails?.name && loc?.deviceDetails?.name} </span>
          </div>  
          </div>`,
          draggable: false
        })
      ))
    }
  }

  return (
    <main className={classes.content}>
      <div className={classes.tableContainer}>
        <div>
          <div id="tempMap" className={classes.mapCSS}></div>
        </div>
      </div>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  allBookings: state.SalesLeadAdminReducer.allBookings,
  loading: state.SalesLeadAdminReducer.loading,
  zoneList: state.SalesLeadAdminReducer.zoneList,
  cities: state.SalesLeadAdminReducer.cities,
  mapmyindiaAccessToken: state.SalesLeadAdminReducer.mapmyindiaAccessToken,
  mapBooking: state.SalesLeadAdminReducer.mapBooking,
  phleboCurrentLocation: state.SalesLeadAdminReducer.phleboCurrentLocation,
});

export default connect(mapStateToProps, {
  getAssignedUnassignedBooking,
  getZone,
  getMapMyIndiaAccessToken,
  getCities,
  getMapBookings,
  getPhleboCurrentLocation
})(ViewBookingOnMap);
