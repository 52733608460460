import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import MessageIcon from "@material-ui/icons/Message";
import CallIcon from "@material-ui/icons/Call";
import {
  clickToCall,
  getLeadSource,
  getTopCfreshLeads,
  getCities,
  getPrescriptionData,
} from "../actions/bookingActions";
import { TableFooter, TablePagination } from "@material-ui/core";
import Loader from "../../CustomerLead-Panel/components/Loader2";
import CommentsModal from "../components/Comments/comment";
import CommentsModal2 from "../components/Comments/Comment2";
import { Button } from "@material-ui/core";
import { generateLeadsUrl } from "../../helpers/generateUrl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RaiseComplaintModal from "../components/Comments/raiseTicketModal";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { CALL_DISABLE_TIME } from "../../CallTimeDisable";

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  tableContainer: {
    marginTop: "1rem",
    position: "sticky",
    top: "200px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

interface Props {
  getTopCfreshLeads: Function;
  topCfreshLeads: any;
  lead_source: any;
  loading: boolean;
  // resendSms: Function;
  clickToCall: Function;
  getLeadSource: any;
  getCities: any;
  cities: any;
  getPrescriptionData: any;
  prescription: any;
  callButton: any;
  setCallButton: any;
}

const LeadsTable: React.FC<Props> = ({
  getTopCfreshLeads,
  topCfreshLeads,
  clickToCall,
  getLeadSource,
  lead_source,
  getCities,
  cities,
  loading,
  getPrescriptionData,
  prescription,
  callButton,
  setCallButton,
}) => {
  const classes = useStyles();
  const [open2, setOpen2] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [raiseComplaint, setRaiseComplaint] = useState(false);

  const [leadId, setLeadId] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    50 || topCfreshLeads.results.count
  );
  const [prscriptionLead, setPrescriptionLead] = useState<any>("");
  const [pLink, setPlink] = useState<any>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openPrescription = Boolean(anchorEl);
  const [fileType, setFileType] = useState<any>("");
  const [openPdf, setOpenPdf] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    let url = ``;
    getTopCfreshLeads("?multiple_category=11&latest_size=10&latest=true");
    // getLeadSource()
    // getCities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (open2 === false) {
      setLeadId(0);
    }
  }, [open2]);

  const handleClick = (id: number) => {
    setOpen(true);
    setLeadId(id);
  };

  const handleModal = (id: number) => {
    setOpen2(true);
    setLeadId(id);
  };

  const handleCallClick = (id: number, type: string) => {
    setLeadId(id);
    setCallButton(false);
    clickToCall(id, type);
    setTimeout(() => {
      setCallButton(true);
    }, CALL_DISABLE_TIME);
  };

  const handleRaiseTicket = (id: number) => {
    setRaiseComplaint(true);
    setLeadId(id);
  };
  useEffect(() => {
    if (prscriptionLead !== "") {
      getPrescriptionData(`?lead=${prscriptionLead}`);
    }
  }, [prscriptionLead]);

  const handlePrescriptionClick = (
    id: any,
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setPrescriptionLead(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
    getTopCfreshLeads("?multiple_category=11&latest_size=10&latest=true");
  };
  const handleFrameView = (data: any) => {
    if (data.base64file !== null && data.image === "") {
      setFileType("b64");
      setPlink(`data:application/pdf;base64,${data.base64file}`);
    } else if (data.base64file === null && data.image !== "") {
      let pieces: any = data.image.split(".").pop();
      const last: any = pieces;
      setFileType(last);
      setPlink(data.image);
    }
    setOpenPdf(true);
  };
  const handlePdfModelClose = () => {
    setOpenPdf(false);
  };
  useEffect(() => {
    if (!open2) {
      getTopCfreshLeads("?multiple_category=11&latest_size=10&latest=true");
    }
  }, [open2]);
  return (
    <>
      <div className="data-table" style={{ width: "100%" }}>
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ height: "500px" }}
        >
          {loading ? (
            <Loader />
          ) : (
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Lead ID</StyledTableCell>
                  <StyledTableCell align="center">Created At</StyledTableCell>
                  <StyledTableCell align="center">Comments</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                  {/* <StyledTableCell align="center">
                    Lead Source Url
                  </StyledTableCell> */}
                  {/* <StyledTableCell align="center">Agent</StyledTableCell> */}
                  <StyledTableCell align="center">Call</StyledTableCell>
                  {/* <StyledTableCell align="center">
                    Contact number
                  </StyledTableCell> */}
                  <StyledTableCell align="center">Patient name</StyledTableCell>
                  <StyledTableCell align="center">City</StyledTableCell>
                  <StyledTableCell align="center">Patient Area</StyledTableCell>
                  <StyledTableCell align="center">Call status</StyledTableCell>
                  <StyledTableCell align="center">Language</StyledTableCell>
                  <StyledTableCell align="center">Edit</StyledTableCell>
                  <StyledTableCell align="center">Raise Ticket</StyledTableCell>
                  <StyledTableCell align="center">Prescription</StyledTableCell>
                  <StyledTableCell align="center">Priority</StyledTableCell>
                  <StyledTableCell align="center">Call Count</StyledTableCell>
                  <StyledTableCell align="center">New Booking</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {topCfreshLeads.count > 0
                  ? topCfreshLeads.results.map((row: any) => (
                      <StyledTableRow
                        key={row.id}
                        // style={{
                        //   background: setColor(row.appt_status),
                        // }}
                      >
                        <StyledTableCell component="th" scope="row">
                          {row.id}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {new Date(row.created_at).toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div>
                            <MessageIcon onClick={() => handleClick(row.id)} />
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div>
                            <MessageIcon onClick={() => handleModal(row.id)} />
                          </div>
                        </StyledTableCell>
                        {/* <StyledTableCell align="center">
                          {row.lead_source_url ? row.lead_source_url : "NA"}
                        </StyledTableCell> */}
                        {/* <StyledTableCell align="center">
                          {row.panel_user ? row.panel_user : "NA"}
                        </StyledTableCell> */}
                        <StyledTableCell align="center">
                          {/* <CallIcon
                          onClick={() => handleCallClick(row.id, "call")}
                        /> */}
                          <Button
                            disabled={!callButton}
                            className="addDeck"
                            variant="contained"
                            color="primary"
                            onClick={() => handleCallClick(row.id, "call")}
                            startIcon={<CallIcon />}
                          ></Button>
                        </StyledTableCell>
                        {/* <StyledTableCell align="center">
                         
                          {row.content_number ? (
                            <Button
                              disabled={!callButton}
                              className="addDeck"
                              variant="contained"
                              color="primary"
                              onClick={() => handleCallClick(row.id, "contact")}
                              startIcon={<CallIcon />}
                            ></Button>
                          ) : null}
                        </StyledTableCell> */}
                        <StyledTableCell align="center">
                          {row.patient_name ? row.patient_name : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.patient_city}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.patient_area ? row.patient_area : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.call_status}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.language ? row.language : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Link to={`/dashboard/ce/fill-info?id=${row.id}`}>
                            <EditIcon />
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button
                            variant={!raiseComplaint ? "contained" : "outlined"}
                            color="secondary"
                            onClick={() => handleRaiseTicket(row.id)}
                          >
                            <ConfirmationNumberIcon />
                          </Button>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.is_prescription ? (
                            <Button
                              aria-controls="fade-menu"
                              aria-haspopup="true"
                              onClick={(event: any) =>
                                handlePrescriptionClick(row.id, event)
                              }
                            >
                              <NoteAddIcon />
                            </Button>
                          ) : (
                            "NA"
                          )}
                          <Menu
                            id="fade-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={openPrescription}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                          >
                            {prescription &&
                              prescription?.results &&
                              prescription?.results?.length > 0 &&
                              prescription?.results.map((data: any) => {
                                let pieces: any = data.image.split(".").pop();
                                const last: any = pieces;
                                return (
                                  <>
                                    {data?.base64file !== null &&
                                    data.image === "" ? (
                                      <MenuItem
                                        onClick={() => handleFrameView(data)}
                                      >
                                        Name :{data?.name}, Age:{data?.age},
                                        Created Date :
                                        {new Date(
                                          data.created_at
                                        ).toLocaleString()}
                                      </MenuItem>
                                    ) : data?.base64file === null &&
                                      data.image !== "" ? (
                                      last === "png" ||
                                      last === "jpg" ||
                                      last === "jpeg" ? (
                                        <MenuItem>
                                          <a
                                            href={data?.image}
                                            rel="noreferrer noopener"
                                            target="_blank"
                                          >
                                            Name :{data?.name}, Age:{data?.age},
                                            Created Date :
                                            {new Date(
                                              data.created_at
                                            ).toLocaleString()}
                                          </a>
                                        </MenuItem>
                                      ) : (
                                        <MenuItem>
                                          <a
                                            href={data?.image}
                                            rel="noreferrer noopener"
                                            target="_blank"
                                          >
                                            Name :{data?.name}, Age:{data?.age},
                                            Created Date :
                                            {new Date(
                                              data.created_at
                                            ).toLocaleString()}
                                          </a>
                                        </MenuItem>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              })}
                          </Menu>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.priority}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.count}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                        <Link to={`/dashboard/ce/booking/?lead=${row?.id}`}>
                          <Button
                            color="primary"
                            variant="contained"
                            size="small"
                          >
                            New Booking
                          </Button>
                        </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  : "NA"}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openPdf}
          onClose={handlePdfModelClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openPdf}>
            <>
              {fileType === "pdf" ? (
                <iframe
                  style={{ width: "80%", height: "750px" }}
                  src={pLink}
                  title="title"
                >
                  Presss me: <a href={pLink}>Download PDF</a>
                </iframe>
              ) : fileType === "b64" ? (
                <iframe
                  src={pLink}
                  style={{ width: "80%", height: "750px" }}
                  title="Iframe Example"
                ></iframe>
              ) : (
                ""
              )}
            </>
          </Fade>
        </Modal>
      </div>
      <CommentsModal open={open} setOpen={setOpen} leadId={leadId} />
      <CommentsModal2
        open={open2}
        getCities={getCities}
        cities={cities}
        setOpen={setOpen2}
        leadId={leadId}
      />
      <RaiseComplaintModal
        open={raiseComplaint}
        setOpen={setRaiseComplaint}
        leadId={Number(leadId)}
        bookingId={0} booking={undefined} totalPrice={0}      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  topCfreshLeads: state.BookingReducer.topCfreshLeads,
  lead_source: state.BookingReducer.lead_source,
  cities: state.BookingReducer.cities,
  loading: state.BookingReducer.loading,
  prescription: state.BookingReducer.prescription,
});

export default connect(mapStateToProps, {
  getTopCfreshLeads,
  clickToCall,
  getLeadSource,
  getCities,
  getPrescriptionData,
})(LeadsTable);
