import React, { useState, useEffect } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Grid, TextField,withStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import { useRef } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../components/Loader2';
import moment from 'moment';
import { MessageRounded } from '@material-ui/icons';
import { getEmailDetails } from '../actions/bookingActions'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from "react-router";
import { generateEmailFilterUrl } from "../../helpers/generateUrl";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        listStyle: {
            marginLeft: "20px",
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);

const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,fontWeight:600
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);
interface Props {
    getEmailDetails: any,
    emailDetails: any,
    loading: Boolean;
    emailLoader: boolean;
}

const BookingsTable: React.FC<Props> = ({
    getEmailDetails,
    emailDetails,
    loading,
    emailLoader
}) => {
    const classes = useStyles()
    useEffect(() => {
        getEmailDetails()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const history = useHistory()
    const [bookingId, setBookingId] = useState<any>("")
    const [email, setEmail] = useState("")
     const [page, setPage] = useState(0);
    const filterBookings = () => {
        const body: any = {
            booking:bookingId,
            email,
        }
        const url = generateEmailFilterUrl(body).substring(2)
        getEmailDetails(`${url}`)
        // setPage(0)
    }
       const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = emailDetails.links && emailDetails.links.next.split("?")[1];
            getEmailDetails(url);
        } else if (newPage < page) {
            let url = emailDetails.links && emailDetails.links.previous.split("?")[1];
            getEmailDetails(url);
        }
        setPage(newPage as number);
    };
    return (
        <div style={{ width: "100%" }} className="data-table">
            <div className={classes.toolbar} />
            <h2>Email Details</h2>
            <Grid container spacing={1} direction="row">
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        className="input"
                        name="name"
                        type="number"
                        label="Booking Id"
                        value={bookingId}
                        variant="outlined"
                        onChange={(e) => setBookingId(e.target.value)}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        className="input"
                        name="email"
                        type="text"
                        label="Email"
                        value={email}
                        variant="outlined"
                        onChange={(e) => setEmail(e.target.value as string)}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <Button
                        variant="contained"
                        color="secondary"

                        fullWidth
                    onClick={filterBookings}
                    >
                        Filter
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <Button
                        variant="contained"
                        color="primary"

                        fullWidth
                        onClick={() => history.push("/dashboard/cl/lead/emailDetails")}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
            <Paper elevation={15} className={classes.paper}>
            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "740px" }}>
                    {emailLoader ? <Loader /> : (
                          <Table stickyHeader aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Booking Id</StyledTableCell>
                                    <StyledTableCell align="center">Created At</StyledTableCell>
                                    <StyledTableCell align="center">Email Id</StyledTableCell>
                                    <StyledTableCell align="center">Message</StyledTableCell>
                                    {/* <StyledTableCell align="center">SMS Status</StyledTableCell> */}
                                    <StyledTableCell align="center">Delivered At</StyledTableCell>
                                    <StyledTableCell align="center">View</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    emailDetails.results && emailDetails.results.length > 0 && emailDetails.results.map((data: any, index: any) => {
                                        return (
                                            <StyledTableRow key={index}>

                                                <StyledTableCell style={{ height: "50px" }} align="center">{data.booking.pk}</StyledTableCell>
                                                <StyledTableCell style={{ height: "50px" }} align="center">{new Date(data.created_at).toLocaleString()}</StyledTableCell>
                                                <StyledTableCell style={{ height: "50px" }} align="center">{data.email}</StyledTableCell>
                                                <StyledTableCell style={{ height: "50px" }} align="center">{data.message}</StyledTableCell>
                                                {/* <StyledTableCell style={{height:"50px"}} align="center">{data.sms_status}</StyledTableCell> */}
                                                <StyledTableCell style={{ height: "50px" }} align="center">{data.delivered_at}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Link to={`/dashboard/cl/booking-view/${data.booking.pk}`} style={{ textDecoration: "none" }}>
                                                        <Button variant="contained" color="secondary">View</Button>
                                                    </Link>
                                                </StyledTableCell>

                                            </StyledTableRow>
                                        )
                                    })}
                            </TableBody>
                            <TableFooter>
                                <StyledTableRow>
                                    <TablePagination
                                      rowsPerPageOptions={[]}
                                        colSpan={30}
                                        count={emailDetails.count || 0}
                                        rowsPerPage={emailDetails.page_size||0}
                                        page={page}
                                        onPageChange={handleChangePage}
                                    />
                                </StyledTableRow>
                            </TableFooter>
                        </Table>
                    )}
                </TableContainer>
            </Paper>

        </div>
    )
}

const mapStateToProps = (state: any) => ({
    emailDetails: state.CustomerLeadReducer.emailDetails,
    emailLoader: state.CustomerLeadReducer.emailLoader,
    loading: state.CustomerLeadReducer.loading,
})

export default connect(mapStateToProps, {
    getEmailDetails,

})(BookingsTable);

