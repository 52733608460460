export const ACTIONS = {
    CHANGE_PASSWORD: "change_password",
    GET_PIN_CODE: "cc/get_pin_code",
    GET_CITIES: "cc/get_cities",
    GET_AREA: "cc/get_area",
    GET_AGENTS: "cc/get_agents",
    GET_AGENTS_BY_USERGROUP: "cc/get_agents_by_usergroup",
    GET_PACKAGES: "cc/get_packages",
    GET_PHLEBOS: "cc/get_phlebos",
    GET_PARTNERS: "cc/get_partners",
    GET_COUPONS: "cc/get_coupons",
    CREATE_BOOKING: "cc/create_booking",
    UPDATE_BOOKING: "cc/update_booking",
    GET_BOOKING_LIST: "cc/get_booking_list",
    GET_LEAD_DETAILS: "cc/get_lead_details",
    GET_LEAD_BY_ID: "cc/get_lead_by_id",
    LEAD_DETAILS_RESET: "cc/lead_details_reset",
    GET_LEADS: "cc/get_leads",
    GET_BOOKING_BY_ID: "cc/get_booking_by_id",
    CLICK_TO_CALL: "cc/click_to_call",
    GET_POSTIVE_CALLS_COMMENTS: "cc/get_postive_calls_commnets",
    ADD_POSTIVE_CALLS_COMMENT: "cc/add_comments",
    SET_COMMENTS: "cc/set_commnets",
    UPLOAD_FILE: "cc/upload",
    SET_LOADING: "cc/set_loading",
    SEARCH_CALL: "cc/serach",
    BOOKING_RESET: "cc/booking_reset",
    ADD_COMMENT: "positive_calls/add_comment",
    CREATE_CALL: "cc/createcall",
    GET_LEAD_SOURCE: "cc/getleadsource",
    GET_RECORDINGS: "cc/get_recordings",
    GET_BOOKINGS_COMMENTS: "cc/get_bookings_commnets",
    ADD_BOOKINGS_COMMENT: "cc/add_bookings_comments",
    GET_WHATSAPP_COMMENTS: "cc/get_whatsapp_comments",
    GET_WHATSAPP_COMMENTS_BY_LEAD: "cc/get_whatsapp_comments_by_lead",
    SEND_WHATSAPP_COMMENTS: "cc/send",
    GET_CALL_STATS: "cc/get_call_stats",
    RAISE_COMPLAINT: "cc/raise_complaint",
    GET_COMPLAINTS: "cc/get_complaints",
    ASSIGN_DOCTOR: "cc/assign_doctor",
    SEND_FEEDBACK: "cc/send_feedback",
    GET_AVAILABLE_SLOTS: "cc/get_available_slots",
    GET_ALL_LEADS: "cc/get_all_leads",
    LEAD_TRANSFER_BY_COMMA: "cc/set_comments",
    GET_PANEL_USERS: "cc/get_panel_user",
    GET_CLIENT_LEDGER_DETAILS: "cc/get_client_ledger_details/",
    GET_CENTER_INFORMATION: "cc/get_center_information",
    GET_TIMESLOTS: "cc/get_timeslot",
    CREATE_ADDITIONAL_BOOKING: "cc/create_additional_booking",
    UPDATE_ADDITIONAL_BOOKING: "cc/update_additional_booking",
    CREATE_IMAGING_BOOKING: "cc/CREATE_IMAGING_BOOKING",
    GET_COLLECTION_SLOT: "cc/get_collection_slot",
    GET_QRCODE_DATA: "cc/get_qrcode_data",
    GET_PACKAGE_TUBE_DETAILS: "cc/get_package_tube_details",
    GET_PAYMENT_INFORMATION: "cc/get_payment_information",
    GET_PAYMENT_SEND_LINK:"cc/get_payment_send_link",
    GET_SUB_CENTER_INFORMATION:"cc/get_sub_center_information",
    CHECK_GEOFENCE_AREA: "cc/checking-geofence-area",
    GET_RED_COIN:"cc/get_red_coins",
    GET_IMAGING_TIMESLOTS:"cc/get_imaging_time_slots",
    GET_MULTIPLE_PACKAGES: "cc/get_multiple_package",
    GET_DISPLAY_CITIES:"cc/get_display_city",
    CHECK_GEOFENCE_AREA_MAMPMYINDIA: "cc/checking-geofence-area-mapmyindia",
    GET_MAMPMYINDIA_ACCESS_TOKEN: "cc/get-mapmyindia-access-token",
    POST_CUSTOMER_REVIEW:"cc/post_customer_review",
    GET_TICKET_DATA: "cc/get_ticket_data",
    GET_TICKET_CATEGORIES: "cc/get_ticket_category",
    GET_TICEKT_SUB_CATEGORIES: "cc/get_ticket_categories",
    CREATE_TASK_TICKET: "cc/create_task_ticket",
    GET_RED_TECH_COMMENT: "cc/redtech_comments/",
    POST_RED_TECH_COMMENT: "cc/postredtech_comments/"

};

interface GetPinCode {
    type: typeof ACTIONS.GET_PIN_CODE;
    payload: Array<any>;
}
interface GetMapMyIndiaAccessToken {
    type: typeof ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN
    payload: Array<any>
}
interface PostCustomerReview {
    type: typeof ACTIONS.POST_CUSTOMER_REVIEW
    payload: Array<any>
}
interface CheckGeoFenceAreaMapMyIndia {
    type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA
    payload: Array<any>
}
interface GetDisplayCities {
    type: typeof ACTIONS.GET_DISPLAY_CITIES;
    payload: Array<any>;
}
interface GetPaymentLink {
    type: typeof ACTIONS.GET_PAYMENT_SEND_LINK;
    payload: Array<any>;
}
interface GetImagingTimeSlots {
    type: typeof ACTIONS.GET_IMAGING_TIMESLOTS;
    payload: Array<any>;
}
interface GetSubCenterInformation {
    type: typeof ACTIONS.GET_SUB_CENTER_INFORMATION;
    payload: Array<any>;
}
interface GetPackageTubeDetails {
    type: typeof ACTIONS.GET_PACKAGE_TUBE_DETAILS;
    payload: Array<any>;
}
interface GetPaymentInfro {
    type: typeof ACTIONS.GET_PAYMENT_INFORMATION;
    payload: Array<any>;
}
interface GetCollectionSlot {
    type: typeof ACTIONS.GET_COLLECTION_SLOT;
    payload: Array<any>;
}
interface GetQRCodeData {
    type: typeof ACTIONS.GET_QRCODE_DATA;
    payload: Array<any>;
}
interface CreateImagingBooking {
    type: typeof ACTIONS.CREATE_IMAGING_BOOKING;
    payload: boolean;
}
interface CreateAdditionalBooking {
    type: typeof ACTIONS.CREATE_ADDITIONAL_BOOKING;
    payload: Object;
}
interface UpdateAdditionalBooking {
    type: typeof ACTIONS.UPDATE_ADDITIONAL_BOOKING;
    payload: Object;
}
interface GetTimeSlot {
    type: typeof ACTIONS.GET_TIMESLOTS;
    payload: Array<any>;
}
interface ChangePassword {
    type: typeof ACTIONS.CHANGE_PASSWORD;
    payload: Object;
}

interface UploadFile {
    type: typeof ACTIONS.UPLOAD_FILE;
    payload: Object;
}

interface GetCities {
    type: typeof ACTIONS.GET_CITIES;
    payload: Array<any>;
}

interface GetArea {
    type: typeof ACTIONS.GET_AREA;
    payload: Array<any>;
}

interface GetAgents {
    type: typeof ACTIONS.GET_AGENTS;
    payload: Array<any>;
}

interface GetPhlebo {
    type: typeof ACTIONS.GET_PHLEBOS;
    payload: Array<any>;
}

interface SetComments {
    type: typeof ACTIONS.SET_COMMENTS;
    payload: Object;
}

interface GetPositiveCallsComments {
    type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
    payload: Object;
}

interface AddPositiveCallsComments {
    type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
    payload: Object;
}

interface ClickToCall {
    type: typeof ACTIONS.CLICK_TO_CALL;
    payload: Array<any>;
}

interface GetPackages {
    type: typeof ACTIONS.GET_PACKAGES;
    payload: Array<any>;
}

interface GetPartners {
    type: typeof ACTIONS.GET_PARTNERS;
    payload: Array<any>;
}

interface GetCoupons {
    type: typeof ACTIONS.GET_COUPONS;
    payload: Array<any>;
}

interface SetLoading {
    type: typeof ACTIONS.SET_LOADING;
    payload: boolean;
}

interface CreateBooking {
    type: typeof ACTIONS.CREATE_BOOKING;
    payload: boolean;
}

interface UpdateBooking {
    type: typeof ACTIONS.UPDATE_BOOKING;
    payload: boolean;
}
interface GetRedCoins {
    type: typeof ACTIONS.GET_RED_COIN
    payload: Array<any>
  }

interface GetBookings {
    type: typeof ACTIONS.GET_BOOKING_LIST;
    payload: boolean;
}

interface GetBookingById {
    type: typeof ACTIONS.GET_BOOKING_BY_ID;
    payload: boolean;
}

interface GetLeads {
    type: typeof ACTIONS.GET_LEADS;
    payload: boolean;
}

interface GetLeadDetails {
    type: typeof ACTIONS.GET_LEAD_DETAILS;
    payload: boolean;
}

interface GetLeadById {
    type: typeof ACTIONS.GET_LEAD_BY_ID;
    payload: boolean;
}

interface ResetLeadDetails {
    type: typeof ACTIONS.LEAD_DETAILS_RESET;
    payload: boolean;
}

interface GetPinCode {
    type: typeof ACTIONS.GET_PIN_CODE;
    payload: Array<any>;
}
interface ChangePassword {
    type: typeof ACTIONS.CHANGE_PASSWORD;
    payload: Object;
}
interface GetCities {
    type: typeof ACTIONS.GET_CITIES;
    payload: Array<any>;
}

interface GetArea {
    type: typeof ACTIONS.GET_AREA;
    payload: Array<any>;
}
interface GetPhlebo {
    type: typeof ACTIONS.GET_PHLEBOS;
    payload: Array<any>;
}

interface GetAgents {
    type: typeof ACTIONS.GET_AGENTS;
    payload: Array<any>;
}
interface CheckGeoFenceArea {
    type: typeof ACTIONS.CHECK_GEOFENCE_AREA
    payload: Array<any>
}

interface SetComments {
    type: typeof ACTIONS.SET_COMMENTS;
    payload: Object;
}

interface GetPositiveCallsComments {
    type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
    payload: Object;
}

interface AddPositiveCallsComments {
    type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
    payload: Object;
}
interface ClickToCall {
    type: typeof ACTIONS.CLICK_TO_CALL;
    payload: Array<any>;
}

interface GetPackages {
    type: typeof ACTIONS.GET_PACKAGES;
    payload: Array<any>;
}

interface GetPartners {
    type: typeof ACTIONS.GET_PARTNERS;
    payload: Array<any>;
}

interface GetCoupons {
    type: typeof ACTIONS.GET_COUPONS;
    payload: Array<any>;
}

interface SetLoading {
    type: typeof ACTIONS.SET_LOADING;
    payload: boolean;
}

interface CreateBooking {
    type: typeof ACTIONS.CREATE_BOOKING;
    payload: boolean;
}

interface UpdateBooking {
    type: typeof ACTIONS.UPDATE_BOOKING;
    payload: boolean;
}

interface GetBookings {
    type: typeof ACTIONS.GET_BOOKING_LIST;
    payload: boolean;
}

interface GetBookingById {
    type: typeof ACTIONS.GET_BOOKING_BY_ID;
    payload: boolean;
}

interface GetLeads {
    type: typeof ACTIONS.GET_LEADS;
    payload: boolean;
}

interface GetLeadDetails {
    type: typeof ACTIONS.GET_LEAD_DETAILS;
    payload: boolean;
}

interface GetLeadById {
    type: typeof ACTIONS.GET_LEAD_BY_ID;
    payload: boolean;
}

interface ResetLeadDetails {
    type: typeof ACTIONS.LEAD_DETAILS_RESET;
    payload: boolean;
}
interface SearchCall {
    type: typeof ACTIONS.SEARCH_CALL;
    payload: Object;
}
interface SearchCall {
    type: typeof ACTIONS.SEARCH_CALL;
    payload: Object;
}
interface GetAgentByUserGroup {
    type: typeof ACTIONS.GET_AGENTS_BY_USERGROUP;
    payload: Object;
}
interface GetClientLedgerDetails {
    type: typeof ACTIONS.GET_CLIENT_LEDGER_DETAILS;
    payload: Array<any>;
}
interface GetCenterInfo {
    type: typeof ACTIONS.GET_CENTER_INFORMATION;
    payload: Array<any>;
}
interface GetMultiplepackage {
    type: typeof ACTIONS.GET_MULTIPLE_PACKAGES;
    payload: Array<any>;
}
interface GetRedTechComment {
    type: typeof ACTIONS.GET_RED_TECH_COMMENT;
    payload: Array<any>;
  }
  interface GetTicketData {
    type: typeof ACTIONS.GET_TICKET_DATA;
    payload: Object;
  }
  interface GetTicketCategory {
    type: typeof ACTIONS.GET_TICKET_CATEGORIES;
    payload: Object;
  }
  interface GetTicketSubCategories {
    type: typeof ACTIONS.GET_TICEKT_SUB_CATEGORIES;
    payload: Object;
  }
  interface PostRedTechComment {
    type: typeof ACTIONS.POST_RED_TECH_COMMENT;
    payload: Object;
  }
  interface CreateTaskTicket {
    type: typeof ACTIONS.CREATE_TASK_TICKET;
    payload: Array<any>;
  }

export type PhleboAdminTypes =
    | SearchCall
    | GetPinCode
    | ChangePassword
    | GetCities
    | GetArea
    | GetAgents
    | GetPhlebo
    | GetAgentByUserGroup
    | GetPackages
    | CreateBooking
    | UpdateBooking
    | GetPartners
    | GetCoupons
    | GetBookings
    | GetBookingById
    | SetLoading
    | GetLeadDetails
    | GetLeads
    | GetLeadById
    | ResetLeadDetails
    | ClickToCall
    | GetPositiveCallsComments
    | AddPositiveCallsComments
    | SetComments
    | UploadFile
    | GetPinCode
    | ChangePassword
    | GetCities
    | GetArea
    | GetAgents
    | GetPhlebo
    | GetPackages
    | CreateBooking
    | UpdateBooking
    | GetPartners
    | GetCoupons
    | GetBookings
    | GetBookingById
    | SetLoading
    | GetLeadDetails
    | GetLeads
    | GetLeadById
    | ResetLeadDetails
    | ClickToCall
    | GetPositiveCallsComments
    | AddPositiveCallsComments
    | SetComments
    | SearchCall
    | GetClientLedgerDetails
    | GetCenterInfo
    | GetTimeSlot
    | CreateAdditionalBooking
    | UpdateAdditionalBooking
    | CreateImagingBooking
    | GetCollectionSlot
    | GetQRCodeData
    | GetPackageTubeDetails
    | GetPaymentInfro
    | GetPaymentLink
    | GetSubCenterInformation
    | CheckGeoFenceArea
    | GetRedCoins
    | GetImagingTimeSlots
    | GetMultiplepackage
    | GetDisplayCities
    | CheckGeoFenceAreaMapMyIndia
    | GetMapMyIndiaAccessToken
    | PostCustomerReview
    | GetTicketData
    | GetTicketCategory
    | GetTicketSubCategories
    | CreateTaskTicket
    | GetRedTechComment
    | PostRedTechComment