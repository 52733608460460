import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Chip, Paper, withStyles, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Button, } from '@material-ui/core';
import { connect } from 'react-redux';
import Loader from '../Components/loader/index';
import moment from 'moment';
import AdditonalFamilyModal from '../tables/AdditionalFamily/AdditionFamilyDetails';
import { MessageRounded } from '@material-ui/icons';
import CleanHandsIcon from '@mui/icons-material/CleanHands';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,fontWeight:600
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);


interface Props {
    getBookings: any;
    bookingList: any;
    page: any;
    bookingId: any;
    setBookingId: any;
    setPage: any;
    setopenModal: any;
    openModal: any;
    loading: Boolean;
    setTicketId: any;
    setDocDisOpen: any
}

const BookingsTable: React.FC<Props> = ({
    getBookings,
    bookingList,
    page,
    setPage,
    setopenModal,
    bookingId,
    setBookingId,
    openModal,
    loading,
    setTicketId,
    setDocDisOpen
}) => {
    const classes = useStyles()

    // const [bookingId, setBookingId] = useState<number>(0);

    const [additionalMemebr, setAdditionalMemebr] = useState<any>()
    const [additionalModal, setAdditonalModal] = useState<boolean>(false)
    const handleClick = (id: number) => {
        setopenModal(true);
        setBookingId(id);
    };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = bookingList?.links && bookingList?.links?.next.split("?")[1];
            getBookings(url);
        } else if (newPage < page) {
            let url = bookingList?.links && bookingList?.links?.previous.split("?")[1];
            getBookings(url);
        }
        setPage(newPage as number);
    };
    const handleAdditionalView = (member: any) => {
        setAdditionalMemebr(member);
        setAdditonalModal(true)

    }
    const handleDoctorDisposition = (id: any) => {
        setTicketId(id)
        setDocDisOpen(true)
    }

    return (
        <div style={{ width: "100%" }} className="data-table">

            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "580px" }}>
                {loading ? <Loader /> : (
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="center">Action</StyledTableCell>
                                <StyledTableCell align="center">Booking Id</StyledTableCell>
                                <StyledTableCell align="center">Comment</StyledTableCell>
                                <StyledTableCell align="center">Dispose</StyledTableCell>
                                <StyledTableCell align="center">Disposition</StyledTableCell>
                                <StyledTableCell align="center">Booking Date</StyledTableCell>
                                <StyledTableCell align="center">Call-Later Date/Time</StyledTableCell>
                                <StyledTableCell align="center">Doctor Name</StyledTableCell>
                                <StyledTableCell align="center">Name</StyledTableCell>
                                <StyledTableCell align="center">Age</StyledTableCell>
                                <StyledTableCell align="center">Gender</StyledTableCell>
                                <StyledTableCell align="center">Complete Date</StyledTableCell>
                                <StyledTableCell align="center">Feedback</StyledTableCell>
                                <StyledTableCell align="center">Collection Date</StyledTableCell>
                                <StyledTableCell align="center">Collection Time</StyledTableCell>
                                <StyledTableCell align="center">Ticket Created Date</StyledTableCell>
                                <StyledTableCell align="center">Zone Name</StyledTableCell>
                                <StyledTableCell align="center">Report Status</StyledTableCell>

                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {
                                bookingList?.results && bookingList?.results.length > 0 && bookingList?.results.map((booking: any, index: any) => {
                                    const pendingData = booking?.booking?.additional_members.filter((data: any) => data?.pickup_status === "pending")
                                    const confirmedData = booking?.booking?.additional_members.filter((data: any) => data?.pickup_status === "confirmed")

                                    return (
                                        <StyledTableRow key={index} style={{ backgroundColor: booking.feedback !== null ? "#00c853" : "" }}>
                                            <StyledTableCell align="center">
                                                <Link
                                                    to={`/dashboard/sla/booking-view/${booking?.booking?.pk}`}
                                                    style={{ textDecoration: "none" }}
                                                >
                                                    <Button variant="contained" color="secondary">View</Button>
                                                </Link>
                                            </StyledTableCell>
                                            {/* <StyledTableCell align="center">{booking.booking.pk}</StyledTableCell> */}
                                            <StyledTableCell align="center">{booking?.booking?.additional_members.length > 0 ? (
                                                <div>
                                                    {booking?.booking?.pk}+
                                                    <Chip
                                                        label={"T" + booking?.booking?.additional_members.length + `${confirmedData.length > 0 ? "C" + confirmedData.length : ""}` + `${pendingData.length > 0 ? "P" + pendingData.length : ""}`}
                                                        style={{ background: pendingData.length > 0 ? "#e91e63" : "#1b5e20" }}
                                                        onClick={() => handleAdditionalView(booking.booking.additional_members)}
                                                    />
                                                </div>

                                            ) : booking?.booking?.pk}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <div>
                                                    <MessageRounded onClick={() => handleClick(booking.booking.pk)} />
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <div>
                                                    <CleanHandsIcon onClick={() => handleDoctorDisposition(booking?.id)} />
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{booking?.disposition ? booking?.disposition : "NA"}</StyledTableCell>

                                            <StyledTableCell align="center">{booking?.booking?.booking_date}</StyledTableCell>
                                            <StyledTableCell align="center">{booking?.call_later_time !== null ? new Date(booking?.call_later_time).toLocaleString() : "NA"}</StyledTableCell>
                                            <StyledTableCell align="center">{booking?.doctor?.user?.fullname}</StyledTableCell>
                                            <StyledTableCell align="center">{booking?.booking?.customer_name}</StyledTableCell>
                                            <StyledTableCell align="center">{booking?.booking?.customer_age}</StyledTableCell>
                                            <StyledTableCell align="center">{booking?.booking?.customer_gender}</StyledTableCell>
                                            <StyledTableCell align="center">{booking?.completed_date}</StyledTableCell>
                                            <StyledTableCell align="center">{booking?.feedback ? booking.feedback : "NA"}</StyledTableCell>
                                            <StyledTableCell align="center">{booking?.booking?.collection_date}</StyledTableCell>

                                            <StyledTableCell align="center">
                                                {booking?.booking?.collection_slot && moment(`${booking?.booking?.collection_slot.slot.split("-")[0]}`, ["HH.mm.ss"]).format("hh:mm A")}
                                                {" - "}
                                                {booking?.booking?.collection_slot && moment(`${booking?.booking?.collection_slot.slot.split("-")[1]}`, ["HH.mm.ss"]).format("hh:mm A")}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{booking?.created_at !== null ? new Date(booking?.created_at).toLocaleString() : "NA"}</StyledTableCell>

                                            <StyledTableCell align="center">{booking?.booking?.customer_areapincode?.area}</StyledTableCell>
                                            <StyledTableCell align="center"><Chip label={`${booking?.booking?.report_status}`} color="primary" /></StyledTableCell>

                                        </StyledTableRow>
                                    )
                                })}
                        </TableBody>
                        <TableFooter>
                            <StyledTableRow>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    colSpan={3}
                                    count={bookingList?.count || 0}
                                    rowsPerPage={bookingList?.page_size}
                                    page={page}
                                    onPageChange={handleChangePage}
                                />
                            </StyledTableRow>
                        </TableFooter>
                    </Table>
                )}
            </TableContainer>
            {bookingList?.results && bookingList?.results.length === 0 && (
                <h6 style={{ textAlign: "center" }}>No Data Found</h6>
            )}
            {/* <BookingCommentsModal openModal={openModal} setopenModal={setopenModal} bookingId={bookingId} /> */}
            <AdditonalFamilyModal additionalMemebr={additionalMemebr} additionalModal={additionalModal} setAdditonalModal={setAdditonalModal} loading={loading} />

        </div>
    )
}

const mapStateToProps = (state: any) => ({

})

export default connect(mapStateToProps, {

})(BookingsTable);

