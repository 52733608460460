import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, MenuItem, Select, TextField ,Grid} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { sendNewFeedback } from "../../actions/salesLeadAdminAction";
import Autocomplete from "@material-ui/lab/Autocomplete";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

type ModalProps = {
    docDisOpen: boolean;
    setDocDisOpen: Function;
    ticketId:any;
    sendNewFeedback:any
};

const DoctorDisposition: React.FC<ModalProps> = ({
    docDisOpen,
    setDocDisOpen,
    ticketId,
    sendNewFeedback,
}) => {
    const classes = useStyles();
    const [feedback, setFeedback] = useState("")
    const [category, setCategory] = React.useState<any>("");
    const [callDate, setCallDate] = useState("")
    const [callTime, setCallTime] = useState("")

    const CATEGORY: any = [
        {
            id: 1,
            text: "CUSTOMER NOT RESPONDING",
            value:"customer_not_responding",
        },
        {
            id: 2,
            text: "FAULTY REPORTS",
            value:"faulty_reports",
        },
        {
            id: 3,
            text: "RESAMPLING REQUIRED",
            value:"resampling_required",
        },
        {
            id: 4,
            text: "CUSTOMER NOT CONTACTABLE",
            value:"customer_not_contactable",
        },
        {
            id: 5,
            text: "CALL LATER",
            value:"call_later",
            dateTime: true,
        },
        {
            id: 6,
            text: "COMPLETE",
            value:"complete",
            feedback:true
        },
       
    ]
    const handleClose = () => {
        setDocDisOpen(false);
    };
    const bodyData = CATEGORY.filter((data: any) => data.value == category)
    const sendFeedback = () => {
        const body: any = {
            status: category,
            feedback,
            call_later_time:callDate!==""?callDate+" "+callTime:""
        }
        
        if (body['feedback'] === "") {
            delete body['feedback'];
        }
        if (body['call_later_time'] === "") {
            delete body['call_later_time'];
        }
        if(category==="call_later"){
            if(callDate===""){
                alert("Date is Required For Call Later")
            }
            else if(callTime===""){
                alert("Time is Required For Call Later")
            }
            else{
                sendNewFeedback(ticketId, body)
                setFeedback("")
                setCategory("")
                setCallTime("")
                setCallDate("")
                handleClose()
            }
        }
        else if(category==="complete"){
            if(feedback===""){
                alert("Please Write Feedback")
            }
            else{
                sendNewFeedback(ticketId, body)
                setFeedback("")
                setCategory("")
                setCallTime("")
                setCallDate("")
                handleClose()
            }
        }
        else{
            sendNewFeedback(ticketId, body)
            setFeedback("")
            setCategory("")
            setCallTime("")
            setCallDate("")
            handleClose()
        }
       
    }

    let maxDate = new Date().toISOString().slice(0,10);
    let monthValue = maxDate.slice(5,7);
    let monthValueInt = parseInt(monthValue) + 1;
    let monthValueIntoString = monthValueInt === 13 ? "01" : monthValueInt < 10 ? `0${monthValueInt}` : `${monthValueInt}`;
    let yearValue = monthValueInt === 13 ? parseInt(maxDate.slice(0,4)) + 1 : parseInt(maxDate.slice(0,4));

    let maxFinal = `${yearValue}-${monthValueIntoString}-${maxDate.slice(8,10)}`
    
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={docDisOpen}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={docDisOpen}>
                    <div className={classes.paper}>
                        <div className={classes.head}>
                            <p
                                style={{
                                    marginBottom: "1rem",
                                }}
                                id="transition-modal-title"
                            >
                                Select an option
                            </p>
                            <CloseIcon onClick={() => setDocDisOpen(false)} />
                        </div>
                        <TextField className="input"
                            name="action"
                            select
                            variant="outlined"
                            label="Select Disposition"
                            style={{ width: "100%", marginBottom: "1rem" }}
                            onChange={(e) => setCategory(e.target.value)}
                        >
                            {CATEGORY.map((item: any) => {
                                return (
                                    
                                    <MenuItem key={item.id} value={`${item.value}`}>{item.text}</MenuItem>
                                )
                            })}
                        </TextField>
                        {bodyData[0] && bodyData[0].dateTime && (
                    <Grid container spacing={3} direction="row" alignItems="center" justify="center" style={{ margin: "0.5rem" }}>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                className="input"
                                name="call_date"
                                type="date"
                                label={bodyData[0] && bodyData[0].text + " Date"}
                                value={callDate}
                                variant="outlined"
                                inputProps={{ 
                                    min: new Date().toISOString().slice(0,10), 
                                    max: maxFinal
                                }}
                                onChange={(e) => setCallDate(e.target.value as string)}
                                style={{ width: "100%" }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <TextField
                                id="time"
                                label={bodyData[0] && bodyData[0].text + " Time"}
                                type="time"
                                value={callTime}
                                defaultValue="07:30"
                                className={classes.textField}
                                onChange={(e) => setCallTime(e.target.value as string)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 300, // 5 min
                                }}
                            />
                        </Grid>
                    </Grid>
                )}
                {bodyData[0] && bodyData[0].feedback && (
                      <TextField
                      name="feedback"
                      type="text"
                      multiline
                      rows={4}
                      value={feedback}
                      className="input"
                      label="Feedback"
                      variant="outlined"
                      placeholder="Comment"
                      onChange={(e) => setFeedback(e.target.value as string)}
                      style={{ width: "100%", marginBottom: "1rem" }}
                      required
                  />
                )}
                      
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            style={{ height: "50px" }}
                            onClick={sendFeedback}
                            disabled={category===""}
                         
                        >
                            Submit
                        </Button>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {
    sendNewFeedback
})(DoctorDisposition);
