import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { TextField, Grid, Select, MenuItem } from "@material-ui/core";
import Button from '@mui/material/Button';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { updatePanelUser } from '../../actions/HealthAdviserAdminAction';
import { useHistory } from "react-router-dom";
import Loader from '../loader'
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    }
}));
type ModalProps = {
    userDetails: any;
    setOpenUserModal: Function;
    loading: any;
    openUserModal: boolean;
    updatePanelUser: any;

};

const CreatePhlebo: React.FC<ModalProps> = ({
    userDetails,
    setOpenUserModal,
    openUserModal,
    updatePanelUser,
    loading,
}) => {
    const history = useHistory()
    const classes = useStyles();
    const [name, setName] = useState(userDetails && userDetails?.fullname)
    const [username, setUserName] = useState(userDetails && userDetails?.username)
    const [phoneNumber, setPhoneNumber] = useState<String>(userDetails && userDetails?.phonenumber)
    const [email, setEmail] = useState<String>(userDetails && userDetails?.email)
    const [userGroup, setUserGroup] = useState<any>(userDetails && userDetails?.usergroup)
    const [mobileValidation, setMobileValidation] = useState({
        phoneNumber: true,
    })
    const handleClose = () => {
        setOpenUserModal(false);
        history.push("/dashboard/haa/users")
    };

    const verifyMobile = (type: any) => {
        if (type === "mobile") {
            if (parseInt(phoneNumber && phoneNumber[0]) < 6 || phoneNumber && phoneNumber.length < 10) {
                setMobileValidation((prev: any) => ({
                    ...prev,
                    phoneNumber: false,
                }));
            }
        }
    }






    const submitHandler = async () => {
        const body: any = {
            phonenumber: phoneNumber,
            fullname: name,
            usergroup: userGroup,
            email,
            username,
        }
        await updatePanelUser(body, userDetails.phonenumber)
        handleClose()
    };
    // useEffect(()=>{ 
    //     updatePhlebo(phoneNumber)
    // },[phoneNumber])
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openUserModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                {loading ? <Loader /> : (
                    <Fade in={openUserModal}>
                        <div className={classes.paper}>
                            <div className={classes.head}>
                                <h4
                                    style={{
                                        marginBottom: "1rem",
                                    }}
                                    id="transition-modal-title"
                                >
                                    Edit Panel User
                                </h4>
                                <CloseIcon onClick={() => handleClose()} />
                            </div>

                            <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                                <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                    <Grid item xs={12} style={{ padding: "0" }}>
                                        <p>Full Name</p>
                                    </Grid>
                                    <Grid item xs={12} style={{ padding: "0" }}>
                                        <TextField className="input"
                                            name="title"
                                            type="text"
                                            placeholder="Enter FullName"
                                            value={name}
                                            defaultValue={userDetails?.fullname}
                                            variant="outlined"
                                            style={{ margin: "0", width: "100%" }}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                    <Grid item xs={12} style={{ padding: "0" }}>
                                        <p>UserName</p>
                                    </Grid>
                                    <Grid item xs={12} style={{ padding: "0" }}>
                                        <TextField className="input"
                                            name="title"
                                            type="text"
                                            placeholder="Enter Username"
                                            value={username}
                                            defaultValue={userDetails?.username}
                                            variant="outlined"
                                            style={{ margin: "0", width: "100%" }}
                                            onChange={(e) => setUserName(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                    <Grid item xs={12} style={{ padding: "0" }}>
                                        <p>Phone Number</p>
                                    </Grid>
                                    <Grid item xs={12} style={{ padding: "0" }}>

                                        <TextField
                                            className="input"
                                            name="customer_phonenumber"
                                            type="text"
                                            // onWheel={(e: any) => e.target.blur()}
                                            // onInput={(e: any) => {
                                            //     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                            // }}
                                            value={phoneNumber}
                                            placeholder="Enter Mobile Number"
                                            defaultValue={userDetails?.phonenumber}
                                            variant="outlined"
                                            style={{ margin: "0", width: "100%" }}
                                            onChange={(e) =>
                                                setPhoneNumber(e.target.value as String)
                                            }
                                            onBlur={() => verifyMobile("mobile")}
                                            onFocus={() => {
                                                setMobileValidation((prev: any) => ({
                                                    ...prev,
                                                    phoneNumber: true,
                                                }));
                                            }}
                                            helperText={
                                                !mobileValidation.phoneNumber &&
                                                "Incorrect Phone Number"
                                            }
                                            required
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                    <Grid item xs={12} style={{ padding: "0" }}>
                                        <p>Email</p>
                                    </Grid>
                                    <Grid item xs={12} style={{ padding: "0" }}>
                                        <TextField className="input"
                                            name="customer_email"
                                            type="email"
                                            placeholder="Enter Email"
                                            value={email}
                                            variant="outlined"
                                            defaultValue={userDetails.email}
                                            style={{ margin: "0", width: "100%" }}
                                            onChange={(e) => setEmail(e.target.value as String)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                    <Grid item xs={12} style={{ padding: "0" }}>
                                        <p>User Group</p>
                                    </Grid>
                                    <Grid item xs={12} style={{ padding: "0" }}>
                                        <Select className="input"
                                            name="sampleregisterd"
                                            variant="outlined"
                                            value={userGroup}
                                            defaultValue={userDetails.usergroup}
                                            style={{ width: "100%", margin: "0" }}

                                            onChange={(e) => setUserGroup(e.target.value as String)}

                                        >
                                            <MenuItem disabled value={"none"}>User Group</MenuItem>
                                            <MenuItem value={"CustomerExecutive"}>Customer Executive</MenuItem>
                                            <MenuItem value={"SupportExecutive"}>Suport Executtive</MenuItem>
                                            <MenuItem value={"CustomerSupport"}>Customer Support</MenuItem>
                                            <MenuItem value={"VerificationExecutive"}>Verification Executive</MenuItem>

                                        </Select>
                                    </Grid>
                                </Grid>

                                <Grid container item xs={12} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                    <Grid item xs={12} style={{ padding: "0" }}>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            fullWidth
                                            disabled={!mobileValidation?.phoneNumber}
                                            onClick={submitHandler}
                                        >
                                            Update User
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Fade>
                )}

            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, {
    updatePanelUser
})(CreatePhlebo);
