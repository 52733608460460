import React, { useState } from "react";
import { useRef } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import {  Paper, TextField, Grid, Chip,Card } from "@material-ui/core";

import { useEffect } from "react";
import { getRoutes, getRiderList, getCities, getPhlebos } from "../actions/salesLeadAdminAction";
import { useHistory } from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Loader from "../../components/loader/index";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@mui/material/Avatar";
import { TableFooter, TablePagination } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import BarChartTwoToneIcon from "@mui/icons-material/BarChartTwoTone";
import { generateStopsFilter } from "../../helpers/generateUrl";
import moment from "moment";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    table: {
      minWidth: 700,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
      minHeight: "64px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    hoveItem: {
      cursor: "pointer",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    card: {
      height: "100%",
      padding: "20px",
    },
    cardContent: {
      textAlign: "center",
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    text: { color: "black" },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
interface Props {
  getRoutes: any;
  routesData: any;
  getRiderList: any;
  riderList: any;
  getCities: any;
  cities: any;
  getPhlebos: any;
  phleboList: any;
  loading: any;
}
const RoutePlan: React.FC<Props> = ({
  getRoutes,
  routesData,
  getRiderList,
  riderList,
  getCities,
  cities,
  getPhlebos,
  phleboList,
  loading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const timer = useRef<any>(0);
  const [routeName, setRouteName] = useState<any>("");
  const [endRouteName, setendRouteName] = useState<any>("");
  const [page, setPage] = useState(0);
  const [rider, setRider] = useState<any>("");
  const [riderDef, setRiderDef] = useState<any>("");
  const [citys, setCitys] = useState<any>("");
  const [cityNames, setCityNames] = useState<any>("")
  const [phlebo, setPhlebo] = useState<any>("");
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = routesData.links && routesData.links.next.split("?")[1];
      getRoutes(url);
    } else if (newPage < page) {
      let url = routesData.links && routesData.links.previous.split("?")[1];
      getRoutes(url);
    }
    setPage(newPage as number);
  };
  useEffect(() => {
    getRoutes();
    getRiderList(`?usergroup=Rider`);
    getCities();
  }, []);
  const filterStopsData = (e: any) => {
    const body: any = {
      rider,
      city__id: citys,
      name: routeName,
      phlebo,
      // panel_users: phlebo,
      // cc: center,

    };
    const url = generateStopsFilter(body).substring(2);
    getRoutes(url);
    setPage(0);
  };
  const handleSearch = async (e: any) => {
    e.preventDefault();
    // getRoutes(`name=${routeName}`);
  };
  return (
    <div style={{ width: "90%", margin: "60px auto" }}>
      <Grid container spacing={3}>
        <Grid item xs={10}>
          <h3>Route Plan</h3>
        </Grid>
        {/* <Grid item xs={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              history.push(`/dashboard/sla/route_plan_action/create`)
            }
          >
            Create New Route
          </Button>
        </Grid> */}
        <form style={{ width: "100%" }} onSubmit={handleSearch}>
          <Grid container spacing={3} style={{ marginTop: 10 }}>
            <Grid item xs={12} sm={6} md={2}>
              <TextField
                label="Route Name"
                variant="outlined"
                className="input"
                fullWidth
                value={routeName}
                onChange={(e) => setRouteName(e.target.value)}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} md={2}>
              <TextField
                label="Route End Point"
                variant="outlined"
                className="input"
                fullWidth
              value={endRouteName}
              onChange={(e) => setendRouteName(e.target.value)}
              />
            </Grid> */}
            <Grid item xs={12} sm={4} md={2}>
              <Autocomplete
                id="city"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setCitys(obj.id);
                  }
                }}
                inputValue={cityNames}
                loading={loading}
                options={cities || []}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getCities(newInputValue);
                  }, 1000);

                  setCityNames(newInputValue);
                  if (newInputValue === "") {
                    setCitys("");
                  }
                }}
                freeSolo
                blurOnSelect
                getOptionLabel={(option: any) => `${option?.name}`}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className="input"
                    label="City"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Autocomplete
                id="rider"
                key={riderDef}
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setRider(obj.id)
                  }
                }}
                options={riderList?.results || []}
                freeSolo
                blurOnSelect
                defaultValue={riderDef}
                getOptionLabel={(option: any) => option?.id && option?.username}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current)
                  timer.current = setTimeout(() => {
                    getRiderList(`?code=${newInputValue.replace(" ", "")}&usergroup=Rider`);
                  }, 1000)
                  if (newInputValue.length === 0) {
                    setRider("")
                  }
                }}
                renderInput={(params) => (
                  <TextField className="input"
                    {...params}
                    placeholder="Assign Rider"
                    label="Rider"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Autocomplete
                id="phleboName"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setPhlebo(obj.id)
                  }
                }}
                options={phleboList.results ? [...phleboList.results, { id: 0, user: { username: "NoPhlebo" } }] : []}
                freeSolo
                blurOnSelect
                aria-required
                getOptionLabel={(option: any) => option.user && option.user.username}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current)
                  timer.current = setTimeout(() => {
                    getPhlebos(`${newInputValue}`)
                  }, 1000)
                  if (newInputValue.length === 0) {
                    setPhlebo("")
                  }
                }}
                renderInput={(params) => (
                  <TextField className="input"
                    {...params}
                    placeholder="Phlebo Name"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    required
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={4} md={2}>
              <Button
                variant="contained"
                color="secondary"
               
                fullWidth
                onClick={filterStopsData}
              >
                Filter
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <Button
                variant="contained"
                color="primary"
               
                fullWidth
                onClick={() => history.push("/dashboard/sla/route_plan")}
              >
                reset
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
      <Grid container style={{ marginTop: 10 }}>
      <div style={{ width: "100%" }} className="data-table">
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ maxHeight: "540px" }}
        >
          {loading ? (
            <Loader />
          ) : (
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">S. No.</StyledTableCell>
                  <StyledTableCell align="center">Route Name</StyledTableCell>
                  <StyledTableCell align="center">Route Start Point</StyledTableCell>
                  <StyledTableCell align="center">Route End Point</StyledTableCell>
                  <StyledTableCell align="center">Start Time</StyledTableCell>
                  <StyledTableCell align="center">End Time</StyledTableCell>
                  <StyledTableCell align="center">City</StyledTableCell>
                  <StyledTableCell align="center">Colection Center</StyledTableCell>
                  <StyledTableCell align="center">Assigned Rider</StyledTableCell>
                  <StyledTableCell align="center">Assigned phlebos</StyledTableCell>
                  <StyledTableCell align="center">Stops</StyledTableCell>
                  {/* <StyledTableCell align="center">Action</StyledTableCell> */}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {routesData &&
                  routesData.results &&
                  routesData.results?.length > 0 &&
                  routesData.results?.map((row: any, index: number) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">
                          {index + 1}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.source}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.destination}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.start_time}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.end_time}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.city_name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.stops_detail &&
                            row.stops_detail.length > 0 &&
                            row.stops_detail.map((val: any, index1: any) => {
                              return (
                                val?.cc?
                                <Chip
                                  label={
                                    index1 +
                                    1 +
                                    ". " +
                                    val?.cc?.name 
                                  }
                                  variant="outlined"
                                  size="small"
                                />:""
                              );
                            })}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.rider}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row?.stops_detail && row?.stops_detail.map((data: any) => {
                            return (
                              data?.panel_users && data?.panel_users.map((data2: any, index2: any) => {

                                return (
                                  <Chip
                                    label={
                                      index2 +
                                      1 +
                                      ". " +
                                      data2?.user
                                    }
                                    variant="outlined"
                                    size="small"
                                  />
                                )
                              })
                            )
                          })}

                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.stops_detail &&
                            row.stops_detail.length > 0 &&
                            row.stops_detail.map((val: any, index1: any) => {
                              return (
                                <Chip
                                  label={
                                    index1 +
                                    1 +
                                    ". " +
                                    val?.name +
                                    "(" +
                                    val?.time +
                                    ")"
                                  }
                                  variant="outlined"
                                  size="small"
                                />
                              );
                            })}
                        </StyledTableCell>
                        {/* <StyledTableCell align="center">
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() =>
                              history.push(
                                `/dashboard/sla/route_plan_action/${row?.id}`
                              )
                            }
                          >
                            edit
                          </Button>
                        </StyledTableCell> */}
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={3}
                  count={routesData.count}
                  rowsPerPage={routesData.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </TableFooter>
            </Table>
          )}
        </TableContainer>
        </div>
      </Grid>
    </div>


  );
};
const mapStateToProps = (state: any) => ({
  routesData: state.SalesLeadAdminReducer.routesData,
  loading: state.SalesLeadAdminReducer.loading,
  riderList: state.SalesLeadAdminReducer.riderList,
  cities: state.SalesLeadAdminReducer.cities,
  phleboList: state.SalesLeadAdminReducer.phleboList,


});
export default connect(mapStateToProps, {
  getRoutes,
  getRiderList,
  getCities,
  getPhlebos,
})(RoutePlan);
