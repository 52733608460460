import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
  fade,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import StoreIcon from "@material-ui/icons/Store";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  logout,
  panelUserLogout,
  getFirebaseNotification,
} from "../../actions/loginActions";
import { useDispatch } from "react-redux";
import HomeIcon from "@material-ui/icons/Home";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import {
  PhonelinkLock,
  ConfirmationNumberRounded,
  SettingsApplicationsRounded,
  AddBoxTwoTone,
} from "@material-ui/icons";
import {
  ListAltRounded,
  PhoneAndroidRounded,
  WhatsApp,
} from "@material-ui/icons";
import EqualizerRoundedIcon from "@material-ui/icons/EqualizerRounded";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import { Tooltip } from "@material-ui/core";
import {
  changePassword,
  searchCall,
  getUserDetails,
  getNotificationMessage,
} from "../actions/bookingActions";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import Button from '@mui/material/Button';
import CallModal from "../components/call-modal/index";
import HistoryIcon from "@material-ui/icons/History";
import WarningIcon from "@material-ui/icons/Warning";
import TableRowsIcon from "@mui/icons-material/TableRows";
import QuizIcon from "@mui/icons-material/Quiz";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import LeakAddIcon from "@material-ui/icons/LeakAdd";
import FreeBreakfastIcon from "@material-ui/icons/FreeBreakfast";
import DialpadIcon from "@material-ui/icons/Dialpad";
import CallMissedIcon from "@material-ui/icons/CallMissed";
// import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import TextsmsIcon from "@material-ui/icons/Textsms";
import FilterCenterFocusIcon from "@material-ui/icons/FilterCenterFocus";
import AllInboxIcon from "@material-ui/icons/AllInbox";
// import { messaging } from "../../firebase";
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import InvertColorsIcon from "@material-ui/icons/InvertColors";
import NewUpdateModal from "../components/Comments/newUpdate";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import EmailIcon from '@mui/icons-material/Email';
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    title: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      color: "#fff",
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "left",
      justifyContent: "flex-start",
      backgroundColor: "#924A91",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
    dashbord: {
      color: "#fff",
      fontSize: "18px",
      fontWeight: "normal",
      padding: "20px 0px 10px 10px",
    },
    content: {
      width: "80%",
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    titleButtor: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
      color: "#fff",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  })
);

interface Props {
  getUserDetails: any;
  userDetails: any;
  getNotificationMessage: any;
  notificationMessage: any;
}
const CustomerLead: React.FC<Props> = ({
  getUserDetails,
  getNotificationMessage,
  userDetails,
  notificationMessage,
}) => {
  let dispatch = useDispatch();
  const classes = useStyles();
  let history = useHistory();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [option, setOption] = React.useState<string>("call_id");
  const [value, setValue] = React.useState<string>("");
  const [open1, setOpen1] = React.useState(false);
  const [heading, setHeading] = React.useState<string>("");
  const [modelFormIndex, setModelFormIndex] = React.useState<number>(0);
  const [changePasswordModal, setchangePasswordModal] = React.useState(false);
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [notificationData, setnotificationData] = useState("");
  const [heights, setheights] = useState(window.innerHeight);
  const openPopover = Boolean(anchorEl);
  const [openRightMenu, setOpenRightMenu] = React.useState<boolean>(false);
  const [currentNotification, setCurrentNotification] = React.useState<any>([]);
  const [anchorElUpdate, setAnchorElUpdate] = React.useState(null);
  const id = open ? "transitions-popper" : undefined;

  const handleClick = (event: any) => {
    setOpenRightMenu(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpenRightMenu(false);
  };
  useEffect(() => {
    getUserDetails();
  }, []);
  const updatePassword = async () => {
    const body: any = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    await dispatch(changePassword(body));
    setchangePasswordModal(false);
    dispatch(logout());
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleValueChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value as string);
  };
  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
    // getNotificationMessage(`?is_read=false`);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // const handleOpen = (name: string, index: number) => {
  //   setOpen1(true);
  //   setHeading(name);
  //   setModelFormIndex(index);
  // };
  const handleUpdateDetails = (event: any) => {
    setAnchorElUpdate(anchorElUpdate ? null : event.currentTarget);
  };

  const submitForm = (e: any) => {
    e.preventDefault();
    let body = {
      search_by: option,
      value,
    };
    dispatch(searchCall(body));
    history.push("/dashboard/cl/lead/search");
  };
  // useEffect(() => {
  //   Notification.requestPermission()
  //     .then(async function () {
  //       const token = await messaging.getToken();

  //       window.localStorage.setItem("device_token", token);
  //       const body = {
  //         token
  //       }
  //      dispatch(getFirebaseNotification(body))
  //     })
  //     .catch(function (err) {
  //
  //     });
  //   navigator.serviceWorker.addEventListener("message", (message) => {
  //     setnotificationData(message.data.notification.body);

  //   });
  //   window.addEventListener("resize", handleWindowSizeChange);
  // }, [window.innerHeight]);
  // const handleWindowSizeChange = () => {
  //   setheights(window.innerHeight);
  // };
  const handleNotification = () => {
    history.push("/dashboard/cl/inbox");
  };
  const handleLogout = () => {
    dispatch(panelUserLogout());
    dispatch(logout());
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={{ backgroundColor: "#924A91" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Redcliffelabs
          </Typography>
          <Typography className={classes.titleButtor} variant="h6">
            {/* <Link to="/dashboard/pcc/fill-info"> */}
            {/* <Button
              variant="contained"
              color="secondary"
              onClick={() => handleOpen("Create Call", 7)}
            >
              Create Call
            </Button> */}
            {/* </Link>     */}
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <form onSubmit={submitForm}>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                value={value}
                onChange={handleValueChange}
                inputProps={{ "aria-label": "search" }}
              />
            </form>
          </div>
          <div>
            <Button
              variant="text"
              onClick={(e) => handleClick(e)}
              size="medium"
              endIcon={<KeyboardArrowDownIcon />}
              style={{ color: "#fff" }}
            >
              {userDetails &&
                userDetails.profile &&
                userDetails.profile.username}
            </Button>
            <Menu
              id="fade-menu"
              open={openRightMenu}
              onClose={handleClose}
              TransitionComponent={Fade}
              anchorEl={anchorEl}
            >
              <MenuItem onClick={() => setchangePasswordModal(true)}>
                Change Password
              </MenuItem>
              <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
            </Menu>
          </div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-owns={openPopover ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            onClick={handleNotification}
            color="inherit"
          >
            <NotificationsActiveIcon />
          </IconButton>
          <IconButton>
            <DeveloperModeIcon
              aria-describedby={id}
              onClick={handleUpdateDetails}
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Dialog
          open={changePasswordModal}
          // onClose={() => setchangePasswordModal(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
          <DialogContent>
            <TextField
              id="name"
              placeholder="Old Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="New Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="Confirm Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => updatePassword()} color="primary">
              Update
            </Button>
            {/* <Button
              onClick={() => setchangePasswordModal(false)}
              color="primary"
            >
              Cancel
            </Button> */}
          </DialogActions>
        </Dialog>
        <div className={classes.toolbar}>
          <span className={classes.dashbord}>Dashboard</span>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to="/dashboard/cl">
            <ListItem button>
              <Tooltip title="Home" arrow>
                <ListItemIcon>
                  <HomeIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Home" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cl/AuditScore">
            <ListItem button>
              <Tooltip title="Audit Score" arrow>
                <ListItemIcon>
                  <LoyaltyIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Audit Score" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cl/lead/emailDetails">
            <ListItem button>
              <Tooltip title="Booking Email Details" arrow>
                <ListItemIcon>
                  <EmailIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Booking Email Details" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cl/booking_table">
            <ListItem button>
              <Tooltip title="Booking Table" arrow>
                <ListItemIcon>
                  <TableRowsIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Booking Table" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cl/booking/tickets">
            <ListItem button>
              <Tooltip title="Booking Tickets" arrow>
                <ListItemIcon>
                  <ConfirmationNumberRounded style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Booking Tickets" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cl/lead/smsDetails">
            <ListItem button>
              <Tooltip title="Booking SMS Details" arrow>
                <ListItemIcon>
                  <TextsmsIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Booking SMS Details" />
            </ListItem>
          </Link>
        </List>
       
        <List>
          <Link to="/dashboard/cl/breakData">
            <ListItem button>
              <Tooltip title="Break Details" arrow>
                <ListItemIcon>
                  <FastfoodIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Break Details" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cl/call_recording">
            <ListItem button>
              <Tooltip title="Call Recording" arrow>
                <ListItemIcon>
                  <HistoryIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Call Recording" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cl/calls/stats">
            <ListItem button>
              <Tooltip title="Call Stats" arrow>
                <ListItemIcon>
                  <EqualizerRoundedIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Call Stats" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cl/centerInfo">
            <ListItem button>
              <Tooltip title="Center Information" arrow>
                <ListItemIcon>
                  <FilterCenterFocusIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Center Information" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cl/dispositions">
            <ListItem button>
              <Tooltip title="Dispositions" arrow>
                <ListItemIcon>
                  <ThumbsUpDownIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Dispositions" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cl/performance">
            <ListItem button>
              <Tooltip title="Employee Performance" arrow>
                <ListItemIcon>
                  <HowToRegIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Employee Performance" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cl/generate-coupon">
            <ListItem button>
              <Tooltip title="Family Package" arrow>
                <ListItemIcon>
                  <StoreIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Family Package" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cl/helpdesk">
            <ListItem button>
              <Tooltip title=" Help Desk" arrow>
                <ListItemIcon>
                  <QuizIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary=" Help Desk" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cl/inbox">
            <ListItem button>
              <Tooltip title="Inbox" arrow>
                <ListItemIcon>
                  <AllInboxIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Inbox" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cl/lead/incorrectNumberLead">
            <ListItem button>
              <Tooltip title="Incorrect Number Lead" arrow>
                <ListItemIcon>
                  <WarningIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Incorrect Number Lead" />
            </ListItem>
          </Link>
        </List>

        <List>
          <Link to="/dashboard/cl/labDetails">
            <ListItem button>
              <Tooltip title="Lab Details" arrow>
                <ListItemIcon>
                  <DialpadIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Lab Details" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cl/leads">
            <ListItem button>
              <Tooltip title="Leads" arrow>
                <ListItemIcon>
                  <ListAltRounded style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Leads" />
            </ListItem>
          </Link>
        </List>
        {/* <List>
          <Link to="/dashboard/cl/lead/tickets">
            <ListItem button>
              <Tooltip title="Lead Tickets" arrow>
                <ListItemIcon>
                  <ConfirmationNumberRounded style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Lead Tickets" />
            </ListItem>
          </Link>
        </List> */}
        <List>
          <Link to="/dashboard/cl/coupons">
            <ListItem button>
              <Tooltip title="Manage Coupons" arrow>
                <ListItemIcon>
                  <FreeBreakfastIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Manage Coupons" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cl/users">
            <ListItem button>
              <Tooltip title="Manage Users" arrow>
                <ListItemIcon>
                  <SettingsApplicationsRounded style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Manage Users" />
            </ListItem>
          </Link>
        </List>


        <List>
          <Link to="/dashboard/cl/booking">
            <ListItem button>
              <Tooltip title="New Booking" arrow>
                <ListItemIcon>
                  <AddBoxTwoTone style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="New Booking" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cl/testPackages">
            <ListItem button>
              <Tooltip title="Test Packages" arrow>
                <ListItemIcon>
                  <InvertColorsIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Test Packages" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cl/uploader">
            <ListItem button>
              <Tooltip title="Uploader" arrow>
                <ListItemIcon>
                  <HourglassFullIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Uploader" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cl/verifiedbooking">
            <ListItem button>
              <Tooltip title="Verified Booking" arrow>
                <ListItemIcon>
                  <CollectionsBookmarkIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Verified Booking" />
            </ListItem>
          </Link>
        </List>
        {/* <List>
          <Link to="/dashboard/cl/leads-transfer">
            <ListItem button>
              <Tooltip title="Leads Transfer" arrow>
                <ListItemIcon>
                  <LeakAddIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Leads Transfer" />
            </ListItem>
          </Link>
        </List> */}

        {/* <List>
          <Link to="/dashboard/cl/calls">
            <ListItem button>
              <Tooltip title="Calls" arrow>
                <ListItemIcon>
                  <PhoneAndroidRounded style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Calls" />
            </ListItem>
          </Link>
        </List> */}
        <List>
          <Link to="/dashboard/cl/whatsapp">
            <ListItem button>
              <Tooltip title="Whatsapp Messages" arrow>
                <ListItemIcon>
                  <WhatsApp style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Whatsapp Messages" />
            </ListItem>
          </Link>
        </List>





        {/* <List>
          <Link to="/dashboard/cl/lead/missedCall">
            <ListItem button>
              <Tooltip title="Missed Calls" arrow>
                <ListItemIcon>
                  <CallMissedIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Missed Call" />
            </ListItem>
          </Link>
        </List> */}


        {/* <List>
          <Link to="/dashboard/cl/callLog">
            <ListItem button>
              <Tooltip title="Call Details" arrow>
                <ListItemIcon>
                  <DeveloperModeIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Call Details" />
            </ListItem>
          </Link>
        </List> */}








        <Divider />
        <List>
          <ListItem button onClick={() => handleLogout()}>
            <Tooltip title="Logout" arrow>
              <ListItemIcon>
                <ExitToAppIcon style={{ color: "#924A91" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={openPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {notificationMessage &&
          notificationMessage?.results &&
          notificationMessage?.results?.length > 0 &&
          notificationMessage?.results.map((data: any) => {
            return <Typography>{data?.notification || ""}</Typography>;
          })}
      </Popover>
      <CallModal
        open={open1}
        setOpen={setOpen1}
        heading={heading}
        index={modelFormIndex}
      />
      <NewUpdateModal
        anchorElUpdate={anchorElUpdate}
        setAnchorElUpdate={setAnchorElUpdate}
        id={id}
      />
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  userDetails: state.CustomerLeadReducer.userDetails,
  notificationMessage: state.CustomerLeadReducer.notificationMessage,
});

export default connect(mapStateToProps, {
  getUserDetails,
  getNotificationMessage,
})(CustomerLead);
