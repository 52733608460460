type ApiData = {
    api?: string;
    api2?: string;
    api3?: string;
    apiv2?: string;
}

const Prefix: ApiData = {
    api: 'api/v1',
    api2: 'api/phlebo/v2',
    api3: 'api/sso-v1',
    apiv2:'api'
};

export default Prefix;