import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Container,
  Grid,
  TextField,
  Checkbox,
  Select,
  MenuItem,
} from "@material-ui/core";
import Box from "@mui/material/Box";
import Menu from "@material-ui/core/Menu";
import Button from "@mui/material/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { generateBookingFilterUrl } from "../../helpers/generateUrl";
import BookingCommentsModal from "../Components/Comments/bookingComments";
import MailIcon from "@material-ui/icons/Mail";
import { connect } from "react-redux";
import {
  getAgentList,
  getLeadSource,
  getPartners,
  getAllBookings,
  getCities,
  getPhlebos,
  getPaymentInfo,
  getCollectionSlot,
  getPrescriptionData,
  getUserDetails,
  getAllBookingsExports,
} from "../actions/CollectionCenterAdmin";
import "./filter.sass";
import { useHistory } from "react-router-dom";
import BookingsTable from "../Tables/bookingsTable";
import Loader from "../../CustomerLead-Panel/components/loader/index";
import { CheckBoxOutlineBlank, CheckBoxRounded } from "@material-ui/icons";
import PaymentModal from "../Components/Comments/paymentforModal";
import Modal from "@mui/material/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

interface Props {
  children: any;
  agentsList: any;
  bookingList: any;
  partnerList: any;
  getAgentList: any;
  getPartners: any;
  getAllBookings: any;
  getCities: any;
  cities: any;
  getPhlebos: any;
  getPaymentInfo: any;
  paymentInformation: any;
  phleboList: any;
  getLeadSource: any;
  lead_source: any;
  getCollectionSlot: any;
  collectionSlot: any;
  loading: Boolean;
  getPrescriptionData: any;
  prescription: any;
  raiseTicektAgent: any;
  getUserDetails: any;
  userDetails: any;
  getAllBookingsExports: any;
  get_all_booking_exports: any;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
  agentsList,
  partnerList,
  getAgentList,
  getPartners,
  getAllBookings,
  bookingList,
  getCities,
  cities,
  getPhlebos,
  phleboList,
  getLeadSource,
  getPaymentInfo,
  paymentInformation,
  lead_source,
  getCollectionSlot,
  collectionSlot,
  loading,
  getPrescriptionData,
  prescription,
  raiseTicektAgent,
  getUserDetails,
  userDetails,
  getAllBookingsExports,
  get_all_booking_exports,
}) => {
  const classes = useStyles();

  const history = useHistory();

  const BOOKING_DISPOSITION: any = [
    // {
    //     id: 1,
    //     name: "Call Back From Sales"
    // },

    // {
    //     id: 2,
    //     name: "Phlebo Didn't Reach on time"
    // },
    // {
    //     id: 3,
    //     name: "Wrong Report Received"
    // },
    // {
    //     id: 4,
    //     name: "Edit Report"
    // },
    // {
    //     id: 5,
    //     name: "Report not received"
    // },
    // {
    //     id: 6,
    //     name: "Partial report received"
    // },
    // {
    //     id: 7,
    //     name: "Rescheduled to next Date"
    // },
    // {
    //     id: 8,
    //     name: "Time Slot Changed"
    // },
    // {
    //     id: 9,
    //     name: "Cancel- Out of Station"
    // },
    // {
    //     id: 10,
    //     name: "Cancel- Travel Plan Changed"
    // },
    // {
    //     id: 11,
    //     name: "Cancel- Duplicate Booking"
    // },
    // {
    //     id: 12,
    //     name: "Cancel"
    // },
    // {
    //     id: 13,
    //     name: "Subscribed to Competitor"
    // },
    // {
    //     id: 14,
    //     name: "Call Later"
    // },
    // {
    //     id: 15,
    //     name: "Wrong Number"
    // },
    // {
    //     id: 16,
    //     name: "Health Consultation"
    // },
    // {
    //     id: 17,
    //     name: "Phlebo Complaint"
    // },
    // {
    //     id: 18,
    //     name: "Wrong Commitment By Sales"
    // },
    // {
    //     id: 19,
    //     name: "Wrong Commitment By Phlebo"
    // },
    // {
    //     id: 20,
    //     name: "Booking Done"
    // }
    {
      id: 8,
      name: "Time Slot Changed",
    },
    {
      id: 9,
      name: "Cancel-Out Of Station",
    },
    {
      id: 10,
      name: "Cancel-Travel Plan Changed",
    },
    {
      id: 11,
      name: "Cancel-Duplicate Booking",
    },
    {
      id: 12,
      name: "Cancel",
    },
    {
      id: 21,
      name: "Booked From Different Lab",
    },
    {
      id: 15,
      name: "Wrong Number",
    },
    {
      id: 22,
      name: "Cancel-Out Of Service Area ",
    },
    {
      id: 23,
      name: "Confirmed ",
    },
    {
      id: 24,
      name: "Rescheduled  ",
    },
    {
      id: 14,
      name: "Call Later ",
    },
    {
      id: 54,
      name: "Cancel-Customer did not picked the call",
    },
    {
      id: 55,
      name: "Cancel-Wrong Commitment by Sales/Phelbo",
    },
    {
      id: 56,
      name: "Cancel-Number Switched Off",
    },
    {
      id: 57,
      name: "Cancel-Patient Hospitalized",
    },
    {
      id: 58,
      name: "Sample Collected",
    },
    {
      id: 59,
      name: "Phlebo On The Way/Phlebo At Location",
    },
    {
      id: 60,
      name: "Cancel-Requested Time Slot Not Given",
    },
    {
      id: 61,
      name: "Booking Reschedule-Customer Not in Fasting",
    },
    {
      id: 62,
      name: "Booking Reschedule-Phlebo did not reach on time",
    },
  ];

  const [openModal, setopenModal] = useState<boolean>(false);
  const [open2, setOpen2] = useState(false);

  const [status, setStatus] = useState<any>([]);
  const [source, setSource] = useState<any>([]);
  const [partner, setPartner] = useState<any>([]);
  const [agent, setAgent] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [booking_date, setBooking_date] = useState("");
  const [collection_date, setCollection_date] = useState("");
  const [verificationStatus, setVerificationStatus] = useState<any>([]);
  const [pickup_status, setPickup_status] = useState<any>([]);
  const [VerifiedbyList, setVerifiedbyList] = useState<any>([]);
  const [name, setName] = useState("");
  const [lead, setLeadId] = useState<any>("");
  const [phlebo, setPhlebo] = useState<any>([]);
  const [phone, setPhone] = useState("");
  const [bookingId, setBookingId] = useState<any>("");
  const [disposition, setDisposition] = useState<any>([]);
  const [sample_registered, setSampleRegisterd] = useState<any>("none");
  const [report_status, setReportStatus] = useState<any>("none");
  const [paymentId, setPaymentId] = useState<any>(0);
  const [openPaymentModal, setOpenPaymentModal] = useState<any>(false);
  const [page, setPage] = useState(0);
  const [slot, setSlot] = useState<any>([]);
  const [prscriptionLead, setPrescriptionLead] = useState<any>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openPrescription = Boolean(anchorEl);
  const [openPdf, setOpenPdf] = useState<boolean>(false);
  const [fileType, setFileType] = useState<any>("");
  const [pLink, setPlink] = useState<any>("");
  const timer = useRef<any>(0);
  const [verifiedby, setVerifiedby] = useState<any>([]);
  const [pickup_received_amount, setPickupReceivedAmount] =
    useState<string>("none");
  const [heights, setheights] = useState(window.innerHeight);
  const [notificationData, setnotificationData] = useState("");
  const [defaultStatus, setDefaultStatus] = useState<any>([]);
  const [openTubeModal, setOpenTubeModal] = useState<any>(false);
  const [tubeId, setTubeId] = useState<any>("");
  const [updateTube, setUpdateTube] = useState<any>("");
  const [partnerType, setPartnerType] = useState<any>("BML");
  const BILLING_STATUS = [
    {
      text: "Pending",
      value: "pending",
    },
    {
      text: "Confirmed",
      value: "confirmed",
    },
    {
      text: "Cancelled",
      value: "cancelled",
    },
    {
      text: "Rescheduled",
      value: "rescheduled",
    },
    {
      text: "Resampling",
      value: "resampling",
    },
    {
      text: "Sample Not Received Due To Payment Information",
      value: "Sample Not Received Due To Payment Information",
    },
    {
      text: "Sample Received",
      value: "sample received",
    },
    {
      text: "Partial Received",
      value: "partial received",
    },
    {
      text: "Hold",
      value: "hold",
    },
  ];
  useEffect(() => {
    if (history.action === "POP") {
      let ceBookingFilter: any;
      ceBookingFilter = localStorage.getItem("CEBookingFilter");
      if (ceBookingFilter) {
        ceBookingFilter = JSON.parse(ceBookingFilter);

        const statusD = ceBookingFilter?.status?.split(",");
        let statusTemp = new Array();
        BILLING_STATUS.map((item: any) => {
          statusD.map((itm: any) => {
            if (item.value == itm) {
              statusTemp.push(item);
            }
          });
        });
        setDefaultStatus(statusTemp);
        ceBookingFilter?.status &&
          setStatus(ceBookingFilter?.status?.split(","));
        ceBookingFilter?.source &&
          setSource(ceBookingFilter?.source?.split(","));
        ceBookingFilter?.partner &&
          setPartner(ceBookingFilter?.partner?.split(","));
        ceBookingFilter?.city && setCity(ceBookingFilter?.city?.split(","));
        ceBookingFilter?.start_date &&
          setStart_date(ceBookingFilter?.start_date);
        setCollection_date(ceBookingFilter?.collection_date);
        ceBookingFilter?.verificationStatus &&
          setVerificationStatus(
            ceBookingFilter?.verificationStatus?.split(",")
          );
        ceBookingFilter?.pickup_status &&
          setPickup_status(ceBookingFilter?.pickup_status?.split(","));
        setName(ceBookingFilter?.name);
        setPhlebo(ceBookingFilter?.phlebo);
        setPhone(ceBookingFilter?.phone);
        setReportStatus(ceBookingFilter?.report_status);
        setSampleRegisterd(ceBookingFilter?.sample_registered);
        setPickupReceivedAmount(ceBookingFilter?.pickup_received_amount);
        setBookingId(ceBookingFilter?.bookingId);

        const url = generateBookingFilterUrl(ceBookingFilter).substring(2);
        const pageNo = page ? page : 1;
        getAllBookings(`${url}&page=${pageNo}&optimize=true`);
        // {
        //     userDetails?.profile?.executive_type === "BML" ? (
        //         getAllBookings(`${url}&page=${pageNo}&org_type=BML&optimize=true`)

        //     ) :
        //     getAllBookings(`${url}&page=${pageNo}&optimize=true`);
        // } by kamal sir
      }
    } else {
      getAgentList();
      // getAgentList("?CustomerLead&org_type=imaging");

      getPartners();
      getCities("is_imaging=true");
      getCollectionSlot();

      getLeadSource();
      getAllBookings(`optimize=true`);
      // {
      //     userDetails?.profile?.executive_type ==="BML" ? (
      //         getAllBookings('org_type=BML&optimize=true')

      //     ) :
      //     getAllBookings(`optimize=true`)
      // }
      localStorage.removeItem("CEBookingFilter");
      // setDefaultStatus([]);
    }
  }, []);
  useEffect(() => {
    if (paymentId != 0) {
      getPaymentInfo(`?booking=${paymentId}`);
    }
  }, [paymentId]);
  const filterBookings = (e: any) => {
    const body: any = {
      status: status ? status.join(",") : "",
      verificationStatus: verificationStatus
        ? verificationStatus.join(",")
        : "",
      source: source ? source.join(",") : "",
      agent: agent ? agent.join(",") : "",
      city: city ? city.join(",") : "",
      slot: slot.join(","),
      start_date,
      end_date,
      booking_date,
      collection_date,
      pickup_status: pickup_status ? pickup_status.join(",") : "",
      name,
      partner: partner ? partner.join(",") : "",
      phone,
      report_status,
      sample_registered,
      is_pickup_receive_amount: pickup_received_amount,
      bookingId,
    };
    const url = generateBookingFilterUrl(body).substring(2);
    localStorage.setItem("CEBookingFilter", JSON.stringify(body));
    getAllBookings(`${url}&optimize=true`);
    // {
    //     userDetails?.profile?.executive_type ==="BML" ? (

    //         getAllBookings(`${url}&org_type=${partnerType}&optimize=true`)

    //     ) : getAllBookings(`${url}&optimize=true`)
    // }

    setPage(0);
  };
  const exportBooking = (e: any) => {
    const body: any = {
      status: status ? status.join(",") : "",
      verificationStatus: verificationStatus
        ? verificationStatus.join(",")
        : "",
      source: source ? source.join(",") : "",
      agent: agent ? agent.join(",") : "",
      city: city ? city.join(",") : "",
      slot: slot.join(","),
      start_date,
      end_date,
      booking_date,
      collection_date,
      pickup_status: pickup_status ? pickup_status.join(",") : "",
      name,
      partner: partner ? partner.join(",") : "",
      phone,
      report_status,
      sample_registered,
      is_pickup_receive_amount: pickup_received_amount,
      bookingId,
    };
    const url = generateBookingFilterUrl(body).substring(2);
    // localStorage.setItem("CEBookingFilter", JSON.stringify(body));
    getAllBookingsExports(`${url}&download=true`);
    // {
    //     userDetails?.profile?.executive_type ==="BML" ? (

    //         getAllBookings(`${url}&org_type=${partnerType}&optimize=true`)

    //     ) : getAllBookings(`${url}&optimize=true`)
    // }

    // setPage(0);
  };
  useEffect(() => {
    if (prscriptionLead !== "") {
      getPrescriptionData(`?lead=${prscriptionLead}`);
    }
  }, [prscriptionLead]);
  useEffect(() => {
    getUserDetails("");
  }, []);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFrameView = (data: any) => {
    if (data.base64file !== null && data.image === "") {
      setFileType("b64");
      setPlink(`data:application/pdf;base64,${data.base64file}`);
    } else if (data.base64file === null && data.image !== "") {
      let pieces: any = data.image.split(".").pop();
      const last: any = pieces;
      setFileType(last);
      setPlink(data.image);
    }
    setOpenPdf(true);
  };
  const handlePdfModelClose = () => {
    setOpenPdf(false);
  };

  const onKeyDown = (e: any) => {
    if (e.key === "Enter") {
      filterBookings(e);
    }
  };

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "left" }}
        >
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="booking"
              type="number"
              label="Booking Id"
              value={bookingId}
              variant="outlined"
              onKeyDown={onKeyDown}
              onChange={(e) => setBookingId(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="name"
              type="text"
              label="Name"
              value={name}
              variant="outlined"
              onKeyDown={onKeyDown}
              onChange={(e) => setName(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="phone"
              type="number"
              label="Phone"
              value={phone}
              variant="outlined"
              onKeyDown={onKeyDown}
              onChange={(e) => setPhone(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setCity(sourceIds);
              }}
              options={cities || []}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              onKeyDown={onKeyDown}
              getOptionLabel={(option: any) => option.name}
              getOptionDisabled={(option: any) => {
                return city.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCities(`q=${newInputValue}`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setCity([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="City"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="status"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setStatus(sourceIds);
              }}
              options={BILLING_STATUS}
              blurOnSelect
              aria-required
              onKeyDown={onKeyDown}
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return status.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Booking Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
              defaultValue={defaultStatus}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="partner"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setPartner(sourceIds);
              }}
              freeSolo
              blurOnSelect
              limitTags={1}
              multiple
              options={partnerList?.results || []}
              onKeyDown={onKeyDown}
              getOptionDisabled={(option: any) => {
                return partner.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) => option.user && option.user.name}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPartners(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPartner([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Partner"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setSource(sourceIds);
              }}
              freeSolo
              multiple
              id="checkboxes-tags-demo"
              options={lead_source || []}
              disableCloseOnSelect
              limitTags={1}
              disableClearable
              onKeyDown={onKeyDown}
              getOptionLabel={(option: any) => option.name}
              getOptionDisabled={(option: any) => {
                return source.includes(option.id);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Source"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="agent"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setAgent(sourceIds);
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              options={agentsList?.results || []}
              getOptionDisabled={(option: any) => {
                return agent.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                option.user && option.user.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getAgentList(`${newInputValue.replace(" ", "")}`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPartner([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Agent"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          {/* ***************************************************** */}

          {/* <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="verifiedBy"
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                SetVerifiedBy(sourceIds)
                            }}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            options={agentsList?.results || []}
                            getOptionDisabled={(option: any) => {
                                return agent.includes(option.id)
                            }}
                            disableClearable
                            disableCloseOnSelect
                            getOptionLabel={(option: any) => option.user && option.user.username}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                                    getAgentList(`${newInputValue.replace(" ", "")}`)
                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setPartner([])
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    label="Verified By"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                     */}
          {/* ******************************************************* */}

          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setVerificationStatus(sourceIds);
              }}
              options={[
                {
                  text: "Pending",
                  value: "pending",
                },
                {
                  text: "Confirmed",
                  value: "confirmed",
                },
                {
                  text: "Cancelled",
                  value: "cancelled",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              onKeyDown={onKeyDown}
              multiple
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return verificationStatus.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Verification Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setPickup_status(sourceIds);
              }}
              options={[
                {
                  text: "Pending",
                  value: "pending",
                },
                {
                  text: "Confirmed",
                  value: "confirmed",
                },
                {
                  text: "Cancelled",
                  value: "cancelled",
                },
                {
                  text: "Hold",
                  value: "hold",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.text}
              onKeyDown={onKeyDown}
              getOptionDisabled={(option: any) => {
                return pickup_status.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Pickup Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="start_date"
              type="date"
              label="Start Date"
              value={
                booking_date === "" || collection_date === "" ? start_date : ""
              }
              variant="outlined"
              disabled={booking_date !== "" || collection_date !== ""}
              onKeyDown={onKeyDown}
              onChange={(e) => setStart_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="end_date"
              type="date"
              value={
                booking_date === "" || collection_date === "" ? end_date : ""
              }
              className="input"
              label="End Date"
              variant="outlined"
              disabled={booking_date !== "" || collection_date !== ""}
              onChange={(e) => setEnd_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="booking_date"
              type="date"
              value={start_date === "" || end_date === "" ? booking_date : ""}
              className="input"
              label="Booking Date"
              variant="outlined"
              onKeyDown={onKeyDown}
              disabled={start_date !== "" || end_date !== ""}
              onChange={(e) => setBooking_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="collection_date"
              type="date"
              value={
                start_date === "" || end_date === "" ? collection_date : ""
              }
              className="input"
              label="Collection Date"
              variant="outlined"
              onKeyDown={onKeyDown}
              disabled={start_date !== "" || end_date !== ""}
              onChange={(e) => setCollection_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          {/* Start */}

          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="slot"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setSlot(sourceIds);
              }}
              options={collectionSlot.results}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) =>
                option.start_time + "-" + "OnWard"
              }
              getOptionDisabled={(option: any) => {
                return slot.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCollectionSlot(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setSlot([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Collection Slot"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="booking_status"
              variant="outlined"
              value={pickup_received_amount || "pending"}
              style={{
                width: "100%",
                margin: "0",
                padding: "0",
                height: "40px",
              }}
              onChange={(e) =>
                setPickupReceivedAmount(e.target.value as string)
              }
              required
            >
              <MenuItem disabled value={"none"}>
                Pickup Recieved Amount
              </MenuItem>
              <MenuItem value={"true"}>Received</MenuItem>
              <MenuItem value={"false"}>Not Received</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="sampleregisterd"
              variant="outlined"
              value={sample_registered}
              style={{ width: "100%", height: "40px" }}
              onChange={(e) => setSampleRegisterd(e.target.value as String)}
            >
              <MenuItem disabled value="none">
                Sample Type
              </MenuItem>
              <MenuItem value={"true"}>Registerd</MenuItem>
              <MenuItem value={"false"}>Not Registerd</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Select
              className="input"
              name="sampleregisterd"
              variant="outlined"
              value={report_status}
              style={{ width: "100%", margin: "0", height: "40px" }}
              onChange={(e) => setReportStatus(e.target.value as String)}
            >
              <MenuItem disabled value={"none"}>
                Report Status
              </MenuItem>
              <MenuItem value={"na"}>None</MenuItem>
              <MenuItem value={"pending"}>Pending</MenuItem>
              <MenuItem value={"partial"}>Partial</MenuItem>
              <MenuItem value={"consolidate"}>Consolidate</MenuItem>
            </Select>
          </Grid>

          {/* End */}
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={filterBookings}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => history.push("/dashboard/cca")}
            >
              Reset
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              style={{ background: "#9c27b0", height: "40px" }}
              fullWidth
              onClick={exportBooking}
            >
              <MailIcon /> Export Booking
            </Button>
          </Grid>
        </Grid>
        <PaymentModal
          openModalP={openPaymentModal}
          setOpenModalP={setOpenPaymentModal}
          paymentInformation={paymentInformation}
        />
        <br />
        {loading ? (
          <Loader />
        ) : (
          bookingList.results &&
          bookingList.results.length > 0 && (
            <Box>
              <Container maxWidth={false}>
                <Grid container spacing={3}>
                  <BookingsTable
                    open2={open2}
                    setOpen2={setOpen2}
                    setopenModal={setopenModal}
                    openModal={openModal}
                    page={page}
                    setPage={setPage}
                    loading={loading}
                    getBookings={getAllBookings}
                    bookingList={bookingList}
                    paymentId={paymentId}
                    setPaymentId={setPaymentId}
                    openPaymentModal={openPaymentModal}
                    setOpenPaymentModal={setOpenPaymentModal}
                    getPrescriptionData={getPrescriptionData}
                    prescription={prescription}
                    prscriptionLead={prscriptionLead}
                    setPrescriptionLead={setPrescriptionLead}
                    setAnchorEl={setAnchorEl}
                    setBookingId={setBookingId}
                    bookingId={bookingId}
                  />
                </Grid>
              </Container>
            </Box>
          )
        )}
        <Menu
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={openPrescription}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          {prescription &&
            prescription?.results &&
            prescription?.results?.length > 0 &&
            prescription?.results.map((data: any, index: any) => {
              let pieces: any = data.image.split(".").pop();
              const last: any = pieces;
              return (
                <>
                  {data?.base64file !== null && data.image === "" ? (
                    <MenuItem onClick={() => handleFrameView(data)}>
                      {" "}
                      Name :{data?.name}, Age:{data?.age}, Created Date :
                      {new Date(data.created_at).toLocaleString()}
                    </MenuItem>
                  ) : data?.base64file === null && data.image !== "" ? (
                    last === "png" || last === "jpg" || last === "jpeg" ? (
                      <MenuItem>
                        <a
                          href={data?.image}
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          Name :{data?.name}, Age:{data?.age}, Created Date :
                          {new Date(data.created_at).toLocaleString()}
                        </a>
                      </MenuItem>
                    ) : (
                      <MenuItem>
                        <a
                          href={data?.image}
                          rel="noreferrer noopener"
                          target="_blank"
                        >
                          Name :{data?.name}, Age:{data?.age}, Created Date :
                          {new Date(data.created_at).toLocaleString()}
                        </a>
                      </MenuItem>
                    )
                  ) : (
                    ""
                  )}
                </>
              );
            })}
        </Menu>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openPdf}
          onClose={handlePdfModelClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openPdf}>
            <>
              {fileType === "pdf" ? (
                <iframe
                  style={{ width: "80%", height: "750px" }}
                  src={pLink}
                  title="title"
                >
                  Presss me: <a href={pLink}>Download PDF</a>
                </iframe>
              ) : fileType === "b64" ? (
                <iframe
                  src={pLink}
                  style={{ width: "80%", height: "750px" }}
                  title="Iframe Example"
                ></iframe>
              ) : (
                ""
              )}
            </>
          </Fade>
        </Modal>
        {bookingList.results && bookingList.results.length === 0 && (
          <h6 style={{ textAlign: "center" }}>No Data Found</h6>
        )}
        <BookingCommentsModal
          openModal={openModal}
          setopenModal={setopenModal}
          bookingId={bookingId}
        />
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    agentsList: state.CollectionCenterAdminReducer.agentsList,
    partnerList: state.CollectionCenterAdminReducer.partnerList,
    bookingList: state.CollectionCenterAdminReducer.bookingList,
    cities: state.CollectionCenterAdminReducer.cities,
    phleboList: state.CollectionCenterAdminReducer.phleboList,
    paymentInformation: state.CollectionCenterAdminReducer.paymentInformation,
    loading: state.CollectionCenterAdminReducer.loading,
    collectionSlot: state.CollectionCenterAdminReducer.collectionSlot,
    lead_source: state.CollectionCenterAdminReducer.lead_source,
    prescription: state.CollectionCenterAdminReducer.prescription,
    raiseTicektAgent: state.CollectionCenterAdminReducer.raiseTicektAgent,
    userDetails: state.CollectionCenterAdminReducer.userDetails,
    get_all_booking_exports:
      state.CollectionCenterAdminReducer.get_all_booking_exports,
  };
};

export default connect(mapStateToProps, {
  getAgentList,
  getPartners,
  getCities,
  getPhlebos,
  getLeadSource,
  getPaymentInfo,
  getAllBookings,
  getCollectionSlot,
  getPrescriptionData,
  getUserDetails,
  getAllBookingsExports,
})(CustomerLeadFilterPage);
