import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Grid,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    TablePagination,
    TableFooter,
    withStyles,
    Switch,
} from '@material-ui/core';
import { Button, Chip } from "@material-ui/core";
import './index.sass'
import { CheckCircleOutlineRounded, Edit } from "@material-ui/icons";
import { connect } from "react-redux";
import { getZone, updateZone, getTimeslots, getCities, getZoneComments, } from '../../actions/salesLeadAction'
import { getPhlebos } from '../../actions/salesLeadAction'
import Loader from "../loader";
import CreateZoneModal from "./createZoneModal";
import moment from "moment";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MessageRounded } from '@material-ui/icons';
import { useHistory } from "react-router";
import BookingCommentsModal from '../comments/zonesComments'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        paper: {
            padding: "1rem",
            width: "100%"
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        heading: {
            fontWeight: "normal",
            fontSize: "1.2rem"
        },
        actionButton: {
            margin: "0 0.5rem",
            cursor: "pointer"
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);
const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,fontWeight:600
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);
const DefaultSwitch:any = withStyles({
    switchBase: {
        color: "red",
        '&$checked': {
            color: "green",
        },
        '&$checked + $track': {
            backgroundColor: "green",
        },
    },
    checked: {},
    track: {
        backgroundColor: "red"
    },
})(Switch);

interface Props {
    getZone: any;
    updateZone: any;
    zoneList: any;
    getCities: any;
    cities: any;
    getPhlebos: any;
    phleboList: any;
    getTimeslots: any;
    timeslotsList: any;
    getZoneComments: any;
    loading: Boolean;
}

const ZonePage: React.FC<Props> = ({
    getZone,
    updateZone,
    zoneList,
    getCities,
    cities,
    getPhlebos,
    phleboList,
    getTimeslots,
    timeslotsList,
    getZoneComments,
    loading
}) => {
    const classes = useStyles();

    const [search, setSearch] = useState<String>("")

    const [editZone, seteditZone] = useState("")

    const [timeslots, setTimeslots] = useState<any>([])
    const [timeslotsIds, setTimeslotsIds] = useState<any>([])
    const [phlebos, setPhlebos] = useState<any>([])
    const [phlebosId, setPhlebosIds] = useState<any>([])
    const [city, setCity] = useState<number | null>(0)
    const [cityName, setCityName] = useState("")
    const [maxSlot, setMaxSlot] = useState("")
    const [zoneId, setZoneId] = useState<number>(0);
    const [openModal, setopenModal] = useState(false)
    const timer = useRef<any>(0)
    const history = useHistory()
    const [page, setPage] = useState(0)
    const [pageNumber, setPageNumber] = useState<any>("")

    const [open, setOpen] = useState(false)

    const handleSearch = (e: React.ChangeEvent<{ value: unknown }>) => {
        setSearch(e.target.value as string)
        getZone(e.target.value)
    }

    useEffect(() => {
        getZone()
        getCities()
        getTimeslots()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        open === false && getZone()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = zoneList.links && zoneList.links.next.split("?")[1];
            getZone(`&${url}`);
            setPageNumber(url)
        } else if (newPage < page) {
            let url = zoneList.links && zoneList.links.previous.split("?")[1];
            getZone(`&${url}`);
            setPageNumber(url)
        }
        setPage(newPage as number);
    };
   

    const handleEditZone = (zone: any) => {
        seteditZone(zone.id)
        setCityName(zone.city.name)
        setCity(zone.city.id)
        setPhlebos(zone.phlebos)
        setPhlebosIds(zone.phlebos.map((item: any) => item.id))
        setTimeslotsIds(zone.time_slots.map((item: any) => item.id))
        setTimeslots(zone.time_slots)
        setMaxSlot(zone.max_booking_phlebs_slot)
    }

    const handleSubmit = async (zone: any) => {
        seteditZone("")
        const body: any = {
            city: zone.city?.id,
            time_slots: zone.time_slots?.id,
            phlebos: phlebosId,
            max_booking_phlebs_slot: zone.city?.max_booking_phlebs_slot,
            is_active: zone.is_active

        }
        await updateZone(zone?.id, body)

        getZone("show=true")

        getZone()



    }


    const disableZone = async (zone: any) => {
        const body: any = {
            city: zone.city?.id,
            time_slots: zone.time_slots?.id,
            phlebos: phlebosId,
            max_booking_phlebs_slot: zone.city?.max_booking_phlebs_slot,
            is_active: !zone.is_active
        }
        await updateZone(zone?.id, body)

        getZone("show=true")
        getZone()
    }
    const handleClick = (id: number) => {
        setopenModal(true);
        getZoneComments(id)
    };

    return (
        <>
            <main className={classes.content}>
            <div style={{ width: "100%" }} className="data-table">
                <div className={classes.toolbar} />
                    <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                        <Grid item xs={12} md={4}>
                            <h2 style={{ fontWeight: "bold" }}>Manage Zone</h2>
                        </Grid>
                        <Grid container item xs={12} md={8} direction="row" alignItems="center" justify="flex-end">
                            <TextField
                                id="search"
                                type="search"
                                value={search}
                                className="input"
                                variant="outlined"
                                placeholder="Search"
                                onChange={(e) => handleSearch(e)}
                                style={{ margin: "0" }}
                            />
                            {/* <Button variant="contained" color="primary" style={{ marginLeft: "1rem" }} onClick={() => setOpen(true)}>Add Zone</Button> */}
                        </Grid>
                    </Grid>
                    {loading ? <Loader /> : (
                        <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "625px" }}>
                            <Table stickyHeader aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align="center">City</StyledTableCell>
                                        <StyledTableCell align="center">Comments</StyledTableCell>
                                        <StyledTableCell align="center">Time Slots</StyledTableCell>
                                        <StyledTableCell align="center">Phlebos</StyledTableCell>
                                        <StyledTableCell align="center">Max Booking Phlebo Slot</StyledTableCell>
                                        <StyledTableCell align="center">Active</StyledTableCell>
                                        {/* <StyledTableCell align="center">Edit</StyledTableCell> */}
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {zoneList && zoneList.results && zoneList.results.length > 0 && zoneList.results.map((code: any, index: any) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="center">
                                                    {editZone === code.id ? (
                                                        <Grid item xs={12} sm={6} md={4}>
                                                            <Autocomplete
                                                                id="city"
                                                                onChange={(event, newValue) => {
                                                                    if (newValue) {
                                                                        let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                                                        setCity(obj.id)
                                                                        setCityName(obj.name)
                                                                    }
                                                                }}
                                                                options={cities}
                                                                freeSolo
                                                                blurOnSelect
                                                                aria-required
                                                                inputValue={cityName}
                                                                getOptionLabel={(option: any) => option.name}
                                                               
                                                                renderInput={(params) => (
                                                                    <TextField className="input"
                                                                        {...params}
                                                                        placeholder="City"
                                                                        variant="outlined"
                                                                        style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    ) : (
                                                        code.city.name
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <div>
                                                        <MessageRounded onClick={() => handleClick(code.id)} />
                                                    </div>
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {editZone === code.id ? (
                                                        <Autocomplete
                                                            id="timeslots"
                                                            onChange={(event, newValue) => {
                                                                if (newValue) {
                                                                    let timeslotsArr: any = []
                                                                    let timeslotId: any = []
                                                                    newValue.map((val: any) => {
                                                                        let obj = JSON.parse(JSON.stringify(val, null, " "));
                                                                        timeslotsArr.push(obj)
                                                                        timeslotId.push(obj.id)
                                                                    })
                                                                    setTimeslots(timeslotsArr)
                                                                    setTimeslotsIds(timeslotId)
                                                                }
                                                            }}
                                                            value={timeslots}
                                                            multiple
                                                            disableClearable
                                                            limitTags={1}
                                                            options={timeslotsList.results}
                                                            freeSolo
                                                            blurOnSelect
                                                           
                                                            getOptionLabel={(option: any) => {
                                                                return `${option.start_time}-${option.end_time}`
                                                            }}
                                                            renderTags={(value: string[], getTagProps) =>
                                                                value.map((option: any, index: number) => (
                                                                    <Chip
                                                                        key={index}
                                                                        variant="default"
                                                                        color="primary"
                                                                        label={`${option.start_time}-${option.end_time}`}
                                                                        {...getTagProps({ index })}
                                                                    />
                                                                ))
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder="Time Slots"
                                                                    variant="outlined"
                                                                    style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                                    className="input"
                                                                />
                                                            )}
                                                          
                                                        />
                                                    ) : (
                                                        <React.Fragment>
                                                            {code.time_slots.map((slot: any) => {
                                                                return (
                                                                    <p key={slot.id} style={{ margin: "0.5rem auto" }}>{moment(`${slot.start_time}`, ["HH.mm.ss"]).format("hh:mm A")} - {moment(`${slot.end_time}`, ["HH.mm.ss"]).format("hh:mm A")}</p>
                                                                )
                                                            })}
                                                        </React.Fragment>
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editZone === code.id ? (
                                                        <Autocomplete
                                                            id="phlebo"
                                                            onChange={(event, newValue) => {
                                                                if (newValue) {
                                                                    let timeslotsArr: any = []
                                                                    let phleboId: any = []
                                                                    newValue.map((val: any) => {
                                                                        let obj = JSON.parse(JSON.stringify(val, null, " "));
                                                                        timeslotsArr.push(obj)
                                                                        phleboId.push(obj.id)
                                                                    })
                                                                    setPhlebos(timeslotsArr)
                                                                    setPhlebosIds(phleboId)
                                                                }
                                                            }}
                                                            value={phlebos}
                                                            onInputChange={(event, newInputValue) => {
                                                                clearTimeout(timer.current)
                                                                timer.current = setTimeout(() => {
                                                                    getPhlebos(newInputValue)
                                                                }, 1000)
                                                            }}
                                                            multiple
                                                            disableClearable
                                                            disableCloseOnSelect
                                                            limitTags={1}
                                                            options={phleboList.results ? [...phleboList.results, { id: 0, user: { name: "No Phlebo" } }] : []}
                                                            freeSolo
                                                            blurOnSelect
                                                           
                                                            getOptionLabel={(option: any) => option.user && option?.user?.name}
                                                            renderTags={(value: string[], getTagProps) =>
                                                                value.map((option: any, index: number) => (
                                                                    <Chip
                                                                        key={index}
                                                                        variant="default"
                                                                        color="primary"
                                                                        label={option.user && option.user.name}
                                                                        {...getTagProps({ index })}
                                                                    />
                                                                ))
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder="Phlebo"
                                                                    variant="outlined"
                                                                    style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                                    className="input"
                                                                />
                                                            )}
                                                           
                                                        />
                                                    ) : (
                                                        <React.Fragment>
                                                            {code.phlebos.map((user: any) => {
                                                                return (
                                                                    <p key={user.id} style={{ margin: "0.5rem auto" }}>{user.user.name}</p>
                                                                )
                                                            })}
                                                        </React.Fragment>
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editZone === code.id ? (
                                                        <TextField
                                                            name="area"
                                                            type="text"
                                                            defaultValue={code.max_booking_phlebs_slot}
                                                            value={maxSlot}
                                                            className="input"
                                                            variant="outlined"
                                                            placeholder="Area"
                                                            disabled
                                                            onChange={(e) => {
                                                                setMaxSlot(e.target.value as string);

                                                            }}
                                                            style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                        />
                                                    ) : (
                                                        code.max_booking_phlebs_slot
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <DefaultSwitch
                                                        checked={code.is_active}
                                                        name="checkedA"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        disableRipple
                                                       
                                                        onClick={() => disableZone(code)}
                                                    />
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="center">
                                                    {editZone === code.id ? (
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={() => handleSubmit(code)}
                                                        >
                                                            <CheckCircleOutlineRounded />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={() => handleEditZone(code)}
                                                        >
                                                            <Edit />
                                                        </Button>
                                                    )}
                                                </StyledTableCell> */}
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                                <BookingCommentsModal openModal={openModal} setopenModal={setopenModal} zoneId={zoneId} />

                                <TableFooter>
                                    <StyledTableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[]}
                                            colSpan={3}
                                            count={(zoneList && zoneList.count) || 0}
                                            rowsPerPage={zoneList && zoneList?.page_size}
                                            page={page}
                                            onPageChange={handleChangePage}
                                        />
                                    </StyledTableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    )}
                    {zoneList && zoneList.length === 0 && (
                        <h3 className={classes.heading} style={{ textAlign: "center" }}>No Data Found</h3>
                    )}
                <CreateZoneModal open={open} setOpen={setOpen} />
                </div>
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    timeslotsList: state.SalesLeadReducer.timeslotsList,
    zoneList: state.SalesLeadReducer.zoneList,
    phleboList: state.SalesLeadReducer.phleboList,
    cities: state.SalesLeadReducer.cities,
    loading: state.SalesLeadReducer.loading,
})

export default connect(mapStateToProps, {
    getZone,
    getCities,
    updateZone,
    getTimeslots,
    getPhlebos,
    getZoneComments,
})(ZonePage);