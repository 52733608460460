export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  GET_PIN_CODE: "haa/get_pin_code",
  GET_CITIES: "haa/get_cities",
  GET_AREA: "haa/get_area",
  GET_AGENTS: "haa/get_agents",
  GET_AGENTS_BY_USERGROUP: "haa/get_agents_by_usergroup",
  GET_PACKAGES: "haa/get_packages",
  GET_PHLEBOS: "haa/get_phlebos",
  GET_PARTNERS: "haa/get_partners",
  GET_COUPONS: "haa/get_coupons",
  CREATE_BOOKING: "haa/create_booking",
  UPDATE_BOOKING: "haa/update_booking",
  GET_BOOKING_LIST: "haa/get_booking_list",
  GET_LEAD_DETAILS: "haa/get_lead_details",
  GET_LEAD_BY_ID: "haa/get_lead_by_id",
  LEAD_DETAILS_RESET: "haa/lead_details_reset",
  GET_LEADS: "haa/get_leads",
  GET_BOOKING_BY_ID: "haa/get_booking_by_id",
  CLICK_TO_CALL: "haa/click_to_call",
  GET_POSTIVE_CALLS_COMMENTS: "haa/get_postive_calls_commnets",
  ADD_POSTIVE_CALLS_COMMENT: "haa/add_comments",
  SET_COMMENTS: "haa/set_commnets",
  UPLOAD_FILE: "haa/upload",
  SET_LOADING: "haa/set_loading",
  SEARCH_CALL: "haa/serach",
  BOOKING_RESET: "haa/booking_reset",
  ADD_COMMENT: "haa/positive_calls/add_comment",
  CREATE_CALL: "haa/createcall",
  GET_LEAD_SOURCE: "haa/getleadsource",
  GET_RECORDINGS: "haa/get_recordings",
  GET_BOOKINGS_COMMENTS: "haa/get_bookings_commnets",
  ADD_BOOKINGS_COMMENT: "haa/add_bookings_comments",
  GET_WHATSAPP_COMMENTS: "haa/get_whatsapp_comments",
  GET_WHATSAPP_COMMENTS_BY_LEAD: "haa/get_whatsapp_comments_by_lead",
  SEND_WHATSAPP_COMMENTS: "haa/send",
  GET_CALL_STATS: "haa/get_call_stats",
  RAISE_COMPLAINT: "haa/raise_complaint",
  GET_COMPLAINTS: "haa/get_complaints",
  ASSIGN_DOCTOR: "haa/assign_doctor",
  SEND_FEEDBACK: "haa/send_feedback",
  GET_AVAILABLE_SLOTS: "haa/get_available_slots",
  GET_ALL_LEADS: "haa/get_all_leads",
  LEAD_TRANSFER_BY_COMMA: "haa/set_comments",
  GET_PANEL_USERS: "haa/get_panel_user",
  GET_SMS_DETAILS: "haa/get_sms_details",
  GET_EMAIL_DETAILS: "haa/get_email_details",
  GET_TOP_RECORDS: "haa/get_top_records",
  GET_SYNC_DATA: "haa/get_sync_data",
  GET_UNREGISTERD_BOOKING: "haa/get_unregisterd_booking",
  UPDATE_UNREGISTERD_BOOKING_STATUS: "haa/update_unregisterd_booking_status",
  GET_BOOKING_CALLS: "haa/get_booking_call",
  SAME_NUMBER_BOOKING: "haa/same_number_booking",
  GET_BREAK_RESPONSE: "haa/get_break_response",
  GET_PANEL_BREAK: "haa/get_panel_break",
  GET_SMS_REPORT_SEND: "haa/get_sms_report_send",
  GET_PAYMENT_SEND_LINK: "haa/get_payment_send_link",
  GET_PAYMENT_RESEND_LINK: "haa/get_payment_resend_link",
  GET_PAYMENT_CANCEL_LINK: "haa/get_payment_cancel_link",
  GET_SMS_LINK: "haa/send_sms_link",
  CREATE_ADDITIONAL_BOOKING: "haa/create_additional_booking",
  UPDATE_ADDITIONAL_BOOKING: "haa/update_additional_booking",
  GET_REPORT_DOWNLOAD: "haa/get_report_download",
  GET_REPORT_STATUS: "haa/get_report_status",
  GET_TIMESLOTS: "haa/get_timeslot",
  GET_CENTER_INFORMATION: "haa/get_center_information",
  GET_NOTIFICATION_MESSAGE: "haa/get_notification_message",
  GET_HOLD_BOOKING_LIST: "haa/get_hold_booking_list",
  GET_PRESCRIPTION_DATA: "haa/get_prescription_data",
  UPDATE_PRESCRIPTION_STATUS: "haa/update_prescription_status",
  GET_HOLD_BOOKING_CALL_COUNT: "haa/get_hold_booking_call_count",
  GET_USER_DETAILS: "haa/get_user_details",
  GET_VERIFICATION_BOOKING_DETAILS: "haa/get_verification_booking_details",
  GET_UNMASKED_NUMBER: "haa/get_unmasked_number",
  CHECK_GEOFENCE_AREA: "haa/checking-geofence-area",
  GET_FAMILY_PACKAGES: "haa/get_family_packages",
  GET_FAMILY_PACKAGES_BOOKING: "haa/get_family_packages_booking",
  CREATE_FAMILY_PACKAGES_BOOKING: "haa/create_family_packages_booking",
  GET_RED_COIN: "haa/get_red_coin",
  GET_REPORT_DATA: "haa/get_report_data",
  GET_HEALTH_TEST_PARAMETER: "haa/get_health_test_parameter",
  GET_PARAMETER_COUNT: "haa/get_parameter_count",
  GET_DISPOSITIONS: "haa/get_dispositions",
  GET_SUB_CENTER_INFORMATION: "haa/get_sub_center_information",
  GET_IMAGING_TIMESLOTS: "haa/get_imaging_timeslots",
  CREATE_IMAGING_BOOKING: "haa/CREATE_IMAGING_BOOKING",
  GET_MULTIPLE_PACKAGES: "haa/get_multiple_package",
  GET_DISPLAY_CITIES: "haa/get_display_cities",
  GET_MAMPMYINDIA_ACCESS_TOKEN: "haa/get-mapmyindia-access-token",
  CHECK_GEOFENCE_AREA_MAMPMYINDIA: "haa/checking-geofence-area-mapmyindia",
  GET_ADDRESS_TYPE_SEATING: "haa/get_address_type_seating",
  GET_HEALTHADVISOR_AGENTS: "haa/get_healthadvisor_agent",
  TRANSFER_BOOKING: "haa/transfer_booking",
  GET_TRANSFER_BOOKING: "haa/get_transfer_booking",
  UPDATE_TRANSFER_TICKET_STATUS: "haa/update_ticket_status",
  GET_LAB_DETAILS: "haa/get_lab_details",
  GET_ZONE: "haa/get_zone",
  CREATE_COUPON: "haa/create_coupon",
  UPDATE_COUPON: "haa/update_coupon",
  ADD_REMARK_ON_CALL: "haa/add_remark_on_call",
  GET_PANEL_USER_LIST_DATA: "haa/get_panel_user_list_data",
  GET_BREAK_DATA: "haa/get_break_data",
  CREATE_PANEL_USER: "haa/create_panel_user",
  GET_USERS_LIST: "haa/get_user_list",
  UPDATE_USERS_LIST: "haa/update_user_list",
  GET_PANEL_COMMENT: "haa/get_panel_comments",
  GET_PANELUSER_ACTIVE_DEACTIVE: "haa/get_active_deactive_panel_user",
  GET_AGENT_DATA: "haa/get_agent_data",
  GET_ASSIGNED_USERGROUP: "haa/get_assigned_usergroup",
  UPDATE_USERS_STATUS: "haa/update_user_status",
  UPDATED_PANEL_USER: "haa/updated_panel_user",
  LEAD_TRANSFER_TO_TATATALE: "haa/lead_transfer_to_tatatale",
  GET_LEAD_STATUS: "haa/get_lead_status",
  GET_PANEL_USER_LIST_DATA_SALES_LEAD:
    "haa/get_panel_user_list_data_sales_lead",
  GET_PANEL_USER_LIST_DATA_TEAM_LEAD: "haa/get_panel_user_list_data_team_lead",
  GET_TICKET_DATA: "haa/get_ticket_data",
  GET_TICKET_CATEGORIES: "haa/get_ticket_category",
  GET_TICEKT_SUB_CATEGORIES: "haa/get_ticket_categories",
  CREATE_TASK_TICKET: "haa/create_task_ticket",
  GET_RED_TECH_COMMENT: "haa/redtech_comments/",
  POST_RED_TECH_COMMENT: "haa/postredtech_comments/",
  GET_CE_CENTER_FOR_CC: "haa/get_ce_center_for_cc",
  CREATE_PILOT_BOOKING: "haa/create_pilot_booking",
};

interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface TrasferBooking {
  type: typeof ACTIONS.TRANSFER_BOOKING;
  payload: Array<any>;
}
interface UpdateTicketStatus {
  type: typeof ACTIONS.UPDATE_TRANSFER_TICKET_STATUS;
  payload: Array<any>;
}
interface GetTransferBooking {
  type: typeof ACTIONS.GET_TRANSFER_BOOKING;
  payload: Array<any>;
}
interface GetHealthAdvisorAgent {
  type: typeof ACTIONS.GET_HEALTHADVISOR_AGENTS;
  payload: Array<any>;
}
interface GetDispositions {
  type: typeof ACTIONS.GET_DISPOSITIONS;
  payload: Array<any>;
}
interface GetUnmaskedNumber {
  type: typeof ACTIONS.GET_UNMASKED_NUMBER;
  payload: Array<any>;
}
interface CreatePilotBooking {
  type: typeof ACTIONS.CREATE_PILOT_BOOKING;
  payload: boolean;
}
interface GetParameterCount {
  type: typeof ACTIONS.GET_PARAMETER_COUNT;
  payload: Array<any>;
}
interface GetHealthTestParameter {
  type: typeof ACTIONS.GET_HEALTH_TEST_PARAMETER;
  payload: Array<any>;
}
interface GetReportData {
  type: typeof ACTIONS.GET_REPORT_DATA;
  payload: Array<any>;
}
interface GetPanelComments {
  type: typeof ACTIONS.GET_PANEL_COMMENT;
  payload: Array<any>;
}
interface GetBreakData {
  type: typeof ACTIONS.GET_BREAK_DATA;
  payload: Object;
}
interface GetRedCoin {
  type: typeof ACTIONS.GET_RED_COIN;
  payload: Array<any>;
}
interface CheckGeoFenceArea {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA;
  payload: Array<any>;
}
interface GetUserDetails {
  type: typeof ACTIONS.GET_USER_DETAILS;
  payload: Array<any>;
}
interface UpdateUserStatus {
  type: typeof ACTIONS.UPDATE_USERS_STATUS;
  payload: Object;
}
interface UpdatePrescriptionStatus {
  type: typeof ACTIONS.UPDATE_PRESCRIPTION_STATUS;
  payload: Array<any>;
}
interface GetHoldBookingCallCount {
  type: typeof ACTIONS.GET_HOLD_BOOKING_CALL_COUNT;
  payload: Array<any>;
}
interface GetPrescriptionData {
  type: typeof ACTIONS.GET_PRESCRIPTION_DATA;
  payload: Array<any>;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface GetUserList {
  type: typeof ACTIONS.GET_USERS_LIST;
  payload: Object;
}
interface SetUserList {
  type: typeof ACTIONS.UPDATE_USERS_LIST;
  payload: Object;
}
interface GetAddressTypeSeating {
  type: typeof ACTIONS.GET_ADDRESS_TYPE_SEATING;
  payload: Object;
}
interface GetAssignedUsergroup {
  type: typeof ACTIONS.GET_ASSIGNED_USERGROUP;
  payload: Object;
}
interface GetPanelUserDataTeamLead {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA_TEAM_LEAD;
  payload: Array<any>;
}
interface CreatePanelUser {
  type: typeof ACTIONS.CREATE_PANEL_USER;
  payload: Object;
}
interface GetCeCenterForCc {
  type: typeof ACTIONS.GET_CE_CENTER_FOR_CC;
  payload: Array<any>;
}

interface UploadFile {
  type: typeof ACTIONS.UPLOAD_FILE;
  payload: Object;
}

interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}
interface GetLeadStatus {
  type: typeof ACTIONS.GET_LEAD_STATUS;
  payload: Object;
}

interface GetPanelUserList {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA;
  payload: Array<any>;
}
interface UpdateCoupon {
  type: typeof ACTIONS.UPDATE_COUPON;
  payload: Object;
}
interface GetArea {
  type: typeof ACTIONS.GET_AREA;
  payload: Array<any>;
}

interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}

interface UpdatedPanelUser {
  type: typeof ACTIONS.UPDATED_PANEL_USER;
  payload: Object;
}
interface GetPhlebo {
  type: typeof ACTIONS.GET_PHLEBOS;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}
interface AddRemarkOnCall {
  type: typeof ACTIONS.ADD_REMARK_ON_CALL;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}
interface GetTimeSlot {
  type: typeof ACTIONS.GET_TIMESLOTS;
  payload: Array<any>;
}

interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}

interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}

interface GetActiveDeactivePanelUser {
  type: typeof ACTIONS.GET_PANELUSER_ACTIVE_DEACTIVE;
  payload: Array<any>;
}
interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}

interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}

interface GetArea {
  type: typeof ACTIONS.GET_AREA;
  payload: Array<any>;
}
interface CreateCoupon {
  type: typeof ACTIONS.CREATE_COUPON;
  payload: Object;
}
interface GetPhlebo {
  type: typeof ACTIONS.GET_PHLEBOS;
  payload: Array<any>;
}
interface GetAgentData {
  type: typeof ACTIONS.GET_AGENT_DATA;
  payload: Array<any>;
}

interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}
interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}
interface GetZone {
  type: typeof ACTIONS.GET_ZONE;
  payload: Object;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}
interface GetLabDetails {
  type: typeof ACTIONS.GET_LAB_DETAILS;
  payload: Array<any>;
}

interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}

interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}
interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}

interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface GetAgentByUserGroup {
  type: typeof ACTIONS.GET_AGENTS_BY_USERGROUP;
  payload: Object;
}
interface GetSmsDetails {
  type: typeof ACTIONS.GET_SMS_DETAILS;
  payload: Array<any>;
}
interface GetEmailDetails {
  type: typeof ACTIONS.GET_EMAIL_DETAILS;
  payload: Array<any>;
}
interface GetTopRecords {
  type: typeof ACTIONS.GET_TOP_RECORDS;
  payload: Array<any>;
}
interface GetSyncData {
  type: typeof ACTIONS.GET_SYNC_DATA;
  payload: Object;
}
interface GetUnregisterdBooking {
  type: typeof ACTIONS.GET_UNREGISTERD_BOOKING;
  payload: Object;
}
interface UpdateUnregisterdBookingStatus {
  type: typeof ACTIONS.UPDATE_UNREGISTERD_BOOKING_STATUS;
  payload: Object;
}
interface GetBookingCall {
  type: typeof ACTIONS.GET_BOOKING_CALLS;
  payload: Array<any>;
}
interface SameNumberBooking {
  type: typeof ACTIONS.SAME_NUMBER_BOOKING;
  payload: Object;
}
interface GetBreakResponse {
  type: typeof ACTIONS.GET_BREAK_RESPONSE;
  payload: Object;
}
interface GetPanelBreak {
  type: typeof ACTIONS.GET_PANEL_BREAK;
  payload: Object;
}
interface GetSmsReportSent {
  type: typeof ACTIONS.GET_SMS_REPORT_SEND;
  payload: Object;
}
interface GetPaymentSendLink {
  type: typeof ACTIONS.GET_PAYMENT_SEND_LINK;
  payload: Object;
}
interface GetPaymentReSendLink {
  type: typeof ACTIONS.GET_PAYMENT_RESEND_LINK;
  payload: Object;
}
interface GetPaymentCancelLink {
  type: typeof ACTIONS.GET_PAYMENT_CANCEL_LINK;
  payload: Object;
}
interface SendSmsLink {
  type: typeof ACTIONS.GET_SMS_LINK;
  payload: Object;
}
interface CreateAdditionalBooking {
  type: typeof ACTIONS.CREATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface UpdateAdditionalBooking {
  type: typeof ACTIONS.UPDATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface GetTataTalyLead {
  type: typeof ACTIONS.LEAD_TRANSFER_TO_TATATALE;
  payload: Object;
}
interface GetReportDownload {
  type: typeof ACTIONS.GET_REPORT_DOWNLOAD;
  payload: Object;
}
interface GetReportStatus {
  type: typeof ACTIONS.GET_REPORT_STATUS;
  payload: Array<any>;
}
interface GetCenterInfo {
  type: typeof ACTIONS.GET_CENTER_INFORMATION;
  payload: Array<any>;
}
interface GetPanelUserDataSalesLead {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA_SALES_LEAD;
  payload: Array<any>;
}
interface GetNotificationMessage {
  type: typeof ACTIONS.GET_NOTIFICATION_MESSAGE;
  payload: Array<any>;
}
interface GetHoldBookingList {
  type: typeof ACTIONS.GET_HOLD_BOOKING_LIST;
  payload: Array<any>;
}
interface GetVerificationBookingDetails {
  type: typeof ACTIONS.GET_VERIFICATION_BOOKING_DETAILS;
  payload: Array<any>;
}
interface GetFamilyPackages {
  type: typeof ACTIONS.GET_FAMILY_PACKAGES;
  payload: Array<any>;
}

interface GetFamilyPackagesBooking {
  type: typeof ACTIONS.GET_FAMILY_PACKAGES_BOOKING;
  payload: Array<any>;
}

interface CreateFamilyPackageBooking {
  type: typeof ACTIONS.CREATE_FAMILY_PACKAGES_BOOKING;
  payload: Object;
}
interface GetSubCenterInformation {
  type: typeof ACTIONS.GET_SUB_CENTER_INFORMATION;
  payload: Array<any>;
}
interface GetImagingTimeSlots {
  type: typeof ACTIONS.GET_IMAGING_TIMESLOTS;
  payload: Array<any>;
}
interface CreateImagingBooking {
  type: typeof ACTIONS.CREATE_IMAGING_BOOKING;
  payload: boolean;
}
interface GetMultiplepackage {
  type: typeof ACTIONS.GET_MULTIPLE_PACKAGES;
  payload: Array<any>;
}
interface GetDisplayCities {
  type: typeof ACTIONS.GET_DISPLAY_CITIES;
  payload: Array<any>;
}
interface GetMapMyIndiaAccessToken {
  type: typeof ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN;
  payload: Array<any>;
}
interface CheckGeoFenceAreaMapMyIndia {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA;
  payload: Array<any>;
}
interface GetRedTechComment {
  type: typeof ACTIONS.GET_RED_TECH_COMMENT;
  payload: Array<any>;
}
interface GetTicketData {
  type: typeof ACTIONS.GET_TICKET_DATA;
  payload: Object;
}
interface GetTicketCategory {
  type: typeof ACTIONS.GET_TICKET_CATEGORIES;
  payload: Object;
}
interface GetTicketSubCategories {
  type: typeof ACTIONS.GET_TICEKT_SUB_CATEGORIES;
  payload: Object;
}
interface PostRedTechComment {
  type: typeof ACTIONS.POST_RED_TECH_COMMENT;
  payload: Object;
}
interface CreateTaskTicket {
  type: typeof ACTIONS.CREATE_TASK_TICKET;
  payload: Array<any>;
}

export type PhleboAdminTypes =
  | SearchCall
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetArea
  | GetAgents
  | GetPhlebo
  | GetAgentByUserGroup
  | GetPackages
  | CreateBooking
  | UpdateBooking
  | GetPartners
  | GetCoupons
  | GetBookings
  | GetBookingById
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | SetComments
  | UploadFile
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetArea
  | GetAgents
  | GetPhlebo
  | GetPackages
  | CreateBooking
  | UpdateBooking
  | GetPartners
  | GetCoupons
  | GetBookings
  | GetBookingById
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | SetComments
  | SearchCall
  | GetSmsDetails
  | GetEmailDetails
  | GetTopRecords
  | GetSyncData
  | GetUnregisterdBooking
  | UpdateUnregisterdBookingStatus
  | GetBookingCall
  | SameNumberBooking
  | GetBreakResponse
  | GetPanelBreak
  | GetSmsReportSent
  | GetPaymentSendLink
  | GetPaymentReSendLink
  | GetPaymentCancelLink
  | SendSmsLink
  | CreateAdditionalBooking
  | UpdateAdditionalBooking
  | GetReportDownload
  | GetReportStatus
  | GetCenterInfo
  | GetNotificationMessage
  | GetHoldBookingList
  | GetPrescriptionData
  | UpdatePrescriptionStatus
  | GetHoldBookingCallCount
  | GetUserDetails
  | GetVerificationBookingDetails
  | GetUnmaskedNumber
  | CheckGeoFenceArea
  | GetFamilyPackages
  | GetFamilyPackagesBooking
  | CreateFamilyPackageBooking
  | GetRedCoin
  | GetReportData
  | GetHealthTestParameter
  | GetParameterCount
  | GetDispositions
  | GetSubCenterInformation
  | GetImagingTimeSlots
  | CreateImagingBooking
  | GetMultiplepackage
  | GetDisplayCities
  | GetMapMyIndiaAccessToken
  | CheckGeoFenceAreaMapMyIndia
  | GetAddressTypeSeating
  | GetHealthAdvisorAgent
  | TrasferBooking
  | GetTransferBooking
  | UpdateTicketStatus
  | GetLabDetails
  | GetZone
  | CreateCoupon
  | UpdateCoupon
  | AddRemarkOnCall
  | GetPanelUserList
  | GetBreakData
  | CreatePanelUser
  | SetUserList
  | GetPanelComments
  | GetActiveDeactivePanelUser
  | GetAgentData
  | GetAssignedUsergroup
  | UpdateUserStatus
  | UpdatedPanelUser
  | GetTataTalyLead
  | GetLeadStatus
  | GetPanelUserDataSalesLead
  | GetPanelUserDataTeamLead
  | GetTicketData
  | GetTicketCategory
  | GetTicketSubCategories
  | CreateTaskTicket
  | GetRedTechComment
  | GetTimeSlot
  |CreatePilotBooking
  | GetUserList
  | GetCeCenterForCc
  | PostRedTechComment;
