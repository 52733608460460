import React, { useState, useRef } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Paper, Table, TableBody, Select, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, withStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import Loader from '../../components/loader/index';
import { useEffect } from 'react';
import { genrateCampaignsFiltr } from '../../helpers/generateUrl'
import { useHistory } from "react-router";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  getCampaignsDetails,
  getLeadSource

} from '../actions/salesLeadAdminAction'
import {
  Container,
  Grid,
  TextField,
} from '@material-ui/core';
import Box from '@mui/material/Box';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
     
    },
    toolbar: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto"
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },

  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);


interface Props {
  getCampaignsDetails: any;
  campaingsDetails: any;
  getLeadSource: any;
  lead_source: any;
  loading: any;

}

const BookingsTable: React.FC<Props> = ({
  getCampaignsDetails,
  campaingsDetails,
  getLeadSource,
  lead_source,
  loading,

}) => {
  const history = useHistory()
  const classes = useStyles()
  const timer = useRef<any>(0)


  const [gclId, setGclId] = useState("")

  const [leadSource, setLeadSource] = React.useState<number>(0);
  const [campaignName, setCampaignsName] = React.useState<any>("");
  const [campaignsType, setCampaignsType] = React.useState<any>("");
  const [did, setDid] = React.useState<any>("");
  const [marketingNumber, setMarketingNumber] = React.useState<any>("");

  useEffect(() => {
    getLeadSource()
    getCampaignsDetails()
  }, [])


  const filterCampaignsData = () => {
    const body: any = {
      lead_source: leadSource,
      gcl_id: gclId,
      campaign_name: campaignName,
      campaign_type: campaignsType,
      did: did,
      marketing_number: marketingNumber
    }
    const url = genrateCampaignsFiltr(body).substring(2)
    getCampaignsDetails(`${url ? `${url}&page=1` : "page=1"}`)
    setPage(0)
  }

  const [page, setPage] = useState(0)

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        campaingsDetails.links && campaingsDetails.links.next.split("?")[1];
      getCampaignsDetails(url);
    } else if (newPage < page) {
      let url =
        campaingsDetails.links &&
        campaingsDetails.links.previous.split("?")[1];
      getCampaignsDetails(url);
    }
    setPage(newPage as number);
  };

  document.querySelectorAll("#noTyping").forEach((el) => {
    el.addEventListener("keydown", function (e) {
      e.preventDefault();
    });
  });
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Grid
        container
        spacing={2}
        direction="row"
        style={{ alignItems: "right" }}
      >
        <Grid item xs={12} sm={10}>
          <h2 style={{ fontWeight: "bold" }}>Campaign</h2>
        </Grid>
        <Grid container spacing={1} direction="row" style={{ alignItems: "left" }}>


          <Grid item xs={12} sm={4} md={2}>

            <Autocomplete
              id="leadsource"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setLeadSource(obj.id)
                }
              }}
              options={lead_source}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => option && option.name}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current)
                timer.current = setTimeout(() => {
                  getLeadSource(`?code=${newInputValue}`)
                }, 1000)
                if (newInputValue.length === 0) {
                  setLeadSource(0)
                }
              }}
              renderInput={(params) => (
                <TextField className="input"
                  {...params}
                  placeholder="Lead Source"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />

          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="gcl_Id"
              type="number"
              label="Gcl Id"
              value={gclId}
              variant="outlined"
              onChange={(e) => setGclId(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="campaign_name"
              type="text"
              label="Campaign Name"
              value={campaignName}
              variant="outlined"
              onChange={(e) => setCampaignsName(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              className="input"
              id="noTyping"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setCampaignsType(sourceIds);
              }}
              options={[
                {
                  text: "Online",
                  value: "online",
                },
                {
                  text: "Offline",
                  value: "offline",
                },
                {
                  text: "Online System Calling",
                  value: "onlinesystem",
                },
                {
                  text: "Offline System Calling",
                  value: "offlinesystem",
                },
                {
                  text: "Facebook",
                  value: "facebook",
                },
                {
                  text: "Google",
                  value: "google",
                },
                {
                  text: "Website",
                  value: "website",
                },
                {
                  text: "App",
                  value: "app",
                },
                {
                  text: "Other",
                  value: "other",
                },

              ]}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return campaignsType.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Campaigns Type"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="id"
              type="text"
              label="DID "
              value={did}
              variant="outlined"
              onChange={(e) => setDid(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="marketingNumber"
              type="text"
              label="Marketing Number "
              value={marketingNumber}
              variant="outlined"
              onChange={(e) => setMarketingNumber(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={filterCampaignsData}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => history.push("/dashboard/sla/campaigns")}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "700px" }}>
        {loading ? <Loader /> : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Campaign Name</StyledTableCell>
                <StyledTableCell align="center">Campaign Type </StyledTableCell>
                <StyledTableCell align="center">Did</StyledTableCell>
                <StyledTableCell align="center">Skills</StyledTableCell>
                <StyledTableCell align="center">Marketing Number</StyledTableCell>
                <StyledTableCell align="center">Lead Source</StyledTableCell>
                <StyledTableCell align="center">Gcl Id</StyledTableCell>
                <StyledTableCell align="center">Created At</StyledTableCell>

              </StyledTableRow>
            </TableHead>
            <TableBody>
              {
                campaingsDetails?.results && campaingsDetails?.results.length > 0 && campaingsDetails.results.map((data: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>

                      <StyledTableCell align="center">{data?.campaign_name}</StyledTableCell>
                      <StyledTableCell align="center">{data?.campaign_type}</StyledTableCell>
                      <StyledTableCell align="center">{data?.did}</StyledTableCell>
                      <StyledTableCell align="center">{data?.skills}</StyledTableCell>
                      <StyledTableCell align="center">{data?.marketing_number}</StyledTableCell>
                      <StyledTableCell align="center">{data?.lead_source}</StyledTableCell>
                      <StyledTableCell align="center">{data?.gcl_id}</StyledTableCell>
                      <StyledTableCell align="center">{data?.created_at}</StyledTableCell>



                    </StyledTableRow>
                  )
                })
              }
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={3}
                  count={campaingsDetails.count || 0}
                  rowsPerPage={campaingsDetails.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
        )}
      </TableContainer>

    </main>
  )
}

const mapStateToProps = (state: any) => ({
  loading: state.SalesLeadAdminReducer.loading,
  campaingsDetails: state.SalesLeadAdminReducer.campaingsDetails,
  lead_source: state.SalesLeadAdminReducer.lead_source,

})

export default connect(mapStateToProps, {
  getCampaignsDetails,
  getLeadSource,

})(BookingsTable);

