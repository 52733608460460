import React from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, } from '@material-ui/data-grid';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
    })
);

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

interface Props {
    bookingList: any;
    loading: boolean;
}

const BookingExport: React.FC<Props> = ({
    bookingList,
    loading
}) => {
    const classes = useStyles();

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 200 },
        { field: 'booking_date', headerName: 'Booking Date', width: 200 },
        { field: 'collection_date', headerName: 'Collection Date', width: 200 },
        { field: 'collection_slot', headerName: 'Collection Slot', width: 200 },
        { field: 'customer_name', headerName: 'Name', width: 200 },
        {
            field: 'customer_age',
            headerName: 'Age',
            type: 'number',
            width: 150,
        },
        { field: 'customer_gender', headerName: 'Gender', width: 200 },
        { field: 'customer_address', headerName: 'Address', width: 200 },
        { field: 'customer_phonenumber', type: "string", headerName: 'Phone No', width: 200 },
        { field: 'customer_whatsapppnumber', type: "string", headerName: 'Whatsapp No', width: 200 },
        { field: 'customer_altphonenumber', type: "string", headerName: 'Alternate No', width: 200 },
        { field: 'customer_email', headerName: 'Email', width: 200 },
        { field: 'city', headerName: 'City', width: 200 },
        { field: 'area', headerName: 'Area', width: 200 },
        { field: 'pincode', headerName: 'Pincode', width: 200 },
        { field: 'packages', headerName: 'Packages', width: 300 },
        { field: 'partnerName', headerName: 'Partner', width: 200 },
        { field: 'agentName', headerName: 'Agent', width: 200 },
        { field: 'price', headerName: 'Price', width: 200 },
        { field: 'couponDiscount', headerName: 'Coupon Discount', width: 200 },
        { field: 'phleboCharge', headerName: 'Phlebo Charge', width: 200 },
        { field: 'booking_status', headerName: 'Booking Status', width: 200 },
        { field: 'verification_status', headerName: 'Verification Status', width: 200 },
        { field: 'pickup_status', headerName: 'Pickup Status', width: 200 },
        { field: 'pickup_date', headerName: 'Pickup Date', width: 200 },
        { field: 'pickup_time', headerName: 'Pickup Time', width: 200 },
        { field: 'totalPrice', headerName: 'Total Price', width: 200 },
        { field: 'pickup_receive_amount', headerName: 'Pickup Receive Amount', width: 200 },
        { field: 'payment_source', headerName: 'Pyment Id', width: 200 },
        { field: 'payment_mode', headerName: 'Payment Mode', width: 200 },
        { field: 'phlebo', headerName: 'Phlebo Name', width: 200 },
        { field: 'phlebo_verification_remark', headerName: 'Phlebo Remark', width: 200 },
        { field: 'booking_source', headerName: 'Booking Source', width: 200 },
        { field: 'report_status', headerName: 'Report Status', width: 200 },
        { field: 'verification_remark', headerName: 'Verification Remark', width: 200 },
        { field: 'phlebo_verification_remark', headerName: 'Phlebo Verification Remark', width: 200 },
        { field: 'created_at', headerName: 'Booking Punch Time', width: 200 },
        { field: 'verify_by', headerName: 'verify by', width: 200 },
        { field: 'verification_time', headerName: 'verification_time', width: 200 },

        { field: 'disposition_status', headerName: 'Booking disposition', width: 200 },
    ];

    let rows = []
    if (bookingList.results) {
        rows = bookingList.results.length > 0 && bookingList.results.map((booking: any) => {
            let packs: any = []
            booking.packages && booking.packages.map((pack: any) => {
                packs.push(pack.name)
            })
            return {
                ...booking,
                id: booking.pk,
                collection_slot: booking.collection_slot && booking.collection_slot.slot,
                partnerName: booking.partner&&booking.partner.partner_name,
                agentName: booking.agent && booking.agent.name,
                phlebos: booking.phlebo && booking.phlebo.name,
                pincode: booking.customer_areapincode && booking.customer_areapincode.pincode,
                area: booking.customer_areapincode && booking.customer_areapincode.area,
                verify_by: booking.verify_by && booking.verify_by.name,
                packages: packs.join(", "),
                 payment_source: booking.payment_mode==="online" && booking.pickup_receive_amount!==null?(
                    booking.payment_source
                ):"",
                price: booking.discounted_price && booking.discounted_price.total_price_package && Math.floor(booking.discounted_price.total_price_package),
                couponDiscount: booking.discounted_price && booking.discounted_price.counpon_discount && Math.floor(booking.discounted_price.counpon_discount),
                phleboCharge: booking.discounted_price && booking.discounted_price.phlebo_cost && Math.floor(booking.discounted_price.phlebo_cost),
                totalPrice: booking.discounted_price && booking.discounted_price.final_total_price && Math.floor(booking.discounted_price.final_total_price),
            }
        })
    }
    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Paper elevation={15} className={classes.paper}>
                <div style={{ height: 700 }}>
                    <DataGrid rowsPerPageOptions={[]}
                        rows={rows}
                        columns={columns}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        pageSize={50}
                        rowCount={bookingList.count || 0}
                        loading={loading}
                    />
                </div>
            </Paper>
        </main>
    )
}

const mapStateToProps = (state: any) => ({

})

export default connect(mapStateToProps, {

})(BookingExport);

