import React from "react";
import SalesLeadAdminDashboardLeft from "../../SalesLeadAdmin-Panel/dashboard-left";
import SalesLeadAdminDashboardRight from "../../SalesLeadAdmin-Panel/dashboard-right";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        padding: "20px"
    }
});

interface Props {
    children: any;
}

const AdminDash: React.FC<Props> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <SalesLeadAdminDashboardLeft />
            <SalesLeadAdminDashboardRight>{children && children}</SalesLeadAdminDashboardRight>
        </div>
    );
};

export default AdminDash;
