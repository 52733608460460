import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { Container, Box, Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  getImagingPackageAlias,
  getPackage,
  CreateImagingAlias,
  getCities,
} from "../../actions/ImagingAdminAction";
import PackageCenterAddresstabnle from "./packagecenteraddress";
import { genrateCenterAddressPriceFilter } from "../../../helpers/generateUrl";
import { useHistory } from "react-router";
import { ACTIONS } from "../../interfaces/actionTypes/ImagingAdminType";
import { useDispatch } from "react-redux";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
  })
);

interface Props {
  loading: boolean;
  getImagingPackageAlias: any;
  imagingPackageAlias: any;
  packageList: any;
  getPackage: any;
  CreateImagingAlias: any;
  getSubCenterInfo: any;
  subCenterInfor: any;
  getCenterAddressPrice: any;
  centerAddressPrice: any;
  getDayWiseTimeSlotList: any;
  daywisetimeslot: any;
  getTimeslots: any;
  timeSlots: any;
  updateDayWiseimeSlots: any;
  updatedCenterPackage: any;
  createCenterPackage: any;
  dayList: any;
  setopenModal: any;
  setpackId: any;
  getCities: any;
  cities: any;
}

const CustomUploader: React.FC<Props> = ({
  loading,
  getImagingPackageAlias,
  imagingPackageAlias,
  packageList,
  getPackage,
  CreateImagingAlias,
  getSubCenterInfo,
  subCenterInfor,
  getCenterAddressPrice,
  centerAddressPrice,
  getDayWiseTimeSlotList,
  daywisetimeslot,
  getTimeslots,
  timeSlots,
  updateDayWiseimeSlots,
  updatedCenterPackage,
  createCenterPackage,
  setopenModal,
  setpackId,
  dayList,
  getCities,
  cities,
}) => {
  const timer = useRef<any>(0);
  let dispatch = useDispatch();
  const classes = useStyles();
  const [package_Id, setPackageId] = useState<any>("");
  const [alias, setAlias] = useState<any>("");
  const [city, setCity] = useState<any>("");
  const [centerId, setCenterId] = useState<any>("");
  const [currentUrl, setCurrentUrl] = useState<any>("");
  const [pincodeTxt, setPincodeTxt] = useState<any>("");
  const [openPackageModal, setOpenPackageModal] = useState<boolean>(false);
  const [orgType, setOrgType] = useState<any>("imaging");
  const history = useHistory();

  useEffect(() => {
    getImagingPackageAlias(`?org_type=imaging`);
    getPackage(`?org_type=${orgType}`);
    getSubCenterInfo(`?imaging=true`);
  }, []);
  useEffect(() => {
    if(pincodeTxt.length===6){
      getSubCenterInfo(`?imaging=true&pincode=${pincodeTxt}`)
    }
  }, [pincodeTxt])
  const filterCenter = async () => {
    const body: any = {
      center_address: centerId,
      package_id: package_Id,
    };
    const url = genrateCenterAddressPriceFilter(body).substring(2);
    setCurrentUrl(url);
    getCenterAddressPrice(`?${url}`);
  };

  const handleReset = () => {
    dispatch({ type: ACTIONS.GET_CENTER_ADDRESS_PRICE, payload: [] });
    history.push(`/dashboard/ia/testmaster`);
  };
  const handleAddPackage = () => {
    getTimeslots();
    setOpenPackageModal(true);
  };
  useEffect(() => {
    getPackage(`?org_type=${orgType}`);
  }, [orgType]);

  return (
    <main className={classes.content}>
      <Grid
        container
        spacing={1}
        direction="row"
        style={{ alignItems: "right" }}
      >
        <Grid item xs={12} sm={6} md={2}>
            <TextField
              className="input"
              label="Pincode"
              variant="outlined"
              fullWidth
              onChange={(e) =>
                setPincodeTxt(
                  String(e.target.value.replace(/[^0-9]/g, "")).trimStart()
                    .length <=6
                    ? String(e.target.value.replace(/[^0-9]/g, "")).trimStart()
                    : pincodeTxt
                )
              }
            />
          </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Autocomplete
            id="city"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setCity(obj.id);
                getSubCenterInfo(`?imaging=true&pincode=${pincodeTxt.length===6?pincodeTxt:""}&city=${obj.id}`);
              }
            }}
            options={cities}
            freeSolo
            blurOnSelect
            aria-required
          
            getOptionLabel={(option: any) => option.name}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getCities(newInputValue);
                if (newInputValue.length === 0) {
                  setCity("");
                }
              }, 1000);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                className="input"
                label="City"
                variant="outlined"
                style={{ width: "100%", marginBottom: "1rem" }}
              
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            id="center"
            key={city}
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setCenterId(obj.id);
              }
            }}
            options={subCenterInfor.length > 0 ? subCenterInfor : []}
            freeSolo
            blurOnSelect
            aria-required
            loading={loading}
            getOptionLabel={(option: any) =>
              `Center:${option?.center?.name},Address:${option?.address}`
            }
            disableClearable
            disableCloseOnSelect
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getSubCenterInfo(`?imaging=true&pincode=${pincodeTxt.length===6?pincodeTxt:""}&city=${city}&name=${newInputValue}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setCenterId("");
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="CENTRE NAME "
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Autocomplete
            id="test"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setPackageId(obj.id);
              }
            }}
            options={packageList?.results || []}
            freeSolo
            blurOnSelect
            aria-required
            limitTags={1}
            getOptionLabel={(option: any) => option.name}
            disableClearable
            disableCloseOnSelect
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getPackage(`?org_type=imaging&code=${newInputValue}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setPackageId("");
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="TEST NAME"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={1}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={filterCenter}
            disabled={centerId === "" && package_Id === ""}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={1}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleReset}
          >
            Reset
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleAddPackage}
          >
            Add Test
          </Button>
        </Grid>
      </Grid>
      <PackageCenterAddresstabnle
        getImagingPackageAlias={getImagingPackageAlias}
        imagingPackageAlias={imagingPackageAlias}
        getCenterAddressPrice={getCenterAddressPrice}
        centerAddressPrice={centerAddressPrice}
        getDayWiseTimeSlotList={getDayWiseTimeSlotList}
        daywisetimeslot={daywisetimeslot}
        getTimeslots={getTimeslots}
        timeSlots={timeSlots}
        updateDayWiseimeSlots={updateDayWiseimeSlots}
        updatedCenterPackage={updatedCenterPackage}
        createCenterPackage={createCenterPackage}
        currentUrl={currentUrl}
        packageList={packageList}
        getPackage={getPackage}
        getSubCenterInfo={getSubCenterInfo}
        subCenterInfor={subCenterInfor}
        setOpenPackageModal={setOpenPackageModal}
        openPackageModal={openPackageModal}
        dayList={dayList}
        setopenModal={setopenModal}
        setpackId={setpackId}
        setOrgType={setOrgType}
      />
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.ImagingAdminReducer.loading,
  imagingPackageAlias: state.ImagingAdminReducer.imagingPackageAlias,
  packageList: state.ImagingAdminReducer.packageList,
  cities: state.ImagingAdminReducer.cities,
});

export default connect(mapStateToProps, {
  getImagingPackageAlias,
  getPackage,
  CreateImagingAlias,
  getCities,
})(CustomUploader);