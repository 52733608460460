import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Container,
    Grid,
    TextField,
    Select,
    MenuItem,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import Menu from '@material-ui/core/Menu';
import Button from '@mui/material/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { generateBookingFilterUrl } from "../../../helpers/generateUrl";
import { connect } from "react-redux";
import { getFailedReportData } from '../../actions/salesLeadAdminAction'
import { useHistory } from "react-router-dom";
import Loader from "../../Components/loader/index";
import { CheckBoxOutlineBlank, CheckBoxRounded } from "@material-ui/icons";
import Modal from '@mui/material/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';
import BookingTable from '../ConsolidateReportBooking/table'
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        card: {
            height: "100%",
            padding: theme.spacing(1),
        },
        card_grid: {
            // wordSpacing:3,
            justifyContent: 'space-between'
        },
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    })
);

interface Props {
    children: any;
    agentsList: any;
    consolidateBookingData: any;
    partnerList: any;
    getAgentList: any;
    getPartners: any;
    getCities: any;
    cities: any;
    getPhlebos: any;
    getPaymentInfo: any;
    paymentInformation: any;
    phleboList: any;
    getLeadSource: any;
    lead_source: any;
    getCollectionSlot: any;
    collectionSlot: any;
    loading: Boolean;
    getVerifiedbyList: any;
    verifiedbyList: any;
    getPrescriptionData: any;
    prescription: any
    getSmsReportSend: any
    getFailedReportData: any;
    failedReport: any;

}

const CustomerLeadFilterPage: React.FC<Props> = ({
    agentsList,
    partnerList,
    getAgentList,
    getPartners,
    consolidateBookingData,
    getCities,
    cities,
    getPhlebos,
    phleboList,
    getLeadSource,
    getPaymentInfo,
    paymentInformation,
    lead_source,
    getCollectionSlot,
    collectionSlot,
    loading,
    getVerifiedbyList,
    verifiedbyList,
    getPrescriptionData,
    prescription,
    getSmsReportSend,
    getFailedReportData,
    failedReport,
}) => {
    const classes = useStyles();
    const history = useHistory()
    const [start_date, setStart_date] = useState("")
    const [end_date, setEnd_date] = useState("")
    const [booking_date, setBooking_date] = useState("")
    const [collection_date, setCollection_date] = useState("")
    const [name, setName] = useState("")
    const [lead, setLeadId] = useState<any>("")
    const [phlebo, setPhlebo] = useState<any>([])
    const [phone, setPhone] = useState("")
    const [bookingId, setBookingId] = useState<any>("")
    const [disposition, setDisposition] = useState<any>([])
    const [sample_registered, setSampleRegisterd] = useState<any>("none")
    const [report_status, setReportStatus] = useState<any>("none")
    const [paymentId, setPaymentId] = useState<any>(0)
    const [status, setStatus] = useState<String>("none");
    const [page, setPage] = useState(0)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [booking_type, setbooking_type] = useState<String>("none");
    const timer = useRef<any>(0)

    useEffect(() => {

        if (history.action !== "POP") {
            getFailedReportData()

        }

    }, [])

    useEffect(() => {
        if (paymentId != 0) {
            getPaymentInfo(`?booking=${paymentId}`);
        }

    }, [paymentId])
    const filterBookings = () => {
        const body: any = {
            name,
            lead,
            phone,
            start_date,
            end_date,
            bookingId,
            sample_registered,
            report_status,
            booking_type: booking_type,
            report_not_status: status
        }
        const url = generateBookingFilterUrl(body).substring(2)
        getFailedReportData(url);
        setPage(0)
    }

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <h2>Report Not Deliverd </h2>
                <Grid container spacing={1} direction="row" style={{ alignItems: "left" }}>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="name"
                            type="number"
                            label="Booking Id"
                            value={bookingId}
                            variant="outlined"
                            onChange={(e) => setBookingId(e.target.value)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="leadId"
                            type="number"
                            label="Lead Id"
                            value={lead}
                            variant="outlined"
                            onChange={(e) => setLeadId(e.target.value)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="name"
                            type="text"
                            label="Name"
                            value={name}
                            variant="outlined"
                            onChange={(e) => setName(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="start_date"
                            type="date"
                            label="Start Date"
                            value={booking_date === "" || collection_date === "" ? start_date : ""}
                            variant="outlined"
                            disabled={booking_date !== "" || collection_date !== ""}
                            onChange={(e) => setStart_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="end_date"
                            type="date"
                            value={booking_date === "" || collection_date === "" ? end_date : ""}
                            className="input"
                            label="End Date"
                            variant="outlined"
                            disabled={booking_date !== "" || collection_date !== ""}
                            onChange={(e) => setEnd_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="bookingtype"
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    setbooking_type(obj.value);
                                }

                            }}
                            options={[
                                {
                                    text: "B2B",
                                    value: "b2b",
                                },
                                {
                                    text: "B2C",
                                    value: "b2c",
                                },
                            ]}
                            freeSolo
                            blurOnSelect
                            aria-required
                            getOptionLabel={(option: any) => option.text}
                            disableClearable
                            disableCloseOnSelect
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Booking Type"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="Status"
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    setStatus(obj.value);
                                }

                            }}
                            options={[
                                {
                                    text: "Pending",
                                    value: "pending",
                                },
                                {
                                    text: "Confirm",
                                    value: "confirmed",
                                },
                            ]}
                            freeSolo
                            blurOnSelect
                            aria-required
                            getOptionLabel={(option: any) => option.text}
                            disableClearable
                            disableCloseOnSelect
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Status"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={filterBookings}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => history.push("/dashboard/sla/report_not_deliverd")}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <br />
                {loading ? <Loader /> : failedReport?.results && failedReport?.results?.length > 0 && (
                    <Box>
                        <Container maxWidth={false}>
                            <Grid container spacing={3}>
                                <BookingTable
                                    page={page}
                                    setPage={setPage}
                                    loading={loading}
                                    paymentId={paymentId}
                                    getFailedReportData={getFailedReportData}
                                    setPaymentId={setPaymentId}
                                    setLeadId={setLeadId}
                                    failedReport={failedReport}

                                />
                            </Grid>
                        </Container>
                    </Box>
                )}

                {failedReport.results && failedReport.results.length === 0 && (
                    <h6 style={{ textAlign: "center" }}>No Data Found</h6>
                )}

            </main>
        </>
    );
};

const mapStateToProps = (state: any) => {

    return {

        failedReport: state.SalesLeadAdminReducer.failedReport,
    }
}

export default connect(mapStateToProps, {
    getFailedReportData
})(CustomerLeadFilterPage);
