import { ACTIONS, PhleboAdminTypes, } from "../interfaces/actionTypes/HealthAdviserType";

type InitialState = {
    pin_code: Array<any>;
    cities: Array<any>;
    areaList: Array<any>;
    agentsList: Array<any>;
    phleboList: Array<any>;
    packageList: Array<any>;
    partnerList: Array<any>;
    couponList: Array<any>;
    createBooking: Object;
    booking: Object;
    todays_positive_calls: Object;
    lead_details: Object;
    leadById: Object;
    leads: Array<any>;
    bookingList: Array<any>;
    comment: any;
    data: any;
    users: any;
    bookingsComment: any;
    lead_id: number;
    read_call_details: object;
    all_recordings: object;
    whatsappComments: object;
    whatsappCommentsByLead: object;
    callStats: object;
    complaint: object;
    complaints: object;
    lead_source: any;
    booking_slots: object;
    search_results: object,
    call_status: object;
    all_lead: object;
    call_transfer: object;
    user_panel: object;
    agentBYUserGroup: object;
    smsDetails: Array<any>;
    emailDetails: Array<any>;
    timeslots: Array<any>;
    loading: boolean;
    syncData: Object;
    topRecord: object;
    unregisterdBooking: Array<any>;
    bookingCall: Array<any>;
    unregisterBookingStatus: Array<any>;
    sameNumberBooking: boolean;
    breakResponse: Array<any>;
    panelBreak: Array<any>;
    smsReportSend: Array<any>;
    paymentSendLink: Array<any>;
    paymentReSendLink: Array<any>;
    paymentCancelLink: Array<any>;
    createPilotBooking: Object;
    sendSMSLink: Array<any>;
    reportDownload: Array<any>;
    additional_booking: object,
    reportStatus: Array<any>;
    centerInfo: Array<any>;
    notificationMessage: Array<any>;
    holdBookingList: Array<any>;
    prescriptionData: Array<any>;
    prescriptionStatus: Array<any>;
    holdBookingCallCount: Array<any>;
    userDetails: Array<any>;
    verificationBookingDetails: Array<any>;
    unmaskedNumber: Array<any>;
    checkGeoFenceStatus: boolean;
    createFamilyPackageBooking: Object;
    familyPackageBookingList: Array<any>;
    familyPackageList: Array<any>;
    addressType: Array<any>;
    ceCenterForCC: Array<any>;
    redCoin: Array<any>;
    reportData: Array<any>;
    healthTestParameter: Array<any>;
    parameterCount: Array<any>;
    dispositions: object;
    subCenter: Array<any>;
    imagingTimeSlots: Array<any>;
    createIMGBooking: object;
    multiplePackage: Array<any>;
    displayCities: Array<any>;
    mapmyindiaAccessToken: Array<any>;
    geoFencesMapMyIndia: Array<any>;
    transferBookingData: Array<any>;
    ticketData: Array<any>;
    ticketCategories: Array<any>;
    ticketSubCategories: Array<any>;
    redtechcomment: Array<any>;

};

const initialState: InitialState = {
    pin_code: [],
    cities: [],
    areaList: [],
    agentsList: [],
    phleboList: [],
    packageList: [],
    partnerList: [],
    couponList: [],
    createBooking: {},
    booking: {},
    dispositions: {},
    lead_details: {},

    leadById: {},
    todays_positive_calls: {},
    leads: [],
    bookingList: [],
    comment: {},
    data: {},
    users: {},
    addressType: [],
    loading: false,
    read_call_details: {},
    all_recordings: {},
    whatsappComments: {},
    whatsappCommentsByLead: {},
    callStats: {},
    complaint: {},
    complaints: {},
    bookingsComment: {},
    lead_id: 0,
    lead_source: {},
    booking_slots: {},
    search_results: {},
    call_status: {},
    all_lead: {},
    call_transfer: {},
    agentBYUserGroup: {},
    smsDetails: [],
    emailDetails: [],
    user_panel: {},
    topRecord: {},
    syncData: [],
    unregisterdBooking: [],
    bookingCall: [],
    unregisterBookingStatus: [],
    sameNumberBooking: false,
    breakResponse: [],
    panelBreak: [],
    smsReportSend: [],
    paymentSendLink: [],
    paymentReSendLink: [],
    paymentCancelLink: [],
    sendSMSLink: [],
    additional_booking: {},
    reportDownload: [],
    reportStatus: [],
    centerInfo: [],
    notificationMessage: [],
    holdBookingList: [],
    prescriptionData: [],
    prescriptionStatus: [],
    holdBookingCallCount: [],
    userDetails: [],
    verificationBookingDetails: [],
    unmaskedNumber: [],
    checkGeoFenceStatus: false,
    familyPackageList: [],
    familyPackageBookingList: [],
    redCoin: [],
    reportData: [],
    createFamilyPackageBooking: {},
    healthTestParameter: [],
    parameterCount: [],
    subCenter: [],
    imagingTimeSlots: [],
    createIMGBooking: {},
    ceCenterForCC: [],
    timeslots: [],
    createPilotBooking: {},
    multiplePackage: [],
    displayCities: [],
    mapmyindiaAccessToken: [],
    geoFencesMapMyIndia: [],
    transferBookingData: [],
    ticketData: [],
    ticketCategories: [],
    ticketSubCategories: [],
    redtechcomment: [],
};

const CustomerLeadReducer = (state = initialState, action: PhleboAdminTypes) => {
    switch (action.type) {
        case ACTIONS.GET_PIN_CODE:
            return {
                ...state,
                pin_code: action.payload,
                loading: false,
            };
        case ACTIONS.GET_HOLD_BOOKING_CALL_COUNT:
            return {
                ...state,
                holdBookingCallCount: action.payload,
                loading: false,
            };
        case ACTIONS.UPDATE_PRESCRIPTION_STATUS:
            return {
                ...state,
                prescriptionStatus: action.payload,
                loading: false,
            };
        case ACTIONS.GET_PRESCRIPTION_DATA:
            return {
                ...state,
                prescriptionData: action.payload,
                loading: false,
            };
        case ACTIONS.GET_HOLD_BOOKING_LIST:
            return {
                ...state,
                holdBookingList: action.payload,
                loading: false,
            };
        case ACTIONS.UPLOAD_FILE:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case ACTIONS.GET_PHLEBOS:
            return {
                ...state,
                phleboList: action.payload,
                loading: false,
            };
        case ACTIONS.GET_CITIES:
            return {
                ...state,
                cities: action.payload,
                loading: false,
            };
        case ACTIONS.GET_AREA:
            return {
                ...state,
                areaList: action.payload,
                loading: false,
            };
        case ACTIONS.GET_POSTIVE_CALLS_COMMENTS:
            return {
                ...state,
                todays_positive_calls: action.payload,
                loading: false,
            };
            case ACTIONS.GET_RED_TECH_COMMENT:
                return {
                  ...state,
                  redtechcomment: action.payload,
                  loading: false,
                };
        
        case ACTIONS.SET_COMMENTS:
            return {
                ...state,
                comment: action.payload,
                loading: false,
            };
        case ACTIONS.ADD_POSTIVE_CALLS_COMMENT:
            return {
                ...state,
                todays_positive_calls: action.payload,
                loading: false,
            };
        case ACTIONS.GET_LEAD_DETAILS:
            return {
                ...state,
                lead_details: action.payload,
                loading: false,
            };
        case ACTIONS.GET_LEADS:
            return {
                ...state,
                leads: action.payload,
                loading: false,
            };
        case ACTIONS.GET_LEAD_BY_ID:
            return {
                ...state,
                leadById: action.payload,
                loading: false,
            };
        case ACTIONS.LEAD_DETAILS_RESET:
            return {
                ...state,
                leadById: {},
                lead_details: {},
                loading: false,
            };
        case ACTIONS.GET_AGENTS:
            return {
                ...state,
                agentsList: action.payload,
                loading: false,
            };
        case ACTIONS.GET_AGENTS_BY_USERGROUP:
            return {
                ...state,
                agentBYUserGroup: action.payload,
                loading: false,
            };
        case ACTIONS.GET_PACKAGES:
            return {
                ...state,
                packageList: action.payload,
                loading: false,
            };
        case ACTIONS.GET_PARTNERS:
            return {
                ...state,
                partnerList: action.payload,
                loading: false,
            };
        case ACTIONS.GET_COUPONS:
            return {
                ...state,
                couponList: action.payload,
                loading: false,
            };
        case ACTIONS.GET_ADDRESS_TYPE_SEATING:
            return {
                ...state,
                addressType: action.payload,
                loading: false,
            };
        case ACTIONS.CREATE_BOOKING:
            return {
                ...state,
                createBooking: action.payload,
                loading: false,
            };
        case ACTIONS.GET_BOOKING_LIST:
            return {
                ...state,
                bookingList: action.payload,
                loading: false,
            };
        case ACTIONS.GET_BOOKING_BY_ID:
            return {
                ...state,
                booking: action.payload,
                loading: false,
            };
        case ACTIONS.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ACTIONS.CREATE_CALL:
            return {
                ...state,
                loading: false,
                lead_id: action.payload,
            };
        case ACTIONS.GET_RECORDINGS:
            return {
                ...state,
                all_recordings: action.payload,
                loading: false,
            };
        case ACTIONS.GET_LEAD_SOURCE:
            return {
                ...state,
                lead_source: action.payload,
                loading: false,
            };
        case ACTIONS.SEARCH_CALL:
            return {
                ...state,
                search_results: action.payload,
                loading: false,
            };
        case ACTIONS.ADD_COMMENT:
            return {
                ...state,
                comments: action.payload,
                loading: false,
            };
        case ACTIONS.BOOKING_RESET:
            return {
                ...state,
                createBooking: {},
                bookingList: [],
                loading: false,
            };
        case ACTIONS.GET_BOOKINGS_COMMENTS:
            return {
                ...state,
                bookingsComment: action.payload,
                loading: false,
            };
        case ACTIONS.ADD_BOOKINGS_COMMENT:
            return {
                ...state,
                bookingsComment: action.payload,
                loading: false,
            };
        case ACTIONS.GET_WHATSAPP_COMMENTS:
            return {
                ...state,
                whatsappComments: action.payload,
                loading: false,
            };
        case ACTIONS.GET_WHATSAPP_COMMENTS_BY_LEAD:
            return {
                ...state,
                whatsappCommentsByLead: action.payload,
                loading: false,
            };
        case ACTIONS.GET_DISPOSITIONS:
            return {
                ...state,
                dispositions: action.payload,
                loading: false,
            };
        case ACTIONS.SEND_WHATSAPP_COMMENTS:
            return {
                ...state,
                whatsappComments: action.payload,
                loading: false,
            };
        case ACTIONS.GET_CALL_STATS:
            return {
                ...state,
                callStats: action.payload,
                loading: false,
            };
        case ACTIONS.RAISE_COMPLAINT:
            return {
                ...state,
                complaint: action.payload,
                loading: false,
            };
        case ACTIONS.GET_COMPLAINTS:
            return {
                ...state,
                complaints: action.payload,
                loading: false,
            };
            case ACTIONS.CREATE_PILOT_BOOKING:
                return {
                  ...state,
                  createPilotBooking: action.payload,
                  loading: false,
                };
            case ACTIONS.GET_TIMESLOTS:
                return {
                  ...state,
                  timeslots: action.payload,
                  loading: false,
                };
        case ACTIONS.ASSIGN_DOCTOR:
            return {
                ...state,
                success: true,
                loading: false,
            };
        case ACTIONS.SEND_FEEDBACK:
            return {
                ...state,
                success: true,
                loading: false,
            };
        case ACTIONS.GET_AVAILABLE_SLOTS:
            return {
                ...state,
                booking_slots: action.payload,
                loading: false,
            };
        case ACTIONS.GET_ALL_LEADS:
            return {
                ...state,
                all_lead: action.payload,
                loading: false,
            };
        case ACTIONS.GET_PANEL_USERS:
            return {
                ...state,
                user_panel: action.payload,
                loading: false,
            };
        case ACTIONS.LEAD_TRANSFER_BY_COMMA:
            return {
                ...state,
                all_lead: action.payload,
                loading: false,
            };
        case ACTIONS.GET_SMS_DETAILS:
            return {
                ...state,
                smsDetails: action.payload,
                loading: false,
            };
        case ACTIONS.GET_EMAIL_DETAILS:
            return {
                ...state,
                emailDetails: action.payload,
                loading: false,
            };
        case ACTIONS.GET_TOP_RECORDS:
            return {
                ...state,
                topRecord: action.payload,
                loading: false,
            };
        case ACTIONS.GET_SYNC_DATA:
            return {
                ...state,
                syncData: action.payload,
                loading: false,
            };
        case ACTIONS.GET_UNREGISTERD_BOOKING:
            return {
                ...state,
                unregisterBooking: action.payload,
                loading: false,
            };
        case ACTIONS.UPDATE_UNREGISTERD_BOOKING_STATUS:
            return {
                ...state,
                unregisterBookingStatus: action.payload,
                loading: false,
            };
        case ACTIONS.GET_BOOKING_CALLS:
            return {
                ...state,
                bookingCall: action.payload,
                loading: false,
            };
        case ACTIONS.SAME_NUMBER_BOOKING:
            return {
                ...state,
                sameNumberBooking: action.payload,
                loading: false,
            };
        case ACTIONS.GET_BREAK_RESPONSE:
            return {
                ...state,
                breakResponse: action.payload,
                loading: false,
            };
            case ACTIONS.GET_CE_CENTER_FOR_CC:
                return {
                  ...state,
                  ceCenterForCC: action.payload,
                  loading: false,
                };
        case ACTIONS.GET_PANEL_BREAK:
            return {
                ...state,
                panelBreak: action.payload,
                loading: false,
            };
        case ACTIONS.GET_SMS_REPORT_SEND:
            return {
                ...state,
                smsReportSend: action.payload,
                loading: false,
            };
        case ACTIONS.GET_PAYMENT_SEND_LINK:
            return {
                ...state,
                paymentSendLink: action.payload,
                loading: false,
            };
        case ACTIONS.GET_PAYMENT_RESEND_LINK:
            return {
                ...state,
                paymentReSendLink: action.payload,
                loading: false,
            };
        case ACTIONS.GET_PAYMENT_CANCEL_LINK:
            return {
                ...state,
                paymentCancelLink: action.payload,
                loading: false,
            };
        case ACTIONS.GET_SMS_LINK:
            return {
                ...state,
                sendSMSLink: action.payload,
                loading: false,
            };
        case ACTIONS.CREATE_ADDITIONAL_BOOKING:
            return {
                ...state,
                additional_booking: action.payload,
                loading: false,
            };
        case ACTIONS.UPDATE_ADDITIONAL_BOOKING:
            return {
                ...state,
                additional_booking: action.payload,
                loading: false,
            };
        case ACTIONS.GET_REPORT_DOWNLOAD:
            return {
                ...state,
                reportDownload: action.payload,
                loading: false,
            };
        case ACTIONS.GET_REPORT_STATUS:
            return {
                ...state,
                reportStatus: action.payload,
                loading: false,
            };
        case ACTIONS.GET_CENTER_INFORMATION:
            return {
                ...state,
                centerInfo: action.payload,
                loading: false,
            };
        case ACTIONS.GET_NOTIFICATION_MESSAGE:
            return {
                ...state,
                notificationMessage: action.payload,
                loading: false,
            };
        case ACTIONS.GET_USER_DETAILS:
            return {
                ...state,
                userDetails: action.payload,
                loading: false,
            };
        case ACTIONS.GET_VERIFICATION_BOOKING_DETAILS:
            return {
                ...state,
                verificationBookingDetails: action.payload,
                loading: false,
            };
        case ACTIONS.GET_UNMASKED_NUMBER:
            return {
                ...state,
                unmaskedNumber: action.payload,
                loading: false,
            };
        case ACTIONS.CHECK_GEOFENCE_AREA:
            return {
                ...state,
                checkGeoFenceStatus: action.payload,
                loading: false,
            };
        case ACTIONS.GET_FAMILY_PACKAGES:
            return {
                ...state,
                familyPackageList: action.payload,
                loading: false,
            };
        case ACTIONS.GET_FAMILY_PACKAGES_BOOKING:
            return {
                ...state,
                familyPackageBookingList: action.payload,
                loading: false,
            };
        case ACTIONS.CREATE_FAMILY_PACKAGES_BOOKING:
            return {
                ...state,
                createFamilyPackageBooking: action.payload,
                loading: false,
            };
        case ACTIONS.GET_RED_COIN:
            return {
                ...state,
                redCoin: action.payload,
                loading: false,
            };
        case ACTIONS.GET_REPORT_DATA:
            return {
                ...state,
                reportData: action.payload,
                loading: false,
            };
        case ACTIONS.GET_HEALTH_TEST_PARAMETER:
            return {
                ...state,
                healthTestParameter: action.payload,
                loading: false,
            };
        case ACTIONS.GET_PARAMETER_COUNT:
            return {
                ...state,
                parameterCount: action.payload,
                loading: false,
            };
        case ACTIONS.GET_SUB_CENTER_INFORMATION:
            return {
                ...state,
                subCenter: action.payload,
                loading: false,
            };
        case ACTIONS.GET_IMAGING_TIMESLOTS:
            return {
                ...state,
                imagingTimeSlots: action.payload,
                loading: false,
            };
        case ACTIONS.CREATE_IMAGING_BOOKING:
            return {
                ...state,
                createIMGBooking: action.payload,
                loading: false,
            };
        case ACTIONS.GET_MULTIPLE_PACKAGES:
            return {
                ...state,
                multiplePackage: action.payload,
                loading: false,
            };
        case ACTIONS.GET_DISPLAY_CITIES:
            return {
                ...state,
                displayCities: action.payload,
                loading: false,
            };
        case ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN:
            return {
                ...state,
                mapmyindiaAccessToken: action.payload,
                loading: false,
            };
        case ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA:
            return {
                ...state,
                geoFencesMapMyIndia: action.payload,
                loading: false,
            };
        case ACTIONS.GET_TRANSFER_BOOKING:
            return {
                ...state,
                transferBookingData: action.payload,
                loading: false,
            };
        case ACTIONS.GET_TICKET_DATA:
            return {
                ...state,
                ticketData: action.payload,
                loading: false,
            };
        case ACTIONS.GET_TICKET_CATEGORIES:
            return {
                ...state,
                ticketCategories: action.payload,
                loading: false,
            };
        case ACTIONS.GET_TICEKT_SUB_CATEGORIES:
            return {
                ...state,
                ticketSubCategories: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

export default CustomerLeadReducer;
