import {
  combineReducers
} from 'redux';

import modalReducer from './modalReducer';
import secondModalReducer from './secondModalReducer';
import snackBarReducer from './snackBarReducer';
import loginReducer from './loginReducer';
import BookingReducer from "../CustomerExecutive-Panel/reducers/BookingReducer";
import CustomerLeadReducer from "../CustomerLead-Panel/reducers/CustomerLeadReducer";
import CollectionCenterReducer from "../CollectionCenter-Panel/reducers/CollectionCenterReducer";
import CollectionCenterAdminReducer from "../CollectionCenter-Admin/reducers/CollectionCenterAdmin";
import SalesLeadReducer from "../SalesLead-Panel/reducers/SalesLeadReducer";
import SalesLeadAdminReducer from "../SalesLeadAdmin-Panel/reducers/SalesLeadAdminReducer";
import ImagingAdminReducer from '../ImagingAdmin-Panel/reducers/ImagingAdminReducer'
import HealthAdviserReducer from '../HealthAdviser-Panel/reducers/HealthAdviserReducer'
import HealthAdviserAdminReducer from '../HealthAdviserAdmin-Panel/reducers/HealthAdviserAdminReducer'
// import notificationReducer from './notificationReducer';


const rootReducer = combineReducers({
  modalReducer,
  secondModalReducer,
  snackBarReducer,
  loginReducer,
  BookingReducer,
  CollectionCenterReducer,
  CustomerLeadReducer,
  SalesLeadReducer,
  SalesLeadAdminReducer,
  CollectionCenterAdminReducer,
  ImagingAdminReducer,
  HealthAdviserReducer,
  HealthAdviserAdminReducer,
});

export default rootReducer;