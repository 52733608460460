import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Card,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@material-ui/core";
import {
  getPinCode,
  getPackage,
  updateBooking,
  getBookingById,
  getPartners,
  getCoupons,
  getAvailableSlots,
} from "../../../actions/bookingActions";
import Button from '@mui/material/Button';
import "./index.sass";
import { connect } from "react-redux";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router";
import { Chip } from "@material-ui/core";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { GoogleMapsAPI } from "../../../../helpers/client-config";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
  })
);

interface Props {
  getPinCode: any;
  pin_code: any;
  getPackage: any;
  packageList: any;
  partnerList: any;
  getPartners: any;
  couponList: any;
  getCoupons: any;
  updateBooking: any;
  match: any;
  booking: any;
  getBookingById: any;
  booking_slots: any;
  getAvailableSlots: any;
  loading: boolean;
}

const EditBookingForm: React.FC<Props> = ({
  getPinCode,
  pin_code,
  packageList,
  getPackage,
  partnerList,
  couponList,
  getPartners,
  getCoupons,
  updateBooking,
  match,
  booking,
  getBookingById,
  booking_slots,
  getAvailableSlots,
  loading,
}) => {
  const classes = useStyles();

  const history = useHistory();

  const [collection_date, setCollection_date] = useState<String>(
    booking.collection_date
  );
  const [booking_date, setBooking_date] = useState<string>(
    booking.booking_date
  );
  const [pincode, setPinCode] = useState<String>(
    booking.customer_areapincode && booking.customer_areapincode.pincode
  );
  const [zoneName, setZoneName] = useState<String>(
    booking.customer_areapincode && booking.customer_areapincode.area
  );
  const [timeSlots, setTimeSlots] = useState<Array<Object>>([]);
  const [selectedSlot, setSelectedSlot] = useState<Number>(
    booking.collection_slot && booking.collection_slot.id
  );

  const [customer_name, setCustomer_name] = useState<String>(
    booking.customer_name
  );
  const [customer_designation, setCustomer_designation] = useState<String>(
    booking.designation
  );
  const [customer_age, setCustomer_age] = useState<String>(
    booking.customer_age
  );
  const [customer_gender, setCustomer_gender] = useState<String>(
    booking.customer_gender
  );
  const [customer_phonenumber, setCustomer_phonenumber] = useState<String>(
    booking.customer_phonenumber
  );
  const [customer_alternatenumber, setCustomer_alternatenumber] =
    useState<String>(booking.customer_altphonenumber);
  const [customer_whatsapppnumber, setCustomer_whatsapppnumber] =
    useState<String>(booking.customer_whatsapppnumber);
  const [customer_email, setCustomer_email] = useState<String>(
    booking.customer_email
  );
  const [customer_landmark, setCustomer_landmark] = useState<String>(
    booking.customer_landmark
  );
  const [landmark, setLandmark] = useState<any>(null);
  const [geoLocation, setGeoLocation] = useState<any>([]);
  const [customer_longitude, setCustomerLongitude] = useState<any>("");
  const [customer_latitude, setCustomerLatitude] = useState<any>("");
  const [customer_areapincode, setCustomer_areapincode] = useState<Number>(0);
  const [customer_areaname, setCustomer_areaname] = useState<String>(
    booking.customer_areapincode && booking.customer_areapincode.area
  );
  //  const [customer_houseno, setCustomer_houseno] = useState<String>(booking.customer_address && booking.customer_address)
  const [customer_address, setCustomer_address] = useState<String>(
    booking.customer_address && booking.customer_address
  );
  const [customer_city, setCustomer_city] = useState<String>("");
  const [customer_aadhar, setCustomer_aadhar] = useState<String>(
    booking?.additional_members?.customer_aadhar
  );
  const [partner, setPartner] = useState<String>(
    booking.partner && booking.partner.id
  );
  const [packageDetails, setPackageDetails] = useState<any>(booking.packages);
  const [packageKey, setPackageKey] = useState<any>(0);
  const [packages, setPackages] = useState<Number[]>(
    booking.packages &&
      booking.packages.map((pack: any) => {
        return pack.id;
      })
  );
  const [booking_source, setBooking_source] = useState<Number>(
    booking.partner && booking.partner.id
  );

  const [coupon, setCoupon] = useState<Number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [price, setPrice] = useState<any>(
    booking.discounted_price && booking.discounted_price.total_price_package
  );
  const [discount, setDiscount] = useState<any>(
    booking.discounted_price && booking.discounted_price.counpon_discount
  );
  const [totalPrice, setTotalPrice] = useState<any>(
    booking.discounted_price && booking.discounted_price.final_total_price
  );
  const [extraCharge, setExtraCharge] = useState<boolean>(
    booking.discounted_price && booking.discounted_price.phlebo_cost !== 0
  );
  const [cityId, setCityId] = useState<any>(booking && booking.cityid);
  const [defaultEmail, setDefaultEmail] = useState<any>(
    "customer.reports@redcliffelabs.com"
  );
  const [packKey, setPackKey] = useState<any>(0);
  const [zoneId, setZoneId] = useState<any>("");
  const [couponKey, setCouponKey] = useState<any>(0);
  const [is_pincode, setIsPincode] = useState<boolean>(true);
  // const [removeCoupn, setremoveCoupn] = useState<any>("false")
  const [mobileValidation, setMobileValidation] = useState({
    customer_alternatenumber: true,
    customer_phonenumber: true,
    customer_whatsapppnumber: true,
  });
  const bookingId = match.params.id;
  const timer = useRef<any>(0);

  useEffect(() => {
      if(booking?.coupon_applied){
        setCoupon(booking?.coupon_applied.id)
        setCouponCode(booking?.coupon_applied?.name)
      }
  }, []);

  useEffect(() => {
    getPackage(`city_id=${cityId}`);
    getPackage();
    getPartners();
    getCoupons();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBookingById(`${bookingId}/`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId]);

  useEffect(() => {
    getArea(
      collection_date,
      booking.customer_areapincode && booking.customer_areapincode.pincode
    );
    // handlePackages()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking]);
  useEffect(() => {
    const slots = booking_slots && booking_slots.results;
    setTimeSlots(slots && slots[0].zone_data && slots[0].zone_data.time_slots);
  }, [booking_slots]);

  const handlePinCode = (val: String) => {
   
    setPinCode(val as string);
    // getArea(val as String)
    getArea(collection_date, val as String);
    const { id, area, city, cityid } = pin_code.results.find(
      (code: any) => code.pincode === val
    );
    setCustomer_areapincode(id as Number);
    getPackage(`city_id=${cityid}`);
    setCustomer_areaname(area as String);
    setCustomer_city(city as String);
    setCityId(cityid);
    setIsPincode(false);
    setPackageDetails([]);
    setPackages([]);
    setPackageKey(cityid);
    setCoupon(0);
    setCouponKey(cityid);
  };
  const getArea = (collection_date: String, pincode: String) => {
    const zone =
      pin_code.results &&
      pin_code.results.find((code: any) => code.pincode === pincode);
    setZoneName(zone && (zone.area as String));
    if (zone && zone.zone_data && zone.zone_data.id) {
      getAvailableSlots(
        collection_date,
        zone && zone.zone_data && zone.zone_data.id
      );
    }

    setCustomer_areapincode(zone && (zone.id as Number));
    setCustomer_city(zone && (zone.city as String));
    setZoneId(zone && zone.zone_data && zone.zone_data.id);
  };

  const handlePackages = () => {
    const min_phlebo_cost=packageList&&packageList?.results&&packageList?.results.length>0&&packageList?.results[0].min_booking_amount_for_phlebo
    let accumulatedPrice: any = 0;
    let packIds: any = [];
    packageDetails &&
      packageDetails.length > 0 &&
      packageDetails.map((item: any) => {
        packIds.push(item.id);
        if (item.package_city_prices === null) {
          accumulatedPrice = accumulatedPrice + item.offer_price;
        } else {
          accumulatedPrice =
            accumulatedPrice + item.package_city_prices.offer_price;
        }
        if (accumulatedPrice < min_phlebo_cost) {
          setExtraCharge(true);
        } else {
          setExtraCharge(false);
        }
      });
    booking.additional_members &&
      booking.additional_members.length > 0 &&
      booking.additional_members.map((item: any) => {
        item.packages &&
          item.packages.map((pack: any) => {
            accumulatedPrice = accumulatedPrice + pack.offer_price;
            if (accumulatedPrice < min_phlebo_cost) {
              setExtraCharge(true);
            } else {
              setExtraCharge(false);
            }
          });
      });
    setPackages(packIds);
    setPrice(Math.floor(accumulatedPrice));
  };

  useEffect(() => {
    handlePackages();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageDetails]);

  useEffect(() => {
    if (extraCharge) {
      setTotalPrice(Math.floor(price - discount + 200));
    } else {
      setTotalPrice(Math.floor(price - discount));
    }
  }, [price, discount, extraCharge]);

  useEffect(() => {
    if (totalPrice < 0) {
      setTotalPrice(0);
    }
  }, [totalPrice]);

  const applyCoupon = () => {
    if (couponCode === "") {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
      return;
    }
    const appliedCoupon =
      couponList.results.find((coup: any) => coup.id === coupon) ||
      couponList.results.find((coup: any) => coup.code === couponCode);
    if (appliedCoupon && appliedCoupon.minimum_price < totalPrice) {
      setCoupon(appliedCoupon.id);
      if (appliedCoupon.discount_type.toLowerCase() === "percentage") {
        setDiscount(Math.floor((price * appliedCoupon.discount) / 100));
      }
      if (appliedCoupon.discount_type.toLowerCase() === "amount") {
        setDiscount(Math.floor(appliedCoupon.discount));
      }
    } else {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
    }
  };
 const verifyMobile = (type: any) => {
    if(type==="mobile"){
      if(parseInt(customer_phonenumber&&customer_phonenumber[0])<6||customer_phonenumber&&customer_phonenumber.length<10){
        setMobileValidation((prev: any) => ({
              ...prev,
              customer_phonenumber: false,
        }));}
    }
    if(type==="alternate"){
      if(parseInt(customer_alternatenumber&&customer_alternatenumber[0])<6||customer_alternatenumber&&customer_alternatenumber.length<10){
        setMobileValidation((prev: any) => ({
              ...prev,
              customer_alternatenumber: false,
          }));}
    }
    if(type=== "whatsapp" ){
      if(parseInt(customer_whatsapppnumber&&customer_whatsapppnumber[0])<6||customer_whatsapppnumber&&customer_whatsapppnumber.length<10){
        setMobileValidation((prev: any) => ({
              ...prev,
              customer_whatsapppnumber: false,
            }));}
    }
  };
  const submitForm = async (e: any) => {
    e.preventDefault();
    const data: any = {
      booking_date: moment(new Date()).format("YYYY-MM-DD"),
      collection_date: collection_date,
      collection_slot: selectedSlot,
      customer_name: customer_name,
      customer_age: customer_age,
      customer_gender: customer_gender,
      customer_email: customer_email,
      customer_phonenumber: customer_phonenumber,
      customer_altphonenumber: customer_alternatenumber,
      customer_whatsapppnumber: customer_whatsapppnumber,
      customer_address: customer_address,
      customer_landmark: customer_landmark,
      customer_longitude: customer_longitude,
      customer_latitude: customer_latitude,
      customer_areapincode: customer_areapincode,
      customer_city: customer_city,
      customer_aadhar: customer_aadhar,
      packages: packages,
      coupon_applied: coupon,
      // "partner": booking_source,
      designation: customer_designation,
    };


    if (data["coupon_applied"] === 0 || couponCode === "") {
      delete data["coupon_applied"];
    }

    // if (removeCoupn && !data['coupon_applied']) {
    //     data['remove_coupon'] = "true"
    // }
    await updateBooking(data, bookingId);
    history.push(`/dashboard/ce/booking-view/${bookingId}`);
  };
  const changeCollectionDate = (e: any) => {
    setSelectedSlot(0)
    setCollection_date(e.target.value as string);
    if (zoneId) {
      getAvailableSlots(e.target.value, zoneId);
      getPackage(`city_id=${cityId}`);
    }
  };

  useEffect(() => {
    
    setCustomer_landmark(landmark ? landmark.label : customer_landmark);
    if (landmark) {
      geocodeByAddress(landmark.label)
        .then((results) => {
          setGeoLocation(results);
          setCustomerLongitude(
            geoLocation[0] &&
              geoLocation[0].geometry &&
              geoLocation[0].geometry.viewport &&
              geoLocation[0].geometry.viewport.Hb &&
              geoLocation[0].geometry.viewport.Hb.g
          );
          setCustomerLatitude(
            geoLocation[0] &&
              geoLocation[0].geometry &&
              geoLocation[0].geometry.viewport &&
              geoLocation[0].geometry.viewport.tc &&
              geoLocation[0].geometry.viewport.tc.g
          );
        })
        .catch((error) => console.error(error));
    }
  }, [landmark]);
  document.querySelectorAll("#noTyping").forEach(el=>{
    el.addEventListener("keydown", function(e){
      e.preventDefault()
    })
  })

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Paper className={classes.paper} elevation={15}>
        <h3 className={classes.head}>Edit Booking Form</h3>
        <form onSubmit={submitForm}>
          <Grid container spacing={3} style={{ margin: "1rem auto" }}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Booking date</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    className="input"
                    name="collection_date"
                    type="date"
                    value={booking_date || ""}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "18.5px 14px",
                    }}
                    required
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Sample Collection date</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    className="input"
                    name="collection_date"
                    type="date"
                    value={
                      (collection_date && collection_date.toString()) || ""
                    }
                    // onChange={(e) => setCollection_date(e.target.value as string)}
                    onChange={(e) => changeCollectionDate(e)}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "18.5px 14px",
                    }}
                    id="noTyping"
                    max={`${new Date().getFullYear() + 1}-${
                      new Date().getMonth() + 1 < 10
                        ? `0${new Date().getMonth() + 1}`
                        : new Date().getMonth() + 1
                    }-${
                      new Date().getDate() < 10
                        ? `0${new Date().getDate()}`
                        : new Date().getDate()
                    }`}
                    // min={`${new Date().getFullYear()}-${(new Date().getMonth() + 1) < 10 ? `0${(new Date().getMonth() + 1)}` : (new Date().getMonth() + 1)}-${new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()}`}
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Pin Code</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Autocomplete
                    id="pincode"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        handlePinCode(obj.pincode as String);
                      }
                    }}
                    defaultValue={
                      pin_code.results &&
                      pin_code.results.find((code: any) => {
                        return code.pincode === pincode;
                      })
                    }
                    options={pin_code.results}
                    freeSolo
                    blurOnSelect
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getPinCode(newInputValue.split(",")[0]);
                      }, 1000);
                    }}
                    getOptionLabel={(option: any) =>
                      `${option.pincode}`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Pin Code"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        className="input"
                        disabled={booking.phlebo !== null}
                        required
                      />
                    )}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Zone Name</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="zone"
                    type="text"
                    value={zoneName}
                    className="input"
                    variant="outlined"
                    style={{ width: "100%", margin: "0" }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                className="sampleCollectionTime"
                style={{ height: "100%", overflow: "auto" }}
              >
                <p style={{ marginBottom: "1rem" }}>Sample Collection Time</p>
                <div className={classes.slotContainer}>
                  {timeSlots &&
                    timeSlots.length > 0 &&
                    timeSlots.map((slot: any) => {
                      return (
                        slot.available_slots > 0 && (
                          <Card
                            key={slot.id}
                            className={
                              slot.id === selectedSlot
                                ? classes.selectedSlot
                                : classes.slot
                            }
                            onClick={() => setSelectedSlot(slot.id as Number)}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                color: "black",
                                fontSize: "14px",
                              }}
                            >
                              {slot.start_time1hr}-{slot.end_time1hr}
                            </p>
                            {/* <p style={{ textAlign: "center", color: "black" }}>{moment(`${slot.start_time}`, ["HH.mm.ss"]).format("hh:mm A")} - {moment(`${slot.end_time}`, ["HH.mm.ss"]).format("hh:mm A")}</p> */}
                            <p style={{ textAlign: "center", color: "green" }}>
                              {" "}
                              Availabe slots: {slot.available_slots}
                            </p>
                          </Card>
                        )
                      );
                    })}
                </div>
              </Card>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            spacing={3}
            style={{ margin: "1rem auto", background: "#eff" }}
          >
            <Grid item xs>
              <p>Title</p>
              <Select
                className="input"
                name="customer_designation"
                variant="outlined"
                value={customer_designation}
                style={{ width: "100%", margin: "0" }}
                onChange={(e) =>
                  setCustomer_designation(e.target.value as String)
                }
                required
              >
                <MenuItem value={"Mr"}>Mr</MenuItem>
                <MenuItem value={"Mrs"}>Mrs</MenuItem>
                <MenuItem value={"Master"}>Master</MenuItem>
                <MenuItem value={"Miss"}>Miss</MenuItem>
                <MenuItem value={"Smt"}>Smt</MenuItem>
                <MenuItem value={"Dr."}>Dr.</MenuItem>
                <MenuItem value={"Baby or Just Born"}>
                  Baby or Just Born
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs>
              <p>Customer Name</p>
              <TextField
                name="customer_name"
                type="text"
                placeholder="Enter Name"
                value={customer_name}
                className="input"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%", margin: "0" }}
                onChange={(e) => setCustomer_name(e.target.value.replace(/[^A-Za-z- ']/, '') as String)}
                required
              />
            </Grid>
            <Grid item xs>
              <p>Age</p>
              <TextField
                name="customer_age"
                type="number"
                placeholder="Enter Age"
                value={customer_age}
                className="input"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%", margin: "0" }}
                inputProps={{ min: 0, max: 150 }}
                onChange={(e) => {
                    let ageValue = parseInt(e.target.value);
                    if (ageValue > 150) ageValue = 150;
                    if (ageValue < 0) ageValue = 0;
                    e.target.value = ageValue.toString()
                    setCustomer_age(e.target.value as any)
                }}
                required
              />
            </Grid>
            <Grid item xs>
              <p>Gender</p>
              <Select
                className="input"
                name="customer_gender"
                variant="outlined"
                value={customer_gender || ""}
                style={{ width: "100%", margin: "0", padding: "0" }}
                onChange={(e) => setCustomer_gender(e.target.value as String)}
                required
              >
                <MenuItem selected hidden value={""}>
                  Please Select
                </MenuItem>
                <MenuItem value={"male"}>Male</MenuItem>
                <MenuItem value={"female"}>Female</MenuItem>
                <MenuItem value={"other"}>Other</MenuItem>
              </Select>
            </Grid>
            <Grid item xs>
              <p>Package Name</p>
              <Autocomplete
                id="package"
                key={packageKey}
                onChange={(event, newValue) => {
                  if (newValue) {
                    let packs: any = [];
                    newValue.map((val: any) => {
                      let obj = JSON.parse(JSON.stringify(val, null, " "));
                      packs.push(obj);
                    });
                    setPackageDetails(packs);
                    setPackKey(1);
                  }
                }}
                multiple
                options={packageList.results}
                value={packageDetails}
                disableClearable
                limitTags={0}
                getOptionLabel={(option: any) =>
                  option.name && option.package_city_prices
                    ? option.name.toString() +
                      ", " +
                      option.package_city_prices.tat_time
                    : option.name + ", " + option.tat_time
                }
                getOptionDisabled={(option: any) => {
                  const packID = packageDetails.map((pack: any) => pack.id);
                  return packID.includes(option.id);
                }}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getPackage(`city_id=${cityId}&search=${newInputValue}`);
                  }, 1000);
                }}
                freeSolo
                disableCloseOnSelect
                renderTags={(value: string[], getTagProps) =>
                  value.map((option: any, index: number) => (
                    <Chip
                      variant="default"
                      color="primary"
                      label={
                        option.name && option.package_city_prices
                          ? option.name.toString() +
                            ", " +
                            option.package_city_prices.tat_time
                          : option.name + ", " + option.tat_time
                      }
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Package"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    className="input"
                  />
                )}
              />
            </Grid>
            <Grid item xs>
              <p>Partner</p>
              <TextField
                name="partner"
                type="text"
                value={partner}
                placeholder="Enter Order Id"
                className="input"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setPartner(e.target.value as String)}
                style={{ width: "100%", margin: "0" }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ margin: "1rem auto" }}
          >
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Mobile Number</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="customer_phonenumber"
                    type="text"
                    value={customer_phonenumber}
                    placeholder="Enter Mobile Number"
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_phonenumber(e.target.value as String)
                    }
                    onBlur={() => verifyMobile("mobile")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_phonenumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_phonenumber &&
                      "Incorrect Phone Number"
                    }
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Alternate Mobile Number</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="customer_alternatenumber"
                    type="text"
                    value={customer_alternatenumber}
                    placeholder="Enter Alternate Mobile Number"
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_alternatenumber(e.target.value as String)
                    }
                    required
                    onBlur={() => verifyMobile("whatsapp")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_whatsapppnumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_whatsapppnumber &&
                      "Incorrect Whatsapp Number"
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Email</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="customer_email"
                    type="email"
                    placeholder="Enter Email"
                    value={customer_email}
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_email(e.target.value as String)
                    }
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Whatsapp Number</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    name="customer_whatsapppnumber"
                    type="text"
                    placeholder="Enter Whatsapp Number"
                    value={customer_whatsapppnumber}
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_whatsapppnumber(e.target.value as String)
                    }
                    onBlur={() => verifyMobile("mobile")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_phonenumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_phonenumber &&
                      "Incorrect Phone Number"
                    }
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Aadhar No</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    className="input"
                    name="customer_aadhar"
                    type="number"
                      onWheel={(e: any) => e.target.blur()}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 12);
                      }}
                    placeholder="Enter Aadhar Number"
                    value={booking?.additional_members?.customer_aadhar || ""}
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_aadhar(e.target.value as String)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} style={{ padding: "1rem" }}>
              <div className="priceDetails" style={{ padding: "1rem" }}>
                <table style={{ width: "100%", margin: "0" }}>
                  <thead>
                    <tr>
                      <td className="cell">Customer Name</td>
                      <td className="cell">Packages</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="cell">{customer_name}</td>
                      <td className="cell">
                        <ul>
                          {packageDetails.map((pack: any, index: any) => {
                            return <li key={index}>{pack.name}
                                          <br />DESCRIPTION : {pack.description}
                                          <br />
                                          TaT: {
                                            pack.package_city_prices
                                              ? pack.package_city_prices.tat_time
                                              : pack.tat_time
                                          }
                                          <br />
                                          Price: {
                                            pack.package_city_prices
                                              ? pack.package_city_prices.offer_price
                                              : pack.offer_price
                                          }
                                        </li>;
                          })}
                        </ul>
                      </td>
                    </tr>
                    {booking.additional_members &&
                      booking.additional_members.map(
                        (member: any, index: any) => {
                          return (
                            <tr key={index}>
                              <td className="cell">{member.customer_name}</td>
                              <td className="cell">
                                <ul>
                                  {member.packages &&
                                    member.packages.map(
                                      (pack: any, index: any) => {
                                        return <li key={index}>{pack.name}
                                          <br />DESCRIPTION : {pack.description}
                                          <br />
                                          TaT: {
                                            pack.package_city_prices
                                              ? pack.package_city_prices.tat_time
                                              : pack.tat_time
                                          }
                                          <br />
                                          Price: {
                                            pack.package_city_prices
                                              ? pack.package_city_prices.offer_price
                                              : pack.offer_price
                                          }
                                        </li>;
                                      }
                                    )}
                                </ul>
                              </td>
                            </tr>
                          );
                        }
                      )}
                  </tbody>
                </table>
              </div>
              <div
                className="priceDetails"
                style={{ padding: "1rem 1rem 0 1rem" }}
              >
                 <h6 className="totalPrice">
                  Price: ₹ {Math.floor(price)} {extraCharge ? "+ 200" : ""}
                </h6>
                <h6 className="totalPrice">
                RedCash Discount: ₹ {Math.floor(booking?.redcash_discounted_amount)}
                </h6>
                <h6 className="totalPrice">Discount: ₹ {discount}</h6>
                <h6 className="totalPrice">Total Amount: ₹ {totalPrice-Math.floor(booking?.redcash_discounted_amount)}</h6>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justify="center"
                  alignItems="center"
                  style={{ margin: "1rem auto" }}
                >
                  <Grid item xs={2}>
                    <p>Enter Coupon</p>
                  </Grid>
                  <Grid item xs={3}>
                    <Autocomplete
                      key={couponKey}
                      id="coupon"
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          setCoupon(obj.id as Number);
                        }
                      }}
                      defaultValue={
                        couponList.results &&
                        couponList.results.find((code: any) => {
                          return code.id === coupon;
                        })
                      }
                      inputValue={couponCode}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        setCouponCode(newInputValue);
                        timer.current = setTimeout(() => {
                          getCoupons(newInputValue);
                        }, 1000);
                        if (newInputValue === "") {
                          setCoupon(0);
                          setDiscount(0);
                          // setremoveCoupn("true")
                        }
                      }}
                      options={couponList.results}
                      freeSolo
                      blurOnSelect
                      getOptionLabel={(option: any) => option.name}
                      getOptionDisabled={(option: any) =>
                        option.minimum_price > totalPrice
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Coupon"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          className="input"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="contained"
                      fullWidth
                      color="primary"
                      onClick={applyCoupon}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ margin: "1rem auto" }}
          >
            <Grid item xs={12}>
              <Divider style={{ backgroundColor: "#009fff" }} />
              <h3 style={{ textAlign: "center" }}>Address</h3>
              <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                {/* <Grid container item xs={12} md={3} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                    <Grid item xs={12} style={{ padding: "0" }}>
                                        <p>House Number</p>
                                    </Grid>
                                    <Grid item xs={12} style={{ padding: "0" }}>
                                        <TextField
                                            name="houseNumber"
                                            type="text"
                                            placeholder="Enter house number"
                                            value={customer_houseno}
                                            className="input"
                                            variant="outlined"
                                            style={{ margin: "0", width: "100%" }}
                                            onChange={(e) => setCustomer_houseno(e.target.value as String)}
                                            required

                                        />
                                    </Grid>
                                </Grid> */}
                <Grid
                  container
                  item
                  xs={12}
                  md={3}
                  spacing={3}
                  style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}
                >
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Full Address</p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <TextField
                      name="customer_address"
                      type="text"
                      placeholder="Enter address"
                      value={customer_address}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                      onChange={(e) =>
                        setCustomer_address(e.target.value as String)
                      }
                      required
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={3}
                  spacing={3}
                  style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}
                >
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Landmark</p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <TextField
                      name="customer_landmark"
                      type="text"
                      placeholder="Enter landmark"
                      value={customer_landmark}
                      className="input"
                      variant="outlined"
                      // disabled
                      style={{ margin: "0", width: "100%" }}
                      onChange={(e) =>
                        setCustomer_landmark(e.target.value as String)
                      }
                      required
                    />
                    <GooglePlacesAutocomplete
                      apiKey={GoogleMapsAPI}
                      selectProps={{
                        landmark,
                        onChange: setLandmark,
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={3}
                  spacing={3}
                  style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}
                >
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>City</p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <TextField
                      name="customer_city"
                      type="text"
                      placeholder="Enter City"
                      value={customer_city}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={3}
                  spacing={3}
                  style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}
                >
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Area Name</p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <TextField
                      name="customer_areaname"
                      type="text"
                      placeholder="Enter area name"
                      value={customer_areaname}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  md={3}
                  spacing={3}
                  style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}
                >
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <p>Pincode</p>
                  </Grid>
                  <Grid item xs={12} style={{ padding: "0" }}>
                    <TextField
                      name="customer_areapincode"
                      type="text"
                      placeholder="Enter Pincode"
                      value={pincode}
                      className="input"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={loading || !selectedSlot || packages.length === 0}
            >
              Update booking
            </Button>
          </div>
        </form>
      </Paper>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  pin_code: state.BookingReducer.pin_code,
  packageList: state.BookingReducer.packageList,
  partnerList: state.BookingReducer.partnerList,
  couponList: state.BookingReducer.couponList,
  booking: state.BookingReducer.booking,
  booking_slots: state.BookingReducer.booking_slots,
  loading: state.BookingReducer.loading,
});

export default connect(mapStateToProps, {
  getPinCode,
  getPackage,
  updateBooking,
  getPartners,
  getCoupons,
  getBookingById,
  getAvailableSlots,
})(EditBookingForm);
