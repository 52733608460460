import React from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, } from '@material-ui/data-grid';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "100%",
            flexGrow: 21,
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
    })
);

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

interface Props {
    imagingTestList: any;
    loading: boolean;
}

const BookingExport: React.FC<Props> = ({
    imagingTestList,
    loading
}) => {
    const classes = useStyles();

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Code', width: 200 },
        { field: 'name', headerName: 'Test Name', width: 200 },
        { field: 'mrp', headerName: 'MRP', width: 200 },
        { field: 'sellingprice', headerName: 'GolivePrice', width: 200 },
        { field: 'transferprice', headerName: 'TransferPrice', width: 200 },
    ];


    let rows:any = []
    if (imagingTestList&&imagingTestList.length) {
        rows =imagingTestList&&imagingTestList.length > 0 && imagingTestList.map((pack: any) => {
            return {
                ...pack,
                id: pack?.code,
                name: pack?.name,
            }
        })
    }

    console.log("rows", rows);

    return (
        <main className={classes.content}>
            <Paper elevation={15} className={classes.paper}>
                <div style={{ height: 700 }}>
                    <DataGrid rowsPerPageOptions={[]}
                        rows={rows}
                        columns={columns}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        pageSize={50}
                        // rowCount={imagingTestList.count || 0}
                        loading={loading}
                    />
                </div>
            </Paper>
        </main>
    )
}

const mapStateToProps = (state: any) => ({

})

export default connect(mapStateToProps, {

})(BookingExport);

