import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  getAgentList,
  getAgentNewData,
  getPartners,
  getAllBookings,
} from "../actions/salesLeadAction";
import LeadsTable from "../tables/leads";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
  })
);

interface Props {
  children: any;
  agentsList: any;
  agentsListnewdata: any;
  bookingList: any;
  complaints: any;
  partnerList: any;
  getAgentList: any;
  getAgentNewData: any;
  getPartners: any;
  getAllBookings: any;
  loading: Boolean;
}

const LeadsPage: React.FC<Props> = ({
  agentsList,
  agentsListnewdata,
  partnerList,
  complaints,
  getAgentList,
  getAgentNewData,
  getPartners,
  getAllBookings,
  bookingList,
  loading,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getAgentList();
    getAgentNewData("?usergroup=CustomerExecutive");
    getPartners();
    getAllBookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <LeadsTable complaints={complaints} />
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  agentsList: state.SalesLeadReducer.agentsList,
  agentsListnewdata: state.SalesLeadReducer.agentsListnewdata,
  partnerList: state.SalesLeadReducer.partnerList,
  bookingList: state.SalesLeadReducer.bookingList,
  loading: state.SalesLeadReducer.loading,
});

export default connect(mapStateToProps, {
  getAgentList,
  getAgentNewData,
  getPartners,
  getAllBookings,
})(LeadsPage);
