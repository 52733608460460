import React, { useState, useEffect } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Chip, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Grid, TextField, withStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import { useRef } from "react";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../components/loader/index';
import moment from 'moment';
import { MessageRounded } from '@material-ui/icons';
import { getBreakData, getAgentList } from '../actions/HealthAdviserAdminAction'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from "react-router";
import { generateBreakUrl } from "../../helpers/generateUrl";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        listStyle: {
            marginLeft: "20px",
        },
        tableContainer: {
            marginTop: "1rem",
            position: "sticky",
            top: "200px",
        },
    })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,fontWeight:600
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);
interface Props {
    getBreakData: any,
    breakData: any,
    getAgentList: any,
    agentsList: any;
    loading: Boolean;
}

const BookingsTable: React.FC<Props> = ({
    getBreakData,
    breakData,
    agentsList,
    getAgentList,
    loading
}) => {
    const classes = useStyles()
    useEffect(() => {
        getBreakData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const history = useHistory()
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [start_date, setStart_date] = useState("")
    const [end_date, setEnd_date] = useState("")
    const [createdAt, setCreatedAt] = useState<string>("")
    const [agent, setAgent] = useState<any>([])
    const [partner, setPartner] = useState<any>([])
    const timer = useRef<any>(0)
    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = breakData.links && breakData.links.next.split("?")[1];;
            getBreakData(url);
        } else if (newPage < page) {
            let url = breakData.links && breakData.links.previous.split("?")[1];
            getBreakData(url);
        }
        setPage(newPage as number);
    };

    const filterBookings = () => {
        const body: any = {
            start_date,
            end_date,
            agent: agent.join(","),
            created_date: createdAt
        }
        const url = generateBreakUrl(body).substring(2)
        getBreakData(`${url}`)
        setPage(0)
    }

    return (
        <div style={{ width: "100%" }} className="data-table">
            <div className={classes.toolbar} />
            <h2>Break Details</h2>
            <Grid container spacing={1} direction="row">
                <Grid item xs={12} sm={4} md={2}>
                    <Autocomplete
                        id="agent"
                        onChange={(event, newValue) => {
                            let sourceIds: any = []
                            if (newValue) {
                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                sourceIds = obj.map((item: any) => item.id)
                            }
                            setAgent(sourceIds)
                        }}
                        freeSolo
                        blurOnSelect
                        aria-required
                        limitTags={1}
                        multiple
                        options={agentsList.results || []}
                        getOptionDisabled={(option: any) => {
                            return agent.includes(option.id)
                        }}
                        disableClearable
                        disableCloseOnSelect
                        getOptionLabel={(option: any) => option.user && option.user.name}
                        onInputChange={(event, newInputValue) => {
                            clearTimeout(timer.current)
                            timer.current = setTimeout(() => {
                                getAgentList(`?code=${newInputValue.replace(" ", "")}`)

                            }, 1000)
                            if (newInputValue.length === 0) {
                                setPartner([])
                            }
                        }}
                        renderInput={(params) => (
                            <TextField className="input"
                                {...params}
                                placeholder="Agent"
                                variant="outlined"
                                style={{ width: "100%", margin: "0", padding: "0" }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        className="input"
                        name="start_date"
                        type="date"
                        label="Start Date"
                        // value={booking_date === "" || collection_date === "" ? start_date : ""}
                        variant="outlined"
                        // disabled={booking_date !== "" || collection_date !== ""}
                        onChange={(e) => setStart_date(e.target.value as string)}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        name="end_date"
                        type="date"
                        // value={booking_date === "" || collection_date === "" ? end_date : ""}
                        className="input"
                        label="End Date"
                        variant="outlined"
                        // disabled={booking_date !== "" || collection_date !== ""}
                        onChange={(e) => setEnd_date(e.target.value as string)}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        className="input"
                        name="Created At"
                        type="date"
                        label="Created Date"
                        value={createdAt}
                        variant="outlined"
                        onChange={(e) => setCreatedAt(e.target.value as string)}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <Button
                        variant="contained"
                        color="secondary"

                        fullWidth
                        onClick={filterBookings}
                    >
                        Filter
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <Button
                        variant="contained"
                        color="primary"

                        fullWidth
                        onClick={() => history.push("/dashboard/haa/breakData")}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
            <div className={classes.toolbar} />
            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "540px" }}>
                {loading ? <Loader /> : (
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="center">Created At</StyledTableCell>
                                <StyledTableCell align="center">Agent</StyledTableCell>
                                <StyledTableCell align="center">Start Time</StyledTableCell>
                                <StyledTableCell align="center">End Time</StyledTableCell>
                                <StyledTableCell align="center">Total Break Time</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {
                                breakData.results && breakData.results.length > 0 && breakData.results.map((data: any, index: any) => {
                                    let time1 = Date.parse(data.start_time);
                                    let time2 = Date.parse(data.end_time);
                                    let time = Math.abs(time2 - time1);
                                    let diff = time / 1000
                                    const convertHMS = (value: any) => {
                                        const sec = parseInt(value, 10); // convert value to number if it's string
                                        let hours: any = Math.floor(sec / 3600); // get hours
                                        let minutes: any = Math.floor((sec - (hours * 3600)) / 60); // get minutes
                                        let seconds: any = sec - (hours * 3600) - (minutes * 60); //  get seconds
                                        // add 0 if value < 10; Example: 2 => 02
                                        if (hours < 10) { hours = "0" + hours; }
                                        if (minutes < 10) { minutes = "0" + minutes; }
                                        if (seconds < 10) { seconds = "0" + seconds; }
                                        return hours + ':' + minutes + ':' + seconds;
                                    }
                                    return (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell style={{ height: "50px" }} align="center">{new Date(data.created_at).toLocaleString()}</StyledTableCell>
                                            <StyledTableCell style={{ height: "50px" }} align="center">{data.panel_user || "NA"}</StyledTableCell>
                                            <StyledTableCell style={{ height: "50px" }} align="center">{new Date(data.start_time).toLocaleString().split(",")[1]}</StyledTableCell>
                                            <StyledTableCell style={{ height: "50px" }} align="center">{data.end_time === null ? "On Break" : new Date(data.end_time).toLocaleString().split(",")[1]}</StyledTableCell>
                                            <StyledTableCell style={{ height: "50px" }} align="center">{convertHMS(diff)}</StyledTableCell>
                                        </StyledTableRow>
                                    )
                                })}
                        </TableBody>
                        <TableFooter>
                            <StyledTableRow>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    colSpan={5}
                                    count={breakData.count || 0}
                                    rowsPerPage={breakData.page_size}
                                    page={page}
                                    onPageChange={handleChangePage}
                                />
                            </StyledTableRow>
                        </TableFooter>
                    </Table>
                )}
            </TableContainer>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    breakData: state.HealthAdviserAdminReducer.breakData,
    agentsList: state.HealthAdviserAdminReducer.agentsList,
})

export default connect(mapStateToProps, {
    getBreakData,
    getAgentList,
})(BookingsTable);

