import React, { useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Loader from "../Components/Loader2/index";
import { MessageRounded } from "@material-ui/icons";
import { useEffect } from "react";
import RaiseComplaintModal from "../Components/comments/updateTicketModal";
import { useHistory } from "react-router";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  getAgentList,
  getAllBookings,
  getBookingQueryTickets,
  getPackage,
  getAvailableSlots,
} from "../actions/salesLeadAction";
import TicketCommentsModal from '../Components/comments/ticketCommentsHistory'
import { generateQueryTicketBookingFilterUrl } from "../../helpers/generateUrl";
import TicketDispositionModal from '../Components/comments/TicketDispositionModal'
import { Grid, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getBookingQueryTickets: any;
  bookingComplaints: any;
  agentsList: any;
  bookingList: any;
  getAllBookings: any;
  getAgentList: any;
  loading: any;
  getPackage: any;
  packageList: any;
  getAvailableSlots: any;
  booking_slots: any;
}

const BookingsTable: React.FC<Props> = ({
  getBookingQueryTickets,
  bookingComplaints,
  getAllBookings,
  getAgentList,
  agentsList,
  bookingList,
  loading,
  getPackage,
  packageList,
  getAvailableSlots,
  booking_slots,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const timer = useRef<any>(0);
  const [raiseComplaint, setRaiseComplaint] = useState(false);
  const [bookingID, setBookingID] = useState(0);
  const [type, setType] = useState("");
  const [openModal, setopenModal] = useState<boolean>(false);
  const [query, setQuery] = useState("none");
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [status, setStatus] = useState("");
  const [ticket_Status, setTicketStatus] = useState<any>("");
  const [ticketId, setTicketId] = useState(0);
  const [created_date, setCreated_date] = useState("");
  const [bookingId, setBookingid] = React.useState<any>("");
  const [activeTab, setActiveTab] = React.useState<string>("");
  const [ticketDetails, setTicketDetails] = useState<any>("");
  const [followUpStartDate, setFollowUpStartDate] = useState<string>("")
  const [followUpEndDate, setFollowUpEndDate] = useState<string>("")
  const [created_start_date, setCreatedStartDate] = useState<string>("")
  const [ticket_number, setTicket_Number] = useState<string>("")
  const [ticket_type, setTicketType] = useState<string>("")
  const [created_end_date, SetCreatedEndDate] = useState<string>("")
  const [agent, setAgent] = useState<number>(0);
  const [collectionDate, setCollectionDate] = useState<any>("");
  const [timeSlots, setTimeSlots] = useState<Array<Object>>([]);
  const [zoneId, setZoneId] = useState<any>("");
  const handleOpenModal = (ticket: any) => {
    setBookingID(
      ticket.booking !== null
        ? ticket?.booking?.id || ticket?.booking?.id
        : ticket?.add_booking?.mainbooking
    );
    setType(ticket.querytype);
    setQuery(ticket.query);
    setStatus(ticket.status);
    setTicketId(ticket.id);
    setRaiseComplaint(true);
    setTicketDetails(ticket);
    setZoneId(
      ticket.booking !== null
        ? ticket?.booking?.customer_zoneid
        : ""
    );
    if (ticket.booking !== null) {
      getPackage(`booking=${ticket?.booking?.id || ticket?.booking?.id}`);
    } else {
      getPackage(`add_booking=${ticket?.add_booking?.id}`);
    }
  };
  useEffect(() => {
    if (collectionDate !== "") {
      getAvailableSlots(collectionDate, zoneId);
    }
  }, [collectionDate]);
  useEffect(() => {
    const slots = booking_slots && booking_slots?.results;
    setTimeSlots(slots && slots[0].zone_data && slots[0].zone_data.time_slots);
  }, [booking_slots]);

  useEffect(() => {
      getBookingQueryTickets("");
  }, []);

  useEffect(() => {
    getAgentList()
  }, []);

  const [page, setPage] = useState(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url =
        bookingComplaints.links && bookingComplaints.links.next.split("?")[1];
      getBookingQueryTickets(url ? `?${url}` : "");
    } else if (newPage < page) {
      let url =
        bookingComplaints.links &&
        bookingComplaints.links.previous.split("?")[1];
      getBookingQueryTickets(url ? `?${url}` : "");
    }
    setPage(newPage as number);
  };
  const filterBookings = () => {
    const body: any = {
      bookingId,
      ticket_Status,
      created_date,
      end_date,
      start_date,
      ticket_type,
      ticket_number,
      created_start_date,
      created_end_date,
      agent,
      follow_up_start_date: followUpStartDate,
      follow_up_end_date: followUpEndDate,
    };
    const url = generateQueryTicketBookingFilterUrl(body).substring(2);
    getBookingQueryTickets(`?${url}`);
    setPage(0);
  };

  const handleClick = (bookingid: number) => {
    setopenModal(true);
    setBookingid(bookingid);
  };

  const [ticketIndividualDetails, setIndividualTicketDetails] = useState<any>()
  const [openTicketModal, setOpenTicketModal] = useState<boolean>(false)
  const handleDispositionModal = (ticketData: any) => {
    getBookingQueryTickets(`${ticketData.id}/`)
    setIndividualTicketDetails(ticketData)
    setOpenTicketModal(true)
    setZoneId(
      ticketData.booking !== null
        ? ticketData?.booking?.customer_zoneid
        : ""
    );
    setBookingID(
      ticketData.booking !== null
        ? ticketData?.booking?.id || ticketData?.booking?.id
        : ticketData?.add_booking?.mainbooking
    );
    setType(ticketData.querytype);
    setQuery(ticketData.query);
    setStatus(ticketData.status);
    setTicketId(ticketData.id);
    setTicketDetails(ticketData);
  }

  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <h2>Booking Tickets</h2>
      <Grid
        container
        spacing={1}
        direction="row"
        style={{ alignItems: "left" }}
      >
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="booking"
            type="number"
            placeholder="Booking Id"
            value={bookingId}
            variant="outlined"
            onChange={(e) => setBookingid(e.target.value)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="ticketnumber"
            type="number"
            placeholder="Ticket Number"
            value={ticket_number}
            variant="outlined"
            onChange={(e) => setTicket_Number(e.target.value)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="ticket_status"
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                sourceIds = obj.map((item: any) => item.value);
              }
              setTicketStatus(sourceIds);
            }}
            options={[
              {
                text: "Created",
                value: "open",
              },
              {
                text: "Pending",
                value: "pending",
              },
              {
                text: "In Progress",
                value: "in_progress",
              },
              {
                text: "Attempted",
                value: "attempted",
              },
             
              {
                text: "Closed",
                value: "closed",
              },
              {
                text: "Force Closed",
                value: "forced_closed",
              },

            ]}
            freeSolo
            blurOnSelect
            aria-required
            limitTags={1}
            multiple
            getOptionLabel={(option: any) => option.text}
            getOptionDisabled={(option: any) => {
              return ticket_Status.includes(option.value);
            }}
            disableClearable
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="Ticket Status"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="ticket_type"
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                sourceIds = obj.map((item: any) => item.value);
              }
              setTicketType(sourceIds);
            }}
            options={[
              {
                text: "CS Missed Call",
                value: "cs_missed_call",
              },
              {
                text: "Phlebo Delay",
                value: "phlebo_delay",
              },
              {
                text: "Phlebo Behavior",
                value: "phlebo_behavior",
              },
              {
                text: "Phlebo Marked Hold",
                value: "phlebo_markhold",
              },
              {
                text: "Report TAT Breached",
                value: "report_related_query",
              },
              {
                text: "Report Not Delivered",
                value: "report_not_delivered",
              },
              {
                text: "Fault Report",
                value: "fault_report",
              },
              {
                text: "Doctor Consultation",
                value: "doctor_consultation",
              },
              {
                text: "Refund Query",
                value: "refund_query",
              },
              {
                text: "Social Media Ticket",
                value: "social_media_ticket",
              },
              {
                text: "Resampling",
                value: "resampling",
              },
              {
                text: "Document Required for Report",
                value: "document_required",
              },
              {
                text: "Others",
                value: "others",
              },
            ]}
            freeSolo
            blurOnSelect
            aria-required
            limitTags={1}
            multiple
            getOptionLabel={(option: any) => option.text}
            getOptionDisabled={(option: any) => {
              return ticket_type.includes(option.value);
            }}
            disableClearable
            disableCloseOnSelect
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                placeholder="Ticket Type"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="followUpStartDate "
            type="date"
            label="Follow-Up Start Date"
            value={followUpStartDate}
            variant="outlined"
            onChange={(e) => setFollowUpStartDate(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="FollowUpEndDate "
            type="date"
            label="Follow-Up End Date"
            value={followUpEndDate}
            variant="outlined"
            onChange={(e) => setFollowUpEndDate(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            name="Created_start_date"
            type="date"
            value={created_start_date}
            className="input"
            label="Ticket Start Date"
            variant="outlined"

            onChange={(e) => setCreatedStartDate(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            name="created_end_date"
            type="date"
            value={created_end_date}
            className="input"
            label="Ticket End Date"
            variant="outlined"
            onChange={(e) => SetCreatedEndDate(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="agentname"
            onChange={(event, newValue) => {
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                setAgent(obj.id);
              }
            }}
            options={agentsList.results || []}
            freeSolo
            blurOnSelect
            aria-required
            getOptionLabel={(option: any) => option.user && option.user.name}
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getAgentList(`?code=${newInputValue.replace(" ", "")}`)

              }, 1000);
              if (newInputValue.length === 0) {
                setAgent(0);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Last Updated By"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={filterBookings}
            disabled={loading}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => history.push("/dashboard/sl/booking/tickets")}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      <div style={{ width: "100%" }} className="data-table">
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ maxHeight: "540px" }}
        >
          {loading && !openModal ? (
            <Loader />
          ) : (
            <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Ticekt Id</StyledTableCell>
                <StyledTableCell align="center">Booking Id</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
                <StyledTableCell align="center">Comments</StyledTableCell>
                <StyledTableCell align="center">Ticket Type</StyledTableCell>
                <StyledTableCell align="center">Disposition</StyledTableCell>
                <StyledTableCell align="center">Sub Disposition</StyledTableCell>
                <StyledTableCell align="center">Query</StyledTableCell>
                <StyledTableCell align="center">Status</StyledTableCell>
                <StyledTableCell align="center">Created At</StyledTableCell>
                <StyledTableCell align="center">Follow Up </StyledTableCell>
                <StyledTableCell align="center">Updated At </StyledTableCell>
                <StyledTableCell align="center">Attempted </StyledTableCell>
                <StyledTableCell align="center">Created By</StyledTableCell>
                <StyledTableCell align="center">Received By</StyledTableCell>
                <StyledTableCell align="center">View</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {bookingComplaints?.results &&
                bookingComplaints?.results.length > 0 &&
                bookingComplaints?.results?.map((ticket: any, index: any) => {
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell align="center">
                        {ticket?.id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {ticket?.booking !== null
                          ? ticket?.booking?.id || ticket?.booking?.pk
                          : ticket?.add_booking?.mainbooking}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {(ticket?.action_status === "closed" || ticket?.action_status === "forced_closed") ?
                          "" :
                          <Button color="primary" variant="text">
                            <MessageRounded
                              onClick={() => handleDispositionModal(ticket)}
                            />
                          </Button>
                        }
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div>
                          <MessageRounded
                            onClick={() => handleClick(ticket?.id)}
                          />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {ticket?.disposition_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {ticket?.action_disposition}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {ticket?.action_sub_disposition}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {ticket?.query}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {ticket?.action_status_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {new Date(ticket?.created_at).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {new Date(ticket?.call_later_time).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {new Date(ticket?.updated_at).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {ticket?.attempted}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {ticket?.panel_user && ticket?.panel_user?.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {ticket?.received_by?.name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Link
                          to={`/dashboard/sl/booking-view/${ticket?.booking !== null
                            ? ticket?.booking?.id || ticket?.booking?.pk
                            : ticket?.add_booking?.mainbooking
                            }`}
                        >
                          <Button color="secondary" variant="contained">
                            View
                          </Button>
                        </Link>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
            <TableFooter>
              <StyledTableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={3}
                  count={bookingComplaints.count || 0}
                  rowsPerPage={bookingComplaints.page_size}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </StyledTableRow>
            </TableFooter>
          </Table>
          )}
        </TableContainer>
      </div>
      <RaiseComplaintModal
        open={raiseComplaint}
        setOpen={setRaiseComplaint}
        ticketId={ticketId}
        queryType={type}
        queryDesc={query}
        queryStatus={status}
        bookingId={Number(bookingID)}
        leadId={0}
        ticketDetails={ticketDetails}
        bookingList={bookingList}
        packageList={packageList}
        getPackage={getPackage}
        getAvailableSlots={getAvailableSlots}
        booking_slots={timeSlots}
        setCollectionDate={setCollectionDate}
        collectionDate={collectionDate}
        zoneId={zoneId}
        getQueryTickets={getBookingQueryTickets}
        activeTab={activeTab}
      />
      {/* </Paper> */}
      <TicketCommentsModal
        openModal={openModal}
        setopenModal={setopenModal}
        bookingId={bookingId}

      />
      <TicketDispositionModal
        openTicketModal={openTicketModal}
        setOpenTicketModal={setOpenTicketModal}
        bookingComplaints={bookingComplaints}
        ticketIndividualDetails={ticketIndividualDetails}
        getBookingQueryTickets={getBookingQueryTickets}
        setCollectionDate={setCollectionDate}
        packageList={packageList}
        getPackage={getPackage}
        getAvailableSlots={getAvailableSlots}
        zoneId={zoneId}
        ticketDetails={ticketDetails}
        collectionDate={collectionDate}
        booking_slots={timeSlots}
      />
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.SalesLeadReducer.loading,
  agentsList: state.SalesLeadReducer.agentsList,
  bookingList: state.SalesLeadReducer.bookingList,
  bookingComplaints: state.SalesLeadReducer.bookingComplaints,
  packageList: state.SalesLeadReducer.packageList,
  booking_slots: state.SalesLeadReducer.booking_slots,
});

export default connect(mapStateToProps, {
  getBookingQueryTickets,
  getAgentList,
  getAllBookings,
  getPackage,
  getAvailableSlots,
})(BookingsTable);
