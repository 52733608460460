export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  GET_USERS_LIST: "sl/get_user_list",
  UPDATE_USERS_LIST: "sl/update_user_list",
  GET_AREA: "sl/get_area",
  CREATE_AREA: "sl/create_area",
  UPDATE_AREA: "sl/update_area",
  GET_LTV_LEAD: "ce/get_ltv_lead",
  GET_ZONE: "sl/get_zone",
  UPDATE_ZONE: "sl/update_zone",
  CREATE_ZONE: "sl/create_zone",
  GET_TIMESLOTS: "sl/get_timeslots",
  GET_BLOG_TAGS: "sl/get_tags",
  GET_BLOG_CATEGORY: "sl/get_blog_categgory",
  GET_BLOG_IMAGES: "sl/get_blog_images",
  GET_DASHBOARD_OVERVIEW: "sl/get_dashboard_overview",
  GET_BLOGS: "sl/get_blogs",
  GET_BLOG_BY_SLUG: "sl/get_blog_by_slug",
  UPDATE_BLOGS: "sl/update_blogs",
  CREATE_BLOGS: "sl/create_blogs",
  DELETE_BLOG: "sl/delete_blog",
  GET_AGENTS_NEW_DATA: "sl/get_agents_new_data",
  CREATE_COUPON: "sl/create_coupon",
  UPDATE_COUPON: "sl/update_coupon",
  GET_PACKAGE: "sl/get_package",
  CREATE_PACKAGE: "sl/create_package",
  UPDATE_PACKAGE: "sl/update_package",
  GET_CITY_PRICES: "sl/get_city_prices",
  SET_LOADING: "sl/set_loading",
  SET_BOOKING_ID_LOADER: "sl/set_booking_id_loader",
  SET_BIOMARKER_LOADER: "sl/set_biomarker_loader",
  GET_PIN_CODE: "sl/get_pin_code",
  GET_CITIES: "sl/get_cities",
  GET_AGENTS: "sl/get_agents",
  GET_AGENTS_BY_USERGROUP: "sl/get_agents_by_usergroup",
  GET_PACKAGES: "sl/get_packages",
  SET_ALL_BOOKING_LOADING: "sl/set_all_booking_loading",
  GET_NEW_BOOKING_LIST: "sl/get_new_booking_list",
  GET_PHLEBOS: "sl/get_phlebos",
  GET_PARTNERS: "sl/get_partners",
  GET_COUPONS: "sl/get_coupons",
  CREATE_BOOKING: "sl/create_booking",
  UPDATE_BOOKING: "sl/update_booking",
  SET_LOADING_COMMENT: "sl_booking/set_loading_comment",
  GET_BOOKING_LIST: "sl/get_booking_list",
  GET_LEAD_DETAILS: "sl/get_lead_details",
  GET_LEAD_BY_ID: "sl/get_lead_by_id",
  LEAD_DETAILS_RESET: "sl/lead_details_reset",
  GET_LEADS: "sl/get_leads",
  GET_BOOKING_BY_ID: "sl/get_booking_by_id",
  CLICK_TO_CALL: "sl/click_to_call",
  GET_POSTIVE_CALLS_COMMENTS: "sl/get_postive_calls_commnets",
  ADD_POSTIVE_CALLS_COMMENT: "sl/add_comments",
  SET_COMMENTS: "sl/set_commnets",
  UPLOAD_FILE: "sl/upload",
  SEARCH_CALL: "sl/serach",
  BOOKING_RESET: "sl/booking_reset",
  ADD_COMMENT: "positive_calls/add_comment",
  CREATE_CALL: "sl/createcall",
  GET_LEAD_SOURCE: "sl/getleadsource",
  GET_RECORDINGS: "sl/get_recordings",
  GET_BOOKINGS_COMMENTS: "sl/get_bookings_commnets",
  ADD_BOOKINGS_COMMENT: "sl/add_bookings_comments",
  GET_WHATSAPP_COMMENTS: "sl/get_whatsapp_comments",
  GET_WHATSAPP_COMMENTS_BY_LEAD: "sl/get_whatsapp_comments_by_lead",
  SEND_WHATSAPP_COMMENTS: "sl/send",
  GET_CALL_STATS: "sl/get_call_stats",
  RAISE_COMPLAINT: "sl/raise_complaint",
  GET_COMPLAINTS: "sl/get_complaints",
  ASSIGN_DOCTOR: "sl/assign_doctor",
  SEND_FEEDBACK: "sl/send_feedback",
  SET_LOADING_CHAT: "se_booking/set_loading_chat",
  GET_AVAILABLE_SLOTS: "sl/get_available_slots",
  GET_ALL_LEADS: "sl/get_all_leads",
  LEAD_TRANSFER_BY_COMMA: "sl/set_comments",
  GET_COLLECTION_SLOT: "sl/get_collection_slot",
  LEAD_TRANSFER_TO_TATATALE: "sl/lead_transfer_to_tatatale",
  GET_PANEL_USERS: "sl/get_panel_user",
  GET_LAB_CREDENTIAL_DETAILS: "sl/get_lab_credential_details",
  UPDATE_LAB_CREDENTIALS: "sl/update_lab_credentials",
  GET_ZONE_COMMENTS: "sl/get_zone_comments",
  GET_DISPOSITIONS: "sl/get_dispositions",
  SET_LOADING_INDIVIDUAL_CHAT: "sl_booking/set_loading_individual_chat",
  GET_DISPOSITION_DATA: "sl/get_disposition_data",
  ADD_REMARK_ON_CALL: "sl/add_remark_on_call",
  GET_INCORRECT_NUMBER_LEAD: "sl/get_incorrect_number_lead",
  SAME_NUMBER_BOOKING: "sl/same_number_booking",
  GET_PAYMENT_SEND_LINK: "sl/get_payment_send_link",
  GET_PAYMENT_RESEND_LINK: "sl/get_payment_resend_link",
  GET_PAYMENT_CANCEL_LINK: "sl/get_payment_cancel_link",
  GET_SMS_REPORT_SEND: "sl/get_sms_report_send",
  GET_BREAK_DATA: "sl/get_break_data",
  CREATE_ADDITIONAL_BOOKING: "sl/create_additional_booking",
  UPDATE_ADDITIONAL_BOOKING: "sl/update_additional_booking",
  GET_ASSIGNED_USERGROUP: "sl/get_assigned_usergroup",
  GET_TODAYS_CALL_DETAILS: "sl/get_todays_call_details",
  GET_TOP_RECORDS: "sl/get_top_records",
  GET_VERIFICATION_BOOKING_DETAILS: "sl/get_verification_booking_details",
  GET_SMS_DETAILS: "sl/get_sms_details",
  GET_EMAIL_DETAILS: "sl/get_email_details",
  GET_REPORT_STATUS: "sl/get_report_status",
  GET_CENTER_INFORMATION: "sl/get_center_information",
  GET_LAB_DETAILS: "sl/get_lab_details",
  GET_PAYMENT_INFORMATION: "sl/get_payment_information",
  GET_NOTIFICATION_MESSAGE: "sl/get_notification_message",
  GET_USER_DETAILS: "sl/get_user_details",
  GET_REPORT_DOWNLOAD: "sl/get_report_download",
  CREATE_PANEL_USER: "sl/create_panel_user",
  UPDATED_PANEL_USER: "sl/updated_panel_user",
  ASSIGNED_PANEL_USER_DATA: "sl/assigned_panel_user_data",
  UPDATE_PANEL_USERS: "sl/update_panel_users",
  GET_PANEL_USER_LIST_DATA: "sl/get_panel_user_list_data",
  GET_PANEL_LIST: "sl/get_panel_list",
  GET_PANEL_USER_DATA: "sl/get_panel_user_DATA",
  GET_PANELS_USERS: "sl/get_panels_users",
  GET_AREA_COMMENTS: "sl/get_area_comments",
  GET_PANEL_COMMENT: "sl/get_panel_comments",
  GET_UNMASKED_NUMBER: "sl/get_unmasked_number",
  GET_FIREBASE_NOTIFICATION: "sl/get_firebase_notification",
  GET_BREAK_RESPONSE: "sl/get_break_response",
  GET_PANELUSER_ACTIVE_DEACTIVE: "sl/get_active_deactive_panel_user",
  GET_AGENT_DATA: "sl/get_agent_data",
  GET_TODAYS_MISSED_CALLS: "sl/get_today_missed_calls",
  UPDATE_USERS_STATUS: "sl/update_user_status",
  CHECK_GEOFENCE_AREA: "sl/checking-geofence-area",
  GET_RCFRESH_LEAD_DATA: "sl/get_rcfresh_lead_data",
  GET_INDIVIDUAL_WHATSAPP_CHAT: "sl/get_individual_whatsapp_chat",
  GET_BOOKING_LIST_WITH_TAT_TIME: "sl/get_booking_list_with_tat_time",
  GET_ADDITIONAL_BOOKING_LIST_WITH_TAT_TIME:
    "sl/get_additional_booking_list_with_tat_time",
  GET_VERIFIED_BY_LIST: "sla/get_verified_by_list",
  GET_SELF_USER_TYPE: "sl/get_self_user_type",
  GET_CUSTOMERS_LIST: "sl/get_customers_list",
  GET_RED_COIN: "sl/get_red_coins",
  GET_SUB_CENTER_INFORMATION: "sl/get_sub_center_information",
  GET_LEAD_STATUS: "sl/get_lead_status",
  GET_PRESCRIPTION_DATA: "sl/get_prescription_data",
  GET_ADDRESS_TYPE_SEATING: "sl/get_address_type_seating",
  CHECK_GEOFENCE_AREA_MAMPMYINDIA: "sl/checking-geofence-area-mapmyindia",
  GET_MAMPMYINDIA_ACCESS_TOKEN: "sl/get-mapmyindia-access-token",
  GET_ADDRESS_DETAILS_LAT_LONG: "sl/get-address-details-lat-long",
  UPDATE_LOGIN_STATUS: "sl/update-login-status/",
  GET_IMAGING_TIMESLOTS: "sl/get_imaging_timeslots",
  CREATE_IMAGING_BOOKING: "sl/CREATE_IMAGING_BOOKING",
  GET_MULTIPLE_PACKAGES: "sl/get_multiple_package",
  GET_DISPLAY_CITIES: "sl/get_display_cities",
  GET_BOOKING_CALLS: "sl/get_booking_call",
  GET_ADDITIONAL_BOOKING: "sl/get_additional_booking",
  GET_SIGNATURE: "sl/get_signature",
  GET_QRCODE_DATA: "sl/get_qrcode_data",
  GET_PERFORMANCE_DATA: "sl/get_performance_data",
  GET_TICKET_DATA: "sl/get_ticket_data",
  GET_TICKET_CATEGORIES: "sl/get_ticket_category",
  GET_TICEKT_SUB_CATEGORIES: "sl/get_ticket_categories",
  CREATE_TASK_TICKET: "sl/create_task_ticket",
  GET_RED_TECH_COMMENT: "sl/redtech_comments/",
  POST_RED_TECH_COMMENT: "sl/postredtech_comments/",
  GET_QUALITY_SCORING_REPORT: "ve/get_quality_scoring_report/",
  GET_QUALITY_SCORING_REPORT_FOR_BACKUP:
    "ve/get_quality_scoring_report_for_backup/",
  GET_PANEL_USER_LIST_DATA_TEAM_LEAD: "ve/get_panel_user_list_data_team_lead",
  GET_ASSIGNED_UNASSIGNED_BOOKING: "sl/get_assigned_unassigned_bookings",
  GET_CE_CENTER_FOR_CC: "sl/get_ce_center_for_cc",
  CREATE_PILOT_BOOKING: "sl/create_pilot_booking",
  SET_GEO_LOADING: "sl/set_geo_loading",
  SET_GEO_CHECK_ERROR: "sl/set_geo_check_error",
  UPDATE_LEADS: "sl/update_leads",
  GET_TICKET_COMMENTS: "sl/ticket_comments",
  RAISE_NEW_RAISE_TICEKT_DISPOSITION: "sl/cstech_create-ticekt",
  UPDATE_TICKET_STATUS: "sl/update_ticekt_status",
  GET_BOOKING_COMPLAINTS: "sl/get_booking_complaints",
  UPDATE_DISPOSITION: "sl/update_disposition",
  GET_BIOMARKER_SCREENING: "sl/biomarker_screening",
  GET_BIOMARKER_ULTASOUND: "sl/biomarker_ultasound",
  GET_ADDITIONAL_MEMBER: "sl/additional_member",
  BIOMARKER_RESET: "sl/biomarker_reset",
  SEND_LOCATION_REQUEST:"sl/communication/send-location-request/",
  GET_CRM_LEAD_ADDRESS:"sl/get_auth/address-crm/",
  POST_CRM_LEAD_ADDRESS:"sl/post_auth/address-crm/",
  LOCATION_VERIFIED: "sl/location_verified",
  GET_WHATSAPP_TEMPLATE: "sl/get_whatsapp_template",
  CREATE_WHATSAPP_TEMPLATE: "sl/create_whatsapp_template",
  GET_PANEL_USER_LIST_DATA_SALES_LEAD: "sl/get_panel_user_list_data_sales_lead",
  GET_OZONTEL_SKILL_DATA: "sl/get_ozontel_skill_data",
  // BOOKING_RESET: "sl/booking_reset",
  SET_REPORT_LOADING: "sl/set_report_loading",
  SIGN_OUT_IN_WHATSAPP_CHAT: "sl/sign_out_in_whatsapp_chat",
  SWITCH_CHAT_AGENT_IN_WHATSAPP_CHAT: "sl/switch_chat_agent_in_whatsapp_chat",
  HOLD_THE_CHAT: "sl/hold_the_chat",
  REDTECH_TICKETS_USING_LEAD: "sl/redtech_tickets_using_lead",
  SEND_LAST_REPORT_TO_LEAD: "sl/send_last_report_to_lead",
  LATEST_BOOKING_USING_LEAD: "sl/latest_booking_using_lead",
  GET_FAILED_REPORT_DATA: "sl/report_failed/",
  SEND_REPORT_BY_ID: "sl/send_report",
  QUEUE_NUMBER: "sl/queue_number",
  INITIATE_REFUND: "sl/initiate_refund",
};

interface UpdatedPanelUser {
  type: typeof ACTIONS.UPDATED_PANEL_USER;
  payload: Object;
}
interface queueNumber {
  type: typeof ACTIONS.QUEUE_NUMBER;
  payload: boolean;
}
interface SignOutInWhatsappChat {
  type: typeof ACTIONS.SIGN_OUT_IN_WHATSAPP_CHAT;
  payload: boolean;
}
interface SwitchChatAgentInWhatsappChat {
  type: typeof ACTIONS.SWITCH_CHAT_AGENT_IN_WHATSAPP_CHAT;
  payload: boolean;
}
interface getHoldTheChat {
  type: typeof ACTIONS.HOLD_THE_CHAT;
  payload: boolean;
}
interface allRedtechTicketsUsingLead {
  type: typeof ACTIONS.REDTECH_TICKETS_USING_LEAD;
  payload: boolean;
}
interface latestBookingUsingLead {
  type: typeof ACTIONS.LATEST_BOOKING_USING_LEAD;
  payload: boolean;
}
interface sendLastReportToLeadId {
  type: typeof ACTIONS.SEND_LAST_REPORT_TO_LEAD;
  payload: boolean;
}
interface GetFailedReportData {
  type: typeof ACTIONS.GET_FAILED_REPORT_DATA;
  payload: Array<any>;
}
interface SendReportById {
  type: typeof ACTIONS.SEND_REPORT_BY_ID;
  payload: Object;
}
interface SetReportLoading {
  type: typeof ACTIONS.SET_REPORT_LOADING;
  payload: boolean;
}
interface GetWhatsappTemplate {
  type: typeof ACTIONS.GET_WHATSAPP_TEMPLATE;
  payload: Array<any>;
}
interface CreateWhatsappTemplate {
  type: typeof ACTIONS.CREATE_WHATSAPP_TEMPLATE;
  payload: boolean;
}
interface getCrmLeadAddress {
  type: typeof ACTIONS.GET_CRM_LEAD_ADDRESS;
  payload: Array<any>;
}
interface PostCrmLeadAddress {
  type: typeof ACTIONS.POST_CRM_LEAD_ADDRESS;
  payload: Object;
}
interface SendLocationRequest {
  type: typeof ACTIONS.SEND_LOCATION_REQUEST;
  payload: Object;
}
interface LocationVerified {
  type: typeof ACTIONS.LOCATION_VERIFIED;
  payload: boolean;
}
interface GetCeCenterForCc {
  type: typeof ACTIONS.GET_CE_CENTER_FOR_CC;
  payload: Array<any>;
}
interface GetBookingCall {
  type: typeof ACTIONS.GET_BOOKING_CALLS;
  payload: Array<any>;
}
interface GetDashboardOverview {
  type: typeof ACTIONS.GET_DASHBOARD_OVERVIEW;
  payload: Array<any>;
}

interface GetAgentNewData {
  type: typeof ACTIONS.GET_AGENTS_NEW_DATA;
  payload: Array<any>;
}
interface GetAdditionalBooking {
  type: typeof ACTIONS.GET_ADDITIONAL_BOOKING;
  payload: Array<any>;
}
interface GetQRCodeData {
  type: typeof ACTIONS.GET_QRCODE_DATA;
  payload: Array<any>;
}
interface GetLtvLead {
  type: typeof ACTIONS.GET_LTV_LEAD;
  payload: boolean;
}
interface GetSignature {
  type: typeof ACTIONS.GET_SIGNATURE;
  payload: Array<any>;
}
interface CheckGeoFenceAreaMapMyIndia {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA;
  payload: Array<any>;
}
interface GetMapMyIndiaAccessToken {
  type: typeof ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN;
  payload: Array<any>;
}
interface GetAddressDetailsLatLong {
  type: typeof ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG;
  payload: Array<any>;
}
interface UpdateLoginStatus {
  type: typeof ACTIONS.UPDATE_LOGIN_STATUS;
  payload: Object;
}

interface GetPrescriptionData {
  type: typeof ACTIONS.GET_PRESCRIPTION_DATA;
  payload: Array<any>;
}

interface UpdateUserStatus {
  type: typeof ACTIONS.UPDATE_USERS_STATUS;
  payload: Object;
}
interface GetAddressTypeSeating {
  type: typeof ACTIONS.GET_ADDRESS_TYPE_SEATING;
  payload: Object;
}
interface GetSelfUserType {
  type: typeof ACTIONS.GET_SELF_USER_TYPE;
  payload: Array<any>;
}
interface GetCustomerList {
  type: typeof ACTIONS.GET_CUSTOMERS_LIST;
  payload: Array<any>;
}
interface CheckGeoFenceArea {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA;
  payload: Array<any>;
}
interface GetIndividualWhatsappChat {
  type: typeof ACTIONS.GET_INDIVIDUAL_WHATSAPP_CHAT;
  payload: Array<any>;
}
interface GetBookingsTatTime {
  type: typeof ACTIONS.GET_BOOKING_LIST_WITH_TAT_TIME;
  payload: boolean;
}
interface GetCollectionSlot {
  type: typeof ACTIONS.GET_COLLECTION_SLOT;
  payload: Array<any>;
}
interface GetAdditionalBookingsTatTime {
  type: typeof ACTIONS.GET_ADDITIONAL_BOOKING_LIST_WITH_TAT_TIME;
  payload: boolean;
}
interface GetRCFreshLeadData {
  type: typeof ACTIONS.GET_RCFRESH_LEAD_DATA;
  payload: Array<any>;
}
interface GetUnmaskedNumber {
  type: typeof ACTIONS.GET_UNMASKED_NUMBER;
  payload: Array<any>;
}
interface GetBreakResponse {
  type: typeof ACTIONS.GET_BREAK_RESPONSE;
  payload: Object;
}
interface GetAgentData {
  type: typeof ACTIONS.GET_AGENT_DATA;
  payload: Array<any>;
}
interface GetActiveDeactivePanelUser {
  type: typeof ACTIONS.GET_PANELUSER_ACTIVE_DEACTIVE;
  payload: Array<any>;
}
interface GetAreaComments {
  type: typeof ACTIONS.GET_AREA_COMMENTS;
  payload: Array<any>;
}
interface GetPanelComments {
  type: typeof ACTIONS.GET_PANEL_COMMENT;
  payload: Array<any>;
}
interface AssignedpanelUser {
  type: typeof ACTIONS.ASSIGNED_PANEL_USER_DATA;
  payload: Object;
}
interface UpadtepanelUser {
  type: typeof ACTIONS.UPDATE_PANEL_USERS;
  payload: Object;
}
interface SetLoadingChat {
  type: typeof ACTIONS.SET_LOADING_CHAT;
  payload: boolean;
}
interface CreatePanelUser {
  type: typeof ACTIONS.CREATE_PANEL_USER;
  payload: Object;
}
interface GetTataTalyLead {
  type: typeof ACTIONS.LEAD_TRANSFER_TO_TATATALE;
  payload: Object;
}
interface GetAssignedUsergroup {
  type: typeof ACTIONS.GET_ASSIGNED_USERGROUP;
  payload: Object;
}
interface SetUserList {
  type: typeof ACTIONS.UPDATE_USERS_LIST;
  payload: Object;
}
interface CreatePilotBooking {
  type: typeof ACTIONS.CREATE_PILOT_BOOKING;
  payload: boolean;
}
interface GetArea {
  type: typeof ACTIONS.GET_AREA;
  payload: Object;
}
interface CreateArea {
  type: typeof ACTIONS.CREATE_AREA;
  payload: Object;
}
interface UpdateArea {
  type: typeof ACTIONS.UPDATE_AREA;
  payload: Object;
}
interface GetZone {
  type: typeof ACTIONS.GET_ZONE;
  payload: Object;
}
interface CreateZone {
  type: typeof ACTIONS.CREATE_ZONE;
  payload: Object;
}
interface UpdateZone {
  type: typeof ACTIONS.UPDATE_ZONE;
  payload: Object;
}
interface GetTimeslots {
  type: typeof ACTIONS.GET_TIMESLOTS;
  payload: Object;
}
interface GetBlogTags {
  type: typeof ACTIONS.GET_BLOG_TAGS;
  payload: Object;
}
interface GetMultiplepackage {
  type: typeof ACTIONS.GET_MULTIPLE_PACKAGES;
  payload: Array<any>;
}
interface GetDisplayCities {
  type: typeof ACTIONS.GET_DISPLAY_CITIES;
  payload: Array<any>;
}

interface GetBlogCategory {
  type: typeof ACTIONS.GET_BLOG_CATEGORY;
  payload: Object;
}
interface GetBlogImages {
  type: typeof ACTIONS.GET_BLOG_IMAGES;
  payload: Object;
}
interface GetBlogs {
  type: typeof ACTIONS.GET_BLOGS;
  payload: Object;
}
interface GetBlogBySlug {
  type: typeof ACTIONS.GET_BLOG_BY_SLUG;
  payload: Object;
}
interface GetRedCoins {
  type: typeof ACTIONS.GET_RED_COIN;
  payload: Array<any>;
}
interface CreateBlogs {
  type: typeof ACTIONS.CREATE_BLOGS;
  payload: Object;
}
interface UpdateBlogs {
  type: typeof ACTIONS.UPDATE_BLOGS;
  payload: Object;
}
interface DeleteBlog {
  type: typeof ACTIONS.DELETE_BLOG;
  payload: Object;
}
interface CreateCoupon {
  type: typeof ACTIONS.CREATE_COUPON;
  payload: Object;
}
interface UpdateCoupon {
  type: typeof ACTIONS.UPDATE_COUPON;
  payload: Object;
}
interface GeoLoading {
  type: typeof ACTIONS.SET_GEO_LOADING;
  payload: boolean;
}
interface CheckGeoCheckError {
  type: typeof ACTIONS.SET_GEO_CHECK_ERROR;
  payload: boolean;
}
interface GetPackageBySLug {
  type: typeof ACTIONS.GET_PACKAGE;
  payload: Object;
}
interface CreatePackage {
  type: typeof ACTIONS.CREATE_PACKAGE;
  payload: Object;
}
interface UpdatePackage {
  type: typeof ACTIONS.UPDATE_PACKAGE;
  payload: Object;
}
interface GetCityPrices {
  type: typeof ACTIONS.GET_CITY_PRICES;
  payload: Object;
}

interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}

interface UploadFile {
  type: typeof ACTIONS.UPLOAD_FILE;
  payload: Object;
}

interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}

interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}

interface GetPhlebo {
  type: typeof ACTIONS.GET_PHLEBOS;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}
interface GetImagingTimeSlots {
  type: typeof ACTIONS.GET_IMAGING_TIMESLOTS;
  payload: Array<any>;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}

interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}
interface GetPerformanceData {
  type: typeof ACTIONS.GET_PERFORMANCE_DATA;
  payload: boolean;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}

interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}

interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}

interface GetPhlebo {
  type: typeof ACTIONS.GET_PHLEBOS;
  payload: Array<any>;
}

interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}
interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}

interface SetBookingIdLoader {
  type: typeof ACTIONS.SET_BOOKING_ID_LOADER;
  payload: boolean;
}
interface SetBiomarkerLoader {
  type: typeof ACTIONS.SET_BIOMARKER_LOADER;
  payload: boolean;
}

interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}
interface GetPanelUserDataTeamLead {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA_TEAM_LEAD;
  payload: Array<any>;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}

interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface GetAgentByUserGroup {
  type: typeof ACTIONS.GET_AGENTS_BY_USERGROUP;
  payload: Object;
}
interface GetLabCredentialDetails {
  type: typeof ACTIONS.GET_LAB_CREDENTIAL_DETAILS;
  payload: Object;
}
interface UpdatelabCredential {
  type: typeof ACTIONS.UPDATE_LAB_CREDENTIALS;
  payload: Object;
}
interface GetZoneComment {
  type: typeof ACTIONS.GET_ZONE_COMMENTS;
  payload: Object;
}
interface GetDispositions {
  type: typeof ACTIONS.GET_DISPOSITIONS;
  payload: Array<any>;
}
interface GetDispositionData {
  type: typeof ACTIONS.GET_DISPOSITION_DATA;
  payload: Object;
}
interface AddRemarkOnCall {
  type: typeof ACTIONS.ADD_REMARK_ON_CALL;
  payload: Object;
}
interface GetIncorrectNumberLead {
  type: typeof ACTIONS.GET_INCORRECT_NUMBER_LEAD;
  payload: Object;
}
interface SameNumberBooking {
  type: typeof ACTIONS.SAME_NUMBER_BOOKING;
  payload: Object;
}
interface GetPaymentSendLink {
  type: typeof ACTIONS.GET_PAYMENT_SEND_LINK;
  payload: Object;
}
interface GetPaymentReSendLink {
  type: typeof ACTIONS.GET_PAYMENT_RESEND_LINK;
  payload: Object;
}
interface GetPaymentCancelLink {
  type: typeof ACTIONS.GET_PAYMENT_CANCEL_LINK;
  payload: Object;
}
interface GetSmsReportSent {
  type: typeof ACTIONS.GET_SMS_REPORT_SEND;
  payload: Object;
}
interface GetBreakData {
  type: typeof ACTIONS.GET_BREAK_DATA;
  payload: Object;
}
interface CreateAdditionalBooking {
  type: typeof ACTIONS.CREATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface UpdateAdditionalBooking {
  type: typeof ACTIONS.UPDATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface GetTodayCallDetails {
  type: typeof ACTIONS.GET_TODAYS_CALL_DETAILS;
  payload: Array<any>;
}
interface SetLoadingIndividualChat {
  type: typeof ACTIONS.SET_LOADING_INDIVIDUAL_CHAT;
  payload: boolean;
}
interface GetTopRecords {
  type: typeof ACTIONS.GET_TOP_RECORDS;
  payload: Array<any>;
}
interface GetVerificationBookingDetails {
  type: typeof ACTIONS.GET_VERIFICATION_BOOKING_DETAILS;
  payload: Array<any>;
}
interface GetSmsDetails {
  type: typeof ACTIONS.GET_SMS_DETAILS;
  payload: Array<any>;
}
interface GetEmailDetails {
  type: typeof ACTIONS.GET_EMAIL_DETAILS;
  payload: Array<any>;
}
interface GetReportStatus {
  type: typeof ACTIONS.GET_REPORT_STATUS;
  payload: Array<any>;
}

interface SetLoadingComment {
  type: typeof ACTIONS.SET_LOADING_COMMENT;
  payload: boolean;
}
interface GetCenterInfo {
  type: typeof ACTIONS.GET_CENTER_INFORMATION;
  payload: Array<any>;
}
interface GetLabDetails {
  type: typeof ACTIONS.GET_LAB_DETAILS;
  payload: Array<any>;
}
interface GetPaymentInfro {
  type: typeof ACTIONS.GET_PAYMENT_INFORMATION;
  payload: Array<any>;
}
interface GetNotificationMessage {
  type: typeof ACTIONS.GET_NOTIFICATION_MESSAGE;
  payload: Array<any>;
}
interface GetPanelUserList {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA;
  payload: Array<any>;
}
interface GetPanelList {
  type: typeof ACTIONS.GET_PANEL_LIST;
  payload: Array<any>;
}
interface GetReportDownload {
  type: typeof ACTIONS.GET_REPORT_DOWNLOAD;
  payload: Object;
}
interface getPanelUserData {
  type: typeof ACTIONS.GET_PANEL_USER_DATA;
  payload: Object;
}
interface getPanelsUsers {
  type: typeof ACTIONS.GET_PANELS_USERS;
  payload: Object;
}
interface GetUserDetails {
  type: typeof ACTIONS.GET_USER_DETAILS;
  payload: Object;
}
interface GetFireBaseNotification {
  type: typeof ACTIONS.GET_FIREBASE_NOTIFICATION;
  payload: Object;
}
interface GetTodayMissedCalls {
  type: typeof ACTIONS.GET_TODAYS_MISSED_CALLS;
  payload: Object;
}
interface GetVerifiedByList {
  type: typeof ACTIONS.GET_VERIFIED_BY_LIST;
  payload: Object;
}
interface GetSubCenterInformation {
  type: typeof ACTIONS.GET_SUB_CENTER_INFORMATION;
  payload: Array<any>;
}
interface CreateImagingBooking {
  type: typeof ACTIONS.CREATE_IMAGING_BOOKING;
  payload: boolean;
}
interface GetLeadStatus {
  type: typeof ACTIONS.GET_LEAD_STATUS;
  payload: Object;
}

interface GetRedTechComment {
  type: typeof ACTIONS.GET_RED_TECH_COMMENT;
  payload: Array<any>;
}
interface GetTicketData {
  type: typeof ACTIONS.GET_TICKET_DATA;
  payload: Object;
}
interface GetTicketCategory {
  type: typeof ACTIONS.GET_TICKET_CATEGORIES;
  payload: Object;
}
interface GetTicketSubCategories {
  type: typeof ACTIONS.GET_TICEKT_SUB_CATEGORIES;
  payload: Object;
}
interface PostRedTechComment {
  type: typeof ACTIONS.POST_RED_TECH_COMMENT;
  payload: Object;
}
interface CreateTaskTicket {
  type: typeof ACTIONS.CREATE_TASK_TICKET;
  payload: Array<any>;
}

interface GetQualityScoringReport {
  type: typeof ACTIONS.GET_QUALITY_SCORING_REPORT;
  payload: Array<any>;
}

interface GetQualityScoringReportForBackup {
  type: typeof ACTIONS.GET_QUALITY_SCORING_REPORT_FOR_BACKUP;
  payload: Array<any>;
}

interface GetAssignedUnassignedBooking {
  type: typeof ACTIONS.GET_ASSIGNED_UNASSIGNED_BOOKING;
  payload: Array<any>;
}
interface UpdateLeads {
  type: typeof ACTIONS.UPDATE_LEADS;
  payload: Array<any>;
}
interface RaiseNewTicektDisposition {
  type: typeof ACTIONS.RAISE_NEW_RAISE_TICEKT_DISPOSITION;
  payload: Array<any>;
}
interface GetTicketComments {
  type: typeof ACTIONS.GET_TICKET_COMMENTS;
  payload: Array<any>;
}
interface UpdateTicketStatus {
  type: typeof ACTIONS.UPDATE_TICKET_STATUS;
  payload: Array<any>;
}
interface GetBookingQueryTickets {
  type: typeof ACTIONS.GET_BOOKING_COMPLAINTS;
  payload: boolean;
}
interface UpdateActionDisposition {
  type: typeof ACTIONS.UPDATE_DISPOSITION;
  payload: Array<any>;
}
interface BiomarkerReset {
  type: typeof ACTIONS.BIOMARKER_RESET;
  payload: boolean;
}
interface GetBiomarkerScreening {
  type: typeof ACTIONS.GET_BIOMARKER_SCREENING;
  payload: Object;
}
interface GetAdditionalMember {
  type: typeof ACTIONS.GET_ADDITIONAL_MEMBER;
  payload: Array<any>;
}
interface GetBiomarkerUltasound {
  type: typeof ACTIONS.GET_BIOMARKER_ULTASOUND;
  payload: Object;
}
interface GetNewBookings {
  type: typeof ACTIONS.GET_NEW_BOOKING_LIST;
  payload: boolean;
}
interface GetBookingsLoader {
  type: typeof ACTIONS.SET_ALL_BOOKING_LOADING;
  payload: boolean;
}
interface GetPanelUserDataSalesLead {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA_SALES_LEAD;
  payload: Array<any>;
}
interface InitiateRefund {
  type: typeof ACTIONS.INITIATE_REFUND;
  payload: Array<any>;
}
interface getOzontelSkillData {
  type: typeof ACTIONS.GET_OZONTEL_SKILL_DATA;
  payload: Object;
}
export type BookingTypes =
  | SetUserList
  | GetArea
  | CreateArea
  | UpdateArea
  | GetZone
  | CreateZone
  | UpdateZone
  | GetBlogs
  | GetBlogBySlug
  | CreateBlogs
  | UpdateBlogs
  | GetTimeslots
  | GetBlogTags
  | GetBlogCategory
  | GetBlogImages
  | CreateCoupon
  | UpdateCoupon
  | DeleteBlog
  | GetPackageBySLug
  | CreatePackage
  | UpdatePackage
  | GetCityPrices
  | SetLoading
  | SearchCall
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetAgents
  | GetPhlebo
  | GetAgentByUserGroup
  | GetPackages
  | CreateBooking
  | UpdateBooking
  | GetPartners
  | GetCoupons
  | GetBookings
  | GetBookingById
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | CreateAdditionalBooking
  | UpdateAdditionalBooking
  | SetComments
  | UploadFile
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetAgents
  | GetPhlebo
  | GetPackages
  | CreateBooking
  | UpdateBooking
  | GetPartners
  | GetCoupons
  | GetBookings
  | GetBookingById
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | SetComments
  | SearchCall
  | GetLabCredentialDetails
  | UpdatelabCredential
  | GetZoneComment
  | GetDispositions
  | GetDispositionData
  | AddRemarkOnCall
  | GetIncorrectNumberLead
  | SameNumberBooking
  | GetPaymentSendLink
  | GetPaymentReSendLink
  | GetPaymentCancelLink
  | GetSmsReportSent
  | GetBreakData
  | GetCollectionSlot
  | GetAssignedUsergroup
  | GetTodayCallDetails
  | GetTopRecords
  | GetVerificationBookingDetails
  | GetSmsDetails
  | GetEmailDetails
  | GetReportStatus
  | GetCenterInfo
  | GetLabDetails
  | GetPaymentInfro
  | GetLtvLead
  | GetBookingCall
  | GetNotificationMessage
  | GetTataTalyLead
  | GetUserDetails
  | GetReportDownload
  | CreatePanelUser
  | UpdatedPanelUser
  | AssignedpanelUser
  | UpadtepanelUser
  | GetPanelUserList
  | GetPanelList
  | getPanelUserData
  | getPanelsUsers
  | GetDashboardOverview
  | GetAreaComments
  | GetPanelComments
  | GetUnmaskedNumber
  | GetActiveDeactivePanelUser
  | GetAgentData
  | GetTodayMissedCalls
  | UpdateUserStatus
  | CheckGeoFenceArea
  | GetRCFreshLeadData
  | GetIndividualWhatsappChat
  | GetBookingsTatTime
  | GetAdditionalBookingsTatTime
  | GetVerifiedByList
  | GetSelfUserType
  | GetCustomerList
  | GetRedCoins
  | GetBreakResponse
  | GetSubCenterInformation
  | GetLeadStatus
  | GetPrescriptionData
  | GetAddressTypeSeating
  | CheckGeoFenceAreaMapMyIndia
  | GetMapMyIndiaAccessToken
  | UpdateLoginStatus
  | GetImagingTimeSlots
  | CreateImagingBooking
  | GetMultiplepackage
  | GetDisplayCities
  | GetAdditionalBooking
  | GetQRCodeData
  | GetSignature
  | GetPerformanceData
  | GetTicketData
  | GetTicketCategory
  | GetTicketSubCategories
  | CreateTaskTicket
  | GetRedTechComment
  | PostRedTechComment
  | GetQualityScoringReport
  | GetPanelUserDataTeamLead
  | GetQualityScoringReportForBackup
  | GetAssignedUnassignedBooking
  | GetCeCenterForCc
  | CreatePilotBooking
  | UpdateLeads
  | GetTicketComments
  | RaiseNewTicektDisposition
  |UpdateTicketStatus
  |UpdateActionDisposition
  |GetAgentNewData
  | GetBookingQueryTickets
  | BiomarkerReset
  | GetBiomarkerScreening
  | GetAdditionalMember
  | GetBiomarkerUltasound
  | GetNewBookings
  | GetBookingsLoader
  | SendLocationRequest
  | getCrmLeadAddress
  | SetLoadingComment
  | PostCrmLeadAddress
  | SetLoadingChat
  | LocationVerified
  | GetAddressDetailsLatLong
  | SetLoadingIndividualChat
  | GetWhatsappTemplate
  | CreateWhatsappTemplate
  | SetBookingIdLoader
  | SetBiomarkerLoader
  | GetPanelUserDataSalesLead
  | getOzontelSkillData
  | SetReportLoading
  | SignOutInWhatsappChat
  | SwitchChatAgentInWhatsappChat
  | getHoldTheChat
  | allRedtechTicketsUsingLead
  | sendLastReportToLeadId
  | latestBookingUsingLead
  | GetFailedReportData
  | SendReportById
  | InitiateRefund
  | queueNumber