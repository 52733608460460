import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, TextField, Grid,} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from "react-router-dom";
import Loader from '../../Components/loader';
import Stack from '@mui/material/Stack';
import { useDispatch } from "react-redux";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center', position: 'absolute',
        top: '10%',
        left: '10%',
        overflow: 'scroll',
        height: '100%',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    },
    table: {
        minWidth: 650,
    },
}));
type ModalProps = {
    setOpenPackageModal: Function;
    openPackageModal: boolean;
    loading: any;
    packageList: any
    getPackage: any
    getSubCenterInfo: any
    subCenterInfor: any
    getTimeslots: any
    timeSlots: any
    dayList: any;
    createCenterPackage: any;
    getCenterAddressPrice: any;
    currentUrl: any;
    setOrgType:any;

};

const CreatePhlebo: React.FC<ModalProps> = ({
    setOpenPackageModal,
    openPackageModal,
    packageList,
    getPackage,
    getSubCenterInfo,
    subCenterInfor,
    getTimeslots,
    timeSlots,
    loading,
    dayList,
    createCenterPackage,
    getCenterAddressPrice,
    currentUrl,
    setOrgType,
}) => {
    const history = useHistory()
    const classes = useStyles();
    let dispatch = useDispatch();
    const timer = useRef<any>(0)
    
    const [packagePrice, setPackagePrice] = useState<any>("")
    const [offerPrice, setOfferPrice] = useState<any>("")
    const [transferPrice, setTransferPrice] = useState<String>("")
    const [centerId, setCenterId] = useState<any>("")
    const [packageId, setPackageId] = useState<any>("")
    const [timeslotsIds, setTimeslotsIds] = useState<any>([])
    const [timeInfo, setTimeInfo] = useState<any>([])
    const [timeInfoDisp, setTimeInfoDisp] = useState<any>([])
    const [dayId, setDayId] = useState<any>("")
    const [dayDisp, setDayDisp] = useState<any>("")
    const [dayKey, setDayKey] = useState<any>(0)
    const [timeKey, setTimeKey] = useState<any>(0)
    const [timeDayDisplay, setTimeDayDisplay] = useState<any>([])
    const [centreInput, setCentreInput] = useState("")
    const [testInput, setTestInput] = useState("")
    const[remarks,setRemarks]=useState<String>("")
    const [packType,setPackType]=useState<any>("imaging")
    const [key,setKey]=useState<any>(0)
    const handleClose = () => {
        setOpenPackageModal(false);
        history.push("/dashboard/ia/testmaster")
    };

    const handleCreatepackage = async () => {
        const body = {
            offer_price: offerPrice,
            package_price: packagePrice,
            transfer_price: transferPrice,
            center_address: centerId,
            remarks:remarks,
           package: packageId, 
        }
     
            await createCenterPackage(body)
            handleClose()
       
        // dispatch({ type: ACTIONS.GET_CENTER_ADDRESS_PRICE, payload: [] });
       
    }
    // const handleDayTimeAdd = () => {
    //     let tempData = [...timeInfo]
    //     let dataDisp = [...timeDayDisplay]
    //     dataDisp.push({ day: dayDisp, timeslots: timeInfoDisp })
    //     tempData.push({ day: dayId, timeslot: timeslotsIds })
    //     setTimeInfo(tempData)
    //     setTimeDayDisplay(dataDisp)
    //     setDayKey(dayId as number)
    //     setTimeKey(dayId as number)
    // }
    const handleOrgType = (type:any) =>{
        setPackType(type)
        setOrgType(type)
        setKey(Number(packageId)*3)
    }
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openPackageModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                {loading ? <Loader /> : (
                    <Fade in={openPackageModal}>

                        <div className={classes.paper}>
                            <div className={classes.head}>
                                <h4
                                    style={{
                                        marginBottom: "1rem",
                                    }}
                                    id="transition-modal-title"
                                >
                                    CREATE INDIVIDUAL TEST
                                </h4>
                                <CloseIcon onClick={() => handleClose()} />
                            </div>
                            <Stack direction="row" spacing={2}>
                            <Button variant="contained" style={{background:packType==="imaging"?"#4caf50":""}} onClick={()=>handleOrgType("imaging")}>Imaging Package</Button>
                            <Button variant="contained" style={{background:packType==="homedx"?"#4caf50":""}} onClick={()=>handleOrgType("homedx")}>Home-Dx Package</Button>
                            </Stack>
                            <form onSubmit={handleCreatepackage}>
                                <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                                    <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                        <Grid item xs={12} style={{ padding: "0" }}>
                                            <p>SELECT CENTRE</p>
                                        </Grid>
                                        <Grid item xs={12} style={{ padding: "0" }}>
                                            <Autocomplete
                                                id="center"
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                                        setCenterId(obj.id);
                                                    }

                                                }}
                                                options={subCenterInfor.length > 0 ? subCenterInfor : []}
                                                freeSolo
                                                blurOnSelect
                                                aria-required
                                                inputValue={centreInput}

                                                limitTags={1}
                                                getOptionLabel={(option: any) => `Center:${option?.center?.name},Address:${option?.address}`}
                                                disableClearable
                                                disableCloseOnSelect
                                                onInputChange={(event, newInputValue) => {
                                                    setCentreInput(newInputValue)
                                                    clearTimeout(timer.current);
                                                    timer.current = setTimeout(() => {
                                                        getSubCenterInfo(`?imaging=true&name=${newInputValue}`);
                                                    }, 1000);
                                                    if (newInputValue.length === 0) {
                                                        setCenterId("");
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        className="input"
                                                        {...params}
                                                        label="CENTRE NAME"
                                                        variant="outlined"
                                                        style={{ width: "100%", margin: "0", padding: "0" }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                        <Grid item xs={12} style={{ padding: "0" }}>
                                            <p>SELECT TEST</p>
                                        </Grid>
                                        <Grid item xs={12} style={{ padding: "0" }}>
                                            <Autocomplete
                                                id="package"
                                                key={key}
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                                        setPackageId(obj.id);
                                                    }
                                                }}
                                                options={packageList?.results || []}
                                                freeSolo
                                                blurOnSelect
                                                aria-required
                                                inputValue={testInput}
                                                limitTags={1}
                                                getOptionLabel={(option: any) => option.name}
                                                disableClearable
                                                disableCloseOnSelect
                                                onInputChange={(event, newInputValue) => {
                                                    setTestInput(newInputValue)
                                                    clearTimeout(timer.current);
                                                    timer.current = setTimeout(() => {
                                                        getPackage(`?org_type=imaging&code=${newInputValue}`);
                                                    }, 1000);
                                                    if (newInputValue.length === 0) {
                                                        setPackageId("");
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        className="input"
                                                        {...params}
                                                        label="TEST NAME"
                                                        variant="outlined"
                                                        style={{ width: "100%", margin: "0", padding: "0" }}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                        <Grid item xs={12} style={{ padding: "0" }}>
                                            <p>OFFER PRICE</p>
                                        </Grid>
                                        <Grid item xs={12} style={{ padding: "0" }}>
                                            <TextField className="input"
                                                name="offerprice"
                                                type="number"
                                                onWheel={(e: any) => e.target.blur()}
                                                placeholder="Enter offer Price"
                                                value={offerPrice}
                                                variant="outlined"
                                                style={{ margin: "0", width: "100%" }}
                                                onChange={(e) => setOfferPrice(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                        <Grid item xs={12} style={{ padding: "0" }}>
                                            <p>MRP</p>
                                        </Grid>
                                        <Grid item xs={12} style={{ padding: "0" }}>
                                            <TextField className="input"
                                                name="packagePrice"
                                                type="number"
                                                onWheel={(e: any) => e.target.blur()}
                                                placeholder="Enter Mrp Price"
                                                value={packagePrice}
                                                variant="outlined"
                                                style={{ margin: "0", width: "100%" }}
                                                onChange={(e) => setPackagePrice(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                        <Grid item xs={12} style={{ padding: "0" }}>
                                            <p>TRANSFER PRICE</p>
                                        </Grid>
                                        <Grid item xs={12} style={{ padding: "0" }}>

                                            <TextField className="input"
                                                name="transferprice"
                                                type="number"
                                                onWheel={(e: any) => e.target.blur()}
                                                placeholder="Enter Transfer price"
                                                value={transferPrice}
                                                variant="outlined"
                                                style={{ margin: "0", width: "100%" }}
                                                onChange={(e) => setTransferPrice(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                        <Grid item xs={12} style={{ padding: "0" }}>
                                            <p>SCAN DESCRIPTION</p>
                                        </Grid>
                                        <Grid item xs={12} style={{ padding: "0" }}>

                                            <TextField className="input"
                                                name="remarks"
                                                type="text"
                                                onWheel={(e: any) => e.target.blur()}
                                                placeholder="Please Enter "
                                                value={remarks}
                                                variant="outlined"
                                                style={{ margin: "0", width: "100%" }}
                                                onChange={(e) => setRemarks(e.target.value)}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} style={{ padding: "0" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={{ height: "56px" }}
                                            fullWidth
                                            type="submit"
                                            disabled={packageId===""}
                                        >
                                            CREATE TEST
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>

                        </div>

                    </Fade>
                )}

            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
});

export default connect(mapStateToProps, {
})(CreatePhlebo);
