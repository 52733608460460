import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { TableCell, Chip } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { getBookingCalls } from '../../actions/salesLeadAction'
import { useHistory } from 'react-router';
import GamesIcon from '@material-ui/icons/Games';
// import { updateUnregisterdBooking } from "../../actions/CustomerSupport";

let width = window.innerWidth;
let isMobile = width <= 500;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

type ModalProps = {
  bookingCall: any;
  getBookingCalls: any;
  openModal: any;
  setopenModal: any;
  bookingId: any;
};

const BookingCallCount: React.FC<ModalProps> = ({
  bookingCall,
  getBookingCalls,
  openModal,
  setopenModal,
  bookingId,
}) => {
  const classes = useStyles();
  const history = useHistory()

  const handleClose = () => {
    setopenModal(false);
  };

  useEffect(()=>{
    if(bookingId !== 0 && openModal){
      getBookingCalls(bookingId)
    }  
  },[openModal])

  return (
    <div>
      <Dialog open={openModal} onClose={handleClose}>
        {bookingCall && bookingCall.results && bookingCall.results.length > 0 ? (
            bookingCall.results.map((data: any) => {
              return (
                <div>
                  <DialogTitle>Customer Name : {data.booking.customer_name}</DialogTitle>
                  <DialogTitle> First Call: {new Date(data.created_at).toLocaleString()}</DialogTitle>
                  <DialogTitle> Last Call: {new Date(data.updated_at).toLocaleString()}</DialogTitle>
                  <DialogTitle> Customer Support Call : {data.cs_call_count}</DialogTitle>
                  <DialogTitle> Phlebo User Call: {data.ph_call_count}</DialogTitle>
                  <DialogTitle> Phlebo Admin Call: {data.pha_call_count}</DialogTitle>
                  <DialogTitle> Sample Accessioning Call: {data.sa_call_count}</DialogTitle>
                  <DialogTitle> Support Executive Call: {data.se_call_count}</DialogTitle>
                  <DialogTitle> Support Executive To Phlebo Call: {data.se_call_to_phlebo_count}</DialogTitle>
                </div>
              )
            })
          ) : <DialogTitle> No Booking Call Found</DialogTitle>}
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  bookingCall: state.SalesLeadReducer.bookingCall,
});

export default connect(mapStateToProps, {
  getBookingCalls,
})(BookingCallCount);
