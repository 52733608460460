import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { Grid, TextField,Select,MenuItem } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import MessageIcon from "@material-ui/icons/Message";
import CallIcon from "@material-ui/icons/Call";

import {
  getLeadSource,
  getAgentList,
  getCities,
  getAllLeads,
  leadTransferByComma,
  leadTransferTataTale,
  getPanelUserList,
  getPrescriptionData,
  getLeadStatus,
  getQueryTickets,
  getPanelUserListSalesLead,
  getPanelUserListTeamLead,
  clickToCall

} from "../actions/HealthAdviserAdminAction";
import { TableFooter, TablePagination } from "@material-ui/core";
import Loader from "../../CustomerLead-Panel/components/loader";
import CommentsModal from "../components/Comments/comment";
import CommentsModal2 from "../components/Comments/Comment2";
import RaiseComplaintModal from "../components/Comments/raiseTicketModal";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button } from "@material-ui/core";
import { generateLeadsUrl } from "../../helpers/generateUrl";
import { useRef } from "react";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import Pagination from "@material-ui/lab/Pagination";
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import Menu from '@material-ui/core/Menu';
import Fade from '@material-ui/core/Fade';
import Modal from '@mui/material/Modal';
import Backdrop from '@material-ui/core/Backdrop';


const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  tableContainer: {
    marginTop: "2rem",
    position: "sticky",
    top: "200px",
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
const StyledTableCell:any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
    
  })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  getAllLeads: Function;
  all_lead: any;
  lead_source: any;
  loading: boolean;
  // resendSms: Function;
  clickToCall: Function;
  getLeadSource: any;
  user_panel: any;
  leadTransferByComma: any;
  leadTransferTataTale: any;
  getAgentList: any;
  agentsList: any;
  agentBYUserGroup: any;
  getCities: any;
  cities: any;
  getPanelUserList:any;
  panelUserList:any;
  getLeadStatus:any;
  leadStatus:any;
  getQueryTickets:any;
  complaints:any;
  getPrescriptionData: any;
  prescription:any;
  getPanelUserListSalesLead:any;
  salesLeadList:any;
  getPanelUserListTeamLead:any;
  teamLeadList:any;
}

const LeadsTable: React.FC<Props> = ({
  getAllLeads,
  all_lead,
  clickToCall,
  getLeadSource,
  lead_source,
  user_panel,
  leadTransferByComma,
  leadTransferTataTale,
  getAgentList,
  agentsList,
  agentBYUserGroup,
  getCities,
  cities,
  loading,
  getPanelUserList,
  getQueryTickets,
  complaints,
  panelUserList,
  getLeadStatus,
  leadStatus,
  getPrescriptionData,
  prescription,
  salesLeadList,
  getPanelUserListSalesLead,
  getPanelUserListTeamLead,
  teamLeadList,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [open2, setOpen2] = useState<boolean>(false);
  const [raiseComplaint, setRaiseComplaint] = useState(false);

  const [leadId, setLeadId] = useState<number>(0);
  const [partner, setPartner] = useState<any>([]);
  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50 || all_lead.results.count);
  const [category, setCategory] = useState<any>([]);
  const [id, setId] = useState<string>("");
  const [start_date, setStart_date] = useState<string>("");
  const [end_date, setEnd_date] = useState<string>("");
  const [followUpDate, setFollowUpDate] = useState<string>("");
  const [leadSource, setleadSource] = useState<any>([]);
  const [priority, setPriority] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [agent, setAgent] = useState<any>([]);
  const [selectedLeads, setSelectedLeads] = useState<any>([]);
  const [panel_user, setPanelUser] = React.useState<number | null>(null);
  const [selectbutton, setSelectButton] = useState<any>("lead");
  const [phone, setPhone] = useState("");
  const timer = useRef<any>(0);
  const history = useHistory();
  const [pageNum, setPageNum] = useState(1);
  const [leadUrl, setLeadUrl] = useState<any>("");
  const [teamLead, setTeamLead] = useState<any>([]);
  const [salesLead, setSalesLead] = useState<any>([]);
  const [callFrom,setCallFrom]=useState<any>("call")
  const [prscriptionLead, setPrescriptionLead] = useState<any>("")
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openPrescription = Boolean(anchorEl);
  const [pLink, setPlink] = useState<any>("")
  const [openPdf, setOpenPdf] = useState<boolean>(false)
  const [fileType, setFileType] = useState<any>("")
  const [inboundModeFilter, setInbountModeFilter] = useState("none")
  const CATEGORY: any = [
    {
      id: 1,
      text: "Interested/Follow up",
      city: true,
      leadId: false,
    },
    {
      id: 2,
      text: "Not Interested",
      city: true,
      query: false,
    },
    {
      id: 3,
      text: "Order Booked",
      city: true,
      query: false,
    },
    {
      id: 4,
      text: "Ringing",
      city: false,
      query: false,
    },
    {
      id: 5,
      text: "Number Doesn't Exist",
      city: false,
      query: false,
    },
    {
      id: 6,
      text: "By Mistake & Wrong Number",
      city: false,
      query: false,
    },
    {
      id: 7,
      text: "Call Back",
      city: true,
      query: false,
    },
    {
      id: 8,
      text: "Business Realated Call",
      city: true,
      query: false,
    },
    {
      id: 9,
      text: "Spam Call",
      city: false,
      query: false,
    },
    {
      id: 10,
      text: "Enquiry Call",
      city: true,
      query: false,
    },
    {
      id: 11,
      text: "CFresh",
      city: false,
      query: false,
    },
    {
      id: 12,
      text: "Test Not Available",
      city: true,
      query: false,
    },
    {
      id: 13,
      text: "Subscribed to Competitor",
      city: true,
      query: false,
    },
    {
      id: 14,
      text: "Slot Issue",
      city: true,
      query: false,
    },
    {
      id: 15,
      text: "Same Day Slot",
      city: true,
      query: false,
    },
    {
      id: 16,
      text: "Already Booked",
      city: false,
      query: false,
    },
    {
      id: 17,
      text: "Not Contactable",
      city: false,
      query: false,
    },
    {
      id: 18,
      text: "Out of Station",
      city: true,
      query: false,
    },
    {
      id: 19,
      text: "Out of Service Area",
      city: true,
      query: false,
    },
    {
      id: 20,
      text: "Need Time to Think",
      city: true,
      query: false,
    },
    {
      id: 21,
      text: "Email/Whatsapp Package",
      city: true,
      leadId: false,
    },
    {
      id: 22,
      text: "Discount/Price Enquiry",
      city: true,
      leadId: false,
    },
    {
      id: 23,
      text: "CS issue",
      city: false,
      leadId: true,
    },
    {
      id: 24,
      text: "Call Not Reachable",
      city: false,
      leadId: false,
    },
    {
      id: 25,
      text: "Call Later",
      city: true,
      leadId: false,
    },
    {
      id: 26,
      text: "Call drop",
      city: true,
      leadId: false,
    },
    {
      id: 27,
      text: "Call Disconnected",
      city: true,
      leadId: false,
    },
    {
      id: 28,
      text: "Below <5 year patient",
      city: true,
      leadId: false,
    },
    {
      id: 29,
      text: "DND",
      city: false,
      leadId: false,
    },
    {
      id: 30,
      text: "Phlebo's Issue",
      city: false,
      leadId: true,
    },
    {
      id: 31,
      text: "Reports Regarding Issue",
      city: false,
      leadId: true,
    },
    {
      id: 32,
      text: "Health Advice",
      city: false,
      leadId: true,
    },
    {
      id: 33,
      text: "Health Consultation",
      city: false,
      leadId: true,
    },
    {
      id: 34,
      text: "Health Consultation",
      city: false,
      leadId: false,
    },
    {
      id: 35,
      text: "Language Barrier",
      city: false,
      leadId: false,
    },
    {
      id: 36,
      text: "RCFresh",
      city: false,
      leadId: false,
    },
    {
      id: 37,
      text: "HCbooking",
      city: false,
      leadId: false,
    },
  ];

  const priorityArr: any = [
    {
      value: "SuperCritical",
    },
    {
      value: "Urgent",
    },
    {
      value: "HopeFull",
    },
    {
      value: "Low",
    },
  ];

  useEffect(() => {
    getLeadSource();
    getCities();
    getAgentList();
    getPanelUserList()
    getAgentList("", "CustomerExecutive");
    getLeadStatus()
    getPanelUserListSalesLead(`?usergroup=SalesLead`)
    getPanelUserListTeamLead(`?usergroup=CustomerLead`)
  }, []);
  useEffect(() => {
    getAllLeads(leadUrl);
  }, [leadUrl]);

  const filterLeads = () => {
    const body: any = {
      lead_call_status_id: category.join(","),
      leadSource: leadSource.join(","),
      priority: priority.join(","),
      followUpDate,
      city: city.join(","),
      inbound_mode: inboundModeFilter,
      start_date,
      phone,
      end_date,
      agent: agent.join(","),
      id,
      lead_form_call:callFrom,
      tl: teamLead ? teamLead.join(",") : "",
      sl:salesLead?salesLead:"",
    };
    const url = generateLeadsUrl(body).substring(2);
    getAllLeads(`${leadUrl === "" ? `?${url}` : `${leadUrl}&${url}`}`);
    setPage(0);
  };

  useEffect(() => {
    if (open2 === false) {
      setLeadId(0);
    }
  }, [open2]);

  const handleClick = (id: number) => {
    setOpen(true);
    setLeadId(id);
  };

  const handleModal = (id: number) => {
    setOpen2(true);
    setLeadId(id);
  };

  const handleRaiseTicket = (id: number) => {
    setRaiseComplaint(true);
    setLeadId(id);
  };

  const handleCallClick = (id: number, type: string) => {
    setLeadId(id);
    clickToCall(id, type);
  };
  // const handleResendClick = (id: number) => {
  //   let body = {
  //     appt_id: id,
  //     template_id: "1010101"
  //   };
  //   resendSms(body);
  // };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = all_lead.next;

      getAllLeads(url.substring(url.indexOf("?")));
    } else if (newPage < page) {
      let url = all_lead.previous;

      getAllLeads(
        url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?"))
      );
    }
    setPage(newPage);
  };

  const transferLead = async () => {
    let body = {
      lead_ids: selectedLeads.join(", "),
      panel_user,
    };
    await leadTransferByComma(body);

    setSelectedLeads([]);

    getAllLeads(`?page_size=${rowsPerPage}`);
  };
  const transferLeadTataTale = async () => {
    let body = {
      leadids: selectedLeads.join(", "),
    };
    await leadTransferTataTale(body);

    setSelectedLeads([]);

    getAllLeads(`?page_size=${rowsPerPage}`);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    getAllLeads(`?page_size=${event.target.value}`);
    setPage(0);
  };
  const handlePage = (event: any, value: any) => {
    if (value > page) {
      let url = all_lead.next;
      getAllLeads(url.substring(url.indexOf("?")));
    } else if (value < page) {
      let url = all_lead.previous;

      getAllLeads(
        url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?"))
      );
    }
    setPage(value);
  };

  const checkedLead = (event: any, lead: number) => {
    
    if (selectedLeads?.length < 11) {
      !event.target.checked
        ? setSelectedLeads(selectedLeads.filter((lead: any) => lead !== lead))
        : setSelectedLeads((prev: any) => [...prev, lead]);
    } else {
      return false;
    }
  };
  const handleLeadButton = (type: any, urlType: any) => {
    setSelectButton(type);
    setLeadUrl(urlType);
  };
  useEffect(() => {
    if (prscriptionLead !== "") {
      getPrescriptionData(`?lead=${prscriptionLead}`)
    }
  }, [prscriptionLead])
  const handlePrescriptionClick = (id: any, event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setPrescriptionLead(id)
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFrameView = (data: any) => {
    if (data.base64file !== null && data.image === "") {
      setFileType("b64")
      setPlink(`data:application/pdf;base64,${data.base64file}`)
    }
    else if (data.base64file === null && data.image !== "") {
      let pieces: any = data.image.split('.').pop()
      const last: any = pieces
      setFileType(last)
      setPlink(data.image)
    }
    setOpenPdf(true)
  }
  const handlePdfModelClose = () => {
    setOpenPdf(false)
  }

  return (
    <>
      <Grid container spacing={2} direction="row">
        <Grid
          container
          spacing={1}
          direction="row"
          style={{marginTop: "10px", marginBottom: "10px"}}
        >
          <Grid item xs={12} sm={6} md={8} >
          <Grid
          container
          spacing={1}
          direction="row"
          style={{marginTop: "10px", marginBottom: "10px"}}
        >
          <Grid item xs={12} sm={6} md={4} >
          <h3 style={{fontWeight:"bold"}}>Leads Tables</h3>
          </Grid>
            <Grid item xs={12} sm={6} md={8} >
              <h3 style={{fontWeight:"bold"}}>Total Lead Count : {all_lead.count}</h3>
            </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <Button
              fullWidth
              onClick={() => handleLeadButton("lead", "")}
              variant="contained"
              color={selectbutton == "lead" ? "primary" : "default"}
            >
              Leads
            </Button>
          </Grid>
          <Grid item xs={12} sm={3} md={2}>
            <Button
              fullWidth
              onClick={() =>
                handleLeadButton("unattempted", "?unattempted=true")
              }
              variant="contained"
              color={selectbutton == "unattempted" ? "primary" : "default"}
            >
              Unattempted
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            name="leadId"
            type="number"
            value={id}
            className="input"
            variant="outlined"
            label="Lead Id"
            onChange={(e) => setId(e.target.value as string)}
            style={{ width: "100%" }}
            required
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="phone"
            type="text"
            label="Phone"
            value={phone}
            variant="outlined"
            onChange={(e) => setPhone(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="city"
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                sourceIds = obj.map((item: any) => item.id);
              }
              setCity(sourceIds);
            }}
            options={cities || []}
            freeSolo
            blurOnSelect
            aria-required
            limitTags={1}
            multiple
            getOptionLabel={(option: any) => option.name}
            getOptionDisabled={(option: any) => {
              return city.includes(option.id);
            }}
            disableClearable
            disableCloseOnSelect
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getCities(newInputValue);
              }, 1000);
              if (newInputValue.length === 0) {
                setCity([]);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="City"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            id="agent"
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                sourceIds = obj.map((item: any) => item.id);
              }
              setAgent(sourceIds);
            }}
            freeSolo
            blurOnSelect
            aria-required
            limitTags={1}
            multiple
            options={agentsList?.results || []}
            getOptionDisabled={(option: any) => {
              return agent.includes(option.id);
            }}
            disableClearable
            disableCloseOnSelect
            getOptionLabel={(option: any) =>
              option.user && option.user.username
            }
            onInputChange={(event, newInputValue) => {
              clearTimeout(timer.current);
              timer.current = setTimeout(() => {
                getAgentList(`?code=${newInputValue.replace(" ", "")}`);
              }, 1000);
              if (newInputValue.length === 0) {
                setPartner([]);
              }
            }}
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Agent"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
              />
            )}
          />
        </Grid>
        
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="start_date"
            type="date"
            label="Start Date"
            value={start_date}
            variant="outlined"
            onChange={(e) => setStart_date(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            name="end_date"
            type="date"
            value={end_date}
            className="input"
            label="End Date"
            variant="outlined"
            onChange={(e) => setEnd_date(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <TextField
            className="input"
            name="Created At"
            type="date"
            label="Follow-Up Date"
            value={followUpDate}
            variant="outlined"
            onChange={(e) => setFollowUpDate(e.target.value as string)}
            style={{ width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                sourceIds = obj.map((item: any) => item.id);
              }
              setCategory(sourceIds);
            }}
            multiple
            id="checkboxes-tags-demo"
            options={leadStatus||[]}
            disableCloseOnSelect
            limitTags={1}
            getOptionLabel={(option: any) => option?.name}
            getOptionDisabled={(option: any) => {
              return category.includes(option.id);
            }}
            disableClearable
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Status"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                sourceIds = obj.map((item: any) => item.id);
              }
              setleadSource(sourceIds);
            }}
            multiple
            id="checkboxes-tags-demo"
            options={lead_source || []}
            disableCloseOnSelect
            limitTags={1}
            getOptionLabel={(option: any) => option.name}
            getOptionDisabled={(option: any) => {
              return leadSource.includes(option.id);
            }}
            disableClearable
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Lead Source"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Autocomplete
            onChange={(event, newValue) => {
              let sourceIds: any = [];
              if (newValue) {
                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                sourceIds = obj.map((item: any) => item.value);
              }
              setPriority(sourceIds);
            }}
            multiple
            id="checkboxes-tags-demo"
            options={priorityArr}
            disableCloseOnSelect
            limitTags={1}
            getOptionLabel={(option: any) => option.value}
            getOptionDisabled={(option: any) => {
              return priority.includes(option.value);
            }}
            disableClearable
            renderInput={(params) => (
              <TextField
                className="input"
                {...params}
                label="Priority"
                variant="outlined"
                style={{ width: "100%", margin: "0", padding: "0" }}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="Team Lead"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setTeamLead(obj.id);
                }
                
              }}
              freeSolo
              blurOnSelect
              limitTags={1}
              multiple
              options={teamLeadList?.results || []}
              getOptionDisabled={(option: any) => {
                return teamLead.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) => option.user && option.user.name}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPanelUserListTeamLead(`${newInputValue}&usergroup=CustomerLead`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setTeamLead("");
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Team Lead"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="SalesLead"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setSalesLead(sourceIds);
              }}
              freeSolo
              blurOnSelect
              limitTags={1}
              multiple
              options={salesLeadList?.results || []}
              getOptionDisabled={(option: any) => {
                return salesLead.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) => option.user && option.user.name}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPanelUserListSalesLead(`?code=${newInputValue}&usergroup=SalseLead`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setSalesLead([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Sales Lead"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <Select
            className="input"
            name="Call Form"
            // placeholder="Call Form"
            variant="outlined"
            value={callFrom || "pending"}
            style={{ width: "100%", margin: "0", padding: "0",height: "40px" }}
            onChange={(e) => setCallFrom(e.target.value as string)}
            required
          >
             <MenuItem value={"Call/From"}>Call From</MenuItem> 
            <MenuItem  value={"call"}>Call</MenuItem>
            <MenuItem value={"form"}>Form</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={4} md={2} >
          <Select className="input"
            name="inbound_mode"
            variant="outlined"
            value={inboundModeFilter}
            style={{ width: "100%",height: "40px" }}
            onChange={(e) => setInbountModeFilter(e.target.value as string)}
          >
            <MenuItem value={"none"}>Inbound Mode</MenuItem>
            <MenuItem value={"true"}>Inbound</MenuItem>
            <MenuItem value={"false"}>Outbound</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Button
            variant="contained"
            color="secondary"
           
            fullWidth
            onClick={filterLeads}
          >
            Filter
          </Button>
        </Grid>
        <Grid item xs={6} sm={4} md={2}>
          <Button
            variant="contained"
            color="primary"
           
            fullWidth
            onClick={() => history.push("/dashboard/haa/leads")}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      {selectedLeads.length > 0 && (
        <React.Fragment>
          <h2 style={{ marginTop: "1rem" }}>Transfer To:</h2>
          <Grid container spacing={2} direction="row">
            <Grid item xs={12} sm={3}>
              <Autocomplete
                id="agentname"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setPanelUser(obj.id);
                  }
                }}
                options={agentBYUserGroup?.results || []}
                freeSolo
                blurOnSelect
                aria-required
                getOptionLabel={(option: any) =>
                  option.user && option.user.username
                }
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getAgentList(
                      `?code=${newInputValue.replace(" ", "")}`,
                      "CustomerExecutive"
                    );
                  }, 1000);
                  if (newInputValue.length === 0) {
                    setPanelUser(0);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Agent username"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                variant="contained"
                color="default"
               
                fullWidth
                onClick={transferLead}
                disabled={
                  panel_user === null ||
                  panel_user === 0 
                 
                }
              >
                Transfer
              </Button>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Button
                variant="contained"
                color="default"
               
                fullWidth
                onClick={transferLeadTataTale}
                
              >
                 TataTale
              </Button>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
      <div className="data-table" style={{ width: "100%", marginTop: "2rem" }}>
      <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "560px" }}>
          {loading ? (
            <Loader />
          ) : (
            <Table className={classes.table} stickyHeader aria-label="customized table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>
                    <input
                      type="checkbox"
                      className="input"
                      name="selectall"
                      id="selectall"
                      onChange={(e) =>
                        e.target.checked
                          ? setSelectedLeads(
                              all_lead.results &&
                                all_lead.results.map(
                                  (lead: any, index: number) => {
                                    if (index < 100) {
                                      return lead.id && lead.id;
                                    }
                                  }
                                )
                            )
                          : setSelectedLeads([])
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell>Lead ID</StyledTableCell>
                  <StyledTableCell>Booking ID</StyledTableCell>
                  {/* <StyledTableCell align="center">Count</StyledTableCell> */}
                  <StyledTableCell align="center">Created At</StyledTableCell>
                  <StyledTableCell align="center">Followup Date</StyledTableCell>
                  <StyledTableCell align="center">Comments</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                  <StyledTableCell align="center">
                    Lead Source URL
                  </StyledTableCell>
                  <StyledTableCell align="center">Agent</StyledTableCell>
                  <StyledTableCell align="center">Call</StyledTableCell>
                  <StyledTableCell align="center">
                    Contact number
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Whatsapp contact
                  </StyledTableCell>
                  <StyledTableCell align="center">Patient name</StyledTableCell>
                  <StyledTableCell align="center">
                    Calling gender
                  </StyledTableCell>
                  <StyledTableCell align="center">Patient age</StyledTableCell>
                  <StyledTableCell align="center">City</StyledTableCell>
                  <StyledTableCell align="center">City Name</StyledTableCell>
                  <StyledTableCell align="center">Patient Area</StyledTableCell>
                  <StyledTableCell align="center">Lead source</StyledTableCell>
                  <StyledTableCell align="center">Call status</StyledTableCell>
                  <StyledTableCell align="center">Language</StyledTableCell>
                  <StyledTableCell align="center">Edit</StyledTableCell>
                  <StyledTableCell align="center">Raise Ticket</StyledTableCell>
                  <StyledTableCell align="center">Prescription</StyledTableCell>
                  <StyledTableCell align="center">Priority</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {all_lead.results && all_lead.results.length > 0 ? (
                  all_lead.results.map((row: any, index: number) => (
                    <StyledTableRow
                      key={row.id}
                     style={{
                        background: row?.lead_source === "imaging" || row?.lead_source === "imaging_lp"|| row?.lead_source === "imaging_call"|| row?.lead_source === "imaging_homedx"|| row?.lead_source === "ZOKO_IMAGING"||row?.lead_source === "imaging_alliance"||row?.lead_source === "imaging_dsa"||row?.lead_source === "imaging_web"||row?.lead_source === "justdial_imaging" ? "#ffeb3b" : "",
                      }}
                    >
                      <StyledTableCell component="th" scope="row">
                        <input
                          type="checkbox"
                          name="selectall"
                          id="selectall"
                          checked={selectedLeads.find(
                            (lead: any) => lead === row.id
                          )}
                          onChange={(e) => checkedLead(e, row.id)}
                        />
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row.id}
                      </StyledTableCell>
                      <StyledTableCell component="th" scope="row">
                        {row?.booking_id}
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                        {row.count}
                      </StyledTableCell> */}
                      <StyledTableCell align="center">
                        {new Date(row.created_at).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.followup_date} {" "} {row.followup_time}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div>
                          <MessageIcon onClick={() => handleClick(row.id)} />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div>
                          <MessageIcon onClick={() => handleModal(row.id)} />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.lead_source_url ? row.lead_source_url : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.panel_user ? row.panel_user : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <CallIcon
                          onClick={() => handleCallClick(row.id, "call")}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.content_number ? (
                          <CallIcon
                            onClick={() => handleCallClick(row.id, "contact")}
                          />
                        ) : null}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.whatsapp_contact !== row.content_number ? (
                          <CallIcon
                            onClick={() => handleCallClick(row.id, "whatsapp")}
                          />
                        ) : (
                          "NA"
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.patient_name ? row.patient_name : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.calling_gender ? row.calling_gender : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.patient_age}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.patient_city}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.city_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.patient_area ? row.patient_area : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.lead_source}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.call_status}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.language ? row.language : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Link to={`/dashboard/haa/fill-info?id=${row.id}`}>
                          <EditIcon />
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          variant={!raiseComplaint ? "contained" : "outlined"}
                          color="secondary"
                          onClick={() => handleRaiseTicket(row.id)}
                        >
                          <ConfirmationNumberIcon />
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.is_prescription ? (
                          <Button aria-controls="fade-menu" aria-haspopup="true" onClick={(event: any) => handlePrescriptionClick(row.id, event)}>
                            <NoteAddIcon />
                          </Button>) : "NA"}
                        <Menu
                          id="fade-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={openPrescription}
                          onClose={handleClose}
                          TransitionComponent={Fade}
                        >
                          {prescription && prescription?.results && prescription?.results?.length > 0 && prescription?.results.map((data: any,index:any) => {
                             let pieces: any = data.image.split('.').pop()
                             const last: any = pieces
                            return (
                              <>
                              {data?.base64file !== null && data.image === "" ?(
                                <MenuItem onClick={() => handleFrameView(data)}> Name :{data?.name}, Age:{data?.age}, Created Date :{new Date(data.created_at).toLocaleString()}</MenuItem>
                              ): data?.base64file === null && data.image !== "" ?(
                                last === "png" || last === "jpg" || last === "jpeg" ?(
                                  <MenuItem >
                                    <a href={data?.image} rel="noreferrer noopener" target="_blank">
                                    Name :{data?.name}, Age:{data?.age}, Created Date :{new Date(data.created_at).toLocaleString()}
                                    </a>
                                    </MenuItem>
                                ):(
                                  <MenuItem >
                                    <a href={data?.image} rel="noreferrer noopener" target="_blank">
                                    Name :{data?.name}, Age:{data?.age}, Created Date :{new Date(data.created_at).toLocaleString()}
                                    </a>
                                    </MenuItem>
                                )
                                
                              ) :""}
                              </>
                            )

                          })}
                        </Menu>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.priority}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow key={1}>
                    <StyledTableCell colSpan={18}>
                      <strong>No Data Found</strong>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          )}
          <Grid container xs={12}>
            <Grid container item md={6}>
              <TablePagination
                colSpan={6}
                count={all_lead.count || 0}
                rowsPerPageOptions={[10, 20, 30, 40, 50]}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                SelectProps={{
                  native: true,
                }}
                onPageChange={handleChangePage}
              />
            </Grid>
          </Grid>
        </TableContainer>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openPdf}
          onClose={handlePdfModelClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openPdf}>
            <>
              {fileType === "pdf" ? (
                <iframe
                  style={{ width: "80%", height: "750px" }}
                  src={pLink} title="title">
                  Presss me: <a href={pLink}>Download PDF</a>
                </iframe>
              ) : fileType === "b64" ? (
                <iframe
                  src={pLink}
                  style={{ width: "80%", height: "750px" }}
                  title="Iframe Example"
                ></iframe>
              ) : ""}
            </>

          </Fade>
        </Modal>
      </div>
      <CommentsModal open={open} setOpen={setOpen} leadId={leadId} />
      <CommentsModal2
        open={open2}
        setOpen={setOpen2}
        leadId={leadId}
        getCities={getCities}
        cities={cities}
      />
     <RaiseComplaintModal
        open={raiseComplaint}
        setOpen={setRaiseComplaint}
        complaints={ complaints}
        leadId={Number(leadId)}
        bookingId={0}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  all_lead: state.HealthAdviserAdminReducer.all_lead,
  lead_source: state.HealthAdviserAdminReducer.lead_source,
  user_panel: state.HealthAdviserAdminReducer.user_panel,
  agentsList: state.HealthAdviserAdminReducer.agentsList,
  agentBYUserGroup: state.HealthAdviserAdminReducer.agentBYUserGroup,
  cities: state.HealthAdviserAdminReducer.cities,
  loading: state.HealthAdviserAdminReducer.loading,
  panelUserList: state.HealthAdviserAdminReducer.panelUserList,
  leadStatus: state.HealthAdviserAdminReducer.leadStatus,
  complaints: state.HealthAdviserAdminReducer.complaints,
  prescription: state.HealthAdviserAdminReducer.prescription,
});

export default connect(mapStateToProps, {
  getAllLeads,
  clickToCall,
  getLeadSource,
  getCities,
  leadTransferByComma,
  leadTransferTataTale,
  getAgentList,
  getPanelUserList,
  getLeadStatus,
  getPrescriptionData,
  getQueryTickets
})(LeadsTable);
