import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './index.sass';
import * as serviceWorker from './serviceWorker';
import CreateStore from './store';
import Routes from './config/Routes';

const store = CreateStore();
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function (registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function (err) {
      console.log("Service worker registration failed, error:", err);
    });
}

// @ts-ignore
ReactDOM.render(<Provider store={store}><Routes /></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
