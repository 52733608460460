import React, { useEffect, useState } from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import Loader from "../components/loader/index";
import TablePagination from "@material-ui/core/TablePagination";
import TableFooter from "@material-ui/core/TableFooter";
import ReactAudioPlayer from "react-audio-player";
import { Link } from "react-router-dom";
import { Edit, Message } from "@material-ui/icons";
import CommentsModal from '../components/Comments/comment'
import CommentsModal2 from '../components/Comments/comments3'
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },

  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  tableContainer: {
    marginTop: "2rem",
    position: "sticky",
    top: "200px",
  },
});

interface Props {
  getAllRecordings: any;
  all_recordings: any;
  loading: boolean;
  setTotalCount: any;
}

const ClRecordingTable: React.FC<Props> = ({
  getAllRecordings,
  loading,
  all_recordings,
  setTotalCount,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(
    20 || all_recordings.results.count
  );

  const [open, setOpen] = useState(false)
  const [open2, setOpen2] = useState(false)
  const [open3, setOpen3] = useState(false)

  const [score, setScore] = useState("")
  const [remark, setRemark] = useState("")
  const [leadId, setLeadId] = useState(0)

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = all_recordings.next;
      getAllRecordings(url.substring(url.indexOf("?")));
    } else if (newPage < page) {
      let url = all_recordings.previous;

      getAllRecordings(
        url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?"))
      );
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  useEffect(() => {
    getAllRecordings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    open2 === false && getAllRecordings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open2]);

  const handleClick = (id: number) => {
    setOpen(true);
    setLeadId(id);
  };

  const handleModal = (id: number) => {
    setOpen2(true);
    setLeadId(id);
  };

  const viewScore = (score: any, remark: any) => {
    setScore(score)
    setRemark(remark)
    setOpen3(true)
  };
  useEffect(() => {
    setTotalCount(all_recordings.count)
  }, [all_recordings])

  return (
    <>
      <div className="data-table" style={{ width: "100%" }}>
        <Dialog
          open={open3}
          onClose={() => setOpen3(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ color: "purple", background: "pink" }} id="alert-dialog-title">View Score</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <h6 style={{ color: "purple", margin: "1rem 0" }}>Score: {score || "Not Given"}</h6>
              <h6 style={{ color: "purple", margin: "1rem 0" }}>Remark: {remark || "Not Given"}</h6>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "560px" }}>
          {loading ? <Loader /> : (

            <Table className={classes.table} stickyHeader aria-label="customized table">
              <TableHead>
                <StyledTableRow style={{ height: "50px", padding: "5px" }}>
                  <StyledTableCell>Lead ID</StyledTableCell>
                  <StyledTableCell align="center">
                    Patient Support
                  </StyledTableCell>
                  <StyledTableCell align="center">Comments</StyledTableCell>
                  <StyledTableCell align="center">Evaluate recording</StyledTableCell>
                  <StyledTableCell align="center">View Score</StyledTableCell>
                  <StyledTableCell align="center">Provider</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">Call Duration</StyledTableCell>
                  <StyledTableCell align="center">Lead Status</StyledTableCell>
                  <StyledTableCell align="center">Direction</StyledTableCell>
                  <StyledTableCell align="center">Hangup Cause</StyledTableCell>
                  <StyledTableCell align="center">Disposition</StyledTableCell>
                  <StyledTableCell align="center">
                    Recording Date/Time
                  </StyledTableCell>
                  <StyledTableCell align="center">Edit</StyledTableCell>
                  <StyledTableCell align="center">Play</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {all_recordings.count > 0 ? (
                  all_recordings.results.map((row: any) => (
                    <StyledTableRow
                      key={row.id}
                    >
                      <StyledTableCell component="th" scope="row">
                        {row.lead ? row.lead : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.panel_user ? row.panel_user : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div>
                          <Message onClick={() => handleClick(row.lead)} />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div>
                          <Message onClick={() => handleModal(row.id)} />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Button
                          variant="text"
                          onClick={() => viewScore(row.score, row.remark)}
                        >
                          View Score
                        </Button>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.provider ? row.provider : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.status ? row.status : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.callduration ? row.callduration : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.lead_call_status ? row.lead_call_status : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.direction ? row.direction : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.hangup_clause ? row.hangup_clause : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.disposition ? row.disposition : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.start_time ? new Date(row.start_time).toLocaleString() : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.lead ? (
                          <Link
                            to={`/dashboard/haa/fill-info?id=${row.lead}`}
                          >
                            <Edit />
                          </Link>
                        ) : "NA"}

                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.uploaded_url ? (
                          // <ReactAudioPlayer
                          //   src={row.uploaded_url}
                          //   // autoPlay
                          //   controls
                          // // onSeeked
                          // />
                          <audio
                            controls

                          >
                            <source src={row.uploaded_url} type="audio/wav" />
                            Your browser does not support the
                            <code>audio</code> element.
                          </audio>
                        ) : null}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow
                    key={2}
                  >
                    <StyledTableCell colSpan={15}>
                      <h4>No Data Found</h4>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
              <TableFooter>
                <TablePagination
                  colSpan={5}
                  rowsPerPageOptions={[]}
                  count={all_recordings.count || 0}
                  rowsPerPage={rowsPerPage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                // ActionsComponent={RecordingTablePaginationActions}
                />
              </TableFooter>
            </Table>
          )}
        </TableContainer>
      </div>
      <CommentsModal open={open} setOpen={setOpen} leadId={leadId} />
      <CommentsModal2 open={open2} setOpen={setOpen2} leadId={leadId} />
    </>
  );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {})(
  ClRecordingTable
);
