export const BOOKING_STATUS = [
        {
            text: "Pending",
            value: "pending",
          },
          {
            text: "Confirmed",
            value: "confirmed",
          },
          {
            text: "Cancelled",
            value: "cancelled",
          },
          {
            text: "Rescheduled",
            value: "rescheduled",
          },
          {
            text: "Resampling",
            value: "resampling",
          },
          {
            text: "Sample Not Received",
            value: "sample not received",
          },
          {
            text: "Sample Not Received Due To Payment Information",
            value: "sample not received due to payment information",
          },
          {
            text: "Sample Received",
            value: "sample received",
          },
          {
            text: "Partial Received",
            value: "partial received",
          },
          {
            text: "Order booked",
            value: "order booked",
          },
          {
            text: "Verified",
            value: "verified",
          },
          {
            text: "Phlebo Assigned",
            value: "phlebo assigned",
          },
          {
            text: "Phlebo Reached",
            value: "phlebo reached",
          },
          {
            text: "Phlebo Started",
            value: "phlebo started",
          },
          {
            text: "Sample Collected",
            value: "sample collected",
          },
          {
            text: "Sample Received in Lab",
            value: "sample received in lab",
          },
          {
            text: "Reports Sent",
            value: "reports sent",
          }
    ];