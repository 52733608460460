export const arr = [
  {
    role: "CustomerExecutive",
    url: "ce",
  },
  {
    role: "CustomerLead",
    url: "cl",
  },
  {
    role: "SalesLead",
    url: "sl",
  },
  {
    role: "SalesLeadAdmin",
    url: "sla",
  },
  {
    role: "CollectionCenter",
    url: "cc",
  },
  {
    role: "CollectionCenterAdmin",
    url: "cca",
  },
  {
    role: "ImagingAdmin",
    url: "ia",
  },
  {
    role: "HealthAdviser",
    url: "ha",
  },
  {
    role: "HealthAdviserAdmin",
    url: "haa",
  },
  
];
