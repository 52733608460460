import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import "./BookingPage.sass"
import NewBooking from './NewBooking';
import EditBooking from './EditBooking';
import { leadTransfer } from '../../actions/CollectionCenter';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,

        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        }
    })
);


interface Props {
    lead: any;
    getNewPackage: any;


}

const BookingPage: React.FC<Props> = ({
    getNewPackage,
    lead,
  }) => {

    const classes = useStyles();

    const [newBooking, setNewBooking] = useState<Boolean>(true)

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <div className={classes.buttonContainer}>
                    {/* <Button variant={newBooking ? "contained" : "outlined"} color="primary" onClick={() => setNewBooking(true)}>New Booking</Button> */}
                    <Button variant={!newBooking ? "contained" : "outlined"} color="primary" onClick={() => setNewBooking(false)}>Edit/Reschedule Booking</Button>
                </div>
                {newBooking ? <NewBooking lead={lead} getNewPackage={getNewPackage} /> : <EditBooking />}
            </main>
        </>
    )
}

export default BookingPage
