import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    withStyles,
    Theme,
    createStyles,
    makeStyles,
} from "@material-ui/core/styles";
import { Grid, TextField, Select, MenuItem } from '@material-ui/core'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { getWhatsappComments, getAgentList, searchCall } from "../actions/bookingActions";
import { TableFooter, TablePagination } from "@material-ui/core";
import Loader from "../../CustomerLead-Panel/components/loader";
import Button from '@mui/material/Button';
import { generateWhatsappMessageUrl } from "../../helpers/generateUrl";
import { ExitToAppRounded, MessageRounded } from "@material-ui/icons";
import ReplyModal from '../components/Comments/replyModal'
import Autocomplete from "@material-ui/lab/Autocomplete";

const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,fontWeight:600
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 700,
        },
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);

interface Props {
    loading: boolean;
    whatsappComments: any;
    getWhatsappComments: any;
    getAgentList: any;
    agentsList: any;
}

const LeadsTable: React.FC<Props> = ({
    whatsappComments,
    getWhatsappComments,
    getAgentList,
    agentsList,
    loading,
}) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [id, setId] = useState<string>("")
    const [createdAt, setCreatedAt] = useState<string>("")
    const [start_date, setStart_date] = useState("")
    const [end_date, setEnd_date] = useState("")
    const [agent, setAgent] = useState(0)
    const [type, setType] = useState("none")

    const timer = useRef<any>(0)

    const [open, setOpen] = useState<boolean>(false)
    const [leadId, setLeadId] = useState<number>(0)

    const history = useHistory()

    useEffect(() => {
        getAgentList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getWhatsappComments()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatch = useDispatch()

    const filterLeads = () => {
        const body: any = {
            start_date,
            lead: id,
            created_date: createdAt,
            panel_user: agent,
            end_date,
            type
        }
        const url = generateWhatsappMessageUrl(body).substring(2)
        
        getWhatsappComments(`${url}`)
    }

    const viewLead = (id: any) => {
        let body = {
            search_by: "call_id",
            value: `${id}`,
        };
        dispatch(searchCall(body));
        history.push("/dashboard/cl/lead/search");
    }

    const handlereply = (id: any) => {
        setOpen(true)
        setLeadId(id)
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = whatsappComments.links.next;
            
            getWhatsappComments(url.substring(url.indexOf("?") + 1));
        } else if (newPage < page) {
            let url = whatsappComments.links.previous;
            getWhatsappComments(
                url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?") + 1)
            );
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <h2>Whatsapp Messages</h2>
                <Grid container spacing={1} direction="row" >
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="leadID"
                            type="number"
                            value={id}
                            className="input"
                            variant="outlined"
                            placeholder="Lead Id"
                            onChange={(e) => setId(e.target.value as string)}
                            style={{ width: "100%" }}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="Created At"
                            type="date"
                            label="Created Date"
                            value={createdAt}
                            variant="outlined"
                            onChange={(e) => setCreatedAt(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="Start At"
                            type="date"
                            label="Start Date"
                            value={start_date}
                            variant="outlined"
                            onChange={(e) => setStart_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="End At"
                            type="date"
                            label="End Date"
                            value={end_date}
                            variant="outlined"
                            onChange={(e) => setEnd_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="agentname"
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    setAgent(obj.id)
                                }
                            }}
                            options={agentsList.results||[]}
                            freeSolo
                            blurOnSelect
                            aria-required
                            getOptionLabel={(option: any) => option.user && option.user.name}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                            getAgentList(`?code=${newInputValue.replace(" ","")}`)

                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setAgent(0)
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Agent Name"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Select className="input"
                            name="status"
                            variant="outlined"
                            value={type}
                            style={{ width: "100%", height: "39px" }}
                            onChange={(e) => setType(e.target.value as string)}
                        >
                            <MenuItem value={"none"} disabled>Type</MenuItem>
                            <MenuItem value={"1"}>Incoming</MenuItem>
                            <MenuItem value={"2"}>Outgoing</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            // style={{ height: "56px" }}
                            fullWidth
                            onClick={filterLeads}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            // style={{ height: "56px" }}
                            fullWidth
                            onClick={() => history.push("/dashboard/cl/whatsapp")}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <div className="data-table" style={{ width: "100%", marginTop: "2rem" }}>
                <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "540px" }}>
                        {loading ? <Loader /> : (
                            <Table stickyHeader aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align="center">Lead ID</StyledTableCell>
                                        <StyledTableCell align="center">Created At</StyledTableCell>
                                        <StyledTableCell align="center">Message</StyledTableCell>
                                        <StyledTableCell align="center">Panel User</StyledTableCell>
                                        <StyledTableCell align="center">Mobile</StyledTableCell>
                                        <StyledTableCell align="center">Reply</StyledTableCell>
                                        <StyledTableCell align="center">Status</StyledTableCell>
                                        <StyledTableCell align="center">Action</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {whatsappComments && whatsappComments.results ? whatsappComments.results.map((item: any) => {
                                        return (
                                            <StyledTableRow key={item.id}>
                                                <StyledTableCell align="center">
                                                    {item.lead ? item.lead : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {new Date(item.created_at).toLocaleString()}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {item.message ? item.message : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {item.panel_user ? item.panel_user : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {item.mobile_no ? item.mobile_no : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <MessageRounded onClick={() => handlereply(item.lead)} />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {item.status ? item.status : "NA"}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <ExitToAppRounded onClick={() => viewLead(item.lead)} />
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    }) : (
                                        <p style={{ textAlign: "center" }}>No data found</p>
                                    )}
                                </TableBody>
                                {whatsappComments && whatsappComments.count > 0 && (
                                    <TableFooter>
                                        <TablePagination
                                            colSpan={20}
                                            count={whatsappComments.count || 0}
                                            rowsPerPageOptions={[]}
                                            rowsPerPage={rowsPerPage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            page={page}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                        />
                                    </TableFooter>
                                )}
                            </Table>
                        )}
                    </TableContainer>
                </div>
                {open && (
                    <ReplyModal open={open} setOpen={setOpen} setLeadId={setLeadId} leadId={leadId} />
                )}
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    whatsappComments: state.CustomerLeadReducer.whatsappComments,
    agentsList: state.CustomerLeadReducer.agentsList,
    loading: state.CustomerLeadReducer.loading,
});

export default connect(mapStateToProps, { getWhatsappComments, searchCall, getAgentList })(
    LeadsTable
);