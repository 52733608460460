import React, { useEffect } from "react";
import Button from '@mui/material/Button';
import TextField from "@material-ui/core/TextField";
import PhoneInput from "react-phone-input-2";
import { connect } from "react-redux";
import { createCall, getLeadSource } from "../../actions/salesLeadAdminAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import "react-phone-input-2/lib/high-res.css";
import { useHistory } from "react-router-dom";
import './index.sass'

interface Props {
  createCall: Function;
  getLeadSource: Function;
  getCitiesDropdown: Function;
  loading: boolean;
  lead_source: any;
  //  cities: any;
  setOpen: Function;
  lead_id: number;
  profile: any;
}

const CreateCall: React.FC<Props> = ({
  createCall,
  loading,
  setOpen,
  getLeadSource,
  getCitiesDropdown,
  lead_source,
  // cities,
  lead_id,
  profile,
}) => {
  let history = useHistory();
  const [patient_name, setPatientName] = React.useState<string>("");
  const [phone, setPhone] = React.useState<string>("");
  const [treatment, setTreatment] = React.useState("IVF");
  const [source, setSource] = React.useState<string>("Pt. Ref");
  const [city, setCity] = React.useState<string>("");
  const [formStatus, setformStatus] = React.useState<boolean>(true);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPatientName(event.target.value as string);
  };

  const handlePhoneChange = (phone: string) => {
    setPhone(phone);
  };

  const handleTreatmentChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setTreatment(event.target.value as string);
  };
  const handleSourceChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSource(event.target.value as string);
  };

  useEffect(() => {
    // getLeadSource();
    // getCitiesDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = async () => {
    let body = {
      name: patient_name,
      phone_no: phone,
      source: source,
    };

     await createCall(body);
    setOpen(false);
    if (phone && phone.length < 12 ||parseInt(phone &&phone[2])<6) {
      setformStatus(false);
    } else {
      setformStatus(true);
      await createCall(body);
      setOpen(false);

      // if(profile.profile.userrole == 'CE')
      //   history.push(`/dashboard/pcc/fill-info`)

      // {
      //   !isEmpty(profile) ? (
      //     profile.profile.userrole !== "PCC" ? null :
      //       (
      //         <h1>df</h1>
      //       )
      //   ) : null
      // } 
    }
  };
  return (
    <form className="modal-form">
      <div className="modal-form-fields" style={{ marginBottom: "7px" }}>
        <TextField
          id="outlined-basic"
          placeholder="Patient name"
          variant="outlined"
          value={patient_name}
          onChange={handleChange}
          style={{ width: "100%", maxWidth: "440.95px" }}
        />
      </div>
      <div style={{ marginBottom: "7px" }}>
        <PhoneInput
          inputProps={{
            name: "phone",
            required: true,
            // autoFocus: true,
          }}
          country={"in"}
          value={phone}
          onChange={(phone) => handlePhoneChange(phone)}
        />
        {formStatus ? "" : <p className="">Please enter correct number</p>}
      </div>
      <div className="modal-form-fields" style={{ marginBottom: "7px" }}>
        <FormControl variant="outlined" required>
          <Select
            id="demo-simple-select-required"
            value={source}
            onChange={handleSourceChange}
            style={{ width: "100%" }}
            inputProps={{ "aria-label": "Without label" }}
          >
            {/* <MenuItem value="none">Lead Source</MenuItem>
            <MenuItem value="FaceBook">FaceBook</MenuItem> */}
            <MenuItem value="Pt. Ref">Pt. Ref</MenuItem>
            {/* <MenuItem value="Google">Google</MenuItem>
            <MenuItem value="Others">Others</MenuItem> */}

          </Select>
        </FormControl>
      </div>
      <div className="modal-form-fields">
        <FormControl variant="outlined" required>
          {/* {!isEmpty(cities) && cities.results.length > 0 ? (
            <Autocomplete
              id="combo-box-demo"
              // value={treatment_city}
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));

                  setCity(obj.name.split(",")[0]);
                }
              }}
              options={cities.results}
              freeSolo
              getOptionLabel={(option: any) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="City Name"
                  variant="outlined"
                  style={{ width: "90%", maxWidth: "440.89px" }}
                />
              )}
            />
          ) : (
            <TextField variant="outlined" />
          )} */}
        </FormControl>
      </div>
      <div className="modal-form-fields">
        <Button
          variant="contained"
          color="secondary"
          style={{ marginTop: "20px", minWidth: "50%" }}
          disabled={loading}
          onClick={submitForm}
        >
          {loading ? <CircularProgress size={24} /> : "Submit"}
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.SalesLeadAdminReducer.loading,
  lead_source: state.SalesLeadAdminReducer.lead_source,
  lead_id: state.SalesLeadAdminReducer.lead_id,
  profile: state.loginReducer.profile,
  // cities: state.dropdownReducer.cities,
});

export default connect(mapStateToProps, {
  createCall,
  getLeadSource,
})(CreateCall);
