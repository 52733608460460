import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MessageIcon from "@material-ui/icons/Message";
import { Button } from "@material-ui/core";
import CallIcon from "@material-ui/icons/Call";
import {
  clickToCall,
  getLeadSource,
  getLatestLeads,
  searchCall,
  getCities,
  getPrescriptionData,
} from "../actions/bookingActions";
import Loader from "../components/Loader2";
import CommentsModal from "../components/Comments/comment";
import CommentsModal2 from "../components/Comments/Comment2";
import { ExitToAppRounded } from "@material-ui/icons";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { CALL_DISABLE_TIME } from "../../CallTimeDisable";

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  tableContainer: {
    marginTop: "1rem",
    position: "sticky",
    top: "200px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

interface Props {
  getLatestLeads: Function;
  latest_lead: any;
  loading: boolean;
  clickToCall: Function;
  getCities: Function;
  cities: any;
  getPrescriptionData: any;
  prescription: any;
  callButton: any;
  setCallButton: any;
}

const LeadsTable: React.FC<Props> = ({
  getLatestLeads,
  latest_lead,
  clickToCall,
  getCities,
  cities,
  loading,
  getPrescriptionData,
  prescription,
  callButton,
  setCallButton,
}) => {
  const classes = useStyles();
  const [open2, setOpen2] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [leadId, setLeadId] = useState<number>(0);
  const [prscriptionLead, setPrescriptionLead] = useState<any>("");
  const [pLink, setPlink] = useState<any>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openPrescription = Boolean(anchorEl);
  const [fileType, setFileType] = useState<any>("");
  const [openPdf, setOpenPdf] = useState<boolean>(false);
  useEffect(() => {
    let url = `?latest=true`;
    getLatestLeads(url);
    getCities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (open2 === false) {
      setLeadId(0);
    }
  }, [open2]);

  const handleClick = (id: number) => {
    setOpen(true);
    setLeadId(id);
  };

  const handleModal = (id: number) => {
    setOpen2(true);
    setLeadId(id);
  };

  const handleCallClick = (id: number, type: string) => {
    setLeadId(id);
    setCallButton(false);
    clickToCall(id, type);
    setTimeout(() => {
      setCallButton(true);
    }, CALL_DISABLE_TIME);
  };

  const dispatch = useDispatch();
  const history = useHistory();

  const viewLead = (id: any) => {
    let body = {
      search_by: "call_id",
      value: `${id}`,
    };
    dispatch(searchCall(body));
    history.push("/dashboard/ce/lead/search");
  };

  useEffect(() => {
    if (prscriptionLead !== "") {
      getPrescriptionData(`?lead=${prscriptionLead}`);
    }
  }, [prscriptionLead]);

  const handlePrescriptionClick = (
    id: any,
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setPrescriptionLead(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFrameView = (data: any) => {
    if (data.base64file !== null && data.image === "") {
      setFileType("b64");
      setPlink(`data:application/pdf;base64,${data.base64file}`);
    } else if (data.base64file === null && data.image !== "") {
      let pieces: any = data.image.split(".").pop();
      const last: any = pieces;
      setFileType(last);
      setPlink(data.image);
    }
    setOpenPdf(true);
  };
  const handlePdfModelClose = () => {
    setOpenPdf(false);
  };

  return (
    <>
      <div className="data-table" style={{ width: "100%" }}>
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ height: "500px" }}
        >
          {loading ? (
            <Loader />
          ) : (
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell>Lead ID</StyledTableCell>
                  {/* <StyledTableCell align="center">
                    Lead Source URL
                  </StyledTableCell> */}
                  <StyledTableCell align="center">Comments</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                  <StyledTableCell align="center">Call</StyledTableCell>
                  <StyledTableCell align="center">
                    Follow-up Date
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Follow-up Time
                  </StyledTableCell>
                  <StyledTableCell align="center">Prescription</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {latest_lead.count > 0
                  ? latest_lead.results.map((row: any) => (
                      <StyledTableRow
                        key={row.id}
                        // style={{
                        //   background: setColor(row.appt_status),
                        // }}
                      >
                        <StyledTableCell component="th" scope="row">
                          {row.id}
                          <ExitToAppRounded onClick={() => viewLead(row.id)} />
                        </StyledTableCell>
                        {/* <StyledTableCell align="center">
                          {row.lead_source_url ? row.lead_source_url : "NA"}
                        </StyledTableCell> */}
                        <StyledTableCell align="center">
                          <div>
                            <MessageIcon onClick={() => handleClick(row.id)} />
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <div>
                            <MessageIcon onClick={() => handleModal(row.id)} />
                          </div>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {/* <CallIcon
                                                    onClick={() => handleCallClick(row.id, "call")}
                                                /> */}
                          <Button
                            disabled={!callButton}
                            className="addDeck"
                            variant="contained"
                            color="primary"
                            onClick={() => handleCallClick(row.id, "call")}
                            startIcon={<CallIcon />}
                          ></Button>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.followup_date}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.followup_time}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.is_prescription ? (
                            <Button
                              aria-controls="fade-menu"
                              aria-haspopup="true"
                              onClick={(event: any) =>
                                handlePrescriptionClick(row.id, event)
                              }
                            >
                              <NoteAddIcon />
                            </Button>
                          ) : (
                            "NA"
                          )}
                          <Menu
                            id="fade-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={openPrescription}
                            onClose={handleClose}
                            TransitionComponent={Fade}
                          >
                            {prescription &&
                              prescription?.results &&
                              prescription?.results?.length > 0 &&
                              prescription?.results.map((data: any) => {
                                let pieces: any = data.image.split(".").pop();
                                const last: any = pieces;
                                return (
                                  <>
                                    {data?.base64file !== null &&
                                    data.image === "" ? (
                                      <MenuItem
                                        onClick={() => handleFrameView(data)}
                                      >
                                        Name :{data?.name}, Age:{data?.age},
                                        Created Date :
                                        {new Date(
                                          data.created_at
                                        ).toLocaleString()}
                                      </MenuItem>
                                    ) : data?.base64file === null &&
                                      data.image !== "" ? (
                                      last === "png" ||
                                      last === "jpg" ||
                                      last === "jpeg" ? (
                                        <MenuItem>
                                          <a
                                            href={data?.image}
                                            rel="noreferrer noopener"
                                            target="_blank"
                                          >
                                            Name :{data?.name}, Age:{data?.age},
                                            Created Date :
                                            {new Date(
                                              data.created_at
                                            ).toLocaleString()}
                                          </a>
                                        </MenuItem>
                                      ) : (
                                        <MenuItem>
                                          <a
                                            href={data?.image}
                                            rel="noreferrer noopener"
                                            target="_blank"
                                          >
                                            Name :{data?.name}, Age:{data?.age},
                                            Created Date :
                                            {new Date(
                                              data.created_at
                                            ).toLocaleString()}
                                          </a>
                                        </MenuItem>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              })}
                          </Menu>
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  : "NA"}
              </TableBody>
            </Table>
          )}
        </TableContainer>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openPdf}
          onClose={handlePdfModelClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openPdf}>
            <>
              {fileType === "pdf" ? (
                <iframe
                  style={{ width: "80%", height: "750px" }}
                  src={pLink}
                  title="title"
                >
                  Presss me: <a href={pLink}>Download PDF</a>
                </iframe>
              ) : fileType === "b64" ? (
                <iframe
                  src={pLink}
                  style={{ width: "80%", height: "750px" }}
                  title="Iframe Example"
                ></iframe>
              ) : (
                ""
              )}
            </>
          </Fade>
        </Modal>
      </div>
      <CommentsModal open={open} setOpen={setOpen} leadId={leadId} />
      <CommentsModal2
        open={open2}
        getCities={getCities}
        cities={cities}
        setOpen={setOpen2}
        leadId={leadId}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  latest_lead: state.BookingReducer.latest_lead,
  lead_source: state.BookingReducer.lead_source,
  cities: state.BookingReducer.cities,
  loading: state.BookingReducer.loading,
  prescription: state.BookingReducer.prescription,
});

export default connect(mapStateToProps, {
  getLatestLeads,
  clickToCall,
  getLeadSource,
  searchCall,
  getCities,
  getPrescriptionData,
})(LeadsTable);
