export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  SET_LOADING: "ia/set_loading",
  GET_CITIES: "ia/get_cities",
  GET_AREA: "ia/get_area",
  UPLOAD_BULK_PACAKGE: "ia/upload_bulk_package",
  GET_IMAGING_TEST_LIST: "ia/get_imaging_test_list",
  GET_IMAGING_PACKAGE_ALIAS: "ia/get_imaging_package_alias",
  GET_PACKAGES: "ia/get_package",
  CREATE_IMAGING_ALIAS: "ia/package_alias_created",
  UPDATED_PACKAGE_ALIAS: "ia/package_alias_updated",
  GET_SUB_CENTER_INFORMATION: "ia/get_sub_center_information",
  GET_CENTER_ADDRESS_PRICE: "ia/get_center_address_price",
  GET_DAY_WISE_TIME_SLOT: "ia/get_day_wise_time_slot",
  GET_TIMESLOTS: "ia/get_timeslots",
  DAY_WISE_TIME_SLOT_EDIT: "ia/day_wise_time_slot_edit",
  CREATE_CENTER_PACKAGE: "ia/create_center_pacakge",
  UPDATED_CENTER_PACKAGE: "ia/update_center_pacakge",
  UPDATE_IMAGING_PACKAGE: "ia/update_imagin_package",
  GET_DAYS_LIST: "ia/get_days_list",
  GET_PIN_CODE: "ia/get_pin_code",
  GET_SALES_MANAGER: "ia/get_sales_manager",
  GET_DOCUMENT: "ia/get_document",
  CREATE_NEW_CENTER: "ia/create_new_center",
  CREATE_SUB_CENTER: "ia/create_sub_center",
  GET_CENTER_INFORMATION: "ia/get_center_information",
  UPDATE_SUB_CENTRE: "ia/update_sub_centre",
  GET_CODE_GENRATOR_DATA: "ia/get_code_genrator_data",
  CREATE_IMAGING_PACKAGE: "ia/create_imaging_package",
  UPDATE_BULK_PACKAGE: "ia/update_bulk_pacakge",
  CHECK_GEOFENCE_AREA: "ia/checking-geofence-area",
  GET_MAMPMYINDIA_ACCESS_TOKEN: "ia/get-mapmyindia-access-token",
  CHECK_GEOFENCE_AREA_MAMPMYINDIA: "ia/checking-geofence-area-mapmyindia",
  GET_CENTER_PACKAGE_COMMENTS: "ia/get_center_package_comments",
  UPDATE_CENTER: "ccadmin/update_center",
  GET_TICKET_DATA: "ia/get_ticket_data",
  GET_TICKET_CATEGORIES: "ia/get_ticket_category",
  GET_TICEKT_SUB_CATEGORIES: "ia/get_ticket_categories",
  CREATE_TASK_TICKET: "ia/create_task_ticket",
  GET_RED_TECH_COMMENT: "ia/redtech_comments/",
  POST_RED_TECH_COMMENT: "ia/postredtech_comments/",
  GET_CENTER_COMMENTS: "ia/get_package_comments",
  GET_DISPLAY_CITIES: "ia/get_display_city",
  DISABLED_CENTER: "ia/center/update",
  DISABLED_SUB_CENTER: "ia/center_address/update",
  UPDATE_PACKAGE: "ia/update_package",

};

interface UpdateImaginPackage {
  type: typeof ACTIONS.UPDATE_IMAGING_PACKAGE;
  payload: Array<any>;
}

interface GetMapMyIndiaAccessToken {
  type: typeof ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN;
  payload: Array<any>;
}
interface CheckGeoFenceAreaMapMyIndia {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA;
  payload: Array<any>;
}
interface GetDisplayCity {
  type: typeof ACTIONS.GET_DISPLAY_CITIES;
  payload: Array<any>;
}

interface DisabledCenter {
  type: typeof ACTIONS.DISABLED_CENTER;
  payload: Array<any>;
}
interface UpdateCenter {
  type: typeof ACTIONS.UPDATE_CENTER;
  payload: Array<any>;
}
interface UpateBulkPackage {
  type: typeof ACTIONS.UPDATE_BULK_PACKAGE;
  payload: Array<any>;
}
interface GetCenterPackageComments {
  type: typeof ACTIONS.GET_CENTER_PACKAGE_COMMENTS;
  payload: Array<any>;
}
interface GetCentreComments {
  type: typeof ACTIONS.GET_CENTER_COMMENTS;
  payload: Array<any>;
}
interface GetDocument {
  type: typeof ACTIONS.GET_DOCUMENT;
  payload: Array<any>;
}
interface CreateImagingPackage {
  type: typeof ACTIONS.CREATE_IMAGING_PACKAGE;
  payload: Array<any>;
}
interface GetCodeGenratorData {
  type: typeof ACTIONS.GET_CODE_GENRATOR_DATA;
  payload: Array<any>;
}
interface UpdateSubCentre {
  type: typeof ACTIONS.UPDATE_SUB_CENTRE;
  payload: Array<any>;
}
interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface GetSalesManager {
  type: typeof ACTIONS.GET_SALES_MANAGER;
  payload: Array<any>;
}
interface CreateNewCenter {
  type: typeof ACTIONS.CREATE_NEW_CENTER;
  payload: boolean;
}
interface CreateSubCenter {
  type: typeof ACTIONS.CREATE_SUB_CENTER;
  payload: boolean;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface UploadBulkPackage {
  type: typeof ACTIONS.UPLOAD_BULK_PACAKGE;
  payload: Array<any>;
}
interface GetDaysList {
  type: typeof ACTIONS.GET_DAYS_LIST;
  payload: Array<any>;
}
interface UploadBulkPackage {
  type: typeof ACTIONS.UPLOAD_BULK_PACAKGE;
  payload: Array<any>;
}
interface CreateCenterPackage {
  type: typeof ACTIONS.CREATE_CENTER_PACKAGE;
  payload: Array<any>;
}
interface UpdateCenterPackage {
  type: typeof ACTIONS.UPDATED_CENTER_PACKAGE;
  payload: Array<any>;
}
interface UpdateDayWiseTimeSlots {
  type: typeof ACTIONS.DAY_WISE_TIME_SLOT_EDIT;
  payload: Array<any>;
}
interface GetTimeSlots {
  type: typeof ACTIONS.GET_TIMESLOTS;
  payload: Array<any>;
}
interface GetDayWiseTimeSlot {
  type: typeof ACTIONS.GET_DAY_WISE_TIME_SLOT;
  payload: Array<any>;
}
interface GetCenterAddressPrice {
  type: typeof ACTIONS.GET_CENTER_ADDRESS_PRICE;
  payload: Array<any>;
}
interface GetSubCenterInfo {
  type: typeof ACTIONS.GET_SUB_CENTER_INFORMATION;
  payload: Array<any>;
}
interface UpdatePackageAlias {
  type: typeof ACTIONS.UPDATED_PACKAGE_ALIAS;
  payload: Array<any>;
}
interface CreatePackageAlias {
  type: typeof ACTIONS.CREATE_IMAGING_ALIAS;
  payload: Array<any>;
}
interface GetPackage {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}
interface GetImagingPackageAlias {
  type: typeof ACTIONS.GET_IMAGING_PACKAGE_ALIAS;
  payload: Array<any>;
}
interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}
interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}
interface GetArea {
  type: typeof ACTIONS.GET_AREA;
  payload: Object;
}
interface GetImagingTestList {
  type: typeof ACTIONS.GET_IMAGING_TEST_LIST;
  payload: Array<any>;
}

interface GetRedTechComment {
  type: typeof ACTIONS.GET_RED_TECH_COMMENT;
  payload: Array<any>;
}
interface GetTicketData {
  type: typeof ACTIONS.GET_TICKET_DATA;
  payload: Object;
}
interface GetTicketCategory {
  type: typeof ACTIONS.GET_TICKET_CATEGORIES;
  payload: Object;
}
interface GetTicketSubCategories {
  type: typeof ACTIONS.GET_TICEKT_SUB_CATEGORIES;
  payload: Object;
}
interface PostRedTechComment {
  type: typeof ACTIONS.POST_RED_TECH_COMMENT;
  payload: Object;
}
interface CreateTaskTicket {
  type: typeof ACTIONS.CREATE_TASK_TICKET;
  payload: Array<any>;
}
interface UpdatePackage {
  type: typeof ACTIONS.UPDATE_PACKAGE;
  payload: Object;
}
interface DisabledSubCenter {
  type: typeof ACTIONS.DISABLED_SUB_CENTER;
  payload: Array<any>;
}
export type BookingTypes =
  | UpdateImaginPackage
  | ChangePassword
  | SetLoading
  | GetCities
  | GetArea
  | UploadBulkPackage
  | GetImagingTestList
  | GetImagingPackageAlias
  | GetPackage
  | CreatePackageAlias
  | UpdatePackageAlias
  | GetSubCenterInfo
  | GetCenterAddressPrice
  | GetDayWiseTimeSlot
  | GetTimeSlots
  | UpdatePackage
  | UpdateCenter
  | UpdateDayWiseTimeSlots
  | CreateCenterPackage
  | UpdateCenterPackage
  | GetDaysList
  | GetDocument
  | GetMapMyIndiaAccessToken
  | GetPinCode
  | GetSalesManager
  | UpdateSubCentre
  | GetDaysList
  | GetCodeGenratorData
  | CreateImagingPackage
  | UpateBulkPackage
  | CheckGeoFenceAreaMapMyIndia
  | GetCenterPackageComments
  | GetTicketData
  | GetTicketCategory
  | GetTicketSubCategories
  | CreateTaskTicket
  | GetRedTechComment
  | PostRedTechComment
  | GetCentreComments
  | GetDisplayCity
  | DisabledSubCenter
  | DisabledCenter;
