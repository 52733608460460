import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, MenuItem, Select, TextField, Grid } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useHistory } from 'react-router';
import { postImagingComplaint, getAgentList, getAgentDataRaiseTicektData,} from '../../actions/CollectionCenterAdmin';




let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    }
}));

type ModalProps = {
    open: boolean;
    bookingId: number;
    leadId: number;
    agentsList: any;
    getAgentList: any;
    assigneUserList: any;
    postImagingComplaint: any;
    getAgentDataRaiseTicektData:any;
    raiseTicektAgent:any;
    setOpen: Function;
};

const CommentsModal2: React.FC<ModalProps> = ({
    open,
    bookingId,
    leadId,
    assigneUserList,
    getAgentList,
    agentsList,
    postImagingComplaint,
    getAgentDataRaiseTicektData,
    raiseTicektAgent,
    setOpen,
}) => {
    const classes = useStyles();

    const [description, setDescription] = useState("")
    const [agent, setAgent] = useState<any>([]);

    const history = useHistory()
    const timer = useRef<any>(0)

    const handleClose = () => {
        setOpen(false);
    };

    const raiseComplaint = () => {
        const id = bookingId !== 0 ? bookingId : leadId !== 0 ? leadId : 0
        const body: any = {

            query: description,
            agent,
            booking_ticket:id,

        }
        postImagingComplaint(body)
        setDescription("")
        history.push("/dashboard/cca")

    };

    useEffect(() => {
        if (history.action === "POP") {
            getAgentDataRaiseTicektData()
        }
    }, [])


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.head}>
                            <h4
                                style={{
                                    marginBottom: "1rem",
                                }}
                                id="transition-modal-title"
                            >
                                Raise a query ticket
                            </h4>
                            <CloseIcon onClick={() => setOpen(false)} />
                        </div>
                        <Grid container direction="row" alignItems="flex-start" justify="space-between" spacing={3} style={{ margin: "1rem auto" }}>
                            <Grid item xs={12} md={12}>
                                <Autocomplete
                                    id="agent"
                                    onChange={(event, newValue) => {
                                        let sourceIds: any = [];
                                        if (newValue) {
                                            let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                            sourceIds = obj.map((item: any) => item.id);
                                        }
                                        setAgent(sourceIds);
                                    }}
                                    freeSolo
                                    blurOnSelect
                                    aria-required
                                    limitTags={1}
                                    multiple
                                    options={raiseTicektAgent?.results || []}
                                    getOptionDisabled={(option: any) => {
                                        return agent.includes(option.id);
                                    }}
                                    disableClearable
                                    disableCloseOnSelect
                                    getOptionLabel={(option: any) =>
                                        option.user && option.user.username
                                    }
                                    onInputChange={(event, newInputValue) => {
                                        clearTimeout(timer.current);
                                        timer.current = setTimeout(() => {
                                            getAgentList(`?code=${newInputValue.replace(" ", "")}`);
                                        }, 1000);
                                        if (newInputValue.length === 0) {
                                            setAgent([]);
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            className="input"
                                            {...params}
                                            placeholder="Assign To"
                                            variant="outlined"
                                            inputProps={{
                                                ...params.inputProps,
                                                onKeyDown: (e) => {
                                                      if (e.key === 'Enter') {
                                                        e.stopPropagation();
                                                      }
                                                },
                                              }}
                                            style={{ width: "100%", margin: "0", padding: "0" }}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="comment"
                                    type="text"
                                    multiline
                                    rows={3}
                                    value={description}
                                    className="input"
                                    variant="outlined"
                                    placeholder="Description"
                                    onChange={(e) => setDescription(e.target.value as string)}
                                    style={{ width: "100%" }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ height: "50px" }}
                                    onClick={raiseComplaint}
                                    disabled={description === ""}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    complaint: state.CollectionCenterAdminReducer.complaint,
    assigneUserList: state.CollectionCenterAdminReducer.assigneUserList,
    agentsList: state.CollectionCenterAdminReducer.agentsList,
    raiseTicektAgent: state.CollectionCenterAdminReducer.raiseTicektAgent,
});

export default connect(mapStateToProps, {
    getAgentList,
    postImagingComplaint,
    getAgentDataRaiseTicektData,
})(CommentsModal2);
