import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { Container, Box, Grid, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@mui/material/Button';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport } from '@material-ui/data-grid';
import {genrateCenterAddressPriceFilter} from '../../../helpers/generateUrl'
import { getCities } from "../../actions/ImagingAdminAction";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "100%",
            flexGrow: 21,
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
    })
);

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

interface Props {
    getCenterAddressPrice:any;
    centerAddressPrice:any;
    getSubCenterInfo: any;
    subCenterInfor: any;
    loading: boolean;
    getCities: any;
    cities: any;
}

const BookingExport: React.FC<Props> = ({
    getCenterAddressPrice,
    centerAddressPrice,
    getSubCenterInfo,
    subCenterInfor,
    loading,
    getCities,
    cities,
}) => {
    const classes = useStyles();
    const timer = useRef<any>(0)
    const [centerId, setCenterId] = useState<any>("")
    const [pincodeTxt, setPincodeTxt] = useState<any>("");
    const [city, setCity] = useState<any>("");
    const filterCenter = async () => {
        const body: any = {
            center_address: centerId,
        }
        const url = genrateCenterAddressPriceFilter(body).substring(2);
        getCenterAddressPrice(`?${url}&download=true`)
      
    }
    useEffect(() => {
        if(pincodeTxt.length===6){
          getSubCenterInfo(`?imaging=true&pincode=${pincodeTxt.length===6?pincodeTxt:""}&pincode=${pincodeTxt}`)
        }
      }, [pincodeTxt])
    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Test Code', width: 200 },
        { field: 'name', headerName: 'Test Name', width: 200 },
        { field: 'mrp', headerName: 'MRP', width: 200 },
        { field: 'sellingprice', headerName: 'Selling Price', width: 200 },
        { field: 'transferprice', headerName: 'Transfer Price', width: 200 },
    ];


    let rows:any = []
    if (centerAddressPrice&&centerAddressPrice?.results&&centerAddressPrice?.results.length>0) {
        rows =centerAddressPrice&&centerAddressPrice?.results?.length > 0 && centerAddressPrice.results.map((pack: any) => {
            return {
                ...pack,
                id: pack?.package?.code,
                name: pack?.package?.name,
                mrp: pack?.package_price,
                sellingprice: pack?.offer_price,
                transferprice: pack?.transfer_price,
            }
        })
    }

    return (
        <main className={classes.content}>
            <Grid container spacing={1} direction="row" style={{ alignItems: "right" }}>
            <Grid item xs={12} sm={6} md={2}>
            <TextField
              className="input"
              label="Pincode"
              variant="outlined"
              fullWidth
              onChange={(e) =>
                setPincodeTxt(
                  String(e.target.value.replace(/[^0-9]/g, "")).trimStart()
                    .length <=6
                    ? String(e.target.value.replace(/[^0-9]/g, "")).trimStart()
                    : pincodeTxt
                )
              }
            />
          </Grid>
            <Grid item xs={4}>
                <Autocomplete
                  id="city"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setCity(obj.id);
                    }
                  }}
                  options={cities}
                  freeSolo
                  blurOnSelect
                  aria-required
                  getOptionLabel={(option: any) => option.name}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getCities(newInputValue);
                      if (newInputValue.length === 0) {
                        setCity("");
                      }
                    }, 1000);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="input"
                      label="City"
                      variant="outlined"
                      style={{ width: "100%", marginBottom: "1rem" }}
                    />
                  )}
                />
              </Grid>
            <Grid item xs={12} sm={6} md={6}>
                    <Autocomplete
                        id="center"
                        key={city}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                setCenterId(obj.id);
                                getSubCenterInfo(`?imaging=true&pincode=${pincodeTxt.length===6?pincodeTxt:""}&city=${obj.id}`);
                            }

                        }}
                        options={subCenterInfor.length > 0 ? subCenterInfor : []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        limitTags={1}
                        getOptionLabel={(option: any) => `Center:${option?.center?.name},Address:${option?.address}`}
                        disableClearable
                        disableCloseOnSelect
                        onInputChange={(event, newInputValue) => {
                            clearTimeout(timer.current);
                            timer.current = setTimeout(() => {
                                getSubCenterInfo(`?imaging=true&pincode=${pincodeTxt.length===6?pincodeTxt:""}&city=${city}&name=${newInputValue}`);
                            }, 1000);
                            if (newInputValue.length === 0) {
                                setCenterId("");
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                className="input"
                                {...params}
                                label="CENTRE NAME"
                                variant="outlined"
                                style={{ width: "100%", margin: "0", padding: "0" }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <Button
                        variant="contained"
                        color="secondary"

                        fullWidth
                        onClick={filterCenter}
                        disabled={centerId === ""}
                    >
                        Filter
                    </Button>
                </Grid>
            </Grid>
            <Paper elevation={15} className={classes.paper}>
                <div style={{ height: 700 }}>
                    <DataGrid rowsPerPageOptions={[]}
                        rows={rows}
                        columns={columns}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        pageSize={50}
                        // rowCount={imagingTestList.count || 0}
                        loading={loading}
                    />
                </div>
            </Paper>
        </main>
    )
}

const mapStateToProps = (state: any) => ({
    cities: state.ImagingAdminReducer.cities,
})

export default connect(mapStateToProps, {
    getCities,
})(BookingExport);
