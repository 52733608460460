import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

import "react-sliding-pane/dist/react-sliding-pane.css";
import "../react-sliding-pane.css";
import {
  Card,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@material-ui/core";
import Geocode from "react-geocode";
import { useDispatch } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  getPackage,
  createNewBooking,
  getCoupons,
  getLeads,
  getLeadDetails,
  resetLeadDetails,
  getAvailableSlots,
  getCities,
  getCenterInfo,
  getImagingTimeslots,
  createImagingBooking,
  getRedCoing,
  getSubCenterInfo,
  getPinCode,
  getMultiplePackage,
  getDisplayCities,
  getMapMyIndiaAccessToken,
  checkGeoFenceArea,
  checkGeoFenchMapMyIndia,
  createImagingBookingCustom,
  getPartners,
} from "../../../actions/CollectionCenterAdmin";
import Button from '@mui/material/Button';
import "./index.sass";
import { connect } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { GENDER_CHOICE } from "../../constant";
import { ACTIONS } from "../../../interfaces/actionTypes/CollectionCenterAdmin";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DispositionType from "./orderDisposition";

Geocode.setApiKey("AIzaSyBPjDlsHYhWtjt3FxgRPnTMXN-qJZ9hz0s");
Geocode.enableDebug();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },

    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "300px",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    selectedHomeDxSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    paper: {
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "100%",
      padding: "20px 10px 10px 10px",
      background: "#ffffff",
      marginBottom: "10px",
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    },
    packageLink: {
      color: "#ffffff",
      textDecoration: "none",
    },
    tableContainer: {
      marginTop: "1rem",
      position: "sticky",
      top: "200px",
    },
    option: {
      "&:hover": {
        backgroundColor: "cyan !important",
      },
      dialog: {
        zIndex: 1,
      },
    },
  })
);
interface Props {
  getPackage: any;
  packageList: any;
  couponList: any;
  getCoupons: any;
  createNewBooking: any;
  getLeads: any;
  leads: any;
  getLeadDetails: any;
  lead_details: any;
  booking_slots: any;
  resetLeadDetails: any;
  createBooking: any;
  getAvailableSlots: any;
  loading: boolean;
  sameNumberBooking: boolean;
  getCities: any;
  cities: any;
  getCenterInfo: any;
  centerInfo: any;
  getImagingTimeslots: any;
  imagingTimeSlots: any;
  createImagingBooking: any;
  createIMGBooking: any;
  getRedCoing: any;
  createIMGBookingCustom: any;
  redCoin: any;
  getSubCenterInfo: any;
  subCenter: any;
  getPinCode: any;
  pin_code: any;
  getMultiplePackage: any;
  multiplePackage: any;
  getDisplayCities: any;
  displayCities: any;
  checkGeoFenceArea: any;
  checkGeoFenceStatus: boolean;
  checkGeoFenchMapMyIndia: any;
  geoFencesMapMyIndia: any;
  getMapMyIndiaAccessToken: any;
  mapmyindiaAccessToken: any;
  createImagingBookingCustom: any;
  getPartners: any;
  partnerList: any;
  userDetails: any;
}

const NewBooking: React.FC<Props> = ({
  partnerList,
  getPartners,
  packageList,
  getPackage,
  couponList,
  getCoupons,
  getLeads,
  getLeadDetails,
  lead_details,
  resetLeadDetails,
  getImagingTimeslots,
  imagingTimeSlots,
  loading,
  createIMGBookingCustom,
  createImagingBooking,
  createIMGBooking,
  getSubCenterInfo,
  getAvailableSlots,
  subCenter,
  booking_slots,
  getPinCode,
  getMultiplePackage,
  multiplePackage,
  getDisplayCities,
  displayCities,
  checkGeoFenceArea,
  checkGeoFenceStatus,
  checkGeoFenchMapMyIndia,
  geoFencesMapMyIndia,
  getMapMyIndiaAccessToken,
  mapmyindiaAccessToken,
  createImagingBookingCustom,
  pin_code,
  cities,
  userDetails,
  leads,
}) => {
  const classes = useStyles();
  const history = useHistory();
  let dispatch = useDispatch();
  const [collection_dateHDX, setCollection_dateHDX] = useState<String>("");
  const [selectedSlot, setSelectedSlot] = useState<Number>(0);
  const [selectedHomeDxSlot, setHomeDSelectedSlot] = useState<Number>(0);

  const [customer_name, setCustomer_name] = useState<String>("");
  const [customer_age, setCustomer_age] = useState<String>("");
  const [customer_gender, setCustomer_gender] = useState<string>("");
  const [customer_designation, setCustomerDesignation] = useState<String>("");
  const [singlePackage, setSinglePackage] = useState<any>({});
  const [isPaneOpen, setPaneOpen] = useState<boolean>(false);
  const [customer_phonenumber, setCustomer_phonenumber] = useState<String>("");
  const [customer_alternatenumber, setCustomer_alternatenumber] =
    useState<String>("");
  const [customer_whatsapppnumber, setCustomer_whatsapppnumber] =
    useState<String>("");
  const [customer_address, setCustomer_address] = useState<String>("");
  const [customer_email, setCustomer_email] = useState<String>("");
  const [customer_landmark, setCustomer_landmark] = useState<String>("");
  const [customer_aadhar, setCustomer_aadhar] = useState<String>("");
  const [passport, setPassport] = useState<String>("");
  const [adharPassport, setAdharPassport] = useState("customer_aadhar");
  const [packageDetails, setPackageDetails] = useState<any>([]);
  const [packages, setPackages] = useState<Number[]>([]);
  const [homePackages, setHomePackages] = useState<any>([]);
  const [price, setPrice] = useState<any>(0);
  const [discount, setDiscount] = useState<any>(0);
  const [totalPrice, setTotalPrice] = useState<any>(0);
  const [coupon, setCoupon] = useState<Number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [extraCharge, setExtraCharge] = useState<boolean>(false);
  const [cityId, setCityId] = useState<any>("");
  const [packageKey, setPackageKey] = useState<any>(0);
  const [redDisc, setRedDisc] = useState<any>(0);
  const [mobileValidation, setMobileValidation] = useState({
    customer_alternatenumber: true,
    customer_phonenumber: true,
    customer_whatsapppnumber: true,
  });
  const timer = useRef<any>(0);
  const [defaultEmail, setDefaultEmail] = useState<any>(
    "customer.reports@redcliffelabs.com"
  );
  const [customer_longitude, setCustomerLongitude] = useState<any>("");
  const [customer_latitude, setCustomerLatitude] = useState<any>("");
  const [center_longitude, setCenterLongitude] = useState<any>("");
  const [center_latitude, setCenterLatitude] = useState<any>("");
  const [bookingFor, setBookingFor] = useState<string>("");
  const [testType, setTestType] = useState<string>("None");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [centerId, setCenterId] = useState<any>("");
  const [centerAddress, setCenterAddress] = useState<any>("");
  const [subCenterId, setSubCenterId] = useState<any>("");
  const [customer_areapincode, setCustomer_areapincode] = useState<Number>(0);
  const [centerKey, setCenterKey] = useState<any>(0);
  const [customer_city, setCustomer_city] = useState<String>("");
  const [customer_cityName, setCustomer_cityName] = useState<String>("");
  const [couponMessage, setCouponMessage] = useState<string>("");
  const [tncMessage, setTncMessage] = useState<string>("");
  const [redCashCheck, setRedCashCheck] = useState<boolean>(false);
  const [subCenterData, setSubCenterData] = useState<any>([]);
  const [centerMessage, setCenterMessage] = useState<string>("");
  const [cityNames, setCityNames] = useState<string>("");
  const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
  const [mapAddressObject, setMapAddressObject] = useState<any>({});
  const [accessToken, setAccessToken] = useState<any>("");
  const [search, setSearch] = useState<String>("");
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  //pathlogies booking only
  const [pathtimeSlots, setPathTimeSlots] = useState<Array<Object>>([]);
  const [pathpincode, setPathPinCode] = useState<String>("");
  const [openLocationModal, setOpenLocationModal] = useState<boolean>(false);
  const [favoritePlace, setFavoritePlace] = useState<any>("");
  const [addressLine2, setAddressLine2] = useState<string>("");
  const [zoneName, setZoneName] = useState<String>("");
  const [is_pincode, setIsPincode] = useState<boolean>(true);
  const [zoneId, setZoneId] = useState<any>("");
  const [pincode, setPinCode] = useState<String>("");
  const [homePackAvl, setHomePackAvl] = useState<boolean>(false);
  const [homeDxPackage, setHomeDxpackage] = useState<any>([]);
  const [typeFinder, setTypeFinder] = useState<any>();
  const [homeDxAtHome, setHomeDxAtHome] = useState<any>("");
  const [error, setError] = useState("");
  const [inputList, setInputList] = useState<any>([
    {
      customer_designation: "",
      customer_name: "",
      customer_gender: "",
      customer_age: "",
    },
  ]);

  //Imagin State Declartiob
  const [packagesImg, setPackagesImg] = useState<any>("");
  const [ImgPackage, setImgPackage] = useState<any>([]);
  const [totalPriceH, setTotalPriceH] = useState<any>("");
  const [priceI, setPriceI] = useState<any>("");
  const [packDetI, setPackDetI] = useState<any>("");

  const [partner, setPartner] = useState<any>(0);
  const [isPriceHide, setIsPriceHide] = useState<boolean>(false);
  const [isCreditBtn, setIsCreditBtn] = useState<boolean>(false);
  const [isCreadit, setIsCreadit] = useState<any>("false");
  const [customer_type, setCustomer_type] = useState<any>("")
  const [partnerName, setPartnername] = useState<any>("")
  const [partnerId, setPartnerId] = useState<any>("")

  const [collection_date, setCollection_date] = useState<String>(
    `${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
      ? `0${new Date().getMonth() + 1}`
      : new Date().getMonth() + 1
    }-${new Date().getDate() + 1 < 10
      ? `0${new Date().getDate() + 1}`
      : new Date().getDate() + 1
    }`
  );
  const [numCheck, setNumCheck] = useState({
    altCheck: false,
    whatsCheck: false,
  });
  const onKeyDown = (e: any) => {
    if (e.key === "Enter") {
      searchLead(e);
    }
  };
  useEffect(() => {

  }, [])

  useEffect(() => {
    if (partnerList && partnerList?.results && partnerList?.results.length > 0) {
      setPartnername(partnerList?.results[0]?.user?.name);
      setPartnerId(partnerList?.results[0]?.id);
    } else if (partnerList && partnerList?.results && partnerList?.results.length == 0) {
      setPartnername("");
      setPartnerId("")
    }
  }, [partnerList]);
  const searchLead = (e: any) => {
    let body = {
      search_by: "call_id",
      value: search,
    };
    getLeads(body, "number=true");
  };
  const selectLead = (id: any) => {
    getLeadDetails(`${id}`, "true");
  };

  const handleSearch = (e: React.ChangeEvent<{ value: unknown }>) => {
    setSearch(e.target.value as string);
  };

  useEffect(() => {
    setCustomer_age(lead_details.patient_age as String);
    setCustomer_name(lead_details.patient_name as String);
    setCustomer_phonenumber(lead_details.mobile as String);
    setCustomer_whatsapppnumber(lead_details.mobile as String);
    setCustomer_gender(lead_details.calling_gender as string);
  }, [lead_details]);

  useEffect(() => {
    resetLeadDetails();
    getDisplayCities();
    getPartners();
    getMapMyIndiaAccessToken();
  }, []);

  useEffect(() => {
    getCoupons();
  }, []);

  const handlePackages = () => {
    const min_phlebo_cost =
      packageList &&
      packageList?.results &&
      packageList?.results.length > 0 &&
      packageList?.results[0].min_booking_amount_for_phlebo;
    //for imaging

    let accumulatedPriceH: any = 0;
    let packIdsI: any = [];
    let packIdsH: any = [];
    let packDetImg: any = [];
    let packDetH: any = [];
    packageDetails &&
      packageDetails.length > 0 &&
      packageDetails.map((item: any) => {
        if (item.org_type === "imaging") {
          packIdsI.push(item.id);
          packDetImg.push(item);
        } else if (item.org_type === "homedx") {
          setHomePackAvl(true);
          packIdsH.push(item.id);
          packDetH.push(item);
          accumulatedPriceH = accumulatedPriceH + item?.offer_price;
        }

        setCenterKey(item.id);
        setSubCenterData([]);
      });

    //for submit
    setPackagesImg(packIdsI);
    setHomePackages(packIdsH);
    //for blue box
    setHomeDxpackage(packDetH);
    setImgPackage(packDetImg);

    setDiscount(0);
    setTotalPriceH(Math.floor(accumulatedPriceH));
    setCoupon(0);
    setCouponCode("");
  };
  useEffect(() => {
    if (testType !== "" && testType !== "PAH") {
      getPackage(`org_type=imaging&type=${testType}`);
    }
  }, [testType]);
  useEffect(() => {
    if (testType === "PAH") {
      getPackage(`org_type=homedx`);
    }
  }, [testType]);
  useEffect(() => {
    handlePackages();
  }, [packageDetails]);
  useEffect(() => {
    let accumulatedPriceI: any = 0;
    multiplePackage &&
      multiplePackage.results &&
      multiplePackage.results.length > 0 &&
      multiplePackage.results.map((item: any) => {
        if (item.package_center_address_prices === null) {
          accumulatedPriceI = accumulatedPriceI + item.offer_price;
        } else {
          accumulatedPriceI =
            accumulatedPriceI + item.package_center_address_prices.offer_price;
        }
      });
    setPriceI(Math.floor(accumulatedPriceI));
  }, [multiplePackage]);

  useEffect(() => {
    if (extraCharge) {
      setTotalPrice(Math.floor(price - discount + 200));
    } else {
      setTotalPrice(Math.floor(price - discount));
    }
  }, [price, discount, extraCharge]);

  useEffect(() => {
    if (totalPrice < 0) {
      setTotalPrice(0);
    }
  }, [totalPrice]);

  const applyCoupon = () => {
    if (couponCode === "") {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
      return;
    }
    const appliedCoupon =
      couponList.results.find((coup: any) => coup.id === coupon) ||
      couponList.results.find((coup: any) => coup.code === couponCode);
    if (appliedCoupon && appliedCoupon.minimum_price < totalPrice) {
      setCoupon(appliedCoupon.id);
      if (appliedCoupon.discount_type.toLowerCase() === "percentage") {
        setDiscount(Math.floor((price * appliedCoupon.discount) / 100));
      }
      if (appliedCoupon.discount_type.toLowerCase() === "amount") {
        setDiscount(Math.floor(appliedCoupon.discount));
      }
    } else {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
    }
  };
  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(customer_phonenumber && customer_phonenumber[0]) < 6 ||
        (customer_phonenumber && customer_phonenumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_phonenumber: false,
        }));
      }
    }
    if (type === "alternate") {
      if (
        parseInt(customer_alternatenumber && customer_alternatenumber[0]) < 6 ||
        (customer_alternatenumber && customer_alternatenumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_alternatenumber: false,
        }));
      }
    }
    if (type === "whatsapp") {
      if (
        parseInt(customer_whatsapppnumber && customer_whatsapppnumber[0]) < 6 ||
        (customer_whatsapppnumber && customer_whatsapppnumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_whatsapppnumber: false,
        }));
      }
    }
  };

  useEffect(() => {
    if (subCenter) {
      setSubCenterData(subCenter);
    }
  }, [subCenter]);
  useEffect(() => {

    getPartners("")

  }, []);

  const submitForm = async (e: any) => {
    e.preventDefault();
    const packHomeDx: any = [];
    const packImgaging: any = [];
    const homeDxpackCheck = packageDetails.filter(
      (data: any) => data.org_type === "homedx"
    );
    const data1: any = {
      customer_name: inputList ? inputList[0]?.customer_name : "",
      customer_age: inputList ? inputList[0]?.customer_age : "0",
      customer_gender: inputList ? inputList[0]?.customer_gender : "",
      packages:
        homeDxAtHome === "true"
          ? packagesImg
          : packagesImg.concat(homePackages),
      booking_date: moment(new Date()).format("YYYY-MM-DD"),
      collection_date: collection_date,
      collection_slot: selectedSlot,
      customer_email: customer_email === "" ? defaultEmail : customer_email,
      customer_phonenumber: customer_phonenumber,
      customer_altphonenumber: numCheck.altCheck
      ? customer_phonenumber
      : customer_alternatenumber,
      customer_whatsapppnumber: numCheck.whatsCheck
        ? customer_phonenumber
        : customer_whatsapppnumber,
      customer_address: customer_address,
      customer_landmark: customer_landmark,
      customer_areapincode: customer_areapincode,
      customer_longitude: customer_longitude,
      customer_latitude: customer_latitude,
      customer_city: customer_city,
      customer_aadhar: customer_aadhar,
      passport_number: passport,
      coupon_applied: coupon,
      designation: inputList ? inputList[0]?.customer_designation : "",
      display_name: cityNames,
      book_for: bookingFor || "",
      cityid: cityId,
      center: centerId,

      partner: partnerId,
      is_credit: isCreadit === "true" ? true : false,

      is_redcash_applied: redCashCheck ? "true" : "false",
      center_address: subCenterId,
      is_homedx_at_home: homeDxAtHome,
      landmark: favoritePlace,
      address_line2: addressLine2,
      homedx_data: JSON.stringify({
        homedx_packages: homePackages,
        homedx_collection_slot: selectedHomeDxSlot,
        collection_date: collection_dateHDX,
        customer_altphonenumber: numCheck.altCheck
          ? customer_phonenumber
          : customer_alternatenumber,
        customer_whatsapppnumber: numCheck.whatsCheck
          ? customer_phonenumber
          : customer_whatsapppnumber,
        customer_landmark: customer_landmark,
        address_line2: addressLine2,
        landmark: favoritePlace,
        designation: inputList ? inputList[0]?.customer_designation : "",
      }),
    };
    if (homeDxAtHome === "false") {
      delete data1.is_homedx_at_home;
      delete data1.homedx_data;
    }
    if (data1["coupon_applied"] === 0) {
      delete data1["coupon_applied"];
    }
    if (homeDxpackCheck.length === 0) {
      delete data1["homedx_data"];
    }
    if (data1["dob"] === "") {
      delete data1["dob"];
    }

    await createImagingBookingCustom(data1);
  };
  useEffect(() => {
    if (subCenterId !== "") {
      getImagingTimeslots(
        `?packages=${packagesImg}&collection_date=${collection_date}&center_address=${subCenterId}`
      );
    } else {
      dispatch({ type: ACTIONS.GET_MULTIPLE_PACKAGES, payload: "" });
    }
  }, [subCenterId, collection_date]);

  // useEffect(() => {
  //   if (createIMGBooking?.pk) {
  //     history.push(`/dashboard/ce/booking-view/${createIMGBooking.pk}`);
  //     dispatch({ type: ACTIONS.CREATE_IMAGING_BOOKING, payload: "" });
  //     dispatch({ type: ACTIONS.GET_MULTIPLE_PACKAGES, payload: "" });
  //   }
  // }, [createIMGBooking]);
  useEffect(() => {
    if (createIMGBookingCustom?.pk) {
      history.push(`/dashboard/cca/booking-view/${createIMGBookingCustom.pk}/?booked=true`);
      dispatch({ type: ACTIONS.CREATE_IMAGING_BOOKING_CUSTOM, payload: "" });
      dispatch({ type: ACTIONS.CREATE_IMAGING_BOOKING_CUSTOM, payload: "" });
    }
  }, [createIMGBookingCustom]);

  const changeCollectionDate = (e: any) => {
    setSelectedSlot(0);
    setCollection_date(e.target.value as string);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdharPassport((event.target as HTMLInputElement).value);
  };
  const handleSubCenter = (val: any) => {
    setCentreName(val?.center?.name);
    setCenterId(val.center.id);
    setSubCenterId(val.id);
    setCenterLatitude(val.latitude);
    setCenterLongitude(val.logitude);
    setCenterAddress(val.address);
    // setCustomer_address(val.address);
    setCustomer_areapincode(parseInt(val.location.id));
    setCustomer_city(val.location.city);
    // setPriceI(val?.cost?.offer_price__sum)
  };
  useEffect(() => {
    if (packagesImg.length > 0 || homePackages.length > 0) {
      if (homeDxAtHome == "false") {
        getSubCenterInfo(
          `?imaging=true&customer_latitude=${customer_latitude}&customer_longitude=${customer_longitude}&package=${packagesImg.concat(
            homePackages
          )}&display_name=${cityNames}`
        );
      } else {
        getSubCenterInfo(
          `?imaging=true&customer_latitude=${customer_latitude}&customer_longitude=${customer_longitude}&package=${packagesImg}&display_name=${cityNames}`
        );
      }
    }
  }, [packagesImg, customer_latitude, customer_longitude, homePackages])
  const handleGender = (value: any) => {
    setCustomerDesignation(value);
    const findGender = GENDER_CHOICE.filter((el: any) => el.db_name == value);
    setCustomer_gender(findGender[0]?.gender);
  };

  useEffect(() => {
    if (subCenterId !== "") {
      if (homeDxAtHome === "false") {
        getMultiplePackage(
          `?center_packages=true&multiple_ids=${packagesImg.concat(
            homePackages
          )}&center_address=${subCenterId}&imaging=true`
        );
      } else {
        getMultiplePackage(
          `?center_packages=true&multiple_ids=${packagesImg}&center_address=${subCenterId}&imaging=true`
        );
      }
    }
  }, [subCenterId, packagesImg]);
  useEffect(() => {
    const slots = booking_slots && booking_slots?.results;
    setPathTimeSlots(
      slots && slots[0].zone_data && slots[0].zone_data.time_slots
    );
  }, [booking_slots]);

  useEffect(() => {
    if (ImgPackage.length > 0) {
      if (subCenterData.length === undefined || subCenterData.length === null || subCenterData.length === 0) {
        // setSubCenterId("");
        setCenterMessage("No Center Available for this package");
      } else {
        setCenterMessage("");
      }
    }
  }, [subCenterData]);

  useEffect(() => {
    if (ImgPackage?.length === 0) {
      setCenterMessage("");
    }
  }, [ImgPackage]);

  const handleNumSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNumCheck({ ...numCheck, [event.target.name]: event.target.checked });
  };

  useEffect(() => {
    if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
      setCustomerLatitude(geoFencesMapMyIndia?.latitude);
      setCustomerLongitude(geoFencesMapMyIndia?.longitude);
    }
  }, [geoFencesMapMyIndia]);

  useEffect(() => {
    if (mapmyindiaAccessToken?.accesstoken) {
      setAccessToken(mapmyindiaAccessToken?.accesstoken);
    }
  }, [mapmyindiaAccessToken]);

  useEffect(() => {
    if (mapAddressObject?.eLoc) {
      getPinCode(mapAddressObject?.addressTokens?.pincode);
      setCustomer_landmark(mapAddressObject?.placeAddress);
      setPinCode(mapAddressObject?.addressTokens?.pincode);
      checkGeoFenchMapMyIndia(mapAddressObject?.eLoc, "NA");
    }
  }, [mapAddressObject]);

  useEffect(() => {
    if (pin_code?.results && pin_code?.results.length > 0) {
      setCityId(pin_code?.results[0].cityid);
    }
  }, [pin_code]);

  useEffect(() => {
    if (pincode && pin_code?.results?.length == 0) {
      setErrorMessage("We are not providing service on this pincode.");
    }
    if (pincode && pin_code?.results?.length > 0) {
      const findPinCode = pin_code?.results.find(
        (code: any) => code.pincode == pincode
      );
      if (findPinCode) {
        setErrorMessage("");
        getArea(pincode);
        const { id, area, city, cityid } = pin_code.results.find(
          (code: any) => code.pincode === pincode
        );
        // getPackage(`city_id=${cityid}&org_type=homedx`);
        setCityId(cityid);
        setCustomer_areapincode(id as Number);
        setCityId(cityid as String);
        setCustomer_cityName(city as String);
        setIsPincode(false);
        setPackageKey(cityid);
      } else {
        setErrorMessage("We are not providing service on this pincode.");
      }
    }
  }, [pin_code, pincode, collection_dateHDX]);

  const getArea = (pincode: String) => {
    const zone =
      pin_code?.results &&
      pin_code?.results.find((code: any) => code?.pincode === pincode);
    setZoneName(zone && (zone?.area as String));
    if (zone && zone?.zone_data && zone?.zone_data?.id) {
      if (collection_dateHDX) {
        getAvailableSlots(
          collection_dateHDX,
          zone && zone?.zone_data && zone?.zone_data.id
        );
      }
    }
    setZoneId(zone?.zone_data?.id);
  };

  const changeCollectionDateForHomeDx = (e: any) => {
    setHomeDSelectedSlot(0);
    setCollection_date(e.target.value as string);
    if (zoneId) {
      getAvailableSlots(e.target.value, zoneId);
      // getPackage(`city_id=${cityId}&org_type=homedx`);
    }
  };
  const locationAutoCompleteSearch = (val: string, e: any) => {
    if (val.length > 2) {
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName = "jsonp_callback_" + "data" + id;
        window[callbackName] = (data: any) => {
          delete window[callbackName];
          const ele = document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          const suggestedData = data?.suggestedLocations;
          if (suggestedData.length > 0) {
            setSuggestedAddres(data?.suggestedLocations);
          }
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          document.getElementsByTagName("head")[0] ||
          document.body ||
          document.documentElement
        ).appendChild(script);
      });
    }
  };
  //order book and Dispose Modal start
  const [centreName, setCentreName] = useState<any>("");
  const [leadId, setLeadId] = useState<any>("");
  const tempData = {
    booking_date: moment(new Date()).format("YYYY-MM-DD"),
    collection_date: collection_date,
    cityid: cityId,
    customer_address: customer_landmark,
    packages: packages,
    center_address: subCenterId,
    centreName: centreName,
    display_name: cityNames,
  };

  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        customer_designation: " ",
        customer_name: " ",
        customer_age: " ",
        customer_gender: " ",
      },
    ]);
  };
  const handleRemoveClick = (index: any) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const handleInputChange = (e: any, index: number) => {
    const { name, value } = e.target;
    if (name === "customerName") {
      const newValue = e.target.value;

      if (!newValue.match(/[@!#^*%&<>()_+=1234567890:~`.\\$'"]/)) {
        setError("");
      } else {
        setError("Forbidden character: @!#^*%&<>()_+=1234567890:~`.\\$'\"");
      }
    }
    const list = [...inputList];
    list[index][name] = value;
    if (e.target.name == "customer_designation") {
      const findGender = GENDER_CHOICE.filter(
        (el: any) => el.display_name == e.target.value
      );
      list[index]["customer_gender"] = findGender[0]?.gender;
      list[index]["customer_designation"] = findGender[0]?.db_name;
    }
    if (e.target.name == "dateOfBirth") {
      let today = new Date();
      let birthDate = new Date(e.target.value);
      let age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      // list[index]["customerAge"] = age;
    }
    setInputList(list);
  };
  const [pageType, setPageType] = useState<any>("none");
  const handleOrderBook = () => {
    if (pageType === "none" || pageType === "disposeorder") {
      setPageType("orderbook");
    } else {
      setPageType("none");
    }
    setTimeout(() => {
      window.scroll({
        top: 810,
        behavior: "smooth",
      });
    }, 300);
  };
  const handleDisposeOrder = () => {
    if (pageType === "none" || pageType === "orderbook") {
      setPageType("disposeorder");
    } else {
      setPageType("none");
    }
  };
  const orgTypeFinder = packageDetails.filter(
    (data: any) => data.org_type == "homedx"
  );

  return (
    <div>
      <div className={classes.contentTable}>
      {
        userDetails?.profile?.executive_type === "BML" ? (
          <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <h6>Search Lead</h6>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
            direction="row"
            justify="flex-start"
          >
            <TextField
              className="input"
              id="search"
              type="search"
              value={search}
              variant="outlined"
              placeholder="Search"
              onChange={(e) => handleSearch(e)}
              style={{ margin: "0", padding: "0 9px", width: "80%" }}
            />
            <Button
              variant="contained"
              style={{ height: "56px" }}
              disabled={search.length === 0}
              color="primary"
              onClick={searchLead}
            >
              Search
            </Button>
          </Grid>
        </Grid>

                    ) :""}
                  
                
        
        {leads.results && leads.results.length > 0 && (
          <TableContainer className={classes.table}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Lead ID</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Age</TableCell>
                  <TableCell align="center">Gender</TableCell>
                  <TableCell align="center">Mobile Number</TableCell>
                  <TableCell align="center">Whatsapp Number</TableCell>
                  <TableCell align="center">Address</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leads.results.map((lead: any) => {
                  return (
                    <TableRow>
                      <TableCell align="center">{lead.id || ""}</TableCell>
                      <TableCell align="center">
                        {lead.patient_name || ""}
                      </TableCell>
                      <TableCell align="center">
                        {lead.patient_age || ""}
                      </TableCell>
                      <TableCell align="center">
                        {lead.calling_gender || ""}
                      </TableCell>
                      <TableCell align="center">XXXXXXXXXX</TableCell>
                      <TableCell align="center">XXXXXXXXXX</TableCell>
                      <TableCell align="center">{lead.address || ""}</TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => selectLead(lead.id)}
                        >
                          Select
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <Paper className={classes.paper} elevation={15}>
        <form onSubmit={submitForm}>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            spacing={3}
            style={{ margin: "1rem auto", background: "#eff" }}
          >
            <Grid item xs={12} md={4}>
              <p>Parnter Name</p>
              {/* <Autocomplete
                id="partnername"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setPartner(obj.id)
                    setIsPriceHide(obj.is_price_hide)
                    setIsCreditBtn(obj.is_credit_button_display)
                  }
                }}
                options={partnerList?.results}
                value={partnerName}
                freeSolo
                blurOnSelect
                aria-required
                // disabled={addressLine2 === "" || customer_address === "" || favoritePlace === ""}
                getOptionLabel={(option: any) => option.user && option.user.name}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current)
                  timer.current = setTimeout(() => {
                    getPartners(`${newInputValue}`)
                  }, 1000)
                  if (newInputValue.length === 0) {
                    setPartner(0)
                  }
                }}
                renderInput={(params) => (
                  <TextField className="input"
                    {...params}
                    placeholder="Partner"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    required
                  />
                )}
              /> */}

              <TextField
                className="input"
                name="Partner"
                type="text"
                placeholder="Partner"
                value={partnerName}
                disabled
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%", margin: "0" }}
                onChange={(e) => setPartner(e.target.value as any)}
              />
            </Grid>
            {/* <Grid item xs={12} md={4}>
              <TextField
                className="input"
                label="Booking date"
                name="collection_date"
                type="date"
                value={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                    ? `0${new Date().getMonth() + 1}`
                    : new Date().getMonth() + 1
                  }-${new Date().getDate() < 10
                    ? `0${new Date().getDate()}`
                    : new Date().getDate()
                  }`}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%", margin: "0" }}
                required
                disabled
              />
            </Grid> */}

            {/* <Grid item xs={12} md={4}>
              <TextField
                className="input"
                name="collection_date"
                label="Collection date"
                type="date"
                value={
                  (collection_dateHDX && collection_dateHDX.toString()) || ""
                }
                // onChange={(e) => setCollection_date(e.target.value as string)}
                onChange={(e) => setCollection_dateHDX(e.target.value)}
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: "100%", margin: "0" }}
                required={homeDxAtHome === "true" ? true : false}
                inputProps={{
                  min: `${new Date().getFullYear()}-${
                    new Date().getMonth() + 1 < 10
                      ? `0${new Date().getMonth() + 1}`
                      : new Date().getMonth() + 1
                  }-${
                    new Date().getDate() < 10
                      ? `0${new Date().getDate()}`
                      : new Date().getDate()
                  }`,
                }}
              />
            </Grid> */}
            <Grid item xs={12} md={4}>
              <Autocomplete
                id="citynames"
                onChange={(event, newValue) => {
                  let cName: any = "";
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    cName = obj;
                  }
                  setCityNames(cName);
                }}
                options={displayCities?.cities || []}
                freeSolo
                blurOnSelect
                aria-required
                disabled={collection_date === ""}
                limitTags={1}
                getOptionLabel={(option: any) => option}
                disableClearable
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="City Name"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Autocomplete
                id="colony-area-sector"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setMapAddressObject(obj);
                  }
                }}
                options={suggestedAddress ? suggestedAddress : []}
                freeSolo
                blurOnSelect
                aria-required
                getOptionLabel={(option: any) =>
                  `${option?.placeName}, ${option?.placeAddress}`
                }
                filterOptions={(option: any) => option}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    locationAutoCompleteSearch(newInputValue, event);
                  }, 500);
                }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    placeholder="Search Address"
                    label="Customer Address"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    disabled={collection_date === ""}
                    required
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                            if (e.key === 'Enter') {
                              e.stopPropagation();
                            }
                      },
                    }}
                  />
                )}
              />
              {customer_latitude !== "" ? (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {errorMessage}
                </div>
              ) : (
                ""
              )}
            </Grid>

            {/* <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            spacing={3}
            style={{ margin: "1rem auto", background: "#e1f5fe" }}
          > */}




            {/* <Grid item xs={12} md={4}>
            <p>Payment Mode</p>
              <Select
                className="input"
                name="isCreadit"
                variant="outlined"
                value={isCreadit}
                style={{ width: "100%", margin: "0" }}
                onChange={(e) => setIsCreadit(e.target.value as String)}
                required
              >
                <MenuItem value="none">Please Select Payment Mode</MenuItem>
                <MenuItem value="false">COD</MenuItem>
                <MenuItem value="true">Prepaid</MenuItem>
              </Select>
            </Grid> */}
            {/* </Grid> */}

            {/* <Grid item xs={12} md={2} >
              <RadioGroup
                row
                value={homeDxAtHome}
                aria-labelledby="demo-customized-radios"
                name="customized-radios"
                onChange={(e) => setHomeDxAtHome(e.target.value)}
              >
                <FormControlLabel
                  value={"true"}
                  control={<Radio />}
                  label="Pathology At Home"
                />
                <FormControlLabel
                  value={"false"}
                  control={<Radio />}
                  label="Pathology At Centre"
                  
                />
              </RadioGroup>
            </Grid> */}
            <Grid item xs={12} md={4}>
              <Select
                className="input"
                name="testType"
                label="Test Type"
                variant="outlined"
                value={testType}
                style={{ width: "100%", margin: "0", height: "40px" }}
                onChange={(e) => setTestType(e.target.value as string)}
              // disabled={homeDxAtHome === ""}
              >
                <MenuItem value="None">Test Type</MenuItem>
                <MenuItem value="CT">CT</MenuItem>
                <MenuItem value="MRI">MRI</MenuItem>
                <MenuItem value="XRay">X-Ray</MenuItem>
                <MenuItem value="USG">USG</MenuItem>
                <MenuItem value="PET">PET SCAN</MenuItem>
                <MenuItem value="NUC">NUCLEAR</MenuItem>
                <MenuItem value="DEXA">DEXA</MenuItem>
                <MenuItem value="MAM">MAMMOGRAPHY</MenuItem>
                <MenuItem value="CAR">CARDIO</MenuItem>
                <MenuItem value="NEU">NEUROLOGY</MenuItem>
                <MenuItem value="CBCT">CBCT</MenuItem>
                <MenuItem value="PFT">PFT</MenuItem>
                {/* <MenuItem value="PAC">PATHOLOGY AT CENTER</MenuItem> */}
                {/* <MenuItem value="PAH">PATHOLOGY</MenuItem> */}
                <MenuItem value="Audiology">Audiology</MenuItem>
                <MenuItem value="Gyne">GYNE</MenuItem>
                <MenuItem value="PAC">Path At Centre</MenuItem>
              </Select>
            </Grid>

            <Grid item xs={12} md={8}>
              <Autocomplete
                onChange={(event, newValue) => {
                  let sourceIds: any = [];
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    sourceIds = obj.map((item: any) => item);
                  }
                  setPackageDetails(sourceIds);
                }}
                freeSolo
                multiple
                id="checkboxes-tags-demo"
                // disabled={customer_landmark === "" || testType === ""}
                options={packageList?.results || []}
                disableCloseOnSelect
                limitTags={5}
                disableClearable
                onInputChange={(event, newInputValue) => {
                  if (testType !== "" && testType !== "PAH") {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getPackage(
                        `org_type=imaging&type=${testType}&code=${newInputValue}`
                      );
                    }, 1000);
                  } else {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getPackage(`org_type=homedx&code=${newInputValue}`);
                    }, 1000);
                  }
                }}
                getOptionLabel={(option: any) => {
                  return option.name && option.package_city_prices
                    ? option?.code + " " + option.name.toString() + ", " + ""
                    : option?.code +
                    " " +
                    option.name +
                    ", " +
                    "Alias:" +
                    option.alias1;
                }}
                renderOption={(option: any) => {
                  return (
                    <p>
                      <strong>
                        {option.name && option.package_city_prices
                          ? option?.code +
                          " " +
                          option.name.toString() +
                          ", " +
                          ""
                          : option?.code + " " + option.name}
                        <br />
                        </strong>
                        <b style={{color:"red"}}>Alias:</b>
                          {/* Alias: */}
                        
                        {option.alias1}
                      
                    </p>
                  );
                }}
                getOptionDisabled={(option: any) => {
                  const packages = packageDetails?.map((pack: any) => pack.id);
                  return packages.includes(option.id);
                }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Package/Test"
                    variant="outlined"
                    style={{ width: "100%", margin: "0" }}
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                            if (e.key === 'Enter') {
                              e.stopPropagation();
                            }
                      },
                    }}
                  />
                )}
              />
              <span style={{ color: "red" }}>{centerMessage}</span>

            </Grid>
            <Grid item xs>
              <Autocomplete
                id="sub_center"
                key={centerKey}
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    handleSubCenter(obj);
                  }
                }}
                options={subCenterData.length > 0 ? subCenterData : []}
                style={{ width: "100%" }}
                freeSolo
                blurOnSelect
                aria-required
                getOptionLabel={(option: any) =>
                  `Center:${option?.center?.name}Address:${option?.address
                  },Distance : ${Math.trunc(option?.distance)} KM ,Price:  ₹${option?.cost?.offer_price__sum
                  }`
                }
                renderOption={(option: any) => {
                  return (
                    <p
                      style={{
                        backgroundColor:
                          option?.center_status === "p1" ? "#ffecb3" : "",
                      }}
                    >
                      <strong>Center :</strong>
                      {option?.center?.name} <strong>Address :</strong>{" "}
                      {option?.address},
                      <strong>
                        Distance : {Math.trunc(option?.distance)} KM ,<br />
                        Price: ₹{option?.cost?.offer_price__sum}
                      </strong>
                    </p>
                  );
                }}
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    const searchTerm = "Address:";
                    const indexOfFirst = newInputValue.indexOf(searchTerm);
                    let str = newInputValue.substring(7, indexOfFirst);
                    if (homeDxAtHome == "false") {
                      getSubCenterInfo(
                        `?imaging=true&name=${str}&customer_latitude=${customer_latitude}&customer_longitude=${customer_longitude}&package=${packagesImg.concat(
                          homePackages
                        )}&display_name=${cityNames}`
                      );
                    } else {
                      getSubCenterInfo(
                        `?imaging=true&name=${str}&customer_latitude=${customer_latitude}&customer_longitude=${customer_longitude}&package=${packagesImg}&display_name=${cityNames}`
                      );
                    }
                  }, 1000);
                }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Center Address"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    required
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                            if (e.key === 'Enter') {
                              e.stopPropagation();
                            }
                      },
                    }}
                  />
                )}
                disabled={packagesImg.length === 0 || !customer_latitude}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <p>Scanning date</p>
              <input
                className="input"
                name="collection_date"
                type="date"
                value={
                  (collection_date && collection_date.toString()) || ""
                }
                // onChange={(e) => setCollection_date(e.target.value as string)}
                onChange={(e) => changeCollectionDate(e)}
                style={{
                  width: "100%",
                  margin: "0",
                  padding: "18.5px 14px",
                  height: "40px",
                }}
                min={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                  ? `0${new Date().getMonth() + 1}`
                  : new Date().getMonth() + 1
                  }-${new Date().getDate() < 10
                    ? `0${new Date().getDate()}`
                    : new Date().getDate()
                  }`}
              />
            </Grid>
          </Grid>


          {homeDxPackage.length > 0 && homeDxAtHome === "true" ? (
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="flex-end"
              spacing={3}
              style={{ margin: "1rem auto", background: "#eff" }}
            >
              <Grid item xs={12} md={2}>
                <TextField
                  className="input"
                  name="zone"
                  type="text"
                  value={pincode || 0}
                  disabled
                  variant="outlined"
                  label="Pin Code"
                  style={{ width: "100%", margin: "0", background: "#ccc" }}
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  className="input"
                  name="zone"
                  type="text"
                  label="Zone"
                  value={zoneName || ""}
                  disabled
                  variant="outlined"
                  style={{ width: "100%", margin: "0", background: "#ccc" }}
                  required
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  className="input"
                  name="city"
                  type="text"
                  value={customer_cityName}
                  variant="outlined"
                  label="City"
                  style={{ width: "100%", margin: "0", background: "#ccc" }}
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  className="input"
                  placeholder="Ex: School/College/Restaurant/Shop/Bank/Government Office"
                  name="zone"
                  type="text"
                  value={favoritePlace || ""}
                  variant="outlined"
                  style={{ width: "100%", margin: "0" }}
                  required
                  label="Landmark/Sublocality"
                  onChange={(e) => setFavoritePlace(e.target.value as string)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  className="input"
                  name="zone"
                  type="text"
                  value={customer_address}
                  variant="outlined"
                  style={{ width: "100%", margin: "0" }}
                  required
                  label="House No./Plot No./Flat No./Door No./Shop/ c/o"
                  onChange={(e) =>
                    setCustomer_address(e.target.value as string)
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  className="input"
                  name="zone"
                  type="text"
                  value={addressLine2 || ""}
                  variant="outlined"
                  style={{ width: "100%", margin: "0" }}
                  required
                  label="Apartment/Building /Colony/ Block/Sector/ Street/Gali/Road/Chawl"
                  onChange={(e) => setAddressLine2(e.target.value as string)}
                />
              </Grid>
            </Grid>
          ) : (
            ""
          )}

          <Grid container spacing={3} style={{ margin: "1rem auto" }}>
            {/* <Grid item xs={12} md={6}>
              <div
                className="sampleCollectionTimeImaging2"
                style={{ overflow: "auto" }}
              >
                <p style={{ marginBottom: "1rem" }}>Sample Collection Time</p>
                <div className={classes.slotContainer}>
                  {pathtimeSlots &&
                    pathtimeSlots.length > 0 &&
                    pathtimeSlots.map((slot: any) => {
                      return slot?.available_slots > 0 &&
                        homeDxAtHome === "true" &&
                        homeDxPackage.length > 0 ? (
                        <Card
                          key={slot.id}
                          className={
                            slot.id === selectedHomeDxSlot
                              ? classes.selectedHomeDxSlot
                              : classes.slot
                          }
                          onClick={() =>
                            setHomeDSelectedSlot(slot.id as Number)
                          }
                        >
                          <p
                            style={{
                              textAlign: "center",
                              color: "black",
                              fontSize: "14px",
                            }}
                          >
                            {slot.start_time}-{slot.end_time}
                          </p>
                          <p style={{ textAlign: "center", color: "green" }}>
                            {" "}
                            Available slots: {slot.available_slots}
                          </p>
                        </Card>
                      ) : (
                        ""
                      );
                    })}
                  {}
                </div>
              </div>
            </Grid> */}

            <Grid item xs={12} md={8}style={{alignItems:"center",margin:"auto"}}>
              <div
                className="sampleCollectionTimeImaging"
                style={{ overflow: "auto" }}
              >
                <p style={{ marginBottom: "1rem" }}>Scanning Time</p>
                <div className={classes.slotContainer}>
                  {imagingTimeSlots &&
                    imagingTimeSlots?.results &&
                    imagingTimeSlots?.results?.length > 0 &&
                    imagingTimeSlots.results.map((slot: any) => {
                      return (
                        imagingTimeSlots?.results.length > 0 && (
                          <Card
                            key={slot.id}
                            className={
                              slot.id === selectedSlot
                                ? classes.selectedSlot
                                : classes.slot
                            }
                            onClick={() => setSelectedSlot(slot.id as Number)}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                color: "black",
                                fontSize: "14px",
                              }}
                            >
                              {slot.start_time} onwards
                            </p>
                            <p style={{ textAlign: "center", color: "green" }}>
                              {" "}
                              {/* Availabe slots: {slot.available_slots} */}
                            </p>
                          </Card>
                        )
                      );
                    })}
                </div>
              </div>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justify="space-between"
            spacing={3}
            style={{ margin: "1rem auto", background: "#eff" }}
          >
            <Grid item xs>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleOrderBook}
              >
                BOOK ORDER
              </Button>
            </Grid>
            <Grid item xs>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={handleDisposeOrder}
              >
                DISPOSE ORDER
              </Button>
            </Grid>
          </Grid>

          {pageType === "none" ? (
            ""
          ) : pageType === "orderbook" ? (
            <div>
              {inputList.map((x: any, index: number) => {
                return (
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-end"
                    spacing={3}
                    style={{ margin: "1rem auto", background: "#eff" }}
                  >
                    {/* <Grid item md={1}>
                      {inputList.length !== 1 && (
                        <HighlightOffIcon
                          style={{
                            marginBottom: "1rem",
                            height: "40px",
                            width: "40px",
                            borderRadius: "50%",
                          }}
                          onClick={() => handleRemoveClick(index)}
                        />
                      )}
                      {inputList.length - 1 === index && inputList.length < 5 && (
                        <AddCircleIcon
                          style={{
                            marginBottom: "1rem",
                            height: "40px",
                            width: "40px",
                            borderRadius: "50%",
                          }}
                          onClick={handleAddClick}
                        />
                      )}
                    </Grid> */}
                    <Grid item xs>
                      <p>Title</p>
                      <Select
                        className="input"
                        name="customer_designation"
                        variant="outlined"
                        // value={customer_designation}
                        value={x.customer_designation}
                        style={{ width: "100%", margin: "0", height: "40px" }}
                        onChange={(e) => handleInputChange(e, index)}
                        required
                      >
                        <MenuItem value="Mrs">Mrs</MenuItem>
                        <MenuItem value="Miss">Miss</MenuItem>
                        <MenuItem value="Ms">Ms</MenuItem>
                        <MenuItem value="Smt">Smt</MenuItem>
                        <MenuItem value="Mr">Mr</MenuItem>
                        <MenuItem value="Baby Boy">Baby Boy</MenuItem>
                        <MenuItem value="Baby Girl">Baby Girl</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </Grid>
                    <Grid item xs>
                      <p>Customer Name</p>
                      <TextField
                        className="input"
                        name="customer_name"
                        type="text"
                        placeholder="Enter Name"
                        value={x.customer_name || ""}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        helperText={error} // error message
                        error={!!error} 
                        style={{ width: "100%", margin: "0" }}
                        // onChange={(e) => setCustomer_name(e.target.value as String)}
                        onChange={(e) => handleInputChange(e, index)}
                        required
                      />
                    </Grid>
                    <Grid item xs>
                      <p>Age</p>
                      <TextField
                        className="input"
                        name="customer_age"
                        type="number"
                        placeholder="Enter Age"
                        value={x.customer_age}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        style={{ width: "100%", margin: "0" }}
                        // onChange={(e) => setCustomer_age(e.target.value as String)}
                        onChange={(e) => handleInputChange(e, index)}
                        required
                      />
                    </Grid>
                    <Grid item xs>
                      <p>Gender</p>
                      <TextField
                        className="input"
                        name="gender"
                        type="text"
                        placeholder="Select Gender"
                        value={x.customer_gender}
                        variant="outlined"
                        disabled
                        InputProps={{ inputProps: { min: 0 } }}
                        style={{ width: "100%", margin: "0" }}
                        onChange={(e) => handleInputChange(e, index)}
                      />
                      {/* <Select className="input"
                name="customer_gender"
                variant="outlined"
                value={customer_gender}
                disabled
                style={{ width: "100%", margin: "0" }}
                // onChange={(e) => setCustomer_gender(e.target.value as String)}
                required
              >
                <MenuItem value={"male"}>Male</MenuItem>
                <MenuItem value={"female"}>Female</MenuItem>
                <MenuItem value={"other"}>Other</MenuItem>
              </Select> */}
                    </Grid>
                  </Grid>
                );
              })}
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-end"
                spacing={3}
                style={{ margin: "1rem auto", background: "#eff" }}
              >
                <Grid item xs={12} md={3}>
                  <TextField
                    className="input"
                    name="customer_email"
                    type="email"
                    label="Email"
                    placeholder="customer.reports@redcliffelabs.com"
                    value={customer_email}
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_email(e.target.value as String)
                    }
                  // required
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    className="input"
                    name="customer_phonenumber"
                    type="number"
                    // onInput={(e: any) => {
                    //   e.target.value = Math.max(0, parseInt(e.target.value))
                    //     .toString()
                    //     .slice(0, 10);
                    // }}
                    // onWheel={(e: any) => e.target.blur()}
                    value={customer_phonenumber || ""}
                    placeholder="Enter Mobile Number"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      String(e.target.value).length!==11?   setCustomer_phonenumber(e.target.value as String):""
                    }
                    onBlur={() => verifyMobile("mobile")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_phonenumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_phonenumber &&
                      "Incorrect Phone Number"
                    }
                    label="Mobile Number"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                <TextField
                    className="input"
                    name="customer_alternatenumber"
                    type="number"
                    // onInput={(e: any) => {
                    //   e.target.value = Math.max(0, parseInt(e.target.value))
                    //     .toString()
                    //     .slice(0, 10);
                    // }}
                    // onWheel={(e: any) => e.target.blur()}
                    value={
                      !numCheck.altCheck
                        ? customer_phonenumber
                        : customer_alternatenumber || ""
                    }
                    placeholder="Enter Alternate Mobile Number"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                     String(e.target.value).length!==11? setCustomer_alternatenumber(e.target.value as String):""
                    }
                    onBlur={() => verifyMobile("alternate")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_alternatenumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_alternatenumber &&
                      "Incorrect Phone Number"
                    }
                    required
                    label="Alternate Mobile Number"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                <TextField
                    className="input"
                    name="customer_whatsapppnumber"
                    type="number"
                    // onInput={(e: any) => {
                    //   e.target.value = Math.max(0, parseInt(e.target.value))
                    //     .toString()
                    //     .slice(0, 10);
                    // }}
                    // onWheel={(e: any) => e.target.blur()}
                    placeholder="Enter Whatsapp Number"
                    value={
                      !numCheck.whatsCheck
                        ? customer_phonenumber
                        : customer_whatsapppnumber || ""
                    }
                    variant="outlined"
                    // maxLength={12}
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      String(e.target.value).length!==11?  setCustomer_whatsapppnumber(e.target.value as String):""
                    }
                    required
                    onBlur={() => verifyMobile("whatsapp")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_whatsapppnumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_whatsapppnumber &&
                      "Incorrect Whatsapp Number"
                    }
                    label="Whatsapp Number"
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <TextField
                    className="input"
                    name="customer_aadhar"
                    type="text"
                    placeholder={"Enter Aadhaar Number"}
                    value={customer_aadhar}
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_aadhar(e.target.value as String)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextField
                    className="input"
                    name="customer_passport"
                    type="text"
                    placeholder={"Enter Passport Number"}
                    value={passport}
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) => setPassport(e.target.value as String)}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={numCheck.altCheck}
                        onChange={handleNumSelect}
                        name="altCheck"
                      />
                    }
                    label="Change Alternate Number"
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={numCheck.whatsCheck}
                        onChange={handleNumSelect}
                        name="whatsCheck"
                      />
                    }
                    label="Change Whatsapp Number"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                direction="row"
                justify="center"
                alignItems="center"
                style={{ margin: "1rem auto" }}
              >
                {/* <Grid item xs={12} md={6} style={{ padding: "10px" }}>
                  <div className="price-details-new-booking">
                    <h5>HomeDx Package</h5>
                    <table style={{ width: "100%", margin: "0" }}>
                      <thead>
                        <tr>
                          <td className="cell" align="center">
                            S No.
                          </td>
                          <td className="cell" align="center">
                            Name
                          </td>
                          <td className="cell" align="center">
                            Package
                          </td>
                          <td className="cell" align="center">
                            Price
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {inputList &&
                          inputList.length > 0 &&
                          homeDxAtHome === "true" &&
                          inputList.map(
                            (customerDetails: any, index: number) => {
                              return (
                                <tr>
                                  <td className="cell" align="center">
                                    {index + 1}
                                  </td>
                                  <td className="cell" align="left">
                                    {customerDetails.customer_designation}{" "}
                                    {customerDetails.customer_name}
                                  </td>
                                  <td className="cell" align="left">
                                    {homeDxPackage &&
                                      homeDxPackage.length > 0 &&
                                      homeDxPackage.map(
                                        (packageItem: any, index: any) => {
                                          return (
                                            <ul>
                                              <li>
                                                <a
                                                  onClick={() => {
                                                    setSinglePackage(
                                                      packageItem
                                                    );
                                                    setPaneOpen(true);
                                                  }}
                                                  className={
                                                    classes.packageLink
                                                  }
                                                >
                                                  {packageItem?.name}
                                                  <br /> DESCRIPTION :{" "}
                                                  {packageItem?.description}
                                                  <br />
                                                  TaT :{" "}
                                                  {packageItem.package_center_address_prices
                                                    ? packageItem
                                                        .package_center_address_prices
                                                        .tat_time
                                                    : packageItem.tat_time}
                                                  <br />
                                                  Price :{" "}
                                                  {packageItem.offer_price}
                                                  <br />
                                                  <span
                                                    style={{
                                                      textDecoration:
                                                        "line-through",
                                                    }}
                                                  >
                                                    MRP :{" "}
                                                    {packageItem.package_price}
                                                  </span>
                                                </a>
                                              </li>
                                            </ul>
                                          );
                                        }
                                      )}
                                  </td>
                                  <td className="cell" align="center">
                                    ₹ {totalPriceH}
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        <tr>
                          <td colSpan={4}>
                            <table style={{ width: "100%" }}>
                              <tr>
                                <td
                                  className="cell"
                                  align="left"
                                  rowSpan={4}
                                  colSpan={2}
                                  style={{ width: "60%" }}
                                  valign="top"
                                >
                                  <Grid container spacing={1} direction="row">
                                    <Grid item xs={12}>
                                      <p>Enter Coupon</p>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <Autocomplete
                                        id="coupon"
                                        // key={couponKey}
                                        onChange={(event, newValue) => {
                                          if (newValue) {
                                            let obj = JSON.parse(
                                              JSON.stringify(
                                                newValue,
                                                null,
                                                " "
                                              )
                                            );
                                            setCoupon(obj.id as Number);
                                            setCouponCode(obj.code as string);
                                            // setCouponType(obj.is_red_cash);
                                            // setRedCouponDiscount(
                                            //   obj.discount as number
                                            // );
                                          }
                                        }}
                                        inputValue={couponCode}
                                        options={couponList?.results || []}
                                        freeSolo
                                        blurOnSelect
                                        disabled={redCashCheck}
                                        onInputChange={(
                                          event,
                                          newInputValue
                                        ) => {
                                          clearTimeout(timer.current);
                                          setCouponCode(newInputValue);
                                          timer.current = setTimeout(() => {
                                            getCoupons(
                                              `?code=${newInputValue}&org_type=homedx`
                                            );
                                          }, 1000);
                                          if (newInputValue === "") {
                                            setCoupon(0);
                                            setDiscount(0);
                                            setTncMessage("");
                                            setCouponMessage("");
                                          }
                                        }}
                                        getOptionLabel={(option: any) =>
                                          option.code
                                        }
                                        getOptionDisabled={(option: any) =>
                                          option.minimum_price > totalPrice
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            className="input"
                                            {...params}
                                            placeholder="Enter Coupon"
                                            variant="outlined"
                                            style={{
                                              width: "100%",
                                              margin: "0",
                                              padding: "0",
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Button
                                        variant="contained"
                                        fullWidth
                                        color="primary"
                                        disabled={redCashCheck}
                                        onClick={applyCoupon}
                                      >
                                        Apply
                                      </Button>
                                    </Grid>
                                    <p
                                      style={{
                                        color: "#8e24aa",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        alignItems: "center",
                                      }}
                                    >
                                      {couponMessage} <br />
                                      {tncMessage}
                                    </p>
                                  </Grid>
                                </td>
                                <td
                                  className="cell"
                                  align="right"
                                  style={{ width: "20%" }}
                                >
                                  Discount
                                </td>
                                <td
                                  className="cell"
                                  align="center"
                                  style={{ width: "20%" }}
                                >
                                  ₹ {Math.floor(discount)}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  className="cell"
                                  align="right"
                                  style={{ width: "20%" }}
                                >
                                  RedCash Discount
                                </td>
                                <td
                                  className="cell"
                                  align="center"
                                  style={{ width: "20%" }}
                                >
                                  ₹ {redDisc > 0 ? Math.floor(redDisc) : 0}
                                </td>
                              </tr>
                              <tr>
                                <td className="cell" align="right">
                                  Phlebo Cost
                                </td>
                                <td className="cell" align="center">
                                  ₹ {extraCharge ? "+ 200" : "0"}
                                </td>
                              </tr>
                              <tr>
                                <td className="cell" align="right">
                                  <h4 className="totalPrice">Total Price</h4>
                                </td>
                                <td className="cell" align="center">
                                  <h4 className="totalPrice">
                                    ₹{" "}
                                    {homeDxAtHome === "true"
                                      ? redDisc > 0
                                        ? Math.floor(totalPriceH) -
                                          Math.floor(redDisc)
                                        : Math.floor(totalPriceH)
                                      : ""}
                                  </h4>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Grid> */}
                <Grid item xs={12} md={12} style={{ padding: "10px" }}>
                  <div className="price-details-new-booking">
                    <h5>Imaging Package</h5>
                    <table style={{ width: "100%", margin: "0" }}>
                      <thead>
                        <tr>
                          <td className="cell" align="center">
                            S No.
                          </td>
                          <td className="cell" align="center">
                            Name
                          </td>
                          <td className="cell" align="center">
                            Package
                          </td>
                          <td className="cell" align="center">
                            Price
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {inputList &&
                          inputList.length > 0 &&
                          inputList.map(
                            (customerDetails: any, index: number) => {
                              return (
                                <tr>
                                  <td className="cell" align="center">
                                    1
                                  </td>
                                  <td className="cell" align="left">
                                    {customerDetails.customer_designation}{" "}
                                    {customerDetails.customer_name}
                                  </td>
                                  <td className="cell" align="left">
                                    {multiplePackage &&
                                      multiplePackage.results &&
                                      multiplePackage.results.length > 0 &&
                                      multiplePackage.results.map(
                                        (packageItem: any, index: any) => {
                                          return (
                                            <ul>
                                              <li>
                                                <a
                                                  onClick={() => {
                                                    setSinglePackage(
                                                      packageItem
                                                    );
                                                    setPaneOpen(true);
                                                  }}
                                                  className={
                                                    classes.packageLink
                                                  }
                                                >
                                                  {packageItem?.name}
                                                  <br /> DESCRIPTION :{" "}
                                                  {packageItem?.description}
                                                  <br />
                                                  TaT :{" "}
                                                  {packageItem.package_center_address_prices
                                                    ? packageItem
                                                      .package_center_address_prices
                                                      .tat_time
                                                    : packageItem.tat_time}
                                                  <br />
                                                  Price :{" "}
                                                  {packageItem.package_center_address_prices
                                                    ? packageItem
                                                      .package_center_address_prices
                                                      .offer_price
                                                    : packageItem.offer_price}
                                                  <br />
                                                  <span
                                                    style={{
                                                      textDecoration:
                                                        "line-through",
                                                    }}
                                                  >
                                                    MRP :{" "}
                                                    {packageItem.package_center_address_prices
                                                      ? packageItem
                                                        .package_center_address_prices
                                                        .package_price
                                                      : packageItem.package_price}
                                                  </span>
                                                </a>
                                              </li>
                                            </ul>
                                          );
                                        }
                                      )}
                                  </td>
                                  <td className="cell" align="center">
                                    ₹ {Math.floor(priceI)}
                                  </td>
                                </tr>
                              );
                            }
                          )}

                        <tr>
                          <td colSpan={4}>
                            <table style={{ width: "100%" }}>
                              <tr>
                                <td
                                  className="cell"
                                  align="left"
                                  rowSpan={3}
                                  colSpan={2}
                                  style={{ width: "60%" }}
                                  valign="top"
                                >
                                  <Grid container spacing={1} direction="row">
                                    <Grid item xs={12}>
                                      <p>Enter Coupon</p>
                                    </Grid>
                                    <Grid item xs={8}>
                                      <Autocomplete
                                        id="coupon_imagin"
                                        onChange={(event, newValue) => {
                                          if (newValue) {
                                            let obj = JSON.parse(
                                              JSON.stringify(
                                                newValue,
                                                null,
                                                " "
                                              )
                                            );
                                            setCoupon(obj.id as Number);
                                            setCouponCode(obj.code as string);
                                          }
                                        }}
                                        inputValue={couponCode}
                                        options={couponList?.results || []}
                                        freeSolo
                                        blurOnSelect
                                        onInputChange={(
                                          event,
                                          newInputValue
                                        ) => {
                                          clearTimeout(timer.current);
                                          setCouponCode(newInputValue);
                                          timer.current = setTimeout(() => {
                                            getCoupons(
                                              `?code=${newInputValue}&org_type=imaging`
                                            );
                                          }, 1000);
                                          if (newInputValue === "") {
                                            setCoupon(0);
                                            setDiscount(0);
                                            setTncMessage("");
                                            setCouponMessage("");
                                          }
                                        }}
                                        getOptionLabel={(option: any) =>
                                          option.code
                                        }
                                        getOptionDisabled={(option: any) =>
                                          option.minimum_price > totalPrice
                                        }
                                        renderInput={(params) => (
                                          <TextField
                                            className="input"
                                            {...params}
                                            placeholder="Enter Coupon"
                                            inputProps={{
                                              ...params.inputProps,
                                              onKeyDown: (e) => {
                                                    if (e.key === 'Enter') {
                                                      e.stopPropagation();
                                                    }
                                              },
                                            }}
                                            variant="outlined"
                                            style={{
                                              width: "100%",
                                              margin: "0",
                                              padding: "0",
                                            }}
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Button
                                        variant="contained"
                                        fullWidth
                                        color="primary"
                                        disabled={redCashCheck}
                                        onClick={applyCoupon}
                                      >
                                        Apply
                                      </Button>
                                    </Grid>
                                    <p
                                      style={{
                                        color: "#8e24aa",
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        alignItems: "center",
                                      }}
                                    >
                                      {couponMessage} <br />
                                      {tncMessage}
                                    </p>
                                  </Grid>
                                </td>
                                <td
                                  className="cell"
                                  align="right"
                                  style={{ width: "20%" }}
                                >
                                  Discount
                                </td>
                                <td
                                  className="cell"
                                  align="center"
                                  style={{ width: "20%" }}
                                >
                                  ₹ {Math.floor(discount)}
                                </td>
                              </tr>
                              <tr>
                                <td
                                  className="cell"
                                  align="right"
                                  style={{ width: "20%" }}
                                >
                                  RedCash Discount
                                </td>
                                <td
                                  className="cell"
                                  align="center"
                                  style={{ width: "20%" }}
                                >
                                  ₹ {redDisc > 0 ? Math.floor(redDisc) : 0}
                                </td>
                              </tr>

                              <tr>
                                <td className="cell" align="right">
                                  <h4 className="totalPrice">Total Price</h4>
                                </td>
                                <td className="cell" align="center">
                                  <h4 className="totalPrice">
                                    ₹{" "}
                                    {redDisc > 0
                                      ? Math.floor(priceI) - Math.floor(redDisc)
                                      : Math.floor(priceI)}
                                  </h4>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Grid>
              </Grid>
              {orgTypeFinder?.length > 0 ? (
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="center"
                  alignItems="center"
                  style={{ margin: "1rem auto" }}
                >
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <h3
                        style={{
                          borderTop: "dotted 1px #cccc",
                          paddingTop: "10px",
                          fontSize: "24px",
                          fontWeight: "bold",
                        }}
                      >
                        CENTER ADDRESS
                      </h3>
                    </Grid>
                    <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                      <Grid item xs={12} md={3}>
                        <Grid item xs={12} style={{ padding: "0" }}>
                          <p>Center Address</p>
                        </Grid>
                        <Grid item xs={12} style={{ padding: "0" }}>
                          <TextField
                            className="input"
                            name="customer_address"
                            type="text"
                            placeholder="Enter address"
                            value={centerAddress}
                            disabled
                            variant="outlined"
                            style={{ margin: "0", width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Grid item xs={12} style={{ padding: "0" }}>
                          <p>Center Longitude</p>
                        </Grid>
                        <Grid item xs={12} style={{ padding: "0" }}>
                          <TextField
                            className="input"
                            name="customer_longitude"
                            type="text"
                            placeholder="Enter Longitude"
                            value={center_longitude}
                            disabled
                            variant="outlined"
                            style={{ margin: "0", width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Grid item xs={12} style={{ padding: "0" }}>
                          <p>Center Latitude</p>
                        </Grid>
                        <Grid item xs={12} style={{ padding: "0" }}>
                          <TextField
                            className="input"
                            name="customer_latitude"
                            type="text"
                            placeholder="Enter Latitude"
                            value={center_latitude}
                            disabled
                            variant="outlined"
                            style={{ margin: "0", width: "100%" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Grid item xs={12} style={{ padding: "0" }}>
                          <p>Booking For</p>
                        </Grid>
                        <Grid item xs={12} style={{ padding: "0" }}>
                          <Select
                            className="input"
                            name="booking_for"
                            variant="outlined"
                            value={bookingFor}
                            style={{ width: "100%", margin: "0" }}
                            onChange={(e) =>
                              setBookingFor(e.target.value as string)
                            }
                          >
                            <MenuItem value="self">Self</MenuItem>
                            <MenuItem value="father_in_law">
                              Father/Father in law
                            </MenuItem>
                            <MenuItem value="mother_in_law">
                              Mother/Mother in law
                            </MenuItem>
                            <MenuItem value="son_in_law">
                              Son/Son in law
                            </MenuItem>
                            <MenuItem value="daughter_in_law">
                              Daughter/Daughter in law
                            </MenuItem>
                            <MenuItem value="spouse">Spouse</MenuItem>
                            <MenuItem value="friend">Friend</MenuItem>
                            <MenuItem value="other">Other</MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}

              <div className={classes.buttonContainer}>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: "1rem", marginBottom: "20px" }}
                  disabled={loading || !selectedSlot || !packages||error!==""}
                >
                  Proceed for booking
                </Button>
              </div>
            </div>
          ) : (
            <>
              <Grid container item xs={12} direction="row" justify="flex-start">
                <Grid item xs={12} md={6}>
                  <TextField
                    className="input"
                    id="search"
                    type="search"
                    value={search}
                    label="Search Lead"
                    variant="outlined"
                    placeholder="Please Type Phone Number or Lead Id"
                    onKeyDown={onKeyDown}
                    onChange={(e) => handleSearch(e)}
                    style={{ margin: "0", padding: "0 9px", width: "80%" }}
                  />
                </Grid>
                {leads.results && leads.results.length === 0
                  ? "Please Search Lead"
                  : ""}
                <Grid item xs={12} md={6}>
                  {leads.results && leads.results.length > 0 && (
                    <Autocomplete
                      id="lead"
                      onChange={(event, newValue) => {
                        let leadIds: any = "";
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          leadIds = obj.id;
                        }
                        setLeadId(leadIds);
                      }}
                      options={leads.results || []}
                      freeSolo
                      blurOnSelect
                      aria-required
                      limitTags={1}
                      getOptionLabel={(option: any) =>
                        `Lead Id : ${option.id} Name : ${option.patient_name}`
                      }
                      disableClearable
                      disableCloseOnSelect
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          label="Select Lead"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                        />
                      )}
                    />
                  )}
                </Grid>
              </Grid>

              <br />
              {leadId !== "" ? (
                <DispositionType
                  tempData={tempData}
                  displayCities={displayCities}
                  leadId={leadId}
                  centreName={centreName}
                  cityNames={cityNames}
                />
              ) : (
                ""
              )}
            </>
          )}

          <Dialog
            open={openLocationModal}
            onClose={() => setOpenLocationModal(false)}
            aria-labelledby="form-dialog-title"
          //  className={classes.dialog}
          >
            <DialogTitle id="form-dialog-title">Add New Address</DialogTitle>
            <DialogContent>
              <Grid
                container
                spacing={2}
                style={{
                  margin: "0px 10px 10px 10px",
                  minWidth: "500px",
                  width: "70%",
                }}
              >
                <Grid item xs={12} md={12}>
                  <p>Locality</p>
                  <Autocomplete
                    id="customer_landmark"
                    key={1}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setMapAddressObject(obj);
                      }
                    }}
                    options={suggestedAddress ? suggestedAddress : []}
                    freeSolo
                    aria-required
                    getOptionLabel={(option: any) =>
                      `${option?.placeName}, ${option?.placeAddress}`
                    }
                    filterOptions={(option: any) => option}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        locationAutoCompleteSearch(newInputValue, event);
                      }, 500);
                    }}
                    // onKeyDown={(e)=>handleKeyDown(e)}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Search Address"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        disabled={collection_date === ""}
                        required
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                                if (e.key === 'Enter') {
                                  e.stopPropagation();
                                }
                          },
                        }}
                      />
                    )}
                  />
                  {/* <p style={{ color: "red" }}>{errorMessage}</p> */}
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>Pin Code</p>
                  <TextField
                    className="input"
                    name="zone"
                    type="text"
                    value={pincode || 0}
                    disabled
                    variant="outlined"
                    style={{ width: "100%", margin: "0", background: "#ccc" }}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>Area</p>
                  <TextField
                    className="input"
                    name="zone"
                    type="text"
                    value={zoneName || ""}
                    disabled
                    variant="outlined"
                    style={{ width: "100%", margin: "0", background: "#ccc" }}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <p>House No./Plot No./Flat No./Door No./Shop/ c/o</p>
                  <TextField
                    className="input"
                    name="zone"
                    type="text"
                    value={customer_address}
                    variant="outlined"
                    style={{ width: "100%", margin: "0" }}
                    required
                    onChange={(e) =>
                      setCustomer_address(e.target.value as string)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>
                    Apartment/Building /Colony/ Block/Sector/
                    Street/Gali/Road/Chawl
                  </p>
                  <TextField
                    className="input"
                    name="zone"
                    type="text"
                    value={addressLine2 || ""}
                    variant="outlined"
                    style={{ width: "100%", margin: "0" }}
                    required
                    onChange={(e) => setAddressLine2(e.target.value as string)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>Landmark/Sublocality</p>
                  <TextField
                    className="input"
                    placeholder="Ex: School/College/Restaurant/Shop/Bank/Government Office"
                    name="zone"
                    type="text"
                    value={favoritePlace || ""}
                    variant="outlined"
                    style={{ width: "100%", margin: "0" }}
                    required
                    onChange={(e) => setFavoritePlace(e.target.value as string)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>City</p>
                  <TextField
                    className="input"
                    name="city"
                    type="text"
                    value={customer_city}
                    variant="outlined"
                    style={{ width: "100%", margin: "0", background: "#ccc" }}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenLocationModal(false)}
                color="primary"
              >
                Select
              </Button>
              <Button
                onClick={() => setOpenLocationModal(false)}
                color="primary"
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </Paper>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  packageList: state.CollectionCenterAdminReducer.packageList,
  pin_code: state.CollectionCenterAdminReducer.pin_code,
  couponList: state.CollectionCenterAdminReducer.couponList,
  createBooking: state.CollectionCenterAdminReducer.createBooking,
  leads: state.CollectionCenterAdminReducer.leads,
  lead_details: state.CollectionCenterAdminReducer.lead_details,
  booking_slots: state.CollectionCenterAdminReducer.booking_slots,
  sameNumberBooking: state.CollectionCenterAdminReducer.sameNumberBooking,
  loading: state.CollectionCenterAdminReducer.loading,
  cities: state.CollectionCenterAdminReducer.cities,
  centerInfo: state.CollectionCenterAdminReducer.centerInfo,
  imagingTimeSlots: state.CollectionCenterAdminReducer.imagingTimeSlots,
  createIMGBooking: state.CollectionCenterAdminReducer.createIMGBooking,
  redCoin: state.CollectionCenterAdminReducer.redCoin,
  subCenter: state.CollectionCenterAdminReducer.subCenter,
  multiplePackage: state.CollectionCenterAdminReducer.multiplePackage,
  displayCities: state.CollectionCenterAdminReducer.displayCities,
  createIMGBookingCustom: state.CollectionCenterAdminReducer.createIMGBookingCustom,
  checkGeoFenceStatus: state.CollectionCenterAdminReducer.checkGeoFenceStatus,
  geoFencesMapMyIndia: state.CollectionCenterAdminReducer.geoFencesMapMyIndia,
  mapmyindiaAccessToken: state.CollectionCenterAdminReducer.mapmyindiaAccessToken,
  partnerList: state.CollectionCenterAdminReducer.partnerList,
  userDetails: state.CollectionCenterAdminReducer.userDetails,
});

export default connect(mapStateToProps, {
  getPackage,
  getCoupons,
  createNewBooking,
  getLeads,
  getLeadDetails,
  resetLeadDetails,
  getAvailableSlots,
  getCities,
  getCenterInfo,
  getImagingTimeslots,
  createImagingBooking,
  getRedCoing,
  getSubCenterInfo,
  getPinCode,
  getMultiplePackage,
  getDisplayCities,
  checkGeoFenceArea,
  checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken,
  createImagingBookingCustom,
  getPartners,
})(NewBooking);