import React, { useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
  fade,
} from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import QuizIcon from '@mui/icons-material/Quiz';
import { logout,panelUserLogout } from "../../actions/loginActions";
import { useDispatch } from "react-redux";
import HomeIcon from "@material-ui/icons/Home";
import { PhonelinkLock, Book, AddBoxTwoTone } from "@material-ui/icons";
import MenuBookIcon from '@material-ui/icons/MenuBook';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import NewUpdateModal from '../Components/Comments/newUpdate';
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import { changePassword } from "../actions/CollectionCenter";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    title: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      color: "#fff",
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "left",
      justifyContent: "flex-start",
      backgroundColor: "#3f51b5",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    titleButtor: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
      color: "#fff",
    },
    dashbord: {
      color: "#fff",
      fontSize: "18px",
      fontWeight: "normal",
      padding: "20px 0px 10px 10px",
    },
    logedUser: {
      padding: "10px 0px 10px 20px",
      fontSize: "14px",
      fontWeight: "bold",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  })
);

export default function CustomerExecutive() {
  let dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  /* Right Menu click */
  const [openRightMenu, setOpenRightMenu] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState();
  const handleClick = (event: any) => {
    setOpenRightMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenRightMenu(false);
  };

  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [heading, setHeading] = React.useState<string>("");
  const [modelFormIndex, setModelFormIndex] = React.useState<number>(0);

  const [changePasswordModal, setchangePasswordModal] = React.useState(false);

  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [anchorElUpdate, setAnchorElUpdate] = React.useState(null);
  const id = open ? 'transitions-popper' : undefined;

  const updatePassword = async () => {
    const body: any = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    await dispatch(changePassword(body));
    setchangePasswordModal(false);
  };

  const handleDrawerOpen = () => {
    setOpen1(true);
  };

  const handleDrawerClose = () => {
    setOpen1(false);
  };
  const handleOpen = (name: string, index: number) => {
    setOpen(true);
    setHeading(name);
    setModelFormIndex(index);
  };
  const  handleLogout= ()=>{
    dispatch(panelUserLogout())
    dispatch(logout())
  }
  const handleUpdateDetails = (event: any) => {
    setAnchorElUpdate(anchorElUpdate ? null : event.currentTarget);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open1,
        })}
      >
        <Toolbar style={{ backgroundColor: "#3f51b5" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open1,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
          Redcliffe Imaging
          </Typography>

          <div>
            <Button
              variant="text"
              onClick={(e) => handleClick(e)}
              size="medium"
              endIcon={<KeyboardArrowDownIcon />}
              style={{ color: "#fff" }}
            >
              Center Name
            </Button>
            <Menu
              id="fade-menu"
              open={openRightMenu}
              onClose={handleClose}
              TransitionComponent={Fade}
              anchorEl={anchorEl}
            >
              <span className={classes.logedUser}>User Name</span>
              <Divider />
              <MenuItem onClick={() => setchangePasswordModal(true)}>Change Password</MenuItem>
              <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
            </Menu>
            <IconButton>
          <DeveloperModeIcon aria-describedby={id} onClick={handleUpdateDetails} />
        </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open1,
          [classes.drawerClose]: !open1,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open1,
            [classes.drawerClose]: !open1,
          }),
        }}
      >
        <Dialog
          open={changePasswordModal}
          // onClose={() => setchangePasswordModal(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
          <DialogContent>
            <TextField
              id="name"
              placeholder="Old Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="New Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="Confirm Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => updatePassword()} color="primary">
              Update
            </Button>
            {/* <Button
              onClick={() => setchangePasswordModal(false)}
              color="primary"
            >
              Cancel
            </Button> */}
          </DialogActions>
        </Dialog>
        <div className={classes.toolbar}>
          <span className={classes.dashbord}>Dashboard</span>
          <IconButton onClick={handleDrawerClose} style={{ color: "#fff" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to="/dashboard/cc">
            <ListItem button>
              <Tooltip title="Home" arrow>
                <ListItemIcon>
                  <HomeIcon style={{ color: "#3f51b5" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Home" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cc/helpdesk">
            <ListItem button>
              <Tooltip title="Help Desk" arrow>
                <ListItemIcon>
                  <QuizIcon style={{ color: "#3f51b5" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Help Desk" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cc/booking">
            <ListItem button>
              <Tooltip title="New Bookings" arrow>
                <ListItemIcon>
                  <AddBoxTwoTone style={{ color: "#3f51b5" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="New Bookings" />
            </ListItem>
          </Link>
        </List>
        {/* <List>
          <Link to="/dashboard/cc/package-list">
            <ListItem button>
              <Tooltip title="Package Lists" arrow>
                <ListItemIcon>
                  <DirectionsBoatIcon style={{ color: "#3f51b5" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Package List" />
            </ListItem>
          </Link>
        </List>
         <Link to="/dashboard/cc/ledger-details">
            <ListItem button>
              <Tooltip title="Collection Center Ledger" arrow>
                <ListItemIcon>
                  <MenuBookIcon style={{ color: "#3f51b5" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Collection Center Ledger" />
            </ListItem>
          </Link> */}
          
        <Divider />
        <List>
          <ListItem button onClick={() => handleLogout()}>
            <Tooltip title="Logout" arrow>
              <ListItemIcon>
                <ExitToAppIcon style={{ color: "#3f51b5" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <NewUpdateModal
        anchorElUpdate={anchorElUpdate}
        setAnchorElUpdate={setAnchorElUpdate}
        id={id}
      />
    </div>
  );
}
