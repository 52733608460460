import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {
  uploadBulkPackage,
  getImagingTestList,
  getSubCenterInfo,
  getCities,
} from "../../actions/ImagingAdminAction";
import ExportTest from "./exportTestList";
import { Container, Box, Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExportFormat from "./exportTestList";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
  })
);

interface Props {
  loading: boolean;
  uploadBulkPackage: any;
  getImagingTestList: any;
  imagingTestList: any;
  getSubCenterInfo: any;
  subCenterInfor: any;
  getCities: any;
  cities: any;
  // coustomFileUpload: Function;
}

const CustomUploader: React.FC<Props> = ({
  loading,
  uploadBulkPackage,
  getImagingTestList,
  imagingTestList,
  getSubCenterInfo,
  subCenterInfor,
  getCities,
  cities,
}) => {
  const classes = useStyles();
  const [file, setFile] = React.useState<any>([]);
  const [file_name, setFileName] = React.useState<string>("");
  const [pincodeTxt, setPincodeTxt] = useState<any>("");
  const [usertype, setUserType] = React.useState<string>("none");
  const [source, setSource] = React.useState<string>("none");
  const [error, setError] = React.useState<string>("");
  const [buttonState, setButtonState] = useState<boolean>(false);
  const [centerId, setCenterId] = useState<any>("");
  const timer = useRef<any>(0);
  const [city, setCity] = useState<any>("");
  const handleFileChange = (e: React.ChangeEvent<{ files: any }>) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };
  useEffect(() => {
    if(pincodeTxt.length===6){
      getSubCenterInfo(`?imaging=true&pincode=${pincodeTxt}`)
    }
  }, [pincodeTxt])
  useEffect(() => {
    getImagingTestList();
    getSubCenterInfo(`?imaging=true`);
  }, []);
  const submitFile = async () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("center_address", centerId);
    await uploadBulkPackage(formData);
    setButtonState(true);
    setTimeout(() => {
      setButtonState(false);
    }, 1000 * 60 * 10);
  };

  return (
    <main className={classes.content}>
      <Grid
        container
        spacing={1}
        direction="row"
        style={{ alignItems: "right" }}
      >
        <Grid item xs={12} sm={6} md={6}>
          <p>STEP 1: Download Test File and Update All Details</p>
          <ExportFormat imagingTestList={imagingTestList} loading={loading} />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Grid
            container
            spacing={1}
            direction="row"
            style={{ alignItems: "right" }}
          ></Grid>
          <br />
          <p>STEP 2:Select Center</p>
          <div className="file-uploader">
            <Grid
              container
              spacing={1}
              direction="row"
              style={{ alignItems: "right" }}
            >
              <Grid item xs={3}>
            <TextField
              className="input"
              label="Pincode"
              variant="outlined"
              fullWidth
              onChange={(e) =>
                setPincodeTxt(
                  String(e.target.value.replace(/[^0-9]/g, "")).trimStart()
                    .length <=6
                    ? String(e.target.value.replace(/[^0-9]/g, "")).trimStart()
                    : pincodeTxt
                )
              }
            />
          </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  id="city"
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setCity(obj.id);
                      getSubCenterInfo(`?imaging=true&pincode=${pincodeTxt.length===6?pincodeTxt:""}&city=${obj.id}`);
                    }
                  }}
                  options={cities}
                  freeSolo
                  blurOnSelect
                  aria-required
                  getOptionLabel={(option: any) => option.name}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getCities(newInputValue);
                      if (newInputValue.length === 0) {
                        setCity("");
                      }
                    }, 1000);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className="input"
                      label="City"
                      variant="outlined"
                      style={{ width: "100%", marginBottom: "1rem" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  id="center"
                  key={city}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setCenterId(obj.id);
                    }
                  }}
                  options={subCenterInfor.length > 0 ? subCenterInfor : []}
                  freeSolo
                  blurOnSelect
                  aria-required
                  loading={loading}
                  getOptionLabel={(option: any) =>
                    `Center:${option?.center?.name},Address:${option?.address}`
                  }
                  disableClearable
                  disableCloseOnSelect
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      getSubCenterInfo(`?imaging=true&pincode=${pincodeTxt.length===6?pincodeTxt:""}&city=${city}&name=${newInputValue}`);
                    }, 1000);
                    if (newInputValue.length === 0) {
                      setCenterId("");
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      label="Center Name"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <br />
            <p>STEP 3:Browse Updated File</p>
            <Grid
              container
              spacing={1}
              direction="row"
              style={{ alignItems: "right" }}
            >
              <Grid item xs={12} sm={12} md={12}>
                <Button
                  variant="contained"
                  component="label"
                  color="secondary"
                  fullWidth
                  disabled={centerId === ""}
                >
                  Browse File
                  <input
                    type="file"
                    name="file"
                    id="file"
                    hidden
                    onChange={handleFileChange}
                  />
                </Button>
              </Grid>
              <br />
              {file_name !== "" ? `File Name:${file_name}` : ""}
            </Grid>
            <br />
            <p>STEP 4:Upload </p>
            <Grid item xs={12} sm={12} md={12}>
              <Button
                variant="contained"
                component="label"
                fullWidth
                style={{
                  backgroundColor: "#3f51b5",
                  color: "#fff",
                }}
                onClick={submitFile}
                disabled={centerId === ""}
              >
                Upload
              </Button>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </main>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.ImagingAdminReducer.loading,
  imagingTestList: state.ImagingAdminReducer.imagingTestList,
  subCenterInfor: state.ImagingAdminReducer.subCenterInfor,
  cities: state.ImagingAdminReducer.cities,
});

export default connect(mapStateToProps, {
  uploadBulkPackage,
  getImagingTestList,
  getSubCenterInfo,
  getCities,
})(CustomUploader);