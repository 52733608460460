import React from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, } from '@material-ui/data-grid';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
    })
);

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

interface Props {
    all_lead: any;
    loading: boolean;
}

const LeadsExport: React.FC<Props> = ({
    all_lead,
    loading
}) => {
    const classes = useStyles();

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 100 },
        { field: 'count', headerName: 'Count', width: 150 },
        { field: 'createdDate', headerName: 'Created Date', width: 200 },
        { field: 'createdTime', headerName: 'Created Time', width: 200 },
        { field: 'call', headerName: 'Call', width: 200 },
        { field: 'call_status', headerName: 'Call Status', width: 200 },
        { field: 'calling_gender', headerName: 'Calling Gender', width: 200 },
        { field: 'content_number', headerName: 'Contact Number', width: 200 },
        { field: 'followup_date', headerName: 'Follow Up Date', width: 200 },
        { field: 'followup_time', headerName: 'Follow Up Time', width: 200 },
        { field: 'language', headerName: 'Language', width: 200 },
        { field: 'lead_source', headerName: 'Lead Source', width: 200 },
        { field: 'panel_user', headerName: 'Agent', width: 200 },
        { field: 'patient_name', headerName: 'patient Name', width: 200 },
        { field: 'patient_age', headerName: 'patient Age', width: 200 },
        { field: 'patient_area', headerName: 'patient Area', width: 200 },
        { field: 'patient_city', headerName: 'patient City', width: 200 },
        { field: 'priority', headerName: 'Priority', width: 200 },
        { field: 'whatsapp_contact', headerName: 'Whatsapp Contact', width: 200 },
    ];

    let rows = []

    if (all_lead.results) {
        rows = all_lead.results.length > 0 && all_lead.results.map((lead: any) => {
            return {
                ...lead,
                id:lead.pk||lead.id,
                createdDate: lead.created_at && new Date(lead.created_at).toLocaleDateString(),
                createdTime: lead.created_at && new Date(lead.created_at).toLocaleTimeString(),
                patient_age:lead.patient_age,
                patient_area:lead.patient_area&&lead.patient_area.area,
                patient_city:lead.patient_city,
            }
        })
    }

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Paper elevation={15} className={classes.paper}>
                <div style={{ height: 700 }}>
                    <DataGrid rowsPerPageOptions={[]}
                        rows={rows}
                        columns={columns}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        loading={loading}
                        pageSize={50}
                        rowCount={all_lead.count || 0}
                    />
                </div>
            </Paper>
        </main>
    )
}

const mapStateToProps = (state: any) => ({

})

export default connect(mapStateToProps, {

})(LeadsExport);