import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import clsx from "clsx";
import { Link } from "react-router-dom";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
  fade,
} from "@material-ui/core/styles";
import Snackbar from "@material-ui/core/Snackbar";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Popover from "@material-ui/core/Popover";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import List from "@material-ui/core/List";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {
  logout,
  panelUserLogout,
  getFirebaseNotification,
} from "../../actions/loginActions";
import { useDispatch } from "react-redux";
import HomeIcon from "@material-ui/icons/Home";
import {
  PhonelinkLock,
  Book,
  AddBoxTwoTone,
  SettingsApplicationsRounded,
  ConfirmationNumberRounded
} from "@material-ui/icons";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import DirectionsBoatIcon from "@material-ui/icons/DirectionsBoat";
import NewUpdateModal from "../Components/Comments/newUpdate";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import QuizIcon from "@mui/icons-material/Quiz";
import RateReviewIcon from "@material-ui/icons/RateReview";
import { getToken, onMessageListener } from "../../firebase";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Button } from "@material-ui/core";
import {
  changePassword,
  getNotificationMessage,
} from "../actions/CollectionCenterAdmin";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    title: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    popover: {
      pointerEvents: "none",
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      color: "#fff",
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    paper: {
      padding: theme.spacing(1),
    },
    toolbar: {
      display: "flex",
      alignItems: "left",
      justifyContent: "flex-start",
      backgroundColor: "#3f51b5",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    titleButtor: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
      color: "#fff",
    },
    dashbord: {
      color: "#fff",
      fontSize: "18px",
      fontWeight: "normal",
      padding: "20px 0px 10px 10px",
    },
    logedUser: {
      padding: "10px 0px 10px 20px",
      fontSize: "14px",
      fontWeight: "bold",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  })
);

interface Props {
  getNotificationMessage: any;
  notificationMessage: any;
  passwordStatus: boolean;
}
const PhleboAdmin: React.FC<Props> = ({
  getNotificationMessage,
  notificationMessage,
  passwordStatus,
}) => {
  let dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  /* Right Menu click */
  const [openRightMenu, setOpenRightMenu] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handleClick = (event: any) => {
    setOpenRightMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenRightMenu(false);
  };

  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [heading, setHeading] = React.useState<string>("");
  const [modelFormIndex, setModelFormIndex] = React.useState<number>(0);

  const [changePasswordModal, setchangePasswordModal] = React.useState(false);

  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [anchorElUpdate, setAnchorElUpdate] = React.useState(null);
  const id = open ? "transitions-popper" : undefined;
  const audio = new Audio(
    "https://static.redcliffelabs.com/media/mixkit-modern-classic-door-bell-sound-113.mp3"
  );
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [openSnack, setOpenSnack] = useState<boolean>(false);
  const [isTokenFound, setTokenFound] = useState(false);
  const [show, setShow] = useState(false);
  const openPopover = Boolean(anchorEl);
  useEffect(() => {
    let token;

    async function tokenFunc() {
      token = await getToken(setTokenFound);
      if (token) {
        const body = {
          token,
        };
        dispatch(getFirebaseNotification(body));
      }
      return token;
    }

    tokenFunc();
  }, [setTokenFound]);

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log("failed: ", err));

  const updatePassword = async () => {
    const body: any = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    await dispatch(changePassword(body));
    setchangePasswordModal(false);
  };

  const handleDrawerOpen = () => {
    setOpen1(true);
  };

  const handleDrawerClose = () => {
    setOpen1(false);
  };
  const handleOpen = (name: string, index: number) => {
    setOpen(true);
    setHeading(name);
    setModelFormIndex(index);
  };
  const handleLogout = () => {
    dispatch(panelUserLogout());
    dispatch(logout());
  };
  const handleUpdateDetails = (event: any) => {
    setAnchorElUpdate(anchorElUpdate ? null : event.currentTarget);
  };
  const handleSnackClose = () => {
    setOpenSnack(false);
  };
  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    // setAnchorEl(event.currentTarget);
    // getNotificationMessage(`?is_read=false`)
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleNotification = () => {
    history.push("/dashboard/cca/inbox");
  };
  return (
    <div className={classes.root}>
      <Snackbar
        // anchorOrigin={{ vertical, horizontal }}
        open={openSnack}
        onClose={handleSnackClose}
        message={notification.body}
      />
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open1,
        })}
      >
        <Toolbar style={{ backgroundColor: "#3f51b5" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open1,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Redcliffe Imaging
          </Typography>

          <div>
            <Button
              variant="text"
              onClick={(e) => handleClick(e)}
              size="medium"
              endIcon={<KeyboardArrowDownIcon />}
              style={{ color: "#fff" }}
            >
              Center Name
            </Button>
            <Menu
              id="fade-menu"
              open={openRightMenu}
              onClose={handleClose}
              TransitionComponent={Fade}
              anchorEl={anchorEl}
            >
              <span className={classes.logedUser}>User Name</span>
              <Divider />
              <MenuItem onClick={() => setchangePasswordModal(true)}>
                Change Password
              </MenuItem>
              <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
            </Menu>
            {/* <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-owns={openPopover ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              onClick={handleNotification}
              color="inherit"
            >
              <NotificationsActiveIcon />
            </IconButton> */}
            <IconButton>
              <DeveloperModeIcon
                aria-describedby={id}
                onClick={handleUpdateDetails}
              />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open1,
          [classes.drawerClose]: !open1,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open1,
            [classes.drawerClose]: !open1,
          }),
        }}
      >
        <Dialog
          open={changePasswordModal}
          // onClose={() => setchangePasswordModal(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
          <DialogContent>
            <TextField
              id="name"
              placeholder="Old Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="New Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="Confirm Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => updatePassword()} color="primary">
              Update
            </Button>
            {/* <Button
              onClick={() => setchangePasswordModal(false)}
              color="primary"
            >
              Cancel
            </Button> */}
          </DialogActions>
        </Dialog>
        <div className={classes.toolbar}>
          <span className={classes.dashbord}>Dashboard</span>
          <IconButton onClick={handleDrawerClose} style={{ color: "#fff" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to="/dashboard/cca">
            <ListItem button>
              <Tooltip title="Home" arrow>
                <ListItemIcon>
                  <HomeIcon style={{ color: "#3f51b5" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Home" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cca/booking/tickets">
            <ListItem button>
              <Tooltip title="Booking Tickets" arrow>
                <ListItemIcon>
                  <ConfirmationNumberRounded style={{ color: "#3f51b5" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Booking Tickets" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cca/booking/review">
            <ListItem button>
              <Tooltip title="Customer Review" arrow>
                <ListItemIcon>
                  <RateReviewIcon style={{ color: "#3f51b5" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Customer Review" />
            </ListItem>
          </Link>
        </List>
         
        <List>
          <Link to="/dashboard/cca/helpdesk">
            <ListItem button>
              <Tooltip title="Help Desk" arrow>
                <ListItemIcon>
                  <QuizIcon style={{ color: "#3f51b5" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Help Desk" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/cca/booking">
            <ListItem button>
              <Tooltip title="New Bookings" arrow>
                <ListItemIcon>
                  <AddBoxTwoTone style={{ color: "#3f51b5" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="New Bookings" />
            </ListItem>
          </Link>
        </List>
       
        {/* <List>
          <Link to="/dashboard/cca/users">
            <ListItem button>
              <Tooltip title="Manage Users" arrow>
                <ListItemIcon>
                  <SettingsApplicationsRounded style={{ color: "#3f51b5" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Manage Users" />
            </ListItem>
          </Link>
        </List> */}
        {/* <List>
          <Link to="/dashboard/cca/package-list">
            <ListItem button>
              <Tooltip title="Package Lists" arrow>
                <ListItemIcon>
                  <DirectionsBoatIcon style={{ color: "#3f51b5" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Package List" />
            </ListItem>
          </Link>
        </List> */}
        {/* <Link to="/dashboard/cca/ledger-details">
            <ListItem button>
              <Tooltip title="Collection Center Ledger" arrow>
                <ListItemIcon>
                  <MenuBookIcon style={{ color: "#3f51b5" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Collection Center Ledger" />
            </ListItem>
          </Link> */}
      
        <Divider />
        <List>
          <ListItem button onClick={() => handleLogout()}>
            <Tooltip title="Logout" arrow>
              <ListItemIcon>
                <ExitToAppIcon style={{ color: "#3f51b5" }} />
              </ListItemIcon>
            </Tooltip>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      {notificationMessage &&
      notificationMessage?.results &&
      notificationMessage?.results?.length > 0
        ? notificationMessage?.results.map((data: any, index: any) => {
            return (
              <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                  paper: classes.paper,
                }}
                open={openPopover}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography>{index + 1 + "." + data?.notification}</Typography>
              </Popover>
            );
          })
        : null}
      <NewUpdateModal
        anchorElUpdate={anchorElUpdate}
        setAnchorElUpdate={setAnchorElUpdate}
        id={id}
      />
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  notificationMessage: state.CollectionCenterAdminReducer.notificationMessage,
  passwordStatus: state.loginReducer.passwordStatus,
});

export default connect(mapStateToProps, {
  getNotificationMessage,
})(PhleboAdmin);
