import React from "react";
import { Grid } from "@material-ui/core";
import "./index.sass";
import { useEffect } from "react";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { Tooltip } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { connect } from "react-redux";
import Loader from "../../components/Loader2";
import Switch from '@mui/material/Switch';
import moment from "moment";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Link } from "react-router-dom";
import { Alert, AlertTitle } from "@material-ui/lab";
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 21,
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
  })
);

interface Props {
  userStats: any;
  loading: any;
  redCoin: any
}

const CallsStatsPage: React.FC<Props> = ({ userStats, loading, redCoin }) => {
  const [checked, setChecked] = React.useState(true);
  const classes = useStyles();
  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };
  useEffect(() => {
    if (redCoin && redCoin?.uniquecustomer && redCoin?.uniquecustomer.length > 0) {
      setChecked(true)
    }
    else {
      setChecked(false)
    }
  }, [])
  return (
    <>
      <main className={classes.content}>
        <TableContainer
          component={Paper}
          style={{ minHeight: "300px", width: "100%" }}
        >
          {loading ? (
            <Loader />
          ) : (
            <Table
              stickyHeader
              aria-label="simple table"
              style={{ width: "100%" }}
            >
              {checked ? (
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>Action</StyledTableCell>
                    <StyledTableCell>UHID</StyledTableCell>
                    <StyledTableCell>Lead</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Age</StyledTableCell>
                  </StyledTableRow>
                </TableHead>
              ) : (
                <TableHead>
                  <StyledTableRow>
                    <StyledTableCell>{userStats?.username}</StyledTableCell>
                    <StyledTableCell></StyledTableCell>
                  </StyledTableRow>
                </TableHead>
              )}
              {checked ? (
                <TableBody>
                  {redCoin && redCoin?.uniquecustomer && redCoin?.uniquecustomer.length > 0 && redCoin?.uniquecustomer.map((data: any) => {
                    return (
                      <StyledTableRow>
                        <StyledTableCell>
                          <Link
                         to={{pathname:"/dashboard/ce/booking",state:data}}
                         target="_blank">
                            <AddCircleIcon />
                          </Link>
                          {/* <Link
                          to="/dashboard/ce/booking"
                          target="_blank">
                            <AddCircleIcon />
                          </Link> */}
                        </StyledTableCell>
                        <StyledTableCell>{data?.id}</StyledTableCell>
                        <StyledTableCell>{data?.lead}</StyledTableCell>
                        <StyledTableCell>{data?.display_name}</StyledTableCell>
                        <StyledTableCell>{data?.age}</StyledTableCell>
                      </StyledTableRow>
                    )
                  })}

                </TableBody>
              ) : (
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>Login Time</StyledTableCell>
                    <StyledTableCell>
                      {/* {moment(userStats?.login_time).format(
                        "DD-MM-YYYY, hh:mm A"
                      )} */}
                        Date: {userStats?.login_time?.substring(0, 10)}{" "}
                     Time :{userStats?.login_time?.substring(11, 19)}{" "}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>Login Hour</StyledTableCell>

                    <StyledTableCell>
                      {/* {userStats?.hours_since_login} */}
                      {parseFloat(userStats?.hours_since_login).toFixed(2)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>Break Time</StyledTableCell>

                    <StyledTableCell>
                      {/* {userStats?.total_break_time} */}
                      {parseFloat(userStats?.total_break_time).toFixed(2)}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>Idle Time</StyledTableCell>

                    <StyledTableCell>
                    {parseFloat(userStats?.total_ideal_time).toFixed(2)}
                      {/* {userStats?.total_ideal_time} */}
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>Talk Time</StyledTableCell>

                    <StyledTableCell>{userStats?.total_talktime}</StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              )}

            </Table>
          )}
          <Tooltip title={checked ? "Status" : "Booking"} arrow>
            <Switch
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Tooltip>
        </TableContainer>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.BookingReducer.loading,
});

export default connect(mapStateToProps, {})(CallsStatsPage);
