import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import LeadsTable from '../tables/leads'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        card: {
            height: "100%",
            padding: theme.spacing(1),
        },
        card_grid: {
            justifyContent: 'space-between'
        },
    })
);

interface Props {
    loading: Boolean;
}

const LeadsPage: React.FC<Props> = ({
    loading,
}) => {
    const classes = useStyles();
    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <LeadsTable />
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.SalesLeadAdminReducer.loading,
})

export default connect(mapStateToProps, {   
})(LeadsPage);
