import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
    withStyles,
    Theme,
    createStyles,
    makeStyles,
} from "@material-ui/core/styles";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Grid, TextField } from '@material-ui/core'
import Table from "@material-ui/core/Table";
import Button from '@mui/material/Button';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import MessageIcon from "@material-ui/icons/Message";
import CallIcon from "@material-ui/icons/Call";
import {
    getImagingRaiseTicket,
    getImagingTicketCount,
    clickToCall,
    getLeadSource,
    getTopRCFreshLeads,
    getCities,
    getPrescriptionData,
    getImagingTicket,
    getAssignAgentList,
    transferImagingTickets
} from "../actions/salesLeadAdminAction";
import { TableFooter, TablePagination } from "@material-ui/core";
import Loader from "../../CustomerLead-Panel/components/loader";
import CommentsModal from "../Components/comments/comment";
import CommentsModal2 from "../Components/comments/Comment2";
import { generateLeadsUrl } from "../../helpers/generateUrl";
import RaiseComplaintModal from "../Components/comments/raiseTicketModal";
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import Fade from '@material-ui/core/Fade';
import Modal from '@mui/material/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { CALL_DISABLE_TIME } from '../../CallTimeDisable'

const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14, fontWeight: 600
        },

    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const useStyles = makeStyles({
    table: {
        minWidth: 700,
    },
    tableContainer: {
        marginTop: "1rem",
        position: "sticky",
        top: "200px",
    },
    content: {
        width: "80%",
        flexGrow: 21,
        //   padding: theme.spacing(3),
    },
    paper: {
        marginTop: "2rem",
        padding: "0.5rem 2rem",
        width: "100%",
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
});

interface Props {
    getTopRCFreshLeads: Function;
    topRcFreshLeads: any;
    lead_source: any;
    loading: boolean;
    // resendSms: Function;
    clickToCall: Function;
    getLeadSource: any;
    getCities: any;
    cities: any;
    getPrescriptionData: any;
    prescription: any;
    getImagingRaiseTicket: any;
    imaginraiseTicket: any;
    getImagingTicketCount: any;
    imagingTicketcount: any;
    getImagingTicket: any;
    getAssignAgentList: any;
    assigneUserList: any;
    transferImagingTickets: any
}

const LeadsTable: React.FC<Props> = ({
    getTopRCFreshLeads,
    topRcFreshLeads,
    clickToCall,
    getLeadSource,
    lead_source,
    getCities,
    cities,
    loading,
    getPrescriptionData,
    prescription,
    getImagingRaiseTicket,
    imaginraiseTicket,
    getImagingTicket,
    getImagingTicketCount,
    imagingTicketcount,
    getAssignAgentList,
    assigneUserList,
    transferImagingTickets
}) => {
    const classes = useStyles();
    const [open2, setOpen2] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false);
    const [raiseComplaint, setRaiseComplaint] = useState(false)

    const [leadId, setLeadId] = useState<number>(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(
        50 || topRcFreshLeads.results.count
    );
    const [prscriptionLead, setPrescriptionLead] = useState<any>("")
    const [pLink, setPlink] = useState<any>("")
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openPrescription = Boolean(anchorEl);
    const [fileType, setFileType] = useState<any>("")
    const [openPdf, setOpenPdf] = useState<boolean>(false)
    const history = useHistory()
    const [callButton, setCallButton] = useState<boolean>(true)
    const [activeButton, setActiveButton] = useState<any>("pending")
    const [tableStatus, setTableStatus] = useState<any>("pending")
    const [id, setId] = useState<string>("");
    const [panel_user, setPanelUser] = React.useState<number | null>(null);
    const timer = useRef<any>(0)


    const [selectedTicket, setSelectedTicket] = useState<any>([]);

    const CATEGORY: any = [
        {
            id: 1,
            text: "Interested/Follow up",
            city: true,
            leadId: false
        },
        {
            id: 2,
            text: "Not Interested",
            city: true,
            query: false,
        },
        {
            id: 3,
            text: "Order Booked",
            city: true,
            query: false,
        },
        {
            id: 4,
            text: "Ringing",
            city: false,
            query: false,
        },
        {
            id: 5,
            text: "Number Doesn't Exist",
            city: false,
            query: false,
        },
        {
            id: 6,
            text: "By Mistake & Wrong Number",
            city: false,
            query: false,
        },
        {
            id: 7,
            text: "Call Back",
            city: true,
            query: false,
        },
        {
            id: 8,
            text: "Business Realated Call",
            city: true,
            query: false,
        },
        {
            id: 9,
            text: "Spam Call",
            city: false,
            query: false,
        },
        {
            id: 10,
            text: "Enquiry Call",
            city: true,
            query: false,
        },
        {
            id: 11,
            text: "CFresh",
            city: false,
            query: false,
        },
        {
            id: 12,
            text: "Test Not Available",
            city: true,
            query: false,
        },
        {
            id: 13,
            text: "Subscribed to Competitor",
            city: true,
            query: false,
        },
        {
            id: 14,
            text: "Slot Issue",
            city: true,
            query: false,
        },
        {
            id: 15,
            text: "Same Day Slot",
            city: true,
            query: false,
        },
        {
            id: 16,
            text: "Already Booked",
            city: false,
            query: false,
        },
        {
            id: 17,
            text: "Not Contactable",
            city: false,
            query: false,
        },
        {
            id: 18,
            text: "Out of Station",
            city: true,
            query: false,
        },
        {
            id: 19,
            text: "Out of Service Area",
            city: true,
            query: false,
        },
        {
            id: 20,
            text: "Need Time to Think",
            city: true,
            query: false,
        },
        {
            id: 21,
            text: "Email/Whatsapp Package",
            city: true,
            leadId: false
        },
        {
            id: 22,
            text: "Discount/Price Enquiry",
            city: true,
            leadId: false
        },
        {
            id: 23,
            text: "CS issue",
            city: false,
            leadId: true
        },
        {
            id: 24,
            text: "Call Not Reachable",
            city: false,
            leadId: false
        },
        {
            id: 25,
            text: "Call Later",
            city: true,
            leadId: false
        },
        {
            id: 26,
            text: "Call drop",
            city: true,
            leadId: false
        },
        {
            id: 27,
            text: "Call Disconnected",
            city: true,
            leadId: false
        },
        {
            id: 28,
            text: "Below <5 year patient",
            city: true,
            leadId: false
        },
        {
            id: 29,
            text: "DND",
            city: false,
            leadId: false
        },
        {
            id: 30,
            text: "Phlebo's Issue",
            city: false,
            leadId: true
        },
        {
            id: 31,
            text: "Reports Regarding Issue",
            city: false,
            leadId: true
        },
        {
            id: 32,
            text: "Health Advice",
            city: false,
            leadId: true
        },
        {
            id: 33,
            text: "Health Consultation",
            city: false,
            leadId: true
        },
        {
            id: 34,
            text: "Health Consultation",
            city: false,
            leadId: false
        },
        {
            id: 35,
            text: "Language Barrier",
            city: false,
            leadId: false
        },
        {
            id: 36,
            text: "RCFresh",
            city: false,
            leadId: false
        },
        {
            id: 37,
            text: "HCbooking",
            city: false,
            leadId: false
        },
    ]

    const priorityArr: any = [
        {
            value: "SuperCritical",
        },
        {
            value: "Urgent",
        },
        {
            value: "HopeFull",
        },
        {
            value: "Low",
        }
    ]

    useEffect(() => {
        let url = ``;
        getTopRCFreshLeads("?multiple_category=36&latest_size=10&latest=true");
        getLeadSource()
        getImagingRaiseTicket()
        getCities()
    }, []);
    useEffect(() => {
        getAssignAgentList(`?usergroup=CustomerExecutive`)
        getImagingTicketCount()
    }, []);

    useEffect(() => {
        if (open2 === false) {
            setLeadId(0)
        }
    }, [open2])

    const handleClick = (id: number) => {
        setOpen(true);
        setLeadId(id);
    };

    const handleModal = (id: number) => {
        setOpen2(true);
        setLeadId(id);
    };

    const filterLeads = () => {
        const body: any = {
            lead: id,
        };
        const url = generateLeadsUrl(body).substring(2);
        getImagingRaiseTicket(`&${url}`)
        setPage(0);
    };
    const handleCallClick = (id: number, type: string) => {
        setLeadId(id);
        setCallButton(false)
        clickToCall(id, type);
        setTimeout(() => {
            setCallButton(true)
        }, CALL_DISABLE_TIME)
    };

    const handleRaiseTicket = (id: number) => {
        setRaiseComplaint(true);
        setLeadId(id);
    };

    useEffect(() => {
        if (prscriptionLead !== "") {
            getPrescriptionData(`?lead=${prscriptionLead}`)
        }
    }, [prscriptionLead])

    const handlePrescriptionClick = (id: any, event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setPrescriptionLead(id)
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleFrameView = (data: any) => {

        if (data.base64file !== null && data.image === "") {
            setFileType("b64")
            setPlink(`data:application/pdf;base64,${data.base64file}`)
        }
        else if (data.base64file === null && data.image !== "") {
            let pieces: any = data.image.split('.').pop()
            const last: any = pieces
            setFileType(last)
            setPlink(data.image)
        }
        setOpenPdf(true)
    }
    const handlePdfModelClose = () => {
        setOpenPdf(false)
    }


    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = imaginraiseTicket.links && imaginraiseTicket.links.next.split("?")[1];
            getImagingRaiseTicket("imaging_ticket", activeButton === "" ? url : `${activeButton}&${url}`);
        } else if (newPage < page) {
            let url = imaginraiseTicket.links && imaginraiseTicket.links.previous.split("?")[1];
            getImagingRaiseTicket("imaging_ticket", activeButton === "" ? url : `${activeButton}&${url}`);
        }
        setPage(newPage as number);
    };

    useEffect(() => {
        getImagingTicket(`?status=${tableStatus}`)
    }, [tableStatus])


    const handlePending = () => {
        setActiveButton("pending")
        setTableStatus("pending")
    }
    const handleConfirm = () => {
        setActiveButton("confirm")
        setTableStatus("confirmed")


    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };


    const transferBooking = async () => {
        let body = {
            ids: selectedTicket.join(", "),
            panel_user

        };
        await transferImagingTickets(body);
        setSelectedTicket([])
        getAssignAgentList(`?usergroup=CustomerExecutive`)
        getImagingTicket(`?status=${tableStatus}`)
    };


    return (
        <>
            <main className={classes.content}>
                <div className="data-table" style={{ width: "100%", marginTop: "4rem" }}>
                    <h2>Imaging Tickets</h2>
                    <Grid container spacing={1} direction="row" style={{ alignItems: "left" }}>
                        <Grid item xs={12} sm={4} md={2}>
                            <TextField
                                name="leadId"
                                type="number"
                                value={id}
                                className="input"
                                variant="outlined"
                                label="Lead Id"
                                onChange={(e) => setId(e.target.value as string)}
                                style={{ width: "100%" }}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                onClick={filterLeads}
                            >
                                Filter
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => history.push("/dashboard/sla/booking/imaging-ticket")}
                            >
                                Reset
                            </Button>
                        </Grid>
                    </Grid>
                    {selectedTicket.length > 0 && (
                        <React.Fragment>
                            <h2 style={{ textAlign: "center", marginTop: "2rem" }}>
                                Transfer To:
                            </h2>
                            <Grid
                                container
                                spacing={3}
                                direction="row"
                                alignItems="center"
                                justify="center"
                            >
                                <Grid item xs={12} sm={4} md={2}>
                                    <Autocomplete
                                        id="agentname"
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                                setPanelUser(obj.id);
                                            }
                                        }}
                                        options={assigneUserList.results || []}
                                        freeSolo
                                        blurOnSelect
                                        aria-required
                                        getOptionLabel={(option: any) =>
                                            option?.id && option?.username
                                        }
                                        onInputChange={(event, newInputValue) => {
                                            clearTimeout(timer.current);
                                            timer.current = setTimeout(() => {
                                                getAssignAgentList(
                                                    `?code=${newInputValue.replace(" ", "")}&usergroup=CustomerExecutive`
                                                );
                                            }, 1000);
                                            if (newInputValue.length === 0) {
                                                setPanelUser(0);
                                            }
                                        }}
                                        renderInput={(params: any) => (
                                            <TextField
                                                className="input"
                                                {...params}
                                                label="Agent username"
                                                variant="outlined"
                                                style={{ width: "100%", margin: "0", padding: "0" }}
                                                required
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4} md={2}>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        fullWidth
                                        onClick={transferBooking}
                                    >
                                        Transfer
                                    </Button>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}
                    <Grid container spacing={2} direction="row" style={{ alignItems: "left", marginTop: "4px" }}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Button
                                variant={activeButton == "pending" ? "contained" : "outlined"}
                                fullWidth
                                color="primary"
                                onClick={() => handlePending()}
                            >
                                Pending
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Button
                                variant={activeButton == "confirm" ? "contained" : "outlined"}
                                fullWidth
                                color="primary"
                                onClick={() => handleConfirm()}
                            >
                                Confirm
                            </Button>
                        </Grid>
                    </Grid>
                    <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "580px" }}>
                        {loading ? <Loader /> : (
                            <Table stickyHeader aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align="center">
                                            <input
                                                type="checkbox"
                                                className="input"
                                                name="selectall"
                                                id="selectall"
                                                onChange={(e) =>
                                                    e.target.checked
                                                        ? setSelectedTicket(
                                                            imaginraiseTicket.results &&
                                                            imaginraiseTicket.results.map((bData: any) => {
                                                                return bData?.id && bData?.id;
                                                            })
                                                        )
                                                        : setSelectedTicket([])
                                                }
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell>Lead ID</StyledTableCell>
                                        <StyledTableCell align="center">Created At</StyledTableCell>
                                        <StyledTableCell align="center">Comments</StyledTableCell>
                                        <StyledTableCell align="center">Action</StyledTableCell>
                                        <StyledTableCell align="center">Lead Source Url</StyledTableCell>
                                        <StyledTableCell align="center">Agent</StyledTableCell>
                                        <StyledTableCell align="center">Call</StyledTableCell>
                                        <StyledTableCell align="center">Contact number</StyledTableCell>
                                        <StyledTableCell align="center">Patient name</StyledTableCell>
                                        <StyledTableCell align="center">City</StyledTableCell>
                                        <StyledTableCell align="center">Patient Area</StyledTableCell>
                                        <StyledTableCell align="center">Call status</StyledTableCell>
                                        <StyledTableCell align="center">Language</StyledTableCell>
                                        <StyledTableCell align="center">Edit</StyledTableCell>
                                        {/* <StyledTableCell align="center">Raise Ticket</StyledTableCell> */}
                                        <StyledTableCell align="center">Prescription</StyledTableCell>
                                        <StyledTableCell align="center">Priority</StyledTableCell>
                                        <StyledTableCell align="center">Call Count</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {imaginraiseTicket && imaginraiseTicket?.results && imaginraiseTicket?.results.length > 0 && imaginraiseTicket?.results.map((data: any, index: any) => {

                                        return (
                                            <StyledTableRow
                                                key={index}
                                            >
                                                <StyledTableCell align="center">
                                                    <input
                                                        type="checkbox"
                                                        name="selectall"
                                                        id="selectall"
                                                        checked={selectedTicket.find(
                                                            (bI: any) => bI === data?.id
                                                        )}
                                                        onChange={(e) =>
                                                            !e.target.checked
                                                                ? setSelectedTicket(
                                                                    selectedTicket.filter(
                                                                        (bI: any) => bI !== data?.id
                                                                    )
                                                                )
                                                                : setSelectedTicket((prev: any) => [
                                                                    ...prev,
                                                                    data?.id,
                                                                ])
                                                        }
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    {data?.lead?.id}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {new Date(data?.created_at).toLocaleString()}
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    <div>
                                                        <MessageIcon onClick={() => handleClick(data?.lead?.id)} />
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <div>
                                                        <MessageIcon onClick={() => handleModal(data?.lead?.id)} />
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.lead?.lead_source_url}
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {data?.by_user?.name}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Button
                                                        disabled={!callButton}
                                                        className="addDeck"
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => handleCallClick(data?.lead?.id, "call")}
                                                        startIcon={<CallIcon />}
                                                    >
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.agent?.phonenumber}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.lead?.patient_name}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.lead?.patient_city}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.lead?.patient_area}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.lead?.call_status?.name}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.lead?.language}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Link
                                                        to={`/dashboard/sla/fill-info?id=${data?.lead?.id}`}
                                                    >
                                                        <EditIcon />
                                                    </Link>
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="center">
                            <Button variant={!raiseComplaint ? "contained" : "outlined"} color="secondary" onClick={() => handleRaiseTicket(data.lead.id)}>
                              <ConfirmationNumberIcon />
                            </Button>
                          </StyledTableCell> */}
                                                <StyledTableCell align="center">


                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.lead?.priority}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {data?.lead?.count}
                                                </StyledTableCell>

                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <StyledTableRow>
                                        <TablePagination
                                            rowsPerPageOptions={[]}
                                            colSpan={3}
                                            count={imaginraiseTicket?.count || 0}
                                            rowsPerPage={imaginraiseTicket.page_size}
                                            page={page}
                                            onPageChange={handleChangePage}
                                        />
                                    </StyledTableRow>
                                </TableFooter>
                            </Table>
                        )}
                    </TableContainer>
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className={classes.modal}
                        open={openPdf}
                        onClose={handlePdfModelClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={openPdf}>
                            <>
                                {fileType === "pdf" ? (
                                    <iframe
                                        style={{ width: "80%", height: "750px" }}
                                        src={pLink} title="title">
                                        Presss me: <a href={pLink}>Download PDF</a>
                                    </iframe>
                                ) : fileType === "b64" ? (
                                    <iframe
                                        src={pLink}
                                        style={{ width: "80%", height: "750px" }}
                                        title="Iframe Example"
                                    ></iframe>
                                ) : ""}
                            </>

                        </Fade>
                    </Modal>
                </div>
                <CommentsModal open={open} setOpen={setOpen} leadId={leadId} />
                <CommentsModal2 open={open2} getCities={getCities} cities={cities} setOpen={setOpen2} leadId={leadId} />
                {/* <RaiseComplaintModal open={raiseComplaint} setOpen={setRaiseComplaint} leadId={Number(leadId)} bookingId={0} /> */}
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.SalesLeadAdminReducer.loading,
    cities: state.SalesLeadAdminReducer.cities,
    imaginraiseTicket: state.SalesLeadAdminReducer.imaginraiseTicket,
    imagingTicketcount: state.SalesLeadAdminReducer.imagingTicketcount,
    topRcFreshLeads: state.SalesLeadAdminReducer.topRcFreshLeads,
    lead_source: state.SalesLeadAdminReducer.lead_source,
    prescription: state.SalesLeadAdminReducer.prescription,
    assigneUserList: state.SalesLeadAdminReducer.assigneUserList,

});

export default connect(mapStateToProps, {
    getImagingRaiseTicket,
    getImagingTicketCount,
    getCities,
    getTopRCFreshLeads,
    clickToCall,
    getLeadSource,
    getImagingTicket,
    getPrescriptionData,
    getAssignAgentList,
    transferImagingTickets
})(LeadsTable);
