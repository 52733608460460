import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@mui/material/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PhoneIcon from '@material-ui/icons/Phone';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		width: isMobile ? '400px' : '1200px'
	},
	head: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: '2rem'
	},
	toastNotification: {
		right: 0,
		width: "35%",
		/* border: 1px solid, */
		bottom: "0rem",
		/* height: 92%, */
		display: "flex",
		position: "fixed",
		zIndex: 999,
		alignItems: "flex-end",
		justifyContent: "flex-end",
	},
	displayProp: {
		display: "flex",
		width: "100%",
		justifyContent: "space-evenly"
	},
	displayPropBetween: {
		display: "flex",
		justifyContent: "space-between",
		margin: "8px 1rem",
		flexDirection: "column",
		padding: "1rem",

	},
	listStyle: {
		listStyle: "none",
		borderRadius: "5px",
		backgroundColor: "#dee2e6",

	},
	ulDiv: {
		width: isMobile ? '30%' : '100%',


		zIndex: 999999,
		margin: "1rem",
		borderRadius: "5px",
	}
}));

type ModalProps = {
	bodyData: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
	bodyData,
}) => {
	const classes = useStyles();
	const [notificationList, setNotificationList] = useState<any>([])
	const [showNotify,setShowNotify]=useState<boolean>(false)

	useEffect(() => {
		let notify = new Array();
		if (notify.length === 0) {
			notify.push(
				{
					"CallSid": bodyData.CallSid,
					"answer_agent_number": bodyData.answer_agent_number,
					"answer_agent_usergroup": bodyData.answer_agent_usergroup,
					"answer_agent_username": bodyData.answer_agent_username,
					"billing_circle": bodyData.billing_circle,
					"call_type": bodyData.call_type,
					"customer_number": bodyData.customer_number,
					"direction": bodyData.direction,
					"lead": bodyData.lead,
					"provider": bodyData.provider,
					"start_stamp": bodyData.start_stamp,
					"status":true
				}
			)
		}
		else {
			notify.map((data: any) => {
				if (data.lead === bodyData.lead) {

				}
				else {
					notify.push({
						"CallSid": bodyData.CallSid,
						"answer_agent_number": bodyData.answer_agent_number,
						"answer_agent_usergroup": bodyData.answer_agent_usergroup,
						"answer_agent_username": bodyData.answer_agent_username,
						"billing_circle": bodyData.billing_circle,
						"call_type": bodyData.call_type,
						"customer_number": bodyData.customer_number,
						"direction": bodyData.direction,
						"lead": bodyData.lead,
						"provider": bodyData.provider,
						"start_stamp": bodyData.start_stamp,
						"status":true
					})
				}
			})
		}
		setNotificationList(notify)
		setShowNotify(true)
	}, [bodyData])

	const history = useHistory();
	
	return (
		<div className={classes.toastNotification}>
			{showNotify===true?(
				bodyData?.call_type ? (
				<ul className={classes.ulDiv}>
					<li className={classes.listStyle}>
						<div className={classes.displayPropBetween}>
							<span>CALL TYPE : {bodyData.call_type}</span>
							<span>Provider : {bodyData.provider} </span>
							<span onClick={() => history.push(`/dashboard/ce/fill-info?id=${bodyData.lead}`)}>Lead Id : {bodyData.lead} </span>
						</div>
						<div className={classes.displayProp}  >
							<span>Close <CloseIcon  onClick={()=>setShowNotify(false)}/></span>
							<span onClick={() => history.push(`/dashboard/ce/fill-info?id=${bodyData.lead}`)} >Action  <PhoneIcon /></span>
						</div>
					</li>
				</ul>
			) : ""
			):""}
			

		</div>
	);
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(CommentsModal2);
