import {
  ACTIONS,
  BookingTypes,
} from "../interfaces/actionTypes/ImagingAdminType";

type InitialState = {
  loading: boolean;
  cities: Array<any>;
  areaList: Object;
  centreObject: Object;
  imagingTestList: Array<any>;
  imagingPackageAlias: Array<any>;
  packageList: Array<any>;
  subCenterInfor: Array<any>;
  centerAddressPrice: Array<any>;
  daywisetimeslot: Array<any>;
  timeSlots: Array<any>;
  dayList: Array<any>;
  salesManager: Array<any>;
  centerInfo: Array<any>;
  pin_code: Array<any>;
  document: Array<any>;
  codeGenrator: Array<any>;
  mapmyindiaAccessToken: Array<any>;
  geoFencesMapMyIndia: Array<any>;
  checkGeoFenceStatus: boolean;
  centrePackageComments: Array<any>;
  centreComments: Array<any>;
  ticketData: Array<any>;
  ticketCategories: Array<any>;
  ticketSubCategories: Array<any>;
  redtechcomment: Array<any>;
  displayCities: Array<any>;

};

const initialState: InitialState = {
  loading: false,
  cities: [],
  areaList: {},
  centreObject: {},
  imagingTestList: [],
  imagingPackageAlias: [],
  packageList: [],
  subCenterInfor: [],
  centerAddressPrice: [],
  daywisetimeslot: [],
  timeSlots: [],
  dayList: [],
  pin_code: [],
  salesManager: [],
  centerInfo: [],
  document: [],
  codeGenrator: [],
  mapmyindiaAccessToken: [],
  geoFencesMapMyIndia: [],
  checkGeoFenceStatus: false,
  centrePackageComments: [],
  centreComments: [],
  ticketData: [],
  ticketCategories: [],
  ticketSubCategories: [],
  redtechcomment: [],
  displayCities: [],
};

const AdminReducer = (state = initialState, action: BookingTypes) => {
  switch (action.type) {
    // case ACTIONS.GET_USERS_LIST:
    //     return {
    //         ...state,
    //         users: action.payload,
    //         loading: false,
    //     };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case ACTIONS.GET_CITIES:
      return {
        ...state,
        cities: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AREA:
      return {
        ...state,
        areaList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_IMAGING_TEST_LIST:
      return {
        ...state,
        imagingTestList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_IMAGING_PACKAGE_ALIAS:
      return {
        ...state,
        imagingPackageAlias: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGES:
      return {
        ...state,
        packageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SUB_CENTER_INFORMATION:
      return {
        ...state,
        subCenterInfor: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTER_ADDRESS_PRICE:
      return {
        ...state,
        centerAddressPrice: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DAY_WISE_TIME_SLOT:
      return {
        ...state,
        daywisetimeslot: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TIMESLOTS:
      return {
        ...state,
        timeSlots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DOCUMENT:
      return {
        ...state,
        document: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DAYS_LIST:
      return {
        ...state,
        dayList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SALES_MANAGER:
      return {
        ...state,
        salesManager: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PIN_CODE:
      return {
        ...state,
        pin_code: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SUB_CENTER_INFORMATION:
      return {
        ...state,
        subCenterInfor: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTER_INFORMATION:
      return {
        ...state,
        centerInfo: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CODE_GENRATOR_DATA:
      return {
        ...state,
        codeGenrator: action.payload,
        loading: false,
      };
      case ACTIONS.GET_RED_TECH_COMMENT:
        return {
            ...state,
            redtechcomment: action.payload,
            loading: false,
        };
    case ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN:
      return {
        ...state,
        mapmyindiaAccessToken: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA:
      return {
        ...state,
        geoFencesMapMyIndia: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA:
      return {
        ...state,
        checkGeoFenceStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTER_PACKAGE_COMMENTS:
      return {
        ...state,
        centrePackageComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTER_COMMENTS:
      return {
        ...state,
        centreComments: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_NEW_CENTER:
      return {
        ...state,
        centreObject: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_DATA:
      return {
        ...state,
        ticketData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_CATEGORIES:
      return {
        ...state,
        ticketCategories: action.payload,
        loading: false,
      };

    case ACTIONS.GET_TICEKT_SUB_CATEGORIES:
      return {
        ...state,
        ticketSubCategories: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RED_TECH_COMMENT:
      return {
        ...state,
        redtechcomment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DISPLAY_CITIES:
      return {
        ...state,
        displayCities: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default AdminReducer;
