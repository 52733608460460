import React, { useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  getPhlebos,
  getAllAttendence,
  updateAttendanceCompliance,
} from "../../actions/salesLeadAdminAction";
import {
  Grid,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableFooter,
  TablePagination,
  Container,
  Box,
  TextField,
  withStyles,
} from "@material-ui/core";
import { generateAttendenceFilterUrl } from "../../../helpers/generateUrl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useEffect } from "react";
import Loader from "../loader";
import { DeleteForever, Edit } from "@material-ui/icons";
import { useHistory } from "react-router";
import Modal from "@mui/material/Modal";
import moment from "moment";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    paper: {
      padding: "1rem",
      width: "100%",
      borderRadius: "10px",
    },
    head: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "1rem",
      textAlign: "center",
    },
    heading: {
      fontWeight: "normal",
      fontSize: "1.2rem",
      margin: "0",
    },
    table: {
      margin: "auto",
      overflow: "auto",
    },
    image: {
      width: "100px",
      height: "auto",
    },
    imageSM: {
      width: "60px",
      height: "60px",
      border: "2px solid #000 ",
      borderRadius: "50%",
    },
    imgContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "wrap",
    },
    imagePaper: {
      position: "absolute",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[1],
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

interface Props {
  attendenceList: any;
  getAllAttendence: any;
  phleboList: any;
  loading: any;
  updateAttendanceCompliance: any;
  getPhlebos: any;
}

const AttendancePage: React.FC<Props> = ({
  attendenceList,
  phleboList,
  getAllAttendence,
  loading,
  updateAttendanceCompliance,
  getPhlebos,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [attendanceId, setAttendanceId] = useState<number | null>();
  const [created_date, setCreated_date] = useState("");
  const [phlebo, setPhlebo] = useState<any>([]);
  const [key1, setKey1] = useState<any>(false);
  const [activePhlebo, setActivePhlebo] = useState<any>();
  const [isPunchOutImage, setIsPunchOutImage] = useState(false);
  const [disableComplianceButton, setDisableComplianceButton] = useState(false);
  const timer = useRef<any>(0);
  const [openImage, setImageOpen] = React.useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [modalStyle] = React.useState(getModalStyle);
  const history = useHistory();
  useEffect(() => {
    getPhlebos();
    getAllAttendence();
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = attendenceList.links && attendenceList.links.next.split("?")[1];
      getAllAttendence(url);
    } else if (newPage < page) {
      let url =
        attendenceList.links && attendenceList.links.previous.split("?")[1];
      getAllAttendence(url);
    }
    setPage(newPage as number);
  };
  const filterAttendence = () => {
    const body: any = {
      start_date,
      end_date,
      created_date,
      phlebo: phlebo?.length > 0 ? phlebo?.join(",") : "",
    };
    const url = generateAttendenceFilterUrl(body).substring(2);
    getAllAttendence(`${url}`);
    setPage(0);
  };
  const handleStartModal = (e: any) => {
    setImageUrl(e);
    setImageOpen(true);
  };
  const handleEndModal = (e: any) => {
    if (e) {
      setImageUrl(e);
      setImageOpen(true);
    }
  };
  const handleImageClose = () => {
    setImageOpen(false);
  };

  const updateCompliance = async (compliance: boolean) => {
    setDisableComplianceButton(true);
    await updateAttendanceCompliance(attendanceId, compliance);
    setImageOpen(false);

    setActivePhlebo((previousState: any) => {
      return {
        ...previousState,
        compliance:
          previousState.compliance === null
            ? compliance
            : !previousState.compliance,
      };
    });
    setDisableComplianceButton(false);

    if (phlebo?.length > 0 || start_date || end_date || created_date) {
      const body: any = {
        start_date,
        end_date,
        created_date,
        phlebo: phlebo?.length > 0 ? phlebo?.join(",") : "",
      };

      const url = generateAttendenceFilterUrl(body).substring(2);
      getAllAttendence(`${url}`);
    } else {
      getAllAttendence(`page=${page + 1}`);
    }
  };

  useEffect(() => {
    const selectedPhlebo = attendenceList?.results?.find(
      (phelbo: any) => phelbo.id === attendanceId
    );
    setActivePhlebo(selectedPhlebo);
  }, [attendanceId]);

  const buttons = (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={updateCompliance.bind(null, true)}
        disabled={disableComplianceButton}
      >
        In Compliance
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={updateCompliance.bind(null, false)}
        disabled={disableComplianceButton}
      >
        Not in Compliance
      </Button>
    </>
  );

  const body = (
    <div style={modalStyle} className={classes.imagePaper}>
      <img
        src={imageUrl}
        alt="popup"
        style={{ width: "550px", height: "450px" }}
      />
      {/* <SimpleModal /> */}
      {!isPunchOutImage && <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          gap: "10px",
          margin: "1rem auto",
        }}
      >
        {activePhlebo?.compliance === null ||
        activePhlebo?.compliance === undefined ? (
          buttons
        ) : activePhlebo?.compliance ? (
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={updateCompliance.bind(null, true)}
              disabled={true}
            >
              In Compliance
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={updateCompliance.bind(null, false)}
              disabled={disableComplianceButton}
            >
              Not in Compliance
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={updateCompliance.bind(null, true)}
              disabled={disableComplianceButton}
            >
              In Compliance
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={updateCompliance.bind(null, false)}
              disabled={true}
            >
              Not in Compliance
            </Button>
          </>
        )}
      </div>}
    </div>
  );

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <h2>Attendance</h2>
        <Grid container spacing={1} direction="row">
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              key={key1}
              id="phlebo"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setPhlebo(sourceIds);
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              options={
                phleboList.results
                  ? [
                      ...phleboList.results,
                      { id: 0, user: { username: "NoPhlebo" } },
                    ]
                  : []
              }
              getOptionDisabled={(option: any) => {
                return phlebo.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                option.user && option.user.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPhlebos(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPhlebo([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Phlebo Name"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="start_date"
              type="date"
              label="Start Date"
              value={start_date}
              variant="outlined"
              disabled={created_date !== ""}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) => setStart_date(e.target.value as string)}
              inputProps={{ max: moment(new Date()).format("YYYY-MM-DD") }}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="end_date"
              type="date"
              value={end_date}
              className="input"
              label="End Date"
              variant="outlined"
              disabled={created_date !== ""}
              onKeyDown={(e) => e.preventDefault()}
              onChange={(e) => setEnd_date(e.target.value as string)}
              inputProps={{
                min: moment(start_date).format("YYYY-MM-DD"),
                max: moment(new Date()).format("YYYY-MM-DD"),
              }}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="created_date"
              type="date"
              value={start_date === "" || end_date === "" ? created_date : ""}
              className="input"
              label="Created Date"
              variant="outlined"
              disabled={start_date !== "" || end_date !== ""}
              onChange={(e) => setCreated_date(e.target.value as string)}
              inputProps={{ max: moment(new Date()).format("YYYY-MM-DD") }}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={filterAttendence}
              disabled={
                loading ||
                (phlebo?.length === 0 &&
                  start_date.trim().length === 0 &&
                  end_date.trim().length === 0 &&
                  created_date.trim().length === 0)
              }
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                getAllAttendence();
                setPhlebo("");
                setKey1(!key1);
                setStart_date("");
                setEnd_date("");
                setCreated_date("");
              }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <br />
        {loading ? (
          <Loader />
        ) : (
          <TableContainer
            className={classes.tableContainer}
            component={Paper}
            style={{ maxHeight: "560px" }}
          >
            <Table stickyHeader aria-label="simple table">
              {attendenceList?.results?.length === 0 && (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <p style={{ fontSize: "20px", fontWeight: "500" }}>
                    No data available!
                  </p>
                </div>
              )}
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Date</StyledTableCell>
                  <StyledTableCell align="center">Phlebo Name</StyledTableCell>
                  <StyledTableCell align="center">
                    Phlebo Contact
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Punch In Time
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Punch Out Time
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Punch In Image
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Punch Out Image
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    Compliance Check
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {attendenceList.results &&
                  attendenceList.results.length > 0 &&
                  attendenceList.results.map((attend: any) => {
                    return (
                      <StyledTableRow key={attend.id}>
                        <StyledTableCell align="center">
                          {attend.attendance_date}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {attend.phlebo.user.name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {attend.phlebo.user.phonenumber}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {attend.start_time}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {attend.end_time}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          onClick={() => {
                            handleStartModal(
                              attend.start_attendance_image.image
                            );
                            setAttendanceId(attend.id as number);
                            setIsPunchOutImage(false);
                          }}
                        >
                          <img
                            key={attend.start_attendance_image.id}
                            src={attend.start_attendance_image.image}
                            alt="startimage"
                            className={classes.imageSM}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          onClick={() => {
                            handleEndModal(attend.end_attendance_image.image);
                            setIsPunchOutImage(true);
                          }}
                        >
                          {attend.end_attendance_image.image ? (
                            <img
                              key={attend.end_attendance_image.id}
                              src={attend.end_attendance_image.image}
                              alt="startimage"
                              className={classes.imageSM}
                            />
                          ) : (
                            "NA"
                          )}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <p
                            style={{
                              background: attend.compliance
                                ? "#924A91"
                                : attend.compliance === false
                                ? "#1565c0"
                                : "none",
                              color:
                                attend.compliance || attend.compliance === false
                                  ? "#fff"
                                  : "#000",
                              padding: "2px 4px",
                              borderRadius: "2px",
                            }}
                          >
                            {attend.compliance
                              ? "In Compliance"
                              : attend.compliance === false
                              ? "Not in Compliance"
                              : "Not marked"}
                          </p>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <StyledTableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={7}
                    count={(attendenceList && attendenceList.count) || 0}
                    rowsPerPage={attendenceList && attendenceList.page_size}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </StyledTableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}

        <Modal
          open={openImage}
          onClose={handleImageClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  attendenceList: state.SalesLeadAdminReducer.attendenceList,
  phleboList: state.SalesLeadAdminReducer.phleboList,
  loading: state.SalesLeadAdminReducer.loading,
});

export default connect(mapStateToProps, {
  getAllAttendence,
  getPhlebos,
  updateAttendanceCompliance,
})(AttendancePage);
