import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, MenuItem, Select, TextField, Grid } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { updateQueryTicket } from '../../actions/HealthAdviserAdminAction';
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    }
}));

type ModalProps = {
    open: boolean;
    bookingId: number;
    updateQueryTicket: any;
    complaint: any;
    queryType: any
    queryDesc: any
    queryStatus: any;
    leadId: number;
    ticketId: number;
    setOpen: Function;
};

const CommentsModal2: React.FC<ModalProps> = ({
    open,
    bookingId,
    leadId,
    updateQueryTicket,
    complaint,
    queryType,
    queryDesc,
    queryStatus,
    ticketId,
    setOpen,
}) => {
    const classes = useStyles();

    const history = useHistory()

    const [query, setQuery] = useState<string>("")
    const [description, setDescription] = useState<string>("")
    const [status, setStatus] = useState<string>("pending")
    const [reciveRemark, setReciveRemark] = React.useState<any>("");
    const [errorMessage,setErrorMessage]=useState<any>("")

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(()=>{
        if(reciveRemark!==""){
            setErrorMessage("")
        }
    },[reciveRemark])

    useEffect(() => {
        setQuery(queryType)
        setDescription(queryDesc)
        setStatus(queryStatus)
    }, [queryType, queryDesc, queryStatus])

    const raiseComplaint = () => {
        const type = bookingId !== 0 ? "booking_ticket" : leadId !== 0 ? "lead_ticket" : ""
        const body: any = {
            status,
            querytype: query,
            query: description,
            receicer_remarks: reciveRemark,
        }
        if(reciveRemark===""){
            setErrorMessage("Please Enter Remarks")
            
        }else{
            updateQueryTicket(type, body, ticketId)
            setReciveRemark("")
            handleClose()
        }
        
    };

    useEffect(() => {
        if (complaint) {
            handleClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [complaint])

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.head}>
                            <h4
                                style={{
                                    marginBottom: "1rem",
                                }}
                                id="transition-modal-title"
                            >
                                Action
                            </h4>
                            <CloseIcon onClick={() => setOpen(false)} />
                        </div>
                        <Grid container direction="row" alignItems="flex-start" justify="space-between" spacing={3} style={{ margin: "1rem auto" }}>
                        <Grid item xs={12} md={6}>
                            <p>Recive Remarks</p>
                                <TextField
                                    className="input"
                                    name="reciveRemark"
                                    type="text"
                                    value={reciveRemark}
                                    disabled={queryStatus==="confirmed"}
                                    variant="outlined"
                                    onChange={(e) => setReciveRemark(e.target.value as string)}
                                    style={{ width: "100%" }}
                                />
                                <p style={{color:"red"}}>{errorMessage}</p>
                            </Grid>

                            <Grid item xs={12} md={6}>
                            <p>Query Type</p>
                                <Select className="input"
                                    name="action"
                                    variant="outlined"
                                    value={query}
                                    style={{ width: "100%" }}
                                    onChange={(e) => setQuery(e.target.value as string)}
                                    
                                >
                                    <MenuItem value={"SupportExecutive"}>SupportExecutive</MenuItem>
                                    <MenuItem value={"SampleAccessioning"}>SampleAccessioning</MenuItem>
                                    <MenuItem value={"CustomerSupport"}>CustomerSupport</MenuItem>
                                    <MenuItem value={"PhleboAdmin"}>PhleboAdmin</MenuItem>
                                    {/* <MenuItem value={"Doctor"}>Doctor</MenuItem> */}
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <p>Select Status</p>
                                <Select className="input"
                                    name="action"
                                    variant="outlined"
                                    value={status}
                                    style={{ width: "100%" }}
                                    onChange={(e) => setStatus(e.target.value as string)}
                                >
                                    <MenuItem disabled value={"none"}>Select Status</MenuItem>
                                    <MenuItem value={"pending"}>Pending</MenuItem>
                                    <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="comment"
                                    type="text"
                                    multiline
                                    disabled
                                    rows={3}
                                    value={description}
                                    className="input"
                                    variant="outlined"
                                    placeholder="Description"
                                    onChange={(e) => setDescription(e.target.value as string)}
                                    style={{ width: "100%" }}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ height: "50px" }}
                                    onClick={raiseComplaint}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    complaint: state.HealthAdviserAdminReducer.complaint
});

export default connect(mapStateToProps, {
    updateQueryTicket
})(CommentsModal2);
