import React, { useEffect, useState } from "react";
import { Theme, createStyles } from "@material-ui/core/styles";
import { Link, useHistory } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Typography, Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { connect } from "react-redux";
import CallMissedIcon from '@material-ui/icons/CallMissed';
import { getTodaysCallDetails } from '../../actions/bookingActions'
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        spacebtm: {
            marginBottom: 15,
        },
        root: {
            flexGrow: 1,
        }, table: {
            minWidth: 650,
        },
        card: {
            padding: 15,
            width: "100%",
        },
        content: {
            width: "100%",
            flexGrow: 21,
            margin: "50px auto"
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            marginBottom: "1rem",
            flexWrap: "wrap"
        },
        paper: {
            padding: theme.spacing(3),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        },
    })
);

interface Props {
    getTodaysCallDetails: any;
    todayCallDetails: any;
    loading: any;
}

const SampleTypeForm: React.FC<Props> = ({
    getTodaysCallDetails,
    todayCallDetails,
    loading,
}) => {
    const classes = useStyles();
    const [connectCall, setConnectCall] = useState<any>([])
    const [connectCallOutgoing, setConnectCallOutgoing] = useState<any>([])
    const [xnumber, setXNumber] = useState<number>(0);
    const [callType, setCallType] = useState<any>("")
    const [timer, setTimer] = useState<any>(0)
    const [isActive, setIsActive] = useState(false);
    const [isPaused, setIsPaused] = useState(true);
    const [timeDuration, setTimeDuration] = useState<any>("")
    const [time, setTime] = useState(0);
    const WS_URL = process.env.REACT_APP_WEBSOCKET_URL || "";
    useEffect(() => {
        getTodaysCallDetails()
    }, [])

    useEffect(() => {
        const ws = new WebSocket(WS_URL);
        ws.onmessage = (event: any) => {
            setCallType(JSON.parse(event.data).call_type)
            let incommingCall:any = [];
            let outGoingCall:any = [];
            
            var callId = connectCall.findIndex((data: any) => data.CallSid === JSON.parse(event.data).CallSid)
            var answerCallId = connectCallOutgoing.findIndex((data: any) => data.CallSid === JSON.parse(event.data).CallSid)
            if (JSON.parse(event.data).call_type === "call_info" || JSON.parse(event.data).call_type === "incomming_call_answered" || JSON.parse(event.data).call_type === "incomming_call_status") {
                if (callId > -1) {
                    connectCall[callId] = JSON.parse(event.data)
                    // setConnectCall(incommingCall)
                }
                else {
                    connectCall.push(JSON.parse(event.data))
                    // setConnectCall(incommingCall)
                }
            }
            else if (JSON.parse(event.data).call_type === "click_to_call_answered_agent" || JSON.parse(event.data).call_type === "click_to_call_answered_customer" || JSON.parse(event.data).call_type === "click_to_call_status") {
                if (answerCallId > -1) {
                    connectCallOutgoing[answerCallId] = JSON.parse(event.data)
                    // setConnectCallOutgoing(outGoingCall)
                }
                else {
                    connectCallOutgoing.push(JSON.parse(event.data))
                    // setConnectCallOutgoing(outGoingCall)

                }
            }


            // if (JSON.parse(event.data).call_type === "call_info" || JSON.parse(event.data).call_type === "incomming_call_answered" || JSON.parse(event.data).call_type === "incomming_call_status") {
            //     var callId = connectCall.findIndex((data: any) => data.CallSid === JSON.parse(event.data).CallSid)
            //     if (callId > -1) connectCall[callId] = JSON.parse(event.data);
            //     else connectCall.push(JSON.parse(event.data))
            // }
            // else if (JSON.parse(event.data).call_type === "click_to_call_answered_agent" || JSON.parse(event.data).call_type === "click_to_call_answered_customer" || JSON.parse(event.data).call_type === "click_to_call_status") {
            //     var answerCallId = connectCallOutgoing.findIndex((data: any) => data.CallSid === JSON.parse(event.data).CallSid)
            //     if (answerCallId > -1) connectCallOutgoing[answerCallId] = JSON.parse(event.data);
            //     else connectCallOutgoing.push(JSON.parse(event.data))
            // }

        };
        ws.onclose = () => {
            ws.close();
        };

        return () => {
            ws.close();
        };
    }, []);
    
    
    return (
        <main className={classes.content}>
            <div style={{ width: "100%", margin: "50px auto" }}>
                <div className={classes.root}>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <Paper className={classes.paper} style={{ backgroundColor: "#9cf294" }}>
                                <Typography variant="h6" align="left">Total Incomming Calls</Typography>
                                <Typography variant="h3" align="left">{todayCallDetails.total_incoming_call_count}</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper className={classes.paper} style={{ backgroundColor: "#9cf294" }}>
                                <Typography variant="h6" align="left">
                                    Incomming Answerd
                                </Typography>
                                <Typography variant="h3" align="left">{todayCallDetails.total_incoming_answer_call_count}</Typography>

                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper className={classes.paper} style={{ backgroundColor: "#9cf294" }}>
                                <Typography variant="h6" align="left">Incomming Missed</Typography>
                                <Typography variant="h3" align="left">{todayCallDetails.total_incoming_missed_call_count}</Typography>
                            </Paper>
                        </Grid>

                    </Grid>
                    <Grid container spacing={3}>

                        <Grid item xs={4}>
                            <Paper className={classes.paper} style={{ backgroundColor: "#9cf294" }}>
                                <Typography variant="h6" align="left">Total Outgoing Call Attempted</Typography>
                                <Typography variant="h3" align="left">{todayCallDetails.total_outbound_call_count}</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper className={classes.paper} style={{ backgroundColor: "#9cf294" }}>
                                <Typography variant="h6" align="left">Outgoing Call Answerd</Typography>
                                <Typography variant="h3" align="left">{todayCallDetails.total_outbound_answer_call_count}</Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper className={classes.paper} style={{ backgroundColor: "#9cf294" }}>
                                <Typography variant="h6" align="left">Outgoing Call Missed</Typography>
                                <Typography variant="h3" align="left">{todayCallDetails.total_outbound_missed_call_count}</Typography>
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
                <Paper />
                <div className={classes.toolbar} />
                <div>
                    <TableContainer component={Paper}>
                        <h3 style={{ textAlign: 'center' }}>CALL INFO</h3>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Provider</TableCell>
                                    <TableCell align="center">Customer</TableCell>
                                    <TableCell align="center">Username</TableCell>
                                    <TableCell align="center">Call Direction</TableCell>
                                    <TableCell align="center">Call Duration</TableCell>
                                    <TableCell align="center">Call Type</TableCell>
                                    <TableCell align="center">State</TableCell>
                                    <TableCell align="center">Action</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {connectCall && connectCall.length > 0 && connectCall.map((data: any) => {

                                    return (
                                        <TableRow>
                                            <TableCell align="center" style={{ backgroundColor: data.call_type === "call_info" ? '#265738' : data.call_type === "incomming_call_answered" ? "#9c36f5" : "#fc267c" }}>{data.provider}</TableCell>
                                            <TableCell align="center" style={{ backgroundColor: data.call_type === "call_info" ? '#265738' : data.call_type === "incomming_call_answered" ? "#9c36f5" : "#fc267c" }}>{data.customer_number}</TableCell>
                                            <TableCell align="center" style={{ backgroundColor: data.call_type === "call_info" ? '#265738' : data.call_type === "incomming_call_answered" ? "#9c36f5" : "#fc267c" }}>{data.answer_agent_username}</TableCell>
                                            <TableCell align="center" style={{ backgroundColor: data.call_type === "call_info" ? '#265738' : data.call_type === "incomming_call_answered" ? "#9c36f5" : "#fc267c" }}>{data.direction}</TableCell>
                                            <TableCell align="center" style={{ backgroundColor: data.call_type === "call_info" ? '#265738' : data.call_type === "incomming_call_answered" ? "#9c36f5" : "#fc267c" }}>{data.duration}</TableCell>
                                            <TableCell align="center" style={{ backgroundColor: data.call_type === "call_info" ? '#265738' : data.call_type === "incomming_call_answered" ? "#9c36f5" : "#fc267c" }}>{data.call_type}</TableCell>
                                            {/* <TableCell align="center" style={{backgroundColor: '#fc267c'}}>{data.answer_agent_number }</TableCell> */}
                                            {data.call_type === "call_info" ?
                                                (<TableCell align="center" style={{ backgroundColor: '#265738' }}>Connecting</TableCell>) :
                                                data.call_type === "incomming_call_answered" ?
                                                    (<TableCell align="center" style={{ backgroundColor: '#9c36f5' }}>On Call</TableCell>) :
                                                    (<TableCell align="center" style={{ backgroundColor: '#fc267c' }}>Disconnected</TableCell>)}

                                            <TableCell align="center" style={{ backgroundColor: '#fc267c' }}>
                                                <Link
                                                    to={`/dashboard/cl/fill-info?id=${data.lead}`}
                                                >
                                                    <Button variant="contained" color="primary">
                                                        View
                                                    </Button>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className={classes.toolbar} />

                <div>
                    <TableContainer component={Paper}>
                        <h3 style={{ textAlign: 'center' }}>CALL INFO</h3>
                        <Table className={classes.table} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Provider</TableCell>
                                    <TableCell align="center">Customer</TableCell>
                                    <TableCell align="center">Username</TableCell>
                                    <TableCell align="center">Call Direction</TableCell>
                                    <TableCell align="center">Call Type</TableCell>
                                    <TableCell align="center">Call Duration</TableCell>
                                    <TableCell align="center">State</TableCell>
                                    <TableCell align="center">Duration</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {connectCallOutgoing && connectCallOutgoing.length > 0 && connectCallOutgoing.map((data: any) => {
                                    return (
                                        <TableRow>
                                            <TableCell align="center" style={{ color: data.call_type === "call_info" ? '#265738' : data.call_type === "incomming_call_answered" ? "#9c36f5" : "#fc267c" }}>{data.provider}</TableCell>
                                            <TableCell align="center" style={{ color: data.call_type === "call_info" ? '#265738' : data.call_type === "incomming_call_answered" ? "#9c36f5" : "#fc267c" }}>{data.customer_number}</TableCell>
                                            <TableCell align="center" style={{ color: data.call_type === "call_info" ? '#265738' : data.call_type === "incomming_call_answered" ? "#9c36f5" : "#fc267c" }}>{data.answer_agent_username}</TableCell>
                                            {/* <TableCell align="center" style={{color: '#fc267c'}}>{data.answer_agent_number }</TableCell> */}
                                            <TableCell align="center" style={{ color: data.call_type === "call_info" ? '#265738' : data.call_type === "incomming_call_answered" ? "#9c36f5" : "#fc267c" }}>{data.direction}</TableCell>
                                            <TableCell align="center" style={{ color: data.call_type === "call_info" ? '#265738' : data.call_type === "incomming_call_answered" ? "#9c36f5" : "#fc267c" }}>{data.call_type}</TableCell>
                                            <TableCell align="center" style={{ color: data.call_type === "call_info" ? '#265738' : data.call_type === "incomming_call_answered" ? "#9c36f5" : "#fc267c" }}>{data.duration} Sec</TableCell>
                                            {data.call_type === "click_to_call_answered_agent" ? (
                                                <TableCell align="center" style={{ color: '#9c36f5' }}>Connecting</TableCell>
                                            ) : data.call_type === "click_to_call_answered_customer" ? (
                                                <TableCell align="center" style={{ color: '#9c36f5' }}>On Going</TableCell>
                                            ) : (
                                                <TableCell align="center" style={{ color: '#9c36f5' }}>Disconnected</TableCell>

                                            )
                                            }
                                            <TableCell align="center" style={{ color: data.call_type === "call_info" ? '#265738' : data.call_type === "incomming_call_answered" ? "#9c36f5" : "#fc267c" }}>
                                                <Link
                                                    to={`/dashboard/cl/fill-info?id=${data.lead}`}
                                                >
                                                    <Button variant="contained" color="primary">
                                                        View
                                                    </Button>
                                                </Link>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </main>

    );
};

const mapStateToProps = (state: any) => ({
    todayCallDetails: state.CustomerLeadReducer.todayCallDetails,
    loading: state.CustomerLeadReducer.loading,
});

export default connect(mapStateToProps, { getTodaysCallDetails })(SampleTypeForm);