import React, { useEffect, useState,useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    withStyles,
    Theme,
    createStyles,
    makeStyles,
} from "@material-ui/core/styles";
import { Switch, TextField, Select, MenuItem, Grid, Chip } from '@material-ui/core';
import Button from '@mui/material/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { getUserList, getLeadSource, updateUserList,getAssignAgentList } from "../actions/bookingActions";
import { TableFooter, TablePagination } from "@material-ui/core";
import { genratePanelUserFiltr } from '../../helpers/generateUrl'
import Loader from "../components/loader";
import { useHistory } from 'react-router';

const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,fontWeight:600
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 700,
        },
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);

const DefaultSwitch:any = withStyles({
    switchBase: {
        color: "red",
        '&$checked': {
            color: "green",
        },
        '&$checked + $track': {
            backgroundColor: "green",
        },
    },
    checked: {},
    track: {
        backgroundColor: "red"
    },
})(Switch);

interface Props {
    getLeadSource: any;
    lead_source: any;
    updateUserList: any;
    getUserList: any;
    usersList: any;
    loading: boolean;
    getAssignAgentList:any;
    assigneUserList:any;
}

const LeadsTable: React.FC<Props> = ({
    getLeadSource,
    lead_source,
    updateUserList,
    getUserList,
    usersList,
    getAssignAgentList,
    assigneUserList,
    loading,
}) => {
    const classes = useStyles();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);

    const [mappedNo, setMappedNo] = useState<string | null>("")
    const [breakFilter, setBreakFilter] = useState("none")
    const [onCallFilter, setOnCallFilter] = useState("none")
    const [inboundModeFilter, setInbountModeFilter] = useState("none")
    const [on_call, setOnCall]=useState<boolean>(false)
    const [break_button,setBreakButton]=useState<boolean>(false)
    const [mappedNoFb, setMappedNoFb] = useState<string | null>("")
    const [mappedNoGoogle, setMappedNoGoogle] = useState<string | null>("")
    const [userStatus, setUserStatus] = useState("none")
    const [callFrom, setCallFrom] = useState<string>("none")
    const [isActive, setIsActive] = useState(false)
    const [isGoogleAd, setIsGoogleAd] = useState(false)
     const [isJdAd, setIsJdAd] = useState(false)
    const [isFbAd, setIsFbAd] = useState(false)
    const [user_name, setUser_name] = useState<any>([])
    const timer = useRef<any>(0)
    const [editUser, setEditUser] = useState<any>({})
     const history = useHistory()
    useEffect(() => {
        getLeadSource()
        getUserList()
        getAssignAgentList()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getLeadSource, getUserList,getAssignAgentList])

    useEffect(() => {
        setIsActive(editUser.status)
        setMappedNo(editUser.mapped_number)
        setMappedNoFb(editUser.mapped_number_fb)
        setMappedNoGoogle(editUser.mapped_number_google)
        setCallFrom(editUser.ctc_from)
        setIsFbAd(editUser.is_fb_ad)
        setIsGoogleAd(editUser.is_google_ad)
        setIsJdAd(editUser.is_jd_ad)
        setOnCall(editUser?.on_call)
        setBreakButton(editUser?.break_button)
    }, [editUser])

    const handleUser = () => {
        let body: any = {
            ...editUser,
            status: isActive,
            mapped_number: mappedNo,
            mapped_number_fb: mappedNoFb,
            mapped_number_google: mappedNoGoogle,
            is_fb_ad: isFbAd,
            is_google_ad: isGoogleAd,
            ctc_from: callFrom,
            is_jd_ad:isJdAd,
        }
        updateUserList(body)
        setEditUser({})
         getUserList()
    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = usersList.links.next;
            getUserList(url.substring(url.indexOf("?")))
        } else if (newPage < page) {
            let url = usersList.links.previous;
            getUserList(url.substring(url.indexOf("?")))
        }
        setPage(newPage);
    };
     const filterPanelUser = () => {
        const body: any = {
            username: user_name,
            on_call:onCallFilter,
            break_button:breakFilter,
            is_active: userStatus,
            inbound_mode: inboundModeFilter
        }
        const url = genratePanelUserFiltr(body).substring(2)
        getUserList(`?${url}`)
    }
    
    return (
        <>
            <main className="data-table" style={{ width: "100%" }}>
                <div className={classes.toolbar} />
                 <Grid container spacing={1} direction="row" style={{alignItems:"left"}}>
                    <Grid item xs={12} sm={12} md={12}>
                        <h2>Manage Users</h2>
                    </Grid>

                      <Grid item xs={12} sm={4} md={2}>
                        <Select className="input"
                            name="usergroup"
                            variant="outlined"
                            value={breakFilter}

                            style={{ width: "100%", height: "39px" }}
                            onChange={(e) => setBreakFilter(e.target.value as string)}
                        >
                            <MenuItem disabled value={"none"}>Break Status</MenuItem>
                            <MenuItem value={"true"}>Active</MenuItem>
                            <MenuItem value={"false"}>Inactive</MenuItem>
                        </Select>
                    </Grid>
                     <Grid item xs={12} sm={4} md={2}>
                        <Select className="input"
                            name="usergroup"
                            variant="outlined"
                            value={onCallFilter}

                            style={{ width: "100%", height: "39px" }}
                            onChange={(e) => setOnCallFilter(e.target.value as string)}
                        >
                            <MenuItem disabled value={"none"}>On Call Status</MenuItem>
                            <MenuItem value={"true"}>Active</MenuItem>
                            <MenuItem value={"false"}>Inactive</MenuItem>
                        </Select>
                    </Grid>
                      <Grid item xs={12} sm={4} md={2}>
                        <Select className="input"
                            name="usergroup"
                            variant="outlined"
                            value={userStatus}
                            style={{ width: "100%", height: "39px" }}
                            onChange={(e) => setUserStatus(e.target.value as string)}
                        >
                            <MenuItem value={"none"}>Status</MenuItem>
                            <MenuItem value={"true"}>Active</MenuItem>
                            <MenuItem value={"false"}>Inactive</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Select className="input"
                            name="inbound_mode"
                            variant="outlined"
                            value={inboundModeFilter}
                            style={{ width: "100%", height: "39px" }}
                            onChange={(e) => setInbountModeFilter(e.target.value as string)}
                        >
                            <MenuItem value={"none"}>Inbound Mode</MenuItem>
                            <MenuItem value={"true"}>Active</MenuItem>
                            <MenuItem value={"false"}>Inactive</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={filterPanelUser}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => history.push("/dashboard/cl/users")}
                        >
                            Reset
                        </Button>
                    </Grid>
                 </Grid>
                
                <div className="data-table" style={{ width: "100%", marginTop: "2rem" }}>
                <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "580px" }}>
                        {loading ? <Loader /> : (
                            <Table stickyHeader aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align="center">Panel user</StyledTableCell>
                                        <StyledTableCell align="center">User Role</StyledTableCell>
                                        <StyledTableCell align="center">On Call  </StyledTableCell>
                                        <StyledTableCell align="center">On Break  </StyledTableCell>
                                        <StyledTableCell align="center">Status</StyledTableCell>
                                        <StyledTableCell align="center">FB Ad</StyledTableCell>
                                        <StyledTableCell align="center">Google Ad</StyledTableCell>
                                        <StyledTableCell align="center">Call from</StyledTableCell>
                                        <StyledTableCell align="center">Jd Ad</StyledTableCell>
                                        <StyledTableCell align="center">Inbound Mode  </StyledTableCell>
                                        {/* <StyledTableCell align="center">Action</StyledTableCell> */}
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {usersList && usersList.results && usersList.results.map((user: any) => {
                                        return (
                                            <StyledTableRow key={user.username}>
                                                <StyledTableCell align="center">
                                                    {user.username}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {user.usergroup}
                                                </StyledTableCell>
                                                 <StyledTableCell align="center">
                                                  
                                                        <DefaultSwitch
                                                            checked={user?.on_call}
                                                            name="checkedA"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            disableRipple
                                                        />
                                                  
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                  
                                                        <DefaultSwitch
                                                            checked={user?.break_button}
                                                            name="checkedA"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            disableRipple
                                                        />
                                                  
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <Switch
                                                            defaultChecked={user.status}
                                                            checked={isActive}
                                                            onChange={(e) => setIsActive(e.target.checked)}
                                                            name="checkedA"
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            disableRipple
                                                        />
                                                    ) : (
                                                        <DefaultSwitch
                                                            checked={user.status}
                                                            name="checkedA"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            disableRipple
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <TextField
                                                            name="leadId"
                                                            type="text"
                                                            value={mappedNo !== null ? mappedNo : user.mapped_number}
                                                            className="input"
                                                            variant="outlined"
                                                            placeholder="Mapped No"
                                                            disabled
                                                            onChange={(e) => setMappedNo(e.target.value as string)}
                                                            style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                        />
                                                    ) : (
                                                        user.mapped_number
                                                    )}
                                                </StyledTableCell> */}
                                                {/* <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <TextField
                                                            name="leadId"
                                                            type="text"
                                                            value={mappedNoFb !== null ? mappedNoFb : user.mapped_number_fb}
                                                            className="input"
                                                            variant="outlined"
                                                            disabled
                                                            placeholder="Mapped Number FB"
                                                            onChange={(e) => setMappedNoFb(e.target.value as string)}
                                                            style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                        />
                                                    ) : (
                                                        user.mapped_number_fb
                                                    )}
                                                </StyledTableCell> */}
                                                <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <Switch
                                                            defaultChecked={user.is_fb_ad}
                                                            checked={isFbAd}
                                                            onChange={(e) => setIsFbAd(e.target.checked)}
                                                            name="checkedA"
                                                            disabled
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                    ) : (
                                                        <DefaultSwitch
                                                            checked={user.is_fb_ad}
                                                            name="checkedA"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            disableRipple
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <TextField
                                                            name="leadId"
                                                            type="text"
                                                            value={mappedNoGoogle !== null ? mappedNoGoogle : user.mapped_number_google}
                                                            className="input"
                                                            variant="outlined"
                                                            disabled
                                                            placeholder="Mapped Number Google"
                                                            onChange={(e) => setMappedNoGoogle(e.target.value as string)}
                                                            style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                        />
                                                    ) : (
                                                        user.mapped_number_google
                                                    )}
                                                </StyledTableCell> */}
                                                <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <Switch
                                                            defaultChecked={user.is_google_ad}
                                                            checked={isGoogleAd}
                                                            onChange={(e) => setIsGoogleAd(e.target.checked)}
                                                            name="checkedA"
                                                            color="primary"
                                                            disabled
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                    ) : (
                                                        <DefaultSwitch
                                                            checked={user.is_google_ad}
                                                            name="checkedA"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            disableRipple
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <Select className="input"
                                                            name="callFrom"
                                                            variant="outlined"
                                                            defaultValue={user.ctc_from}
                                                            disabled
                                                            value={callFrom !== "none" ? callFrom : user.ctc_from}
                                                            style={{ width: "100%", minWidth: "200px", textAlign: "left" }}
                                                            onChange={(e) => setCallFrom(e.target.value as string)}
                                                        >
                                                            <MenuItem value={"none"} disabled>Call from</MenuItem>
                                                            <MenuItem value={"none"} disabled>Call from</MenuItem>
                                                            <MenuItem value={"OZONETEL"} >OZONETEL</MenuItem>
                                                            <MenuItem value={"KNO"} >KNOWLARITY</MenuItem>
                                                            <MenuItem value={"AVYUKTA"} >AVYUKTA Gateway</MenuItem>
                                                            <MenuItem value={"TATATELE"} >TATA TELE</MenuItem>
                                                        </Select>
                                                    ) : (
                                                        user.ctc_from
                                                    )}
                                                </StyledTableCell>
                                                 <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <Switch
                                                            defaultChecked={user.is_jd_ad}
                                                            checked={isJdAd}
                                                            onChange={(e) => setIsJdAd(e.target.checked)}
                                                            name="checkedA"
                                                            color="primary"
                                                            disabled
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                    ) : (
                                                        <DefaultSwitch
                                                            checked={user.is_jd_ad}
                                                            name="checkedA"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            disableRipple
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {/* {editUser.username === user.username ? (
                                                        <Switch
                                                            defaultChecked={user?.inbound_mode}
                                                            checked={inboundMode}
                                                            onChange={(e) => setInboundMode(e.target.checked)}
                                                            name="checkedA"
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                    ) : ( */}
                                                        <DefaultSwitch
                                                            checked={user?.inbound_mode}
                                                            name="checkedA"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            disableRipple
                                                        />
                                                    {/* )} */}
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <Button
                                                            color="primary"
                                                            variant="text"
                                                            onClick={() => handleUser()}
                                                        >
                                                            Submit
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            color="primary"
                                                            variant="text"
                                                            onClick={() => setEditUser(user)}
                                                        >
                                                            Edit
                                                        </Button>
                                                    )}
                                                </StyledTableCell> */}
                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                                <TableFooter>
                                   <TablePagination
                                        colSpan={30}
                                        count={usersList.count || 0}
                                        rowsPerPageOptions={[]}
                                        rowsPerPage={rowsPerPage}
                                        // onChangeRowsPerPage={handleChangeRowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                    />
                                </TableFooter>
                            </Table>
                        )}
                    </TableContainer>
                </div>
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    usersList: state.CustomerLeadReducer.usersList,
    loading: state.CustomerLeadReducer.loading,
    lead_source: state.CustomerLeadReducer.lead_source,
    assigneUserList: state.CustomerLeadReducer.assigneUserList,
});

export default connect(mapStateToProps, { getUserList, updateUserList, getLeadSource,getAssignAgentList })(
    LeadsTable
);