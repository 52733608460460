import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "../react-sliding-pane.css";
import Modal from '@mui/material/Modal';
import { useDispatch } from "react-redux";
import GoogleAutoComplete from "react-google-autocomplete";
import Geocode from "react-geocode";

import {
  Card,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  checkGeoFenceArea,
  getPinCode,
  getPackage,
  createNewBooking,
  getPartners,
  getCoupons,
  getLeads,
  getLeadDetails,
  resetLeadDetails,
  getAvailableSlots,
  getRedCoing,
  getAddresstypeSeating,
  checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken
} from "../../../actions/HealthAdviserAdminAction";
import Button from '@mui/material/Button';
import "./index.sass";
import { connect } from "react-redux";
import moment from "moment";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useHistory } from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { GENDER_CHOICE } from "../../constant";
import { ACTIONS } from "../../../interfaces/actionTypes/HealthAdviserAdminType";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
Geocode.setApiKey("AIzaSyBPjDlsHYhWtjt3FxgRPnTMXN-qJZ9hz0s");
Geocode.enableDebug();
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },

    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    head: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "2rem",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
      marginTop:"60px"
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "100%",
      padding: "20px 10px 10px 10px",
      background: "#ffffff",
      margin: "10px",
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    },
    packageLink: {
      color: "#ffffff",
      textDecoration: "none",
    },
    dialog: {
      zIndex: 1
    },
  })
);

interface Props {
  getPinCode: any;
  pin_code: any;
  getPackage: any;
  packageList: any;
  getPartners: any;
  couponList: any;
  getCoupons: any;
  createNewBooking: any;
  getLeads: any;
  leads: any;
  getLeadDetails: any;
  lead_details: any;
  booking_slots: any;
  resetLeadDetails: any;
  createBooking: any;
  getAvailableSlots: any;
  loading: boolean;
  sameNumberBooking: boolean;
  checkGeoFenceArea: any;
  checkGeoFenceStatus: boolean;
  getRedCoing: any;
  redCoin: any;
  getAddresstypeSeating:any;
  addressType:any;
  checkGeoFenchMapMyIndia:any;
  geoFencesMapMyIndia:any;
  mapmyindiaAccessToken:any;
  getMapMyIndiaAccessToken:any
}

const BookingWithLocalitySearch: React.FC<Props> = ({
  getPinCode,
  pin_code,
  packageList,
  getPackage,
  couponList,
  getPartners,
  getCoupons,
  createNewBooking,
  getLeads,
  leads,
  getLeadDetails,
  lead_details,
  booking_slots,
  resetLeadDetails,
  createBooking,
  getAvailableSlots,
  sameNumberBooking,
  checkGeoFenceArea,
  checkGeoFenceStatus,
  getRedCoing,
  redCoin,
  loading,
  getAddresstypeSeating,
  addressType,
  checkGeoFenchMapMyIndia,
  geoFencesMapMyIndia,
  mapmyindiaAccessToken,
  getMapMyIndiaAccessToken
}) => {
  const classes = useStyles();
  let dispatch = useDispatch();
  const history = useHistory();
  const [inputList, setInputList] = useState<any>([
    {
      customerDesignation: "",
      designation: "",
      customerName: "",
      customerAge: "",
      customerGender: "",
      customerPackage: [],
      packagePrice: 0,
      dateOfBirth: "",
    },
  ]);
  const [collection_date, setCollection_date] = useState<String>("");
  const [pincode, setPinCode] = useState<String>("");
  const [zoneName, setZoneName] = useState<String>("");
  const [timeSlots, setTimeSlots] = useState<Array<Object>>([]);
  const [selectedSlot, setSelectedSlot] = useState<Number>(0);
  const [customer_phonenumber, setCustomer_phonenumber] = useState<String>("");
  const [customer_alternatenumber, setCustomer_alternatenumber] =
    useState<String>("");
  const [customer_whatsapppnumber, setCustomer_whatsapppnumber] =
    useState<String>("");
  const [customer_address, setCustomer_address] = useState<String>("");
  const [customer_email, setCustomer_email] = useState<String>("");
  const [customer_landmark, setCustomer_landmark] = useState<string>("");
  const [customerLatitude, setCustomerLatitude] = useState<any>("");
  const [customerLongitude, setCustomerLongitude] = useState<any>("");
  const [customer_areapincode, setCustomer_areapincode] = useState<Number>(0);
  //const [customer_areaname, setCustomer_areaname] = useState<String>("");
  const [customer_city, setCustomer_city] = useState<String>("");
  const [customer_aadhar, setCustomer_aadhar] = useState<String>("");
  const [passport, setPassport] = useState<String>("");
  const [adharPassport, setAdharPassport] = useState("customer_aadhar");
  const [price, setPrice] = useState<any>(0);
  const [discount, setDiscount] = useState<any>(0);
  const [totalPrice, setTotalPrice] = useState<any>(0);
  const [coupon, setCoupon] = useState<Number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [extraCharge, setExtraCharge] = useState<boolean>(false);
  const [cityId, setCityId] = useState<any>("");
  const [is_pincode, setIsPincode] = useState<boolean>(true);
  const [packageKey, setPackageKey] = useState<any>(0);
  const [defaultEmail, setDefaultEmail] = useState<any>(
    "customer.reports@redcliffelabs.com"
  );
  const [mobileValidation, setMobileValidation] = useState({
    customer_alternatenumber: true,
    customer_phonenumber: true,
    customer_whatsapppnumber: true,
  });
  const [search, setSearch] = useState<String>("");
  const [zoneId, setZoneId] = useState<any>("");
  const timer = useRef<any>(0);
  const handleSearch = (e: React.ChangeEvent<{ value: unknown }>) => {
    setSearch(e.target.value as string);
  };
  const [addressLine2, setAddressLine2] = useState<string>("");
  const [favoritePlace, setFavoritePlace] = useState<any>("");
  const [openLocationModal, setOpenLocationModal] = useState<boolean>(false);
  const [bookingEnabled, setBookingEnabled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [bookingFor, setBookingFor] = useState<string>("");
  const [isPaneOpen, setPaneOpen] = useState<boolean>(false);
  const [singlePackage, setSinglePackage] = useState<any>({});
  const [mobError, setMobError] = React.useState<string>("");
  const [altError, setAltError] = React.useState<string>("");
  const [whatsError, setWhatsError] = React.useState<string>("");
  const [tncPerCentage, setTnPercentage] = useState<string>("")
  const [couponMessage, setCouponMessage] = useState<string>("")
  const [tncMessage, setTncMessage] = useState<string>("")
  const [redCoinMessage, setRedCoinMessage] = useState<any>("")
  const [redCashCheck, setRedCashCheck] = useState<boolean>(false)
  const [totalRedCoin, setTotalRedCoin] = useState<any>("")
  const [redDisc, setRedDisc] = useState<any>(0)
  const [error, setError] = useState("");
  const [couponType, setCouponType] = useState<boolean>(false)
  const [redCouponDiscount, setRedCouponDiscount] = useState<number>(0)
  const [lead_customer_phonenumber, setLeadCustomer_phonenumber] = useState<string>("");
  const [lead_customer_whatsapppnumber, setLeadCustomer_whatsapppnumber] = useState<string>("");
  const [mapData,setMapData]=useState<any>([])
  const [mapOpen, setMapOpen] = useState<boolean>(false)
  const searchLead = () => {
    let body = {
      search_by: "call_id",
      value: search,
    };
    getLeads(body);
  };

  const selectLead = (id: any) => {
    getLeadDetails(`${id}`, "true");
  };

  useEffect(() => {
    setCustomer_address(lead_details.address as String);
    setLeadCustomer_phonenumber(lead_details.content_number as string);
    setLeadCustomer_whatsapppnumber(lead_details.whatsapp_contact as string);
    setInputList(
      [{
         customerDesignation: lead_details?.calling_gender === "male" ? "Mr" : "Ms",
         designation: lead_details?.calling_gender ,
        customerName: lead_details?.patient_name,
        customerAge: lead_details?.patient_age,
        customerGender: lead_details?.calling_gender,
        customerPackage: [],
        packagePrice: 0,
        dateOfBirth: "",
      }]
    )
  }, [lead_details]);

  useEffect(() => {
    resetLeadDetails();
    getPartners();
    getMapMyIndiaAccessToken();
    getCoupons("?org_type=homedx");
  }, []);

  useEffect(() => {
    if (customerLatitude && customerLongitude && zoneId) {
      // check booking is allow to not in Geo-fence
      checkGeoFenceArea(
        customerLatitude as string,
        customerLongitude as string,
        zoneId as string
      );
    }
  }, [customerLatitude, customerLongitude, zoneId]);

  useEffect(() => {
    setBookingEnabled(checkGeoFenceStatus);
  }, [checkGeoFenceStatus]);

  useEffect(() => {
    const slots = booking_slots && booking_slots?.results;
    setTimeSlots(slots && slots[0].zone_data && slots[0].zone_data.time_slots);
  }, [booking_slots]);
  useEffect(() => {
    if (pincode && pin_code?.results?.length == 0) {
      setErrorMessage("We are not providing service on this pincode.");
      resetAddressComponent();
    }
    if (pincode && pin_code?.results?.length  > 0) {
      const findPinCode = pin_code?.results.find(
        (code: any) => code.pincode == pincode
      );
      if (findPinCode) {
        setErrorMessage("");
        getArea(pincode);
        const { id, area, city, cityid } = pin_code.results.find(
          (code: any) => code.pincode === pincode
        );
        getPackage(`city_id=${cityid}&org_type=homedx`);
        setCityId(cityid);
        setCustomer_areapincode(id as Number);
        setCityId(cityid as String)
        setCustomer_city(city as String);
        setIsPincode(false);
        setPackageKey(cityid);
      } else {
        setErrorMessage("We are not providing service on this pincode.");
      }
    }
  }, [pin_code, pincode]);

  const getArea = (pincode: String) => {
    const zone =
      pin_code?.results &&
      pin_code?.results.find((code: any) => code?.pincode === pincode);
    setZoneName(zone && (zone?.area as String));
    if (zone && zone?.zone_data && zone?.zone_data?.id) {
      if(collection_date){
        getAvailableSlots(
          collection_date,
          zone && zone?.zone_data && zone?.zone_data.id
        );
      }
    }
    setZoneId(zone?.zone_data?.id);
  };

  const resetAddressComponent = () => {
    setZoneId("");
    setCityId("");
    //setCustomer_areaname("");
    setIsPincode(true)
    setPackageKey(0);
    setZoneName("");
    setCustomer_city("");
    setPinCode("")
  }

  useEffect(() => {
    if (extraCharge) {
      setTotalPrice(Math.floor(price - discount + 200));
    } else {
      setTotalPrice(Math.floor(price - discount));
    }
  }, [price, discount, extraCharge]);

  useEffect(() => {
    if (totalPrice < 0) {
      setTotalPrice(0);
    }
  }, [totalPrice]);

  const applyCoupon = () => {
    if (couponCode === "") {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
      setTnPercentage("")
      setCouponMessage("")
      setTncMessage("")
      return;
    }
    const appliedCoupon =
      couponList.results.find((coup: any) => coup.id === coupon) ||
      couponList.results.find((coup: any) => coup.code === couponCode);
    if (appliedCoupon && appliedCoupon.minimum_price < totalPrice) {
      setCoupon(appliedCoupon.id);
      if (appliedCoupon.discount_type.toLowerCase() === "percentage") {
        if (appliedCoupon.is_red_cash) {
          setDiscount(0);
        }
        else {
          setDiscount(Math.floor((price * appliedCoupon.discount) / 100));
        }
        setTnPercentage(appliedCoupon.tnc !== null ? appliedCoupon.tnc : "")
        setCouponMessage(`Coupon ${appliedCoupon.code}  has been applied successfully`)
        setTncMessage(`${appliedCoupon.tnc !== null ? `TNC : ${appliedCoupon.tnc}` : ""}`)
      }
      if (appliedCoupon.discount_type.toLowerCase() === "amount") {
        if (appliedCoupon.is_red_cash) {
          setDiscount(0);
        }
        else {
          setDiscount(Math.floor(appliedCoupon.discount));
        }
        setTnPercentage(appliedCoupon.tnc !== null ? appliedCoupon.tnc : "")
        setCouponMessage(`Coupon ${appliedCoupon.code}  has been applied successfully`)
        setTncMessage(`${appliedCoupon.tnc !== null ? `TNC : ${appliedCoupon.tnc}` : ""}`)
      }
    } else {
      setCouponCode("");
      setCoupon(0);
      setDiscount(0);
      setTnPercentage("")
      setCouponMessage("")
      setTncMessage("")
    }
  };
  const verifyMobile = (type: any) => {
    if(type==="mobile"){
      if(parseInt(customer_phonenumber&&customer_phonenumber[0])<6||customer_phonenumber&&customer_phonenumber.length<10){
        setMobileValidation((prev: any) => ({
              ...prev,
              customer_phonenumber: false,
        }));}
    }
    if(type==="alternate"){
      if(parseInt(customer_alternatenumber&&customer_alternatenumber[0])<6||customer_alternatenumber&&customer_alternatenumber.length<10){
        setMobileValidation((prev: any) => ({
              ...prev,
              customer_alternatenumber: false,
          }));}
    }
    if(type=== "whatsapp" ){
      if(parseInt(customer_whatsapppnumber&&customer_whatsapppnumber[0])<6||customer_whatsapppnumber&&customer_whatsapppnumber.length<10){
        setMobileValidation((prev: any) => ({
              ...prev,
              customer_whatsapppnumber: false,
            }));}
    }
  };

  const submitForm = async (e: any) => {
    e.preventDefault();

    let packagesIds = new Array();
    inputList &&
      inputList[0]?.customerPackage.map((item: any) => {
        packagesIds.push(item.id);
      });

    let additionalPacakges = new Array();
    inputList &&
      inputList.length > 1 &&
      inputList.map((item: any, index: number) => {
        if (index > 0) {
          additionalPacakges.push(item);
        }
      });

    const data: any = {
      booking_date: moment(new Date()).format("YYYY-MM-DD"),
      collection_date: collection_date,
      collection_slot: selectedSlot,
      customer_name: inputList ? inputList[0]?.customerName : "",
      customer_age: inputList ? inputList[0]?.customerAge : "0",
      customer_gender: inputList ? inputList[0]?.customerGender : "",
      customer_email: customer_email === "" ? defaultEmail : customer_email,
      customer_phonenumber: lead_customer_phonenumber !== undefined ? lead_customer_phonenumber : customer_phonenumber,
      customer_altphonenumber: lead_customer_phonenumber !== undefined ? lead_customer_phonenumber : customer_alternatenumber,
      customer_whatsapppnumber: lead_customer_whatsapppnumber !== undefined ? lead_customer_whatsapppnumber : customer_whatsapppnumber,
      customer_address: customer_address,
      customer_landmark: customer_landmark,
      customer_areapincode: customer_areapincode,
      customer_longitude: customerLongitude,
      customer_latitude: customerLatitude,
      customer_city: customer_city,
      customer_aadhar: customer_aadhar,
      passport_number: passport,
      packages: packagesIds,
      coupon_applied: coupon,
      designation: inputList ? inputList[0]?.customerDesignation : "",
      dob: inputList ? inputList[0]?.dateOfBirth : "",
      book_for: bookingFor || "",
      cityid: cityId,
      additional_member: additionalPacakges,
      address_line2: addressLine2,
      landmark: favoritePlace,
      is_redcash_applied: redCashCheck ? "true" : "false"
    };
    

    if (data["coupon_applied"] === 0) {
      delete data["coupon_applied"];
    }
    if (data["dob"] === "") {
      delete data["dob"];
    }
    if (mobileValidation.customer_alternatenumber && mobileValidation.customer_phonenumber && mobileValidation.customer_whatsapppnumber) {

      if (ageCheck !== undefined || data.customer_age==undefined||data.customer_age===""||data.customer_age===null) {
        alert("Please Provide Customer Age")
      }
      else if (packageCheck !== undefined) {
        alert("Customer Package can't be Blank")
      }
      else {
        await createNewBooking(data);
        resetAddressComponent()
      }
    }
    else {
      if (customer_phonenumber.length !== 10) {
        setMobError("Mobile Number Must be 10 Digit")
      }
      else if (customer_alternatenumber.length !== 10) {
        setAltError("Alternate Number must be 10 Digit")
      }
      else if (customer_whatsapppnumber.length !== 10) {
        setWhatsError("Whatsapppnumber must be 10 Digit")
      }
    }

  };

  useEffect(() => {
    if (createBooking.pk) {
      history.push(`/dashboard/haa/booking-view/${createBooking.pk}`);
      dispatch({ type: ACTIONS.CREATE_BOOKING, payload: "" });
      dispatch({ type: ACTIONS.GET_PIN_CODE, payload: [] });
    }
  }, [createBooking]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdharPassport((event.target as HTMLInputElement).value);
  };

  const changeCollectionDate = (e: any) => {
    setSelectedSlot(0)
    setCollection_date(e.target.value as string);
    if (zoneId) {
      getAvailableSlots(e.target.value, zoneId);
      getPackage(`city_id=${cityId}&org_type=homedx`);
    }
  };
  const handleInputChange = (e: any, index: number) => {
    const { name, value } = e.target;
    if (name === "customerName") {
      const newValue = e.target.value;

      if (!newValue.match(/[@!#^*%&<>()_+=1234567890:~`.\\$'"]/)) {
        setError("");
      } else {
        setError("Forbidden character: @!#^*%&<>()_+=1234567890:~`.\\$'\"");
      }
    }
    const list = [...inputList];
    if (name==="passport") {
      const result = value.replace(/[^A-Z0-9]/, '');
      list[index][name] = result;
    }
    else {
      list[index][name] = value;
    }
    if (e.target.name == "designation") {
      const findGender = GENDER_CHOICE.filter(
        (el: any) => el.display_name == e.target.value
      );
      list[index]["customerGender"] = findGender[0]?.gender;
      list[index]["customerDesignation"] = findGender[0]?.db_name;
    }
    if (e.target.name == "dateOfBirth") {
      let today = new Date();
      let birthDate = new Date(e.target.value);
      let age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      list[index]["customerAge"] = age;
    }
    setInputList(list);
  };

  const handleRemoveClick = (index: any) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const checkNormalPackage = (packages: any) => {
    let isNormalPackage = false;
    packages &&
      packages.length > 0 &&
      packages.map((item: any) => {
        if (!item.is_addon) {
          isNormalPackage = true;
        }
      });
    return isNormalPackage;
  }

  const checkBuyOneGetOneFreePackage = (index: number, packages: any) => {
    if (
      inputList &&
      inputList?.length == 1 &&
      packages &&
      packages?.length > 0
    ) {
      packages.map((item: any) => {
        if (item && item.code == "PL50" && item?.code == "PL50") {
          // if use select buy one get one package
          setInputList([
            ...inputList,
            {
              customerDesignation: "",
              designation: "",
              customerName: "",
              customerAge: "",
              customerGender: "",
              customerPackage: [item],
              packagePrice: item?.offer_price,
              dateOfBirth: "",
            },
          ]);
        }
      });
    }
  }

  const getNoofPackageBuyOneGetOne = () => {
    let noOfBuyOneGetOne = 0;
    inputList &&
      inputList.map((rowItem: any) => {
        if (rowItem?.customerPackage && rowItem?.customerPackage.length > 0) {
          rowItem?.customerPackage.map((packageItem: any) => {
            if (packageItem?.code == "PL50") {
              noOfBuyOneGetOne = noOfBuyOneGetOne + 1;
            }
          });
        }
      });
    return noOfBuyOneGetOne;
  };

  const buyOneGetOnePackagePrice = () => {
    let packagePrice = 0;
    inputList &&
      inputList.map((rowItem: any) => {
        if (rowItem?.customerPackage && rowItem?.customerPackage.length > 0) {
          rowItem?.customerPackage.map((packageItem: any) => {
            if (packageItem?.code == "PL50") {
              packagePrice = packageItem?.offer_price;
            }
          });
        }
      });
    return packagePrice;
  };

  const handlePackage = (index: number, customerPackages: any) => {
    const min_phlebo_cost = packageList && packageList?.results && packageList?.results.length > 0 && packageList?.results[0].min_booking_amount_for_phlebo
    const list = [...inputList];
    list[index]["customerPackage"] = customerPackages;
    setInputList(list);
    let accumulatedPrice: any = 0;
    if (customerPackages && customerPackages.length > 0) {
      let atLeastOneNormalPackage = checkNormalPackage(customerPackages);
      customerPackages.map((item: any) => {
        if (
          atLeastOneNormalPackage &&
          item.is_addon &&
          item.addon_price &&
          item.addon_price > 0
        ) {
          accumulatedPrice = accumulatedPrice + item.addon_price;
        } else {
          if (item?.package_city_prices === null) {
            accumulatedPrice = accumulatedPrice + item.offer_price;
          } else {
            accumulatedPrice =
              accumulatedPrice + item.package_city_prices.offer_price;
          }
        }
      });
    }
    // set price and package for row
    list[index]["packagePrice"] = accumulatedPrice;
    setInputList(list);

    //call function for buy one get one free
    if (inputList?.length == 1) {
      checkBuyOneGetOneFreePackage(index, customerPackages);
    }
    let allPackagePrice = 0;
    inputList &&
      inputList.map((rowItem: any) => {
        allPackagePrice = allPackagePrice + rowItem?.packagePrice;
      });
    //Minus By One Get One Price
    let pl50Count = inputList?.length > 0 ? getNoofPackageBuyOneGetOne() : 0;
    if (pl50Count > 1 && inputList?.length > 1) {
      let noOfFreePackage = 0;
      if (pl50Count === 2) {
        noOfFreePackage = 1;
      } else if (pl50Count === 3) {
        noOfFreePackage = 1;
      } else if (pl50Count === 4) {
        noOfFreePackage = 2;
      } else if (pl50Count === 5) {
        noOfFreePackage = 2;
      } else if (pl50Count === 6) {
        noOfFreePackage = 3;
      } else {
        noOfFreePackage = 0;
      }
      if (noOfFreePackage > 0) {
        let buyOneGetOnePrice = buyOneGetOnePackagePrice();
        let freePackagepPrice = noOfFreePackage * buyOneGetOnePrice;
        allPackagePrice = allPackagePrice - freePackagepPrice;
      }
    }
    setPrice(Math.floor(allPackagePrice));
    setDiscount(0);
    setTotalPrice(Math.floor(allPackagePrice));
    setCoupon(0);
    setCouponCode("");
    setRedCashCheck(false)
    if (allPackagePrice > min_phlebo_cost) {
      setExtraCharge(false);
    } else {
      setExtraCharge(true);
    }
  };
  useEffect(() => {
    const tempPrice = totalPrice;
    const redDiscount = (totalPrice / 100) * redCoin?.redcash_percentage;
    if (redCashCheck) {
      if (!extraCharge) {
        setRedDisc(redDiscount)
      }
    }
    else {
      setRedDisc(0)
    }
  }, [redCashCheck])

  useEffect(() => {
    if (customer_phonenumber) {
      if (customer_phonenumber.length === 10) {
        getRedCoing(customer_phonenumber)
      }
    }
  }, [customer_phonenumber])
  useEffect(() => {
    if (lead_customer_phonenumber) {
      getRedCoing(lead_customer_phonenumber)
    }
  }, [lead_customer_phonenumber])

  useEffect(() => {
    if (redCoin) {
      setRedCoinMessage(`You have ${redCoin.coins} Red Cash`)
      setTotalRedCoin(redCoin.coins)
    }

  }, [redCoin.coins])

  const handleRedCheck = (event: any) => {
    setRedCashCheck(event.target.checked)
  }

  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        customerDesignation: "",
        customerName: "",
        customerAge: "",
        customerGender: "",
        customerPackage: [],
        packagePrice: 0,
        dateOfBirth: "",
      },
    ]);
  };
  const ageCheck = inputList.find((data: any) => data.customerAge === "")
  const packageCheck = inputList.find((data: any) => data?.customerPackage.length === 0)
  const handleCloseMap = () => {
    setMapOpen(false)
  }
  useEffect(() => {
    if(geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude){
      setCustomerLatitude(geoFencesMapMyIndia?.latitude)
      setCustomerLongitude(geoFencesMapMyIndia?.longitude)
    }
  }, [geoFencesMapMyIndia]);

  //mapmyindia
  const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
  const [mapAddressObject, setMapAddressObject] = useState<any>({});
  const [accessToken, setAccessToken] = useState<any>("");

  useEffect(() => {
    if(geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude){
      setCustomerLatitude(geoFencesMapMyIndia?.latitude)
      setCustomerLongitude(geoFencesMapMyIndia?.longitude)
    }
  }, [geoFencesMapMyIndia]);

  useEffect(() => {
    if(mapmyindiaAccessToken?.accesstoken){
      setAccessToken(mapmyindiaAccessToken?.accesstoken)
    }
  }, [mapmyindiaAccessToken]);

  useEffect(() => {
    if (mapAddressObject?.eLoc) {
      getPinCode(mapAddressObject?.addressTokens?.pincode);
      resetAddressComponent();
      setCustomer_landmark(mapAddressObject?.placeAddress);
      setPinCode(mapAddressObject?.addressTokens?.pincode);
      checkGeoFenchMapMyIndia(mapAddressObject?.eLoc, "NA");
    }
  }, [mapAddressObject]);

  

  const locationAutoCompleteSearch = (val: string) => {
    if (val.length > 2) {
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName = "jsonp_callback_" + "data" + id;
        window[callbackName] = (data: any) => {
          delete window[callbackName];
          const ele = document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          const suggestedData = data?.suggestedLocations
          if (suggestedData.length > 0){
            setSuggestedAddres(data?.suggestedLocations);
          }
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          document.getElementsByTagName("head")[0] ||
          document.body ||
          document.documentElement
        ).appendChild(script);
      });
    } 
  };
  //mapmyindia
  document.querySelectorAll("#noTyping").forEach(el=>{
    el.addEventListener("keydown", function(e){
      e.preventDefault()
    })
  })

  
  return (
    <main className={classes.content}>
      <div className={classes.contentTable}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <h6>Search Lead</h6>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
            direction="row"
            justify="flex-start"
          >
            <TextField
              className="input"
              id="search"
              type="search"
              value={search}
              variant="outlined"
              placeholder="Search By Lead Id/ Lead Name/ Phone number"
              onChange={(e) => handleSearch(e)}
              style={{ margin: "0", padding: "0 9px", width: "80%" }}
            />
             <Button
              variant="contained"
              disabled={search.length === 0}
              color="primary"
              onClick={searchLead}
            >
              Search
            </Button>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
        {leads.results && leads.results.length > 0 && (
          <TableContainer className={classes.table}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Lead ID</TableCell>
                  <TableCell align="center">Name</TableCell>
                  <TableCell align="center">Age</TableCell>
                  <TableCell align="center">Gender</TableCell>
                  <TableCell align="center">Mobile Number</TableCell>
                  <TableCell align="center">Whatsapp Number</TableCell>
                  <TableCell align="center">Address</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leads.results.map((lead: any) => {
                  return (
                    <TableRow>
                      <TableCell align="center">{lead.id || ""}</TableCell>
                      <TableCell align="center">
                        {lead.patient_name || ""}
                      </TableCell>
                      <TableCell align="center">
                        {lead.patient_age || ""}
                      </TableCell>
                      <TableCell align="center">
                        {lead.calling_gender || ""}
                      </TableCell>
                      <TableCell align="center">
                        XXXXXXXXXX
                      </TableCell>
                      <TableCell align="center">
                        XXXXXXXXXX
                      </TableCell>
                      <TableCell align="center">{lead.address || ""}</TableCell>
                      <TableCell align="center">
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => selectLead(lead.id)}
                        >
                          Select
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <div className={classes.contentTable}>
        <form onSubmit={submitForm}>
          <Grid container spacing={3} style={{ margin: "1rem auto" }}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Booking Date</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    className="input"
                    name="collection_date"
                    type="date"
                    value={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                        ? `0${new Date().getMonth() + 1}`
                        : new Date().getMonth() + 1
                      }-${new Date().getDate() < 10
                        ? `0${new Date().getDate()}`
                        : new Date().getDate()
                      }`}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "18.5px 14px",
                    }}
                    required
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Sample Collection date</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    className="input"
                    name="collection_date"
                    type="date"
                    value={
                      (collection_date && collection_date.toString()) || ""
                    }
                    // onChange={(e) => setCollection_date(e.target.value as string)}
                    onChange={(e) => changeCollectionDate(e)}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "18.5px 14px",
                    }}
                    required
                    id="noTyping"
                    max={`${new Date().getFullYear() + 1}-${
                      new Date().getMonth() + 1 < 10
                        ? `0${new Date().getMonth() + 1}`
                        : new Date().getMonth() + 1
                    }-${
                      new Date().getDate() < 10
                        ? `0${new Date().getDate()}`
                        : new Date().getDate()
                    }`}
                    min={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                        ? `0${new Date().getMonth() + 1}`
                        : new Date().getMonth() + 1
                      }-${new Date().getDate() < 10
                        ? `0${new Date().getDate()}`
                        : new Date().getDate()
                      }`}
                  />
                </Grid>
              </Grid>
              {!pincode && (
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>&nbsp;</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ fontSize: "10px" }}
                      onClick={() =>setOpenLocationModal(true)}
                      disabled={collection_date === ""}
                    >
                      Select Address
                    </Button>

                    <p>
                      <small>
                        After selecting sample collection date you can enter pin
                        code.
                      </small>
                    </p>
                  </Grid>
                </Grid>
              )}
              {pincode && (
                <Grid
                  container
                  spacing={0}
                  style={{
                    padding: "10px",
                    background: "#eff",
                    marginLeft: "-10px",
                    marginTop: "10px",
                  }}
                >
                  <Grid item xs={12} md={6}>
                    <p>House No./Plot No./Flat No./Door No./Shop/C/-</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>
                      : <strong>{customer_address}</strong>
                    </p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>Apartment/Building/Colony/Block/Sector/ Street/Gali/Road/Chawl</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>
                      : <strong>{addressLine2}</strong>
                    </p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>Landmark/Sublocality</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>
                      : <strong>{favoritePlace}</strong>
                    </p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>Pincode: </p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>
                      : <strong>{pincode}</strong>
                    </p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>Zone </p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>
                      : <strong>{zoneName}</strong>
                    </p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>City </p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <p>
                      : <strong>{customer_city}</strong>
                      &nbsp;{" "}
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ fontSize: "10px" }}
                        onClick={() => setOpenLocationModal(true)}
                        disabled={collection_date === ""}
                      >
                        Edit Address
                      </Button>
                    </p>
                  </Grid>
                  {addressLine2 === "" || customer_address === "" || favoritePlace === "" ? (
                    <p style={{ color: "red", alignItems: "center" }}>All Field are mandatory for  Proceed booking</p>
                  ) : ""}

                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                className="sampleCollectionTime"
                style={{ height: "100%", overflow: "auto" }}
              >
                <p style={{ marginBottom: "1rem" }}>Sample Collection Time</p>
                <div className={classes.slotContainer}>
                  {timeSlots &&
                    timeSlots.length > 0 &&
                    pincode !== "" &&
                    timeSlots.map((slot: any) => {
                      return (
                        slot.available_slots > 0 ? (
                          <Card
                            key={slot.id}
                            className={
                              slot.id === selectedSlot
                                ? classes.selectedSlot
                                : classes.slot
                            }
                            onClick={() => setSelectedSlot(slot.id as Number)}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                color: "black",
                                fontSize: "14px",
                              }}
                            >
                              {slot.start_time}-{slot.end_time}
                            </p>
                            <p style={{ textAlign: "center", color: "green" }}>
                              {" "}
                              Available slots: {slot.available_slots}
                            </p>
                          </Card>
                        ):(
                          <Card
                          key={slot.id}
                          className={
                            slot.id === selectedSlot
                              ? classes.selectedSlot
                              : classes.slot
                          }
                          onClick={() => alert("No Available Slot for this Area")}
                        >
                          <p
                            style={{
                              textAlign: "center",
                              color: "black",
                              fontSize: "14px",
                            }}
                          >
                            {slot.start_time}-{slot.end_time}
                          </p>
                          <p style={{ textAlign: "center", color: "green" }}>
                            {" "}
                            Available slots: {slot.available_slots}
                          </p>
                        </Card>
                        )
                      );
                    })}
                    {}
                </div>
              </Card>
            </Grid>
          </Grid>
          {inputList.map((x: any, index: number) => {
            return (
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-end"
                spacing={3}
                style={{ margin: "1rem auto", background: "#eff" }}
              >
                <Grid item md={1}>
                  {inputList.length !== 1 && (
                    <HighlightOffIcon
                      style={{
                        marginBottom: "1rem",
                        height: "40px",
                        width: "40px",
                        borderRadius: "50%",
                      }}
                      onClick={() => handleRemoveClick(index)}
                    />
                  )}
                  {inputList.length - 1 === index && inputList.length < 5 && (
                    <AddCircleIcon
                      style={{
                        marginBottom: "1rem",
                        height: "40px",
                        width: "40px",
                        borderRadius: "50%",
                      }}
                      onClick={handleAddClick}
                    />
                  )}
                </Grid>

                <Grid item md={2}>
                  <p>Title</p>
                  <Select
                    className="input"
                    name="designation"
                    variant="outlined"
                    value={x.designation}
                    style={{ width: "100%", margin: "0" }}
                    onChange={(e) => handleInputChange(e, index)}
                    required
                  >
                    <MenuItem value="Mrs">Mrs</MenuItem>
                    <MenuItem value="Miss">Miss</MenuItem>
                    <MenuItem value="Ms">Ms</MenuItem>
                    <MenuItem value="Smt">Smt</MenuItem>
                    <MenuItem value="Mr">Mr</MenuItem>
                    <MenuItem value="Baby Boy">Baby Boy</MenuItem>
                    <MenuItem value="Baby Girl">Baby Girl</MenuItem>
                  </Select>
                </Grid>
                <Grid item md={2}>
                  <p>Customer Name</p>
                  <TextField
                    className="input"
                    name="customerName"
                    type="text"
                    placeholder="Enter Name"
                    value={x.customerName || ""}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText={error} // error message
                    error={!!error} 
                    style={{ width: "100%", margin: "0" }}
                    onChange={(e) => handleInputChange(e, index)}
                    required
                  />
                </Grid>
                <Grid item md={2}>
                  <p>DOB</p>
                  <input
                    className="input"
                    name="dateOfBirth"
                    type="date"
                    value={x.dateOfBirth}
                    onChange={(e) => handleInputChange(e, index)}
                    max={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                        ? `0${new Date().getMonth() + 1}`
                        : new Date().getMonth() + 1
                      }-${new Date().getDate() < 10
                        ? `0${new Date().getDate()}`
                        : new Date().getDate()
                      }`}

                    style={{
                      width: "100%",
                      padding: "15px 14px",
                      background: "#eff",
                    }}
                  />
                </Grid>
                <Grid item md={1}>
                  <p>Age</p>
                  <TextField
                    className="input"
                    name="customerAge"
                    type="number"
                    placeholder="Enter Age"
                    value={x.customerAge}
                    disabled={x.dateOfBirth !== ""}
                    variant="outlined"
                    InputProps={{ inputProps: { min: 0 } }}
                    style={{ width: "100%", margin: "0" }}
                    onChange={(e) =>
                      handleInputChange(e, index)
                    }
                  />
                </Grid>


                <Grid item md={1}>
                  <p>Gender</p>
                  <TextField
                    className="input"
                    name="customerGender"
                    variant="outlined"
                    value={x.customerGender === "male" ? "Male" : x.customerGender === "female" ? "Female" : ""}
                    style={{ width: "100%", margin: "0" }}
                    onChange={(e) => handleInputChange(e, index)}
                    disabled={true}
                    required={true}
                  />
                </Grid>
                <Grid item md={3}>
                  <p>Package Name</p>
                  <Autocomplete
                    id="customerPackage"
                    key={packageKey}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let packs: any = [];
                        newValue.map((val: any) => {
                          let obj = JSON.parse(JSON.stringify(val, null, " "));
                          packs.push(obj);
                        });
                        handlePackage(index, packs);
                      }
                    }}
                    multiple
                    disableClearable
                    options={packageList?.results || []}
                    limitTags={0}
                    disabled={is_pincode}
                    freeSolo
                    disableCloseOnSelect
                    aria-required
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        getPackage(`city_id=${cityId}&search=${newInputValue}&org_type=homedx`);
                      }, 1000);
                    }}
                    value={x?.customerPackage}
                    getOptionLabel={(option: any) => {
                      return option.name && option.package_city_prices
                        ? option.name.toString() +
                        ", " +
                        option.package_city_prices.tat_time +
                        ", Rs. " +
                        option?.package_city_prices.offer_price + "/-"
                        : option.name + ", " + option?.tat_time + ", Rs. " + option?.offer_price + "/-";
                    }}
                    getOptionDisabled={(option: any) => {
                      const packID = x?.customerPackage.map(
                        (pack: any) => pack.id
                      );
                      return packID.includes(option.id);
                    }}
                    renderOption={(option, { selected }) => (
                      <React.Fragment>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                          checkedIcon={<CheckBoxIcon fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.package_city_prices
                          ? option.name.toString() +
                          ", " +
                          option.package_city_prices.tat_time +
                          ", Rs. " +
                          option?.package_city_prices.offer_price + "/-"
                          : option.name.toString() + ", " + option.tat_time + ", Rs." + option?.offer_price + "/-"}
                      </React.Fragment>
                    )}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Package"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            );
          })}
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ margin: "1rem auto" }}
          >
            <Grid item xs={12} md={5}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Mobile Number</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  {lead_customer_phonenumber !== undefined?(
                    <>
                     <TextField
                     className="input"
                     name="customer_phonenumber"
                     type="text"
                     onWheel={(e:any) => e.target.blur()}
                     disabled
                     value={"XXXXXXXXXX"}
                     placeholder="Enter Mobile Number"
                     variant="outlined"
                     style={{ margin: "0", width: "100%" }}
                   />
                   <p style={{ fontWeight: "bold", color: "#7b1fa2" }}>{redCoin?.coins ? redCoinMessage : ""}</p>
                   {redCoin && redCoin?.coins && redCoin?.coins > 0 ? (
                         <FormControlLabel
                           control={<Checkbox checked={redCashCheck} onChange={handleRedCheck} name="Redeem Redcash" />}
                           label="Redeem Redcash"
                         />
                       ) : ""}
                   </>
                  ):(
                    <>
                      <TextField
                    className="input"
                    name="customer_phonenumber"
                    type="number"
                    onWheel={(e:any) => e.target.blur()}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                    }}
                    value={customer_phonenumber || ""}
                    placeholder="Enter Mobile Number"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_phonenumber(e.target.value as String)
                    }
                    onBlur={() => verifyMobile("mobile")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_phonenumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_phonenumber && 
                      "Incorrect Phone Number"
                    }
                    required
                  />
                  {customer_phonenumber && customer_phonenumber.length === 10 ? (
                    <p style={{ fontWeight: "bold", color: "#7b1fa2" }}>{redCoin?.coins ? redCoinMessage : ""}</p>
                  ) : ""}
                  {customer_phonenumber && customer_phonenumber.length === 10 ? (
                    redCoin && redCoin?.coins && redCoin?.coins > 0 ? (
                      <FormControlLabel
                        control={<Checkbox checked={redCashCheck} onChange={handleRedCheck} name="Redeem Redcash" />}
                        label="Redeem Redcash"
                      />
                    ) : ""
                  ) : ""}
                    </>
                  )}
                
                </Grid>
              </Grid>
              <p style={{ color: "red" }}>{mobError}</p>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Alternate Mobile Number</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  {lead_customer_phonenumber !== undefined?(
                      <TextField
                      className="input"
                      name="customer_alternatenumber"
                      type="text"
                      disabled={lead_customer_phonenumber !== undefined}
                      value={"XXXXXXXXXX"}
                      placeholder="Enter Alternate Mobile Number"
                      variant="outlined"
                      style={{ margin: "0", width: "100%" }}
                    />
                  ):(
                    <TextField
                    className="input"
                    name="customer_alternatenumber"
                    type="number"
                      onInput = {(e:any) =>{
                        e.target.value= Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                    }}
                    onWheel={(e:any) => e.target.blur()}
                    value={customer_alternatenumber || ""}
                    placeholder="Enter Alternate Mobile Number"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_alternatenumber(e.target.value as String)
                    }
                    onBlur={() => verifyMobile("alternate")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_alternatenumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_alternatenumber &&
                      "Incorrect Phone Number"
                    }
                    required
                  />
                  )}
                 
                </Grid>
              </Grid>
              <p style={{ color: "red" }}>{altError}</p>

              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Email</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    className="input"
                    name="customer_email"
                    type="email"
                    placeholder="customer.reports@redcliffelabs.com"
                    value={customer_email}
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_email(e.target.value as String)
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <p>Whatsapp Number</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  {lead_customer_whatsapppnumber !== undefined?(
                     <TextField
                     className="input"
                     name="customer_whatsapppnumber"
                     type="text"
                     placeholder="Enter Whatsapp Number"
                     disabled
                     value={"XXXXXXXXXX"}
                     variant="outlined"
                     style={{ margin: "0", width: "100%" }}
                   />
                  ):(
                    <TextField
                    className="input"
                    name="customer_whatsapppnumber"
                    type="number"
                      onInput = {(e:any) =>{
                        e.target.value= Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                    }}
                    onWheel={(e:any) => e.target.blur()}
                    placeholder="Enter Whatsapp Number"
                    value={customer_whatsapppnumber || ""}
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_whatsapppnumber(e.target.value as String)
                    }
                    required
                    onBlur={() => verifyMobile("whatsapp")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_whatsapppnumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_whatsapppnumber &&
                      "Incorrect Whatsapp Number"
                    }
                  />
                  )}
                 
                </Grid>
              </Grid>
              <p style={{ color: "red" }}>{whatsError}</p>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">Aadhaar/Passport</FormLabel>
                    <RadioGroup
                      aria-label="Choose"
                      name="adharpassport"
                      value={adharPassport}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="customer_aadhar"
                        control={<Radio />}
                        label="Aadhaar Number"
                      />
                      <FormControlLabel
                        value="passport_number"
                        control={<Radio />}
                        label="Passport Number"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    className="input"
                    name="customer_aadhar"
                    type="text"
                    placeholder={
                      adharPassport === "customer_aadhar"
                        ? "Enter Aadhaar Number"
                        : "Enter Passport Number"
                    }
                    value={
                      adharPassport === "customer_aadhar"
                        ? customer_aadhar
                        : passport
                    }
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onInput={(e: any) => {
                      e.target.value = adharPassport === "customer_aadhar" ? (e.target.value)
                        .toString()
                        .slice(0, 12) : (e.target.value)
                        .toString()
                        .slice(0, 10)
                    }}
                    onChange={(e) =>
                    adharPassport === "customer_aadhar" ?
                        setCustomer_aadhar(e.target.value.replace(/[^0-9]/, '') as String) : setPassport(e.target.value.replace(/[^A-Z0-9]/, '') as String)
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} style={{ paddingTop: "35px" }}>
                  <p>Booking For</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    className="input"
                    name="booking_for"
                    variant="outlined"
                    value={bookingFor}
                    style={{ width: "100%", margin: "0" }}
                    onChange={(e) => setBookingFor(e.target.value as string)}
                  >
                    <MenuItem value="self">Self</MenuItem>
                    <MenuItem value="family">Family</MenuItem>
                    <MenuItem value="friend">Friend</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={7} style={{ padding: "10px" }}>
              <div className="price-details-new-booking">
                <table style={{ width: "100%", margin: "0" }}>
                  <thead>
                    <tr>
                      <td className="cell" align="center">
                        S No.
                      </td>
                      <td className="cell" align="center">
                        Name
                      </td>
                      <td className="cell" align="center">
                        Package
                      </td>
                      <td className="cell" align="center">
                        Price
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {inputList &&
                      inputList.length > 0 &&
                      inputList.map((customerDetails: any, index: number) => {
                        return (
                          <tr>
                            <td className="cell" align="center">
                              {index + 1}
                            </td>
                            <td className="cell" align="left">
                              {customerDetails.designation}{" "}
                              {customerDetails.customerName}
                            </td>
                            <td className="cell" align="left">
                              {customerDetails?.customerPackage &&
                                customerDetails?.customerPackage.length > 0 &&
                                customerDetails?.customerPackage.map(
                                  (packageItem: any, index: any) => {
                                    return (
                                      <ul>
                                        <li>
                                          <a
                                            onClick={() => {
                                              setSinglePackage(packageItem);
                                              setPaneOpen(true);
                                            }}
                                            className={classes.packageLink}
                                          >
                                            {packageItem?.name}<br /> DESCRIPTION : {packageItem?.description}
                                            <br />
                                            TaT : {packageItem.package_city_prices
                                              ? packageItem.package_city_prices.tat_time
                                              : packageItem.tat_time}
                                            <br />
                                            Price : {packageItem.package_city_prices
                                              ? packageItem.package_city_prices.offer_price
                                              : packageItem.offer_price}
                                            <br />
                                            Fasting : {packageItem?.fasting_time}
                                          </a>
                                        </li>
                                      </ul>
                                    );
                                  }
                                )}
                            </td>
                            <td className="cell" align="center">
                              ₹ {customerDetails?.packagePrice}
                            </td>
                          </tr>
                        );
                      })}
                    <tr>
                      <td colSpan={4}>
                        <table style={{ width: "100%" }}>
                          <tr>
                            <td className="cell" align="left" rowSpan={4} colSpan={2} style={{ width: "60%" }} valign="top">
                              <Grid container spacing={1} direction="row">
                                <Grid item xs={12}>
                                  <p>Enter Coupon</p>
                                </Grid>
                                <Grid item xs={8}>
                                  <Autocomplete
                                    id="coupon"
                                    onChange={(event, newValue) => {
                                      if (newValue) {
                                        let obj = JSON.parse(
                                          JSON.stringify(newValue, null, " ")
                                        );
                                        setCoupon(obj.id as Number);
                                        setCouponCode(obj.code as string);
                                        setCouponType(obj.is_red_cash)
                                        setRedCouponDiscount(obj.discount as number)
                                      }
                                    }}
                                    inputValue={couponCode}
                                    options={couponList?.results || []}
                                    freeSolo
                                    blurOnSelect
                                    disabled={redCashCheck}
                                    onInputChange={(event, newInputValue) => {
                                      clearTimeout(timer.current);
                                      setCouponCode(newInputValue);
                                      timer.current = setTimeout(() => {
                                        getCoupons(`?code=${newInputValue}&org_type=homedx`);
                                      }, 1000);
                                      if (newInputValue === "") {
                                        setCoupon(0);
                                        setDiscount(0);
                                        setTncMessage("")
                                        setCouponMessage("")
                                      }
                                    }}
                                    getOptionLabel={(option: any) => option.code}
                                    getOptionDisabled={(option: any) =>
                                      option.minimum_price > totalPrice
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        className="input"
                                        {...params}
                                        placeholder="Enter Coupon"
                                        variant="outlined"
                                        style={{
                                          width: "100%",
                                          margin: "0",
                                          padding: "0",
                                        }}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <Button
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                    disabled={redCashCheck}
                                    onClick={applyCoupon}
                                  >
                                    Apply
                                  </Button>
                                </Grid>
                                <p style={{ color: "#8e24aa", fontSize: "14px", fontWeight: "bold", alignItems: "center" }}>
                                  {couponMessage} <br />
                                  {tncMessage}
                                </p>
                              </Grid>
                            </td>
                            <td className="cell" align="right" style={{ width: "20%" }}>
                              Discount
                            </td>
                            <td className="cell" align="center" style={{ width: "20%" }}>
                              ₹ {Math.floor(discount)}
                            </td>
                          </tr>
                          <tr>
                            <td className="cell" align="right" style={{ width: "20%" }}>
                              RedCash Discount
                            </td>
                            <td className="cell" align="center" style={{ width: "20%" }}>
                              ₹ {redDisc > 0 ? Math.floor(redDisc) : 0}
                            </td>
                          </tr>
                          <tr>
                            <td className="cell" align="right">
                              Phlebo Cost
                            </td>
                            <td className="cell" align="center">
                              ₹ {extraCharge ? "+ 200" : "0"}
                            </td>
                          </tr>
                          <tr>
                            <td className="cell" align="right" >
                              <h4 className="totalPrice">Total Price</h4>
                            </td>
                            <td className="cell" align="center">
                              <h4 className="totalPrice">
                                ₹ {redDisc > 0 ? (Math.floor(totalPrice) - Math.floor(redDisc)) : Math.floor(totalPrice)}
                              </h4>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{ marginLeft: "1rem", marginBottom: "20px" }}
              disabled={loading || selectedSlot == 0 ||error!==""|| sameNumberBooking === true || addressLine2 === "" || customer_address === "" || favoritePlace === "" || !singlePackage||is_pincode}
            >
              Proceed for booking
            </Button>
            {/* <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{ marginLeft: "1rem", marginBottom: "20px" }}
              disabled={loading || !selectedSlot || sameNumberBooking === false}
            >
              Proceed With Same booking
            </Button> */}
          </div>

          {/* Location Map Modal  */}
          <Dialog
            open={openLocationModal}
            onClose={() => setOpenLocationModal(false)}
            aria-labelledby="form-dialog-title"
            className={classes.dialog}
          >
            <DialogTitle id="form-dialog-title">Add New Address</DialogTitle>
            <DialogContent>
              <Grid
                container
                spacing={2}
                style={{
                  margin: "0px 10px 10px 10px",
                  minWidth: "500px",
                  width: "70%",
                }}
              >
                <Grid item xs={12} md={12}>
                  <p>Locality</p>
                    <Autocomplete
                    id="colony-area-sector"
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setMapAddressObject(obj);
                      }
                    }}
                    options={suggestedAddress ? suggestedAddress : []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    getOptionLabel={(option: any) => `${option?.placeName}, ${option?.placeAddress}`}
                    filterOptions={(option: any) => option}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        locationAutoCompleteSearch(newInputValue);
                      }, 500);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Search Address"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        disabled={collection_date===""}
                        required
                      />
                    )}
                  />
                  <p style={{color:"red"}}>{errorMessage}</p>
                  
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>Pin Code</p>
                  <TextField
                    className="input"
                    name="zone"
                    type="text"
                    value={pincode || 0}
                    disabled
                    variant="outlined"
                    style={{ width: "100%", margin: "0", background: "#ccc" }}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>Area</p>
                  <TextField
                    className="input"
                    name="Area Name"
                    type="text"
                    value={zoneName || ""}
                    disabled
                    variant="outlined"
                    style={{ width: "100%", margin: "0", background: "#ccc" }}
                    required
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <p>House No./Plot No./Flat No./Door No./Shop/ c/o</p>
                  <TextField
                    className="input"
                    name="zone"
                    type="text"
                    value={customer_address}
                    variant="outlined"
                    style={{ width: "100%", margin: "0" }}
                    required
                    onChange={(e) => setCustomer_address(e.target.value as string)}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>Apartment/Building /Colony/ Block/Sector/ Street/Gali/Road/Chawl</p>
                  <TextField
                    className="input"
                    name="zone"
                    type="text"
                    value={addressLine2 || ""}
                    variant="outlined"
                    style={{ width: "100%", margin: "0" }}
                    required
                    onChange={(e) =>
                      setAddressLine2(e.target.value as string)
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>Landmark/Sublocality</p>
                  <TextField
                    className="input"
                    placeholder="Ex: School/College/Restaurant/Shop/Bank/Government Office"
                    name="zone"
                    type="text"
                    value={favoritePlace || ""}
                    variant="outlined"
                    style={{ width: "100%", margin: "0" }}
                    required
                    onChange={(e) => setFavoritePlace(e.target.value as string)}
                  />
                 
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>City</p>
                  <TextField
                    className="input"
                    name="city"
                    type="text"
                    value={customer_city}
                    variant="outlined"
                    style={{ width: "100%", margin: "0", background: "#ccc" }}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpenLocationModal(false)}
                color="primary"
               disabled={addressLine2===""||customer_address===""||favoritePlace===""}
              >
                Select
              </Button>
              <Button
                onClick={() => setOpenLocationModal(false)}
                color="primary"
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      </div>
      <SlidingPane
        className="some-custom-class"
        overlayClassName="some-custom-overlay-class"
        isOpen={isPaneOpen}
        title={singlePackage?.name}
        width="80%"
        from="right"
        onRequestClose={() => {
          setPaneOpen(false);
        }}
      >
        <div>
          <iframe
            src={singlePackage?.web_link}
            style={{ width: "100%", height: "700px" }}
            title="Iframe Example"
          ></iframe>
        </div>
      </SlidingPane>
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  pin_code: state.HealthAdviserAdminReducer.pin_code,
  packageList: state.HealthAdviserAdminReducer.packageList,
  couponList: state.HealthAdviserAdminReducer.couponList,
  createBooking: state.HealthAdviserAdminReducer.createBooking,
  leads: state.HealthAdviserAdminReducer.leads,
  lead_details: state.HealthAdviserAdminReducer.lead_details,
  booking_slots: state.HealthAdviserAdminReducer.booking_slots,
  sameNumberBooking: state.HealthAdviserAdminReducer.sameNumberBooking,
  loading: state.HealthAdviserAdminReducer.loading,
  checkGeoFenceStatus: state.HealthAdviserAdminReducer.checkGeoFenceStatus,
  redCoin: state.HealthAdviserAdminReducer.redCoin,
  addressType: state.HealthAdviserAdminReducer.addressType,
  geoFencesMapMyIndia: state.HealthAdviserAdminReducer.geoFencesMapMyIndia,
  mapmyindiaAccessToken: state.HealthAdviserAdminReducer.mapmyindiaAccessToken,
});

export default connect(mapStateToProps, {
  getPinCode,
  getPackage,
  getPartners,
  getCoupons,
  createNewBooking,
  getLeads,
  getLeadDetails,
  resetLeadDetails,
  getAvailableSlots,
  checkGeoFenceArea,
  getRedCoing,
  getAddresstypeSeating,
  checkGeoFenchMapMyIndia,
  getMapMyIndiaAccessToken
})(BookingWithLocalitySearch);
