import { ACTIONS, LoginActionTypes } from '../interfaces/actionTypes/loginTypes';
import SecureStorage from "../config/SecureStorage";

type LoginInitialState = {
  user: object;
  status: boolean;
  success: boolean;
  otp: any;
  userDetails: Array<any>;
  passwordStatus: boolean;
  notificationMessage: Array<any>;
  locationDataFromPusher: Array<any>;
  loading: boolean;
  loadingOTP: boolean;
  breakResponse: Array<any>;
  addressType: Array<any>;
  userProfile: Array<any>;
  whatsappDataFromPusher: Array<any>;
  whatsappDataFromPusherForSL: Array<any>;
  whatsappAddressIdDetail: Array<any>;
}

const initialState: LoginInitialState = {
  user: {
    username: "",
    email: "",
    age: 0
  },
  status: false,
  success: false,
  otp: false,
  userDetails: [],
  passwordStatus: true,
  loading: false,
  loadingOTP: false,
  notificationMessage: [],
  locationDataFromPusher: [],
  breakResponse: [],
  addressType: [],
  userProfile:[],
  whatsappDataFromPusher: [],
  whatsappDataFromPusherForSL: [],
  whatsappAddressIdDetail: [],
};

const userReducer = (state = initialState, action: LoginActionTypes) => {
  switch (action.type) {
    case ACTIONS.SET_USER:
      return {
        ...state,
        user: action.payload,
        status: true
      };
    case ACTIONS.LOCATION_DATA_FROM_PUSHER:
      return {
        ...state,
        locationDataFromPusher: action.payload,
        // loading: false,
      };
    case ACTIONS.GET_USER:
      return {
        ...state,
        passwordStatus: action.payload,
        status: true
      };
    case ACTIONS.GET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload,
        status: true
      };
    case ACTIONS.RESET_PASSWORD:
      return {
        ...state,
        success: action.payload,
      };
    case ACTIONS.GET_BREAK_RESPONSE:
      return {
        ...state,
        breakResponse: action.payload,
        loading: false,
      };
    case ACTIONS.SEND_OTP:
      return {
        ...state,
        otp: action.payload,
      };
    case ACTIONS.LOGOUT:
      SecureStorage.removeItem("token");
      window.location.href = '/';
      return {
        ...state,
        ...initialState
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case ACTIONS.SET_LOADING_OTP:
      return {
        ...state,
        loadingOTP: action.payload
      };
    case ACTIONS.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_FIREBASE_NOTIFICATION:
      return {
        ...state,
        notificationMessage: action.payload,
        loading: false,
      };
    case ACTIONS.WHATSAPP_DATA_FROM_PUSHER:
      return {
        ...state,
        whatsappDataFromPusher: action.payload,
        // loading: false,
      };
    case ACTIONS.WHATSAPP_DATA_FROM_PUSHER_FOR_SL:
      return {
        ...state,
        whatsappDataFromPusherForSL: action.payload,
        // loading: false,
      };
    case ACTIONS.WHATSAPP_ADDRESS_ID_DETAIL:
      return {
        ...state,
        whatsappAddressIdDetail: action.payload,
      };
    case ACTIONS.GET_ADDRESS_TYPE_SEATING:
      return {
        ...state,
        addressType: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default userReducer;
