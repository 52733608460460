import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Container, Grid, TextField } from "@material-ui/core";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { generateBookingFilterUrl } from "../../helpers/generateUrl";
import { connect } from "react-redux";
import "./filter.sass";
import { useHistory } from "react-router";
import CallIcon from '@mui/icons-material/Call';
import Fab from '@mui/material/Fab';
import InventoryIcon from '@mui/icons-material/Inventory';
import PackagesSearch from "./PackagesSearch";


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        iframe: {
            // border: "1px solid",
            position: "fixed",
            /* top: 0; */
            zIndex: 999,
            bottom: 20,
            right: "2rem",
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        contentHeader: {
            width: "100%",
            padding: "0px 10px 40px 10px",
        },
        leftContentHeader: {
            width: "70%",
            float: "left",
        },
        rightContentHeader: {
            width: "30%",
            float: "right",
            textAlign: "right",
            paddingRight: "20px",
        },
        contentTable: {
            width: "100%",
            minHeight: "400px",
        },
        contentFilter: {
            width: "100%",
            padding: "0px 10px 10px 0px",
        },
        card: {
            height: "100%",
            padding: theme.spacing(1),
        },
        card_grid: {
            // wordSpacing:3,
            justifyContent: "space-between",
        },
        model: {
            background: "#fff",
            height: "65vh",
            minWidth: "23rem",
            position: "absolute",
            top: "-36rem",
            right: 0,
            boxShadow: "1px 3px 6px #495057",
            borderRadius: "5px",
        },
        model1: {
            background: "#fff",
            height: "65vh",
            minWidth: "23rem",
            position: "absolute",
            top: "-36rem",
            right: 0,
            boxShadow: "1px 3px 6px #495057",
            borderRadius: "5px",
            overflow: "scroll"
        },
        hideclass: {
            display: "none"
        },
        hideclass1: {
            display: "none"
        }
    })
);

interface Props {
    userDetails: any;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
    userDetails
}) => {
    const classes = useStyles();
    const history = useHistory();

    const [show, setShow] = useState(false);
    const [showPackage, setShowPackage] = useState(false);


    return (
        <>
            <div className={classes.iframe}>
                {userDetails?.profile?.pilot_user ? (
                    <div className={show ? classes.model : classes.hideclass}>
                        <iframe src={`https://agent1.cloudagent.in/toolbar_widget/index.html?apiKey={apiKey}&customer=redcliffe_lab&agentid=${userDetails?.profile?.username}&phoneNumber=${userDetails?.profile?.phonenumber}&pin=123&action=formLogin#dashboard-agent`} style={{ width: "100%", height: "100%", border: 0 }} sandbox="allow-scripts allow-forms allow-same-origin allow-popups allow-modals allow-downloads" allow="geolocation; microphone; display-capture"></iframe>
                    </div>
                ) : ""}
                
                    <div className={showPackage ? classes.model1 : classes.hideclass1}>

                        <PackagesSearch
                            show={showPackage}
                        />

                    </div>
              



                {/* {userDetails?.profile?.pilot_user ? (
                    <Fab color="primary" aria-label="add" onClick={() => setShow(!show)}>
                        <CallIcon />
                    </Fab>
                ) : ""} */}
                <Fab color="success" aria-label="add" style={{ marginLeft: "10px" }} onClick={() => setShowPackage(!showPackage)}>
                    <InventoryIcon />
                </Fab> 


                {/* <div  style={{border:"1px solid black",borderRadius:"50%",width:"50px",height:"50px"}}>

                </div> */}
            </div>


        </>
    );
};

const mapStateToProps = (state: any) => ({
    userDetails: state.BookingReducer.userDetails,
});

export default connect(mapStateToProps, {

})(CustomerLeadFilterPage);