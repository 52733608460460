import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import { Grid, Paper, Dialog } from "@material-ui/core";
import "./Dashboard.sass";
import { Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Button from '@mui/material/Button';
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import CallIcon from "@material-ui/icons/Call";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import VisibilityTwoToneIcon from "@material-ui/icons/VisibilityTwoTone";
import { connect } from "react-redux";
import CommentsModal2 from "../Comments/Comment2";
import CommentsModal from "../Comments/comment";
import WhatsAppModal from "../Comments/whatsappModal"
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import MessageIcon from "@material-ui/icons/Message";
import FollowupLeads from "../../tables/followupLeads";
import MissedCallsList from "../../tables/MisssedCallsList";
import LatestWhatsapp from "../../tables/latestWhatsapp";
import TopRcFreshLead from "../../tables/Top10RcFresh";
import CallStats from "../../components/CallsStats/index";
import Loader from "../../components/Loader2";
import { WhatsApp } from "@material-ui/icons";
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);
const StyledTableCellBordered: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
      border: "0.6px solid grey",
    },
    body: {
      fontSize: 14,
      border: "0.6px solid grey",
    },
  })
)(TableCell);
const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    heading: {
      fontWeight: "normal",
      fontSize: "1.3rem",
    },
    leadDetails: {
      background: "rgb(146, 74, 145)",
      color: "white",
      width: "100%",
      overflow: "hidden",
    },
    leadData: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      paddingLeft: "1rem",
    },
    leadInput: {
      width: "100%",
      padding: "1rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: "1rem",
    },
    leadButtons: {
      width: "100%",
      padding: "1rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      paddingLeft: "1rem",
    },
  })
);

interface Props {
  getLeadById: any;
  leadById: any;
  clickToCall: any;
  getCities: any;
  cities: any;
  loading: Boolean;
  userDetails: any;
  getLtvLead: any;
  ltvLead: any;
  userStats: any;
  getRedCoing: any;
  redCoin: any
}

const Dashboard: React.FC<Props> = ({
  getLeadById,
  leadById,
  clickToCall,
  getCities,
  cities,
  loading,
  userDetails,
  getLtvLead,
  ltvLead,
  userStats,
  getRedCoing,
  redCoin,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [commentModalOpen, setCommentModalOpen] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [openM, setOpenM] = useState<boolean>(false);
  const [openWhatsapp, setOpenWhatsapp] = useState<boolean>(false);

  const handleClickToCall = (type: any) => {
    clickToCall(leadById.id, type.toLowerCase());
  };
  const handleView = (id: any) => {
    getLtvLead(id);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = () => {
    setOpenM(true);
  };
  const handleClickWhatsapp = () => {
    setOpenWhatsapp(true);
  };
  // useEffect(() => {
  //   getUserStats();

  // }, []);
  useEffect(() => {
    if (leadById?.content_number !== undefined) {
      getRedCoing(leadById?.content_number)
    }
  }, [leadById?.content_number])
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <TableContainer style={{ maxHeight: "580px" }}>
          {loading ? (
            <Loader />
          ) : (
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCellBordered align="center">
                    Parameters
                  </StyledTableCellBordered>
                  <StyledTableCellBordered align="center">
                    Ref Value
                  </StyledTableCellBordered>
                  <StyledTableCellBordered align="center">
                    Customer<span style={{ display: "none" }}>_</span>Name :
                    {ltvLead && ltvLead.booking && ltvLead.booking.length > 0
                      ? ltvLead.booking[0]?.customer_name
                      : ""}{" "}
                    Booking<span style={{ display: "none" }}>_</span>id:
                    {ltvLead && ltvLead.booking && ltvLead.booking.length > 0
                      ? ltvLead.booking[0]?.id
                      : ""}{" "}
                    Collection<span style={{ display: "none" }}>_</span>Date:
                    {ltvLead && ltvLead.booking && ltvLead.booking.length > 0
                      ? ltvLead.booking[0]?.collection_date
                      : ""}
                  </StyledTableCellBordered>
                  <StyledTableCellBordered align="center">
                    Customer<span style={{ display: "none" }}>_</span>Name :
                    {ltvLead && ltvLead.booking && ltvLead.booking.length > 1
                      ? ltvLead.booking[0]?.customer_name
                      : ""}{" "}
                    Booking<span style={{ display: "none" }}>_</span>id:
                    {ltvLead && ltvLead.booking && ltvLead.booking.length > 1
                      ? ltvLead.booking[1]?.id
                      : ""}{" "}
                    Collection<span style={{ display: "none" }}>_</span>Date:
                    {ltvLead && ltvLead.booking && ltvLead.booking.length > 1
                      ? ltvLead.booking[1]?.collection_date
                      : ""}
                  </StyledTableCellBordered>
                  <StyledTableCellBordered align="center">
                    Customer<span style={{ display: "none" }}>_</span>Name :
                    {ltvLead && ltvLead.booking && ltvLead.booking.length > 2
                      ? ltvLead.booking[0]?.customer_name
                      : ""}{" "}
                    Booking<span style={{ display: "none" }}>_</span>id:
                    {ltvLead && ltvLead.booking && ltvLead.booking.length > 2
                      ? ltvLead.booking[2]?.id
                      : ""}{" "}
                    Collection<span style={{ display: "none" }}>_</span>Date:
                    {ltvLead && ltvLead.booking && ltvLead.booking.length > 2
                      ? ltvLead.booking[2]?.collection_date
                      : ""}
                  </StyledTableCellBordered>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {ltvLead &&
                  ltvLead.test_parameters &&
                  ltvLead.test_parameters.map((row: any) => (
                    <StyledTableRow>
                      <StyledTableCellBordered align="center">
                        {row.test_values__test_parameter__test_name}
                      </StyledTableCellBordered>
                      <StyledTableCellBordered align="center">
                        {ltvLead &&
                          ltvLead.booking &&
                          ltvLead.booking.length > 0
                          ? ltvLead.booking[0]?.customer_gender === "female"
                            ? row?.test_values__test_parameter__other_female
                            : row?.test_values__test_parameter__other_male
                          : ""}
                      </StyledTableCellBordered>
                      <StyledTableCellBordered align="center">
                        {ltvLead &&
                          ltvLead.booking &&
                          ltvLead.booking.length > 0 &&
                          ltvLead.booking[0].test_parameters &&
                          ltvLead.booking[0].test_parameters.length > 0 &&
                          ltvLead.booking[0].test_parameters.map((val: any) => {
                            if (
                              val.test_values__test_parameter ===
                              row.test_values__test_parameter__id
                            ) {
                              return String(val.test_values__value).slice(
                                0,
                                25
                              );
                            }
                          })}
                      </StyledTableCellBordered>
                      <StyledTableCellBordered align="center">
                        {ltvLead &&
                          ltvLead.booking &&
                          ltvLead.booking.length > 1 &&
                          ltvLead.booking[1].test_parameters &&
                          ltvLead.booking[1].test_parameters.length > 0 &&
                          ltvLead.booking[1].test_parameters.map((val: any) => {
                            if (
                              val.test_values__test_parameter ===
                              row.test_values__test_parameter__id
                            ) {
                              return String(val.test_values__value).slice(
                                0,
                                25
                              );
                            }
                          })}
                      </StyledTableCellBordered>
                      <StyledTableCellBordered align="center">
                        {ltvLead &&
                          ltvLead.booking &&
                          ltvLead.booking.length > 2 &&
                          ltvLead.booking[2].test_parameters &&
                          ltvLead.booking[2].test_parameters.length > 0 &&
                          ltvLead.booking[2].test_parameters.map((val: any) => {
                            if (
                              val.test_values__test_parameter ===
                              row.test_values__test_parameter__id
                            ) {
                              return String(val.test_values__value).slice(
                                0,
                                25
                              );
                            }
                          })}
                      </StyledTableCellBordered>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Dialog>
      <Paper
        elevation={5}
        style={{ marginBottom: "3rem", padding: "1rem", width: "100%" }}
      >
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="flex-start"
          justify="space-between"
          style={{ margin: "0" }}
        >
          <Grid item xs={12} md={6}>
            <TableContainer component={Paper} style={{ minHeight: "300px" }}>
              {loading ? (
                <Loader />
              ) : (
                <Table stickyHeader aria-label="simple table">
                  <TableHead>
                    <StyledTableRow>
                      <StyledTableCell>
                        {userDetails?.profile?.executive_type === "LTV"
                          ? "LTV Id"
                          : "CFresh Id"}{" "}
                        = {leadById.id}
                      </StyledTableCell>

                      <StyledTableCell style={{ padding: 5 }}>
                        {leadById.lead_source === "LTV" ? (
                          <VisibilityTwoToneIcon
                            style={{ marginLeft: 20, marginRight: 20 }}
                            onClick={() => handleView(leadById.id)}
                          />
                        ) : (
                          ""
                        )}
                        {/* {leadById?.id == undefined ? "Leads not Available...." : (
                          <Link to={`/dashboard/ce/booking/?lead=${leadById?.id}&org_type=${leadById?.org_type}`}>
                            <Button
                              color="primary"
                              variant="contained"
                              size="small"
                            >
                              New Booking
                            </Button>
                          </Link>
                        )} */}

                      </StyledTableCell>
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell>Patient Name</StyledTableCell>

                      <StyledTableCell>
                        {leadById?.patient_name}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>Call Status</StyledTableCell>

                      <StyledTableCell>{leadById.call_status}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>City</StyledTableCell>

                      <StyledTableCell>{leadById.patient_city}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell>FollowUp Date</StyledTableCell>

                      <StyledTableCell>
                        {leadById.followup_date}
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <StyledTableCell style={{ padding: "8px 16px" }}>
                        Action
                      </StyledTableCell>

                      <StyledTableCell style={{ padding: "8px 16px" }}>
                          <Button
                            onClick={() => handleClickWhatsapp()}
                            size="small"
                            variant="contained"
                            color="secondary"
                            disabled={typeof leadById.id === "undefined"}
                          >
                            <WhatsAppIcon />
                          </Button>
                        {leadById.call && (
                          <Button
                            onClick={() => handleClickToCall(`Call`)}
                            size="small"
                            style={{ marginLeft: 10 }}
                            variant="contained"
                            color="primary"
                          >
                            <CallIcon />
                          </Button>
                        )}
                        {/* {leadById.content_number !== leadById.call &&
                          leadById.content_number && (
                            <Button
                              onClick={() => handleClickToCall(`Contact`)}
                              size="small"
                              variant="contained"
                              color="primary"
                            >
                              <CallIcon />
                            </Button>
                          )}
                        {leadById.whatsapp_contact !==
                          leadById.content_number &&
                          leadById.whatsapp_contact !== leadById.call &&
                          leadById.whatsapp_contact && (
                            <Button
                              onClick={() => handleClickToCall(`Whatsapp`)}
                              size="small"
                              variant="contained"
                              color="primary"
                            >
                              <CallIcon />
                            </Button>
                           )} */}
                        <Tooltip title="Comments" arrow>
                          <Button
                            onClick={() => handleClick()}
                            size="small"
                            variant="contained"
                            color="secondary"
                            style={{ marginLeft: 10 }}
                            disabled={typeof leadById.id === "undefined"}
                          >
                            <MessageIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="fill-info" arrow>
                          <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            style={{ marginLeft: 10 }}
                            disabled={typeof leadById.id === "undefined"}
                            onClick={() =>
                              history.push(
                                `/dashboard/ce/fill-info?id=${leadById.id}`
                              )
                            }
                          >
                            <EditIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title="Dispose" arrow>
                          <Button
                            variant="contained"
                            size="small"
                            style={{ marginLeft: 10 }}
                            color="secondary"
                            disabled={typeof leadById.id === "undefined"}
                            onClick={() => setCommentModalOpen(true)}
                          >
                            Dispose
                          </Button>
                        </Tooltip>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Grid>

          <Grid item xs={12} md={6}>
            <CallStats userStats={userStats} redCoin={redCoin} />
          </Grid>
        </Grid>
      </Paper>
      <CommentsModal2
        open={commentModalOpen}
        getCities={getCities}
        cities={cities}
        setOpen={setCommentModalOpen}
        leadId={leadById.id}
      />
      <CommentsModal open={openM} setOpen={setOpenM} leadId={leadById.id} />
      <WhatsAppModal open={openWhatsapp} setOpen={setOpenWhatsapp} leadId={leadById.id} />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.BookingReducer.loading,
});

export default connect(mapStateToProps, {})(Dashboard);
