import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Paper, withStyles, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, TablePagination } from '@material-ui/core';
import { connect } from 'react-redux';
import Loader from '../components/loader/index';
import moment from 'moment';
import { useHistory } from "react-router-dom";
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { clickToCallBooking } from "../actions/HealthAdviserAdminAction";
import CallIcon from "@material-ui/icons/Call";
import { MessageRounded } from '@material-ui/icons';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        tableContainer: {
            position: "sticky",
            top: "200px",
        },

    })
);
const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,fontWeight:600
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);



interface Props {
    getTransferBooking: any;
    transferBookingData: any;
    page: any;
    setPage: any;
    clickToCallBooking: any;
    loading: Boolean;
    setTicketId: any;
    setopenModal: any;
    setTicketStatus: any;
    setRemarks: any;
}

const BookingsTable: React.FC<Props> = ({
    getTransferBooking,
    transferBookingData,
    page,
    setPage,
    clickToCallBooking,
    setTicketId,
    setopenModal,
    setTicketStatus,
    setRemarks,
    loading,
}) => {
    const classes = useStyles()
    const history = useHistory()
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
      ) => {
        if (newPage > page) {
          let url = transferBookingData.links && transferBookingData.links.next.split("?")[1];
          getTransferBooking(`?${url}`);
        } else if (newPage < page) {
          let url = transferBookingData.links && transferBookingData.links.previous.split("?")[1];
          getTransferBooking(`?${url}`);
        }
        setPage(newPage);
      };
    const handleCallClick = (id: number, type: string) => {
        clickToCallBooking(id, type);
    };

    const handleClick = (id: number, tStatus: any, rem: any) => {
        setopenModal(true);
        setTicketId(id);
        setTicketStatus(tStatus)
        setRemarks(rem)
    };
    return (
        <div style={{ width: "100%" }} className="data-table">

            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "600px" }}>
                {loading ? <Loader /> : (
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="center">Action</StyledTableCell>
                                <StyledTableCell align="center">Booking Id</StyledTableCell>
                                <StyledTableCell align="center">Update Ticket</StyledTableCell>
                                <StyledTableCell align="center">Call</StyledTableCell>
                                <StyledTableCell align="center">Customer Name</StyledTableCell>
                                <StyledTableCell align="center">Created At</StyledTableCell>
                                <StyledTableCell align="center">Updated At</StyledTableCell>
                                <StyledTableCell align="center">Remarks</StyledTableCell>
                                <StyledTableCell align="center">Transferd By</StyledTableCell>
                                <StyledTableCell align="center">Ticket Status</StyledTableCell>
                                <StyledTableCell align="center">Agent</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {transferBookingData && transferBookingData?.results && transferBookingData.results.length > 0 && transferBookingData.results.map((data: any, index: any) => {
                                return (
                                    <StyledTableRow key={index}>
                                        <StyledTableCell align="center">
                                            <Link to={`/dashboard/haa/booking-view/${data?.booking?.id}`} style={{ textDecoration: "none" }}>
                                                <Button variant="contained" style={{ backgroundColor: "#87d5fa" }}>View</Button>
                                            </Link>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.booking?.id}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <div>
                                                <MessageRounded onClick={() => handleClick(data?.id, data?.ticket_status, data?.remarks)} />
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <CallIcon
                                                onClick={() => handleCallClick(data?.booking?.id, "call")}
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.booking?.customer_name}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {new Date(data.created_at).toLocaleString()}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {new Date(data.updated_at).toLocaleString()}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.remarks}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.transfer_by?.usergroup}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.ticket_status}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {data?.agent?.name}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                        <TableFooter>
                            <StyledTableRow>
                                <TablePagination
                                    colSpan={6}
                                    count={transferBookingData.count || 0}
                                    rowsPerPageOptions={[]}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                />
                            </StyledTableRow>
                        </TableFooter>
                    </Table>
                )}
            </TableContainer>
            {transferBookingData.results && transferBookingData.results.length === 0 && (
                <h6 style={{ textAlign: "center" }}>No Data Found</h6>
            )}
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    bookingCall: state.HealthAdviserAdminReducer.bookingCall,
})

export default connect(mapStateToProps, {
    clickToCallBooking
})(BookingsTable);

