import React, { useEffect } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button } from '@material-ui/core';
import { getBreakResponse,getPanelBreak } from '../../actions/HealthAdviserAction'
import CircularProgress from "@material-ui/core/CircularProgress";
// import {
//     getBookingsComments,
//     addBookingsComment,
// } from "../../../CustomerLead-Panel/actions/bookingActions";
import "./index.sass";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[10],
        padding: theme.spacing(2, 4, 3),
    },
}));
type ModalProps = {
    openModal: boolean;
    setOpenModal: Function;
    loading: boolean;
    getPanelBreak:any;
    breakResponse: any;
    getBreakResponse: any;
    // bookingsComment: any;
    // getBookingsComments: any;
    // addBookingsComment: any;
};

const BrakModal: React.FC<ModalProps> = ({
    openModal,
    setOpenModal,
    getBreakResponse,
    getPanelBreak,
    breakResponse,
    loading,
    // bookingsComment,
    // getBookingsComments,
    // addBookingsComment,
}) => {
    const classes = useStyles();

    const [comment, setComment] = React.useState<string>("");
    const [message,setMessage]=React.useState<any>("Break Option")

    const handleClose = () => {
        setOpenModal(false);
    };

    useEffect(() => {
        
        getBreakResponse(`self=true`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModal]);
    const handleBreak=()=>{
        getPanelBreak("true")
        setMessage("You are on Break Please click RE JOIN to Start Work")
        setTimeout(() => {
           getBreakResponse(`self=true`)
        }, 100);
        
        //  setOpenModal(false);
    }
    const handleReJoin=()=>{ 
        getPanelBreak("false")
        setMessage("Break Option")
         setOpenModal(false);
    }
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <div className={classes.paper}>
                        <h5>{message}</h5>
                        {breakResponse.results && breakResponse.results.map((breaks: any) => {
                            return (
                                <div>
                                    <Button onClick={()=>handleReJoin()} disabled={breaks.break_button===false} variant="contained" color="primary" style={{ marginLeft: "1rem", marginTop: "2.5rem" }}>
                                        Re Join
                                    </Button>
                                    <Button onClick={()=>handleBreak()} disabled={breaks.break_button===true} variant="contained" color="primary" style={{ marginLeft: "1rem", marginTop: "2.5rem" }}>
                                        Take Break
                                    </Button>

                                </div>
                            )

                        })}

                    </div>
                </Fade>
            </Modal>
        </div >
    );
};

const mapStateToProps = (state: any) => ({
    breakResponse: state.HealthAdviserReducer.breakResponse,
    loading: state.HealthAdviserReducer.loading,
});

export default connect(mapStateToProps, {
    getBreakResponse,
    getPanelBreak,
})(BrakModal);
