export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  SET_USER: 'login/set_user',
  LOGOUT: 'login/logout',
  SET_PROFILE: "login/set_profile",
  RESET_PASSWORD: "login/reset_password",
  SEND_OTP: "login/send_otp",
  SET_LOADING: 'login/set_loading',
  SET_LOADING_OTP: 'login/set_loading_otp',
  PANELUSER_LOGOUT:'login/paneluser_logout',
  GET_FIREBASE_NOTIFICATION:'login/get_leads',
  GET_USER_DETAILS:"login/get_user_details",
  GET_USER:"login/get_user_data",
  GET_BREAK_RESPONSE: "login/get_break_response",
  GET_ADDRESS_TYPE_SEATING: "login/get_address_type_seating",
  GET_USER_PROFILE: "login/get_user_profile",
  LOCATION_DATA_FROM_PUSHER: "login/location_data_from_pusher",
  WHATSAPP_DATA_FROM_PUSHER: "login/whatsapp_data_from_pusher",
  WHATSAPP_DATA_FROM_PUSHER_FOR_SL:"login/whatsapp_data_from_pusher_for_sl",
  WHATSAPP_ADDRESS_ID_DETAIL:"login/whatsapp_address_id_detail"
};

interface SetUser {
  type: typeof ACTIONS.SET_USER
  payload: Array<string | number>
}
interface LocationDataFromPusher {
  type: typeof ACTIONS.LOCATION_DATA_FROM_PUSHER
  payload: Array<any>
}
interface GetUserProfile {
  type: typeof ACTIONS.GET_USER_PROFILE
  payload: Array<string | number>
}
interface GetAddressTypeSeating {
  type: typeof ACTIONS.GET_ADDRESS_TYPE_SEATING;
  payload: Object;
}
interface GetBreakResponse {
  type: typeof ACTIONS.GET_BREAK_RESPONSE;
  payload: Object;
}
interface GetUserDetails {
    type: typeof ACTIONS.GET_USER_DETAILS
    payload: Array<any>
}
interface GetUserDeta {
    type: typeof ACTIONS.GET_USER
    payload: Array<any>
}
interface Logout {
  type: typeof ACTIONS.LOGOUT
  payload: any
}
interface PanelUserLogout {
  type: typeof ACTIONS.PANELUSER_LOGOUT
  payload: any
}
interface SetProfile {
  type: typeof ACTIONS.SET_PROFILE;
  payload: object;
}
interface WhatsappDataFromPusher {
  type: typeof ACTIONS.WHATSAPP_DATA_FROM_PUSHER
  payload: Array<any>
}
interface WhatsappDataFromPusherForSL {
  type: typeof ACTIONS.WHATSAPP_DATA_FROM_PUSHER_FOR_SL
  payload: Array<any>
}
interface WhatsappAddressIdDetail {
  type: typeof ACTIONS.WHATSAPP_ADDRESS_ID_DETAIL
  payload: Array<any>
}
interface ResetPassword {
  type: typeof ACTIONS.RESET_PASSWORD;
  payload: object;
}
interface FireBaseToken {
  type: typeof ACTIONS.GET_FIREBASE_NOTIFICATION;
  payload: object;
}
interface SendOTP {
  type: typeof ACTIONS.SEND_OTP;
  payload: object;
}

interface SetLoadng {
  type: typeof ACTIONS.SET_LOADING
  payload: boolean
}
interface SetLoadngOTP {
  type: typeof ACTIONS.SET_LOADING_OTP
  payload: boolean
}


export type LoginActionTypes = SetUser | Logout | SetProfile | SetLoadng | SetLoadngOTP | GetBreakResponse | ResetPassword | PanelUserLogout | FireBaseToken | GetUserDetails | GetUserDeta | GetAddressTypeSeating | GetUserProfile | LocationDataFromPusher | WhatsappDataFromPusher | WhatsappDataFromPusherForSL | WhatsappAddressIdDetail