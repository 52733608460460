import React, { useEffect, useState, useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory,Link } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from '@mui/material/Button';

import { searchCall } from "../../CustomerExecutive-Panel/actions/bookingActions";
import Loader from "../components/Loader2";
import { ExitToAppRounded, MessageRounded } from "@material-ui/icons";
import ReplyModal from "../components/Comments/replyModal";

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 700,
    },
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    tableContainer: {
      marginTop: "1rem",
      position: "sticky",
      top: "200px",
    },
  })
);

interface Props {
  loadingWhatsapp: boolean;
  whatsappComments: any;
  getWhatsappComments: any;
}

const LeadsTable: React.FC<Props> = ({
  whatsappComments,
  getWhatsappComments,
  loadingWhatsapp,
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState<boolean>(false);
  const [leadId, setLeadId] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(true);

  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    getWhatsappComments("latest=true");
  }, []);

  const viewLead = (id: any) => {
    let body = {
      search_by: "call_id",
      value: `${id}`,
    };
    dispatch(searchCall(body));
    history.push("/dashboard/ce/lead/search");
  };

  const handlereply = (id: any) => {
    setOpen(true);
    setLeadId(id);
    getWhatsappComments("latest=true");
  };

  return (
    <>
      <div className="data-table" style={{ width: "100%" }}>
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ height: "500px" }}
        >
          {loadingWhatsapp ? (
            <Loader />
          ) : (
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Lead ID</StyledTableCell>
                  <StyledTableCell align="center">Created At</StyledTableCell>
                  <StyledTableCell align="center">Message</StyledTableCell>
                  {/* <StyledTableCell align="center">Panel User</StyledTableCell>
                  <StyledTableCell align="center">Mobile</StyledTableCell> */}
                  <StyledTableCell align="center">Reply</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                  <StyledTableCell align="center">Booking</StyledTableCell>

                </StyledTableRow>
              </TableHead>
              <TableBody>
                {whatsappComments &&
                whatsappComments.results &&
                whatsappComments.results.length > 0 ? (
                  whatsappComments.results.map((item: any) => {
                    return (
                      <StyledTableRow key={item.id}>
                        <StyledTableCell align="center">
                          {item.lead ? item.lead : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {new Date(item.created_at).toLocaleString()}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.message ? item.message : "NA"}
                        </StyledTableCell>
                        {/* <StyledTableCell align="center">
                          {item.panel_user ? item.panel_user : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.mobile_no ? item.mobile_no : "NA"}
                        </StyledTableCell> */}
                        <StyledTableCell align="center">
                          <MessageRounded
                            onClick={() => handlereply(item.lead)}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {item.status ? item.status : "NA"}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <ExitToAppRounded
                            onClick={() => viewLead(item.lead)}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                        <Link to={`/dashboard/ce/booking/?lead=${item?.lead}`}>
                          <Button
                            color="primary"
                            variant="contained"
                            size="small"
                          >
                            New Booking
                          </Button>
                        </Link>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                ) : (
                  <p style={{ textAlign: "center" }}>No data found</p>
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </div>
      {open && (
        <ReplyModal
          open={open}
          setOpen={setOpen}
          setLeadId={setLeadId}
          leadId={leadId}
          callApi={"no"}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loadingWhatsapp: state.BookingReducer.loadingWhatsapp,
});

export default connect(mapStateToProps, { searchCall })(LeadsTable);
