import { ACTIONS } from "../interfaces/actionTypes/CollectionCenterAdmin";
import AXIOS from "../../config/Axios";
import Prefix from "../../config/ApiPrefix";
import PrefixBeta from "../../config/ApiPrefixBeta";
import snackBarUpdate from "../../actions/snackBarActions";
import SecureStorage from "../../config/SecureStorage";

export const changePassword = (body: any) => async (
    dispatch: Function
) => {
    try {
        dispatch({ type: ACTIONS.SET_LOADING, payload: true });
        await AXIOS.post(`${Prefix.api}/auth/password_reset/reset_password/`,
            body,
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            });
        dispatch({ type: ACTIONS.CHANGE_PASSWORD, payload: null });
        snackBarUpdate({
            payload: {
                message: "Password Updated",
                status: true,
                type: "success",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
    } catch (err: any) {
        let title = "";
        if (err.response) {
          title = JSON.stringify(err.response.data?.errors).replace(
            /"|{|}|[|]/g,
            ""
          );
        } else {
          title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const clickToCall = (id: number, contact_type: string) => async (
    dispatch: Function
) => {
    try {
        await AXIOS.get(`${Prefix.api}/lead/clicktocall/${id}/${contact_type}/`, {
            headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        });
        dispatch({ type: ACTIONS.CLICK_TO_CALL, payload: null });
        snackBarUpdate({
            payload: {
                message: "Call Succeded",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err.response) {

            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const getPinCode = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
  
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/phlebo/pincode/${url ? `?code=${url}` : ""}`,
  
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_PIN_CODE, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getPhlebos = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/phlebo/data/${url ? `?code=${url}` : ""}`,

            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_PHLEBOS, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getCities = (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/core/cities/${url ? `?${url}` : ""}`,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_CITIES, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getAreaName = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/core/city-area/${url ? `?id=${url}` : ""}`,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_AREA, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getAgentList = (url: string, usergroup: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/panel/data/${url ? `?code=${url}` : ""}${usergroup ? `${url ? "&" : "?"}usergroup=${usergroup}` : ""}`,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        if (usergroup) {
            dispatch({ type: ACTIONS.GET_AGENTS_BY_USERGROUP, payload: data });
        } else {
            dispatch({ type: ACTIONS.GET_AGENTS, payload: data });
        }
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getPackage = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/package/crmdata/${url ? `?${url}` : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_PACKAGES, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getPartners = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/partner/data/${url ? `?code=${url}` : ""}`,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_PARTNERS, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getCoupons = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/coupon/data/${url ? `${url}` : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_COUPONS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  

export const createNewBooking =
  (bookingData: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/booking/data/${id ? `?${id}` : ""}`,
        bookingData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_BOOKING, payload: data });
      snackBarUpdate({
        payload: {
          message: "Booked",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
    } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
    } else {
        title = "Something went wrong!";
    }

      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getAllBookings = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/booking/data/${url ? `?${url}` : ""}`,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_BOOKING_LIST, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.detail;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong!",
                status: true,
                type: err.response.data.detail === "An Email has been sent to your registered mail address" ? "success" : "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.GET_BOOKING_LIST, payload: [] });
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getBookings = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/booking/data/${url ? `search?id=${url}` : ""}`,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_BOOKING_LIST, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong!",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.GET_BOOKING_LIST, payload: [] });
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getBookingById = (id: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/booking/data/${id ? `${id}/` : ""}`,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_BOOKING_BY_ID, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong!",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const updateBooking = (bookingData: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.put(
            `${Prefix.api}/booking/data/${id}/`,
            bookingData,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.CREATE_BOOKING, payload: data });
        snackBarUpdate({
            payload: {
                message: "Booking Updated",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err?.response?.data?.errors) {
            title = JSON.stringify(err.response.data.errors).replace(
              /"|{|}|[|]/g,
              ""
            );
        } else if (err?.response?.data?.detail) {
            title = JSON.stringify(err.response.data.detail).replace(
              /"|{|}|[|]/g,
              ""
            );
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getLeadDetails =
  (id: string, shownumber: string = "false") =>
  async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      if (shownumber === "true") {
        var { data } = await AXIOS.get(
          `${Prefix.api}/lead/lead-update/${id}/`,
          {
            headers: {
              Authorization: `Token ${SecureStorage.getItem("token")}`,
            },
          }
        );
      } else {
        var { data } = await AXIOS.get(
          `${Prefix.api}/lead/lead-update/${id}/`,
          {
            headers: {
              Authorization: `Token ${SecureStorage.getItem("token")}`,
            },
          }
        );
      }
      dispatch({ type: ACTIONS.GET_LEAD_DETAILS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong!",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const updateLeadDetailsF_2 = (id: string, body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    const { followup_date, followup_time, language } = body;
    try {
        const { data } = await AXIOS.patch(
            `${Prefix.api}/lead/lead-update/${id}/`, { followup_date, followup_time, language },
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_LEAD_DETAILS, payload: data });
        snackBarUpdate({
            payload: {
                message: "Success",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong!",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const updateLeadDetailsF_1 = (id: string, body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    const {
        patient_name,
        patient_age,
        calling_gender,
        priority,
        content_number,
        whatsapp_contact,
        address,
        patient_city,
        patient_area, } = body;
    try {
        const { data } = await AXIOS.patch(
            `${Prefix.api}/lead/lead-update/${id}/`, {
            patient_name,
            patient_age,
            calling_gender,
            priority,
            content_number,
            whatsapp_contact,
            address,
            patient_city,
            patient_area,
        },
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_LEAD_DETAILS, payload: data });
        snackBarUpdate({
            payload: {
                message: "Success",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong!",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getLeads = (body: any, url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/panel/search/${url ? `?${url}` : ""}`,
        body,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_LEADS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong!",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const resetLeadDetails = () => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.LEAD_DETAILS_RESET });
  };

export const getLeadById = () => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(`${Prefix.api}/lead/dashboard/`,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_LEAD_BY_ID, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong!",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getPositiveCallsComments = (id: number) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/lead/lead-related-comments/${id}/`,
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.GET_POSTIVE_CALLS_COMMENTS,
            payload: data,
        });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const addPositiveCallComment = (body: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    const { comment, callId } = body;
    try {
        const { data } = await AXIOS.post(
            `${Prefix.api}/lead/lead-details/${callId}/`,
            {
                comment,
            },
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        snackBarUpdate({
            payload: {
                message: "Created!",
                status: true,
                type: "success",
            },
        })(dispatch);

        dispatch({
            type: ACTIONS.ADD_POSTIVE_CALLS_COMMENT,
            payload: data,
        });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};


export const setCFreshComments = (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    const {
        category,
        sub_category,
        lead_comment,
        comment,
    } = body;
    try {
        const { data } = await AXIOS.post(
            `${Prefix.api}/lead/status-allot/`,
            {
                category,
                sub_category,
                lead_comment,
                comment,
            },
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.SET_COMMENTS, payload: data });
        snackBarUpdate({
            payload: {
                message: "Success!",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err?.response?.data?.errors) {
            title = JSON.stringify(err.response.data.errors).replace(
              /"|{|}|[|]/g,
              ""
            );
        } else if (err?.response?.data?.detail) {
            title = JSON.stringify(err.response.data.detail).replace(
              /"|{|}|[|]/g,
              ""
            );
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const coustomFileUpload = (file: File, usertype: String, source: String) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const formData = new FormData();
        formData.append("file", file);
        const { data } = await AXIOS.post(
            `${Prefix.api}/upload/custom-upload/?usertype=${usertype}&source=${source}`,
            formData,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.UPLOAD_FILE, payload: data });
        snackBarUpdate({
            payload: {
                message: "Success!",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const clickToCallBooking = (id: number, contact_type: string) => async (
    dispatch: Function
) => {
    try {
        await AXIOS.get(`${Prefix.api}/booking/clicktocall/${id}/${contact_type}/`, {
            headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        });
        dispatch({ type: ACTIONS.CLICK_TO_CALL, payload: null });
        snackBarUpdate({
            payload: {
                message: "Call Succeded",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const resetBookings = () => async (dispatch: Function) => {
    dispatch({
        type: ACTIONS.BOOKING_RESET
    });
};

export const addComment = (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    const { comment, callId } = body;
    try {
        const { data } = await AXIOS.post(
            `${Prefix.api}/lead/lead-details/${callId}/`,
            {
                comment,
            },
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        snackBarUpdate({
            payload: {
                message: "Created!",
                status: true,
                type: "success",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.ADD_COMMENT, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const createCall = (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    const { name, phone_no, source } = body;

    try {
        const { data } = await AXIOS.post(
            `${Prefix.api}/lead/generate-lead-by-user/`,
            {
                name,
                phone_no,
                source
            },
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.CREATE_CALL, payload: data.lead });
        snackBarUpdate({
            payload: {
                message: "Success!",
                status: true,
                type: "success",
            },
        })(dispatch);
        // window.location.assign("/agent/dashboard/patient/create-appointment");
    } catch (err: any) {
        let title = "";
        if (err.response) {

            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getLeadSource = () => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/lead/lead-source/`,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_LEAD_SOURCE, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {

            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getAllRecordings = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/communication/call_status/${url ? url : ""}`,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_RECORDINGS, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getBookingsComments = (id: number) => async (
    dispatch: Function
) => {
    // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/lead/booking-related-comments/${id}/`,
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.GET_BOOKINGS_COMMENTS,
            payload: data,
        });
    } catch (err:any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const addBookingsComment = (body: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    const { comment, bookingId } = body;
    try {
        const { data } = await AXIOS.post(
            `${Prefix.api}/lead/booking-details/${bookingId}/`,
            {
                comment,
            },
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        snackBarUpdate({
            payload: {
                message: "Created!",
                status: true,
                type: "success",
            },
        })(dispatch);

        dispatch({
            type: ACTIONS.ADD_BOOKINGS_COMMENT,
            payload: data,
        });
    } catch (err:any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getWhatsappComments = (url: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/communication/whatsapp_message_comment/${url ? `?${url}` : ""}`,
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.GET_WHATSAPP_COMMENTS,
            payload: data,
        });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getWhatsappCommentsByLead = (url: string) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/communication/whatsapp_message_comment/?${url}`,
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.GET_WHATSAPP_COMMENTS_BY_LEAD,
            payload: data,
        });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const sendWhatsappComments = (leadId: any, message: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.post(
            `${Prefix.api}/communication/send_whatsapp_message/${leadId}/`,
            { message },
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.SEND_WHATSAPP_COMMENTS,
            payload: data,
        });
        snackBarUpdate({
            payload: {
                message: "Message Created",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getCallStats = () => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/analytics/panaluser_calls/`,
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.GET_CALL_STATS,
            payload: data,
        });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const raiseNewComplaint = (type: any, body: any, id: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.post(
            `${Prefix.api}/booking/${type}/create/${id}/`,
            body,
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.RAISE_COMPLAINT,
            payload: data,
        });
        snackBarUpdate({
            payload: {
                message: "Ticket Raised",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getQueryTickets = (type: any, id: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/booking/${type}/data/${id ? `?${id}` : ""}`,
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.GET_COMPLAINTS,
            payload: data,
        });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const updateQueryTicket = (type: any, body: any, id: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.put(
            `${Prefix.api}/booking/${type}/${id}/`,
            body,
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.RAISE_COMPLAINT,
            payload: data,
        });
        snackBarUpdate({
            payload: {
                message: "Ticket Updated",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const complaintReset = () => async (
    dispatch: Function
) => {
    dispatch({
        type: ACTIONS.RAISE_COMPLAINT,
        payload: {},
    })
};

export const assignDoctor = (body: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.post(
            `${Prefix.api}/booking/doctor_cunsultation/`,
            body,
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.ASSIGN_DOCTOR,
            payload: data,
        });
        snackBarUpdate({
            payload: {
                message: "Doctor Assigned",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getDoctorBookings = (url: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/booking/doctor_cunsultation/${url ? `?${url}` : ""}`,
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.GET_BOOKING_LIST,
            payload: data,
        });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getDoctorBookingById = (id: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/booking/doctor_cunsultation/${id}/`,
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.GET_BOOKING_BY_ID,
            payload: data,
        });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const sendNewFeedback = (id: any, body: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.put(
            `${Prefix.api}/booking/doctor_cunsultation/${id}/`,
            body,
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.SEND_FEEDBACK,
            payload: data,
        });
        snackBarUpdate({
            payload: {
                message: "Feedback Sent",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getAvailableSlots =
  (collection_date: any, customer_zone: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/booking_slot_collection_date/?collection_date=${
          collection_date ? collection_date : ""
        }&customer_zone=${customer_zone ? customer_zone : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_AVAILABLE_SLOTS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

export const getAllLeads = (query: string) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/lead/lead/${query}`,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_ALL_LEADS, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const leadTransferByComma = (body: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    const { lead_ids, value, panel_user, status_call, count, priority } = body;

    try {
        const { data } = await AXIOS.post(
            `${Prefix.api}/panel/lead-transfer/`,
            { lead_ids, value, panel_user, status_call, count, priority },
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.LEAD_TRANSFER_BY_COMMA, payload: data });
        snackBarUpdate({
            payload: {
                message: "Transferred!",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const leadTransfer = () => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/panel/lead-transfer/`,

            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_PANEL_USERS, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const searchCall = (body: any) => async (dispatch: Function) => {
    const { search_by, value } = body;
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.post(
            `${Prefix.api}/panel/search/`,
            {
                search_by,
                value,
            },
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.SEARCH_CALL, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {

            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const getLedgerDetails =
    (url: string) => async (dispatch: Function) => {

        dispatch({ type: ACTIONS.SET_LOADING, payload: true });
        try {
            const { data } = await AXIOS.get(`${Prefix.api}/package/cc-ledger/${url ? `?${url}` : ""}`, {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            });
            dispatch({ type: ACTIONS.GET_CLIENT_LEDGER_DETAILS, payload: data });
        } catch (err: any) {
            let title = "";
            if (err.response) {
                title = err.response.data.errors;
            } else {
                title = "Something went wrong!";
            }
            snackBarUpdate({
                payload: {
                    message: title || "Something went wrong!",
                    status: true,
                    type: "error",
                },
            })(dispatch);
            dispatch({ type: ACTIONS.GET_CLIENT_LEDGER_DETAILS, payload: [] });
            dispatch({ type: ACTIONS.SET_LOADING, payload: false });
            throw err;
        }
    };
    export const getCenterInfo = (url: string) => async (dispatch: Function) => {
        dispatch({ type: ACTIONS.SET_LOADING, payload: true });
        try {
          const { data } = await AXIOS.get(
            `${Prefix.api}/center/data/${url ? url : ""}`,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
          );
          dispatch({ type: ACTIONS.GET_CENTER_INFORMATION, payload: data });
        } catch (err: any) {
          let title = "";
          if (err.response) {
            title = err.response.data.errors;
          } else {
            title = "Something went wrong!";
          }
          snackBarUpdate({
            payload: {
              message: title || "Something went wrong!",
              status: true,
              type: "error",
            },
          })(dispatch);
          dispatch({ type: ACTIONS.GET_CENTER_INFORMATION, payload: [] });
          dispatch({ type: ACTIONS.SET_LOADING, payload: false });
          throw err;
        }
      };
      export const getTimeslots = () => async (dispatch: Function) => {
        dispatch({ type: ACTIONS.SET_LOADING, payload: true });
        try {
          const { data } = await AXIOS.get(`${Prefix.api}/phlebo/timeslots/`, {
            headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
          });
          dispatch({
            type: ACTIONS.GET_TIMESLOTS,
            payload: data,
          });
        } catch (err: any) {
          let title = "";
          if (err.response) {
            title = err.response.data.errors;
          } else {
            title = "Something went wrong!";
          }
          snackBarUpdate({
            payload: {
              message: title || "Something went wrong",
              status: true,
              type: "error",
            },
          })(dispatch);
          dispatch({ type: ACTIONS.SET_LOADING, payload: false });
          throw err;
        }
      };
export const createAdditionalBooking = (id: any, bookingData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.post(
            `${Prefix.api}/booking/additional_booking/${id}/`,
            bookingData,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.CREATE_ADDITIONAL_BOOKING, payload: data });
        snackBarUpdate({
            payload: {
                message: "Family member added",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const updateAdditionalBooking = (id: any, bookingData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.put(
            `${Prefix.api}/booking/ret_up_additional_booking/${id}/`,
            bookingData,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.CREATE_ADDITIONAL_BOOKING, payload: data });
        snackBarUpdate({
            payload: {
                message: "Family member updated",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const createImagingBooking = (bookingData: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.post(
            `${Prefix.api}/booking/imaging/${id ? `?${id}` : ""}`,
            bookingData,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.CREATE_IMAGING_BOOKING, payload: data });
        snackBarUpdate({
            payload: {
                message: "Booking Create Successfully",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err?.response?.data?.errors || err?.response?.data?.coupon_applied;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const getCollectionSlot = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/phlebo/timeslots/${url ? `?q=${url}` : ""}`,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_COLLECTION_SLOT, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const getQRCodeData = (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/booking/received_packages/?booking_id=${url}`,

            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_QRCODE_DATA, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const updateTubeStatus = (id: any, tubeData: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.put(
            `${Prefix.api}/booking/received_packages/${id ? `${id}/` : ""}`,
            tubeData,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_PACKAGE_TUBE_DETAILS, payload: data });
        snackBarUpdate({
            payload: {
                message: "Tube Status updated",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const getPaymentInfo = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/payment/info/${url ? url : ""}`,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_PAYMENT_INFORMATION, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const getPaymentSendLink = (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/payment/send_link/${url ? `${url}/` : ""}`,

            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_PAYMENT_SEND_LINK, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.detail;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const getSubCenterInfo = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/center/center-address/${url ? url : ""}`,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_SUB_CENTER_INFORMATION, payload: data });
    } catch (err: any) {
        let title = "";
        if (err?.response?.data?.errors) {
            title = JSON.stringify(err.response.data.errors).replace(
              /"|{|}|[|]/g,
              ""
            );
        } else if (err?.response?.data?.detail) {
            title = JSON.stringify(err.response.data.detail).replace(
              /"|{|}|[|]/g,
              ""
            );
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong!",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.GET_SUB_CENTER_INFORMATION, payload: [] });
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const getRedCoing = (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    dispatch({ type: ACTIONS.GET_RED_COIN, payload: [] });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/coupon/available-coins/?phonenumber=${url}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_RED_COIN,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const checkGeoFenceArea =
  (latitude: any, longitude: string, zone_id: string) =>
  async (dispatch: Function) => {
    dispatch({ type: ACTIONS.CHECK_GEOFENCE_AREA, payload: false });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/checking-geofence-area/?latitude=${latitude}&longitude=${longitude}&zone_id=${zone_id}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CHECK_GEOFENCE_AREA, payload: true });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      //dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getImagingTimeslots = (url: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/phlebo/imaging-package-slots/${url ? url : ""}`,
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.GET_IMAGING_TIMESLOTS,
            payload: data,
        });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getMultiplePackage = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/package/crmdata/${url ? `${url}` : ""}`,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_MULTIPLE_PACKAGES, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const getDisplayCities =
  (url: string, newUrl: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(`${Prefix.api}/core/displaycities/`, {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      });
      dispatch({ type: ACTIONS.GET_DISPLAY_CITIES, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
export const getUserList = (url: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/cron/changepaneruserstatus/${url ? url : ""}`,
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.GET_USERS_LIST,
            payload: data,
        });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const updateUserList = (body: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.post(
            `${Prefix.api}/cron/changepaneruserstatus/`,
            { userdata: [body] },
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.UPDATE_USERS_LIST,
            payload: data,
        });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.detail;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const getPanelComment = (id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/panel/panel_comments/${id ? `${id}/` : ""}`,

            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_PANEL_COMMENT, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.detail;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const getPanelUserList = (url: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/panel/data/${url ? `?code=${url}&usergroup=SalesLead` : "?usergroup=SalesLead"}`,
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.GET_PANEL_USER_LIST_DATA,
            payload: data,
        });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const getActiveDeactivePanel = (url: string, status: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(`${Prefix.api}/panel/team/${url ? `${url}` : ""}/${status ? `${status}` : ""}/`, {
            headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        });
        dispatch({ type: ACTIONS.GET_PANELUSER_ACTIVE_DEACTIVE, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong!",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.GET_PANELUSER_ACTIVE_DEACTIVE, payload: [] });
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const getAgentData = (url: string) => async (dispatch: Function) => {
    // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/panel/data/?usergroup=CustomerLead${url ? `&code=${url}` : ""}`,

            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_AGENT_DATA, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getAssignAgentList = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/panel/data/${url ? `${url}` : ""}`,

            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_ASSIGNED_USERGROUP, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const updateUserStatus = (body: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.post(
            `${Prefix.api}/cron/changepaneruserstatus/`,
            body,
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.UPDATE_USERS_STATUS,
            payload: data,
        });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const updatePanelUser = (body: any, phone: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.put(
            `${Prefix.api}/auth/users/${phone ? `${phone}/` : ""}`,
            body,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.UPDATED_PANEL_USER, payload: data });
        snackBarUpdate({
            payload: {
                message: "User Updated Successfully",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors[0];
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const createPanelUser = (body: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.post(
            `${Prefix.api}/panel/create/`,
            body,
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.CREATE_PANEL_USER,
            payload: data,
        });
        snackBarUpdate({
            payload: {
                message: "Panel User Created",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const getPrescriptionData = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/booking/prescription_upload/${url ? url : ""}`,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_PRESCRIPTION_DATA, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const checkGeoFenchMapMyIndia =
  (eloc: string, pincode: string, cityname: string = 'NA') => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA, payload: [] });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/checking-geofence-area-mapmyindia/?eloc=${eloc}&pincode=${pincode}&cityname=${cityname}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      //dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };


  export const getMapMyIndiaAccessToken = () => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN, payload: [] });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/panel/mapmyindia-auth-token/`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      throw err;
    }
  };
  
export const customerReview = (reviewDetails: any) => async (dispatch: Function) => {
    // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.post(
            `${Prefix.api}/booking/review/`,
            reviewDetails,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.POST_CUSTOMER_REVIEW, payload: data });
        snackBarUpdate({
            payload: {
                message: "REVIEW SUBMIT SUCCESSFULLY",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const getCustomerReviewData = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/booking/review/`,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_CUSTOMER_REVIEW_DATA, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const getNotificationMessage = (url: string) => async (dispatch: Function) => {
    // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/notification/send/${url ? url : ""}`,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_NOTIFICATION_MESSAGE, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getTicketData = (url: string) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/redtech/tasks/${url ? `${url}` : ""}`,

            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_TICKET_DATA, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const getTicketCategories = (url: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/redtech/categories/${url ? `${url}/` : ""}`,

            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_TICKET_CATEGORIES, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getTicketSubCategories = (url: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });

    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/redtech/sub-categories/${url ? `${url}` : ""}`,

            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_TICEKT_SUB_CATEGORIES, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const CreateTaskTicket = (body: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.post(
            `${Prefix.api}/redtech/tasks/`,
            body,
            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.CREATE_TASK_TICKET, payload: data });
        snackBarUpdate({
            payload: {
                message: "Task Created Successfully",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};


export const getRedTechComment = (id: number) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/redtech/comments/?ticket=${id}&ordering=-created_at`,

            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.GET_RED_TECH_COMMENT,
            payload: data,
        });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err?.response?.data?.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const postRedTechComment = (body: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.post(
            `${Prefix.api}/redtech/comments/`,
            body,
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.POST_RED_TECH_COMMENT,
            payload: data,
        });
        snackBarUpdate({
            payload: {
                message: "Message Created",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.message;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const postImagingComplaint = (body: any) => async (
    dispatch: Function
) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.post(
            `${Prefix.api}/booking/ticket/`,
            body,
            {
                headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
            }
        );
        dispatch({
            type: ACTIONS.RAISE_IMAGING_COMPLAINT,
            payload: data,
        });
        snackBarUpdate({
            payload: {
                message: "Ticket Raised",
                status: true,
                type: "success",
            },
        })(dispatch);
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err?.response?.data?.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};

export const getAgentDataRaiseTicektData = (url: string) => async (dispatch: Function) => {
    // dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
        const { data } = await AXIOS.get(
            `${Prefix.api}/panel/data/?usergroup=CustomerLead&org_type=imaging${url ? `&code=${url}` : ""}`,

            { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
        );
        dispatch({ type: ACTIONS.GET_AGENT_RAISE_TICEKT_DATA, payload: data });
    } catch (err: any) {
        let title = "";
        if (err.response) {
            title = err.response.data.errors;
        } else {
            title = "Something went wrong!";
        }
        snackBarUpdate({
            payload: {
                message: title || "Something went wrong",
                status: true,
                type: "error",
            },
        })(dispatch);
        // dispatch({ type: ACTIONS.SET_LOADING, payload: false });
        throw err;
    }
};
export const getUserDetails = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(`${Prefix.api3}/auth/shortprofile/`, {
        headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
      });
      dispatch({ type: ACTIONS.GET_USER_DETAILS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response.data?.detail) {
        title = JSON.stringify(err.response.data?.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong!",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.GET_USER_DETAILS, payload: [] });
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const createImagingBookingCustom =
  (bookingData: any, id: any) => async (dispatch: Function) => {
      dispatch({ type: ACTIONS.SET_LOADING, payload: true });
      try {
          const { data } = await AXIOS.post(
              `${Prefix.api}/booking/imaging-custom/${id ? `?${id}/` : ""}`,
              bookingData,
              {
                  headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
              }
          );
          dispatch({ type: ACTIONS.CREATE_IMAGING_BOOKING_CUSTOM, payload: data });
          snackBarUpdate({
              payload: {
                  message: "Booking Create Successfully",
                  status: true,
                  type: "success",
              },
          })(dispatch);
      } catch (err: any) {
          let title = "";
          if (err.response.data?.errors) {
              title = JSON.stringify(err.response.data?.errors).replace(
                  /"|{|}|[|]/g,
                  ""
              );
          } else {
              title = "Something went wrong!";
          }
          snackBarUpdate({
              payload: {
                  message: title || "Something went wrong",
                  status: true,
                  type: "error",
              },
          })(dispatch);
          dispatch({ type: ACTIONS.SET_LOADING, payload: false });
          throw err;
      }
  };

  export const checkGeoFenchMapMyIndiaPilot =
  (eloc: string, pincode: string, cityname: string = 'NA') => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA, payload: [] });
    dispatch({ type: ACTIONS.SET_GEO_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/checking-geofence-area-mapmyindia_2/?eloc=${eloc}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
        dispatch({ type: ACTIONS.SET_GEO_CHECK_ERROR, payload:err.response.data.errors });
      } else {
        title = "Something went wrong!";
        dispatch({ type: ACTIONS.SET_GEO_CHECK_ERROR, payload:""});
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_GEO_LOADING, payload: false });
      throw err;
    }
  };
  export const createPilotNewBooking =
  (bookingData: any, id: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.post(
        `${Prefix.api}/booking/data_v2/${id ? `?${id}` : ""}`,
        bookingData,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.CREATE_PILOT_BOOKING, payload: data });
      snackBarUpdate({
        payload: {
          message: "Booked",
          status: true,
          type: "success",
        },
      })(dispatch);
    } catch (err: any) {
      let title = "";
      if (err?.response?.data?.errors) {
        title = JSON.stringify(err.response.data.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
    } else if (err?.response?.data?.detail) {
        title = JSON.stringify(err.response.data.detail).replace(
          /"|{|}|[|]/g,
          ""
        );
    } else {
        title = "Something went wrong!";
    }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const getceCentre =(url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/center/ce_center/${ url ? `${url}` : "" }`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({
        type: ACTIONS.GET_CE_CENTER_FOR_CC,
        payload: data,
      });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = JSON.stringify(err.response.data?.errors).replace(
          /"|{|}|[|]/g,
          ""
        );
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const getBreakResponse = (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/panel/data/${url ? `?${url}` : ""}`,
  
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_BREAK_RESPONSE, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  export const getNewPackage = (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${PrefixBeta.api}/core/packages${url ? `?${url}` : ""}`,
        { headers: { Authorization: `Token ${SecureStorage.getItem("token")}` } }
      );
      dispatch({ type: ACTIONS.GET_PACKAGES, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
  
  export const getCurrentDayBooking =
  (url: any) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/analytics/booking_analysis/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_CURRENT_DAY_BOOKING, payload: data });
      //
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.errors;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong",
          status: true,
          type: "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };

  export const getAllBookingsExports =
  (url: string) => async (dispatch: Function) => {
    dispatch({ type: ACTIONS.SET_LOADING, payload: true });
    try {
      const { data } = await AXIOS.get(
        `${Prefix.api}/booking/data/${url ? `?${url}` : ""}`,
        {
          headers: { Authorization: `Token ${SecureStorage.getItem("token")}` },
        }
      );
      dispatch({ type: ACTIONS.GET_ALL_BOOKING_EXPORTS, payload: data });
    } catch (err: any) {
      let title = "";
      if (err.response) {
        title = err.response.data.detail;
      } else {
        title = "Something went wrong!";
      }
      snackBarUpdate({
        payload: {
          message: title || "Something went wrong!",
          status: true,
          type:
            err.response.data.detail ===
            "An Email has been sent to your registered mail address"
              ? "success"
              : "error",
        },
      })(dispatch);
      dispatch({ type: ACTIONS.GET_ALL_BOOKING_EXPORTS, payload: [] });
      dispatch({ type: ACTIONS.SET_LOADING, payload: false });
      throw err;
    }
  };
