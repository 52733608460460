import React from "react";
import AgentDashboardLeft from "../../CustomerExecutive-Panel/dashboard-left";
import AnalystDashboardRight from "../../CustomerExecutive-Panel/dashboard-right";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  container:{
    display:'flex',
    padding: "20px"
  }
});

interface Props {
  children: any;
}

const AnalystAgentDash: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <AgentDashboardLeft />
      <AnalystDashboardRight>{children && children}</AnalystDashboardRight>
    </div>
  );
};

export default AnalystAgentDash;
