import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "../react-sliding-pane.css";
import { CITY, LOCALITY, ADDRESS_LINE1, ADDRESS_LINE2, LANDMARK, PINCODE } from "../../constant";
// import Modal from '@mui/material/Modal';
import { useDispatch } from "react-redux";
// import GoogleAutoComplete from "react-google-autocomplete";
// import Geocode from "react-geocode";

import {
    Card,
    Checkbox,
    Grid,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    // Paper,
    // Chip,
} from "@material-ui/core";
// import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
    checkGeoFenceArea,
    getPinCode,
    getPackage,
    createNewBooking,
    getCoupons,
    getLeads,
    getLeadDetails,
    resetLeadDetails,
    getAvailableSlots,
    getRedCoing,
    checkGeoFenchMapMyIndia,
    checkGeoFenchMapMyIndiaPilot,
    getMapMyIndiaAccessToken,
    getCenterInfo,
    getceCentre,
    getTimeslots,
    getDisplayCities,
    createPilotNewBooking,
    getBreakResponse,
    getZone,getArea
} from "../../../actions/salesLeadAdminAction";
import Button from "@material-ui/core/Button";
import "./index.sass";
import { connect } from "react-redux";
import moment from "moment";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useHistory } from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// import Radio from "@material-ui/core/Radio";
// import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormControl from "@material-ui/core/FormControl";
// import FormLabel from "@material-ui/core/FormLabel";
import { GENDER_CHOICE } from "../../constant";
import { ACTIONS } from "../../../interfaces/actionTypes/salesLeadAdminType";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import RefreshIcon from '@mui/icons-material/Refresh';
import CopyAllIcon from '@mui/icons-material/CopyAll';
// import Backdrop from "@material-ui/core/Backdrop";
// import Fade from "@material-ui/core/Fade";
// Geocode.setApiKey("AIzaSyBPjDlsHYhWtjt3FxgRPnTMXN-qJZ9hz0s");
// Geocode.enableDebug();
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },

        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        slotContainer: {
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
        },
        slot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            margin: "0.5rem",
        },
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        paper: {
            width: "100%",
        },
        head: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: "2rem",
        },
        selectedSlot: {
            width: "150px",
            height: "100px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
            cursor: "pointer",
            margin: "0.5rem",
        },
        chips: {
            display: "flex",
        },
        chip: {
            margin: 2,
            background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
            maxWidth: "130px",
        },
        table: {
            maxWidth: "100%",
            margin: "auto",
        },
        toolbar: {
            alignItems: "center",
            justifyContent: "flex-end",
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "100%",
            flexGrow: 21,
        },
        contentHeader: {
            width: "100%",
            padding: "0px 10px 40px 10px",
        },
        leftContentHeader: {
            width: "50%",
            float: "left",
        },
        rightContentHeader: {
            width: "50%",
            float: "right",
            textAlign: "right",
            paddingRight: "20px",
        },
        contentTable: {
            width: "100%",
            padding: "20px 10px 10px 10px",
            background: "#ffffff",
            margin: "10px",
            boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        },
        packageLink: {
            color: "#ffffff",
            textDecoration: "none",
        },
        dialog: {
            zIndex: 2,
        },
    })
);

interface Props {
    lead: any;
    getPinCode: any;
    pin_code: any;
    getPackage: any;
    packageList: any;
    couponList: any;
    getCoupons: any;
    createNewBooking: any;
    getLeads: any;
    leads: any;
    getLeadDetails: any;
    lead_details: any;
    booking_slots: any;
    resetLeadDetails: any;
    createPilotBooking: any;
    getAvailableSlots: any;
    loading: boolean;
    sameNumberBooking: boolean;
    checkGeoFenceArea: any;
    checkGeoFenceStatus: boolean;
    getRedCoing: any;
    redCoin: any;
    checkGeoFenchMapMyIndia: any;
    checkGeoFenchMapMyIndiaPilot: any;
    geoFencesMapMyIndia: any;
    mapmyindiaAccessToken: any;
    getMapMyIndiaAccessToken: any;
    getCenterInfo: any;
    centerInfo: any;
    ceCenterForCC: any;
    getceCentre: any;
    getTimeslots: any;
    timeslots: any;
    getDisplayCities: any;
    displayCities: any;
    createPilotNewBooking: any;
    userDetails: any;
    breakResponse: any;
    getBreakResponse: any;
    getZone: any;
    zoneList: any;
    getArea:any;
    areaList:any
}

const BookingWithLocalitySearch: React.FC<Props> = ({
    lead,
    getPinCode,
    pin_code,
    packageList,
    getPackage,
    couponList,
    getCoupons,
    createNewBooking,
    getLeads,
    leads,
    getLeadDetails,
    lead_details,
    booking_slots,
    resetLeadDetails,
    createPilotBooking,
    getAvailableSlots,
    sameNumberBooking,
    checkGeoFenceArea,
    checkGeoFenceStatus,
    getRedCoing,
    redCoin,
    loading,
    checkGeoFenchMapMyIndia,
    checkGeoFenchMapMyIndiaPilot,
    geoFencesMapMyIndia,
    mapmyindiaAccessToken,
    getMapMyIndiaAccessToken,
    getCenterInfo,
    centerInfo,
    getceCentre,
    ceCenterForCC,
    getTimeslots,
    timeslots,
    getDisplayCities,
    displayCities,
    createPilotNewBooking,
    userDetails,
    breakResponse,
    getBreakResponse,
    getZone,
    zoneList,
    getArea,
    areaList
}) => {
    const classes = useStyles();
    let dispatch = useDispatch();
    const history = useHistory();
    const [inputList, setInputList] = useState<any>([
        {
            customerId: "",
            customerDesignation: "",
            designation: "",
            customerName: "",
            customerAge: "",
            customerGender: "",
            customerPackage: [],
            packagePrice: 0,
            dateOfBirth: "",
            is_prescribed: "",
            bookingFor: "",
            passport: "",
            aadhar: ""
        },
    ]);
    const [collection_date, setCollection_date] = useState<String>("");
    const [pincode, setPinCode] = useState<string>("");
    const [zoneName, setZoneName] = useState<String>("");
    const [timeSlots, setTimeSlots] = useState<Array<Object>>([]);
    const [selectedSlot, setSelectedSlot] = useState<Number>(0);
    const [customer_phonenumber, setCustomer_phonenumber] = useState<String>("");
    const [customer_alternatenumber, setCustomer_alternatenumber] =
        useState<String>("");
    const [customer_whatsapppnumber, setCustomer_whatsapppnumber] =
        useState<String>("");
    const [customer_address, setCustomer_address] = useState<String>("");
    const [customer_email, setCustomer_email] = useState<String>("");
    const [customer_landmark, setCustomer_landmark] = useState<string>("");
    const [customerLatitude, setCustomerLatitude] = useState<any>("");
    const [customerLongitude, setCustomerLongitude] = useState<any>("");
    const [customer_areapincode, setCustomer_areapincode] = useState<string>("");
    const [customer_areapincode_id, setCustomer_areapincode_id] = useState<Number>(0);
    //const [customer_areaname, setCustomer_areaname] = useState<String>("");
    const [customer_city, setCustomer_city] = useState<String>("");
    const [customer_aadhar, setCustomer_aadhar] = useState<String>("");
    const [passport, setPassport] = useState<String>("");
    const [price, setPrice] = useState<any>(0);
    const [discount, setDiscount] = useState<any>(0);
    const [totalPrice, setTotalPrice] = useState<any>(0);
    const [coupon, setCoupon] = useState<Number>(0);
    const [couponCode, setCouponCode] = useState<string>("");
    const [extraCharge, setExtraCharge] = useState<boolean>(false);
    const [cityId, setCityId] = useState<any>("");
    const [is_pincode, setIsPincode] = useState<boolean>(true);
    const [packageKey, setPackageKey] = useState<any>(0);
    const [defaultEmail, setDefaultEmail] = useState<any>(
        "customer.reports@redcliffelabs.com"
    );
    const [mobileValidation, setMobileValidation] = useState({
        customer_alternatenumber: true,
        customer_phonenumber: true,
        customer_whatsapppnumber: true,
    });
    const [readology, setReadology] = useState<any>(false)
    const [search, setSearch] = useState<String>("");
    const [zoneId, setZoneId] = useState<any>("");
    const timer = useRef<any>(0);
    const handleSearch = (e: React.ChangeEvent<{ value: unknown }>) => {
        setSearch(e.target.value as string);
    };
    const [addressLine2, setAddressLine2] = useState<string>("");
    const [favoritePlace, setFavoritePlace] = useState<any>("");
    const [openLocationModal, setOpenLocationModal] = useState<boolean>(false);
    const [bookingEnabled, setBookingEnabled] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isPaneOpen, setPaneOpen] = useState<boolean>(false);
    const [singlePackage, setSinglePackage] = useState<any>({});
    const [mobError, setMobError] = React.useState<string>("");
    const [altError, setAltError] = React.useState<string>("");
    const [whatsError, setWhatsError] = React.useState<string>("");
    const [tncPerCentage, setTnPercentage] = useState<string>("");
    const [couponMessage, setCouponMessage] = useState<string>("");
    const [tncMessage, setTncMessage] = useState<string>("");
    const [redCoinMessage, setRedCoinMessage] = useState<any>("");
    const [redCashCheck, setRedCashCheck] = useState<boolean>(false);
    const [totalRedCoin, setTotalRedCoin] = useState<any>("");
    const [redDisc, setRedDisc] = useState<any>(0);
    // const [couponType, setCouponType] = useState<boolean>(false);
    const [redCouponDiscount, setRedCouponDiscount] = useState<number>(0);
    const [lead_customer_phonenumber, setLeadCustomer_phonenumber] =
        useState<string>("");
    const [lead_customer_whatsapppnumber, setLeadCustomer_whatsapppnumber] =
        useState<string>("");
    // const [mapData, setMapData] = useState<any>([]);
    // const [mapOpen, setMapOpen] = useState<boolean>(false);
    // const [backSpaceDetect, setbackSpaceDetcet] = useState<boolean>(false);
    const [couponKey, setCouponKey] = useState<any>(0);
    //Uhid Implementation start
    const [uhidModalOpen, setUhidmodalOpen] = useState<boolean>(false);
    const [collectionCenterId, setCollectionCenterId] = useState<any>("")
    const [collectionKey, setCollectionkey] = useState<any>(0)
    const [collectionType, setCollectionType] = useState<any>("homecollection")
    const [zoneKey, setZoneKey] = useState<any>(0)
    const [cityName, setCityName] = useState<string>("");
    //Uhid Implementation end


    const searchLead = () => {
        let body = {
            search_by: "call_id",
            value: search,
        };
        getLeads(body);
    };


    const selectLead = (id: any) => {
        getLeadDetails(`${id}`, "true");
    };
    useEffect(() => {
        if (lead) {

            setSearch(lead)
            getLeads({
                search_by: "call_id",
                value: lead,
            });
            selectLead(lead)
        }
    }, [lead]);

    useEffect(() => {
        setCustomer_address(lead_details.address as String);
        setLeadCustomer_phonenumber(lead_details.content_number as string);
        setLeadCustomer_whatsapppnumber(lead_details.whatsapp_contact as string);
        setInputList([
            {
                customerDesignation: lead_details?.calling_gender === "male" || "Male" ? "Mr" : lead_details?.calling_gender === "female" || "Female" ? "Ms" : "",
                designation: lead_details?.calling_gender,
                customerName: lead_details?.patient_name,
                customerAge: lead_details?.patient_age,
                customerGender: lead_details?.calling_gender,
                customerPackage: [],
                packagePrice: 0,
                dateOfBirth: "",
                customerId: "",
                is_prescribed: "",
                bookingFor: "",
                passport: "",
                aadhar: ""
            },
        ]);
    }, [lead_details]);

    useEffect(() => {
        resetLeadDetails();
        getMapMyIndiaAccessToken();
        getCoupons("?org_type=homedx");
        getDisplayCities();
        getBreakResponse(`self=true`)
        getZone()
    }, []);

    // useEffect(() => {
    //   if (customerLatitude && customerLongitude && zoneId) {
    //     check booking is allow to not in Geo-fence
    //     checkGeoFenceArea(
    //       customerLatitude as string,
    //       customerLongitude as string,
    //       zoneId as string
    //     );
    //   }
    // }, [customerLatitude, customerLongitude, zoneId]);

    // useEffect(() => {
    //   setBookingEnabled(checkGeoFenceStatus);
    // }, [checkGeoFenceStatus]);

    useEffect(() => {
        const slots = booking_slots && booking_slots?.results;
        setTimeSlots(slots && slots[0].zone_data && slots[0].zone_data.time_slots);
    }, [booking_slots, collection_date]);
    useEffect(() => {
        if (pincode && pin_code?.results?.length == 0) {
            // setErrorMessage("We are not providing service on this pincode.");
            resetAddressComponent();
        }
        if (pincode && pin_code?.results?.length > 0) {
            const findPinCode = pin_code?.results.find(
                (code: any) => code.pincode == pincode
            );
            if (findPinCode) {
                setErrorMessage("");
                // getArea(pincode);
                const { id, area, city, cityid } = pin_code.results.find(
                    (code: any) => code.pincode === pincode
                );
                getPackage(`city_id=${cityid}&org_type=homedx`);
                setCustomer_city(city as String);
                setIsPincode(false);
                setPackageKey(cityid);
            } else {
                // setErrorMessage("We are not providing service on this pincode.");
            }
        }
    }, [pin_code, pincode]);



    useEffect(() => {
        if (zoneId !== "") {
            if (collectionType === "collectioncentre") {
                getTimeslots()
            }
            else {
                if (collection_date !== "") {
                    getAvailableSlots(collection_date, zoneId);
                }

            }
        }
    }, [zoneId, collectionType])

    const resetAddressComponent = () => {
        setZoneId("");
        setCityId("");
        //setCustomer_areaname("");
        setIsPincode(true);
        setPackageKey(0);
        // setZoneName("");
        setCustomer_city("");
        setPinCode("");
    };

    useEffect(() => {

            if (cityId !== "") {
                getceCentre(`?customer_latitude=0.00000&customer_longitude=0.00000&city=${cityId}`)
                getArea(`city=${cityId}`)
                getPackage(`city_id=${cityId}&org_type=homedx`);
            }
    }, [cityId])

    useEffect(() => {
        if (extraCharge) {
            setTotalPrice(Math.floor(price - discount + 200));
        } else {
            setTotalPrice(Math.floor(price - discount));
        }
    }, [price, discount, extraCharge]);

    useEffect(() => {
        if (totalPrice < 0) {
            setTotalPrice(0);
        }
    }, [totalPrice]);

    const applyCoupon = () => {
        if (couponCode === "") {
            setCouponCode("");
            setCoupon(0);
            setDiscount(0);
            setTnPercentage("");
            setCouponMessage("");
            setTncMessage("");
            return;
        }
        const appliedCoupon =
            couponList.results.find((coup: any) => coup.id === coupon) ||
            couponList.results.find((coup: any) => coup.code === couponCode);
        if (appliedCoupon && appliedCoupon.minimum_price < totalPrice) {
            setCoupon(appliedCoupon.id);
            if (appliedCoupon.discount_type.toLowerCase() === "percentage") {
                if (appliedCoupon.is_red_cash) {
                    setDiscount(0);
                } else {
                    setDiscount(Math.floor((price * appliedCoupon.discount) / 100));
                }
                setTnPercentage(appliedCoupon.tnc !== null ? appliedCoupon.tnc : "");
                setCouponMessage(
                    `Coupon ${appliedCoupon.code}  has been applied successfully`
                );
                setTncMessage(
                    `${appliedCoupon.tnc !== null ? `TNC : ${appliedCoupon.tnc}` : ""}`
                );
            }
            if (appliedCoupon.discount_type.toLowerCase() === "amount") {
                if (appliedCoupon.is_red_cash) {
                    setDiscount(0);
                } else {
                    setDiscount(Math.floor(appliedCoupon.discount));
                }
                setTnPercentage(appliedCoupon.tnc !== null ? appliedCoupon.tnc : "");
                setCouponMessage(
                    `Coupon ${appliedCoupon.code}  has been applied successfully`
                );
                setTncMessage(
                    `${appliedCoupon.tnc !== null ? `TNC : ${appliedCoupon.tnc}` : ""}`
                );
            }
        } else {
            setCouponCode("");
            setCoupon(0);
            setDiscount(0);
            setTnPercentage("");
            setCouponMessage("");
            setTncMessage("");
        }
    };
    const verifyMobile = (type: any) => {
        if (type === "mobile") {
            if (
                parseInt(customer_phonenumber && customer_phonenumber[0]) < 6 ||
                (customer_phonenumber && customer_phonenumber.length < 10)
            ) {
                setMobileValidation((prev: any) => ({
                    ...prev,
                    customer_phonenumber: false,
                }));
            }
        }
        if (type === "alternate") {
            if (
                parseInt(customer_alternatenumber && customer_alternatenumber[0]) < 6 ||
                (customer_alternatenumber && customer_alternatenumber.length < 10)
            ) {
                setMobileValidation((prev: any) => ({
                    ...prev,
                    customer_alternatenumber: false,
                }));
            }
        }
        if (type === "whatsapp") {
            if (
                parseInt(customer_whatsapppnumber && customer_whatsapppnumber[0]) < 6 ||
                (customer_whatsapppnumber && customer_whatsapppnumber.length < 10)
            ) {
                setMobileValidation((prev: any) => ({
                    ...prev,
                    customer_whatsapppnumber: false,
                }));
            }
        }
    };

    const submitForm = async (e: any) => {
        e.preventDefault();

        let packagesIds = new Array();
        inputList &&
            inputList[0]?.customerPackage.map((item: any) => {
                packagesIds.push(item.id);
            });

        let additionalPacakges = new Array();
        inputList &&
            inputList.length > 1 &&
            inputList.map((item: any, index: number) => {
                if (index > 0) {
                    additionalPacakges.push(item);
                }
            });

        const data: any = {
            center: collectionCenterId,
            is_intrested_in_imaging: readology,
            booking_date: moment(new Date()).format("YYYY-MM-DD"),
            collection_date: collection_date,
            collection_slot: selectedSlot,
            customer_name: inputList ? inputList[0]?.customerName : "",
            customer_age: inputList ? inputList[0]?.customerAge : "0",
            customer_gender: inputList ? inputList[0]?.customerGender : "",
            customerid: inputList ? inputList[0]?.customerId : "",
            book_for: inputList ? inputList[0]?.bookingFor : "",
            is_prescription: inputList ? inputList[0]?.is_prescribed : "",
            customer_aadhar: inputList ? inputList[0]?.aadhar : "",
            passport_number: inputList ? inputList[0]?.passport : "",

            customer_email: customer_email === "" ? defaultEmail : customer_email,
            customer_phonenumber:
                lead_customer_phonenumber !== undefined
                    ? lead_customer_phonenumber
                    : customer_phonenumber,
            customer_altphonenumber:
                lead_customer_phonenumber !== undefined
                    ? lead_customer_phonenumber
                    : customer_alternatenumber,
            customer_whatsapppnumber:
                lead_customer_whatsapppnumber !== undefined
                    ? lead_customer_whatsapppnumber
                    : customer_whatsapppnumber,
            customer_address: customer_address,
            customer_landmark: customer_landmark,
            customer_pincode: customer_areapincode,
            customer_longitude: customerLongitude,
            customer_latitude: customerLatitude,
            // customer_city: cityName,
            packages: packagesIds,
            coupon_applied: coupon,
            designation: inputList ? inputList[0]?.customerDesignation : "",
            dob: inputList ? inputList[0]?.dateOfBirth : "",
            cityid: cityId,
            additional_member: additionalPacakges,
            address_line2: addressLine2,
            landmark: favoritePlace,
            is_redcash_applied: redCashCheck ? "true" : "false",
            customer_zone: zoneId
        };
        // if (!bookingEnabled) {
        //     alert("There is no sericable area or not a valid address. Please try again.");
        // }

        if (data["coupon_applied"] === 0) {
            delete data["coupon_applied"];
        }
        if (data["center"] === "") {
            delete data["center"];
        }
        if (data["dob"] === "") {
            delete data["dob"];
        }
        if (
            mobileValidation.customer_alternatenumber &&
            mobileValidation.customer_phonenumber &&
            mobileValidation.customer_whatsapppnumber
        ) {
            if (
                ageCheck !== undefined ||
                data.customer_age == undefined ||
                data.customer_age === "" ||
                data.customer_age === null
            ) {
                alert("Please Provide Customer Age");
            } else if (packageCheck !== undefined) {
                alert("Customer Package can't be Blank");
            } else {
                await createPilotNewBooking(data)
                resetAddressComponent();
            }
        } else {
            if (customer_phonenumber.length !== 10) {
                setMobError("Mobile Number Must be 10 Digit");
            } else if (customer_alternatenumber.length !== 10) {
                setAltError("Alternate Number must be 10 Digit");
            } else if (customer_whatsapppnumber.length !== 10) {
                setWhatsError("Whatsapppnumber must be 10 Digit");
            }
        }
    };

    useEffect(() => {
        if (createPilotBooking.pk) {
            history.push(`/dashboard/sla/booking-view/${createPilotBooking.pk}`);
            dispatch({ type: ACTIONS.CREATE_BOOKING, payload: "" });
            dispatch({ type: ACTIONS.CREATE_PILOT_BOOKING, payload: "" });
            dispatch({ type: ACTIONS.GET_PIN_CODE, payload: [] });
        }
        dispatch({ type: ACTIONS.GET_RED_COIN, payload: [] });
        dispatch({ type: ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA, payload: [] });

    }, [createPilotBooking]);

    const changeCollectionDate = (e: any) => {
        setSelectedSlot(0);
        setCollection_date(e.target.value as string);
        if (zoneId) {
            getAvailableSlots(e.target.value, zoneId);
            getPackage(`city_id=${cityId}&org_type=homedx`);
        }
    };
    const handleInputChange = (e: any, index: number) => {
        const { name, value } = e.target;
        const list = [...inputList];
        if (name==="passport") {
            const result = value.replace(/[^A-Z0-9]/, '');
            list[index][name] = result;
          }
          else {
            list[index][name] = value;
          }
        if (e.target.name == "designation") {
            const findGender = GENDER_CHOICE.filter(
                (el: any) => el.display_name == e.target.value
            );
            list[index]["customerGender"] = findGender[0]?.gender;
            list[index]["customerDesignation"] = findGender[0]?.db_name;
            getPackage(`city_id=${cityId}&org_type=homedx&gender=${findGender[0]?.gender}`);
        }
        if (e.target.name == "dateOfBirth") {
            let today = new Date();
            let birthDate = new Date(e.target.value);
            let age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            list[index]["customerAge"] = age;
        }
        setInputList(list);
    };

    const handleRemoveClick = (index: any) => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    const checkNormalPackage = (packages: any) => {
        let isNormalPackage = false;
        packages &&
            packages.length > 0 &&
            packages.map((item: any) => {
                if (!item.is_addon) {
                    isNormalPackage = true;
                }
            });
        return isNormalPackage;
    };

    const checkBuyOneGetOneFreePackage = (index: number, packages: any) => {
        if (
            inputList &&
            inputList?.length == 1 &&
            packages &&
            packages?.length > 0
        ) {
            packages.map((item: any) => {
                if (item && item.code == "PL50" && item?.code == "PL50") {
                    // if use select buy one get one package
                    setInputList([
                        ...inputList,
                        {
                            customerDesignation: "",
                            designation: "",
                            customerName: "",
                            customerAge: "",
                            customerGender: "",
                            customerPackage: [item],
                            packagePrice: item?.offer_price,
                            dateOfBirth: "",
                            customerId: "",
                            is_prescribed: "",
                            bookingFor: "",
                            passport: "",
                            aadhar: ""
                        },
                    ]);
                }
            });
        }
    };

    const getNoofPackageBuyOneGetOne = () => {
        let noOfBuyOneGetOne = 0;
        inputList &&
            inputList.map((rowItem: any) => {
                if (rowItem?.customerPackage && rowItem?.customerPackage.length > 0) {
                    rowItem?.customerPackage.map((packageItem: any) => {
                        if (packageItem?.code == "PL50") {
                            noOfBuyOneGetOne = noOfBuyOneGetOne + 1;
                        }
                    });
                }
            });
        return noOfBuyOneGetOne;
    };

    const buyOneGetOnePackagePrice = () => {
        let packagePrice = 0;
        inputList &&
            inputList.map((rowItem: any) => {
                if (rowItem?.customerPackage && rowItem?.customerPackage.length > 0) {
                    rowItem?.customerPackage.map((packageItem: any) => {
                        if (packageItem?.code == "PL50") {
                            packagePrice = packageItem?.offer_price;
                        }
                    });
                }
            });
        return packagePrice;
    };

    const handlePackage = (index: number, customerPackages: any) => {
        const min_phlebo_cost =
            packageList &&
            packageList?.results &&
            packageList?.results.length > 0 &&
            packageList?.results[0].min_booking_amount_for_phlebo;
        const list = [...inputList];
        list[index]["customerPackage"] = customerPackages;
        setInputList(list);
        let accumulatedPrice: any = 0;
        if (customerPackages && customerPackages.length > 0) {
            let atLeastOneNormalPackage = checkNormalPackage(customerPackages);
            customerPackages.map((item: any) => {
                if (
                    atLeastOneNormalPackage &&
                    item.is_addon &&
                    item.addon_price &&
                    item.addon_price > 0
                ) {
                    accumulatedPrice = accumulatedPrice + item.addon_price;
                } else {
                    if (item?.package_city_prices === null) {
                        accumulatedPrice = accumulatedPrice + item.offer_price;
                    } else {
                        accumulatedPrice =
                            accumulatedPrice + item.package_city_prices.offer_price;
                    }
                }
            });
        }
        // set price and package for row
        list[index]["packagePrice"] = accumulatedPrice;
        setInputList(list);

        //call function for buy one get one free
        if (inputList?.length == 1) {
            checkBuyOneGetOneFreePackage(index, customerPackages);
        }
        let allPackagePrice = 0;
        inputList &&
            inputList.map((rowItem: any) => {
                allPackagePrice = allPackagePrice + rowItem?.packagePrice;
            });
        //Minus By One Get One Price
        let pl50Count = inputList?.length > 0 ? getNoofPackageBuyOneGetOne() : 0;
        if (pl50Count > 1 && inputList?.length > 1) {
            let noOfFreePackage = 0;
            if (pl50Count === 2) {
                noOfFreePackage = 1;
            } else if (pl50Count === 3) {
                noOfFreePackage = 1;
            } else if (pl50Count === 4) {
                noOfFreePackage = 2;
            } else if (pl50Count === 5) {
                noOfFreePackage = 2;
            } else if (pl50Count === 6) {
                noOfFreePackage = 3;
            } else {
                noOfFreePackage = 0;
            }
            if (noOfFreePackage > 0) {
                let buyOneGetOnePrice = buyOneGetOnePackagePrice();
                let freePackagepPrice = noOfFreePackage * buyOneGetOnePrice;
                allPackagePrice = allPackagePrice - freePackagepPrice;
            }
        }
        setPrice(Math.floor(allPackagePrice));
        setDiscount(0);
        setTotalPrice(Math.floor(allPackagePrice));
        setCoupon(0);
        setCouponCode("");
        setRedCashCheck(false);
        if (allPackagePrice > min_phlebo_cost) {
            setExtraCharge(false);
        } else {
            setExtraCharge(true);
        }
    };
    useEffect(() => {
        const tempPrice = totalPrice;
        const maxRedDiscount = (totalPrice / 100) * redCoin?.redcash_percentage;
        const redCashDeduct = maxRedDiscount - redCoin?.coins>=0?redCoin?.coins:maxRedDiscount
        if (redCashCheck) {
            setRedDisc(redCashDeduct);
        } else {
          setRedDisc(0);
        }
      }, [redCashCheck]);

    const handleRedCheck = (event: any) => {
        setRedCashCheck(event.target.checked);
        setDiscount(0);
        setCouponKey(couponKey + 1);
    };

    const handleAddClick = () => {
        setInputList([
            ...inputList,
            {
                customerId: "",
                customerDesignation: "",
                designation: "",
                customerName: "",
                customerAge: "",
                customerGender: "",
                customerPackage: [],
                packagePrice: 0,
                dateOfBirth: "",
                is_prescribed: "",
                bookingFor: "",
                passport: "",
                aadhar: ""
            },
        ]);
    };
    const ageCheck = inputList.find((data: any) => data.customerAge === "");
    const packageCheck = inputList.find(
        (data: any) => data?.customerPackage.length === 0
    );

    // const handleCloseMap = () => {
    //   setMapOpen(false);
    // };

    // useEffect(() => {
    //     if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
    //         setCustomerLatitude(geoFencesMapMyIndia?.latitude);
    //         setCustomerLongitude(geoFencesMapMyIndia?.longitude);
    //         const zone_Id = geoFencesMapMyIndia?.customer_zone;
    //         const city_id = geoFencesMapMyIndia?.cityid;
    //         const area_id = geoFencesMapMyIndia?.area_id;
    //         if (city_id) {
    //             setZoneId(zone_Id);
    //             setCityId(city_id);
    //             setCustomer_city(geoFencesMapMyIndia?.zone_name);
    //             setIsPincode(false);
    //             setPackageKey(city_id);
    //             setCustomer_areapincode(pincode as string);
    //             setCustomer_areapincode_id(area_id as Number)
    //             getPackage(`city_id=${city_id}&org_type=homedx`);
    //             setBookingEnabled(true);
    //         } else {
    //             setBookingEnabled(false);
    //             // setErrorMessage("Please try again. Zone or pin code is not enabled.");
    //         }

    //     }
    // }, [geoFencesMapMyIndia]);

    //mapmyindia
    const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
    const [mapAddressObject, setMapAddressObject] = useState<any>({});
    const [accessToken, setAccessToken] = useState<any>("");

    // useEffect(() => {
    //   if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
    //     setCustomerLatitude(geoFencesMapMyIndia?.latitude);
    //     setCustomerLongitude(geoFencesMapMyIndia?.longitude);
    //   }
    // }, [geoFencesMapMyIndia]);

    useEffect(() => {
        if (mapmyindiaAccessToken?.accesstoken) {
            setAccessToken(mapmyindiaAccessToken?.accesstoken);
        }
    }, [mapmyindiaAccessToken]);

    // useEffect(() => {
    //     if (mapAddressObject?.eLoc) {
    //         resetAddressComponent();

    //         const displayAddress = `${mapAddressObject?.placeName}, ${mapAddressObject?.placeAddress}`
    //         //getPinCode(mapAddressObject?.addressTokens?.pincode);
    //         setCustomer_landmark(displayAddress);
    //         setPinCode(mapAddressObject?.addressTokens?.pincode);
    //         if (mapAddressObject?.addressTokens?.pincode !== "") {
    //             setErrorMessage("")
    //             checkGeoFenchMapMyIndiaPilot(mapAddressObject?.eLoc);
    //         }
    //         else {
    //             setErrorMessage("Please select complete locality from dorpdown with pincode")
    //         }
    //     }
    // }, [mapAddressObject]);



    // const locationAutoCompleteSearch = (val: string, e: any) => {
    //     if (val.length > 2) {
    //         const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
    //         return new Promise(function (resolve, reject) {
    //             const id = "_" + Math.round(10000 * Math.random());
    //             const callbackName = "jsonp_callback_" + "data" + id;
    //             window[callbackName] = (data: any) => {
    //                 delete window[callbackName];
    //                 const ele = document.getElementById(id);
    //                 ele?.parentNode?.removeChild(ele);
    //                 const suggestedData = data?.suggestedLocations;
    //                 if (suggestedData.length > 0) {
    //                     const removePincode = data?.suggestedLocations.filter((data: any) => data.type !== "PINCODE")
    //                     setZoneKey(suggestedData.length)
    //                     setSuggestedAddres(removePincode);

    //                 }
    //             };
    //             const src = urlForMap + "&callback=" + callbackName;
    //             const script = document.createElement("script");
    //             script.src = src;
    //             script.id = id;
    //             script.addEventListener("error", reject);
    //             (
    //                 document.getElementsByTagName("head")[0] ||
    //                 document.body ||
    //                 document.documentElement
    //             ).appendChild(script);
    //         });
    //     }
    // };
    //UHID Start
    const [allUHIDCustomer, setAllUHIDCustomer] = useState<any>([]);
    // const [allCustomer, setAllCustomer] = useState<any>([]);
    useEffect(() => {
        if (customer_phonenumber) {
            if (customer_phonenumber.length === 10) {
                getRedCoing(customer_phonenumber);
            }
        }
    }, [customer_phonenumber]);

    const handleCloseUHID = () => {
        setUhidmodalOpen(false);
    };
    useEffect(() => {
        if (redCoin?.uniquecustomer?.length === undefined || redCoin?.uniquecustomer?.length === 0) {
            setUhidmodalOpen(false);
        }
    }, [customer_phonenumber, redCoin])

    useEffect(() => {
        if (lead_customer_phonenumber) {
            getRedCoing(lead_customer_phonenumber);
        }
    }, [lead_customer_phonenumber]);

    useEffect(() => {
        if (redCoin) {
            setRedCoinMessage(`You have ${redCoin.coins} Red Cash`);
            setTotalRedCoin(redCoin.coins);
        }
    }, [redCoin.coins]);

    useEffect(() => {
        const allUHID = new Array();
        if (
            redCoin &&
            redCoin?.uniquecustomer &&
            redCoin.uniquecustomer.length > 0
        ) {
            redCoin.uniquecustomer.map((data: any) => {
                allUHID.push({
                    customerId: data?.id,
                    lead: data?.lead,
                    customerDesignation:
                        data?.gender === "male" || data?.gender === "Male" ? "Mr" : "Ms",
                    designation:
                        data?.gender === "male" || data?.gender === "Male" ? "Mr" : "Ms",
                    customerName: data?.display_name,
                    customerAge: "",
                    customerGender:
                        data?.gender === "Male" || data?.gender === "male"
                            ? "male"
                            : data?.gender,
                    customerPackage: [],
                    packagePrice: 0,
                    dateOfBirth: "",
                    isSelected: false,
                    is_prescribed: "",
                    bookingFor: "",
                    passport: "",
                    aadhar: ""
                });
            });
            setUhidmodalOpen(true);
        }
        setAllUHIDCustomer(allUHID);
    }, [redCoin?.uniquecustomer]);

    useEffect(() => {
        const selectedCustomer = new Array();
        allUHIDCustomer.map((data: any, index: any) => {
            if (data.isSelected) {
                selectedCustomer.push(data);
            }
        });
        if (selectedCustomer.length !== 0) {
            setInputList(selectedCustomer);
        }
    }, [allUHIDCustomer]);

    const handleUHIDAddCustomer = (data: any, index: any) => {
        const list = [...allUHIDCustomer];
        list[index]["isSelected"] = true;
        setAllUHIDCustomer(list);
    };
    const handleUHIDRemoveCustomer = (data: any, index: any) => {
        const list = [...allUHIDCustomer];
        list[index]["isSelected"] = false;
        setAllUHIDCustomer(list);
    };
    //UHID End

    //mapmyindia
    // const handleKeyDown =(e:any) =>{
    //   e.preventDefault();
    //   if (e.key === "Delete" || e.key === "Backspace") {
    //     setbackSpaceDetcet(true)
    // }
    // else{
    //   setbackSpaceDetcet(false)
    // }
    // }
    const handleHomeCollection = () => {
        setCollectionCenterId("")
        setCollectionkey(collectionKey + 2)
        setCollectionType("homecollection")
    }
    const handleCollectionCentre = () => {

        setCollectionType("collectioncentre")
    }
    const handleCopySearch = (id: any) => {
        setSearch(id);
        let body = {
            search_by: "call_id",
            value: id,
        };
        getLeads(body);
    };
    const handleRefreshLead = () => {
        getBreakResponse(`self=true`)
    };
    useEffect(()=>{
        if(areaList&&areaList?.results&&areaList?.results.length>0){
            setPinCode(areaList?.results[0].pincode);
            setZoneName(areaList?.results[0].city_name);
        }
        
    },[areaList])
    document.querySelectorAll("#noTyping").forEach(el=>{
        el.addEventListener("keydown", function(e){
          e.preventDefault()
        })
      })
    return (
        <main className={classes.content}>
            <div className={classes.contentTable}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={2}>
                        <h6>Search Lead</h6>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        direction="row"
                        justify="flex-start"
                    >
                        <TextField
                            className="input"
                            id="search"
                            type="search"
                            value={search}
                            variant="outlined"
                            placeholder="Search by Name/Phone No./Booking Id"
                            onChange={(e) => handleSearch(e)}
                            style={{ margin: "0", padding: "0 9px", width: "80%" }}
                        />
                        <Button
                            variant="contained"
                            style={{ height: "56px" }}
                            disabled={search.length === 0}
                            color="primary"
                            onClick={searchLead}
                        >
                            Search
                        </Button>
                    </Grid>
                    {/* <Grid item xs={12} md={3}>
            <Stack direction="row" spacing={1}>
              {breakResponse &&
                breakResponse?.results &&
                breakResponse?.results.length > 0? 
                breakResponse?.results.map((data: any) => {
                  return (
                    <>
                    <Chip
                      label={data?.lead_id !== null ? `${data?.notification} `: "NA"}
                      onClick={()=>handleCopySearch(data?.lead_id)}
                      icon={<CopyAllIcon />}
                    />
                    <Chip
                      label=""
                      onClick={handleRefreshLead}
                      icon={<RefreshIcon/>}
                    />
                    </>
                  )
                }):( <Chip
                  label={ "NA"}
                  icon={<CopyAllIcon />}
                />)}

            </Stack>
          </Grid> */}
                </Grid>
                {leads.results && leads.results.length > 0 && (
                    <TableContainer className={classes.table}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Lead ID</TableCell>
                                    <TableCell align="center">Name</TableCell>
                                    <TableCell align="center">Age</TableCell>
                                    <TableCell align="center">Gender</TableCell>
                                    <TableCell align="center">Mobile Number</TableCell>
                                    <TableCell align="center">Whatsapp Number</TableCell>
                                    <TableCell align="center">Address</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {leads.results.map((lead: any) => {
                                    return (
                                        <TableRow>
                                            <TableCell align="center">{lead.id || ""}</TableCell>
                                            <TableCell align="center">
                                                {lead.patient_name || ""}
                                            </TableCell>
                                            <TableCell align="center">
                                                {lead.patient_age || ""}
                                            </TableCell>
                                            <TableCell align="center">
                                                {lead.calling_gender || ""}
                                            </TableCell>
                                            <TableCell align="center">XXXXXXXXXX</TableCell>
                                            <TableCell align="center">XXXXXXXXXX</TableCell>
                                            <TableCell align="center">{lead.address || ""}</TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() => selectLead(lead.id)}
                                                >
                                                    Select
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </div>
            <div className={classes.contentTable}>
                <form onSubmit={submitForm}>
                    <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} style={{ paddingTop: "35px" }}>
                                    <p >Booking With Radiology</p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Select
                                        className="input"
                                        name="bookingreadology"
                                        variant="outlined"
                                        value={readology}
                                        style={{ width: "100%", margin: "0" }}
                                        onChange={(e) => setReadology(e.target.value)}
                                    >
                                        <MenuItem value="true">Yes</MenuItem>
                                        <MenuItem value="false">No</MenuItem>

                                    </Select>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <p>Booking Date</p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <input
                                        className="input"
                                        name="collection_date"
                                        type="date"
                                        value={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                                            ? `0${new Date().getMonth() + 1}`
                                            : new Date().getMonth() + 1
                                            }-${new Date().getDate() < 10
                                                ? `0${new Date().getDate()}`
                                                : new Date().getDate()
                                            }`}
                                        style={{
                                            width: "100%",
                                            margin: "0",
                                            padding: "18.5px 14px",
                                        }}
                                        required
                                        disabled
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <p>Sample Collection date</p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <input
                                        className="input"
                                        name="collection_date"
                                        type="date"
                                        value={
                                            (collection_date && collection_date.toString()) || ""
                                        }
                                        // onChange={(e) => setCollection_date(e.target.value as string)}
                                        onChange={(e) => changeCollectionDate(e)}
                                        style={{
                                            width: "100%",
                                            margin: "0",
                                            padding: "18.5px 14px",
                                        }}
                                        required
                                        id="noTyping"
                                        max={`${new Date().getFullYear() + 1}-${
                                        new Date().getMonth() + 1 < 10
                                            ? `0${new Date().getMonth() + 1}`
                                            : new Date().getMonth() + 1
                                        }-${
                                        new Date().getDate() < 10
                                            ? `0${new Date().getDate()}`
                                            : new Date().getDate()
                                        }`}
                                        min={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                                            ? `0${new Date().getMonth() + 1}`
                                            : new Date().getMonth() + 1
                                            }-${new Date().getDate() < 10
                                                ? `0${new Date().getDate()}`
                                                : new Date().getDate()
                                            }`}
                                    />
                                </Grid>
                            </Grid>
                            {!pincode ? (
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <p>&nbsp;</p>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            style={{ fontSize: "10px" }}
                                            onClick={() => setOpenLocationModal(true)}
                                            disabled={collection_date === ""}
                                        >
                                            Select Address
                                        </Button>
                                       
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <p>&nbsp;</p>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            style={{ fontSize: "10px" }}
                                            onClick={() => setOpenLocationModal(true)}
                                            disabled={collection_date === ""}
                                        >
                                            Edit Address
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid
                                container
                                spacing={0}
                                style={{
                                    padding: "10px",
                                    background: "#e1f5fe",
                                    marginLeft: "-10px",
                                }}
                            >
                                {/* <Grid item xs={12} md={6}>
                  <p>City </p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>
                    : <strong>{cityName}</strong>
                    &nbsp;{" "}
                  </p>
                </Grid> */}
                                <Grid item xs={12} md={6}>
                                    <p>Locality </p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <p>
                                        : <strong>{customer_landmark}</strong>
                                        &nbsp;{" "}
                                    </p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <p>House No./Plot No./Flat No./Door No./Shop/C/-</p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <p>
                                        : <strong>{customer_address}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <p style={{ fontSize: "14px", }}>
                                        Apartment/Building/Colony/Block/Sector/
                                        Street/Gali/Road/Chawl
                                    </p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <p>
                                        : <strong>{addressLine2}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <p>Landmark/Sublocality</p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <p>
                                        : <strong>{favoritePlace}</strong>
                                    </p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <p>Pincode: </p>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <p>
                                        : <strong>{pincode}</strong>
                                    </p>
                                </Grid>


                                {addressLine2 === "" ||
                                    customer_address === "" ||
                                    favoritePlace === "" ? (
                                    <p style={{ color: "red", alignItems: "center" }}>
                                        All Field are mandatory for Proceed booking
                                    </p>
                                ) : (
                                    ""
                                )}
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                            <Grid item xs={12} md={6}>
                                <Button variant="contained" fullWidth style={{ background: collectionType === "homecollection" ? "#cddc39" : "" }} onClick={handleHomeCollection}>Home Collection</Button>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button variant="contained" fullWidth style={{ background: collectionType === "collectioncentre" ? "#cddc39" : "" }} onClick={handleCollectionCentre}>Collection Centre</Button>
                            </Grid>
                        </Grid>
                        {pincode && ceCenterForCC && ceCenterForCC.length > 0 ? (
                            <>
                                {collectionType === "homecollection" ? "" : (
                                    <Grid item xs={12} md={12} sm={12}>
                                        <Autocomplete
                                            id="centre"
                                            key={collectionKey}
                                            onChange={(event, newValue) => {
                                                let leadIds: any = "";
                                                if (newValue) {
                                                    let obj = JSON.parse(
                                                        JSON.stringify(newValue, null, " ")
                                                    );
                                                    leadIds = obj.id;
                                                }
                                                setCollectionCenterId(leadIds);
                                            }}
                                            options={ceCenterForCC || []}
                                            freeSolo
                                            blurOnSelect
                                            loading={loading}
                                            aria-required
                                            limitTags={1}
                                            onInputChange={(event, newInputValue) => {
                                                clearTimeout(timer.current);
                                                timer.current = setTimeout(() => {
                                                    getCenterInfo(`?name_or_code=${newInputValue}`);
                                                }, 500);
                                            }}
                                            getOptionLabel={(option: any) =>
                                                "Centre : (" + option?.center_code + ") " + option?.display_name + " Distance : " + Math.trunc(option?.distance) + "KM" + " Address : " + option?.address}
                                            disableClearable
                                            disableCloseOnSelect
                                            renderInput={(params) => (
                                                <TextField
                                                    className="input"
                                                    {...params}
                                                    label="Collection Centre"
                                                    variant="outlined"
                                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        onKeyDown: (e) => {
                                                              if (e.key === 'Enter') {
                                                                e.stopPropagation();
                                                              }
                                                        },
                                                      }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                )}
                            </>
                        ) : (
                            ""
                        )}
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-end"
                        spacing={3}
                        style={{ margin: "1rem auto", background: "#eff" }}
                    >
                        <Grid item md={3}>
                            {customer_phonenumber && customer_phonenumber.length === 10 ? (
                                <p style={{ fontWeight: "bold", color: "#7b1fa2" }}>
                                    {redCoin?.coins ? redCoinMessage : ""}
                                </p>
                            ) : (
                                ""
                            )}
                            {customer_phonenumber && customer_phonenumber.length === 10 ? (
                                redCoin && redCoin?.coins && redCoin?.coins > 0 ? (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={redCashCheck}
                                                onChange={handleRedCheck}
                                                name="Redeem Redcash"
                                            />
                                        }
                                        label="Redeem Redcash"
                                    />
                                ) : (
                                    ""
                                )
                            ) : (
                                ""
                            )}
                            <p>Mobile Number</p>
                            {lead_customer_phonenumber !== undefined ? (
                                <>
                                    <TextField
                                        className="input"
                                        name="customer_phonenumber"
                                        type="text"
                                        onWheel={(e: any) => e.target.blur()}
                                        disabled
                                        value={"XXXXXXXXXX"}
                                        placeholder="Enter Mobile Number"
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                    />
                                    <p style={{ fontWeight: "bold", color: "#7b1fa2" }}>
                                        {redCoin?.coins ? redCoinMessage : ""}
                                    </p>
                                    {redCoin && redCoin?.coins && redCoin?.coins > 0 ? (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={redCashCheck}
                                                    onChange={handleRedCheck}
                                                    name="Redeem Redcash"
                                                />
                                            }
                                            label="Redeem Redcash"
                                        />
                                    ) : (
                                        ""
                                    )}
                                </>
                            ) : (
                                <>
                                    <TextField
                                        className="input"
                                        name="customer_phonenumber"
                                        type="number"
                                        onWheel={(e: any) => e.target.blur()}
                                        onInput={(e: any) => {
                                            e.target.value = Math.max(0, parseInt(e.target.value))
                                                .toString()
                                                .slice(0, 10);
                                        }}
                                        value={customer_phonenumber || ""}
                                        placeholder="Enter Mobile Number"
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) =>
                                            setCustomer_phonenumber(e.target.value as String)
                                        }
                                        onBlur={() => verifyMobile("mobile")}
                                        onFocus={() => {
                                            setMobileValidation((prev: any) => ({
                                                ...prev,
                                                customer_phonenumber: true,
                                            }));
                                        }}
                                        helperText={
                                            !mobileValidation.customer_phonenumber &&
                                            "Incorrect Phone Number"
                                        }
                                        required
                                    />
                                </>
                            )}
                        </Grid>
                        <p style={{ color: "red" }}>{mobError}</p>
                        <Grid item md={3}>
                            <p>Alternate Mobile Number</p>
                            {lead_customer_phonenumber !== undefined ? (
                                <TextField
                                    className="input"
                                    name="customer_alternatenumber"
                                    type="text"
                                    onWheel={(e: any) => e.target.blur()}
                                    disabled
                                    value={"XXXXXXXXXX"}
                                    placeholder="Enter Alternate Mobile Number"
                                    variant="outlined"
                                    style={{ margin: "0", width: "100%" }}
                                />
                            ) : (
                                <TextField
                                    className="input"
                                    name="customer_alternatenumber"
                                    type="number"
                                    onInput={(e: any) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value))
                                            .toString()
                                            .slice(0, 10);
                                    }}
                                    onWheel={(e: any) => e.target.blur()}
                                    value={customer_alternatenumber}
                                    placeholder="Enter Alternate Mobile Number"
                                    variant="outlined"
                                    style={{ margin: "0", width: "100%" }}
                                    onChange={(e) =>
                                        setCustomer_alternatenumber(e.target.value as String)
                                    }
                                    onBlur={() => verifyMobile("alternate")}
                                    onFocus={() => {
                                        setMobileValidation((prev: any) => ({
                                            ...prev,
                                            customer_alternatenumber: true,
                                        }));
                                    }}
                                    helperText={
                                        !mobileValidation.customer_alternatenumber &&
                                        "Incorrect Phone Number"
                                    }
                                    required
                                />
                            )}
                        </Grid>
                        <p style={{ color: "red" }}>{altError}</p>
                        <Grid item md={3}>
                            <p>Whatsapp Number</p>
                            {lead_customer_whatsapppnumber !== undefined ? (
                                <TextField
                                    className="input"
                                    name="customer_whatsapppnumber"
                                    type="text"
                                    placeholder="Enter Whatsapp Number"
                                    disabled
                                    value={"XXXXXXXXXX"}
                                    variant="outlined"
                                    style={{ margin: "0", width: "100%" }}
                                />
                            ) : (
                                <TextField
                                    className="input"
                                    name="customer_whatsapppnumber"
                                    type="number"
                                    onInput={(e: any) => {
                                        e.target.value = Math.max(0, parseInt(e.target.value))
                                            .toString()
                                            .slice(0, 10);
                                    }}
                                    onWheel={(e: any) => e.target.blur()}
                                    placeholder="Enter Whatsapp Number"
                                    value={customer_whatsapppnumber || ""}
                                    variant="outlined"
                                    style={{ margin: "0", width: "100%" }}
                                    onChange={(e) =>
                                        setCustomer_whatsapppnumber(e.target.value as String)
                                    }
                                    required
                                    onBlur={() => verifyMobile("whatsapp")}
                                    onFocus={() => {
                                        setMobileValidation((prev: any) => ({
                                            ...prev,
                                            customer_whatsapppnumber: true,
                                        }));
                                    }}
                                    helperText={
                                        !mobileValidation.customer_whatsapppnumber &&
                                        "Incorrect Whatsapp Number"
                                    }
                                />
                            )}
                        </Grid>
                        <p style={{ color: "red" }}>{whatsError}</p>
                        <Grid item md={3}>
                            <p>Email</p>
                            <TextField
                                className="input"
                                name="customer_email"
                                type="email"
                                placeholder="customer.reports@redcliffelabs.com"
                                value={customer_email}
                                variant="outlined"
                                style={{ margin: "0", width: "100%" }}
                                onChange={(e) => setCustomer_email(e.target.value as String)}
                            />
                        </Grid>
                        <Grid item md={3}></Grid>
                    </Grid>
                    {inputList.map((x: any, index: number) => {
                        return (
                            <>
                                <p>{x.customerId !== "" ? "UHID :" + x.customerId : ""}</p>
                                <Grid
                                    container
                                    direction="row"
                                    spacing={3}
                                    style={{ margin: "1rem auto", background: "#e1f5fe" }}
                                >
                                    <Grid item md={1}>
                                        {inputList.length !== 1 && (
                                            <HighlightOffIcon
                                                style={{
                                                    marginBottom: "1rem",
                                                    height: "40px",
                                                    width: "40px",
                                                    borderRadius: "50%",
                                                }}
                                                onClick={() => handleRemoveClick(index)}
                                            />
                                        )}
                                        {inputList.length - 1 === index && inputList.length < 5 && (
                                            <AddCircleIcon
                                                style={{
                                                    marginBottom: "1rem",
                                                    height: "40px",
                                                    width: "40px",
                                                    borderRadius: "50%",
                                                }}
                                                onClick={handleAddClick}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item md={2}>
                                        <p>Title</p>
                                        <TextField
                                            className="input"
                                            select
                                            name="designation"
                                            variant="outlined"
                                            value={x.designation}
                                            style={{ width: "100%", margin: "0" }}
                                            onChange={(e) => handleInputChange(e, index)}
                                            required
                                        >
                                            <MenuItem value="Mrs">Mrs</MenuItem>
                                            <MenuItem value="Miss">Miss</MenuItem>
                                            <MenuItem value="Ms">Ms</MenuItem>
                                            <MenuItem value="Smt">Smt</MenuItem>
                                            <MenuItem value="Mr">Mr</MenuItem>
                                            <MenuItem value="Baby Boy">Baby Boy</MenuItem>
                                            <MenuItem value="Baby Girl">Baby Girl</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Grid item md={2}>
                                        <p>Customer Name</p>
                                        <TextField
                                            className="input"
                                            name="customerName"
                                            type="text"
                                            placeholder="Enter Name"
                                            value={x.customerName || ""}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            style={{ width: "100%", margin: "0" }}
                                            onChange={(e) => handleInputChange(e, index)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item md={2}>
                                        <p>DOB</p>
                                        <input
                                            className="input"
                                            name="dateOfBirth"
                                            type="date"
                                            value={x.dateOfBirth}
                                            onChange={(e) => handleInputChange(e, index)}
                                            max={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                                                ? `0${new Date().getMonth() + 1}`
                                                : new Date().getMonth() + 1
                                                }-${new Date().getDate() < 10
                                                    ? `0${new Date().getDate()}`
                                                    : new Date().getDate()
                                                }`}
                                            style={{
                                                width: "100%",
                                                padding: "15px 14px",
                                                background: "#eff",
                                            }}
                                        />
                                    </Grid>
                                    <Grid item md={1}>
                                        <p>Age</p>
                                        <TextField
                                            className="input"
                                            name="customerAge"
                                            type="number"
                                            placeholder="Enter Age"
                                            value={x.customerAge}
                                            disabled={x.dateOfBirth !== ""}
                                            variant="outlined"
                                            InputProps={{ inputProps: { min: 0 } }}
                                            style={{ width: "100%", margin: "0" }}
                                            onChange={(e) => handleInputChange(e, index)}
                                        />
                                    </Grid>
                                    <Grid item md={1}>
                                        <p>Gender</p>
                                        <TextField
                                            className="input"
                                            name="customerGender"
                                            variant="outlined"
                                            value={x.customerGender === "male" ? "Male" : x.customerGender === "female" ? "Female" : ""}
                                            style={{ width: "100%", margin: "0" }}
                                            onChange={(e) => handleInputChange(e, index)}
                                            disabled={true}
                                            required={true}
                                        />
                                        <p style={{ color: "red" }}>{inputList[0]?.customerGender === null ? "Please select gender" : ""}</p>
                                    </Grid>
                                    <Grid item md={3} >
                                        <p>Package Name</p>
                                        <Autocomplete
                                            id="customerPackage"
                                            key={packageKey}
                                            onChange={(event, newValue) => {
                                                if (newValue) {
                                                    let packs: any = [];
                                                    newValue.map((val: any) => {
                                                        let obj = JSON.parse(
                                                            JSON.stringify(val, null, " ")
                                                        );
                                                        packs.push(obj);
                                                    });
                                                    handlePackage(index, packs);
                                                }
                                            }}
                                            multiple
                                            disableClearable
                                            options={packageList?.results || []}
                                            limitTags={0}
                                            disabled={cityId===""}
                                            freeSolo
                                            disableCloseOnSelect
                                            aria-required
                                            onInputChange={(event, newInputValue) => {
                                                clearTimeout(timer.current);
                                                timer.current = setTimeout(() => {
                                                    getPackage(
                                                        `city_id=${cityId}&search=${newInputValue}&org_type=homedx&${x.customerGender}`
                                                    );
                                                }, 1000);
                                            }}
                                            value={x?.customerPackage}
                                            getOptionLabel={(option: any) => {
                                                return option.name && option.package_city_prices
                                                    ? option.name.toString() +
                                                    ", " +
                                                    option.package_city_prices.tat_time +
                                                    ", Rs. " +
                                                    option?.package_city_prices.offer_price +
                                                    "/-"
                                                    : option.name +
                                                    ", " +
                                                    option?.tat_time +
                                                    ", Rs. " +
                                                    option?.offer_price +
                                                    "/-";
                                            }}
                                            getOptionDisabled={(option: any) => {
                                                const packID = x?.customerPackage.map(
                                                    (pack: any) => pack.id
                                                );
                                                return packID.includes(option.id);
                                            }}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.package_city_prices
                                                        ? option.name.toString() +
                                                        ", " +
                                                        option.package_city_prices.tat_time +
                                                        ", Rs. " +
                                                        option?.package_city_prices.offer_price +
                                                        "/-"
                                                        : option.name.toString() +
                                                        ", " +
                                                        option.tat_time +
                                                        ", Rs." +
                                                        option?.offer_price +
                                                        "/-"}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    className="input"
                                                    {...params}
                                                    placeholder="Package"
                                                    variant="outlined"
                                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        onKeyDown: (e) => {
                                                              if (e.key === 'Enter') {
                                                                e.stopPropagation();
                                                              }
                                                        },
                                                      }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item md={2} style={{ marginLeft: "7rem" }}>
                                        <p>Passport</p>
                                        <TextField
                                            className="input"
                                            name="passport"
                                            type="text"
                                            placeholder="Enter Passport Number"
                                            value={x.passport || ""}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            style={{ width: "100%", margin: "0" }}
                                            onInput={(e: any) => {e.target.value = e.target.value.toString().slice(0, 10)}}
                                            onChange={(e) => handleInputChange(e, index)}
                                        />
                                    </Grid>
                                    <Grid item md={2}>
                                        <p>Aadhar Number</p>
                                        <TextField
                                            className="input"
                                            name="aadhar"
                                            type="number"
                                            onWheel={(e: any) => e.target.blur()}
                                            onInput={(e: any) => {
                                                e.target.value = Math.max(0, parseInt(e.target.value))
                                                    .toString()
                                                    .slice(0, 12);
                                            }}
                                            placeholder="Enter Aadhar Number"
                                            value={x.aadhar || ""}
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            style={{ width: "100%", margin: "0" }}
                                            onChange={(e) => handleInputChange(e, index)}
                                        />
                                    </Grid>
                                    <Grid item md={3}>
                                        <p>Booking For</p>
                                        <Select
                                            className="input"
                                            name="bookingFor"
                                            variant="outlined"
                                            value={x.bookingFor}
                                            style={{ width: "100%", margin: "0" }}
                                            required
                                            onChange={(e) => handleInputChange(e, index)}
                                        >
                                            <MenuItem value="self">Self</MenuItem>
                                            <MenuItem value="father_in_law">Father/Father in law</MenuItem>
                                            <MenuItem value="mother_in_law">Mother/Mother in law</MenuItem>
                                            <MenuItem value="son_in_law">Son/Son in law</MenuItem>
                                            <MenuItem value="daughter_in_law">Daughter/Daughter in law</MenuItem>
                                            <MenuItem value="spouse">Spouse</MenuItem>
                                            <MenuItem value="friend">Friend</MenuItem>
                                            <MenuItem value="other">Other</MenuItem>
                                        </Select>
                                    </Grid>
                                    <Grid item md={3}>
                                        <p>Is Prescribed</p>
                                        <Select
                                            className="input"
                                            name="is_prescribed"
                                            variant="outlined"
                                            value={x.is_prescribed}
                                            style={{ width: "100%", margin: "0" }}
                                            onChange={(e) => handleInputChange(e, index)}
                                        >
                                            <MenuItem value="true">Yes</MenuItem>
                                            <MenuItem value="false">No</MenuItem>
                                        </Select>
                                    </Grid>
                                </Grid>
                            </>
                        );
                    })}
                    <Grid
                        container
                        spacing={3}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        style={{ margin: "1rem auto" }}
                    >
                        <Grid item xs={12} md={5}>
                            <Card
                                className="sampleCollectionTimeNewBooking"
                                style={{ overflow: "auto", minHeight: "500px" }}
                            >
                                <p style={{ marginBottom: "1rem" }}>Sample Collection Time</p>
                                <div className={classes.slotContainer}>
                                    {
                                        collectionType === "collectioncentre" ? (
                                            timeslots && timeslots?.results && timeslots.results.length > 0 &&
                                            pincode !== "" && timeslots.results.map((slot: any) => {
                                                return (
                                                    <Card
                                                        key={slot.id}
                                                        className={
                                                            slot.id === selectedSlot
                                                                ? classes.selectedSlot
                                                                : classes.slot
                                                        }
                                                        onClick={() => setSelectedSlot(slot.id as Number)}
                                                    >
                                                        <p
                                                            style={{
                                                                textAlign: "center",
                                                                color: "black",
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            {slot.start_time}-{slot.end_time}
                                                        </p>
                                                        <p style={{ textAlign: "center", color: "green" }}>
                                                        </p>
                                                    </Card>
                                                )
                                            })
                                        ) : (
                                            timeSlots &&
                                            timeSlots.length > 0 &&
                                            pincode !== "" &&
                                            timeSlots.map((slot: any) => {
                                                return slot.available_slots > 0 ? (
                                                    <Card
                                                        key={slot.id}
                                                        className={
                                                            slot.id === selectedSlot
                                                                ? classes.selectedSlot
                                                                : classes.slot
                                                        }
                                                        onClick={() => setSelectedSlot(slot.id as Number)}
                                                    >
                                                        <p
                                                            style={{
                                                                textAlign: "center",
                                                                color: "black",
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            {slot.start_time1hr}-{slot.end_time1hr}
                                                        </p>
                                                        <p style={{ textAlign: "center", color: "green" }}>
                                                            {" "}
                                                            Available slots: {slot.available_slots}
                                                        </p>
                                                    </Card>
                                                ) : (
                                                    <Card
                                                        key={slot.id}
                                                        className={
                                                            slot.id === selectedSlot
                                                                ? classes.selectedSlot
                                                                : classes.slot
                                                        }
                                                        onClick={() =>
                                                            alert("No Available Slot for this Area")
                                                        }
                                                    >
                                                        <p
                                                            style={{
                                                                textAlign: "center",
                                                                color: "black",
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            {slot.start_time1hr}-{slot.end_time1hr}
                                                        </p>
                                                        <p style={{ textAlign: "center", color: "green" }}>
                                                            {" "}
                                                            Available slots: {slot.available_slots}
                                                        </p>
                                                    </Card>
                                                );
                                            })
                                        )
                                    }
                                </div>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={7} style={{ padding: "10px" }}>
                            <div className="price-details-new-booking">
                                <table style={{ width: "100%", margin: "0" }}>
                                    <thead>
                                        <tr>
                                            <td className="cell" align="center">
                                                S No.
                                            </td>
                                            <td className="cell" align="center">
                                                Name
                                            </td>
                                            <td className="cell" align="center">
                                                Package
                                            </td>
                                            <td className="cell" align="center">
                                                Price
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {inputList &&
                                            inputList.length > 0 &&
                                            inputList.map((customerDetails: any, index: number) => {
                                                return (
                                                    <tr>
                                                        <td className="cell" align="center">
                                                            {index + 1}
                                                        </td>
                                                        <td className="cell" align="left">
                                                            {customerDetails.designation}{" "}
                                                            {customerDetails.customerName}
                                                        </td>
                                                        <td className="cell" align="left">
                                                            {customerDetails?.customerPackage &&
                                                                customerDetails?.customerPackage.length > 0 &&
                                                                customerDetails?.customerPackage.map(
                                                                    (packageItem: any, index: any) => {
                                                                        return (
                                                                            <ul>
                                                                                <li>
                                                                                    <a
                                                                                        onClick={() => {
                                                                                            setSinglePackage(packageItem);
                                                                                            setPaneOpen(true);
                                                                                        }}
                                                                                        className={classes.packageLink}
                                                                                    >
                                                                                        {packageItem?.name}
                                                                                        <br /> DESCRIPTION :{" "}
                                                                                        {packageItem?.description}
                                                                                        <br />
                                                                                        TaT :{" "}
                                                                                        {packageItem.package_city_prices
                                                                                            ? packageItem.package_city_prices
                                                                                                .tat_time
                                                                                            : packageItem.tat_time}
                                                                                        <br />
                                                                                        Price :{" "}
                                                                                        {packageItem.package_city_prices
                                                                                            ? packageItem.package_city_prices
                                                                                                .offer_price
                                                                                            : packageItem.offer_price}
                                                                                        <br />
                                                                                        Fasting :{" "}
                                                                                        {packageItem?.fasting_time}
                                                                                    </a>
                                                                                </li>
                                                                            </ul>
                                                                        );
                                                                    }
                                                                )}
                                                        </td>
                                                        <td className="cell" align="center">
                                                            ₹ {customerDetails?.packagePrice}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        <tr>
                                            <td colSpan={4}>
                                                <table style={{ width: "100%" }}>
                                                    <tr>
                                                        <td
                                                            className="cell"
                                                            align="left"
                                                            rowSpan={4}
                                                            colSpan={2}
                                                            style={{ width: "60%" }}
                                                            valign="top"
                                                        >
                                                            <Grid container spacing={1} direction="row">
                                                                <Grid item xs={12}>
                                                                    <p>Enter Coupon</p>
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <Autocomplete
                                                                        id="coupon"
                                                                        key={couponKey}
                                                                        onChange={(event, newValue) => {
                                                                            if (newValue) {
                                                                                let obj = JSON.parse(
                                                                                    JSON.stringify(newValue, null, " ")
                                                                                );
                                                                                setCoupon(obj.id as Number);
                                                                                setCouponCode(obj.code as string);
                                                                                // setCouponType(obj.is_red_cash);
                                                                                setRedCouponDiscount(
                                                                                    obj.discount as number
                                                                                );
                                                                            }
                                                                        }}
                                                                        inputValue={couponCode}
                                                                        options={couponList?.results || []}
                                                                        freeSolo
                                                                        blurOnSelect
                                                                        disabled={redCashCheck}
                                                                        onInputChange={(event, newInputValue) => {
                                                                            clearTimeout(timer.current);
                                                                            setCouponCode(newInputValue);
                                                                            timer.current = setTimeout(() => {
                                                                                getCoupons(
                                                                                    `?code=${newInputValue}&org_type=homedx`
                                                                                );
                                                                            }, 1000);
                                                                            if (newInputValue === "") {
                                                                                setCoupon(0);
                                                                                setDiscount(0);
                                                                                setTncMessage("");
                                                                                setCouponMessage("");
                                                                            }
                                                                        }}
                                                                        getOptionLabel={(option: any) =>
                                                                            option.code
                                                                        }
                                                                        getOptionDisabled={(option: any) =>
                                                                            option.minimum_price > totalPrice
                                                                        }
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                className="input"
                                                                                {...params}
                                                                                placeholder="Enter Coupon"
                                                                                variant="outlined"
                                                                                style={{
                                                                                    width: "100%",
                                                                                    margin: "0",
                                                                                    padding: "0",
                                                                                }}
                                                                                inputProps={{
                                                                                    ...params.inputProps,
                                                                                    onKeyDown: (e) => {
                                                                                          if (e.key === 'Enter') {
                                                                                            e.stopPropagation();
                                                                                          }
                                                                                    },
                                                                                  }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={4}>
                                                                    <Button
                                                                        variant="contained"
                                                                        fullWidth
                                                                        color="primary"
                                                                        disabled={redCashCheck}
                                                                        onClick={applyCoupon}
                                                                    >
                                                                        Apply
                                                                    </Button>
                                                                </Grid>
                                                                <p
                                                                    style={{
                                                                        color: "#8e24aa",
                                                                        fontSize: "14px",
                                                                        fontWeight: "bold",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    {couponMessage} <br />
                                                                    {tncMessage}
                                                                </p>
                                                            </Grid>
                                                        </td>
                                                        <td
                                                            className="cell"
                                                            align="right"
                                                            style={{ width: "20%" }}
                                                        >
                                                            Discount
                                                        </td>
                                                        <td
                                                            className="cell"
                                                            align="center"
                                                            style={{ width: "20%" }}
                                                        >
                                                            ₹ {Math.floor(discount)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td
                                                            className="cell"
                                                            align="right"
                                                            style={{ width: "20%" }}
                                                        >
                                                            RedCash Discount
                                                        </td>
                                                        <td
                                                            className="cell"
                                                            align="center"
                                                            style={{ width: "20%" }}
                                                        >
                                                            ₹ {redDisc > 0 ? Math.floor(redDisc) : 0}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="cell" align="right">
                                                            Phlebo Cost
                                                        </td>
                                                        <td className="cell" align="center">
                                                            ₹ {extraCharge ? "+ 200" : "0"}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="cell" align="right">
                                                            <h4 className="totalPrice">Total Price</h4>
                                                        </td>
                                                        <td className="cell" align="center">
                                                            <h4 className="totalPrice">
                                                                ₹{" "}
                                                                {redDisc > 0
                                                                    ? Math.floor(totalPrice) - Math.floor(redDisc)
                                                                    : Math.floor(totalPrice)}
                                                            </h4>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Grid>
                    </Grid>
                    <div className={classes.buttonContainer}>
                        <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                            style={{ marginLeft: "1rem", marginBottom: "20px" }}
                            disabled={
                                loading ||
                                selectedSlot == 0 ||
                                sameNumberBooking === true ||
                                addressLine2 === "" ||
                                customer_address === "" ||
                                favoritePlace === "" ||
                                !singlePackage || inputList[0]?.customerGender === null
                            }
                        >
                            Proceed for booking
                        </Button>
                        <Dialog
                            open={uhidModalOpen}
                            onClose={handleCloseUHID}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">UNIQUE CUSTOMER</DialogTitle>
                            <DialogTitle id="alert-dialog-title">
                                {inputList.length === 5 ? "Member Add Limit is 4 only" : ""}
                            </DialogTitle>
                            <DialogContent>
                                <TableContainer className={classes.table}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">Lead ID</TableCell>
                                                <TableCell align="center">Name</TableCell>
                                                <TableCell align="center">Gender</TableCell>
                                                <TableCell align="center">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {allUHIDCustomer &&
                                                allUHIDCustomer.length > 0 &&
                                                allUHIDCustomer.map((data: any, index: any) => {
                                                    return (
                                                        <TableRow key={index}>
                                                            <TableCell align="center">
                                                                {data.lead || ""}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {data.customerName || ""}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {data.customerGender || ""}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {data.isSelected ? (
                                                                    ""
                                                                ) : (
                                                                    <Button
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        disabled={inputList.length === 5}
                                                                        onClick={() =>
                                                                            handleUHIDAddCustomer(data, index)
                                                                        }
                                                                    >
                                                                        Add
                                                                    </Button>
                                                                )}

                                                                {data.isSelected ? (
                                                                    <Button
                                                                        variant="contained"
                                                                        color="secondary"
                                                                        onClick={() =>
                                                                            handleUHIDRemoveCustomer(data, index)
                                                                        }
                                                                    >
                                                                        Remove
                                                                    </Button>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    );
                                                })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </DialogContent>
                        </Dialog>
                    </div>

                    {/* Location Map Modal  */}
                    <Dialog
                        open={openLocationModal}
                        onClose={() => setOpenLocationModal(false)}
                        aria-labelledby="form-dialog-title"
                        className={classes.dialog}
                    >
                        <DialogTitle id="form-dialog-title">Add New Address</DialogTitle>
                        <DialogContent>
                            <Grid
                                container
                                spacing={2}
                                style={{
                                    margin: "0px 10px 10px 10px",
                                    minWidth: "500px",
                                    width: "70%",
                                }}
                            >
                                {/* <Grid item xs={12} md={12}>
                  <p>{LOCALITY}</p>
                  <Autocomplete
                    id="customer_landmark"
                    key={1}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setMapAddressObject(obj);
                      }
                    }}
                    options={suggestedAddress ? suggestedAddress : []}
                    freeSolo
                    aria-required
                    getOptionLabel={(option: any) =>
                      `${option?.placeName}, ${option?.placeAddress}`
                    }
                    filterOptions={(option: any) => option}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        locationAutoCompleteSearch(newInputValue, event);
                      }, 500);
                    }}
                    // onKeyDown={(e)=>handleKeyDown(e)}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Search Locality"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        disabled={collection_date === ""}
                        required
                      />
                    )}
                  />
                  <p style={{ color: "red" }}>{errorMessage}</p>
                </Grid> */}
                                <Grid item xs={12} md={6}>
                                    <p>Zone Name</p>

                                    <Autocomplete
                                        id="areaname"
                                        key={zoneKey}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                                setCityId(obj?.city.id)
                                                setZoneId(obj?.id)
                                            }

                                        }}
                                        options={zoneList.results || []}
                                        freeSolo
                                        blurOnSelect
                                        aria-required
                                        limitTags={1}
                                        onInputChange={(event, newInputValue) => {
                                            clearTimeout(timer.current);
                                            timer.current = setTimeout(() => {
                                                getZone(`${newInputValue}`);
                                            }, 500);
                                        }}
                                        getOptionLabel={(option: any) => option?.city?.name}
                                        disableClearable
                                        disableCloseOnSelect
                                        renderInput={(params) => (
                                            <TextField
                                                className="input"
                                                {...params}
                                                label="Zone"
                                                variant="outlined"
                                                style={{ width: "100%", margin: "0", padding: "0" }}
                                            />
                                        )}
                                    />
                                </Grid>

                                {/* <Grid item xs={12} md={6}>
                                    <p>Area Name</p>

                                    <Autocomplete
                                        id="areaname"
                                        key={zoneKey}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                                setCustomer_areapincode(obj.pincode);
                                                setPinCode(obj.pincode)
                                                setZoneName(obj?.city_name)
                                            }

                                        }}
                                        options={areaList.results || []}
                                        freeSolo
                                        blurOnSelect
                                        aria-required
                                        limitTags={1}
                                        //      onInputChange={(event, newInputValue) => {
                                        //   clearTimeout(timer.current);
                                        //   timer.current = setTimeout(() => {
                                        //     getCenterInfo(`?name_or_code=${newInputValue}`);
                                        //   }, 500);
                                        // }}
                                        getOptionLabel={(option: any) => option?.pincode+", "+option?.area}
                                        disableClearable
                                        disableCloseOnSelect
                                        renderInput={(params) => (
                                            <TextField
                                                className="input"
                                                {...params}
                                                label="Area Name"
                                                variant="outlined"
                                                style={{ width: "100%", margin: "0", padding: "0" }}
                                            />
                                        )}
                                    />
                                </Grid> */}

                              
                                <Grid item xs={12} md={6}>
                                    <p>{LANDMARK}</p>
                                    <TextField
                                        className="input"
                                        placeholder="Ex: School/College/Restaurant/Shop/Bank/Government Office"
                                        name="zone"
                                        type="text"
                                        value={favoritePlace || ""}
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0" }}
                                        required
                                        inputProps={{ maxLength: 100 }}
                                        onChange={(e) => setFavoritePlace(e.target.value as string)}
                                    />
                                    <small>Max Length 100</small>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <p>{ADDRESS_LINE1}</p>
                                    <TextField
                                        className="input"
                                        name="zone"
                                        type="text"
                                        value={customer_address}
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0" }}
                                        required
                                        inputProps={{ maxLength: 30 }}
                                        onChange={(e) =>
                                            setCustomer_address(e.target.value as string)
                                        }
                                        placeholder="Type Here..."
                                    />
                                    <small>Max Length 30</small>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <p>{LOCALITY}</p>
                                    <TextField
                                        className="input"
                                        name="customer_landmark"
                                        type="text"
                                        value={customer_landmark}
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0" }}
                                        required
                                        inputProps={{ maxLength: 30 }}
                                        onChange={(e) =>
                                            setCustomer_landmark(e.target.value as string)
                                        }
                                        placeholder="Enter locality..."
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <p>{ADDRESS_LINE2}</p>
                                    <TextField
                                        className="input"
                                        name="zone"
                                        type="text"
                                        value={addressLine2 || ""}
                                        inputProps={{ maxLength: 30 }}
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0" }}
                                        required
                                        onChange={(e) => setAddressLine2(e.target.value as string)}
                                        placeholder="Type Here..."
                                    />
                                    <small>Max Length 30</small>
                                </Grid>
                              
                                <Grid item xs={12} md={6}>
                                    <p>{PINCODE}</p>
                                    <TextField
                                        className="input"
                                        name="zone"
                                        type="text"
                                        value={pincode || 0}
                                        disabled
                                        variant="outlined"
                                        style={{ width: "100%", margin: "0", background: "#ccc" }}
                                        required
                                    />
                                </Grid>
                                {/* <Grid item xs={12} md={6}>
                  <p>Zone/City</p>
                  <TextField
                    className="input"
                    name="city"
                    type="text"
                    value={customer_city}
                    variant="outlined"
                    style={{ width: "100%", margin: "0", background: "#ccc" }}
                    disabled={true}
                  />
                </Grid> */}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => setOpenLocationModal(false)}
                                color="primary"
                                disabled={customer_landmark === ""&&customer_address===""&&addressLine2===""||favoritePlace===""}
                            >
                                Select
                            </Button>
                            <Button
                                onClick={() => setOpenLocationModal(false)}
                                color="primary"
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            </div>
            <SlidingPane
                className="some-custom-class"
                overlayClassName="some-custom-overlay-class"
                isOpen={isPaneOpen}
                title={singlePackage?.name}
                width="80%"
                from="right"
                onRequestClose={() => {
                    setPaneOpen(false);
                }}
            >
                <div>
                    <iframe
                        src={singlePackage?.web_link}
                        style={{ width: "100%", height: "700px" }}
                        title="Iframe Example"
                    ></iframe>
                </div>
            </SlidingPane>
        </main>
    );
};
const mapStateToProps = (state: any) => ({
    pin_code: state.SalesLeadAdminReducer.pin_code,
    centerInfo: state.SalesLeadAdminReducer.centerInfo,
    packageList: state.SalesLeadAdminReducer.packageList,
    couponList: state.SalesLeadAdminReducer.couponList,
    createPilotBooking: state.SalesLeadAdminReducer.createPilotBooking,
    leads: state.SalesLeadAdminReducer.leads,
    lead_details: state.SalesLeadAdminReducer.lead_details,
    booking_slots: state.SalesLeadAdminReducer.booking_slots,
    sameNumberBooking: state.SalesLeadAdminReducer.sameNumberBooking,
    loading: state.SalesLeadAdminReducer.loading,
    checkGeoFenceStatus: state.SalesLeadAdminReducer.checkGeoFenceStatus,
    redCoin: state.SalesLeadAdminReducer.redCoin,
    geoFencesMapMyIndia: state.SalesLeadAdminReducer.geoFencesMapMyIndia,
    ceCenterForCC: state.SalesLeadAdminReducer.ceCenterForCC,
    mapmyindiaAccessToken: state.SalesLeadAdminReducer.mapmyindiaAccessToken,
    timeslots: state.SalesLeadAdminReducer.timeslots,
    displayCities: state.SalesLeadAdminReducer.displayCities,
    userDetails: state.SalesLeadAdminReducer.userDetails,
    breakResponse: state.SalesLeadAdminReducer.breakResponse,
    zoneList: state.SalesLeadAdminReducer.zoneList,
    areaList: state.SalesLeadAdminReducer.areaList,
});

export default connect(mapStateToProps, {
    getPinCode,
    getPackage,
    getCoupons,
    createNewBooking,
    getLeads,
    getLeadDetails,
    resetLeadDetails,
    getAvailableSlots,
    checkGeoFenceArea,
    getRedCoing,
    checkGeoFenchMapMyIndia,
    checkGeoFenchMapMyIndiaPilot,
    getMapMyIndiaAccessToken,
    getCenterInfo,
    getceCentre,
    getTimeslots,
    getDisplayCities,
    createPilotNewBooking,
    getBreakResponse,
    getZone,getArea
})(BookingWithLocalitySearch);
