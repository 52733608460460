import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Chip, Paper, Table, withStyles, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../Components/loader';
import BookingCommentsModal from '../Components/Comments/bookingComments'
import ImaginRaiseComplaintModal from '../Components/Comments/imaginRaiseComplaintModel'
import { MessageRounded } from '@material-ui/icons';
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import moment from 'moment';
import AdditonalFamilyModal from '../Tables/AdditionalFamily/AdditionFamilyDetails';
import NoteAddIcon from '@material-ui/icons/NoteAdd';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        conlorChange: {
            background: "red"
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },

    })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#3f51b5",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);
const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

interface Props {
    getBookings: any;
    bookingList: any;
    page: any;
    setPage: any;
    setopenModal: any;
    openModal: any;
    open2: any;
    setOpen2: any;
    setPaymentId: any;
    paymentId: any;
    openPaymentModal: any;
    setOpenPaymentModal: any;
    loading: Boolean;
    getPrescriptionData: any;
    prescription: any;
    prscriptionLead: any;
    setPrescriptionLead: any;
    setAnchorEl: any;
    bookingId:any;
    setBookingId:any
}

const BookingsTable: React.FC<Props> = ({
    getBookings,
    bookingList,
    page,
    setPage,
    setopenModal,
    openModal,
    open2,
    setOpen2,
    setPaymentId,
    setOpenPaymentModal,
    openPaymentModal,
    paymentId,
    loading,
    getPrescriptionData,
    setPrescriptionLead,
    prscriptionLead,
    prescription,
    setAnchorEl,
    bookingId,
    setBookingId
    
}) => {
    const classes = useStyles()
    const [bookingData, setBookingData] = useState<any>();
    const [lead, setLead] = useState<number>(0);
    const [openUpdate, setOpenUpdate] = React.useState(false);
    const [additionalMemebr, setAdditionalMemebr] = useState<any>()
    const [additionalModal, setAdditonalModal] = useState<boolean>(false)
    const [raiseImaginComplaint, setRaiseImaginComplaint] = useState(false)
    const [leadId, setLeadId] = useState<number>(0);
    const handleClick = (id: number) => {
        setopenModal(true);
        setBookingId(id);
    };

    const CATEGORY: any = [
        {
            id: 1,
            text: "Interested/Follow up",
            city: true,
            leadId: false
        },
        {
            id: 2,
            text: "Not Interested",
            city: true,
            query: false,
        },
        {
            id: 3,
            text: "Order Booked",
            city: true,
            query: false,
        },
        {
            id: 4,
            text: "Ringing",
            city: false,
            query: false,
        },
        {
            id: 5,
            text: "Number Doesn't Exist",
            city: false,
            query: false,
        },
        {
            id: 6,
            text: "By Mistake & Wrong Number",
            city: false,
            query: false,
        },
        {
            id: 7,
            text: "Call Back",
            city: true,
            query: false,
        },
        {
            id: 8,
            text: "Business Realated Call",
            city: true,
            query: false,
        },
        {
            id: 9,
            text: "Spam Call",
            city: false,
            query: false,
        },
        {
            id: 10,
            text: "Enquiry Call",
            city: true,
            query: false,
        },
        {
            id: 11,
            text: "CFresh",
            city: false,
            query: false,
        },
        {
            id: 12,
            text: "Test Not Available",
            city: true,
            query: false,
        },
        {
            id: 13,
            text: "Subscribed to Competitor",
            city: true,
            query: false,
        },
        {
            id: 14,
            text: "Slot Issue",
            city: true,
            query: false,
        },
        {
            id: 15,
            text: "Same Day Slot",
            city: true,
            query: false,
        },
        {
            id: 16,
            text: "Already Booked",
            city: false,
            query: false,
        },
        {
            id: 17,
            text: "Not Contactable",
            city: false,
            query: false,
        },
        {
            id: 18,
            text: "Out of Station",
            city: true,
            query: false,
        },
        {
            id: 19,
            text: "Out of Service Area",
            city: true,
            query: false,
        },
        {
            id: 20,
            text: "Need Time to Think",
            city: true,
            query: false,
        },
        {
            id: 21,
            text: "Email/Whatsapp Package",
            city: true,
            leadId: false
        },
        {
            id: 22,
            text: "Discount/Price Enquiry",
            city: true,
            leadId: false
        },
        {
            id: 23,
            text: "CS issue",
            city: false,
            leadId: true
        },
        {
            id: 24,
            text: "Call Not Reachable",
            city: false,
            leadId: false
        },
        {
            id: 25,
            text: "Call Later",
            city: true,
            leadId: false
        },
        {
            id: 26,
            text: "Call drop",
            city: true,
            leadId: false
        },
        {
            id: 27,
            text: "Call Disconnected",
            city: true,
            leadId: false
        },
        {
            id: 28,
            text: "Below <5 year patient",
            city: true,
            leadId: false
        },
        {
            id: 29,
            text: "DND",
            city: false,
            leadId: false
        },
        {
            id: 30,
            text: "Phlebo's Issue",
            city: false,
            leadId: true
        },
        {
            id: 31,
            text: "Reports Regarding Issue",
            city: false,
            leadId: true
        },
        {
            id: 32,
            text: "Health Advice",
            city: false,
            leadId: true
        },
        {
            id: 33,
            text: "Health Consultation",
            city: false,
            leadId: true
        },
        {
            id: 34,
            text: "Resampling",
            city: false,
            leadId: false
        },
        {
            id: 35,
            text: "Language Barrier",
            city: false,
            leadId: false
        },
    ]
    const handleUpdateBooking = (booking: number) => {
        setOpenUpdate(true)
        setBookingData(booking);
    };
    const handleRaiseImaginTicket = (id: number) => {
        setRaiseImaginComplaint(true);
        // setLeadId(id)
        setBookingId(id)
      
      };

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = bookingList.links && bookingList.links.next.split("?")[1];
            getBookings(url);
        } else if (newPage < page) {
            let url = bookingList.links && bookingList.links.previous.split("?")[1];
            getBookings(url);
        }
        setPage(newPage as number);
    };

    const handleModal = (leadid: number) => {
        setOpen2(true);
        setLead(leadid);
    };
    const handlePaymentInfo = (id: any) => {
        setPaymentId(id)
        setOpenPaymentModal(true);
        setPaymentId(id);
    };
    const handleAdditionalView = (member: any) => {
        setAdditionalMemebr(member);
        setAdditonalModal(true)

    }

    const handlePrescriptionClick = (id: any, event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setPrescriptionLead(id)
    }
    return (
        <div style={{ width: "100%" }} className="data-table">

            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "560px" }}>
                {loading ? <Loader /> : (
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="center">Action</StyledTableCell>
                                <StyledTableCell align="center">Booking_Id</StyledTableCell>
                                <StyledTableCell align="center">Lead_Id</StyledTableCell>
                                <StyledTableCell align="center">Booking Date</StyledTableCell>
                                <StyledTableCell align="center">Collection Date</StyledTableCell>
                                <StyledTableCell align="center">Collection Time</StyledTableCell>
                                <StyledTableCell align="center"><span style={{ color: "#3f51b5" }}>______</span>Center<span style={{ color: "#3f51b5" }}>_</span>Name<span style={{ color: "#3f51b5" }}>_____</span></StyledTableCell>
                                <StyledTableCell align="center"><span style={{ color: "#3f51b5" }}>______</span>Center<span style={{ color: "#3f51b5" }}>_</span>Address<span style={{ color: "#3f51b5" }}>_____</span></StyledTableCell>
                                <StyledTableCell align="center">Patient Name</StyledTableCell>
                                <StyledTableCell align="center">Prescription</StyledTableCell>
                                <StyledTableCell align="center">Raise Ticekt</StyledTableCell>
                                <StyledTableCell align="center">Age</StyledTableCell>
                                <StyledTableCell align="center">Gender</StyledTableCell>
                                <StyledTableCell align="center">Mobile Number</StyledTableCell>
                                <StyledTableCell align="center">Email</StyledTableCell>
                                <StyledTableCell align="center">Booking Status</StyledTableCell>
                                <StyledTableCell align="center">Report Status</StyledTableCell>
                                <StyledTableCell align="center">Pickup Status</StyledTableCell>
                                <StyledTableCell align="center">Verification Status</StyledTableCell>
                                <StyledTableCell align="center">Agent</StyledTableCell>
                                <StyledTableCell align="center">Patient Status</StyledTableCell>
                                <StyledTableCell align="center">Payment Mode</StyledTableCell>
                                <StyledTableCell align="center">Payment Id</StyledTableCell>
                                <StyledTableCell align="center">Pickup Recieve Amount</StyledTableCell>
                                <StyledTableCell align="center">Pickup Date</StyledTableCell>
                                <StyledTableCell align="center">Pickup Time</StyledTableCell>
                                <StyledTableCell align="center">Booking Time</StyledTableCell>
                                <StyledTableCell align="center">verified By</StyledTableCell>
                                <StyledTableCell align="center">Remarks</StyledTableCell>
                                <StyledTableCell align="center">Comment</StyledTableCell>
                                <StyledTableCell align="center">Payment Info</StyledTableCell>

                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {
                                bookingList?.results && bookingList?.results.length > 0 && bookingList?.results.map((booking: any, index: any) => {
                                    const pendingData = booking?.additional_members.filter((data: any) => data?.pickup_status === "pending")
                                    const confirmedData = booking?.additional_members.filter((data: any) => data?.pickup_status === "confirmed")
                                    return (
                                        <StyledTableRow key={index}>
                                            <StyledTableCell align="center">
                                                <Link to={`/dashboard/cca/booking-view/${booking.pk}`} style={{ textDecoration: "none" }}>
                                                    <Button variant="contained" color="secondary">View</Button>
                                                </Link>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{booking?.additional_members?.length > 0 ? (
                                                <div>
                                                    {booking.pk}+
                                                    <Chip
                                                        label={"T" + booking?.additional_members?.length + `${confirmedData?.length > 0 ? "C" + confirmedData?.length : ""}` + `${pendingData.length > 0 ? "P" + pendingData?.length : ""}`}
                                                        style={{ background: pendingData?.length > 0 ? "#e91e63" : "#1b5e20" }}
                                                        onClick={() => handleAdditionalView(booking?.additional_members)}
                                                    />
                                                </div>

                                            ) : booking.pk}</StyledTableCell>
                                            <StyledTableCell align="center">{booking?.lead}</StyledTableCell>
                                            <StyledTableCell align="center">{booking?.booking_date}</StyledTableCell>
                                            <StyledTableCell align="center">{booking?.collection_date}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                {booking?.collection_slot && booking?.collection_slot?.slot}
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{booking?.center_address?.center_name}</StyledTableCell>
                                            <StyledTableCell align="center">{booking?.center_address?.address}</StyledTableCell>
                                            <StyledTableCell align="center">{`${booking?.designation || ""} ${booking?.customer_name}`}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                {booking?.is_prescription ? (
                                                    <Button aria-controls="fade-menu" aria-haspopup="true" onClick={(event: any) => handlePrescriptionClick(booking.lead, event)}>
                                                        <NoteAddIcon />
                                                    </Button>) : "NA"}

                                            </StyledTableCell>
                                            <StyledTableCell component="th" scope="row">
                                                <Button variant={!raiseImaginComplaint ? "contained" : "outlined"} color="secondary" onClick={() => handleRaiseImaginTicket(booking?.pk)}>
                                                    <ConfirmationNumberIcon />
                                                </Button>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">{booking?.customer_age}</StyledTableCell>
                                            <StyledTableCell align="center">{booking?.customer_gender}</StyledTableCell>
                                            <StyledTableCell align="center">{booking?.customer_phonenumber}</StyledTableCell>
                                            <StyledTableCell align="center">{booking?.customer_email}</StyledTableCell>




                                            {/* <TableCell align="center">{booking.pickup_amount || "NA"}</TableCell> */}


                                            <StyledTableCell align="center"><Chip label={`${booking?.booking_status}`} style={{ background: booking?.booking_status === "pending" ? "#ffee58" : booking?.booking_status === "confirmed" ? "#00e676" : booking?.booking_status === "cancelled" ? "#ff3d00" : "#d500f9" }} /></StyledTableCell>
                                            <StyledTableCell align="center"><Chip label={`${booking?.report_status}`} style={{ background: booking?.report_status === "pending" ? "#ffee58" : booking?.report_status === "consolidate" ? "#00e676" : booking?.report_status === "partial" ? "#d500f9" : "#ff3d00" }} /></StyledTableCell>
                                            <StyledTableCell align="center"><Chip label={`${booking?.pickup_status}`} style={{ background: booking?.pickup_status === "pending" ? "#ffee58" : booking.pickup_status === "confirmed" ? "#00e676" : booking.pickup_status === "cancelled" ? "#ff3d00" : "#d500f9" }} /></StyledTableCell>
                                            <StyledTableCell align="center"><Chip label={`${booking?.verification_status}`} style={{ background: booking.verification_status === "pending" ? "#ffee58" : booking.verification_status === "confirmed" ? "#00e676" : booking.verification_status === "cancelled" ? "#ff3d00" : "#d500f9" }} /></StyledTableCell>
                                            <StyledTableCell align="center">{(booking?.agent && booking.agent.name) || "NA"}</StyledTableCell>
                                            <StyledTableCell align="center">{(booking?.patient_status===true?"Arrived":"Not Arrived")}</StyledTableCell>
                                            <StyledTableCell align="center">{booking?.payment_mode || "NA"}</StyledTableCell>
                                            {booking?.payment_mode === "online" && booking?.pickup_receive_amount !== null ? (
                                                <StyledTableCell align="center">{booking?.plink_id}</StyledTableCell>
                                            ) :
                                                <StyledTableCell align="center"></StyledTableCell>}
                                            <StyledTableCell align="center">{booking?.pickup_receive_amount}</StyledTableCell>
                                            <StyledTableCell align="center">{booking?.pickup_date}</StyledTableCell>
                                            <StyledTableCell align="center">{booking?.pickup_time}</StyledTableCell>
                                            <StyledTableCell align="center"> {new Date(booking.created_at).toLocaleString()}</StyledTableCell>
                                            <StyledTableCell align="center">{booking?.verify_by?.name}</StyledTableCell>
                                            <StyledTableCell align="center">{booking?.remarks}</StyledTableCell>
                                            <StyledTableCell align="center">
                                                <div>
                                                    <MessageRounded onClick={() => handleClick(booking?.pk)} />
                                                </div>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <LocalAtmIcon
                                                    onClick={() => handlePaymentInfo(booking?.pk)}
                                                />
                                            </StyledTableCell>

                                        </StyledTableRow>
                                    )
                                })}
                        </TableBody>
                        <TableFooter>
                            <StyledTableRow>
                                <TablePagination
                                    rowsPerPageOptions={[]}
                                    colSpan={3}
                                    count={bookingList?.count || 0}
                                    rowsPerPage={bookingList?.page_size}
                                    page={page}
                                    onPageChange={handleChangePage}
                                />
                            </StyledTableRow>
                        </TableFooter>
                    </Table>
                )}
            </TableContainer>

            {bookingList?.results && bookingList?.results.length === 0 && (
                <h6 style={{ textAlign: "center" }}>No Data Found</h6>
            )}
            <AdditonalFamilyModal additionalMemebr={additionalMemebr} additionalModal={additionalModal} setAdditonalModal={setAdditonalModal} loading={loading} />
            <ImaginRaiseComplaintModal open={raiseImaginComplaint} setOpen={setRaiseImaginComplaint} leadId={Number(leadId)} bookingId={bookingId} />
            <BookingCommentsModal openModal={openModal} setopenModal={setopenModal} bookingId={bookingId} />

        </div>
    )
}

const mapStateToProps = (state: any) => ({


})

export default connect(mapStateToProps, {

})(BookingsTable);

