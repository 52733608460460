import {
  ACTIONS,
  BookingTypes,
} from "../interfaces/actionTypes/salesLeadAdminType";

type InitialState = {
  users: Object;
  areaList: Object;
  area: Object;
  zoneList: Object;
  zone: Object;
  timeslotsList: Object;
  updatedPanelUser: Object;
  failedReport: Array<any>;
  blogList: Object;
  blog: Object;
  blogBySlug: Object;
  blogCategories: Object;
  blogTags: Object;
  blogImages: Object;
  coupon: Object;
  pack: Object;
  cityPriceList: Object;
  agentsListnewdata: Array<any>;
  loading: boolean;
  bookingIdLoader: boolean;
  biomarkerLoader: boolean;
  pin_code: Array<any>;
  bookingListLoader: boolean;
  cities: Array<any>;
  attendenceList: Object;
  autoAssignStatus: boolean;
  agentsList: Array<any>;
  phleboList: Array<any>;
  packageList: Array<any>;
  partnerList: Array<any>;
  couponList: Array<any>;
  createBooking: Object;
  rcFreshLead: Array<any>;
  booking: Object;
  todays_positive_calls: Object;
  lead_details: Object;
  leadById: Object;
  leads: Array<any>;
  bookingList: Array<any>;
  newbookingList: Array<any>;
  packageTags: Array<any>;
  comment: any;
  additional_booking: object;
  data: any;
  bookingsComment: any;
  lead_id: number;
  read_call_details: object;
  all_recordings: object;
  whatsappComments: object;
  whatsappUnread: object;
  whatsappCommentsByLead: object;
  callStats: object;
  complaint: object;
  complaints: object;
  lead_source: Array<any>;
  booking_slots: object;
  incorrectNumberLeads: Array<any>;
  search_results: object;
  call_status: object;
  all_lead: object;
  all_lead_tatataly: object;
  call_transfer: object;
  user_panel: object;
  agentBYUserGroup: object;
  createPanelUser: object;
  labCredential: Array<any>;
  updateLabCredential: Array<any>;
  dispositions: object;
  dispositionData: Array<any>;
  zoneComment: any;
  sameNumberBooking: boolean;
  paymentSendLink: Array<any>;
  paymentReSendLink: Array<any>;
  paymentCancelLink: Array<any>;
  bookingListWithTatTime: Array<any>;
  additionalBookingListWithTatTime: Array<any>;
  smsReportSend: Array<any>;
  breakData: Array<any>;
  assigneUserList: Array<any>;
  verificationBookingDetails: Array<any>;
  todayCallDetails: object;
  updateUserStatus: object;
  topRecord: object;
  phleboCurrentLocation: Array<any>;
  smsDetails: Array<any>;
  manualreportsend: Array<any>;
  emailDetails: Array<any>;
  reportStatus: Array<any>;
  centerInfo: Array<any>;
  labDetails: Array<any>;
  paymentInformation: Array<any>;
  notificationMessage: Array<any>;
  reportDownload: Array<any>;
  panelUserList: Array<any>;
  mapBooking: Array<any>;
  loadingChat: boolean;
  userListData: Array<any>;
  areaComments: Array<any>;
  panelComments: Array<any>;
  assignedPanel: object;
  allBookings: object;
  unmaskedNumber: Array<any>;
  userDetails: Array<any>;
  newuserDetails: Array<any>;
  bookingJourney: Array<any>;
  agentdata: Array<any>;
  ozontelskilldata: Array<any>;
  todayMissedCallsList: Array<any>;
  individualWhatsappChat: Array<any>;
  currentBooking: Array<any>;
  cityWisePhlebos: Array<any>;
  checkGeoFenceStatus: boolean;
  logistictatdata: Array<any>;
  verifiedbylist: Array<any>;
  additionMember: Array<any>;
  qrcode: Array<any>;
  loadingIndividualChat: boolean;
  signature: Array<any>;
  packageCategory: Array<any>;
  sampleHandOverData: Array<any>;
  riderList: Array<any>;
  sampleJourneyComments: Object;
  phleboComment: any;
  collectionSlot: Array<any>;
  redCoin: Array<any>;
  subCenter: Array<any>;
  reportData: Array<any>;
  healthTestParameter: Array<any>;
  parameterCount: Array<any>;
  leadStatus: Array<any>;
  prescription: Array<any>;
  imagingTimeSlots: Array<any>;
  createIMGBooking: object;
  multiplePackage: Array<any>;
  displayCities: Array<any>;
  mapmyindiaAccessToken: Array<any>;
  addressDetailsLatLong: Array<any>;
  geoFencesMapMyIndia: Array<any>;
  geoFencesMapMyIndiaForCustomer: Array<any>;
  addressType: Array<any>;
  bookingCall: Array<any>;
  salesLeadList: Array<any>;
  teamLeadList: Array<any>;
  phlebo_rider_due: Array<any>;
  imagingTicket: Array<any>;
  imagingTicketcount: Array<any>;
  topRcFreshLeads: object;
  imaginraiseTicket: Array<any>;
  paymentRefundList: Array<any>;
  performanceData: Array<any>;
  ticketData: Array<any>;
  ticketCategories: Array<any>;
  ticketSubCategories: Array<any>;
  redtechcomment: Array<any>;
  ltvLead: Object;
  dashboardOverview: Object;
  qualityScoringReport: Array<any>;
  get_quality_scoring_report_for_backup: Array<any>;
  batchbookingamount: Array<any>;
  batchPayment: Object;
  ceCenterForCC: Array<any>;
  routesData: Array<any>;
  breakResponse: Array<any>;
  createPilotBooking: Object;
  geoLoading: boolean;
  geoLoadingforcustomer: boolean;
  phleboridermap: Array<any>;
  geoCheckError: string;
  geoCheckErrorForCustomer: string;
  ticketComments: Array<any>;
  bookingComplaints: Array<any>
  baseCities: Array<any>
  coldCallData:Array<any>
  panelList: Object;
  campaingsDetails: object;
  userList: Array<any>;
  qualityScoreBackupLoader: boolean;
  phleboLoader: boolean;
  riderLoader: boolean;
  assignedloader: boolean;
  analyticsRecording: Object;
  ticketCommentsForDocuments: Array<any>;
  phleboLeaveList: Array<any>;
  packageAlias: Array<any>;
  cityAlias: Array<any>;
  phleboUnderRm: Array<any>;
  biomarkerScreening: Array<any>;
  biomarkerUltasound: Array<any>;
  markerAdditionMember: Array<any>;
  createWhatsappTemplate: Array<any>;
  whatsappTemplate: Array<any>;
  sendLocationOnWhatsapp: Array<any>;
  crmLeadAddress: Array<any>;
  crmPostLeadAddress: Array<any>;
  locationVerified: Array<any>;
  loadingComment: boolean;
  phleboTimeline: Array<any>;
  reportLoading: boolean;
  signOutData: Array<any>;
  phleboAutoAssign: Array<any>;
  holdTheChatData: Array<any>;
  allRedtechTicketsUsingLeadData: Array<any>;
  latestBookingUsingLeadData: Array<any>;
  queueNumber: Object;
  packageComments: Array<any>;
  cityComments: Array<any>;
};

const initialState: InitialState = {
  users: {},
  batchbookingamount: [],
  updatedPanelUser: {},
  imaginraiseTicket: [],
  assignedPanel: {},
  topRcFreshLeads: {},
  prescription: [],
  areaList: {},
  area: {},
  zoneList: {},
  zone: {},
  timeslotsList: {},
  allBookings: {},
  blogList: {},
  blog: {},
  blogBySlug: {},
  blogCategories: {},
  blogTags: {},
  blogImages: {},
  loadingIndividualChat: false,
  coupon: {},
  pack: {},
  cityPriceList: {},
  additional_booking: {},
  loadingComment: false,
  attendenceList: {},
  autoAssignStatus: false,
  loading: false,
  bookingIdLoader: false,
  biomarkerLoader: false,
  pin_code: [],
  packageTags: [],
  currentBooking: [],
  cities: [],
  packageComments: [],
  cityComments: [],
  agentsList: [],
  phleboList: [],
  rcFreshLead: [],
  packageList: [],
  partnerList: [],
  couponList: [],
  qrcode: [],
  createBooking: {},
  booking: {},
  lead_details: {},
  leadById: {},
  todays_positive_calls: {},
  leads: [],
  bookingList: [],
  newbookingList: [],
  comment: {},
  data: {},
  read_call_details: {},
  all_recordings: {},
  whatsappComments: {},
  whatsappUnread: {},
  whatsappCommentsByLead: {},
  callStats: {},
  agentsListnewdata: [],
  complaint: {},
  complaints: {},
  bookingsComment: {},
  lead_id: 0,
  lead_source: [],
  booking_slots: {},
  search_results: {},
  updateUserStatus: {},
  call_status: {},
  all_lead: {},
  mapBooking: [],
  all_lead_tatataly: {},
  call_transfer: {},
  agentBYUserGroup: {},
  user_panel: {},
  labCredential: [],
  updateLabCredential: [],
  dispositions: {},
  zoneComment: {},
  bookingListLoader:false,
  incorrectNumberLeads: [],
  sameNumberBooking: false,
  failedReport: [],
  dispositionData: [],
  cityWisePhlebos: [],
  paymentSendLink: [],
  paymentReSendLink: [],
  paymentCancelLink: [],
  smsReportSend: [],
  breakData: [],
  assigneUserList: [],
  todayCallDetails: {},
  topRecord: {},
  verificationBookingDetails: [],
  smsDetails: [],
  emailDetails: [],
  reportStatus: [],
  centerInfo: [],
  labDetails: [],
  paymentInformation: [],
  notificationMessage: [],
  userDetails: [],
  newuserDetails: [],
  reportDownload: [],
  phleboCurrentLocation: [],
  panelUserList: [],
  createPanelUser: {},
  userListData: [],
  areaComments: [],
  panelComments: [],
  collectionSlot: [],
  unmaskedNumber: [],
  agentdata: [],
  ozontelskilldata: [],
  manualreportsend: [],
  bookingJourney: [],
  individualWhatsappChat: [],
  todayMissedCallsList: [],
  bookingListWithTatTime: [],
  additionalBookingListWithTatTime: [],
  checkGeoFenceStatus: false,
  verifiedbylist: [],
  additionMember: [],
  signature: [],
  packageCategory: [],
  sampleHandOverData: [],
  riderList: [],
  sampleJourneyComments: {},
  phleboComment: {},
  redCoin: [],
  subCenter: [],
  reportData: [],
  healthTestParameter: [],
  parameterCount: [],
  leadStatus: [],
  imagingTimeSlots: [],
  createIMGBooking: {},
  multiplePackage: [],
  displayCities: [],
  loadingChat: false,
  mapmyindiaAccessToken: [],
  addressDetailsLatLong: [],
  geoFencesMapMyIndia: [],
  geoFencesMapMyIndiaForCustomer: [],
  addressType: [],
  bookingCall: [],
  salesLeadList: [],
  teamLeadList: [],
  phlebo_rider_due: [],
  imagingTicket: [],
  imagingTicketcount: [],
  paymentRefundList: [],
  performanceData: [],
  ticketData: [],
  ticketCategories: [],
  ticketSubCategories: [],
  redtechcomment: [],
  ltvLead: {},
  dashboardOverview: {},
  logistictatdata: [],
  qualityScoringReport: [],
  get_quality_scoring_report_for_backup: [],
  batchPayment: [],
  ceCenterForCC: [],
  breakResponse: [],
  routesData: [],
  createPilotBooking: {},
  packageAlias:[],
  cityAlias:[],
  geoLoading: false,
  geoLoadingforcustomer: false,
  geoCheckError: "",
  geoCheckErrorForCustomer: "",
  phleboridermap: [],
  ticketComments: [],
  bookingComplaints: [],
  baseCities:[],
  coldCallData:[],
  panelList: {},
  userList: [],
  qualityScoreBackupLoader: false,
  phleboLoader: false,
  riderLoader: false,
  assignedloader: false,
  analyticsRecording: {},
  ticketCommentsForDocuments: [],
  phleboLeaveList:[],
  phleboUnderRm:[],
  biomarkerScreening: [],
  biomarkerUltasound: [],
  markerAdditionMember: [],
  createWhatsappTemplate: [],
  whatsappTemplate: [],
  locationVerified: [],
  crmLeadAddress: [],
  crmPostLeadAddress: [],
  sendLocationOnWhatsapp: [],
  reportLoading: false,
  holdTheChatData: [],
  signOutData: [],
  allRedtechTicketsUsingLeadData: [],
  latestBookingUsingLeadData: [],
  phleboAutoAssign: [],
  queueNumber: {},
  campaingsDetails: {},
  phleboTimeline: [],
  
};

const SalesLeadReduce = (state = initialState, action: BookingTypes) => {
  switch (action.type) {
    case ACTIONS.GET_USERS_LIST:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case ACTIONS.QUEUE_NUMBER:
      return {
        ...state,
        queueNumber: action.payload,
        loading: false,
      };
    case ACTIONS.HOLD_THE_CHAT:
      return {
        ...state,
        holdTheChatData: action.payload,
        loading: false,
      };
    case ACTIONS.REDTECH_TICKETS_USING_LEAD:
      return {
        ...state,
        allRedtechTicketsUsingLeadData: action.payload,
        loading: false,
      };
    case ACTIONS.LATEST_BOOKING_USING_LEAD:
      return {
        ...state,
        latestBookingUsingLeadData: action.payload,
        loading: false,
      };
    case ACTIONS.SIGN_OUT_IN_WHATSAPP_CHAT:
      return {
        ...state,
        signOutData: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_WHATSAPP_TEMPLATE:
        return {
          ...state,
          createWhatsappTemplate: action.payload,
          loading: false,
        };
        case ACTIONS.GET_PACKAGE_ALIAS:
                return {
                  ...state,
                  packageAlias: action.payload,
                  loading: false,
                };
    case ACTIONS.GET_WHATSAPP_TEMPLATE:
      return {
        ...state,
        whatsappTemplate: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DASHBOARD_OVERVIEW:
      return {
        ...state,
        dashboardOverview: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LTV_LEAD:
      return {
        ...state,
        ltvLead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BATCH_BOOKING_AMOUNT:
      return {
        ...state,
        batchbookingamount: action.payload,
        loading: false,
      };
    case ACTIONS.ASSIGNED_PANEL_USER_DATA:
      return {
        ...state,
        assignedPanel: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA:
      return {
        ...state,
        checkGeoFenceStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AGENT_DATA:
      return {
        ...state,
        agentdata: action.payload,
        loading: false,
      };
    case ACTIONS.GET_OZONTEL_SKILL_DATA:
      return {
        ...state,
        ozontelskilldata: action.payload,
        loading: false,
      };
      case ACTIONS.GET_PACKAGE_COMMENTS:
        return {
          ...state,
          packageComments: action.payload,
          loading: false,
        };
    case ACTIONS.UPDATE_USERS_LIST:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_AREA:
      return {
        ...state,
        area: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_AREA:
      return {
        ...state,
        area: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AREA:
      return {
        ...state,
        areaList: action.payload,
        loading: false,
      };
    case ACTIONS.SEND_REPORT_BY_ID:
      return {
        ...state,
        manualreportsend: action.payload,
        loading: false,
      };
      


    case ACTIONS.CREATE_ZONE:
      return {
        ...state,
        zone: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_ZONE:
      return {
        ...state,
        zone: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ZONE:
      return {
        ...state,
        zoneList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_CURRENT_LOCATION:
      return {
        ...state,
        phleboCurrentLocation: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TIMESLOTS:
      return {
        ...state,
        timeslotsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLOG_CATEGORY:
      return {
        ...state,
        blogCategories: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLOG_TAGS:
      return {
        ...state,
        blogTags: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLOG_IMAGES:
      return {
        ...state,
        blogImages: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_BLOGS:
      return {
        ...state,
        blog: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_CALLS:
      return {
        ...state,
        bookingCall: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_BLOGS:
      return {
        ...state,
        blog: action.payload,
        loading: false,
      };
      case ACTIONS.SET_ALL_BOOKING_LOADING:
        return {
          ...state,
          bookingListLoader: action.payload,
        };
    case ACTIONS.DELETE_BLOG:
      return {
        ...state,
        blog: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLOGS:
      return {
        ...state,
        blogList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLOG_BY_SLUG:
      return {
        ...state,
        blogBySlug: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_COUPON:
      return {
        ...state,
        coupon: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_COUPON:
      return {
        ...state,
        coupon: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_PACKAGE:
      return {
        ...state,
        pack: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_AUTO_ASSIGN:
      return {
        ...state,
        phleboAutoAssign: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ROUTES:
      return {
        ...state,
        routesData: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE:
      return {
        ...state,
        pack: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_PACKAGE:
      return {
        ...state,
        pack: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITY_PRICES:
      return {
        ...state,
        cityPriceList: action.payload,
        loading: false,
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.SET_BOOKING_ID_LOADER:
      return {
        ...state,
        bookingIdLoader: action.payload,
      };
    case ACTIONS.SET_BIOMARKER_LOADER:
      return {
        ...state,
        biomarkerLoader: action.payload,
      };
    case ACTIONS.GET_PIN_CODE:
      return {
        ...state,
        pin_code: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_LIST_WITH_TAT_TIME:
      return {
        ...state,
        bookingListWithTatTime: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDITIONAL_BOOKING_LIST_WITH_TAT_TIME:
      return {
        ...state,
        additionalBookingListWithTatTime: action.payload,
        loading: false,
      };
    case ACTIONS.UPLOAD_FILE:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBOS:
      return {
        ...state,
        phleboList: action.payload,
        phleboLoader: false,
      };
    case ACTIONS.SET_PHLEBO_LOADING:
      return {
        ...state,
        phleboLoader: action.payload,
      
      };
    case ACTIONS.GET_CITIES:
      return {
        ...state,
        cities: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RED_TECH_COMMENT:
      return {
        ...state,
        redtechcomment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_JOURNEY_DETAILS:
      return {
        ...state,
        bookingJourney: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COLLECTION_SLOT:
      return {
        ...state,
        collectionSlot: action.payload,
        loading: false,
      };
    case ACTIONS.GET_POSTIVE_CALLS_COMMENTS:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loadingComment: false,
      };
    case ACTIONS.GET_PHLEBO_COMMENTS:
      return {
        ...state,
        phleboComment: action.payload,
        loading: false,
      };
    case ACTIONS.SET_COMMENTS:
      return {
        ...state,
        comment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_POSTIVE_CALLS_COMMENT:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_DETAILS:
      return {
        ...state,
        lead_details: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEADS:
      return {
        ...state,
        leads: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_BY_ID:
      return {
        ...state,
        leadById: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_DETAILS_RESET:
      return {
        ...state,
        leadById: {},
        lead_details: {},
        whatsappComments: {},
        individualWhatsappChat: [],
        todays_positive_calls: {},
        complaints: {},
        loadingChat: false,
        loadingIndividualChat: false,
        loadingComment: false,
        geoFencesMapMyIndia: [],
        packageList: [],
        mapmyindiaAccessToken: [],
        failedReport: [],
        loading: false,
      };
    case ACTIONS.GET_FAILED_REPORT_DATA:
      return {
        ...state,
        failedReport: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AGENTS:
      return {
        ...state,
        agentsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AGENTS_BY_USERGROUP:
      return {
        ...state,
        agentBYUserGroup: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGES:
      return {
        ...state,
        packageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PARTNERS:
      return {
        ...state,
        partnerList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COUPONS:
      return {
        ...state,
        couponList: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_BOOKING:
      return {
        ...state,
        createBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_LIST:
      return {
        ...state,
        bookingList: action.payload,
        bookingListLoader: false,
      };
      case ACTIONS.GET_NEW_BOOKING_LIST:
        return {
          ...state,
          newbookingList: action.payload,
          bookingListLoader: false,
        };
    case ACTIONS.GET_BOOKING_BY_ID:
      return {
        ...state,
        booking: action.payload,
        bookingIdLoader: false,
      };
    case ACTIONS.CREATE_CALL:
      return {
        ...state,
        loading: false,
        lead_id: action.payload,
      };
    case ACTIONS.GET_RECORDINGS:
      return {
        ...state,
        all_recordings: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MAP_BOOKING_LIST:
      return {
        ...state,
        mapBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_SOURCE:
      return {
        ...state,
        lead_source: action.payload,
        loading: false,
      };
    case ACTIONS.SEARCH_CALL:
      return {
        ...state,
        search_results: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_COMMENT:
      return {
        ...state,
        comments: action.payload,
        loading: false,
      };
    case ACTIONS.BOOKING_RESET:
      return {
        ...state,
        createBooking: {},
        bookingList: [],
        booking: {},
        loading: false,
        bookingIdLoader: false,
      };
    case ACTIONS.TABLE_RESET:
      return {
        ...state,
        phleboLeaveList: [],
        loading: false,
      };
    case ACTIONS.GET_BOOKINGS_COMMENTS:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_BOOKINGS_COMMENT:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loading: false,
      };
      case ACTIONS.GET_WHATSAPP_UNREAD_MSG:
        return {
          ...state,
          whatsappUnread: action.payload,
          loading: false,
        };
    case ACTIONS.GET_WHATSAPP_COMMENTS_BY_LEAD:
      return {
        ...state,
        whatsappCommentsByLead: action.payload,
        loadingIndividualChat: false,
      };
    case ACTIONS.SEND_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loadingChat: false,
      };
    case ACTIONS.GET_CALL_STATS:
      return {
        ...state,
        callStats: action.payload,
        loading: false,
      };
    case ACTIONS.RAISE_COMPLAINT:
      return {
        ...state,
        complaint: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COMPLAINTS:
      return {
        ...state,
        complaints: action.payload,
        loading: false,
      };
    case ACTIONS.ASSIGN_DOCTOR:
      return {
        ...state,
        success: true,
        loading: false,
      };
      case ACTIONS.GET_PHLEBO_TIMELINE:
        return {
          ...state,
          phleboTimeline: action.payload,
          loading: false,
        };
      case ACTIONS.SET_LOADING_COMMENT:
        return {
          ...state,
          loadingComment: action.payload,
        };
    case ACTIONS.SEND_FEEDBACK:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.GET_AVAILABLE_SLOTS:
      return {
        ...state,
        booking_slots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_LEADS:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USERS:
      return {
        ...state,
        user_panel: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_TRANSFER_BY_COMMA:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_TRANSFER_TO_TATATALE:
      return {
        ...state,
        all_lead_tatataly: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LOGISTIC_TAT_DATA:
      return {
        ...state,
        logistictatdata: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_CREDENTIAL_DETAILS:
      return {
        ...state,
        labCredential: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_LAB_CREDENTIALS:
      return {
        ...state,
        updateLabCredential: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ZONE_COMMENTS:
      return {
        ...state,
        zoneComment: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_USERS_STATUS:
      return {
        ...state,
        updateUserStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DISPOSITIONS:
      return {
        ...state,
        dispositions: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DISPOSITION_DATA:
      return {
        ...state,
        dispositionData: action.payload,
        loading: false,
      };
      case ACTIONS.GET_AGENTS_NEW_DATA:
        return {
          ...state,
          agentsListnewdata: action.payload,
          loading: false,
        };
        case ACTIONS.SET_LOADING_INDIVIDUAL_CHAT:
          return {
            ...state,
            loadingIndividualChat: action.payload,
          };
    case ACTIONS.GET_INCORRECT_NUMBER_LEAD:
      return {
        ...state,
        incorrectNumberLeads: action.payload,
        loading: false,
      };
    case ACTIONS.SAME_NUMBER_BOOKING:
      return {
        ...state,
        sameNumberBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_SEND_LINK:
      return {
        ...state,
        paymentSendLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_RESEND_LINK:
      return {
        ...state,
        paymentReSendLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_CANCEL_LINK:
      return {
        ...state,
        paymentCancelLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_REPORT_SEND:
      return {
        ...state,
        smsReportSend: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BREAK_DATA:
      return {
        ...state,
        breakData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ASSIGNED_USERGROUP:
      return {
        ...state,
        assigneUserList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RCFRESH_LEAD_DATA:
      return {
        ...state,
        rcFreshLead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TODAYS_CALL_DETAILS:
      return {
        ...state,
        todayCallDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TOP_RECORDS:
      return {
        ...state,
        topRecord: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VERIFICATION_BOOKING_DETAILS:
      return {
        ...state,
        verificationBookingDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_DETAILS:
      return {
        ...state,
        smsDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_EMAIL_DETAILS:
      return {
        ...state,
        emailDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_STATUS:
      return {
        ...state,
        reportStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTER_INFORMATION:
      return {
        ...state,
        centerInfo: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_DETAILS:
      return {
        ...state,
        labDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_INFORMATION:
      return {
        ...state,
        paymentInformation: action.payload,
        loading: false,
      };
    case ACTIONS.GET_NOTIFICATION_MESSAGE:
      return {
        ...state,
        notificationMessage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_NEW_USER_DETAILS:
      return {
        ...state,
        newuserDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_DOWNLOAD:
      return {
        ...state,
        reportDownload: action.payload,
        reportLoading: false,
      };
    case ACTIONS.CREATE_PANEL_USER:
      return {
        ...state,
        createPanelUser: action.payload,
        loading: false,
      };
    default:
    case ACTIONS.GET_PANEL_USER_LIST_DATA:
      return {
        ...state,
        panelUserList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USER_DATA:
      return {
        ...state,
        userListData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AREA_COMMENTS:
      return {
        ...state,
        areaComments: action.payload,

        loading: false,
      };
    case ACTIONS.GET_UNMASKED_NUMBER:
      return {
        ...state,
        unmaskedNumber: action.payload,
        loading: false,
      };
    case ACTIONS.GET_INDIVIDUAL_WHATSAPP_CHAT:
      return {
        ...state,
        individualWhatsappChat: action.payload,
        loadingIndividualChat: false,
      };

    case ACTIONS.GET_PANEL_COMMENT:
      return {
        ...state,
        panelComments: action.payload,
        loading: false,
      };

    case ACTIONS.GET_TODAYS_MISSED_CALLS:
      return {
        ...state,
        todayMissedCallsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ASSIGNED_UNASSIGNED_BOOKING:
      return {
        ...state,
        allBookings: action.payload,
        assignedloader: false,
      };
    case ACTIONS.SET_ASSIGNED_LOADING:
      return {
        ...state,
        assignedloader: action.payload,
        
      };

    case ACTIONS.AUTO_ASSIGN_BOOKING:
      return {
        ...state,
        autoAssignStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CURRENT_DAY_BOOKING:
      return {
        ...state,
        currentBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITY_WISE_PHLEBOS:
      return {
        ...state,
        cityWisePhlebos: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ATTENDENCE:
      return {
        ...state,
        attendenceList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VERIFIED_BY_LIST:
      return {
        ...state,
        verifiedbyList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_TAGS:
      return {
        ...state,
        packageTags: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SIGNATURE:
      return {
        ...state,
        signature: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDITIONAL_BOOKING:
      return {
        ...state,
        additionMember: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QRCODE_DATA:
      return {
        ...state,
        qrcode: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGE_CATEGORY:
      return {
        ...state,
        packageCategory: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SAMPLE_HANDOVER_DATA:
      return {
        ...state,
        sampleHandOverData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RIDER_LIST:
      return {
        ...state,
        riderList: action.payload,
        riderLoader: false,
      };
    case ACTIONS.GET_SAMPLE_JOURNEY_COMMENTS:
      return {
        ...state,
        sampleJourneyComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RED_COIN:
      return {
        ...state,
        redCoin: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SUB_CENTER_INFORMATION:
      return {
        ...state,
        subCenter: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_DATA:
      return {
        ...state,
        reportData: action.payload,
        loading: false,
      };
      case ACTIONS.SET_LOADING_CHAT:
      return {
        ...state,
        loadingChat: action.payload,
      };
    case ACTIONS.GET_HEALTH_TEST_PARAMETER:
      return {
        ...state,
        healthTestParameter: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PARAMETER_COUNT:
      return {
        ...state,
        parameterCount: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_STATUS:
      return {
        ...state,
        leadStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PRESCRIPTION_DATA:
      return {
        ...state,
        prescription: action.payload,
        loading: false,
      };
    case ACTIONS.GET_IMAGING_TIMESLOTS:
      return {
        ...state,
        imagingTimeSlots: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_IMAGING_BOOKING:
      return {
        ...state,
        createIMGBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MULTIPLE_PACKAGES:
      return {
        ...state,
        multiplePackage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DISPLAY_CITIES:
      return {
        ...state,
        displayCities: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN:
      return {
        ...state,
        mapmyindiaAccessToken: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG:
      return {
        ...state,
        addressDetailsLatLong: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA:
      return {
        ...state,
        geoFencesMapMyIndia: action.payload,
        // loading: false,
        geoLoading: false,
        geoCheckError: "",
      };
      case ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA_FOR_CUSTOMER_ADDRESS:
        return {
          ...state,
          geoFencesMapMyIndiaForCustomer: action.payload,
          // loading: false,
          geoLoadingforcustomer: false,
          geoCheckErrorForCustomer: "",
        };
    case ACTIONS.GET_ADDRESS_TYPE_SEATING:
      return {
        ...state,
        addressType: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USER_LIST_DATA_SALES_LEAD:
      return {
        ...state,
        salesLeadList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USER_LIST_DATA_TEAM_LEAD:
      return {
        ...state,
        teamLeadList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_RIDER_DUE_AMOUNT:
      return {
        ...state,
        phlebo_rider_due: action.payload,
        loading: false,
      };
    case ACTIONS.GET_IMAGING_TICKET:
      return {
        ...state,
        imagingTicket: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TOP_RC_FRESH_LEADS:
      return {
        ...state,
        topRcFreshLeads: action.payload,
        loading: false,
      };
    case ACTIONS.GET_IMAGING_TICKET_COUNT:
      return {
        ...state,
        imagingTicketcount: action.payload,
        loading: false,
      };
    case ACTIONS.GET_IMAGING_RAISE_TICKET:
      return {
        ...state,
        imaginraiseTicket: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_REFUND_LIST:
      return {
        ...state,
        paymentRefundList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PERFORMANCE_DATA:
      return {
        ...state,
        performanceData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_DATA:
      return {
        ...state,
        ticketData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_CATEGORIES:
      return {
        ...state,
        ticketCategories: action.payload,
        loading: false,
      };

    case ACTIONS.GET_TICEKT_SUB_CATEGORIES:
      return {
        ...state,
        ticketSubCategories: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QUALITY_SCORING_REPORT:
      return {
        ...state,
        qualityScoringReport: action.payload,
        loading: false,
      };
      case ACTIONS.GET_QUALITY_SCORING_REPORT_FOR_BACKUP:
        return {
          ...state,
          get_quality_scoring_report_for_backup: action.payload,
          qualityScoreBackupLoader: false,
        };
    case ACTIONS.GET_BATCH_PAYMENT:
      return {
        ...state,
        batchPayment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CE_CENTER_FOR_CC:
      return {
        ...state,
        ceCenterForCC: action.payload,
        loading: false,
      };
      case ACTIONS.GET_PHLEBO_LEAVE_LIST:
        return {
          ...state,
          phleboLeaveList: action.payload,
        };
    case ACTIONS.GET_BREAK_RESPONSE:
      return {
        ...state,
        breakResponse: action.payload,
        loading: false,
      };
    case ACTIONS.SET_GEO_LOADING:
      return {
        ...state,
        geoLoading: action.payload,
      };
      case ACTIONS.SET_GEO_LOADING_FOR_CUSTOMER:
        return {
          ...state,
          geoLoadingforcustomer: action.payload,
        };
    case ACTIONS.SET_GEO_CHECK_ERROR:
      return {
        ...state,
        geoCheckError: action.payload,
      };
      case ACTIONS.SET_GEO_ERROR_FOR_CUSTOMER:
        return {
          ...state,
          geoCheckErrorForCustomer: action.payload,
        };
    case ACTIONS.CREATE_PILOT_BOOKING:
      return {
        ...state,
        createPilotBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBO_RIDER:
      return {
        ...state,
        phleboridermap: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_COMMENTS:
      return {
        ...state,
        ticketComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_COMMENTS:
      return {
        ...state,
        ticketComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BASE_CITIES:
      return {
        ...state,
        baseCities: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COLD_CALLING_LEAD_DATA:
      return {
        ...state,
        coldCallData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_LIST:
      return {
        ...state,
        panelList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANELS_USERS:
      return {
        ...state,
        userList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_COMPLAINTS:
      return {
        ...state,
        bookingComplaints: action.payload,
        loading: false,
      };
      case ACTIONS.SET_QUALITYSCOREBACKUP_LOADING:
        return {
          ...state,
          qualityScoreBackupLoader: action.payload,
        };
      case ACTIONS.SET_RIDER_LOADING:
        return {
          ...state,
          riderLoader: action.payload,
        };
    case ACTIONS.GET_ANALYTICS_RECORDING:
      return {
        ...state,
        analyticsRecording: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_COMMENTS_FOR_DOCUMENTS:
      return {
        ...state,
        ticketCommentsForDocuments: action.payload,
        loading: false,
      };
      case ACTIONS.GET_PHLEBO_LIST_UNDER_ROUTE_MANAGER:
        return {
          ...state,
          phleboUnderRm: action.payload,
        };
        case ACTIONS.GET_BIOMARKER_SCREENING:
          return {
            ...state,
            biomarkerScreening: action.payload,
            biomarkerLoader: false,
          };
        case ACTIONS.GET_BIOMARKER_ULTASOUND:
          return {
            ...state,
            biomarkerUltasound: action.payload,
            loading: false,
          };
        case ACTIONS.GET_ADDITIONAL_MEMBER:
          return {
            ...state,
            markerAdditionMember: action.payload,
            loading: false,
          };
    case ACTIONS.BIOMARKER_RESET:
      return {
        ...state,
        biomarkerScreening: {},
      };
    case ACTIONS.SEND_LOCATION_REQUEST:
      return {
        ...state,
        sendLocationOnWhatsapp: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CRM_LEAD_ADDRESS:
      return {
        ...state,
        crmLeadAddress: action.payload,
        loading: false,
      };
    case ACTIONS.POST_CRM_LEAD_ADDRESS:
      return {
        ...state,
        crmPostLeadAddress: action.payload,
        loading: false,
      };
    case ACTIONS.LOCATION_VERIFIED:
      return {
        ...state,
        locationVerified: action.payload,
        loading: false,
      };
      case ACTIONS.GET_CAMPAINGS_DETAILS:
        return {
            ...state,
            campaingsDetails: action.payload,
            loading: false,
        };
    case ACTIONS.SET_REPORT_LOADING:
      return {
        ...state,
        reportLoading: action.payload,
      };
      case ACTIONS.GET_CITY_ALIAS:
        return {
          ...state,
          cityAlias: action.payload,
          loading: false,
        };
        case ACTIONS.GET_CITY_COMMENTS:
          return {
            ...state,
            cityComments: action.payload,
            loading: false,
          };
  }
};

export default SalesLeadReduce;
