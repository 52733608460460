import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../loader";

import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import MessageIcon from "@material-ui/icons/Message";
import CallIcon from "@material-ui/icons/Call";
import { connect } from "react-redux";

// import SecondFilterHeader from "../filter-headers/second-filter-header";
import CommentsModal from "../Comments/comment";
import CommentsModal2 from "../Comments/Comment4";
import { clickToCall, getCities } from "../../actions/bookingActions";

const StyledTableCell:any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  root: {
    flexGrow: 0,
  },
  table: {
    minWidth: 340,
  },
  tableCell: {
    paddingRight: 4,
    paddingLeft: 5
  }
});

interface Props {
  search_results: any;
  clickToCall: Function;
  getCities: Function;
  cities: Function;
  loading: boolean;
}

const SearchResultsMain: React.FC<Props> = ({ search_results, loading, clickToCall, getCities, cities }) => {
  const classes = useStyles();

  const [open, setOpen] = React.useState<boolean>(false);
  const [open2, setOpen2] = React.useState<boolean>(false)
  const [leadId, setLeadId] = React.useState<number>(0);

  useEffect(() => {
    getCities()
  }, [])

  useEffect(() => {
    if (open2 === false) {
      // let body = {
      //   search_by: "call_id",
      //   value: leadId.toString(),
      // };
      // if (leadId !== 0) {
      //   dispatch(searchCall(body));
      //   history.push("/dashboard/ce/lead/search");
      // }
      setLeadId(0)
    }
  }, [open2])

  const handleClick = (id: number) => {
    setOpen(true);
    setLeadId(id);
  };

  const handleModal = (id: number) => {
    setOpen2(true);
    setLeadId(id);
  };

  const handleCallClick = (id: number, type: string) => {
    setLeadId(id);
    clickToCall(id, type);
  };

  return (
    <>
      <div className="data-table" style={{ width: "100%", marginTop: "2rem" }}>
        <TableContainer component={Paper}>
          {loading ? <Loader /> : (
            (<Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Lead ID</StyledTableCell>
                  <StyledTableCell align="center">Created At</StyledTableCell>
                  <StyledTableCell align="center">Comments</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                  <StyledTableCell align="center">Agent</StyledTableCell>
                  <StyledTableCell align="center">Call</StyledTableCell>
                  <StyledTableCell align="center">Contact number</StyledTableCell>
                  <StyledTableCell align="center">Whatsapp contact</StyledTableCell>
                  <StyledTableCell align="center">Patient name</StyledTableCell>
                  <StyledTableCell align="center">Calling gender</StyledTableCell>
                  <StyledTableCell align="center">Patient age</StyledTableCell>
                  <StyledTableCell align="center">City</StyledTableCell>
                  <StyledTableCell align="center">Patient Area</StyledTableCell>
                  <StyledTableCell align="center">Lead source</StyledTableCell>
                  <StyledTableCell align="center">Call status</StyledTableCell>
                  <StyledTableCell align="center">Language</StyledTableCell>
                  <StyledTableCell align="center">Edit</StyledTableCell>
                  <StyledTableCell align="center">Priority</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {search_results.count > 0 ? (
                  search_results.results.map((row: any) => (
                    <StyledTableRow
                      key={row.id}
                    >
                      <StyledTableCell component="th" scope="row">
                        {row.id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {new Date(row.created_at).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div>
                          <MessageIcon onClick={() => handleClick(row.id)} />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div>
                          <MessageIcon onClick={() => handleModal(row.id)} />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.panel_user ? row.panel_user : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <CallIcon
                          onClick={() => handleCallClick(row.id, "call")}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.content_number ? <CallIcon
                          onClick={() => handleCallClick(row.id, "contact")}
                        /> : null}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.whatsapp_contact !== row.content_number ? <CallIcon
                          onClick={() => handleCallClick(row.id, "whatsapp")}
                        /> : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.patient_name ? row.patient_name : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.calling_gender ? row.calling_gender : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.patient_age}
                      </StyledTableCell>
                      <StyledTableCell align="center">{row.patient_city}</StyledTableCell>
                      <StyledTableCell align="center">
                        {row.patient_area ? row.patient_area : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.lead_source}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.call_status}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.language ? row.language : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Link
                          to={`/dashboard/cl/fill-info?id=${row.id}`}
                        >
                          <EditIcon />
                        </Link>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.priority}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  "NA"
                )}
              </TableBody>
            </Table>)
          )}
        </TableContainer>
      </div>
      <CommentsModal open={open} setOpen={setOpen} leadId={leadId} />
      <CommentsModal2 open={open2} getCities={getCities} cities={cities} setOpen={setOpen2} leadId={leadId} />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  search_results: state.CustomerLeadReducer.search_results,
  cities: state.CustomerLeadReducer.cities,
  loading: state.CustomerLeadReducer.loading,
});

export default connect(mapStateToProps, { clickToCall, getCities })(SearchResultsMain);
