import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, TextField, Button, Select, MenuItem } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { manageAgentLogin, getAgentList } from "../../actions/salesLeadAction";
import "./Agent.sass";
import { useHistory } from "react-router";
import Loader from "../../Components/loader";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

interface Props {
  getAgentList: any;
  agentsList: any;
  loginStatusReducer: any;
  manageAgentLogin:any;
  loading: Boolean;
}

const CustomerLeadStateFilterPage: React.FC<Props> = ({
  loading,
  getAgentList,
  agentsList,
  loginStatusReducer,
  manageAgentLogin,

}) => {
  const classes = useStyles();
  const history = useHistory();
  const [agent, setAgent] = useState<any>([]);
  const [loginStatus, setLoginStatus] = useState("none");
  const timer = useRef<any>(0);

  useEffect(() => {
    getAgentList();
  }, [getAgentList]);

  const updateLoginStatusForm = async () => {
    const body: any = {
        login_status: loginStatus,
        agent: agent.join(","),
    };
  
    await manageAgentLogin(body)
    //setAgent([]);
    //setLoginStatus("none");
  }

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <h4 style={{fontWeight:"bold"}}>Disabled Agents Login</h4>
        <Grid container spacing={2} direction="row" style={{ margin: "0" }}>
         <Grid item xs={12} sm={6} md={6}>
            <Autocomplete
              id="agent"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item?.user?.username);
                }
                setAgent(sourceIds);
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              options={agentsList.results || []}
              getOptionDisabled={(option: any) => {
                return agent.includes(option?.user?.username);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                option?.user && option?.user?.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getAgentList(`?code=${newInputValue.replace(" ", "")}`);
                }, 1000);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Agent"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Select
              className="input"
              name="usergroup"
              variant="outlined"
              value={loginStatus}
              style={{ width: "100%",height: "40px" }}
              onChange={(e) => setLoginStatus(e.target.value as string)}
            >
              <MenuItem disabled value={"none"}>Login Status</MenuItem>
              <MenuItem value={"false"}>Enabled</MenuItem>
              <MenuItem value={"true"}>Disabled</MenuItem>
            </Select>
          </Grid>
          </Grid>
        <Grid container spacing={2} direction="row" style={{ margin: "0" }}>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              variant="contained"
              type="submit"
              color="secondary"
              fullWidth
              disabled={loginStatus==="none"}
              onClick={()=>updateLoginStatusForm()}
            >
              Submit
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => history.push("/dashboard/sl/agentloginstatus")}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.SalesLeadReducer.loading,
  agentsList: state.SalesLeadReducer.agentsList,
  loginStatusReducer: state.SalesLeadReducer.loginStatus,
});

export default connect(mapStateToProps, {
  getAgentList,
  manageAgentLogin,
})(CustomerLeadStateFilterPage);
