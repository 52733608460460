export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  GET_PIN_CODE: "cl/get_pin_code",
  GET_CITIES: "cl/get_cities",
  GET_AREA: "cl/get_area",
  GET_AGENTS: "cl/get_agents",
  GET_AGENTS_BY_USERGROUP: "cl/get_agents_by_usergroup",
  GET_PACKAGES: "cl/get_packages",
  GET_PHLEBOS: "cl/get_phlebos",
  GET_PARTNERS: "cl/get_partners",
  GET_COUPONS: "cl/get_coupons",
  CREATE_BOOKING: "cl/create_booking",
  UPDATE_BOOKING: "cl/update_booking",
  GET_BOOKING_LIST: "cl/get_booking_list",
  GET_LEAD_DETAILS: "cl/get_lead_details",
  GET_LEAD_BY_ID: "cl/get_lead_by_id",
  LEAD_DETAILS_RESET: "cl/lead_details_reset",
  GET_LEADS: "cl/get_leads",
  GET_DASHBOARD_OVERVIEW: "cl/get_dashboard_overview",
  GET_BOOKING_BY_ID: "cl/get_booking_by_id",
  CLICK_TO_CALL: "cl/click_to_call",
  GET_POSTIVE_CALLS_COMMENTS: "cl/get_postive_calls_commnets",
  ADD_POSTIVE_CALLS_COMMENT: "cl/add_comments",
  SET_COMMENTS: "cl/set_commnets",
  UPLOAD_FILE: "cl/upload",
  SET_LOADING: "cl_booking/set_loading",
  GET_ASSIGNED_USERGROUP: "sl/get_assigned_usergroup",
  SEARCH_CALL: "cl/serach",
  BOOKING_RESET: "cl/booking_reset",
  ADD_COMMENT: "positive_calls/add_comment",
  CREATE_CALL: "cl/createcall",
  GET_LEAD_SOURCE: "cl/getleadsource",
  GET_RECORDINGS: "cl/get_recordings",
  GET_BOOKINGS_COMMENTS: "cl/get_bookings_commnets",
  ADD_BOOKINGS_COMMENT: "cl/add_bookings_comments",
  GET_WHATSAPP_COMMENTS: "cl/get_whatsapp_comments",
  GET_WHATSAPP_COMMENTS_BY_LEAD: "cl/get_whatsapp_comments_by_lead",
  SEND_WHATSAPP_COMMENTS: "cl/send",
  GET_CALL_STATS: "cl/get_call_stats",
  RAISE_COMPLAINT: "cl/raise_complaint",
  GET_COMPLAINTS: "cl/get_complaints",
  ASSIGN_DOCTOR: "cl/assign_doctor",
  SEND_FEEDBACK: "cl/send_feedback",
  GET_AVAILABLE_SLOTS: "cl/get_available_slots",
  GET_ALL_LEADS: "cl/get_all_leads",
  LEAD_TRANSFER_BY_COMMA: "cl/set_comments",
  GET_PANEL_USERS: "cl/get_panel_user",
  GET_DISPOSITIONS: "cl/get_dispositions",
  GET_USERS_LIST: "admin/get_user_list",
  UPDATE_USERS_LIST: "admin/update_user_list",
  ADD_REMARK_ON_CALL: "cl/add_remark_on_call",
  GET_INCORRECT_NUMBER_LEAD: "cl/get_incorrect_number_lead",
  GET_DISPOSITION_DATA: "cl/get_disposition_data",
  GET_BOOKING_CALLS: "cl/get_booking_call",
  GET_BREAK_DATA: "cl/get_break_data",
  CREATE_COUPON: "cl/create_coupon",
  UPDATE_COUPON: "cl/update_coupon",
  GET_TOP_RECORDS: "cl/get_top_records",
  GET_LAB_DETAILS: "cl/get_lab_details",
  GET_TODAYS_CALL_DETAILS: "cl/get_today_call_details",
  GET_VERIFICATION_BOOKING_DETAILS: "cl/get_verification_booking_details",
  GET_SMS_DETAILS: "cl/get_sms_details",
  GET_EMAIL_DETAILS: "cl/get_email_details",
  GET_SMS_REPORT_SEND: "cl/get_sms_report_send",
  GET_CENTER_INFORMATION: "cl/get_center_information",
  GET_PAYMENT_INFORMATION: "cl/get_payment_information",
  GET_NOTIFICATION_MESSAGE: "cl/get_notification_message",
  GET_USER_DETAILS: "cl/get_user_details",
  GET_FIREBASE_NOTIFICATION: "CE/get_firebase_notification",
  GET_REPORT_STATUS: "cl/get_report_status",
  LEAD_TRANSFER_TO_TATATALE: "sl/lead_transfer_to_tatatale",
  GET_UNMASKED_NUMBER: "cl/get_unmasked_number",
  GET_PANEL_USER_LIST_DATA_TEAM_LEAD: "cl/get_panel_user_list_data_team_lead",
  GET_PANEL_USER_LIST_DATA_SALES_LEAD: "cl/get_panel_user_list_data_sales_lead",
  GET_ZONE: "cl/get_zone",
  CREATE_ADDITIONAL_BOOKING: "cl/create_additional_booking",
  SAME_NUMBER_BOOKING: "cl/same_number_booking",
  GET_TODAYS_MISSED_CALLS: "cl/get_today_missed_calls",
  CHECK_GEOFENCE_AREA: "cl/checking-geofence-area",
  GET_FAMILY_PACKAGES: "ce/get_family_packages",
  GET_FAMILY_PACKAGES_BOOKING: "ce/get_family_packages_booking",
  CREATE_FAMILY_PACKAGES_BOOKING: "ce/create_family_packages_booking",
  GET_VERIFIED_BY_LIST: "cl/get_verified_by_list",
  GET_RED_COIN: "cl/get_red_coins",
  GET_LEAD_STATUS: "cl/get_lead_status",
  GET_PRESCRIPTION_DATA: "cl/get_prescription_data",
  GET_SUB_CENTER_INFORMATION: "cl/get_sub_center_information",
  GET_IMAGING_TIMESLOTS: "cl/get_imaging_timeslots",
  CREATE_IMAGING_BOOKING: "cl/CREATE_IMAGING_BOOKING",
  GET_MULTIPLE_PACKAGES: "cl/get_multiple_package",
  GET_DISPLAY_CITIES: "cl/get_display_cities",
  GET_MAMPMYINDIA_ACCESS_TOKEN: "cl/get-mapmyindia-access-token",
  CHECK_GEOFENCE_AREA_MAMPMYINDIA: "cl/checking-geofence-area-mapmyindia",
  GET_ADDRESS_TYPE_SEATING: "cl/get_address_type_seating",
  UPDATE_ADDITIONAL_BOOKING: "cl/update_additional_booking",
  GET_CUSTOMERS_LIST: "cl/get_customers_list",
  GET_PERFORMANCE_DATA: "cl/get_performance_data",
  GET_TICKET_DATA: "cl/get_ticket_data",
  GET_TICKET_CATEGORIES: "cl/get_ticket_category",
  GET_TICEKT_SUB_CATEGORIES: "cl/get_ticket_categories",
  CREATE_TASK_TICKET: "cl/create_task_ticket",
  GET_RED_TECH_COMMENT: "cl/redtech_comments/",
  POST_RED_TECH_COMMENT: "cl/postredtech_comments/",
  GET_QUALITY_SCORING_REPORT: "cl/get_quality_scoring_report/",
  GET_QUALITY_SCORING_REPORT_FOR_BACKUP: "cl/get_quality_scoring_report_for_backup/",
  GET_CE_CENTER_FOR_CC: "cl/get_ce_center_for_cc",
  GET_TIMESLOTS: "ce/get_timeslot",
  CREATE_PILOT_BOOKING: "ce/create_pilot_booking",
  SET_GEO_LOADING: "cl/set_geo_loading",
  SET_GEO_CHECK_ERROR: "cl/set_geo_check_error",
  GET_BREAK_RESPONSE: "cl/get_break_response",
  GET_TICKET_COMMENTS: "cl/ticket_comments",
  UPDATE_TICKET_STATUS: "cl/update_ticekt_status",
  GET_BOOKING_COMPLAINTS: "cl/get_booking_complaints",
  UPDATE_DISPOSITION: "cl/update_disposition",
  SET_ALL_BOOKING_LOADING: "cl/set_all_booking_loading",
  SET_EMAIL_LOADING: "cl/set_email_loading",
  SET_TICKET_LOADING: "cl/ticket_loading",
  GET_BIOMARKER_SCREENING: "cl/biomarker_screening",
  GET_BIOMARKER_ULTASOUND: "cl/biomarker_ultasound",
  GET_ADDITIONAL_MEMBER: "cl/additional_member",
  BIOMARKER_RESET: "cl/biomarker_reset",
  GET_NEW_BOOKING_LIST: "cl/get_new_booking_list",
  LOCATION_VERIFIED: "cl/location_verified",
  SEND_LOCATION_REQUEST:"cl/communication/send-location-request/",
  GET_ADDRESS_DETAILS_LAT_LONG: "cl/get-address-details-lat-long",
  INITIATE_REFUND: "cl/initiate_refund",
  // BOOKING_RESET: "cl/booking_reset",
};
interface GetRedTechComment {
  type: typeof ACTIONS.GET_RED_TECH_COMMENT;
  payload: Array<any>;
}
interface LocationVerified {
  type: typeof ACTIONS.LOCATION_VERIFIED;
  payload: boolean;
}
interface SendLocationRequest {
  type: typeof ACTIONS.SEND_LOCATION_REQUEST;
  payload: Object;
}
interface GetAddressDetailsLatLong {
  type: typeof ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG;
  payload: Array<any>;
}
interface GeoLoading {
  type: typeof ACTIONS.SET_GEO_LOADING;
  payload: boolean;
}
interface CheckGeoCheckError {
  type: typeof ACTIONS.SET_GEO_CHECK_ERROR;
  payload: boolean;
}
interface CreatePilotBooking {
  type: typeof ACTIONS.CREATE_PILOT_BOOKING;
  payload: boolean;
}
interface GetCeCenterForCc {
  type: typeof ACTIONS.GET_CE_CENTER_FOR_CC;
  payload: Array<any>;
}
interface GetTimeSlot {
  type: typeof ACTIONS.GET_TIMESLOTS;
  payload: Array<any>;
}
interface GetDashboardOverview {
  type: typeof ACTIONS.GET_DASHBOARD_OVERVIEW;
  payload: Array<any>;
}
interface GetTicketData {
  type: typeof ACTIONS.GET_TICKET_DATA;
  payload: Object;
}
interface GetTicketCategory {
  type: typeof ACTIONS.GET_TICKET_CATEGORIES;
  payload: Object;
}
interface GetTicketSubCategories {
  type: typeof ACTIONS.GET_TICEKT_SUB_CATEGORIES;
  payload: Object;
}
interface PostRedTechComment {
  type: typeof ACTIONS.POST_RED_TECH_COMMENT;
  payload: Object;
}
interface GetPanelUserDataTeamLead {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA_TEAM_LEAD;
  payload: Array<any>;
}
interface CreateTaskTicket {
  type: typeof ACTIONS.CREATE_TASK_TICKET;
  payload: Array<any>;
}
interface GetBreakResponse {
  type: typeof ACTIONS.GET_BREAK_RESPONSE;
  payload: Object;
}
interface GetTodayCallDetails {
  type: typeof ACTIONS.GET_TODAYS_CALL_DETAILS;
  payload: Array<any>;
}
interface GetAddressTypeSeating {
  type: typeof ACTIONS.GET_ADDRESS_TYPE_SEATING;
  payload: Object;
}
interface GetPrescriptionData {
  type: typeof ACTIONS.GET_PRESCRIPTION_DATA;
  payload: Array<any>;
}
interface CheckGeoFenceArea {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA;
  payload: Array<any>;
}
interface GetLeadStatus {
  type: typeof ACTIONS.GET_LEAD_STATUS;
  payload: Array<any>;
}
interface GetRedCoins {
  type: typeof ACTIONS.GET_RED_COIN;
  payload: Array<any>;
}
interface CreateAdditionalBooking {
  type: typeof ACTIONS.CREATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface SameNumberBooking {
  type: typeof ACTIONS.SAME_NUMBER_BOOKING;
  payload: Object;
}
interface GetUnmaskedNumber {
  type: typeof ACTIONS.GET_UNMASKED_NUMBER;
  payload: Array<any>;
}
interface GetSmsReportSend {
  type: typeof ACTIONS.GET_SMS_REPORT_SEND;
  payload: Array<any>;
}
interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface AddRemarkOnCall {
  type: typeof ACTIONS.ADD_REMARK_ON_CALL;
  payload: Object;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface GetDispositions {
  type: typeof ACTIONS.GET_DISPOSITIONS;
  payload: Array<any>;
}
interface GetAssignedUsergroup {
  type: typeof ACTIONS.GET_ASSIGNED_USERGROUP;
  payload: Object;
}
interface UploadFile {
  type: typeof ACTIONS.UPLOAD_FILE;
  payload: Object;
}

interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}

interface GetArea {
  type: typeof ACTIONS.GET_AREA;
  payload: Array<any>;
}
interface GetPerformanceData {
  type: typeof ACTIONS.GET_PERFORMANCE_DATA;
  payload: boolean;
}

interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}

interface GetPhlebo {
  type: typeof ACTIONS.GET_PHLEBOS;
  payload: Array<any>;
}
interface CreatePilotBooking {
  type: typeof ACTIONS.CREATE_PILOT_BOOKING;
  payload: boolean;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}

interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}
interface InitiateRefund {
  type: typeof ACTIONS.INITIATE_REFUND;
  payload: Array<any>;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}

interface GetCustomerList {
  type: typeof ACTIONS.GET_CUSTOMERS_LIST;
  payload: Array<any>;
}
interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}
interface UpdateAdditionalBooking {
  type: typeof ACTIONS.UPDATE_ADDITIONAL_BOOKING;
  payload: Object;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}

interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}

interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}

interface GetArea {
  type: typeof ACTIONS.GET_AREA;
  payload: Array<any>;
}
interface GetPhlebo {
  type: typeof ACTIONS.GET_PHLEBOS;
  payload: Array<any>;
}

interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}
interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}
interface GetBookingsComments {
  type: typeof ACTIONS.GET_BOOKINGS_COMMENTS;
  payload: Object;
}
interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}

interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}

interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface GetAgentByUserGroup {
  type: typeof ACTIONS.GET_AGENTS_BY_USERGROUP;
  payload: Object;
}
interface GetUserList {
  type: typeof ACTIONS.GET_USERS_LIST;
  payload: Object;
}
interface SetUserList {
  type: typeof ACTIONS.UPDATE_USERS_LIST;
  payload: Object;
}
interface GetIncorrectNumberLead {
  type: typeof ACTIONS.GET_INCORRECT_NUMBER_LEAD;
  payload: Object;
}
interface GetDispositionData {
  type: typeof ACTIONS.GET_DISPOSITION_DATA;
  payload: Object;
}
interface GetBookingCall {
  type: typeof ACTIONS.GET_BOOKING_CALLS;
  payload: Array<any>;
}
interface GetBreakData {
  type: typeof ACTIONS.GET_BREAK_DATA;
  payload: Object;
}
interface CreateCoupon {
  type: typeof ACTIONS.CREATE_COUPON;
  payload: Object;
}
interface UpdateCoupon {
  type: typeof ACTIONS.UPDATE_COUPON;
  payload: Object;
}
interface GetTopRecords {
  type: typeof ACTIONS.GET_TOP_RECORDS;
  payload: Array<any>;
}
interface GetLabDetails {
  type: typeof ACTIONS.GET_LAB_DETAILS;
  payload: Array<any>;
}
interface GetVerificationDetails {
  type: typeof ACTIONS.GET_VERIFICATION_BOOKING_DETAILS;
  payload: Array<any>;
}
interface GetSmsDetails {
  type: typeof ACTIONS.GET_SMS_DETAILS;
  payload: Array<any>;
}
interface GetEmailDetails {
  type: typeof ACTIONS.GET_EMAIL_DETAILS;
  payload: Array<any>;
}
interface GetEmailLoader {
  type: typeof ACTIONS.SET_EMAIL_LOADING;
  payload: Array<any>;
}
interface GetSmsReportSend {
  type: typeof ACTIONS.GET_SMS_REPORT_SEND;
  payload: Array<any>;
}
interface GetCenterInfo {
  type: typeof ACTIONS.GET_CENTER_INFORMATION;
  payload: Array<any>;
}
interface GetPaymentInfro {
  type: typeof ACTIONS.GET_PAYMENT_INFORMATION;
  payload: Array<any>;
}
interface GetNotificationMessage {
  type: typeof ACTIONS.GET_NOTIFICATION_MESSAGE;
  payload: Array<any>;
}
interface GetReportStatus {
  type: typeof ACTIONS.GET_REPORT_STATUS;
  payload: Array<any>;
}
interface GetTataTalyLead {
  type: typeof ACTIONS.LEAD_TRANSFER_TO_TATATALE;
  payload: Object;
}
interface GetUserDetails {
  type: typeof ACTIONS.GET_USER_DETAILS;
  payload: Object;
}
interface GetFireBaseNotification {
  type: typeof ACTIONS.GET_FIREBASE_NOTIFICATION;
  payload: Object;
}
interface GetZone {
  type: typeof ACTIONS.GET_ZONE;
  payload: Object;
}
interface GetTodayMissedCalls {
  type: typeof ACTIONS.GET_TODAYS_MISSED_CALLS;
  payload: Object;
}
interface GetFamilyPackages {
  type: typeof ACTIONS.GET_FAMILY_PACKAGES;
  payload: Array<any>;
}
interface UpdateAdditionalBooking {
  type: typeof ACTIONS.UPDATE_ADDITIONAL_BOOKING;
  payload: Object;
}

interface GetFamilyPackagesBooking {
  type: typeof ACTIONS.GET_FAMILY_PACKAGES_BOOKING;
  payload: Array<any>;
}

interface GetPanelUserDataSalesLead {
  type: typeof ACTIONS.GET_PANEL_USER_LIST_DATA_SALES_LEAD;
  payload: Array<any>;
}
interface CreateFamilyPackageBooking {
  type: typeof ACTIONS.CREATE_FAMILY_PACKAGES_BOOKING;
  payload: Object;
}
interface GetVerifiedByList {
  type: typeof ACTIONS.GET_VERIFIED_BY_LIST;
  payload: Object;
}
interface GetSubCenterInformation {
  type: typeof ACTIONS.GET_SUB_CENTER_INFORMATION;
  payload: Array<any>;
}
interface GetImagingTimeSlots {
  type: typeof ACTIONS.GET_IMAGING_TIMESLOTS;
  payload: Array<any>;
}
interface CreateImagingBooking {
  type: typeof ACTIONS.CREATE_IMAGING_BOOKING;
  payload: boolean;
}
interface GetMultiplepackage {
  type: typeof ACTIONS.GET_MULTIPLE_PACKAGES;
  payload: Array<any>;
}
interface GetDisplayCities {
  type: typeof ACTIONS.GET_DISPLAY_CITIES;
  payload: Array<any>;
}
interface GetMapMyIndiaAccessToken {
  type: typeof ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN;
  payload: Array<any>;
}
interface CheckGeoFenceAreaMapMyIndia {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA;
  payload: Array<any>;
}

interface GetQualityScoringReport {
  type: typeof ACTIONS.GET_QUALITY_SCORING_REPORT;
  payload: Array<any>;
}

interface GetQualityScoringReportForBackup {
  type: typeof ACTIONS.GET_QUALITY_SCORING_REPORT_FOR_BACKUP;
  payload: Array<any>;
}
interface GetTicketComments {
  type: typeof ACTIONS.GET_TICKET_COMMENTS;
  payload: Array<any>;
}
interface UpdateTicketStatus {
  type: typeof ACTIONS.UPDATE_TICKET_STATUS;
  payload: Array<any>;
}
interface GetBookingQueryTickets {
  type: typeof ACTIONS.GET_BOOKING_COMPLAINTS;
  payload: boolean;
}
interface UpdateActionDisposition {
  type: typeof ACTIONS.UPDATE_DISPOSITION;
  payload: Array<any>;
}
interface GetBookingsLoader {
  type: typeof ACTIONS.SET_ALL_BOOKING_LOADING;
  payload: boolean;
}
interface GetTicketLoader {
  type: typeof ACTIONS.SET_TICKET_LOADING;
  payload: boolean;
}
interface BiomarkerReset {
  type: typeof ACTIONS.BIOMARKER_RESET;
  payload: boolean;
}
interface GetBiomarkerScreening {
  type: typeof ACTIONS.GET_BIOMARKER_SCREENING;
  payload: Object;
}
interface GetAdditionalMember {
  type: typeof ACTIONS.GET_ADDITIONAL_MEMBER;
  payload: Array<any>;
}
interface GetBiomarkerUltasound {
  type: typeof ACTIONS.GET_BIOMARKER_ULTASOUND;
  payload: Object;
}
interface GetNewBookings {
  type: typeof ACTIONS.GET_NEW_BOOKING_LIST;
  payload: boolean;
}

export type PhleboAdminTypes =
  | GetUserList
  | SetUserList
  | SearchCall
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetArea
  | GetAgents
  | GetPhlebo
  | GetAgentByUserGroup
  | GetPackages
  | CreateBooking
  | UpdateBooking
  | GetPartners
  | GetCoupons
  | GetBookings
  | GetBookingById
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | SetComments
  | UploadFile
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetDispositions
  | GetArea
  | GetAgents
  | GetPhlebo
  | GetPackages
  | CreateBooking
  | UpdateBooking
  | GetPartners
  | GetCoupons
  | GetBookings
  | GetBookingById
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | SetComments
  | SearchCall
  | AddRemarkOnCall
  | GetIncorrectNumberLead
  | GetDispositionData
  | GetBookingCall
  | GetBreakData
  | UpdateCoupon
  | CreateCoupon
  | GetBookingsComments
  | GetTopRecords
  | GetLabDetails
  | GetTodayCallDetails
  | GetVerificationDetails
  | GetSmsDetails
  | GetEmailDetails
  | GetSmsReportSend
  | GetCenterInfo
  | GetPaymentInfro
  | GetNotificationMessage
  | GetReportStatus
  | GetTataTalyLead
  | GetUnmaskedNumber
  | GetUserDetails
  | GetFireBaseNotification
  | GetZone
  | GetDashboardOverview
  | CreateAdditionalBooking
  | GetAssignedUsergroup
  | SameNumberBooking
  | GetTodayMissedCalls
  | CheckGeoFenceArea
  | GetFamilyPackages
  | GetFamilyPackagesBooking
  | CreateFamilyPackageBooking
  | GetVerifiedByList
  | GetRedCoins
  | GetLeadStatus
  | GetPrescriptionData
  | GetSubCenterInformation
  | GetImagingTimeSlots
  | CreateImagingBooking
  | GetMultiplepackage
  | UpdateAdditionalBooking
  | GetDisplayCities
  | GetMapMyIndiaAccessToken
  | CheckGeoFenceAreaMapMyIndia
  | GetAddressTypeSeating
  | GetCustomerList
  | GetPerformanceData
  | GetTicketData
  | GetTicketCategory
  | GetTicketSubCategories
  | CreateTaskTicket
  | GetRedTechComment
  | PostRedTechComment
  | GetQualityScoringReport
  | GetQualityScoringReportForBackup
  | GetPanelUserDataTeamLead
  | GetPanelUserDataSalesLead
  | GetCeCenterForCc
  | GetTimeSlot
  | GetBreakResponse
  | CreatePilotBooking
  | GeoLoading
  | CheckGeoCheckError
  | GetTicketComments
  | GetBookingQueryTickets
  | UpdateTicketStatus
  | UpdateActionDisposition
  | GetBookingsLoader
  | GetEmailLoader
  | GetTicketLoader
  | BiomarkerReset
  | GetBiomarkerScreening
  | GetAdditionalMember
  | GetBiomarkerUltasound
  | GetNewBookings
  | SendLocationRequest
  | LocationVerified
  |InitiateRefund
  | GetAddressDetailsLatLong;