import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow,withStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../components/loader/index';
import { MessageRounded } from '@material-ui/icons';
import { getQueryTickets } from '../../CustomerLead-Panel/actions/bookingActions'
import { useEffect } from 'react';
import RaiseComplaintModal from '../components/Comments/updateTicketModal'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);

const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,fontWeight:600
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);
interface Props {
    getQueryTickets: any
    complaints: any
    loading: any
}

const BookingsResamplingTable: React.FC<Props> = ({
    getQueryTickets,
    complaints,
    loading,
}) => {
    const classes = useStyles()

    const [raiseComplaint, setRaiseComplaint] = useState(false)

    const [bookingID, setBookingID] = useState(0)
    const [type, setType] = useState("")
    const [query, setQuery] = useState("")
    const [status, setStatus] = useState("")

    const [ticketId, setTicketId] = useState(0)

    const handleOpenModal = (ticket: any) => {
        setBookingID(ticket.booking?.pk||ticket.booking?.id)
        setType(ticket.querytype)
        setQuery(ticket.query)
        setStatus(ticket.status)
        setTicketId(ticket.id)
        setRaiseComplaint(true)
    }

    useEffect(() => {
        getQueryTickets("booking_ticket")
    }, [])

    useEffect(() => {
        if (raiseComplaint === false) {
            getQueryTickets("booking_ticket")
        }
    }, [raiseComplaint])

    const [page, setPage] = useState(0)

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = complaints.links && complaints.links.next.split("?")[1];
            getQueryTickets(url);
        } else if (newPage < page) {
            let url = complaints.links && complaints.links.previous.split("?")[1];
            getQueryTickets(url);
        }
        setPage(newPage as number);
    };

    return (
        <main className="data-table">
            <div className={classes.toolbar} />
            <Paper elevation={15} className={classes.paper}>
            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "580px" }}>
                    {loading ? <Loader /> : (
                        <Table stickyHeader aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Booking Id</StyledTableCell>
                                    <StyledTableCell align="center">Agent</StyledTableCell>
                                    <StyledTableCell align="center">Comment</StyledTableCell>
                                    <StyledTableCell align="center">Query Type</StyledTableCell>
                                    <StyledTableCell align="center">Query Description</StyledTableCell>
                                    <StyledTableCell align="center">Status</StyledTableCell>
                                    <StyledTableCell align="center">View</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    complaints.results && complaints.results.length > 0 && complaints.results.map((ticket: any, index: any) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="center">{ticket?.booking?.id||ticket?.booking?.pk}</StyledTableCell>
                                                <StyledTableCell align="center">{ticket.panel_user && ticket.panel_user.user && ticket.panel_user.user.name}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Button
                                                        color="primary"
                                                        variant="text"
                                                    >
                                                        <MessageRounded onClick={() => handleOpenModal(ticket)} />
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">{ticket.querytype}</StyledTableCell>
                                                <StyledTableCell align="center">{ticket.query}</StyledTableCell>
                                                <StyledTableCell align="center">{ticket.status}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Link to={`/dashboard/cl/booking-view/${ticket?.booking?.id||ticket?.booking?.pk}`}>
                                                        <Button
                                                            color="secondary"
                                                            variant="contained"
                                                        >
                                                            View
                                                        </Button>
                                                    </Link>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })
                                }
                            </TableBody>
                            <TableFooter>
                                <StyledTableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[]}
                                        colSpan={3}
                                        count={complaints.count || 0}
                                        rowsPerPage={complaints.page_size}
                                        page={page}
                                        onPageChange={handleChangePage}
                                    />
                                </StyledTableRow>
                            </TableFooter>
                        </Table>
                    )}
                </TableContainer>
                <RaiseComplaintModal open={raiseComplaint} setOpen={setRaiseComplaint} ticketId={ticketId} queryType={type} queryDesc={query} queryStatus={status} bookingId={Number(bookingID)} leadId={0} getQueryTickets={undefined} bookingList={undefined} ticketDetails={undefined} packageList={undefined} getPackage={undefined} getAvailableSlots={undefined} booking_slots={undefined} collectionDate={undefined} setCollectionDate={undefined}  zoneId={undefined} />
            </Paper>
        </main>
    )
}

const mapStateToProps = (state: any) => ({
    loading: state.CustomerLeadReducer.loading,
    complaints: state.CustomerLeadReducer.complaints
})

export default connect(mapStateToProps, {
    getQueryTickets
})(BookingsResamplingTable);

