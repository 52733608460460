import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Container, Box, Grid, TextField } from '@material-ui/core';
import { Paper, Table, TableBody, TableCell, MenuItem, Select, TableContainer, TableFooter, TableHead, TablePagination, TableRow, } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getImagingPackageAlias, getPackage, CreateImagingAlias, getCenterAddressPrice, getCentrePackageComments } from '../../actions/ImagingAdminAction'
import PackageTable from './packagelist'
import packagelist from "./packagelist";
import { useHistory } from 'react-router';
import { genrateCenterAddressPriceFilter } from '../../../helpers/generateUrl'
import PackageCommentModal from '../../Components/comment/packageComment'
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        card: {
            height: "100%",
            padding: theme.spacing(1),
        },
        card_grid: {
            // wordSpacing:3,
            justifyContent: 'space-between'
        },
    })
);

interface Props {
    loading: boolean;
    getImagingPackageAlias: any;
    imagingPackageAlias: any;
    packageList: any;
    getPackage: any;
    CreateImagingAlias: any;
    getCenterAddressPrice: any;
    getCentrePackageComments: any;
    centrePackageComments: any;

}

const CustomUploader: React.FC<Props> = ({
    loading,
    getImagingPackageAlias,
    imagingPackageAlias,
    packageList,
    getPackage,
    getCenterAddressPrice,
    CreateImagingAlias,
    getCentrePackageComments,
    centrePackageComments,

}) => {
    const timer = useRef<any>(0)
    const classes = useStyles();
    const [packageId, setPackageId] = useState<any>("")
    const [alias, setAlias] = useState<any>("")
    const [openModal, setopenModal] = useState<boolean>(false)
    const [packId, setpackId] = useState<any>("")
    const history = useHistory()

    useEffect(() => {
        getImagingPackageAlias(`?org_type=imaging`)
        getPackage(`?org_type=imaging`)
    }, [])

    const updateAlias = async () => {
        const body: any = {
            package: packageId,
            name: alias
        }
        await CreateImagingAlias(body)
        getImagingPackageAlias(`?org_type=imaging`)
    }

    const filterCenter = async () => {
        const body: any = {
            packageid: packageId
        }
        const url = genrateCenterAddressPriceFilter(body).substring(2);
        // setCurrentUrl(url)
        getImagingPackageAlias(`?org_type=imaging&${url}`)

    }

    const handleReset = () => {
        // dispatch({ type: ACTIONS.GET_CENTER_ADDRESS_PRICE, payload: [] });
        history.push(`/dashboard/ia/create-alias`)
    }


    return (

        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Grid container spacing={1} direction="row" style={{ alignItems: "right" }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Autocomplete
                        id="package"
                        onChange={(event, newValue) => {
                            if (newValue) {
                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                setPackageId(obj?.id);
                            }

                        }}
                        options={packageList?.results || []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        limitTags={1}
                        getOptionLabel={(option: any) => option?.name}
                        disableClearable
                        disableCloseOnSelect
                        onInputChange={(event, newInputValue) => {
                            clearTimeout(timer.current);
                            timer.current = setTimeout(() => {
                                getPackage(`?org_type=imaging&code=${newInputValue}`);
                            }, 1000);
                            if (newInputValue.length === 0) {
                                setPackageId("");
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                className="input"
                                {...params}
                                label="TEST NAME"
                                variant="outlined"
                                style={{ width: "100%", margin: "0", padding: "0" }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        name="alias"
                        type="text"
                        value={alias}
                        className="input"
                        label="ENTER ALIAS"
                        variant="outlined"
                        disabled={packageId === ""}
                        onChange={(e) => setAlias(e.target.value as string)}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Button
                        variant="contained"
                        color="secondary"

                        fullWidth
                        onClick={updateAlias}
                        disabled={packageId === "" || alias === ""}
                    >
                        Create Alias
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={1} direction="row" style={{ alignItems: "right", marginTop: "1rem" }}>
                <Grid item xs={12} sm={6} md={3}>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                        id="test"
                        onChange={(event, newValue) => {
                            if (newValue) {
                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                setPackageId(obj.id);
                            }

                        }}
                        options={packageList?.results || []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        limitTags={1}
                        getOptionLabel={(option: any) => option.name}
                        disableClearable
                        disableCloseOnSelect
                        onInputChange={(event, newInputValue) => {
                            clearTimeout(timer.current);
                            timer.current = setTimeout(() => {
                                getPackage(`?org_type=imaging&code=${newInputValue}`);
                            }, 1000);
                            if (newInputValue.length === 0) {
                                setPackageId("");
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                className="input"
                                {...params}
                                label="TEST NAME"
                                variant="outlined"
                                style={{ width: "100%", margin: "0", padding: "0" }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <Button
                        variant="contained"
                        color="secondary"

                        fullWidth
                        onClick={filterCenter}
                        disabled={packageId === ""}
                    >
                        Filter
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={2}>
                    <Button
                        variant="contained"
                        color="primary"

                        fullWidth
                        disabled={packageId === ""}
                        onClick={handleReset}
                    >
                        Reset
                    </Button>
                </Grid>

            </Grid>

            <PackageTable
                getImagingPackageAlias={getImagingPackageAlias}
                imagingPackageAlias={imagingPackageAlias}
                setopenModal={setopenModal}
                setpackId={setpackId}
            />
            <PackageCommentModal packId={packId} openModal={openModal} setopenModal={setopenModal} getCentrePackageComments={getCentrePackageComments} centrePackageComments={centrePackageComments} loading={loading} />

        </main>
    );
};

const mapStateToProps = (state: any) => ({
    loading: state.ImagingAdminReducer.loading,
    imagingPackageAlias: state.ImagingAdminReducer.imagingPackageAlias,
    packageList: state.ImagingAdminReducer.packageList,
    centrePackageComments: state.ImagingAdminReducer.centrePackageComments,
});

export default connect(mapStateToProps, {
    getImagingPackageAlias,
    getPackage,
    CreateImagingAlias,
    getCentrePackageComments,
    getCenterAddressPrice,
})(CustomUploader);
