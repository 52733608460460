import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
import Autocomplete from '@mui/material/Autocomplete';
import { generateBookingFilterUrl } from "../../helpers/generateUrl";
import { connect } from "react-redux";
import Button from '@mui/material/Button';
import {
  getAgentList,
  getLeadSource,
  getPartners,
  getAllBookings,
  getCities,
  getPhlebos,
  getPanelUserList,
  getVerifiedbyList,
  getCollectionSlot,
  getPanelUserListSalesLead,
  getPanelUserListTeamLead,
  getZone,
  getAgentNewData,
  getAllNewBookings,
  getUserDetails,
  getPhleboTimeline,
} from "../actions/salesLeadAdminAction";
import {
  getAllLeads,
  leadTransferByComma,
} from "../actions/salesLeadAdminAction";
import "./filter.sass";
import { useHistory } from "react-router";
import BookingsTable from "../tables/bookingsTable";
import Loader from "../Components/Loader2/index";
import { getPaymentInfo } from "../actions/salesLeadAdminAction";
import PaymentModal from "../Components/comments/paymentforModal";
import HelpIcon from "@material-ui/icons/Help";
import { BOOKING_STATUS } from "../../booking_status";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import PhleboJourneysModel from "../Components/comments/phleboJourneysModel";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
  })
);

interface Props {
  children: any;
  agentsList: any;
  agentsListnewdata: any;
  bookingList: any;
  newbookingList: any;
  partnerList: any;
  getAgentList: any;
  getPartners: any;
  getCollectionSlot: any;
  collectionSlot: any;
  getAllBookings: any;
  getAllNewBookings: any;
  leadTransferByComma: any;
  agentBYUserGroup: any;
  getCities: any;
  cities: any;
  getPhlebos: any;
  getAllLeads: any;
  phleboList: any;
  getLeadSource: any;
  lead_source: any;
  loading: Boolean;
  getPaymentInfo: any;
  paymentInformation: any;
  getPanelUserList: any;
  panelUserList: any;
  getVerifiedbyList: any;
  verifiedbyList: any;
  getPanelUserListSalesLead: any;
  salesLeadList: any;
  getPanelUserListTeamLead: any;
  teamLeadList: any;
  zoneList: any;
  getZone: any;
  bookingListLoader: boolean;
  getAgentNewData: any;
  userDetails: any
  getUserDetails: any;
  getPhleboTimeline: any;
  phleboTimeline: any;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
  agentsList,
  agentsListnewdata,
  partnerList,
  getAgentList,
  getPartners,
  getCollectionSlot,
  getAllBookings,
  getAllNewBookings,
  leadTransferByComma,
  agentBYUserGroup,
  getAllLeads,
  bookingList,
  newbookingList,
  collectionSlot,
  getCities,
  cities,
  getPhlebos,
  phleboList,
  getLeadSource,
  lead_source,
  getPaymentInfo,
  paymentInformation,
  getPanelUserList,
  panelUserList,
  loading,
  getVerifiedbyList,
  verifiedbyList,
  salesLeadList,
  getPanelUserListSalesLead,
  getPanelUserListTeamLead,
  teamLeadList,
  zoneList,
  bookingListLoader,
  getZone,
  getAgentNewData,
  userDetails,
  getPhleboTimeline,
  phleboTimeline,
  getUserDetails
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [openModal, setopenModal] = useState<boolean>(false);
  const [status, setStatus] = useState<any>([]);
  const [source, setSource] = useState<any>([]);
  const [partner, setPartner] = useState<any>([]);
  const [agent, setAgent] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [VerifiedbyList, setVerifiedbyList] = useState<any>([]);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [booking_date, setBooking_date] = useState("");
  const [collection_date, setCollection_date] = useState("");
  const [verificationStatus, setVerificationStatus] = useState<any>([]);
  const [pickup_status, setPickup_status] = useState<any>([]);
  const [name, setName] = useState("");
  const [phlebo, setPhlebo] = useState<any>([]);
  const [phone, setPhone] = useState("");
  const [bookingId, setBookingid] = React.useState<any>("");
  const [leadId, setLeadId] = React.useState<any>("");
  const [no_call, setNoCall] = React.useState<any>("");
  const [userGroup, setUserGroup] = useState("none");
  const [page, setPage] = useState(0);
  const [sample_registered, setSampleRegisterd] = useState<any>("none");
  const [report_status, setReportStatus] = useState<any>("none");
  const [selectedLeads, setSelectedLeads] = useState<any>([]);
  const [panel_user, setPanelUser] = React.useState<number | null>(null);
  const [paymentId, setPaymentId] = useState<any>(0);
  const [openPaymentModal, setOpenPaymentModal] = useState<any>(false);
  const [defaultStatus, setDefaultStatus] = useState<any>([]);
  const [teamLead, setTeamLead] = useState<any>("");
  const [salesLead, setSalesLead] = useState<any>("");
  const [booking_type, setbooking_type] = useState<String>("none");
  const timer = useRef<any>(0);
  const [slot, setSlot] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<any>("");
  const [phleboJourney, setPhleboJourney] = useState<any>("none");
  const [sourceType, setSourceType] = useState<any>("");
  const [open, setOpen] = React.useState(false);
  const [locationVerified, setLocationVerified] = useState<any>("none");
  const [locationMessageStatus, setLocationMessageStatus] = useState<any>([]);
  const [openPhlebotModel, setOpenPhleboModel] = useState<boolean>(false);
  const [bookingIdModal, setBookingIdModal] = useState<any>("");

  //transition modal start
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //transition modal end

  useEffect(() => {
    if (history.action === "POP") {
      let ceBookingFilter: any;
      ceBookingFilter = localStorage.getItem("CEBookingFilter");
      if (ceBookingFilter) {
        ceBookingFilter = JSON.parse(ceBookingFilter);
        const statusD = ceBookingFilter?.status?.split(",");
        let statusTemp = new Array();
        BOOKING_STATUS.map((item: any) => {
          statusD.map((itm: any) => {
            if (item.value == itm) {
              statusTemp.push(item);
            }
          });
        });
        setDefaultStatus(statusTemp);
        ceBookingFilter?.locationMessageStatus &&
          setLocationMessageStatus(ceBookingFilter?.locationMessageStatus?.split(","));
        ceBookingFilter?.status &&
          setStatus(ceBookingFilter?.status?.split(","));
        ceBookingFilter?.source &&
          setSource(ceBookingFilter?.source?.split(","));
        ceBookingFilter?.partner &&
          setPartner(ceBookingFilter?.partner?.split(","));
        ceBookingFilter?.city && setCity(ceBookingFilter?.city?.split(","));
        ceBookingFilter?.start_date &&
          setStart_date(ceBookingFilter?.start_date);
        setCollection_date(ceBookingFilter?.collection_date);
        ceBookingFilter?.verificationStatus &&
          setVerificationStatus(
            ceBookingFilter?.verificationStatus?.split(",")
          );
        ceBookingFilter?.pickup_status &&
          setPickup_status(ceBookingFilter?.pickup_status?.split(","));
        setName(ceBookingFilter?.name);
        setPhlebo(ceBookingFilter?.phlebo);
        setPhone(ceBookingFilter?.phone);
        setSampleRegisterd(ceBookingFilter?.sample_registered);
        setSampleRegisterd(ceBookingFilter?.tl);
        setUserGroup(ceBookingFilter?.userGroup);
        setReportStatus(ceBookingFilter?.report_status);
        setPhleboJourney(ceBookingFilter?.journey);
        setBookingid(ceBookingFilter?.bookingId);
        setLeadId(ceBookingFilter?.leadId);
        const url = generateBookingFilterUrl(ceBookingFilter).substring(2);
        const pageNo = page ? page : 1;
        getAllBookings(`${url}&page=${pageNo}&optimize=true`);
        // {
        //   userDetails.profile.pilot_user ? (
        //     getAllNewBookings(`${url}&page=${pageNo}`)
        //   ) : getAllBookings(`${url}&page=${pageNo}&optimize=true`);
        // }

      }
    } else {
      let url = ``;
      // getAgentList();
      // getAgentList("", "CustomerExecutive");
      getAgentNewData("?usergroup=CustomerExecutive");
      // getPartners();
      getAllBookings(`optimize=true`);
      // {
      //   userDetails.profile.pilot_user ? (
      //     getAllNewBookings("")
      //   ) :  getAllBookings(`optimize=true`);
      // }
      // getVerifiedbyList();
      // getCities();
      getCollectionSlot();
      getLeadSource();
      getPanelUserListSalesLead(`?usergroup=SalesLead`);
      getPanelUserListTeamLead(`?usergroup=CustomerLead`);
      localStorage.removeItem("CEBookingFilter");
      setDefaultStatus([]);
      // getVerifiedbyList("?usergroup=VerificationExecutive")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setDefaultStatus]);
  const onKeyDown = (e: any) => {
    if (e.key === "Enter") {
      filterBookings(e);
    }
  };
  document.querySelectorAll("#noTyping").forEach(el => {
    el.addEventListener("keydown", function (e) {
      e.preventDefault()
    })
  })
  const filterBookings = (e: any) => {
    const body: any = {
      status: status ? status.join(",") : "",
      verificationStatus: verificationStatus
        ? verificationStatus.join(",")
        : "",
      source: source ? source.join(",") : "",
      agent: agent ? agent.join(",") : "",
      city: city ? city.join(",") : "",
      start_date,
      end_date,
      booking_date,
      bookingId,
      collection_date,
      pickup_status: pickup_status ? pickup_status.join(",") : "",
      verified_by: VerifiedbyList ? VerifiedbyList : "",
      name,
      phlebo: phlebo ? phlebo.join(",") : "",
      partner: partner ? partner.join(",") : "",
      user_group: userGroup,
      phone,
      sample_registered,
      report_status,
      lead: leadId,
      no_call: no_call,
      booking_type: booking_type,
      journey: phleboJourney,
      tl: teamLead ? teamLead : "",
      sl: salesLead,
      slot: slot.join(","),
      source_type: sourceType,
      location_event_status: locationMessageStatus ? locationMessageStatus.join(",") : "",
    };
    // const url = generateBookingFilterUrl(body).substring(2);
    localStorage.setItem("CEBookingFilter", JSON.stringify(body));
    let url = generateBookingFilterUrl(body).substring(2);
    if (locationVerified === "a") {
      if (url === "") url = "address_verified=true&distance_above_deviation=true"
      else url = url + "&address_verified=true&distance_above_deviation=true"
    }
    if (locationVerified === "b") {
      if (url === "") url = "address_verified=false&distance_above_deviation=true"
      else url = url + "&address_verified=false&distance_above_deviation=true"
    }
    if (locationVerified === "c") {
      if (url === "") url = "address_verified=true&distance_above_deviation=false"
      else url = url + "&address_verified=true&distance_above_deviation=false"
    }
    if (locationVerified === "d") {
      if (url === "") url = "location_not_shared=true"
      else url = url + "&location_not_shared=true"
    }
    if (locationVerified === "e") {
      if (url === "") url = "customer_out_of_zone=true"
      else url = url + "&customer_out_of_zone=true"
    }
    getAllBookings(url ? `${url}&page=1&optimize=true` : "page=1&optimize=true");
    // {
    //   userDetails.profile.pilot_user ? (
    //     getAllNewBookings(url ? `${url}&page=1` : "page=1")
    //   ) :   getAllBookings(url ? `${url}&page=1&optimize=true` : "page=1&optimize=true")
    // }

    setPage(0);
  };
  const transferLead = async () => {
    let body = {
      lead_ids: selectedLeads.join(", "),
      panel_user,
      booking: true,
    };
    await leadTransferByComma(body);
    getAllLeads("");
    setSelectedLeads([]);
    getAllLeads("");
    getAgentList("", "CustomerExecutive");
    // history.push("/dashboard/sla")
  };
  useEffect(() => {
    if (paymentId != 0) {
      getPaymentInfo(`?booking=${paymentId}`);
    }
  }, [paymentId]);

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Grid
          container
          spacing={2}
          direction="row"
          style={{ alignItems: "right", display: "flex" }}
        >
          <Grid item xs={12} sm={8}>
            <h4 style={{ fontWeight: "bold" }}>BOOKING LIST <HelpIcon style={{ color: "#9c27b0" }} onClick={handleClickOpen} /></h4>
          </Grid>
          <Grid item xs={12} sm={4}>
            <h5 style={{ fontWeight: "bold" }}>BOOKING COUNT : {totalCount}</h5>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "right", marginTop: "20px" }}
        >
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="lead"
              type="number"
              label="Lead ID"
              value={leadId}
              onKeyDown={onKeyDown}
              variant="outlined"
              onChange={(e) => setLeadId(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="no_call"
              type="number"
              label="Call Count"
              value={no_call}
              onKeyDown={onKeyDown}
              variant="outlined"
              onChange={(e) => setNoCall(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="booking"
              type="number"
              label="Booking Id"
              value={bookingId}
              onKeyDown={onKeyDown}
              variant="outlined"
              onChange={(e) => setBookingid(e.target.value)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="name"
              type="text"
              label="Name"
              value={name}
              variant="outlined"
              onKeyDown={onKeyDown}
              onChange={(e) => setName(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="phone"
              type="number"
              label="Phone"
              value={phone}
              variant="outlined"
              onKeyDown={onKeyDown}
              onChange={(e) => setPhone(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setCity(sourceIds);
              }}
              options={cities || []}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              onKeyDown={onKeyDown}
              getOptionLabel={(option: any) => option.name}
              getOptionDisabled={(option: any) => {
                return city.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCities(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setCity([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="City"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="status"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setStatus(sourceIds);
              }}
              options={BOOKING_STATUS || []}
              blurOnSelect
              aria-required
              onKeyDown={onKeyDown}
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return status.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Booking Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
              defaultValue={defaultStatus}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="partner"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setPartner(sourceIds);
              }}
              freeSolo
              blurOnSelect
              limitTags={1}
              multiple
              options={partnerList?.results || []}
              onKeyDown={onKeyDown}
              getOptionDisabled={(option: any) => {
                return partner.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) => option.user && option.user.name}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPartners(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPartner([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Partner"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setSource(sourceIds);
              }}
              freeSolo
              multiple
              id="checkboxes-tags-demo"
              options={lead_source || []}
              disableCloseOnSelect
              limitTags={1}
              disableClearable
              onKeyDown={onKeyDown}
              getOptionLabel={(option: any) => option.name}
              getOptionDisabled={(option: any) => {
                return source.includes(option.id);
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Source"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="agent"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setAgent(sourceIds);
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              options={agentsListnewdata?.results || []}
              getOptionDisabled={(option: any) => {
                return agent.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                option?.id && option?.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getAgentNewData(`?code=${newInputValue.replace(" ", "")}&usergroup=CustomerExecutive`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPartner([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Agent"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                      if (e.key === 'Enter') {
                        e.stopPropagation();
                      }
                    },
                  }}
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="verifiedby"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setVerifiedbyList(sourceIds);
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              options={verifiedbyList?.results || []}
              getOptionDisabled={(option: any) => {
                return VerifiedbyList.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) =>
                option?.id && option?.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getVerifiedbyList(
                    `?code=${newInputValue}&usergroup=VerificationExecutive`
                  );
                }, 1000);
                if (newInputValue.length === 0) {
                  setVerifiedbyList([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Verified by"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="Team Lead"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setTeamLead(obj.id);
                }
              }}
              freeSolo
              blurOnSelect
              limitTags={1}
              options={teamLeadList?.results || []}
              onKeyDown={onKeyDown}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) => option?.id && option?.username}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPanelUserListTeamLead(`?code=${newInputValue}&usergroup=CustomerLead`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setTeamLead("");
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Team Lead"
                  variant="outlined"
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                      if (e.key === 'Enter') {
                        e.stopPropagation();
                      }
                    },
                  }}
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="SalesLead"
              onChange={(event, newValue) => {
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setSalesLead(obj.id);
                }
              }}
              freeSolo
              blurOnSelect
              limitTags={1}
              options={salesLeadList?.results || []}
              onKeyDown={onKeyDown}
              disableClearable
              disableCloseOnSelect
              getOptionLabel={(option: any) => option?.id && option?.username}
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPanelUserListSalesLead(
                    `?code=${newInputValue}&usergroup=SalseLead`
                  );
                }, 1000);
                if (newInputValue.length === 0) {
                  setSalesLead([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Sales Lead"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                      if (e.key === 'Enter') {
                        e.stopPropagation();
                      }
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="phlebo"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setPhlebo(sourceIds);
              }}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              options={
                phleboList.results
                  ? [
                    ...phleboList.results,
                    { id: 0, user: { username: "NoPhlebo" } },
                  ]
                  : []
              }
              getOptionDisabled={(option: any) => {
                return phlebo.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onKeyDown={onKeyDown}
              getOptionLabel={(option: any) =>
                option.user && option.user.username
              }
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getPhlebos(`search=${newInputValue}`);
                }, 1000);
                if (newInputValue.length === 0) {
                  setPhlebo([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Phlebo Name"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setVerificationStatus(sourceIds);
              }}
              options={[
                {
                  text: "Pending",
                  value: "pending",
                },
                {
                  text: "Confirmed",
                  value: "confirmed",
                },
                {
                  text: "Cancelled",
                  value: "cancelled",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              onKeyDown={onKeyDown}
              multiple
              getOptionLabel={(option: any) => option.text}
              getOptionDisabled={(option: any) => {
                return verificationStatus.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Verification Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="city"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setPickup_status(sourceIds);
              }}
              options={[
                {
                  text: "Pending",
                  value: "pending",
                },
                {
                  text: "Confirmed",
                  value: "confirmed",
                },
                {
                  text: "Cancelled",
                  value: "cancelled",
                },
                {
                  text: "Hold",
                  value: "hold",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.text}
              onKeyDown={onKeyDown}
              getOptionDisabled={(option: any) => {
                return pickup_status.includes(option.value);
              }}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Pickup Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="usertype"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setUserGroup(obj.value);
                }

              }}
              options={[
                {
                  text: "SupportExecutive",
                  value: "SupportExecutive",
                },
                {
                  text: "CustomerExecutive",
                  value: "CustomerExecutive",
                },
                {
                  text: "SampleAccessioning",
                  value: "SampleAccessioning",
                },
                {
                  text: "VerificationExecutive",
                  value: "VerificationExecutive",
                },
                {
                  text: "PhleboAdmin",
                  value: "PhleboAdmin",
                },
                {
                  text: "Phlebos",
                  value: "Phlebos",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => option.text}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="User Type"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  inputProps={{
                    ...params.inputProps,
                    onKeyDown: (e) => {
                      if (e.key === 'Enter') {
                        e.stopPropagation();
                      }
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              name="start_date"
              type="date"
              label="Start Date"
              value={
                booking_date === "" || collection_date === "" ? start_date : ""
              }
              variant="outlined"
              disabled={booking_date !== "" || collection_date !== ""}
              onChange={(e) => setStart_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="end_date"
              type="date"
              value={
                booking_date === "" || collection_date === "" ? end_date : ""
              }
              className="input"
              label="End Date"
              variant="outlined"
              disabled={booking_date !== "" || collection_date !== ""}
              onChange={(e) => setEnd_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="booking_date"
              type="date"
              value={start_date === "" || end_date === "" ? booking_date : ""}
              className="input"
              label="Booking Date"
              variant="outlined"
              disabled={start_date !== "" || end_date !== ""}
              onChange={(e) => setBooking_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="collection_date"
              type="date"
              value={
                start_date === "" || end_date === "" ? collection_date : ""
              }
              className="input"
              label="Collection Date"
              variant="outlined"
              disabled={start_date !== "" || end_date !== ""}
              onChange={(e) => setCollection_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="slot"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setSlot(sourceIds);
              }}
              options={collectionSlot.results || []}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) =>
                option.start_time + "-" + option.end_time
              }
              getOptionDisabled={(option: any) => {
                return slot.includes(option.id);
              }}
              disableClearable
              disableCloseOnSelect
              onInputChange={(event, newInputValue) => {
                clearTimeout(timer.current);
                timer.current = setTimeout(() => {
                  getCollectionSlot(newInputValue);
                }, 1000);
                if (newInputValue.length === 0) {
                  setSlot([]);
                }
              }}
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Collection Slot"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="bookingtype"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setSampleRegisterd(obj.value);
                }

              }}
              options={[
                {
                  text: "Registerd",
                  value: "true",
                },
                {
                  text: "Not Registerd",
                  value: "false",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => option.text}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Sample Type"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="bookingtype"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setReportStatus(obj.value);
                }

              }}
              options={[
                {
                  text: "None",
                  value: "na",
                },
                {
                  text: "Pending",
                  value: "pending",
                },
                {
                  text: "Partial",
                  value: "partial",
                },
                {
                  text: "Consolidate",
                  value: "consolidate",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => option.text}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Report Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="bookingtype"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setbooking_type(obj.value);
                }

              }}
              options={[
                {
                  text: "B2B",
                  value: "b2b",
                },
                {
                  text: "B2C",
                  value: "b2c",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => option.text}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Booking Type"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="PhleboJourney"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setPhleboJourney(obj.value);
                }

              }}
              options={[
                {
                  text: "Started",
                  value: "started",
                },
                {
                  text: "Not Started",
                  value: "not-started",
                },
                {
                  text: "End",
                  value: "end",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => option.text}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Phlebo Journey"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="SourceType"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setSourceType(obj.value);
                }

              }}
              options={[
                {
                  text: "CoCall",
                  value: "cocall",
                },
                {
                  text: "Hotcall",
                  value: "hotcall",
                },
                {
                  text: "Ltv",
                  value: "ltv",
                },
                {
                  text: "H1",
                  value: "h1",
                },
                {
                  text: "H2",
                  value: "h2",
                },
                {
                  text: "BML",
                  value: "bml",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => option.text}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Source Type"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="location_verified"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  setLocationVerified(obj.value);
                }

              }}
              options={[
                {
                  text: "More than 3 Km - Verified",
                  value: "a",
                },
                {
                  text: "More than 3 Km - Not Verified",
                  value: "b",
                },
                {
                  text: "Less than 3 Km - Verified",
                  value: "c",
                },
                {
                  text: "Location not shared",
                  value: "d",
                },
                {
                  text: "Non-Serviceable - Not Verified",
                  value: "e",
                },
              ]}
              freeSolo
              blurOnSelect
              aria-required
              getOptionLabel={(option: any) => option.text}
              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Location/Verified"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              id="noTyping"
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setLocationMessageStatus(sourceIds);
              }}
              options={[
                {
                  text: "Triggred",
                  value: "triggred",
                },
                {
                  text: "Sent",
                  value: "sent",
                },
                {
                  text: "Delivered",
                  value: "delivered",
                },
                {
                  text: "Read",
                  value: "read",
                },


              ]}
              freeSolo
              blurOnSelect
              aria-required
              limitTags={1}
              multiple
              getOptionLabel={(option: any) => option.text}
              onKeyDown={onKeyDown}

              disableClearable
              disableCloseOnSelect
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  label="Location Message Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={filterBookings}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => history.push("/dashboard/sla/booking_table")}
            >
              Reset
            </Button>
          </Grid>

        </Grid>
        <br />
        {selectedLeads.length > 0 && (
          <Grid
            container
            spacing={1}
            direction="row"
            style={{ alignItems: "right", display: "flex" }}
          >
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="agentname"
                onChange={(event, newValue) => {
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    setPanelUser(obj.id);
                  }
                }}
                options={agentsListnewdata.results || []}
                freeSolo
                blurOnSelect
                aria-required
                getOptionLabel={(option: any) =>
                  option?.id && option?.username
                }
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getAgentNewData(`?code=${newInputValue.replace(" ", "")}&usergroup=CustomerExecutive`);
                  }, 1000);
                  if (newInputValue.length === 0) {
                    setPanelUser(0);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Agent username"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                        if (e.key === 'Enter') {
                          e.stopPropagation();
                        }
                      },
                    }}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="success"
                fullWidth
                onClick={transferLead}
                disabled={
                  panel_user === null ||
                  panel_user === 0

                }
              >
                Transfer
              </Button>
            </Grid>
          </Grid>
        )}
        <PaymentModal
          openModalP={openPaymentModal}
          setOpenModalP={setOpenPaymentModal}
          paymentInformation={paymentInformation}
        />

        {bookingListLoader ? (
          <Loader />
        ) : (
          bookingList.results &&
          bookingList.results.length > 0 && (
            <Grid container spacing={1}
            >
              <BookingsTable
                setopenModal={setopenModal}
                openModal={openModal}
                page={page}
                setPage={setPage}
                loading={loading}
                getBookings={getAllBookings}
                bookingList={bookingList}
                newbookingList={newbookingList}
                agentBYUserGroup={agentBYUserGroup}
                leadTransferByComma={leadTransferByComma}
                getAllLeads={getAllLeads}
                selectedLeads={selectedLeads}
                setSelectedLeads={setSelectedLeads}
                panel_user={panel_user}
                setPanelUser={setPanelUser}
                paymentId={paymentId}
                setPaymentId={setPaymentId}
                openPaymentModal={openPaymentModal}
                setOpenPaymentModal={setOpenPaymentModal}
                setTotalCount={setTotalCount}
                getZone={getZone}
                zoneList={zoneList}
                bookingListLoader={bookingListLoader}
                setBookingIdModal={setBookingIdModal}
                setOpenPhleboModel={setOpenPhleboModel}
              />
            </Grid>
          )
        )}
        {bookingList.results && bookingList.results.length === 0 && (
          <h6 style={{ textAlign: "center" }}>No Data Found</h6>
        )}
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Note:"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div style={{ margin: "1rem" }}>
                <div style={{ alignItems: "left", display: "inline-block", margin: "1rem" }}>
                  <small>
                    <ul style={{ paddingLeft: "15px" }}>
                      <li>
                        You can filter by booking date or start and end date.
                      </li>
                      <li>T: Total Additional Booking count</li>
                      <li>P: Pending count for additional member</li>
                      <li>C: Confirmed count for additional member</li>
                      <li>
                        For B2B booking first select Booking Type b2b then apply
                        any filter option
                      </li>
                    </ul>
                  </small>
                </div>
                <div style={{ alignItems: "left", display: "inline-block", margin: "1rem" }}>
                  <small>
                    <div className="r-cl" style={{ display: "flex" }}><div className="boxStyle" style={{ border: "1px solid", width: "17px", height: "17px", background: "#ffe082", }}></div><div style={{ marginLeft: "5px" }}>B2B booking and Payable Amount is Zero</div></div>
                    <div className="r-cl" style={{ display: "flex" }}><div className="boxStyle" style={{ border: "1px solid", width: "17px", height: "17px", background: "#f48fb1", }}></div><div style={{ marginLeft: "5px" }}>B2B booking and Payable Amount is not Zero</div></div>
                    <div className="r-cl" style={{ display: "flex" }}><div className="boxStyle" style={{ border: "1px solid", width: "17px", height: "17px", background: "#64ffda", }}></div><div style={{ marginLeft: "5px" }}>B2C booking and Payable Amount is Zero</div></div>
                    <div className="r-cl" style={{ display: "flex" }}><div className="boxStyle" style={{ border: "1px solid", width: "17px", height: "17px", background: "default", }}></div><div style={{ marginLeft: "5px" }}>B2C booking and Payable Amount is not Zero</div></div>
                    <div className="r-cl" style={{ display: "flex" }}><div className="boxStyle" style={{ border: "1px solid", width: "17px", height: "17px", background: "#4caf50", }}></div><div style={{ marginLeft: "5px" }}>Phlebo Reached</div></div>
                  </small>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>

        </Dialog>
        <PhleboJourneysModel
          setOpenPhleboModel={setOpenPhleboModel}
          openPhlebotModel={openPhlebotModel}
          bookingId={bookingIdModal}
          getPhleboTimeline={getPhleboTimeline}
          phleboTimeline={phleboTimeline}
          loading={loading}
        />

      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  agentsList: state.SalesLeadAdminReducer.agentsList,
  agentsListnewdata: state.SalesLeadAdminReducer.agentsListnewdata,
  partnerList: state.SalesLeadAdminReducer.partnerList,
  bookingList: state.SalesLeadAdminReducer.bookingList,
  newbookingList: state.SalesLeadAdminReducer.newbookingList,
  cities: state.SalesLeadAdminReducer.cities,
  phleboList: state.SalesLeadAdminReducer.phleboList,
  loading: state.SalesLeadAdminReducer.loading,
  lead_source: state.SalesLeadAdminReducer.lead_source,
  paymentInformation: state.SalesLeadAdminReducer.paymentInformation,
  agentBYUserGroup: state.SalesLeadAdminReducer.agentBYUserGroup,
  panelUserList: state.SalesLeadAdminReducer.panelUserList,
  collectionSlot: state.SalesLeadAdminReducer.collectionSlot,
  verifiedbyList: state.SalesLeadAdminReducer.verifiedbyList,
  salesLeadList: state.SalesLeadAdminReducer.salesLeadList,
  teamLeadList: state.SalesLeadAdminReducer.teamLeadList,
  bookingListLoader: state.SalesLeadAdminReducer.bookingListLoader,
  zoneList: state.SalesLeadAdminReducer.zoneList,
  userDetails: state.SalesLeadAdminReducer.userDetails,
  phleboTimeline: state.SalesLeadAdminReducer.phleboTimeline,
});

export default connect(mapStateToProps, {
  getAgentList,
  getPartners,
  getCities,
  getPhlebos,
  getLeadSource,
  getAllLeads,
  leadTransferByComma,
  getAllBookings,
  getAllNewBookings,
  getPaymentInfo,
  getPanelUserList,
  getCollectionSlot,
  getVerifiedbyList,
  getPanelUserListSalesLead,
  getPanelUserListTeamLead,
  getZone,
  getUserDetails,
  getAgentNewData,
  getPhleboTimeline,
})(CustomerLeadFilterPage);



