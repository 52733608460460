import React, { useState } from "react";
import { useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Loader from "../../Components/loader/index";
import {
  Paper,
  TextField,
  Grid,
  Select,
  MenuItem,
  TableContainer,
  Slider,
  Chip,
  withStyles,
  TableFooter,
  TablePagination,
  Switch,
} from "@material-ui/core";
import Button from '@mui/material/Button';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import CommentIcon from "@material-ui/icons/Comment";
import { Editor, OriginalTools } from "react-bootstrap-editor";
import { useEffect } from "react";
import {
  getCentreComments,
  disabledCenter,
} from "../../actions/ImagingAdminAction";
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CenterCommentModal from "../../Components/comment/centerComment";
import "./index.sass";
import { StringDecoder } from "string_decoder";
import CenterDeactivate from "../../Components/comment/centerDeactivateModel";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#3f51b5",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    table: {
      minWidth: 700,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem 2rem 2rem",
      width: "100%",
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
      minHeight: "64px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    head: {
      textAlign: "center",
      fontWeight: "normal",
      marginBottom: "2rem",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    hoveItem: {
      cursor: "pointer",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00dfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    input: {
      padding: "1px",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    centerIt: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

const PrettoSlider: any = withStyles({
  root: {
    color: "rgb(146, 74, 145)",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "violet",
    border: "2px solid purple",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
const DefaultSwitch: any = withStyles({
  switchBase: {
    color: "red",
    "&$checked": {
      color: "green",
    },
    "&$checked + $track": {
      backgroundColor: "green",
    },
  },
  checked: {},
  track: {
    backgroundColor: "red",
  },
})(Switch);

interface Props {
  centreData: any;
  getCenterInfo: any;
  getCentreComments: any;
  disabledCenter: any;
  centreComments: any;
  loading: any;
  openModal: any;
  handleEdit: any;
}
const CreateCentrePage: React.FC<Props> = ({
  centreData,
  getCenterInfo,
  getCentreComments,
  centreComments,
  disabledCenter,
  loading,
  handleEdit,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [CenterId, setCenterId] = React.useState<any>("");
  const timer = useRef<any>(0);
  const [openModal, setopenModal] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [isActive, setIsActive] = useState(false);
  const [openReportModel, setOpenReportModel] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [centreDataStatus,setCentreDataStatus]=useState<any>()

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = centreData.links && centreData.links.next.split("?")[1];
      getCenterInfo(`?${url}`);
    } else if (newPage < page) {
      let url = centreData.links && centreData.links.previous.split("?")[1];
      getCenterInfo(`${url == undefined ? "" : "?" + url}`);
    }
    setPage(newPage);
  };
  const handleModal = (id: any) => {
    setopenModal(true);
    setCenterId(id);
  };
  useEffect(() => {
    if (CenterId !== "") {
      getCentreComments(`?center=${CenterId}`);
    }
  }, [CenterId]);

  const DisableCenterAddress = async (pack: any) => {
    const body: any = {
      is_active: !pack.is_active,
    };
   
    //  setOpenReportModel(!openReportModel);
     
     await disabledCenter(pack.id, body);
     history.push("/dashboard/ia/center_deactivate");
  };

 const handlePopUpModel=async()=>
 {
  setOpenReportModel(!openReportModel);
 }

 const handleClose = () => {
  setOpen(false);
};
//mapmyindia
const handleActivateCentre = () =>{
  DisableCenterAddress(centreDataStatus)
}
const handleClickOpen = (data:any) => {
  setCentreDataStatus(data)
  setOpen(true);
};

  
  return (
    <main className={classes.content}>
      <div className="data-table" style={{ width: "100%", marginTop: "1rem" }}>
        <Grid
          container
          spacing={3}
          className={classes.centerIt}
          style={{
            margin: "1rem auto",
          }}
        >
          <Grid item xs={12} sm={6} md={2}>
            <h5 style={{ marginTop: 15 }}>Search Option </h5>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              className="input"
              label="Centre/Code"
              variant="outlined"
              fullWidth
              onChange={(e) =>
                getCenterInfo(`?imaging=true&name_or_code=${e.target.value}`)
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => history.push("/dashboard/ia")}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          style={{ maxHeight: "515px" }}
        >
          {loading ? (
            <Loader />
          ) : (
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Centre Name</StyledTableCell>
                  <StyledTableCell align="center">Centre Owner</StyledTableCell>
                  <StyledTableCell align="center">Centre Email</StyledTableCell>
                  <StyledTableCell align="center">Number</StyledTableCell>
                  <StyledTableCell align="center">COMMENT</StyledTableCell>
                  <StyledTableCell align="center">Created Date</StyledTableCell>
                  <StyledTableCell align="center">Expiry Date</StyledTableCell>
                  <StyledTableCell align="center">City</StyledTableCell>
                  <StyledTableCell align="center">Centre Type</StyledTableCell>
                  <StyledTableCell align="center">Edit Centre</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">Deactivate Center{" "} </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {centreData &&
                  centreData.results &&
                  centreData.results.length > 0 &&
                  centreData.results.map((data: any, index: any) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center">
                          {data?.display_name === null
                            ? data?.name
                            : data?.display_name}
                        </StyledTableCell>
                        {/* <StyledTableCell align="center">
                          {data?.center_code}
                        </StyledTableCell> */}
                        <StyledTableCell align="center">
                          {data?.owner_name}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.email}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.mobile}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <CommentIcon onClick={() => handleModal(data?.id)} />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.contract_start_date}{" "}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {data?.contract_end_date}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {data?.area?.city_name}
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          {" "}
                          {data.center_type}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => handleEdit(data)}
                          >
                            edit
                          </Button>
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <DefaultSwitch
                            checked={data.is_active}
                            name="checkedA"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                            disableRipple
                            // onClick={() => DisableCenterAddress(data)}
                          />
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button
                            // color="primary"
                            // variant="contained"
                            onClick={()=>handleClickOpen(data)}
                          >
                            <DefaultSwitch />
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
              <TableFooter>
                <TablePagination
                  colSpan={6}
                  count={centreData.count || 0}
                  rowsPerPageOptions={[]}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                />
              </TableFooter>
            </Table>
          )}
        </TableContainer>
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{" Do You want to Deactivate Centre"}</DialogTitle>
            <DialogActions>
              <Button onClick={handleClose}>Disagree</Button>
              <Button onClick={handleActivateCentre}>Agree</Button>
            </DialogActions>
          </Dialog>
      </div>
      <CenterCommentModal
        openModal={openModal}
        setopenModal={setopenModal}
        centreComments={centreComments}
      />

      <CenterDeactivate
        setOpenReportModel={setOpenReportModel}
        openReportModel={openReportModel}
      />
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  loading: state.ImagingAdminReducer.loading,
  centreComments: state.ImagingAdminReducer.centreComments,
});
export default connect(mapStateToProps, {
  getCentreComments,
  disabledCenter,
})(CreateCentrePage);
