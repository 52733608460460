import { AccessAlarm } from "@material-ui/icons";

export const ACTIONS = {
  CHANGE_PASSWORD: "change_password",
  GET_PIN_CODE: "ce/get_pin_code",
  GET_CITIES: "ce/get_cities",
  GET_AREA: "ce/get_area",
  GET_AGENTS: "ce/get_agents",
  GET_PACKAGES: "ce/get_packages",
  GET_PACKAGES_TAGS: "ce/get_packages_tags",
  GET_PACKAGES_CATEGORY: "ce/get_packages_category",
  GET_FAMILY_PACKAGES: "ce/get_family_packages",
  GET_FAMILY_PACKAGES_BOOKING: "ce/get_family_packages_booking",
  CREATE_FAMILY_PACKAGES_BOOKING: "ce/create_family_packages_booking",
  GET_PARTNERS: "ce/get_partners",
  GET_COUPONS: "ce/get_coupons",
  CREATE_BOOKING: "ce/create_booking",
  CREATE_IMAGING_BOOKING: "ce/CREATE_IMAGING_BOOKING",
  CREATE_WHATSAPP_TEMPLATE: "ce/create_whatsapp_template",
  CREATE_ADDITIONAL_BOOKING: "ce/create_additional_booking",
  GET_PHLEBOS: "ce/get_phlebos",
  SEARCH_CALL: "ce/serach",
  UPDATE_ADDITIONAL_BOOKING: "ce/update_additional_booking",
  UPDATE_BOOKING: "ce/updatel_booking",
  GET_BOOKING_LIST: "ce/get_booking_list",
  GET_LEAD_DETAILS: "ce/get_lead_details",
  GET_LEAD_BY_ID: "ce/get_lead_by_id",
  GET_LTV_LEAD: "ce/get_ltv_lead",
  LEAD_DETAILS_RESET: "ce/lead_details_reset",
  BOOKING_RESET: "ce/booking_reset",
  GET_LEADS: "ce/get_leads",
  GET_BOOKING_BY_ID: "ce/get_booking_by_id",
  GET_ZONE: "ce/get_zone",
  GET_NEW_BOOKING_LIST: "ce/get_new_booking_list",
  CLICK_TO_CALL: "ce/click_to_call",
  GET_POSTIVE_CALLS_COMMENTS: "ce/get_postive_calls_commnets",
  ADD_POSTIVE_CALLS_COMMENT: "ce/add_comments",
  SET_COMMENTS: "ce/set_commnets",
  ADD_COMMENT: "positive_calls/add_comment",
  CREATE_CALL: "ce/createcall",
  GET_LEAD_SOURCE: "ce/getleadsource",
  GET_RECORDINGS: "ce/get_recordings",
  GET_BOOKINGS_COMMENTS: "ce/get_bookings_commnets",
  ADD_BOOKINGS_COMMENT: "ce/add_bookings_comments",
  GET_WHATSAPP_COMMENTS: "ce/get_whatsapp_comments",
  GET_WHATSAPP_COMMENTS_BY_LEAD: "ce/get_whatsapp_comments_by_lead",
  SEND_WHATSAPP_COMMENTS: "ce/send",
  GET_CALL_STATS: "ce/get_call_stats",
  GET_USER_STATS: "ce/get_call_stats",
  RAISE_COMPLAINT: "ce/raise_complaint",
  GET_COMPLAINTS: "ce/get_complaints",
  ASSIGN_DOCTOR: "ce/assign_doctor",
  SEND_FEEDBACK: "ce/send_feedback",
  SET_LOADING: "ce/set_loading",
  SET_LEAD_ADDRESS_LOADER: "ce/set_lead_address_loader",
  SET_BOOKING_ID_LOADER: "ce/set_booking_id_loader",
  SET_BIOMARKER_LOADER: "ce/set_biomarker_loader",
  LOCATION_VERIFIED: "ce/location_verified",
  SET_LOADING_WHATSAPP: "ce/set_loading_whatsapp",
  GET_AVAILABLE_SLOTS: "ce/get_available_slots",
  GET_ALL_LEADS: "ce/get_all_leads",
  GET_LATEST_LEAD: "ce/get_latest_lead",
  GET_SYNC_DATA: "ce/get_sync_data",
  SAME_NUMBER_BOOKING: "ce/same_number_booking",
  GET_BREAK_RESPONSE: "ce/get_break_response",
  GET_PANEL_BREAK: "ce/get_panel_break",
  GET_PAYMENT_SEND_LINK: "ce/get_payment_send_link",
  GET_PAYMENT_RESEND_LINK: "ce/get_payment_resend_link",
  GET_PAYMENT_CANCEL_LINK: "ce/get_payment_cancel_link",
  GET_SMS_DETAILS: "ce/get_sms_details",
  GET_EMAIL_DETAILS: "ce/get_email_details",
  GET_SMS_REPORT_SEND: "ce/get_sms_report_sms",
  GET_CENTER_INFORMATION: "ce/get_center_information",
  GET_SMS_LINK: "cs/send_sms_link",
  GET_NOTIFICATION_MESSAGE: "ce/get_notification_message",
  GET_REPORT_STATUS: "ce/get_report_status",
  GET_UNMASKED_NUMBER: "ce/get_unmasked_number",
  GET_USER_DETAILS: "se/get_user_details",
  GET_FIREBASE_NOTIFICATION: "ce/get_firebase_notification",
  GET_LAB_DETAILS: "ce/get_lab_details",
  GET_USER_DATA: "ce/get_users_data",
  GET_NOTIFICATION_TYPE: "ce/get_notification_type",
  CHECK_GEOFENCE_AREA: "ce/checking-geofence-area",
  CHECK_GEOFENCE_AREA_MAMPMYINDIA: "ce/checking-geofence-area-mapmyindia",
  GET_MAMPMYINDIA_ACCESS_TOKEN: "ce/get-mapmyindia-access-token",
  GET_ADDRESS_DETAILS_LAT_LONG: "ce/get-address-details-lat-long",
  GET_TODAYS_MISSED_CALLS: "ce/get_today_missed_calls",
  GET_REPEATED_LEAD: "ce/get_repeated_list",
  GET_REPORT_DOWNLOAD: "ce/get_report_download",
  GET_REPEATED_LEADS: "ce/get_repeted_lead",
  UPDATED_REPEATED_LIST: "ce/updated_repeated_lead",
  SEND_NOTIFICATION: "ce/send_notification",
  GET_TIMESLOTS: "ce/get_timeslot",
  GET_TOP_RC_FRESH_LEADS: "ce/get_top_rc_fresh_leads",
  GET_TOP_C_FRESH_LEADS: "ce/get_top_c_fresh_leads",
  GET_RED_COIN: "ce/get_red_coin",
  GET_SUB_CENTER_INFORMATION: "ce/get_sub_center_information",
  GET_IMAGING_TIMESLOTS: "ce/get_imaging_timeslots",
  GET_MULTIPLE_PACKAGES: "ce/get_multiple_package",
  GET_DISPLAY_CITIES: "ce/get_display_cities",
  GET_LEAD_STATUS: "ce/get_lead_status",
  GET_PRESCRIPTION_DATA: "ce/get_prescription_data",
  GET_DOCTOR_INFO_BY_ID: "ce/get_doctor_info",
  POST_IMAGING_BOOKING_DISPOSITION: "ce/lead_imaging_disposition",
  GET_IMAGING_TICKET: "ce/get_imaging_ticket",
  GET_IMAGING_TICKET_COUNT: "ce/get_imaging_ticket_count",
  GET_IMAGING_RAISE_TICKET: "ce/get_imaging_raise_ticket",
  SEND_INITIAL_WHATSAPP_MESSAGE: "ce/send_initial_whatsapp_message",
  CREATE_IMAGING_BOOKING_CUSTOM: "ce/imaging_booking_custom",
  UPDATE_IMAGING_TICKET: "ce/update_imaging_ticket",
  GET_TICKET_DATA: "ce/get_ticket_data",
  GET_TICKET_CATEGORIES: "ce/get_ticket_category",
  GET_TICEKT_SUB_CATEGORIES: "ce/get_ticket_categories",
  CREATE_TASK_TICKET: "ce/create_task_ticket",
  GET_RED_TECH_COMMENT: "ce/redtech_comments/",
  POST_RED_TECH_COMMENT: "ce/postredtech_comments/",
  GET_HOLD_CANCEL_BOOKING: "ce/get_cancel_hold_booking_list",
  GET_QUALITY_SCORING_REPORT: "ve/get_quality_scoring_report/",
  GET_QUALITY_SCORING_REPORT_FOR_BACKUP: "ve/get_quality_scoring_report_for_backup/",
  GET_CE_CENTER_FOR_CC: "ce/get_ce_center_for_cc",
  CREATE_PILOT_BOOKING: "ce/create_pilot_booking",
  SET_GEO_LOADING: "ce/set_geo_loading",
  SET_GEO_CHECK_ERROR: "ce/set_geo_check_error",
  GET_BOOKING_COMPLAINTS: "ce/get_booking_complaints",
  UPDATE_DISPOSITION: "ce/update_disposition",
  UPDATE_TICKET_STATUS: "ce/update_ticekt_status",
  GET_TICKET_COMMENTS: "ce/ticket_comments",
  SET_ALL_BOOKING_LOADING: "ce/set_all_booking_loading",
  SET_QUALITYSCORE_LOADING: "ce/set_qualityscore_loader",
  SET_QUALITYSCOREBACKUP_LOADING: "ce/set_qualityscorebackup_loader",
  SET_CSTICKET_LOADING: "ce/set_csticket_loading",
  CHECK_GEOFENCE_AREA_MAMPMYINDIA_FOR_CUSTOMER_ADDRESS: "ce/checking-geofence-area-mapmyindia_for_customer",
  SET_GEO_LOADING_FOR_CUSTOMER: "ce/set_geo_loading_for_customer",
  SET_GEO_ERROR_FOR_CUSTOMER: "ce/set_geo_check_error_for_customer",
  GET_WHATSAPP_TEMPLATE: "ce/get_whatsapp_template",
  GET_BIOMARKER_SCREENING: "ce/biomarker_screening",
  GET_BIOMARKER_ULTASOUND: "ce/biomarker_ultasound",
  GET_ADDITIONAL_MEMBER: "ce/additional_member",
  BIOMARKER_RESET: "ce/biomarker_reset",
  GET_SEND_LOCATION_REQUEST: "ce/communication/on-whatsapp",
  SEND_LOCATION_REQUEST: "ce/communication/send-location-request/",
  GET_CRM_LEAD_ADDRESS: "ce/get_auth/address-crm/",
  POST_CRM_LEAD_ADDRESS: "ce/post_auth/address-crm/",
  GET_WHATSAPP_MESSAGE_STATUS: "ce/get_whatsapp_message_status",
  GET_LEAD_DISPOSITION_LIST: "ce/plead/status-category/",
  POST_DISPOSITION_DATA: "ce/lead/lead-status-update/",
  INITIATE_REFUND: "se/initiate_refund",

};

interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface SetCsLoading {
  type: typeof ACTIONS.SET_CSTICKET_LOADING;
  payload: Object;
}
interface CheckGeoFenceAreaMapMyIndiaForCustomer {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA_FOR_CUSTOMER_ADDRESS;
  payload: Array<any>;
}
interface GeoLoadingForCustomer {
  type: typeof ACTIONS.SET_GEO_LOADING_FOR_CUSTOMER;
  payload: boolean;
}
interface CheckGeoCheckErrorForCustomer {
  type: typeof ACTIONS.SET_GEO_ERROR_FOR_CUSTOMER;
  payload: boolean;
}
interface GeoLoading {
  type: typeof ACTIONS.SET_GEO_LOADING;
  payload: boolean;
}
interface GetLeadDispostion {
  type: typeof ACTIONS.GET_LEAD_DISPOSITION_LIST;
  payload: Object;
}
interface CheckGeoCheckError {
  type: typeof ACTIONS.SET_GEO_CHECK_ERROR;
  payload: boolean;
}
interface CreatePilotBooking {
  type: typeof ACTIONS.CREATE_PILOT_BOOKING;
  payload: boolean;
}
interface GeoLoading {
  type: typeof ACTIONS.SET_GEO_LOADING;
  payload: boolean;
}
interface GetUserStats {
  type: typeof ACTIONS.GET_USER_STATS;
  payload: Object;
}

interface GetTopRcFreshLeads {
  type: typeof ACTIONS.GET_TOP_RC_FRESH_LEADS;
  payload: Array<any>;
}
interface GetCeCenterForCc {
  type: typeof ACTIONS.GET_CE_CENTER_FOR_CC;
  payload: Array<any>;
}
interface GetCancelHoldBooking {
  type: typeof ACTIONS.GET_HOLD_CANCEL_BOOKING;
  payload: Array<any>;
}
interface CreateTaskTicket {
  type: typeof ACTIONS.CREATE_TASK_TICKET;
  payload: Array<any>;
}
interface GetRedTechComment {
  type: typeof ACTIONS.GET_RED_TECH_COMMENT;
  payload: Array<any>;
}
interface UpdateImagingTicket {
  type: typeof ACTIONS.UPDATE_IMAGING_TICKET;
  payload: Array<any>;
}
interface SendInititalWhatsapp {
  type: typeof ACTIONS.SEND_INITIAL_WHATSAPP_MESSAGE;
  payload: Array<any>;
}
interface GetPrescriptionData {
  type: typeof ACTIONS.GET_PRESCRIPTION_DATA;
  payload: Array<any>;
}
interface GetDisplayCities {
  type: typeof ACTIONS.GET_DISPLAY_CITIES;
  payload: Array<any>;
}
interface GetMultiplepackage {
  type: typeof ACTIONS.GET_MULTIPLE_PACKAGES;
  payload: Array<any>;
}
interface GetImagingTimeSlots {
  type: typeof ACTIONS.GET_IMAGING_TIMESLOTS;
  payload: Array<any>;
}
interface GetSubCenterInformation {
  type: typeof ACTIONS.GET_SUB_CENTER_INFORMATION;
  payload: Array<any>;
}
interface GetRedCoin {
  type: typeof ACTIONS.GET_RED_COIN;
  payload: Array<any>;
}
interface GetTopCFreshLeads {
  type: typeof ACTIONS.GET_TOP_C_FRESH_LEADS;
  payload: Array<any>;
}
interface GetAllLeads {
  type: typeof ACTIONS.GET_ALL_LEADS;
  payload: Object;
}
interface GetLatestLeads {
  type: typeof ACTIONS.GET_LATEST_LEAD;
  payload: Object;
}
interface SearchCall {
  type: typeof ACTIONS.SEARCH_CALL;
  payload: Object;
}
interface GetRecordings {
  type: typeof ACTIONS.GET_RECORDINGS;
  payload: Object;
}
interface PostCrmLeadAddress {
  type: typeof ACTIONS.POST_CRM_LEAD_ADDRESS;
  payload: Object;
}

interface GetPinCode {
  type: typeof ACTIONS.GET_PIN_CODE;
  payload: Array<any>;
}
interface UpdatedRepeatedLead {
  type: typeof ACTIONS.UPDATED_REPEATED_LIST;
  payload: Array<any>;
}
interface GetTimeSlot {
  type: typeof ACTIONS.GET_TIMESLOTS;
  payload: Array<any>;
}
interface SendNotification {
  type: typeof ACTIONS.SEND_NOTIFICATION;
  payload: Array<any>;
}
interface GetReportDownload {
  type: typeof ACTIONS.GET_REPORT_DOWNLOAD;
  payload: Array<any>;
}
interface GetRepeatedList {
  type: typeof ACTIONS.GET_REPEATED_LEAD;
  payload: Array<any>;
}
interface ChangePassword {
  type: typeof ACTIONS.CHANGE_PASSWORD;
  payload: Object;
}
interface CreateCall {
  type: typeof ACTIONS.CREATE_CALL;
  payload: Object;
}
interface AddComment {
  type: typeof ACTIONS.ADD_COMMENT;
  payload: Object;
}
interface GetLeadSource {
  type: typeof ACTIONS.GET_LEAD_SOURCE;
  payload: Object;
}
interface GetCities {
  type: typeof ACTIONS.GET_CITIES;
  payload: Array<any>;
}
interface imaginBookingDisposition {
  type: typeof ACTIONS.POST_IMAGING_BOOKING_DISPOSITION;
  payload: Array<any>;
}
interface GetNotificationType {
  type: typeof ACTIONS.GET_NOTIFICATION_TYPE;
  payload: Array<any>;
}

interface GetArea {
  type: typeof ACTIONS.GET_AREA;
  payload: Array<any>;
}
interface GetPhlebo {
  type: typeof ACTIONS.GET_PHLEBOS;
  payload: Array<any>;
}

interface getCrmLeadAddress {
  type: typeof ACTIONS.GET_CRM_LEAD_ADDRESS;
  payload: Array<any>;
}
interface GetZone {
  type: typeof ACTIONS.GET_ZONE;
  payload: Object;
}
interface GetAgents {
  type: typeof ACTIONS.GET_AGENTS;
  payload: Array<any>;
}
interface GetCommunicationOnWhatsapp {
  type: typeof ACTIONS.GET_SEND_LOCATION_REQUEST;
  payload: Array<any>;
}

interface SetComments {
  type: typeof ACTIONS.SET_COMMENTS;
  payload: Object;
}

interface GetPositiveCallsComments {
  type: typeof ACTIONS.GET_POSTIVE_CALLS_COMMENTS;
  payload: Object;
}

interface AddPositiveCallsComments {
  type: typeof ACTIONS.ADD_POSTIVE_CALLS_COMMENT;
  payload: Object;
}

interface GetBookingsComments {
  type: typeof ACTIONS.GET_BOOKINGS_COMMENTS;
  payload: Object;
}

interface AddBookingsComments {
  type: typeof ACTIONS.ADD_BOOKINGS_COMMENT;
  payload: Object;
}

interface ClickToCall {
  type: typeof ACTIONS.CLICK_TO_CALL;
  payload: Array<any>;
}

interface GetPackages {
  type: typeof ACTIONS.GET_PACKAGES;
  payload: Array<any>;
}
interface GetPackagesTags {
  type: typeof ACTIONS.GET_PACKAGES_TAGS;
  payload: Array<any>;
}
interface GetPackagesCategory {
  type: typeof ACTIONS.GET_PACKAGES_CATEGORY;
  payload: Array<any>;
}
interface GetFamilyPackages {
  type: typeof ACTIONS.GET_FAMILY_PACKAGES;
  payload: Array<any>;
}

interface GetFamilyPackagesBooking {
  type: typeof ACTIONS.GET_FAMILY_PACKAGES_BOOKING;
  payload: Array<any>;
}

interface CreateFamilyPackageBooking {
  type: typeof ACTIONS.CREATE_FAMILY_PACKAGES_BOOKING;
  payload: Object;
}

interface GetPartners {
  type: typeof ACTIONS.GET_PARTNERS;
  payload: Array<any>;
}

interface GetCoupons {
  type: typeof ACTIONS.GET_COUPONS;
  payload: Array<any>;
}

interface LocationVerified {
  type: typeof ACTIONS.LOCATION_VERIFIED;
  payload: boolean;
}
interface SetLoading {
  type: typeof ACTIONS.SET_LOADING;
  payload: boolean;
}
interface SetLeadAddressLoading {
  type: typeof ACTIONS.SET_LEAD_ADDRESS_LOADER;
  payload: boolean;
}
interface SetBookingIdLoader {
  type: typeof ACTIONS.SET_BOOKING_ID_LOADER;
  payload: boolean;
}
interface SetBiomarkerLoader {
  type: typeof ACTIONS.SET_BIOMARKER_LOADER;
  payload: boolean;
}
interface SetLoadingWhatsapp {
  type: typeof ACTIONS.SET_LOADING_WHATSAPP;
  payload: boolean;
}
interface CreateBooking {
  type: typeof ACTIONS.CREATE_BOOKING;
  payload: boolean;
}
interface CreateImagingBooking {
  type: typeof ACTIONS.CREATE_IMAGING_BOOKING;
  payload: boolean;
}
interface CreateWhatsappTemplate {
  type: typeof ACTIONS.CREATE_WHATSAPP_TEMPLATE;
  payload: boolean;
}

interface UpdateBooking {
  type: typeof ACTIONS.UPDATE_BOOKING;
  payload: boolean;
}

interface CreateImagingBookingCustom {
  type: typeof ACTIONS.CREATE_IMAGING_BOOKING_CUSTOM;
  payload: boolean;
}

interface GetBookings {
  type: typeof ACTIONS.GET_BOOKING_LIST;
  payload: boolean;
}
interface GetBookingsLoader {
  type: typeof ACTIONS.SET_ALL_BOOKING_LOADING;
  payload: boolean;
}

interface GetBookingById {
  type: typeof ACTIONS.GET_BOOKING_BY_ID;
  payload: boolean;
}

interface GetLeads {
  type: typeof ACTIONS.GET_LEADS;
  payload: boolean;
}

interface GetLeadDetails {
  type: typeof ACTIONS.GET_LEAD_DETAILS;
  payload: boolean;
}

interface GetLeadById {
  type: typeof ACTIONS.GET_LEAD_BY_ID;
  payload: boolean;
}
interface GetLtvLead {
  type: typeof ACTIONS.GET_LTV_LEAD;
  payload: boolean;
}
interface ResetLeadDetails {
  type: typeof ACTIONS.LEAD_DETAILS_RESET;
  payload: boolean;
}

interface ResetBooking {
  type: typeof ACTIONS.BOOKING_RESET;
  payload: boolean;
}

interface GetWhatsappComments {
  type: typeof ACTIONS.GET_WHATSAPP_COMMENTS;
  payload: boolean;
}

interface GetWhatsappCommentsByLead {
  type: typeof ACTIONS.GET_WHATSAPP_COMMENTS_BY_LEAD;
  payload: boolean;
}

interface SendWhatsappComments {
  type: typeof ACTIONS.SEND_WHATSAPP_COMMENTS;
  payload: boolean;
}

interface GetCallStats {
  type: typeof ACTIONS.GET_CALL_STATS;
  payload: boolean;
}

interface RaiseComplaint {
  type: typeof ACTIONS.RAISE_COMPLAINT;
  payload: boolean;
}

interface GetQueryTickets {
  type: typeof ACTIONS.GET_COMPLAINTS;
  payload: boolean;
}

interface AssignDoctor {
  type: typeof ACTIONS.ASSIGN_DOCTOR;
  payload: boolean;
}

interface SendNewFeedback {
  type: typeof ACTIONS.SEND_FEEDBACK;
  payload: boolean;
}

interface GetAvailabeSlots {
  type: typeof ACTIONS.GET_AVAILABLE_SLOTS;
  payload: Object;
}
interface CreateAdditionalBooking {
  type: typeof ACTIONS.CREATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface UpdateAdditionalBooking {
  type: typeof ACTIONS.UPDATE_ADDITIONAL_BOOKING;
  payload: Object;
}
interface GetSyncData {
  type: typeof ACTIONS.GET_SYNC_DATA;
  payload: Object;
}
interface SameNumberBooking {
  type: typeof ACTIONS.SAME_NUMBER_BOOKING;
  payload: Object;
}
interface GetBreakResponse {
  type: typeof ACTIONS.GET_BREAK_RESPONSE;
  payload: Object;
}
interface GetPanelBreak {
  type: typeof ACTIONS.GET_PANEL_BREAK;
  payload: Object;
}
interface GetPaymentSendLink {
  type: typeof ACTIONS.GET_PAYMENT_SEND_LINK;
  payload: Object;
}
interface GetPaymentReSendLink {
  type: typeof ACTIONS.GET_PAYMENT_RESEND_LINK;
  payload: Object;
}
interface GetPaymentCancelLink {
  type: typeof ACTIONS.GET_PAYMENT_CANCEL_LINK;
  payload: Object;
}
interface GetSmsDetails {
  type: typeof ACTIONS.GET_SMS_DETAILS;
  payload: Array<any>;
}
interface GetEmailDetails {
  type: typeof ACTIONS.GET_EMAIL_DETAILS;
  payload: Array<any>;
}
interface GetSmsReportSend {
  type: typeof ACTIONS.GET_SMS_REPORT_SEND;
  payload: Object;
}
interface GetCenterInfo {
  type: typeof ACTIONS.GET_CENTER_INFORMATION;
  payload: Array<any>;
}
interface SendSmsLink {
  type: typeof ACTIONS.GET_SMS_LINK;
  payload: Object;
}
interface GetNotificationMessage {
  type: typeof ACTIONS.GET_NOTIFICATION_MESSAGE;
  payload: Array<any>;
}
interface GetReportStatus {
  type: typeof ACTIONS.GET_REPORT_STATUS;
  payload: Array<any>;
}
interface CheckGeoFenceArea {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA;
  payload: Array<any>;
}
interface CheckGeoFenceAreaMapMyIndia {
  type: typeof ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA;
  payload: Array<any>;
}
interface GetMapMyIndiaAccessToken {
  type: typeof ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN;
  payload: Array<any>;
}
interface GetAddressDetailsLatLong {
  type: typeof ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG;
  payload: Array<any>;
}
interface GetWhatsappMessageStatus {
  type: typeof ACTIONS.GET_WHATSAPP_MESSAGE_STATUS;
  payload: Array<any>;
}

interface GetUnmaskedNumber {
  type: typeof ACTIONS.GET_UNMASKED_NUMBER;
  payload: Array<any>;
}
interface GetLabDetails {
  type: typeof ACTIONS.GET_LAB_DETAILS;
  payload: Array<any>;
}
interface GetUserDetails {
  type: typeof ACTIONS.GET_USER_DETAILS;
  payload: Object;
}
interface GetFireBaseNotification {
  type: typeof ACTIONS.GET_FIREBASE_NOTIFICATION;
  payload: Object;
}
interface GetUsersData {
  type: typeof ACTIONS.GET_USER_DATA;
  payload: Object;
}
interface GetTodayMissedCalls {
  type: typeof ACTIONS.GET_TODAYS_MISSED_CALLS;
  payload: Object;
}
interface GetRepetedLead {
  type: typeof ACTIONS.GET_REPEATED_LEADS;
  payload: Array<any>;
}
interface PostDispositionData {
  type: typeof ACTIONS.POST_DISPOSITION_DATA;
  payload: Array<any>;
}
interface GetLeadStatus {
  type: typeof ACTIONS.GET_LEAD_STATUS;
  payload: Object;
}
interface SendLocationRequest {
  type: typeof ACTIONS.SEND_LOCATION_REQUEST;
  payload: Object;
}
interface getDoctorInfoById {
  type: typeof ACTIONS.GET_DOCTOR_INFO_BY_ID;
  payload: object;
}
interface GetImagingTicketCount {
  type: typeof ACTIONS.GET_IMAGING_TICKET_COUNT;
  payload: Object;
}
interface GetImagingTicket {
  type: typeof ACTIONS.GET_IMAGING_TICKET;
  payload: Object;
}
interface GetImagingRaiseTicket {
  type: typeof ACTIONS.GET_IMAGING_RAISE_TICKET;
  payload: Object;
}

interface GetNewBookings {
  type: typeof ACTIONS.GET_NEW_BOOKING_LIST;
  payload: boolean;
}
interface GetTicketData {
  type: typeof ACTIONS.GET_TICKET_DATA;
  payload: Object;
}
interface GetTicketCategory {
  type: typeof ACTIONS.GET_TICKET_CATEGORIES;
  payload: Object;
}
interface GetTicketSubCategories {
  type: typeof ACTIONS.GET_TICEKT_SUB_CATEGORIES;
  payload: Object;
}
interface PostRedTechComment {
  type: typeof ACTIONS.POST_RED_TECH_COMMENT;
  payload: Object;
}

interface GetQualityScoringReport {
  type: typeof ACTIONS.GET_QUALITY_SCORING_REPORT;
  payload: Array<any>;
}

interface GetQualityScoringReportForBackup {
  type: typeof ACTIONS.GET_QUALITY_SCORING_REPORT_FOR_BACKUP;
  payload: Array<any>;
}

interface GetBookingQueryTickets {
  type: typeof ACTIONS.GET_BOOKING_COMPLAINTS;
  payload: boolean;
}
interface updateActionDisposition {
  type: typeof ACTIONS.UPDATE_DISPOSITION;
  payload: Array<any>;
}
interface UpdateTicketStatus {
  type: typeof ACTIONS.UPDATE_TICKET_STATUS;
  payload: Array<any>;
}
interface GetTicketComments {
  type: typeof ACTIONS.GET_TICKET_COMMENTS;
  payload: Array<any>;
}
interface SetQualityScoreLoader {
  type: typeof ACTIONS.SET_QUALITYSCORE_LOADING;
  payload: Array<any>;
}
interface SetQualityScoreBackupLoader {
  type: typeof ACTIONS.SET_QUALITYSCOREBACKUP_LOADING;
  payload: Array<any>;
}
interface GetWhatsappTemplate {
  type: typeof ACTIONS.GET_WHATSAPP_TEMPLATE;
  payload: Array<any>;
}
interface BiomarkerReset {
  type: typeof ACTIONS.BIOMARKER_RESET;
  payload: boolean;
}
interface GetBiomarkerScreening {
  type: typeof ACTIONS.GET_BIOMARKER_SCREENING;
  payload: Object;
}
interface GetAdditionalMember {
  type: typeof ACTIONS.GET_ADDITIONAL_MEMBER;
  payload: Array<any>;
}
interface InitiateRefund {
  type: typeof ACTIONS.INITIATE_REFUND;
  payload: Array<any>;
}
interface GetBiomarkerUltasound {
  type: typeof ACTIONS.GET_BIOMARKER_ULTASOUND;
  payload: Object;
}
export type BookingTypes =
  | ChangePassword
  | SearchCall
  | GetPinCode
  | ChangePassword
  | GetCities
  | GetArea
  | GetAgents
  | GetPhlebo
  | GetPackages
  | GetFamilyPackages
  | GetFamilyPackagesBooking
  | CreateFamilyPackageBooking
  | CreateBooking
  | UpdateBooking
  | CreateAdditionalBooking
  | UpdateAdditionalBooking
  | GetRecordings
  | GetPartners
  | GetCoupons
  | LocationVerified
  | GetBookings
  | GetBookingById
  | SetLoading
  | GetLeadDetails
  | GetLeads
  | GetLeadById
  | ResetLeadDetails
  | ResetBooking
  | ClickToCall
  | GetPositiveCallsComments
  | AddPositiveCallsComments
  | SetComments
  | AddComment
  | GetLeadSource
  | GetBookingsComments
  | AddBookingsComments
  | GetWhatsappComments
  | GetWhatsappCommentsByLead
  | SendWhatsappComments
  | GetCallStats
  | RaiseComplaint
  | GetQueryTickets
  | AssignDoctor
  | SendNewFeedback
  | GetAvailabeSlots
  | GetAllLeads
  | GetLatestLeads
  | CreateCall
  | GetSyncData
  | SameNumberBooking
  | GetBreakResponse
  | GetPanelBreak
  | GetPaymentSendLink
  | GetPaymentReSendLink
  | GetLtvLead
  | GetPaymentCancelLink
  | GetSmsDetails
  | GetEmailDetails
  | GetSmsReportSend
  | GetCenterInfo
  | SendSmsLink
  | GetNotificationMessage
  | GetReportStatus
  | GetUnmaskedNumber
  | GetUserDetails
  | GetFireBaseNotification
  | GetLabDetails
  | GetZone
  | GetUsersData
  | GetLeadDispostion
  | GetNotificationType
  | CheckGeoFenceArea
  | CheckGeoFenceAreaMapMyIndia
  | GetMapMyIndiaAccessToken
  | GetTodayMissedCalls
  | GetRepeatedList
  | GetReportDownload
  | GetRepetedLead
  | UpdatedRepeatedLead
  | SendNotification
  | GetTimeSlot
  | CreateImagingBooking
  | CreateWhatsappTemplate
  | GetTopRcFreshLeads
  | GetTopCFreshLeads
  | GetRedCoin
  | GetSubCenterInformation
  | GetImagingTimeSlots
  | GetMultiplepackage
  | GetDisplayCities
  | GetLeadStatus
  | GetPrescriptionData
  | getDoctorInfoById
  | imaginBookingDisposition
  | GetImagingTicket
  | GetUserStats
  | SendInititalWhatsapp
  | GetImagingRaiseTicket
  | GetImagingTicketCount
  | CreateImagingBookingCustom
  | GetImagingTicketCount
  | UpdateImagingTicket
  | GetTicketData
  | GetTicketCategory
  | GetTicketSubCategories
  | CreateTaskTicket
  | GetRedTechComment
  | PostRedTechComment
  | GetCancelHoldBooking
  | GetQualityScoringReport
  | SetLoadingWhatsapp
  | GetQualityScoringReportForBackup
  | GetCeCenterForCc
  | CreatePilotBooking
  | GeoLoading
  | CheckGeoCheckError
  | GetBookingQueryTickets
  | updateActionDisposition
  | UpdateTicketStatus
  | GetTicketComments
  | GetBookingsLoader
  | SetQualityScoreLoader
  | SetQualityScoreBackupLoader
  | SetCsLoading
  | CheckGeoFenceAreaMapMyIndiaForCustomer
  | GeoLoadingForCustomer
  | CheckGeoCheckErrorForCustomer
  | GetWhatsappTemplate
  | BiomarkerReset
  | GetBiomarkerScreening
  | GetAdditionalMember
  | GetBiomarkerUltasound
  | GetNewBookings
  | GetCommunicationOnWhatsapp
  | SendLocationRequest
  | getCrmLeadAddress
  | PostCrmLeadAddress
  | GetPackagesTags
  | GetPackagesCategory
  | GetAddressDetailsLatLong
  | SetLeadAddressLoading
  | SetBookingIdLoader
  | SetBiomarkerLoader
  | GetWhatsappMessageStatus
  | PostDispositionData
  | InitiateRefund