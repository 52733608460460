import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow,withStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../Components/loader';
import { MessageRounded } from '@material-ui/icons';
import { getQueryTickets,getPackage, getCollectionSlot,searchCall } from '../actions/salesLeadAction'
import { useEffect } from 'react';
import RaiseComplaintModal from '../Components/comments/updateLeadModal'
import {
    Container,
   Grid,
    TextField,
} from '@material-ui/core';
import Box from '@mui/material/Box';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
          },
    })
);

const StyledTableCell:any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
    getQueryTickets: any;
    complaints: any;
    searchCall: any;
    getPackage:any;
    getCollectionSlot:any,
    collectionSlot:any;
    packageList:any;
    bookingList:any;
    loading: any
}

const BookingsTable: React.FC<Props> = ({
    getQueryTickets,
    complaints,
    searchCall,
    getPackage,
    getCollectionSlot,
    collectionSlot,
    packageList,
    bookingList,
    loading,
}) => {
    const classes = useStyles()
    const history = useHistory()
    const [raiseComplaint, setRaiseComplaint] = useState(false)
    const [leadId, setLeadId] = useState(0)
    const [type, setType] = useState("")
    const [query, setQuery] = useState("")
    const [status, setStatus] = useState("")
    const [activeTab, setActiveTab] = React.useState<string>("");
    const [ticketId, setTicketId] = useState(0)
    const [ticketDetails,setTicketDetails]=useState<any>("");

    const handleOpenModal = (ticket: any) => {
        setLeadId(ticket.lead.id)
        setType(ticket.querytype)
        setQuery(ticket.query)
        setStatus(ticket.status)
        setRaiseComplaint(true)
        setTicketId(ticket.id)
        setTicketDetails(ticket)
    }

    useEffect(() => {
        getQueryTickets("lead_ticket",activeTab)
        getPackage()
    },[activeTab])

    useEffect(() => {
        if (raiseComplaint === false) {
            getQueryTickets("lead_ticket", activeTab)
        }
    }, [raiseComplaint])

    const [page, setPage] = useState(0)

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = complaints.links && complaints.links.next.split("?")[1];
            getQueryTickets("lead_ticket", activeTab === "" ? url : `${activeTab}&${url}`);
        } else if (newPage < page) {
            let url = complaints.links && complaints.links.previous.split("?")[1];
            getQueryTickets("lead_ticket", activeTab === "" ? url : `${activeTab}&${url}`);

        }
        setPage(newPage as number);
    };

    const viewLeadHandler = (id: any) => {
        let body = {
            search_by: "call_id",
            value: id || 0,
        };
        searchCall(body)
        history.push("/dashboard/sl/lead/search");
    };
    const handleReceivedTicket = (type: any) => {
        setActiveTab(`type=${type}`)
    }
    const handleAllTicket = () => {
        setActiveTab("")
    }
    const handleSendTicket = (type: any) => {
        setActiveTab(`type=${type}`)
    }
    return (
        <main className={classes.content}>
        
            <div className={classes.toolbar} />
            <h2>Lead Tickets</h2>
            <Grid
                container
                spacing={1}
                direction="row"
                style={{ margin: "1rem" }}
            >
              <Grid item xs={12} sm={4} md={3}>
                    <Button
                        variant={activeTab == "" ? "contained" : "outlined"}
                        color="primary"
                        fullWidth
                        onClick={() => handleAllTicket()}
                    >
                        All
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <Button
                        variant={activeTab == "type=received" ? "contained" : "outlined"}
                        color="primary" 
                        fullWidth
                        onClick={() => handleReceivedTicket("received")}
                    >
                        Received Ticket
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <Button
                        variant={activeTab == "type=send" ? "contained" : "outlined"}
                        color="primary"
                        fullWidth
                        onClick={() => handleSendTicket("send")}
                    >
                        Send Ticket
                    </Button>
                </Grid>
            </Grid>
            {/* <Paper elevation={15} className={classes.paper}> */}
            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "560px" }}>
                    {loading ? <Loader /> : (
                       <Table stickyHeader aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Lead Id</StyledTableCell>
                                    <StyledTableCell align="center">Agent</StyledTableCell>
                                    <StyledTableCell align="center">Action</StyledTableCell>
                                    <StyledTableCell align="center">Created At</StyledTableCell>
                                    <StyledTableCell align="center">Query Type</StyledTableCell>
                                    <StyledTableCell align="center">Query Description</StyledTableCell>
                                    <StyledTableCell align="center">Status</StyledTableCell>
                                    <StyledTableCell align="center">View</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    complaints.results && complaints.results.length > 0 && complaints.results.map((ticket: any, index: any) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="center">{ticket.lead && ticket.lead.id}</StyledTableCell>
                                                <StyledTableCell align="center">{ticket.panel_user && ticket.panel_user.name}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Button
                                                        color="primary"
                                                        variant="text"
                                                    >
                                                        <MessageRounded onClick={() => handleOpenModal(ticket)} />
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">{new Date(ticket.created_at).toLocaleString()}</StyledTableCell>
                                                <StyledTableCell align="center">{ticket.querytype}</StyledTableCell>
                                                <StyledTableCell align="center">{ticket.query}</StyledTableCell>
                                                <StyledTableCell align="center">{ticket.status}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Button
                                                        color="secondary"
                                                        variant="contained"
                                                        onClick={() => viewLeadHandler(ticket.lead.id)}
                                                    >
                                                        View
                                                    </Button>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })
                                }
                            </TableBody>
                            <TableFooter>
                                <StyledTableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[]}
                                        colSpan={3}
                                        count={complaints.count || 0}
                                        rowsPerPage={complaints.page_size}
                                        page={page}
                                        onPageChange={handleChangePage}
                                    />
                                </StyledTableRow>
                            </TableFooter>
                        </Table>
                    )}
                </TableContainer>
         
            <RaiseComplaintModal 
                open={raiseComplaint} 
                setOpen={setRaiseComplaint} 
                ticketId={ticketId} 
                queryType={type} 
                queryDesc={query} 
                queryStatus={status} 
                bookingId={0} 
                leadId={Number(leadId)} 
                ticketDetails={ticketDetails} 
                bookingList={bookingList}  
                packageList={packageList} 
                getPackage={getPackage} 
                getCollectionSlot={getCollectionSlot}
                collectionSlot={collectionSlot}
                />
        </main>
    )
}

const mapStateToProps = (state: any) => ({
    loading: state.SalesLeadReducer.loading,
    bookingList:state.SalesLeadReducer.bookingList,
    packageList: state.SalesLeadReducer.packageList,
    collectionSlot: state.SalesLeadReducer.collectionSlot,
    complaints: state.SalesLeadReducer.complaints
})

export default connect(mapStateToProps, {
    getQueryTickets,
    searchCall,
    getPackage,
    getCollectionSlot,
})(BookingsTable);

