import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Container,
   Grid,
    TextField,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Paper, Table, TableBody, TableCell, MenuItem, Select, TableContainer, TableFooter, TableHead, TablePagination, TableRow } from '@material-ui/core';
import { generateBookingFilterUrl } from "../../helpers/generateUrl";
import { connect } from "react-redux";
import {
    getAgentList,
    getLeadSource,
    getPartners,
    getAllBookingsWithTatTime,
    getCities,
    getPhlebos,
    getAllLeads,
    leadTransferByComma,
    getAditionalBookingWithTatTime
} from '../actions/salesLeadAction'
import './filter.sass'
import { useHistory } from "react-router";
import Loader from "../Components/loader/index";
import TatExport from '../Components/Export/tatExporttable'
import  {BOOKING_STATUS} from '../../booking_status'
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        card: {
            height: "100%",
            padding: theme.spacing(1),
        },
        card_grid: {
            // wordSpacing:3,
            justifyContent: 'space-between'
        },
    })
);

interface Props {
    children: any;
    agentsList: any;
    bookingListWithTatTime: any;
    partnerList: any;
    getAgentList: any;
    getPartners: any;
    getAllBookingsWithTatTime: any;
    leadTransferByComma: any;
    agentBYUserGroup: any;
    getCities: any;
    cities: any;
    getPhlebos: any;
    getAllLeads: any;
    phleboList: any;
    getLeadSource: any;
    lead_source: any;
    loading: Boolean;
    getPaymentInfo: any;
    paymentInformation: any;
    getAditionalBookingWithTatTime: any;
    additionalBookingListWithTatTime: any;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
    agentsList,
    partnerList,
    getAgentList,
    getPartners,
    getAllBookingsWithTatTime,
    leadTransferByComma,
    agentBYUserGroup,
    getAllLeads,
    bookingListWithTatTime,
    getCities,
    cities,
    getPhlebos,
    phleboList,
    getLeadSource,
    lead_source,
    getPaymentInfo,
    paymentInformation,
    getAditionalBookingWithTatTime,
    additionalBookingListWithTatTime,
    loading
}) => {
    const classes = useStyles();

    const history = useHistory()

    const [openModal, setopenModal] = useState<boolean>(false);

    const [status, setStatus] = useState<any>([]);
    const [source, setSource] = useState<any>([]);
    const [partner, setPartner] = useState<any>([]);
    const [agent, setAgent] = useState<any>([]);
    const [city, setCity] = useState<any>([]);
    const [start_date, setStart_date] = useState("")
    const [due_date, setDueDate] = useState("")
    const [due_start_date, setDueStartDate] = useState("")
    const [due_end_date, setDueEndDate] = useState("")
    const [end_date, setEnd_date] = useState("")
    const [booking_date, setBooking_date] = useState("")
    const [collection_date, setCollection_date] = useState("")
    const [verificationStatus, setVerificationStatus] = useState<any>([])
    const [name, setName] = useState("")
    const [phlebo, setPhlebo] = useState<any>([])
    const [phone, setPhone] = useState("")
    const [bookingId, setBookingid] = React.useState<any>("")
    const [userGroup, setUserGroup] = useState("none")
    const [page, setPage] = useState(0)
    const [sample_registered, setSampleRegisterd] = useState<any>("none")
    const [report_status, setReportStatus] = useState<any>("none")
    const [selectedLeads, setSelectedLeads] = useState<any>([]);
    const [panel_user, setPanelUser] = React.useState<number | null>(null);
    const [paymentId, setPaymentId] = useState<any>(0)
    const [openPaymentModal, setOpenPaymentModal] = useState<any>(false)
    const [defaultStatus, setDefaultStatus] = useState<any>([]);
    const [additionalaMemberId, setAdditionalMemberId] = useState<any>("")
    const [additionalModal, setAdditonalModal] = useState<boolean>(false)
    const [filtered, setFiltered] = useState(false)
    const timer = useRef<any>(0)

    useEffect(() => {
        if (history.action === "POP") {
            let ceBookingFilter: any;
            ceBookingFilter = localStorage.getItem("CEBookingFilter");
            if (ceBookingFilter) {
                ceBookingFilter = JSON.parse(ceBookingFilter);

                const statusD = ceBookingFilter?.status?.split(",")
                let statusTemp = new Array()
                BOOKING_STATUS.map((item: any) => {
                    statusD.map((itm: any) => {
                        if (item.value == itm) {
                            statusTemp.push(item);
                        }
                    });
                });
                setDefaultStatus(statusTemp);
                ceBookingFilter?.status &&
                    setStatus(ceBookingFilter?.status?.split(","));
                ceBookingFilter?.source &&
                    setSource(ceBookingFilter?.source?.split(","));
                ceBookingFilter?.partner &&
                    setPartner(ceBookingFilter?.partner?.split(","));
                ceBookingFilter?.city && setCity(ceBookingFilter?.city?.split(","));
                ceBookingFilter?.start_date &&
                    setStart_date(ceBookingFilter?.start_date);
                setCollection_date(ceBookingFilter?.collection_date);
                ceBookingFilter?.verificationStatus &&
                    setVerificationStatus(
                        ceBookingFilter?.verificationStatus?.split(",")
                    );
                setName(ceBookingFilter?.name);
                setPhlebo(ceBookingFilter?.phlebo);

                setPhone(ceBookingFilter?.phone);
                setSampleRegisterd(ceBookingFilter?.sample_registered);
                setUserGroup(ceBookingFilter?.userGroup);
                setReportStatus(ceBookingFilter?.report_status);
                setBookingid(ceBookingFilter?.bookingId);
                const url = generateBookingFilterUrl(ceBookingFilter).substring(2);
                const pageNo = page ? page : 1;
                getAllBookingsWithTatTime(`tat=true&pickup_status=confirmed&${url}&page=${pageNo}`);
            }
        } else {
            let url = ``;
            getAgentList()
            getAgentList("", "CustomerExecutive");
            getPartners()
            getAllBookingsWithTatTime("tat=true&pickup_status=confirmed")
            // getCities()
            getLeadSource()
            localStorage.removeItem("CEBookingFilter");
            setDefaultStatus([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setDefaultStatus])

    useEffect(() => {
        if (additionalaMemberId !== "") {
            getAditionalBookingWithTatTime(additionalaMemberId)
        }

    }, [additionalaMemberId])

    const onKeyDown = (e: any) => {
        if (e.key === "Enter") {
            filterBookings(e);
        }
    };

    const filterBookings = (e: any) => {
        const body: any = {
            status: status ? status.join(",") : "",
            verificationStatus: verificationStatus
                ? verificationStatus.join(",")
                : "",
            source: source ? source.join(",") : "",
            agent: agent ? agent.join(",") : "",
            city: city ? city.join(",") : "",
            start_date,
            end_date,
            booking_date,
            bookingId,
            collection_date,
            due_date,
            due_start_date,
            due_end_date,
            name,
            phlebo: phlebo ? phlebo.join(",") : "",
            partner: partner ? partner.join(",") : "",
            user_group: userGroup,
            phone,
            sample_registered,
            report_status,
        }
        const url = generateBookingFilterUrl(body).substring(2)
        localStorage.setItem("CEBookingFilter", JSON.stringify(body));
        getAllBookingsWithTatTime(`tat=true&pickup_status=confirmed&${url}&download=true&page=1`)
        setPage(0)
        setFiltered(true)
    }
    useEffect(() => {
        if (paymentId != 0) {
            getPaymentInfo(`?booking=${paymentId}`);
        }

    }, [paymentId])
    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid container spacing={3} direction="row" alignItems="center" justify="center">
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="booking"
                            type="number"
                            placeholder="Booking Id"
                            value={bookingId}
                            onKeyDown={onKeyDown}
                            variant="outlined"
                            onChange={(e) => setBookingid(e.target.value)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="name"
                            type="text"
                            label="Name"
                            value={name}
                            variant="outlined"
                            onKeyDown={onKeyDown}
                            onChange={(e) => setName(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="phone"
                            type="text"
                            label="Phone"
                            value={phone}
                            variant="outlined"
                            onKeyDown={onKeyDown}
                            onChange={(e) => setPhone(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                            id="city"
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id);
                                }
                                setCity(sourceIds);
                            }}
                            options={cities}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            onKeyDown={onKeyDown}
                            getOptionLabel={(option: any) => option.name}
                            getOptionDisabled={(option: any) => {
                                return city.includes(option.id);
                            }}
                            disableClearable
                            disableCloseOnSelect
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    getCities(newInputValue);
                                }, 1000);
                                if (newInputValue.length === 0) {
                                    setCity([]);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    placeholder="City"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                            id="status"
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.value);
                                }
                                setStatus(sourceIds);
                            }}
                            options={BOOKING_STATUS || []}
                            blurOnSelect
                            aria-required
                            onKeyDown={onKeyDown}
                            limitTags={1}
                            multiple
                            getOptionLabel={(option: any) => option.text}
                            getOptionDisabled={(option: any) => {
                                return status.includes(option.value);
                            }}
                            disableClearable
                            disableCloseOnSelect
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    placeholder="Booking Status"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                            defaultValue={defaultStatus}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                            id="partner"
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id);
                                }
                                setPartner(sourceIds);
                            }}
                            freeSolo
                            blurOnSelect
                            limitTags={1}
                            multiple
                            options={partnerList.results || []}
                            onKeyDown={onKeyDown}
                            getOptionDisabled={(option: any) => {
                                return partner.includes(option.id);
                            }}
                            disableClearable
                            disableCloseOnSelect
                            getOptionLabel={(option: any) => option.user && option.user.name}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    getPartners(newInputValue);
                                }, 1000);
                                if (newInputValue.length === 0) {
                                    setPartner([]);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    placeholder="Partner"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id);
                                }
                                setSource(sourceIds);
                            }}
                            freeSolo
                            multiple
                            id="checkboxes-tags-demo"
                            options={lead_source || []}
                            disableCloseOnSelect
                            limitTags={1}
                            disableClearable
                            onKeyDown={onKeyDown}
                            getOptionLabel={(option: any) => option.name}
                            getOptionDisabled={(option: any) => {
                                return source.includes(option.id);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    placeholder="Source"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                            id="agent"
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setAgent(sourceIds)
                            }}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            options={agentsList.results || []}
                            getOptionDisabled={(option: any) => {
                                return agent.includes(option.id)
                            }}
                            disableClearable
                            disableCloseOnSelect
                            getOptionLabel={(option: any) => option.user && option.user.username}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                                    getAgentList(`?code=${newInputValue.replace(" ", "")}`)
                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setPartner([])
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Agent"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                            id="phlebo"
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id);
                                }
                                setPhlebo(sourceIds);
                            }}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            options={
                                phleboList.results
                                    ? [
                                        ...phleboList.results,
                                        { id: 0, user: { username: "NoPhlebo" } },
                                    ]
                                    : []
                            }
                            getOptionDisabled={(option: any) => {
                                return phlebo.includes(option.id);
                            }}
                            disableClearable
                            disableCloseOnSelect
                            onKeyDown={onKeyDown}
                            getOptionLabel={(option: any) => option.user && option.user.username}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    getPhlebos(newInputValue);
                                }, 1000);
                                if (newInputValue.length === 0) {
                                    setPhlebo([]);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    placeholder="Phlebo Name"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Autocomplete
                            id="city"
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.value);
                                }
                                setVerificationStatus(sourceIds);
                            }}
                            options={[
                                {
                                    text: "Pending",
                                    value: "pending",
                                },
                                {
                                    text: "Confirmed",
                                    value: "confirmed",
                                },
                                {
                                    text: "Cancelled",
                                    value: "cancelled",
                                },
                            ]}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            onKeyDown={onKeyDown}
                            multiple
                            getOptionLabel={(option: any) => option.text}
                            getOptionDisabled={(option: any) => {
                                return verificationStatus.includes(option.value);
                            }}
                            disableClearable
                            disableCloseOnSelect
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    placeholder="Verification Status"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Select className="input"
                            name="usergroup"
                            variant="outlined"
                            value={userGroup}

                            style={{ width: "100%" }}
                            onChange={(e) => setUserGroup(e.target.value as string)}
                        >
                            <MenuItem disabled value={"none"}>User Type</MenuItem>
                            <MenuItem value={"SupportExecutive"}>SupportExecutive</MenuItem>
                            <MenuItem value={"SampleAccessioning"}>SampleAccessioning</MenuItem>
                            <MenuItem value={"CustomerExecutive"}>CustomerExecutive</MenuItem>
                            <MenuItem value={"VerificationExecutive"}>VerificationExecutive</MenuItem>
                            <MenuItem value={"PhleboAdmin"}>PhleboAdmin</MenuItem>
                            <MenuItem value={"Phlebos"}>Phlebos</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="start_date"
                            type="date"
                            label="Start Date"
                            value={booking_date === "" || collection_date === "" ? start_date : ""}
                            variant="outlined"
                            disabled={booking_date !== "" || collection_date !== ""}
                            onChange={(e) => setStart_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            name="end_date"
                            type="date"
                            value={booking_date === "" || collection_date === "" ? end_date : ""}
                            className="input"
                            label="End Date"
                            variant="outlined"
                            disabled={booking_date !== "" || collection_date !== ""}
                            onChange={(e) => setEnd_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            name="booking_date"
                            type="date"
                            value={start_date === "" || end_date === "" ? booking_date : ""}
                            className="input"
                            label="Booking Date"
                            variant="outlined"
                            disabled={start_date !== "" || end_date !== ""}
                            onChange={(e) => setBooking_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            name="collection_date"
                            type="date"
                            value={start_date === "" || end_date === "" ? collection_date : ""}
                            className="input"
                            label="Collection Date"
                            variant="outlined"
                            disabled={start_date !== "" || end_date !== ""}
                            onChange={(e) => setCollection_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>

                        <Select className="input"
                            name="sampleregisterd"
                            variant="outlined"
                            value={sample_registered}
                            style={{ width: "100%", margin: "0" }}

                            onChange={(e) => setSampleRegisterd(e.target.value as String)}

                        >
                            <MenuItem disabled value={"none"}>Sample Type</MenuItem>
                            <MenuItem value={"true"}>Registerd</MenuItem>
                            <MenuItem value={"false"}>Not Registerd</MenuItem>

                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>

                        <Select className="input"
                            name="sampleregisterd"
                            variant="outlined"
                            value={report_status}
                            style={{ width: "100%", margin: "0" }}

                            onChange={(e) => setReportStatus(e.target.value as String)}

                        >
                            <MenuItem disabled value={"none"}>Report Status</MenuItem>
                            <MenuItem value={"na"}>None</MenuItem>
                            <MenuItem value={"pending"}>Pending</MenuItem>
                            <MenuItem value={"partial"}>Partial</MenuItem>
                            <MenuItem value={"consolidate"}>Consolidate</MenuItem>

                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="due_date"
                            type="date"
                            label="Due Date"
                            value={due_date}
                            variant="outlined"
                            onChange={(e) => setDueDate(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="due_start_date"
                            type="date"
                            label="Due Start Date"
                            value={due_start_date}
                            variant="outlined"
                            onChange={(e) => setDueStartDate(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <TextField
                            className="input"
                            name="due_end_date"
                            type="date"
                            label="Due End Date"
                            value={due_end_date}
                            variant="outlined"
                            onChange={(e) => setDueEndDate(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Button
                            variant="contained"
                            color="secondary"
                           
                            fullWidth
                            onClick={filterBookings}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Button
                            variant="contained"
                            color="primary"
                    
                            fullWidth
                            onClick={() => {
                                setFiltered(false)
                                history.push("/dashboard/sl/export_tat_booking")
                            }}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <br />
                {loading ? <Loader /> : filtered && bookingListWithTatTime.results && bookingListWithTatTime.results.length > 0 && (
                    <Box>
                        <Container maxWidth={false}>
                            <Grid container spacing={3}>
                                <TatExport loading={loading} bookingListWithTatTime={bookingListWithTatTime} />
                            </Grid>
                        </Container>
                    </Box>
                )}
                {bookingListWithTatTime.results && bookingListWithTatTime.results.length === 0 && (
                    <h6 style={{ textAlign: "center" }}>No Data Found</h6>
                )}

            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    agentsList: state.SalesLeadReducer.agentsList,
    partnerList: state.SalesLeadReducer.partnerList,
    bookingListWithTatTime: state.SalesLeadReducer.bookingListWithTatTime,
    cities: state.SalesLeadReducer.cities,
    phleboList: state.SalesLeadReducer.phleboList,
    loading: state.SalesLeadReducer.loading,
    lead_source: state.SalesLeadReducer.lead_source,
    agentBYUserGroup: state.SalesLeadReducer.agentBYUserGroup,
    additionalBookingListWithTatTime: state.SalesLeadReducer.additionalBookingListWithTatTime,
})

export default connect(mapStateToProps, {
    getAgentList,
    getPartners,
    getCities,
    getPhlebos,
    getLeadSource,
    getAllLeads,
    leadTransferByComma,
    getAllBookingsWithTatTime,
    getAditionalBookingWithTatTime,
})(CustomerLeadFilterPage);
