import React, { useEffect } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { connect } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import Stack from "@mui/material/Stack";
import {
  Paper,
  Table,
  MenuItem,
  TableBody,
  Select,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from "@material-ui/core";
import {
  getTicketComments,
  addBookingsComment,
  getTicketCommentsForDocument,
} from "../../actions/salesLeadAdminAction";
import "./index.sass";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      //   width: "100%",
      //   marginTop: "20px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
      //   padding: theme.spacing(3),
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    imageSM: {
      width: "60px",
      height: "60px",
      border: "2px solid #000 ",
      borderRadius: "50%",
    },
    imagePaper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[1],
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: isMobile ? "400px" : "1200px",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
  };
}

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

type ModalProps = {
  openModal: boolean;
  setopenModal: Function;
  bookingId: any;
  loading: boolean;
  user: any;
  ticketComments: any;
  getTicketComments: any;
  addBookingsComment: any;
  ticketCommentsForDocuments: any;
  getTicketCommentsForDocument: any;
  ticketDisposition: any;
};

const CommentsModal: React.FC<ModalProps> = ({
  openModal,
  setopenModal,
  bookingId,
  loading,
  ticketComments,
  getTicketComments,
  addBookingsComment,
  user,
  ticketCommentsForDocuments,
  getTicketCommentsForDocument,
  ticketDisposition,
}) => {
  const classes = useStyles();

  const [comment, setComment] = React.useState<string>("");
  const [maxHeight, setMaxHeight] = React.useState<any>(300);
  const [openImage, setImageOpen] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState("");
  const [modalStyle] = React.useState(getModalStyle);

  const handleClose = () => {
    setopenModal(false);
  };

  useEffect(() => {
    if (bookingId !== "" && openModal) {
      getTicketComments(`${bookingId}/`);
      if (ticketDisposition ==="document_required" || ticketDisposition ==="refund_query") {
        getTicketCommentsForDocument(`${bookingId}/`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const handleStartModal = (e: any) => {
    setImageUrl(e);
    setImageOpen(true);
  };

  const body = (
    <div style={modalStyle} className={classes.imagePaper}>
        <img src={imageUrl} alt="popup" style={{ width: "750px", height: "670px" }} />
        {/* <SimpleModal /> */}
    </div>
);

  const handleImageClose = () => {
    setImageOpen(false);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <h6
              style={{
                marginBottom: "10px",
                textAlign: "center",
                borderBottom: "1px solid rgba(0,0,0,0.5)",
              }}
              id="transition-modal-title"
            >
              Comment History
              {Number(maxHeight) === Number(300) ? (
                <Stack
                  direction="row"
                  spacing={2}
                  style={{ display: "block", textAlign: "end" }}
                >
                  <ZoomOutMapIcon
                    fontSize="large"
                    onClick={() =>
                      Number(maxHeight) === Number(600)
                        ? setMaxHeight(Number(600))
                        : setMaxHeight(Number(600))
                    }
                  />
                </Stack>
              ) : (
                <Stack
                  direction="row"
                  spacing={2}
                  style={{ display: "block", textAlign: "end" }}
                >
                  <ZoomInMapIcon
                    fontSize="large"
                    onClick={() =>
                      Number(maxHeight) === Number(300)
                        ? setMaxHeight(Number(300))
                        : setMaxHeight(Number(300))
                    }
                  />
                </Stack>
              )}
            </h6>
            <div
              id="transition-modal-description"
              className="modal-form-wrapper"
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: `${maxHeight}px`,
              }}
            >
              <div
                className="comment-history"
                style={{ height: `${maxHeight}px` }}
              >
                {loading
                  ? "Please Wait"
                  : Object.keys(ticketComments).length > 0
                  ? ticketComments.map((comment: any) => (
                      <p>
                        {comment.created_at.substring(0, 10)} :{" "}
                        {comment.created_at.substring(11, 19)} :{" "}
                        {comment.comments} : {comment.disposition}:{" "}
                      </p>
                    ))
                  : ""}
                  { ticketCommentsForDocuments?.length > 0 ?
                    <TableContainer
                      className={classes.tableContainer}
                      component={Paper}
                      style={{ maxHeight: "540px" }}
                    >
                      <Table stickyHeader aria-label="simple table">
                        <TableHead>
                          <StyledTableRow>
                              <StyledTableCell align="center">Ticket Id</StyledTableCell>
                              { ticketDisposition ==="refund_query" ? <StyledTableCell align="center">Amount</StyledTableCell> : ""}
                              { ticketDisposition ==="refund_query" ? <StyledTableCell align="center">Receipt Number</StyledTableCell> : ""}
                              { ticketDisposition ==="refund_query" ? <StyledTableCell align="center">Mode Of Payment</StyledTableCell> : ""}
                              <StyledTableCell align="center">Document Type</StyledTableCell>
                              <StyledTableCell align="center">Document Image</StyledTableCell>
                              <StyledTableCell align="center">Created At</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          {ticketCommentsForDocuments &&
                            ticketCommentsForDocuments?.length > 0 &&
                            ticketCommentsForDocuments?.map((ticket: any, index: any) => {
                              return (
                                <StyledTableRow key={index}>
                                  <StyledTableCell align="center">
                                      {ticket?.ticket_info}
                                    </StyledTableCell>
                                    { ticketDisposition ==="refund_query" ?
                                    <StyledTableCell align="center">
                                      {ticket?.amount}
                                    </StyledTableCell> : ""}
                                    { ticketDisposition ==="refund_query" ?
                                    <StyledTableCell align="center">
                                      {ticket?.receipt_number===null ? "" : ticket?.receipt_number}
                                    </StyledTableCell> : ""}
                                    { ticketDisposition ==="refund_query" ?
                                    <StyledTableCell align="center">
                                      {ticket?.mode_of_payment_name===null ? "" : ticket?.mode_of_payment_name}
                                    </StyledTableCell> : ""}
                                    <StyledTableCell align="center">
                                      {ticket?.document_type_name===null ? "" : ticket?.document_type_name}
                                    </StyledTableCell>
                                    <StyledTableCell 
                                      align="center"
                                      onClick={() =>
                                        handleStartModal(
                                          ticket?.document
                                        )
                                      }>
                                        <img
                                          key={ticket?.document}
                                          src={ticket?.document}
                                          alt="startimage"
                                          className={classes.imageSM}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                      {new Date(ticket?.created_at).toLocaleString()}
                                    </StyledTableCell>
                                  </StyledTableRow>
                              );
                            })}
                        </TableBody>
                        {/* <TableFooter>
                          <StyledTableRow>
                            <TablePagination
                              rowsPerPageOptions={[]}
                              colSpan={3}
                              count={bookingComplaints.count || 0}
                              rowsPerPage={bookingComplaints.page_size}
                              page={page}
                              onPageChange={handleChangePage}
                            />
                          </StyledTableRow>
                        </TableFooter> */}
                      </Table>
                    </TableContainer>
                    : ""
                  }
              </div>
              <Modal
                    open={openImage}
                    onClose={handleImageClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    {body}
                </Modal>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  ticketComments: state.SalesLeadAdminReducer.ticketComments,
  loading: state.SalesLeadAdminReducer.loading,
  ticketCommentsForDocuments: state.SalesLeadAdminReducer.ticketCommentsForDocuments,
  user: state.loginReducer.user,
});

export default connect(mapStateToProps, {
  getTicketComments,
  addBookingsComment,
  getTicketCommentsForDocument,
})(CommentsModal);
