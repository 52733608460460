import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Container,
   Grid,
    TextField,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { generateTransferdTicketFilterUrl } from "../../helpers/generateUrl";
import { connect } from "react-redux";
import { getTransferBooking,updateTrasferTicketStatus,getAgentListHealthAdvisor} from '../actions/HealthAdviserAdminAction'
import './filter.sass'
import { useHistory } from "react-router";
import TransferBookingsTable from "../tables/TransferbookingsTable";
import Loader from "../components/loader";
import BookingCommentsModal from '../components/Comments/bookingComments'
import TicketStatusModal from '../components/Comments/ticketStatusModal'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        card: {
            height: "100%",
            padding: theme.spacing(1),
        },
        card_grid: {
            // wordSpacing:3,
            justifyContent: 'space-between'
        },
    })
);

interface Props {
    children: any;
    transferBookingData: any;
    getTransferBooking: any;
    loading: Boolean;
    updateTrasferTicketStatus:any;
    getAgentListHealthAdvisor:any;
    healthAdvisorAgent:any;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
    getTransferBooking,
    transferBookingData,
    updateTrasferTicketStatus,
    getAgentListHealthAdvisor,
    healthAdvisorAgent,
    loading
}) => {
    const classes = useStyles();

    const history = useHistory()
    const [openModal, setopenModal] = useState<boolean>(false);
    const [bookingId, setBookingId] = React.useState<any>("")
    const [page, setPage] = useState(0)
    const [ticketStatusType, setTicketStatusType] = useState<any>("re_transferred")
    const [ticketStatus, setTicketStatus] = useState<any>("")
    const [remarks, setRemarks] = useState<any>("")
    const [ticketId,setTicketId]=useState<any>("")
    const [panel_user, setPanelUser] = React.useState<number | null>(null);
    const [agentKey,setAgentkey]=useState(0)
    const timer = useRef<any>(0)

    useEffect(() => {
        getTransferBooking(`?ticket_status=${ticketStatusType}`)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketStatusType])
    useEffect(() => {
        getTransferBooking(`?ticket_status=${ticketStatusType}`)
        getAgentListHealthAdvisor("?usergroup=HealthAdviser");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const filterBookings = () => {
        const body: any = {
            booking:bookingId,
            agent:panel_user,
        }

        const url = generateTransferdTicketFilterUrl(body).substring(2)
        getTransferBooking(`?${url}&ticket_status=${ticketStatusType}`)
        setPage(0)
    }

    const handleTicketStatus = (type: any) => {
        setTicketStatusType(type)
    }
    const handleResetButton=()=>{
        setBookingId("")
        setPanelUser(null)
        setAgentkey(agentKey+1)
        getAgentListHealthAdvisor("?usergroup=HealthAdviser");
        getTransferBooking(`?ticket_status=${ticketStatusType}`)
    }
    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <h2>Transfered Booking</h2>
                <Grid container spacing={1} direction="row" style={{ alignItems: "left" }}>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="booking"
                            type="number"
                            label="Booking Id"
                            value={bookingId}
                            variant="outlined"
                            onChange={(e) => setBookingId(e.target.value)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                <Autocomplete
                  id="agentname"
                  key={agentKey}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setPanelUser(obj.id);
                    }
                  }}
                  options={healthAdvisorAgent.results || []}
                  freeSolo
                  blurOnSelect
                  aria-required
                  getOptionLabel={(option: any) =>
                    option.user && option.user.username
                  }
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                        getAgentListHealthAdvisor(
                        `?code=${newInputValue.replace(" ", "")}&usergroup=HealthAdviser`
                      );
                    }, 1000);
                    if (newInputValue.length === 0) {
                      setPanelUser(0);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      label="Agent username"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                      required
                    />
                  )}
                />
              </Grid>
               
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                           
                            fullWidth
                            onClick={filterBookings}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                           
                            fullWidth
                            onClick={() => handleResetButton()}
                        >
                            Reset
                        </Button>
                </Grid>
                </Grid>
                <Grid container spacing={3} direction="row" style={{ alignItems: "left",marginTop:"1rem",padding: "0.5rem 1rem", }}>
                    <Grid item xs={12} sm={4} md={4}>
                        <Button
                            variant="contained"
                            style={{ background: "#ff9800" }}
                            fullWidth
                            onClick={() => handleTicketStatus("re_transferred")}
                        >
                            Re Transfred ticket
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <Button
                            variant="contained"
                            fullWidth
                            style={{ background: "#cddc39" }}

                            onClick={() => handleTicketStatus("pending")}
                        >
                            Pending Ticket
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4}>
                        <Button
                            variant="contained"
                            style={{ background: "#4caf50" }}
                            fullWidth
                            onClick={() => handleTicketStatus("confirmed")}
                        >
                            Confirmed  ticket
                        </Button>
                    </Grid>
                </Grid>

                <br />
                {loading ? <Loader /> : transferBookingData.results && transferBookingData.results.length > 0 && (
                    <Box>
                        <Container maxWidth={false}>
                            <Grid container spacing={3}>
                                <TransferBookingsTable page={page} setPage={setPage} loading={loading} getTransferBooking={getTransferBooking} transferBookingData={transferBookingData} setTicketId={setTicketId} setopenModal={setopenModal} setTicketStatus={setTicketStatus} setRemarks={setRemarks} />
                            </Grid>
                        </Container>
                    </Box>
                )}
                {transferBookingData.results && transferBookingData.results.length === 0 && (
                    <h6 style={{ textAlign: "center" }}>No Data Found</h6>
                )}
            </main>
            <TicketStatusModal openModal={openModal} setopenModal={setopenModal} updateTrasferTicketStatus={updateTrasferTicketStatus} ticketId={ticketId} ticketStatus={ticketStatus} setTicketStatus={setTicketStatus} remarks={remarks} setRemarks={setRemarks} getTransferBooking={getTransferBooking} ticketStatusType={ticketStatusType}/>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    transferBookingData: state.HealthAdviserAdminReducer.transferBookingData,
    loading: state.HealthAdviserAdminReducer.loading,
    healthAdvisorAgent: state.HealthAdviserAdminReducer.healthAdvisorAgent,
})

export default connect(mapStateToProps, {
    getTransferBooking,
    updateTrasferTicketStatus,
    getAgentListHealthAdvisor
})(CustomerLeadFilterPage);
