import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
    withStyles,
    Theme,
    createStyles,
    makeStyles,
} from "@material-ui/core/styles";
import { Switch, TextField, Select, MenuItem, Button, Grid, Chip } from '@material-ui/core'
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {
    getUserList,
    getLeadSource,
    updateUserList,
    getPanelComment,
    getPanelUserList,
    getActiveDeactivePanel,
    getAgentList,
    getAgentData,
    getAssignAgentList,
    updateUserStatus
} from "../../actions/HealthAdviserAdminAction";
import { TableFooter, TablePagination } from "@material-ui/core";
import Loader from "../loader";
import EditUser from '../SubAdmin/editUser'
import { useHistory } from 'react-router';
import { MessageRounded } from '@material-ui/icons';
import PanelUserComment from '../Comments/panelComments'
import { genratePanelUserFiltr } from '../../../helpers/generateUrl'
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 12,fontWeight:600
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        table: {
            minWidth: 700,
        },
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 18,
            //   padding: theme.spacing(3),
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);

const DefaultSwitch: any = withStyles({
    switchBase: {
        color: "red",
        '&$checked': {
            color: "green",
        },
        '&$checked + $track': {
            backgroundColor: "green",
        },
    },
    checked: {},
    track: {
        backgroundColor: "red"
    },
})(Switch);

interface Props {
    getLeadSource: any;
    getPanelUserList: any;
    lead_source: any;
    updateUserList: any;
    getUserList: any;
    users: any;
    getPanelComment: any;
    panelUserList: any;
    panelComments: any;
    getActiveDeactivePanel: any;
    updateUserStatus: any;
    getAgentList: any;
    agentBYUserGroup: any
    loading: boolean;
    getAgentData: any;
    assigneUserList: any;
    getAssignAgentList: any;
    agentdata: any;
}

const LeadsTable: React.FC<Props> = ({
    getLeadSource,
    lead_source,
    updateUserList,
    getUserList,
    users,
    getPanelComment,
    panelComments,
    getPanelUserList,
    panelUserList,
    getActiveDeactivePanel,
    updateUserStatus,
    getAgentList,
    agentBYUserGroup,
    getAssignAgentList,
    assigneUserList,
    getAgentData,
    agentdata,
    loading,
}) => {
    const classes = useStyles();

    const [page, setPage] = useState(0);
    const [userGroup, setUserGroup] = useState("none")
    const [userStatus, setUserStatus] = useState("none")
    const [breakFilter, setBreakFilter] = useState("none")
    const [onCallFilter, setOnCallFilter] = useState("none")
    const [inboundModeFilter, setInbountModeFilter] = useState("none")
    const [imagingLeadFilter, setImagingLeadFilter] = useState("none")
    const [homedxLeadFilter, setHomedxLeadFilter] = useState("none")
    const [callFrominBulk, setCallFrominBulk] = useState("none")
    const [panelUser, setPanelUser] = useState("none")
    const [activeDeactiveTeam, setActiveDeactiveTeam] = useState("")
    const [changeStatus, setChangeStatus] = useState("")
    const [statusButton, setStatusButton] = useState("none")
    const [fbAdButton, setFbAdButton] = useState("none")
    const [googleAdButton, setGoogleAdButton] = useState("none")
    const [jdAdButton, setJdAdButton] = useState("none")
    const [isInbondButton, setIsInbondButton] = useState("none")
    const [defaultCallButton, setDefaultCallButton] = useState("none")
    const [defaultAdButton, setDefaultAdButton] = useState("none")
    const [fbCallButton, setFbCallButton] = useState("none")
    const [isWhatsappButton, setIsWhatsappButton] = useState("none")
    const [googleCallButton, setGoogleCallButton] = useState("none")
    const [updateInboundMode, setUpdateInboundMode] = useState("none")
    const [rcFreshButton, setRcFreshButton] = useState("none")
    const [smsCallButton, setSmsCallButton] = useState("none")
    const [smsAdButton, setSmsAdButton] = useState("none")
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const history = useHistory()

    const [callFrom, setCallFrom] = useState<string>("none")
    const [userName, setUserName] = useState<any>([])
    const [userId, setUserId] = useState<any>([])
    const [isActive, setIsActive] = useState(false)
    const [isImagingLead, setIsImagingLead] = useState(false)
    const [isHomeDxLead, setIsHomedxLead] = useState(false)
    const [isGoogleAd, setIsGoogleAd] = useState(false)
    const [isGoogleCall, setIsGoogleCall] = useState(false)
    const [isJdAd, setIsJdAd] = useState(false)
    const [is_inbound, setIsInBond] = useState(false)
    const [is_outbound, setIsOutBond] = useState(false)
    const [is_default_call, setIsDefaultCall] = useState(false)
    const [is_default_ad, setIsDefaultAd] = useState(false)
    const [is_rcfresh, setIs_rcfresh] = useState(false)
    const [isSMSCall, setIsSMSCall] = useState(false)
    const [isSMSAd, setIsSMSAd] = useState(false)
    const [isWhatsapp, setIsWhatsapp] = useState(false)
    const [editUser, setEditUser] = useState<any>({})
    const [isFbAd, setIsFbAd] = useState(false)
    const [isFbCall, setIsFbCall] = useState(false)
    const [editUserDetails, setEditUserDetails] = useState<any>({})
    const [openUserModal, setOpenUserModal] = useState(false)
    const [openModal, setopenModal] = useState(false)
    const [user_name, setUser_name] = useState<any>([])
    const [selectedUsers, setSelectedUsers] = useState<any>([]);
    const [inboundMode, setInboundMode] = useState<boolean>(false)
    const [salseLead, setSalseLead] = useState<any>("")
    const timer = useRef<any>(0)

    useEffect(() => {
        if (history.action === "POP") {
            let PANELUSERFILTER: any;
            let PANELSTATUS: any
            setUserGroup(PANELUSERFILTER?.usergroup)
            setUserStatus(PANELUSERFILTER?.is_active)
            setPanelUser(PANELUSERFILTER?.tl)
            setActiveDeactiveTeam(PANELSTATUS)
        }
        else {
            getLeadSource();
            getPanelUserList();
            getAgentList("", `CustomerLead`)
            localStorage.removeItem("PANELUSERFILTER");
            getUserList();
            getAssignAgentList()
            getAgentData()
        }

    }, [getLeadSource, getPanelUserList, getAgentList, getUserList, getAgentData])


    useEffect(() => {
        setIsActive(editUser.status)
        setInboundMode(editUser.inbound_mode)
        setCallFrom(editUser?.ctc_from)
        setIsFbAd(editUser?.is_fb_ad)
        setIsFbCall(editUser?.is_fb_call)
        setIsGoogleAd(editUser?.is_google_ad)
        setIsGoogleCall(editUser?.is_google_call)
        setIsJdAd(editUser?.is_jd_ad)
        setIsInBond(editUser?.is_in_bond)
        setIsOutBond(editUser?.is_outbound)
        setIsDefaultAd(editUser?.is_default_ad)
        setIsDefaultCall(editUser?.is_default_call)
        setIs_rcfresh(editUser?.is_rcfresh)
        setIsSMSCall(editUser?.is_sms_call)
        setIsSMSAd(editUser?.is_sms_ad)
        setIsWhatsapp(editUser?.is_whatsapp)
        setIsHomedxLead(editUser.is_homedx_lead)
        setIsImagingLead(editUser.is_imaging_lead)
    }, [editUser])

    const handleUser = () => {
        let body: any = {
            ...editUser,
            status: isActive,
            inbound_mode: inboundMode,
            ctc_from: callFrom,
            is_inbound: is_inbound,
            tl: userId
        }
        updateUserList(body)
        setEditUser({})
        setPage(0)
        getUserList()

    }

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = users.links.next;
            getUserList(url.substring(url.indexOf("?")))
        } else if (newPage < page) {
            let url = users.links.previous;
            getUserList(url.includes("?") ? url.substring(url.indexOf("?")) : "")
        }
        setPage(newPage);
    };

    const handleEditUser = (user: any) => {
        setOpenUserModal(true)
        setEditUserDetails(user)
    }
    const handleClick = (id: number) => {
        setopenModal(true);
        getPanelComment(id)
    };
    const filterPanelUser = () => {
        const body: any = {
            usergroup: userGroup,
            is_active: userStatus,
            tl: panelUser,
            username: user_name,
            on_call: onCallFilter,
            break_button: breakFilter,
            inbound_mode: inboundModeFilter,
            is_imaging_lead: imagingLeadFilter,
            is_homedx_lead: homedxLeadFilter,

        }
        localStorage.setItem("PANELUSERFILTER", JSON.stringify(body));
        const url = genratePanelUserFiltr(body).substring(2)
        getUserList(`?${url}`)
    }
    const handleEditMember = (user: any) => {
        setEditUser(user)
    }
    const handleUpdateStatus = () => {
        const body: any = {
            "ids": selectedUsers,
            type: {
                "status": statusButton,
                "inbound_mode": updateInboundMode,
                "is_fb_ad": fbAdButton,
                "is_google_ad": googleAdButton,
                "is_jd_ad": jdAdButton,
                "is_inbound": isInbondButton,
                "is_default_call": defaultCallButton,
                "is_default_ad": defaultAdButton,
                "is_rcfresh": rcFreshButton,
                "is_google_call": googleCallButton,
                "is_sms_call": smsCallButton,
                "is_sms_ad": smsAdButton,
                "is_fb_call": fbCallButton,
                "is_whatsapp": isWhatsappButton,
                "ctc_from": callFrominBulk,
            }
        }
        updateUserStatus(body)
        setSelectedUsers([])
        getUserList()
    }
    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid
                    container
                    spacing={2}
                    direction="row"
                    style={{ alignItems: "right" }}
                >
                    <Grid item xs={12} sm={10}>
                        <h2 >Manage Users</h2>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Link to="/dashboard/sl/users/create" style={{ textDecoration: "none" }}>
                            <Button
                                color="secondary"
                                variant="contained"
                            >
                                Add user
                            </Button>
                        </Link>
                    </Grid>
                </Grid>

                <br />
                <Grid container spacing={1} direction="row" style={{ alignItems: "left" }}>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="username"
                            onChange={(event, newValue) => {
                                let sourceusername: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceusername = obj.map((item: any) => item.user.username);
                                }
                                setUser_name(sourceusername);
                            }}
                            options={assigneUserList.results}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            getOptionLabel={(option: any) => option.user.username}
                            getOptionDisabled={(option: any) => {
                                return user_name.includes(option.id);
                            }}
                            disableClearable
                            disableCloseOnSelect
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    getAssignAgentList(`?code=${newInputValue}`)
                                }, 1000);
                                if (newInputValue.length === 0) {
                                    setUser_name([]);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    placeholder="User Name"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Select className="input"
                            name="usergroup"
                            variant="outlined"
                            value={userGroup}

                            style={{ width: "100%" }}
                            onChange={(e) => setUserGroup(e.target.value as string)}
                        >
                            <MenuItem value={"none"}>User Group</MenuItem>
                            <MenuItem value={"SupportExecutive"}>SE</MenuItem>
                            <MenuItem value={"CustomerExecutive"}>CE</MenuItem>
                            <MenuItem value={"VerificationExecutive"}>VE</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Select className="input"
                            name="usergroup"
                            variant="outlined"
                            value={userStatus}

                            style={{ width: "100%" }}
                            onChange={(e) => setUserStatus(e.target.value as string)}
                        >
                            <MenuItem value={"none"}>Status</MenuItem>
                            <MenuItem value={"true"}>Active</MenuItem>
                            <MenuItem value={"false"}>Inactive</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Select className="input"
                            name="usergroup"
                            variant="outlined"
                            value={panelUser}

                            style={{ width: "100%" }}
                            onChange={(e) => setPanelUser(e.target.value as string)}
                        > <MenuItem disabled value={"none"}>Team Lead</MenuItem>
                            {panelUserList && panelUserList.results && panelUserList.results.length > 0 && panelUserList.results.map((data: any) => {
                                return (
                                    <MenuItem value={data.id}>{data.user.name}</MenuItem>
                                )
                            })}
                            <MenuItem value={"0"}>No TL</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Select className="input"
                            name="usergroup"
                            variant="outlined"
                            value={breakFilter}

                            style={{ width: "100%" }}
                            onChange={(e) => setBreakFilter(e.target.value as string)}
                        >
                            <MenuItem disabled value={"none"}>Break Status</MenuItem>
                            <MenuItem value={"true"}>Active</MenuItem>
                            <MenuItem value={"false"}>Inactive</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2} >
                        <Select className="input"
                            name="usergroup"
                            variant="outlined"
                            value={onCallFilter}

                            style={{ width: "100%" }}
                            onChange={(e) => setOnCallFilter(e.target.value as string)}
                        >
                            <MenuItem disabled value={"none"}>On Call Status</MenuItem>
                            <MenuItem value={"true"}>Active</MenuItem>
                            <MenuItem value={"false"}>Inactive</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Select className="input"
                            name="inbound_mode"
                            variant="outlined"
                            value={inboundModeFilter}
                            style={{ width: "100%" }}
                            onChange={(e) => setInbountModeFilter(e.target.value as string)}
                        >
                            <MenuItem value={"none"}>Inbound Mode</MenuItem>
                            <MenuItem value={"true"}>Inbound</MenuItem>
                            <MenuItem value={"false"}>Outbound</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Select className="input"
                            name="imaging_lead"
                            variant="outlined"
                            value={imagingLeadFilter}
                            style={{ width: "100%" }}
                            onChange={(e) => setImagingLeadFilter(e.target.value as string)}
                        >
                            <MenuItem value={"none"}>Imaging Lead</MenuItem>
                            <MenuItem value={"true"}>Active</MenuItem>
                            <MenuItem value={"false"}>Inactive</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Select className="input"
                            name="homedx_lead"
                            variant="outlined"
                            value={homedxLeadFilter}
                            style={{ width: "100%" }}
                            onChange={(e) => setHomedxLeadFilter(e.target.value as string)}
                        >
                            <MenuItem value={"none"}>Homedx Lead</MenuItem>
                            <MenuItem value={"true"}>Active</MenuItem>
                            <MenuItem value={"false"}>Inactive</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            style={{ height: "56px" }}
                            fullWidth
                            onClick={filterPanelUser}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ height: "56px" }}
                            fullWidth
                            onClick={() => history.push("/dashboard/haa/users")}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <br />

                {selectedUsers.length > 0 && (
                    <React.Fragment>
                        <h4 style={{ fontWeight: "bold", textAlign: "left", fontSize: "24px" }}>
                            UPDATE STATUS
                        </h4>
                        <Grid
                            container
                            spacing={3}
                            direction="row"
                        >
                            <Grid item xs={12} sm={4} md={2}>
                                <Select className="input"
                                    name="usergroup"
                                    variant="outlined"
                                    value={statusButton}

                                    style={{ width: "100%" }}
                                    onChange={(e) => setStatusButton(e.target.value as any)}
                                >
                                    <MenuItem disabled value={"none"}>Status</MenuItem>
                                    <MenuItem value={"true"}>Active</MenuItem>
                                    <MenuItem value={"false"}>Deactive</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12} sm={4} md={2}>
                                <Select className="input"
                                    name="isinbond"
                                    variant="outlined"
                                    value={isInbondButton}

                                    style={{ width: "100%" }}
                                    onChange={(e) => setIsInbondButton(e.target.value as any)}
                                >
                                    <MenuItem disabled value={"none"}>Auto Login</MenuItem>
                                    <MenuItem value={"true"}>Active</MenuItem>
                                    <MenuItem value={"false"}>Deactive</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item xs={12} sm={2} md={2} >
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ height: "56px" }}
                                    fullWidth
                                    onClick={handleUpdateStatus}
                                >
                                    Update
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={2} md={2} >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ height: "56px" }}
                                    fullWidth
                                    onClick={() => history.push("/dashboard/haa/users")}
                                >
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
                <div className="data-table" style={{ width: "100%", marginTop: "2rem" }}>
                    <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "560px" }}>
                        {loading ? <Loader /> : (
                            <Table stickyHeader aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align="center">Action</StyledTableCell>
                                        <StyledTableCell align="center">Edit User</StyledTableCell>
                                        <StyledTableCell align="center">Panel user</StyledTableCell>
                                        <StyledTableCell align="center">Comments</StyledTableCell>
                                        <StyledTableCell align="center">On Call  </StyledTableCell>
                                        <StyledTableCell align="center">On Break  </StyledTableCell>
                                        <StyledTableCell align="center">Status  </StyledTableCell>
                                        <StyledTableCell align="center">Auto Login</StyledTableCell>
                                        <StyledTableCell align="center">Inbound Mode  </StyledTableCell>
                                        <StyledTableCell align="center">Imaging Lead  </StyledTableCell>
                                        <StyledTableCell align="center">HomeDx Lead  </StyledTableCell>
                                        <StyledTableCell align="center">FB Ad</StyledTableCell>
                                        <StyledTableCell align="center">Google Ad</StyledTableCell>
                                        <StyledTableCell align="center">JD Ad</StyledTableCell>
                                        <StyledTableCell align="center">Default Ad</StyledTableCell>
                                        <StyledTableCell align="center">RCFresh</StyledTableCell>
                                        <StyledTableCell align="center">Is SMS Ad</StyledTableCell>
                                        <StyledTableCell align="center">Is Whatsapp</StyledTableCell>
                                        <StyledTableCell align="center">Call from</StyledTableCell>
                                        <StyledTableCell align="center">Assigned</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {users && users.results && users.results.length > 0 && users.results.map((user: any) => {
                                        return (
                                            <StyledTableRow key={user.username}>
                                                <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <Button
                                                            color="primary"
                                                            variant="text"
                                                            onClick={() => handleUser()}
                                                        >
                                                            Submit
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            color="primary"
                                                            variant="text"
                                                            onClick={() => handleEditMember(user)}
                                                        >
                                                            Edit
                                                        </Button>
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Button
                                                        color="primary"
                                                        variant="text"
                                                        onClick={() => handleEditUser(user)}
                                                    >
                                                        Edit User
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {user.username}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <div>
                                                        <MessageRounded onClick={() => handleClick(user.id)} />
                                                    </div>
                                                </StyledTableCell>

                                                <StyledTableCell align="center">

                                                    <DefaultSwitch
                                                        checked={user?.on_call}
                                                        name="checkedA"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        disableRipple
                                                    />

                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <DefaultSwitch
                                                        checked={user?.break_button}
                                                        name="checkedA"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        disableRipple
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <Switch
                                                            defaultChecked={user?.status}
                                                            checked={isActive}
                                                            onChange={(e) => setIsActive(e.target.checked)}
                                                            name="checkedA"
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                    ) : (
                                                        <DefaultSwitch
                                                            checked={user?.status}
                                                            name="checkedA"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            disableRipple
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <Switch
                                                            defaultChecked={user?.is_inbound}
                                                            checked={is_inbound}
                                                            onChange={(e) => setIsInBond(e.target.checked)}
                                                            name="checkedA"
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                    ) : (
                                                        <DefaultSwitch
                                                            checked={user?.is_inbound}
                                                            name="checkedA"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            disableRipple
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <DefaultSwitch
                                                        checked={user?.inbound_mode}
                                                        name="checkedA"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        disableRipple
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <DefaultSwitch
                                                        checked={user?.is_imaging_lead}
                                                        name="checkedA"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        disableRipple
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <DefaultSwitch
                                                        checked={user?.is_homedx_lead}
                                                        name="checkedA"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        disableRipple
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <DefaultSwitch
                                                        checked={user?.is_fb_ad}
                                                        name="checkedA"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        disableRipple
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <DefaultSwitch
                                                        checked={user?.is_google_ad}
                                                        name="checkedA"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        disableRipple
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <DefaultSwitch
                                                        checked={user?.is_jd_ad}
                                                        name="checkedA"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        disableRipple
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <DefaultSwitch
                                                        checked={user?.is_default_ad}
                                                        name="checkedA"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        disableRipple
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <DefaultSwitch
                                                        checked={user?.is_rcfresh}
                                                        name="checkedA"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        disableRipple
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <DefaultSwitch
                                                        checked={user?.is_sms_ad}
                                                        name="checkedA"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        disableRipple
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <DefaultSwitch
                                                        checked={user?.is_whatsapp}
                                                        name="checkedA"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        disableRipple
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <Select className="input"
                                                            name="callFrom"
                                                            variant="outlined"
                                                            defaultValue={user.ctc_from}
                                                            value={callFrom !== "none" ? callFrom : user.ctc_from}
                                                            style={{ width: "100%", minWidth: "150px", textAlign: "left" }}
                                                            onChange={(e) => setCallFrom(e.target.value as string)}
                                                        >
                                                            <MenuItem value={"none"} disabled>Call from</MenuItem>
                                                            <MenuItem value={"OZONETEL"} >OZONETEL</MenuItem>
                                                            <MenuItem value={"KNO"} >KNOWLARITY</MenuItem>
                                                            <MenuItem value={"TATATELE"} >TATA TELE</MenuItem>
                                                        </Select>
                                                    ) : (
                                                        user.ctc_from
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editUser.username === user.username ? (
                                                        <Autocomplete
                                                            id="username"
                                                            onChange={(event, newValue) => {
                                                                if (newValue) {
                                                                    let userARR: any = []
                                                                    let sourceId: any = []
                                                                    newValue.map((val: any) => {
                                                                        let obj = JSON.parse(JSON.stringify(val, null, " "));
                                                                        userARR.push(obj)
                                                                        sourceId.push(obj.id)
                                                                    })
                                                                    setUserName(userARR)
                                                                    setUserId(sourceId)
                                                                }
                                                            }}
                                                            value={userName}
                                                            onInputChange={(event, newInputValue) => {
                                                                clearTimeout(timer.current)
                                                                timer.current = setTimeout(() => {
                                                                    getAgentData(`${newInputValue}`)
                                                                }, 1000)
                                                            }}
                                                            multiple
                                                            disableClearable
                                                            disableCloseOnSelect
                                                            limitTags={1}
                                                            options={agentdata.results}
                                                            freeSolo
                                                            blurOnSelect
                                                            getOptionDisabled={(option: any) => {
                                                                const scId = user.asigned.map((data: any) => data.id)
                                                                return scId.includes(option.id)
                                                            }}
                                                            getOptionLabel={(option: any) => option.user && option.user.username}
                                                            renderTags={(value: string[], getTagProps) =>
                                                                value.map((option: any, index: number) => (
                                                                    <Chip
                                                                        key={index}
                                                                        variant="default"
                                                                        color="primary"
                                                                        label={option.user && option.user.username}
                                                                        {...getTagProps({ index })}
                                                                    />
                                                                ))
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder="Agent name"
                                                                    variant="outlined"
                                                                    style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                                    className="input"
                                                                />
                                                            )}
                                                        />
                                                    ) : (
                                                        <React.Fragment>
                                                            {user.asigned.map((user: any) => {
                                                                return (
                                                                    <p key={user.id} style={{ margin: "0.5rem auto" }}>{user.user__username}</p>
                                                                )
                                                            })}
                                                        </React.Fragment>
                                                    )}
                                                </StyledTableCell>

                                            </StyledTableRow>
                                        )
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TablePagination
                                        colSpan={6}
                                        count={users.count || 0}
                                        rowsPerPageOptions={[]}
                                        rowsPerPage={rowsPerPage}
                                        // onChangeRowsPerPage={handleChangeRowsPerPage}
                                        page={page}
                                        SelectProps={{
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                    />
                                </TableFooter>
                            </Table>
                        )}
                    </TableContainer>
                    <PanelUserComment openModal={openModal} setopenModal={setopenModal} panelComments={panelComments} loading={loading} />
                    <EditUser userDetails={editUserDetails} setOpenUserModal={setOpenUserModal} openUserModal={openUserModal} loading={loading} />
                </div>
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    users: state.HealthAdviserAdminReducer.users,
    loading: state.HealthAdviserAdminReducer.loading,
    panelComments: state.HealthAdviserAdminReducer.panelComments,
    panelUserList: state.HealthAdviserAdminReducer.panelUserList,
    agentBYUserGroup: state.HealthAdviserAdminReducer.agentBYUserGroup,
    agentdata: state.HealthAdviserAdminReducer.agentdata,
    lead_source: state.HealthAdviserAdminReducer.lead_source,
    assigneUserList: state.HealthAdviserAdminReducer.assigneUserList,
});

export default connect(mapStateToProps, {
    getUserList,
    updateUserList,
    getLeadSource,
    getPanelComment,
    getPanelUserList,
    getActiveDeactivePanel,
    getAgentList,
    getAssignAgentList,
    getAgentData,
    updateUserStatus
})(
    LeadsTable
);
