import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Grid,
  TextField,
  withStyles,
  Select,
} from "@material-ui/core";
import Modal from '@mui/material/Modal';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SendIcon from '@mui/icons-material/Send';
import Autocomplete from "@mui/material/Autocomplete";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { connect } from "react-redux";
import {
  getTicketData,
  getTicketCategories,
  getTicketSubCategories,
  CreateTaskTicket
}
  from "../actions/CollectionCenter";
import JiraTicketModal from "../Components/Comments/JiraTicketModal";
// import { genratePhleboRiderCashDueFilter } from "../../helpers/generateUrl";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      marginTop: "50px",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
      overflow: "scroll",
      position: "sticky",

    },
    imageSM: {
      width: "60px",
      height: "60px",
      border: "2px solid #000 ",
      borderRadius: "50%"
    },
    table: {
      maxWidth: "100%",
      overflow: "scroll",
    },
    tableHead: {
      background: "rgb(146, 74, 145)!important",
    },
    imagePaper: {
      position: 'absolute',
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[1],
    },
    rightContentHeader: {
      width: "30%",
      float: "right",
      textAlign: "right",
      paddingRight: '20px',
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const Input = styled("input")({
  display: "none",
});

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);
function rand() {
  return Math.round(Math.random() * 20) - 10;
}
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
interface Props {
  loading: boolean;
  getTicketData: any;
  getTicketCategories: any;
  getTicketSubCategories: any;
  CreateTaskTicket: any;
  ticketData: any;
  ticketSubCategories: any;
  ticketCategories: any;
}

const RaiseTicket: React.FC<Props> = ({
  getTicketData,
  getTicketCategories,
  getTicketSubCategories,
  ticketData,
  ticketSubCategories,
  ticketCategories,
  CreateTaskTicket,
  loading,
}) => {
  const classes = useStyles();
  const timer = useRef<any>(0);
  const [page, setPage] = useState(0)
  const history = useHistory();
  const [file1, setFile1] = React.useState<any>([]);
  const [category, setCategory] = useState<any>("")
  const [subCategory, setSubCategory] = useState<any>("")
  const [priority, setPriority] = useState<any>("")
  const [title, setTitle] = useState<any>("")
  const [description, setDescription] = useState<any>("")
  const [openImage, setImageOpen] = React.useState(false);
  const [imageUrl, setImageUrl] = useState("")
  const [modalStyle] = React.useState(getModalStyle);
  const [scrolldown, setScrolldown] = useState<boolean>(false)
  const [jiramodelOpen, setJiramodelOpen] = useState(false);
  const [ticketId, setTicketId] = useState<any>("")
  const [ticket, setTicket] = useState<any>()
  const scrollFunction = () => {
    setScrolldown(!scrolldown)
  }

  useEffect(() => {
    getTicketData(`?ordering=-id`)
    getTicketCategories()
  }, [])
  useEffect(() => {
    if (category !== "") {
      getTicketSubCategories(`?category=${category}`)
    }
  }, [category])
  const handleHelpData = async () => {
    const formData = new FormData();
    formData.append("screen_shot", file1);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("priority", priority);
    formData.append("category", category);
    formData.append("sub_category", subCategory);
    await CreateTaskTicket(formData)
    history.push("/dashboard/cc/helpdesk");
  }
  const fileSelectedHandler1 = (e: React.ChangeEvent<{ files: any }>) => {
    setFile1(e.target.files[0]);
  };

  const handleDeskModal = (e: any) => {
    setImageUrl(e)
    setImageOpen(true)
  }
  const handleImageClose = () => {
    setImageOpen(false)

  }
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = ticketData.links && ticketData.links.next.split("?")[1];
      getTicketData(url);
    } else if (newPage < page) {
      let url = ticketData.links && ticketData.links.previous.split("?")[1];
      getTicketData(url);
    }
    setPage(newPage as number);
  };
  const body = (
    <div style={modalStyle} className={classes.imagePaper}>
      <img src={imageUrl}
        alt="popup"
        style={{ width: "1050px", height: "700px", alignItems: "center", marginBottom: "50px" }}
      />
    </div>
  );

  const downloadImage = () => {
    var element = document.createElement("a");
    var file = new Blob(
      [
        ticketData?.screen_shot
      ],
      { type: "image/*" }
    );
    element.href = URL.createObjectURL(file);
    element.download = "image.png";
    element.click();
  };
  const handleJiraTicket = (data: any) => {
    setTicket(data)
    setTicketId(data.id)
    setJiramodelOpen(true)
  }
   const handleCancleForm=()=>{
     history.push("/dashboard/cc/helpdesk")
     setScrolldown(true)
  
  }
 
  return (
    <>

      <div style={{ width: "100%", marginTop: "80px" }}>
        <div className={classes.toolbar}>
          <Paper className={classes.paper} elevation={15}>
            <div className={classes.rightContentHeader}>
              <Grid container item xs={12} md={12} style={{ margin: "auto", justifyContent: 'flex-end' }}>
                <Button
                  variant="contained"
                  style={{
                    background: "#f73378",
                  }}
                  onClick={scrollFunction}
                >
                  {scrolldown !== true ? (
                    <KeyboardDoubleArrowDownIcon />

                  ) : (<ArrowUpwardIcon />)}

                  Raise Task
                </Button>
              </Grid>
            </div>
            <h3 style={{ fontWeight: "bold" }}>Help Desk</h3>



            {scrolldown === true ? (
              <>
                <div className="ScollTask">
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item xs={12} md={6} sm={6}>
                      <Autocomplete
                        id="category"
                        onChange={(event, newValue) => {
                          let sourceIds: any = [];
                          if (newValue) {
                            let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                            setCategory(obj.id);
                          }

                        }}
                        freeSolo
                        blurOnSelect
                        limitTags={1}
                        options={ticketCategories.results || []}
                        disableClearable
                        disableCloseOnSelect
                        getOptionLabel={(option: any) => option?.title}
                        // onInputChange={(event, newInputValue) => {
                        //   clearTimeout(timer.current);
                        //   timer.current = setTimeout(() => {
                        //     getTicketCategories(newInputValue);
                        //   }, 1000);
                        //   if (newInputValue.length === 0) {
                        //     setCategory("");
                        //   }
                        // }}
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            label="Category"
                            variant="outlined"
                            style={{ width: "100%", margin: "0", padding: "0" }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <Autocomplete
                        id="category"
                        onChange={(event, newValue) => {
                          let sourceIds: any = [];
                          if (newValue) {
                            let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                            setSubCategory(obj.id);
                          }

                        }}
                        freeSolo
                        blurOnSelect
                        limitTags={1}
                        options={ticketSubCategories.results || []}
                        disableClearable
                        disableCloseOnSelect
                        disabled={category === ""}
                        getOptionLabel={(option: any) => option?.title}
                        // onInputChange={(event, newInputValue) => {
                        //   clearTimeout(timer.current);
                        //   timer.current = setTimeout(() => {
                        //     getTicketSubCategories(newInputValue);
                        //   }, 1000);
                        //   if (newInputValue.length === 0) {
                        //     setSubCategory("");
                        //   }
                        // }}
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            label="Sub Category"
                            variant="outlined"
                            style={{ width: "100%", margin: "0", padding: "0" }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        className="input"
                        name="Title Enter"
                        placeholder="Title Enter"
                        type="text"
                        value={title}
                        variant="outlined"
                        onChange={(e) => setTitle(e.target.value as string)}
                        style={{ width: "100%" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextareaAutosize
                        aria-label="Description "
                        minRows={3}
                        value={description}
                        onChange={(e) => setDescription(e.target.value as string)}
                        placeholder="Description"
                        style={{ width: "100%", height: "220px" }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={6} >
                      <sup
                        style={{
                          fontSize: "11px",
                          marginLeft: "15px",
                          marginTop: "15px",
                          background: "#fff",
                          color: "rgba(0, 0, 0, 0.54)",
                          letterSpacing: "0.00938em",
                        }}
                      >
                        Upload ScreenShot
                      </sup>
                      <input
                        className="input"
                        style={{
                          width: "100%",
                          marginTop: "-15px",
                          padding: "18.5px 14px",
                          border: "1px solid rgba(0, 0, 0, 0.23)",
                          borderRadius: "5px",
                        }}
                        type="file"
                        name="file"
                        id="file"
                        multiple
                        onChange={fileSelectedHandler1}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6}>
                      <TextField
                        name="Priority"
                        className="input"
                        select
                        placeholder="Priority"
                        label="Priority"
                        variant="outlined"
                        value={priority}
                        style={{
                          width: "100%",
                          margin: "0",
                          padding: "0",
                          height: "50px",
                        }}
                        onChange={(e) => setPriority(e.target.value as string)}
                        required
                      >
                        <MenuItem disabled value={"none"}>
                          Priority
                        </MenuItem>
                        <MenuItem value={"1"}>Highest </MenuItem>
                        <MenuItem value={"2"}>High</MenuItem>
                        <MenuItem value={"3"}>Medium </MenuItem>
                        <MenuItem value={"4"}>Low</MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    direction="row"
                    style={{ alignItems: "left" }}
                  >
                    <Grid item xs={12} md={2} sm={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleHelpData}
                        disabled={title === "" || description === ""}
                        startIcon={<SendIcon />}

                      >
                        Submit
                      </Button>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2}>
                      <Button
                        variant="contained"
                        // onClick={() => history.push("/dashboard/cc/helpdesk")}
                        onClick={handleCancleForm}
                        startIcon={<RestartAltIcon />}
                      >
                        Reset Form
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </>) : ""}
          </Paper>
          <Paper className={classes.paper} elevation={15}>
          <div style={{ width: "100%" }} className="data-table">
            <TableContainer
              className={classes.tableContainer}
              component={Paper}
              style={{ maxHeight: "560px" }}
            >
              <Table stickyHeader aria-label="simple table">
                <TableHead className={classes.tableHead}>
                  <StyledTableRow>
                    <StyledTableCell className={classes.tableHeadCell}>
                      Title
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableHeadCell}>
                      Description
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableHeadCell}>
                      Created Date
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableHeadCell}>
                      Category
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableHeadCell}>
                      Sub Category
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableHeadCell}>
                      Resolved Date
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableHeadCell}>
                      Status
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableHeadCell}>
                      Image
                    </StyledTableCell>
                    <StyledTableCell className={classes.tableHeadCell}>
                      Download Image
                    </StyledTableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {ticketData && ticketData.results && ticketData.results.length > 0 && ticketData.results.map((data: any, index: any) => {
                    return (
                      <StyledTableRow key={index}>
                        <StyledTableCell align="center" onClick={() => handleJiraTicket(data)} style={{ color: "blue", cursor: "pointer" }} >{data?.title}</StyledTableCell>
                        <StyledTableCell align="center">{data?.description}</StyledTableCell>
                        <StyledTableCell align="center">{data?.created_at.substring(0, 10)}</StyledTableCell>
                        <StyledTableCell align="center">{data?.category}</StyledTableCell>
                        <StyledTableCell align="center">{data?.sub_category}</StyledTableCell>
                        <StyledTableCell align="center">{data?.due_date}</StyledTableCell>
                        <StyledTableCell align="center">{data?.status}</StyledTableCell>
                        {data.screen_shot !== null ? (
                          <StyledTableCell align="center" onClick={() => handleDeskModal(data?.screen_shot)}><img key={data?.screen_shot} src={data?.screen_shot} alt="startimage" className={classes.imageSM} /></StyledTableCell>
                        ) : <StyledTableCell align="center">NA</StyledTableCell>}
                        {data.screen_shot !== null ? (
                          <StyledTableCell align="center" onClick={() => downloadImage()}>
                            <CloudDownloadIcon />
                          </StyledTableCell>
                        ) : <StyledTableCell align="center">NA</StyledTableCell>}
                      </StyledTableRow>
                    )
                  })}
                </TableBody>
                <TableFooter>
                  <StyledTableRow>
                    <TablePagination
                      rowsPerPageOptions={[]}
                      colSpan={7}
                      count={(ticketData && ticketData.count) || 0}
                      rowsPerPage={ticketData && ticketData.page_size}
                      page={page}
                      onPageChange={handleChangePage}
                    />
                  </StyledTableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            </div>
          </Paper>
        </div>
        <Modal
          open={openImage}
          onClose={handleImageClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
        {jiramodelOpen ? (<JiraTicketModal
          open={jiramodelOpen}
          setOpen={setJiramodelOpen}
          ticketId={ticketId}
          ticket={ticket}
        />) : ""}

      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  ticketData: state.CollectionCenterReducer.ticketData,
  ticketCategories: state.CollectionCenterReducer.ticketCategories,
  ticketSubCategories: state.CollectionCenterReducer.ticketSubCategories,
});

export default connect(mapStateToProps, {
  getTicketData,
  getTicketCategories,
  getTicketSubCategories,
  CreateTaskTicket
})
  (RaiseTicket);
