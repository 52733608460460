import {
  ACTIONS,
  PhleboAdminTypes,
} from "../interfaces/actionTypes/CustomerLead";

type InitialState = {
  pin_code: Array<any>;
  cities: Array<any>;
  areaList: Array<any>;
  agentsList: Array<any>;
  phleboList: Array<any>;
  packageList: Array<any>;
  partnerList: Array<any>;
  couponList: Array<any>;
  createBooking: Object;
  createPilotBooking: Object;
  booking: Object;
  todays_positive_calls: Object;
  lead_details: Object;
  leadById: Object;
  additional_booking: object;
  leads: Array<any>;
  bookingList: Array<any>;
  comment: any;
  data: any;
  users: any;
  bookingsComment: any;
  lead_id: number;
  read_call_details: object;
  all_recordings: object;
  whatsappComments: object;
  whatsappCommentsByLead: object;
  salesLeadList: Array<any>;
  dispositions: object;
  callStats: object;
  complaint: object;
  complaints: object;
  lead_source: any;
  booking_slots: object;
  search_results: object;
  call_status: object;
  all_lead: object;
  call_transfer: object;
  user_panel: object;
  sameNumberBooking: boolean;
  agentBYUserGroup: object;
  usersList: Object;
  incorrectNumberLeads: Array<any>;
  dispositionData: Array<any>;
  bookingCall: Array<any>;
  loading: boolean;
  coupon: Object;
  breakData: Array<any>;
  labDetails: Array<any>;
  topRecord: object;
  teamLeadList: Array<any>;
  todayCallDetails: object;
  verificationBookingDetails: Array<any>;
  smsDetails: Array<any>;
  emailDetails: Array<any>;
  smsReportSend: Array<any>;
  paymentInformation: Array<any>;
  centerInfo: Array<any>;
  notificationMessage: Array<any>;
  reportStatus: Array<any>;
  all_lead_tatataly: object;
  unmaskedNumber: Array<any>;
  userDetails: Array<any>;
  assigneUserList: Array<any>;
  zoneList: Object;
  todayMissedCallsList: Array<any>;
  checkGeoFenceStatus: boolean;
  createFamilyPackageBooking: Object;
  familyPackageBookingList: Array<any>;
  familyPackageList: Array<any>;
  verifiedbylist: Array<any>;
  redCoin: Array<any>;
  leadStatus: Array<any>;
  prescription: Array<any>;
  subCenter: Array<any>;
  imagingTimeSlots: Array<any>;
  createIMGBooking: object;
  multiplePackage: Array<any>;
  displayCities: Array<any>;
  customerList: Array<any>;
  mapmyindiaAccessToken: Array<any>;
  geoFencesMapMyIndia: Array<any>;
  breakResponse: Array<any>;
  addressType: Array<any>;
  performanceData: Array<any>;
  ticketData: Array<any>;
  ticketCategories: Array<any>;
  ticketSubCategories: Array<any>;
  dashboardOverview: Object;
  redtechcomment: Array<any>;
  qualityScoringReport: Array<any>;
  get_quality_scoring_report_for_backup: Array<any>;
  ceCenterForCC: Array<any>;
  timeslots: Array<any>;
  geoLoading: boolean;
  ticketComments: Array<any>
  bookingComplaints: Array<any>
  geoCheckError: string
  bookingListLoader: boolean;
  emailLoader: boolean;
  ticketLoader: boolean;
  biomarkerScreening: Array<any>;
  biomarkerUltasound: Array<any>;
  markerAdditionMember: Array<any>;
  newbookingList: Array<any>;
  locationVerified: Array<any>;
  sendLocationOnWhatsapp: Array<any>;
  addressDetailsLatLong: Array<any>;
};

const initialState: InitialState = {
  pin_code: [],
  cities: [],
  areaList: [],
  agentsList: [],
  phleboList: [],
  packageList: [],
  partnerList: [],
  couponList: [],
  redCoin: [],
  createBooking: {},
  usersList: {},
  booking: {},
  lead_details: {},
  createPilotBooking: {},
  leadById: {},
  todays_positive_calls: {},
  dispositions: {},
  breakResponse: [],
  leads: [],
  bookingList: [],
  comment: {},
  data: {},
  users: {},
  loading: false,
  read_call_details: {},
  all_recordings: {},
  whatsappComments: {},
  whatsappCommentsByLead: {},
  callStats: {},
  complaint: {},
  complaints: {},
  assigneUserList: [],
  coupon: {},
  bookingsComment: {},
  lead_id: 0,
  lead_source: {},
  booking_slots: {},
  search_results: {},
  sameNumberBooking: false,
  call_status: {},
  all_lead: {},
  call_transfer: {},
  agentBYUserGroup: {},
  user_panel: {},
  incorrectNumberLeads: [],
  bookingCall: [],
  dispositionData: [],
  breakData: [],
  labDetails: [],
  topRecord: {},
  additional_booking: {},
  todayCallDetails: {},
  verificationBookingDetails: [],
  smsDetails: [],
  emailDetails: [],
  smsReportSend: [],
  teamLeadList: [],
  centerInfo: [],
  paymentInformation: [],
  notificationMessage: [],
  reportStatus: [],
  all_lead_tatataly: {},
  unmaskedNumber: [],
  userDetails: [],
  zoneList: {},
  todayMissedCallsList: [],
  checkGeoFenceStatus: false,
  familyPackageList: [],
  familyPackageBookingList: [],
  createFamilyPackageBooking: {},
  verifiedbylist: [],
  leadStatus: [],
  customerList: [],
  prescription: [],
  subCenter: [],
  imagingTimeSlots: [],
  createIMGBooking: {},
  multiplePackage: [],
  displayCities: [],
  mapmyindiaAccessToken: [],
  geoFencesMapMyIndia: [],
  addressType: [],
  performanceData: [],
  ticketData: [],
  ticketCategories: [],
  salesLeadList: [],
  ticketSubCategories: [],
  dashboardOverview: {},
  redtechcomment: [],
  qualityScoringReport: [],
  get_quality_scoring_report_for_backup: [],
  ceCenterForCC: [],
  timeslots: [],
  geoLoading: false,
  bookingComplaints:[],
  geoCheckError: "",
  ticketComments:[],
  bookingListLoader:false,
  emailLoader:false,
  ticketLoader:false,
  biomarkerScreening: [],
  biomarkerUltasound: [],
  markerAdditionMember: [],
  newbookingList: [],
  locationVerified: [],
  sendLocationOnWhatsapp: [],
  addressDetailsLatLong: [],
};

const CustomerLeadReducer = (
  state = initialState,
  action: PhleboAdminTypes
) => {
  switch (action.type) {
    case ACTIONS.GET_USERS_LIST:
      return {
        ...state,
        usersList: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_USERS_LIST:
      return {
        ...state,
        usersList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PIN_CODE:
      return {
        ...state,
        pin_code: action.payload,
        loading: false,
      };
    case ACTIONS.UPLOAD_FILE:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DASHBOARD_OVERVIEW:
      return {
        ...state,
        dashboardOverview: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBOS:
      return {
        ...state,
        phleboList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITIES:
      return {
        ...state,
        cities: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AREA:
      return {
        ...state,
        areaList: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ASSIGNED_USERGROUP:
      return {
        ...state,
        assigneUserList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_POSTIVE_CALLS_COMMENTS:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.SET_COMMENTS:
      return {
        ...state,
        comment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_POSTIVE_CALLS_COMMENT:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_DETAILS:
      return {
        ...state,
        lead_details: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USER_LIST_DATA_TEAM_LEAD:
      return {
        ...state,
        teamLeadList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEADS:
      return {
        ...state,
        leads: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_BY_ID:
      return {
        ...state,
        leadById: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_DETAILS_RESET:
      return {
        ...state,
        leadById: {},
        lead_details: {},
        loading: false,
      };
    case ACTIONS.GET_AGENTS:
      return {
        ...state,
        agentsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USER_LIST_DATA_SALES_LEAD:
      return {
        ...state,
        salesLeadList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AGENTS_BY_USERGROUP:
      return {
        ...state,
        agentBYUserGroup: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDRESS_TYPE_SEATING:
      return {
        ...state,
        addressType: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGES:
      return {
        ...state,
        packageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PARTNERS:
      return {
        ...state,
        partnerList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COUPONS:
      return {
        ...state,
        couponList: action.payload,
        loading: false,
      };
      case ACTIONS.CREATE_PILOT_BOOKING:
        return {
          ...state,
          createPilotBooking: action.payload,
          loading: false,
        };
    case ACTIONS.CREATE_BOOKING:
      return {
        ...state,
        createBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_LIST:
      return {
        ...state,
        bookingList: action.payload,
        bookingListLoader: false,
      };
    case ACTIONS.GET_BOOKING_BY_ID:
      return {
        ...state,
        booking: action.payload,
        bookingListLoader: false,
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.CREATE_CALL:
      return {
        ...state,
        loading: false,
        lead_id: action.payload,
      };
    case ACTIONS.GET_RECORDINGS:
      return {
        ...state,
        all_recordings: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_SOURCE:
      return {
        ...state,
        lead_source: action.payload,
        loading: false,
      };
    case ACTIONS.SEARCH_CALL:
      return {
        ...state,
        search_results: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_COMMENT:
      return {
        ...state,
        comments: action.payload,
        loading: false,
      };
    case ACTIONS.BOOKING_RESET:
      return {
        ...state,
        createBooking: {},
        bookingList: [],
        loading: false,
      };
    case ACTIONS.GET_BOOKINGS_COMMENTS:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CUSTOMERS_LIST:
      return {
        ...state,
        customerList: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_BOOKINGS_COMMENT:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RED_TECH_COMMENT:
      return {
        ...state,
        redtechcomment: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS_BY_LEAD:
      return {
        ...state,
        whatsappCommentsByLead: action.payload,
        loading: false,
      };
      case ACTIONS.GET_BREAK_RESPONSE:
        return {
          ...state,
          breakResponse: action.payload,
          loading: false,
        };
    case ACTIONS.GET_PERFORMANCE_DATA:
      return {
        ...state,
        performanceData: action.payload,
        loading: false,
      };
    case ACTIONS.SEND_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CALL_STATS:
      return {
        ...state,
        callStats: action.payload,
        loading: false,
      };
    case ACTIONS.RAISE_COMPLAINT:
      return {
        ...state,
        complaint: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COMPLAINTS:
      return {
        ...state,
        complaints: action.payload,
        loading: false,
      };
    case ACTIONS.ASSIGN_DOCTOR:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.SEND_FEEDBACK:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.GET_AVAILABLE_SLOTS:
      return {
        ...state,
        booking_slots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_LEADS:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USERS:
      return {
        ...state,
        user_panel: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_TRANSFER_BY_COMMA:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DISPOSITIONS:
      return {
        ...state,
        dispositions: action.payload,
        loading: false,
      };
    case ACTIONS.GET_INCORRECT_NUMBER_LEAD:
      return {
        ...state,
        incorrectNumberLeads: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DISPOSITION_DATA:
      return {
        ...state,
        dispositionData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_CALLS:
      return {
        ...state,
        bookingCall: action.payload,
        loading: false,
      };

    case ACTIONS.GET_BREAK_DATA:
      return {
        ...state,
        breakData: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_COUPON:
      return {
        ...state,
        coupon: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_COUPON:
      return {
        ...state,
        coupon: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TOP_RECORDS:
      return {
        ...state,
        topRecord: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_DETAILS:
      return {
        ...state,
        labDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TODAYS_CALL_DETAILS:
      return {
        ...state,
        todayCallDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VERIFICATION_BOOKING_DETAILS:
      return {
        ...state,
        verificationBookingDetails: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_DETAILS:
      return {
        ...state,
        smsDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_EMAIL_DETAILS:
      return {
        ...state,
        emailDetails: action.payload,
        emailLoader: false,
      };
    case ACTIONS.SET_EMAIL_LOADING:
      return {
        ...state,
        emailLoader: action.payload,
        
      };
    case ACTIONS.GET_SMS_REPORT_SEND:
      return {
        ...state,
        smsReportSend: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTER_INFORMATION:
      return {
        ...state,
        centerInfo: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_INFORMATION:
      return {
        ...state,
        paymentInformation: action.payload,
        loading: false,
      };
    case ACTIONS.GET_NOTIFICATION_MESSAGE:
      return {
        ...state,
        notificationMessage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_STATUS:
      return {
        ...state,
        reportStatus: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_TRANSFER_TO_TATATALE:
      return {
        ...state,
        all_lead_tatataly: action.payload,
        loading: false,
      };
    case ACTIONS.GET_UNMASKED_NUMBER:
      return {
        ...state,
        unmaskedNumber: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ZONE:
      return {
        ...state,
        zoneList: action.payload,
        loading: false,
      };
    case ACTIONS.SAME_NUMBER_BOOKING:
      return {
        ...state,
        sameNumberBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TODAYS_MISSED_CALLS:
      return {
        ...state,
        todayMissedCallsList: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA:
      return {
        ...state,
        checkGeoFenceStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_FAMILY_PACKAGES:
      return {
        ...state,
        familyPackageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_FAMILY_PACKAGES_BOOKING:
      return {
        ...state,
        familyPackageBookingList: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_FAMILY_PACKAGES_BOOKING:
      return {
        ...state,
        createFamilyPackageBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VERIFIED_BY_LIST:
      return {
        ...state,
        verifiedbyList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RED_COIN:
      return {
        ...state,
        redCoin: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_STATUS:
      return {
        ...state,
        leadStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PRESCRIPTION_DATA:
      return {
        ...state,
        prescription: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SUB_CENTER_INFORMATION:
      return {
        ...state,
        subCenter: action.payload,
        loading: false,
      };
    case ACTIONS.GET_IMAGING_TIMESLOTS:
      return {
        ...state,
        imagingTimeSlots: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_IMAGING_BOOKING:
      return {
        ...state,
        createIMGBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MULTIPLE_PACKAGES:
      return {
        ...state,
        multiplePackage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DISPLAY_CITIES:
      return {
        ...state,
        displayCities: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN:
      return {
        ...state,
        mapmyindiaAccessToken: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA:
      return {
        ...state,
        geoFencesMapMyIndia: action.payload,
        loading: false,
        geoLoading: false,
        geoCheckError: ""
      };
    case ACTIONS.GET_TICKET_DATA:
      return {
        ...state,
        ticketData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_CATEGORIES:
      return {
        ...state,
        ticketCategories: action.payload,
        loading: false,
      };

    case ACTIONS.GET_TICEKT_SUB_CATEGORIES:
      return {
        ...state,
        ticketSubCategories: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QUALITY_SCORING_REPORT:
      return {
        ...state,
        qualityScoringReport: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QUALITY_SCORING_REPORT_FOR_BACKUP:
      return {
        ...state,
        get_quality_scoring_report_for_backup: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CE_CENTER_FOR_CC:
      return {
        ...state,
        ceCenterForCC: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TIMESLOTS:
      return {
        ...state,
        timeslots: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_PILOT_BOOKING:
      return {
        ...state,
        createPilotBooking: action.payload,
        loading: false,
      };
    case ACTIONS.SET_GEO_LOADING:
      return {
        ...state,
        geoLoading: action.payload,
      };
    case ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG:
      return {
        ...state,
        addressDetailsLatLong: action.payload,
        loading: false,
      };
    case ACTIONS.SEND_LOCATION_REQUEST:
      return {
        ...state,
        sendLocationOnWhatsapp: action.payload,
        loading: false,
      };
    case ACTIONS.LOCATION_VERIFIED:
      return {
        ...state,
        locationVerified: action.payload,
        loading: false,
      };
    case ACTIONS.SET_GEO_CHECK_ERROR:
      return {
        ...state,
        geoCheckError: action.payload,
      };
      case ACTIONS.GET_TICKET_COMMENTS:
        return {
          ...state,
          ticketComments: action.payload,
          loading: false,
        };
        case ACTIONS.GET_BOOKING_COMPLAINTS:
          return {
            ...state,
            bookingComplaints: action.payload,
            ticketLoader: false,
          };
          case ACTIONS.SET_ALL_BOOKING_LOADING:
            return {
              ...state,
              bookingListLoader: action.payload,
            };
          case ACTIONS.SET_TICKET_LOADING:
            return {
              ...state,
              ticketLoader: action.payload,
            };
            case ACTIONS.GET_BIOMARKER_SCREENING:
              return {
                ...state,
                biomarkerScreening: action.payload,
                loading: false,
              };
            case ACTIONS.GET_BIOMARKER_ULTASOUND:
              return {
                ...state,
                biomarkerUltasound: action.payload,
                loading: false,
              };
            case ACTIONS.GET_ADDITIONAL_MEMBER:
              return {
                ...state,
                markerAdditionMember: action.payload,
                loading: false,
              };
              case ACTIONS.BIOMARKER_RESET:
                return {
                  ...state,
                  biomarkerScreening: {},
                };
                case ACTIONS.GET_NEW_BOOKING_LIST:
                  return {
                    ...state,
                    newbookingList: action.payload,
                    bookingListLoader: false,
                  };
    default:
      return state;
  }
};

export default CustomerLeadReducer;
