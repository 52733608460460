import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Grid, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TextField, TablePagination, TableFooter, withStyles, Switch, } from '@material-ui/core';
import { Chip } from "@material-ui/core";
import './index.sass'
import CancelIcon from '@mui/icons-material/Cancel';
import { CheckCircleOutlineRounded, Edit } from "@material-ui/icons";
import { connect } from "react-redux";
import { getZone, updateZone, getTimeslots, getCities, getZoneComments, } from '../../actions/salesLeadAdminAction'
import { getPhlebos } from '../../actions/salesLeadAdminAction'
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';
import Loader from "../loader";
import CreateZoneModal from "./createZoneModal";
import Button from '@mui/material/Button';
import moment from "moment";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MessageRounded } from '@material-ui/icons';
import BookingCommentsModal from '../comments/zonesComments'
import PreviewIcon from '@mui/icons-material/Preview';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import { TransitionProps } from '@mui/material/transitions';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import ZoneViewMap from './zoneView'
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import ManIcon from '@mui/icons-material/Man';
import { genrateZoneFilter } from "../../../helpers/generateUrl";
import { useHistory } from "react-router-dom";
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        paper: {
            padding: "1rem",
            width: "100%",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        heading: {
            fontWeight: "normal",
            fontSize: "1.2rem"
        },
        actionButton: {
            margin: "0 0.5rem",
            cursor: "pointer"
        },
        tableContainer: {
            marginTop: "2rem",
            position: "sticky",
            top: "200px",
        },
    })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);
const DefaultSwitch: any = withStyles({
    switchBase: {
        color: "red",
        '&$checked': {
            color: "green",
        },
        '&$checked + $track': {
            backgroundColor: "green",
        },
    },
    checked: {},
    track: {
        backgroundColor: "red"
    },
})(Switch);

interface Props {
    getZone: any;
    updateZone: any;
    zoneList: any;
    getCities: any;
    cities: any;
    getPhlebos: any;
    phleboList: any;
    getTimeslots: any;
    timeslotsList: any;
    getZoneComments: any;
    loading: Boolean;
}

const ZonePage: React.FC<Props> = ({
    getZone,
    updateZone,
    zoneList,
    getCities,
    cities,
    getPhlebos,
    phleboList,
    getTimeslots,
    timeslotsList,
    getZoneComments,
    loading
}) => {
    const classes = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [search, setSearch] = useState<String>("")
    const [editZone, seteditZone] = useState("")
    const [timeslots, setTimeslots] = useState<any>([])
    const [timeslotsIds, setTimeslotsIds] = useState<any>([])
    const [phlebosInitialLength, setPhlebosInitialLength] = useState<any>([])
    const [phlebos, setPhlebos] = useState<any>([])
    const [phlebosId, setPhlebosIds] = useState<any>([])
    const [city, setCity] = useState<number | null>(0)
    const [cityName, setCityName] = useState("")
    const [maxSlot, setMaxSlot] = useState("")
    const [mapMyIndiaId, setMapMyIndiaid] = useState("")
    const [zoneId, setZoneId] = useState<number>(0);
    const [isGeoFence, setIsGeoFence] = useState(false)
    const [openModal, setopenModal] = useState(false)
    const timer = useRef<any>(0)
    const [open, setOpen] = useState(false)
    const [openMapModal, setOpenMapModal] = React.useState(false);
    const [cityId, setCityId] = useState<any>()
    const [openTimeView, setOpenTimeView] = React.useState(false);
    const [pageNumber, setPageNumber] = useState<any>("")
    const [isActive, setIsActive] = useState(false);
    const [removedIds, setRemovedIds] = useState<number[]>([]);
    const history = useHistory()
    useEffect(() => {
        getZone()
        getCities()
        getPhlebos()
        getTimeslots()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // useEffect(() => {
    //     open === false && getZone()
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [open])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = zoneList.links && zoneList.links.next.split("?")[1];
            getZone("", `?${url}`);
            setPageNumber(url)
        } else if (newPage < page) {
            let url = zoneList.links && zoneList.links.previous.split("?")[1];
            setPageNumber(url)
            getZone("", url !== undefined ? `?${url}` : "");
        }
        setPage(newPage as number);
    };

    const handleEditZone = (zone: any) => {
        seteditZone(zone.id)
        setCityName(zone.city.name)
        setCity(zone.city.id)
        setPhlebos(zone.phlebos)
        setPhlebosIds(zone.phlebos.map((item: any) => item.id))
        setTimeslotsIds(zone.time_slots.map((item: any) => item.id))
        setPhlebosInitialLength(zone.phlebos.map((item: any) => item.id))
        setTimeslots(zone.time_slots)
        setMaxSlot(zone.max_booking_phlebs_slot)
        setMapMyIndiaid(zone.mapmyindiaid)
        setIsGeoFence(zone.is_geofence)
        setIsActive(zone.is_active)
    }

    const handleSubmit = async (zone: any) => {
        seteditZone("")
        const body: any = {
            // city: zone.city?.id,
            time_slots: timeslotsIds,
            phlebos: phlebosId,
            remove_phlebos: removedIds,
            max_booking_phlebs_slot: maxSlot,
            is_active: isActive,

            mapmyindiaid: mapMyIndiaId,
            is_geofence: isGeoFence

        }
        await updateZone(zone?.id, body)
        getCities()
        getPhlebos()
        getTimeslots()
        setRemovedIds([]);
        getZone("", "?show=true")
    }
    const handleCancel = async () => {
        seteditZone("");
        setRemovedIds([]);
    };

    const disableZone = async (zone: any) => {
        const body: any = {
            city: zone.city?.id,
            time_slots: zone.time_slots?.id,
            phlebos: phlebosId,
            max_booking_phlebs_slot: zone.city?.max_booking_phlebs_slot,
            is_active: !zone.is_active
        }
        await updateZone(zone?.id, body)
        getZone("", "?show=true")
        getCities()
        getPhlebos()
        getTimeslots()
    }
    const handleClick = (id: number) => {
        setopenModal(true);
        getZoneComments(id)
    };

    const handleClickOpen = (id: any) => {
        setCityId(id)
        setOpenMapModal(true);
    };
    const handleClose = () => {
        setOpenMapModal(false);
    };
    const onKeyDown = (e: any) => {
        if (e.key === "Enter") {
            handleZoneFilter(e);
        }
    };
    const [timeSlotData, setTimeSlotData] = useState<any>()
    const [phleboData, setPhleboData] = useState<any>()
    const [phleboDataOpen, setPhleboDataOpen] = useState<boolean>(false)
    const handleSubmitLat = () => {

    }
    const handleTimeView = (timeSlot: any) => {
        setTimeSlotData(timeSlot)
        setOpenTimeView(true)
    };
    const handleTimeViewClose = () => {
        setOpenTimeView(false)
    }
    const handlePhleboView = (phlebo: any) => {
        setPhleboData(phlebo)
        setPhleboDataOpen(true)
    };
    const handlePhleboViewClose = () => {
        setPhleboDataOpen(false)
    }
    const [status, setStatus] = useState<any>("")
    const handleZoneFilter = (e: any) => {
        e.preventDefault();
        const filterBody: any = {
            city_name: search,
            is_active: status
        }
        const url = genrateZoneFilter(filterBody).substring(2)
        getZone("", `?${url}`)

    }
    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <h2 >Manage Zone</h2>

                <Grid container
                    spacing={1}
                    direction="row"
                    style={{ alignItems: "right" }}>

                    <Grid item xs={12} sm={4} md={3}>
                        <TextField
                            id="search"
                            className="input"
                            name="search"
                            type="search"
                            placeholder="Search Zone"
                            value={search}
                            variant="outlined"
                            onChange={(e) => setSearch(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <Autocomplete
                            id="status"
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    setStatus(obj.value)
                                }

                            }}
                            options={[
                                {
                                    text: "Active",
                                    value: "true"
                                },
                                {
                                    text: "In-Active",
                                    value: "false"
                                }
                            ]}
                            freeSolo
                            blurOnSelect
                            aria-required
                            getOptionLabel={(option: any) => option.text}
                            disableClearable
                            disableCloseOnSelect
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Zone Status"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <Button variant="contained" fullWidth onClick={handleZoneFilter}>Filter Zone </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <Button variant="contained" color="error" fullWidth onClick={() => { history.push("/dashboard/sla/zones") }}>Reset Zone </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ marginLeft: "1rem" }}
                            onClick={() => setOpen(true)}
                        >
                            Add Zone
                        </Button>
                    </Grid>
                </Grid>

                {loading ? <Loader /> : (
                    <div style={{ width: "100%" }} className="data-table">
                        <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "680px" }}>
                            <Table stickyHeader aria-label="simple table">
                                <TableHead>
                                    <StyledTableRow>
                                        <StyledTableCell align="center">View Zone</StyledTableCell>
                                        <StyledTableCell align="center">City</StyledTableCell>
                                        <StyledTableCell align="center">Comments</StyledTableCell>
                                        <StyledTableCell align="center">Time Slots</StyledTableCell>
                                        <StyledTableCell align="center">Phlebos</StyledTableCell>
                                        <StyledTableCell align="center">Max Booking Phlebo Slot</StyledTableCell>
                                        <StyledTableCell align="center">MapMyIndiaId</StyledTableCell>
                                        <StyledTableCell align="center">Active</StyledTableCell>
                                        <StyledTableCell align="center">GeoStatus</StyledTableCell>
                                        <StyledTableCell align="center">Edit</StyledTableCell>
                                    </StyledTableRow>
                                </TableHead>
                                <TableBody>
                                    {zoneList && zoneList.results && zoneList.results.length > 0 && zoneList.results.map((code: any, index: any) => {
                                        return (
                                            <StyledTableRow key={index}>
                                                <StyledTableCell align="center">
                                                    <div>
                                                        {code?.is_geofence ? (
                                                            <PreviewIcon onClick={() => handleClickOpen(code?.city?.id)} />
                                                        ) : "Geofence inactive"}

                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editZone === code.id ? (
                                                        <Grid item xs={12} sm={6} md={4}>
                                                            <Autocomplete
                                                                id="city"
                                                                onChange={(event, newValue) => {
                                                                    if (newValue) {
                                                                        let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                                                        setCity(obj.id)
                                                                        setCityName(obj.name)
                                                                    }
                                                                }}
                                                                options={cities}
                                                                freeSolo
                                                                blurOnSelect
                                                                aria-required
                                                                inputValue={cityName}
                                                                getOptionLabel={(option: any) => option.name}

                                                                renderInput={(params) => (
                                                                    <TextField className="input"
                                                                        {...params}
                                                                        placeholder="City"
                                                                        variant="outlined"
                                                                        style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    ) : (
                                                        code.city.name
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <div>
                                                        <MessageRounded onClick={() => handleClick(code.id)} />
                                                    </div>
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {editZone === code.id ? (
                                                        <Autocomplete
                                                            id="timeslots"
                                                            onChange={(event, newValue) => {
                                                                if (newValue) {
                                                                    let timeslotsArr: any = []
                                                                    let timeslotId: any = []
                                                                    newValue.map((val: any) => {
                                                                        let obj = JSON.parse(JSON.stringify(val, null, " "));
                                                                        timeslotsArr.push(obj)
                                                                        timeslotId.push(obj.id)
                                                                    })
                                                                    setTimeslots(timeslotsArr)
                                                                    setTimeslotsIds(timeslotId)
                                                                }
                                                            }}
                                                            value={timeslots}
                                                            multiple
                                                            disableClearable
                                                            limitTags={1}
                                                            options={timeslotsList.results}
                                                            freeSolo
                                                            blurOnSelect
                                                            getOptionDisabled={(option: any) => {
                                                                return timeslotsIds.includes(option.id)
                                                            }}
                                                            getOptionLabel={(option: any) => {
                                                                return `${option.start_time}-${option.end_time}`
                                                            }}
                                                            renderTags={(value: string[], getTagProps) =>
                                                                value.map((option: any, index: number) => (
                                                                    <Chip
                                                                        key={index}
                                                                        variant="default"
                                                                        color="primary"
                                                                        label={`${option.start_time}-${option.end_time}`}
                                                                        {...getTagProps({ index })}
                                                                    />
                                                                ))
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder="Time Slots"
                                                                    variant="outlined"
                                                                    style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                                    className="input"
                                                                />
                                                            )}
                                                        // disabled
                                                        />
                                                    ) : (
                                                        <React.Fragment>
                                                            {/* {code.time_slots.map((slot: any) => {
                                                                return (
                                                                    <p key={slot.id} style={{ margin: "0.5rem auto" }}>{moment(`${slot.start_time}`, ["HH.mm.ss"]).format("hh:mm A")} - {moment(`${slot.end_time}`, ["HH.mm.ss"]).format("hh:mm A")}</p>
                                                                )
                                                            })} */}
                                                            {code.time_slots.length > 0 ? (<AvTimerIcon onClick={() => handleTimeView(code.time_slots)} />) : "Not Available"}


                                                        </React.Fragment>
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editZone === code.id ? (
                                                        <Autocomplete
                                                            id="phlebo"
                                                            onChange={(event, newValue, reason, detail) => {
                                                                if (newValue) {
                                                                    let timeslotsArr: any = [];
                                                                    let phleboId: any = [];
                                                                    newValue.map((val: any) => {
                                                                        let obj = JSON.parse(
                                                                            JSON.stringify(val, null, " ")
                                                                        );
                                                                        timeslotsArr.push(obj);
                                                                        phleboId.push(obj.id);
                                                                    });
                                                                    setPhlebos(timeslotsArr);
                                                                    setPhlebosIds(phleboId);

                                                                    if (reason === "select-option") {
                                                                        const selectedId = detail?.option.id;

                                                                        if (removedIds.includes(selectedId)) {
                                                                            setRemovedIds((previousState) =>
                                                                                previousState.filter(
                                                                                    (id) => id !== selectedId
                                                                                )
                                                                            );
                                                                        }
                                                                    }

                                                                    if (reason === "remove-option") {
                                                                        setPhlebosIds((previousState: any[]) =>
                                                                            previousState.filter(
                                                                                (currentId: any) =>
                                                                                    currentId !== detail?.option.id
                                                                            )
                                                                        );
                                                                        setRemovedIds((previousState) => [
                                                                            ...previousState,
                                                                            detail?.option?.id,
                                                                        ]);
                                                                    }
                                                                }
                                                            }}
                                                            value={phlebos}
                                                            onInputChange={(event, newInputValue) => {
                                                                clearTimeout(timer.current)
                                                                timer.current = setTimeout(() => {
                                                                    getPhlebos(newInputValue)
                                                                    setPhlebosInitialLength(phlebos)
                                                                }, 1000)
                                                            }}
                                                            multiple
                                                            disableClearable
                                                            disableCloseOnSelect
                                                            limitTags={1}
                                                            options={phleboList?.results ? [...phleboList?.results, { id: 0, user: { name: "No Phlebo" } }] : []}
                                                            freeSolo
                                                            blurOnSelect
                                                            getOptionDisabled={(option: any) => {
                                                                return phlebosId.includes(option?.id)
                                                            }}
                                                            getOptionLabel={(option: any) => option?.user && option?.user?.name}
                                                            renderTags={(value: string[], getTagProps) =>
                                                                value.map((option: any, index: number) => (
                                                                    <Chip
                                                                        key={index}
                                                                        variant="default"
                                                                        color="primary"
                                                                        label={option?.user && option?.user?.name}
                                                                        {...getTagProps({ index })}
                                                                    />
                                                                ))
                                                            }
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder="Phlebo"
                                                                    variant="outlined"
                                                                    style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                                    className="input"
                                                                />
                                                            )}

                                                        />
                                                    ) : (
                                                        <React.Fragment>

                                                            {code?.phlebos.length > 0 ? (<ManIcon onClick={() => handlePhleboView(code?.phlebos)} />) : "Not Available"}
                                                        </React.Fragment>
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editZone === code?.id ? (
                                                        <TextField
                                                            name="slot"
                                                            type="text"
                                                            defaultValue={code?.max_booking_phlebs_slot}
                                                            value={maxSlot}
                                                            className="input"
                                                            variant="outlined"
                                                            placeholder="Max Booking Phlebo Slot"

                                                            onChange={(e) => {
                                                                setMaxSlot(e.target.value as string);

                                                            }}
                                                            style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                        />
                                                    ) : (
                                                        code.max_booking_phlebs_slot
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editZone === code.id ? (
                                                        <TextField
                                                            name="area"
                                                            type="text"
                                                            defaultValue={code?.mapmyindiaid}
                                                            value={mapMyIndiaId}
                                                            className="input"
                                                            variant="outlined"
                                                            placeholder="MapMyIndia Id"

                                                            onChange={(e) => {
                                                                setMapMyIndiaid(e.target.value as string);

                                                            }}
                                                            style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                        />
                                                    ) : (
                                                        code.mapmyindiaid
                                                    )}
                                                </StyledTableCell>

                                                <StyledTableCell align="center">
                                                    {editZone === code.id ? (
                                                        <Switch
                                                            defaultChecked={code?.is_active}
                                                            checked={isActive}
                                                            onChange={(e) => setIsActive(e.target.checked)}
                                                            name="isActive"
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                    ) : (
                                                        <DefaultSwitch
                                                            checked={code.is_active}
                                                            name="checkedA"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            disableRipple

                                                        //  onClick={() => disableZone(code)}
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {editZone === code.id ? (
                                                        <Switch
                                                            defaultChecked={code?.is_geofence}

                                                            checked={isGeoFence}
                                                            onChange={(e) => setIsGeoFence(e.target.checked)}
                                                            name="checkedA"
                                                            color="primary"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        />
                                                    ) : (
                                                        <DefaultSwitch
                                                            checked={code?.is_geofence}
                                                            name="checkedA"
                                                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                            disableRipple
                                                        />
                                                    )}
                                                </StyledTableCell>
                                                <TableCell align="center">
                                                    {editZone === code.id ? (
                                                        <>
                                                            <Button
                                                                color="primary"
                                                                variant="contained"
                                                                onClick={() => handleSubmit(code)}
                                                            >
                                                                <CheckCircleOutlineRounded />
                                                            </Button>
                                                            <Button
                                                                color="secondary"
                                                                variant="contained"
                                                                style={{ marginTop: "4px" }}
                                                                onClick={() => handleCancel()}
                                                            >
                                                                <CancelIcon />
                                                            </Button>
                                                        </>
                                                    ) : (
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={() => handleEditZone(code)}
                                                        >
                                                            <Edit />
                                                        </Button>
                                                    )}
                                                </TableCell>

                                            </StyledTableRow>

                                        )
                                    })}
                                </TableBody>
                                <BookingCommentsModal openModal={openModal} setopenModal={setopenModal} zoneId={zoneId} />
                                <TableFooter>
                                    <StyledTableRow>
                                        <TablePagination
                                            colSpan={2}
                                            count={zoneList?.count || 0}
                                            rowsPerPageOptions={[]}
                                            rowsPerPage={zoneList?.page_size || 20}
                                            page={page}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            onPageChange={handleChangePage}
                                        />
                                    </StyledTableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    </div>
                )}
                {zoneList && zoneList.length === 0 && (
                    <h3 className={classes.heading} style={{ textAlign: "center" }}>No Data Found</h3>
                )}
                <CreateZoneModal open={open} setOpen={setOpen} />
                <Dialog
                    fullScreen
                    open={openMapModal}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                MapMyIndia Polygon
                            </Typography>

                        </Toolbar>
                    </AppBar>
                    <ZoneViewMap cityId={cityId} />
                </Dialog>
                <Dialog
                    open={openTimeView}
                    onClose={handleTimeViewClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Available Time"}
                    </DialogTitle>
                    <DialogContent>
                        {timeSlotData && timeSlotData.map((slot: any) => {
                            return (
                                <ul>
                                    <li>
                                        {moment(`${slot.start_time}`, ["HH.mm.ss"]).format("hh:mm A")} - {moment(`${slot.end_time}`, ["HH.mm.ss"]).format("hh:mm A")}
                                    </li>
                                </ul>
                                // <p key={slot.id} style={{ margin: "0.5rem auto" }}></p>
                            )
                        })}
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={phleboDataOpen}
                    onClose={handlePhleboViewClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Available Phlebos"}
                    </DialogTitle>
                    <DialogContent>
                        {phleboData !== undefined ? phleboData.map((user: any) => {
                            return (
                                <ul>
                                    <li>
                                        {user.user.name}
                                    </li>
                                </ul>
                                // <p key={user.id} style={{ margin: "0.5rem auto" }}>{user.user.name}</p>
                            )
                        }) : ""}
                    </DialogContent>
                </Dialog>
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    timeslotsList: state.SalesLeadAdminReducer.timeslotsList,
    zoneList: state.SalesLeadAdminReducer.zoneList,
    phleboList: state.SalesLeadAdminReducer.phleboList,
    cities: state.SalesLeadAdminReducer.cities,
    loading: state.SalesLeadAdminReducer.loading,
})

export default connect(mapStateToProps, {
    getZone,
    getCities,
    updateZone,
    getTimeslots,
    getPhlebos,
    getZoneComments,
})(ZonePage);