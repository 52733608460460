import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useHistory } from "react-router-dom";
import { Button, Paper, Grid, TextField, MenuItem } from "@material-ui/core";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { sendReportById } from "../../actions/salesLeadAction";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "scroll",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: isMobile ? "400px" : "800px",
    maxHeight: "600px",
    overflow: "scroll",
    position: "sticky",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));

type ModalProps = {
  setOpenReportModel: Function;
  openReportModel: any;
  sendReportById: any;
  customerDetails: any;
  setCustomerDetails: any;
  objId: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  sendReportById,
  setOpenReportModel,
  openReportModel,
  customerDetails,
  setCustomerDetails,
  objId,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [disposition, setDisposition] = useState<any>("");
  const [file1, setFile1] = React.useState<any>([]);
  const [comment, setComment] = useState<any>("");
  const [buttonType, setButtonType] = useState<boolean>(false);
  const [id, setId] = useState(0);
  const [customer_email, setCustomer_email] = useState<String>("");
  const [remark, setRemark] = useState<any>("");
  const [checked, setChecked] = React.useState(false);
  const [report_sent_on_whatsapp, setReportSentOnWhatsApp] =
    useState<String>("");

  const handleClose = () => {
    setOpenReportModel(false);
    setButtonType(false);
  };
  const openCommentFun = () => {
    setButtonType(false);
  };

  const fileSelectedHandler1 = (e: React.ChangeEvent<{ files: any }>) => {
    setFile1(e.target.files[0]);
  };

  const handleSendEmail = async (id: any) => {
    await sendReportById(
      customerDetails?.add_member_booking !== null
        ? customerDetails?.add_member_booking.id
        : customerDetails?.booking?.id,
      `?email=${customer_email}&failed_report_id=${objId}&remarks=${remark}&disposition=${disposition}`,
      customerDetails?.add_member_booking !== null ? "add_booking" : "booking"
    );
    alert("Report Send Successfully");

    history.push(`/dashboard/sl/report_not_deliverd`);
  };
  const handleCheckChange = (event: any) => {
    setChecked(event.target.checked);
  };

  const handleCanelEmail = () => {
    history.push(`/dashboard/sl/report_not_deliverd`);
    setButtonType(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openReportModel}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openReportModel}>
        <div className={classes.paper}>
          <>
            <h3>Send Report From Here</h3>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  className="input"
                  name="name"
                  type="number"
                  label="Booking Id"
                  value={
                    customerDetails?.add_member_booking !== null
                      ? customerDetails?.add_member_booking?.id
                      : customerDetails?.booking?.id
                  }
                  variant="outlined"
                  //  onChange={(e) => setBookingId(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className="input"
                  label="Select disposition"
                  value={disposition}
                  select
                  variant="outlined"
                  onChange={(e) => setDisposition(e.target.value)}
                  style={{ width: "100%" }}
                >
                  <MenuItem value="Customer_is_not_responding_to_the_calls">
                    Customer is not responding to the calls
                  </MenuItem>
                  <MenuItem value="Customer_has_already_received_the_reports">
                    Customer has already received the reports
                  </MenuItem>
                  <MenuItem value="Reports_shared_over_whatsapp">
                    Reports shared over whatsapp
                  </MenuItem>
                  <MenuItem value="Reports_shared">Reports shared</MenuItem>
                </TextField>
              </Grid>
              {disposition !== "Reports_shared" ? (
                ""
              ) : (
                <Grid item xs={12}>
                  <TextField
                    name="customer_email"
                    type="email"
                    label="Enter Customer Email"
                    value={customer_email}
                    disabled={disposition !== "Reports_shared"}
                    className="input"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_email(e.target.value as String)
                    }
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  name="remark"
                  className="input"
                  // label="Remark"
                  label="Enter Remark"
                  variant="outlined"
                  style={{ margin: "0", width: "100%", marginTop: "1rem" }}
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
              </Grid>

              <Button
                variant="contained"
                style={{
                  marginTop: "20px",
                }}
                color="primary"
                onClick={() => handleSendEmail(id)}
                disabled={disposition === ""}
              >
                SEND REPORT
              </Button>
              <Button
                variant="contained"
                style={{
                  marginTop: "20px",
                  marginLeft: "10px",
                }}
                color="secondary"
                onClick={handleCanelEmail}
              >
                Cancel
              </Button>
            </Grid>
          </>
        </div>
      </Fade>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.SalesLeadReducer.loading,
  user: state.loginReducer.user,
});

export default connect(mapStateToProps, {
  sendReportById,
})(CommentsModal2);
