import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "./whatsAppChat.css";
import SearchIcon from "@material-ui/icons/Search";
import DonutLargeIcon from '@mui/icons-material/DonutLarge'
import ChatIcon from "@material-ui/icons/Chat";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { SearchOutlined, AttachFile } from '@material-ui/icons';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ArticleIcon from '@mui/icons-material/Article';
import Switch from '@mui/material/Switch';
import MicIcon from '@material-ui/icons/Mic';
import ReplayIcon from '@mui/icons-material/Replay';
import { Chip } from '@material-ui/core';
import { ACTIONS } from "../../interfaces/actionTypes/salesLeadAdminType";
import { 
  Avatar, 
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  MenuItem,
  Select,
 } from "@material-ui/core";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import InputAdornment from "@material-ui/core/InputAdornment";
import Pusher from "pusher-js";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Badge from '@mui/material/Badge';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from "@mui/material/Button";
import RaiseComplaintModal from "../comments/raiseTicketModal";
import TicketUsingLeadModal from "../comments/ticketUsingLead"
import ChangeAgentModal from "../comments/changeAgentModal";
import ResolvedChatModal from "../comments/resolvedChatModal";
import SendReportModel from "../../pages/ConsolidateReportBooking/sendReportModel";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import { connect } from "react-redux";
import Loader from "../../../loader.svg";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import moment from "moment";
import Pagination from '@mui/material/Pagination';
import TablePagination from '@mui/material/TablePagination';
import { AddBoxTwoTone, ConfirmationNumberRounded } from '@material-ui/icons';
import {
  getWhatsappComments,
  getIndividualWhatsappChat,
  sendWhatsappComments,
  updateLeadDetails,
  addComment,
  getPositiveCallsComments,
  checkGeoFenchMapMyIndiaPilot,
  getMapMyIndiaAccessToken,
  getPackage,
  signOutInWhatsappChat,
  getFailedReportData,
  resetLeadDetails,
  sendLastReportToLead,
  getLatestBookingUsingLead,
  getAllBookings,
  getUserDetails,
  getQueueNumber,
} from "../../actions/salesLeadAdminAction";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import WhatsAppModal from "../comments/whatsappModal"
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ImageIcon from "@mui/icons-material/Image";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { styled } from '@mui/material/styles';
import CloseIcon from '@material-ui/icons/Close';
import { keyframes } from '@mui/system';

const blink = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const BlinkedBox = styled('div')({
  backgroundColor: '#FC7438',
  width: 12,
  height: 12,
  borderRadius: 6,
  animation: `${blink} 1s linear infinite`,
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#7569b3",
    color: "white",
    boxShadow: theme.shadows[1],
    fontSize: 15,
    marginBottom: 10,
  },
}))(Tooltip);

const Input = styled('input')({
  display: 'none',
});

const badgeStyle = {
  "& .MuiBadge-badge": {
    color: 'white',
    backgroundColor: '#FC7438',
  }
}

const useStyles = makeStyles((theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },

    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "100%",
    },
    head: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "2rem",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "100%",
      padding: "20px 10px 10px 10px",
      background: "#ffffff",
      margin: "10px",
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    },
    packageLink: {
      color: "#ffffff",
      textDecoration: "none",
    },
    dialog: {
      zIndex: 2,
    },
  })
);

const WhatsAppChat = ({
  getWhatsappComments,
  whatsappComments,
  getIndividualWhatsappChat,
  individualWhatsappChat,
  sendWhatsappComments,
  updateLeadDetails,
  addComment,
  getPositiveCallsComments,
  checkGeoFenchMapMyIndiaPilot,
  getMapMyIndiaAccessToken,
  todays_positive_calls,
  complaints,
  whatsappDataFromPusher,
  loading,
  loadingChat,
  loadingIndividualChat,
  loadingComment,
  geoFencesMapMyIndia,
  getPackage,
  mapmyindiaAccessToken,
  packageList,
  signOutInWhatsappChat,
  getFailedReportData,
  failedReport,
  resetLeadDetails,
  sendLastReportToLead,
  getLatestBookingUsingLead,
  getAllBookings,
  latestBookingUsingLeadData,
  userDetails,
  getUserDetails,
  signOutData,
  getQueueNumber,
  queueNumber,
}) => {
  const ref = useRef();
  let dispatch = useDispatch();
  const searchRef = useRef();
  const attachRef = useRef();
  const timer = useRef(0);
  const inputRef = useRef(null);
  const classes = useStyles();
  const [tabs, settabs] = useState(1);
  const [tabs1, settabs1] = useState(2);
  const [chats, setChats] = useState("");
  const [packageChats, setPackageChats] = useState("");
  const [messageData, setmessageData] = useState({
    id: "",
    patient_name: "",
    dateThen: "",
    lead: ""
  });
  const [openTemplate, setopenTemplate] = useState(false);
  const [leadIdSearch, setleadIdSearch] = useState("");
  const [file, setFile] = React.useState();
  const [base64files, setbase64File] = React.useState([]);
  const [templateId, settemplateId] = useState();
  const [heights, setheights] = useState(0);
  const [divheights, setDivheights] = useState(0);
  const [id, setId] = useState("");
  const [bookingId, setbookingId] = useState("");
  const [open, setOpen] = React.useState(false);
  const [leadId, setLeadId] = useState("");
  const [lastMessageId, setLastMessageId] = useState("");
  const [patientName, setpatientName] = useState("");
  const [currentSelectedLead, setCurrentSelectedLead] = useState("");
  const [openFloat, setOpenFloat] = React.useState(false);
  const [raiseComplaint, setRaiseComplaint] = useState(false);
  const [ticketModal, setTicketModal] = useState(false);
  const [show, setShow] = useState(false);
  const [fileType, setFileType] = useState("");
  const history = useHistory();
  const [whatsAppTabs, setWhatsAppTabs] = useState("All");
  const [messageType, setMessageType] = useState("");
  const [leadUnread, setLeadUnread] = useState([]);
  const [loadMoreChats, setLoadMoreChats] = useState(false);
  const [loadMoreChatsPage, setLoadMoreChatsPage] = useState(2);
  const [leftContainer, setLeftContainer] = useState([]);
  const [openReportModel1, setOpenReportModel1] = useState(false);
  const [openResolvedChatModal, setOpenResolvedChatModal] = useState(false);
  const [pagination, setPagination] = React.useState(1);
  const [page, setPage] = React.useState(1);
  const [newPage, setNewPage] = React.useState(2);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [previousPage, setPreviousPage] = React.useState([]);
  const [listenerRegistered, setListenerRegistered] = React.useState(false);
  const [key, setKey] = React.useState(false);
  const [customerDetails, setCustomerDetails] = useState("");
  const [objId, setObjId] = useState("");
  const [openReportModel, setOpenReportModel] = useState(false);
  const [whatsappTemplateShow, setWhatsappTemplateShow] = React.useState(false);
  const [openWhatsapp, setOpenWhatsapp] = React.useState(false);
  const [whatsappLeadSearch, setWhatsappLeadSearch] = React.useState("")
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [cityId, setCityId] = useState("");
  const [customer_city, setCustomer_city] = useState("");
  const [dateOfLastMsgArray, setDateOfLastMsgArray] = useState("");
  const [dateOfLastMsg, setDateOfLastMsg] = useState("");
  const [personId, setPersonId] = React.useState('none');
  let dateYesterday = new Date();
  const [packageDetails, setPackageDetails] = useState([])
  const [tabLeadColor, setTabLeadColor] = React.useState(0);
  const [tabData, setTabData] = useState([]);
  const [packages, setPackages] = useState([])
  dateYesterday.setDate(dateYesterday.getDate() - 1);
  let dateAddingFiveThirty = new Date(dateYesterday.getTime() + (5*60+30)*60000);
  const [checked, setChecked] = useState(userDetails?.profile?.get_chat);
  const handleChange = (event) => {
    setChecked(event.target.checked);
    let body = {
      get_chat: event.target.checked,
    };
    signOutInWhatsappChat(event.target.checked, body);
  };

  useEffect(() => {
    if (signOutData?.msg === "success") {
      getUserDetails();
    }
  }, [signOutData]);

  useEffect(() => {
    getWhatsappComments("get_all=true&multiple_chat_status=active");
    scrollToBottom();
    getQueueNumber();
  }, []);

  const handleClickWhatsapp = () => {
    setOpenWhatsapp(true);
  };

  useEffect(() => {
    if (tabData?.length === 0) {
      setmessageData({
        id: "",
        patient_name: "",
        dateThen: "",
        lead: ""
      });
    }
  }, [tabData])

  useEffect(() => {
    if (!loadMoreChats) {
      setLeadUnread(individualWhatsappChat?.results)
      if (individualWhatsappChat?.results) {
        setDateOfLastMsgArray(individualWhatsappChat?.results.filter(function (el) {
          return el.msgtype === "1"
        }))
      }
    } else if (loadMoreChats) {
      setLeadUnread([...leadUnread, ...individualWhatsappChat?.results]);
      setLoadMoreChats(false)
    }
  }, [individualWhatsappChat]);

  useEffect(() => {
    if (loadMoreChats) {
      setLoadMoreChatsPage(loadMoreChatsPage + 1)
      getIndividualWhatsappChat(`lead=${messageData.id}&page=${loadMoreChatsPage}`);
    }
  }, [loadMoreChats]);

  useEffect(() => {
    if (dateOfLastMsgArray?.length > 0) {
      setDateOfLastMsg(dateOfLastMsgArray[0]?.created_at)
    } else {
      setWhatsappTemplateShow(true)
    }
  }, [dateOfLastMsgArray]);

  useEffect(() => {
    let timeDiff = moment(dateYesterday).isAfter(dateOfLastMsg);

    if (timeDiff === false) {
      setWhatsappTemplateShow(false)
    } else {
      setWhatsappTemplateShow(true)
    }
  }, [dateOfLastMsg])

  useEffect(() => {
    setLeftContainer(whatsappComments?.results)
    // if (whatsappComments?.count === 1) {
    //   setLeftContainer(whatsappComments.results);

    // }  else if (whatsappComments?.count > 1) {
    //   // Code commented (This was used for scrolling loader)
    //   // if (page === 2) {
    //   //   if (leftContainer?.length > 0 && whatsappComments?.results?.length > 0) {
    //   //     let newLeftContainer = [];
    //   //     newLeftContainer = leftContainer?.length > 0 && leftContainer.map((leftMan) =>  {
    //   //       return leftMan;
    //   //     })
    //   //     for(let i = 0; i < whatsappComments?.results?.length; i++) {
    //   //       newLeftContainer.push(whatsappComments.results[i]);
    //   //     }
    //   //     setLeftContainer(newLeftContainer)
    //   //   } else {
    //   //     setLeftContainer(whatsappComments.results)
    //   //   }
    //   //   // console.log(leftContainer, "leftContainer")
    //   // } else if (page === 1 && whatsappComments?.results?.length > 0)  {
    //   //   setLeftContainer(whatsappComments.results)
    //   //   // console.log(leftContainer, "leftContainer")
    //   //   setPage(2)
    //   // }
    //   setLeftContainer(whatsappComments.results)
    // }
  }, [whatsappComments]);

  // const changePanel = (data) => {
  //   settabs(data);
  //   allPerson(`?type=unread`);
  // };

  useEffect(() => {
    setLeftContainer([])
    setLeadUnread([])
    resetLeadDetails()
  },[])

  const handlePackagesChats = () => {
    setOpenLocationModal(false)
      setChats(packageDetails.reduce((accumulator, current) => {
        return accumulator.concat(
          `PACKAGE NAME :${" "}${current?.name}${". "}
          DESCRIPTION :${" "}${current?.description}${". "}
          TaT :${" "}${current?.package_city_prices
            ? current?.package_city_prices
              .tat_time
            : current?.tat_time}${". "}
          PRICE :${" "}${current?.package_city_prices
            ? current?.package_city_prices
              .offer_price
            : current?.offer_price}
          ${". "}
          FASTING :${" "}${current?.fasting_time}${"."}`
          );
        }, []).join(' '))
    }

  // console.log(packageDetails, "packageDetails")

  const AllChats = () => {
    setleadIdSearch("")
    setWhatsAppTabs("All")
    settabs(1)
    setPagination(1)
    getWhatsappComments("get_all=true&multiple_chat_status=active")
    setCityId("")
    setPackages([])
    setPackageDetails([])
  }

  const unreadChats = () => {
    setleadIdSearch("")
    setWhatsAppTabs("unread")
    settabs(2)
    setPagination(1)
    getWhatsappComments(`get_all=true&multiple_chat_status=inactive,hidden,inqueue`)
    setCityId("")
    setPackages([])
    setPackageDetails([])
  }

  const chat = (data) => {
    settabs1(data);
    if (data === 1) {
      // readCallDetails(messageData.id);
    }
    if (data === 2) {
      getIndividualWhatsappChat(`lead=${messageData.id}`);
    }

    // allPerson(`?type=unread`);
  };
  const specificMessage = (lead, mid, patient_name, msgDate, leadId) => {
    setmessageData({
      id: lead,
      patient_name: patient_name,
      dateThen: msgDate,
      lead: leadId
    });
    setTabLeadColor(lead);
    setLoadMoreChats(false);
    setLoadMoreChatsPage(2);
    setLastMessageId(mid)
    setChats("");
    setMessageType("")
    setFileNameValue("")
    setFileName("")
    setShow(false)
    setheights(0)
    setDivheights(0)
    setCityId("")
    setPackages([])
    setPackageDetails([])
    dispatch({ type: ACTIONS.GET_PACKAGES, payload: [] });
    // console.log(msgDate, dateAddingFiveThirty, dateYesterday, "sate")
    setCurrentSelectedLead(lead);
    setId(mid);
    getPositiveCallsComments(lead);
    getIndividualWhatsappChat(`lead=${lead}`);
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("file_name", fileNameValue);
    formData.append("message_type", messageType === "application/pdf" ? "document" : "image");
    formData.append("file", fileName);
    let body = {
      comment: chats,
      callId: currentSelectedLead,
    };
    // let body1 = {
    //   message:fileNameValue,
    //   message_type: fileType==="jpeg"?"image":"document",
    //   file: fileName
    // };
    const body3 = { message: chats };

    if (tabs1 === 1) {
      addComment(body);
      setChats("");
      getIndividualWhatsappChat(`lead=${messageData.id}`);
      setleadIdSearch(currentSelectedLead);
      setPagination(1)
      getWhatsappComments(`get_all=true&multiple_chat_status=active&search=${currentSelectedLead}`);
    }
    if (tabs1 === 2) {
      // await axios.post(awsPostUrl, formData)
      if (body3 === "") {
        alert("Please Provide Message.");
      } else {
        setChats("");
        setPackages([])
        setPackageDetails([])
        setMessageType("")
        setFileNameValue("")
        setFileName("")
        setheights(0);
        setDivheights(0);
        await sendWhatsappComments(individualWhatsappChat?.results[0]?.id, messageType === "" ? body3 : formData);
        // setleadIdSearch("");
        await getIndividualWhatsappChat(`lead=${messageData.id}`);
        if (individualWhatsappChat?.results[0]?.msgtype === "1") {

          if (leadIdSearch !== "")  {
            setPagination(1);
            await getWhatsappComments(`get_all=true&multiple_chat_status=active&search=${leadIdSearch}`);
          }
          else  {
            setLeftContainer([]);
            setPagination(1);
            getWhatsappComments("get_all=true&multiple_chat_status=active");
          }
        }
        // setTimeout(() => {
        //   getIndividualWhatsappChat(`lead=${messageData.id}`);
        //   getWhatsappComments();
        // }, 1000);
      }
    }
  };

  const scrollToBottom = () => {
    ref?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setPagination(1);
      if (whatsAppTabs === "All") {
        getWhatsappComments(`get_all=true&multiple_chat_status=active&search=${leadIdSearch}`);
      } else {
        getWhatsappComments(`get_all=true&multiple_chat_status=inactive,hidden,inqueue&search=${leadIdSearch}`);
      }
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  // const handleFileChange = async (event) => {
  //   let fileName = [{ id: "", uploaded_file: "" }];
  //   let fileList = [];
  //   let base64 = [];
  //   for (let i = 0; i < event.target.files.length; i++) {

  //     let fileKey = `file${i}`;
  //     let baseString = await toBase64(event.target.files[i]);
  //     base64.push(baseString);
  //     fileList.push([
  //       {
  //         id: i,
  //         uploaded_file: event.target.files[i].name,
  //       },
  //     ]);
  //   }

  //   for (let k in fileList) {
  //     fileName.push(fileList[k][0].uploaded_file);
  //   }
  //   fileName.shift();
  //   setChats(fileName.toString());
  //   setFile(event.target.files);
  //   setbase64File(base64);
  // };
  const [fileName, setFileName] = useState();
  const [fileNameValue, setFileNameValue] = useState("");
  const handleFileChange = async (e, type) => {
    setFileType(type);
    setFileName(e.target.files[0]);
    setMessageType(e.target.files[0]?.type);
    setFileNameValue(e.target.files[0]?.name);
    setChats(e.target.files[0]?.name);
    setOpenFloat(false);
    setShow(!show);
    setKey(!key);
  };

  const onChangeHeight = (element) => {
    let text = document.getElementById("myTextarea").value;
    let lines = text.split(/\r|\r\n|\n/);
    let count = lines.length;
    setChats(element);
    if (heights > 0 && heights < 7) {
      setDivheights(11 * heights);
    }
    setheights(count);
  };

  const handleClickOpen = (id) => {
    setLeadId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLeadUpdate = async () => {
    const body = {
      patient_name: patientName,
    };
    await updateLeadDetails(body, leadId);
    getWhatsappComments();
    getIndividualWhatsappChat(`lead=${currentSelectedLead}`);
    setpatientName("");
    setOpen(false);
  };
  //AWS Integration Start
  const [awsPostUrl, setAwsPostUrl] = useState("");
  const [awsAwsField, setAwsField] = useState({
    acl: "",
    key: "",
    xamzalgorithm: "",
    xamzcredential: "",
    xamzdate: "20220513T074356Z",
    policy: "",
    xamzsignature: "",
  });
  const aws3GetApi = () => {
    const aws3Url = `https://api.redcliffelabs.com/api/v1/ppmc/get-presigned-s3/?extension=${fileType}&path=media`;
    axios
      .get(aws3Url)
      .then((res) => {
        setAwsPostUrl(res?.data?.url);
        setAwsField({
          acl: Object.values(res?.data?.fields)[0],
          key: Object.values(res?.data?.fields)[1],
          xamzalgorithm: Object.values(res?.data?.fields)[2],
          xamzcredential: Object.values(res?.data?.fields)[3],
          xamzdate: Object.values(res?.data?.fields)[4],
          policy: Object.values(res?.data?.fields)[5],
          xamzsignature: Object.values(res?.data?.fields)[6],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    aws3GetApi();
  }, [fileType]);
  //AWS Integration End
  const handleSearch = () => {
    setPagination(1);
    getWhatsappComments(`get_all=true&multiple_chat_status=active&search=${leadIdSearch}`);
  };

  const handleDownloadFile = (url, filename) => {
    fetch(url).then(function (t) {
      return t.blob().then((b) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", filename);
        a.click();
      }
      );
    });
  }

  useEffect(() => {
    if (leadUnread !== undefined && leftContainer!== undefined && whatsappDataFromPusher?.id) {
      let newLeftContainer = [];
      let tmpLeftMan = {};
        if (messageData?.id && messageData?.id === whatsappDataFromPusher.id) {
          setLeadUnread([whatsappDataFromPusher, ...leadUnread]);
        }
        // Commenting as pusher data is not matching for now
        newLeftContainer = leftContainer.filter((leftMan) =>  {
          if (leftMan?.id && leftMan?.id !== whatsappDataFromPusher?.id) {
            return leftMan;
          }
        })
        newLeftContainer.length && whatsappDataFromPusher.id !== undefined && whatsappDataFromPusher.id !== "undefined" && setLeftContainer([whatsappDataFromPusher, ...newLeftContainer]);
    }
    if (tabData !== undefined && whatsappDataFromPusher?.id) {
      let newTabConatiner = [];
        newTabConatiner = tabData.filter((leftMan) =>  {
          if (leftMan?.id && leftMan?.id === whatsappDataFromPusher?.id) {
            leftMan["notification"] = true;
            return leftMan;
          }  else {
            return leftMan;
          }
        })
        newTabConatiner.length && whatsappDataFromPusher.id !== undefined && whatsappDataFromPusher.id !== "undefined" && setTabData([...newTabConatiner]);
    }
  }, [whatsappDataFromPusher]);

  const limit = whatsappComments?.count / 20;
  let fetchinPage = false;

  // const loadMoreChats = async (evt) => {
  //   let myScrollTop = evt.target.scrollTop;
  //   let myScrollHeight = evt.target.scrollHeight;
  //   let diff = myScrollHeight - myScrollTop;
  //   let height = evt.target.clientHeight;
  //   if(diff < (height + 100) && limit > page){
  //     if(fetchinPage){
  //       // console.log("Scrolled I will not fire", evt);
  //     }else{
  //       fetchinPage = true;
  //       // console.log("Scrolled I will fire", evt);
  //       try {
  //           if (limit > newPage) {
  //               if (previousPage.indexOf(newPage) === -1) {
  //                 setPreviousPage([...previousPage,newPage])
  //                 setNewPage(newPage+1);
  //                 await getWhatsappComments(`page=${newPage}`);
  //               }
  //           }
  //       } catch (error) {
  //           console.log(error.message);
  //       }
  //       setTimeout(() => {fetchinPage=false;}, 5000);
  //     }
  //     return;
  //   }
  //   return;
  // };

  const handleChangePage = (
    event, newPage
  ) => {
    if (whatsAppTabs === "All") {
      getWhatsappComments(`get_all=true&multiple_chat_status=active&page=${newPage}`);
    } else if (whatsAppTabs === "unread") {
      getWhatsappComments(`get_all=true&multiple_chat_status=inactive,hidden,inqueue&page=${newPage}`);
    }
    setPagination(newPage);
  };

  const handleSubmit = event => {
    event.preventDefault();
  };

  useEffect(() => {
    if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
      // setCustomerLatitude(geoFencesMapMyIndia?.latitude);
      // setCustomerLongitude(geoFencesMapMyIndia?.longitude);
      // const zone_Id = geoFencesMapMyIndia?.customer_zone;
      const city_id = geoFencesMapMyIndia?.cityid;
      // const area_id = geoFencesMapMyIndia?.area_id;
      if (city_id) {
        // setZoneId(zone_Id);
        setCityId(city_id);
        // if(Number(city_id)!==Number(cityId)){
        //   setPackageKey(random)
        //   clearPackage()
        // }
        setCustomer_city(geoFencesMapMyIndia?.zone_name);
        // setIsPincode(false);
        // setPackageKey(city_id);
        // setCustomer_areapincode(pincode as string);
        // setCustomer_areapincode_id(area_id as Number)
        getPackage(`city_id=${city_id}`);
        // setBookingEnabled(true);
      } else {
        // setBookingEnabled(false);
        // setErrorMessage("Please try again. Zone or pin code is not enabled.");
      }

    }
  }, [geoFencesMapMyIndia]);

  //mapmyindia


  // useEffect(() => {
  //   if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
  //     setCustomerLatitude(geoFencesMapMyIndia?.latitude);
  //     setCustomerLongitude(geoFencesMapMyIndia?.longitude);
  //   }
  // }, [geoFencesMapMyIndia]);

  //mapmyindia
  const [suggestedAddress, setSuggestedAddres] = useState([]);
  const [mapAddressObject, setMapAddressObject] = useState({});
  const [accessToken, setAccessToken] = useState("");
  const [inputList, setInputList] = useState([
    {
      customerPackage: [],
    },
  ]);

  // useEffect(() => {
  //   if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
  //     setCustomerLatitude(geoFencesMapMyIndia?.latitude);
  //     setCustomerLongitude(geoFencesMapMyIndia?.longitude);
  //   }
  // }, [geoFencesMapMyIndia]);

  useEffect(() => {
    if (mapmyindiaAccessToken?.accesstoken) {
      setAccessToken(mapmyindiaAccessToken?.accesstoken);
    }
  }, [mapmyindiaAccessToken]);

  useEffect(() => {
    if (openLocationModal === true) {
      getMapMyIndiaAccessToken();
    }
  }, [openLocationModal]);

  useEffect(() => {
    if (userDetails?.profile?.get_chat === true) {
      setChecked(true);
      // let body = {
      //   get_chat: true,
      // };
      // signOutInWhatsappChat(true, body);
      // // getUserDetails();
    }
  }, [userDetails]);

  useEffect(() => {
    if (mapAddressObject?.eLoc) {
      // resetAddressComponent();

      const displayAddress = `${mapAddressObject?.placeName}, ${mapAddressObject?.placeAddress}`;
      //getPinCode(mapAddressObject?.addressTokens?.pincode);
      // setCustomer_landmark(displayAddress);
      // setPinCode(mapAddressObject?.addressTokens?.pincode);
      if (mapAddressObject?.addressTokens?.pincode !== "") {
        // setErrorMessage("")
        checkGeoFenchMapMyIndiaPilot(mapAddressObject?.eLoc);
      }
      else {
        // setErrorMessage("Please select complete locality from dorpdown with pincode")
      }
    }
  }, [mapAddressObject]);

  const locationAutoCompleteSearch = (val, e) => {
    if (val.length > 2) {
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName = "jsonp_callback_" + "data" + id;
        window[callbackName] = (data) => {
          delete window[callbackName];
          const ele = document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          const suggestedData = data?.suggestedLocations;
          if (suggestedData.length > 0) {
            const removePincode = data?.suggestedLocations.filter(
              (data) => data.type !== "PINCODE"
            );
            // setZoneKey(suggestedData.length);
            setSuggestedAddres(removePincode);
          }
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          document.getElementsByTagName("head")[0] ||
          document.body ||
          document.documentElement
        ).appendChild(script);
      });
    }
  };

  const handlePackage = (index, customerPackages) => {
    const list = [...inputList];
    list[index]["customerPackage"] = customerPackages;
    setInputList(list);
    let accumulatedPrice = 0;
    if (customerPackages && customerPackages.length > 0) {
      // let atLeastOneNormalPackage = checkNormalPackage(customerPackages);
      // customerPackages.map((item) => {
      //   if (
      //     atLeastOneNormalPackage &&
      //     item.is_addon &&
      //     item.addon_price &&
      //     item.addon_price > 0
      //   ) {
      //     accumulatedPrice = accumulatedPrice + item.addon_price;
      //   } else {
      //     if (item?.package_city_prices === null) {
      //       accumulatedPrice = accumulatedPrice + item.offer_price;
      //     } else {
      //       accumulatedPrice =
      //         accumulatedPrice + item.package_city_prices.offer_price;
      //     }
      //   }
      // });
    }
    // set price and package for row
    list[index]["packagePrice"] = accumulatedPrice;
    setInputList(list);

    //call function for buy one get one free
    if (inputList?.length == 1) {
      // checkBuyOneGetOneFreePackage(index, customerPackages);
    }
    let allPackagePrice = 0;
    inputList &&
      inputList.map((rowItem) => {
        allPackagePrice = allPackagePrice + rowItem?.packagePrice;
      });
    //Minus By One Get One Price
    // let pl50Count = inputList?.length > 0 ? getNoofPackageBuyOneGetOne() : 0;
    // if (pl50Count > 1 && inputList?.length > 1) {
    //   let noOfFreePackage = 0;
    //   if (pl50Count === 2) {
    //     noOfFreePackage = 1;
    //   } else if (pl50Count === 3) {
    //     noOfFreePackage = 1;
    //   } else if (pl50Count === 4) {
    //     noOfFreePackage = 2;
    //   } else if (pl50Count === 5) {
    //     noOfFreePackage = 2;
    //   } else if (pl50Count === 6) {
    //     noOfFreePackage = 3;
    //   } else {
    //     noOfFreePackage = 0;
    //   }
    //   if (noOfFreePackage > 0) {
    //     let buyOneGetOnePrice = buyOneGetOnePackagePrice();
    //     let freePackagepPrice = noOfFreePackage * buyOneGetOnePrice;
    //     allPackagePrice = allPackagePrice - freePackagepPrice;
    //   }
    // }
    // setPrice(Math.floor(allPackagePrice));
    // setDiscount(0);
    // setTotalPrice(Math.floor(allPackagePrice));
    // setCoupon(0);
    // setCouponCode("");
    // setRedCashCheck(false);
    // if (allPackagePrice > min_phlebo_cost) {
    //   setExtraCharge(false);
    // } else {
    //   setExtraCharge(true);
    // }
  };

  const handleChatAgent = (data) => {
    setLeadId(data);
    setOpenReportModel1(!openReportModel1);
  };

  const handleResolvedChat = (data) => {
    setLeadId(data);
    setOpenResolvedChatModal(!openResolvedChatModal);
  };

  const handleSendReportApi = (data) => {
    getLatestBookingUsingLead(data);
  };

  useEffect(() => {
    if (latestBookingUsingLeadData && latestBookingUsingLeadData?.result?.length > 0) {
      setOpenReportModal(true)
    }
  }, [latestBookingUsingLeadData])

  const handleSendReport = () => {
    setOpenReportModal(false)
    const body = {
      chat_id: lastMessageId,
      lead_id: messageData?.lead,
      add_booking_id: addBookingIdReport,
    };
    if (body["add_booking_id"] === "" || body["add_booking_id"] === null || body["add_booking_id"] === undefined) {
      delete body["add_booking_id"];
    }
    setBookingIdReport("")
    setAddBookingIdReport("")
    setPersonId("none")
    sendLastReportToLead(body, messageData?.lead)
  };

  const handleChangeReport = (event, data) => {
    // console.log(event, event.target, event.target.value, data, "valuejj")
    setPersonId(event.target.value);
	};

  const [bookingIdReport, setBookingIdReport] = React.useState("");
  const [addBookingIdReport, setAddBookingIdReport] = React.useState("");

  const handleReportData = (event, data) => {
    // console.log(event, event.target, event.target.value, data, "valuejjk")
    if (data?.add_booking_id !== null) {
      setAddBookingIdReport(data?.add_booking_id)
      setBookingIdReport("")
    } else {
      setBookingIdReport(data?.booking_id)
      setAddBookingIdReport("")
    }
	};

  // const sendMessage = (e) => {
  //   e.preventDefault();
  //   console.log("logic of sent here")
  //   setInput("")
  // }

  // console.log(leadUnread, "messagesALL")
  // console.log(leftContainer, "messagesALL")

  return (
    <div className="app">
      <div className="app_body">
        <div className="sidebar">
          <div style={{ height: "75px" }}>
            <div className="sidebar_header">
              <div style={{ display: "flex" }}>
                <h2 style={{ fontSize: "16px", width: "140px", paddingTop: "10px", paddingLeft: "5px", fontWeight: "900" }}>Whatsapp Chat</h2>
              </div>
              <div className="sidebar_headerRight">
                <h3 style={{ fontSize: "16px", width: "140px", paddingTop: "10px", color: "red", textDecorationLine: "underline" }} className="blink">{queueNumber?.result} users in queue</h3>
                <Switch
                  checked={checked}
                  onChange={handleChange}
                  color="success"
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </div>
            </div>
            <div className="sidebar_search">
              <div className="sidebar_searchContainer">
                <SearchOutlined />
                <input 
                  placeholder="Search booking, lead, ph number" 
                  value={leadIdSearch}
                  onChange={(e) => setleadIdSearch(e.target.value.replace(/[^0-9]/, ''))}
                  ref={searchRef}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
          </div>
          <div className="whatsAppCallTab">
            <div
              // onClick={() => changePanel(1)}
              onClick={() => AllChats()}
              style={
                tabs === 1 || whatsAppTabs === "All"
                  ? {
                    borderBottom: "2px solid #7569B3",
                    color: "#7569B3",
                    fontWeight: "600",
                    backgroundColor: "#bebedb",
                  }
                  : {
                    borderBottom: "2px solid #D3D3D3",
                    color: "#D3D3D3",
                    fontWeight: "600",
                    backgroundColor: "#ffffff",
                  }
              }
            >
              <h2 style={{ fontSize: "16px" }}>Active</h2>
            </div>
            <div
              // onClick={() => changePanel(2)}
              onClick={() => unreadChats()}
              style={
                tabs === 2 || whatsAppTabs === "unread"
                  ? {
                    borderBottom: "2px solid #7569B3",
                    color: "#7569B3",
                    fontWeight: "600",
                    backgroundColor: "#bebedb",
                  }
                  : {
                    borderBottom: "2px solid #D3D3D3",
                    color: "#D3D3D3",
                    fontWeight: "600",
                    backgroundColor: "#ffffff",
                  }
              }
            >
              <h2 style={{ fontSize: "16px" }}>On Hold</h2>
            </div>
          </div>
          <div className="sidebar_chats">
            {
              loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img src={Loader} width="50" height="50" />
                </div>
              ) : (
              leftContainer?.length > 0 ? (
                leftContainer.map((row) => (
                  <div
                    className="whatsAppCallNamesSingle"
                    style={
                      messageData?.id === row?.id
                        ? { backgroundColor: "#ebebeb" }
                        : {}
                    }
                    onClick={() => {
                      let newTabConatiner = [];
                      // if (messageData?.id && messageData?.id.toString() === whatsappDataFromPusher.id) {
                      //   setLeadUnread([...leadUnread, whatsappDataFromPusher]);
                      // }
                      newTabConatiner = tabData.filter((leftMan) =>  {
                        if (leftMan?.id && row?.id && leftMan?.id !== row?.id) {
                          return leftMan;
                        }
                      })
                      row["notification"] = false;
                      if (newTabConatiner.length && row?.id) {
                        setTabData([...newTabConatiner, row]);
                      } else {
                        setTabData([row])
                      }
                      specificMessage(row?.id, row?.latest_chat_id, row?.patient_name, row?.latest_chat_created_at, row?.id)
                    }}
                    // key={`${row?.id} + ${row?.msgtype} + ${row?.message} + ${row?.messageid} + ${row?.latest_chat_id}`}
                  >
                    <div className="sidebarChat">
                      <Avatar
                      src={`https://avatars.dicebear.com/api/human/${row?.id}.svg`}
                      />
                      <div className="sidebarChat_info">
                        <h2>{row?.patient_name} - {row?.id}</h2>
                        <p>{new Date(row?.latest_chat_created_at).toLocaleString()}</p>
                      </div>
                    </div>
                    <div className="badgeinfo" style={{ marginBottom: "40px" }}>
                      { 
                        (row?.latest_chat_msgtype === "1" || (row?.latest_chat_msgtype === "2" && row?.panel_user === "SYSTEM")) ?
                        <Badge sx={badgeStyle} color="secondary" badgeContent={"Unread"} max={99}>
                        </Badge> : 
                        ""
                      }
                    </div>
                  </div>
                ))
              ) : (
                <p style={{ fontSize: "16px", fontWeight: "700", padding: "10px" }}>Please Wait. No Chats are available. We'll be assigning new chats soon.</p>
              ))
            }
            {
              (leftContainer?.length > 0 && whatsappComments?.count > whatsappComments?.page_size) ? (
              <Pagination
                count={Math.ceil(whatsappComments?.count/20) || 0}
                page={pagination}
                // siblingCount={0}
                // boundaryCount={0}
                onChange={handleChangePage}
                disabled={loading}
              />) : ""
            }
          </div>
        </div>
        <div className="chat">
          <div className="chat_header_upper">
            {
              tabData?.length > 0 && tabData.map((row, index) => (
                <div 
                style={
                  tabLeadColor === row?.id
                    ? { backgroundColor: "#ebebeb", display: "flex", cursor: "pointer", paddingTop: "3px", paddingLeft: "16px", borderRadius: "15px 15px 0px 0px" }
                    : { display: "flex", cursor: "pointer", paddingTop: "3px", paddingLeft: "16px", borderRadius: "0px 0px 15px 15px" }
                }
                className="tab"
                >
                  <button 
                    className="tablinks"
                    style={{ 
                      fontSize: "16px",
                      padding: "3px 10px 3px 0px",
                      cursor: "pointer",
                      width: "140px",
                      height: "40px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    onClick={() => {
                      specificMessage(row?.id, row?.latest_chat_id, row?.patient_name, row?.latest_chat_created_at, row?.id)
                    }}
                  >
                    {row?.patient_name ? row?.patient_name : row?.id}
                  </button>
                  {row.notification === true ? 
                      <div style={{ right: "15px" }}>
                        <BlinkedBox />
                      </div>
                      : ""
                    }
                  <CloseIcon 
                    className="cross"
                    style={{ padding: "2px" }}
                    onClick={() => {
                      const list = [...tabData]
                      list.splice(index, 1)
                      if (tabLeadColor === tabData[index]?.id && tabData?.length > 1) {
                        let num = index === 0 ? 1 : index - 1;
                        specificMessage(tabData[num]?.id, tabData[num]?.latest_chat_id, tabData[num]?.patient_name, tabData[num]?.latest_chat_created_at, tabData[num]?.id)
                      }
                      setTabData(list)
                    }}
                  />
                </div>
              ))
            }
          </div>
          { messageData?.id && tabData?.length > 0 ?
          <div className="chat_header">
            <Avatar
              src={`https://avatars.dicebear.com/api/human/${messageData?.id}.svg`}
            />
            <div className="chat_headerInfo">
              <h3>{messageData?.patient_name}</h3>
              <p>Id - {messageData?.id}</p>
            </div>
            <div className="chat_headerRight">
              {/* <IconButton>
              <Tooltip title="Select Location" arrow>
                <LocationOnIcon 
                  onClick={() => setOpenLocationModal(true)}
                  style={{ color: "#924A91" }} />
              </Tooltip>
              </IconButton> */}
              <div className="header_search">
                <div className="header_searchContainer"
                  onClick={() => setOpenLocationModal(true)}
                >
                  {/* <Tooltip title="Select Location" arrow> */}
                    <SearchOutlined />
                    <input 
                      placeholder="Search packages here" 
                      disabled
                    />
                    <LocationOnIcon 
                    style={{ color: "#924A91" }} />
                  {/* </Tooltip> */}
                  {/* <Autocomplete
                      onChange={(event, newValue) => {
                          let sourceIds = [];
                          if (newValue) {
                              let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                              sourceIds = obj.map((item) => item);
                          }
                          setPackageDetails(sourceIds);
                      }}
                      freeSolo
                      multiple
                      id="checkboxes-tags-demo"
                      disabled={cityId === ""}
                      options={packageList?.results}
                      // disableCloseOnSelect
                      limitTags={0}
                      // disableClearable
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current)
                        timer.current = setTimeout(() => {
                          getPackage(`city_id=${cityId}&search=${newInputValue}`);
                        }, 1000)
                      }}
                      getOptionLabel={(option) => { return option.name && option.package_city_prices ? option?.code + " " + option.name.toString() + ", " + option.package_city_prices.tat_time : option?.code + " " + option.name + ", " + option.tat_time }}
                      getOptionDisabled={(option) => {
                          return packages.includes(option.id);
                      }}
                      renderInput={(params) => (
                          <TextField
                              className="input"
                              {...params}
                              // label="Package"
                              placeholder="Search packages here" 
                              variant="outlined"
                              // InputProps={{
                              //   endAdornment: (
                              //     <InputAdornment>
                              //       <IconButton>
                              //         <SearchOutlined />
                              //       </IconButton>
                              //     </InputAdornment>
                              //   )
                              // }}
                              style={{ width: "100%", fontSize: "12px", margin: "0", padding: "0", backgroundColor: "#e8e3e3", minWidth:"110px" }}
                          />
                      )}
                  /> */}
                </div>
              </div>
              <IconButton>
                <Tooltip title="Tickets" arrow>
                  <LocalActivityIcon 
                    variant={!ticketModal ? "contained" : "outlined"}
                    style={{ color: "#924A91" }}
                    onClick={ () => {
                      setTicketModal(!ticketModal)
                      setLeadId(messageData?.id)
                  }} />
                </Tooltip>
              </IconButton>
              <IconButton>
                <Tooltip title="Raise a Ticket" arrow>
                  <ConfirmationNumberIcon 
                    variant={!raiseComplaint ? "contained" : "outlined"}
                    style={{ color: "#924A91" }}
                    onClick={ () => {
                      setRaiseComplaint(!raiseComplaint)
                      setLeadId(messageData?.id)
                      getAllBookings(`lead=${messageData?.id}`)
                  }} />
                </Tooltip>
              </IconButton>
              <IconButton>
                <Link to={`/dashboard/sla/fill-info?id=${messageData?.id}`} target="_blank">
                  <Tooltip title="Customer Details" arrow>
                      <AccountBoxIcon style={{ color: "#924A91" }} />
                  </Tooltip>
                </Link>
              </IconButton>
              <IconButton>
                <Link to={`/dashboard/sla/booking/?lead=${messageData?.id}`} target="_blank">
                  <Tooltip title="New Booking" arrow>
                      <FiberNewIcon style={{ color: "#924A91" }} />
                  </Tooltip>
                </Link>
              </IconButton>
              <IconButton>
                <Tooltip title="Support" arrow>
                  <SupportAgentIcon 
                    style={{ color: "#924A91" }} 
                    onClick={() => handleChatAgent(messageData?.id)}
                  />
                </Tooltip>
              </IconButton>
              <IconButton>
                <Tooltip title="Report" arrow>
                  <ArticleIcon 
                    style={{ color: "#924A91" }} 
                    onClick={() => handleSendReportApi(messageData?.id)}
                  />
                </Tooltip>
              </IconButton>
              <IconButton>
                <Tooltip title="Resolved" arrow>
                  <CheckBoxIcon 
                    style={{ color: "#924A91" }} 
                    onClick={() => handleResolvedChat(messageData?.id)}
                  />
                </Tooltip>
              </IconButton>
            </div>
            <div className="toggleChats">
              <p
                onClick={() => chat(1)}
                style={
                  tabs1 === 1
                    ? {
                      borderBottom: "2px solid #7569B3",
                      color: "#7569B3",
                      fontWeight: "600",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "40%",
                      cursor: "pointer",
                    }
                    : {
                      borderBottom: "2px solid #D3D3D3",
                      color: "#D3D3D3",
                      fontWeight: "600",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "40%",
                      cursor: "pointer",
                    }
                }
              >
                Comment
              </p>
              <p
                onClick={() => chat(2)}
                style={
                  tabs1 === 2
                    ? {
                      borderBottom: "2px solid #7569B3",
                      color: "#7569B3",
                      fontWeight: "600",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "40%",
                      cursor: "pointer",
                    }
                    : {
                      borderBottom: "2px solid #D3D3D3",
                      color: "#D3D3D3",
                      fontWeight: "600",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "40%",
                      cursor: "pointer",
                    }
                }
              >
                Chats
              </p>
            </div>
          </div> : null }
          <div className="chat_body">
          {
            tabs1 === 2 && tabData?.length > 0 && leadUnread?.length > 0 ? ( [...leadUnread].map((row, index) => (
              <>
                <p className={`chat_message ${row?.msgtype === "2" && "chat_receiver"}`}>
                  <span className="chat_name">{ row?.msgtype === "2" ? row?.panel_user : row?.patient_name }</span>
                  {(row?.file_url === "" || row?.file_url === null) ? row?.message : (
                    <>
                      {row?.file_url?.includes("pdf") ? (
                        row?.msgtype === "2" ?
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                          <Tooltip title="Click on the icon to download the pdf" arrow followCursor>
                            <embed
                              src={`${row?.file_url}`}
                              alt="whatsapp_image"
                              style={{ width: "300px", height: "100px", padding: "20px 0px 0px 0px" }}
                            />
                          </Tooltip>
                          <div className="download_icon" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "300px" }}>
                            <DownloadForOfflineIcon onClick={() => handleDownloadFile(row?.file_url, row?.patient_name ? row?.patient_name : row?.lead)} />
                            <span className="chat_timestamp" style={{ marginTop: "10px" }}>{new Date(row?.created_at).toLocaleString()}</span>
                          </div>
                        </div>
                        :
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                          <Tooltip title="Click on the icon to download the pdf" arrow followCursor>
                            <img
                              src={`https://is4-ssl.mzstatic.com/image/thumb/Purple122/v4/6d/d2/02/6dd2028a-ef9d-9166-9848-bbf9fefde374/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/460x0w.webp`}
                              alt="whatsapp_image"
                              style={{ width: "300px", height: "100px", padding: "20px 0px 0px 0px" }}
                            />
                          </Tooltip>
                          <div className="download_icon" style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "300px" }}>
                            <DownloadForOfflineIcon onClick={() => handleDownloadFile(row?.file_url, row?.patient_name ? row?.patient_name : row?.lead)} />
                            <span className="chat_timestamp" style={{ marginTop: "10px" }}>{new Date(row?.created_at).toLocaleString()}</span>
                          </div>
                        </div>
                      ) : (row?.file_url?.includes("https://youtu.be/") || row?.file_url?.includes("https://www.youtube.com/")) ? (
                          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                            {/* <Tooltip title="Click on the link to open the video in new tab" arrow followCursor> */}
                            <img
                                  src={row?.file_url?.includes("https://youtu.be/") ? 
                                  `https://img.youtube.com/vi/${(row?.file_url).slice(17)}/0.jpg` :
                                  `https://img.youtube.com/vi/${(row?.file_url).slice(32)}/0.jpg`
                                }
                                  alt="youtube_image"
                                  style={{ width: "300px", height: "200px", padding: "20px 0px 0px 0px" }}
                                />
                                <Tooltip title="Click on the link to open the video in new tab" arrow followCursor>
                                  <a id="downloadtag" href={row?.file_url} target="_blank" >
                                    {/* <img
                                      src={row?.file_url?.includes("https://youtu.be/") ? 
                                      `https://img.youtube.com/vi/${(row?.file_url).slice(17)}/0.jpg` :
                                      `https://img.youtube.com/vi/${(row?.file_url).slice(32)}/0.jpg`
                                    }
                                      alt="youtube_image"
                                      style={{ width: "300px", height: "100px", padding: "20px 0px 0px 0px" }}
                                    /> */}
                                    <div class="play-button"></div>
                                  </a>
                                </Tooltip>
                            <div className="download_icon" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "300px" }}>
                              <span className="chat_timestamp" style={{ marginTop: "10px" }}>{new Date(row?.created_at).toLocaleString()}</span>
                            </div>
                          </div>
                      ) : (
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                          <Tooltip title="Click on image to open/download it in new tab" arrow followCursor>
                            <a id="downloadtag" href={row?.file_url} target="_blank" >
                              <img
                                src={row?.file_url}
                                alt="whatsapp_image"
                                style={{ width: "300px", height: "300px", padding: "20px 0px 0px 0px" }}
                              />
                            </a>
                          </Tooltip>
                          <span className="chat_timestamp" style={{ marginTop: "10px" }}>{new Date(row?.created_at).toLocaleString()}</span>
                        </div>
                    )}
                    </>
                  )}
                  { (row?.file_url === "" || row?.file_url === null) ?
                  <span className="chat_timestamp">{new Date(row?.created_at).toLocaleString()}</span> : 
                  ""
                  }
                </p>
                {(leadUnread?.length === index + 1 && leadUnread?.length < individualWhatsappChat?.count) ? 
                  <IconButton
                    onClick={() => {
                      setLoadMoreChats(true)
                    }}
                  >
                    <Tooltip title="Load More Chats" arrow>
                      <ReplayIcon 
                        style={{ color: "#924A91" }} 
                      />
                    </Tooltip>
                  </IconButton> : (
                    (leadUnread?.length === index + 1 && leadUnread?.length === individualWhatsappChat?.count) ? 
                    <>
                      {/* <h2 style={{ textAlign: "center", fontSixe: "24px" }}>"You have reached the top."</h2> */}
                    </>
                    : "")
                }
              </>
            ))) : ""
          }
          {tabs1 === 1 && tabData?.length > 0 && Object.keys(todays_positive_calls).length > 0 ? (
                todays_positive_calls.map((row) => (
                  <div
                    className="specificMessages"
                    style={{
                      alignSelf: "flex-start",
                      backgroundColor: "#fff",
                      borderRadius: "0px 10px 10px 10px",
                      margin: "20px 20px 0px 0px",
                    }}
                  >
                    <span className="chat_name_comment" style={{ marginTop: "10px" }}>{row?.by_user}</span>
                    <p style={{ color: "#707070", paddingLeft: "10px" }}>{row?.comment}</p>
                    <div>
                      <span className="chat_timestamp" style={{ marginTop: "10px" }}>{new Date(row?.created_at).toLocaleString()}</span>
                    </div>
                  </div>
                ))
            ) : (
              null
            )
          }
          </div>
          { (messageData?.id && tabData?.length > 0 && !whatsappTemplateShow) ?
            <div className="chat_footer">
              {tabs1 === 2 ? 
              <>
                {/* <input
                    id="icon-button-photo"
                    // style={{ display: 'none' }}
                    onChange={handleFileChange}
                    type="file"
                />
                <label htmlFor="icon-button-photo">
                    <IconButton>
                        <AttachFile />
                    </IconButton>
                </label> */}
                <label htmlFor="icon-button-file" key={key}>
                  <Input 
                  id="icon-button-file" 
                  type="file" 
                  // onClick={handleFileChange}
                  onChange={handleFileChange} 
                  disabled={tabs === 2 || whatsAppTabs === "unread"}
                  />
                  <IconButton component="span" disabled={tabs === 2 || whatsAppTabs === "unread"}>
                    <AttachFile 
                    />
                  </IconButton>
                </label>
              </>
               : ""}
              <form onSubmit={handleSubmit}>
                <input 
                  value={chats} 
                  onChange={(e) => setChats(e.target.value)}
                  placeholder="Type a message" 
                  type="text" 
                  disabled={tabs === 2 || whatsAppTabs === "unread"}
                />
                {/* <button onClick={sendMessage} type="submit">Send a message</button> */}
              </form>
              <IconButton disabled={(messageType === "" && chats === "") || loadingIndividualChat || loadingChat}>
                <SendIcon 
                  onClick={(e) => sendMessage(e)}
                />
              </IconButton>
            </div> : 
            (messageData?.lead && tabData?.length > 0 && whatsappTemplateShow && tabs1 === 2) ?
            <div style={{ display: "flex", flexDirection: "column", border: "2px solid grey", borderRadius: "10px", backgroundColor: "#e5e4e4" }} >
              <div>
                <p style={{ padding: "20px", fontSize: "18px" }}>
                  <span style={{ fontSize: "18px" }}>Customer Service window expired.</span> If a person initiates a chat with you, you may continue that conversation via WhatsApp for up to 24 hours after the last message sent to you by that person ("24-hour Window"). Outside of the 24-hour Window, you may only initiate chats messages via approved Message Templates.
                </p>
              </div>
              <div style={{ display: "flex", justifyContent: "center", paddingBottom: "8px" }}>
                <Button variant="contained" style={{ width: "350px", backgroundColor: "#d36109" }}
                  onClick={() => {
                    setWhatsappLeadSearch(messageData?.lead)
                    handleClickWhatsapp()
                  }}
                >Click to Send WhatsApp Template</Button>
              </div>
            </div> :
            null
          }
        </div>
        <Dialog
            open={openLocationModal}
            onClose={() => setOpenLocationModal(false)}
            aria-labelledby="form-dialog-title"
            className={classes.dialog}
          >
            <DialogTitle id="form-dialog-title">Select Locality for Packages</DialogTitle>
            <DialogContent>
              <Grid
                container
                spacing={2}
                style={{
                  margin: "0px 10px 10px 10px",
                  minWidth: "500px",
                  width: "70%",
                }}
              >
                <Grid item xs={12} md={12}>
                  <p>{"LOCALITY"}</p>
                  <Autocomplete
                    id="customer_landmark"
                    key={1}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setMapAddressObject(obj);
                      }
                    }}
                    options={suggestedAddress ? suggestedAddress : []}
                    freeSolo
                    aria-required
                    getOptionLabel={(option) =>
                      `${option?.placeName}, ${option?.placeAddress}`
                    }
                    filterOptions={(option) => option}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        locationAutoCompleteSearch(newInputValue, event);
                      }, 500);
                    }}
                    // onKeyDown={(e)=>handleKeyDown(e)}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Search Locality"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        // disabled={collection_date === ""}
                        required
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                                if (e.key === 'Enter') {
                                  e.stopPropagation();
                                }
                          },
                        }}
                      />
                    )}
                  />
                  {/* <p style={{ color: "red" }}>{errorMessage}</p> */}
                </Grid>
                {true ?
                <Grid item xs={12} md={12} disabled={packageList?.count === 0}>
                  <p>SELECT PACKAGES</p>
                  <Autocomplete
                      id="package"
                      // key={packageKey}
                      onChange={(event, newValue) => {
                          if (newValue) {
                              let packs = []
                              newValue.map((val) => {
                                  let obj = JSON.parse(JSON.stringify(val, null, " "));
                                  packs.push(obj)
                              })
                              setPackageDetails(packs)
                          }
                      }}
                      multiple
                      options={packageList?.results}
                      value={packageDetails}
                      // disableClearable
                      limitTags={0}
                      getOptionLabel={(option) =>
                        option.name && option.package_city_prices
                          ? option.name.toString() +
                          ", " +
                          option.package_city_prices.tat_time +
                          ", Rs. " +
                          option?.package_city_prices.offer_price +
                          "/-"
                          : option.name +
                          ", " +
                          option?.tat_time +
                          ", Rs. " +
                          option?.offer_price +
                          "/-"
                      }
                      getOptionDisabled={(option) => {
                          const packID = packageDetails.map((pack) => pack.id)
                          return packID.includes(option.id)
                      }}
                      onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current)
                          timer.current = setTimeout(() => {
                              getPackage(`city_id=${cityId}&search=${newInputValue}`)
                          }, 1000)
                      }}
                      freeSolo
                      disableCloseOnSelect
                      renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                              <Chip
                                  variant="default"
                                  color="primary"
                                  label={option.name && option.package_city_prices ? option.name.toString() + ", " + option.package_city_prices.tat_time : option.name + ", " + option.tat_time}
                                  {...getTagProps({ index })}
                              />
                          ))
                      }
                      disabled={packageList?.count > 0 ? false : true}
                      renderInput={(params) => (
                          <TextField
                              {...params}
                              placeholder="Package"
                              variant="outlined"
                              style={{ width: "100%", margin: "0", padding: "0" }}
                              className="input"
                          />
                      )}
                  />
                </Grid>
                : ""}
              </Grid>
            </DialogContent>
            {false ? <Loader /> : (
              <DialogActions>
                <Button
                  onClick={() => {
                    handlePackagesChats()
                  }}
                  color="primary"
                  disabled={packageList?.count === 0}
                >
                  Select
                </Button>
                <Button
                  onClick={() => {
                    setChats("")
                    setOpenLocationModal(false)
                  }}
                  color="primary"
                >
                  Cancel
                </Button>
              </DialogActions>
            )}
          </Dialog>
        <Dialog
            open={openReportModal}
            onClose={() => setOpenReportModal(false)}
            aria-labelledby="form-dialog-title"
            className={classes.dialog}
          >
            <DialogTitle id="form-dialog-title">Select Customer to send a Report</DialogTitle>
            <DialogContent>
              <Grid
                container
                spacing={2}
                style={{
                  margin: "0px 10px 10px 10px",
                  minWidth: "500px",
                  width: "70%",
                }}
              >
                <Grid item xs={12} md={12}>
                {/* {
                  latestBookingUsingLeadData && latestBookingUsingLeadData?.result?.length > 0 && latestBookingUsingLeadData?.result.map((booking, index) => { */}
                  <Select className="input"
                    name="report"
                    variant="outlined"
                    style={{ width: "90%", marginBottom: "1rem", alignItems: "flex-start" }}
                    value={personId}

                    onChange={(e) => handleChangeReport(e, "booking")}
                  >
                    <MenuItem disabled selected value={'none'}>Customer Name</MenuItem>
                    {/* <MenuItem value={booking?.name}>{booking?.name}</MenuItem> */}
                    {latestBookingUsingLeadData &&
                      latestBookingUsingLeadData?.result?.length > 0 &&
                      latestBookingUsingLeadData?.result.map((data) => {
                        return (
                          <MenuItem value={data?.name} onClick={(e) => handleReportData(e, data)}>{data?.name}</MenuItem>
                        )
                      })}
                  </Select>
                  {/* })} */}
                </Grid>
              </Grid>
            </DialogContent>
            {false ? <Loader /> : (
              <DialogActions>
                <Button
                  onClick={() => handleSendReport()}
                  color="primary"
                  disabled={bookingIdReport === "" && addBookingIdReport === ""}
                >
                  Send
                </Button>
                <Button
                  onClick={() => {
                    setBookingIdReport("")
                    setAddBookingIdReport("")
                    setPersonId("none")
                    setOpenReportModal(false)
                  }}
                  color="primary"
                >
                  Cancel
                </Button>
              </DialogActions>
            )}
          </Dialog>
          <RaiseComplaintModal
            open={raiseComplaint}
            setOpen={setRaiseComplaint}
            bookingId={Number(bookingId)}
            complaints={complaints}
            leadId={Number(leadId)}
          />
          <TicketUsingLeadModal
            open={ticketModal}
            setOpen={setTicketModal}
            leadId={Number(leadId)}
          />
          <ChangeAgentModal
            setOpenReportModel1={setOpenReportModel1}
            openReportModel1={openReportModel1}
            setLeadId={setLeadId}
            leadId={leadId}
            lastMessageId={lastMessageId}
          />
          <ResolvedChatModal
            setOpenResolvedChatModal={setOpenResolvedChatModal}
            openResolvedChatModal={openResolvedChatModal}
            setLeadId={setLeadId}
            leadId={leadId}
          />
          <SendReportModel
            setOpenReportModel={setOpenReportModel}
            openReportModel={openReportModel}
            customerDetails={customerDetails}
            setCustomerDetails={setCustomerDetails}
            objId={objId}
            setFilterUrl={"lead"}
            filterUrl={"lead"}
            getFailedReportData={getFailedReportData}
          />
          <WhatsAppModal open={openWhatsapp} setOpen={setOpenWhatsapp} leadId={whatsappLeadSearch} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  whatsappComments: state.SalesLeadAdminReducer.whatsappComments,
  individualWhatsappChat: state.SalesLeadAdminReducer.individualWhatsappChat,
  todays_positive_calls: state.SalesLeadAdminReducer.todays_positive_calls,
  complaints: state.SalesLeadAdminReducer.complaints,
  loading: state.SalesLeadAdminReducer.loading,
  loadingChat: state.SalesLeadAdminReducer.loadingChat,
  loadingIndividualChat: state.SalesLeadAdminReducer.loadingIndividualChat,
  loadingComment: state.SalesLeadAdminReducer.loadingComment,
  geoFencesMapMyIndia: state.SalesLeadAdminReducer.geoFencesMapMyIndia,
  packageList: state.SalesLeadAdminReducer.packageList,
  mapmyindiaAccessToken: state.SalesLeadAdminReducer.mapmyindiaAccessToken,
  whatsappDataFromPusher: state.loginReducer.whatsappDataFromPusher,
  failedReport: state.SalesLeadAdminReducer.failedReport,
  latestBookingUsingLeadData: state.SalesLeadAdminReducer.latestBookingUsingLeadData,
  userDetails: state.SalesLeadAdminReducer.userDetails,
  signOutData: state.SalesLeadAdminReducer.signOutData,
  queueNumber: state.SalesLeadAdminReducer.queueNumber,
});

export default connect(mapStateToProps, {
  getWhatsappComments,
  getIndividualWhatsappChat,
  sendWhatsappComments,
  updateLeadDetails,
  addComment,
  getPositiveCallsComments,
  checkGeoFenchMapMyIndiaPilot,
  getMapMyIndiaAccessToken,
  getPackage,
  signOutInWhatsappChat,
  getFailedReportData,
  resetLeadDetails,
  sendLastReportToLead,
  getLatestBookingUsingLead,
  getAllBookings,
  getUserDetails,
  getQueueNumber,
})(WhatsAppChat);