import React, { useState, useRef } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Paper, Table, MenuItem, TableBody, Select, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, withStyles, Switch } from '@material-ui/core';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../Components/loader';
import { MessageRounded } from '@material-ui/icons';
import { useEffect } from 'react';
import { useHistory } from "react-router";
import Autocomplete from '@material-ui/lab/Autocomplete';


import {
    Container,
   Grid,
    TextField,
} from '@material-ui/core';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "100%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        tableContainer: {
            marginTop: "1rem",
            position: "sticky",
            top: "200px",
        },
    })
);

const DefaultSwitch:any = withStyles({
    switchBase: {
        color: "red",
        '&$checked': {
            color: "green",
        },
        '&$checked + $track': {
            backgroundColor: "green",
        },
    },
    checked: {},
    track: {
        backgroundColor: "red"
    },
})(Switch);

const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#3f51b5",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);


interface Props {
    loading: any;
    centerAddressPrice: any;
    currentUrl: any;
    getCenterAddressPrice: any;
    setopenModal:any;
    setpackId:any
}

const BookingsTable: React.FC<Props> = ({
    loading,
    centerAddressPrice,
    currentUrl,
    getCenterAddressPrice,
    setopenModal,
    setpackId,
}) => {
    const history = useHistory()
    const classes = useStyles()
    const timer = useRef<any>(0)
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = centerAddressPrice.links.next;
            getCenterAddressPrice(url.substring(url.indexOf("?")));
        } else if (newPage < page) {
            let url = centerAddressPrice.links.previous;
            getCenterAddressPrice(
                url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?"))
            );
        }
        setPage(newPage);
    };
    const handleModal = (id:any) => {
        setopenModal(true)
        setpackId(id)
    }
    return (
        <main className={classes.content}>
            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "600px" }}>
                {loading ? <Loader /> : (
                    <Table stickyHeader aria-label="simple table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell align="center">CENTRE</StyledTableCell>
                                <StyledTableCell align="center">TEST NAME</StyledTableCell>
                                <StyledTableCell align="center">COMMENT</StyledTableCell>
                                <StyledTableCell align="center">MRP</StyledTableCell>
                                <StyledTableCell align="center">GO LIVE PRICE </StyledTableCell>
                                <StyledTableCell align="center">STATUS </StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {centerAddressPrice && centerAddressPrice.results && centerAddressPrice.results.length > 0 && centerAddressPrice.results.map((data: any, index: any) => {
                                return (
                                    <StyledTableRow>
                                        <StyledTableCell align="center">{data?.center_address?.center_address?.name}</StyledTableCell>
                                        <StyledTableCell align="center">{data?.package?.name}</StyledTableCell>
                                        <StyledTableCell align="center"><MessageRounded onClick={()=>handleModal(data?.package?.id)}/></StyledTableCell>
                                        <StyledTableCell align="center">{data?.package_price}</StyledTableCell>
                                        <StyledTableCell align="center">{data?.offer_price}</StyledTableCell>
                                        <StyledTableCell align="center">
                                            <DefaultSwitch
                                                checked={data?.is_active}
                                                name="checkedA"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                disableRipple
                                            /></StyledTableCell>
                                    </StyledTableRow>
                                )
                            })}
                        </TableBody>
                        <TableFooter>
                            <TablePagination
                                colSpan={6}
                                count={centerAddressPrice.count || 0}
                                rowsPerPageOptions={[]}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                            />
                        </TableFooter>
                    </Table>
                )}
            </TableContainer>
        </main>
    )
}

const mapStateToProps = (state: any) => ({
    loading: state.ImagingAdminReducer.loading,
})

export default connect(mapStateToProps, {

})(BookingsTable);

