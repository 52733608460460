import React, { useState, useRef } from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Paper, Table, MenuItem, TableBody, Select, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, withStyles } from '@material-ui/core';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../components/loader/index';
import { MessageRounded } from '@material-ui/icons';
import { useEffect } from 'react';
import RaiseComplaintModal from '../components/Comments/updateTicketModal'
import { useHistory } from "react-router";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getAgentList, getAllBookings ,getQueryTickets} from '../actions/HealthAdviserAdminAction'
import { generateQueryTicketBookingFilterUrl } from '../../helpers/generateUrl'

import {
    Container,
   Grid,
    TextField,
} from '@material-ui/core';
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        tableContainer: {
            marginTop: "1rem",
            position: "sticky",
            top: "200px",
        },
    })
);

const StyledTableCell:any = withStyles((theme: Theme) =>
    createStyles({
        head: {
            backgroundColor: "#924A91",
            color: theme.palette.common.white,
            fontSize: "14px",
        },
        body: {
            fontSize: 14,fontWeight:600
        },
    })
)(TableCell);

const StyledTableRow:any = withStyles((theme: Theme) =>
    createStyles({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    })
)(TableRow);


interface Props {
    getQueryTickets: any;
    complaints: any;
    agentsList: any;
    getAllBookings: any;
    getAgentList: any;
    loading: any;
}

const BookingsTable: React.FC<Props> = ({
    getQueryTickets,
    complaints,
    getAllBookings,
    getAgentList,
    agentsList,
    loading,
}) => {
    const history = useHistory()
    const classes = useStyles()
    const timer = useRef<any>(0)
    const [raiseComplaint, setRaiseComplaint] = useState(false)
    const [partner, setPartner] = useState<any>([])
    const [bookingID, setBookingID] = useState(0)
    const [type, setType] = useState("")
    const [query, setQuery] = useState("none")
    const [start_date, setStart_date] = useState("")
    const [end_date, setEnd_date] = useState("")
    const [status, setStatus] = useState("")
    const [ticket_Status, setTicketStatus] = useState<any>("")
    const [ticketId, setTicketId] = useState(0)
    const [created_date, setCreated_date] = useState("")
    const [bookingId, setBookingid] = React.useState<any>("")
    const [activeTab, setActiveTab] = React.useState<string>("");
    const handleOpenModal = (ticket: any) => {
        setBookingID(ticket.booking?.pk||ticket.booking?.id)
        setType(ticket.querytype)
        setQuery(ticket.query)
        setStatus(ticket.status)
        setTicketId(ticket.id)
        setRaiseComplaint(true)
    }

    useEffect(() => {
        
        getAgentList()
        getAllBookings()
    }, [])
    useEffect(() => {
        getQueryTickets("booking_ticket",activeTab)
    },[activeTab])

    useEffect(() => {
        if (raiseComplaint === false) {
            getQueryTickets("booking_ticket",activeTab)
        }
    }, [raiseComplaint])

    const [page, setPage] = useState(0)

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = complaints.links && complaints.links.next.split("?")[1];
            getQueryTickets("booking_ticket", activeTab===""?url:`${activeTab}&${url}`);
        } else if (newPage < page) {
            let url = complaints.links && complaints.links.previous.split("?")[1];
            getQueryTickets("booking_ticket", activeTab===""?url:`${activeTab}&${url}`);
        }
        setPage(newPage as number);
    };
    const filterBookings = () => {
        const body: any = {
            bookingId,
            ticket_Status,
            created_date,
            start_date,
            end_date,

        }
        const url = generateQueryTicketBookingFilterUrl(body).substring(2)
        getQueryTickets("booking_ticket", `${url}&page=1`)
        setPage(0)

    }
    const handleReceivedTicket=(type:any)=>{
        setActiveTab(`type=${type}`)
    }
    const handleAllTicket=()=>{
        setActiveTab("")
    }
    const handleSendTicket=(type:any)=>{
         setActiveTab(`type=${type}`)
    }
    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <h2>Ticket Booking</h2>
            <Grid container spacing={1} direction="row">
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        className="input"
                        name="booking"
                        type="number"
                        placeholder="Booking Id"
                        value={bookingId}
                        variant="outlined"
                        onChange={(e) => setBookingid(e.target.value)}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <Autocomplete
                        id="ticket_status"
                        onChange={(event, newValue) => {
                            let sourceIds: any = []
                            if (newValue) {
                                let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                sourceIds = obj.map((item: any) => item.value)
                            }
                            setTicketStatus(sourceIds)
                        }}
                        options={[
                            {
                                text: "Pending",
                                value: "pending"
                            },
                            {
                                text: "Confirmed",
                                value: "confirmed"
                            },
                            {
                                text: "Cancelled",
                                value: "cancelled"
                            },
                            {
                                text: "Resampling",
                                value: "resampling"
                            },
                            {
                                text: "Rescheduled",
                                value: "rescheduled"
                            },
                            {
                                text: "Hold",
                                value: "hold"
                            },
                            {
                                text: "Sample Not Received",
                                value: "sample not received"
                            },
                        ]}
                        freeSolo
                        blurOnSelect
                        aria-required
                        limitTags={1}
                        multiple
                        getOptionLabel={(option: any) => option.text}
                        getOptionDisabled={(option: any) => {
                            return ticket_Status.includes(option.value)
                        }}
                        disableClearable
                        disableCloseOnSelect
                        renderInput={(params) => (
                            <TextField className="input"
                                {...params}
                                placeholder="Ticket Status"
                                variant="outlined"
                                style={{ width: "100%", margin: "0", padding: "0" }}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        className="input"
                        name="created_date"
                        type="date"
                        label="Created Date"
                        value={created_date}
                        variant="outlined"
                        disabled={start_date !== "" || end_date !== ""}
                        onChange={(e) => setCreated_date(e.target.value as string)}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        className="input"
                        name="start_date"
                        type="date"
                        label="Start Date"
                        value={start_date}
                        variant="outlined"
                        disabled={created_date !== ""}
                        onChange={(e) => setStart_date(e.target.value as string)}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <TextField
                        name="end_date"
                        type="date"
                        value={end_date}
                        className="input"
                        label="End Date"
                        variant="outlined"
                        disabled={created_date !== ""}
                        onChange={(e) => setEnd_date(e.target.value as string)}
                        style={{ width: "100%" }}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <Button
                        variant="contained"
                        color="secondary"

                        fullWidth
                        onClick={filterBookings}
                    >
                        Filter
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={2}>
                    <Button
                        variant="contained"
                        color="primary"

                        fullWidth
                        onClick={() => history.push("/dashboard/haa/booking/tickets")}
                    >
                        Reset
                    </Button>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                direction="row"
                style={{ margin: "1rem" }}
            >
              <Grid item xs={12} sm={4} md={3}>
                    <Button
                        variant={activeTab == "" ? "contained" : "outlined"}
                        color="primary"
                        fullWidth
                        onClick={() => handleAllTicket()}
                    >
                        All
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <Button
                        variant={activeTab == "type=received" ? "contained" : "outlined"}
                        color="primary" 
                        fullWidth
                        onClick={() => handleReceivedTicket("received")}
                    >
                        Received Ticket
                    </Button>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <Button
                        variant={activeTab == "type=send" ? "contained" : "outlined"}
                        color="primary"
                        fullWidth
                        onClick={() => handleSendTicket("send")}
                    >
                        Send Ticket
                    </Button>
                </Grid>
            </Grid>
            <TableContainer className={classes.tableContainer} component={Paper} style={{ maxHeight: "540px" }}>
                    {loading ? <Loader /> : (
                        <Table stickyHeader aria-label="simple table">
                            <TableHead>
                                <StyledTableRow>
                                    <StyledTableCell align="center">Booking Id</StyledTableCell>
                                    <StyledTableCell align="center">Agent</StyledTableCell>
                                    <StyledTableCell align="center">Action</StyledTableCell>
                                    <StyledTableCell align="center">Created At</StyledTableCell>
                                    <StyledTableCell align="center">Query Type</StyledTableCell>
                                    <StyledTableCell align="center">Query Description</StyledTableCell>
                                    <StyledTableCell align="center">Receiver Remarks</StyledTableCell>
                                    <StyledTableCell align="center">Status</StyledTableCell>
                                    <StyledTableCell align="center">View</StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    complaints.results && complaints.results.length > 0 && complaints.results.map((ticket: any, index: any) => {
                                        return (
                                            <StyledTableRow key={index}>
                                               <StyledTableCell align="center">{ticket?.booking!==null?(ticket?.booking?.id||ticket?.booking?.pk):(ticket?.add_booking?.mainbooking)}</StyledTableCell> 
                                                <StyledTableCell align="center">{ticket.panel_user && ticket.panel_user.user && ticket.panel_user.user.name}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Button
                                                        color="primary"
                                                        variant="text"
                                                    >
                                                        <MessageRounded onClick={() => handleOpenModal(ticket)} />
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">{new Date(ticket.created_at).toLocaleString()}</StyledTableCell>
                                                <StyledTableCell align="center">{ticket.querytype}</StyledTableCell>
                                                <StyledTableCell align="center">{ticket.query}</StyledTableCell>
                                                <StyledTableCell align="center">{ticket.receicer_remarks}</StyledTableCell>
                                                <StyledTableCell align="center">{ticket.status}</StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Link to={`/dashboard/haa/booking-view/${ticket?.booking?.id||ticket?.booking?.pk}`}>
                                                        <Button
                                                            color="secondary"
                                                            variant="contained"
                                                        >
                                                            View
                                                        </Button>
                                                    </Link>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })
                                }
                            </TableBody>
                            <TableFooter>
                                <StyledTableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[]}
                                        colSpan={3}
                                        count={complaints.count || 0}
                                        rowsPerPage={complaints.page_size}
                                        page={page}
                                        onPageChange={handleChangePage}
                                    />
                                </StyledTableRow>
                            </TableFooter>
                        </Table>
                    )}
                </TableContainer>
                <RaiseComplaintModal open={raiseComplaint} setOpen={setRaiseComplaint} ticketId={ticketId} queryType={type} queryDesc={query} queryStatus={status} bookingId={Number(bookingID)} leadId={0} />
        </main>
    )
}

const mapStateToProps = (state: any) => ({
    loading: state.HealthAdviserAdminReducer.loading,
    agentsList: state.HealthAdviserAdminReducer.agentsList,
    complaints: state.HealthAdviserAdminReducer.complaints
})

export default connect(mapStateToProps, {
    getQueryTickets,
    getAgentList,
    getAllBookings,
})(BookingsTable);

