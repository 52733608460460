import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Container,
    Grid,
    TextField,
    Select,
    MenuItem,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { generateBookingFilterUrl } from "../../helpers/generateUrl";
import { connect } from "react-redux";
import { getAgentList, getLeadSource, getPartners, getAllBookings, getCities, getPhlebos, getCollectionSlot, getQRCodeData } from '../actions/CollectionCenter'
import './filter.sass'
import { useHistory } from "react-router";
import BookingsTable from "../Tables/bookingsTable";
import Loader from "../Components/Loader2/index";
import { getPaymentInfo } from '../actions/CollectionCenter'
import PaymentModal from '../Components/Comments/paymentforModal';
import  {BOOKING_STATUS} from '../../booking_status'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        card: {
            height: "100%",
            padding: theme.spacing(1),
        },
        card_grid: {
            // wordSpacing:3,
            justifyContent: 'space-between'
        },
    })
);

interface Props {
    children: any;
    agentsList: any;
    bookingList: any;
    partnerList: any;
    getAgentList: any;
    getCollectionSlot: any;
    getPartners: any;
    getAllBookings: any;
    getCities: any;
    collectionSlot: any;
    cities: any;
    getPhlebos: any;
    phleboList: any;
    getLeadSource: any;
    lead_source: any;
    getPaymentInfo: any;
    paymentInformation: any;
    getQRCodeData: any;
    qrcode: any;
    loading: Boolean;
}

const CollectionCenterFilterPage: React.FC<Props> = ({
    agentsList,
    partnerList,
    getAgentList,
    getPartners,
    getAllBookings,
    bookingList,
    getCollectionSlot,
    getPaymentInfo,
    paymentInformation,
    collectionSlot,
    getCities,
    cities,
    getPhlebos,
    phleboList,
    getLeadSource,
    lead_source,
    getQRCodeData,
    qrcode,
    loading
}) => {
    const classes = useStyles();

    const history = useHistory()
    const timer = useRef<any>(0)
    const [openModal, setopenModal] = useState<boolean>(false);

    const [status, setStatus] = useState<any>([])
    const [source, setSource] = useState<any>([])
    const [partner, setPartner] = useState<any>([])
    const [agent, setAgent] = useState<any>([])
    const [city, setCity] = useState<any>([])
    const [slot, setSlot] = useState<any>([])
    const [start_date, setStart_date] = useState("")
    const [end_date, setEnd_date] = useState("")
    const [booking_date, setBooking_date] = useState("")
    const [collection_date, setCollection_date] = useState("")
    const [verificationStatus, setVerificationStatus] = useState<any>([])
    const [pickup_status, setPickup_status] = useState<any>([])
    const [name, setName] = useState("")
    const [phlebo, setPhlebo] = useState<any>([])
    const [phone, setPhone] = useState("")
    const [bookingId, setBookingId] = useState<any>("")
    const [report_status, setReportStatus] = useState<any>("none")
    const [pickup_received_amount, setPickupReceivedAmount] = useState<string>("none")
    const [page, setPage] = useState(0)
    const [sample_registered, setSampleRegisterd] = useState<any>("none")
    const [paymentId, setPaymentId] = useState<any>(0)
    const [openPaymentModal, setOpenPaymentModal] = useState<any>(false)
    const [heights, setheights] = useState(window.innerHeight);
    const [notificationData, setnotificationData] = useState("");
    const [defaultStatus, setDefaultStatus] = useState<any>([]);
    const [openTubeModal, setOpenTubeModal] = useState<any>(false)
    const [tubeId, setTubeId] = useState<any>('')
    const [updateTube, setUpdateTube] = useState<any>('')

    useEffect(() => {
        if (history.action === "POP") {
            let ceBookingFilter: any;
            ceBookingFilter = localStorage.getItem("CEBookingFilter");
            if (ceBookingFilter) {
                ceBookingFilter = JSON.parse(ceBookingFilter);

                const statusD = ceBookingFilter?.status?.split(",")
                let statusTemp = new Array()
                BOOKING_STATUS.map((item: any) => {
                    statusD.map((itm: any) => {
                        if (item.value == itm) {
                            statusTemp.push(item);
                        }
                    });
                });
                setDefaultStatus(statusTemp);
                ceBookingFilter?.status &&
                    setStatus(ceBookingFilter?.status?.split(","));
                ceBookingFilter?.source &&
                    setSource(ceBookingFilter?.source?.split(","));
                ceBookingFilter?.partner &&
                    setPartner(ceBookingFilter?.partner?.split(","));
                ceBookingFilter?.city && setCity(ceBookingFilter?.city?.split(","));
                ceBookingFilter?.start_date &&
                    setStart_date(ceBookingFilter?.start_date);
                setCollection_date(ceBookingFilter?.collection_date);
                ceBookingFilter?.verificationStatus &&
                    setVerificationStatus(
                        ceBookingFilter?.verificationStatus?.split(",")
                    );
                ceBookingFilter?.pickup_status &&
                    setPickup_status(ceBookingFilter?.pickup_status?.split(","));
                setName(ceBookingFilter?.name);
                setPhlebo(ceBookingFilter?.phlebo);
                setPhone(ceBookingFilter?.phone);
                setReportStatus(ceBookingFilter?.report_status);
                setSampleRegisterd(ceBookingFilter?.sample_registered);
                setPickupReceivedAmount(ceBookingFilter?.pickup_received_amount);
                setBookingId(ceBookingFilter?.bookingId);

                const url = generateBookingFilterUrl(ceBookingFilter).substring(2);
                const pageNo = page ? page : 1;
                getAllBookings(`${url}&page=${pageNo}`);
            }
        } else {
            getAgentList()
            getPartners()
            getCities("is_imaging=true")
            getCollectionSlot()
            
            getLeadSource()
            getAllBookings()
            localStorage.removeItem("CEBookingFilter");
            setDefaultStatus([])
        }
    }, [setDefaultStatus])

    useEffect(() => {
        if (paymentId != 0) {
            getPaymentInfo(`?booking=${paymentId}`);
        }
    }, [paymentId])

    useEffect(() => {
        if (tubeId !== '') {
            getQRCodeData(tubeId)
            setOpenTubeModal(true)
        }

    }, [tubeId, updateTube])
    const filterBookings = (e: any) => {
        const body: any = {
            status: status ? status.join(",") : "",
            verificationStatus: verificationStatus
                ? verificationStatus.join(",")
                : "",
            source: source ? source.join(",") : "",
            agent: agent ? agent.join(",") : "",
            city: city ? city.join(",") : "",
            slot: slot.join(","),
            start_date,
            end_date,
            booking_date,
            collection_date,
            pickup_status: pickup_status ? pickup_status.join(",") : "",
            name,
            partner: partner ? partner.join(",") : "",
            phone,
            report_status,
            sample_registered,
            is_pickup_receive_amount: pickup_received_amount,
            bookingId
        }
        const url = generateBookingFilterUrl(body).substring(2)
        localStorage.setItem("CEBookingFilter", JSON.stringify(body));
        getAllBookings(`${url}`)
        setPage(0)
    }
    const onKeyDown = (e: any) => {
        if (e.key === "Enter") {
            filterBookings(e);
        }
    };

    return (
        <>
            <main className={classes.content}>
                {/* {notificationData !== "" ? <MyNotify /> : null} */}
                <div className={classes.toolbar} />
                <Grid container spacing={1} direction="row" style={{alignItems:"left"}}>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="booking"
                            type="number"
                            label="Booking Id"
                            value={bookingId}
                            variant="outlined"
                            onKeyDown={onKeyDown}
                            onChange={(e) => setBookingId(e.target.value)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="name"
                            type="text"
                            label="Name"
                            value={name}
                            variant="outlined"
                            onKeyDown={onKeyDown}
                            onChange={(e) => setName(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="phone"
                            type="number"
                            label="Phone"
                            value={phone}
                            variant="outlined"
                            onKeyDown={onKeyDown}
                            onChange={(e) => setPhone(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="city"
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id);
                                }
                                setCity(sourceIds);
                            }}
                            options={cities || []}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            onKeyDown={onKeyDown}
                            getOptionLabel={(option: any) => option.name}
                            getOptionDisabled={(option: any) => {
                                return city.includes(option.id);
                            }}
                            disableClearable
                            disableCloseOnSelect
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    getCities(`q=${newInputValue}&is_imaging=true`);
                                }, 1000);
                                if (newInputValue.length === 0) {
                                    setCity([]);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="City"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="status"
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.value);
                                }
                                setStatus(sourceIds);
                            }}
                            options={BOOKING_STATUS}
                            blurOnSelect
                            aria-required
                            onKeyDown={onKeyDown}
                            limitTags={1}
                            multiple
                            getOptionLabel={(option: any) => option.text}
                            getOptionDisabled={(option: any) => {
                                return status.includes(option.value);
                            }}
                            disableClearable
                            disableCloseOnSelect
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Booking Status"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                            defaultValue={defaultStatus}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="partner"
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id);
                                }
                                setPartner(sourceIds);
                            }}
                            freeSolo
                            blurOnSelect
                            limitTags={1}
                            multiple
                            options={partnerList?.results || []}
                            onKeyDown={onKeyDown}
                            getOptionDisabled={(option: any) => {
                                return partner.includes(option.id);
                            }}
                            disableClearable
                            disableCloseOnSelect
                            getOptionLabel={(option: any) => option.user && option.user.name}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current);
                                timer.current = setTimeout(() => {
                                    getPartners(newInputValue);
                                }, 1000);
                                if (newInputValue.length === 0) {
                                    setPartner([]);
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Partner"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id);
                                }
                                setSource(sourceIds);
                            }}
                            freeSolo
                            multiple
                            id="checkboxes-tags-demo"
                            options={lead_source || []}
                            disableCloseOnSelect
                            limitTags={1}
                            disableClearable
                            onKeyDown={onKeyDown}
                            getOptionLabel={(option: any) => option.name}
                            getOptionDisabled={(option: any) => {
                                return source.includes(option.id);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Source"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="agent"
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setAgent(sourceIds)
                            }}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            options={agentsList?.results || []}
                            getOptionDisabled={(option: any) => {
                                return agent.includes(option.id)
                            }}
                            disableClearable
                            disableCloseOnSelect
                            getOptionLabel={(option: any) => option.user && option.user.username}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                                    getAgentList(`${newInputValue.replace(" ", "")}`)
                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setPartner([])
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    label="Agent"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                   
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="city"
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.value);
                                }
                                setVerificationStatus(sourceIds);
                            }}
                            options={[
                                {
                                    text: "Pending",
                                    value: "pending",
                                },
                                {
                                    text: "Confirmed",
                                    value: "confirmed",
                                },
                                {
                                    text: "Cancelled",
                                    value: "cancelled",
                                },
                            ]}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            onKeyDown={onKeyDown}
                            multiple
                            getOptionLabel={(option: any) => option.text}
                            getOptionDisabled={(option: any) => {
                                return verificationStatus.includes(option.value);
                            }}
                            disableClearable
                            disableCloseOnSelect
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Verification Status"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="city"
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.value);
                                }
                                setPickup_status(sourceIds);
                            }}
                            options={[
                                {
                                    text: "Pending",
                                    value: "pending",
                                },
                                {
                                    text: "Confirmed",
                                    value: "confirmed",
                                },
                                {
                                    text: "Cancelled",
                                    value: "cancelled",
                                },
                                {
                                    text: "Hold",
                                    value: "hold",
                                },
                            ]}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            getOptionLabel={(option: any) => option.text}
                            onKeyDown={onKeyDown}
                            getOptionDisabled={(option: any) => {
                                return pickup_status.includes(option.value);
                            }}
                            disableClearable
                            disableCloseOnSelect
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    label="Pickup Status"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="start_date"
                            type="date"
                            label="Start Date"
                            value={
                                booking_date === "" || collection_date === "" ? start_date : ""
                            }
                            variant="outlined"
                            disabled={booking_date !== "" || collection_date !== ""}
                            onKeyDown={onKeyDown}
                            onChange={(e) => setStart_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="end_date"
                            type="date"
                            value={
                                booking_date === "" || collection_date === "" ? end_date : ""
                            }
                            className="input"
                            label="End Date"
                            variant="outlined"
                            disabled={booking_date !== "" || collection_date !== ""}
                            onChange={(e) => setEnd_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="booking_date"
                            type="date"
                            value={start_date === "" || end_date === "" ? booking_date : ""}
                            className="input"
                            label="Booking Date"
                            variant="outlined"
                            onKeyDown={onKeyDown}
                            disabled={start_date !== "" || end_date !== ""}
                            onChange={(e) => setBooking_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="collection_date"
                            type="date"
                            value={
                                start_date === "" || end_date === "" ? collection_date : ""
                            }
                            className="input"
                            label="Collection Date"
                            variant="outlined"
                            onKeyDown={onKeyDown}
                            disabled={start_date !== "" || end_date !== ""}
                            onChange={(e) => setCollection_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    {/* Start */}

                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="slot"
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setSlot(sourceIds)
                            }}
                            options={collectionSlot.results}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            getOptionLabel={(option: any) => option.start_time + "-" + "OnWard"}
                            getOptionDisabled={(option: any) => {
                                return slot.includes(option.id)
                            }}
                            disableClearable
                            disableCloseOnSelect
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                                    getCollectionSlot(newInputValue)
                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setSlot([])
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    label="Collection Slot"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Select
                            className="input"
                            name="booking_status"
                            variant="outlined"
                            value={pickup_received_amount || "pending"}
                            style={{ width: "100%", margin: "0", padding: "0" ,height:"40px"}}
                            onChange={(e) => setPickupReceivedAmount(e.target.value as string)}
                            required
                        >
                            <MenuItem disabled value={"none"}>Pickup Recieved Amount</MenuItem>
                            <MenuItem value={"true"}>Received</MenuItem>
                            <MenuItem value={"false"}>Not Received</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Select className="input"
                            name="sampleregisterd"
                            variant="outlined"
                            value={sample_registered}
                            style={{ width: "100%",height:"40px" }}
                            onChange={(e) => setSampleRegisterd(e.target.value as String)}
                        >
                            <MenuItem disabled value="none">Sample Type</MenuItem>
                            <MenuItem value={"true"}>Registerd</MenuItem>
                            <MenuItem value={"false"}>Not Registerd</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>

                        <Select className="input"
                            name="sampleregisterd"
                            variant="outlined"
                            value={report_status}
                            style={{ width: "100%", margin: "0" ,height:"40px"}}

                            onChange={(e) => setReportStatus(e.target.value as String)}

                        >
                            <MenuItem disabled value={"none"}>Report Status</MenuItem>
                            <MenuItem value={"na"}>None</MenuItem>
                            <MenuItem value={"pending"}>Pending</MenuItem>
                            <MenuItem value={"partial"}>Partial</MenuItem>
                            <MenuItem value={"consolidate"}>Consolidate</MenuItem>

                        </Select>
                    </Grid>

                    {/* End */}
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={filterBookings}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => history.push("/dashboard/cc")}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <PaymentModal openModalP={openPaymentModal} setOpenModalP={setOpenPaymentModal} paymentInformation={paymentInformation} />
                <br />
                {loading ? <Loader /> : bookingList.results && bookingList.results.length > 0 && (
                    <Box>
                        <Container maxWidth={false}>
                            <Grid container spacing={3}>
                                <BookingsTable
                                    setOpenTubeModal={setOpenTubeModal}
                                    setTubeId={setTubeId}
                                    setopenModal={setopenModal}
                                    openModal={openModal}
                                    page={page}
                                    setPage={setPage}
                                    loading={loading}
                                    getBookings={getAllBookings}
                                    bookingList={bookingList}
                                    paymentId={paymentId}
                                    setPaymentId={setPaymentId}
                                    openPaymentModal={openPaymentModal}
                                    setOpenPaymentModal={setOpenPaymentModal}
                                />
                            </Grid>
                        </Container>
                    </Box>
                )}
                {bookingList.results && bookingList.results.length === 0 && (
                    <h6 style={{ textAlign: "center" }}>No Data Found</h6>
                )}
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({

    agentsList: state.CollectionCenterReducer.agentsList,
    partnerList: state.CollectionCenterReducer.partnerList,
    bookingList: state.CollectionCenterReducer.bookingList,
    cities: state.CollectionCenterReducer.cities,
    phleboList: state.CollectionCenterReducer.phleboList,
    collectionSlot: state.CollectionCenterReducer.collectionSlot,
    paymentInformation: state.CollectionCenterReducer.paymentInformation,
    loading: state.CollectionCenterReducer.loading,
    qrcode: state.CollectionCenterReducer.qrcode,
    lead_source: state.CollectionCenterReducer.lead_source,
})

export default connect(mapStateToProps, {
    getAgentList,
    getPartners,
    getCities,
    getPhlebos,
    getLeadSource,
    getCollectionSlot,
    getPaymentInfo,
    getAllBookings,
    getQRCodeData,
})(CollectionCenterFilterPage);
