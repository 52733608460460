import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Loader from "../loader";
import { connect } from "react-redux";
import { Button, Paper, TextField, Grid, Select, MenuItem, Slider, Chip, withStyles } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Editor, OriginalTools } from 'react-bootstrap-editor';
import { createPackage, getCityPrice } from '../../actions/salesLeadAdminAction'
import { useEffect } from "react";
import { useRef } from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        paper: {
            padding: "1rem",
            width: "100%",
            borderRadius: "10px"
        },
        head: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "1rem",
            textAlign: "center"
        },
        heading: {
            fontWeight: "normal",
            fontSize: "1.2rem",
            margin: "0"
        },
        table: {
            maxHeight: "400px",
            margin: "auto",
            overflow: "auto"
        },
        image: {
            width: "100px",
            height: "auto",
        },
        imageSM: {
            width: "50px",
            height: "auto",
        },
        imgContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap"
        }
    })
);

const PrettoSlider:any = withStyles({
    root: {
        color: 'rgb(146, 74, 145)',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: 'violet',
        border: '2px solid purple',
        marginTop: -8,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

interface Props {
    createPackage: any;
    cityPriceList: any;
    getCityPrice: any;
    loading: any;
}

const CreatePackagePage: React.FC<Props> = ({
    createPackage,
    cityPriceList,
    getCityPrice,
    loading,
}) => {
    const classes = useStyles();

    const [name, setName] = useState<string | null>(null)
    const [description, setDescription] = useState<string | null>("")
    const [details, setDetails] = useState<string | null>("")
    const [faq, setFaq] = useState<string | null>("")
    const [packagePrice, setPackagePrice] = useState<number>(0)
    const [cityPrice, setCityPrice] = useState<any>([])
    const [discount, setDiscount] = useState<number>(0)
    const [offerPrice, setOfferPrice] = useState<number>(0)
    const [parameter, setParameter] = useState<string | null>(null)
    const [isAddon, setIsAddon] = useState("none")
    const [recommendedAge, setRecommendedAge] = useState<any>([10, 20])
    const [recommendedGender, setRecommendedGender] = useState<string>("any")
    const [metaTitle, setMetaTitle] = useState("")
    const [metaDescription, setMetaDescription] = useState("")

    const timer = useRef<any>(0)

    useEffect(() => {
        getCityPrice()
    }, [])

    const submitHandler = () => {
        const body: any = {
            name: name,
            description: description,
            package_price: packagePrice,
            discount: discount,
            offer_price: offerPrice,
            parameter: parameter,
            recommended_gender: recommendedGender,
            recommended_age: recommendedAge.join("-"),
            details: details,
            meta_title: metaTitle,
            meta_description: metaDescription,
            is_addon: isAddon,
            faq: faq,
            city_prices: cityPrice,
        }
        createPackage(body)
    }

    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Paper elevation={5} className={classes.paper}>
                    <h3 style={{ textAlign: "center" }}>Create Package</h3>
                    {loading ? <Loader /> : (
                        <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Name</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter Name"
                                        value={name}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Parameter</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="number"
                                        placeholder="Enter Parameter"
                                        value={parameter}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e) => setParameter(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Meta Title</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter Meta Title"
                                        value={metaTitle}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e: any) => setMetaTitle(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Meta Description</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="text"
                                        placeholder="Enter Meta Description"
                                        value={metaDescription}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e: any) => setMetaDescription(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Description</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Editor
                                        tools={OriginalTools}
                                        value={` ${description} `}
                                        onChange={(e: any) => setDescription(e)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Details</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Editor
                                        tools={OriginalTools}
                                        value={` ${details} `}
                                        onChange={(e: any) => setDetails(e)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>FAQ</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Editor
                                        tools={OriginalTools}
                                        value={` ${faq} `}
                                        onChange={(e: any) => setFaq(e)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Package Price</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="number"
                                        placeholder="Enter Package Price"
                                        value={packagePrice}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e: any) => setPackagePrice(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Discount</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="number"
                                        placeholder="Enter Discount"
                                        value={discount}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        onChange={(e: any) => setDiscount(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Offer Price</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <TextField className="input"
                                        name="title"
                                        type="number"
                                        placeholder="Enter Offer Price"
                                        value={Math.floor(packagePrice - ((packagePrice * discount) / 100))}
                                        variant="outlined"
                                        style={{ margin: "0", width: "100%" }}
                                        disabled
                                        onChange={(e: any) => setOfferPrice(e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>City Price</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Autocomplete
                                        id="cityprice"
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                let cityPriceId: any = []
                                                newValue.map((val: any) => {
                                                    let obj = JSON.parse(JSON.stringify(val, null, " "));
                                                    cityPriceId.push(obj.id)
                                                })
                                                setCityPrice(cityPriceId)
                                            }
                                        }}
                                        onInputChange={(event, newInputValue) => {
                                            clearTimeout(timer.current)
                                            timer.current = setTimeout(() => {
                                                getCityPrice(newInputValue)
                                            }, 1000)
                                        }}
                                        multiple
                                        disableClearable
                                        limitTags={2}
                                        disabled
                                        options={cityPriceList.results}
                                        freeSolo
                                        blurOnSelect
                                        getOptionLabel={(option: any) => {
                                            const price: any = Math.floor(option.package_price - ((option.package_price * option.discount) / 100))
                                            return option.city && `${option.city.name} - ${price}`
                                        }}
                                        renderTags={(value: string[], getTagProps) =>
                                            value.map((option: any, index: number) => {
                                                const price: any = Math.floor(option.package_price - ((option.package_price * option.discount) / 100))
                                                return (
                                                    <Chip
                                                        key={index}
                                                        variant="default"
                                                        color="primary"
                                                        label={option.city && `${option.city.name} - ${price}`}
                                                        {...getTagProps({ index })}
                                                    />
                                                )
                                            })
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder="City Price"
                                                variant="outlined"
                                                style={{ width: "100%", margin: "0", padding: "0" }}
                                                className="input"
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Is Add-On</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Select className="input"
                                        name="validOnce"
                                        variant="outlined"
                                        value={isAddon}
                                        style={{ width: "100%" }}
                                        onChange={(e: any) => setIsAddon(e.target.value as string)}
                                    >
                                        <MenuItem disabled value={"none"}>Is Add-On</MenuItem>
                                        <MenuItem value={"true"}>Yes</MenuItem>
                                        <MenuItem value={"false"}>No</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} md={6} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Recommended Gender</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Select className="input"
                                        name="validOnce"
                                        variant="outlined"
                                        value={recommendedGender}
                                        style={{ width: "100%" }}
                                        onChange={(e: any) => setRecommendedGender(e.target.value as string)}
                                    >
                                        <MenuItem value={"any"}>Recommended Gender</MenuItem>
                                        <MenuItem value={"male"}>Male</MenuItem>
                                        <MenuItem value={"female"}>Female</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <p>Recommended Age</p>
                                </Grid>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <PrettoSlider
                                        value={recommendedAge}
                                        onChange={(event: any, newValue: number | number[]) => setRecommendedAge(newValue)}
                                        valueLabelDisplay="auto"
                                        aria-labelledby="range-slider"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} spacing={3} style={{ margin: "1rem 0", width: "100%", padding: "1rem" }}>
                                <Grid item xs={12} style={{ padding: "0" }}>
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        fullWidth
                                        onClick={submitHandler}
                                    >
                                        Create Package
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Paper>
            </main>
        </>
    );
};


const mapStateToProps = (state: any) => ({
    cityPriceList: state.SalesLeadAdminReducer.cityPriceList,
    loading: state.SalesLeadAdminReducer.loading,
})

export default connect(mapStateToProps, {
    createPackage,
    getCityPrice,
})(CreatePackagePage);