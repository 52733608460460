import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useHistory } from 'react-router-dom';
import { Button, Paper, Grid } from "@material-ui/core";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { getRedTechComment, postRedTechComment } from '../../actions/CollectionCenterAdmin';

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "scroll",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
        maxHeight: "600px",
        overflow: "scroll",
        position: "sticky",

    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    }
}));

type ModalProps = {
    open: boolean;
    setOpen: Function;
    postRedTechComment: any;
    getRedTechComment: any;
    redtechcomment: any;
    ticketId: any;
    ticket: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
    open,
    setOpen,
    getRedTechComment,
    redtechcomment,
    postRedTechComment,
    ticketId,
    ticket
}) => {
    const classes = useStyles();
    const history = useHistory();
    const [file1, setFile1] = React.useState<any>([]);
    const [comment, setComment] = useState<any>("")
    const [buttonType, setButtonType] = useState<boolean>(false)
    const [id, setId] = useState(0);


    const handleClose = () => {
        setOpen(false);
        setButtonType(false)
    };
    const openCommentFun = () => {
        setButtonType(false)
    }
    // const openHistoryModel = () => {
    //         getRedTechComment(ticketId)
    //     setButtonType(true)
    // }
    useEffect(() => {
        if (ticketId !== "") {
            getRedTechComment(ticketId)
        }
    }, [ticketId])
    const fileSelectedHandler1 = (e: React.ChangeEvent<{ files: any }>) => {
        setFile1(e.target.files[0]);
    };



    const handleSaveComment = async () => {
        const body = {  
            message: comment,
            ticket: ticketId,
            screen_shot: file1,
            reply_to: redtechcomment.results[ticketId] || null
        }
        await postRedTechComment(body)
        setComment("")
        getRedTechComment(ticketId)
        setButtonType(true)
    }
    const handleCanelComment = () => {
        history.push(`/dashboard/cca/helpdesk`);
        setButtonType(false)
    }

    return (
        <Paper className={classes.paper} elevation={15}>
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>

                        <div className={classes.paper}>

                            <h5>Description</h5>

                            <>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextareaAutosize
                                        aria-label=" comment"
                                        minRows={3}
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value as string)}
                                        placeholder="comment"
                                        style={{ width: "70%", height: "100px" }}
                                    />
                                    <Grid item xs={12} sm={3} md={3} >
                                        <sup
                                            style={{
                                                fontSize: "11px",
                                                marginLeft: "15px",
                                                marginTop: "15px",
                                                background: "#fff",
                                                color: "rgba(0, 0, 0, 0.54)",
                                                letterSpacing: "0.00938em",
                                            }}
                                        >
                                            Upload ScreenShot
                                        </sup>
                                        <input
                                            className="input"
                                            style={{
                                                width: "100%",
                                                marginTop: "-15px",
                                                marginBottom: "10px",
                                                padding: "18.5px 14px",
                                                border: "1px solid rgba(0, 0, 0, 0.23)",
                                                borderRadius: "5px",
                                            }}
                                            type="file"
                                            name="file"
                                            id="file"
                                            multiple
                                            onChange={fileSelectedHandler1}
                                        />
                                    </Grid>
                                    <Button
                                        variant="contained"
                                        style={{
                                            marginLeft: "1rem",
                                            marginBottom: "1rem",
                                            backgroundColor: "#1976d2",
                                        }}
                                        onClick={handleSaveComment}
                                    >
                                        Submit

                                    </Button>
                                    <Button
                                        variant="contained"
                                        style={{
                                            marginLeft: "1rem",
                                            marginBottom: "1rem",
                                            backgroundColor: "#880e4f",
                                        }}
                                        onClick={handleCanelComment}
                                    >
                                        Cancel

                                    </Button>
                                </Grid>
                                {redtechcomment && redtechcomment.results && redtechcomment.results.length > 0 && redtechcomment.results.map((data: any, index: any) => {
                                    return (
                                        <>
                                            <span >Comment: {data?.message}</span><br /><br />

                                            <p>{data?.username} <span> : </span> Create At:  {new Date(data?.created_at).toLocaleString()}</p>

                                        </>
                                    )
                                })}
                            </>
                        </div>
                    </Fade>
                </Modal>
            </div>
        </Paper>
    );
};

const mapStateToProps = (state: any) => ({
    redtechcomment: state.CollectionCenterAdminReducer.redtechcomment,
    loading: state.CollectionCenterAdminReducer.loading,
    user: state.loginReducer.user,
});

export default connect(mapStateToProps, {
    getRedTechComment,
    postRedTechComment
})(CommentsModal2);
