import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Container, Grid, TextField, Button } from "@material-ui/core";
import "./BookingPage.sass";
import Box from '@mui/material/Box';
import NewBooking from "./NewBooking/NewBooking";
import ImagineBooking from './NewBooking/ImagingBooking'
import ImagineNewBooking from './NewBooking/ImagingNewBooking '
import NewBookingWithUHID from './NewBooking/newBookingWithUHID'
import MapMyIndia from './NewBooking/MapMyIndia'
import GenomicsBooking from './NewBooking/Genomics'
import GoogleBooking from './NewBooking/GoogleMapBooking'
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import PilotBooking from './NewBooking/pilotBooking'
import {getUserDetails} from '../../actions/bookingActions'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "100%",
      padding: "0px 0px 10px 0px",
      marginTop: "40px",
      minHeight: "300px",
    },
  })
);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
  style: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


interface Props {
  addressType: any;
  match: any;
  userDetails: any;
  getUserDetails: any;
}

const BookingPage: React.FC<Props> = ({
  addressType,
  match,
  userDetails,
  getUserDetails,
}) => {
  const classes = useStyles();
  const [bookingType, setBookingType] = useState<any>("homedx");
  const [value, setValue] = React.useState(0);
  const [leadId, setLeadId] = useState<string>("");
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {

    setValue(newValue);
  };
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const org_type = params.get("org_type");
    const lead = params.get("lead");
    if (org_type==="imagine") {
      setValue(1);
    }
    if (lead) {
      setLeadId(lead)
    }
   
  }, [window.location.search]);

  // console.log(addressType, "addressType")

  return (
    <>

     <div className={classes.root} style={{marginTop:"3rem"}}>
      <AppBar  position="static" >
        {/* <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="simple tabs example" style={{justifyContent:"space-around"}}> */}
          {/* <Tab style={{background:value===0?"#ffeb3b":"#fffdfc",color:"black"}}  label="Home-DX Booking" {...a11yProps(0)} /> */}
          {/* <Tab style={{background:value===1?"#ffeb3b":"#fffdfc",color:"black"}}  label="Home-DX Booking WITH UHID" {...a11yProps(1)} /> */}
          {/* <Tab style={{background:value===1?"#ffeb3b":"#fffdfc",color:"black"}} label="Imaging Booking" {...a11yProps(1)} /> */}
           {/* <Tab style={{background:value===2?"#ffeb3b":"#fffdfc",color:"black"}} label="Imaging New Booking" {...a11yProps(2)} />     */}
{/* \        </Tabs> */}
      </AppBar>
      
      <TabPanel style={{ width: "100%" }} value={value} index={0} >
         {/* {userDetails?.profile?.pilot_user?<PilotBooking lead={leadId}/>: <NewBookingWithUHID lead={leadId}/>}*/}
       {addressType?.booking_address==="mapmyindia"?<NewBookingWithUHID lead={leadId}/>:addressType?.booking_address==="address_iframe"?<MapMyIndia/>:<GoogleBooking/>} 
      </TabPanel>
      {/* <TabPanel  style={{ width: "100%" }} value={value} index={1}>
        <NewBookingWithUHID/>
      </TabPanel> */}
        {/* <TabPanel style={{ width: "100%" }} value={value} index={1}>
          <ImagineBooking lead={leadId}/>
        </TabPanel> */}
        {/* <TabPanel style={{ width: "100%" }} value={value} index={2}>
          <ImagineNewBooking />
        </TabPanel> */}
        {/* <TabPanel  style={{ width: "100%" }} value={value} index={2}>
        <GenomicsBooking/>
      </TabPanel> */}
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  addressType: state.loginReducer.addressType,
  userDetails: state.BookingReducer.userDetails,
});
export default connect(mapStateToProps, {
  getUserDetails
})(BookingPage);

