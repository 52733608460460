import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useHistory } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import { Button, Paper, Grid } from "@material-ui/core";

let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxHeight: "400px",

    position: "sticky",
  },
  head: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "2rem",
  },
}));

type ModalProps = {
  setOpenReportModel: Function;
  openReportModel: any;
};

const CommentsModal2: React.FC<ModalProps> = ({
  setOpenReportModel,
  openReportModel,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClose = () => {
    setOpenReportModel(false);
  };
  const handleCancelCenter = () => {
    history.push(`dashboard/ia`);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openReportModel}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openReportModel}>
        <div
          className={classes.paper}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <span style={{ margin: "3rem" ,marginBottom:"16px",marginTop:"13px"}}>
            <CancelIcon
              style={{
                color: "#ff1744",
                width: "3rem",
                height: "3rem",
                marginRight: "1rem",
              }}
            />
          </span>
          <span
            style={{
              fontSize: "x-large",
              textAlign: "center",
              width: "100%",
              display: "block",
              marginBottom: "10px",
            }}
          >
            Are You Sure
          </span>
          <p>Do you Really Want To Deactivate This Center?</p>

          <div style={{ marginTop: "20px" }}>
            <Button
              variant="contained"
              color="primary"
              style={{ marginRight: "10px" }}
              onClick={handleCancelCenter}
            >
              Cancel
            </Button>

            <Button 
            variant="contained" 
            color="secondary"
            
            >
              Deactivate
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  loading: state.ImagingAdminReducer.loading,
  user: state.loginReducer.user,
});

export default connect(mapStateToProps, {})(CommentsModal2);
