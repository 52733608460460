import React from "react";
import CustomerLeadDashboardLeft from "../../CustomerLead-Panel/dashboard-left";
import CustomerLeadDashboardRight from "../../CustomerLead-Panel/dashboard-right";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  container: {
    display: 'flex',
    padding: "20px"
  }
});

interface Props {
  children: any;
}

const CustomerLeadDash: React.FC<Props> = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CustomerLeadDashboardLeft />
      <CustomerLeadDashboardRight>{children && children}</CustomerLeadDashboardRight>
    </div>
  );
};

export default CustomerLeadDash;
