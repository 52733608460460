import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Container,
    Grid,
    TextField,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { generateBookingFilterUrl } from "../../helpers/generateUrl";
import { connect } from "react-redux";
import { getAgentList, getLeadSource, getPartners, getAllBookings, getCities, getPhlebos } from '../actions/bookingActions'
import './filter.sass'
import { useHistory } from "react-router";
import { Paper, Table, TableBody, TableCell,MenuItem, Select} from '@material-ui/core';
import Loader from "../../CustomerLead-Panel/components/loader/index";
import Export from "../components/Export";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(2),
        },
        card: {
            height: "100%",
            padding: theme.spacing(1),
        },
        card_grid: {
            // wordSpacing:2,
            justifyContent: 'space-between'
        },
    })
);

interface Props {
    children: any;
    agentsList: any;
    bookingList: any;
    partnerList: any;
    getAgentList: any;
    getPartners: any;
    getAllBookings: any;
    getCities: any;
    cities: any;
    getPhlebos: any;
    phleboList: any;
    getLeadSource: any;
    lead_source: any;
    loading: Boolean;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
    agentsList,
    partnerList,
    getAgentList,
    getPartners,
    getAllBookings,
    bookingList,
    getCities,
    cities,
    getPhlebos,
    phleboList,
    getLeadSource,
    lead_source,
    loading
}) => {
    const classes = useStyles();

    const history = useHistory()

    const [openModal, setopenModal] = useState<boolean>(false);

    const [filtered, setFiltered] = useState(false)

    const [status, setStatus] = useState<any>([])
    const [source, setSource] = useState<any>([])
    const [partner, setPartner] = useState<any>([])
    const [agent, setAgent] = useState<any>([])
    const [city, setCity] = useState<any>([])
    const [start_date, setStart_date] = useState("")
    const [end_date, setEnd_date] = useState("")
    const [booking_date, setBooking_date] = useState("")
    const [collection_date, setCollection_date] = useState("")
    const [verificationStatus, setVerificationStatus] = useState<any>([])
    const [pickup_status, setPickup_status] = useState<any>([])
    const [name, setName] = useState("")
    const [phlebo, setPhlebo] = useState<any>([])
    const [phone, setPhone] = useState("")
    const [report_status, setReportStatus] = useState<any>("none")
    const [page, setPage] = useState(0)
    const timer = useRef<any>(0)
    const [category, setCategory] = useState<any>([])

    const BOOKINGDISPOSITION: any = [
        // {
        //     id: 1,
        //     text: "Call Back From Sales"},
        // {
        //     id: 2,
        //     text: "Phlebo Didn't Reach on time"},
        // {
        //     id: 2,
        //     text: "Wrong Report Received"},
        // {
        //     id: 4,
        //     text: "Edit Report"},
        // {
        //     id: 5,
        //     text: "Report not received"},
        // {
        //     id: 4,
        //     text: "Partial report received"},
        // {
        //     id: 7,
        //     text: "Rescheduled to next Date"},
        // {
        //     id: 8,
        //     text: "Time Slot Changed"},
        // {
        //     id: 9,
        //     text: "Cancel- Out of Station"},
        // {
        //     id: 10,
        //     text: "Cancel- Travel Plan Changed"},
        // {
        //     id: 11,
        //     text: "Cancel- Duplicate Booking"},
        // {
        //     id: 12,
        //     text: "Cancel"},
        // {
        //     id: 12,
        //     text: "Subscribed to Competitor"},
        // {
        //     id: 14,
        //     text: "Call Later"},
        // {
        //     id: 15,
        //     text: "Wrong Number"},
        // {
        //     id: 14,
        //     text: "Health Consultation"},
        // {
        //     id: 17,
        //     text: "Phlebo Complaint"},
        // {
        //     id: 18,
        //     text: "Wrong Commitment By Sales"},
        // {
        //     id: 19,
        //     text: "Wrong Commitment By Phlebo"},
        // {
        //     id: 20,
        //     text: "Booking Done"}
          {
            id:8,
            text:"Time Slot Changed"
        },
          {
            id:9,
            text:"Cancel-Out Of Station"
        },
          {
            id:10,
            text:"Cancel-Travel Plan Changed"
        },
          {
            id:11,
            text:"Cancel-Duplicate Booking"
        },
          {
            id:12,
            text:"Cancel"
        },
         {
            id:21,
            text:"Booked From Different Lab"
        },
          {
            id:15,
            text:"Wrong Number"
        },
         {
            id:22,
            text:"Cancel-Out Of Service Area "
        },
          {
            id:22,
            text:"Confirmed "
        },
        {
            id:24,
            text:"Rescheduled  "
        },
          {
            id:14,
            text:"Call Later "
        }
    ]

    useEffect(() => {
        getAgentList()
        getPartners()
        getCities()
        getLeadSource()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const filterBookings = () => {
        const body: any = {
            status: status.join(","),
           lead_call_status_id: category.join(","),
            verificationStatus: verificationStatus.join(","),
            source: source.join(","),
            agent: agent.join(","),
            city: city.join(","),
            start_date,
            end_date,
            booking_date,
            collection_date,
            pickup_status: pickup_status.join(","),
            name,
            phlebo: phlebo.join(","),
            partner: partner.join(","),
            report_status,
            phone
        }
        const url = generateBookingFilterUrl(body).substring(2)
        getAllBookings(`${url}&download=true`)
        setPage(0)
        setFiltered(true)
    }
    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Grid container spacing={1} direction="row" alignItems="center" justify="center">
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="name"
                            type="text"
                            label="Name"
                            value={name}
                            variant="outlined"
                            onChange={(e) => setName(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="phone"
                            type="text"
                            label="Phone"
                            value={phone}
                            variant="outlined"
                            onChange={(e) => setPhone(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="city"
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setCity(sourceIds)
                            }}
                            options={cities}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            getOptionLabel={(option: any) => option.name}
                            getOptionDisabled={(option: any) => {
                                return city.includes(option.id)
                            }}
                            disableClearable
                            disableCloseOnSelect
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                                    getCities(newInputValue)
                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setCity([])
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="City"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="bookingstatus"
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.value)
                                }
                                setStatus(sourceIds)
                            }}
                            options={[
                                {
                                    text: "Pending",
                                    value: "pending"
                                },
                                {
                                    text: "Confirmed",
                                    value: "confirmed"
                                },
                                {
                                    text: "Cancelled",
                                    value: "cancelled"
                                },
                                {
                                    text: "Rescheduled",
                                    value: "rescheduled"
                                },
                                {
                                    text: "Resampling",
                                    value: "resampling"
                                },
                                 {
                                    text: "Sample Not Received Due To Payment Information",
                                    value: "Sample Not Received Due To Payment Information"
                                },
                                {
                                    text: "Sample Received",
                                    value: "sample received"
                                },
                                 {
                                    text: "Partial Received",
                                    value: "partial received"
                                },
                            ]}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            getOptionLabel={(option: any) => option.text}
                            getOptionDisabled={(option: any) => {
                                return status.includes(option.value)
                            }}
                            disableClearable
                            disableCloseOnSelect
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Booking Status"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="partner"
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setPartner(sourceIds)
                            }}
                            freeSolo
                            blurOnSelect
                            limitTags={1}
                            multiple
                            options={partnerList.results}
                            getOptionDisabled={(option: any) => {
                                return partner.includes(option.id)
                            }}
                            disableClearable
                            disableCloseOnSelect
                            getOptionLabel={(option: any) => option.user && option.user.name}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                                    getPartners(newInputValue)
                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setPartner([])
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Partner"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setSource(sourceIds)
                            }}
                            freeSolo
                            multiple
                            id="checkboxes-tags-demo"
                            options={lead_source}
                            disableCloseOnSelect
                            limitTags={1}
                            disableClearable
                            getOptionLabel={(option: any) => option.name}
                            getOptionDisabled={(option: any) => {
                                return source.includes(option.id)
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Source"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="agent"
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setAgent(sourceIds)
                            }}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            options={agentsList.results||[]}
                            getOptionDisabled={(option: any) => {
                                return agent.includes(option.id)
                            }}
                            disableClearable
                            disableCloseOnSelect
                            getOptionLabel={(option: any) => option.user && option.user.username}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                            getAgentList(`?code=${newInputValue.replace(" ","")}`)

                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setPartner([])
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Agent"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="phlebo"
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setPhlebo(sourceIds)
                            }}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            options={phleboList.results ? [...phleboList.results, { id: 0, user: { username: "NoPhlebo" } }] : []}
                            getOptionDisabled={(option: any) => {
                                return phlebo.includes(option.id)
                            }}
                            disableClearable
                            disableCloseOnSelect
                            getOptionLabel={(option: any) => option.user && option.user.username}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                                    getPhlebos(newInputValue)
                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setPhlebo([])
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Phlebo Name"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="verificationstatus"
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.value)
                                }
                                setVerificationStatus(sourceIds)
                            }}
                            options={[
                                {
                                    text: "Pending",
                                    value: "pending"
                                },
                                {
                                    text: "Confirmed",
                                    value: "confirmed"
                                },
                                {
                                    text: "Cancelled",
                                    value: "cancelled"
                                }
                            ]}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            getOptionLabel={(option: any) => option.text}
                            getOptionDisabled={(option: any) => {
                                return verificationStatus.includes(option.value)
                            }}
                            disableClearable
                            disableCloseOnSelect
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Verification Status"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="pickupstatus"
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.value)
                                }
                                setPickup_status(sourceIds)
                            }}
                            options={[
                                {
                                    text: "Pending",
                                    value: "pending"
                                },
                                {
                                    text: "Confirmed",
                                    value: "confirmed"
                                },
                                {
                                    text: "Cancelled",
                                    value: "cancelled"
                                },
                                {
                                    text: "Hold",
                                    value: "hold"
                                }
                            ]}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            getOptionLabel={(option: any) => option.text}
                            getOptionDisabled={(option: any) => {
                                return pickup_status.includes(option.value)
                            }}
                            disableClearable
                            disableCloseOnSelect
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Pickup Status"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={4} md={4}>
                        <Autocomplete
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setCategory(sourceIds)
                            }}
                            multiple
                            id="checkboxes-tags-demo"
                            options={BOOKINGDISPOSITION}
                            disableCloseOnSelect
                            limitTags={1}
                            getOptionLabel={(option: any) => option.text}
                            getOptionDisabled={(option: any) => {
                                return category.includes(option.id)
                            }}
                            disableClearable
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    placeholder="Booking Disposition"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required
                                />
                            )}
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="start_date"
                            type="date"
                            label="Start Date"
                            value={booking_date === "" || collection_date === "" ? start_date : ""}
                            variant="outlined"
                            disabled={booking_date !== "" || collection_date !== ""}
                            onChange={(e) => setStart_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="end_date"
                            type="date"
                            value={booking_date === "" || collection_date === "" ? end_date : ""}
                            className="input"
                            label="End Date"
                            variant="outlined"
                            disabled={booking_date !== "" || collection_date !== ""}
                            onChange={(e) => setEnd_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="booking_date"
                            type="date"
                            value={start_date === "" || end_date === "" ? booking_date : ""}
                            className="input"
                            label="Booking Date"
                            variant="outlined"
                            disabled={start_date !== "" || end_date !== ""}
                            onChange={(e) => setBooking_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="collection_date"
                            type="date"
                            value={start_date === "" || end_date === "" ? collection_date : ""}
                            className="input"
                            label="Collection Date"
                            variant="outlined"
                            disabled={start_date !== "" || end_date !== ""}
                            onChange={(e) => setCollection_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                     <Grid item xs={12} sm={4} md={2}>
                           
                            <Select className="input"
                                name="sampleregisterd"
                                variant="outlined"
                                value={report_status}
                                style={{ width: "100%", margin: "0" }}
                                
                                onChange={(e) => setReportStatus(e.target.value as String)}
                               
                            >
                                <MenuItem disabled value={"none"}>Report Status</MenuItem>
                                <MenuItem value={"na"}>None</MenuItem>
                                 <MenuItem value={"pending"}>Pending</MenuItem>
                                <MenuItem value={"partial"}>Partial</MenuItem>
                                <MenuItem value={"consolidate"}>Consolidate</MenuItem>
                               
                            </Select>
                        </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            // style={{ height: "54px" }}
                            fullWidth
                            onClick={filterBookings}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            // style={{ height: "54px" }}
                            fullWidth
                            onClick={() => {
                                setFiltered(false)
                                history.push("/dashboard/cl/bookings/export")
                            }}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
                <br />
                {loading ? <Loader /> : filtered && bookingList.results && bookingList.results.length > 0 && (
                    <Box>
                        <Container maxWidth={false}>
                            <Grid container spacing={2}>
                                <Export loading={loading} bookingList={bookingList} />
                            </Grid>
                        </Container>
                    </Box>
                )}
                {bookingList.results && bookingList.results.length === 0 && (
                    <h4 style={{ textAlign: "center" }}>No Data Found</h4>
                )}
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    agentsList: state.CustomerLeadReducer.agentsList,
    partnerList: state.CustomerLeadReducer.partnerList,
    bookingList: state.CustomerLeadReducer.bookingList,
    cities: state.CustomerLeadReducer.cities,
    phleboList: state.CustomerLeadReducer.phleboList,
    loading: state.CustomerLeadReducer.loading,
    lead_source: state.CustomerLeadReducer.lead_source,
})

export default connect(mapStateToProps, {
    getAgentList,
    getPartners,
    getCities,
    getPhlebos,
    getLeadSource,
    getAllBookings
})(CustomerLeadFilterPage);
