import React from 'react'
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import moment from "moment";
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, } from '@material-ui/data-grid';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
    })
);

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

interface Props {
    callStats: any;
    loading: boolean;
}

const CallExport: React.FC<Props> = ({
    callStats,
    loading,
}) => {
    const classes = useStyles();

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Call Agent', width: 200 },
        { field: 'outgoing_total_call', headerName: 'Outgoing Total Call', width: 200 },
        { field: 'outgoing_unique_call', headerName: 'Outgoing Unique Call', width: 200 },
        { field: 'outgoing_total_talktime', headerName: 'Collection Slot', width: 200 },
        { field: 'incoming_total_call', headerName: 'Incoming Total Call', width: 200 },
        { field: 'incoming_unique_call', headerName: 'Incoming Unique Call', width: 200 },
        { field: 'incoming_total_talktime', headerName: 'Incoming Total TalkTime', width: 200 },
        { field: 'total_talktime', headerName: 'Total TalkTime<', width: 200 },
      
    ];

    let rows:any = []

    if (callStats.data) {
        
        rows = callStats.data.length > 0 && callStats.data.map((call: any) => {
            return {
                ...call,
                id: call.username,
                outgoing_total_call: call.outgoing_call_missed + call.outgoing_call_picked,
                outgoing_unique_call: call.outgoing_unique_call_missed + call.outgoing_unique_call_picked,
                outgoing_total_talktime: moment.utc(call.outgoing_talktime_picked * 1000).format('HH:mm:ss'),
                incoming_total_call: call.incomming_call_missed + call.incomming_call_picked,
                incoming_unique_call: call.incomming_unique_call_missed + call.incomming_unique_call_picked,
                incoming_total_talktime: moment.utc(call.incomming_talktime_picked * 1000).format('HH:mm:ss'),
                total_talktime: moment.utc((call.incomming_talktime_picked + call.outgoing_talktime_picked) * 1000).format('HH:mm:ss'),
            }
        })
    }

    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <div style={{ width: "100%" }} className="data-table">
            <Paper elevation={15} className={classes.paper}>
                <div style={{ height: 700 }}>
                    <DataGrid rowsPerPageOptions={[]}
                        rows={rows}
                        columns={columns}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        pageSize={50}
                        rowCount={callStats.count || 0}
                        loading={loading}
                    />
                </div>
            </Paper>
            </div>
        </main>
    )
}

const mapStateToProps = (state: any) => ({

})

export default connect(mapStateToProps, {

})(CallExport);

