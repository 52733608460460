import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  withStyles,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import { Grid, TextField } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import MessageIcon from "@material-ui/icons/Message";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import CallIcon from "@material-ui/icons/Call";
import Modal from "@mui/material/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { CALL_DISABLE_TIME } from "../../CallTimeDisable";
import ReplyModal from "../components/Comments/replyModal";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import UserDetails from "../pages/userStatus";
import VisibilityTwoToneIcon from "@material-ui/icons/VisibilityTwoTone";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ImagingTicket from "./imagingTicket";
import SendIcon from "@material-ui/icons/Send";
import {
  clickToCall,
  getLeadSource,
  getAllLeads,
  getCities,
  getLeadStatus,
  getPrescriptionData,
  sendInitialMessage,
  getLtvLead,
} from "../actions/bookingActions";
import { TableFooter, TablePagination, Dialog } from "@material-ui/core";
import Loader from "../components/Loader2";
import CommentsModal from "../components/Comments/comment";
import CommentsModal2 from "../components/Comments/Comment2";
import { generateLeadsUrl } from "../../helpers/generateUrl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import RaiseComplaintModal from "../components/Comments/raiseTicketModal";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import { ExitToAppRounded, MessageRounded } from "@material-ui/icons";
import ImagingTicketLeads from "../tables/ImagingTicketFilter";
import WhatsAppModal from "../components/Comments/whatsappModal"
import EqualizerIcon from "@material-ui/icons/Equalizer";
const StyledTableCellBordered: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
      border: "0.6px solid grey",
    },
    body: {
      fontSize: 14,
      border: "0.6px solid grey",
    },
  })
)(TableCell);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
    },
    contentFilter: {
      width: "100%",
      padding: "0px 10px 10px 0px",
    },
    tableHead: {
      background: "rgb(146, 74, 145)!important",
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
    tableContainer: {
      marginTop: "2rem",
      position: "sticky",
      top: "200px",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);
interface Props {
  getAllLeads: Function;
  all_lead: any;
  loading: boolean;
  // resendSms: Function;
  clickToCall: Function;
  getCities: any;
  cities: any;
  getLeadStatus: any;
  leadStatus: any;
  getPrescriptionData: any;
  prescription: any;
  sendInitialMessage: any;
  getLtvLead: any;
  ltvLead: any;
  breakResponse: any;
  leadById: any;
  booking: any;
}

const LeadsTable: React.FC<Props> = ({
  getAllLeads,
  all_lead,
  clickToCall,
  getCities,
  cities,
  getLeadStatus,
  leadStatus,
  loading,
  getPrescriptionData,
  prescription,
  sendInitialMessage,
  getLtvLead,
  ltvLead,
  breakResponse,
  leadById,
  booking,
}) => {
  const classes = useStyles();
  document.querySelectorAll("#dateWidthCustom").forEach(el=>{
    el.addEventListener("keydown", function(e){
      e.preventDefault()
    })
  })
  const [open2, setOpen2] = useState<boolean>(false);
  const [openWhatsapp, setOpenWhatsapp] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [raiseComplaint, setRaiseComplaint] = useState(false);
  const [leadId, setLeadId] = useState<number>(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50 || all_lead.results.count);
  const [openView, setOpenView] = useState<boolean>(false);
  const [category, setCategory] = useState<any>([]);
  const [id, setId] = useState<string>("");
  const [createdAt, setCreatedAt] = useState<string>("");
  const [followUpDate, setFollowUpDate] = useState<string>("");
  const [leadSource, setleadSource] = useState<any>([]);
  const [priority, setPriority] = useState<any>([]);
  const [start_date, setStart_date] = useState<string>("");
  const [end_date, setEnd_date] = useState<string>("");
  const [prscriptionLead, setPrescriptionLead] = useState<any>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openPrescription = Boolean(anchorEl);
  const [pLink, setPlink] = useState<any>("");
  const [openPdf, setOpenPdf] = useState<boolean>(false);
  const [openWhatsappTemp, setOpenWhatsappTemp] = useState<boolean>(false);
  const [leadIdWhatsapp, setLeadIdWhatsapp] = useState<number>(0);
  const [fileType, setFileType] = useState<any>("");
  const history = useHistory();
  const [callButton, setCallButton] = useState<boolean>(true);

  const priorityArr: any = [
    {
      value: "SuperCritical",
    },
    {
      value: "Urgent",
    },
    {
      value: "HopeFull",
    },
    {
      value: "Low",
    },
  ];

  useEffect(() => {
    // let url = ``;
    getAllLeads("");
    getLeadStatus();
    // getCities()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterLeads = () => {
    const body: any = {
      lead_call_status_id: category.join(","),
      leadSource: leadSource.join(","),
      priority: priority.join(","),
      followUpDate,
      start_date,
      end_date,
      id,
    };
    const url = generateLeadsUrl(body).substring(2);
    getAllLeads(url ? `?${url}` : "");
    setPage(0);
  };

  useEffect(() => {
    if (open2 === false) {
      setLeadId(0);
    }
  }, [open2]);

  const handleClick = (id: number) => {
    setOpen(true);
    setLeadId(id);
  };
  const handleClickWhatsapp = (id: number) => {
    setOpenWhatsappTemp(true);
    setLeadIdWhatsapp(id);
  };


  const handleModal = (id: number) => {
    setOpen2(true);
    setLeadId(id);
  };

  const handleCallClick = (id: number, type: string) => {
    setLeadId(id);
    clickToCall(id, type);
    setCallButton(false);
    setTimeout(() => {
      setCallButton(true);
    }, CALL_DISABLE_TIME);
  };

  const handleRaiseTicket = (id: number) => {
    setRaiseComplaint(true);
    setLeadId(id);
  };

  // const handleResendClick = (id: number) => {
  //   let body = {
  //     appt_id: id,
  //     template_id: "1010101"
  //   };
  //   resendSms(body);
  // };
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    if (newPage > page) {
      let url = all_lead.next;
      getAllLeads(url.substring(url.indexOf("?")));
    } else if (newPage < page) {
      let url = all_lead.previous;

      getAllLeads(
        url.indexOf("?") === -1 ? "" : url.substring(url.indexOf("?"))
      );
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  useEffect(() => {
    if (prscriptionLead !== "") {
      getPrescriptionData(`?lead=${prscriptionLead}`);
    }
  }, [prscriptionLead]);
  const handlePrescriptionClick = (
    id: any,
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setPrescriptionLead(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFrameView = (data: any) => {
    if (data.base64file !== null && data.image === "") {
      setFileType("b64");
      setPlink(`data:application/pdf;base64,${data.base64file}`);
    } else if (data.base64file === null && data.image !== "") {
      let pieces: any = data.image.split(".").pop();
      const last: any = pieces;
      setFileType(last);
      setPlink(data.image);
    }
    setOpenPdf(true);
  };
  const handlePdfModelClose = () => {
    setOpenPdf(false);
  };
  const handlereply = (id: any) => {
    setOpenWhatsapp(true);
    setLeadId(id);
  };
  const handleInitialWhatsapp = (id: any) => {
    sendInitialMessage(id);
    let url = ``;
    getAllLeads(url);
  };
  const handleView = (id: any) => {
    getLtvLead(id);
    setOpenView(true);
  };
  const handleCloseView = () => {
    setOpenView(false);
  };
  return (
    <div style={{ width: "95%" }}>
      <div className={classes.toolbar} />
      <UserDetails
        cities={cities}
        getCities={getCities}
        setCallButton={setCallButton}
        callButton={callButton}
        breakResponse={breakResponse}
        clickToCall={clickToCall}
        leadById={leadById}
        booking={booking}
      />
      <br />
      <ImagingTicketLeads sendInitialMessage={sendInitialMessage} />
      <br />
      <br />
      <h4 style={{ fontWeight: "bold" }}>LEAD DETAILS</h4>
      <div className={classes.contentFilter}>
        <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "left" }}
        >
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="city"
              type="text"
              value={id}
              className="input"
              variant="outlined"
              placeholder="Lead Id"
              disabled={createdAt !== ""}
              onChange={(e) => setId(e.target.value.replace(/[^0-9]/g,"") as string)}
              style={{ width: "100%" }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              id="dateWidthCustom"
              name="Created At"
              type="date"
              label="Follow-Up Date"
              disabled={
                start_date !== "" || end_date !== "" || createdAt !== ""
              }
              value={followUpDate}
              variant="outlined"
              onChange={(e) => setFollowUpDate(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              className="input"
              id="dateWidthCustom"
              name="start_date"
              type="date"
              label="Start Date"
              value={start_date}
              disabled={createdAt !== "" || followUpDate !== ""}
              variant="outlined"
              onChange={(e) => setStart_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <TextField
              name="end_date"
              id="dateWidthCustom"
              type="date"
              value={end_date}
              className="input"
              disabled={createdAt !== "" || followUpDate !== ""}
              label="End Date"
              variant="outlined"
              onChange={(e) => setEnd_date(e.target.value as string)}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.id);
                }
                setCategory(sourceIds);
              }}
              multiple
              id="checkboxes-tags-demo"
              options={leadStatus || []}
              disableCloseOnSelect
              limitTags={1}
              getOptionLabel={(option: any) => option?.name}
              getOptionDisabled={(option: any) => {
                return category.includes(option.id);
              }}
              disableClearable
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Status"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Autocomplete
              onChange={(event, newValue) => {
                let sourceIds: any = [];
                if (newValue) {
                  let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                  sourceIds = obj.map((item: any) => item.value);
                }
                setPriority(sourceIds);
              }}
              multiple
              id="checkboxes-tags-demo"
              options={priorityArr}
              disableCloseOnSelect
              limitTags={1}
              getOptionLabel={(option: any) => option.value}
              getOptionDisabled={(option: any) => {
                return priority.includes(option.value);
              }}
              disableClearable
              renderInput={(params) => (
                <TextField
                  className="input"
                  {...params}
                  placeholder="Priority"
                  variant="outlined"
                  style={{ width: "100%", margin: "0", padding: "0" }}
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={filterLeads}
            >
              Filter
            </Button>
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => history.push("/dashboard/ce/Leadtable")}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </div>
      <div style={{ width: "100%" }} className="data-table">
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        style={{ maxHeight: "540px" }}
      >
        {loading ? (
          <Loader />
        ) : (
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="center">Lead_ID</StyledTableCell>
                <StyledTableCell align="center">Prescription</StyledTableCell>
                <StyledTableCell align="center">Comments</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
                <StyledTableCell align="center">Whatsapp</StyledTableCell>
                <StyledTableCell align="center">Send Whatsapp</StyledTableCell>
                <StyledTableCell align="center">Whatsapp Template</StyledTableCell>
                <StyledTableCell align="center">
                  Lead Source Url
                </StyledTableCell>
                <StyledTableCell align="center">Agent</StyledTableCell>
                <StyledTableCell align="center">Call</StyledTableCell>
                <StyledTableCell align="center">Contact number</StyledTableCell>
                <StyledTableCell align="center">Patient name</StyledTableCell>
                <StyledTableCell align="center">City</StyledTableCell>
                <StyledTableCell align="center">City Name</StyledTableCell>
                <StyledTableCell align="center">Patient Area</StyledTableCell>
                <StyledTableCell align="center">Call status</StyledTableCell>
                <StyledTableCell align="center">Language</StyledTableCell>
                <StyledTableCell align="center">Edit</StyledTableCell>
                {/* <StyledTableCell align="center">Raise Ticket</StyledTableCell> */}
                <StyledTableCell align="center">Priority</StyledTableCell>
                {/* <StyledTableCell align="center">Call Count</StyledTableCell> */}
                <StyledTableCell align="center">View</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {all_lead.count > 0
                ? all_lead.results.map((row: any) => (
                    <StyledTableRow
                      key={row.id}
                      style={{
                        background:
                          row?.lead_source === "imaging" ||
                          row?.lead_source === "imaging_lp" ||
                          row?.lead_source === "imaging_call" ||
                          row?.lead_source === "imaging_homedx" ||
                          row?.lead_source === "ZOKO_IMAGING" ||
                          row?.lead_source === "imaging_alliance" ||
                          row?.lead_source === "imaging_dsa" ||
                          row?.lead_source === "imaging_web" ||
                          row?.lead_source === "justdial_imaging"
                            ? "#ffeb3b"
                            : "",
                      }}
                    >
                      <StyledTableCell component="th" scope="row" align="center">
                        {row.id}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.is_prescription ? (
                          <Button
                            aria-controls="fade-menu"
                            aria-haspopup="true"
                            onClick={(event: any) =>
                              handlePrescriptionClick(row.id, event)
                            }
                          >
                            <NoteAddIcon />
                          </Button>
                        ) : (
                          "NA"
                        )}
                        <Menu
                          id="fade-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={openPrescription}
                          onClose={handleClose}
                          TransitionComponent={Fade}
                        >
                          {prescription &&
                            prescription?.results &&
                            prescription?.results?.length > 0 &&
                            prescription?.results.map(
                              (data: any, index: any) => {
                                let pieces: any = data.image.split(".").pop();
                                const last: any = pieces;
                                return (
                                  <>
                                    {data?.base64file !== null &&
                                    data.image === "" ? (
                                      <MenuItem
                                        onClick={() => handleFrameView(data)}
                                      >
                                        {" "}
                                        Created Date :
                                        {new Date(
                                          data.created_at
                                        ).toLocaleString()}
                                      </MenuItem>
                                    ) : data?.base64file === null &&
                                      data.image !== "" ? (
                                      last === "png" ||
                                      last === "jpg" ||
                                      last === "jpeg" ? (
                                        <MenuItem>
                                          <a
                                            href={data?.image}
                                            rel="noreferrer noopener"
                                            target="_blank"
                                          >
                                            Created Date :
                                            {new Date(
                                              data.created_at
                                            ).toLocaleString()}
                                          </a>
                                        </MenuItem>
                                      ) : (
                                        <MenuItem>
                                          <a
                                            href={data?.image}
                                            rel="noreferrer noopener"
                                            target="_blank"
                                          >
                                            Created Date :
                                            {new Date(
                                              data.created_at
                                            ).toLocaleString()}
                                          </a>
                                        </MenuItem>
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </>
                                );
                              }
                            )}
                        </Menu>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div>
                          <MessageIcon onClick={() => handleClick(row.id)} />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div>
                          <AccountBalanceWalletIcon
                            onClick={() => handleModal(row.id)}
                          />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <WhatsAppIcon onClick={() => handlereply(row.id)} />
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.lead_source === "imaging" ||
                        row.lead_source === "imaging_lp" ||
                        row.lead_source === "imaging_call" ||
                        row.lead_source === "imaging_homedx" ||
                        row.lead_source === "ZOKO_IMAGING" ||
                        row.lead_source === "imaging_alliance" ||
                        row.lead_source === "imaging_dsa" ||
                        row.lead_source === "justdial_imaging" ||
                        row.lead_source === "imaging_web" ? (
                          <SendIcon
                            onClick={() => handleInitialWhatsapp(row.id)}
                          />
                        ) : (
                          ""
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <WhatsAppIcon onClick={() => handleClickWhatsapp(row.id)}/>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.lead_source_url ? row.lead_source_url : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.panel_user ? row.panel_user : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {/* <CallIcon
                          onClick={() => handleCallClick(row.id, "call")}

                        /> */}
                        <Button
                          disabled={!callButton}
                          className="addDeck"
                          variant="contained"
                          color="primary"
                          onClick={() => handleCallClick(row.id, "call")}
                          startIcon={<CallIcon />}
                        ></Button>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {/* {row.content_number ? <CallIcon
                          onClick={() => handleCallClick(row.id, "contact")}
                        /> : null} */}
                        {row.content_number ? (
                          <Button
                            disabled={!callButton}
                            className="addDeck"
                            variant="contained"
                            color="primary"
                            onClick={() => handleCallClick(row.id, "contact")}
                            startIcon={<CallIcon />}
                          ></Button>
                        ) : null}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.patient_name ? row.patient_name : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.patient_city}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.city_name}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.patient_area ? row.patient_area : "NA"}
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        {row.call_status}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.language ? row.language : "NA"}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Link to={`/dashboard/ce/fill-info?id=${row.id}`}>
                          <EditIcon />
                        </Link>
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                        <Button
                          variant={!raiseComplaint ? "contained" : "outlined"}
                          color="secondary"
                          onClick={() => handleRaiseTicket(row.id)}
                        >
                          <ConfirmationNumberIcon />
                        </Button>
                      </StyledTableCell> */}
                      <StyledTableCell align="center">
                        {row.priority}
                      </StyledTableCell>
                      {/* <StyledTableCell align="center">
                        {row.count}
                      </StyledTableCell> */}
                      <StyledTableCell align="center">
                        {row.lead_source === "LTV" ? (
                          <VisibilityTwoToneIcon
                            style={{ marginLeft: 20 }}
                            color="primary"
                            fontSize="large"
                            onClick={() => handleView(row.id)}
                          />
                        ) : (
                          ""
                        )}
                        {/* <VisibilityTwoToneIcon
                            style={{ marginLeft: 20 }}
                            color="primary"
                            fontSize="large"
                            onClick={() => handleView(row.id)}
                          /> */}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                : "NA"}
            </TableBody>
            <TableFooter>
              <TablePagination
                colSpan={6}
                count={all_lead.count || 0}
                rowsPerPageOptions={[]}
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page}
                SelectProps={{
                  native: true,
                }}
                onPageChange={handleChangePage}
              />
            </TableFooter>
          </Table>
        )}
      </TableContainer>
      </div>
      <Dialog
        open={openView}
        onClose={handleCloseView}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <TableContainer style={{ maxHeight: "580px" }}>
          {loading ? (
            <Loader />
          ) : (
            <Table stickyHeader aria-label="simple table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCellBordered align="center">
                    Parameters
                  </StyledTableCellBordered>
                  <StyledTableCellBordered align="center">
                    Ref Value
                  </StyledTableCellBordered>
                  <StyledTableCellBordered align="center">
                    Customer<span style={{ display: "none" }}>_</span>Name :
                    {ltvLead && ltvLead.booking && ltvLead.booking.length > 0
                      ? ltvLead.booking[0]?.customer_name
                      : ""}{" "}
                    Booking<span style={{ display: "none" }}>_</span>id:
                    {ltvLead && ltvLead.booking && ltvLead.booking.length > 0
                      ? ltvLead.booking[0]?.id
                      : ""}{" "}
                    Collection<span style={{ display: "none" }}>_</span>Date:
                    {ltvLead && ltvLead.booking && ltvLead.booking.length > 0
                      ? ltvLead.booking[0]?.collection_date
                      : ""}
                  </StyledTableCellBordered>
                  <StyledTableCellBordered align="center">
                    Customer<span style={{ display: "none" }}>_</span>Name :{" "}
                    {ltvLead && ltvLead.booking && ltvLead.booking.length > 1
                      ? ltvLead.booking[0]?.customer_name
                      : ""}{" "}
                    Booking<span style={{ display: "none" }}>_</span>id:
                    {ltvLead && ltvLead.booking && ltvLead.booking.length > 1
                      ? ltvLead.booking[1]?.id
                      : ""}{" "}
                    Collection<span style={{ display: "none" }}>_</span>Date:
                    {ltvLead && ltvLead.booking && ltvLead.booking.length > 1
                      ? ltvLead.booking[1]?.collection_date
                      : ""}
                  </StyledTableCellBordered>
                  <StyledTableCellBordered align="center">
                    Customer<span style={{ display: "none" }}>_</span>Name :{" "}
                    {ltvLead && ltvLead.booking && ltvLead.booking.length > 2
                      ? ltvLead.booking[0]?.customer_name
                      : ""}{" "}
                    Booking<span style={{ display: "none" }}>_</span>id:
                    {ltvLead && ltvLead.booking && ltvLead.booking.length > 2
                      ? ltvLead.booking[2]?.id
                      : ""}{" "}
                    Collection<span style={{ display: "none" }}>_</span>Date:
                    {ltvLead && ltvLead.booking && ltvLead.booking.length > 2
                      ? ltvLead.booking[2]?.collection_date
                      : ""}
                  </StyledTableCellBordered>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {ltvLead &&
                  ltvLead.test_parameters &&
                  ltvLead.test_parameters.map((row: any) => (
                    <StyledTableRow>
                      <StyledTableCellBordered align="center">
                        {row.test_values__test_parameter__test_name}
                      </StyledTableCellBordered>
                      <StyledTableCellBordered align="center">
                        {ltvLead &&
                        ltvLead.booking &&
                        ltvLead.booking.length > 0
                          ? ltvLead.booking[0]?.customer_gender === "female"
                            ? row?.test_values__test_parameter__other_female
                            : row?.test_values__test_parameter__other_male
                          : ""}
                      </StyledTableCellBordered>
                      <StyledTableCellBordered align="center">
                        {ltvLead &&
                          ltvLead.booking &&
                          ltvLead.booking.length > 0 &&
                          ltvLead.booking[0].test_parameters &&
                          ltvLead.booking[0].test_parameters.length > 0 &&
                          ltvLead.booking[0].test_parameters.map((val: any) => {
                            if (
                              val.test_values__test_parameter ===
                              row.test_values__test_parameter__id
                            ) {
                              return String(val.test_values__value).slice(
                                0,
                                25
                              );
                            }
                          })}
                      </StyledTableCellBordered>
                      <StyledTableCellBordered align="center">
                        {ltvLead &&
                          ltvLead.booking &&
                          ltvLead.booking.length > 1 &&
                          ltvLead.booking[1].test_parameters &&
                          ltvLead.booking[1].test_parameters.length > 0 &&
                          ltvLead.booking[1].test_parameters.map((val: any) => {
                            if (
                              val.test_values__test_parameter ===
                              row.test_values__test_parameter__id
                            ) {
                              return String(val.test_values__value).slice(
                                0,
                                25
                              );
                            }
                          })}
                      </StyledTableCellBordered>
                      <StyledTableCellBordered align="center">
                        {ltvLead &&
                          ltvLead.booking &&
                          ltvLead.booking.length > 2 &&
                          ltvLead.booking[2].test_parameters &&
                          ltvLead.booking[2].test_parameters.length > 0 &&
                          ltvLead.booking[2].test_parameters.map((val: any) => {
                            if (
                              val.test_values__test_parameter ===
                              row.test_values__test_parameter__id
                            ) {
                              return String(val.test_values__value).slice(
                                0,
                                25
                              );
                            }
                          })}
                      </StyledTableCellBordered>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Dialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openPdf}
        onClose={handlePdfModelClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openPdf}>
          <>
            {fileType === "pdf" ? (
              <iframe
                style={{ width: "80%", height: "750px" }}
                src={pLink}
                title="title"
              >
                Presss me: <a href={pLink}>Download PDF</a>
              </iframe>
            ) : fileType === "b64" ? (
              <iframe
                src={pLink}
                style={{ width: "80%", height: "750px" }}
                title="Iframe Example"
              ></iframe>
            ) : (
              ""
            )}
          </>
        </Fade>
      </Modal>

      {open && <CommentsModal open={open} setOpen={setOpen} leadId={leadId} />}
      {open2 && <CommentsModal2
        open={open2}
        getCities={getCities}
        cities={cities}
        setOpen={setOpen2}
        leadId={leadId}
      />}
      {raiseComplaint && <RaiseComplaintModal
        open={raiseComplaint}
        setOpen={setRaiseComplaint}
        leadId={Number(leadId)}
        bookingId={0} booking={undefined} totalPrice={0}      />}
      {openWhatsapp && <ReplyModal
        open={openWhatsapp}
        setOpen={setOpenWhatsapp}
        setLeadId={setLeadId}
        leadId={leadId}
        callApi={"no"}
      />}
      {openWhatsappTemp && 
      <WhatsAppModal 
        open={openWhatsappTemp} 
        setOpen={setOpenWhatsappTemp} 
        leadId={leadIdWhatsapp} 
      />}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  all_lead: state.BookingReducer.all_lead,
  // lead_source: state.BookingReducer.lead_source,
  cities: state.BookingReducer.cities,
  loading: state.BookingReducer.loading,
  leadStatus: state.BookingReducer.leadStatus,
  prescription: state.BookingReducer.prescription,
  ltvLead: state.SalesLeadAdminReducer.ltvLead,
  breakResponse: state.loginReducer.breakResponse,
  leadById: state.BookingReducer.leadById,
});

export default connect(mapStateToProps, {
  getAllLeads,
  clickToCall,
  getLeadSource,
  getCities,
  getLeadStatus,
  getPrescriptionData,
  sendInitialMessage,
  getLtvLead,
})(LeadsTable);
