import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import { DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport, } from '@material-ui/data-grid';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        paper: {
            marginTop: "2rem",
            padding: "0.5rem 2rem",
            width: "100%",
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
    })
);

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

interface Props {
    bookingListWithTatTime: any;
    loading: boolean;
}

const BookingExport: React.FC<Props> = ({
    bookingListWithTatTime,
    loading
}) => {
    const classes = useStyles();
    const [allData, setAllData ]= useState<any>([]);
useEffect(()=>{
    const allArrayData:any=[]
    bookingListWithTatTime?.results.length > 0 && bookingListWithTatTime?.results.map((booking:any)=>{
        if(booking){
            allArrayData.push({'pk': booking?.pk,'child':"",'booking_date': booking?.booking_date,
            'collection_date': booking?.collection_date,'customer_name': booking?.customer_name,
            'customer_age':booking?.customer_age,'customer_gender': booking?.customer_gender,
            'customer_phonenumber':booking?.customer_phonenumber,'customer_whatsapppnumber':booking?.customer_whatsapppnumber,
            'customer_altphonenumber':booking?.customer_altphonenumber,'customer_email': booking?.customer_email,
            'city':booking?.city,'pickup_status':booking?.pickup_status,'status': booking?.intatouttat?.status,
            'allotted_time': booking?.intatouttat?.allotted_time,'intat': booking?.intatouttat?.intat,
            'pickup_datetime': booking?.intatouttat?.pickup_datetime,'report_consolidated_time': booking?.intatouttat?.report_consolidated_time,
            'timedifference':booking?.intatouttat?.timedifference,'total_time_from_pickup': booking?.intatouttat?.total_time_from_pickup})
            if(booking.add_member_booking.length>0){
                booking.add_member_booking.map((data:any)=>{
                    allArrayData.push({'pk': data?.pk,'child':data?.id,'booking_date': "",
                    'collection_date': "",'customer_name': data?.customer_name,
                    'customer_age':data?.customer_age,'customer_gender': data?.customer_gender,
                    'customer_phonenumber':"",'customer_whatsapppnumber':"",
                    'customer_altphonenumber':"",'customer_email': "",
                    'city':data?.city,'pickup_status':data?.pickup_status,'status': data?.intatouttat?.status,
                    'allotted_time': data?.intatouttat?.allotted_time,'intat': data?.intatouttat?.intat,
                    'pickup_datetime': data?.intatouttat?.pickup_datetime,'report_consolidated_time': data?.intatouttat?.report_consolidated_time,
                    'timedifference':data?.intatouttat?.timedifference,'total_time_from_pickup': data?.intatouttat?.total_time_from_pickup})
                })
            }
        }
    })
    setAllData(allArrayData)
}
,[bookingListWithTatTime])
    const columns: GridColDef[] = [
        { field: 'id', headerName: 'S/N', width: 200 },
        { field: 'pk', headerName: 'Booking Id', width: 200 },
        { field: 'child', headerName: 'Child ID', width: 200 },
        { field: 'booking_date', headerName: 'Booking Date', width: 200 },
        { field: 'collection_date', headerName: 'Collection Date', width: 200 },
        { field: 'customer_name', headerName: 'Name', width: 200 },
        {
            field: 'customer_age',
            headerName: 'Age',
            type: 'number',
            width: 150,
        },
        { field: 'customer_gender', headerName: 'Gender', width: 200 },
        { field: 'customer_phonenumber', type: "string", headerName: 'Phone No', width: 200 },
        { field: 'customer_whatsapppnumber', type: "string", headerName: 'Whatsapp No', width: 200 },
        { field: 'customer_altphonenumber', type: "string", headerName: 'Alternate No', width: 200 },
        { field: 'customer_email', headerName: 'Email', width: 200 },
        { field: 'city', headerName: 'City', width: 200 },
        { field: 'pickup_status', headerName: 'Pickup Status', width: 200 },
        { field: 'status', headerName: 'Report Status', width: 200 },
        { field: 'allotted_time', headerName: 'Allotted Time', width: 200 },
        { field: 'intat', headerName: 'InTaT', width: 200 },
        { field: 'pickup_datetime', headerName: 'Pickup Datetime', width: 200 },
        { field: 'report_consolidated_time', headerName: 'Report Consolidated Time', width: 200 },
        { field: 'timedifference', headerName: 'Time Difference', width: 200 },
        { field: 'total_time_from_pickup', headerName: 'Total Time From Pickup', width: 200 },
    ];

    let rows = []
    if (allData.length>0) {
        rows = allData.map((booking: any,index:any) => {
            return {
                ...booking,
                id: index+1,
                // child: booking?.id,
                // status:booking?.intatouttat?.status,
                // allotted_time:booking?.intatouttat?.allotted_time,
                // intat:booking?.intatouttat?.intat,
                // pickup_datetime:booking?.intatouttat?.pickup_datetime,
                // report_consolidated_time:booking?.intatouttat?.report_consolidated_time,
                // timedifference:booking?.intatouttat?.timedifference,
                // total_time_from_pickup:booking?.intatouttat?.total_time_from_pickup,

            }
        })
    }
    return (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            <Paper elevation={15} className={classes.paper}>
                <div style={{ height: 700 }}>
                    <DataGrid rowsPerPageOptions={[]}
                        rows={rows}
                        columns={columns}
                        components={{
                            Toolbar: CustomToolbar,
                        }}
                        pageSize={50}
                        rowCount={bookingListWithTatTime.count || 0}
                        loading={loading}
                    />
                </div>
            </Paper>
        </main>
    )
}

const mapStateToProps = (state: any) => ({

})

export default connect(mapStateToProps, {

})(BookingExport);

