import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import Box from "@mui/material/Box";
import Typography from "@material-ui/core/Typography";
import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  Tooltip,
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import MessageIcon from "@material-ui/icons/Message";
import EditIcon from "@material-ui/icons/Edit";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { useLocation } from "react-router-dom";
import InvertColorsIcon from "@material-ui/icons/InvertColors";
import { ACTIONS } from "../interfaces/actionTypes/booking";
import Button from "@mui/material/Button";
import Relevent from "../components/Fill-Info";
import OtherUpdates from "../components/Other-Info";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getLeadDetails, getCities } from "../actions/bookingActions";
import Loader from "../components/Loader2";
import CommentHistory from "../components/comment-history";
import moment from "moment";
import NewBooking from "../components/Bookings/NewBooking/newBookingWithLEAD";
import WhatsAppModal from "../components/Comments/whatsappModal";
import CommentsModal from "../components/Comments/comment";
import CommentsModal2 from "../components/Comments/Comment2";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "10px 10px 20px 10px",
      marginBottom: "20px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },

    toolContainer: {
      width: "100%",
      float: "right",
      display: "flex",
      justifyContent: "center",
      padding: "10px",
      boxShadow: "0 0 40px 40px rgba(0,0,0,0)",
      position: "fixed",
      top: "75px",
      right: "82px",
      // overflow:"hiden",
      background: "transprent",
    },

    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
    paper: {
      marginTop: "2rem",
      padding: "0.5rem 2rem",
      width: "100%",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    tableHead: {
      background: "rgb(146, 74, 145)!important",
    },
    tableHeadCell: {
      color: "#fff",
      fontWeight: "bold",
      textAlign: "center",
    },
    tableBodyCell: {
      padding: "10px 5px 10px 5px!important",
    },
    tableContainer: {
      marginTop: "1rem",
      position: "sticky",
      top: "200px",
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
      minWidth: "200px !important",
    },
    body: {
      fontSize: 14,
      minWidth: "200px !important",
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  children: any;
  getLeadDetails: Function;
  getCities: any;
  cities: any;
  lead_details: any;
  loading: boolean;
}

interface IProps {
  booking: any;
  prop: any;
}

const AdditionalMembersData: React.FC<IProps> = ({ booking, prop }) => {
  return (
    <StyledTableCell align="center">
      {booking.additional_members.length > 0
        ? booking.additional_members.map((member: any, i: number) => (
            <p
              style={{
                display: "inline-block",
              }}
            >
              {member[prop]}{" "}
              {booking.additional_members.length - 1 !== i && ","}
            </p>
          ))
        : ""}
    </StyledTableCell>
  );
};

const CustomerExecutiveFillInfoPage: React.FC<Props> = ({
  getLeadDetails,
  getCities,
  cities,
  lead_details,
  loading,
}) => {
  const classes = useStyles();
  let query = useQuery();

  const [id, setId] = React.useState<number>(0);
  let dispatch = useDispatch();
  const history = useHistory();
  const [whatsappLeadSearch, setWhatsappLeadSearch] = useState<any>("");
  const [openWhatsapp, setOpenWhatsapp] = useState<boolean>(false);
  const [commentModalOpen, setCommentModalOpen] = useState<boolean>(false);
  const [openM, setOpenM] = useState<boolean>(false);
  const [additionalMemebr, setAdditionalMemebr] = useState<any>();
  const [additionalModal, setAdditonalModal] = useState<boolean>(false);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const handleClickWhatsapp = () => {
    setOpenWhatsapp(true);
  };
  const handleClickThroughLead = () => {
    setOpenM(true);
  };
  useEffect(() => {
    dispatch({ type: ACTIONS.GET_LEAD_DETAILS, payload: {} });
    if (
      Number(query.get("id")!) !== 0 &&
      query.get("id")! !== null &&
      query.get("id")! !== undefined &&
      query.get("id")! !== "undefined"
    ) {
      getLeadDetails(query.get("id")!);
      setId(parseInt(query.get("id")!));
    }
  }, []);

 
  const handleNewBooking = () => {
    setTimeout(() => {
      window.scroll({
        top: 1200,
        behavior: "smooth",
      });
    }, 200);
  };
  const handleUpArrow = () => {
    setTimeout(() => {
      window.scroll({
        top: 5,
        left: 5,
        behavior: "smooth",
      });
    }, 200);
  };
  return (
    <main className={classes.content}>
      <div className={classes.toolbar} />
      <div className={classes.contentHeader}>
        <div className={classes.leftContentHeader}>
          <h4>Lead - {lead_details?.id}</h4>
        </div>
        <div className={classes.toolContainer}>
          <Tooltip title="Whatsapp" arrow>
            <Button
              onClick={() => {
                setWhatsappLeadSearch(lead_details?.id);
                handleClickWhatsapp();
              }}
              size="small"
              style={{ marginLeft: 10 }}
              variant="contained"
              color="primary"
              disabled={typeof lead_details.id === "undefined"}
            >
              <WhatsAppIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Comments" arrow>
            <Button
              onClick={() => handleClickThroughLead()}
              size="small"
              variant="contained"
              color="secondary"
              style={{ marginLeft: 10 }}
              disabled={typeof lead_details.id === "undefined"}
            >
              <MessageIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Dispose" arrow>
            <Button
              variant="contained"
              size="small"
              style={{ marginLeft: 10 }}
              color="secondary"
              disabled={typeof lead_details.id === "undefined"}
              onClick={() => setCommentModalOpen(true)}
            >
              Dispose
            </Button>
          </Tooltip>
          <Tooltip title="Whatsapp" arrow>
            <Button
              color="primary"
              variant="contained"
              size="small"
              style={{ marginLeft: 10 }}
              startIcon={<ExpandCircleDownIcon />}
              onClick={handleNewBooking}
            >
              New Booking
            </Button>
          </Tooltip>
        </div>
      </div>
      <Box>
        <Container maxWidth={false} style={{ marginTop: "10px" }}>
          <Grid container spacing={3}>
            {!lead_details ? (
              <Loader />
            ) : Object.keys(lead_details).length > 0 ? (
              <>
                <Relevent id={id} data={lead_details} />
                {/* <OtherUpdates id={id} data={lead_details} /> */}

                <div style={{ width: "100%" }}>
                  <Paper elevation={15} className={classes.paper}>
                    <TableContainer
                      className={classes.tableContainer}
                      component={Paper}
                      style={{ maxHeight: "580px" }}
                    >
                      {loading ? (
                        <Loader />
                      ) : (
                        <Table stickyHeader aria-label="simple table">
                          <TableHead>
                            <StyledTableRow>
                              <StyledTableCell align="center">
                                Booking Id
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                Name
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                Age
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                Gender
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                Package
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Collection Date
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                Zone Name
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                Booking Status
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                Action
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            {lead_details.bookings &&
                              lead_details.bookings.length > 0 &&
                              lead_details.bookings.map(
                                (booking: any, index: any) => {
                                  return (
                                    <>
                                      <StyledTableRow key={index}>
                                        <StyledTableCell align="center">
                                          {booking.additional_members.length >
                                          0 ? (
                                            <div>
                                              {booking.pk}
                                            </div>
                                          ) : (
                                            booking.pk
                                          )}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {booking.customer_name}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {booking.customer_age}
                                        </StyledTableCell>

                                        <StyledTableCell align="center">
                                          {booking.customer_gender}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {booking?.test_package
                                            ? booking?.test_package
                                            : "NA"}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          {booking.collection_date}
                                        </StyledTableCell>

                                        <StyledTableCell align="center">
                                          {booking.customer_areapincode.area}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                          <Chip
                                            label={`${booking.booking_status}`}
                                            style={{
                                              background:
                                                booking.booking_status ===
                                                "pending"
                                                  ? "#ffee58"
                                                  : booking.booking_status ===
                                                    "confirmed"
                                                  ? "#00e676"
                                                  : booking.booking_status ===
                                                    "cancelled"
                                                  ? "#ff3d00"
                                                  : "#d500f9",
                                            }}
                                          />
                                        </StyledTableCell>

                                        <StyledTableCell align="center">
                                          <Link
                                            to={`/dashboard/ce/booking-view/${booking.pk}`}
                                            style={{ textDecoration: "none" }}
                                          >
                                            <Button
                                              variant="contained"
                                              color="secondary"
                                            >
                                              View
                                            </Button>
                                          </Link>
                                        </StyledTableCell>
                                      </StyledTableRow>
                                      {booking?.additional_members &&
                                        booking?.additional_members.length >
                                          0 &&
                                        booking?.additional_members.map(
                                          (data: any, index2: any) => {
                                            return (
                                              <StyledTableRow
                                                key={index2}
                                                style={{
                                                  backgroundColor: "#fff4d6",
                                                }}
                                              >
                                                <StyledTableCell align="center">
                                                  {data?.id}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                  {data?.customer_name}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                  {data?.customer_age}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                  {data?.customer_gender}
                                                </StyledTableCell>
                                                <StyledTableCell align="center"></StyledTableCell>
                                                <StyledTableCell align="center"></StyledTableCell>
                                                <StyledTableCell align="center"></StyledTableCell>
                                                <StyledTableCell align="center">
                                                  {data?.booking_status}
                                                </StyledTableCell>
                                                <StyledTableCell align="center"></StyledTableCell>
                                              </StyledTableRow>
                                            );
                                          }
                                        )}
                                    </>
                                  );
                                }
                              )}
                          </TableBody>
                        </Table>
                      )}
                    </TableContainer>
                    {lead_details.bookings &&
                      lead_details.bookings.length === 0 && (
                        <h6 style={{ textAlign: "center" }}>No Data Found</h6>
                      )}
                  </Paper>
                </div>
                <div style={{ marginTop: "20px", background: "#fff" }}>
                  <TableContainer className={classes.table}>
                    <Table aria-label="simple table">
                      {lead_details?.family_package.length > 0 && (
                        <TableHead className={classes.tableHead}>
                          <StyledTableRow>
                            <StyledTableCell className={classes.tableHeadCell}>
                              S No.
                            </StyledTableCell>
                            <StyledTableCell className={classes.tableHeadCell}>
                              ID
                            </StyledTableCell>
                            <StyledTableCell className={classes.tableHeadCell}>
                              Customer Name 123
                            </StyledTableCell>
                            <StyledTableCell className={classes.tableHeadCell}>
                              Customer Email
                            </StyledTableCell>
                            <StyledTableCell className={classes.tableHeadCell}>
                              Customer Mobile
                            </StyledTableCell>
                            <StyledTableCell className={classes.tableHeadCell}>
                              Package
                            </StyledTableCell>
                            <StyledTableCell className={classes.tableHeadCell}>
                              No of Coupon
                            </StyledTableCell>
                            <StyledTableCell className={classes.tableHeadCell}>
                              Agent
                            </StyledTableCell>
                            <StyledTableCell className={classes.tableHeadCell}>
                              Created Date
                            </StyledTableCell>
                            <StyledTableCell className={classes.tableHeadCell}>
                              Status
                            </StyledTableCell>
                          </StyledTableRow>
                        </TableHead>
                      )}
                      <TableBody>
                        {lead_details?.family_package.length > 0 &&
                          lead_details?.family_package.map(
                            (booking: any, index: any) => {
                              return (
                                <StyledTableRow key={index}>
                                  <StyledTableCell align="center">
                                    {index + 1}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {booking.id}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={classes.tableBodyCell}
                                  >
                                    {booking.customer_name}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={classes.tableBodyCell}
                                  >
                                    {booking.customer_email}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    className={classes.tableBodyCell}
                                  >
                                    {booking.customer_mobile}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className={classes.tableBodyCell}
                                  >
                                    {booking?.package_name}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className={classes.tableBodyCell}
                                  >
                                    {booking?.no_of_member}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className={classes.tableBodyCell}
                                  >
                                    {booking?.agent?.name}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className={classes.tableBodyCell}
                                  >
                                    {booking.created_at}
                                  </StyledTableCell>
                                  <StyledTableCell
                                    align="center"
                                    className={classes.tableBodyCell}
                                  >
                                    {booking?.status}
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            }
                          )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </>
            ) : (
              "NA"
            )}
            
          </Grid>
        </Container>
        <div style={{ width: "100%" }} className={classes.paper}>
          <CommentHistory />
          <Button
            color="secondary"
            variant="contained"
            size="small"
            style={{ marginLeft: 10, float: "right" }}
            startIcon={<ArrowCircleUpIcon style={{ color: "red" }} />}
            onClick={handleUpArrow}
          >
            Back TO Top
          </Button>
          <NewBooking lead={lead_details?.id} />
        </div>
      </Box>
      <WhatsAppModal
        open={openWhatsapp}
        setOpen={setOpenWhatsapp}
        leadId={whatsappLeadSearch}
      />
      <CommentsModal open={openM} setOpen={setOpenM} leadId={lead_details.id} />
      <CommentsModal2
        open={commentModalOpen}
        getCities={getCities}
        cities={cities}
        setOpen={setCommentModalOpen}
        leadId={lead_details.id}
      />
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  lead_details: state.BookingReducer.lead_details,
  cities: state.BookingReducer.cities,
  loading: state.BookingReducer.loading,
});

export default connect(mapStateToProps, { getLeadDetails, getCities })(
  CustomerExecutiveFillInfoPage
);
