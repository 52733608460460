import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Grid,
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    TablePagination,
    TableFooter,
    withStyles,
    Switch,
} from '@material-ui/core';
import Button from '@mui/material/Button';
import './index.sass'
import { CheckCircleOutlineRounded, Edit } from "@material-ui/icons";
import { connect } from "react-redux";
import { getArea, updateArea, getLabCredential, updateLabCredential, getPinCode } from '../../actions/salesLeadAdminAction'
import { getCities } from '../../../CustomerExecutive-Panel/actions/bookingActions'
import Loader from "../loader";
import CreatePincodeModal from "./createPincodeModal";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useRef } from "react";
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
        },
        paper: {
            padding: "1rem",
            width: "100%"
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        },
        table: {
            maxWidth: "100%",
            margin: "auto"
        },
        heading: {
            fontWeight: "normal",
            fontSize: "1.2rem"
        },
        actionButton: {
            margin: "0 0.5rem",
            cursor: "pointer"
        }
    })
);

const DefaultSwitch:any = withStyles({
    switchBase: {
        color: "red",
        '&$checked': {
            color: "green",
        },
        '&$checked + $track': {
            backgroundColor: "green",
        },
    },
    checked: {},
    track: {
        backgroundColor: "red"
    },
})(Switch);

interface Props {
    getArea: any;
    areaList: any;
    updateArea: any;
    getLabCredential: any;
    getPinCode: any;
    pin_code: any;
    updateLabCredential: any;
    getCities: any;
    labCredential: any;
    cities: any;
    loading: Boolean;
}

const PinCodePage: React.FC<Props> = ({
    getArea,
    areaList,
    updateArea,
    updateLabCredential,
    getCities,
    pin_code,
    getPinCode,
    labCredential,
    getLabCredential,
    cities,
    loading
}) => {
    const history = useHistory()
    const classes = useStyles();

    const [search, setSearch] = useState<String>("")

    const [editPincode, setEditPincode] = useState("")
    const [editLabName,setEditLabName]= useState("")

    const [pincode, setPincode] = useState<string | null>(null)
    // const [city, setCity] = useState<number | null>(0)
    const [customer_city, setCustomer_city] = useState<String>("")
    const [customer_areaname, setCustomer_areaname] = useState<String>("")
    const [key, setKey] = useState("")
    const [org_id, setOrgId] = useState("")
    const [labName, setLabName] = useState("")
    const [customer_areapincode, setCustomer_areapincode] = useState<Number>(0)

    const timer = useRef<any>(0)

    const [page, setPage] = useState(0)

    const [open, setOpen] = useState(false)

    const handleSearch = (e: React.ChangeEvent<{ value: unknown }>) => {
        setSearch(e.target.value as string)
        getArea(e.target.value)
    }

    useEffect(() => {
        getArea()
        getCities()
        getPinCode()
        getLabCredential()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        open === false && getArea()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        if (newPage > page) {
            let url = areaList.links && areaList.links.next.split("?")[1];
            getArea(url);
        } else if (newPage < page) {
            let url = areaList.links && areaList.links.previous.split("?")[1];
            getArea(url);
        }
        setPage(newPage as number);
    };

    const handleEditArea = (code: any) => {
        setEditPincode(code.area.id)
        setEditLabName(code.name)
        setPincode(code.area.pincode)
        setKey(code.key)
        setOrgId(code.org_id)
        setLabName(code.name)
    }
    const handlePinCode = (val: String) => {

        const { id, area, city } = pin_code.results.find((code: any) => code.pincode === val)
        setCustomer_city(city as String)
        setCustomer_areaname(area as String)
         setCustomer_areapincode(id as Number)
    }

    const handleSubmit = async (code: any) => {
        setEditPincode("")
        setEditLabName("")
        const body: any = {
            city_name: customer_city,
            area: customer_areapincode,
            key,
            org_id,
            pincode,
            name:labName,
        }
        await updateLabCredential(body, code.id)
        history.push("/dashboard/sla/labcredential")
        getArea()
    }

    const disablePincode = async (pack: any) => {
        const body: any = {
            is_active: !pack.is_active
        }
        await updateLabCredential(pack.id, body)
        getArea()
    }
    
    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Paper elevation={15} className={classes.paper}>
                    <Grid container spacing={3} style={{ margin: "1rem auto" }}>
                        <Grid item xs={12} md={4}>
                            <h3 className={classes.heading}>Lab Credentials</h3>
                        </Grid>
                        {/* <Grid container item xs={12} md={8} direction="row" alignItems="center" justify="flex-end">
                            <TextField
                                id="search"
                                type="search"
                                value={search}
                                className="input"
                                variant="outlined"
                                placeholder="Search"
                                onChange={(e) => handleSearch(e)}
                                style={{ margin: "0" }}
                            />
                            <Button variant="contained" color="primary" style={{ marginLeft: "1rem" }} onClick={() => setOpen(true)}>Add Pincode</Button>
                        </Grid> */}
                    </Grid>
                    {loading ? <Loader /> : (
                        <TableContainer className={classes.table} component={Paper}>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">Pincode</TableCell>
                                        <TableCell align="center">Area</TableCell>
                                        <TableCell align="center">City</TableCell>
                                        <TableCell align="center">Key</TableCell>
                                        <TableCell align="center">Org_id</TableCell>
                                         <TableCell align="center">Lab Name</TableCell>
                                        {/* <TableCell align="center">Active</TableCell> */}
                                        <TableCell align="center">Edit</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {labCredential && labCredential.results && labCredential.results.length > 0 && labCredential.results.map((code: any, index: any) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell align="center">
                                                    {editPincode === code.area.id ? (
                                                        <Autocomplete
                                                            id="pincode"
                                                            onChange={(event, newValue) => {
                                                                if (newValue) {
                                                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                                                    handlePinCode(obj.pincode as String)
                                                                }
                                                            }}
                                                            defaultValue={pin_code.results && pin_code.results.find((code: any) => {
                                                                return code.pincode === code.area.pincode
                                                            })}
                                                            onInputChange={(event, newInputValue) => {
                                                                clearTimeout(timer.current)
                                                                timer.current = setTimeout(() => {
                                                                    getPinCode(newInputValue.split(",")[0])
                                                                }, 1000)
                                                            }}
                                                            options={pin_code.results}
                                                            freeSolo
                                                            blurOnSelect
                                                            getOptionLabel={(option: any) => `${option.pincode}`}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    {...params}
                                                                    placeholder="Pin Code"
                                                                    variant="outlined"
                                                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                                                    className="input"
                                                                    disabled
                                                                    required
                                                                />
                                                            )}
                                                        />
                                                    ) : (
                                                        code.area.pincode
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {editPincode === code.area.id ? (
                                                        <TextField
                                                            name="customer_areaname"
                                                            type="text"
                                                            placeholder="Enter area name"
                                                            disabled
                                                            value={customer_areaname}
                                                            className="input"
                                                            variant="outlined"
                                                            style={{ margin: "0", width: "100%" }}
                                                        />
                                                    ) : (
                                                        code.area.area
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {editPincode === code.area.id ? (
                                                        <TextField
                                                            name="customer_city"
                                                            type="text"
                                                            placeholder="Enter City"
                                                            disabled
                                                            value={customer_city}
                                                            className="input"
                                                            variant="outlined"
                                                            style={{ margin: "0", width: "100%" }}
                                                        />
                                                    ) : (
                                                        code.area.area
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {editPincode === code.area.id ? (
                                                        <TextField
                                                            name="area"
                                                            type="text"
                                                            value={code.key}
                                                            className="input"
                                                            variant="outlined"
                                                            placeholder="Area"
                                                            onChange={(e) => setKey(e.target.value as string)}
                                                            style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                        />
                                                    ) : (
                                                        code.key
                                                    )}
                                                </TableCell>
                                                
                                                <TableCell align="center">
                                                    {editPincode === code.area.id ? (
                                                        <TextField
                                                            name="area"
                                                            type="text"
                                                            value={code.org_id}
                                                            className="input"
                                                            variant="outlined"
                                                            placeholder="Area"
                                                            onChange={(e) => setOrgId(e.target.value as string)}
                                                            style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                        />
                                                    ) : (
                                                        code.org_id
                                                    )}
                                                </TableCell>
                                                  <TableCell align="center">
                                                    {editLabName === code.name ? (
                                                        <TextField
                                                            name="labname"
                                                            type="text"
                                                            value={code.name}
                                                            className="input"
                                                            variant="outlined"
                                                            placeholder="Lab Name"
                                                            onChange={(e) => setLabName(e.target.value as string)}
                                                            style={{ width: "100%", padding: "10px", minWidth: "200px" }}
                                                        />
                                                    ) : (
                                                        code.name
                                                    )}
                                                </TableCell>

                                                {/* <TableCell align="center">
                                                    <DefaultSwitch
                                                        checked={code.is_active}
                                                        name="checkedA"
                                                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                        disableRipple
                                                        onClick={() => disablePincode(code)}
                                                    />
                                                </TableCell> */}
                                                <TableCell align="center">
                                                    {editPincode === code.area.id ? (
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={() => handleSubmit(code)}
                                                        >
                                                            <CheckCircleOutlineRounded />
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            onClick={() => handleEditArea(code)}
                                                        >
                                                            <Edit />
                                                        </Button>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        {/* <TablePagination
                                            rowsPerPageOptions={[]}
                                            colSpan={3}
                                            count={(areaList && areaList.count) || 0}
                                            rowsPerPage={areaList && areaList.page_size}
                                            page={page}
                                            onPageChange={handleChangePage}
                                        /> */}
                                    </TableRow>
                                </TableFooter>
                            </Table>
                        </TableContainer>
                    )}
                    {/* {areaList && areaList.length === 0 && (
                        <h3 className={classes.heading} style={{ textAlign: "center" }}>No Data Found</h3>
                    )} */}
                </Paper>
                <CreatePincodeModal open={open} setOpen={setOpen} />
            </main>
        </>
    );
};

const mapStateToProps = (state: any) => ({
    areaList: state.SalesLeadAdminReducer.areaList,
    cities: state.SalesLeadAdminReducer.cities,
    pin_code: state.SalesLeadAdminReducer.pin_code,
    labCredential: state.SalesLeadAdminReducer.labCredential,
    loading: state.SalesLeadAdminReducer.loading,
})

export default connect(mapStateToProps, {
    getArea,
    getCities,
    updateArea,
    getPinCode,
    updateLabCredential,
    getLabCredential,
})(PinCodePage);
