import React, { useEffect } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
  fade,
} from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import Drawer from "@material-ui/core/Drawer";
import InputBase from "@material-ui/core/InputBase";
import AppBar from "@material-ui/core/AppBar";
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import Toolbar from "@material-ui/core/Toolbar";
import Fade from "@material-ui/core/Fade";
import List from "@material-ui/core/List";
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import CameraRearIcon from "@material-ui/icons/CameraRear";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import AssignmentLateIcon from "@material-ui/icons/AssignmentLate";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import MenuItem from "@material-ui/core/MenuItem";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AccountTreeTwoToneIcon from "@material-ui/icons/AccountTreeTwoTone";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { logout, panelUserLogout } from "../../actions/loginActions";
import PaymentsIcon from "@mui/icons-material/Payments";
import CallModal from "../Components/call-modal/index";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import MergeIcon from '@mui/icons-material/Merge';
import { useDispatch } from "react-redux";
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import GraphicEqRoundedIcon from '@mui/icons-material/GraphicEqRounded';
import {
  changePassword,
  searchCall,
  getUserDetails,
  getFirebaseNotification,
  getNotificationMessage,
} from "../actions/salesLeadAdminAction";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from "@material-ui/core";
import Popover from "@material-ui/core/Popover";
import BusinessIcon from "@material-ui/icons/Business";
import FreeBreakfastIcon from "@material-ui/icons/FreeBreakfast";
import ThumbsUpDownIcon from "@material-ui/icons/ThumbsUpDown";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SearchIcon from "@material-ui/icons/Search";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import PrintIcon from "@material-ui/icons/Print";
import CallToActionIcon from "@material-ui/icons/CallToAction";
import AdbIcon from "@material-ui/icons/Adb";
import HealingIcon from "@material-ui/icons/Healing";
import Collections from "@material-ui/icons/Collections";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import {
  CloudUpload,
  ListAltRounded,
  AddBoxTwoTone,
  PhoneAndroidRounded,
  WhatsApp,
  ConfirmationNumberRounded,
  SettingsApplicationsRounded,
  Book,
} from "@material-ui/icons";
import WarningIcon from "@material-ui/icons/Warning";
import HomeIcon from "@material-ui/icons/Home";
import HistoryIcon from "@material-ui/icons/History";
import TableRowsIcon from "@mui/icons-material/TableRows";
import QuizIcon from "@mui/icons-material/Quiz";
import DataThresholdingIcon from "@mui/icons-material/DataThresholding";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import EqualizerRoundedIcon from "@material-ui/icons/EqualizerRounded";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import { useHistory } from "react-router-dom";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";
import TextsmsIcon from "@material-ui/icons/Textsms";
import CollectionsBookmarkIcon from "@material-ui/icons/CollectionsBookmark";
import InvertColorsIcon from "@material-ui/icons/InvertColors";
import FilterCenterFocusIcon from "@material-ui/icons/FilterCenterFocus";
import DialpadIcon from "@material-ui/icons/Dialpad";
import BugReportIcon from "@mui/icons-material/BugReport";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
// import WhatshotIcon from "@material-ui/icons/Whatshot";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import NewUpdateModal from "../Components/comments/newUpdate";
import PaymentIcon from "@material-ui/icons/Payment";
import EmailIcon from '@mui/icons-material/Email';
const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    title: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      color: "#fff",
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    dashbord: {
      color: "#fff",
      fontSize: "18px",
      fontWeight: "normal",
      padding: "20px 0px 10px 10px",
      marginLeft: "0px",
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      backgroundColor: "#924A91",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    titleButtor: {
      flexGrow: 1,
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
      color: "#fff",
    },
    popover: {
      pointerEvents: "none",
    },
    paper: {
      padding: theme.spacing(1),
    },
    content: {
      width: "80%",
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);
interface Props {
  getUserDetails: any;
  // newuserDetails: any;
  userDetails: any;
  getFirebaseNotification: any;
  getNotificationMessage: any;
  notificationMessage: any;
  passwordStatus: boolean;
}
const SalesDashBoard: React.FC<Props> = ({
  getUserDetails,
  getNotificationMessage,
  // newuserDetails,
  userDetails,
  notificationMessage,
  passwordStatus,
  getFirebaseNotification,
}) => {
  let dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const [changePasswordModal, setchangePasswordModal] = React.useState(false);
  const history = useHistory();
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [openRightMenu, setOpenRightMenu] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const openPopover = Boolean(anchorEl);
  const [value, setValue] = React.useState<string>("");
  const [heading, setHeading] = React.useState<string>("");
  const [modelFormIndex, setModelFormIndex] = React.useState<number>(0);
  const [option, setOption] = React.useState<string>("call_id");
  const [anchorElUpdate, setAnchorElUpdate] = React.useState(null);
  const [open1, setOpen1] = React.useState(false);
  const id = open ? "transitions-popper" : undefined;
  const handleClick = (event: any) => {
    setOpenRightMenu(true);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpenRightMenu(false);
  };
  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
    // getNotificationMessage(`?is_read=false`);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const handleOpen = (name: string, index: number) => {
    setOpen1(true);
    setHeading(name);
    setModelFormIndex(index);
  };
  useEffect(() => {
    getUserDetails();
  }, []);
  useEffect(() => {
    if (!passwordStatus) {
      setchangePasswordModal(true);
    }
  }, [passwordStatus]);
  const updatePassword = async () => {
    const body: any = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_password: confirmPassword,
    };
    await dispatch(changePassword(body));
    setchangePasswordModal(false);
    dispatch(logout());
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleValueChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value as string);
  };
  const submitForm = (e: any) => {
    e.preventDefault();
    let body = {
      search_by: option,
      value,
    };
    dispatch(searchCall(body));
    history.push("/dashboard/sla/lead/search");
  };
  const handleNotification = () => {
    history.push("/dashboard/sla/inbox");
  };
  const handleLogout = () => {
    dispatch(panelUserLogout());
    dispatch(logout());
  };
  const handleUpdateDetails = (event: any) => {
    setAnchorElUpdate(anchorElUpdate ? null : event.currentTarget);
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={{ backgroundColor: "#924A91" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Redcliffelabs
          </Typography>
          <Typography className={classes.titleButtor} variant="h6">
            {/* <Link to="/dashboard/pcc/fill-info"> */}
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleOpen("Create Call", 7)}
            >
              Create Call
            </Button>
            {/* </Link>     */}
          </Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <form onSubmit={submitForm}>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                value={value}
                onChange={handleValueChange}
                inputProps={{ "aria-label": "search" }}
              />
            </form>
          </div>
          <div>
            <Button
              variant="text"
              onClick={(e) => handleClick(e)}
              size="medium"
              endIcon={<KeyboardArrowDownIcon />}
              style={{ color: "#fff" }}
            >
              {userDetails &&
                userDetails.profile &&
                userDetails.profile.username}
            </Button>
            <Menu
              id="fade-menu"
              open={openRightMenu}
              onClose={handleClose}
              TransitionComponent={Fade}
              anchorEl={anchorEl}
            >
              <MenuItem onClick={() => setchangePasswordModal(true)}>
                Change Password
              </MenuItem>
              <MenuItem onClick={() => {
                if (userDetails?.profile?.get_chat === true) {
                  alert("Sign Out From Whatsapp Chat First.")
                } else {
                  handleLogout()
                }
                }}>Logout</MenuItem>
            </Menu>
          </div>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-owns={openPopover ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            onClick={handleNotification}
            color="inherit"
          >
            <NotificationsActiveIcon />
          </IconButton>
          <IconButton>
            <DeveloperModeIcon
              aria-describedby={id}
              onClick={handleUpdateDetails}
            />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Dialog
          open={changePasswordModal}
          // onClose={() => setchangePasswordModal(false)}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
          <DialogContent>
            <TextField
              id="name"
              placeholder="Old Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="New Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <TextField
              id="name"
              placeholder="Confirm Password"
              type="password"
              fullWidth
              style={{
                width: "100%",
                margin: "0.3rem auto",
                padding: "14px !important",
              }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => updatePassword()} color="primary">
              Update
            </Button>
            {/* <Button
              onClick={() => setchangePasswordModal(false)}
              color="primary"
            >
              Cancel
            </Button> */}
          </DialogActions>
        </Dialog>
        <div className={classes.toolbar}>
          <span className={classes.dashbord}>Dashboard</span>
          <IconButton onClick={handleDrawerClose} style={{ color: "#fff" }}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to="/dashboard/sla">
            <ListItem button>
              <Tooltip title="Home" arrow>
                <ListItemIcon>
                  <HomeIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Home" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/analytics-recording">
            <ListItem button>
              <Tooltip title="Analytics Recording" arrow>
                <ListItemIcon>
                  <GraphicEqRoundedIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Analytics Recording" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/assignPanelUser">
            <ListItem button>
              <Tooltip title="Assign Panel User" arrow>
                <ListItemIcon>
                  <BusinessIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Assign Panel User" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/assignbookings">
            <ListItem button>
              <Tooltip title="Assign Bookings" arrow>
                <ListItemIcon>
                  <Book style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Assign Bookings" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/auditScore">
            <ListItem button>
              <Tooltip title="Audit Score" arrow>
                <ListItemIcon>
                  <LoyaltyIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Audit Score" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/availableSlot">
            <ListItem button>
              <Tooltip title="Available Slots" arrow>
                <ListItemIcon>
                  <CameraRearIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Available Slots" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/batched_payment">
            <ListItem button>
              <Tooltip title="Batched Payment" arrow>
                <ListItemIcon>
                  <PaymentIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Batched Payment" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/bookings/export">
            <ListItem button>
              <Tooltip title="Booking Export" arrow>
                <ListItemIcon>
                  <CloudUpload style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Booking Export" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/lead/emailDetails">
            <ListItem button>
              <Tooltip title="Booking Email Details" arrow>
                <ListItemIcon>
                  <EmailIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Booking Email Details" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/booking_table">
            <ListItem button>
              <Tooltip title="Booking List" arrow>
                <ListItemIcon>
                  <TableRowsIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Booking List" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/booking-tat-report">
            <ListItem button>
              <Tooltip title="Booking TAT Report" arrow>
                <ListItemIcon>
                  <DataThresholdingIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Booking TAT Report" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/booking/tickets">
            <ListItem button>
              <Tooltip title="Booking Tickets" arrow>
                <ListItemIcon>
                  <ConfirmationNumberRounded style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Booking Tickets" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/breakData">
            <ListItem button>
              <Tooltip title="Break Details" arrow>
                <ListItemIcon>
                  <FastfoodIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Break Details" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/call_details">
            <ListItem button>
              <Tooltip title="Call Recording" arrow>
                <ListItemIcon>
                  <HistoryIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Call Recording" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/calls/stats">
            <ListItem button>
              <Tooltip title="Call Stats" arrow>
                <ListItemIcon>
                  <EqualizerRoundedIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Call Stats" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/campaigns">
            <ListItem button>
              <Tooltip title="Campaigns " arrow>
                <ListItemIcon>
                  <HolidayVillageIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Campaigns" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/centerInfo">
            <ListItem button>
              <Tooltip title="Center Information" arrow>
                <ListItemIcon>
                  <FilterCenterFocusIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Center Information" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/cityWisePhlebos">
            <ListItem button>
              <Tooltip title="City Wise Phlebos" arrow>
                <ListItemIcon>
                  <LocationCityIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="City Wise Phlebos" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/coldcallleaduploader">
            <ListItem button>
              <Tooltip title="Cold Calling lead Uploader" arrow>
                <ListItemIcon>
                  <UploadFileIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Cold Calling lead Uploader" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/currentDayBooking">
            <ListItem button>
              <Tooltip title="Current Day Bookings" arrow>
                <ListItemIcon>
                  <AssignmentLateIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Current Day Booking" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/dispositions">
            <ListItem button>
              <Tooltip title="Dispositions" arrow>
                <ListItemIcon>
                  <ThumbsUpDownIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Dispositions" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/doctor_consultation">
            <ListItem button>
              <Tooltip title="Doctor Consultation" arrow>
                <ListItemIcon>
                  <LocalHospitalIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Doctor Consultation" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/performance">
            <ListItem button>
              <Tooltip title="Employee Performance" arrow>
                <ListItemIcon>
                  <HowToRegIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Employee Performance" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/health_data">
            <ListItem button>
              <Tooltip title="Health Data" arrow>
                <ListItemIcon>
                  <HealingIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Health Data" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/helpdesk">
            <ListItem button>
              <Tooltip title=" Help Desk" arrow>
                <ListItemIcon>
                  <QuizIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary=" Help Desk" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/holiday">
            <ListItem button>
              <Tooltip title="Holiday Calender" arrow>
                <ListItemIcon>
                  <TimeToLeaveIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Holiday Calender" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/booking/imaging-ticket">
            <ListItem button>
              <Tooltip title="Imaging Tickets" arrow>
                <ListItemIcon>
                  <CheckCircleOutlineIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Imaging Tickets" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/inbox">
            <ListItem button>
              <Tooltip title="Inbox" arrow>
                <ListItemIcon>
                  <AllInboxIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Inbox" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/lead/incorrectNumberLead">
            <ListItem button>
              <Tooltip title="Incorrect Number Lead" arrow>
                <ListItemIcon>
                  <WarningIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Incorrect Number Lead" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/labDetails">
            <ListItem button>
              <Tooltip title="Lab Details" arrow>
                <ListItemIcon>
                  <DialpadIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Lab Details" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/leads">
            <ListItem button>
              <Tooltip title="Leads" arrow>
                <ListItemIcon>
                  <ListAltRounded style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Leads" />
            </ListItem>
          </Link>
        </List>
        {/* <List>
          <Link to="/dashboard/sla/lead/tickets">
            <ListItem button>
              <Tooltip title="Lead Tickets" arrow>
                <ListItemIcon>
                  <ConfirmationNumberRounded style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Lead Tickets" />
            </ListItem>
          </Link>
        </List> */}
         <List>
          <Link to="/dashboard/sla/alias">
            <ListItem button>
              <Tooltip title="Manage package Alias" arrow>
                <ListItemIcon>
                  <CreateNewFolderIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Manage Package Alias" />
            </ListItem>
          </Link>
        </List>
         <List>
          <Link to="/dashboard/sla/CityAlias-Master">
            <ListItem button>
              <Tooltip title="Manage City Alias" arrow>
                <ListItemIcon>
                  <ElectricCarIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Manage City Alias" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/coupons">
            <ListItem button>
              <Tooltip title="Manage Coupons" arrow>
                <ListItemIcon>
                  <FreeBreakfastIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Manage Coupons" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/phlebo">
            <ListItem button>
              <Tooltip title="Manage Phlebos" arrow>
                <ListItemIcon>
                  <AdbIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Manage Phlebos" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/attendance">
            <ListItem button>
              <Tooltip title="Manage Phlebo Attendance" arrow>
                <ListItemIcon>
                  <MenuBookIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Manage Phlebo Attendance" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/pincodes">
            <ListItem button>
              <Tooltip title="Manage Pincodes" arrow>
                <ListItemIcon>
                  <PersonPinCircleIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Manage Pincodes" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/users">
            <ListItem button>
              <Tooltip title="Manage Users" arrow>
                <ListItemIcon>
                  <SettingsApplicationsRounded style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Manage Users" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/zones">
            <ListItem button>
              <Tooltip title="Manage Zones" arrow>
                <ListItemIcon>
                  <BusinessIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Manage Zones" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/booking">
            <ListItem button>
              <Tooltip title="New Booking" arrow>
                <ListItemIcon>
                  <AddBoxTwoTone style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="New Booking" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/payment-refund">
            <ListItem button>
              <Tooltip title="Payment Refund" arrow>
                <ListItemIcon>
                  <PaymentsIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Payment Refund" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/cash-due-on-pha-rider">
            <ListItem button>
              <Tooltip title="Phlebo  Rider Due" arrow>
                <ListItemIcon>
                  <MoneyOffIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Phlebo  Rider Due" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/phleboridermap">
            <ListItem button>
              <Tooltip title="Phlebo Rider Map" arrow>
                <ListItemIcon>
                  <MergeIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Phlebo Rider Map" />
            </ListItem>
          </Link>
        </List>
        {/* <List>
          <Link to="/dashboard/sla/RCFreshLeadsTable">
            <ListItem button>
              <Tooltip title="RC Fresh Leads" arrow>
                <ListItemIcon>
                  <AccountTreeIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="RC Fresh Leads" />
            </ListItem>
          </Link>
        </List> */}
        <List>
          <Link to="/dashboard/sla/report_not_deliverd">
            <ListItem button>
              <Tooltip title="Report Not Deliverd  " arrow>
                <ListItemIcon>
                  <BugReportIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Report Not Deliverd " />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/route_plan">
            <ListItem button>
              <Tooltip title="Route Plan" arrow>
                <ListItemIcon>
                  <AccountTreeTwoToneIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Route Plan" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/SampleHandOver">
            <ListItem button>
              <Tooltip title="Sample HandOver" arrow>
                <ListItemIcon>
                  <Collections style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Sample HandOver" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/tat_timeBooking">
            <ListItem button>
              <Tooltip title="Tat Time Booking" arrow>
                <ListItemIcon>
                  <AccessAlarmIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Tat Time Booking" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/testPackages">
            <ListItem button>
              <Tooltip title="Test Packages" arrow>
                <ListItemIcon>
                  <InvertColorsIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Test Packages" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/uploader">
            <ListItem button>
              <Tooltip title="Uploader" arrow>
                <ListItemIcon>
                  <HourglassFullIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Uploader" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/verifiedbooking">
            <ListItem button>
              <Tooltip title="Verified Booking" arrow>
                <ListItemIcon>
                  <CollectionsBookmarkIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Verified Booking" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/whatsapp_chat">
            <ListItem button>
              <Tooltip title="Whatsapp Chat" arrow>
                <ListItemIcon>
                  <WhatsAppIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Whatsapp Chat" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/whatsapp">
            <ListItem button>
              <Tooltip title="Whatsapp Messages" arrow>
                <ListItemIcon>
                  <WhatsApp style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Whatsapp Messages" />
            </ListItem>
          </Link>
        </List>
        {/* <List>
					<Link to="/dashboard/sla/dash">
						<ListItem button>
							<Tooltip title="Dashboard" arrow>
								<ListItemIcon>
									<DashboardRounded style={{ color: "#924A91" }} />
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary="Dashboard" />
						</ListItem>
					</Link>
				</List> */}
        {/* <List>
          <Link to="/dashboard/sla/booking">
            <ListItem button>
              <Tooltip title="New booking" arrow>
                <ListItemIcon>
                  <AddBoxTwoTone style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="New booking" />
            </ListItem>
          </Link>
        </List> */}
        {/* <List>
          <Link to="/dashboard/sla/phleboBookingView">
            <ListItem button>
              <Tooltip title="Phelbo Booking View" arrow>
                <ListItemIcon>
                  <TransferWithinAStationIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Phelbo Booking View" />
            </ListItem>
          </Link>
        </List> */}
        {/* <List>
          <Link to="/dashboard/sla/export_tat_booking">
            <ListItem button>
              <Tooltip title="Export TaT Booking" arrow>
                <ListItemIcon>
                  <PrintIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Export TaT Booking" />
            </ListItem>
          </Link>
        </List> */}
        {/* <List>
					<Link to="/dashboard/sla/packages">
						<ListItem button>
							<Tooltip title="Manage Packages" arrow>
								<ListItemIcon>
									<ComputerIcon style={{ color: "#924A91" }} />
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary="Manage Packages" />
						</ListItem>
					</Link>
				</List> */}
        {/* <List>
					<Link to="/dashboard/sla/labcredential">
						<ListItem button>
							<Tooltip title="Manage Lab Credentials" arrow>
								<ListItemIcon>
									<LocalHospitalIcon style={{ color: "#924A91" }} />
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary="Manage Lab Credentials" />
						</ListItem>
					</Link>
				</List> */}
        {/* <List>
					<Link to="/dashboard/sla/blogs">
						<ListItem button>
							<Tooltip title="Manage Blogs" arrow>
								<ListItemIcon>
									<WidgetsRounded style={{ color: "#924A91" }} />
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary="Manage Blogs" />
						</ListItem>
					</Link>
				</List> */}
        {/* <List>
          <Link to="/dashboard/sla/leads/export">
            <ListItem button>
              <Tooltip title="Export Leads" arrow>
                <ListItemIcon>
                  <CloudUpload style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Export Leads" />
            </ListItem>
          </Link>
        </List> */}
        {/* <List>
          <Link to="/dashboard/sla/dispositions/bookingexport">
            <ListItem button>
              <Tooltip title="Export Booking Dispositions" arrow>
                <ListItemIcon>
                  <CloudUpload style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Export  Booking Dispositions" />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/dashboard/sla/dispositions/leadexport">
            <ListItem button>
              <Tooltip title="Export Lead Dispositions" arrow>
                <ListItemIcon>
                  <CloudUpload style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Export Lead Dispositions" />
            </ListItem>
          </Link>
        </List> */}
        {/* <List>
          <Link to="/dashboard/sla/calls/export">
            <ListItem button>
              <Tooltip title="Export Calls" arrow>
                <ListItemIcon>
                  <CallSplitIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Export Calls" />
            </ListItem>
          </Link>
        </List> */}
        {/* <List>
					<Link to="/dashboard/sla/leads-transfer">
						<ListItem button>
							<Tooltip title="Leads Transfer" arrow>
								<ListItemIcon>
									<LeakAddIcon style={{ color: "#924A91" }} />
								</ListItemIcon>
							</Tooltip>
							<ListItemText primary="Leads Transfer" />
						</ListItem>
					</Link>
				</List> */}
        {/* <List>
          <Link to="/dashboard/sla/calls">
            <ListItem button>
              <Tooltip title="Calls" arrow>
                <ListItemIcon>
                  <PhoneAndroidRounded style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Calls" />
            </ListItem>
          </Link>
        </List> */}
        {/* <List>
          <Link to="/dashboard/sla/missed-calls">
            <ListItem button>
              <Tooltip title="Missed Calls" arrow>
                <ListItemIcon>
                  <PhoneAndroidRounded style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Missed Calls" />
            </ListItem>
          </Link>
        </List> */}
        {/* <List>
          <Link to="/dashboard/sla/callLog">
            <ListItem button>
              <Tooltip title="Call Details" arrow>
                <ListItemIcon>
                  <DeveloperModeIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Call Details" />
            </ListItem>
          </Link>
        </List> */}
        {/* <List>
          <Link to="/dashboard/sla/crm_chat">
            <ListItem button>
              <Tooltip title="CRM CHAT" arrow>
                <ListItemIcon>
                  <WhatshotIcon style={{ color: "#924A91" }} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary="Crm Chat" />
            </ListItem>
          </Link>
        </List> */}
        <Divider />
        <List>
          <ListItem button onClick={() => {
              if (userDetails?.profile?.get_chat === true) {
                alert("Sign Out From Whatsapp Chat First.")
              } else {
                handleLogout()
              }
              }}>
            <ListItemIcon>
              <ExitToAppIcon style={{ color: "#924A91" }} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={openPopover}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {notificationMessage &&
          notificationMessage?.results &&
          notificationMessage?.results?.length > 0 &&
          notificationMessage?.results.map((data: any) => {
            return <Typography>{data?.notification || ""}</Typography>;
          })}
      </Popover>
      <NewUpdateModal
        anchorElUpdate={anchorElUpdate}
        setAnchorElUpdate={setAnchorElUpdate}
        id={id}
      />
      <CallModal
        open={open1}
        setOpen={setOpen1}
        heading={heading}
        index={modelFormIndex}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  // newuserDetails: state.SalesLeadAdminReducer.newuserDetails,
  userDetails: state.SalesLeadAdminReducer.userDetails,
  notificationMessage: state.SalesLeadAdminReducer.notificationMessage,
  passwordStatus: state.loginReducer.passwordStatus,
});

export default connect(mapStateToProps, {
  getUserDetails,
  getFirebaseNotification,
  getNotificationMessage,
})(SalesDashBoard);
