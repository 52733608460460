import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import Button from '@mui/material/Button';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import { Link } from "react-router-dom";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import { connect } from "react-redux";
import RaiseComplaintModal from "../components/Comments/raiseTicketModal";
import MessageIcon from "@material-ui/icons/Message";
import CommentsModal from "../components/Comments/Comment2";
import CallIcon from "@material-ui/icons/Call";
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import { CALL_DISABLE_TIME } from "../../CallTimeDisable";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "70%",
      float: "left",
    },
    tableContainer: {
      marginTop: "1rem",
      position: "sticky",
      top: "200px",
    },
    rightContentHeader: {
      width: "30%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "100%",
      padding: "0px 0px 10px 0px",
      marginTop: "40px",
      minHeight: "300px",
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#1a237e",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
      height: "40px",
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  breakResponse: any;
  clickToCall: Function;
  callButton: any;
  setCallButton: any;
  leadById: any;
  booking: any;
  getCities: any;
  cities: any;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
  breakResponse,
  clickToCall,
  callButton,
  setCallButton,
  leadById,
  booking,
  getCities,
  cities,
}) => {
  const classes = useStyles();
  const [leadId, setLeadId] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [raiseComplaint, setRaiseComplaint] = useState(false);
  const handleClick = (id: number) => {
    setOpen(true);
    setLeadId(id);
  };

  const handleClickToCall = (type: any) => {
    clickToCall(leadById.id, type.toLowerCase());
  };

  const handleCallClick = (id: number, type: string) => {
    setLeadId(id);
    setCallButton(false);
    clickToCall(id, type, "?is_outbound=true");
    setTimeout(() => {
      setCallButton(true);
    }, CALL_DISABLE_TIME);
  };

  return (
    <TableContainer>
      <Table aria-label="simple table">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell align="center">Mode</StyledTableCell>
            {/* <StyledTableCell align="center">CRM</StyledTableCell> */}
            {/* <StyledTableCell align="center">Dialer Mode</StyledTableCell> */}
            <StyledTableCell align="center">On Call</StyledTableCell>
            <StyledTableCell align="center">On Break</StyledTableCell>
            <StyledTableCell align="center">Message</StyledTableCell>
            <StyledTableCell align="center">Call</StyledTableCell>
            <StyledTableCell align="center">Action</StyledTableCell>
            <StyledTableCell align="center">Create Booking</StyledTableCell>
            <StyledTableCell align="center">Raise a Ticket</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {breakResponse &&
            breakResponse?.results &&
            breakResponse?.results.length > 0 &&
            breakResponse?.results.map((data: any) => {
              return (
                <StyledTableRow style={{ background: "#c5cae9" }}>
                  <StyledTableCell
                    align="center"
                    style={{ fontWeight: "bold" }}
                  >
                    {data?.inbound_mode ? "InBound" : "OutBound"}
                  </StyledTableCell>
                  {/* <StyledTableCell
                    align="center"
                    style={{ fontWeight: "bold" }}
                  >
                    {data?.is_login ? "Active" : "InActive"}
                  </StyledTableCell> */}
                  <StyledTableCell
                    align="center"
                    style={{ fontWeight: "bold" }}
                  >
                    {data?.on_call ? "Yes" : "No"}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ fontWeight: "bold" }}
                  >
                    {data?.break_button ? "Yes" : "No"}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ fontWeight: "bold" }}
                  >
                    {data?.lead_id !== null ? data?.notification : "NA"}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ fontWeight: "bold" }}
                  >
                  {data?.lead_id !== null ?( <Button
                        disabled={!callButton}
                        className="addDeck"
                        variant="contained"
                        color="primary"
                        onClick={() => handleCallClick(data.lead_id, "call")}
                        startIcon={<CallIcon />}
                      ></Button>):""}
                     
                   
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <div>
                      {data?.lead_id !== null ? (
                        <MessageIcon
                          onClick={() => handleClick(data?.lead_id)}
                        />
                      ) : (
                        "NA"
                      )}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <div>
                      {leadById?.id == undefined ? "NA" : (
                        <Link to={`/dashboard/ce/booking/?lead=${leadById?.id}`}>
                          <Button
                            color="primary"
                            variant="contained"
                            size="small"
                          >
                            New Booking
                          </Button>
                        </Link>
                      )}
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    {/* {booking.org_type == "homedx" ? (<>  */}
                    <Button
                      variant={"contained"}
                      color="primary"
                      disabled={!leadById?.id}
                      onClick={() => setRaiseComplaint(!raiseComplaint)}
                      startIcon={<ConfirmationNumberIcon />}
                    >
                    </Button>
                    {/* <Select className="input"
                name="priority"
                variant="outlined"
                style={{ width: "17%", marginLeft: "1rem", marginBottom: "1rem", alignItems: "flex-start",height:"45px" }}
                value={personId}

                onChange={(e: any) => handleChange(e, booking)}
              >
                <MenuItem disabled selected value={'none'}>Customer Name</MenuItem>
                <MenuItem value={booking?.pk}>{booking?.customer_name}</MenuItem>
                {booking.additional_members &&
                  booking.additional_members.length > 0 &&
                  booking.additional_members.map((data: any) => {
                    return (
                      <MenuItem value={data?.id}>{data?.customer_name}</MenuItem>

                    )
                  })}
              </Select> */}
                     {/* </>
            ) : ""}  */}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          <RaiseComplaintModal
            open={raiseComplaint}
            setOpen={setRaiseComplaint}
            leadId={leadById?.id}
            bookingId={0} booking={undefined} totalPrice={0}          />
        </TableBody>
      </Table>
      <CommentsModal open={open} getCities={getCities} cities={cities} setOpen={setOpen} leadId={leadId} />
    </TableContainer>
  );
};

const mapStateToProps = (state: any) => ({});

export default connect(mapStateToProps, {})(CustomerLeadFilterPage);
