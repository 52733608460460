import { ACTIONS, PhleboAdminTypes, } from "../interfaces/actionTypes/CollectionCenter";

type InitialState = {
    pin_code: Array<any>;
    cities: Array<any>;
    areaList: Array<any>;
    agentsList: Array<any>;
    phleboList: Array<any>;
    packageList: Array<any>;
    partnerList: Array<any>;
    couponList: Array<any>;
    createBooking: Object;
    booking: Object;
    todays_positive_calls: Object;
    lead_details: Object;
    leadById: Object;
    leads: Array<any>;
    bookingList: Array<any>;
    qrcode: Array<any>;
    comment: any;
    data: any;
    users: any;
    bookingsComment: any;
    lead_id: number;
    read_call_details: object;
    all_recordings: object;
    whatsappComments: object;
    whatsappCommentsByLead: object;
    callStats: object;
    complaint: object;
    complaints: object;
    lead_source: any;
    booking_slots: object;
    search_results: object,
    call_status: object;
    createIMGBooking: object;
    all_lead: object;
    call_transfer: object;
    user_panel: object;
    agentBYUserGroup: object;
    clientLedgerDetails: Array<any>;
    centerInfo: Array<any>;
    loading: boolean;
    timeslots: Array<any>;
    additional_booking: object,
    collectionSlot: Array<any>;
    paymentInformation: Array<any>;
    subCenter: Array<any>;
    checkGeoFenceStatus: boolean;
    redCoin: Array<any>;
    imagingTimeSlots: Array<any>;
    multiplePackage: Array<any>;
    displayCities: Array<any>;
    mapmyindiaAccessToken: Array<any>;
    geoFencesMapMyIndia: Array<any>;
    ticketData: Array<any>;
    ticketCategories: Array<any>;
    ticketSubCategories: Array<any>;
    redtechcomment:Array<any>;

};

const initialState: InitialState = {
    pin_code: [],
    cities: [],
    areaList: [],
    agentsList: [],
    phleboList: [],
    packageList: [],
    partnerList: [],
    couponList: [],
    createBooking: {},
    booking: {},
    lead_details: {},
    leadById: {},
    todays_positive_calls: {},
    leads: [],
    qrcode: [],
    bookingList: [],
    comment: {},
    createIMGBooking: {},
    data: {},
    additional_booking: {},
    users: {},
    loading: false,
    read_call_details: {},
    all_recordings: {},
    whatsappComments: {},
    whatsappCommentsByLead: {},
    callStats: {},
    complaint: {},
    complaints: {},
    bookingsComment: {},
    lead_id: 0,
    lead_source: {},
    booking_slots: {},
    search_results: {},
    call_status: {},
    all_lead: {},
    call_transfer: {},
    agentBYUserGroup: {},
    clientLedgerDetails: [],
    centerInfo: [],
    collectionSlot: [],
    user_panel: {},
    timeslots: [],
    paymentInformation: [],
    subCenter: [],
    checkGeoFenceStatus: false,
    redCoin: [],
    imagingTimeSlots: [],
    multiplePackage: [],
    displayCities: [],
    geoFencesMapMyIndia: [],
    mapmyindiaAccessToken: [],
    ticketData: [],
    ticketCategories: [],
    ticketSubCategories: [],
    redtechcomment:[],

};

const CustomerLeadReducer = (state = initialState, action: PhleboAdminTypes) => {
    switch (action.type) {
        case ACTIONS.GET_PIN_CODE:
            return {
                ...state,
                pin_code: action.payload,
                loading: false,
            };
        case ACTIONS.UPLOAD_FILE:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case ACTIONS.GET_PAYMENT_INFORMATION:
            return {
                ...state,
                paymentInformation: action.payload,
                loading: false,
            };
        case ACTIONS.GET_PHLEBOS:
            return {
                ...state,
                phleboList: action.payload,
                loading: false,
            };
        case ACTIONS.GET_CITIES:
            return {
                ...state,
                cities: action.payload,
                loading: false,
            };
        case ACTIONS.GET_SUB_CENTER_INFORMATION:
            return {
                ...state,
                subCenter: action.payload,
                loading: false,
            };
        case ACTIONS.GET_AREA:
            return {
                ...state,
                areaList: action.payload,
                loading: false,
            };
        case ACTIONS.GET_QRCODE_DATA:
            return {
                ...state,
                qrcode: action.payload,
                loading: false,
            };
        case ACTIONS.GET_POSTIVE_CALLS_COMMENTS:
            return {
                ...state,
                todays_positive_calls: action.payload,
                loading: false,
            };
        case ACTIONS.SET_COMMENTS:
            return {
                ...state,
                comment: action.payload,
                loading: false,
            };
            case ACTIONS.GET_RED_TECH_COMMENT:
        return {
            ...state,
            redtechcomment: action.payload,
            loading: false,
        };
        case ACTIONS.ADD_POSTIVE_CALLS_COMMENT:
            return {
                ...state,
                todays_positive_calls: action.payload,
                loading: false,
            };
        case ACTIONS.GET_LEAD_DETAILS:
            return {
                ...state,
                lead_details: action.payload,
                loading: false,
            };
        case ACTIONS.GET_LEADS:
            return {
                ...state,
                leads: action.payload,
                loading: false,
            };
        case ACTIONS.GET_LEAD_BY_ID:
            return {
                ...state,
                leadById: action.payload,
                loading: false,
            };
        case ACTIONS.LEAD_DETAILS_RESET:
            return {
                ...state,
                leadById: {},
                lead_details: {},
                loading: false,
            };
        case ACTIONS.GET_AGENTS:
            return {
                ...state,
                agentsList: action.payload,
                loading: false,
            };
        case ACTIONS.GET_AGENTS_BY_USERGROUP:
            return {
                ...state,
                agentBYUserGroup: action.payload,
                loading: false,
            };
        case ACTIONS.GET_PACKAGES:
            return {
                ...state,
                packageList: action.payload,
                loading: false,
            };
        case ACTIONS.GET_PARTNERS:
            return {
                ...state,
                partnerList: action.payload,
                loading: false,
            };
        case ACTIONS.GET_COUPONS:
            return {
                ...state,
                couponList: action.payload,
                loading: false,
            };
        case ACTIONS.CREATE_BOOKING:
            return {
                ...state,
                createBooking: action.payload,
                loading: false,
            };
        case ACTIONS.GET_BOOKING_LIST:
            return {
                ...state,
                bookingList: action.payload,
                loading: false,
            };
        case ACTIONS.GET_BOOKING_BY_ID:
            return {
                ...state,
                booking: action.payload,
                loading: false,
            };
        case ACTIONS.SET_LOADING:
            return {
                ...state,
                loading: action.payload,
            };
        case ACTIONS.CREATE_CALL:
            return {
                ...state,
                loading: false,
                lead_id: action.payload,
            };
        case ACTIONS.GET_RECORDINGS:
            return {
                ...state,
                all_recordings: action.payload,
                loading: false,
            };
        case ACTIONS.GET_LEAD_SOURCE:
            return {
                ...state,
                lead_source: action.payload,
                loading: false,
            };
        case ACTIONS.SEARCH_CALL:
            return {
                ...state,
                search_results: action.payload,
                loading: false,
            };
        case ACTIONS.ADD_COMMENT:
            return {
                ...state,
                comments: action.payload,
                loading: false,
            };
        case ACTIONS.BOOKING_RESET:
            return {
                ...state,
                createBooking: {},
                bookingList: [],
                loading: false,
            };
        case ACTIONS.GET_BOOKINGS_COMMENTS:
            return {
                ...state,
                bookingsComment: action.payload,
                loading: false,
            };
        case ACTIONS.ADD_BOOKINGS_COMMENT:
            return {
                ...state,
                bookingsComment: action.payload,
                loading: false,
            };
        case ACTIONS.GET_WHATSAPP_COMMENTS:
            return {
                ...state,
                whatsappComments: action.payload,
                loading: false,
            };
        case ACTIONS.GET_WHATSAPP_COMMENTS_BY_LEAD:
            return {
                ...state,
                whatsappCommentsByLead: action.payload,
                loading: false,
            };
        case ACTIONS.CHECK_GEOFENCE_AREA:
            return {
                ...state,
                checkGeoFenceStatus: action.payload,
                loading: false,
            };
        case ACTIONS.SEND_WHATSAPP_COMMENTS:
            return {
                ...state,
                whatsappComments: action.payload,
                loading: false,
            };
        case ACTIONS.GET_CALL_STATS:
            return {
                ...state,
                callStats: action.payload,
                loading: false,
            };
        case ACTIONS.RAISE_COMPLAINT:
            return {
                ...state,
                complaint: action.payload,
                loading: false,
            };
        case ACTIONS.GET_COMPLAINTS:
            return {
                ...state,
                complaints: action.payload,
                loading: false,
            };
        case ACTIONS.ASSIGN_DOCTOR:
            return {
                ...state,
                success: true,
                loading: false,
            };
        case ACTIONS.SEND_FEEDBACK:
            return {
                ...state,
                success: true,
                loading: false,
            };
        case ACTIONS.GET_AVAILABLE_SLOTS:
            return {
                ...state,
                booking_slots: action.payload,
                loading: false,
            };
        case ACTIONS.GET_ALL_LEADS:
            return {
                ...state,
                all_lead: action.payload,
                loading: false,
            };
        case ACTIONS.GET_PANEL_USERS:
            return {
                ...state,
                user_panel: action.payload,
                loading: false,
            };
        case ACTIONS.LEAD_TRANSFER_BY_COMMA:
            return {
                ...state,
                all_lead: action.payload,
                loading: false,
            };
        case ACTIONS.GET_CLIENT_LEDGER_DETAILS:
            return {
                ...state,
                clientLedgerDetails: action.payload,
                loading: false,
            };
        case ACTIONS.GET_CENTER_INFORMATION:
            return {
                ...state,
                centerInfo: action.payload,
                loading: false,
            };
        case ACTIONS.GET_TIMESLOTS:
            return {
                ...state,
                timeslots: action.payload,
                loading: false,
            };
        case ACTIONS.CREATE_ADDITIONAL_BOOKING:
            return {
                ...state,
                additional_booking: action.payload,
                loading: false,
            };
        case ACTIONS.UPDATE_ADDITIONAL_BOOKING:
            return {
                ...state,
                additional_booking: action.payload,
                loading: false,
            };
        case ACTIONS.CREATE_IMAGING_BOOKING:
            return {
                ...state,
                createIMGBooking: action.payload,
                loading: false,
            };
        case ACTIONS.GET_COLLECTION_SLOT:
            return {
                ...state,
                collectionSlot: action.payload,
                loading: false,
            };
        case ACTIONS.GET_RED_COIN:
            return {
                ...state,
                redCoin: action.payload,
                loading: false,
            };
        case ACTIONS.GET_IMAGING_TIMESLOTS:
            return {
                ...state,
                imagingTimeSlots: action.payload,
                loading: false,
            };
        case ACTIONS.GET_MULTIPLE_PACKAGES:
            return {
                ...state,
                multiplePackage: action.payload,
                loading: false,
            };
        case ACTIONS.GET_DISPLAY_CITIES:
            return {
                ...state,
                displayCities: action.payload,
                loading: false,
            };
        case ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN:
            return {
                ...state,
                mapmyindiaAccessToken: action.payload,
                loading: false,
            };
        case ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA:
            return {
                ...state,
                geoFencesMapMyIndia: action.payload,
                loading: false,
            };
            case ACTIONS.GET_TICKET_DATA:
                return {
                  ...state,
                  ticketData: action.payload,
                  loading: false,
                };
              case ACTIONS.GET_TICKET_CATEGORIES:
                return {
                  ...state,
                  ticketCategories: action.payload,
                  loading: false,
                };
              case ACTIONS.GET_TICEKT_SUB_CATEGORIES:
                return {
                  ...state,
                  ticketSubCategories: action.payload,
                  loading: false,
                };
        default:
            return state;
    }
};

export default CustomerLeadReducer;
