import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "../react-sliding-pane.css";
import {
  CITY,
  LOCALITY,
  ADDRESS_LINE1,
  ADDRESS_LINE2,
  LANDMARK,
  PINCODE,
  SUBLOCALITY,
} from "../../constant";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@material-ui/icons/Close";
import MessageIcon from "@material-ui/icons/Message";
import EditIcon from "@material-ui/icons/Edit";
import { useDispatch } from "react-redux";
// import { ACTIONS as ACTIONS1 } from '../../../../interfaces/actionTypes/loginTypes';
import {
  Card,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Dialog,
  DialogActions,
  InputAdornment,
  DialogContent,
  DialogTitle,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CommentsModal2 from "../../Comments/Comment2";
import CommentsModal from "../../Comments/comment";
import {
  checkGeoFenceArea,
  getPinCode,
  getPackage,
  createNewBooking,
  getCoupons,
  getLeads,
  getLeadDetails,
  resetLeadDetails,
  getAvailableSlots,
  getRedCoing,
  checkGeoFenchMapMyIndia,
  checkGeoFenchMapMyIndiaPilot,
  getMapMyIndiaAccessToken,
  getCenterInfo,
  getceCentre,
  getTimeslots,
  createPilotNewBooking,
  getBreakResponse,
  resetBookings,
  getLeadById,
  getCities,
  checkGeoFenchMapMyIndiaPilotForCustomerAddress,
  getCommunicationOnWhatsapp,
  SendLocationRequest,
  getCrmLeadAddress,
  postCrmLeadAddress,
  getNewPackage,
  getWhatsappMessageStatus,
  // getReferralCurrentBalance,
  // getCouponReferralValidation,
} from "../../../actions/bookingActions";
import Button from "@mui/material/Button";
import "./index.sass";
import { connect } from "react-redux";
import moment from "moment";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { Link, useHistory } from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { GENDER_CHOICE } from "../../constant";
import { ACTIONS } from "../../../interfaces/actionTypes/booking";
import WhatsAppModal from "../../Comments/whatsappModal";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Loader from '../../Loader2'
import { getDistance } from 'geolib';
import ReplayIcon from '@mui/icons-material/Replay';
// import { mappls } from "mappls-web-maps";
import LocationVerificationModal from "../../Comments/locationVerificationModal";
// import PackageQuestionsEnglish from "../../Comments/packageQuestionsEnglish";
// import PackageQuestionsHindi from "../../Comments/packageQuestionsHindi";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    buttonContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    slotContainer: {
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    slot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      margin: "0.5rem",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      width: "100%",
    },
    head: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "2rem",
    },
    selectedSlot: {
      width: "150px",
      height: "100px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      cursor: "pointer",
      margin: "0.5rem",
    },
    chips: {
      display: "flex",
    },
    chip: {
      margin: 2,
      background: "linear-gradient(60deg, cyan, #00cfff, cyan)",
      maxWidth: "130px",
    },
    table: {
      maxWidth: "100%",
      margin: "auto",
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "100%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "50%",
      float: "left",
    },
    rightContentHeader: {
      width: "50%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "100%",
      padding: "20px 10px 10px 10px",
      background: "#ffffff",
      margin: "10px",
      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    },
    mapCSS: {
      height: "300px",
      width: "100%",
    },
    packageLink: {
      color: "#ffffff",
      textDecoration: "none",
    },
    dialog: {
      zIndex: 2,
    },
  })
);

const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
      fontWeight: 600,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  lead: any;
  getPinCode: any;
  pin_code: any;
  getPackage: any;
  packageList: any;
  couponList: any;
  getCoupons: any;
  createNewBooking: any;
  getLeads: any;
  leads: any;
  getLeadDetails: any;
  lead_details: any;
  booking_slots: any;
  resetLeadDetails: any;
  createPilotBooking: any;
  getAvailableSlots: any;
  loading: boolean;
  sameNumberBooking: boolean;
  checkGeoFenceArea: any;
  getRedCoing: any;
  redCoin: any;
  checkGeoFenchMapMyIndia: any;
  checkGeoFenchMapMyIndiaPilot: any;
  geoFencesMapMyIndia: any;
  mapmyindiaAccessToken: any;
  getMapMyIndiaAccessToken: any;
  getCenterInfo: any;
  centerInfo: any;
  ceCenterForCC: any;
  getceCentre: any;
  getTimeslots: any;
  timeslots: any;
  createPilotNewBooking: any;
  userDetails: any;
  breakResponse: any;
  getBreakResponse: any;
  resetBookings: any;
  geoLoading: boolean;
  geoCheckError: string;
  getLeadById: any;
  leadById: any;
  getCities: any;
  cities: any;
  getCommunicationOnWhatsapp: any;
  communicationWhatsapp: any;
  SendLocationRequest: any;
  checkGeoFenchMapMyIndiaPilotForCustomerAddress: any;
  geoFencesMapMyIndiaForCustomer: any;
  getCrmLeadAddress: any;
  crmLeadAddress: any;
  postCrmLeadAddress: any;
  crmPostLeadAddress: any;
  locationDataFromPusher: any;
  getNewPackage: any;
  leadAddressLoading: any;
  whatsappAddressIdDetail: any;
  getWhatsappMessageStatus: any;
  whatsappMessageStatus: any;
  // getReferralCurrentBalance: any;
  // getCouponReferralValidation: any;
  // referralCurrentBalance: any;
  // couponReferralValidation: any;
}

const BookingWithLocalitySearch: React.FC<Props> = ({
  lead,
  getPinCode,
  pin_code,
  packageList,
  getPackage,
  couponList,
  getCoupons,
  createNewBooking,
  getLeads,
  leads,
  getLeadDetails,
  lead_details,
  booking_slots,
  resetLeadDetails,
  createPilotBooking,
  getAvailableSlots,
  sameNumberBooking,
  checkGeoFenceArea,
  getRedCoing,
  redCoin,
  loading,
  checkGeoFenchMapMyIndia,
  checkGeoFenchMapMyIndiaPilot,
  geoFencesMapMyIndia,
  mapmyindiaAccessToken,
  getMapMyIndiaAccessToken,
  getCenterInfo,
  centerInfo,
  getceCentre,
  ceCenterForCC,
  getTimeslots,
  timeslots,
  createPilotNewBooking,
  userDetails,
  breakResponse,
  getBreakResponse,
  resetBookings,
  geoLoading,
  geoCheckError,
  getLeadById,
  leadById,
  cities,
  getCities,
  getCommunicationOnWhatsapp,
  communicationWhatsapp,
  SendLocationRequest,
  checkGeoFenchMapMyIndiaPilotForCustomerAddress,
  geoFencesMapMyIndiaForCustomer,
  getCrmLeadAddress,
  crmLeadAddress,
  postCrmLeadAddress,
  crmPostLeadAddress,
  locationDataFromPusher,
  getNewPackage,
  leadAddressLoading,
  whatsappAddressIdDetail,
  getWhatsappMessageStatus,
  whatsappMessageStatus,
  // getReferralCurrentBalance,
  // getCouponReferralValidation,
  // referralCurrentBalance,
  // couponReferralValidation,
}) => {
  const classes = useStyles();
  let dispatch = useDispatch();
  const history = useHistory();
  const [inputList, setInputList] = useState<any>([
    {
      customerId: "",
      customerDesignation: "",
      designation: "",
      customerName: "",
      customerAge: "",
      customerGender: "",
      customerPackage: [],
      packagePrice: 0,
      dateOfBirth: "",
      is_prescribed: "",
      bookingFor: "",
      passport: "",
      aadhar: "",
      oldNameUHID: "",
    },
  ]);
  const [cType, setCType] = useState<any>("code");
  const [cValue, setCValue] = useState<any>("");
  const [collection_date, setCollection_date] = useState<String>("");
  const [pincode, setPinCode] = useState<string>("");
  const [zoneName, setZoneName] = useState<String>("");
  const [timeSlots, setTimeSlots] = useState<Array<Object>>([]);
  const [selectedSlot, setSelectedSlot] = useState<Number>(0);
  const [customer_phonenumber, setCustomer_phonenumber] = useState<String>("");
  const [customer_alternatenumber, setCustomer_alternatenumber] =
    useState<String>("");
  const [customer_whatsapppnumber, setCustomer_whatsapppnumber] =
    useState<String>("");
  const [customer_address, setCustomer_address] = useState<String>("");
  const [customer_email, setCustomer_email] = useState<String>("");
  const [customer_landmark, setCustomer_landmark] = useState<string>("");
  const [customer_landmark1, setCustomer_landmark1] = useState<string>("");
  const [customerLatitude, setCustomerLatitude] = useState<any>();
  const [customerLongitude, setCustomerLongitude] = useState<any>();
  const [customer_areapincode, setCustomer_areapincode] = useState<string>("");
  const [customer_areapincode_id, setCustomer_areapincode_id] =
    useState<Number>(0);
  //const [customer_areaname, setCustomer_areaname] = useState<String>("");
  const [customer_city, setCustomer_city] = useState<String>("");
  const [customer_aadhar, setCustomer_aadhar] = useState<String>("");
  const [passport, setPassport] = useState<String>("");
  const [price, setPrice] = useState<any>(0);
  const [discount, setDiscount] = useState<any>(0);
  const [discountDueSmartPackage, setDiscountDueSmartPackage] =
    useState<any>(0);
  const [totalPrice, setTotalPrice] = useState<any>(0);
  const [coupon, setCoupon] = useState<Number>(0);
  const [couponCode, setCouponCode] = useState<string>("");
  const [extraCharge, setExtraCharge] = useState<boolean>(false);
  const [cityId, setCityId] = useState<any>("");
  const [is_pincode, setIsPincode] = useState<boolean>(true);
  const [packageKey, setPackageKey] = useState<any>(0);
  const [defaultEmail, setDefaultEmail] = useState<any>(
    "customer.reports@redcliffelabs.com"
  );
  const [mobileValidation, setMobileValidation] = useState({
    customer_alternatenumber: true,
    customer_phonenumber: true,
    customer_whatsapppnumber: true,
  });
  const [readology, setReadology] = useState<any>(false);
  const [search, setSearch] = useState<String>("");
  const [zoneId, setZoneId] = useState<any>("");
  const timer = useRef<any>(0);
  const handleSearch = (e: React.ChangeEvent<{ value: unknown }>) => {
    setSearch(e.target.value as string);
  };
  const [addressLine2, setAddressLine2] = useState<string>();
  const [favoritePlace, setFavoritePlace] = useState<any>();
  const [openLocationModal, setOpenLocationModal] = useState<boolean>(false);
  const [bookingEnabled, setBookingEnabled] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isPaneOpen, setPaneOpen] = useState<boolean>(false);
  const [singlePackage, setSinglePackage] = useState<any>({});
  const [mobError, setMobError] = React.useState<string>("");
  const [altError, setAltError] = React.useState<string>("");
  const [whatsError, setWhatsError] = React.useState<string>("");
  const [tncPerCentage, setTnPercentage] = useState<string>("");
  const [couponMessage, setCouponMessage] = useState<string>("");
  const [tncMessage, setTncMessage] = useState<string>("");
  const [redCoinMessage, setRedCoinMessage] = useState<any>("");
  const [redCashCheck, setRedCashCheck] = useState<boolean>(false);
  const [totalRedCoin, setTotalRedCoin] = useState<any>("");
  const [loading1, setLoading1] = useState<any>(false);
  const [redDisc, setRedDisc] = useState<any>(0);
  const [error, setError] = useState("");
  // const [couponType, setCouponType] = useState<boolean>(false);
  const [redCouponDiscount, setRedCouponDiscount] = useState<number>(0);
  const [lead_customer_phonenumber, setLeadCustomer_phonenumber] =
    useState<string>("");
  const [lead_customer_whatsapppnumber, setLeadCustomer_whatsapppnumber] =
    useState<string>("");

  const [lead_customer_alter, setLeadCustomer_alter] = useState<string>("");
  // const [mapData, setMapData] = useState<any>([]);
  // const [mapOpen, setMapOpen] = useState<boolean>(false);
  // const [backSpaceDetect, setbackSpaceDetcet] = useState<boolean>(false);
  const [couponKey, setCouponKey] = useState<any>(0);
  //Uhid Implementation start
  const [uhidModalOpen, setUhidmodalOpen] = useState<boolean>(false);
  const [collectionCenterId, setCollectionCenterId] = useState<any>("");
  const [collectionKey, setCollectionkey] = useState<any>(0);
  const [collectionType, setCollectionType] = useState<any>("homecollection");
  const [zoneKey, setZoneKey] = useState<any>(0);
  const [leadData, setLeadData] = useState<any>(0);
  const [cityName, setCityName] = useState<string>("");
  const [dummyValue, setDummyValue] = useState<any>("XXXXXXXXXX");
  const [dummyValueMain, setDummyValueMain] = useState<boolean>(false);
  const [dummyValueAlter, setDummyValueAlter] = useState<boolean>(false);
  const [dummyValueWhats, setDummyValueWhats] = useState<boolean>(false);
  const [phleboCost, setPhleboCost] = useState<any>("");
  const [valCoin, setValCoin] = useState<boolean>(false);
  const [commentModalOpen, setCommentModalOpen] = useState<boolean>(false);
  const [openM, setOpenM] = useState<boolean>(false);
  const [openWhatsapp, setOpenWhatsapp] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false)
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false)
  const [addressExeData, setAddressExeData] = useState<any>("")
  const [addressObjData, setAddressObjData] = useState<any>("")
  const [distanceState, setDistanceState] = useState<any>({
    destinationLat: "",
    destinationLong: "",
    distance: null,
    startLat: "",
    startLong: "",
  });
  const [nameValidation, setNameValidation] = useState<any>(true);
  const [newRefrelApi, setNewRefrelApi] = useState(false);
  const [checkedWay, setCheckedWay] = useState<any>("");
  const verifyName = () => {
    const nameRegex = /^([a-zA-Z0-9 ]){2,500}$/;
    if (search && !nameRegex.test(search.toString())) {
      setNameValidation(false);
    }
  };
  const [whatsappLeadSearch, setWhatsappLeadSearch] = useState<any>("");
  const [phleboRemarks, setPhleboRemarks] = useState<any>();
  const [openVerifiedLocationModal, setOpenVerifiedLocationModal] =
    useState<boolean>(false);
  const [bookingIdLocationModal, setBookingIdLocationModal] =
    useState<number>(0);
  const [leadIdLocationModal, setLeadIdLocationModal] = useState<number>(0);
  const [apiHitCount, setApiHitCount] = useState<number>(0);
  const [customerLatitudeModal, setCustomerLatitudeModal] = useState<any>();
  const [customerLongitudeModal, setCustomerLongitudeModal] = useState<any>();
  const [customerLatitude1Modal, setCustomerLatitude1Modal] = useState<any>();
  const [customerLongitude1Modal, setCustomerLongitude1Modal] = useState<any>();
  const [cityIdModal, setCityIdModal] = useState<any>("");
  const [addressLine2Modal, setAddressLine2Modal] = useState<string>();
  const [favoritePlaceModal, setFavoritePlaceModal] = useState<any>();
  const [customer_addressModal, setCustomer_addressModal] =
    useState<String>("");
  const [customer_landmark1Modal, setCustomer_landmark1Modal] =
    useState<string>("");
  const [verifyKey, setVerifyKey] = useState<boolean>(false);
  const [addressId, setAddressId] = useState<string>("");
  const [addressIdCheck, setAddressIdCheck] = useState<number>(0);
  const [locationMessageStatus, setLocationMessageStatus] = useState<string>("");
  const [saveOldAddress, setSaveOldAddress] = useState<any>("");
  const [openQestionModel, setQuestionModel] = useState<any>(false);
  const [manualCouponCode, setManualCouponCode] = useState<any>("");
  const [referralPointsDiscount, setReferralPointsDiscount] = useState<any>(0);
  const min = 1;
  const max = 100;
  const random = Math.floor(min + Math.random() * (max - min));
  const [openOneTime, setOpenOneTime] = useState<boolean>(false);

  //Uhid Implementation end

  useEffect(() => {
    if (
      leads?.count > 0 &&
      locationDataFromPusher?.lead_id === leads?.results[0]?.id
    ) {
      setCustomerLatitudeModal(
        locationDataFromPusher?.booking_location?.latitude
      );
      setCustomerLatitude1Modal(
        locationDataFromPusher?.customer_location?.latitude
      );
      setCustomerLongitudeModal(
        locationDataFromPusher?.booking_location?.longitude
      );
      setCustomerLongitude1Modal(
        locationDataFromPusher?.customer_location?.longitude
      );
      setLeadIdLocationModal(leadData);
      setCityIdModal(cityId);
      setAddressLine2Modal(addressLine2);
      setFavoritePlaceModal(favoritePlace);
      setCustomer_addressModal(customer_address);
      setCustomer_landmark1Modal(customer_landmark1);
      setOpenVerifiedLocationModal(true);
      setAddressId(locationDataFromPusher?.address_id);
      // handleClickLocation(locationDataFromPusher?.pk)
    }
  }, [locationDataFromPusher])

  // useEffect(() => {
  //   if (whatsappAddressIdDetail?.address_id > 0) {
  //     if (crmPostLeadAddress?.id === whatsappAddressIdDetail?.address_id) {
  //       setAddressIdCheck(whatsappAddressIdDetail?.address_id)
  //       setLocationMessageStatus(whatsappAddressIdDetail?.reciept)
  //     }
  //   }
  // }, [whatsappAddressIdDetail])

  useEffect(() => {
    if (crmPostLeadAddress?.id === whatsappMessageStatus?.id) {
      setLocationMessageStatus(whatsappMessageStatus?.location_request_status)
    }
  }, [whatsappMessageStatus])

  const searchLead = () => {
    resetBookings();
    resetLeadDetails();
    resetAddressComponent();
    setMapAddressObject({});
    setCustomer_address("");
    setAddressLine2("");
    setFavoritePlace("");
    setAddressExeData("");
    setCityId("");
    setCustomer_landmark("");
    setApiHitCount(0);
    setCollection_date("");
    setInputList([
      {
        customerId: "",
        customerDesignation: "",
        designation: "",
        customerName: "",
        customerAge: "",
        customerGender: "",
        customerPackage: [],
        packagePrice: 0,
        dateOfBirth: "",
        is_prescribed: "",
        bookingFor: "",
        passport: "",
        aadhar: "",
        oldNameUHID: "",
      },
    ]);
    let body = {
      search_by: "call_id",
      value: search,
    };
    getLeads(body);
  };

  // useEffect(() => {
  //   const unloadCallback = (event: { preventDefault: () => void; returnValue: string; }) => {
  //     event.preventDefault();
  //     event.returnValue = "";
  //     return "ha";
  //   };

  //   window.addEventListener("beforeunload", unloadCallback);
  //   return () => window.removeEventListener("beforeunload", unloadCallback);
  // }, []);

  const selectLead = (id: any) => {
    setValCoin(true);
    // setLeadIdLocationModal(id)
    if (id !== 0 && id !== null && id !== undefined && id !== "undefined") {
      // getLeadDetails(`${id}`, "true");
    }
  };

  useEffect(() => {
    if (lead) {
      setSearch(lead);
      getLeads({
        search_by: "call_id",
        value: lead,
      });
      selectLead(lead);
    }
  }, [lead]);
  const [numCheck, setNumCheck] = useState({
    altCheck: false,
    whatsCheck: false,
    altCheck1: false,
    whatsCheck1: false,
  });
  const [changeNumType, setChangeNumType] = useState<any>("");
  const [changeNumber, setChangeNumber] = useState({
    altNumChange: false,
    whatsappNumChange: false,
  });

  const [changeWhatsapp, setChangeWhatsapp] = useState<boolean>(false);
  const [sameWhatsapp, setSameWhatsapp] = useState<boolean>(false);
  const [changeAlternate, setChangeAlternate] = useState<boolean>(false);
  const [sameAlternate, setSameAlternate] = useState<boolean>(false);
  const [packageHindi, setPackageHindi] = useState(false);

  const handleChangeWhatsapp = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: any
  ) => {
    setChangeWhatsapp(event.target.checked);
    setChangeNumType(type);
  };
  const handleSameWhatsapp = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: any
  ) => {
    setSameWhatsapp(event.target.checked);
  };
  const handleChangeAlternate = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: any
  ) => {
    setChangeAlternate(event.target.checked);
    setChangeNumType(type);
  };
  const handleSameAlternate = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: any
  ) => {
    setSameAlternate(event.target.checked);
  };

  const handleNumSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNumCheck({ ...numCheck, [event.target.name]: event.target.checked });
  };

  const handleNumSelectChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: any
  ) => {
    setChangeNumber({
      ...changeNumber,
      [event.target.name]: event.target.checked,
    });
    setChangeNumType(type);
  };
  useEffect(() => {
    if (changeNumType === "changeWhatsapp" && changeWhatsapp) {
      setDummyValueWhats(false);
    } else if (changeNumType === "changeAlternate" && changeAlternate) {
      setDummyValueAlter(false);
    }
  }, [changeNumType]);
  // useEffect(() => {
  //   if (changeNumType === "whats" && changeNumber.whatsappNumChange) {
  //     setDummyValueWhats(false)
  //   }
  //   else if (changeNumType === "alt" && changeNumber.altNumChange) {
  //     setDummyValueAlter(false)
  //   }
  // }, [changeNumType])

  useEffect(() => {
    if (sameWhatsapp) {
      setDummyValueWhats(true);
      lead_details.whatsapp_contact !== null &&
        lead_details.whatsapp_contact !== undefined
        ? setLeadCustomer_whatsapppnumber(lead_customer_whatsapppnumber)
        : lead_details.mobile !== null && lead_details.mobile !== undefined
          ? setLeadCustomer_whatsapppnumber(lead_customer_phonenumber as any)
          : setLeadCustomer_whatsapppnumber(customer_phonenumber as any);
    }
    if (!sameWhatsapp) {
      setDummyValueWhats(false);
    }
  }, [sameWhatsapp]);

  useEffect(() => {
    if (sameAlternate) {
      setDummyValueAlter(true);
      lead_details.mobile !== null && lead_details.mobile !== undefined
        ? setLeadCustomer_alter(lead_customer_phonenumber)
        : lead_details.mobile !== null && lead_details.mobile !== undefined
          ? setLeadCustomer_alter(lead_customer_phonenumber as any)
          : setLeadCustomer_alter(customer_phonenumber as any);
    }
    if (!sameAlternate) {
      setDummyValueAlter(false);
    }
  }, [sameAlternate]);

  // useEffect(() => {
  //   if (numCheck.altCheck) {
  //     setDummyValueAlter(true)
  //     lead_details.mobile !== null && lead_details.mobile !== undefined ? setLeadCustomer_alter(lead_customer_phonenumber) : lead_details.mobile !== null && lead_details.mobile !== undefined ? setLeadCustomer_alter(lead_customer_phonenumber as any) : setLeadCustomer_alter(customer_phonenumber as any)
  //   }
  //   if (numCheck.whatsCheck) {
  //     setDummyValueWhats(true)
  //     lead_details.whatsapp_contact !== null && lead_details.whatsapp_contact !== undefined ? setLeadCustomer_whatsapppnumber(lead_customer_whatsapppnumber) : lead_details.mobile !== null && lead_details.mobile !== undefined ? setLeadCustomer_whatsapppnumber(lead_customer_phonenumber as any) : setLeadCustomer_whatsapppnumber(customer_phonenumber as any)
  //   }
  //   if (!numCheck.altCheck) {
  //     setDummyValueAlter(false)
  //   }

  //   if (!numCheck.whatsCheck) {
  //     setDummyValueWhats(false)
  //   }
  //   // }
  // }, [numCheck])

  useEffect(() => {
    setCustomer_address(lead_details.address as String);
    lead_details.mobile !== null && lead_details.mobile !== undefined
      ? setLeadCustomer_phonenumber(lead_details.mobile as string)
      : setLeadCustomer_phonenumber("");
    lead_details.mobile !== null && lead_details.mobile !== undefined
      ? setDummyValueMain(true)
      : setDummyValueMain(false);
    lead_details.whatsapp_contact !== null &&
      lead_details.whatsapp_contact !== undefined
      ? setLeadCustomer_whatsapppnumber(lead_details.whatsapp_contact as string)
      : setLeadCustomer_whatsapppnumber("");
    lead_details.whatsapp_contact !== null &&
      lead_details.whatsapp_contact !== undefined
      ? setDummyValueWhats(true)
      : setDummyValueWhats(false);
    lead_details.content_number !== null &&
      lead_details.content_number !== undefined
      ? setLeadCustomer_alter(lead_details.content_number as string)
      : setLeadCustomer_alter("");
    lead_details.content_number !== null &&
      lead_details.content_number !== undefined
      ? setDummyValueAlter(true)
      : setDummyValueAlter(false);
    setLeadData(lead_details?.id);
    setInputList([
      {
        customerDesignation:
          lead_details?.calling_gender === "male" || "Male"
            ? "Mr"
            : lead_details?.calling_gender === "female" || "Female"
              ? "Ms"
              : "",
        designation: lead_details?.calling_gender,
        customerName: lead_details?.patient_name,
        customerAge: lead_details?.patient_age,
        customerGender: lead_details?.calling_gender,
        customerPackage: [],
        packagePrice: 0,
        dateOfBirth: "",
        customerId: "",
        is_prescribed: "",
        bookingFor: "",
        passport: "",
        aadhar: "",
        oldNameUHID: lead_details?.patient_name,
      },
    ]);
  }, [lead_details]);

  const clearPackage = () => {
    let list = inputList;
    list &&
      list.length > 0 &&
      list.map((val: any, index: any) => {
        list[index]["customerPackage"] = [];
        list[index]["packagePrice"] = 0;
      });
    setTotalPrice(0);
    setInputList(list);
  };

  useEffect(() => {
    resetBookings();
    resetLeadDetails();
    getLeadById();
    dispatch({ type: ACTIONS.GET_LEAD_DETAILS, payload: {} });
    // dispatch({ type: ACTIONS1.LOCATION_DATA_FROM_PUSHER, payload: [] });
  }, []);

  const [isHitCouponAPI, setIsHitCouponAPI] = useState(false);

  useEffect(() => {
    if (isHitCouponAPI === true) {
      getCoupons("?org_type=homedx");
    }
  }, [isHitCouponAPI]);

  const [isHitMapAPI, setIsHitMapAPI] = useState(false);

  useEffect(() => {
    if (isHitMapAPI === true) {
      getMapMyIndiaAccessToken();
    }
  }, [isHitMapAPI]);

  useEffect(() => {
    const slots = booking_slots && booking_slots?.results;
    setTimeSlots(slots && slots[0].zone_data && slots[0].zone_data.time_slots);
  }, [booking_slots, collection_date]);

  useEffect(() => {
    if (pincode && pin_code?.results?.length == 0) {
      // setErrorMessage("We are not providing service on this pincode.");
      resetAddressComponent();
    }
    if (pincode && pin_code?.results?.length > 0) {
      const findPinCode = pin_code?.results.find(
        (code: any) => code.pincode == pincode
      );
      if (findPinCode) {
        setErrorMessage("");
        // getArea(pincode);
        const { id, area, city, cityid } = pin_code.results.find(
          (code: any) => code.pincode === pincode
        );
        getPackage(`city_id=${cityid}&org_type=homedx`);
        // {
        //   userDetails?.profile?.pilot_user ? (
        //     getNewPackage(`city_id=${cityid}&org_type=homedx`)
        //   ) : getPackage(`city_id=${cityid}&org_type=homedx`)
        // }
        setCityId(cityid as String);
        if (Number(cityid) !== Number(cityId)) {
          setPackageKey(random);
          clearPackage();
        }
        // setCustomer_areapincode(id as Number);

        setCustomer_city(city as String);
        setIsPincode(false);
      } else {
        // setErrorMessage("We are not providing service on this pincode.");
      }
    }
  }, [pin_code, pincode]);

  useEffect(() => {
    if (cityId !== null && cityId !== "" && cityId !== undefined) {
      getPackage(`city_id=${cityId}&org_type=homedx`);
      // {
      //   userDetails?.profile?.pilot_user ? (
      //     getNewPackage(`city_id=${cityId}&org_type=homedx`)
      //   ) : getPackage(`city_id=${cityId}&org_type=homedx`)
      // }
    }
  }, [cityId]);

  useEffect(() => {
    if (collectionType === "collectioncentre") {
      getTimeslots();
    }
  }, [collectionType]);

  useEffect(() => {
    if (zoneId !== null && zoneId !== "" && zoneId !== undefined) {
      if (collection_date !== "") {
        getAvailableSlots(collection_date, zoneId);
      }
    }
  }, [zoneId]);

  const resetAddressComponent = () => {
    setZoneId("");
    setCityId("");
    //setCustomer_areaname("");
    setIsPincode(true);
    setPackageKey(0);
    // setZoneName("");
    setCustomer_city("");
    setPinCode("");
  };
  useEffect(() => {
    if (cityId) {
      getCenterInfo(`?city=${cityId}&optimise=true`);
    }
  }, [cityId]);

  useEffect(() => {
    if (
      customerLatitude !== "" &&
      customerLatitude !== undefined &&
      customerLatitude !== "undefined" &&
      customerLatitude !== null
    ) {
      if (cityId !== "") {
        getceCentre(
          `?customer_latitude=${customerLatitude}&customer_longitude=${customerLongitude}&city=${cityId}`
        );
      }
    }
  }, [customerLatitude, cityId]);

  useEffect(() => {
    if (extraCharge) {
      setTotalPrice(
        Math.floor(price - discount - discountDueSmartPackage + phleboCost - referralPointsDiscount)
      );
    } else {
      setTotalPrice(Math.floor(price - discount - discountDueSmartPackage - referralPointsDiscount));
    }
  }, [price, discount, discountDueSmartPackage, extraCharge, referralPointsDiscount]);

  useEffect(() => {
    if (totalPrice < 0) {
      setTotalPrice(0);
    }
  }, [totalPrice]);

  const applyCoupon = () => {
    if (couponCode === "") {
      // setCouponCode("");
      alert("Please select a valid coupon.");
      setCoupon(0);
      setDiscount(0);
      setTnPercentage("");
      setCouponMessage("");
      setTncMessage("");
      return false;
    }
    const appliedCoupon =
      couponList.results.find((coup: any) => coup.id === coupon) ||
      couponList.results.find((coup: any) => coup.code === couponCode);
    if (appliedCoupon && appliedCoupon.minimum_price < totalPrice) {
      setCoupon(appliedCoupon.id);
      if (appliedCoupon.discount_type.toLowerCase() === "percentage") {
        if (appliedCoupon.is_red_cash) {
          setDiscount(0);
        } else {
          setDiscount(Math.floor((price * appliedCoupon.discount) / 100));
        }
        setTnPercentage(appliedCoupon.tnc !== null ? appliedCoupon.tnc : "");
        setCouponMessage(
          `Coupon ${appliedCoupon.code}  has been applied successfully`
        );
        setTncMessage(
          `${appliedCoupon.tnc !== null ? `TNC : ${appliedCoupon.tnc}` : ""}`
        );
      }
      if (appliedCoupon.discount_type.toLowerCase() === "amount") {
        if (appliedCoupon.is_red_cash) {
          setDiscount(0);
        } else {
          setDiscount(Math.floor(appliedCoupon.discount));
        }
        setTnPercentage(appliedCoupon.tnc !== null ? appliedCoupon.tnc : "");
        setCouponMessage(
          `Coupon ${appliedCoupon.code}  has been applied successfully`
        );
        setTncMessage(
          `${appliedCoupon.tnc !== null ? `TNC : ${appliedCoupon.tnc}` : ""}`
        );
      }
    } else {
      // setCouponCode("");
      setCoupon(0);
      setDiscount(0);
      setTnPercentage("");
      setCouponMessage("");
      setTncMessage("");
      alert("Please select a valid coupon.");
      return false;
    }
  };

  // const applyCouponManually = () => {
  //   if (search.length > 0 && manualCouponCode?.length > 0) {
  //     getCouponReferralValidation(manualCouponCode, search)
  //   }
  // }

  const applyCouponPerc = () => {
    if (cValue === "") {
      // setCouponCode("");
      alert("Please select a valid coupon.");
      setCoupon(0);
      setDiscount(0);
      return false;
    }
    const appliedCoupon = { cType: cType, cValue: cValue };
    if (appliedCoupon.cValue < totalPrice) {
      if (appliedCoupon.cType === "percentage") {
        if (appliedCoupon.cValue > 100) {
          alert("Please Select Valid Number");
        }
        setDiscount(Math.floor((price * appliedCoupon.cValue) / 100));
      }
      if (appliedCoupon.cType === "amount") {
        setDiscount(Math.floor(appliedCoupon.cValue));
      }
    } else {
      // setCouponCode("");
      alert("Please select a valid coupon.");
      setCoupon(0);
      setDiscount(0);
      return false;
    }
  };
  const SelectLeaddetails = async (lead: any) => {
    if (
      lead?.id !== 0 &&
      lead?.id !== null &&
      lead?.id !== undefined &&
      lead?.id !== "undefined"
    ) {
      // setLeadIdLocationModal(lead?.id)
      selectLead(lead?.id);
      setLeadData(lead?.id);
      getCommunicationOnWhatsapp(`lead_id=${lead?.id}`);
    }
    setShowMessage(true);
  };
  const SendLocationMessage = async () => {
    let body = {
      lead_id: leadData,
      address_id: addressExeData,
      customer_whatsapp_phonenumber: customer_whatsapppnumber,
      attempt: "first",
    };
    if (
      crmPostLeadAddress?.customer_verified_latitude === null ||
      crmPostLeadAddress?.customer_verified_longitude === null ||
      crmPostLeadAddress?.customer_verified_latitude === "" ||
      crmPostLeadAddress?.customer_verified_longitude === "" ||
      crmPostLeadAddress?.customer_verified_latitude === 0 ||
      crmPostLeadAddress?.customer_verified_longitude === 0 ||
      crmPostLeadAddress?.customer_verified_latitude === undefined ||
      crmPostLeadAddress?.customer_verified_longitude === undefined
    ) {
      setApiHitCount(2);

      await SendLocationRequest(body);
    } else {
      alert("We have already customer verified address.");
    }
  };
  const HandleOpenLocation = async () => {
    setOpenLocationModal(true);
    if (leadData !== 0 && leadData !== undefined && leadData !== null) {
      getCrmLeadAddress(`lead_id=${leadData}`);
    }
  };
  const verifyMobile = (type: any) => {
    if (type === "mobile") {
      if (
        parseInt(customer_phonenumber && customer_phonenumber[0]) < 6 ||
        (customer_phonenumber && customer_phonenumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_phonenumber: false,
        }));
      }
    }
    if (type === "alternate") {
      if (
        parseInt(customer_alternatenumber && customer_alternatenumber[0]) < 6 ||
        (customer_alternatenumber && customer_alternatenumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_alternatenumber: false,
        }));
      }
    }
    if (type === "whatsapp") {
      if (
        parseInt(customer_whatsapppnumber && customer_whatsapppnumber[0]) < 6 ||
        (customer_whatsapppnumber && customer_whatsapppnumber.length < 10)
      ) {
        setMobileValidation((prev: any) => ({
          ...prev,
          customer_whatsapppnumber: false,
        }));
      }
    }
  };

  const submitForm = async (e: any) => {
    e.preventDefault();

    let packagesIds: any = [];
    inputList &&
      inputList[0]?.customerPackage.map((item: any) => {
        packagesIds.push(item.id);
      });

    let additionalPacakges: any = [];
    inputList &&
      inputList.length > 1 &&
      inputList.map((item: any, index: number) => {
        if (index > 0) {
          if (item.customerName !== item.oldNameUHID) item.customerId = "";
          additionalPacakges.push(item);
        }
      });
    const data: any = {
      center: collectionCenterId,
      is_intrested_in_imaging: readology,
      booking_date: moment(new Date()).format("YYYY-MM-DD"),
      collection_date: collection_date,
      collection_slot: selectedSlot,
      customer_name: inputList ? inputList[0]?.customerName : "",
      customer_age: inputList ? inputList[0]?.customerAge : "0",
      customer_gender: inputList ? inputList[0]?.customerGender : "",
      customerid: inputList
        ? inputList[0]?.oldNameUHID === inputList[0]?.customerName
          ? inputList[0]?.customerId
          : ""
        : "",
      book_for: inputList ? inputList[0]?.bookingFor : "",
      is_prescription: inputList ? inputList[0]?.is_prescribed : "",
      customer_aadhar: inputList ? inputList[0]?.aadhar : "",
      passport_number: inputList ? inputList[0]?.passport : "",
      customer_email: customer_email === "" ? defaultEmail : customer_email,
      customer_phonenumber: dummyValueMain
        ? lead_customer_phonenumber
        : customer_phonenumber,
      customer_altphonenumber: dummyValueAlter
        ? lead_customer_alter
        : customer_alternatenumber,
      customer_whatsapppnumber: dummyValueWhats
        ? lead_customer_whatsapppnumber
        : customer_whatsapppnumber,
      customer_address: customer_address,
      customer_landmark:
        addressObjData === "" ? customer_landmark1 : customer_landmark,
      customer_pincode: pincode,
      customer_longitude: customerLongitude,
      customer_latitude: customerLatitude,
      // customer_city: cityName,
      packages: packagesIds,
      coupon_amount: Number(cValue),
      coupon_type: cType,
      coupon_applied: coupon,
      designation: inputList ? inputList[0]?.customerDesignation : "",
      dob: inputList ? inputList[0]?.dateOfBirth : "",
      cityid: cityId,
      additional_member: additionalPacakges,
      address_line2: addressLine2,
      landmark: favoritePlace,
      is_redcash_applied: redCashCheck ? "true" : "false",
      customer_zone: zoneId,
      phlebo_remarks: phleboRemarks,
      customer_verified_longitude: customerLongitude1Modal,
      customer_verified_latitude: customerLatitude1Modal,
      address_verified: verifyKey,
      customer_booking_address: addressExeData,
      // smart_discounted_amount: discountDueSmartPackage,
    };

    // if (!bookingEnabled) {
    //   alert(
    //     "There is no sericable area or not a valid address. Please try again."
    //   );
    // }

    if (cValue === "") {
      delete data.coupon_amount;
      delete data.coupon_type;
    }
    if (data["coupon_applied"] === 0) {
      delete data["coupon_applied"];
    }
    if (data["center"] === "") {
      delete data["center"];
    }
    if (data["dob"] === "") {
      delete data["dob"];
    }
    if (
      mobileValidation.customer_alternatenumber &&
      mobileValidation.customer_phonenumber &&
      mobileValidation.customer_whatsapppnumber
    ) {
      if (
        ageCheck !== undefined ||
        data.customer_age == undefined ||
        data.customer_age === "" ||
        data.customer_age === null
      ) {
        alert("Please Provide Customer Age");
      } else if (packageCheck !== undefined) {
        alert("Customer Package can't be Blank");
      } else {
        await createPilotNewBooking(data);
        resetAddressComponent();
      }
    } else {
      if (customer_phonenumber.length !== 10) {
        setMobError("Mobile Number Must be 10 Digit");
      } else if (customer_alternatenumber.length !== 10) {
        setAltError("Alternate Number must be 10 Digit");
      } else if (customer_whatsapppnumber.length !== 10) {
        setWhatsError("Whatsapppnumber must be 10 Digit");
      }
    }
  };
  useEffect(() => {
    setMobError("");
    setAltError("");
    setWhatsError("");
  }, [
    customer_phonenumber,
    customer_alternatenumber,
    customer_whatsapppnumber,
  ]);

  useEffect(() => {
    if (createPilotBooking.pk) {
      history.push(`/dashboard/ce/booking-view/${createPilotBooking.pk}`);
      dispatch({ type: ACTIONS.CREATE_BOOKING, payload: "" });
      dispatch({ type: ACTIONS.CREATE_PILOT_BOOKING, payload: "" });
      dispatch({ type: ACTIONS.GET_PIN_CODE, payload: [] });
    }
    dispatch({ type: ACTIONS.GET_RED_COIN, payload: [] });
    dispatch({ type: ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA, payload: [] });
  }, [createPilotBooking]);

  const changeCollectionDate = (e: any) => {
    setSelectedSlot(0);
    setCollection_date(e.target.value as string);
    if (zoneId !== "") {
      getAvailableSlots(e.target.value, zoneId);
      getPackage(`city_id=${cityId}&org_type=homedx`);
      // {
      //   userDetails?.profile?.pilot_user ? (
      //     getNewPackage(`city_id=${cityId}&org_type=homedx`)
      //   ) : getPackage(`city_id=${cityId}&org_type=homedx`)
      // }
    }
  };
  const handleQuestionModel = () => {
    // setQuestionModel(true);
    const top = (window.innerHeight - 520) / 4;
    const left = (window.innerWidth - 548) / 2;

    window.open(
      "https://redcliffelabs.com/recommendation?query=admin",
      "sharer",
      `toolbar=0,status=0,width=548,height=520,top=${top}/,left=${left}`
    );
  };

  const hindiPackageModal = () => {
    const top = (window.innerHeight - 520) / 4;
    const left = (window.innerWidth - 548) / 2;

    window.open(
      "https://redcliffelabs.com/recommendation-hi?query=admin",
      "sharer",
      `toolbar=0,status=0,width=548,height=520,top=${top}/,left=${left}`
    );
  };

  const handleInputChange = (e: any, index: number) => {
    const { name, value } = e.target;
    if (name === "customerName") {
      const newValue = e.target.value;

      if (!newValue.match(/[@!#^*%&<>()_+=1234567890:~`.\\$'"]/)) {
        setError("");
      } else {
        setError("Forbidden character: @!#^*%&<>()_+=1234567890:~`.\\$'\"");
      }
    }
    if (name === "customerName") {
      const newValue = e.target.value;

      if (!newValue.match(/[@!#^*%&<>()_+=1234567890:~`.\\$'"]/)) {
        setError("");
      } else {
        setError("Forbidden character: @!#^*%&<>()_+=1234567890:~`.\\$'\"");
      }
    }
    const list = [...inputList];
    if (name === "passport") {
      const result = value.replace(/[^A-Z0-9]/, "");
      list[index][name] = result;
    } else if (name === "customerName") {
      const result = value.replace(/[^a-zA-Z ]/g, "");
      list[index][name] = result;
    } else {
      list[index][name] = value;
    }
    if (e.target.name == "designation") {
      const findGender = GENDER_CHOICE.filter(
        (el: any) => el.display_name == e.target.value
      );
      list[index]["customerGender"] = findGender[0]?.gender;
      list[index]["customerDesignation"] = findGender[0]?.db_name;
      getPackage(
        `city_id=${cityId}&org_type=homedx&gender=${findGender[0]?.gender}`
      );
      // {
      //   userDetails?.profile?.pilot_user ? (
      //     getNewPackage(`city_id=${cityId}&org_type=homedx&gender=${findGender[0]?.gender}`)
      //   ) : getPackage(`city_id=${cityId}&org_type=homedx&gender=${findGender[0]?.gender}`)
      // }
    }
    if (e.target.name == "dateOfBirth") {
      let today = new Date();
      let birthDate = new Date(e.target.value);
      let age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      list[index]["customerAge"] = age;
    }
    setInputList(list);
  };

  const handleRemoveClick = (index: any) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // const checkNormalPackage = (packages: any) => {
  //   let isNormalPackage = false;
  //   packages &&
  //     packages.length > 0 &&
  //     packages.map((item: any) => {
  //       if (!item.is_addon) {
  //         isNormalPackage = true;
  //       }
  //     });
  //   return isNormalPackage;
  // };
  // const checkBuyOneGetOneFreePackage = (index: number, packages: any) => {
  //   if (
  //     inputList &&
  //     inputList?.length == 1 &&
  //     packages &&
  //     packages?.length > 0
  //   ) {
  //     packages.map((item: any) => {
  //       if (item && item.code == "PL50" && item?.code == "PL50") {
  //         // if use select buy one get one package
  //         setInputList([
  //           ...inputList,
  //           {
  //             customerDesignation: "",
  //             designation: "",
  //             customerName: "",
  //             customerAge: "",
  //             customerGender: "",
  //             customerPackage: [item],
  //             packagePrice: item?.offer_price,
  //             dateOfBirth: "",
  //             customerId: "",
  //             is_prescribed: "",
  //             bookingFor: "",
  //             passport: "",
  //             aadhar: "",
  //           },
  //         ]);
  //       }
  //     });
  //   }
  // };

  const checkBuyOneGetOneFreePackage = (index: number, packages: any) => {
    if (
      inputList &&
      inputList?.length > 0 &&
      packages &&
      packages?.length > 0
    ) {
      packages.map((item: any) => {
        if (item && item?.no_patients !== null) {
          // if use select buy one get one package
          let obj = {
            customerDesignation: "",
            designation: "",
            customerName: "",
            customerAge: "",
            customerGender: "",
            customerPackage: [item],
            packagePrice: item?.offer_price,
            dateOfBirth: "",
            customerId: "",
            is_prescribed: "",
            bookingFor: "",
            passport: "",
            aadhar: "",
            oldNameUHID: "",
          };
          if (item?.no_patients > 1 && inputList?.length == 1) {
            for (let x = 0; x < item?.no_patients; x++) {
              setInputList([...inputList]);
              const b = JSON.parse(JSON.stringify(obj));
              inputList.push(b);
            }
            let DiscountMoneyNumber = item?.no_patients - 1;
            if (item?.package_city_prices === null) {
              let totDiscount = DiscountMoneyNumber * item?.offer_price;

              setDiscountDueSmartPackage(totDiscount);
            }
            if (item?.package_city_prices !== null) {
              let totDiscount = DiscountMoneyNumber * item?.package_city_prices;

              setDiscountDueSmartPackage(totDiscount);
            }
            // if (item?.no_patients==2) setInputList([ ...inputList, obj ]);
            // if (item?.no_patients==3) setInputList([ ...inputList, obj, obj ]);
            // if (item?.no_patients==4) setInputList([ ...inputList, obj, obj, obj ]);
            // if (item?.no_patients==5) setInputList([ ...inputList, obj, obj, obj, obj ]);
          } else if (item?.no_patients > 1 && inputList?.length > 1) {
            let x = item?.no_patients;
            let y = inputList.length;
            if (x <= y) {
              x--;
              for (let i = 0; i < item?.no_patients; i++) {
                if (x && index !== i) {
                  x--;
                  inputList[i].customerPackage.push(item);
                }
              }
            } else {
              for (let i = 0; i < y; i++) {
                if (index !== i) {
                  inputList[i].customerPackage.push(item);
                }
              }
              for (let j = y; j <= x; j++) {
                setInputList([...inputList]);
                const b = JSON.parse(JSON.stringify(obj));
                inputList.push(b);
              }
            }
            let DiscountMoneyNumber = item?.no_patients - 1;
            if (item?.package_city_prices === null) {
              let totDiscount = DiscountMoneyNumber * item?.offer_price;

              setDiscountDueSmartPackage(totDiscount);
            }
            if (item?.package_city_prices !== null) {
              let totDiscount = DiscountMoneyNumber * item?.package_city_prices;

              setDiscountDueSmartPackage(totDiscount);
            }
          }
        }
      });
    }
  };

  const checkBuyOneGetOneFreePackages = (
    index: number,
    packages: any,
    totPack: any
  ) => {
    if (
      inputList &&
      inputList?.length > 1 &&
      packages &&
      packages?.length > 0
    ) {
      let len = packages.length;
      let len1 = 1;
      packages.map((item: any) => {
        if (item && item?.no_patients !== null) {
          // if use select buy one get one package
          let obj = {
            customerDesignation: "",
            designation: "",
            customerName: "",
            customerAge: "",
            customerGender: "",
            customerPackage: [item],
            packagePrice: item?.offer_price,
            dateOfBirth: "",
            customerId: "",
            is_prescribed: "",
            bookingFor: "",
            passport: "",
            aadhar: "",
          };
          if (item?.no_patients !== null) {
            let x = item?.no_patients;
            let y = inputList.length;
            if (x <= y) {
              x--;
              for (let i = 0; i < item?.no_patients; i++) {
                if (x && index !== i) {
                  x--;
                  inputList[i].customerPackage.push(item);
                }
              }
            } else {
              for (let i = 0; i < y; i++) {
                if (index !== i) {
                  inputList[i].customerPackage.push(item);
                }
              }
              for (let j = y; j <= x; j++) {
                setInputList([...inputList]);
                inputList.push(obj);
              }
            }
          }
        }
      });
    }
  };

  const getNoofPackageBuyOneGetOne = () => {
    let noOfBuyOneGetOne = 0;
    inputList &&
      inputList.map((rowItem: any) => {
        if (rowItem?.customerPackage && rowItem?.customerPackage.length > 0) {
          rowItem?.customerPackage.map((packageItem: any) => {
            if (packageItem?.code == "PL50") {
              noOfBuyOneGetOne = noOfBuyOneGetOne + 1;
            }
          });
        }
      });
    return noOfBuyOneGetOne;
  };

  const buyOneGetOnePackagePrice = () => {
    let packagePrice = 0;
    inputList &&
      inputList.map((rowItem: any) => {
        if (rowItem?.customerPackage && rowItem?.customerPackage.length > 0) {
          rowItem?.customerPackage.map((packageItem: any) => {
            if (packageItem?.code == "PL50") {
              packagePrice = packageItem?.offer_price;
            }
          });
        }
      });
    return packagePrice;
  };

  const handlePackage = (
    index: number,
    customerPackages: any,
    lastPack: any
  ) => {
    const min_phlebo_cost =
      packageList &&
      packageList?.results &&
      packageList?.results.length > 0 &&
      packageList?.results[0].min_booking_amount_for_phlebo;
    const phlebo_cost =
      packageList &&
      packageList?.results &&
      packageList?.results.length > 0 &&
      packageList?.results[0].phlebo_cost;
    const list = [...inputList];

    list[index]["customerPackage"] = customerPackages;
    // inputList[index].customerPackage.push(lastPack)
    setInputList(list);

    let accumulatedPrice: any = 0;
    if (customerPackages && customerPackages.length > 0) {
      // let atLeastOneNormalPackage = checkNormalPackage(customerPackages);
      customerPackages.map((item: any) => {
        // if (
        //   atLeastOneNormalPackage &&
        //   item.is_addon &&
        //   item.addon_price &&
        //   item.addon_price > 0
        // ) {
        //   accumulatedPrice = accumulatedPrice + item.addon_price;
        // } else {
        if (item?.package_city_prices === null) {
          accumulatedPrice = accumulatedPrice + item.offer_price;
        } else {
          accumulatedPrice =
            accumulatedPrice + item.package_city_prices.offer_price;
        }
        // }
      });
    }
    // set price and package for row
    list[index]["packagePrice"] = accumulatedPrice;
    setInputList(list);

    // if (inputList?.length > 1 && lastPack?.no_patients!==null) {
    //   let x = lastPack?.no_patients;
    //   let y = inputList.length;
    //   if (inputList.length>=lastPack.no_patients) {

    //     x--;
    //     for ( let i=0; i<lastPack?.no_patients; i++ ) {
    //       if (x && index!==i) {
    //         x--;
    //         inputList[i].customerPackage.push(lastPack);
    //
    //       }
    //     }
    //   } else {
    //     let obj = {
    //       customerDesignation: "",
    //       designation: "",
    //       customerName: "",
    //       customerAge: "",
    //       customerGender: "",
    //       customerPackage: [lastPack],
    //       packagePrice: lastPack?.offer_price,
    //       dateOfBirth: "",
    //       customerId: "",
    //       is_prescribed: "",
    //       bookingFor: "",
    //       passport: "",
    //       aadhar: "",
    //     }
    //     for ( let i=0; i<inputList.length; i++ ) {
    //       if (index!==i) {
    //
    //         inputList[i].customerPackage.push(lastPack);

    //
    //       }
    //     }
    //     for ( let j=y; j<=x; j++ ) {

    //       setInputList([
    //         ...inputList
    //       ]);
    //       inputList.push(obj);
    //     }
    //   }
    // }

    //call function for buy one get one free
    if (inputList?.length && discountDueSmartPackage === 0) {
      // checkBuyOneGetOneFreePackage(index, customerPackages);
    }
    // else  if (inputList?.length > 1) {
    //   checkBuyOneGetOneFreePackages(index, [lastPack], customerPackages);
    // }
    let allPackagePrice = 0;
    inputList &&
      inputList.map((rowItem: any) => {
        allPackagePrice = allPackagePrice + rowItem?.packagePrice;
      });
    //Minus By One Get One Price
    let pl50Count = inputList?.length > 0 ? getNoofPackageBuyOneGetOne() : 0;
    if (pl50Count > 1 && inputList?.length > 1) {
      let noOfFreePackage = 0;
      if (pl50Count === 2) {
        noOfFreePackage = 1;
      } else if (pl50Count === 3) {
        noOfFreePackage = 1;
      } else if (pl50Count === 4) {
        noOfFreePackage = 2;
      } else if (pl50Count === 5) {
        noOfFreePackage = 2;
      } else if (pl50Count === 6) {
        noOfFreePackage = 3;
      } else {
        noOfFreePackage = 0;
      }
      if (noOfFreePackage > 0) {
        let buyOneGetOnePrice = buyOneGetOnePackagePrice();
        let freePackagepPrice = noOfFreePackage * buyOneGetOnePrice;
        allPackagePrice = allPackagePrice - freePackagepPrice;
      }
    }
    setPrice(Math.floor(allPackagePrice));
    setDiscount(0);
    setTotalPrice(Math.floor(allPackagePrice));
    setCoupon(0);
    setCouponCode("");
    setRedCashCheck(false);
    if (allPackagePrice > min_phlebo_cost) {
      setExtraCharge(false);
    } else {
      setPhleboCost(phlebo_cost);
      setExtraCharge(true);
    }
  };
  useEffect(() => {
    if (referralPointsDiscount === 0) {
      const tempPrice = totalPrice;
      const maxRedDiscount = (totalPrice / 100) * redCoin?.redcash_percentage;
      const redCashDeduct =
        maxRedDiscount - redCoin?.coins >= 0 ? redCoin?.coins : maxRedDiscount;
      if (redCashCheck) {
        setRedDisc(redCashDeduct);
      } else {
        setRedDisc(0);
      }
    } else if (referralPointsDiscount > 0) {
      const maxReferralPointsDiscount = (totalPrice / 100) * 50;
      if (referralPointsDiscount < maxReferralPointsDiscount) {
        const leftAmount = maxReferralPointsDiscount - referralPointsDiscount;
        const maxRedDiscount = (totalPrice / 100) * redCoin?.redcash_percentage;
        const redCashDeduct = maxRedDiscount - redCoin?.coins >= 0 ? redCoin?.coins : maxRedDiscount;
        if (redCashCheck) {
          if (leftAmount >= redCashDeduct) {
            setRedDisc(redCashDeduct);
          } else {
            setRedDisc(leftAmount)
          }
        } else {
          setRedDisc(0);
        }
      }
    }
  }, [redCashCheck, referralPointsDiscount]);

  // useEffect(() => {
  //   if (couponReferralValidation?.already_applied === false) {
  //     const manualCouponDiscount = (totalPrice / 100) * 30; // 30% Discount if Manual Coupon is Valid
  //     setRedDisc(0);
  //     setRedCashCheck(false);
  //     setDiscount(Math.floor(manualCouponDiscount));
  //   }
  // }, [couponReferralValidation])

  const applyReferralPoints = () => {
    const maxReferralPointsDiscount = (totalPrice / 100) * 50;
    const referralPointsDeduct = maxReferralPointsDiscount - referralPoints >= 0 ? referralPoints : maxReferralPointsDiscount;
    if (referralPoints > 0) {
      setReferralPointsDiscount(referralPointsDeduct)
    } else {
      setReferralPointsDiscount(0)
    }
  }

  const handleRedCheck = (event: any) => {
    setRedCashCheck(event.target.checked);
    setDiscount(0);
    setCouponKey(couponKey + 1);
  };
  useEffect(() => {
    if (redCashCheck === true) {
      setDiscount(0);
      setCoupon(0);
      setCouponCode("");
    } else {
      setRedDisc(0);
    }
  }, [redCashCheck])

  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        customerId: "",
        customerDesignation: "",
        designation: "",
        customerName: "",
        customerAge: "",
        customerGender: "",
        customerPackage: [],
        packagePrice: 0,
        dateOfBirth: "",
        is_prescribed: "",
        bookingFor: "",
        passport: "",
        aadhar: "",
        oldNameUHID: "",
      },
    ]);
  };
  const ageCheck = inputList.find((data: any) => data.customerAge === "");
  const packageCheck = inputList.find(
    (data: any) => data?.customerPackage.length === 0
  );

  // const handleCloseMap = () => {
  //   setMapOpen(false);
  // };

  useEffect(() => {
    if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
      const city_id = geoFencesMapMyIndia?.cityid;
      const zone_Id = geoFencesMapMyIndia?.customer_zone;
      setCustomerLatitude(geoFencesMapMyIndia?.latitude);
      setCustomerLongitude(geoFencesMapMyIndia?.longitude);
      if (city_id) {
        setCityId(city_id);
        if (Number(city_id) !== Number(cityId)) {
          setPackageKey(random);
          clearPackage();
        }
        setZoneId(zone_Id);
        // getPackage(`city_id=${city_id}&org_type=homedx`);
        // {
        //   userDetails?.profile?.pilot_user ? (
        //     getNewPackage(`city_id=${city_id}&org_type=homedx`)
        //   ) : getPackage(`city_id=${city_id}&org_type=homedx`)
        // }
      }
    }
    if (
      geoFencesMapMyIndiaForCustomer?.latitude &&
      geoFencesMapMyIndiaForCustomer?.longitude
    ) {
      setDistanceState({
        destinationLat: geoFencesMapMyIndiaForCustomer?.latitude,
        destinationLong: geoFencesMapMyIndiaForCustomer?.longitude,
        startLat: geoFencesMapMyIndia?.latitude,
        startLong: geoFencesMapMyIndia?.longitude,
      });
      setCustomerLatitude(geoFencesMapMyIndiaForCustomer?.latitude);
      setCustomerLongitude(geoFencesMapMyIndiaForCustomer?.longitude);
      const zone_Id = geoFencesMapMyIndiaForCustomer?.customer_zone;
      const city_id = geoFencesMapMyIndiaForCustomer?.cityid;
      const area_id = geoFencesMapMyIndiaForCustomer?.area_id;
      if (city_id) {
        setZoneId(zone_Id);
        setCityId(city_id);
        if (Number(city_id) !== Number(cityId)) {
          setPackageKey(random);
          clearPackage();
        }
        setCustomer_city(geoFencesMapMyIndiaForCustomer?.zone_name);
        setIsPincode(false);
        setCustomer_areapincode(pincode as string);
        setCustomer_areapincode_id(area_id as Number);
        getPackage(`city_id=${city_id}&org_type=homedx`);
        // {
        //   userDetails?.profile?.pilot_user ? (
        //     getNewPackage(`city_id=${city_id}&org_type=homedx`)
        //   ) : getPackage(`city_id=${city_id}&org_type=homedx`)
        // }
        setBookingEnabled(true);
      } else {
        setBookingEnabled(false);
        // setErrorMessage("Please try again. Zone or pin code is not enabled.");
      }
    }
    // if (geoFencesMapMyIndiaForCustomer?.latitude && geoFencesMapMyIndiaForCustomer?.longitude && geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
    //   var dis = getDistance(
    //     { latitude: geoFencesMapMyIndia?.latitude, longitude: geoFencesMapMyIndia?.longitude },
    //     { latitude: geoFencesMapMyIndiaForCustomer?.latitude, longitude: geoFencesMapMyIndiaForCustomer?.longitude },
    //   );

    //   if (Math.floor(dis / 1000) > 6) {
    //     setErrorMessage("Locality and customer address distance are more than 5 Km. Please retry with other address.")
    //   }
    // }

    // if (openLocationModal && geoFencesMapMyIndiaForCustomer?.latitude && geoFencesMapMyIndiaForCustomer?.longitude) {

    //   mapplsClassObject.initialize(accessToken, () => {
    //     mapObject = mapplsClassObject.Map({
    //       id: "map-container",
    //       properties: {
    //         center: [
    //           geoFencesMapMyIndiaForCustomer?.latitude,
    //           geoFencesMapMyIndiaForCustomer?.longitude,
    //         ],
    //         traffic: false,
    //         zoom: 15,
    //         geolocation: false,
    //         clickableIcons: false,
    //       },
    //     });
    //     markerObject = mapplsClassObject.Marker({
    //       map: mapObject,
    //       id: "map-container",
    //       position: {
    //         lat: geoFencesMapMyIndiaForCustomer?.latitude,
    //         lng: geoFencesMapMyIndiaForCustomer?.longitude,
    //       },
    //     });
    //     markerObject.setPosition({
    //       lat: geoFencesMapMyIndiaForCustomer?.latitude,
    //       lng: geoFencesMapMyIndiaForCustomer?.longitude,
    //     });
    //     markerObject.setDraggable({
    //       lat: geoFencesMapMyIndiaForCustomer?.latitude,
    //       lng: geoFencesMapMyIndiaForCustomer?.longitude,
    //     });
    //     mapObject.addListener("mousemove", function (e: any) {

    //     });
    //   });
    //   setLoading1(false);
    // }
  }, [
    geoFencesMapMyIndia,
    geoFencesMapMyIndiaForCustomer,
    customer_landmark1,
    customer_landmark,
  ]);

  //mapmyindia
  const [suggestedAddress, setSuggestedAddres] = useState<any>([]);
  const [suggestedSubAddress, setSuggestedSubAddres] = useState<any>([]);
  const [mapAddressObject, setMapAddressObject] = useState<any>({});
  const [mapSubAddressObject, setMapSubAddressObject] = useState<any>({});
  const [accessToken, setAccessToken] = useState<any>("");
  // const mapProps = {
  //   center: [customerLatitude, customerLongitude],
  //   traffic: false,
  //   zoom: 8,
  //   geolocation: false,
  //   clickableIcons: false,
  // };
  // let mapplsClassObject = new mappls();

  // let mapObject;
  // let markerObject;

  // useEffect(() => {
  //   if (geoFencesMapMyIndia?.latitude && geoFencesMapMyIndia?.longitude) {
  //     setCustomerLatitude(geoFencesMapMyIndia?.latitude);
  //     setCustomerLongitude(geoFencesMapMyIndia?.longitude);
  //   }
  // }, [geoFencesMapMyIndia]);

  useEffect(() => {
    if (mapmyindiaAccessToken?.accesstoken) {
      setAccessToken(mapmyindiaAccessToken?.accesstoken);

      setLoading1(true);
      setAccessToken(mapmyindiaAccessToken?.accesstoken);
    }
    // if (openLocationModal) {
    //   mapplsClassObject.initialize(mapmyindiaAccessToken?.accesstoken, () => {
    //     mapObject = mapplsClassObject.Map({
    //       id: "map-container",
    //       properties: mapProps,
    //     });
    //     markerObject = mapplsClassObject.Marker({
    //       map: mapObject,
    //       position: { lat: customerLatitude, lng: customerLongitude },
    //     });
    //     markerObject.setPosition({ lat: customerLatitude, lng: customerLongitude });
    //     markerObject.setDraggable({ lat: customerLatitude, lng: customerLongitude });
    //     mapObject.addListener("mousemove", function (e: any) { });
    //   });
    //   setLoading1(false);
    // }
  }, [mapmyindiaAccessToken, openLocationModal]);

  useEffect(() => {
    if (mapAddressObject?.eLoc) {
      resetAddressComponent();
      if (mapAddressObject?.addressTokens?.pincode !== "") {
        setPinCode(mapAddressObject?.addressTokens?.pincode);
        const displayAddress = `${mapAddressObject?.placeName}, ${mapAddressObject?.placeAddress}`;
        setCustomer_landmark1(displayAddress);

        checkGeoFenchMapMyIndiaPilot(mapAddressObject?.eLoc);
        setErrorMessage("");
      } else {
        setErrorMessage(
          "Please select complete locality from dorpdown with pincode"
        );
      }
    }
  }, [mapAddressObject]);
  useEffect(() => {
    if (mapSubAddressObject?.eLoc) {
      if (
        mapAddressObject?.customer_zone === mapSubAddressObject?.customer_zone
      ) {
        // resetAddressComponent();
        // setPinCode(mapAddressObject?.mapSubAddressObject?.pincode);
        const displayAddress = `${mapSubAddressObject?.placeName}, ${mapSubAddressObject?.placeAddress}`;
        //getPinCode(mapSubAddressObject?.addressTokens?.pincode);
        setCustomer_landmark(displayAddress);

        if (mapSubAddressObject?.addressTokens?.pincode !== "") {
          setErrorMessage("");
          checkGeoFenchMapMyIndiaPilotForCustomerAddress(
            mapSubAddressObject?.eLoc
          );
        } else {
          setErrorMessage(
            "Please select complete locality from dorpdown with pincode"
          );
        }
      } else {
        setErrorMessage("Zone and Customer address not match");
      }
    }
  }, [mapSubAddressObject]);

  const locationAutoCompleteSearch = (val: string, e: any) => {
    if (val.length > 2) {
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName = "jsonp_callback_" + "data" + id;
        window[callbackName] = (data: any) => {
          delete window[callbackName];
          const ele = document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          const suggestedData = data?.suggestedLocations;
          if (suggestedData.length > 0) {
            const removePincode = data?.suggestedLocations.filter(
              (data: any) => data.type !== "PINCODE"
            );
            setZoneKey(suggestedData.length);
            setSuggestedAddres(removePincode);
          }
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          document.getElementsByTagName("head")[0] ||
          document.body ||
          document.documentElement
        ).appendChild(script);
      });
    }
  };
  const locationAutoCompleteSearchForSubLocality = (val: string, e: any) => {
    if (val.length > 2) {
      const urlForMap = `https://atlas.mapmyindia.com/api/places/search/json?query=${val}&access_token=${accessToken}&tokenizeAddress=true`;
      return new Promise(function (resolve, reject) {
        const id = "_" + Math.round(10000 * Math.random());
        const callbackName = "jsonp_callback_" + "data" + id;
        window[callbackName] = (data: any) => {
          delete window[callbackName];
          const ele = document.getElementById(id);
          ele?.parentNode?.removeChild(ele);
          const suggestedData = data?.suggestedLocations;
          if (suggestedData.length > 0) {
            const removePincode = data?.suggestedLocations.filter(
              (data: any) => data.type !== "PINCODE"
            );
            setZoneKey(suggestedData.length);
            setSuggestedSubAddres(removePincode);
          }
        };
        const src = urlForMap + "&callback=" + callbackName;
        const script = document.createElement("script");
        script.src = src;
        script.id = id;
        script.addEventListener("error", reject);
        (
          document.getElementsByTagName("head")[0] ||
          document.body ||
          document.documentElement
        ).appendChild(script);
      });
    }
  };
  //UHID Start
  const [allUHIDCustomer, setAllUHIDCustomer] = useState<any>([]);
  // const [allCustomer, setAllCustomer] = useState<any>([]);
  useEffect(() => {
    if (customer_phonenumber) {
      if (
        customer_phonenumber !== undefined &&
        customer_phonenumber.length === 10
      ) {
        getRedCoing(customer_phonenumber);
      }
    }
  }, [customer_phonenumber]);

  const handleCloseUHID = () => {
    setUhidmodalOpen(false);
  };
  useEffect(() => {
    if (
      redCoin?.uniquecustomer?.length === undefined ||
      redCoin?.uniquecustomer?.length === 0
    ) {
      setUhidmodalOpen(false);
    }
  }, [customer_phonenumber, redCoin]);

  useEffect(() => {
    if (valCoin && lead_customer_phonenumber === lead_details?.mobile) {
      getRedCoing(lead_customer_phonenumber);
    }
  }, [lead_customer_phonenumber]);

  useEffect(() => {
    if (redCoin) {
      setRedCoinMessage(`You have ${redCoin.coins} Red Cash`);
      setTotalRedCoin(redCoin.coins);
    }
  }, [redCoin.coins]);

  useEffect(() => {
    const allUHID: any = [];
    if (
      redCoin &&
      redCoin?.uniquecustomer &&
      redCoin.uniquecustomer.length > 0
    ) {
      redCoin.uniquecustomer.map((data: any) => {
        allUHID.push({
          customerId: data?.id,
          lead: data?.lead,
          customerDesignation:
            data?.gender === "male" ||
              data?.gender === "Male" ||
              data?.gender === null
              ? "Mr"
              : "Ms",
          designation:
            data?.gender === "male" ||
              data?.gender === "Male" ||
              data?.gender === null
              ? "Mr"
              : "Ms",
          customerName: data?.display_name,
          customerAge: "",
          customerGender:
            data?.gender === "Male" ||
              data?.gender === "male" ||
              data?.gender === null
              ? "male"
              : data?.gender,
          customerPackage: [],
          packagePrice: 0,
          dateOfBirth: "",
          isSelected: false,
          is_prescribed: "",
          bookingFor: "",
          passport: "",
          aadhar: "",
          oldNameUHID: data?.display_name,
        });
      });

    }
    setAllUHIDCustomer(allUHID);
  }, [redCoin?.uniquecustomer]);

  useEffect(() => {    
      setUhidmodalOpen(true);

  }, [collection_date])

  useEffect(() => {
    const selectedCustomer: any = [];
    allUHIDCustomer.map((data: any, index: any) => {
      if (data.isSelected) {
        selectedCustomer.push(data);
      }
    });
    if (selectedCustomer.length !== 0) {
      setInputList(selectedCustomer);
    }
  }, [allUHIDCustomer]);

  const handleUHIDAddCustomer = (data: any, index: any) => {
    const list = [...allUHIDCustomer];
    list[index]["isSelected"] = true;
    setAllUHIDCustomer(list);
  };
  const handleUHIDRemoveCustomer = (data: any, index: any) => {
    const list = [...allUHIDCustomer];
    list[index]["isSelected"] = false;
    setAllUHIDCustomer(list);
  };
  //UHID End

  //mapmyindia
  // const handleKeyDown =(e:any) =>{
  //   e.preventDefault();
  //   if (e.key === "Delete" || e.key === "Backspace") {
  //     setbackSpaceDetcet(true)
  // }
  // else{
  //   setbackSpaceDetcet(false)
  // }
  // }
  const handleHomeCollection = () => {
    setCollectionCenterId("");
    setCollectionkey(collectionKey + 2);
    setCollectionType("homecollection");
  };
  const handleCollectionCentre = () => {
    setCollectionType("collectioncentre");
  };
  const handleCopySearch = (id: any) => {
    setSearch(id);
    let body = {
      search_by: "call_id",
      value: id,
    };
    getLeads(body);
  };
  const handleRefreshLead = () => {
    getBreakResponse(`self=true`);
  };

  const handleClickThroughLead = () => {
    setOpenM(true);
  };
  document.querySelectorAll("#noTyping").forEach((el) => {
    el.addEventListener("keydown", function (e) {
      e.preventDefault();
    });
  });

  const handleClickWhatsapp = () => {
    setOpenWhatsapp(true);
  };





  const handleAddressModel = async () => {
    setOpenLocationModal(false);
    let body = {
      lead_id: leadData,
      street_name: addressLine2,
      house_number: customer_address,
      address_line1:
        addressObjData === "" ? customer_landmark1 : saveOldAddress,
      address_line2: addressLine2,
      latitude: customerLatitude,
      longitude: customerLongitude,
      city: cityId,
    };
    if (
      apiHitCount === 0 &&
      leadData !== undefined &&
      leadData !== null &&
      leadData !== 0
    ) {
      await postCrmLeadAddress(body, leadData);
    }
    setShowMessage(true);
  };

  useEffect(() => {
    if (
      crmPostLeadAddress?.id > 0 &&
      leadData !== 0 &&
      leadData !== undefined &&
      leadData !== null
    ) {
      let data = {
        lead_id: leadData,
        address_id: crmPostLeadAddress.id,
        attempt: "first",
      }
      if (openVerifiedLocationModal === false) {
        setApiHitCount(1)
        if (apiHitCount === 0) {
          setTimeout(() => {
            getWhatsappMessageStatus(`${crmPostLeadAddress.id}`);
          }, 3500)
          SendLocationRequest(data);
        }
      }
      setAddressExeData(crmPostLeadAddress?.id);
    }
  }, [crmPostLeadAddress]);

  const [isHitReferralCurrentBalanceAPI, setIsHitReferralCurrentBalanceAPI] = useState(false);
  const [referralPoints, setReferralPoints] = useState<any>("");

  // useEffect(() => {
  //   setReferralPoints(referralCurrentBalance?.current_point)
  // }, [referralCurrentBalance])

  // useEffect(() => {
  //   if (isHitReferralCurrentBalanceAPI === true) {
  //     if (search.length > 0) {
  //       getReferralCurrentBalance(search);
  //     } else {
  //       setIsHitReferralCurrentBalanceAPI(false)
  //     }
  //   }
  // }, [isHitReferralCurrentBalanceAPI]);

  return (
    <main className={classes.content}>
      <div className={classes.contentTable}>
        <Grid container spacing={2}>




        </Grid>


      </div>
      <div className={classes.contentTable}>
        <p style={{ color: "red", fontWeight: "bold", textAlign: "right" }}>
          {geoCheckError}
        </p>
        <form onSubmit={submitForm}>
          <Grid container spacing={3} style={{ margin: "1rem auto" }}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} style={{ paddingTop: "35px" }}>
                  <p>Booking With Radiology</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Select
                    className="input"
                    name="bookingreadology"
                    variant="outlined"
                    value={readology}
                    style={{ width: "100%", margin: "0" }}
                    onChange={(e) => setReadology(e.target.value)}
                  >
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>Booking Date</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <input
                    className="input"
                    name="collection_date"
                    type="date"
                    value={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                        ? `0${new Date().getMonth() + 1}`
                        : new Date().getMonth() + 1
                      }-${new Date().getDate() < 10
                        ? `0${new Date().getDate()}`
                        : new Date().getDate()
                      }`}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "18.5px 14px",
                      height: "40px",
                    }}
                    required
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6} onMouseEnter={() => setIsHitMapAPI(true)}>
                  <p>Sample Collection date</p>
                  <p>
                    {communicationWhatsapp?.on_whatsapp === false ? (
                      <p style={{ color: "red" }}>
                        Customer Not Available on WhatsApp Please Take Alternate
                        WhatsApp Number
                      </p>
                    ) : (
                      ""
                    )}
                  </p>
                  {communicationWhatsapp?.on_whatsapp === true &&
                    crmPostLeadAddress.id > 0 ? (
                    <p style={{ color: "green" }}>
                      WhatsApp Message Sent For Location Verification
                    </p>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} md={6} onMouseEnter={() => setIsHitMapAPI(true)}>
                  <input
                    className="input"
                    name="collection_date"
                    type="date"
                    value={
                      (collection_date && collection_date.toString()) || ""
                    }
                    disabled={
                      leadData === 0 ||
                      leadData === undefined ||
                      leadData === null
                    }
                    onChange={(e) => changeCollectionDate(e)}
                    style={{
                      width: "100%",
                      margin: "0",
                      padding: "18.5px 14px",
                      height: "40px",
                    }}
                    id="noTyping"
                    max={`${new Date().getFullYear() + 1}-${new Date().getMonth() + 1 < 10
                        ? `0${new Date().getMonth() + 1}`
                        : new Date().getMonth() + 1
                      }-${new Date().getDate() < 10
                        ? `0${new Date().getDate()}`
                        : new Date().getDate()
                      }`}
                    min={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                        ? `0${new Date().getMonth() + 1}`
                        : new Date().getMonth() + 1
                      }-${new Date().getDate() < 10
                        ? `0${new Date().getDate()}`
                        : new Date().getDate()
                      }`}
                  />
                </Grid>
              </Grid>
              {!pincode ? (
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>&nbsp;</p>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ fontSize: "10px" }}
                      onClick={() => HandleOpenLocation()}
                      disabled={collection_date === ""}
                    >
                      Select Address
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      style={{ fontSize: "10px", marginLeft: "5px" }}
                      disabled={buttonDisabled || apiHitCount === 0 || leadData === 0 || leadData === undefined || leadData === null || collection_date === "" || addressExeData === ""}
                      onClick={() => {
                        setTimeout(() => setButtonDisabled(false), 300000);
                        setButtonDisabled(true)
                        SendLocationMessage()
                      }}
                    >
                      Send location Message
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <p>&nbsp;</p>
                  </Grid>
                  <Grid item xs={12} md={6} style={{ display: "flex" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ fontSize: "10px", height: "26px" }}
                      onClick={() => setOpenLocationModal(true)}
                      disabled={collection_date === ""}
                    >
                      Edit Address
                    </Button>
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ fontSize: "10px", marginLeft: "5px" }}
                        onClick={() => {
                          setTimeout(() => setButtonDisabled(false), 300000);
                          setButtonDisabled(true)
                          SendLocationMessage()
                        }}
                        disabled={buttonDisabled || apiHitCount === 0 || leadData === 0 || leadData === undefined || leadData === null || collection_date === "" || addressExeData === ""}
                      >
                        Send location Message
                      </Button>
                      {(apiHitCount > 0) ?
                        <p style={{ color: "green", alignItems: "center", paddingLeft: "20px" }}>
                          Message Sent
                        </p> : ""
                      }
                    </div>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid
                container
                spacing={0}
                style={{
                  padding: "10px",
                  background: "#e1f5fe",
                  marginLeft: "-10px",
                }}
              >
                {/* <Grid item xs={12} md={6}>
                  <p>City </p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>
                    : <strong>{cityName}</strong>
                    &nbsp;{" "}
                  </p>
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <p>Locality </p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>
                    :{" "}
                    <strong>
                      {addressObjData === ""
                        ? customer_landmark1
                        : saveOldAddress}
                    </strong>
                    &nbsp;{" "}
                  </p>
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <p>Sub Locality </p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>
                    : <strong>{customer_landmark}</strong>
                    &nbsp;{" "}
                  </p>
                </Grid> */}
                <Grid item xs={12} md={6}>
                  <p>House No./Plot No./Flat No./Door No./Shop/C/-</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>
                    : <strong>{customer_address}</strong>
                  </p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>
                    Apartment/Building/Colony/Block/Sector/
                    Street/Gali/Road/Chawl
                  </p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>
                    : <strong>{addressLine2}</strong>
                  </p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>Landmark</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>
                    : <strong>{favoritePlace}</strong>
                  </p>
                </Grid>
                {addressLine2 === "" ||
                  customer_address === "" ||
                  favoritePlace === "" ? (
                  <p style={{ color: "red", alignItems: "center" }}>
                    All Field are mandatory for Proceed booking
                  </p>
                ) : (
                  ""
                )}
                <Grid item xs={12} md={6}>
                  <p style={{ fontWeight: "700", paddingTop: "10px" }}>Location Message Status</p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>
                    {/* : <strong>{addressIdCheck === crmPostLeadAddress?.id ? locationMessageStatus : ""}</strong> */}
                    : <strong>{locationMessageStatus === null ? "Attempted" : ((locationMessageStatus !== undefined && locationMessageStatus !== "undefined") ? locationMessageStatus.toUpperCase() : "")}</strong>
                    {
                      crmPostLeadAddress?.id > 0 ?
                        <IconButton
                          onClick={() => {
                            getWhatsappMessageStatus(`${crmPostLeadAddress.id}`);
                          }}
                        >
                          <Tooltip title="Click to check a latest status" arrow>
                            <ReplayIcon
                            // style={{ color: "#924A91" }} 
                            />
                          </Tooltip>
                        </IconButton> : ""
                    }
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ margin: "1rem auto" }}>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  fullWidth
                  style={{
                    background:
                      collectionType === "homecollection" ? "#cddc39" : "",
                  }}
                  onClick={handleHomeCollection}
                >
                  Home Collection
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  variant="contained"
                  fullWidth
                  style={{
                    background:
                      collectionType === "collectioncentre" ? "#cddc39" : "",
                  }}
                  onClick={handleCollectionCentre}
                >
                  Collection Centre
                </Button>
              </Grid>
            </Grid>
            {ceCenterForCC && ceCenterForCC.length > 0 ? (
              <>
                {collectionType === "homecollection" ? (
                  ""
                ) : (
                  <Grid item xs={12} md={12} sm={12}>
                    <Autocomplete
                      id="centre"
                      key={collectionKey}
                      onChange={(event, newValue) => {
                        let leadIds: any = "";
                        if (newValue) {
                          let obj = JSON.parse(
                            JSON.stringify(newValue, null, " ")
                          );
                          leadIds = obj.id;
                        }
                        setCollectionCenterId(leadIds);
                      }}
                      options={ceCenterForCC || []}
                      freeSolo
                      blurOnSelect
                      loading={loading}
                      aria-required
                      limitTags={1}
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getCenterInfo(`?name_or_code=${newInputValue}`);
                        }, 500);
                      }}
                      getOptionLabel={(option: any) =>
                        "Centre : (" +
                        option?.center_code +
                        ") " +
                        option?.display_name +
                        " Distance : " +
                        Math.trunc(option?.distance) +
                        "KM" +
                        " Address : " +
                        option?.address
                      }
                      disableClearable
                      disableCloseOnSelect
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          label="Collection Centre"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          inputProps={{
                            ...params.inputProps,
                            onKeyDown: (e) => {
                              if (e.key === "Enter") {
                                e.stopPropagation();
                              }
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>
                )}
              </>
            ) : (
              ""
            )}
          </Grid>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            spacing={3}
            style={{ margin: "1rem auto", background: "#eff" }}
          >
            <Grid item md={3}>
              {customer_phonenumber && customer_phonenumber.length === 10 ? (
                <p style={{ fontWeight: "bold", color: "#7b1fa2" }}>
                  {redCoin?.coins ? redCoinMessage : ""}
                </p>
              ) : (
                ""
              )}
              {customer_phonenumber && customer_phonenumber.length === 10 ? (
                redCoin && redCoin?.coins && redCoin?.coins > 0 ? (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={redCashCheck}
                        onChange={handleRedCheck}
                        name="Redeem Redcash"
                      />
                    }
                    label="Redeem Redcash"
                  />
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              <p>Mobile Number</p>
              {dummyValueMain ? (
                <>
                  <TextField
                    className="input"
                    name="customer_phonenumber"
                    type="text"
                    onWheel={(e: any) => e.target.blur()}
                    disabled
                    value={dummyValue}
                    placeholder="Enter Mobile Number"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                  />
                  <p style={{ fontWeight: "bold", color: "#7b1fa2" }}>
                    {redCoin?.coins ? redCoinMessage : ""}
                  </p>
                  {redCoin && redCoin?.coins && redCoin?.coins > 0 ? (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={redCashCheck}
                          onChange={handleRedCheck}
                          name="Redeem Redcash"
                        />
                      }
                      label="Redeem Redcash"
                    />
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  <TextField
                    className="input"
                    name="customer_phonenumber"
                    type="number"
                    onWheel={(e: any) => e.target.blur()}
                    onInput={(e: any) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    value={customer_phonenumber || ""}
                    placeholder="Enter Mobile Number"
                    variant="outlined"
                    style={{ margin: "0", width: "100%" }}
                    onChange={(e) =>
                      setCustomer_phonenumber(e.target.value as String)
                    }
                    onBlur={() => verifyMobile("mobile")}
                    onFocus={() => {
                      setMobileValidation((prev: any) => ({
                        ...prev,
                        customer_phonenumber: true,
                      }));
                    }}
                    helperText={
                      !mobileValidation.customer_phonenumber &&
                      "Incorrect Phone Number"
                    }
                    required
                  />
                </>
              )}
            </Grid>
            <p style={{ color: "red" }}>{mobError}</p>
            <Grid item md={3}>
              <p>Alternate Mobile Number</p>
              {dummyValueAlter ? (
                <TextField
                  className="input"
                  name="customer_alternatenumber"
                  type="text"
                  onWheel={(e: any) => e.target.blur()}
                  disabled
                  value={dummyValue}
                  placeholder="Enter Alternate Mobile Number"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              ) : (
                <TextField
                  className="input"
                  name="customer_alternatenumber"
                  type="number"
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onWheel={(e: any) => e.target.blur()}
                  value={customer_alternatenumber}
                  placeholder="Enter Alternate Mobile Number"
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) =>
                    setCustomer_alternatenumber(e.target.value as String)
                  }
                  onBlur={() => verifyMobile("alternate")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      customer_alternatenumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.customer_alternatenumber &&
                    "Incorrect Alternate Phone Number"
                  }
                  required
                />
              )}
            </Grid>
            <p style={{ color: "red" }}>{altError}</p>
            <Grid item md={3}>
              <p>Whatsapp Number</p>
              {dummyValueWhats ? (
                <TextField
                  className="input"
                  name="customer_whatsapppnumber"
                  type="text"
                  placeholder="Enter Whatsapp Number"
                  disabled
                  value={dummyValue}
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                />
              ) : (
                <TextField
                  className="input"
                  name="customer_whatsapppnumber"
                  type="number"
                  onInput={(e: any) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  onWheel={(e: any) => e.target.blur()}
                  placeholder="Enter Whatsapp Number"
                  value={customer_whatsapppnumber || ""}
                  variant="outlined"
                  style={{ margin: "0", width: "100%" }}
                  onChange={(e) =>
                    setCustomer_whatsapppnumber(e.target.value as String)
                  }
                  required
                  onBlur={() => verifyMobile("whatsapp")}
                  onFocus={() => {
                    setMobileValidation((prev: any) => ({
                      ...prev,
                      customer_whatsapppnumber: true,
                    }));
                  }}
                  helperText={
                    !mobileValidation.customer_whatsapppnumber &&
                    "Incorrect Whatsapp Number"
                  }
                />
              )}
            </Grid>
            <p style={{ color: "red" }}>{whatsError}</p>
            <Grid item md={3}>
              <p>Email</p>
              <TextField
                className="input"
                name="customer_email"
                type="email"
                placeholder="customer.reports@redcliffelabs.com"
                value={customer_email}
                variant="outlined"
                style={{ margin: "0", width: "100%" }}
                onChange={(e) => setCustomer_email(e.target.value as String)}
              />
            </Grid>
            <Grid container spacing={2} style={{ display: "flex" }}>
              <Grid item xs={12} sm={6} md={3}></Grid>
              {lead_customer_alter !== "" ? (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  style={{ paddingLeft: "20px" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={changeAlternate}
                        onChange={(e: any) =>
                          handleChangeAlternate(e, "changeAlternate")
                        }
                        name="altNumChange"
                      />
                    }
                    label="Change Alternate Number"
                  />
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  style={{ paddingLeft: "20px" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sameAlternate}
                        disabled={
                          customer_phonenumber === "" &&
                          dummyValueMain === false
                        }
                        onChange={(e: any) =>
                          handleSameAlternate(e, "sameAlternate")
                        }
                        name="altCheck"
                      />
                    }
                    label="Same Alternate Number"
                  />
                </Grid>
              )}
              {lead_customer_whatsapppnumber !== "" ? (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  style={{ paddingLeft: "20px" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={changeWhatsapp}
                        onChange={(e: any) =>
                          handleChangeWhatsapp(e, "changeWhatsapp")
                        }
                        name="whatsappNumChange"
                      />
                    }
                    label="Change Whatsapp Number"
                  />
                </Grid>
              ) : (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  style={{ paddingLeft: "20px" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sameWhatsapp}
                        onChange={(e: any) =>
                          handleSameWhatsapp(e, "sameWhatsapp")
                        }
                        disabled={
                          customer_phonenumber === "" &&
                          dummyValueMain === false
                        }
                        name="whatsCheck"
                      />
                    }
                    label="Same Whatsapp Number"
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={3}></Grid>
            </Grid>
          </Grid>
          {inputList.map((x: any, index: number) => {
            return (
              <>
                <p>{x.customerId !== "" ? "UHID :" + x.customerId : ""}</p>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  style={{ margin: "1rem auto", background: "#e1f5fe" }}
                >
                  <Grid item md={1}>
                    {inputList.length !== 1 && (
                      <HighlightOffIcon
                        style={{
                          marginBottom: "1rem",
                          height: "40px",
                          width: "40px",
                          borderRadius: "50%",
                        }}
                        onClick={() => handleRemoveClick(index)}
                      />
                    )}
                    {inputList.length - 1 === index && inputList.length < 5 && (
                      <AddCircleIcon
                        style={{
                          marginBottom: "1rem",
                          height: "40px",
                          width: "40px",
                          borderRadius: "50%",
                        }}
                        onClick={handleAddClick}
                      />
                    )}
                  </Grid>
                  <Grid item md={2}>
                    <p>Title</p>
                    <Select
                      className="input"
                      name="designation"
                      variant="outlined"
                      value={x.designation}
                      style={{ width: "100%", margin: "0", height: "40px" }}
                      onChange={(e) => handleInputChange(e, index)}
                      required
                    >
                      <MenuItem value="Mr">Mr</MenuItem>
                      <MenuItem value="Ms">Ms</MenuItem>
                      <MenuItem value="Baby Boy">Baby Boy</MenuItem>
                      <MenuItem value="Baby Girl">Baby Girl</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item md={2}>
                    <p>Customer Name</p>
                    <TextField
                      className="input"
                      name="customerName"
                      type="text"
                      placeholder="Enter Name"
                      value={x.customerName || ""}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ width: "100%", margin: "0" }}
                      helperText={error} // error message
                      error={!!error}
                      onChange={(e) => handleInputChange(e, index)}
                      required
                    />
                  </Grid>
                  <Grid item md={2}>
                    <p>DOB</p>
                    <input
                      className="input"
                      name="dateOfBirth"
                      type="date"
                      value={x.dateOfBirth}
                      onChange={(e) => handleInputChange(e, index)}
                      min={`${new Date().getFullYear() - 150}-${new Date().getMonth() + 1 < 10
                          ? `0${new Date().getMonth() + 1}`
                          : new Date().getMonth() + 1
                        }-${new Date().getDate() < 10
                          ? `0${new Date().getDate()}`
                          : new Date().getDate()
                        }`}
                      max={`${new Date().getFullYear()}-${new Date().getMonth() + 1 < 10
                          ? `0${new Date().getMonth() + 1}`
                          : new Date().getMonth() + 1
                        }-${new Date().getDate() < 10
                          ? `0${new Date().getDate()}`
                          : new Date().getDate()
                        }`}
                      style={{
                        width: "100%",
                        padding: "15px 14px",
                        background: "#eff",
                        height: "40px",
                      }}
                    />
                  </Grid>
                  <Grid item md={1}>
                    <p>Age</p>
                    <TextField
                      className="input"
                      name="customerAge"
                      type="number"
                      placeholder="Enter Age"
                      value={x.customerAge}
                      disabled={x.dateOfBirth !== ""}
                      variant="outlined"
                      style={{ width: "100%", margin: "0" }}
                      inputProps={{ min: 0, max: 100 }}
                      onChange={(e) => {
                        let ageValue = parseInt(e.target.value);
                        if (ageValue > 150) ageValue = 150;
                        if (ageValue < 0) ageValue = 0;
                        e.target.value = ageValue.toString();
                        handleInputChange(e, index);
                      }}
                    />
                  </Grid>
                  <Grid item md={1}>
                    <p>Gender</p>
                    <TextField
                      className="input"
                      name="customerGender"
                      variant="outlined"
                      value={
                        x.customerGender === "male"
                          ? "Male"
                          : x.customerGender === "female"
                            ? "Female"
                            : ""
                      }
                      style={{ width: "100%", margin: "0", height: "40px" }}
                      onChange={(e) => handleInputChange(e, index)}
                      disabled={true}
                      required={true}
                    />
                    <p style={{ color: "red" }}>
                      {inputList[0]?.customerGender === null
                        ? "Please select gender"
                        : ""}
                    </p>
                  </Grid>
                  <Grid item md={3}>
                    <p>Package Name</p>
                    <Autocomplete
                      id="customerPackage"
                      key={packageKey}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let packs: any = [];
                          newValue.map((val: any) => {
                            let obj = JSON.parse(
                              JSON.stringify(val, null, " ")
                            );
                            packs.push(obj);
                          });
                          let len = packs.length;
                          handlePackage(index, packs, packs[len - 1]);
                        }
                        setCValue("")
                      }}
                      multiple
                      loading={geoLoading}
                      disableClearable
                      options={packageList?.results || []}
                      limitTags={0}
                      // disabled={is_pincode}
                      freeSolo
                      disableCloseOnSelect
                      aria-required
                      onInputChange={(event, newInputValue) => {
                        clearTimeout(timer.current);
                        timer.current = setTimeout(() => {
                          getPackage(
                            `city_id=${cityId}&search=${newInputValue}&org_type=homedx${x?.customerGender ? `&${x?.customerGender}` : ""
                            }`
                          );
                          // {
                          //   userDetails?.profile?.pilot_user ? (
                          //     getNewPackage(`city_id=${cityId}&search=${newInputValue}&org_type=homedx${x?.customerGender ? `&gender=${x?.customerGender}` : ""}`)
                          //   ) : getPackage(`city_id=${cityId}&code=${newInputValue}&org_type=homedx${x?.customerGender ? `&gender=${x?.customerGender}` : ""}`)
                          // }
                        }, 1000);

                      }}
                      getOptionLabel={(option: any) => {
                        return option?.name && option?.package_city_prices
                          ? option?.name.toString() +
                          ", " +
                          option?.package_city_prices?.tat_time +
                          ", Rs. " +
                          option?.package_city_prices?.offer_price +
                          "/-"
                          : option?.name +
                          ", " +
                          option?.tat_time +
                          ", Rs. " +
                          option?.offer_price +
                          "/-";
                      }}
                      getOptionDisabled={(option: any) => {
                        const packID = x?.customerPackage.map(
                          (pack: any) => pack?.id
                        );
                        return packID.includes(option?.id);
                      }}
                      renderOption={(option, { selected }) => (
                        <React.Fragment>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option?.package_city_prices
                            ? option?.name.toString() +
                            ", " +
                            option?.package_city_prices?.tat_time +
                            ", Rs. " +
                            option?.package_city_prices?.offer_price +
                            "/-"
                            : option?.name.toString() +
                            ", " +
                            option?.tat_time +
                            ", Rs." +
                            option?.offer_price +
                            "/-"}
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          placeholder="Package"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                          inputProps={{
                            ...params.inputProps,
                            onKeyDown: (e) => {
                              if (e.key === "Enter") {
                                e.stopPropagation();
                              }
                            },
                          }}
                        />
                      )}
                    />
                    <p style={{ color: "red" }}>
                      {geoCheckError !== ""
                        ? "Please select correct location"
                        : ""}
                    </p>
                  </Grid>
                  <Grid item md={2} style={{ marginLeft: "7rem" }}>
                    <p>Passport Number</p>
                    <TextField
                      className="input"
                      name="passport"
                      type="text"
                      placeholder="Enter Passport Number"
                      value={x.passport || ""}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ width: "100%", margin: "0" }}
                      onInput={(e: any) => {
                        e.target.value = e.target.value.toString().slice(0, 10);
                      }}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </Grid>
                  <Grid item md={2}>
                    <p>Aadhar Number</p>
                    <TextField
                      className="input"
                      name="aadhar"
                      type="number"
                      onWheel={(e: any) => e.target.blur()}
                      onInput={(e: any) => {
                        e.target.value = Math.max(0, parseInt(e.target.value))
                          .toString()
                          .slice(0, 12);
                      }}
                      placeholder="Enter Aadhar Number"
                      value={x.aadhar || ""}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      style={{ width: "100%", margin: "0" }}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </Grid>
                  <Grid item md={3}>
                    <p>Booking For</p>
                    <Select
                      className="input"
                      name="bookingFor"
                      variant="outlined"
                      value={x.bookingFor}
                      style={{ width: "100%", margin: "0", height: "40px" }}
                      required
                      onChange={(e) => handleInputChange(e, index)}
                    >
                      <MenuItem value="self">Self</MenuItem>
                      <MenuItem value="father_in_law">
                        Father/Father in law
                      </MenuItem>
                      <MenuItem value="mother_in_law">
                        Mother/Mother in law
                      </MenuItem>
                      <MenuItem value="son_in_law">Son/Son in law</MenuItem>
                      <MenuItem value="daughter_in_law">
                        Daughter/Daughter in law
                      </MenuItem>
                      <MenuItem value="spouse">Spouse</MenuItem>
                      <MenuItem value="friend">Friend</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item md={3}>
                    <p>Is Prescribed</p>
                    <Select
                      className="input"
                      name="is_prescribed"
                      variant="outlined"
                      value={x.is_prescribed}
                      style={{ width: "100%", margin: "0", height: "40px" }}
                      onChange={(e) => handleInputChange(e, index)}
                    >
                      <MenuItem value="true">Yes</MenuItem>
                      <MenuItem value="false">No</MenuItem>
                    </Select>
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      marginBottom: "1rem",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        margin: "auto 10px auto 0",
                      }}
                    >
                      Recommended Package:
                    </p>
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleQuestionModel}
                      >
                        Ask question in English
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={hindiPackageModal}
                      >
                        Ask question in Hindi
                      </Button>
                    </div>
                  </div>
                </Grid>
              </>
            );
          })}
          <Grid item md={2}>
            <p style={{ fontWeight: "bold" }}>Phlebo Remarks</p>
            <textarea
              className="input"
              name="phlebo_remarks"
              placeholder="Enter Phlebo Remakrs"
              value={phleboRemarks}
              style={{ width: "100%", margin: "0" }}
              onChange={(e: any) => setPhleboRemarks(e.target.value)}
            />
          </Grid>
          <Grid
            container
            spacing={3}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ margin: "1rem auto" }}
          >
            <Grid item xs={12} md={5}>
              <Card
                className="sampleCollectionTimeNewBooking"
                style={{ overflow: "auto", minHeight: "500px" }}
              >
                <p style={{ marginBottom: "1rem" }}>Sample Collection Time</p>
                {geoLoading ? (
                  <Loader />
                ) : (
                  <div className={classes.slotContainer}>
                    {collectionType === "collectioncentre"
                      ? timeslots &&
                      timeslots?.results &&
                      timeslots.results.length > 0 &&
                      timeslots.results.map((slot: any) => {
                        return (
                          <Card
                            key={slot.id}
                            className={
                              slot.id === selectedSlot
                                ? classes.selectedSlot
                                : classes.slot
                            }
                            onClick={() => setSelectedSlot(slot.id as Number)}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                color: "black",
                                fontSize: "14px",
                              }}
                            >
                              {slot.start_time}-{slot.end_time}
                            </p>
                            <p
                              style={{ textAlign: "center", color: "green" }}
                            ></p>
                          </Card>
                        );
                      })
                      : timeSlots &&
                      timeSlots.length > 0 &&
                      timeSlots.map((slot: any) => {
                        return slot.available_slots > 0 ? (
                          <Card
                            key={slot.id}
                            className={
                              slot.id === selectedSlot
                                ? classes.selectedSlot
                                : classes.slot
                            }
                            onClick={() => setSelectedSlot(slot.id as Number)}
                          >
                            <p
                              style={{
                                textAlign: "center",
                                color: "black",
                                fontSize: "14px",
                              }}
                            >
                              {slot.start_time1hr}-{slot.end_time1hr}
                            </p>
                            <p
                              style={{ textAlign: "center", color: "green" }}
                            >
                              {" "}
                              Available slots: {slot.available_slots}
                            </p>
                          </Card>
                        ) : (
                          <Card
                            key={slot.id}
                            className={
                              slot.id === selectedSlot
                                ? classes.selectedSlot
                                : classes.slot
                            }
                            onClick={() =>
                              alert("No Available Slot for this Area")
                            }
                          >
                            <p
                              style={{
                                textAlign: "center",
                                color: "black",
                                fontSize: "14px",
                              }}
                            >
                              {slot.start_time1hr}-{slot.end_time1hr}
                            </p>
                            <p
                              style={{ textAlign: "center", color: "green" }}
                            >
                              {" "}
                              Available slots: {slot.available_slots}
                            </p>
                          </Card>
                        );
                      })}
                    {timeSlots !== undefined && timeSlots.length === 0 ? (
                      <p style={{ color: "red" }}>
                        "Time Slot is not Active in this Area"
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </Card>
            </Grid>
            <Grid item xs={12} md={7} style={{ padding: "10px" }}>
              <div className="price-details-new-booking">
                <table style={{ width: "100%", margin: "0" }}>
                  <thead>
                    <tr>
                      <td className="cell" align="center">
                        S No.
                      </td>
                      <td className="cell" align="center">
                        Name
                      </td>
                      <td className="cell" align="center">
                        Package
                      </td>
                      <td className="cell" align="center">
                        Price
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {inputList &&
                      inputList.length > 0 &&
                      inputList.map((customerDetails: any, index: number) => {
                        return (
                          <tr>
                            <td className="cell" align="center">
                              {index + 1}
                            </td>
                            <td className="cell" align="left">
                              {customerDetails.designation}{" "}
                              {customerDetails.customerName}
                            </td>
                            <td className="cell" align="left">
                              {customerDetails?.customerPackage &&
                                customerDetails?.customerPackage.length > 0 &&
                                customerDetails?.customerPackage.map(
                                  (packageItem: any, index: any) => {
                                    return (
                                      <ul>
                                        <li>
                                          <a
                                            onClick={() => {
                                              setSinglePackage(packageItem);
                                              setPaneOpen(true);
                                            }}
                                            className={classes.packageLink}
                                          >
                                            {packageItem?.name}
                                            <br /> DESCRIPTION :{" "}
                                            {packageItem?.description}
                                            <br />
                                            TaT :{" "}
                                            {packageItem?.package_city_prices
                                              ? packageItem?.package_city_prices
                                                ?.tat_time
                                              : packageItem?.tat_time}
                                            <br />
                                            Price :{" "}
                                            {packageItem?.package_city_prices
                                              ? packageItem?.package_city_prices
                                                ?.offer_price
                                              : packageItem?.offer_price}
                                            <br />
                                            Fasting :{" "}
                                            {packageItem?.fasting_time}
                                          </a>
                                        </li>
                                      </ul>
                                    );
                                  }
                                )}
                            </td>
                            <td className="cell" align="center">
                              ₹ {customerDetails?.packagePrice}
                            </td>
                          </tr>
                        );
                      })}
                    <tr>
                      <td colSpan={4}>
                        <table style={{ width: "100%" }}>
                          <tr>
                            <td
                              className="cell"
                              align="left"
                              rowSpan={5}
                              colSpan={2}
                              style={{ width: "60%" }}
                              valign="top"
                            >
                              {userDetails?.profile?.executive_type ===
                                "CoCal" ||
                                userDetails?.profile?.executive_type === "LTV" ? (
                                <Grid container spacing={1} direction="row">
                                  <Grid item xs={12}>
                                    <p>Manage Coupon</p>
                                  </Grid>

                                  <Grid item xs={6}>
                                    <TextField
                                      name="area"
                                      value={cValue}
                                      className="input"
                                      variant="outlined"
                                      label="Percentage"
                                      onChange={(e) =>
                                        setCValue(e.target.value)
                                      }
                                      fullWidth
                                      size="small"
                                      select
                                      disabled={price <= 0}
                                    >
                                      <MenuItem value={""}>
                                        none
                                      </MenuItem>
                                      {Array.from(Array(Number(price) < 400 ? 10 : 15).keys()).map(
                                        (val: any) => {
                                          return (

                                            <MenuItem value={val + 1}>
                                              {val + 1}
                                            </MenuItem>
                                          );
                                        }
                                      )}
                                    </TextField>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Button
                                      variant="contained"
                                      fullWidth
                                      color="primary"
                                      disabled={redCashCheck === true}
                                      onClick={() => applyCouponPerc()}
                                    >
                                      Apply
                                    </Button>
                                  </Grid>
                                  {redCashCheck === true ? <p style={{ color: "black", fontSize: "12px", paddingLeft: "4px" }}>Oops! Coupon Can't be applied as you are using RedCash.</p> : ""}
                                  <p
                                    style={{
                                      color: "#8e24aa",
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                      alignItems: "center",
                                    }}
                                  >
                                    {couponMessage} <br />
                                    {tncMessage}
                                  </p>
                                </Grid>
                              ) : (
                                <Grid container spacing={1} direction="row" onMouseEnter={() => setIsHitCouponAPI(true)}>
                                  <Grid item xs={12}>
                                    <p>Enter Coupon</p>
                                  </Grid>
                                  <Grid item xs={8}>
                                    <Autocomplete
                                      id="coupon"
                                      key={couponKey}
                                      onChange={(event, newValue) => {
                                        if (newValue) {
                                          let obj = JSON.parse(
                                            JSON.stringify(newValue, null, " ")
                                          );
                                          setCoupon(obj?.id as Number);
                                          setCouponCode(obj?.code as string);
                                          // setCouponType(obj.is_red_cash);
                                          setRedCouponDiscount(
                                            obj?.discount as number
                                          );
                                        }
                                      }}
                                      inputValue={couponCode}
                                      options={couponList?.results || []}
                                      freeSolo
                                      blurOnSelect
                                      disabled={redCashCheck === true}
                                      onInputChange={(event, newInputValue) => {
                                        clearTimeout(timer.current);
                                        setCouponCode(newInputValue);
                                        timer.current = setTimeout(() => {
                                          getCoupons(
                                            `?code=${newInputValue}&org_type=homedx`
                                          );
                                        }, 1000);
                                        if (newInputValue === "") {
                                          setCoupon(0);
                                          setDiscount(0);
                                          setTncMessage("");
                                          setCouponMessage("");
                                        }
                                      }}
                                      getOptionLabel={(option: any) =>
                                        option?.code
                                      }
                                      getOptionDisabled={(option: any) =>
                                        option?.minimum_price > totalPrice
                                      }
                                      renderInput={(params) => (
                                        <TextField
                                          className="input"
                                          {...params}
                                          placeholder="Enter Coupon"
                                          variant="outlined"
                                          style={{
                                            width: "100%",
                                            margin: "0",
                                            padding: "0",
                                          }}
                                          inputProps={{
                                            ...params.inputProps,
                                            onKeyDown: (e) => {
                                              if (e.key === 'Enter') {
                                                e.stopPropagation();
                                              }
                                            },
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Button
                                      variant="contained"
                                      fullWidth
                                      color="primary"
                                      disabled={redCashCheck === true}
                                      onClick={applyCoupon}
                                    >
                                      Apply
                                    </Button>
                                  </Grid>
                                  {redCashCheck === true ? <p style={{ color: "black", fontSize: "12px", paddingLeft: "4px" }}>Oops! Coupon Can't be applied as you are using RedCash.</p> : ""}
                                  <p
                                    style={{
                                      color: "#8e24aa",
                                      fontSize: "14px",
                                      fontWeight: "bold",
                                      alignItems: "center",
                                    }}
                                  >
                                    {couponMessage} <br />
                                    {tncMessage}
                                  </p>
                                </Grid>
                              )}
                            </td>
                            <td
                              className="cell"
                              align="right"
                              style={{ width: "20%" }}
                            >
                              Discount
                            </td>
                            <td
                              className="cell"
                              align="center"
                              style={{ width: "20%" }}
                            >
                              ₹ {Math.floor(discount)}
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="cell"
                              align="right"
                              style={{ width: "20%" }}
                            >
                              Smart Package Discount
                            </td>
                            <td
                              className="cell"
                              align="center"
                              style={{ width: "20%" }}
                            >
                              ₹{" "}
                              {discountDueSmartPackage > 0
                                ? Math.floor(discountDueSmartPackage)
                                : 0}
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="cell"
                              align="right"
                              style={{ width: "20%" }}
                            >
                              Referral Points Discount
                            </td>
                            <td
                              className="cell"
                              align="center"
                              style={{ width: "20%" }}
                            >
                              ₹{" "}
                              {referralPoints !== undefined
                                ? Math.floor(referralPointsDiscount)
                                : 0}
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="cell"
                              align="right"
                              style={{ width: "20%" }}
                            >
                              RedCash Discount
                            </td>
                            <td
                              className="cell"
                              align="center"
                              style={{ width: "20%" }}
                            >
                              ₹ {redDisc > 0 ? Math.floor(redDisc) : 0}
                            </td>
                          </tr>
                          <tr>
                            <td className="cell" align="right">
                              Phlebo Cost
                            </td>
                            <td className="cell" align="center">
                              ₹ {extraCharge ? `+${phleboCost}` : "0"}
                            </td>
                          </tr>
                          <tr>
                            <td className="cell" align="right">
                              <h4 className="totalPrice">Total Price</h4>
                            </td>
                            <td className="cell" align="center">
                              <h4 className="totalPrice">
                                ₹{" "}
                                {redDisc > 0
                                  ? Math.floor(totalPrice) - Math.floor(redDisc)
                                  : Math.floor(totalPrice)}
                              </h4>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Grid>
          </Grid>
          <div className={classes.buttonContainer}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              style={{ marginLeft: "1rem", marginBottom: "20px" }}
              disabled={
                loading ||
                selectedSlot === 0 ||
                sameNumberBooking === true ||
                addressLine2 === "" ||
                customer_address === "" ||
                favoritePlace === "" ||
                !singlePackage ||
                error !== "" ||
                // is_pincode ||
                inputList[0]?.customerGender === null ||
                !mobileValidation.customer_phonenumber ||
                !mobileValidation.customer_alternatenumber ||
                !mobileValidation.customer_whatsapppnumber ||
                leadAddressLoading
              }
            >
              Proceed for booking
            </Button>
            <Dialog
              open={uhidModalOpen}
              // onClose={handleCloseUHID}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>UNIQUE CUSTOMER</div>
                  <div>
                    <CloseIcon onClick={() => handleCloseUHID()} />
                  </div>
                </div>
              </DialogTitle>
              <DialogTitle id="alert-dialog-title">
                {inputList.length === 5 ? "Member Add Limit is 4 only" : ""}
              </DialogTitle>
              <DialogContent>
                <TableContainer className={classes.table}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell align="center">
                          Lead ID
                        </StyledTableCell>
                        <StyledTableCell align="center">Name</StyledTableCell>
                        <StyledTableCell align="center">Gender</StyledTableCell>
                        <StyledTableCell align="center">Action</StyledTableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {allUHIDCustomer &&
                        allUHIDCustomer.length > 0 &&
                        allUHIDCustomer.map((data: any, index: any) => {
                          return (
                            <StyledTableRow key={index}>
                              <StyledTableCell align="center">
                                {data.lead || ""}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data.customerName || ""}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data.customerGender || ""}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {data.isSelected ? (
                                  ""
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    disabled={inputList.length === 5}
                                    onClick={() =>
                                      handleUHIDAddCustomer(data, index)
                                    }
                                  >
                                    Add
                                  </Button>
                                )}

                                {data.isSelected ? (
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() =>
                                      handleUHIDRemoveCustomer(data, index)
                                    }
                                  >
                                    Remove
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </DialogContent>
            </Dialog>
          </div>

          {/* Location Map Modal  */}
          <Dialog
            open={openLocationModal}
            // onClose={() => setOpenLocationModal(false)}
            aria-labelledby="form-dialog-title"
            className={classes.dialog}
          >
            <DialogTitle id="form-dialog-title">Add New Address</DialogTitle>
            <DialogContent>
              <Grid
                container
                spacing={2}
                style={{
                  margin: "0px 10px 10px 10px",
                  minWidth: "500px",
                  width: "70%",
                }}
              >
                {/* <Grid item xs={12} md={12}>
                  <p>{CITY}</p>
                  <Autocomplete
                    id="cityname"
                    onChange={(event, newValue) => {
                      let cName: any = "";
                      if (newValue) {
                        let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                        cName = obj
                      }
                      setCityName(cName);

                    }}
                    options={displayCities.cities || []}
                    freeSolo
                    blurOnSelect
                    aria-required
                    disabled={collection_date === ""}
                    limitTags={1}
                    getOptionLabel={(option: any) => option}
                    disableClearable
                    disableCloseOnSelect
                    defaultValue={cityName}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        variant="outlined"
                        placeholder="Search City"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                      />
                    )}
                  />
                </Grid> */}
                {crmLeadAddress?.results?.length > 0 ? (
                  <>
                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        id="address"
                        onChange={(event, newValue) => {
                          if (newValue) {
                            let obj = JSON.parse(
                              JSON.stringify(newValue, null, " ")
                            );
                            // setAddressLine2(obj?.address_line1)
                            setMapAddressObject(obj?.address_line1);
                            setCustomer_address(obj?.house_number);
                            setCustomer_landmark(obj?.address_line1);
                            setAddressLine2(obj?.street_name);
                            setFavoritePlace(obj?.address_line1);
                            setAddressExeData(obj?.id);
                            setCityId(obj?.city?.id);
                            if (Number(obj?.city?.id) !== Number(cityId)) {
                              setPackageKey(random);
                              clearPackage();
                            }
                            setZoneId(obj?.city?.customer_zone);
                            setAddressObjData(obj);
                            setCustomerLatitude(obj?.latitude);
                            setCustomerLongitude(obj?.longitude);
                            setSaveOldAddress(obj?.address_line1);
                          }
                        }}
                        options={crmLeadAddress?.results || []}
                        freeSolo
                        blurOnSelect
                        aria-required
                        getOptionLabel={(option: any) => {
                          if (option?.address_line1) {
                            return option?.address_line1;
                          }
                          return "";
                        }}
                        onInputChange={(event, newInputValue) => {
                          clearTimeout(timer.current);
                          timer.current = setTimeout(() => {
                            getCrmLeadAddress(
                              `lead_id=${leadData}&search=${newInputValue}`
                            );
                          }, 1000);
                          if (newInputValue.length === 0) {
                            setAddressExeData(0);
                            setAddressObjData("");
                            setAddressLine2("");
                            setCustomer_address("");
                            // setAddressLine2("")
                            setFavoritePlace("");
                            setCustomer_landmark("");
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            className="input"
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <InputAdornment position="end">
                                  <ArrowDropDownIcon />
                                </InputAdornment>
                              ),
                            }}
                            label="Select  Address"
                            variant="outlined"
                            style={{ width: "100%", margin: "0", padding: "0" }}
                          />
                        )}
                      />
                    </Grid>
                  </>
                ) : (
                  ""
                )}
                {/* <Grid item xs={12} md={12}>
                  {loading1 ? (
                    <Loader />
                  ) : (
                    <div
                      key={customerLatitude}
                      id="map-container"
                      className={classes.mapCSS}
                    ></div>
                  )}
                </Grid> */}
                <Grid item xs={12} md={12}>
                  <p>{LOCALITY}</p>
                  <Autocomplete
                    id="customer_landmark1"
                    key={saveOldAddress}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        let obj = JSON.parse(
                          JSON.stringify(newValue, null, " ")
                        );
                        setMapAddressObject(obj);
                      }
                    }}
                    options={suggestedAddress ? suggestedAddress : []}
                    freeSolo
                    aria-required
                    // value={customer_landmark1 !== "" ?? {placeName: customer_landmark1}}
                    defaultValue={{ placeName: saveOldAddress }}
                    getOptionLabel={(option: any) =>
                      `${option?.placeName}${option?.placeAddress
                        ? String("," + option?.placeAddress)
                        : ""
                      }`
                    }
                    disabled={
                      collection_date === "" ||
                      Object.keys(addressObjData).length !== 0
                    }
                    filterOptions={(option: any) => option}
                    onInputChange={(event, newInputValue) => {
                      clearTimeout(timer.current);
                      timer.current = setTimeout(() => {
                        locationAutoCompleteSearch(newInputValue, event);
                      }, 500);
                    }}
                    // onKeyDown={(e)=>handleKeyDown(e)}
                    renderInput={(params) => (
                      <TextField
                        className="input"
                        {...params}
                        placeholder="Search Locality"
                        variant="outlined"
                        style={{ width: "100%", margin: "0", padding: "0" }}
                        //
                        value={saveOldAddress}
                        // disabled={true}
                        required
                        inputProps={{
                          ...params.inputProps,
                          onKeyDown: (e) => {
                            if (e.key === "Enter") {
                              e.stopPropagation();
                            }
                          },
                        }}
                      />
                    )}
                  />
                  <p style={{ color: "red" }}>{errorMessage}</p>
                </Grid>
                {/* <Grid item xs={12} md={12}>
                <p>{SUBLOCALITY}</p>
                <Autocomplete
                  id="customer_landmark"
                  key={1}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      let obj = JSON.parse(
                        JSON.stringify(newValue, null, " ")
                      );
                      setMapSubAddressObject(obj);
                    }
                  }}
                  options={suggestedSubAddress ? suggestedSubAddress : []}
                  freeSolo
                  aria-required
                  disabled={collection_date === "" ||Object.keys(addressObjData).length!==0}
                  getOptionLabel={(option: any) =>
                    `${option?.placeName}, ${option?.placeAddress}`
                  }
                  filterOptions={(option: any) => option}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timer.current);
                    timer.current = setTimeout(() => {
                      locationAutoCompleteSearchForSubLocality(newInputValue, event);
                    }, 500);
                  }}
                  // onKeyDown={(e)=>handleKeyDown(e)}
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      placeholder={`Search ${SUBLOCALITY}`}
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                     
                      required
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (e) => {
                          if (e.key === 'Enter') {
                            e.stopPropagation();
                          }
                        },
                      }}
                    />
                  )}
                />
                <p style={{ color: "red" }}>{errorMessage}</p>
              </Grid> */}
                {/* </>
                }
                */}

                {/* <Grid item xs={12} md={6}>
                  <p>Area Name</p>
                  
                  <Autocomplete
                      id="areaname"
                      key={zoneKey}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                          setCustomer_areapincode(obj.id);
                          setZoneId(obj?.zone_data.id)
                          setZoneName(obj?.area)
                        }
                        
                      }}
                      options={pin_code.results|| []}
                      freeSolo
                      blurOnSelect
                      aria-required
                      limitTags={1}
                    //      onInputChange={(event, newInputValue) => {
                    //   clearTimeout(timer.current);
                    //   timer.current = setTimeout(() => {
                    //     getCenterInfo(`?name_or_code=${newInputValue}`);
                    //   }, 500);
                    // }}
                      getOptionLabel={(option: any) =>option?.area}
                      disableClearable
                      disableCloseOnSelect
                      renderInput={(params) => (
                        <TextField
                          className="input"
                          {...params}
                          label="Area Name"
                          variant="outlined"
                          style={{ width: "100%", margin: "0", padding: "0" }}
                        />
                      )}
                    />
                </Grid> */}

                <Grid item xs={12} md={6}>
                  <p>{ADDRESS_LINE1}</p>
                  <TextField
                    className="input"
                    name="zone"
                    type="text"
                    value={customer_address}
                    variant="outlined"
                    style={{ width: "100%", margin: "0" }}
                    required
                    inputProps={{ maxLength: 100 }}
                    onChange={(e) =>
                      setCustomer_address(e.target.value as string)
                    }
                    placeholder="Type Here..."
                  />
                  <small>Max Length 100</small>
                </Grid>
                <Grid item xs={12} md={6}>
                  <p>{ADDRESS_LINE2}</p>
                  <TextField
                    className="input"
                    name="zone"
                    type="text"
                    value={addressLine2}
                    // value={"ghgjgwfeqjkh"}
                    inputProps={{ maxLength: 100 }}
                    variant="outlined"
                    style={{ width: "100%", margin: "0" }}
                    required
                    onChange={(e) => setAddressLine2(e.target.value as string)}
                    placeholder="Type Here..."
                  />
                  <small>Max Length 100</small>
                </Grid>
                <Grid item xs={12} md={12}>
                  <p>{LANDMARK}</p>
                  <TextField
                    className="input"
                    placeholder="Ex: School/College/Restaurant/Shop/Bank/Government Office"
                    name="zone"
                    type="text"
                    value={favoritePlace || ""}
                    variant="outlined"
                    style={{ width: "100%", margin: "0" }}
                    required
                    inputProps={{ maxLength: 100 }}
                    onChange={(e) => setFavoritePlace(e.target.value as string)}
                  />
                  <small>Max Length 100</small>
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <p>{PINCODE}</p>
                  <TextField
                    className="input"
                    name="zone"
                    type="text"
                    value={pincode || 0}
                    disabled
                    variant="outlined"
                    style={{ width: "100%", margin: "0", background: "#ccc" }}
                    required
                  />
                </Grid> */}
                {/* <Grid item xs={12} md={6}>
                  <p>Zone/City</p>
                  <TextField
                    className="input"
                    name="city"
                    type="text"
                    value={customer_city}
                    variant="outlined"
                    style={{ width: "100%", margin: "0", background: "#ccc" }}
                    disabled={true}
                  />
                </Grid> */}
              </Grid>
            </DialogContent>
            {geoLoading ? (
              <Loader />
            ) : (
              <DialogActions>
                <Button
                  onClick={() => {
                    handleAddressModel();
                  }}
                  color="primary"
                  disabled={
                    geoCheckError !== "" ||
                    (Object.keys(mapAddressObject).length === 0 &&
                      addressExeData === "")
                  }
                >
                  Select
                </Button>
                <Button
                  onClick={() => {
                    if (
                      (Object.keys(mapAddressObject).length !== 0 ||
                        addressExeData !== "") &&
                      geoCheckError === ""
                    ) {
                      handleAddressModel();
                    } else {
                      setOpenLocationModal(false);
                    }
                  }}
                  color="primary"
                >
                  Cancel
                </Button>
              </DialogActions>
            )}
          </Dialog>
        </form>
      </div>
      <SlidingPane
        className="some-custom-class"
        overlayClassName="some-custom-overlay-class"
        isOpen={isPaneOpen}
        title={singlePackage?.name}
        width="80%"
        from="right"
        onRequestClose={() => {
          setPaneOpen(false);
        }}
      >
        <div>
          <iframe
            src={singlePackage?.web_link}
            style={{ width: "100%", height: "700px" }}
            title="Iframe Example"
          ></iframe>
        </div>
      </SlidingPane>
      <CommentsModal2
        open={commentModalOpen}
        getCities={getCities}
        cities={cities}
        setOpen={setCommentModalOpen}
        leadId={leadById.id}
      />
      <CommentsModal open={openM} setOpen={setOpenM} leadId={leadById.id} />
      {/* <PackageQuestionsEnglish
        openQestionModel={openQestionModel}
        setQuestionModel={setQuestionModel}
      />
      <PackageQuestionsHindi
        openQestionModel={packageHindi}
        setQuestionModel={setPackageHindi}
      /> */}
      <WhatsAppModal
        open={openWhatsapp}
        setOpen={setOpenWhatsapp}
        leadId={whatsappLeadSearch}
      />
      {openVerifiedLocationModal && (
        <LocationVerificationModal
          openModal={openVerifiedLocationModal}
          setopenModal={setOpenVerifiedLocationModal}
          bookingId={bookingIdLocationModal}
          leadId={leadIdLocationModal}
          setCustomerLatitude={setCustomerLatitudeModal}
          setCustomerLongitude={setCustomerLongitudeModal}
          customerLatitude={customerLatitudeModal}
          customerLongitude={customerLongitudeModal}
          setCustomerLatitude1={setCustomerLatitude1Modal}
          setCustomerLongitude1={setCustomerLongitude1Modal}
          customerLatitude1={customerLatitude1Modal}
          customerLongitude1={customerLongitude1Modal}
          cityId={cityIdModal}
          addressLine2={addressLine2Modal}
          favoritePlace={favoritePlaceModal}
          customer_address={customer_addressModal}
          customer_landmark1={customer_landmark1Modal}
          addressId={addressId}
          heading={"Address is more than 3KM"}
          setVerifyKey={setVerifyKey}
        />
      )}
    </main>
  );
};
const mapStateToProps = (state: any) => ({
  pin_code: state.BookingReducer.pin_code,
  centerInfo: state.BookingReducer.centerInfo,
  packageList: state.BookingReducer.packageList,
  couponList: state.BookingReducer.couponList,
  createPilotBooking: state.BookingReducer.createPilotBooking,
  leads: state.BookingReducer.leads,
  lead_details: state.BookingReducer.lead_details,
  booking_slots: state.BookingReducer.booking_slots,
  sameNumberBooking: state.BookingReducer.sameNumberBooking,
  loading: state.BookingReducer.loading,
  redCoin: state.BookingReducer.redCoin,
  geoFencesMapMyIndia: state.BookingReducer.geoFencesMapMyIndia,
  ceCenterForCC: state.BookingReducer.ceCenterForCC,
  mapmyindiaAccessToken: state.BookingReducer.mapmyindiaAccessToken,
  timeslots: state.BookingReducer.timeslots,
  userDetails: state.BookingReducer.userDetails,
  breakResponse: state.BookingReducer.breakResponse,
  geoLoading: state.BookingReducer.geoLoading,
  geoCheckError: state.BookingReducer.geoCheckError,
  leadById: state.BookingReducer.leadById,
  cities: state.BookingReducer.cities,
  crmLeadAddress: state.BookingReducer.crmLeadAddress,
  crmPostLeadAddress: state.BookingReducer.crmPostLeadAddress,
  communicationWhatsapp: state.BookingReducer.communicationWhatsapp,
  geoFencesMapMyIndiaForCustomer:
    state.BookingReducer.geoFencesMapMyIndiaForCustomer,
  leadAddressLoading: state.BookingReducer.leadAddressLoading,
  locationDataFromPusher: state.loginReducer.locationDataFromPusher,
  whatsappAddressIdDetail: state.loginReducer.whatsappAddressIdDetail,
  whatsappMessageStatus: state.BookingReducer.whatsappMessageStatus,
  // referralCurrentBalance: state.BookingReducer.referralCurrentBalance,
  // couponReferralValidation: state.BookingReducer.couponReferralValidation,
});

export default connect(mapStateToProps, {
  getPinCode,
  getPackage,
  getCoupons,
  createNewBooking,
  getLeads,
  getLeadDetails,
  resetLeadDetails,
  getAvailableSlots,
  checkGeoFenceArea,
  getRedCoing,
  checkGeoFenchMapMyIndia,
  checkGeoFenchMapMyIndiaPilot,
  getMapMyIndiaAccessToken,
  getCenterInfo,
  getceCentre,
  getTimeslots,
  createPilotNewBooking,
  getBreakResponse,
  resetBookings,
  getLeadById,
  getCities,
  getCommunicationOnWhatsapp,
  SendLocationRequest,
  postCrmLeadAddress,
  checkGeoFenchMapMyIndiaPilotForCustomerAddress,
  getCrmLeadAddress,
  getNewPackage,
  getWhatsappMessageStatus,
  // getReferralCurrentBalance,
  // getCouponReferralValidation,
})(BookingWithLocalitySearch);
