import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import { Container, Grid } from "@material-ui/core";
import Box from "@mui/material/Box";
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import Dashboard from "../components/Dashboard/Dashboard";
import CallBackFlowup from "../tables/CallBackFlowup";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import FollowupLeads from "../tables/followupLeads";
import UserDetails from "./userStatus";
import ImagingTicket from "../tables/imagingTicket";
import HoldCancelled from "../tables/holdCancelled";
import LatestWhatsapp from "../tables/latestWhatsapp";
import { Tooltip } from "@material-ui/core";
import LazyLoad from "react-lazyload";
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getBreakResponse,
  getCities,
  getTodaysMissedCalls,
  getUserStats,
  getLeadById,
  clickToCall,
  getLtvLead,
  getLeadSource,
  getTopRCFreshLeads,
  getPrescriptionData,
  getWhatsappComments,
  getUserDetails,
  getRedCoing,
} from "../actions/bookingActions";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "70%",
      float: "left",
    },
    tableContainer: {
      marginTop: "1rem",
      position: "sticky",
      top: "200px",
    },
    rightContentHeader: {
      width: "30%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "100%",
      padding: "0px 0px 10px 0px",
      marginTop: "40px",
      minHeight: "300px",
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      // wordSpacing:3,
      justifyContent: "space-between",
    },
  })
);
const StyledTableCell: any = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: "#924A91",
      color: theme.palette.common.white,
      fontSize: "14px",
    },
    body: {
      fontSize: 14,
    },
  })
)(TableCell);

const StyledTableRow: any = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "&:nth-of-type(even)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

interface Props {
  breakResponse: any;
  getBreakResponse: any;
  getCities: any;
  cities: any;
  clickToCall: Function;
  getTodaysMissedCalls: Function;
  todayMissedCallsList: any;
  getUserStats: any;
  userStats: any;
  getLeadById: any;
  leadById: any;
  getLtvLead: any;
  ltvLead: any;
  getLeadSource: any;
  lead_source: any;
  topRcFreshLeads: any;
  prescription: any;
  getWhatsappComments: any;
  whatsappComments: any;
  userDetails: any;
  getUserDetails: any;
  getRedCoing:any;
  redCoin:any;
  booking: any;
}

const CustomerExecutiveHomePage: React.FC<Props> = ({
  breakResponse,
  getBreakResponse,
  getCities,
  cities,
  clickToCall,
  getTodaysMissedCalls,
  todayMissedCallsList,
  getUserStats,
  userStats,
  getLeadById,
  leadById,
  getLtvLead,
  ltvLead,
  getLeadSource,
  lead_source,
  topRcFreshLeads,
  prescription,
  getWhatsappComments,
  whatsappComments,
  userDetails,
  getUserDetails,
  getRedCoing,
  redCoin,
  booking,
}) => {
  const classes = useStyles();
  const [callButton, setCallButton] = useState<boolean>(true);
  let dispatch = useDispatch();

  useEffect(() => {
    // getTodaysMissedCalls();
    getUserStats();
    getLeadById();
    // getLeadSource();
    getUserDetails();
    getBreakResponse(`self=true`);
    // getWhatsappComments("latest=true");
    // getBreakResponse(`self=true`);
  }, []);

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.contentHeader}>
          <div className={classes.leftContentHeader}>
            <h3 style={{ fontWeight: "bold" }}>DASHBOARD</h3>
          </div>
          <div className={classes.rightContentHeader}>
            {/* <Link to="/dashboard/ce/booking"
              target="_blank"
            >
              <Button
                color="primary"
                variant="contained"
                style={{ margin: "5px" }}
              >
                New Booking
              </Button>
            </Link> */}
            {/* <Link to="/dashboard/ce/cloud-agent">
              <Button
                variant="contained"
                style={{ margin: "5px", backgroundColor: "#ff9100" }}
              >
                Ozone Tel
              </Button>
            </Link> */}
          </div>
        </div>

        <div className={classes.contentTable}>
          <Box>
            <Container maxWidth={false}>
              <Grid container spacing={3}>
                <UserDetails
                  setCallButton={setCallButton}
                  callButton={callButton}
                  breakResponse={breakResponse}
                  clickToCall={clickToCall}
                  leadById={leadById}
                  booking={booking}
                  cities={cities}
                  getCities={getCities}
                />
                <Dashboard
                  userStats={userStats}
                  getLeadById={getLeadById}
                  leadById={leadById}
                  getCities={getCities}
                  cities={cities}
                  getLtvLead={getLtvLead}
                  ltvLead={ltvLead}
                  clickToCall={clickToCall}
                  userDetails={userDetails}
                  getRedCoing={getRedCoing}
                  redCoin={redCoin}
                />
              </Grid>
              {/* @ts-ignore */}
              <LazyLoad height={200} once offset={100}>
                <Grid container spacing={3}>
                  {/* whatsapp */}
                  <Grid item container xs={12} md={6} direction="column">
                    <h4
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      Whatsapp
                    </h4>
                    <LatestWhatsapp
                      whatsappComments={whatsappComments}
                      getWhatsappComments={getWhatsappComments}
                    />
                  </Grid>
                  {/* claaback */}
                  <Grid item container xs={12} md={6} direction="column">
                    <h4
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      Call Back
                    </h4>

                    <CallBackFlowup
                      setCallButton={setCallButton}
                      callButton={callButton}
                    />
                  </Grid>
                </Grid>
              </LazyLoad>
              {/* @ts-ignore */}
              <LazyLoad height={200} once offset={100}>
                <Grid container spacing={3} style={{ marginTop: 15 }}>
                  <Grid item container xs={12} md={6} direction="column">
                    <h4
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      Follow-Up
                    </h4>
                    <FollowupLeads
                      setCallButton={setCallButton}
                      callButton={callButton}
                    />
                  </Grid>
                  <Grid item container xs={12} md={6} direction="column">
                    <h4
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        marginLeft: "10px",
                      }}
                    >
                      Imaging Tickets
                    </h4>
                    <ImagingTicket />
                  </Grid>
                </Grid>
              </LazyLoad>
              {/* @ts-ignore */}
              <LazyLoad height={200} once offset={100}>
                <Grid item container xs={12} direction="column">
                  <HoldCancelled />
                </Grid>
              </LazyLoad>
            </Container>
          </Box>
        </div>
      </main>
    </>
  );
};
const mapStateToProps = (state: any) => ({
  breakResponse: state.BookingReducer.breakResponse,
  cities: state.BookingReducer.cities,
  todayMissedCallsList: state.BookingReducer.todayMissedCallsList,
  userStats: state.BookingReducer.userStats,
  leadById: state.BookingReducer.leadById,
  ltvLead: state.BookingReducer.ltvLead,
  lead_source: state.BookingReducer.lead_source,
  topRcFreshLeads: state.BookingReducer.topRcFreshLeads,
  prescription: state.BookingReducer.prescription,
  whatsappComments: state.BookingReducer.whatsappComments,
  userDetails: state.BookingReducer.userDetails,
  redCoin: state.BookingReducer.redCoin,

});

export default connect(mapStateToProps, {
  getBreakResponse,
  getCities,
  clickToCall,
  getTodaysMissedCalls,
  getUserStats,
  getLeadById,
  getLtvLead,
  getLeadSource,
  getTopRCFreshLeads,
  getPrescriptionData,
  getWhatsappComments,
  getUserDetails,
  getRedCoing,
})(CustomerExecutiveHomePage);
