import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Modal from '@mui/material/Modal';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, MenuItem, Select, TextField, Container, Box, Grid } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { updateImagingTicketStataus } from "../../actions/bookingActions";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useEffect } from "react";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
let width = window.innerWidth;
let isMobile = width <= 500;

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        width: isMobile ? "400px" : "1200px",
    },
    head: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "2rem"
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));

type ModalProps = {
    open: boolean;
    ticketId: any;
    setOpen: Function;
    updateImagingTicketStataus: any
};

const CommentsModal2: React.FC<ModalProps> = ({
    open,
    ticketId,
    updateImagingTicketStataus,
    setOpen,
}) => {
    const classes = useStyles();


    const [comment, setComment] = useState<string>("");
    const [category, setCategory] = React.useState<number>(0);
    const [status, setStatus] = useState("none")
    const history = useHistory()
    const [calldate, setCallDate] = useState<any>("")
    const [callTime, setCallTime] = useState<any>("")
    const timer = useRef<any>(0)



    const handleClose = () => {
        setOpen(false);
        // history.push("dashboard/ce/Leadtable")

    };
    const submitForm = (e: any) => {
        e.preventDefault()
        let body: any = {
            status,
            remarks: comment,
            callback_time: calldate + " " + callTime ,
        };
        if (body['callback_time'] === " ") {
            delete body['callback_time'];
        }
        if (status === "call_back_time") {
            if (calldate === "" || callTime === "") {
                alert("Please Select Date And Time")
            }
      
            else {
                updateImagingTicketStataus(ticketId, body);
                setTimeout(() => {
                    history.push("/dashboard/ce/Leadtable")
                }, 1000)

                setComment("")
                handleClose()
            }
        }
        else {
            updateImagingTicketStataus(ticketId, body);
            setTimeout(() => {
                history.push("/dashboard/ce/Leadtable")
            }, 1000)

            setComment("")
            handleClose()
        }


    };


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={classes.head}>
                            <p
                                style={{
                                    marginBottom: "1rem",
                                }}
                                id="transition-modal-title"
                            >
                                Select an options
                            </p>
                            <CloseIcon onClick={() => setOpen(false)} />
                        </div>
                        <form onSubmit={submitForm}>
                            <Select className="input"
                                name="action"
                                variant="outlined"
                                value={status}
                                style={{ width: "100%", marginBottom: "1rem" }}
                                onChange={(e) => setStatus(e.target.value as any)}
                                required
                            >
                                <MenuItem disabled value="none">Please Select</MenuItem>

                                <MenuItem value="pending">Pending</MenuItem>
                                <MenuItem value="confirmed">Confirmed</MenuItem>
                                <MenuItem value="call_back">Call Back</MenuItem>
                                {/* <MenuItem value="call_back_time">Call Back Time</MenuItem> */}
                            </Select>
                            {status === "call_back_time" ||status==="call_back" ? (
                                <Grid container spacing={3} direction="row" alignItems="center" justify="center" style={{ margin: "0.5rem" }}>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            className="input"
                                            name="call_date"
                                            type="date"
                                            label="Date"
                                            value={calldate}
                                            variant="outlined"
                                            onChange={(e) => setCallDate(e.target.value as string)}
                                            style={{ width: "100%" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={3}>
                                        <TextField
                                            id="time"
                                            label="Time"
                                            type="time"
                                            value={callTime}
                                            defaultValue="07:30"
                                            className={classes.textField}
                                            onChange={(e) => setCallTime(e.target.value as string)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                step: 300, // 5 min
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            ) : ""}

                            <TextField
                                name="comment"
                                type="text"
                                multiline
                                rows={4}
                                value={comment}
                                className="input"
                                label="Comment"
                                variant="outlined"
                                placeholder="Comment"
                                onChange={(e) => setComment(e.target.value as string)}
                                style={{ width: "100%", marginBottom: "1rem" }}
                                required
                            />

                            <Button
                                fullWidth
                                variant="contained"
                                color="secondary"
                                style={{ height: "50px" }}
                                type="submit"
                                disabled={
                                    comment === "" || status === "none"
                                }
                            >
                                Submit
                            </Button>
                        </form>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
};

const mapStateToProps = (state: any) => ({

});

export default connect(mapStateToProps, {
    updateImagingTicketStataus,
})(CommentsModal2);
