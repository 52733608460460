import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
    Container,
   Grid,
    TextField,
    Select,
    MenuItem,
    Checkbox,
} from '@material-ui/core';
import Box from '@mui/material/Box';
import { CheckBoxOutlineBlank, CheckBoxRounded } from "@material-ui/icons";
import Button from '@mui/material/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { generateBookingFilterUrl } from "../../helpers/generateUrl";
import { connect } from "react-redux";
import { getAgentList, getPartners, getLeadSource, getAllBookings, getCities, getPhlebos } from '../actions/HealthAdviserAction'
import './filter.sass'
import { useHistory } from "react-router";
import BookingsTable from "../tables/bookingsTable";
import Loader from "../components/loader";
import BookingCommentsModal from '../components/Comments/bookingComments'
import  {BOOKING_STATUS} from '../../booking_status'
import HelpIcon from "@material-ui/icons/Help";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
            //   width: "100%",
            //   marginTop: "20px",
        },
        toolbar: {
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
        },
        content: {
            width: "80%",
            flexGrow: 21,
            //   padding: theme.spacing(3),
        },
        card: {
            height: "100%",
            padding: theme.spacing(1),
        },
        card_grid: {
            // wordSpacing:3,
            justifyContent: 'space-between'
        },
    })
);

interface Props {
    children: any;
    agentsList: any;
    bookingList: any;
    partnerList: any;
    getAgentList: any;
    getPartners: any;
    getAllBookings: any;
    getCities: any;
    cities: any;
    getPhlebos: any;
    phleboList: any;
    getLeadSource: any;
    lead_source: any;
    loading: Boolean;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
    agentsList,
    partnerList,
    getAgentList,
    getPartners,
    getAllBookings,
    bookingList,
    getCities,
    cities,
    getPhlebos,
    phleboList,
    getLeadSource,
    lead_source,
    loading
}) => {
    const classes = useStyles();

    const history = useHistory()

    const [openModal, setopenModal] = useState<boolean>(false);

    const [status, setStatus] = useState<String>("none")
    const [source, setSource] = useState<any>([])
    const [partner, setPartner] = useState<number>(0)
    const [agent, setAgent] = useState<number>(0)
    const [city, setCity] = useState<number>(0)
    const [start_date, setStart_date] = useState("")
    const [end_date, setEnd_date] = useState("")
    const [booking_date, setBooking_date] = useState("")
    const [collection_date, setCollection_date] = useState("")
    const [verificationStatus, setVerificationStatus] = useState("none")
    const [pickup_status, setPickup_status] = useState("none")
    const [name, setName] = useState("")
    const [phlebo, setPhlebo] = useState<any>([])
    const [phone, setPhone] = useState("")
    const [sample_registered, setSampleRegisterd] = useState<any>("none")
    const [report_status, setReportStatus] = useState<any>("none")
    const [bookingId, setBookingId] = React.useState<any>("")
    const [page, setPage] = useState(0)
    const [open, setOpen] = React.useState(false);
    const timer = useRef<any>(0)

    //transition modal start
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    //transition modal end

    useEffect(() => {
        // getAgentList()
        // getPartners()
        getAllBookings()
        // getCities()
        // getPhlebos()
        getLeadSource()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const filterBookings = () => {
        const body: any = {
            status,
            verificationStatus,
            source: source.join(","),
            agent,
            city,
            start_date,
            end_date,
            booking_date,
            collection_date,
            pickup_status,
            name,
            phlebo: phlebo.join(","),
            partner,
            phone,
            sample_registered,
            report_status,
            bookingId,
        }

        const url = generateBookingFilterUrl(body).substring(2)
        getAllBookings(url)
        setPage(0)
    }
    return (
        <>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <h2>Booking List <HelpIcon style={{ color: "#9c27b0" }} onClick={handleClickOpen} /></h2>
                <Grid container spacing={1} direction="row" style={{ alignItems: "left" }}>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="booking"
                            type="number"
                            label="Booking Id"
                            value={bookingId}
                            variant="outlined"
                            onChange={(e) => setBookingId(e.target.value)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="name"
                            type="text"
                            label="Name"
                            value={name}
                            variant="outlined"
                            onChange={(e) => setName(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="phone"
                            type="text"
                            label="Phone"
                            value={phone}
                            variant="outlined"
                            onChange={(e) => setPhone(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="city"
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    setCity(obj.id)
                                }
                            }}
                            options={cities || []}
                            freeSolo
                            blurOnSelect
                            aria-required
                            getOptionLabel={(option: any) => option.name}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                                    getCities(newInputValue)
                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setCity(0)
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    label="City"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="status"
                            onChange={(event, newValue) => {
                                let sourceIds: any = [];
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.value);
                                }
                                setStatus(sourceIds);
                            }}
                            options={BOOKING_STATUS || []}
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            getOptionLabel={(option: any) => option.text}
                            getOptionDisabled={(option: any) => {
                                return status.includes(option.value);
                            }}
                            disableClearable
                            disableCloseOnSelect
                            renderInput={(params) => (
                                <TextField
                                    className="input"
                                    {...params}
                                    placeholder="Booking Status"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="booking_source"
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    setPartner(obj.id)
                                }
                            }}
                            options={partnerList.results || []}
                            freeSolo
                            blurOnSelect
                            aria-required
                            getOptionLabel={(option: any) => option.user && option.user.name}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                                    getPartners(newInputValue)
                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setPartner(0)
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    label="Partner"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setSource(sourceIds)
                            }}
                            multiple
                            id="checkboxes-tags-demo"
                            options={lead_source || []}
                            disableCloseOnSelect
                            limitTags={1}
                            disableClearable
                            getOptionLabel={(option: any) => option.name}
                            renderOption={(option: any, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlank />}
                                        checkedIcon={<CheckBoxRounded />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </React.Fragment>
                            )}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    label="Source"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required
                                />
                            )}
                        />

                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="agentname"
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    setAgent(obj.id)
                                }
                            }}
                            options={agentsList.results || []}
                            freeSolo
                            blurOnSelect
                            aria-required
                            getOptionLabel={(option: any) => option.user && option.user.name}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                                    getAgentList(`${newInputValue.replace(" ", "")}`)
                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setAgent(0)
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    label="Agent Name"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Autocomplete
                            id="phlebo"
                            onChange={(event, newValue) => {
                                let sourceIds: any = []
                                if (newValue) {
                                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                                    sourceIds = obj.map((item: any) => item.id)
                                }
                                setPhlebo(sourceIds)
                            }}
                            freeSolo
                            blurOnSelect
                            aria-required
                            limitTags={1}
                            multiple
                            options={phleboList.results ? [...phleboList.results, { id: 0, user: { username: "NoPhlebo" } }] : []}
                            getOptionDisabled={(option: any) => {
                                return phlebo.includes(option.id)
                            }}
                            disableClearable
                            disableCloseOnSelect
                            getOptionLabel={(option: any) => option.user && option.user.username}
                            onInputChange={(event, newInputValue) => {
                                clearTimeout(timer.current)
                                timer.current = setTimeout(() => {
                                    getPhlebos(newInputValue)
                                }, 1000)
                                if (newInputValue.length === 0) {
                                    setPhlebo([])
                                }
                            }}
                            renderInput={(params) => (
                                <TextField className="input"
                                    {...params}
                                    label="Phlebo Name"
                                    variant="outlined"
                                    style={{ width: "100%", margin: "0", padding: "0" }}
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Select className="input"
                            name="status"
                            variant="outlined"
                            value={verificationStatus}
                            style={{ width: "100%", height: "39px" }}
                            onChange={(e) => setVerificationStatus(e.target.value as string)}
                        >
                            <MenuItem disabled value="none">Verification Status</MenuItem>
                            <MenuItem value={"pending"}>Pending</MenuItem>
                            <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                            <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Select className="input"
                            name="status"
                            variant="outlined"
                            value={pickup_status}
                            style={{ width: "100%", height: "39px" }}
                            onChange={(e) => setPickup_status(e.target.value as string)}
                        >
                            <MenuItem disabled value="none">Pickup Status</MenuItem>
                            <MenuItem value={"pending"}>Pending</MenuItem>
                            <MenuItem value={"confirmed"}>Confirmed</MenuItem>
                            <MenuItem value={"cancelled"}>Cancelled</MenuItem>
                            <MenuItem value={"hold"}>Hold</MenuItem>
                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            className="input"
                            name="start_date"
                            type="date"
                            label="Start Date"
                            value={booking_date === "" || collection_date === "" ? start_date : ""}
                            variant="outlined"
                            disabled={booking_date !== "" || collection_date !== ""}
                            onChange={(e) => setStart_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="end_date"
                            type="date"
                            value={booking_date === "" || collection_date === "" ? end_date : ""}
                            className="input"
                            label="End Date"
                            variant="outlined"
                            disabled={booking_date !== "" || collection_date !== ""}
                            onChange={(e) => setEnd_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="booking_date"
                            type="date"
                            value={start_date === "" || end_date === "" ? booking_date : ""}
                            className="input"
                            label="Booking Date"
                            variant="outlined"
                            disabled={start_date !== "" || end_date !== ""}
                            onChange={(e) => setBooking_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <TextField
                            name="collection_date"
                            type="date"
                            value={start_date === "" || end_date === "" ? collection_date : ""}
                            className="input"
                            label="Collection Date"
                            variant="outlined"
                            disabled={start_date !== "" || end_date !== ""}
                            onChange={(e) => setCollection_date(e.target.value as string)}
                            style={{ width: "100%" }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>

                        <Select className="input"
                            name="sampleregisterd"
                            variant="outlined"
                            value={sample_registered}
                            style={{ width: "100%", height: "39px" }}

                            onChange={(e) => setSampleRegisterd(e.target.value as String)}

                        >
                            <MenuItem disabled value={"none"}>Sample Type</MenuItem>
                            <MenuItem value={"true"}>Registerd</MenuItem>
                            <MenuItem value={"false"}>Not Registerd</MenuItem>

                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>

                        <Select className="input"
                            name="sampleregisterd"
                            variant="outlined"
                            value={report_status}
                            style={{ width: "100%", height: "39px" }}

                            onChange={(e) => setReportStatus(e.target.value as String)}

                        >
                            <MenuItem disabled value={"none"}>Report Status</MenuItem>
                            <MenuItem value={"na"}>None</MenuItem>
                            <MenuItem value={"pending"}>Pending</MenuItem>
                            <MenuItem value={"partial"}>Partial</MenuItem>
                            <MenuItem value={"consolidate"}>Consolidate</MenuItem>

                        </Select>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="secondary"
                            fullWidth
                            onClick={filterBookings}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={4} md={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={() => history.push("/dashboard/ha")}
                        >
                            Reset
                        </Button>
                    </Grid>
                    <Dialog
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                    >
                    <DialogTitle>{"Note:"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                        <div style={{ margin: "1rem" }}>
                            <div style={{ alignItems: "left", display: "inline-block", margin: "1rem" }}>
                            <small>
                                <ul style={{ paddingLeft: "15px" }}>
                                    <li>You can filter by booking date or start and end date.</li>
                                    <li>You can filter leads when bookings are available.</li>
                                    <li>T: Total Additional Booking count</li>
                                    <li>P: Pending count for additional member</li>
                                    <li>C: Confirmed count for additional member</li>
                                </ul>
                            </small>
                            </div>
                        </div>
                        </DialogContentText>
                    </DialogContent>
                    </Dialog>
                </Grid>
                <br />
                {loading ? <Loader /> : bookingList.results && bookingList.results.length > 0 && (
                    <Box>
                        <Container maxWidth={false}>
                            <Grid container spacing={3}>
                                <BookingsTable setopenModal={setopenModal} openModal={openModal} page={page} setPage={setPage} loading={loading} getBookings={getAllBookings} bookingList={bookingList} setBookingId={setBookingId} />
                            </Grid>
                        </Container>
                    </Box>
                )}
                {bookingList.results && bookingList.results.length === 0 && (
                    <h6 style={{ textAlign: "center" }}>No Data Found</h6>
                )}
            </main>
            <BookingCommentsModal openModal={openModal} setopenModal={setopenModal} bookingId={bookingId} />
        </>
    );
};

const mapStateToProps = (state: any) => ({
    agentsList: state.HealthAdviserReducer.agentsList,
    partnerList: state.HealthAdviserReducer.partnerList,
    bookingList: state.HealthAdviserReducer.bookingList,
    cities: state.HealthAdviserReducer.cities,
    phleboList: state.HealthAdviserReducer.phleboList,
    loading: state.HealthAdviserReducer.loading,
    lead_source: state.HealthAdviserReducer.lead_source,
})

export default connect(mapStateToProps, {
    getAgentList,
    getPartners,
    getCities,
    getPhlebos,
    getLeadSource,
    getAllBookings
})(CustomerLeadFilterPage);
