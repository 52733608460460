import React, { useEffect } from "react";
import { Grid, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import BarChartTwoToneIcon from "@mui/icons-material/BarChartTwoTone";
import { getDashboardOverview } from "../actions/salesLeadAction";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import Avatar from "@mui/material/Avatar";
import moment from "moment";
import "./dashboard.css";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: "100%",
      padding: "20px",
    },
    cardContent: {
      textAlign: "center",
    },
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    text: { color: "black" },
  })
);

interface Props {
  getDashboardOverview: any;
  dashboardOverview: any;
  userDetails: any;
}

const Dashboard: React.FC<Props> = ({
  getDashboardOverview,
  dashboardOverview,
  userDetails,
}) => {
  const classes = useStyles();

  useEffect(() => {
    getDashboardOverview(`overview_date=${moment().format("YYYY-MM-DD")}`);
  }, []);

  document.querySelectorAll("#noTyping").forEach(el=>{
    el.addEventListener("keydown", function(e){
      e.preventDefault()
    })
  })

  return (
    <div style={{ width: "90%", margin: "60px auto" }}>
         <Grid
          container
          spacing={1}
          direction="row"
          style={{ alignItems: "right", display: "flex", justifyContent: "space-between" }}
        >
          <Grid item xs={12} sm={8}>
            <h4 style={{ fontWeight: "bold" }}>DASHBOARD</h4>
          </Grid>
          <Grid item xs={12} sm={4} style={{ textAlign: "end" }}>
          <TextField
            type="date"
            id="noTyping"
            defaultValue={moment().format("YYYY-MM-DD")}
            onChange={(e) =>
              getDashboardOverview(
                `overview_date=${moment(e.target.value).format("YYYY-MM-DD")}`
              )
            }
            inputProps={{ min: `${new Date().getFullYear() - 100}-${new Date().getMonth() + 1 < 10
              ? `0${new Date().getMonth() + 1}`
              : new Date().getMonth() + 1
              }-${new Date().getDate() < 10
                ? `0${new Date().getDate()}`
                : new Date().getDate()
              }`, max: `${new Date().getFullYear() + 100}-${
                new Date().getMonth() + 1 < 10
                  ? `0${new Date().getMonth() + 1}`
                  : new Date().getMonth() + 1
              }-${
                new Date().getDate() < 10
                  ? `0${new Date().getDate()}`
                  : new Date().getDate()
              }` }}
          />
          </Grid>
        </Grid>
      <div style={{ margin: "10px 0px 0px 0px" }}>
        <h5>BOOKING</h5>
        <div className="cardsAppointment">
          <div>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{dashboardOverview?.total_booking}</h5>
                <span style={{ color: "#ffffff" }}>Total</span>
              </Grid>
            </Grid>
          </div>
          <div>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{dashboardOverview?.cancelled_booking}</h5>
                <span style={{ color: "#ffffff" }}>Cancelled</span>
              </Grid>
            </Grid>
          </div>
          <div>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{dashboardOverview?.verified_booking}</h5>
                <span style={{ color: "#ffffff" }}>Verified</span>
              </Grid>
            </Grid>
          </div>
          <div>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <BarChartTwoToneIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{dashboardOverview?.unverified_booking}</h5>
                <span style={{ color: "#ffffff" }}>Unverified</span>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      <div style={{ margin: "10px 0px 0px 0px" }}>
        <h5>SYSTEM CFRESH</h5>
        <div className="cardsAppointment">
          <div>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <AccessibilityIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{dashboardOverview?.system_cfresh_today}</h5>
                <span style={{ color: "#ffffff" }}>Today</span>
              </Grid>
            </Grid>
          </div>
          <div>
            <Grid container>
              <Grid item sm={3}>
                <Avatar
                  sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                  aria-label="recipe"
                >
                  <AccessibilityIcon style={{ color: "#fa4d83" }} />
                </Avatar>
              </Grid>
              <Grid item sm={9}>
                <h5 style={{ color: "#ffffff" }}>{dashboardOverview?.system_cfresh_all}</h5>
                <span style={{ color: "#ffffff" }}>All</span>
              </Grid>
            </Grid>
          </div>
          <div style={{opacity:"0"}}></div>
          <div style={{opacity:"0"}}></div>
        </div>
      </div>
      {userDetails?.profile?.executive_type === "Admin" ? (
        <div style={{ margin: "10px 0px 0px 0px" }}>
          <h5>REVENUE</h5>
          <div className="cardsAppointment">
            <div>
              <Grid container>
                <Grid item sm={3}>
                  <Avatar
                    sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                    aria-label="recipe"
                  >
                    <CurrencyRupeeIcon style={{ color: "#fa4d83" }} />
                  </Avatar>
                </Grid>
                <Grid item sm={9}>
                  <h5 style={{ color: "#ffffff" }}>{dashboardOverview?.booking_revenue}</h5>
                  <span style={{ color: "#ffffff" }}>Booking Revenue</span>
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid container>
                <Grid item sm={3}>
                  <Avatar
                    sx={{ bgcolor: "#ffeff5", marginTop: "8px" }}
                    aria-label="recipe"
                  >
                    <CurrencyRupeeIcon style={{ color: "#fa4d83" }} />
                  </Avatar>
                </Grid>
                <Grid item sm={9}>
                  <h5 style={{ color: "#ffffff" }}>{dashboardOverview?.collection_revenue}</h5>
                  <span style={{ color: "#ffffff" }}>Collection Revenue</span>
                </Grid>
              </Grid>
            </div>
            <div style={{opacity:"0"}}></div>
            <div style={{opacity:"0"}}></div>
          </div>
        </div>
      ) : ""}

    </div>
  );
};

const mapStateToProps = (state: any) => ({
  dashboardOverview: state.SalesLeadReducer.dashboardOverview,
  userDetails: state.SalesLeadReducer.userDetails,
});

export default connect(mapStateToProps, {
  getDashboardOverview,
})(Dashboard);
