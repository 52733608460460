import { ACTIONS, BookingTypes } from "../interfaces/actionTypes/salesLeadType";

type InitialState = {
  users: Object;
  areaList: Object;
  area: Object;
  zoneList: Object;
  zone: Object;
  timeslotsList: Object;
  updatedPanelUser: Object;
  blogList: Object;
  blog: Object;
  blogBySlug: Object;
  blogCategories: Object;
  blogTags: Object;
  blogImages: Object;
  coupon: Object;
  pack: Object;
  cityPriceList: Object;
  loading: boolean;
  bookingIdLoader: boolean;
  biomarkerLoader: boolean;
  pin_code: Array<any>;
  cities: Array<any>;
  loadingComment: boolean;
  agentsList: Array<any>;
  phleboList: Array<any>;
  packageList: Array<any>;
  partnerList: Array<any>;
  couponList: Array<any>;
  breakResponse: Array<any>;
  createBooking: Object;
  rcFreshLead: Array<any>;
  booking: Object;
  todays_positive_calls: Object;
  lead_details: Object;
  leadById: Object;
  leads: Array<any>;
  bookingList: Array<any>;
  comment: any;
  loadingChat: boolean;
  additional_booking: object;
  data: any;
  bookingsComment: any;
  lead_id: number;
  read_call_details: object;
  all_recordings: object;
  whatsappComments: object;
  whatsappCommentsByLead: object;
  callStats: object;
  complaint: object;
  complaints: object;
  lead_source: any;
  booking_slots: object;
  incorrectNumberLeads: Array<any>;
  search_results: object;
  call_status: object;
  all_lead: object;
  all_lead_tatataly: object;
  call_transfer: object;
  user_panel: object;
  agentBYUserGroup: object;
  createPanelUser: object;
  labCredential: Array<any>;
  updateLabCredential: Array<any>;
  dispositions: object;
  dispositionData: Array<any>;
  zoneComment: any;
  sameNumberBooking: boolean;
  paymentSendLink: Array<any>;
  paymentReSendLink: Array<any>;
  paymentCancelLink: Array<any>;
  bookingListWithTatTime: Array<any>;
  additionalBookingListWithTatTime: Array<any>;
  smsReportSend: Array<any>;
  teamLeadList: Array<any>;
  breakData: Array<any>;
  assigneUserList: Array<any>;
  verificationBookingDetails: Array<any>;
  customerList: Array<any>;
  todayCallDetails: object;
  updateUserStatus: object;
  topRecord: object;
  smsDetails: Array<any>;
  emailDetails: Array<any>;
  imagingTimeSlots: Array<any>;
  reportStatus: Array<any>;
  centerInfo: Array<any>;
  createIMGBooking: object;
  collectionSlot: Array<any>;
  labDetails: Array<any>;
  paymentInformation: Array<any>;
  notificationMessage: Array<any>;
  reportDownload: Array<any>;
  panelUserList: Array<any>;
  panelList: object;
  userListData: Array<any>;
  userList: Array<any>;
  areaComments: Array<any>;
  panelComments: Array<any>;
  assignedPanel: object;
  unmaskedNumber: Array<any>;
  userDetails: Array<any>;
  agentdata: Array<any>;
  todayMissedCallsList: Array<any>;
  individualWhatsappChat: Array<any>;
  checkGeoFenceStatus: boolean;
  verifiedbylist: Array<any>;
  selfUserType: Array<any>;
  geoLoading:false,
  geoCheckError:""
  redCoin: Array<any>;
  subCenter: Array<any>;
  leadStatus: Array<any>;
  prescription: Array<any>;
  addressType: Array<any>;
  bookingComplaints: Array<any>
  geoFencesMapMyIndia: Array<any>;
  mapmyindiaAccessToken: Array<any>;
  loadingIndividualChat: boolean;
  addressDetailsLatLong: Array<any>;
  loginStatus: Object;
  multiplePackage: Array<any>;
  displayCities: Array<any>;
  bookingCall: Array<any>;
  qrcode: Array<any>;
  signature: Array<any>;
  additionMember: Array<any>;
  performanceData: Array<any>;
  ticketData: Array<any>;
  ticketCategories: Array<any>;
  ticketSubCategories: Array<any>;
  qualityScoringReport: Array<any>;
  createPilotBooking: Object;
  get_quality_scoring_report_for_backup: Array<any>;
  redtechcomment: Array<any>;
  ltvLead: Object;
  dashboardOverview: Object;
  allBookings: object;
  ceCenterForCC:Array<any>;
  ticketComments: Array<any>
  agentsListnewdata: Array<any>
  biomarkerScreening: Array<any>;
  biomarkerUltasound: Array<any>;
  markerAdditionMember: Array<any>;
  bookingListLoader: boolean;
  newbookingList: Array<any>;
  sendLocationOnWhatsapp: Array<any>;
  crmLeadAddress: Array<any>;
  crmPostLeadAddress: Array<any>;
  locationVerified: Array<any>;
  whatsappTemplate: Array<any>;
  createWhatsappTemplate: Array<any>;
  salesLeadList: Array<any>;
  ozontelskilldata: Array<any>;
  reportLoading: boolean;
  signOutData: Array<any>;
  holdTheChatData: Array<any>;
  allRedtechTicketsUsingLeadData: Array<any>;
  latestBookingUsingLeadData: Array<any>;
  failedReport: Array<any>;
  manualreportsend: Array<any>;
  queueNumber: Object;
};

const initialState: InitialState = {
  users: {},
  updatedPanelUser: {},
  prescription: [],
  assignedPanel: {},
  areaList: {},
  area: {},
  zoneList: {},
  zone: {},
  timeslotsList: {},
  blogList: {},
  blog: {},
  blogBySlug: {},
  blogCategories: {},
  blogTags: {},
  blogImages: {},
  coupon: {},
  pack: {},
  cityPriceList: {},
  additional_booking: {},
  breakResponse: [],
  loading: false,
  bookingIdLoader: false,
  biomarkerLoader: false,
  pin_code: [],
  cities: [],
  agentsList: [],
  phleboList: [],
  customerList: [],
  rcFreshLead: [],
  packageList: [],
  partnerList: [],
  couponList: [],
  selfUserType: [],
  createBooking: {},
  booking: {},
  lead_details: {},
  leadById: {},
  todays_positive_calls: {},
  leads: [],
  bookingList: [],
  comment: {},
  createIMGBooking: {},
  data: {},
  read_call_details: {},
  all_recordings: {},
  whatsappComments: {},
  whatsappCommentsByLead: {},
  callStats: {},
  complaint: {},
  complaints: {},
  bookingsComment: {},
  lead_id: 0,
  lead_source: {},
  booking_slots: {},
  search_results: {},
  updateUserStatus: {},
  call_status: {},
  all_lead: {},
  all_lead_tatataly: {},
  call_transfer: {},
  agentBYUserGroup: {},
  user_panel: {},
  labCredential: [],
  updateLabCredential: [],
  dispositions: {},
  zoneComment: {},
  incorrectNumberLeads: [],
  sameNumberBooking: false,
  dispositionData: [],
  paymentSendLink: [],
  paymentReSendLink: [],
  paymentCancelLink: [],
  smsReportSend: [],
  breakData: [],
  assigneUserList: [],
  todayCallDetails: {},
  topRecord: {},
  imagingTimeSlots: [],
  verificationBookingDetails: [],
  smsDetails: [],
  emailDetails: [],
  reportStatus: [],
  centerInfo: [],
  labDetails: [],
  paymentInformation: [],
  notificationMessage: [],
  userDetails: [],
  reportDownload: [],
  panelUserList: [],
  panelList: {},
  collectionSlot: [],
  createPanelUser: {},
  userListData: [],
  userList: [],
  areaComments: [],
  panelComments: [],
  unmaskedNumber: [],
  loadingIndividualChat: false,
  agentdata: [],
  individualWhatsappChat: [],
  todayMissedCallsList: [],
  bookingListWithTatTime: [],
  additionalBookingListWithTatTime: [],
  checkGeoFenceStatus: false,
  verifiedbylist: [],
  redCoin: [],
  subCenter: [],
  leadStatus: [],
  addressType: [],
  geoFencesMapMyIndia: [],
  mapmyindiaAccessToken: [],
  addressDetailsLatLong: [],
  loginStatus: {},
  teamLeadList: [],
  multiplePackage: [],
  displayCities: [],
  bookingCall: [],
  qrcode: [],
  signature: [],
  additionMember: [],
  performanceData: [],
  ticketData: [],
  ticketCategories: [],
  createPilotBooking: {},
  salesLeadList: [],
  ozontelskilldata: [],
  loadingChat: false,
  ticketSubCategories: [],
  qualityScoringReport: [],
  get_quality_scoring_report_for_backup: [],
  redtechcomment: [],
  ltvLead: {},
  dashboardOverview: {},
  geoLoading:false,
  geoCheckError:"",
  allBookings: {},
  bookingComplaints:[],
  ticketComments:[],
  ceCenterForCC: [],
  agentsListnewdata: [],
  loadingComment: false,
  biomarkerScreening: [],
  biomarkerUltasound: [],
  markerAdditionMember: [],
  bookingListLoader:false,
  newbookingList: [],
  locationVerified: [],
  crmLeadAddress: [],
  crmPostLeadAddress: [],
  sendLocationOnWhatsapp: [],
  whatsappTemplate: [],
  createWhatsappTemplate: [],
  reportLoading: false,
  holdTheChatData: [],
  signOutData: [],
  allRedtechTicketsUsingLeadData: [],
  latestBookingUsingLeadData: [],
  failedReport: [],
  manualreportsend: [],
  queueNumber: {},
};

const SalesLeadReduce = (state = initialState, action: BookingTypes) => {
  switch (action.type) {
    case ACTIONS.GET_USERS_LIST:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case ACTIONS.QUEUE_NUMBER:
      return {
        ...state,
        queueNumber: action.payload,
        loading: false,
      };
    case ACTIONS.HOLD_THE_CHAT:
      return {
        ...state,
        holdTheChatData: action.payload,
        loading: false,
      };
    case ACTIONS.REDTECH_TICKETS_USING_LEAD:
      return {
        ...state,
        allRedtechTicketsUsingLeadData: action.payload,
        loading: false,
      };
    case ACTIONS.LATEST_BOOKING_USING_LEAD:
      return {
        ...state,
        latestBookingUsingLeadData: action.payload,
        loading: false,
      };
    case ACTIONS.SIGN_OUT_IN_WHATSAPP_CHAT:
      return {
        ...state,
        signOutData: action.payload,
        loading: false,
      };
    case ACTIONS.SEND_REPORT_BY_ID:
      return {
        ...state,
        manualreportsend: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_TEMPLATE:
      return {
        ...state,
        whatsappTemplate: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_WHATSAPP_TEMPLATE:
      return {
        ...state,
        createWhatsappTemplate: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DASHBOARD_OVERVIEW:
      return {
        ...state,
        dashboardOverview: action.payload,
        loading: false,
      };
    case ACTIONS.ASSIGNED_PANEL_USER_DATA:
      return {
        ...state,
        assignedPanel: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LTV_LEAD:
      return {
        ...state,
        ltvLead: action.payload,
        loading: false,
      };
    case ACTIONS.CHECK_GEOFENCE_AREA:
      return {
        ...state,
        checkGeoFenceStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AGENT_DATA:
      return {
        ...state,
        agentdata: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_USERS_LIST:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_AREA:
      return {
        ...state,
        area: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_AREA:
      return {
        ...state,
        area: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AREA:
      return {
        ...state,
        areaList: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_ZONE:
      return {
        ...state,
        zone: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_ZONE:
      return {
        ...state,
        zone: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ZONE:
      return {
        ...state,
        zoneList: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_IMAGING_BOOKING:
      return {
        ...state,
        createIMGBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TIMESLOTS:
      return {
        ...state,
        timeslotsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLOG_CATEGORY:
      return {
        ...state,
        blogCategories: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLOG_TAGS:
      return {
        ...state,
        blogTags: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLOG_IMAGES:
      return {
        ...state,
        blogImages: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_BLOGS:
      return {
        ...state,
        blog: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_BLOGS:
      return {
        ...state,
        blog: action.payload,
        loading: false,
      };
    case ACTIONS.DELETE_BLOG:
      return {
        ...state,
        blog: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLOGS:
      return {
        ...state,
        blogList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BLOG_BY_SLUG:
      return {
        ...state,
        blogBySlug: action.payload,
        loading: false,
      };
      case ACTIONS.CREATE_PILOT_BOOKING:
        return {
          ...state,
          createPilotBooking: action.payload,
          loading: false,
        };
    case ACTIONS.CREATE_COUPON:
      return {
        ...state,
        coupon: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_COUPON:
      return {
        ...state,
        coupon: action.payload,
        loading: false,
      };
      case ACTIONS.GET_BREAK_RESPONSE:
        return {
          ...state,
          breakResponse: action.payload,
          loading: false,
        };
    case ACTIONS.CREATE_PACKAGE:
      return {
        ...state,
        pack: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_ADDITIONAL_BOOKING:
      return {
        ...state,
        additional_booking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RED_TECH_COMMENT:
      return {
        ...state,
        redtechcomment: action.payload,
        loading: false,
      };

    case ACTIONS.GET_PACKAGE:
      return {
        ...state,
        pack: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_CALLS:
      return {
        ...state,
        bookingCall: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_PACKAGE:
      return {
        ...state,
        pack: action.payload,
        loading: false,
      };
    case ACTIONS.GET_IMAGING_TIMESLOTS:
      return {
        ...state,
        imagingTimeSlots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITY_PRICES:
      return {
        ...state,
        cityPriceList: action.payload,
        loading: false,
      };
    case ACTIONS.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTIONS.GET_PANEL_USER_LIST_DATA_TEAM_LEAD:
      return {
        ...state,
        teamLeadList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PIN_CODE:
      return {
        ...state,
        pin_code: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_LIST_WITH_TAT_TIME:
      return {
        ...state,
        bookingListWithTatTime: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDITIONAL_BOOKING_LIST_WITH_TAT_TIME:
      return {
        ...state,
        additionalBookingListWithTatTime: action.payload,
        loading: false,
      };
    case ACTIONS.UPLOAD_FILE:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PHLEBOS:
      return {
        ...state,
        phleboList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CITIES:
      return {
        ...state,
        cities: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PERFORMANCE_DATA:
      return {
        ...state,
        performanceData: action.payload,
        loading: false,
      };

    case ACTIONS.GET_POSTIVE_CALLS_COMMENTS:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loadingComment: false,
      };
    case ACTIONS.SET_COMMENTS:
      return {
        ...state,
        comment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_POSTIVE_CALLS_COMMENT:
      return {
        ...state,
        todays_positive_calls: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_DETAILS:
      return {
        ...state,
        lead_details: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEADS:
      return {
        ...state,
        leads: action.payload,
        loading: false,
      };
      case ACTIONS.SET_LOADING_INDIVIDUAL_CHAT:
        return {
          ...state,
          loadingIndividualChat: action.payload,
        };
    case ACTIONS.GET_LEAD_BY_ID:
      return {
        ...state,
        leadById: action.payload,
        loading: false,
      };
      case ACTIONS.SET_LOADING_CHAT:
        return {
          ...state,
          loadingChat: action.payload,
        };
    case ACTIONS.LEAD_DETAILS_RESET:
      return {
        ...state,
        leadById: {},
        lead_details: {},
        whatsappComments: {},
        individualWhatsappChat: [],
        todays_positive_calls: {},
        complaints: {},
        loadingChat: false,
        loadingIndividualChat: false,
        loadingComment: false,
        geoFencesMapMyIndia: [],
        packageList: [],
        mapmyindiaAccessToken: [],
        failedReport: [],
        loading: false,
      };
    case ACTIONS.GET_FAILED_REPORT_DATA:
      return {
        ...state,
        failedReport: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AGENTS:
      return {
        ...state,
        agentsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AGENTS_BY_USERGROUP:
      return {
        ...state,
        agentBYUserGroup: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PACKAGES:
      return {
        ...state,
        packageList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PARTNERS:
      return {
        ...state,
        partnerList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COUPONS:
      return {
        ...state,
        couponList: action.payload,
        loading: false,
      };
    case ACTIONS.CREATE_BOOKING:
      return {
        ...state,
        createBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_LIST:
      return {
        ...state,
        bookingList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BOOKING_BY_ID:
      return {
        ...state,
        booking: action.payload,
        bookingIdLoader: false,
      };
    case ACTIONS.CREATE_CALL:
      return {
        ...state,
        loading: false,
        lead_id: action.payload,
      };
    case ACTIONS.GET_RECORDINGS:
      return {
        ...state,
        all_recordings: action.payload,
        loading: false,
      };
      case ACTIONS.GET_AGENTS_NEW_DATA:
        return {
          ...state,
          agentsListnewdata: action.payload,
          loading: false,
        };
    case ACTIONS.GET_LEAD_SOURCE:
      return {
        ...state,
        lead_source: action.payload,
        loading: false,
      };
    case ACTIONS.SEARCH_CALL:
      return {
        ...state,
        search_results: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_COMMENT:
      return {
        ...state,
        comments: action.payload,
        loading: false,
      };
    case ACTIONS.BOOKING_RESET:
      return {
        ...state,
        createBooking: {},
        bookingList: [],
        booking: {},
        loading: false,
        bookingIdLoader: false,
      };
    case ACTIONS.GET_BOOKINGS_COMMENTS:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
    case ACTIONS.ADD_BOOKINGS_COMMENT:
      return {
        ...state,
        bookingsComment: action.payload,
        loading: false,
      };
      case ACTIONS.SET_LOADING_COMMENT:
        return {
          ...state,
          loadingComment: action.payload,
        };
    case ACTIONS.GET_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loading: false,
      };
    case ACTIONS.GET_WHATSAPP_COMMENTS_BY_LEAD:
      return {
        ...state,
        whatsappCommentsByLead: action.payload,
        loadingIndividualChat: false,
      };
    case ACTIONS.SEND_WHATSAPP_COMMENTS:
      return {
        ...state,
        whatsappComments: action.payload,
        loadingChat: false,
      };
    case ACTIONS.GET_CALL_STATS:
      return {
        ...state,
        callStats: action.payload,
        loading: false,
      };
    case ACTIONS.RAISE_COMPLAINT:
      return {
        ...state,
        complaint: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COMPLAINTS:
      return {
        ...state,
        complaints: action.payload,
        loading: false,
      };
    case ACTIONS.GET_COLLECTION_SLOT:
      return {
        ...state,
        collectionSlot: action.payload,
        loading: false,
      };
    case ACTIONS.ASSIGN_DOCTOR:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.SEND_FEEDBACK:
      return {
        ...state,
        success: true,
        loading: false,
      };
    case ACTIONS.GET_AVAILABLE_SLOTS:
      return {
        ...state,
        booking_slots: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ALL_LEADS:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USERS:
      return {
        ...state,
        user_panel: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_TRANSFER_BY_COMMA:
      return {
        ...state,
        all_lead: action.payload,
        loading: false,
      };
    case ACTIONS.LEAD_TRANSFER_TO_TATATALE:
      return {
        ...state,
        all_lead_tatataly: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_CREDENTIAL_DETAILS:
      return {
        ...state,
        labCredential: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_LAB_CREDENTIALS:
      return {
        ...state,
        updateLabCredential: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ZONE_COMMENTS:
      return {
        ...state,
        zoneComment: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_USERS_STATUS:
      return {
        ...state,
        updateUserStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DISPOSITIONS:
      return {
        ...state,
        dispositions: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DISPOSITION_DATA:
      return {
        ...state,
        dispositionData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_INCORRECT_NUMBER_LEAD:
      return {
        ...state,
        incorrectNumberLeads: action.payload,
        loading: false,
      };
    case ACTIONS.SAME_NUMBER_BOOKING:
      return {
        ...state,
        sameNumberBooking: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_SEND_LINK:
      return {
        ...state,
        paymentSendLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_RESEND_LINK:
      return {
        ...state,
        paymentReSendLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_CANCEL_LINK:
      return {
        ...state,
        paymentCancelLink: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_REPORT_SEND:
      return {
        ...state,
        smsReportSend: action.payload,
        loading: false,
      };
    case ACTIONS.GET_BREAK_DATA:
      return {
        ...state,
        breakData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ASSIGNED_USERGROUP:
      return {
        ...state,
        assigneUserList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RCFRESH_LEAD_DATA:
      return {
        ...state,
        rcFreshLead: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TODAYS_CALL_DETAILS:
      return {
        ...state,
        todayCallDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TOP_RECORDS:
      return {
        ...state,
        topRecord: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VERIFICATION_BOOKING_DETAILS:
      return {
        ...state,
        verificationBookingDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SMS_DETAILS:
      return {
        ...state,
        smsDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_EMAIL_DETAILS:
      return {
        ...state,
        emailDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_STATUS:
      return {
        ...state,
        reportStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CENTER_INFORMATION:
      return {
        ...state,
        centerInfo: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LAB_DETAILS:
      return {
        ...state,
        labDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PAYMENT_INFORMATION:
      return {
        ...state,
        paymentInformation: action.payload,
        loading: false,
      };
    case ACTIONS.GET_NOTIFICATION_MESSAGE:
      return {
        ...state,
        notificationMessage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_MULTIPLE_PACKAGES:
      return {
        ...state,
        multiplePackage: action.payload,
        loading: false,
      };
    case ACTIONS.GET_DISPLAY_CITIES:
      return {
        ...state,
        displayCities: action.payload,
        loading: false,
      };
    case ACTIONS.GET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
        loading: false,
      };
    case ACTIONS.GET_REPORT_DOWNLOAD:
      return {
        ...state,
        reportDownload: action.payload,
        reportLoading: false,
      };
    case ACTIONS.CREATE_PANEL_USER:
      return {
        ...state,
        createPanelUser: action.payload,
        loading: false,
      };
    default:
    case ACTIONS.GET_PANEL_USER_LIST_DATA:
      return {
        ...state,
        panelUserList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_LIST:
      return {
        ...state,
        panelList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANEL_USER_DATA:
      return {
        ...state,
        userListData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PANELS_USERS:
      return {
        ...state,
        userList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_AREA_COMMENTS:
      return {
        ...state,
        areaComments: action.payload,

        loading: false,
      };
    case ACTIONS.GET_UNMASKED_NUMBER:
      return {
        ...state,
        unmaskedNumber: action.payload,
        loading: false,
      };
    case ACTIONS.GET_INDIVIDUAL_WHATSAPP_CHAT:
      return {
        ...state,
        individualWhatsappChat: action.payload,
        loadingIndividualChat: false,
      };

    case ACTIONS.GET_PANEL_COMMENT:
      return {
        ...state,
        panelComments: action.payload,
        loading: false,
      };

    case ACTIONS.GET_TODAYS_MISSED_CALLS:
      return {
        ...state,
        todayMissedCallsList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_VERIFIED_BY_LIST:
      return {
        ...state,
        verifiedbyList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SELF_USER_TYPE:
      return {
        ...state,
        selfUserType: action.payload,
        loading: false,
      };

    case ACTIONS.GET_CUSTOMERS_LIST:
      return {
        ...state,
        customerList: action.payload,
        loading: false,
      };
    case ACTIONS.GET_RED_COIN:
      return {
        ...state,
        redCoin: action.payload,
        loading: false,
      };
    case ACTIONS.GET_SUB_CENTER_INFORMATION:
      return {
        ...state,
        subCenter: action.payload,
        loading: false,
      };
    case ACTIONS.GET_LEAD_STATUS:
      return {
        ...state,
        leadStatus: action.payload,
        loading: false,
      };
    case ACTIONS.GET_PRESCRIPTION_DATA:
      return {
        ...state,
        prescription: action.payload,
        loading: false,
      };
      case ACTIONS.GET_TICKET_COMMENTS:
        return {
          ...state,
          ticketComments: action.payload,
          loading: false,
        };
    case ACTIONS.GET_ADDRESS_TYPE_SEATING:
      return {
        ...state,
        addressType: action.payload,
        loading: false,
      };
      case ACTIONS.CHECK_GEOFENCE_AREA_MAMPMYINDIA:
        return {
          ...state,
          geoFencesMapMyIndia: action.payload,
          geoLoading: false,
          geoCheckError:""
        };
    case ACTIONS.GET_MAMPMYINDIA_ACCESS_TOKEN:
      return {
        ...state,
        mapmyindiaAccessToken: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDRESS_DETAILS_LAT_LONG:
      return {
        ...state,
        addressDetailsLatLong: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_LOGIN_STATUS:
      return {
        ...state,
        loginStatus: action.payload,
        loading: false,
      };

    case ACTIONS.GET_SIGNATURE:
      return {
        ...state,
        signature: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDITIONAL_BOOKING:
      return {
        ...state,
        additionMember: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QRCODE_DATA:
      return {
        ...state,
        qrcode: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_DATA:
      return {
        ...state,
        ticketData: action.payload,
        loading: false,
      };
    case ACTIONS.GET_TICKET_CATEGORIES:
      return {
        ...state,
        ticketCategories: action.payload,
        loading: false,
      };

    case ACTIONS.GET_TICEKT_SUB_CATEGORIES:
      return {
        ...state,
        ticketSubCategories: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QUALITY_SCORING_REPORT:
      return {
        ...state,
        qualityScoringReport: action.payload,
        loading: false,
      };
    case ACTIONS.GET_QUALITY_SCORING_REPORT_FOR_BACKUP:
      return {
        ...state,
        get_quality_scoring_report_for_backup: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CE_CENTER_FOR_CC:
      return {
        ...state,
        ceCenterForCC: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ASSIGNED_UNASSIGNED_BOOKING:
      return {
        ...state,
        allBookings: action.payload,
        loading: false,
      };
    case ACTIONS.SET_GEO_LOADING:
      return {
        ...state,
        geoLoading: action.payload,
      };
    case ACTIONS.SET_GEO_CHECK_ERROR:
      return {
        ...state,
        geoCheckError: action.payload,
      };
    case ACTIONS.GET_BOOKING_COMPLAINTS:
      return {
        ...state,
        bookingComplaints: action.payload,
        loading: false,
      };
    case ACTIONS.UPDATE_DISPOSITION:
      return {
          ...state,
          disposition: action.payload,
          loading: false,
      };
    case ACTIONS.GET_BIOMARKER_SCREENING:
      return {
        ...state,
        biomarkerScreening: action.payload,
        biomarkerLoader: false,
      };
    case ACTIONS.GET_BIOMARKER_ULTASOUND:
      return {
        ...state,
        biomarkerUltasound: action.payload,
        loading: false,
      };
    case ACTIONS.GET_ADDITIONAL_MEMBER:
      return {
        ...state,
        markerAdditionMember: action.payload,
        loading: false,
      };
    case ACTIONS.BIOMARKER_RESET:
      return {
        ...state,
        biomarkerScreening: {},
      };
    case ACTIONS.SET_ALL_BOOKING_LOADING:
      return {
        ...state,
        bookingListLoader: action.payload,
      };
    case ACTIONS.GET_NEW_BOOKING_LIST:
      return {
        ...state,
        newbookingList: action.payload,
        bookingListLoader: false,
      };
    case ACTIONS.SEND_LOCATION_REQUEST:
      return {
        ...state,
        sendLocationOnWhatsapp: action.payload,
        loading: false,
      };
    case ACTIONS.GET_CRM_LEAD_ADDRESS:
      return {
        ...state,
        crmLeadAddress: action.payload,
        loading: false,
      };
    case ACTIONS.POST_CRM_LEAD_ADDRESS:
      return {
        ...state,
        crmPostLeadAddress: action.payload,
        loading: false,
      };
    case ACTIONS.LOCATION_VERIFIED:
      return {
        ...state,
        locationVerified: action.payload,
        loading: false,
      };
    case ACTIONS.SET_BOOKING_ID_LOADER:
      return {
        ...state,
        bookingIdLoader: action.payload,
      };
    case ACTIONS.SET_BIOMARKER_LOADER:
      return {
        ...state,
        biomarkerLoader: action.payload,
      };
      case ACTIONS.GET_PANEL_USER_LIST_DATA_SALES_LEAD:
        return {
          ...state,
          salesLeadList: action.payload,
          loading: false,
        };
        case ACTIONS.GET_OZONTEL_SKILL_DATA:
          return {
            ...state,
            ozontelskilldata: action.payload,
            loading: false,
          };
    case ACTIONS.SET_REPORT_LOADING:
      return {
        ...state,
        reportLoading: action.payload,
      };
  }
};

export default SalesLeadReduce;