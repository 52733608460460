import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { Container,Grid, TextField } from "@material-ui/core";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from "@material-ui/lab/Autocomplete";
import HelpIcon from "@material-ui/icons/Help";
import { generateBookingFilterUrl } from "../../helpers/generateUrl";
import { connect } from "react-redux";
import {
  getPartners,
  getAllBookings,
  getCities,
  getAllNewBookings,
  getPhlebos,
  getUserDetails,
} from "../actions/bookingActions";
import "./filter.sass";
import { useHistory } from "react-router";
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import BookingsTable from "../tables/bookingsTable";
import Loader from "../components/Loader2/index";
import  {BOOKING_STATUS} from '../../booking_status'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
    },
    toolbar: {
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      width: "80%",
      flexGrow: 21,
    },
    contentHeader: {
      width: "100%",
      padding: "0px 10px 40px 10px",
    },
    leftContentHeader: {
      width: "70%",
      float: "left",
    },
    rightContentHeader: {
      width: "30%",
      float: "right",
      textAlign: "right",
      paddingRight: "20px",
    },
    contentTable: {
      width: "100%",
      minHeight: "400px",
    },
    contentFilter: {
      width: "100%",
      padding: "0px 10px 10px 0px",
    },
    card: {
      height: "100%",
      padding: theme.spacing(1),
    },
    card_grid: {
      justifyContent: "space-between",
    },
  })
);

interface Props {
  children: any;
  bookingList: any;
  partnerList: any;
  getPartners: any;
  getAllBookings: any;
  getCities: any;
  cities: any;
  getPhlebos: any;
  getAllNewBookings: any;
  newbookingList:any;
  phleboList: any;
  loading: Boolean;
  bookingListLoader:boolean;
  userDetails: any
  getUserDetails: any;
}

const CustomerLeadFilterPage: React.FC<Props> = ({
  partnerList,
  getPartners,
  getAllBookings,
  bookingList,
  getCities,
  cities,
  getAllNewBookings,
  newbookingList,
  getPhlebos,
  phleboList,
  loading,
  bookingListLoader,
  userDetails,
  getUserDetails,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [openModal, setopenModal] = useState<boolean>(false);
  const [status, setStatus] = useState<any>([]);
  const [source, setSource] = useState<any>([]);
  const [partner, setPartner] = useState<any>([]);
  const [agent, setAgent] = useState<any>([]);
  const [city, setCity] = useState<any>([]);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [booking_date, setBooking_date] = useState("");
  const [collection_date, setCollection_date] = useState("");
  const [verificationStatus, setVerificationStatus] = useState<any>([]);
  const [pickup_status, setPickup_status] = useState<any>([]);
  const [name, setName] = useState("");
  const [phlebo, setPhlebo] = useState<any>([]);
  const [phone, setPhone] = useState("");
  const [bookingId, setBookingid] = React.useState<any>("");
  const [defaultStatus, setDefaultStatus] = useState<any>([]);
  const [open, setOpen] = React.useState(false);
  const [locationVerified, setLocationVerified] = useState<any>("none");
  const [locationMessageStatus, setLocationMessageStatus] = useState<any>([]);
  const blockCharInPhoneField = (e: React.KeyboardEvent<HTMLDivElement>) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
  const blockCharInAutocompleteField = (e: React.KeyboardEvent<HTMLDivElement>) => {
    return ("~!@#$%^&*()_+{}|:<>?,./;'[]\=-0987654321`").includes(e.key) && e.preventDefault();
  }
  const [page, setPage] = useState(0);
  const timer = useRef<any>(0);

  useEffect(() => {
    if (history.action === "POP") {
      let ceBookingFilter: any;
      ceBookingFilter = localStorage.getItem("CEBookingFilter");
      if (ceBookingFilter) {
        ceBookingFilter = JSON.parse(ceBookingFilter);
        const statusD = ceBookingFilter?.status?.split(",");
        let statusTemp = new Array();
        BOOKING_STATUS.map((item: any) => {
          statusD.map((itm: any) => {
            if (item.value == itm) {
              statusTemp.push(item);
            }
          });
        });
        setDefaultStatus(statusTemp);
        ceBookingFilter?.status &&
          setStatus(ceBookingFilter?.status?.split(","));
          ceBookingFilter?.locationMessageStatus &&
          setLocationMessageStatus(ceBookingFilter?.locationMessageStatus?.split(","));
        ceBookingFilter?.source &&
          setSource(ceBookingFilter?.source?.split(","));
        ceBookingFilter?.partner &&
          setPartner(ceBookingFilter?.partner?.split(","));
        ceBookingFilter?.city && setCity(ceBookingFilter?.city?.split(","));
        ceBookingFilter?.start_date &&
          setStart_date(ceBookingFilter?.start_date);
        setCollection_date(ceBookingFilter?.collection_date);
        ceBookingFilter?.verificationStatus &&
          setVerificationStatus(
            ceBookingFilter?.verificationStatus?.split(",")
          );
        ceBookingFilter?.pickup_status &&
          setPickup_status(ceBookingFilter?.pickup_status?.split(","));
        setName(ceBookingFilter?.name);
        setPhlebo(ceBookingFilter?.phlebo);
        setPhone(ceBookingFilter?.phone);
        setBookingid(ceBookingFilter?.bookingId);
        const url = generateBookingFilterUrl(ceBookingFilter).substring(2);
        const pageNo = page ? page : 1;
        // getAllBookings(`${url}&page=${pageNo}&optimize=true`);
      }
    } else {
    
      // getAllBookings("optimize=true");
      localStorage.removeItem("CEBookingFilter");
      setDefaultStatus([]);
    }
  }, [setDefaultStatus]);

  useEffect(() => {
    // getAllBookings("optimize=true");
    userDetails?.profile?.pilot_user ? (
      getAllNewBookings("")
    ) : getAllBookings("optimize=true");
  }, [])
  const onKeyDown = (e: any) => {
    if (e.key === "Enter") {
      filterBookings(e);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const filterBookings = (e: any) => {
    const body: any = {
      status: status ? status.join(",") : "",
      verificationStatus: verificationStatus
        ? verificationStatus.join(",")
        : "",
      source: source ? source.join(",") : "",
      agent: agent ? agent.join(",") : "",
      city: city ? city.join(",") : "",
      start_date,
      end_date,
      booking_date,
      collection_date,
      pickup_status: pickup_status ? pickup_status.join(",") : "",
      location_event_status: locationMessageStatus ? locationMessageStatus.join(",") : "",
      name,
      phlebo: phlebo ? phlebo.join(",") : "",
      // partner: partner ? partner.join(",") : "",
      phone,
      bookingId,
      // location_verified: locationVerified,
    };
    localStorage.setItem("CEBookingFilter", JSON.stringify(body));
    let url = generateBookingFilterUrl(body).substring(2);
    if (locationVerified === "a") {
      if (url === "") url = "address_verified=true&distance_above_deviation=true"
      else url = url + "&address_verified=true&distance_above_deviation=true"
    }
    if (locationVerified === "b") {
      if (url === "") url = "address_verified=false&distance_above_deviation=true"
      else url = url + "&address_verified=false&distance_above_deviation=true"
    }
    if (locationVerified === "c") {
      if (url === "") url = "address_verified=true&distance_above_deviation=false"
      else url = url + "&address_verified=true&distance_above_deviation=false"
    }
    if (locationVerified === "d") {
      if (url === "") url = "location_not_shared=true"
      else url = url + "&location_not_shared=true"
    }
    if (locationVerified === "e") {
      if (url === "") url = "customer_out_of_zone=true"
      else url = url + "&customer_out_of_zone=true"
    }
    {
      userDetails?.profile?.pilot_user ? (
        getAllNewBookings(url ? `${url}&page=1` : "page=1")
      ) :   getAllBookings(url ? `${url}&page=1&optimize=true` : "page=1&optimize=true")
    }
    //  getAllBookings(url?`${url}&page=1&optimize=true`:"page=1&optimize=true");
    setPage(0);
  };

  document.querySelectorAll("#noTyping").forEach(el=>{
    el.addEventListener("keydown", function(e){
      e.preventDefault()
    })
  })

  return (
    <>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <div className={classes.contentHeader}>
          <div className={classes.leftContentHeader}>
          <h4 style={{ fontWeight: "bold" }}>BOOKING LIST <HelpIcon style={{ color: "#9c27b0" }} onClick={handleClickOpen} /></h4>
          </div>
          {/* <div className={classes.rightContentHeader}>
            <Link to="/dashboard/ce/booking">
              <Button
                color="primary"
                variant="contained"
                style={{ margin: "5px" }}
              >
                New Booking
              </Button>
            </Link>
          </div> */}
        </div>
        <div className={classes.contentFilter}>
          <Grid
            container
            spacing={1}
            direction="row"
            style={{alignItems:"left"}}
          >
            <Grid item xs={12} sm={4} md={2}>
              <TextField
                className="input"
                name="booking"
                type="number"
                label="Booking Id"
                value={bookingId}
                variant="outlined"
                onWheel={(e: any) => e.target.blur()}
                inputProps={{ min: 0 }}
                onKeyDown={(e) => {
                  blockCharInPhoneField(e)
                  onKeyDown(e)
                }}
                onChange={(e) => setBookingid(e.target.value)}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <TextField
                className="input"
                name="name"
                type="text"
                label="Name"
                value={name}
                variant="outlined"
                onKeyDown={onKeyDown}
                onChange={(e) => setName(e.target.value as string)}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <TextField
                className="input"
                name="phone"
                type="number"
                label="Phone"
                value={phone}
                variant="outlined"
                onWheel={(e: any) => e.target.blur()}
                onKeyDown={(e) => {
                  blockCharInPhoneField(e)
                  onKeyDown(e)
                }}
                inputProps={{ min: 0 }}
                onChange={(e) => setPhone(e.target.value as string)}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Autocomplete
                id="city"
                onChange={(event, newValue) => {
                  let sourceIds: any = [];
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    sourceIds = obj.map((item: any) => item.id);
                  }
                  setCity(sourceIds);
                }}
                options={cities}
                freeSolo
                blurOnSelect
                aria-required
                limitTags={1}
                multiple
                onKeyDown={onKeyDown}
                getOptionLabel={(option: any) => option.name}
                getOptionDisabled={(option: any) => {
                  return city.includes(option.id);
                }}
                disableClearable
                disableCloseOnSelect
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getCities(newInputValue);
                  }, 1000);
                  if (newInputValue.length === 0) {
                    setCity([]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="City"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (e) => {
                            if (e.key === 'Enter') {
                              e.stopPropagation();
                            }
                      },
                    }}
                    onKeyDown={(e) => {
                      blockCharInAutocompleteField(e)
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Autocomplete
                id="noTyping"
                onChange={(event, newValue) => {
                  let sourceIds: any = [];
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    sourceIds = obj.map((item: any) => item.value);
                  }
                  setStatus(sourceIds);
                }}
                options={BOOKING_STATUS}
                blurOnSelect
                aria-required
                onKeyDown={onKeyDown}
                limitTags={1}
                multiple
                getOptionLabel={(option: any) => option.text}
                getOptionDisabled={(option: any) => {
                  return status.includes(option.value);
                }}
                disableClearable
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Booking Status"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                )}
                defaultValue={defaultStatus}
              />
            </Grid>
            {/* <Grid item xs={12} sm={4} md={2}>
              <Autocomplete
                id="partner"
                onChange={(event, newValue) => {
                  let sourceIds: any = [];
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    sourceIds = obj.map((item: any) => item.id);
                  }
                  setPartner(sourceIds);
                }}
                freeSolo
                blurOnSelect
                limitTags={1}
                multiple
                options={partnerList?.results||[]}
                onKeyDown={onKeyDown}
                getOptionDisabled={(option: any) => {
                  return partner.includes(option.id);
                }}
                disableClearable
                disableCloseOnSelect
                getOptionLabel={(option: any) =>
                  option.user && option.user.name
                }
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getPartners(newInputValue);
                  }, 1000);
                  if (newInputValue.length === 0) {
                    setPartner([]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Partner"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                )}
              />
            </Grid> */}
            <Grid item xs={12} sm={4} md={2}>
              <Autocomplete
                id="phlebo"
                onChange={(event, newValue) => {
                  let sourceIds: any = [];
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    sourceIds = obj.map((item: any) => item.id);
                  }
                  setPhlebo(sourceIds);
                }}
                freeSolo
                blurOnSelect
                aria-required
                limitTags={1}
                multiple
                options={
                  phleboList.results
                    ? [
                        ...phleboList.results,
                        { id: 0, user: { username: "NoPhlebo" } },
                      ]
                    : []
                }
                getOptionDisabled={(option: any) => {
                  return phlebo.includes(option.id);
                }}
                disableClearable
                disableCloseOnSelect
                onKeyDown={onKeyDown}
                getOptionLabel={(option: any) =>
                  option.user && option.user.username
                }
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timer.current);
                  timer.current = setTimeout(() => {
                    getPhlebos(newInputValue);
                  }, 1000);
                  if (newInputValue.length === 0) {
                    setPhlebo([]);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Phlebo Name"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Autocomplete
                id="noTyping"
                onChange={(event, newValue) => {
                  let sourceIds: any = [];
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    sourceIds = obj.map((item: any) => item.value);
                  }
                  setVerificationStatus(sourceIds);
                }}
                options={[
                  {
                    text: "Pending",
                    value: "pending",
                  },
                  {
                    text: "Confirmed",
                    value: "confirmed",
                  },
                  {
                    text: "Cancelled",
                    value: "cancelled",
                  },
                ]}
                freeSolo
                blurOnSelect
                aria-required
                limitTags={1}
                onKeyDown={onKeyDown}
                multiple
                getOptionLabel={(option: any) => option.text}
                getOptionDisabled={(option: any) => {
                  return verificationStatus.includes(option.value);
                }}
                disableClearable
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Verification Status"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Autocomplete
                id="noTyping"
                onChange={(event, newValue) => {
                  let sourceIds: any = [];
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    sourceIds = obj.map((item: any) => item.value);
                  }
                  setPickup_status(sourceIds);
                }}
                options={[
                  {
                    text: "Pending",
                    value: "pending",
                  },
                  {
                    text: "Confirmed",
                    value: "confirmed",
                  },
                  {
                    text: "Cancelled",
                    value: "cancelled",
                  },
                  {
                    text: "Hold",
                    value: "hold",
                  },
                ]}
                freeSolo
                blurOnSelect
                aria-required
                limitTags={1}
                multiple
                getOptionLabel={(option: any) => option.text}
                onKeyDown={onKeyDown}
                getOptionDisabled={(option: any) => {
                  return pickup_status.includes(option.value);
                }}
                disableClearable
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Pickup Status"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <TextField
                className="input"
                name="start_date"
                type="date"
                label="Start Date"
                value={
                  booking_date === "" || collection_date === ""
                    ? start_date
                    : ""
                }
                variant="outlined"
                disabled={booking_date !== "" || collection_date !== ""}
                onKeyDown={onKeyDown}
                onChange={(e) => String(e.target.value).length<=10?setStart_date(e.target.value as string):""}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <TextField
                name="end_date"
                type="date"
                value={
                  booking_date === "" || collection_date === "" ? end_date : ""
                }
                className="input"
                label="End Date"
                variant="outlined"
                disabled={booking_date !== "" || collection_date !== ""}
                onChange={(e) => String(e.target.value).length<=10?setEnd_date(e.target.value as string):""}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <TextField
                name="booking_date"
                type="date"
                value={start_date === "" || end_date === "" ? booking_date : ""}
                className="input"
                
                label="Booking Date"
                variant="outlined"
                onKeyDown={onKeyDown}
                disabled={start_date !== "" || end_date !== ""}
                onChange={(e) => String(e.target.value).length<=10?setBooking_date(e.target.value as string):""}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <TextField
                name="collection_date"
                type="date"
                value={
                  start_date === "" || end_date === "" ? collection_date : ""
                }
                className="input"
                label="Collection Date"
                variant="outlined"
                onKeyDown={onKeyDown}
                disabled={start_date !== "" || end_date !== ""}
                onChange={(e) => String(e.target.value).length<=10?setCollection_date(e.target.value as string):""}
                style={{ width: "100%" }}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
                <Autocomplete
                  id="noTyping"
                  onChange={(event, newValue) => {
                    let sourceIds: any = [];
                    if (newValue) {
                      let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                      setLocationVerified(obj.value);
                    }

                  }}
                  options={[
                    {
                      text: "More than 3 Km - Verified",
                      value: "a",
                    },
                    {
                      text: "More than 3 Km - Not Verified",
                      value: "b",
                    },
                    {
                      text: "Less than 3 Km - Verified",
                      value: "c",
                    },
                    {
                      text: "Location not shared",
                      value: "d",
                    },
                    {
                      text: "Non-Serviceable - Not Verified",
                      value: "e",
                    },
                  ]}
                  freeSolo
                  blurOnSelect
                  aria-required
                  getOptionLabel={(option: any) => option.text}
                  disableClearable
                  disableCloseOnSelect
                  renderInput={(params) => (
                    <TextField
                      className="input"
                      {...params}
                      label="Location/Verified"
                      variant="outlined"
                      style={{ width: "100%", margin: "0", padding: "0" }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
              <Autocomplete
                id="noTyping"
                onChange={(event, newValue) => {
                  let sourceIds: any = [];
                  if (newValue) {
                    let obj = JSON.parse(JSON.stringify(newValue, null, " "));
                    sourceIds = obj.map((item: any) => item.value);
                  }
                  setLocationMessageStatus(sourceIds);
                }}
                options={[
                  {
                    text: "Triggred",
                    value: "triggred",
                  },
                  {
                    text: "Sent",
                    value: "sent",
                  },
                  {
                    text: "Delivered",
                    value: "delivered",
                  },
                  {
                    text: "Read",
                    value: "read",
                  },
                ]}
                freeSolo
                blurOnSelect
                aria-required
                limitTags={1}
                multiple
                getOptionLabel={(option: any) => option.text}
                onKeyDown={onKeyDown}

                disableClearable
                disableCloseOnSelect
                renderInput={(params) => (
                  <TextField
                    className="input"
                    {...params}
                    label="Location Message Status"
                    variant="outlined"
                    style={{ width: "100%", margin: "0", padding: "0" }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={filterBookings}
              >
                Filter
              </Button>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => history.push("/dashboard/ce/bookings")}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </div>
        <div className={classes.contentTable}>
          {bookingListLoader ? (
            <Loader />
          ) : (
            bookingList.results &&
            bookingList.results.length > 0 && (
              <Container maxWidth={false}>
                <Grid container spacing={3}>
                  <BookingsTable
                      setopenModal={setopenModal}
                      openModal={openModal}
                      page={page}
                      setPage={setPage}
                      loading={loading}
                      getBookings={getAllBookings}
                      bookingList={bookingList}
                      getAllNewBookings={getAllNewBookings}
                      newbookingList={newbookingList}
                      bookingListLoader={bookingListLoader} userDetails={userDetails}                  />
                </Grid>
              </Container>
            )
          )}
          {bookingList.results && bookingList.results.length === 0 && (
            <h6 style={{ textAlign: "center" }}>No Data Found</h6>
          )}
           <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Note:"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <div style={{ margin: "1rem" }}>
                <div style={{ alignItems: "left", display: "inline-block", margin: "1rem" }}>
                  <small>
                    <ul style={{ paddingLeft: "15px" }}>
                      <li>
                        You can filter by booking date or start and end date.
                      </li>
                      <li>T: Total Additional Booking count</li>
                      <li>P: Pending count for additional member</li>
                      <li>C: Confirmed count for additional member</li>
                      <li>
                        For B2B booking first select Booking Type b2b then apply
                        any filter option
                      </li>
                    </ul>
                  </small>
                </div>
                <div style={{ alignItems: "left", display: "inline-block", margin: "1rem" }}>
                  <small>
                    <div className="r-cl" style={{ display: "flex" }}><div className="boxStyle" style={{ border: "1px solid", width: "17px", height: "17px", background: "#ffe082", }}></div><div style={{ marginLeft: "5px" }}>B2B booking and Payable Amount is Zero</div></div>
                    <div className="r-cl" style={{ display: "flex" }}><div className="boxStyle" style={{ border: "1px solid", width: "17px", height: "17px", background: "#f48fb1", }}></div><div style={{ marginLeft: "5px" }}>B2B booking and Payable Amount is not Zero</div></div>
                    <div className="r-cl" style={{ display: "flex" }}><div className="boxStyle" style={{ border: "1px solid", width: "17px", height: "17px", background: "#64ffda", }}></div><div style={{ marginLeft: "5px" }}>B2C booking and Payable Amount is Zero</div></div>
                    <div className="r-cl" style={{ display: "flex" }}><div className="boxStyle" style={{ border: "1px solid", width: "17px", height: "17px", background: "default", }}></div><div style={{ marginLeft: "5px" }}>B2C booking and Payable Amount is not Zero</div></div>
                    <div className="r-cl" style={{ display: "flex" }}><div className="boxStyle" style={{ border: "1px solid", width: "17px", height: "17px", background: "#4caf50", }}></div><div style={{ marginLeft: "5px" }}>Phlebo Reached</div></div>
                  </small>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
        </div>
      </main>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  bookingList: state.BookingReducer.bookingList,
  cities: state.BookingReducer.cities,
  phleboList: state.BookingReducer.phleboList,
  newbookingList: state.BookingReducer.newbookingList,
  loading: state.BookingReducer.loading,
  bookingListLoader: state.BookingReducer.bookingListLoader,
  userDetails: state.BookingReducer.userDetails,
});

export default connect(mapStateToProps, {
  getPartners,
  getCities,
  getPhlebos,
  getAllBookings,
  getAllNewBookings,
  getUserDetails,
})(CustomerLeadFilterPage);
